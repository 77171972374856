import { Component, Input, OnInit } from '@angular/core';
import { CommonStandardsService } from '../common-standards.service';
import { CommonUtilities } from '@evolenta/utilities';
import { TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-check-lists',
    templateUrl: './common-standard-check-lists.component.html',
})
export class CommonStandardCheckListsComponent implements OnInit {
    @Input() public allowEdit = true;

    public metaData;
    public activeData: any = {};

    public editedCheckList;

    public tabs = [];
    public activeTab;
    public localizations;

    public constructor(
        public standardsService: CommonStandardsService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.metaData = this.standardsService.metaReglament.blocks.find((item) => item.code === 'checkLists');
        console.log('metaData', this.metaData);
        this.tabs.push({ code: this.metaData.checkListsData.code, name: this.metaData.checkListsData.name });
        this.activeData = {
            code: 'checkLists',
            name: this.metaData.checkListsData.name,
            allowEditInReglament: !this.metaData.checkListsData.allowEditInReglament,
        };
        this.standardsService.initBlockData(this.activeData);
        if (this.metaData.commonData) {
            this.tabs.push({ code: 'common', name: this.metaData.commonData.name });
            if (!this.activeData.entity.checkListsCommon) {
                this.activeData.entity.checkListsCommon = {};
            }
        }
        this.activeTab = this.tabs[0].code;
    }

    private _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public add() {
        if (!this.activeData.entity.checkLists) {
            this.activeData.entity.checkLists = [];
        }
        this.editedCheckList = {
            guid: CommonUtilities.GenerateGuid(),
        };
    }

    public edit(checkList) {
        this.editedCheckList = cloneDeep(checkList);
    }

    public delete(checkList) {
        const findIndex = this.activeData.entity.checkLists.findIndex((item) => item.guid === checkList.guid);
        this.activeData.entity.checkLista.splice(findIndex, 1);
        this.standardsService.saveEntity(this.activeData);
    }

    public apply() {
        const findIndex = this.activeData.entity.checkLists.findIndex(
            (item) => item.guid === this.editedCheckList.guid
        );
        if (findIndex === -1) {
            this.activeData.entity.checkLists.push(this.editedCheckList);
        } else {
            this.activeData.entity.checkLists[findIndex] = this.editedCheckList;
        }
        this.editedCheckList = null;
        this.standardsService.saveEntity(this.activeData);
    }

    public cancel() {
        this.editedCheckList = null;
    }
}
