<!-- Редактирование данных выбранного объекта в деле -->
<ng-container *ngIf="editedObject">
    <appeal-object-card [object]="editedObject"
                        [availableKinds]="availableObjectKinds"
                        [appeal]="appeal"
                        [subservice]="subservice"
                        mode="edit"
                        (onApply)="onApplyObject($event)"
                        #editCard>
    </appeal-object-card>
</ng-container>

<!-- Список объектов дела -->
<div *ngIf="!editedObject">
    <ng-container *ngFor="let object of appeal.objects">
        <appeal-object-card [object]="object"
                             mode="view"
                            [appeal]="appeal"
                            (onEdit)="onEditObject($event)"
                            (onDelete)="onDeleteObject($event)"
                            (onInitEdit)="initEdit($event)">
        </appeal-object-card>
    </ng-container>

    <div class="alert alert-warning alert-styled-left alert-bordered"
         *ngIf="appeal.objects.length == 0">
        <span class="text-semibold">
            {{localizations.common.attention}}
        </span>
        {{localizations['appeals.objects'].absent}}
    </div>

    <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20 mr-20"
            (click)="addObject()"
            *ngIf="accessService.hasAccess([permissions.Appeal_Object_Create], true, appeal.status)">
        {{localizations['appeals.objects'].add}}
        <b>
            <i inlineSVG="assets/icon-plus-round.svg"></i>
        </b>
    </button>
</div>

<modal-dialog #applyModal (onSelect)="processingModal($event)"></modal-dialog>
