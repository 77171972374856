import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, SelectionService, StorageService } from '@evolenta/core';
import { Permission } from '../../../common/services/permission';
import { EventsService } from './events.service';
import * as moment from 'moment';

@Injectable()
export class EventsResolve implements Resolve<any> {
    public permissions = Permission;

    public constructor(
        private restService: RestService,
        private selectionService: SelectionService,
        private storage: StorageService,
        private eventsService: EventsService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.loadDefaultData(route);
    }

    public loadDefaultData(route) {
        const defaultStatuses = this.storage.getItem('defaultStatuses');
        if (!defaultStatuses) {
            // Получение базовых данных, нужных для работы с делом
            const promises = [];
            promises.push(this.restService.search('settings', {search: {search: [{field: 'name', operator: 'eq', value: 'defaultStatuses'}]}}));
            promises.push(this.restService.search('nsiErpKnmForms'));
            promises.push(this.restService.search('nsiErpKnmTypes'));

            return Promise.all(promises).then(data => {
                this.storage.setItem('defaultStatuses', data[0][0].value);

                this.storage.setItem('nsiErpKnmForms', data[1]);
                this.storage.setItem('nsiErpKnmTypes', data[2]);

                return this.getEventTypes(route);
            });
        } else {
            return this.getEventTypes(route);
        }
    }

    public getEventTypes(route) {
        const eventTypes = this.eventsService.eventTypes;
        if (!eventTypes || eventTypes.length === 0) {
            return this.restService.search('eventTypes', {size: 100}).then(types => {
                // const data = types.map(item => {return {
                //     _id: item._id,
                //     code: item.code,
                //     name: item.name,
                //     existsKinds: item.existsKinds,
                //     showOnCreate: item.showOnCreate,
                //     useSpecialForm: item.useSpecialForm,
                //     allowCreateAppeal: item.allowCreateAppeal,
                //     allowCreateTask: item.allowCreateTask,
                //     allowCreateProgramGsn: item.allowCreateProgramGsn,
                //     allowCreatePreventionProgram: item.allowCreatePreventionProgram,
                //     statuses: item.statuses,
                //     kinds: item.kinds
                // }});
                this.eventsService.eventTypes = types;

                return this.getData(route);
            });
        } else {
            return this.getData(route);
        }
    }

    public getData(route) {
        const isCreateAction = route.url.filter(item => item.path === 'create').length > 0;
        const isEditAction = route.url.filter(item => item.path === 'edit').length > 0;
        const currentOrganization = this.storage.getItem('currentOrganization');

        const promisess = [];
        if (this.selectionService.isProcessSelect) {
            return {};
        } else if (isCreateAction) {
            promisess.push(Promise.resolve({}));
            promisess.push(this.restService.search('typeAppeals'));
        } else if (isEditAction) {
            const eventId = route.params['eventId'];
            promisess.push(this.restService.find('events', eventId));
            promisess.push(this.restService.search('typeAppeals'));
        } else {
            const params = [
                {
                    field: 'unit.id',
                    operator: 'eq',
                    value: currentOrganization._id,
                },
                {
                    orSubConditions: [
                        {
                            field: 'dateRelevance',
                            operator: 'eq',
                            value: null,
                        },
                        {
                            field: 'dateRelevance',
                            operator: 'le',
                            value: moment().add(3, 'days').format('YYYY-MM-DDTHH:mm:ssZZ'),
                        },
                    ],
                },
            ];

            return this.restService.search('events', { sort: 'dateLastModification,DESC', search: { search: params }});
        }

        return Promise.all(promisess).then(response => {
                this.storage.setItem('typeAppeals', response[1]);

                return response[0];
            },
        );
    }
}
