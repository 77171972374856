import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService } from '@evolenta/core';

@Injectable()
export class ReportsResolve implements Resolve<any> {

    public constructor(
        private restService: RestService,
        private storageService: StorageService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const reportId = route.params.reportId;
        const isCreateAction = route.url.filter(item => item.path === 'create').length > 0;
        if (isCreateAction) {
            // Создание
            return Promise.resolve(this.restService.search('metaReglaments'));
        } else if (reportId) {
            // Редактирование
            return this.restService.find('reports', reportId);
        } else {
            const currentOrganization = this.storageService.getItem('currentOrganization');
            const search = [{field: 'unit.id', operator: 'eq', value: currentOrganization._id}];

            return Promise.resolve(this.restService.search('reports', {
                search: {search: search},
                sort: 'dateLastModification,DESC',
            }));
        }
    }
}
