import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()
export class XsdJsonService {
    public typesObj = {}; // массив дополнительных элементов описывающих основную структуру
    public externalData = {};
    public disableOnInit = [];

    public SCHEMA_TYPES = {
        object: 'object',
        array: 'array',
        string: 'string',
        number: 'number',
        integer: 'integer',
        decimal: 'decimal',
        boolean: 'boolean',
    };

    public FORM_TYPES = {
        selectfieldset: 'selectfieldset',
        fieldset: 'fieldset',
        textarea: 'textarea',
        array: 'array',
        row: 'row',
        none: 'none',
        date: 'date',
        radio: 'radios',
        hidden: 'hidden',
    };

    // ограничение на длину строки, если максимальная длина строки больше, то поле ввода нужно делать textarea
    public MAX_STRING_LENGTH_FOR_INPUT = 255;

    // для добавления в форму кнопок
    public formActionButtons = {
        type: 'actions',
        items: [
            {
                type: 'submit',
                title: 'Сохранить данные',
            },
        ],
    };

    public emptyFormActionButtons = { type: 'actions', items: [] };

    // формирует составной ключ объекта
    public static _createObjectKey(obj, parentObj) {
        let key = obj.name;
        let part = '';
        if (parentObj && parentObj.key) {
            if (parentObj.maxOccurs) {
                part = '[]';
            }
            // if ((parentObj.minOccurs && !parentObj.maxOccurs &&
            //         !(parentObj.complexType && parentObj.choice)) ||
            //     (parentObj.maxOccurs && parentObj.minOccurs &&
            //         parentObj.maxOccurs !== parentObj.minOccurs)) {
            //     part = '[]';
            // }
            key = parentObj.key + part + '.' + key;
        }

        return key;
    }

    public static _isInArray(value, array) {
        return array.indexOf(value) > -1;
    }

    public static _getKey(key) {
        const intKey = parseInt(key, 10);
        if (intKey.toString() === key) {
            return intKey;
        }
        return key;
    }

    // проверяет, является ли объект массивом или нет
    public static _isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    }

    public static _elementsArray(obj) {
        let array: any = [];
        if (XsdJsonService._isArray(obj)) {
            array = obj;
        } else {
            array.push(obj);
        }
        return array;
    }

    private _getObjectPath(obj, path) {
        if (obj) {
            if (typeof path === 'number' || Object.prototype.toString.call(path) === '[object Number]') {
                path = [path];
            }

            if (typeof path === 'string' || Object.prototype.toString.call(path) === '[object String]') {
                return this._getObjectPath(obj, path.split('.'));
            }

            let currentPath = XsdJsonService._getKey(path[0]);

            if (path.length === 1) {
                if (obj !== undefined) {
                    if (Object.prototype.toString.call(obj) === '[object Array]') {
                        const substring = '#';
                        const pos = currentPath.indexOf(substring);
                        if (pos > -1) {
                            const idx = currentPath.substring(0, pos);
                            currentPath = currentPath.substring(pos + 1, currentPath.length);
                            obj = obj[idx];
                        }
                    }
                    if (obj[currentPath] === undefined) {
                        return '';
                    }
                    return obj[currentPath];
                }
            } else {
                return '';
            }
            return this._getObjectPath(obj[currentPath], path.slice(1));
        }
    }

    private _groupingProperties(properties) {
        const propertiesCount = properties.length;
        const grouping = [];
        let innerObj: any = {};
        innerObj.indexes = [];
        for (let i = 0; i < propertiesCount; i++) {
            if (i > 0) {
                const fromComplexToSimple =
                    properties[i - 1] && properties[i - 1].complexType && !(properties[i] && properties[i].complexType);
                const fromSimpleToComplex =
                    !(properties[i - 1] && properties[i - 1].complexType) && properties[i] && properties[i].complexType;
                if (fromComplexToSimple || fromSimpleToComplex) {
                    innerObj.count = innerObj.indexes.length;
                    grouping.push(innerObj);
                    innerObj = {};
                    innerObj.indexes = [];
                }
            }
            if (!innerObj.group) {
                if (properties[i].complexType) {
                    innerObj.group = this.FORM_TYPES.none;
                } else {
                    innerObj.group = this.FORM_TYPES.row;
                }
            }
            innerObj.indexes.push(i);
            if (i === propertiesCount - 1) {
                innerObj.count = innerObj.indexes.length;
                grouping.push(innerObj);
            }
        }
        return grouping;
    }

    private _getExternalType(obj) {
        if (obj.type && this.typesObj[obj.type]) {
            obj = $.extend({}, obj, this.typesObj[obj.type]);
        }
        if (
            !obj.title &&
            (!obj.annotation || (obj.annotation && !obj.annotation.appinfo && !obj.annotation.appinfo.displayName))
        ) {
            if (obj.complexType && obj.complexType.annotation) {
                obj.annotation = {
                    appinfo: {},
                };
                obj.annotation.appinfo.displayName = obj.complexType.annotation.appinfo.displayName;
            }
        }
        return obj;
    }

    public _checkIsListStructure(obj) {
        let isList = false;
        if (obj.simpleType && obj.simpleType.restriction) {
            const item = obj.simpleType.restriction;
            if (
                item.enumeration ||
                (item.annotation && item.annotation.appinfo && item.annotation.appinfo.enumeration)
            ) {
                isList = true;
            }
        }
        return isList;
    }

    public createSchema(obj, schema, form, parentObj, fromChoice = false) {
        let formPart;
        let schemaPart;
        let value;
        schemaPart = {};
        formPart = {};
        if (parentObj) {
            if (obj.annotation && obj.annotation.appinfo.displayName) {
                obj.level = parentObj.level + 1;
            } else {
                obj.level = parentObj.level;
            }
            if (parentObj.lastOrder) {
                obj.order = parentObj.lastOrder + 1;
                if (obj.complexType) {
                    parentObj.lastOrder++;
                }
            } else {
                obj.order = 1;
                parentObj.lastOrder = 1;
            }
        }
        if (obj.name) {
            formPart.level = obj.level;
            formPart.order = obj.order;
            if (obj.minOccurs && (obj.minOccurs === 1 || obj.minOccurs === '1') && !obj.maxOccurs) {
                delete obj.minOccurs;
                obj.required = 1;
            }
            obj = this._getExternalType(obj);

            obj.key = XsdJsonService._createObjectKey(obj, parentObj);
            if (obj.complexType) {
                if (obj.complexType.choice && obj.minOccurs) {
                    if (obj.minOccurs === 1 || obj.minOccurs === '1') {
                        obj.requred = 1;
                    }
                    delete obj.minOccurs;
                    if (obj.maxOccurs) {
                        delete obj.maxOccurs;
                    }
                }
                if (obj.minOccurs || obj.maxOccurs) {
                    if (obj.minOccurs === 0 && !obj.maxOccurs) {
                        obj.maxOccurs = 1;
                    }
                    if (
                        obj.minOccurs &&
                        parseInt(obj.minOccurs, 10) === 1 &&
                        obj.maxOccurs &&
                        parseInt(obj.maxOccurs, 10) === 1
                    ) {
                        obj.requred = 1;
                        delete obj.maxOccurs;
                    }
                    if (obj.maxOccurs) {
                        obj.type = this.SCHEMA_TYPES.array;
                        this._createArraySchema(obj, schemaPart, formPart);
                    } else {
                        schemaPart.type = this.SCHEMA_TYPES.object;
                        schemaPart.required = true;
                        this._createObjectFieldsetSchema(obj, schemaPart, formPart);
                    }
                } else if (obj.complexType.sequence) {
                    schemaPart.type = this.SCHEMA_TYPES.object;
                    this._createObjectFieldsetSchema(obj, schemaPart, formPart);
                } else if (obj.complexType.sequence === '') {
                    obj.type = 'hidden';
                    this._createStringSchema(obj, schemaPart, formPart);
                } else if (obj.complexType.simpleContent) {
                    this._createFiasElementsWithAttribute(obj, schemaPart, formPart);
                }
                if (obj.complexType.choice) {
                    this._createObjectSelectFieldsetSchema(obj, schemaPart, formPart);
                }
            } else if (
                obj.type === this.SCHEMA_TYPES.string ||
                obj.type === 'date' ||
                obj.type === 'boolean' ||
                obj.type === 'integer' ||
                obj.type === 'decimal' ||
                obj.type === 'file' ||
                obj.type === 'nsi' ||
                !this._checkIsListStructure(obj)
            ) {
                this._createStringSchema(obj, schemaPart, formPart);
            } else if (obj.simpleType) {
                this._createSimpleObjectSchema(obj, schemaPart, formPart);
            }
            schema[obj.name] = schemaPart;
            form.push(formPart);
            return {
                schema: schema,
                form: form,
            };
        } else {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    value = obj[key];
                    if (typeof value === 'object') {
                        return this.createSchema(obj[key], schema, form, parentObj);
                    }
                }
            }
        }
    }

    /**
     * Обработка элементов ФИАС (атрибуты элементов: вид города, вид населенного пункта и т.д.)
     * @param obj
     * @param schemaPart
     * @param formPart
     * @private
     */
    private _createFiasElementsWithAttribute(obj, schemaPart, formPart) {
        schemaPart.type = 'string';
        schemaPart.title = obj.annotation.appinfo.displayName;
        formPart.key = obj.key;
        schemaPart.key = obj.key;
        schemaPart.detailData = true;
        const attribute = obj.complexType.simpleContent.extension.attribute;
        if (attribute.type && this.typesObj[attribute.type]) {
            const attributeElement = this.typesObj[attribute.type];
            schemaPart.attribute = {
                name: attribute.name,
                items: [],
            };
            schemaPart.properties = {};
            schemaPart.properties['__text'] = { type: 'string' };
            schemaPart.properties['_' + attribute.name] = { type: 'string' };
            schemaPart.properties['_guid'] = { type: 'string' };

            if (
                attributeElement.complexType &&
                attributeElement.complexType.restriction &&
                attributeElement.complexType.restriction.enumeration
            ) {
                attributeElement.complexType.restriction.enumeration.forEach((item) => {
                    schemaPart.attribute.items.push({
                        name: item.annotation.appinfo.displayName,
                        value: item.value,
                    });
                });
            }
        }
    }

    private _createObjectSelectFieldsetSchema(obj, schemaPart, formPart) {
        // console.log('choice', obj);
        let choiceSchema;
        let created;
        let name;
        let properties;
        let propertiesCount;
        schemaPart.properties = {};
        properties = XsdJsonService._elementsArray(obj.complexType.choice.element);
        propertiesCount = properties.length;
        name = obj.name + 'Choice';
        choiceSchema = {};
        choiceSchema.type = this.SCHEMA_TYPES.string;
        choiceSchema['enum'] = [];
        if (obj.required === 1) {
            choiceSchema.required = true;
        }

        formPart.type = this.FORM_TYPES.selectfieldset;
        if (obj.type === 'radio') {
            formPart.additionalType = 'radio';
        } else if (obj.type === 'boolean') {
            formPart.additionalType = 'boolean';
            schemaPart.notitle = true;
            formPart.notitle = true;
        }
        formPart.key = obj.key + '.' + name;
        formPart.titleMap = {};
        formPart.items = [];
        for (let i = 0; i < propertiesCount; i++) {
            choiceSchema['enum'].push(properties[i].name);
            formPart.titleMap[properties[i].name] = properties[i].annotation.appinfo.displayName;
        }
        schemaPart.properties[name] = choiceSchema;
        schemaPart.type = 'object';
        if (obj.annotation.appinfo) {
            formPart.title = obj.annotation.appinfo.displayName;
        }

        for (let i = 0; i < propertiesCount; i++) {
            created = this.createSchema(properties[i], {}, formPart.items, obj);
            schemaPart.properties = $.extend({}, schemaPart.properties, created.schema);
        }
        return schemaPart;
    }

    private _createArraySchema = function (obj, schemaPart, formPart) {
        let created;
        let externalDataValue;
        let formObj;
        let i;
        let itemsCount;
        let properties;
        if (obj.annotation && obj.annotation.appinfo) {
            schemaPart.title = obj.annotation.appinfo.displayName;
        }
        if (obj.minOccurs && !isNaN(obj.minOccurs)) {
            schemaPart.minItems = parseInt(obj.minOccurs, 10);
        }
        if (obj.maxOccurs && !isNaN(obj.maxOccurs)) {
            schemaPart.maxItems = parseInt(obj.maxOccurs, 10);
        }
        schemaPart.type = this.SCHEMA_TYPES.array;
        formPart.key = obj.key;
        formPart.type = this.FORM_TYPES.array;
        formPart.notitle = true;
        formPart.items = [];
        properties = [];
        if (obj.complexType.sequence) {
            properties = XsdJsonService._elementsArray(obj.complexType.sequence.element);
            itemsCount = properties.length;
        }
        schemaPart.items = {};
        formObj = {};
        formObj.type = 'row';
        formObj.items = [];
        if (obj.annotation && obj.annotation.appinfo.externalField) {
            externalDataValue = this._getObjectPath(this.externalData, obj.annotation.appinfo.externalField);
            if (externalDataValue !== '') {
                formPart.externalData = externalDataValue;
                formPart.externalField = obj.annotation.appinfo.externalField;
            }
        }
        for (i = 0; i < itemsCount; i++) {
            created = this.createSchema(properties[i], {}, formPart.items, obj);
            if (properties[i].complexType) {
                formObj.type = 'longrow';
            }
            formObj.items = $.extend([], formObj.items, created.form);
            schemaPart.items.properties = $.extend({}, schemaPart.items.properties, created.schema);
        }
        formPart.items = [];
        return formPart.items.push(formObj);
    };

    // обработка сложных объектов (не повторяющихся)
    private _createObjectFieldsetSchema(obj, schemaPart, formPart) {
        schemaPart.properties = {};
        formPart.type = this.FORM_TYPES.fieldset;
        if (obj.type === 'STD_Address') {
            // schemaPart.type = 'fias';
            formPart.type = 'fias';
            formPart.columns = 12;
            formPart.key = obj.key;
            schemaPart.key = obj.key;
        }
        formPart.elmKey = obj.key;
        if (obj.annotation && obj.annotation.appinfo) {
            formPart.title = obj.annotation.appinfo.displayName;
            schemaPart.title = obj.annotation.appinfo.displayName;
            if (obj.annotation.appinfo.columns) {
                formPart.columns = obj.annotation.appinfo.columns;
            }
            if (obj.annotation.appinfo.comment) {
                formPart.comment = obj.annotation.appinfo.comment;
            }
        } else if (obj.complexType.annotation) {
            formPart.title = obj.complexType.annotation.appinfo.displayName;
            schemaPart.title = obj.complexType.annotation.appinfo.displayName;
        }

        if (obj.annotation && obj.annotation.appinfo && obj.annotation.appinfo.showLabel) {
            schemaPart.notitle = true;
            formPart.notitle = true;
        }

        if (obj.annotation && obj.annotation.appinfo.externalField) {
            const externalDataValue = this._getObjectPath(this.externalData, obj.annotation.appinfo.externalField);
            if (externalDataValue !== '') {
                formPart.externalData = externalDataValue;
                formPart.externalField = obj.annotation.appinfo.externalField;
            }
        }

        if (obj.complexType.sequence.element) {
            formPart.items = [];
            const properties = XsdJsonService._elementsArray(obj.complexType.sequence.element);
            const grouping = this._groupingProperties(properties);
            const groupingCount = grouping.length;

            for (let i = 0; i < groupingCount; i++) {
                if (grouping[i] && grouping[i].group) {
                    if (grouping[i].group === this.FORM_TYPES.row) {
                        const formPartRow: any = {};
                        formPartRow.type = this.FORM_TYPES.row;
                        formPartRow.items = [];

                        for (let j = grouping[i].indexes[0]; j <= grouping[i].indexes[grouping[i].count - 1]; j++) {
                            const created = this.createSchema(properties[j], {}, formPartRow.items, obj);
                            schemaPart.properties = $.extend({}, schemaPart.properties, created.schema);
                        }

                        if (formPartRow.items.length > 0) {
                            for (const formItem of formPartRow.items) {
                                if (
                                    formItem &&
                                    formItem.type &&
                                    (formItem.type === 'array' || formItem.type === 'fieldset')
                                ) {
                                    formPartRow.type = 'longrow';
                                }
                            }
                        }
                        formPart.items.push(formPartRow);
                    } else {
                        for (let j = grouping[i].indexes[0]; j <= grouping[i].indexes[grouping[i].count - 1]; j++) {
                            const created = this.createSchema(properties[j], {}, formPart.items, obj);
                            schemaPart.properties = $.extend({}, schemaPart.properties, created.schema);
                        }
                    }
                }
            }
        }

        return schemaPart;
    }

    private _createStringSchema(obj, schemaPart, formPart) {
        let additionalType;
        let dateArr;
        let externalField;
        let externalFieldValue;
        formPart.key = obj.key;
        schemaPart.key = obj.key;
        schemaPart.type = 'string';
        if (obj.type === 'date') {
            formPart.type = this.FORM_TYPES.date;
        }
        if (obj.type === 'boolean') {
            schemaPart.type = this.SCHEMA_TYPES.boolean;
        }
        if (obj.type === 'integer') {
            schemaPart.type = this.SCHEMA_TYPES.number;
            formPart.type = this.SCHEMA_TYPES.number;
        }
        if (obj.type === 'file') {
            schemaPart.type = 'file';
            formPart.columns = 6;
        }
        if (obj.type === 'hidden') {
            formPart.type = this.FORM_TYPES.hidden;
        }
        if (obj.annotation) {
            if (obj.type === 'nsi' && obj.annotation.appinfo.dictionaryParams) {
                formPart.dictionaryParams = obj.annotation.appinfo.dictionaryParams;
                formPart.type = 'nsi';
                schemaPart.additionalType = 'dictionary';
                schemaPart.dictionaryParams = obj.annotation.appinfo.dictionaryParams;
            }
            if (obj.annotation.appinfo.toolTip) {
                schemaPart.tooltip = obj.annotation.appinfo.toolTip;
            }
            if (obj.annotation.appinfo.displayName) {
                schemaPart.title = obj.annotation.appinfo.displayName;
            }
            formPart.placeholder = obj.annotation.appinfo.displayName;
            if (obj.annotation.appinfo.comment) {
                schemaPart.description = obj.annotation.appinfo.comment;
            }
            if (obj.annotation.appinfo.prompt) {
                formPart.example = obj.annotation.appinfo.prompt;
                // formPart.placeholder = obj.annotation.appinfo.prompt;
            }
            if (obj.annotation.appinfo.externalField) {
                externalField = obj.annotation.appinfo.externalField;
                formPart.externalField = externalField;
                externalFieldValue = this._getObjectPath(this.externalData, externalField);
                if (externalFieldValue !== '') {
                    if (obj.type === 'date') {
                        dateArr = externalFieldValue.split('.');
                        externalFieldValue = dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0];
                    }
                    formPart['default'] = externalFieldValue;
                    schemaPart['default'] = externalFieldValue;
                }
            }
            if (obj.annotation.appinfo.readOnly) {
                schemaPart.readOnly = true;
            }
            if (obj.annotation.appinfo.additionalType) {
                additionalType = obj.annotation.appinfo.additionalType;
                schemaPart[additionalType] = true;
                if (additionalType === 'inn') {
                    formPart.mask = '9{12}';
                } else if (additionalType === 'innOrg') {
                    formPart.mask = '9{10}';
                } else if (additionalType === 'snils') {
                    formPart.mask = '9{3}-9{3}-9{3} 9{2}';
                } else if (additionalType === 'ogrn') {
                    formPart.mask = '9{13}';
                } else if (additionalType === 'ogrnip') {
                    formPart.mask = '9{15}';
                } else if (additionalType === 'mobile') {
                    formPart.mask = '+7-9{3}-9{3}-9{2}-9{2}';
                }
            }
            if (obj.annotation.appinfo.mask) {
                formPart.mask = obj.annotation.appinfo.mask;
            }
            if (obj.annotation.appinfo.columns) {
                formPart.columns = obj.annotation.appinfo.columns;
            }
            if (obj.annotation.appinfo.colSpan) {
                formPart.columns = obj.annotation.appinfo.colSpan;
                schemaPart.columns = obj.annotation.appinfo.colSpan;
            }
        }
        if ((obj.minOccurs && obj.minOccurs > 0) || obj.required) {
            schemaPart.required = true;
        }
        if (obj['default']) {
            schemaPart['default'] = obj['default'];
        }
        if (obj.maxLength) {
            schemaPart.maxLength = obj.maxLength;
        }
        if (obj.simpleType && obj.simpleType.restriction) {
            if (obj.simpleType.restriction.minLength) {
                schemaPart.minLength = obj.simpleType.restriction.minLength.value;
            }
            if (obj.simpleType.restriction.maxLength) {
                schemaPart.maxLength = obj.simpleType.restriction.maxLength.value;
            }
            if (obj.simpleType.restriction.max) {
                schemaPart.max = obj.simpleType.restriction.max.value;
            }
            if (obj.simpleType.restriction.min) {
                schemaPart.min = obj.simpleType.restriction.min.value;
            }
            if (obj.simpleType.restriction.minDate) {
                schemaPart.minDate = obj.simpleType.restriction.minDate.value;
            }
            if (obj.simpleType.restriction.maxDate) {
                schemaPart.maxDate = obj.simpleType.restriction.maxDate.value;
            }
            if (obj.simpleType.restriction.conditionData) {
                schemaPart.conditionData = obj.simpleType.restriction.conditionData.value;
            }
            if (obj.simpleType.restriction.elementCode) {
                schemaPart.elementCode = obj.simpleType.restriction.elementCode.value;
            }
            // Проблема с отсутствием возможности ввода пробела при использовании параметров ниже
            // if (!formPart.mask) {
            //     let mask;
            //     mask = '*{';
            //     if (obj.simpleType.restriction.minLength) {
            //         mask += obj.simpleType.restriction.minLength.value;
            //     } else {
            //         mask += '0';
            //     }
            //     mask += ',';
            //     if (obj.simpleType.restriction.maxLength) {
            //         mask += obj.simpleType.restriction.maxLength.value;
            //     }
            //     mask += '}';
            //     formPart.mask = mask;
            // }
        }
        if (
            (obj.maxLength && obj.maxLength > this.MAX_STRING_LENGTH_FOR_INPUT) ||
            (obj.annotation && obj.annotation.appinfo.asTextArea)
        ) {
            return (formPart.type = this.FORM_TYPES.textarea);
        }
    }

    private _createSimpleObjectSchema(obj, schemaPart, formPart) {
        let enums;
        let i;
        let listTitle;
        formPart.key = obj.key;
        schemaPart.type = 'string';
        if (obj.annotation) {
            if (obj.annotation.appinfo.toolTip) {
                schemaPart.tooltip = obj.annotation.appinfo.toolTip;
            }
            if (obj.annotation.appinfo.displayName) {
                schemaPart.title = obj.annotation.appinfo.displayName;
            }
            if (obj.annotation.appinfo.comment) {
                schemaPart.description = obj.annotation.appinfo.comment;
                formPart.comment = obj.annotation.appinfo.comment;
            }
            if (obj.annotation.appinfo.isExternalData) {
                formPart.isExternalData = true;
                if (obj.annotation.appinfo.isMultiple) {
                    formPart.isMultiple = true;
                    formPart.columns = 12;
                }
            }
            if (obj.annotation.appinfo.columns) {
                formPart.columns = obj.annotation.appinfo.columns;
            }
            if (obj.annotation.appinfo.classifier) {
                formPart.classifier = obj.annotation.appinfo.classifier;
            }
            if (obj.annotation.appinfo.dependents) {
                formPart.dependents = XsdJsonService._elementsArray(obj.annotation.appinfo.dependents.dependent);
                for (i = 0; i < formPart.dependents.length; i++) {
                    if (formPart.dependents[i].element) {
                        this.disableOnInit.push(formPart.dependents[i].element);
                    }
                }
            }
            if (obj.annotation.appinfo.externalField) {
                schemaPart.externalField = obj.annotation.appinfo.externalField;
            }
            if (obj.annotation.appinfo.readOnly) {
                schemaPart.readOnly = true;
            }
        }
        if (obj['default']) {
            schemaPart['default'] = obj['default'];
        }
        if (XsdJsonService._isInArray(obj.key, this.disableOnInit)) {
            schemaPart.disabled = true;
        }
        if ((obj.minOccurs && obj.minOccurs > 0) || obj.required) {
            schemaPart.required = true;
        }
        schemaPart['enum'] = [];
        if (obj.simpleType.restriction.enumeration || obj.simpleType.restriction.annotation.appinfo.enumeration) {
            if (obj.simpleType.restriction.enumeration) {
                enums = XsdJsonService._elementsArray(obj.simpleType.restriction.enumeration);
            } else {
                enums = XsdJsonService._elementsArray(obj.simpleType.restriction.annotation.appinfo.enumeration);
            }
            if (obj.annotation && obj.annotation.appinfo && obj.annotation.appinfo.asRadio === 'true') {
                formPart.type = this.FORM_TYPES.radio;
            }
            formPart.titleMap = {};
            for (i = 0; i < enums.length; i++) {
                schemaPart['enum'].push(enums[i].value);
                if (enums[i].annotation) {
                    if (enums[i].annotation.appinfo && enums[i].annotation.appinfo.displayName) {
                        listTitle = enums[i].annotation.appinfo.displayName;
                    } else if (enums[i].annotation.documentation) {
                        listTitle = enums[i].annotation.documentation;
                    }
                    formPart.titleMap[enums[i].value] = listTitle;
                } else {
                    listTitle = enums[i].value;
                }
            }
        }
    }

    public getSchemaAndFormDescription(jsonObj, showBtn) {
        // console.log('obj', jsonObj);
        if (jsonObj) {
            let mainObj;
            let element;
            if (jsonObj.schema) {
                element = jsonObj.schema;
            } else {
                element = jsonObj;
            }
            console.log('element', element);

            mainObj = element.element || element.obj.element;

            if (element.simpleType || element.complexType) {
                if (element.simpleType) {
                    if (element.simpleType.constructor === Array) {
                        element.simpleType.forEach((item) => {
                            this.typesObj[item.name] = { complexType: item };
                        });
                    } else {
                        this.typesObj[element.simpleType.name] = { complexType: element.simpleType };
                    }
                }

                if (element.complexType) {
                    if (element.complexType.constructor === Array) {
                        element.complexType.forEach((item) => {
                            this.typesObj[item.name] = { complexType: item };
                        });
                    } else {
                        this.typesObj[element.complexType.name] = { complexType: element.complexType };
                    }
                }
            }

            if (mainObj.annotation) {
                mainObj.level = 1;
                mainObj.order = 1;
            } else {
                mainObj.level = 0;
                mainObj.order = 0;
            }

            const created = this.createSchema(mainObj, {}, [], null);
            const schemaDescription = created.schema;
            const formDescription = created.form;

            // # добавляем кнопки
            if (showBtn) {
                formDescription.push(this.formActionButtons);
            } else {
                formDescription.push(this.emptyFormActionButtons);
            }

            return {
                schema: schemaDescription,
                form: formDescription,
            };
        } else {
            return {
                schema: null,
                form: null,
            };
        }
    }
}
