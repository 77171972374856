<ng-container *ngIf="entity[property]">
    <div class="row" >
        <div class="col-md-12">
            <label>{{title}}
                <span class="anchor text-info text-size-small ml-5" (click)="addSlot()" *ngIf="!disabled">добавить</span>
            </label>
            <ng-container *ngFor="let item of entity[property]; index as idx;">
                <div class="input-group input-group-lg mb-20">
                    <ng-select
                        [allowClear]="false"
                        [items]="documentsForSelect"
                        [placeholder]="placeholder"
                        [active]="getDocumentFromSlot(idx)"
                        (selected)="setDocumentToSlot($event, idx)"
                        (removed)="clearSlot(idx)"
                        [disabled]="disabled">
                    </ng-select>
                    <div class="input-group-btn">
                        <button class="btn btn-default" type="button" *ngIf="!idx" (click)="addSlot()" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                        <button class="btn btn-default" type="button" *ngIf="idx" (click)="deleteSlot(item)" [disabled]="disabled"><i class="icon-trash"></i></button>
                    </div>
                </div>           
            </ng-container>
        </div>
    </div>
</ng-container>
