import { NgModule } from '@angular/core';
import { CoreModule } from '@evolenta/core';
import { SubjectsModule } from '../subjects/subjects.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { CommonAppealCheckListsComponent } from './components/appeal-check-lists/common-appeal-check-lists.component';
import { CommonAppealCheckListsService } from './services/common-appeal-check-lists.service';

@NgModule({
    imports: [
        CoreModule,
        SharedComponentsModule,
        SharedElementsModule,
        SubjectsModule,
    ],
    declarations: [
        CommonAppealCheckListsComponent,
    ],
    providers: [
        CommonAppealCheckListsService,
    ],
    exports: [
        CommonAppealCheckListsComponent,
    ],
})
export class CommonAppealCheckListsModule {
}
