import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService, FiltersBarComponent, RestService, StorageService, TranslateService } from '@evolenta/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Permission } from '../../../common/services/permission';
import { WindowWidth } from '../../../common/services/window-width.service';
import { UsersData } from './users.data';
import * as _ from 'lodash-es';

@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    styleUrls: ['../../elements-list.css'],
})
export class UsersComponent implements OnInit {
    @ViewChild('previewModal', { static: false }) public previewModal: ModalDirective;
    @ViewChild('filtersPanel', { static: false }) public filtersPanel: FiltersBarComponent; // панель фильтров
    public model = 'users';

    public users = [];
    public filtersPanelItems = []; // Настройки панели фильтрации
    public mode = 'short';

    // Параметры пагинации
    public totalUsersCount = 0; // Общее число дел
    public currentPage = 0;
    public totalPages: number;

    // Параметры предпросмотра
    public user = null; // Дело, отображаемое в режиме предпросмотра
    public prevent = false;
    public previewMode = false;
    public timer = null;
    public delay = 300;

    public baseSearch = []; // Параметы базового поиска
    public globalSearch;

    public canAccess = this.access.hasAccess([Permission.Users_Search]);
    public canCreate = this.access.hasAccess([Permission.User_Create]);

    public moduleBaseUrl;
    public localizations;

    public constructor(
        private access: AccessService,
        private router: Router,
        private storage: StorageService,
        private rest: RestService,
        private ref: ApplicationRef,
        private windowWidth: WindowWidth,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
            if (this.moduleBaseUrl !== 'admin') {
                this.baseSearch.push({
                    field: 'sprOrganizations.id',
                    operator: 'eq',
                    value: this.storage.getItem('currentOrganization')['id'],
                });
            }
        });

        this.route.data.subscribe((response) => {
            this.users = response['resolves'];
            this.totalUsersCount = this.rest.pagination.total;
        });
        this.filtersPanelItems = _.cloneDeep(UsersData.FiltersPanelItems);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'users']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getNewUser() {
        this.router.navigate([this.moduleBaseUrl, 'users', 'create']);
    }

    /**
     * Полномочия на активность кнопок
     */
    public disabledBtn(permissions) {
        return !this.access.hasAccess(permissions);
    }

    /**
     * Метод получения дел после фильтрации, либо скроллинга
     * @param type: replace - полное обновление списка; add - добавление записей в конец списка (скроллинг)
     */
    public loadData(type) {
        if (
            type === 'replace' ||
            (type === 'add' && ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages))
        ) {
            // Параметры настроенных фильтров
            const search = this.filtersPanel.prepareFilters();
            this.user = null;

            // Страница
            let page = 0;
            if (type === 'add') {
                page = this.currentPage + 1;
            }

            const params = {
                search: { search: search },
                size: 20,
                page: page,
            };

            // Обработка параметров глобального поиска - добавляем в результирующий поиск orSubCondition по нескольким полям
            if (this.globalSearch) {
                const fieldsParams = ['name', 'login', 'position'].map((field) => ({
                    field,
                    operator: 'like',
                    value: this.globalSearch,
                }));
                const orSubConditions = {
                    orSubConditions: fieldsParams,
                };
                search.push(orSubConditions);
            }

            // Получение списка дел с сервера
            this.rest.search('users', params).then((users: any) => {
                if (type === 'replace') {
                    this.users = users;
                } else {
                    users.forEach((item) => {
                        this.users.push(item);
                    });
                }
                this.totalUsersCount = this.rest.pagination.total;
                this.currentPage = this.rest.pagination.page;
                this.totalPages = this.rest.pagination.totalPages;
            });
        }
    }

    /**
     * Поиск осуществляется при вводе каждой буквы
     */
    public search(event?) {
        if (event) {
            this.loadData('replace');
        } else {
            this.clearSearch();
        }
    }

    public clearSearch() {
        this.globalSearch = null;
        this.loadData('replace');
    }

    /**
     * Выбор клиента для отображения информации на панели просмотра
     * @param user
     */
    public click(user) {
        this.user = null;

        this.timer = setTimeout(() => {
            if (!this.prevent) {
                if (this.windowWidth.getWidth() < 950) {
                    this.previewModal.show();
                } else {
                    this.previewMode = true;
                }
                this.ref.tick();
                const collectionName = 'users';
                this.rest.find(collectionName, user._id).then((item) => {
                    this.user = item;
                });
            }
            this.prevent = false;
        }, 300);
    }

    public dblClickByUser(user) {
        clearTimeout(this.timer);
        this.prevent = true;
        this.router.navigate([this.moduleBaseUrl, 'users', 'edit', user._id]);
    }

    /**
     * Очистка всех фильтров в панели фильтров
     */
    public clearFilters() {
        this.filtersPanel.clearFilters();
    }

    /**
     * Удаление юзера
     */
    public deleteUser() {
        this.user = null;
        this.loadData('replace');
    }
}
