import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@evolenta/core';

interface ITaskType {
    taskName: string;
    taskGuid: string;
    period?: number;
}

@Component({
    selector: 'common-standard-periods-group',
    templateUrl: './common-standard-periods-group.component.html',
    styleUrls: ['./common-standard-periods-group.component.less'],
})
export class CommonStandardPeriodsGroupComponent implements OnInit, OnChanges {
    @Input() public groupIdx: number = -1;
    @Input() public tasks: ITaskType[];
    @Input() public isEditing: boolean;
    @Input() public isUngroup = false;
    @Input() public period: number;
    @Input() public currentGroups: number[];
    public groupOptions: { id: number; text: string }[];

    @Output() public changedTaskGroup = new EventEmitter<{
        taskGuid: string;
        targetGroup: number | null;
        prevGroup: number | null;
    }>();
    @Output() public changedPeriod = new EventEmitter<{ period: number; groupIdx?: number; taskGuid?: string }>();

    public localizations;

    public constructor(private translate: TranslateService) {
        this._loadTranslations();
    }

    public ngOnInit() {
        this.setOptionsList();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (!changes) {
            return;
        }
        if (changes['currentGroups']) {
            this.setOptionsList();
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    private setOptionsList() {
        const groups = this.currentGroups.map((num) => ({
            id: num + 1,
            text: this.localizations.standards.periods.group_num.replace('%s', num + 1),
        }));
        this.groupOptions = [
            { id: -1, text: this.localizations.standards.periods.without_group },
            ...groups,
            {
                id: groups.length + 1,
                text: this.localizations.standards.periods.group_num.replace('%s', groups.length + 1),
            },
        ];
    }

    public selectGroup(taskGuid: string, group: number) {
        const groupIdx = group === -1 ? null : group - 1;
        const prevGroupIdx = this.groupIdx === -1 ? null : this.groupIdx;
        if (groupIdx !== prevGroupIdx) {
            this.changedTaskGroup.emit({
                taskGuid,
                targetGroup: groupIdx,
                prevGroup: prevGroupIdx,
            });
        }
    }

    public changePeriod(days, taskGuid?: string) {
        const inputValue = parseInt(days, 10);
        const period = isNaN(inputValue) || inputValue < 0 ? 0 : inputValue;

        this.changedPeriod.emit({
            taskGuid,
            groupIdx: this.groupIdx === -1 ? null : this.groupIdx,
            period,
        });
    }

    public getSelectedGroup() {
        const idx = this.groupIdx === -1 ? this.groupIdx : this.groupIdx + 1;

        return this.groupOptions.filter((item) => item.id === idx);
    }

    public getGroupTitle() {
        return this.isUngroup
            ? this.localizations.standards.periods.without_group
            : this.localizations.standards.periods.group_num.replace('%s', this.groupIdx + 1);
    }
}
