<ng-container>
    <div class="panel panel-shadow m-20"
         *ngFor="let subject of subjects">
        <div class="panel-heading pl-20 pr-20">
            <div class="panel-title">
                <h4 class="no-margin-top mb-5">
                    {{
                    subject.specialTypeId != 'ulApplicant' && subject.data.person ?
                      subject.data.person.lastName + ' ' + subject.data.person.firstName + ' ' +  subject.data.person.middleName :
                      subject.data.organization.shortName
                    }}
                </h4>
                <div>
                    <span class="badge text-uppercase mr-10"
                          [ngClass]="{
                                  'bg-teal-300': subject.specialTypeId == 'ulApplicant',
                                  'bg-warning-300': subject.specialTypeId !== 'ulApplicant'}">
                        {{
                        subject.specialTypeId != 'ulApplicant' ?
                            localizations['appeals.subjects'].individual_entrepreneur :
                            localizations['appeals.subjects'].legal_body
                        }}
                    </span>
                </div>
            </div>
            <!-- Управляющие кнопки -->
            <div class="heading-elements">
                <button class="btn btn-default"
                        title="Редактировать"
                        (click)="goToSubject(subject)">
                    <b><i inlineSVG="assets/icon-edit.svg"></i></b>
                </button>
                <button class="btn btn-default"
                        title="Удалить"
                        (click)="removeSubject(subject)"
                        [disabled]="!allowEdit">
                    <b><i inlineSVG="assets/icon-trash.svg"></i></b>
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <subject-preview-card [subject]="subject"
                                  [fromAppeal]="false"></subject-preview-card>
        </div>
    </div>

    <ng-container *ngIf="!subjects || subjects.length === 0">
        <div class="alert alert-warning no-border no-border-radius">
            <span class="text-semibold">
                {{ localizations.common.attention }}
            </span>
            {{ localizations.objects.is_not_connected_with_any_subject }}
        </div>
    </ng-container>
    <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20 mr-20"
            (click)="selectSubjects()"
            [disabled]="!allowEdit">
        {{ localizations.objects.add_subject }}
        <b>
            <i inlineSVG="assets/icon-plus-round.svg"></i>
        </b>
    </button>
</ng-container>
