<div class="panel panel-shadow panel-small" [ngClass]="{'m-20': !noMargin, 'no-margin': noMargin, 'mb-20': mode == 'view'}">
    <div class="panel-heading" [ngClass]="{'pl-20 pr-20': mode == 'view'}">
        <div class="panel-title">
            <!-- Заголовок объекта -->
            <h4 class="no-margin-top mb-5">{{object.name ? object.name : 'Новый объект'}}</h4>
            <div>
                <span class="badge badge-info text-uppercase mr-10">Объект</span>
                <span class="badge badge-info text-uppercase mr-10" *ngIf="object.type">{{object.type.name}}</span>
                <span class="badge bg-orange-300 text-uppercase mr-10" *ngIf="checkOtherRegion && isOtherRegionObject">Объект другого региона</span>
            </div>
        </div>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default"
                    [title] = "objectParams.permissions.edit || objectParams.permissions.editMainXsd || objectParams.permissions.editXsd ? 'Редактировать' : 'Просмотреть'"
                    (click)="edit()"
                    *ngIf="mode == 'view' && (objectParams.permissions.edit || objectParams.permissions.editMainXsd || objectParams.permissions.editXsd)">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>
            <button class="btn btn-default"
                    title="Удалить"
                    (click)="deleteObject()" *ngIf="mode == 'view' && objectParams.permissions.remove">
                <b><i inlineSVG="assets/icon-trash.svg"></i></b>
            </button>
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="apply()"
                    *ngIf="mode == 'edit'">
                Применить
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()" *ngIf="mode == 'edit'">
                Отменить
            </button>
        </div>
    </div>
    <div class="panel-body no-padding no-margin-bottom">

        <!-- Карточка предпросмотра объекта -->
        <div class="p-20" *ngIf="!allowEditCommonData">
            <object-preview-card [object]="object" [showName]="false" [showDetail]="false"></object-preview-card>

            <ng-container *ngIf="mode == 'view' && objectSubservices.length > 0">
                <p class="text-muted">Участие в КНМ:</p>
                <ul>
                    <li *ngFor="let subservice of objectSubservices">
                        <p class="mb-3">
                            {{subservice.title}}
                            <ng-container *ngIf="subservice.subjects.length > 0">
                                <span class="text-muted ml-10">Субъекты: </span>
                                <ng-container *ngFor="let subject of subservice.subjects; let lst = last">{{subject}}<ng-container *ngIf="!lst">, </ng-container></ng-container>
                            </ng-container>
                        </p>
                    </li>
                </ul>
            </ng-container>
        </div>

        <div class="ml-20 mr-20 mb-20 p-20 mt-20 border-top border-right border-bottom border-left border-grey-120"
             *ngIf="!object.name && objectParams.allowSelectFromRegistry && mode == 'edit'">
            <!-- Строка поиска -->
            <div class="form-group no-margin-bottom">
                <div class="input-group input-group-lg">
                    <input type="text" class="form-control input"
                           [placeholder]="'Поиск в ' + (objectParams.selectOnlyFromGlobalRegistry ? 'глобальном' : '') + ' реестре объектов '+ (objectParams.selectOnlyFromGlobalRegistry ? '' : 'КНО') + '...'"
                           (keypress)="keypress($event)"
                           name="searchText" [(ngModel)]="searchText">
                    <div class="input-group-btn pl-20">
                        <button type="button" class="btn btn-primary-overline" (click)="searchObject()">Найти</button>
                    </div>
                    <div class="input-group-btn pl-20" *ngIf="appeal">
                        <button class="btn btn-primary-overline"
                                type="button"
                                (click)="selectFromRegistry()">
                            Выбрать из реестра
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="pl-20 pr-20 pb-20" *ngIf="allowEditCommonData" [ngClass]="{'pt-20': !objectParams.allowSelectFromRegistry || object.name}">
            <object-edit [object]="object"
                         [isProcessValidate]="isProcessValidate"
                         [requirementsRules]="getFieldsRequirementsRules()"
                         [allowedEditFields]="objectParams && objectParams.allowedEditFields ? objectParams.allowedEditFields : null"
                         #editObjectForm></object-edit>
        </div>

        <common-appeal-entity-additional-data  [entity]="object"
                                               type="object"
                                               [appeal]="appeal"
                                               [subservice]="subservice"
                                               [mode]="mode"
                                               [permissions]="objectParams.permissions"
                                               #additionalData></common-appeal-entity-additional-data>

        <common-appeal-documents [appeal]="appeal"
                                 [subservice]="subservice"
                                 [byGroupTypes]="true"
                                 [task]="{}"
                                 [showOnInit]="mode === 'edit'"
                                 [object]="object"></common-appeal-documents>
    </div>
</div>

<modal-dialog [message]="modalMessage" [title]="modalTitle" (onSelect)="processingModal($event)"></modal-dialog>

<!-- модальное окно для выбора объекта -->
<ng-template #selectObjectModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Выберите объект</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="radio-block" *ngFor="let object of findedObjects">
            <input type="radio"
                   id="object{{object._id}}"
                   name="radio"
                   class="radio"
                   (click)="selectedObject = object">
            <label for="object{{object._id}}">
                <p class="text-bold mb-3">{{object.name}}</p>
                <p *ngIf="object.address && (object.address | json) != '{}'"><span class="text-muted">Адрес:</span> {{getAddress(object.address)}}</p>
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="selectObject()" [disabled]="!this.selectedObject">Выбрать</button>
    </div>
</ng-template>
