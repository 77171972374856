<div class="panel panel-shadow panel-small" *ngIf="tasks.length > 0">
    <div class="panel-heading cursor-pointer"  (click)="showTasks = !showTasks">
        <h6 class="text-bold">{{tasksName}}</h6>
        <div class="heading-elements">
            <ul class="icons-list">
                <li><a class="control-arrow"><i [ngClass]="{'icon-arrow-down12': showTasks, 'icon-arrow-left12': !showTasks}"></i></a></li>
            </ul>
        </div>
    </div>
    <div class="panel-body collapse no-padding"  [ngClass]="{'in' : showTasks}">
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th class="text-bold">Код</th>
                <th class="text-bold">Наименование</th>
                <th class="text-bold text-center" *ngIf="type && type == 'subProcess'">Подпроцесс</th>
                <th class="text-bold" *ngIf="type == 'businessRuleTasks'">DMN</th>
                <th *ngIf="type == 'businessRuleTasks'"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let task of tasks; let fst = first; let lst = last">
                <tr>
                    <td (click)="toggleRow(task)" width="64">
                        <span *ngIf="isCanChange" class="control-arrow text-slate"><i [ngClass]="{'icon-circle-down2': panels[task.id], 'icon-circle-right2': !panels[task.id]}"></i></span>
                    </td>
                    <td width="200">{{ task.id }}</td>
                    <td>
                        {{ task.name }}
                    </td>
                    <td class="text-center" *ngIf="type && type == 'subProcess'">
                        <span class="badge bg-success-300 text-uppercase ml-5" *ngIf="task.subProcess">Выбран</span>
                        <span class="badge bg-slate-300 text-uppercase ml-5" *ngIf="isCanChange && !task.subProcess">не выбран</span>
                    </td>
                    <td class="no-border-top border-bottom border-bottom-default " *ngIf="type == 'businessRuleTasks'">
                        <ng-container *ngIf="task.dmn && dmnProcesses[task.dmn]">
                            <a class="anchor" [routerLink]="['/processes','business-rules','edit', task.dmn]">{{dmnProcesses[task.dmn].title}}</a>
                        </ng-container>
                    </td>
                    <td class="text-right border-bottom border-bottom-default" *ngIf="type == 'businessRuleTasks'">
                        <button type="button" class="btn btn-sxs btn-default" title="Выбрать DMN"
                                *ngIf="type == 'businessRuleTasks'" (click)="selectDmn(task)">DMN</button>
                    </td>
                </tr>
                <tr *ngIf="type == 'subProcess'">
                    <td colspan="4" class="no-padding no-border" *ngIf="panels[task.id]" [ngClass]="{'warning': !task.subProcess}">
                        <div class="p-20" *ngIf="task.subProcess">
                            <bpmn-card [bpmn]="task.subProcess"
                                       [isSubProcess]="true"
                                       (onDelete)="deleteProcess(task)"
                                       (onEdit)="editProcess(task)"
                            ></bpmn-card>
                        </div>

                        <div class="p-10" *ngIf="!task.subProcess">
                            <span class="text-bold">Внимание!</span> Не выбран подпроцесс.
                            <ng-container *ngIf="allowEdit">
                                <span class="anchor text-warning ml-10" (click)="searchProcess(task.id)">выбрать процесс из реестра</span> или <span class="anchor text-warning" (click)="addNew(task.id)">добавить новый процесс</span>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-container>

            </tbody>
        </table>
    </div>
</div>




