import { NgModule } from '@angular/core';
import { ServicesService } from '../modules/subservices/services/services.service';
import { ProcessRegistryService } from '../modules/processes/process-registry/process-registry.service';
import { OrderByPipe } from './pipes/orderby.pipe';
import { SnilsValidatorDirective } from './validators/snils-validator.directive';
import { InnValidatorDirective } from './validators/inn-validator.directive';
import { IpOgrnValidatorDirective } from './validators/ip-ogrn-validator.directive';
import { OgrnValidatorDirective } from './validators/ogrn-validator.directive';
import { OrgInnValidatorDirective } from './validators/org-inn-validator.directive';
import { KppValidatorDirective } from './validators/kpp-validator.directive';
import { PassportValidatorDirective } from './validators/passport-validator.directive';

@NgModule({
    imports: [],
    declarations: [
        OrderByPipe,
        SnilsValidatorDirective,
        InnValidatorDirective,
        OgrnValidatorDirective,
        OrgInnValidatorDirective,
        IpOgrnValidatorDirective,
        KppValidatorDirective,
        PassportValidatorDirective,
    ],
    exports: [
        SnilsValidatorDirective,
        OrderByPipe,
        SnilsValidatorDirective,
        InnValidatorDirective,
        OgrnValidatorDirective,
        OrgInnValidatorDirective,
        IpOgrnValidatorDirective,
        KppValidatorDirective,
        PassportValidatorDirective,
    ],
    providers: [ServicesService, ProcessRegistryService],
})
export class SharedElementsModule {}
