<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            (click)="saveAppeal()"
            [disabled]="appealSaveService.isProcessSavingAppeal
                        || (appeal._id ? !accessService.hasAccess([permissions.Appeal_Update], true, appeal.status)
                                       : !accessService.hasAccess([permissions.Appeal_Create], true, appeal.status))">
        <b><i class="icon-checkmark-circle" [ngClass]="{'flash': appealSaveService.isProcessSavingAppeal}"></i></b>
        {{localizations.common.save}}
    </button>
    <div class="ml-20 navbar-title mt-20">
        <h4>
            <span *ngIf="!appeal._id">
                {{localizations.appeals.edit.creation}}
            </span>
            <ng-container *ngIf="appeal._id && appeal.status.code != 'consultation'">
                <span>{{localizations.appeals.appeal}}&nbsp;
                <ng-container *ngIf="appeal.number">
                    <span class="text-muted"> № </span>
                    <span class="hidden-md"> {{appeal.number}} </span>
                    <span class="visible-md-inline-block"> {{appeal.shortNumber}} &nbsp;</span>
                    <span class="text-muted">
                        {{localizations.common.from}}
                        {{appeal.dateRegister | date: 'shortDate'}}
                    </span>
                </ng-container>
                </span>
            </ng-container>
            <ng-container *ngIf="appeal._id && appeal.status.code == 'consultation'">
                <span>
                    {{localizations.common.consultation}}
                </span>
                <span *ngIf="appeal.consultationNumber">
                    № {{appeal.consultationNumber}} {{localizations.common.from}} {{appeal.dateConsultation | date: 'shortDate'}}
                </span>
            </ng-container>

            <!-- Статус дела -->
            <label class="badge text-uppercase ml-15 mb-15"
                   [ngClass]="getStatusProperty('background')">
                {{getStatusProperty('name')}}
            </label>
            <label class="badge text-uppercase bg-danger-300 ml-10 mb-15"
                   *ngIf="appeal.fromPgu || appeal.pguEventId">
                {{ localizations.common.rpgu }}
            </label>
            <label class="badge text-uppercase bg-teal-300 ml-10 mb-15"
                   *ngIf="appeal.fromPgu2 || appeal.pgu2EventId">
                {{ localizations.common.rpgu2 }}
            </label>
            <label class="badge text-uppercase bg-brown-300 ml-10 mb-15"
                   *ngIf="appeal.fromMfc || appeal.mfcEventId">
                {{ localizations.common.mfc }}
            </label>
            <label class="badge text-uppercase bg-green-300 ml-10 mb-15"
                   *ngIf="appeal.fromStroyu || appeal.stroyuEventId">
                {{ localizations.common.yastroyu }}
            </label>
            <label class="badge text-uppercase bg-orange-300 ml-10 mb-15"
                   *ngIf="appeal.fromLocolo || appeal.locoloEventId">
                {{ localizations.common.locolo }}
            </label>
            <label class="badge text-uppercase bg-success-300 ml-10 mb-15"
                   *ngIf="appeal.fromZabota || appeal.zabotaEventId">
                {{ localizations.common.zabota }}
            </label>
            <label class="badge text-uppercase bg-purple-200 ml-10 mb-15"
                   *ngIf="appeal.fromEpgu || appeal.epguOrderId">
                {{ localizations.common.epgu }}
            </label>
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container"
     *ngIf="initialized">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content">

            <!-- Вкладка "Активные задачи" -->
            <div class="sidebar-category"
                 *ngIf="appeal._id && appeal.status.code != 'draft' && appealProcessService.isProcessActive"
                 (click)="activateTab('activeTasks')"
                 [ngClass]="{'active': activeTab == 'activeTasks', 'cursor-pointer' : activeTab != 'activeTasks'}" >
                <div class="category-title">
                    <span>
                        {{localizations.appeals.edit.active_tasks}}
                        <span class="badge badge-primary badge-small ml-5 text-semibold">
                            {{appealProcessService.activeTasks.length}}
                        </span>
                    </span>
                </div>
            </div>

            <!-- Вкладка "Процессы" -->
            <div class="sidebar-category"
                 *ngIf="appeal._id && appeal.status.code != 'draft' && existCamundaProcess"
                 (click)="activateTab('completedTasks')"
                 [ngClass]="{'active': activeTab == 'completedTasks', 'cursor-pointer' : activeTab != 'completedTasks'}">
                <div class="category-title">
                    <span>
                        {{localizations.appeals.edit.completed_tasks}}
                    </span>
                </div>
            </div>

            <!-- Вкладка "Услуги" -->
            <div class="sidebar-category"
                 (click)="activateTab('subservice')"
                 [ngClass]="{'active': activeTab == 'subservice', 'cursor-pointer' : activeTab != 'subservice'}">
                <div class="category-title">
                    <span>
                        {{localizations.services.title}}
                        <span class="badge badge-danger badge-small ml-5 text-semibold"
                              *ngIf="validateService.getEntityErrorsCount('subservice') > 0">
                            {{validateService.getEntityErrorsCount('subservice')}}
                        </span>
                    </span>
                </div>
            </div>

            <!-- Вкладка "Субъекты" -->
            <div class="sidebar-category"
                 (click)="activateTab('subjects')"
                 [ngClass]="{'active': activeTab == 'subjects', 'cursor-pointer' : activeTab != 'subjects' && !disabledSubjectsTab, 'disabled': disabledSubjectsTab}">
                <div class="category-title">
                    <span>
                        {{ localizations.common.participants }}
                        <span class="badge badge-danger badge-small ml-5 text-semibold"
                              *ngIf="validateService.getEntityErrorsCount('subjects') > 0">
                            {{validateService.getEntityErrorsCount('subjects')}}
                        </span>
                    </span>
                </div>
            </div>

            <!-- Вкладка "Документы" -->
            <div class="sidebar-category" (click)="activateTab('documents')"
                 [ngClass]="{'active': activeTab == 'documents', 'cursor-pointer' : activeTab != 'documents' && !disabledDocumentTab, 'disabled': disabledDocumentTab}">
                <div class="category-title">
                    <span>
                        {{localizations.appeals.edit.documents}}

                        <!-- Уведомление о наличии необработанных ответов на запросы в документах дела -->
                        <i class="icon-bookmark2 ml-5 text-primary"
                           title="Необработанные ответы на запросы"
                           *ngIf="appeal.isHavingResult">
                        </i>

                        <!-- Уведомление о ошибках в документах дела -->
                        <span class="badge badge-danger badge-small ml-5 text-semibold"
                              *ngIf="validateService.getEntityErrorsCount('documents') > 0">
                            {{validateService.getEntityErrorsCount('documents')}}
                        </span>

                    </span>
                </div>
            </div>

            <!-- Вкладка "История" -->
            <div class="sidebar-category"
                 *ngIf="appeal._id"
                 (click)="activateTab('history')"
                 [ngClass]="{'active': activeTab == 'history', 'cursor-pointer' : activeTab != 'history'}">
                <div class="category-title">
                    <span>
                        {{localizations.appeals.edit.history}}
                    </span>
                </div>
            </div>

            <div class="mt-30">
                <!-- Доступные действия для дела -->
                <div class="status-action"
                     *ngIf="isAllowAppealActions">
                    <div class="status-action-btn">
                        <ul class="list-unstyled">
                            <ng-container *ngFor="let action of actions">
                                <li class="text-center m-15"
                                    *ngIf="checkAllowAction(action)">
                                    <div class="btn-group btn-group-sm flex">
                                        <button class="btn bg-blue-800 btn-labeled"
                                                [disabled]="!accessService.hasAccess([permissions.Appeal_Execute_Action], true, appeal.status) || processingAction"
                                                (click)="activateAction(action)">
                                            <i class="icon-checkmark3"
                                               [ngClass]="{'flash': processingAction && processingAction.guid == action.guid}">
                                            </i>
                                        </button>
                                        <button class="btn no-white-space bg-blue-800 text-center p-5" data-btn="appeal"
                                                [disabled]="!accessService.hasAccess([permissions.Appeal_Execute_Action], true, appeal.status) || processingAction"
                                                (click)="executeAction({action: action})">
                                            {{action.name}}
                                        </button>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>

                <!-- Доступные действия для услуги дела -->
                <div class="status-action"
                     *ngIf="appeal._id && appealSubserviceActions.length > 0 && appeal.status.code !== 'complete'">
                    <div class="status-action-btn">
                        <ul class="list-unstyled">
                            <li *ngFor="let action of appealSubserviceActions"
                                class="text-center m-15">
                                <div class="btn-group flex">
                                    <button class="btn"
                                            [ngClass]="{'bg-blue-800': action.guid == activeAction.guid, 'bg-blue-600': action.guid != activeAction.guid}"
                                            [disabled]="!accessService.hasAccess([permissions.Appeal_Execute_Action], true, appeal.status) || processingAction"
                                            (click)="activateAction(action)">
                                        <i class="icon-checkmark3"
                                           [ngClass]="{'icon-spinner2 spinner': processingAction && processingAction.guid == action.guid,
                                                                           'icon-checkmark3': !processingAction || processingAction.guid != action.guid}">
                                        </i>
                                    </button>
                                    <button class="btn no-white-space p-5"
                                            data-btn="service"
                                            [ngClass]="{'bg-blue-700': action.guid == activeAction.guid, 'bg-blue': action.guid != activeAction.guid}"
                                            [disabled]="!accessService.hasAccess([permissions.Appeal_Execute_Action], true, appeal.status) || processingAction"
                                            (click)="executeAction({action: action, appealSubservice: appeal.subservice})">
                                        {{action.name}}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Действия для дел, находящихся в преархивной коллекции -->
                <div class="status-action"
                     *ngIf="appeal.status.code == 'archive' && appeal.inPreArchive">
                    <div class="status-action-btn">
                        <ul class="list-unstyled">
                            <ng-container>
                                <li class="text-center m-15 mt-30">
                                    <button class="btn no-white-space btn-primary form-control"
                                            (click)="sendToArchive()"
                                            [disabled]="isProcessingCleanupAndMoveToArchive">
                                        {{localizations.appeals.edit.depersonalize}}
                                        <i class="icon-spinner2 spinner"
                                           *ngIf="isProcessingCleanupAndMoveToArchive">
                                        </i>
                                    </button>
                                </li>

                                <li class="text-center m-15">
                                    <button class="btn no-white-space btn-primary form-control"
                                            [disabled]="isProcessingRollbackFromPreArchive"
                                            (click)="returnFromArchive()">
                                        {{localizations.appeals.edit.return_from_archive}}
                                        <i class="icon-spinner2 spinner"
                                           *ngIf="isProcessingRollbackFromPreArchive">
                                        </i>
                                    </button>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Блоки с настройками элементов дела -->
    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-radius no-margin no-padding">
            <div>

                <!-- Услуги дела -->
                <appeal-subservices-create [appeal]="appeal"
                                    [subservice]="subservice"
                                    [complexSubservice]="complexSubservice"
                                    (onAfterSave)="activateTabAfterCheckSave($event)"
                                    (onApply)="continueProcessingAppealAfterApplyEditedElement()"
                                    (onExecuteAction)="executeAction($event)"
                                    (afterSelectSubservice)="onSelectSubservice()"
                                    (onAddNewSubjectToAppealSubserviceGroup)="addNewSubjectToAppealSubserviceGroup($event)"
                                    *ngIf="appeal && (complexSubservice || subservice) && activeTab == 'subservice'"
                                    #appealSubservices>
                </appeal-subservices-create>

                <appeal-subjects-create [appeal]="appeal"
                                 [subservice]="subservice"
                                 *ngIf="activeTab == 'subjects'"
                                 (onAfterSave)="activateTabAfterCheckSave($event)"
                                 (onApply)="continueProcessingAppealAfterApplyEditedElement()"
                                 #appealSubjects>
                </appeal-subjects-create>

                <!-- Объекты дела -->
                <appeal-objects [appeal]="appeal"
                                [subservice]="subservice"
                                (onAfterSave)="activateTabAfterCheckSave($event)"
                                (onApply)="continueProcessingAppealAfterApplyEditedElement()"
                                *ngIf="activeTab == 'objects'"
                                #appealObjects>
                </appeal-objects>

                <!-- Документы дела -->
                <documents-create [appeal]="appeal"
                           [subservice]="subservice"
                           [printForms]="printForms"
                           (onApply)="continueProcessingAppealAfterApplyEditedElement()"
                           (onUpdateAppealAfterSendStatusEnvelope)="updateAppeal()"
                           *ngIf="activeTab == 'documents'"
                           #appealDocuments>
                </documents-create>

                <!-- Сведения проверки -->
                <appeal-entities [appeal]="appeal"
                                 [subservice]="subservice"
                                 *ngIf="activeTab == 'entities'"
                                 #appealEntities>
                </appeal-entities>

                <!-- Конверты -->
                <appeal-envelopes [appeal]="appeal"
                                  [envelopes]="envelopes"
                                  [printForms]="printForms"
                                  *ngIf="appeal.documents && appeal.documents.length > 0
                                         && appeal.status && appeal.status != 'draft' && activeTab == 'envelopes'"
                                  (onChangeEnvelopes)="changeAppealEnvelopes($event)">
                </appeal-envelopes>

                <!-- Сообщения в деле -->
                <appeal-messages [appeal]="appeal"
                                 [messages]="messages"
                                 (onChangeMessages)="changeAppealMessages($event)"
                                 *ngIf="appeal._id && activeTab == 'messages'">
                </appeal-messages>

                <!-- Требования -->
                <appeal-requirements [appeal]="appeal"
                                     [printForms]="printForms"
                                     *ngIf="activeTab == 'requirements'">
                </appeal-requirements>

                <!-- История дела -->
                <history collection="appeals"
                         [id]="appeal._id"
                         *ngIf="appeal._id && activeTab == 'history'"
                         [items]="historyItems"
                         (onGetHistory)="afterGetHistory($event)">
                </history>


                <!-- Активные задачи процесса -->
                <div *ngIf="activeTab == 'activeTasks'">
                    <appeal-process-tasks  [tasks]="appealProcessService.activeTasks"
                                           [mode]="activeTab"
                                           (afterCompleteProcess)="initTabAfterGetProcessInfo()">
                    </appeal-process-tasks>
                </div>


                <div *ngIf="activeTab == 'completedTasks'">
                    <appeal-process-tasks [tasks]="appealProcessService.completedTasks"
                                          [mode]="activeTab">
                    </appeal-process-tasks>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>

</div>


<!-- модальное окно для заполнения дополнительных настроек для действия при переходе на новый статус -->
<reason-modal #reasonModal
              [items]="reasonItems"
              [fields]="reasonFields"
              [title]="reasonTitle"
              (onComplete)="completeReasons($event)"
              (onCancel)="clearProcessExecuteActionData()">
</reason-modal>

<!-- модальное окно для выбора соответствия сохраняемого объекта дела и объекта в реестре клиентов -->
<reestr-modal #reestrModal>
</reestr-modal>
