import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permission } from '../../../../../../common/services/permission';
import { CommonAppealData } from '../../../../services/common-appeal.data';
import { CommonAppealService } from '../../../../services/common-appeal.service';
import { CommonAppealEntityCustomComponent } from '../entity-custom/common-appeal-entity-custom.component';
import { CommonAppealEntityStaticComponent } from '../entity-static/common-appeal-entity-static.component';
import { AccessService } from '@evolenta/core';
import orderBy from 'lodash-es/orderBy';
import values from 'lodash-es/values';

@Component({
    selector: 'common-appeal-entity-card',
    templateUrl: 'common-appeal-entity-card.component.html',
})
export class CommonAppealEntityCardComponent implements OnInit {
    @Input() public entity; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public appealSubservice; // описания услуг на основе которых формируется дело
    @Input() public task; // задача процесса
    @Input() public allowEdit = true;
    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();
    @Output() public onValidate = new EventEmitter<any>();
    @Output() public onNeedSaveBeforePrint = new EventEmitter<any>();

    public editTabs = [];
    public permissions = Permission; // Набор прав системы

    public isProcessSelectEntityType = false;
    public dataEntityTypes = CommonAppealData.entityTypes;

    public entityTypes = [];

    public availableForAddEntityTypes = [];

    public uniqueEntities = ['order', 'checkStatement', 'raidTask', 'buyOrder', 'observationDocument', 'decisionToConductKNM'];
    @ViewChild('customEntityComponent', { static: false }) public customEntityComponent: CommonAppealEntityCustomComponent;
    @ViewChild('staticEntityComponent', { static: false }) public staticEntityComponent: CommonAppealEntityStaticComponent;

    public constructor(
        public accessService: AccessService,
        private appealService: CommonAppealService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.entityTypes = values(this.dataEntityTypes);
        if (this.task && this.task.params.entities && this.task.params.entities.length > 0) {
            this.task.params.entities.forEach(item => {
                this.availableForAddEntityTypes.push({
                    code: item.code,
                    name: item.name,
                });
            });
            // this.availableForAddEntityTypes = this.entityTypes;
        } else {
            if (!(this.appealService.subservice._id === this.appealSubservice.id && this.appealService.subservice.entities && this.appealService.subservice.entities.length)) {
                return;
            }

            this.appealService.subservice.entities.forEach(entity => {
                const find = CommonAppealData.entityTypes[entity.code];
                if (find) {
                    this.availableForAddEntityTypes.push({
                        code: entity.code,
                        name: entity.name,
                    });
                }
            });
        }

        this.availableForAddEntityTypes = orderBy(this.availableForAddEntityTypes, ['name'], ['asc']);

        if (!this.appeal.entities) {
            this.appeal.entities = [];
        }
        this.uniqueEntities.forEach(uniqueEntity => {
            const findEntityType = this.appeal.subservice.entities.find(item => item.type === uniqueEntity);
            if (findEntityType) {
                this.availableForAddEntityTypes = this.availableForAddEntityTypes.filter(item => item.code !== uniqueEntity);
            }
        });

        if (!this.entity.type && this.availableForAddEntityTypes.length > 0) {
            this.isProcessSelectEntityType = true;
        }

        // this.allowEdit = this.appealStatusService.finishStatuses.indexOf(this.appealSubservice.status.code) === -1;
    }

    public selectEntityType(type) {
        this.entity.type = type.code;
        this.entity.name = type.name;
        this.isProcessSelectEntityType = false;
    }

    public applyEntity() {
        this.onApply.emit(this.entity);
    }

    public editEntity(entity) {
        this.onEdit.emit({entity: entity, appealSubservice: this.appealSubservice});
    }

    public deleteEntity(entity) {
        this.onDelete.emit(entity);
    }

    public cancelEntity() {
        this.onCancel.emit(true);
    }

    public isValid() {
        if (this.customEntityComponent) {
            return this.customEntityComponent.isValid();
        }

        return true;
    }

    public getTransferToProcessParams() {
        if (this.customEntityComponent) {
            return this.customEntityComponent.getTransferToProcessParams();
        }

        return null;
    }

    public processingSaveBeforePrint(printDocument) {
        this.onNeedSaveBeforePrint.emit(printDocument);
    }

    public processingPrintFormAfterSave(printDocument) {
        if (this.customEntityComponent) {
            this.customEntityComponent.processingPrintFormAfterSave(printDocument);
        }
        if (this.staticEntityComponent) {
            this.staticEntityComponent.processingPrintFormAfterSave(printDocument);
        }
    }
}
