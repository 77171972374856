<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ getGroupActionMessage() }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    *ngIf="allowEdit"
                    (click)="save()">
                {{localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{ allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body">

        <!-- Основные данные -->
        <dynamic-form
            #mainData
            *ngIf="metaData.groupsAndCategoriesData.groupData.xsd"
            [xsd]="metaData.groupsAndCategoriesData.groupData.xsd"
            [model]="group"
            [saveInMainObject]="true"
            [disabled]="!allowEdit">
        </dynamic-form>

        <!-- Настройка дополнительных данных -->
        <p *ngIf="metaData.groupsAndCategoriesData.groupData.allowCreateXsd">
            <span class="text-bold">
                {{ getAdditionalFieldsSetUpStatusMessage() }}
            </span>
            <span class="text-size-small anchor text-info ml-10"
                  *ngIf="allowEdit || group.xsd"
                  (click)="editXsd()">
                {{
                    !group.xsd ? localizations.common.setup : allowEdit ?
                        localizations.common.change_small :
                        localizations.common.view_small
                }}
            </span>
            <span class="text-size-small anchor text-slate ml-10"
                  *ngIf="group.xsd && allowEdit"
                  (click)="group.xsd = null">
                {{ localizations.common.delete_small }}
            </span>
        </p>

        <!-- Настройка вариантов -->
        <fieldset class="mb-20"
                  *ngIf="metaData.groupsAndCategoriesData.groupData.isVariantsSetting && commonStandardsService.standard.variants && commonStandardsService.standard.variants.length > 0">
            <legend class="text-bold">
                {{ localizations.common.variants }}
            </legend>
            <common-standard-entity-variants [entity]="group">
            </common-standard-entity-variants>
        </fieldset>
    </div>
</div>
