import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService } from '@evolenta/core';
import { ServicesService } from '../../subservices/services/services.service';
import { EventsService } from '../../events/events/events.service';
import { SUBSERVICES_COLLECTION } from '../../../common/constants';

@Injectable()
export class CreateAppealFromEventResolve implements Resolve<any> {
    public constructor(
        private restService: RestService,
        private servicesService: ServicesService,
        private eventsService: EventsService,
        private storage: StorageService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.loadDefaultData(route);
    }

    public loadDefaultData(route) {
        const defaultStatuses = this.storage.getItem('defaultStatuses');
        if (!defaultStatuses) {
            // Получение базовых данных, нужных для работы с делом
            const promises = [];
            promises.push(
                this.restService.search('settings', {
                    search: { search: [{ field: 'name', operator: 'eq', value: 'defaultStatuses' }] },
                })
            );
            promises.push(this.restService.search('catalogueResultTypes'));
            promises.push(this.restService.search('catalogueIssueTypes'));

            promises.push(this.restService.search('nsiErpKnmForms'));
            promises.push(this.restService.search('nsiErpKnmTypes'));

            return Promise.all(promises).then((data) => {
                this.storage.setItem('defaultStatuses', data[0][0].value);
                this.storage.setItem('resultTypes', data[1]);
                this.storage.setItem('issueTypes', data[2]);

                this.storage.setItem('nsiErpKnmForms', data[3]);
                this.storage.setItem('nsiErpKnmTypes', data[4]);

                return this.loadData();
            });
        } else {
            return this.loadData();
        }
    }

    public loadData() {
        if (
            this.servicesService.isProcessSelectSubserviceForCreateAppealFromEvent &&
            this.servicesService.selectedServices.length > 0
        ) {
            const serviceIds = [];
            this.servicesService.selectedServices.forEach((item) => {
                serviceIds.push(item.id);
            });
            return this.restService.search(SUBSERVICES_COLLECTION, {
                search: { search: [{ field: '_id', operator: 'in', value: serviceIds }] },
            });
        } else if (this.eventsService.isProcessingCreateAppeal && this.eventsService.event) {
            const event = this.eventsService.event;
            const promises = [];
            if (event.subserviceId) {
                promises.push(this.restService.find(SUBSERVICES_COLLECTION, event.subserviceId));
            } else if (event.serviceId) {
                promises.push(
                    this.restService.search(SUBSERVICES_COLLECTION, {
                        search: { search: [{ field: 'serviceId', operator: 'eq', value: event.serviceId }] },
                    })
                );
            } else {
                promises.push(false);
            }
            // const subjectsIds = [];
            // const objectsIds = [];
            // if (event.subjects) {
            //     event.subjects.forEach((subject) => {
            //         subjectsIds.push(subject._id);
            //     });
            // }
            // if (event.objects) {
            //     event.objects.forEach((object) => {
            //         objectsIds.push(object._id);
            //     });
            // }
            // if (subjectsIds.length > 0) {
            //     promises.push(this.restService.search('persons', {search: {search: [{field: '_id', operator: 'in', value: subjectsIds}]}}));
            // } else {
            //     promises.push(false)
            // }
            // if (objectsIds.length > 0) {
            //     promises.push(this.restService.search('objects', {search: {search: [{field: '_id', operator: 'in', value: objectsIds}]}}));
            // } else {
            //     promises.push(false)
            // }
            return Promise.all(promises).then((response) => {
                return {
                    subservice: Array.isArray(response[0]) && response[0][0] ? response[0][0] : response[0],
                    subjects: event.subjects,
                    objects: event.objects,
                };
            });
        } else {
            return Promise.resolve([]);
        }
    }
}
