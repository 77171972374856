<div [ngClass]="{'m-20': !editedEntity}">

    <ng-container *ngIf="!editedEntity">

        <!-- Исполнитель -->
        <ng-container *ngIf="appeal.executor">
            <p>
                <span class="text-muted mr-5">Исполнитель: </span>
                <strong>{{appeal.executor.name}}</strong>
                <span class="text-slate ml-5" *ngIf="appeal.executor.position">({{appeal.executor.position}})</span>
            </p>
        </ng-container>

        <!-- Информация о заявлении с портала -->
        <ng-container *ngIf="appeal.epguData">
            <p>
                <span class="text-muted mr-5">Номер заявления на портале:</span> {{appeal.epguData.orderId}}
                <span class="text-muted ml-10 mr-5">Дата поступления:</span> {{appeal.dateCreation | date: 'mediumDate'}}
                <ng-container *ngIf="appeal.epguData.applicant && appeal.epguData.applicant.header">
                    <span class="text-muted ml-10 mr-5">Заявитель: </span> {{appeal.epguData.applicant.header}}
                </ng-container>
            </p>
        </ng-container>

        <!-- Выбор варианта услуги -->
        <common-appeal-subservice-variant [appealSubservice]="appealSubservice"
                                          [subservice]="subservice" [noMargin]="true"></common-appeal-subservice-variant>
    </ng-container>


    <ng-container *ngIf="!subservice.variants || subservice.variants.length === 0 || appealSubservice.variant">

        <!-- Данные о лицензиях -->
        <common-appeal-licenses [appeal]="appeal"
                                [subservice]="subservice"
                                [savedPlace]="savedPlace"
                                *ngIf="subservice.registersModel && !editedEntity" #licenses></common-appeal-licenses>

        <!-- Основной блок доп. данных -->
        <div *ngIf="!editedEntity && savedPlace && (savedPlace.xsd || savedPlace.mainXsd) && showAdditionalData" [ngClass]="{'mb-15': !isAllowEdit}">
            <dynamic-form
                #mainAdditionalData
                [model]="savedPlace.mainXsd ? savedPlace.mainXsdData : savedPlace.xsdData "
                [saveInMainObject]="true"
                [xsd]="savedPlace.xsd ? savedPlace.xsd : savedPlace.mainXsd"
                [textView]="!isAllowEdit"
                [externalData]="externalData"
                [initHandlers]="this.subservice['xsdHandlers']"
                [useXsdService]="appealService.useXsdService"
                (afterChangeForm)="changeAdditionalForm('mainAdditionalData', this.subservice['xsdHandlers'], savedPlace.mainXsd ? savedPlace.mainXsdData : savedPlace.xsdData)">
            </dynamic-form>
        </div>

        <div *ngIf="!editedEntity && subservice.appealXsd">
            <dynamic-form
                #appealAdditionalData
                [model]="appeal"
                [saveInMainObject]="true"
                [xsd]="subservice.appealXsd"
                [textView]="!isAllowAppealDataEdit"
                [externalData]="externalData"
                [initHandlers]="this.subservice['appealXsdHandlers']"
                [useXsdService]="appealService.useXsdService"
                (afterChangeForm)="changeAdditionalForm('appealAdditionalData', this.subservice['appealXsdHandlers'], appeal)">
            </dynamic-form>
        </div>

        <ng-container *ngIf="savedPlace && savedPlace.operationType && savedPlace.operationType !== 'receipt' && showAdditionalData">
            <common-appeal-subjects [appeal]="appeal"
                                    [subservice]="subservice"
                                    [externalParam]="appealSubservice.externalSubjectParam"
                                    viewMode="oneWindow" #appealSubjects
                                    *ngIf="isShowEntities('subjects') && (!editedEntity || editedEntityType === 'subject')"
                                    (onEdit)="editEntity($event, 'subject')"
                                    (onApply)="applyEntity()"
                                    (onApplyError)="applyError()"
            ></common-appeal-subjects>

            <!-- Объекты -->
            <div [ngClass]="{'mt-20': !editedEntity}" *ngIf="isShowEntities('objects') && (!editedEntity || editedEntityType === 'object')">
                <common-appeal-objects [appeal]="appeal"
                                       [subservice]="subservice"
                                       [externalParam]="appealSubservice.externalObjectParam"
                                       viewMode="oneWindow" #appealObjects
                                       (onEdit)="editEntity($event, 'object')"
                                       (onApply)="applyEntity()">
                </common-appeal-objects>
            </div>

        </ng-container>
    </ng-container>

</div>
