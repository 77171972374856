import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { EntityKndKindsComponent } from './entity-knd-kinds/entity-knd-kinds.component';
import { PersonCheckingPeopleComponent } from './person-checking-people/person-checking-people.component';
import { EntityKndKindDataComponent } from './entity-knd-kind-data/entity-knd-kind-data.component';
import { EntityKndKindsDataComponent } from './entity-knd-kinds-data/entity-knd-kinds-data.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    imports: [SharedComponentsModule, InlineSVGModule],
    declarations: [
        EntityKndKindDataComponent,
        EntityKndKindsDataComponent,
        EntityKndKindsComponent,
        PersonCheckingPeopleComponent,
    ],
    providers: [],
    exports: [
        EntityKndKindsComponent,
        EntityKndKindDataComponent,
        EntityKndKindsDataComponent,
        PersonCheckingPeopleComponent,
    ],
})
export class EntitiesDataModule {}
