<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left"
            [ngClass] ="{
                'bg-danger-50': service.invalid,
                'bg-primary-50': !service.invalid
            }">
        <h6 class="panel-title">
            {{ localizations.services.info }}
        </h6>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">
                <fieldset>
                    <legend class="text-semibold cursor-pointer"
                            (click)="panels.common = !panels.common">
                <span>
                    <i class="icon-file-text2 text-slate"></i>
                    <span>
                        {{ localizations.common.general_data }}
                    </span>
                </span>
                        <a class="control-arrow">
                            <i class="main"
                               [ngClass]="{
                                    'icon-circle-down2' : panels.common,
                                    'icon-circle-left2' : !panels.common
                                }"></i>
                        </a>
                    </legend>
                    <div class="collapse pl-20"
                         [ngClass]="{'in': panels.common}">
                        <div>
                            <span class="text-muted text-uppercase text-size-mini mr-5">
                                {{ localizations.services.complex }}
                            </span>
                            <span *ngIf="service.title">
                                {{ service.title }}
                            </span>
                        </div>
                        <div>
                            <span class="text-muted text-uppercase text-size-mini mr-5">
                                {{ localizations['anketa-life-event'].life_situation }}
                            </span>
                            <span *ngIf="service.lifeEvent">
                                {{ service.lifeEvent.title }}
                            </span>
                        </div>
                    </div>
                </fieldset>

                 <fieldset *ngIf="service.standards">
                    <legend class="text-semibold cursor-pointer"
                            (click)="panels.services = !panels.services">
                    <span>
                        <i class="icon-stack text-slate"></i>
                        <span class="ag-large-text">
                            {{ localizations.services.contained }}
                        </span>
                    </span>
                        <a class="control-arrow">
                            <i class="doc"
                               [ngClass]="{
                                    'icon-circle-down2' : panels.services,
                                    'icon-circle-left2' : !panels.services
                               }"></i>
                        </a>
                    </legend>
                    <div class="collapse pl-20"
                         [ngClass]="{'in': panels.services}">
                        <div class="list-item-data"
                             *ngIf ="!service.invalid">
                            <ul class="list-unstyled">
                                <li class="mb-5"
                                    *ngFor="let standard of service.standards; let idx = index">
                                    <span *ngIf="standard.reglaments">
                                        <span class="badge badge-primary text-uppercase mr-5"
                                              *ngIf="standard.displayOrder">
                                            {{ +standard.displayOrder+1 }}
                                        </span>
                                        <span class="text-muted mr-5">#</span>
                                        <span class="text-semibold">
                                            {{ standard.reglaments.code }}
                                        </span>
                                        <span class="ml-5"
                                              *ngIf ="!service.invalid">
                                            {{ standard.reglaments.title }}
                                        </span>
                                         </span>
                                        <div class="ml-30"
                                             *ngIf="standard.startAfter && standard.startAfter.length!=0">
                                            <span class="text-primary-300">
                                                {{ localizations.services.depends_on }}:
                                            </span>
                                            <span *ngFor="let dependence of standard.reglaments.startAfter; let idx = index; let lst = last">
                                                <span class="text-muted mr-5">#</span>
                                                <span class="text-semibold">
                                                    {{ dependence }}
                                                </span>
                                                <span *ngIf="!lst">, </span>
                                            </span>
                                        </div>
                                    <div class="ml-30 text-muted"
                                         *ngIf="!standard.startAfter || standard.startAfter.length==0">
                                        <span>
                                            {{ localizations.services.does_not_depends_on_other }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <ng-container *ngIf ="service.invalid">
                            <div class="alert alert-danger alert-styled-left">
                            <span class="text-semibold">
                                {{ localizations.common.attention }}
                            </span>
                            <span>
                                {{ localizations.services.reglaments_not_loaded }}:
                            </span>
                            <ul class="list-unstyled ml-15"
                                *ngFor="let standard of service.invalidStandards, let lst = last">
                                <li>
                                    <span class="text-muted mr-5">#</span>
                                    <span class="mr-5 text-semibold">
                                        {{ standard.code }}
                                    </span>
                                    <span>
                                        {{ standard.title }}
                                    </span>
                                    <span *ngIf="!lst">,</span>
                                </li>
                            </ul>
                            </div>
                        </ng-container>
                        <div *ngIf="service.standard && service.standard.length == 0">
                            {{ localizations.common.missing_plural }}
                        </div>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
