<div class="panel panel-shadow print"
     *ngIf="registry && license">
    <div class="panel-body">
        <div class="pull-right">
            <span class="badge bg-success text-uppercase"></span>
        </div>
        <h4 class="no-margin-top text-center">
            {{ license.unit.name }}
        </h4>
        <hr>
        <h1 class="no-margin">
            {{ localizations.registers.entry }}
            <ng-container *ngIf="registry.auid">
                № {{ getNumber() }}
            </ng-container>
        </h1>
        <div class="text-center mb-30">
            <span class="label bg-success-300 text-uppercase status"
                  [ngClass]="{ 'bg-success-300': registry.status === 'active' && license.status === 'active',
                              'bg-yellow-600': registry.status === 'reissued' && license.status === 'active',
                              'bg-danger-300': license.status !== 'active' }">
                {{ registry.status === 'active' ?
                    localizations.registers.actual :
                    localizations.registers.expired }}
                {{ localizations.registers.for }}
                <span class="ml-5">
                    {{ currentDate | date: 'shortTime' }}
                </span> <span class="ml-5">
                {{ currentDate | date: 'shortDate'}}
                {{ localizations.common.year_small }}
            </span>
            </span>
            <ng-container *ngIf="activeRegistryId">
                <br>
                    <span class="anchor text-info ml-5 text-size-small"
                          (click)="goToActiveRegistry()">
                        {{ localizations.registers.current_version }}
                    </span>
            </ng-container>
        </div>
        <div class="text-center mb-10">
            {{ localizations.registers.license_status }}:
            <label
                class="badge bg-success-300 text-uppercase ml-5 no-margin-bottom"
                [ngClass]="{ 'bg-success-300': license.status === 'active',
                            'bg-slate-300': license.status === 'annulled',
                            'bg-orange-300': license.status === 'paused' }">
                {{ statuses[license.status] }}
            </label>
        </div>
        <ol>
            <li>
                {{ localizations.registers.registration_license_number }}:
                <span class="value">
                    {{ license.number }}
                </span>
            </li>
            <li>
                {{ localizations.registers.license_grant_date }}:
                <span class="value">
                    {{ license.dateIssued | date: 'shortDate' }} {{ localizations.common.year_small }}
                </span>
            </li>

            <!-- Юридическое лицо -->
            <li *ngIf="checkSpecialTypeId('ulApplicant')">
                {{ localizations.registers.full_and_small_name_description }}
                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.name',
                                                          styleClass: 'display-block',
                                                          additionalDataView: 'brackets',
                                                          additionalKey: 'data.organization.tradeName'}">
                </ng-container>

                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.registrationAddress.fullAddress',
                                                          styleClass: 'display-block',
                                                          checkKey: 'data.organization.registrationAddress'}">
                </ng-container>

                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.ogrn',
                                                          styleClass: 'display-block',
                                                          label: localizations.common.ogrn}">

                </ng-container>
            </li>

            <!-- Иностранное юридическое лицо -->
            <li *ngIf="checkSpecialTypeId('foreignUlApplicant')">
                {{ localizations.registers.full_and_small_name_description2 }}
                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.name',
                                                          styleClass: 'display-block',
                                                          additionalDataView: 'brackets',
                                                          additionalKey: 'data.organization.tradeName'}"></ng-container>

                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.registrationAddress.fullAddress',
                                                          styleClass: 'display-block',
                                                          checkKey: 'data.organization.registrationAddress'}"></ng-container>

                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.accreditationNumber',
                                                          label: 'Номер записи об аккредитации',
                                                          checkKey: 'data.organization.accreditationNumber',
                                                          additionalLabel: 'от',
                                                          additionalDataView: 'label-date',
                                                          additionalKey: 'data.organization.accreditationData'}"></ng-container>
            </li>

            <!-- Индивидуальный предприниматель -->
            <li *ngIf="checkSpecialTypeId('ipApplicant')">
                {{ localizations.registers.full_and_small_name_description3 }}
                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.person.fullName',
                                                          styleClass: 'display-block'}"></ng-container>

                <ng-container [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.person.ogrn',
                                                          styleClass: 'display-block',
                                                          label: localizations.common.ogrn }"></ng-container>
            </li>

            <li>
                {{ localizations.common.inn }}:
                <ng-container *ngIf="checkSpecialTypeId('ulApplicant')"
                              [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.organization.inn'}"></ng-container>

                <ng-container *ngIf="!checkSpecialTypeId('ulApplicant')"
                              [ngTemplateOutlet]="subjectValueTemplate"
                              [ngTemplateOutletContext]="{key: 'data.person.inn'}"></ng-container>

            </li>

            <li>
                {{ localizations.registers.address_description }}
                <ul class="value">
                    <li *ngFor="let object of (changedRegistryData.objects ? changedRegistryData.objects : objects)">
                         <span [class]="object.styleClass || ''">
                             {{ object.address.fullAddress }}
                         </span>
                    </li>
                </ul>
            </li>

            <li>
                {{ localizations.registers.licensing_activity_with_work_types }}
                <ul class="value">
                    <li *ngFor="let element of (changedRegistryData.activityTypes ? changedRegistryData.activityTypes : activityTypes)">
                         <span [class]="element.activityType.styleClass || ''">
                             {{ element.activityType.name }}
                         </span>
                    </li>
                </ul>
            </li>

            <li>
                {{ localizations.registers.order_name_and_date }}
                <span class="value"
                      *ngIf="license.orderData">
                    №{{ license.orderData.number }}
                    {{ localizations.common.from }}
                    {{license.orderData.date | date: 'shortDate'}}
                </span>
            </li>
        </ol>
    </div>
</div>

<ng-template #subjectValueTemplate
             let-key="key"
             let-label="label"
             let-checkKey="checkKey"
             let-styleClass="styleClass"
             let-additionalKey="additionalKey"
             let-additionalLabel="additionalLabel"
             let-additionalDataView="additionalDataView">
    <span class="{{styleClass || ''}} value"
          *ngIf="checkKey ? (getValueByKey(checkKey, subject) || getValueByKey(checkKey, changedRegistryData.subject)) : true">
        <span *ngIf="label" class="mr-5">{{label}}:</span>
        <ng-container *ngIf="!hasSubjectChangesByKey(key); else elseBlock">
            <ng-container [ngTemplateOutlet]="subjectDataTemplate"
                          [ngTemplateOutletContext]="{data: subject,
                                                      key: key,
                                                      additionalKey: additionalKey,
                                                      additionalLabel: additionalLabel,
                                                      additionalDataView: additionalDataView}"></ng-container>
        </ng-container>
        <ng-template #elseBlock>
            <span class="deleted-value mr-10" *ngIf="getValueByKey(key, subject)">
                <ng-container [ngTemplateOutlet]="subjectDataTemplate"
                              [ngTemplateOutletContext]="{data: subject,
                                                          key: key,
                                                          additionalKey: additionalKey,
                                                          additionalLabel: additionalLabel,
                                                          additionalDataView: additionalDataView}"></ng-container>
            </span>
            <span class="new-value" *ngIf="getValueByKey(key, changedRegistryData.subject)">
                <ng-container [ngTemplateOutlet]="subjectDataTemplate"
                              [ngTemplateOutletContext]="{data: changedRegistryData.subject,
                                                          key: key,
                                                          additionalKey: additionalKey,
                                                          additionalLabel: additionalLabel,
                                                          additionalDataView: additionalDataView}"></ng-container>
            </span>
        </ng-template>
    </span>
</ng-template>

<ng-template let-key="key"
             let-data="data"
             let-additionalKey="additionalKey"
             let-additionalLabel="additionalLabel"
             let-additionalDataView="additionalDataView"
             #subjectDataTemplate>
    <span>
        {{ getValueByKey(key, data) }}
    </span>
    <ng-container *ngIf="additionalKey && getValueByKey(additionalKey, data)">
        <ng-container *ngIf="additionalDataView === 'brackets'">
            ({{ getValueByKey(additionalKey, data) }})
        </ng-container>
        <ng-container *ngIf="additionalDataView === 'label-date'">
            {{ additionalLabel }} {{ getValueByKey(additionalKey, data) | date: 'shortDate' }}
        </ng-container>
    </ng-container>
</ng-template>
