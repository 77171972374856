import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-catalogue',
    templateUrl: 'appeal-entity-catalogue.component.html',
    styles: ['.input-group { width: 100%; }'],
})
export class AppealEntityCatalogueComponent implements OnInit {
    @Input() public entity: any;
    @Input() public property: string = '';
    @Input() public placeholder: string = '';
    @Input() public catalogName: string = '';
    @Input() public title: string = '';
    @Input() public large: boolean = false;
    @Input() public small: boolean = false;
    @Input() public disabled = false;

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    public ngOnInit() {
        if (this.property && !this.entity[this.property]) {
            this.entity[this.property] = [this.newItem()];
        }
    }

    /**
     * Добавление нового элемента массива
     * @returns void
     */
    public addItem(): void {
        this.entity[this.property].push(this.newItem());
    }

    /**
     * Возвращает новый объект
     * @returns {object}
     */
    public newItem(): object {
        return {
            guid: CommonUtilities.GenerateGuid(),
            value: null,
        };
    }

    /**
     * Удаление ранее добавленного элемента
     * @param item
     * @returns void
     */
    public deleteItem(item): void {
        const findIndex = this.entity[this.property].findIndex((f) => f.guid === item.guid);
        this.entity[this.property].splice(findIndex, 1);
    }
}
