<!-- Настройка бизнес-процесса -->
<ng-container *ngIf="!isCreateOdEdit">
    <ng-container *ngIf="!organization[organizationField][processCode] || !organization[organizationField][processCode].process">
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-10"
                (click)="selectProcess()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            <span>
                {{ localizations.organizations.process.select }}
            </span>
        </button>
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-10 mt-10"
                (click)="addNewProcess()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            <span>
                {{ localizations.organizations.process.add }}
            </span>
        </button>
    </ng-container>
    <ng-container *ngIf="process">
        <bpmn-card [bpmn]="process"
                   [entityId]="organization._id"
                   *ngIf="process"
                   (onEdit)="editProcess($event)"
                   (onDelete)="deleteProcess()"
                   (onDeleteSubProcess)="deleteSubProcess($event)"
                   (onCreateSubProcess)="createSubProcess($event)"
                   (onSearchSubProcess)="selectProcess($event)"></bpmn-card>

        <button class="btn btn-primary-overline btn-labeled ml-10 mt-10"
                [disabled]="isProcessUploadProcess"
                (click)="uploadProcess()">
            <span>
                {{ localizations.organizations.process.upload }}
            </span>
        </button>
    </ng-container>
</ng-container>

<bpmn-process-edit
    [mode]="'entity'"
    [entityId]="organization._id"
    [entityProcess]="editedProcess"
    (onSaveFromEntity)="saveProcessFromEntity($event)"
    (onCancelFromEntity)="cancelEditProcess()"
    *ngIf="isCreateOdEdit"></bpmn-process-edit>
