<div class="panel panel-preview"
     *ngIf="element"
     [ngClass]="{'panel-shadow m-20': mode == 'card'}">
    <div class="panel-heading no-border-radius no-border-left"
         *ngIf="mode == 'full' || mode == 'card'">
        <h6 class="panel-title">
            {{ localizations.processes.license }}
            № {{ element.number }}
            {{ localizations.common.from }}
            {{ element.dateIssued | date:'shortDate' }}
            {{ localizations.common.year_small}}
            <label
                class="badge text-uppercase ml-10 no-margin-bottom"
                [ngClass]="RegistersService.GetPropertyByStatus(element, 'background')">
                {{ RegistersService.GetPropertyByStatus(element, 'name') }}
            </label>
        </h6>
        <div class="heading-elements">
            <a [title]="localizations.common.edit"
               (click)="goToLicense()">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </a>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body mb-20">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="cursor-pointer mb-10 pb-20 text-bold"
                            (click)="tabs.common = !tabs.common">
                        <span>
                            {{ localizations.common.general_data }}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12' : tabs.common,
                                'icon-arrow-left12' : !tabs.common}">
                            </i>
                        </a>
                    </legend>
                        <div class="collapse pt-20 pb-10"
                             [ngClass]="{'in': tabs.common}">
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.registers.licensing_activity }}
                            </div>
                            <div>
                                {{ element.registryEntryType?.name }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ element.units && element.units.length > 1 ?
                                    localizations.registers.licensing_authorities:
                                    localizations.registers.licensing_authority }}:
                            </div>
                            <div>
                                <ng-container *ngIf="element.unit && !element.units">
                                    {{ element.unit?.name }}
                                </ng-container>
                                <ng-container *ngIf="element.units">
                                    <ng-container *ngFor="let unit of element.units; let lst = last">
                                        {{ unit?.name }}
                                        <ng-container *ngIf="!lst">,</ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="element.dateIssued">
                            <div class="text-muted">
                                {{ localizations.appeals.issue_date }}:
                            </div>
                            <div>
                                {{ element.dateIssued | date:'shortDate' }}
                                {{ localizations.common.year_small }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
*ngIf="element.subject">
                            <div class="text-muted">
                                {{ localizations.registers.licensee }}
                            </div>
                            <div>
                                {{ element.subject.header }}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <!-- Объекты -->
                <fieldset *ngIf="activeRegistry && activeRegistry.objects">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="tabs.objects = !tabs.objects">
                        <span>
                            {{ localizations.registers.licensing_addresses }}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12' : tabs.objects,
                                'icon-arrow-left12' : !tabs.objects
                            }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': tabs.objects}">
                        <ul class="list list-square pl-20">
                            <li *ngFor="let object of activeRegistry.objects; let i = index; let lst = last">
                                <div class="text-bold">
                                    {{ object.name }}
                                </div>
                                <div *ngIf="object.address && object.address.fullAddress">
                                    <span class="text-muted mr-5">
                                        {{ localizations.events.object_address }}:
                                    </span>
                                    {{ object.address.fullAddress }}
                                </div>
                                <ul *ngIf="object.xsdData && object.xsdData.activityTypes">
                                    <li *ngFor="let activity of object.xsdData.activityTypes; let lst = last">
                                        <ng-container *ngIf="activity.activityType">
                                            {{ activity.activityType.name }}
                                        </ng-container>
                                        <ng-container *ngIf="activity.name">
                                            {{ activity.name }}
                                        </ng-container>
                                        <span class="text-slate text-size-small ml-5"
                                              *ngIf="activity.isPaused">
                                            ({{ localizations.registers.statuses.activity_suspended }})
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
