import { NgModule } from '@angular/core';
import { CoreModule } from '@evolenta/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { EntityProcessElementComponent } from './entity-process/element/entity-process-element.component';
import { EntityProcessComponent } from './entity-process/entity-process.component';
import { EntityProcessElementHeaderComponent } from './entity-process/element/header/entity-process-element-header.component';
import { EntityProcessElementDataComponent } from './entity-process/element/data/entity-process-element-data.component';
import { EntityProcessEventsComponent } from './entity-process/events/entity-process-events.component';
import { EntityProcessContainerComponent } from './entity-process/container/entity-process-container.component';
import { EntityProcessElementFooterComponent } from './entity-process/element/footer/entity-process-element-footer.component';
import { EntityProcessElementsComponent } from './entity-process/elements/entity-process-elements.component';
import { EntityProcessElementAdditionaDataTabComponent } from './entity-process/element/data/entity-process-element-additiona-data-tab.component';
import { CommonProcessService } from './common-process.service';
import { CommonAppealDocumentsModule } from '../common-appeals/common-appeal-documents.module';
import { CommonAppealEntitiesModule } from '../common-appeals/common-appeal-entities.module';
import { CommonBpmnLinksComponent } from './bpmn-links/common-bpmn-links.component';
import { CommonBpmnCardComponent } from './bpmn-card/common-bpmn-card.component';
import { CommonBpmnCardTasksComponent } from './bpmn-card/bpmn-card-tasks/common-bpmn-card-tasks.component';
import { ProcessRegistryService } from '../processes/process-registry/process-registry.service';
import { CommonAppealCheckListsModule } from '../common-appeals/common-appeal-check-lists.module';

@NgModule({
    imports: [
        CoreModule,
        RouterModule,
        SharedComponentsModule,
        InlineSVGModule,
        CommonAppealDocumentsModule,
        CommonAppealEntitiesModule,
        CommonAppealCheckListsModule,
    ],
    declarations: [
        EntityProcessComponent,
        EntityProcessEventsComponent,
        EntityProcessElementComponent,
        EntityProcessElementDataComponent,
        EntityProcessContainerComponent,
        EntityProcessElementHeaderComponent,
        EntityProcessElementFooterComponent,
        EntityProcessElementsComponent,
        EntityProcessElementAdditionaDataTabComponent,
        CommonBpmnLinksComponent,
        CommonBpmnCardComponent,
        CommonBpmnCardTasksComponent,
    ],
    providers: [CommonProcessService, ProcessRegistryService],
    exports: [
        EntityProcessComponent,
        EntityProcessContainerComponent,
        EntityProcessEventsComponent,
        EntityProcessElementsComponent,
        CommonBpmnLinksComponent,
        CommonBpmnCardComponent,
        CommonBpmnCardTasksComponent,
    ],
})
export class CommonProcessesModule {}
