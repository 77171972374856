<fieldset>
    <legend class="text-bold"
            [ngClass]="{
                'no-margin': allowEdit,
                'mb-10': !allowEdit
                }">
        {{ localizations['transfer-to-unit'].name }}
    </legend>
    <ng-container *ngIf="allowEdit">
        <div class="alert alert-info no-margin no-border pt-10 pb-10"
             *ngIf="!isCompleteReceiveData || usedUnits.length > 0">
            {{ localizations['transfer-to-unit'].select_from_list }}
            <span class="ml-10 text-bold"
                  *ngIf="selectedUnit">
                {{ localizations['transfer-to-unit'].selected }}:
                {{selectedUnit.name}}
            </span>
        </div>
        <div class="alert alert-warning"
             *ngIf="isCompleteReceiveData && usedUnits.length === 0">
            {{ localizations['transfer-to-unit'].missing }}:
        </div>
        <div class="form-group form-group-lg no-margin mt-15"
             *ngIf="units.length > max">
            <input class="form-control"
                   type="text"
                   [(ngModel)]="searchText"
                   [placeholder]="localizations['transfer-to-unit'].contains"
                   (ngModelChange)="prepareUnits()">
        </div>

        <ng-container *ngFor="let unit of usedUnits; let idx = index; let fst = first">
            <div class="radio-block">
                <input id="unit{{unit.id}}"
                       class="radio"
                       type="radio"
                       name="unit"
                       (change)="selectedUnit = unit">
                <label for="unit{{unit.id}}">
                    {{unit.name}}
                </label>
            </div>
        </ng-container>
        <div class="mt-20"
             *ngIf="showLink" >
            <span class="anchor text-info cursor-pointer pt-20"
                  (click)="showAll = !showAll; prepareUnits()">
                {{showAll ? localizations.common.hide : localizations.common.show_all }}
            </span>
        </div>
    </ng-container>

    <ng-container *ngIf="!allowEdit">
        <p>
            {{ getTransferToUnitDoneMessage() }}
        </p>
    </ng-container>
</fieldset>
