<div class="flex">
    <div style="flex-wrap: wrap; margin-right: 70px;" *ngFor="let group of fields">
        <ng-container *ngFor="let fieldsGroup of group.children; let fst = first; let idx = index">
            <fieldset [ngClass]="{'mt-10': !fst}">
                <legend class="text-bold no-margin-bottom">{{fieldsGroup.groupName}}</legend>
                <div class="checkbox-block" *ngFor="let field of fieldsGroup.items">
                    <input type="checkbox" class="checkbox" id="field{{field.code}}{{idx}}" [checked]="field.checked" (change)="toggleField(field)">
                    <label for="field{{field.code}}{{idx}}">{{field.name}}</label>
                </div>
            </fieldset>
        </ng-container>
    </div>
</div>
