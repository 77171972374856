import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { EventsComponent } from './events/events.component';
import { EventEditComponent } from './events/event-edit/event-edit.component';
import { EventsSectionComponent } from './events/events-section.component';
import { EventsService } from './events/events.service';
import { EventDocumentEditComponent } from './events/event-edit/document-edit/event-document-edit.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { ObjectsModule } from '../objects/objects.module';
import { CreateAppealFromEventComponent } from './create-appeal-from-event/create-appeal-from-event.component';
import { CreateAppealFromEventResolve } from './create-appeal-from-event/create-appeal-from-event.resolve';
import { EventTypesComponent } from './events-types/event-types.component';
import { EventTypeComponent } from './events-types/event-type.component';
import { EventTypesResolve } from './events-types/event-types.resolve';
import { EventTypesSectionComponent } from './events-types/event-types-section.component';
import { EventTasksComponent } from './events/event-edit/event-tasks/event-tasks.component';
import { TasksModule } from '../tasks/tasks.module';
import { EventCitizenRequestComponent } from './events/event-edit/event-citizen-request/event-citizen-request.component';
import { EventSignsViolationsComponent } from './events/event-edit/event-signs-violations/event-signs-violations.component';
import { EventDocumentsComponent } from './events/event-edit/event-documents/event-documents.component';
import { EventGsnNoticeComponent } from './events/event-edit/event-gsn-notice/event-gsn-notice.component';
import { EventGsnStartBuildingNoticeComponent } from './events/event-edit/event-gsn-notice/event-gsn-start-building-notice/event-gsn-start-building-notice.component';
import { EventLinkedEntitiesComponent } from './events/event-edit/event-linked-entities/event-linked-entities.component';
import { KnmModule } from '../knm/knm.module';
import { EventSubjectsComponent } from './events/event-edit/event-subjects/event-subjects.component';
import { SubjectsModule } from '../subjects/subjects.module';
import { EventObjectsComponent } from './events/event-edit/event-objects/event-objects.component';
import { EventTypePreviewComponent } from './events-types/components/event-type-preview/event-type-preview.component';
import { EventTypeParamsComponent } from './events-types/components/event-type-params/event-type-params.component';
import { EventTypePrintFormsComponent } from './events-types/components/event-type-print-forms/event-type-print-forms.component';
import { PrintFormsModule } from '../print-forms/print-forms.module';
import { EventPreviewModule } from './event-preview.module';

@NgModule({
    imports: [
        SharedComponentsModule,
        ObjectsModule,
        InlineSVGModule,
        TasksModule,
        KnmModule,
        SubjectsModule,
        ObjectsModule,
        PrintFormsModule,
        EventPreviewModule,
    ],
    declarations: [
        EventsComponent,
        EventEditComponent,
        EventsSectionComponent,
        EventDocumentEditComponent,
        CreateAppealFromEventComponent,
        EventTypesComponent,
        EventTypeComponent,
        EventTypesSectionComponent,
        EventTasksComponent,
        EventCitizenRequestComponent,
        EventSignsViolationsComponent,
        EventDocumentsComponent,
        EventGsnNoticeComponent,
        EventGsnStartBuildingNoticeComponent,
        EventTypeParamsComponent,
        EventLinkedEntitiesComponent,
        EventSubjectsComponent,
        EventObjectsComponent,
        EventTypePreviewComponent,
        EventTypePrintFormsComponent,
    ],
    providers: [EventsService, EventTypesResolve, CreateAppealFromEventResolve],
    exports: [],
})
export class EventsModule {}
