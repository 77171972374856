<div class="alert alert-warning no-border"
     *ngIf="!meta || !meta.items.length">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations.organizations.meta_info_missing }}
</div>

<table class="table table-hover table-xxs"
       *ngIf="meta && meta.items.length">
    <thead>
    <tr>
        <th width="170">
            {{ localizations.common.code }}
        </th>
        <th>
            {{ localizations.organizations.value }}
        </th>
        <th width="60"></th>
    </tr>
    </thead>
    <ng-container *ngIf="meta">
        <tbody>
            <tr *ngFor="let metaItem of meta.items ; let idx = index">
                <td>
                    <input class="form-control input-xs"
                           type="text"
                           [(ngModel)]="metaItem.code">
                </td>
                <td>
                    <textarea class="form-control input-xs"
                              rows="1"
                              [(ngModel)]="metaItem.value">
                    </textarea>
                </td>
                <td class="text-right">
                    <button class="btn btn-default btn-sxs"
                            type="button"
                            [title]="localizations.common.delete"
                            (click)="deleteMetaItem(idx)">
                        <i class="icon-trash"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </ng-container>
</table>

<button class="btn btn-primary-overline btn-labeled btn-labeled-right m-20"
        (click)="addMetaItem()">
    <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
    {{ localizations.organizations.add_parameter }}
</button>


