<div class="sidebar sidebar-secondary sidebar-default height-100">
    <evolenta-scrollbar>
        <div class="sidebar-content">
            <div class="sidebar-category" *ngFor="let tab of tabs" (click)="selectTab(tab.code)"
                 [ngClass]="{'active': activeTabCode && activeTabCode === tab.code, 'cursor-pointer' : activeTabCode !== tab.code}">
                <div class="category-title">
                    <span>{{ tab.title }}</span>
                </div>
            </div>
        </div>

    </evolenta-scrollbar>
</div>

