import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService } from '@evolenta/core';

@Injectable()
export class OrganizationResolve implements Resolve<any> {
    public promises;
    public otherSearchParams = [{ field: 'isAuthorized', operator: 'eq', value: true }];

    public constructor(
        private restService: RestService,
        private storage: StorageService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.promises = [];
        let organizationId = route.params['organizationId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create');

        // Если компонент вызывается из раздела "Профиль", то в качестве редактируемой организации выступает текущая
        const isProfileMode =
            route.parent.parent.url.filter((item) => item.path === 'admin' || item.path === 'selecting').length === 0;
        if (isProfileMode) {
            organizationId = this.storage.getItem('currentOrganization')._id;
        }

        if (organizationId) {
            return this.edit(organizationId);
        } else if (isCreateAction.length > 0) {
            return this.create();
        } else {
            return this.list();
        }
    }

    /**
     * Редактирование организации
     * @param organizationId
     */
    public edit(organizationId) {
        /** Текущая организация */
        this.promises.push(this.restService.find('organizations', organizationId));
        /** Организации, за исключением текущей */
        // this.otherSearchParams.push({field: '_id', operator: 'neq', value: organizationId});
        // this.promises.push(this.getOrganizations(this.otherSearchParams));
        this.promises.push(
            this.restService.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'organizationProcesses' }] },
            })
        );
        this.promises.push(
            this.restService.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'organizationPrintForms' }] },
            })
        );
        this.promises.push(
            this.restService.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'stend.settings' }] },
            })
        );
        this.promises.push(
            this.restService.search('unitsProductionCalendars', {
                search: { search: [{ field: 'unitId', operator: 'eq', value: organizationId }] },
            })
        );
        return Promise.all(this.promises).then((data: any) => {
            return {
                organization: data[0],
                processes: data[1] && data[1].length > 0 ? data[1][0].value : null,
                printForms: data[2] && data[2].length > 0 ? data[2][0].value : null,
                stendSettings:
                    data[3] && data[3].length > 0 && data[3][0].value && data[3][0].value.unit
                        ? data[3][0].value.unit
                        : null,
                calendar: data[4] ? data[4] : [],
            };
        });
    }

    /**
     * Создание организации
     */
    public create() {
        this.promises.push({});
        this.promises.push(
            this.restService.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'organizationProcesses' }] },
            })
        );
        this.promises.push(
            this.restService.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'organizationPrintForms' }] },
            })
        );
        this.promises.push(
            this.restService.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'stend.settings' }] },
            })
        );
        return Promise.all(this.promises).then((data: any) => {
            return {
                organization: data[0],
                processes: data[1] && data[1].length > 0 ? data[1][0].value : null,
                printForms: data[2] && data[2].length > 0 ? data[2][0].value : null,
                stendSettings:
                    data[3] && data[3].length > 0 && data[3][0].value && data[3][0].value.unit
                        ? data[3][0].value.unit
                        : null,
            };
        });
    }

    /**
     * Список организаций
     */
    public list() {
        this.promises.push(this.getOrganizations());
        return Promise.all(this.promises);
    }

    /**
     * Получение списка организаций
     * @param searchParams
     */
    public getOrganizations(searchParams = []) {
        if (searchParams.length > 0) {
            return this.restService.search('organizations', { search: { search: searchParams }, size: 500 });
        }
        return this.restService.search('organizations');
    }
}
