<div class="panel panel-preview"
     [ngClass]="{'no-box-shadow no-border-bottom': mode != 'full'}">
    <evolenta-scrollbar>
        <div class="panel-body no-padding-top">
            <div class="panel-heading no-border-radius no-border-left no-margin-left no-margin-right mb-20"
                 *ngIf="mode == 'full'">
                <h4 class="panel-title mt-20 mb-20">
                    {{ service.titles.title }}
                </h4>
                <div class="heading-elements"
                     *ngIf="printForms.length > 0">
                    <!-- Одна печатная форма -->
                    <button class="btn btn-default btn-sm no-border"
                            type="button"
                            [title]="localizations.common.print"
                            [disabled]="isProcessingPrintForm"
                            *ngIf="printForms.length == 1"
                            (click)="printServiceInfo(printForms[0])">
                        <i [ngClass]="{
                            'icon-printer2': !isProcessingPrintForm,
                            'icon-spinner2 spinner': isProcessingPrintForm
                            }">
                        </i>
                    </button>

                    <!-- Несколько печатных форм -->
                    <div class="btn-group"
                         *ngIf="printForms.length > 1">
                        <button class="btn btn-default btn-sm"
                                type="button"
                                [disabled]="isProcessingPrintForm">
                            <i [ngClass]="{
                                'icon-printer2': !isProcessingPrintForm,
                                'icon-spinner2 spinner': isProcessingPrintForm}">
                            </i>
                        </button>
                        <button class="btn btn-default dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                [disabled]="isProcessingPrintForm">
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right">
                            <li *ngFor="let printForm of printForms">
                                <a (click)="printServiceInfo(printForm)"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="panel-preview-container">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        <span>
                            {{ localizations.common.general_data }}
                        </span>
                        <a class="control-arrow">
                            <i class="main"
                               [ngClass]="{
                                    'icon-arrow-down12' : panels.common,
                                    'icon-arrow-left12' : !panels.common
                               }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': panels.common}">
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.services.name }}:
                            </div>
                            <div *ngIf="service.titles.title">
                                {{ service.titles.title }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.standards.basic_processing_term }}:
                            </div>
                            <div *ngIf="terms.length > 0">
                                {{ this.getTermText(null) }}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <!-- Варианты услуги -->
                <fieldset *ngIf="service.variants">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.variants = !panels.variants">
                        <span>
                            {{ localizations.common.variants }}
                        </span>
                        <a class="control-arrow">
                            <i class="doc"
                               [ngClass]="{
                                    'icon-arrow-down12' : panels.variants,
                                    'icon-arrow-left12' : !panels.variants
                               }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': panels.variants}">
                        <ul class="list-unstyled no-margin">
                            <li class="mb-5"
                                *ngFor="let variant of service.variants; let idx = index;">
                                {{idx + 1}}. {{variant.name}}
                                <span class="text-muted">
                                    {{ this.getTermText(variant) }}
                                </span>
                                <simple-tree-variant [mainIndex]="idx"
                                                     [variants]="variant.subVariants"
                                                     [terms]="terms"
                                                     *ngIf="variant.subVariants">
                                </simple-tree-variant>
                            </li>
                        </ul>
                    </div>
                </fieldset>

                <!-- Документы -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.documents = !panels.documents">
                        <span>
                            {{ localizations.common.documents }}
                        </span>
                        <a class="control-arrow">
                            <i class="doc"
                               [ngClass]="{
                                    'icon-arrow-down12' : panels.documents,
                                    'icon-arrow-left12' : !panels.documents
                               }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': panels.documents}">
                        <ol class="no-margin pl-20"
                            *ngIf="service.documentGroups">
                            <li class="mb-5"
                                *ngFor="let document of service.documentGroups">
                                <span *ngIf="document.name">
                                    {{ document.name }}
                                </span>
                            </li>
                        </ol>
                        <div class="text-muted"
                             *ngIf="service.documentGroups && service.documentGroups.length == 0">
                            {{ localizations.services.missing }}
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </evolenta-scrollbar>
</div>
