import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../../../common/services/utility.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'service-preview-complex',
    templateUrl: './service-preview-complex.component.html',
})
export class ServicePreviewComplexComponent implements OnInit {
    @Input() public service: any;

    public localizations;

    public panels = {
        common: true,
        services: true,
    };

    public constructor(
        public utility: UtilityService,
        public translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.sortStandards(this.service.standards);
    }

    private _loadTranslations() {
        this.translate
            .get([
                'common',
                'appeals',
                'services',
                'processes',
                'organizations',
                'appeals.subjects',
                'anketa-life-event',
            ])
            .subscribe((res: any) => {
                this.localizations = res;
            });
    }

    public isCollapsed(nameClass: any, event: any): void {
        this.utility.isCollapsedPreview(nameClass, event);
    }

    /**
     * Сортировка стандартов комлексной услуги В порядке возрастания displayOrder
     * @param standards - стандарты услуги
     */
    public sortStandards(standards) {
        standards.sort((a, b) => {
            return +a.displayOrder > +b.displayOrder;
        });
    }
}
