import { Inject, Injectable } from '@angular/core';
import { CORE_CONFIG, CoreDynamicConfigInterface, RestService, StorageService, ToasterService } from '@evolenta/core';
import { TEMPLATES_COLLECTION } from '../../common/constants';
import { CommonUtilities } from '@evolenta/utilities';

@Injectable()
export class TemplatesService {
    public constructor(
        @Inject(CORE_CONFIG) private readonly config: CoreDynamicConfigInterface,
        private rest: RestService,
        private toaster: ToasterService,
        private storage: StorageService,
    ) {}

    public async fetchTemplates() {
        const user = this.storage.getItem(this.config.storageConfig.activeUserKey);
        const params = [
            {
                field: 'userCreation.login',
                operator: 'eq',
                value: user.login,
            },
        ];

        try {
            return await this.rest.search(TEMPLATES_COLLECTION, {
                search: { search: params },
                prj: 'templateList',
                sort: 'dateCreation,DESC',
            });
        } catch (error) {
            this.toaster.error('При запросе шаблонов запроса произошла ошибка: ' + error);
        }
    }

    public async fetchTemplateXml(guid: string) {
        const params = [
            {
                field: 'guid',
                operator: 'eq',
                value: guid,
            },
        ];
        try {
            return await this.rest.search(TEMPLATES_COLLECTION, {
                search: { search: params },
            });
        } catch (error) {
            this.toaster.error('При запросе шаблона произошла ошибка: ' + error);
        }
    }

    public async createNewTemplate(name: string, xml) {
        const createdData = {
            templateName: name,
            xml: xml,
        };

        return this.rest.create(TEMPLATES_COLLECTION, createdData);
    }

    public async deleteTemplate(selectedTemplate) {
        return this.rest.remove(TEMPLATES_COLLECTION, selectedTemplate);
    }
}
