import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable()
export class TreeSelectService {
    public constructor() {}

    public initialSelectedValue(elements, selected, subNodesName) {
        if (!elements) {
            return;
        }
        elements.forEach((element) => {
            const elementSelected = selected.find((item) => {
                return element.guid === item.guid;
            });
            if (elementSelected) {
                element.selected = true;
                element.showBtn = true;

                if (element[subNodesName] && element[subNodesName].length > 0) {
                    this.initialSelectedValue(element[subNodesName], selected, subNodesName);
                }
            } else if (element[subNodesName] && element[subNodesName].length > 0) {
                element.hide = true;
            }
        });
    }

    // открываем все варианты рядом с последним выбранным
    public getListElementsWithLastSelected(treeArray, elements, subNodesName) {
        const lastSelectedElement = elements.find((item) => item.guid === treeArray[treeArray.length - 1].guid);
        if (lastSelectedElement) {
            elements.forEach((item) => {
                item.hide = false;
                item.showBtn = false;
            });
        } else {
            elements.forEach((item) => {
                if (item[subNodesName] != null && item[subNodesName].length !== 0) {
                    this.getListElementsWithLastSelected(treeArray, item[subNodesName], subNodesName);
                }
            });
        }
    }

    public getTreeArray(element, elements, treeArray, subNodesName, titleNode, additionalFields) {
        element.selected = true;
        elements.forEach((item) => {
            if (item.guid !== element.guid) {
                // обработка не выбранных элементов
                item.selected = false;
                if (item[subNodesName] && item[subNodesName].length !== 0) {
                    // обработка дочерних элементов не выбранных элементов
                    this._deselectedChildren(item[subNodesName], subNodesName, treeArray, titleNode);
                }
                item.hide = !item.hide;
                item.showBtn = false;
            } else {
                // обработка выбранного элемента
                item.hide = false;
                item.showBtn = !item.showBtn;

                if (item[subNodesName] && item[subNodesName].length !== 0) {
                    // обработка дочерних элементов не выбранных элементов
                    this._toggleChildren(item[subNodesName], item.showBtn, subNodesName);
                }
            }

            const isCheckSelected = this._checkSelectedElement(item, treeArray, titleNode, additionalFields);
            if (isCheckSelected) {
                treeArray.slice(isCheckSelected[0], 1);
            }
        });

        return treeArray;
    }

    // убираем свойство selected если выбран другой вариант на уровень выше
    private _deselectedChildren(elements, subNodesName, treeArray, titleNode) {
        elements.forEach((item) => {
            item.selected = false;
            // обработка массива с выбранными элементами
            this._checkSelectedElement(item, treeArray, titleNode);

            if (item[subNodesName] && item[subNodesName].length !== 0) {
                this._deselectedChildren(item[subNodesName], subNodesName, treeArray, titleNode);
            }
        });
    }

    // скрываем - раскрываем дочерние элементы - клик по выделенному родителю
    private _toggleChildren(elements, showBtn, subNodesName) {
        elements.forEach((item) => {
            item.hide = !showBtn;
            if (item[subNodesName] && item[subNodesName].length !== 0) {
                this._toggleChildren(item[subNodesName], showBtn, subNodesName);
            }
        });
    }

    // записываем в массив цепочку выбранных элементов дерева от верхнего к нижнему
    private _checkSelectedElement(element, treeArray, titleNode = 'name', additionalFields = []) {
        let currentIndex = false;
        let presentElement;
        // Определяем параметры обрабатываемого элемента
        treeArray.forEach((item, index) => {
            if (item.guid === element.guid) {
                currentIndex = index;
                presentElement = item;
            }
        });
        // console.log('present Element', presentElement);

        // Определяем необходимость удаления или добавления элемента
        if (presentElement) {
            if (!element.selected) {
                treeArray.splice(currentIndex, 1);
            }
        } else {
            if (element.selected) {
                const saveElement: any = {};
                saveElement.guid = element.guid;
                saveElement[titleNode] = element[titleNode];
                if (additionalFields.length > 0) {
                    additionalFields.forEach((field) => {
                        saveElement[field] = element[field];
                    });
                }
                treeArray.push(saveElement);
            }

            return false;
        }
    }
}
