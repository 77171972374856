<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component"
     *ngIf="initialized">
    <breadcrumbs></breadcrumbs>

    <!-- Задел под Функциональные кнопки-->

    <!-- Глобальный поиск -->
    <div class="global-search short ml-30">
        <div class="input-group">
            <input class="form-control" [placeholder]="localizations.common.search_dots"
                   type="text"
                   [(ngModel)]="globalSearch"
                   (ngModelChange)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="fetchAdaptors()">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <!-- Кнопки авторизации + доп.меню -->
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<!-- Основное содержание компонента -->
<div class="page-container">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title no-padding-bottom no-border">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-bold">
                            {{ localizations.smev_configuration.plural }}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{ totalAdaptorsCount }}
                            </span>
                        </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li><i class="icon-cog3"></i></li>
                    </ul>
                </div>
            </div>

            <evolenta-scrollbar scrollClass="sidebar sidebar-secondary sidebar-default pb-20">
                <!-- Панель сортировки -->
                <sorting-bar collection="smevCollection"
                             selectedField="uri"
                             (fieldChange)="setSortOptsAndLoadData($event)">
                </sorting-bar>

                <!-- Панель фильтрации -->
                <filters-bar [filters]="filtersPanelItems"
                             [afterHeader]="true"
                             [base]="baseSearch"
                             (onApplyFilters)="fetchAdaptors(null, true)"
                             #filtersPanel>
                </filters-bar>
            </evolenta-scrollbar>

        </div>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat no-border-top">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="fetchAdaptors()">
                            <ng-container *ngIf="adaptors && adaptors.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let adaptor of adaptors; trackBy:indexedAdaptors; let lst = last">
                                        <smev-configuration [adaptor]="adaptor"
                                                            (click)="adaptorClickedHandler(adaptor)"
                                                            [selected]="checkIsSelected(adaptor)">
                                        </smev-configuration>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!adaptors">
                                <h5>
                                    {{ localizations.smev_configuration.fetching }}
                                </h5>
                            </ng-container>


                        </evolenta-infinite-scrollbar>
                        <!-- Заглушка адаптеров -->
                        <ng-container *ngIf="adaptors && !adaptors.length">
                            <div class="panel-body text-center mt-20">
                                <div class="icon-object border-primary-600 text-primary">
                                    <i class="icon-info3"></i>
                                </div>
                                <h5>
                                    {{ localizations.common.results_missing }}
                                </h5>
                                <p class="mg-15">
                                    {{ localizations.smev_configuration.try_to_adjust_filters }}
                                </p>
                                <button class="btn btn-primary-overline mb-10"
                                        (click)="clearFilters()">
                                    {{ localizations.common.reset_filters }}
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="scroll-panel no-padding no-margin full-width-and-height">
                        <div class="alert alert-warning alert-styled-left alert-bordered"
                             *ngIf="!selected">
                            <button class="close"
                                    type="button"
                                    data-dismiss="alert">
                                <span>×</span>
                            </button>
                            <span class="text-semibold">
                                {{ localizations.common.attention }}
                            </span>
                            {{ localizations.smev_configuration.for_detailed_view_select_smev_adapter }}
                        </div>
                        <smev-configuration-preview-edit [adaptor]="selected"
                                                         *ngIf="selected">
                        </smev-configuration-preview-edit>
                </div>
            </div>
        </div>
    </div>
</div>
