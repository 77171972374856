import { Injectable } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { UtilityService } from '../../../common/services/utility.service';
import { DocumentService } from './components/documents/document.service';
import { AppealSubservicesService } from './appeal-subservices.service';
import { EntityFieldsRequirementsService } from '../../../common/services/entity-fields-requirements.service';
import { DOCUMENTS_SECTION, SUBJECTS_SECTION, SUBSERVICE_SECTION } from '../../../common/constants';

@Injectable()
export class AppealValidateService {
    public appeal; // Формируемое дело
    public subservice;

    public processValidate = false; // Флаг включения/отключения процесса валидации

    public validateParams; // Параметры валидации

    // Элементы валидации
    public validateElements: any;

    public elementTabs = {
        subservice: {
            field: 'guid',
            tabs: ['ogv', 'variant', 'objects', 'additionalData', 'issueResultForm'],
        },
        subjects: {
            field: 'guid',
            tabs: ['common', 'additionalData', 'realty'],
        },
        objects: {
            field: 'guid',
            tabs: ['common'],
        },
        documents: {
            field: 'guid',
            tabs: ['common', 'amount', 'participants', 'properties'],
        },
    };

    public constructor(
        private appealSubservicesService: AppealSubservicesService,
        private utilityService: UtilityService,
        private documentService: DocumentService,
        private storage: StorageService,
        private fieldsRequirementsService: EntityFieldsRequirementsService,
    ) {
        this.validateElements = {
            subservice: {},
            objects: {},
            subjects: {},
            documents: {},
            documentGroups: {},
        };
    }

    /**
     * Очистка данных сервиса
     */
    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.processValidate = false;
        this.validateParams = null;
        this.validateElements = {
            subservice: {},
            objects: {},
            subjects: {},
            documents: {},
            documentGroups: {},
        };
    }

    /**
     * Основной метод валидации дела
     * @param validate - Признак необходимости/отсутствия валидации
     */
    public validateAppeal(validate) {
        this._initValidateParams(validate);
        if (!validate || this.appeal.converted) {
            return true;
        }

        // Проверка параметров услуг дела
        const subserviceValid = this._validateSubservice(this.appeal.subservice);

        // Валидация субъектов дела
        const subjectsValid = this._validateSubjects();

        // Проверка документов дела
        const documentsValid = this._validateDocuments();

        // Возврат результата валидации
        return subserviceValid && subjectsValid && documentsValid;
    }

    /**
     * Инициализация процесса валидации
     * @param validate - флаг необходимости валидации дела
     */
    private _initValidateParams(validate) {
        this.processValidate = validate; // Флаг процесса валиации

        // Описательный объект процесса валидации
        this.validateParams = {
            items: {
                subservice: false,
                objects: false,
                documentGroups: false,
                documents: false,
            },
            subservice: {},
            objects: [],
            subjects: [],
            documentGroups: [],
            documents: [],
            validateComplete: false,
        };

        // Инициализация параметров валидации для каждой проверяемой вкладки каждого элемента
        Object.keys(this.elementTabs).forEach(entities => {
            if (this.appeal[entities]) {
                if (entities !== 'subservice') {
                    if (entities === 'subjects') {
                        this.validateElements[entities] = {
                            valid: true,
                            tabs: this._initTabs(entities),
                        };
                    }

                    this.appeal[entities].forEach(element => {
                        this.validateElements[entities][element[this.elementTabs[entities].field]] = {
                            valid: true,
                            tabs: this._initTabs(entities),
                        };

                        // Инициализация данных для групп документов
                    });
                } else {
                    this.validateElements[entities] = {
                        valid: true,
                        tabs: this._initTabs(entities),
                    };

                    // Инициализация данных для групп документов
                    Object.keys(this.documentService.appealSubservicesData).forEach(subserviceGuid => {
                        this.validateElements.documentGroups[subserviceGuid] = {};
                        this.documentService.appealSubservicesData[subserviceGuid].appealDocumentGroups.forEach(group => {
                            this.validateElements.documentGroups[subserviceGuid][group.guid] = {
                                valid: true,
                                tabs: {
                                    common: {
                                        valid: true,
                                        errors: {},
                                    },
                                },
                            };
                        });
                    });
                }
            }
        });
    }

    /**
     * Инициализация параметров валидации для отдельного элемента (при добавлении элемента)
     * @param entity
     * @param elementId
     */
    public initValidateElement(entity, elementId) {
        if (entity === 'subservice') {
            this.validateElements.subservice = {
                valid: true,
                tabs: this._initTabs(entity),
            };

            return;
        }
        this.validateElements[entity][elementId] = {
            valid: true,
            tabs: this._initTabs(entity),
        };
    }

    /**
     * Удаление параметров валидации для отдельного элемента (при удалении элемента)
     * @param entity
     * @param elementId
     */
    public removeValidateElement(entity, elementId) {
        if (entity === 'subservice') {
            delete this.validateElements.subservice;

            return;
        }

        delete this.validateElements[entity][elementId];
    }

    /**
     * Инициализация данных для каждой вкладки
     * @param entities - вид элементов: услуги, объекты, дела
     * @returns {{}}
     */
    private _initTabs(entities) {
        const object = {};
        this.elementTabs[entities].tabs.forEach(tab => {
            object[tab] = {
                valid: true,
                errors: {},
            };
        });

        return object;
    }

    /**
     * Добавление элемента
     * @param elementId
     * @param entities
     */
    public addValidateElement(elementId, entities) {
        if (this.processValidate) {
            if (entities === 'subservice') {
                this.validateElements.subservice = {
                    valid: true,
                    tabs: this._initTabs(entities),
                };

                return;
            }

            this.validateElements[entities][elementId] = {
                valid: true,
                tabs: this._initTabs(entities),
            };
        }
    }

    // ------------------ МЕТОДЫ ВАЛИДАЦИИ УСЛУГ ДЕЛА ----------------------- //

    /**
     * Проверка отдельной услуги дела
     * @param appealSubservice - обрабатываемая услуга
     */
    private _validateSubservice(appealSubservice) {
        if (!this.processValidate) {
            return;
        }

        // КНМ организации, ответственной за предоставление услуги
        this._validateSubserviceResponsibleOrganization(appealSubservice);

        // КНМ выбора варианта выдачи результата оказания услуги
        this._validateSubserviceIssueResultForm(appealSubservice);

        // КНМ варианта услуги
        this._validateSubserviceVariant(appealSubservice);

        // КНМ настройки объектов
        this._validateSubserviceObjects(appealSubservice);

        // КНМ форм дополнительных данных (услуга + вариант)
        this.validateSubserviceAdditionalData(appealSubservice);

        // Выставление флага итоговой валидации на всю услугу
        this._setElementValidityFlag(appealSubservice.guid, 'subservice');

        return this.validateElements.subservice.valid;
    }

    /**
     * Проверка выбора варианта выдачи результата оказания услуги в случае
     * @param appealSubservice
     */
    private _validateSubserviceIssueResultForm(appealSubservice) {
        if (!(this.validateElements.subservice)) {
            return;
        }

        const data = this.validateElements.subservice.tabs.issueResultForm;
        // Инициализация параметров валидации
        data.valid = true;
        data.errors = {};
        if (!(this.processValidate && appealSubservice.hasOwnProperty('issueResultForm') && this.appealSubservicesService.data[appealSubservice.guid] && !this.appealSubservicesService.data[appealSubservice.guid].issueResultForm)) {
            return;
        }

        data.valid = false;
        data.errors.issueResultForm = 'Не выбран вариант выдачи результата оказания услуги';
    }

    /**
     * Проверка выбора / не выбора ОГВ услуги
     * @param appealSubservice - обрабатываемая услуга
     */
    private _validateSubserviceResponsibleOrganization(appealSubservice) {
        if (!this.validateElements.subservice) {
            return;
        }

        const data = this.validateElements.subservice.tabs.ogv;
        // Инициализация параметров валидации
        data.valid = true;
        data.errors = {};
    }

    /**
     * Проверка выбора / не выбора варианта услуги, если  варианты предусмотрены в описании услуги
     * @param appealSubservice - обрабатываемая услуга дела
     */
    private _validateSubserviceVariant(appealSubservice) {
        if (!this.validateElements.subservice) {
            return;
        }

        const data = this.validateElements.subservice.tabs.variant;
        // Инициализация параметров валидации
        data.valid = true;
        data.errors = {};
        if (this.processValidate && !this.appealSubservicesService.data[appealSubservice.guid].correctVariant) {
            data.valid = false;
            data.errors.vairant = 'Не выбран вариант услуги';
        }
    }

    /**
     * Проверка параметров объектов в разрезе привязки к услуге
     * @param appealSubservice - обрабатываемая услуга
     */
    private _validateSubserviceObjects(appealSubservice) {
        if (!this.validateElements.subservice) {
            return;
        }

        const data = this.validateElements.subservice.tabs.objects;
        // Инициализация параметров валидации
        data.valid = true;
        data.errors = {};
        if (!this.processValidate) {
            return;
        }

        const subserviceData = this.appealSubservicesService.data[appealSubservice.guid];
        // КНМ на соответствие числа участников для групп услуги
        subserviceData.groups.forEach(group => {
            let groupSubjects = 0;
            Object.keys(subserviceData.subjects).forEach(subjectGuid => {
                const subjectData = subserviceData.subjects[subjectGuid];
                if (subjectData.active && subjectData.group && subjectData.group.guid === group.guid) {
                    groupSubjects ++;
                }
            });
            if (group.minCount > groupSubjects) {
                data.errors['group' + group.guid] = 'В группу "' + group.name + '" не добавлено обязательное число участников';
            }
        });

        // Статус валидности всей вкладки
        if (Object.keys(data.errors).length) {
            data.valid = false;
        }
    }

    /**
     * Проверка на завершение выбора категории участника до завершающего уровня
     * @param categories
     * @param selectedCategories
     * @param {number} checkIndex
     * @returns {boolean}
     */
    public checkObjectSelectCategoryFinish(categories, selectedCategories, checkIndex = 0) {
        const find = categories.find(item => item.guid === selectedCategories[checkIndex].guid);
        if (find) {
            if (checkIndex < selectedCategories.length - 1 && find['subCategory'] && find['subCategory'].length > 0) {
                // Есть еще другие категории
                return this.checkElementValid(find['subCategory'], selectedCategories, checkIndex++);
            } else if (checkIndex === selectedCategories.length - 1) {
                // Если у последнего выбранного элемента есть дочерние значения, то ошибка
                return !(find['subCategory'] && find['subCategory'].length);
            }
        }

        return false;
    }

    /**
     * Проверка дополнительных данных услуги (XSD-услуги, XSD-варианта услуги)
     * @param appealSubservice - обрабатываемая услуга
     */
    public validateSubserviceAdditionalData(appealSubservice) {
        if (!this.validateElements.subservice || !Object.keys(this.validateElements.subservice).length/* || this.validateElements.subservice.guid !== appealSubservice.guid*/) {
            return;
        }

        const data = this.validateElements.subservice.tabs.additionalData;
        // Инициализация параметров валидации
        data.valid = true;
        data.errors = {};
        if (!(this.processValidate && !appealSubservice.notValidateAdditionalData
            && (this.appeal.status.code !== 'draft' || this.appeal.status.code === 'draft' && !this.appeal.simplifyMode))) {
            return;
        }

        // Если для услуги дела или варианта услуги есть XSD и в сохраненных данных есть информация об обработке формы и она не валидна
        if ((appealSubservice.xsd && appealSubservice.xsdData && !appealSubservice.xsdDataValid)
            || ((appealSubservice.variantXsd && appealSubservice.variantXsdData && !appealSubservice.variantXsdDataValid))) {
            data.errors.additionalData = 'Ошибки заполнения формы дополнительных данных';
        } else if ((appealSubservice.xsd && !appealSubservice.xsdData && (!appealSubservice.hasOwnProperty('xsdRequired') || appealSubservice.hasOwnProperty('xsdRequired') && appealSubservice['xsdRequired']))
            || (appealSubservice.variantXsd && !appealSubservice.variantXsdData
                && (!appealSubservice.hasOwnProperty('variantXsdRequired')
                    || appealSubservice.hasOwnProperty('variantXsdRequired') && appealSubservice['variantXsdRequired']))) {
            // Если для услуги дела или для варианта услуги есть XSD, но XSD не разу не инициализировалась (не отображалась форма, не было обращение к вкладке с XSD)
            data.errors.additionalData = 'Не обработаны поля формы дополнительных данных';
        }

        // Статус валидности всей вкладки
        if (Object.keys(data.errors).length) {
            data.valid = false;
        }
    }

    // ---------------------- ФУНКЦИИ ПРОВЕРКИ СУБЪЕКТОВ ДЕЛА ---------------------------------- //

    /**
     * Валидация субъектов дела
     */
    private _validateSubjects() {
        let allSubjectsValid = true;
        Object.keys(this.appealSubservicesService.subjectsData).forEach(subjectGuid => {
            const subjectValid = this.validateSubject(this.appealSubservicesService.subjectsData[subjectGuid]);
            allSubjectsValid = !subjectValid ? false : allSubjectsValid;
        });

        return allSubjectsValid;
    }

    /**
     * Валидация отдельного объекта дела
     * @param subject
     * @returns {Promise<boolean>}
     */
    public validateSubject(subject) {
        if (this.processValidate) {
            const subjectData = this.appealSubservicesService.subjectsData[subject.guid];
            this.validateSubjectCommon(subjectData, 'common');

            // Вкладка "Доп.данные"
            this.validateSubjectAdditionalData(subjectData);

            // Выставление флага итоговой валидации на весь объект
            this._setElementValidityFlag(subject.guid, 'subjects');

            return this.validateElements.subjects[subject.guid].valid;
        }
    }

    /**
     * Проверка данных объекта недвижимости
     * @param object
     */
    public validateObjectRealty(object) {
        if (this.validateElements.objects && this.validateElements.objects[object.guid]) {
            const data = this.validateElements.objects[object.guid].tabs.realty;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                const objectData = this.appealSubservicesService.subjectsData[object.guid].data.realty;
                this.validateRealtyItem(objectData, data.errors);
                if ((objectData.typeCode === 'integralPropertyComplex' || objectData.typeCode === 'industrialPropertyComplex')
                    && objectData.dopInfoObjects && objectData.dopInfoObjects.length) {
                    const dopObjectsErrors = {};
                    objectData.dopInfoObjects.forEach(dopObject => {
                        this.validateRealtyItem(dopObject, dopObjectsErrors);
                    });
                    if (Object.keys(dopObjectsErrors).length) {
                        data.errors.dopObjects = 'Ошибки в настройках объектов комплекса';
                    }
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка данных отдельного объекта недвижимости, включая дочерние объекты для сложных комплексов
     * @param objectData
     * @param errorsData
     */
    public validateRealtyItem(objectData, errorsData) {
        // КНМ Кадастрового номера
        if (!objectData.cadastralNumber) {
            errorsData.cadastralNumber = 'Не заполнен кадастровый номер / обозначение';
        }

        // КНМ адреса
        if (!objectData.address) {
            errorsData.address = 'Не заполнен адрес';
        }

        // КНМ площади
        if ((objectData.typeCode === 'landPlace' || objectData.typeCode === 'building'
                || objectData.typeCode === 'room' || objectData.typeCode === 'carPlace') && !objectData.area) {
            errorsData.area = 'Не заполнена площадь';
        }

        // КНМ назначения
        if ((objectData.typeCode === 'building' || objectData.typeCode === 'room') && !objectData.purpose) {
            errorsData.purpose = 'Не выбрано назначение';
        }

        if ((objectData.typeCode === 'construction' || objectData.typeCode === 'progressConstruction')
            && !objectData.area && !objectData.builtUpArea && !objectData.height && !objectData.depthOfOccurrence
            && !objectData.length && !objectData.depth && !objectData.volume) {
            errorsData.params = 'Для объекта должен быть заполнен хотя бы один из следующих параметров: Площадь, Площадь застройки, Высота, Глубина залегания, Протяжённость, Глубина, Объём';
        }
    }

    /**
     * Проверка данных на вкладке "Организация" для ЮЛ
     * @param object - проверяемый объект
     */
    public validateObjectOrganization(object) {
        if (this.validateElements.objects && this.validateElements.objects[object.guid]) {
            const data = this.validateElements.objects[object.guid].tabs.organization;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                // КНМ ОПФ
                if (!object.data.organization.opf) {
                    data.errors.opf = 'Не выбрана организационно-правовая форма';
                }

                // Краткое наименование
                if (!object.data.organization.shortName) {
                    data.errors.shortName = 'Не заполнено краткое наименование';
                }

                // Полное наименование
                if (!object.data.organization.name) {
                    data.errors.opf = 'Не заполнено полное наименование';
                }

                // КНМ ОГРН
                if (!object.data.organization.ogrn) {
                    data.errors.ogrn = 'Не заполнен ОГРН';
                } else {
                    const ogrnError = this.utilityService.validateOgrn(object.data.organization.ogrn);
                    if (ogrnError) {
                        data.errors.ogrn = ogrnError;
                    }
                }

                // КНМ ИНН
                if (!object.data.organization.inn) {
                    data.errors.inn = 'Не заполнен ИНН';
                } else {
                    const innError = this.utilityService.validateOrganizationInn(object.data.organization.inn);
                    if (innError) {
                        data.errors.inn = innError;
                    }
                }

                // КНМ КПП
                if (!object.data.organization.kpp) {
                    data.errors.kpp = 'Не заполнен КПП';
                } else {
                    const kppError = this.utilityService.validateKpp(object.data.organization.kpp);
                    if (kppError) {
                        data.errors.kpp = kppError;
                    }
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length > 0) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка данных на вкладке "Общие" объекта услуги
     * @param subject - проверяемый субъект
     * @param tab - обрабатываемая вкладка (common - ФЛ, ИП; representative: ЮЛ)
     */
    public validateSubjectCommon(subject, tab) {
        if (this.validateElements.subjects && this.validateElements.subjects[subject.guid]) {
            const data = this.validateElements.subjects[subject.guid].tabs[tab];
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                let subjectText;
                if (tab === 'common') {
                    subjectText = 'участника';
                } else {
                    subjectText = 'представителя организации';
                }
                if (subject.specialTypeId !== 'ulApplicant' && subject.data.person) {
                    // ----------- ФИО + Дата рождения + Пол -------------------//
                    // Проверка Фамилии
                    if (this.fieldsRequirementsService.checkFieldRequired('person.lastName', subject) && !subject.data.person.lastName) {
                        data.errors.lastName = 'Не заполнена фамилия ' + subjectText;
                    }

                    // Проверка Имени
                    if (this.fieldsRequirementsService.checkFieldRequired('person.firstName', subject) && !subject.data.person.firstName) {
                        data.errors.firstName = 'Не заполнено имя ' + subjectText;
                    }

                    // Проверка Отчества
                    if (this.fieldsRequirementsService.checkFieldRequired('person.middleName', subject) && !subject.data.person.middleName) {
                        data.errors.middleName = 'Не заполнено отчество ' + subjectText;
                    }

                    // Проверка Даты рождения
                    if (this.fieldsRequirementsService.checkFieldRequired('person.birthday', subject) && !subject.data.person.birthday) {
                        data.errors.birthday = 'Не заполнена дата рождения ' + subjectText;
                    }

                    // Проверка Пола
                    if (this.fieldsRequirementsService.checkFieldRequired('person.sex', subject) && !subject.data.person.sex) {
                        data.errors.birthday = 'Не выбран пол ' + subjectText;
                    }

                    // ----------- ДУЛ -------------------//
                    const catalogueDocuments = this.storage.getItem('catalogueDocuments');
                    let selectedCatalogueDocument;

                    // Проверка выбора вида документа
                    const documentType = Array.isArray(subject.data.person.documentType)
                        ? subject.data.person.documentType[0]
                        : subject.data.person.documentType;

                    if (this.fieldsRequirementsService.checkFieldRequired('person.documentType', subject) && !documentType) {
                        data.errors.documentType = 'Не выбран вид документа';
                    } else if (documentType) {
                        selectedCatalogueDocument = catalogueDocuments.find(item => item.code === documentType.id);
                    }

                    // Проверка серии документа
                    if (this.fieldsRequirementsService.checkFieldRequired('person.documentSeries', subject) && !subject.data.person.documentSeries) {
                        data.errors.documentSeries = 'Не заполнена серия документа';
                    } else if (subject.data.person.documentSeries
                        && selectedCatalogueDocument
                        && selectedCatalogueDocument.seriesPattern
                        && !this.checkValueByRegExp(selectedCatalogueDocument.seriesPattern, subject.data.person.documentSeries)) {
                        data.errors.documentSeries = 'Неверный формат серии документа';
                    }

                    // Проверка номера документа
                    if (this.fieldsRequirementsService.checkFieldRequired('person.documentNumber', subject) && !subject.data.person.documentNumber) {
                        data.errors.documentNumber = 'Не заполнен номер документа';
                    } else if (subject.data.person.documentNumber
                        && selectedCatalogueDocument
                        && selectedCatalogueDocument.numberPattern
                        && !this.checkValueByRegExp(selectedCatalogueDocument.numberPattern, subject.data.person.documentNumber)) {
                        data.errors.documentNumber = 'Неверный формат номер документа';
                    }

                    // Проверка даты выдачи документа
                    if (this.fieldsRequirementsService.checkFieldRequired('person.issuerDate', subject) && !subject.data.person.documentIssueDate) {
                        data.errors.issuerDate = 'Не выбрана дата выдачи документа';
                    }

                    // Проверка заполнения органа, выдавшего документ
                    if (this.fieldsRequirementsService.checkFieldRequired('person.documentIssuer', subject)
                        && (!subject.data.person.documentIssuer
                            || subject.data.person.documentIssuer
                            && !subject.data.person.documentIssuer.code
                            && (!subject.data.person.documentIssuer.name
                                || subject.data.person.documentIssuer.name.trim() === ''))) {
                        data.errors.documentIssuer = 'Не выбран орган, выдавший документ';
                    }
                    if (this.fieldsRequirementsService.checkFieldRequired('person.documentIssuer.code', subject)
                        && (!subject.data.person.documentIssuer
                            || subject.data.person.documentIssuer
                            && !subject.data.person.documentIssuer.code)) {
                        data.errors.documentIssuerCode = 'Не заполнен код органа, выдавшего документ';
                    }

                    // Проверка гражданства
                    if (this.fieldsRequirementsService.checkFieldRequired('person.citizenship', subject) && !subject.data.person.citizenship) {
                        data.errors.citizenship = 'Не заполнено гражданство';
                    }

                    // --------- СНИЛС + ИНН + Мобильный телефон ----------------------------//
                    // Проверка корректности СНИЛС
                    if (this.fieldsRequirementsService.checkFieldRequired('person.snils', subject) && !subject.data.person.snils) {
                        data.errors.snils = 'Не заполнен СНИЛС';
                    } else if (subject.data.person.snils) {
                        const snilsError = this.utilityService.validateSnils(subject.data.person.snils);
                        if (snilsError) {
                            data.errors.snils = snilsError;
                        }
                    }

                    // Проверка корректности ИНН
                    if (this.fieldsRequirementsService.checkFieldRequired('person.inn', subject) && !subject.data.person.inn) {
                        data.errors.inn = 'Не заполнен ИНН';
                    } else if (subject.data.person.inn) {
                        const innError = this.utilityService.validateInn(subject.data.person.inn);
                        if (innError) {
                            data.errors.inn = innError;
                        }
                    }

                    // Мобильный телефон
                    if (this.fieldsRequirementsService.checkFieldRequired('person.mobile', subject) && !subject.data.person.mobile) {
                        data.errors.mobile = 'Не указан мобильный телефон';
                    } else if (subject.data.person.mobile) {
                        const mobilePattern = /^\+[7-9]\([0-9]{3}\)\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}$/;
                        if (!mobilePattern.test(subject.data.person.mobile)) {
                            data.errors.mobile = 'Некорректный номер мобильного телефона';
                        }
                    }

                    // Email
                    if (this.fieldsRequirementsService.checkFieldRequired('person.email', subject) && !subject.data.person.email) {
                        data.errors.email = 'Не указан email';
                    }

                    // Проверка ОГРН ИП
                    if (this.fieldsRequirementsService.checkFieldRequired('person.ogrn', subject) && !subject.data.person.ogrn) {
                        data.errors.ogrn = 'Не заполен ОГРН индивидуального предпринимателя';
                    } else {
                        const ogrnError = this.utilityService.validateIpOgrn(subject.data.person.ogrn);
                        if (ogrnError) {
                            data.errors.ogrn = ogrnError;
                        }
                    }

                    // --------- Адреса ----------------------------//
                    // Место рождения
                    if (this.fieldsRequirementsService.checkFieldRequired('person.birthPlace', subject) && !subject.data.person.birthPlace) {
                        data.errors.mobile = 'Не заполнено место рождения';
                    }

                    // Адрес регистрации
                    if (this.fieldsRequirementsService.checkFieldRequired('person.registrationAddress', subject) && !subject.data.person.registrationAddress) {
                        data.errors.mobile = 'Не заполнен адрес регистрации';
                    }

                    // Адрес проживания
                    if (this.fieldsRequirementsService.checkFieldRequired('person.factAddress', subject) && !subject.data.person.factAddress) {
                        data.errors.mobile = 'Не заполнен адрес проживания';
                    }
                }

                // --------- Данные организации ----------------------------//
                if (subject.specialTypeId === 'ulApplicant') {
                    // Проверка ОПФ
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.opf', subject) && !subject.data.organization.opf) {
                        data.errors.opf = 'Не выбрана организационно-правовая форма';
                    }

                    // Краткое наименование
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.shortName', subject) && !subject.data.organization.shortName) {
                        data.errors.shortName = 'Не заполнено краткое наименование';
                    }

                    // Полное наименование
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.name', subject) && !subject.data.organization.name) {
                        data.errors.opf = 'Не заполнено полное наименование';
                    }

                    // Проверка ОГРН
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.ogrn', subject) && !subject.data.organization.ogrn) {
                        data.errors.ogrn = 'Не заполнен ОГРН';
                    } else if (subject.data.organization.ogrn) {
                        const ogrnError = this.utilityService.validateOgrn(subject.data.organization.ogrn);
                        if (ogrnError) {
                            data.errors.ogrn = ogrnError;
                        }
                    }

                    // Проверка ИНН
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.inn', subject) && !subject.data.organization.inn) {
                        data.errors.inn = 'Не заполнен ИНН';
                    } else if (subject.data.organization.inn) {
                        const innError = this.utilityService.validateOrganizationInn(subject.data.organization.inn);
                        if (innError) {
                            data.errors.inn = innError;
                        }
                    }

                    // Проверка КПП
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.kpp', subject) && !subject.data.organization.kpp) {
                        data.errors.kpp = 'Не заполнен КПП';
                    } else if (subject.data.organization.kpp) {
                        const kppError = this.utilityService.validateKpp(subject.data.organization.kpp);
                        if (kppError) {
                            data.errors.kpp = kppError;
                        }
                    }

                    // Юридический адрес
                    if (this.fieldsRequirementsService.checkFieldRequired('organization.registrationAddress', subject) && !subject.data.organization.registrationAddress) {
                        data.errors.mobile = 'Не заполнен юридический адрес';
                    }

                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length > 0) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка данных на вкладке "Документы" объекта услуги
     * @param object - проверяемый объект
     */
    public validateObjectDocument(object) {
        if (this.validateElements.objects && this.validateElements.objects[object.guid]) {
            const data = this.validateElements.objects[object.guid].tabs.document;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                const catalogueDocuments = this.storage.getItem('catalogueDocuments');
                let selectedCatalogueDocument;

                // Поля обязательны только для участника ФЛ
                if (object.specialTypeId === 'individualApplicant') {
                    // Проверка выбора вида документа
                    const documentType = Array.isArray(object.data.person.documentType)
                        ? object.data.person.documentType[0]
                        : object.data.person.documentType;

                    if (!documentType) {
                        data.errors.documentType = 'Не выбран вид документа';
                    } else {
                        selectedCatalogueDocument = catalogueDocuments.find(item => item.code === documentType.id);
                    }

                    // Проверка серии документа на корректность формата ввода
                    if (object.data.person.documentSeries
                        && selectedCatalogueDocument
                        && selectedCatalogueDocument.seriesPattern
                        && !this.checkValueByRegExp(selectedCatalogueDocument.seriesPattern, object.data.person.documentSeries)) {
                        data.errors.documentSeries = 'Неверный формат серии документа';
                    }

                    // Проверка номера документа
                    if (!object.data.person.documentNumber) {
                        data.errors.documentNumber = 'Не заполнен номер документа';
                    } else if (selectedCatalogueDocument
                        && selectedCatalogueDocument.numberPattern
                        && !this.checkValueByRegExp(selectedCatalogueDocument.numberPattern, object.data.person.documentNumber)) {
                        data.errors.documentNumber = 'Неверный формат номер документа';
                    }

                    // Проверка даты выдачи документа
                    if (!object.data.person.documentIssueDate) {
                        data.errors.issuerDate = 'Не выбрана дата выдачи документа';
                    }

                    // Проверка заполнения органа, выдавшего документ
                    if (!object.data.person.documentIssuer
                        || object.data.person.documentIssuer
                        && !object.data.person.documentIssuer.code
                        && (!object.data.person.documentIssuer.name || object.data.person.documentIssuer.name.trim() === '')) {
                        data.errors.documentIssuer = 'Не выбран орган, выдавший документ';
                    }
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка значения по регулярному выражению
     * @param pattern - шаблон регулярного выражения
     * @param value - проверяемое значение
     */
    public checkValueByRegExp(pattern, value) {
        const regex = new RegExp(pattern);

        return regex.test(value);
    }

    /**
     * Проверка данных на вкладке "Контакты"
     * @param object - проверяемый объект
     */
    public validateObjectContacts(object) {
        if (this.validateElements.objects && this.validateElements.objects[object.guid]) {
            const data = this.validateElements.objects[object.guid].tabs.contacts;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                // Мобильный телефон
                if (!object.data.person.mobile) {
                    data.errors.mobile = 'Не указан мобильный телефон';
                }
                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка корректности заполнения дополнительных полей
     * @param subject - проверяемый субъект
     */
    public validateSubjectAdditionalData(subject) {
        if (this.validateElements.subjects && this.validateElements.subjects[subject.guid]) {
            const data = this.validateElements.subjects[subject.guid].tabs.additionalData;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            // Не проверяем поля, если включена опция "не проверять xsd поля", либо осуществляется заполнение комплексного дела в упрощенном режиме
            if (this.processValidate && !subject.notValidateAdditionalData
                && (this.appeal.status.code !== 'draft' || this.appeal.status.code === 'draft' && !this.appeal.simplifyMode)) {
                let xsdValid = true;
                const currentSubjectData = this.appeal.subservice.subjects.find(item => item.guid === subject.guid);

                if (currentSubjectData && currentSubjectData.xsd) {
                    if (currentSubjectData.xsdData && !currentSubjectData.xsdDataValid) {
                        data.errors.additionalData = 'Ошибки заполнения формы дополнительных данных';
                        xsdValid = false;
                    } else if (!currentSubjectData.xsdData && currentSubjectData.xsdRequired) {
                        data.errors.additionalData = 'Не обработаны поля формы дополнительных данных';
                    }
                }

                if (data.errors.additionalData) {
                    data.valid = false;
                }
            }
        }
    }

    // ---------------------- ФУНКЦИИ ПРОВЕРКИ ДОКУМЕНТОВ ДЕЛА ---------------------------------- //
    /**
     * Валидация объектов дела
     */
    private _validateDocuments() {
        let allGroupsValid = true;
        let allDocumentsValid = true;
        // Проверяем документы и группы документов только в случае, если дело не в статусе "Черновик" или "Черновик", но осуществляется упрощенное заполнение документов для комплексного дела
        if (this.appeal.status.code !== 'draft' || this.appeal.status.code === 'draft' && !this.appeal.simplifyMode) {
            // Валидация групп документов для всех услуг дела
            // Если валидация запущена при инициализации смены статуса для отдельной услуги, проверяем только ее документы
            this.documentService.appealSubservicesData[this.appeal.subservice.guid].appealDocumentGroups.forEach(group => {
                const groupValid = this.validateDocumentGroup(this.appeal.subservice, group);
                allGroupsValid = !groupValid ? false : allGroupsValid;
            });

            // Валидация документов дела
            Object.keys(this.documentService.data).forEach(documentGuid => {
                const documentValid = this.validateDocument(this.documentService.data[documentGuid]);
                allDocumentsValid = !documentValid ? false : allDocumentsValid;
            });
        }

        return allDocumentsValid && allGroupsValid;
    }

    /**
     * Валидация отдельной группы документов
     * @param appealSubservice - услуга в деле
     * @param group - проверяемая группа
     */
    public validateDocumentGroup(appealSubservice, group) {
        if (this.validateElements.documentGroups && this.validateElements.documentGroups[appealSubservice.guid][group.guid]) {
            const data = this.validateElements.documentGroups[appealSubservice.guid][group.guid].tabs.common;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                const groupDocuments = [];
                Object.keys(this.documentService.data).forEach(documentGuid => {
                    if (documentGuid && this.documentService.data[documentGuid].group
                        && this.documentService.data[documentGuid].group.guid === group.guid
                        && this.documentService.data[documentGuid].appealSubservice.guid === appealSubservice.guid) {
                        groupDocuments.push(this.documentService.data[documentGuid]);
                    }
                });
                // Группа документов на услугу
                let requirements;
                if (group.appealRequirements.byService) {
                    requirements = group.appealRequirements.byService;
                    let groupCopies = 0;
                    let groupOriginals = 0;
                    groupDocuments.forEach(document => {
                        groupCopies += document.copies;
                        groupOriginals += document.originals;
                    });
                    // Если группа обязательная и не добавлены документы в группу
                    if (!this.appeal.incompleteSetOfDocuments) {
                        if (requirements.required && groupDocuments.length === 0) {
                            // Если в группу обязательно должны быть добавлены документы
                            data.errors.amount = 'В обязательной группе отсутствуют документы';
                        } else if (groupDocuments.length > 0 && groupDocuments < group.min) {
                            // Если в группу не добавлено минимальное число документов
                            data.errors.amount = 'В группу не добавлено минимально число документов (минимум ' + group.min + ')';
                        }
                    }
                } else if (group.appealRequirements.byObjects) {
                    requirements = group.appealRequirements.byObjects;
                    const objectsDocumentsCount = {};
                    const documentsWithOtherSubserviceLink = [];
                    // Группа документов на участника
                    groupDocuments.forEach(document => {
                        let processDocument = document;
                        // Если есть ссылка на другой документ в деле, то подставляем его параметры для валидации
                        if (document.link) {
                            processDocument = this.documentService.data[document.link];
                        }
                        if (processDocument.useSubjects && processDocument.useSubjects.length > 0) {
                            // Простой документ
                            processDocument.useSubjects.forEach(subject => {
                                if (!objectsDocumentsCount[subject.guid]) {
                                    objectsDocumentsCount[subject.guid] = {originals: 0, copies: 0, principals: []};
                                }
                                objectsDocumentsCount[subject.guid].originals = objectsDocumentsCount[subject.guid]['originals'] + processDocument.originals;
                                objectsDocumentsCount[subject.guid].copies = objectsDocumentsCount[subject.guid]['copies'] + processDocument.copies;
                                if (processDocument.principal) {
                                    objectsDocumentsCount[subject.guid].principals.push(processDocument.principal.guid);
                                }
                            });
                        } else if (processDocument.envelope && processDocument.envelope.object) {
                            // Документ - запрос
                            objectsDocumentsCount[processDocument.envelope.object.guid] = {
                                originals: processDocument.originals,
                                copies: processDocument.copies,
                            };
                        }

                        if (document.resultSubserviceLink) {
                            documentsWithOtherSubserviceLink.push(document);
                        }
                    });
                    // Пробегаемся по всем объектам в настройках условий предоставления
                    Object.keys(requirements).forEach(objectGuid => {
                        const object = this.appeal.subjects.find(item => item.guid === objectGuid);
                        const objectRequirement = requirements[objectGuid];
                        if (!this.appeal.incompleteSetOfDocuments) {
                            // Если группа обязательная, но в группу не добавлено ни одного документа для обрабатываемого участника
                            if (objectRequirement.required && !objectsDocumentsCount[objectGuid] && documentsWithOtherSubserviceLink.length === 0  && !this.appeal.incompleteSetOfDocuments) {
                                data.errors['amountObject' + objectGuid] = 'В обязательную группу не добавлено ни одного документа для объекта ' +
                                    '&laquo;' + object.header + '&raquo;';
                            } else if (objectRequirement && objectRequirement.principals && objectRequirement.principals.length > 0) {
                                objectRequirement.principals.forEach(requiredPrincipal => {
                                    if (requiredPrincipal.required
                                        && (!objectsDocumentsCount[objectGuid]
                                            || objectsDocumentsCount[objectGuid] && objectsDocumentsCount[objectGuid].principals && objectsDocumentsCount[objectGuid].principals.indexOf(requiredPrincipal.guid) === -1)) {
                                        const principal = this.appeal.subjects.find(item => item.guid === requiredPrincipal.guid);
                                        data.errors['principalObject' + requiredPrincipal.guid] = 'В группу не добавлено ни одного документа для объекта ' +
                                            '&laquo;' + object.header + '&raquo; на представителя &laquo;' + principal.header + '&raquo;';
                                    }
                                });
                            }
                        }
                    });

                    if (group.min > 0 && groupDocuments.length < group.min && !this.appeal.incompleteSetOfDocuments) {
                        data.errors.amount = 'В группу не добавлено минимально число документов (минимум ' + group.min + ')';
                    }
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length) {
                    data.valid = false;
                }
            }

            // Выставление флага итоговой валидации на весь объект
            this._setElementValidityFlag(group.guid, 'documentGroups', appealSubservice.guid);

            return this.validateElements.documentGroups[appealSubservice.guid][group.guid].tabs.common.valid;
        }
    }

    /**
     * Валидация отдельного документа дела
     * @param document - данные документа из сервиса
     * @returns {Promise<boolean>}
     */
    public validateDocument(document) {
        if (this.processValidate) {
            let group;
            if (document.subserviceGuid && document.groupGuid) {
                if (this.documentService.appealSubservicesData[document.subserviceGuid]) {
                    group = this.documentService.appealSubservicesData[document.subserviceGuid].appealDocumentGroups.find(item => item.guid === document.groupGuid);
                }
            }
            if (group) {
                // Проверка общих данных
                this.validateDocumentCommon(document);

                // Проверка числа копий и оригиналов
                this.validateDocumentAmount(document, group);

                // Проверка настройки участников
                this.validateDocumentParticipants(document, group);

                // Проверка дополнительных свойств
                this.validateDocumentProperties(document);
            }

            // Выставление флага итоговой валидации на документ
            this._setElementValidityFlag(document.guid, 'documents');

            return this.validateElements.documents[document.guid].valid;
        }
    }

    /**
     * Проверка общих данных по документу
     * @param document
     */
    public validateDocumentCommon(document) {
        if (this.validateElements.documents && this.validateElements.documents[document.guid]) {
            const data = this.validateElements.documents[document.guid].tabs.common;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                // Если осуществляется перевод на новый статус услуги и документ является линком на результат другой услуги и нет линка на этот результат
                if (document.resultSubserviceLink && !document.link) {
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length > 0) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка числа копий и оригиналов для документа
     * @param document - проверяемый документ
     * @param group - группа в которую включен документ
     */
    public validateDocumentAmount(document, group) {
        if (this.validateElements.documents && this.validateElements.documents[document.guid]) {
            const data = this.validateElements.documents[document.guid].tabs.amount;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                const processDocument = document;
                let checkedDocument = document;
                // В случае, если документ является ссылкой на другой документ
                if (document.link) {
                    checkedDocument = this.documentService.data[document.link]; // документ, который проверяется (в котором хранятся основные настройки: файлы, привязка к участникам)
                }
                // Проверка условий предоставления для группы
                if (group.appealRequirements.byService) {
                    // Условия предоставления на услугу
                    if (document.isElectronicDocument) {
                    } else {
                        // Если число копий меньше заявленных
                        if (group.appealRequirements.byService.copies > processDocument.copies) {
                            data.errors.copies = 'Указано неверное число копий. Минимальное значение:&nbsp;<strong>' + group.appealRequirements.byService.copies + '</strong>';
                        }
                        // Если число оригиналов меньше заявленных
                        if (group.appealRequirements.byService.originals > processDocument.originals) {
                            data.errors.originals = 'Указано неверное число оригиналов. Минимальное значение:&nbsp;<strong>' + group.appealRequirements.byService.originals + '</strong>';
                        }
                    }

                } else if (group.appealRequirements.byObjects) {
                    if (checkedDocument.useSubjects && checkedDocument.useSubjects.length > 0) {
                        checkedDocument.useSubjects.forEach(object => {
                            if (group.appealRequirements.byObjects[object.guid]) {
                                if (document.isElectronicDocument) {
                                } else {
                                    // Если число копий меньше заявленных
                                    if (group.appealRequirements.byObjects[object.guid].copies > processDocument.copies) {
                                        data.errors.copies = 'Указано неверное число копий. Минимальное значение:&nbsp;<strong>' + group.appealRequirements.byObjects[object.guid].copies + '</strong>';
                                    }
                                    // Если число оригиналов меньше заявленных
                                    if (group.appealRequirements.byObjects[object.guid].originals > processDocument.originals) {
                                        data.errors.originals = 'Указано неверное число оригиналов. Минимальное значение:&nbsp;<strong>' + group.appealRequirements.byObjects[object.guid].originals + '</strong>';
                                    }
                                }
                            }
                        });
                    }
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка состава участников для документа
     * @param document - проверяемый документ
     * @param group - группа в которую включен документ
     */
    public validateDocumentParticipants(document, group) {
        if (this.validateElements.documents && this.validateElements.documents[document.guid]) {
            const data = this.validateElements.documents[document.guid].tabs.participants;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                let processDocument = document;
                if (document.link) {
                    processDocument = this.documentService.data[document.link];
                }
                if (!document.resultSubserviceLink && group.appealRequirements.byObjects) {
                    if (processDocument.envelope && (!processDocument.envelope.object || !processDocument.envelope.object.guid)
                        || (!processDocument.envelope && (!processDocument.useSubjects || processDocument.useSubjects && processDocument.useSubjects.length === 0))) {
                        data.errors.participants = 'К документу не привязан ни один участник дела дела';
                    } else if (processDocument.useSubjects && processDocument.useSubjects.length > 0) {
                        // Проверка на необходимость выбора доверителя в случае, если объект уже выбран
                        const findByAgent = group.requirements.filter(item => item.byAgent);
                        if (findByAgent.length > 0) {
                            if (group.appealRequirements.byObjects[processDocument.useSubjects[0].guid].principals
                                && group.appealRequirements.byObjects[processDocument.useSubjects[0].guid].principals.length > 0
                                && !processDocument.principal) {
                                data.errors.participants = 'Для документа не выбран доверитель';
                            }
                        }
                    }
                }
                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length) {
                    data.valid = false;
                }
            }
        }
    }

    /**
     * Проверка валидности XSD-полей для документа
     * @param document
     */
    public validateDocumentProperties(document) {
        if (this.validateElements.documents && this.validateElements.documents[document.guid]) {
            const data = this.validateElements.documents[document.guid].tabs.properties;
            // Инициализация параметров валидации
            data.valid = true;
            data.errors = {};
            if (this.processValidate) {
                // Если для документа есть XSD и в сохраненных данных есть информация об обработке формы и она не валидна
                if (document.xsd && document.xsdData && !document.xsdDataValid) {
                    data.errors.additionalData = 'Ошибки заполнения формы дополнительных данных';
                } else if (document.xsd && !document.xsdData && document.xsdRequired) {
                    // Если для документа есть XSD, но XSD не разу не инициализировалась (не отображалась форма, не было обращение к вкладке с XSD)
                    data.errors.additionalData = 'Не обработаны поля формы дополнительных данных';
                }

                // Статус валидности всей вкладки
                if (Object.keys(data.errors).length > 0) {
                    data.valid = false;
                }
            }
        }
    }

    // ----------------------------- ОБЩИЕ ФУНКЦИИ -------------------------------------------- //

    /**
     * Корректировка признака "Валиден" для отдельного элемента дела (услуги, объекта, документа) дела
     * @param elementId - Идентификатор элемента
     * @param entities - вид элемента: subservice, objects, documents
     * @param mainId - ID родительской сущности
     * @returns {boolean}
     */
    private _setElementValidityFlag(elementId, entities, mainId = null) {
        if (this.processValidate) {
            let element;
            if (entities === 'subservice') {
                element = this.validateElements.subservice;
            } else if (mainId) {
                element = this.validateElements[entities][mainId][elementId];
            } else {
                element = this.validateElements[entities][elementId];
            }

            if (element) {
                let valid = true;
                if (element.tabs) {
                    Object.keys(element.tabs).forEach((tab: any) => {
                        valid = !element.tabs[tab].valid ? false : valid;
                    });
                }
                element.valid = valid;
            }
        }
    }

    /**
     * Проверка валидности элемента
     * @param elementId - Идентификатор элемента
     * @param entities - вид элемента: subservice, objects, documents
     * @param mainId - ID родительской сущности
     * @returns {boolean}
     */
    public checkElementValid(elementId, entities, mainId = null) {
        let valid = true;
        if (this.processValidate && this.validateElements[entities]) {
            let element;

            if (entities === 'subservice') {
                element = this.validateElements.subservice;
            } else {
                element = mainId ? this.validateElements[entities][mainId][elementId] : this.validateElements[entities][elementId];
            }

            if (element && !element.valid) {
                valid = false;
            }
        }

        return valid;
    }

    /**
     * Возвращает число ошибок для определенной вкладки отдельного элемента дела
     * @param elementId - Идентификатор элемента
     * @param entities - вид элемента: subservice, objects, documents
     * @param tab - код вкладки
     * @returns {number}
     */
    public getTabErrorsCount(elementId, entities, tab) {
        let countErrors = 0;
        if (entities === 'subservice') {
            if (this.validateElements.subservice && this.validateElements.subservice.tabs[tab]) {
                countErrors = Object.keys(this.validateElements.subservice.tabs[tab].errors).length;
            }
        } else {
            if (this.validateElements[entities] && this.validateElements[entities][elementId]
                && this.validateElements[entities] && this.validateElements[entities][elementId].tabs[tab]) {
                countErrors = Object.keys(this.validateElements[entities][elementId].tabs[tab].errors).length;
            }
        }

        return countErrors;
    }

    /**
     * Возвращает список ошибок
     * @param elementId - идентификатор элемента
     * @param entities - вид элемента: subservice, objects, documents
     * @param mainId - идентификатор родительского элемента
     * @returns {string}
     */
    public getElementErrorsList(elementId, entities, mainId = null) {
        let errors = '';
        if (entities === 'subservice') {
            if (this.processValidate && this.validateElements.subservice) {
                const element = this.validateElements.subservice;
                Object.keys(element.tabs).forEach(tab => {
                    if (!element.tabs[tab].valid) {
                        Object.keys(element.tabs[tab].errors).forEach(key => {
                            errors += '<li>' + element.tabs[tab].errors[key] + '</li>';
                        });
                    }
                });
            }
        } else {
            if (this.processValidate && this.validateElements[entities]) {
                const element = mainId ? this.validateElements[entities][mainId][elementId] : this.validateElements[entities][elementId];
                Object.keys(element.tabs).forEach(tab => {
                    if (!element.tabs[tab].valid) {
                        Object.keys(element.tabs[tab].errors).forEach(key => {
                            errors += '<li>' + element.tabs[tab].errors[key] + '</li>';
                        });
                    }
                });
            }
        }

        return errors;
    }

    /**
     * Возвращает общее число ошибок
     * @param entity - проверяемая сущность: документы, объекты услуги
     * @returns {number}
     */
    public getEntityErrorsCount(entity) {
        if (!this.processValidate) {
            return;
        }
        let errorsCount = 0;

        if (entity === SUBSERVICE_SECTION) {
            if (!this.validateElements[entity].valid) {
                Object.keys(this.validateElements[entity].tabs).forEach(tab => {
                    errorsCount = errorsCount + Object.keys(this.validateElements[entity].tabs[tab].errors).length;
                });
            }
        } else if (entity === SUBJECTS_SECTION && !this.appeal.subjects || !this.appeal.subjects.length) {
            return 1;
        } else {
            Object.keys(this.validateElements[entity]).forEach(key => {
                if (!this.validateElements[entity][key].valid && this.validateElements[entity][key].tabs) {
                    Object.keys(this.validateElements[entity][key].tabs).forEach(tab => {
                        errorsCount = errorsCount + Object.keys(this.validateElements[entity][key].tabs[tab].errors).length;
                    });
                }
            });
        }

        // Если Проверка документов, добавляем ошибки групп документов
        if (entity === DOCUMENTS_SECTION) {
            Object.keys(this.validateElements.documentGroups).forEach(appealSubserviceGuid => {
                Object.keys(this.validateElements.documentGroups[appealSubserviceGuid]).forEach(groupGuid => {
                    if (!this.validateElements.documentGroups[appealSubserviceGuid][groupGuid].valid) {
                        errorsCount = errorsCount + Object.keys(this.validateElements['documentGroups'][appealSubserviceGuid][groupGuid].tabs['common'].errors).length;
                    }
                });
            });
        }

        return errorsCount;
    }
}
