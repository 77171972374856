<!-- Отображение при просмотре -->
<div *ngIf="mode == 'view'" class="card-preview-shadow mt-20 ml-20 mr-20 p-10"  #documentCard>
    <div class="row card-preview-title">
        <div class="col-sm-9">
            <h5 class="no-margin">{{object.name ? object.name : 'Новый объект'}}</h5>
        </div>
        <div class="col-sm-3 text-right">
            <button class="btn btn-default p-15" (click)="edit()" type="button" title="Редактировать">
                <b>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C2 2.44772 2.44772 2 3 2H6C6.55228 2 7 1.55228 7 1C7 0.447715 6.55228 0 6 0H3C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V10C16 9.44771 15.5523 9 15 9C14.4477 9 14 9.44771 14 10V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM7.01347 7.5923L11.7773 2.82843L13.1916 4.24264L8.42768 9.00652L7.00379 9.0162L7.01347 7.5923ZM11.0702 0.707107C11.4608 0.316582 12.0939 0.316583 12.4845 0.707107L15.3129 3.53553C15.7034 3.92606 15.7034 4.55922 15.3129 4.94975L9.55181 10.7108C9.36594 10.8967 9.11436 11.0019 8.8515 11.0037L6.00372 11.023C5.44613 11.0268 4.99316 10.5739 4.99694 10.0163L5.0163 7.16848C5.01809 6.90563 5.1233 6.65404 5.30917 6.46817L11.0702 0.707107Z" fill="#A7A7AB"/>
                    </svg>
                </b>
            </button>

            <!-- Кнопка "Удалить объект" -->
            <button class="btn btn-default p-15 ml-15" (click)="delete()" type="button" title="Удалить">
                <b>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 2.99976V2.00049C4 0.895788 4.89586 0 6 0H10C11.1041 0 12 0.895788 12 2.00049V2.99976H15C15.5523 2.99976 16 3.44773 16 4.00033C16 4.55293 15.5523 5.0009 15 5.0009V13.9995C15 15.1042 14.1041 16 13 16H3C1.89586 16 1 15.1042 1 13.9995V5.0009C0.447715 5.0009 0 4.55293 0 4.00033C0 3.44773 0.447715 2.99976 1 2.99976H4ZM6 2.99976H10V2.00082L6 2.00114V2.99976ZM3 5.0009V13.9992L13 13.9989V5.0009H3ZM7 8C7 7.4474 7.44772 6.99943 8 6.99943C8.55229 6.99943 9 7.4474 9 8V11.9997C9 12.5523 8.55229 13.0002 8 13.0002C7.44772 13.0002 7 12.5523 7 11.9997V8ZM4 8.99992C4 8.44732 4.44772 7.99935 5 7.99935C5.55228 7.99935 6 8.44732 6 8.99992V11.9997C6 12.5523 5.55228 13.0002 5 13.0002C4.44772 13.0002 4 12.5523 4 11.9997V8.99992ZM10 8.99992C10 8.44732 10.4477 7.99935 11 7.99935C11.5523 7.99935 12 8.44732 12 8.99992V11.9997C12 12.5523 11.5523 13.0002 11 13.0002C10.4477 13.0002 10 12.5523 10 11.9997V8.99992Z" fill="#A7A7AB"/>
                    </svg>
                </b>
            </button>
        </div>
    </div>
    <div class="card-preview-body" *ngIf="object.address">
        <span class="text-muted">Адрес: </span>{{getAddress(object.address)}}
    </div>
</div>

<!-- Редактирование объекта -->
<div class="panel panel-default no-border-top no-border-left no-border-right no-border-radius no-margin" *ngIf="mode == 'edit'">

    <div class="panel-body no-padding border-left border-right border-top border-bottom border-grey-120 m-20">
            <!-- Строка поиска -->
            <div class="bg-primary-10 pt-20 pr-20 pl-20 pb-20 border-bottom border-grey-120">
                <div class="row">
                    <div class="col-sm-8">
                        <h4 class="no-margin-top no-margin-bottom pt-5">{{object.name ? object.name : 'Новый объект'}}</h4>
                    </div>
                    <div class="col-sm-4 text-right">
                        <button class="btn btn-lg btn-success-overline" (click)="apply()">
                            Применить
                        </button>

                        <button class="btn btn-lg btn-danger-overline ml-15" (click)="cancel()">
                            <span>Отменить</span>
                        </button>
                    </div>
                </div>

                <div *ngIf="!object.name" class="form-group no-margin-bottom mt-20">
                    <div class="input-group input-group-lg">
                        <input type="text" class="form-control"
                               placeholder="Поиск по наименованию в реестре объектов..."
                               (keypress)="keypress($event)"
                               name="searchText" [(ngModel)]="searchText">
                        <div class="input-group-btn pl-20">
                            <button type="button" class="btn btn-primary-overline" (click)="searchObject()">Найти</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="p-20">
                <div class="form-group form-group-lg">
                    <label>
                        Наименование объекта
                        <span class="text-danger">*</span>
                    </label>
                    <input class="form-control"
                           type="text"
                           name="objectName"
                           #name="ngModel"
                           [(ngModel)]="object.name" ngxTrim>
                </div>

                <div class="form-group no-margin-bottom">
                    <label>
                        Адрес объекта
                        <span class="text-danger">*</span>
                    </label>
                    <evolenta-address-gar
                            [large]="true"
                            [(ngModel)]="object.address"
                    ></evolenta-address-gar>
                </div>

                <div class="row mt-15">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Тип места проведения КНМ</label>
                            <catalogue
                                catalogName="nsiErpPlaceKnmType"
                                name="placeKnmType"
                                [(ngModel)]="object.placeKnmType"
                                [priority]="true"
                                [large]="true"
                                [disabled]="true">
                            </catalogue>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Тип объекта проведения КНМ</label>
                            <catalogue
                                catalogName="nsiErpObjectKnmType"
                                name="objectKnmType"
                                [(ngModel)]="object.objectKnmType"
                                [priority]="true"
                                [large]="true"
                                [disabled]="true">
                            </catalogue>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>

<object-modal [objects]="findedObjects" (onSelect)="selectObject($event)"></object-modal>
