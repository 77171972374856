import { NgModule } from '@angular/core';
import { OrganizationResolve } from './organization/organization.resolve';
import { OrganizationsService } from './organization/organizations.service';
import { OrganizationComponent } from './organization/organizations.component';
import { OrganizationEditComponent } from './organization/organization-edit.component';
import { OrganizationsSectionComponent } from './organization/organizations-section.component';
import { OrganizationMetaComponent } from './organization/organization-meta/organization-meta.component';
import { OrganizationPreviewComponent } from './organization/organization-preview/organization-preview.component';
import { OrganizationKndKindsComponent } from './organization/organization-knd-kinds/organization-knd-kinds.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { OrganizationPlanSettingsComponent } from './organization/organization-plans-settings/organization-plan-settings.component';
import { ProcessesModule } from '../processes/processes.module';
import { PrintFormsModule } from '../print-forms/print-forms.module';
import { OrganizationPlanSettingsBpmnComponent } from './organization/organization-plans-settings/organization-plan-bpmn/organization-plan-settings-bpmn.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { OrganizationCreateMasterComponent } from './organization-create-master/organization-create-master.component';
import { OrganizationPrintFormsComponent } from './organization/organization-print-forms/organization-print-forms.component';
import { OrganizationPlanCriteriaComponent } from './organization/organization-plans-settings/organization-plan-criteria/organization-plan-criteria.component';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { OrganizationProcessesSettingsComponent } from './organization/organization-processes-settings/organization-processes-settings.component';
import { OrganizationProcessComponent } from './organization/organization-processes-settings/organization-process/organization-process.component';
import { CalendarModule } from '../calendar/calendar.module';
import { RequestsModule } from '@evolenta/requests-module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        SharedElementsModule,
        ProcessesModule,
        PrintFormsModule,
        InlineSVGModule,
        RequestsModule,
        CalendarModule,
        BreadcrumbsModule,
    ],
    declarations: [
        OrganizationComponent,
        OrganizationEditComponent,
        OrganizationsSectionComponent,
        OrganizationMetaComponent,
        OrganizationPreviewComponent,
        OrganizationKndKindsComponent,
        OrganizationPlanSettingsComponent,
        OrganizationPlanSettingsBpmnComponent,
        OrganizationCreateMasterComponent,
        OrganizationPrintFormsComponent,
        OrganizationPlanCriteriaComponent,
        OrganizationProcessesSettingsComponent,
        OrganizationProcessComponent,
    ],
    providers: [
        OrganizationResolve,
        OrganizationsService,
    ],
    exports: [],
})

export class OrganizationsModule {
}
