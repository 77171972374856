import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestService, StorageService, ToasterService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-message',
    templateUrl: 'appeal-message.component.html',
})
export class AppealMessageComponent implements OnInit {
    @Input() public message; // обрабатываемое сообщение
    @Input() public appeal; // формируемое дело
    @Input() public mode; // режим работы

    public statuses; // возможные статусы сообщений из справочника
    public templates; // возможные шаблоны сообщений из справочника

    // Виды уведомлений
    public notificationTypes = [
        {
            code: 'call',
            name: 'Звонок',
        },
        {
            code: 'sms',
            name: 'SMS',
        },
        {
            code: 'email',
            name: 'Электронная почта',
        },
    ];

    public isProcessSelectMessageType = false; // Флаг осуществления процесса выбора типа сообщения
    public isProcessSelectMessagePerson = false; // Флаг осуществления процесса выбора участника дела
    public isProcessSelectMessageTemplate = false; // Флаг осуществления процесса выбора шаблона сообщения
    public appealPersons = []; // Массив участников дела, доступных для выбора

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public constructor(
        private storage: StorageService,
        private rest: RestService,
        private toaster: ToasterService
    ) {}

    public ngOnInit() {
        this.statuses = this.storage.getItem('messageStatuses');
        this.templates = this.storage.getItem('messageTemplates');
        if (!this.statuses) {
            const promises = [];
            promises.push(this.rest.search('catalogueMessageStatuses'));
            promises.push(this.rest.search('catalogueMessageTemplates'));

            Promise.all(promises).then((data) => {
                this.statuses = data[0];
                this.storage.setItem('messageStatuses', this.statuses);
                this.templates = data[1];
                this.storage.setItem('messageTemplates', this.templates);
            });
        }

        if (this.mode === 'edit') {
            if (this.message.type) {
                this.filterObjects();
            } else {
                this.isProcessSelectMessageType = true;
            }
        }
    }

    /**
     * Инициализация режима редактирования
     */
    public edit() {
        this.onEdit.emit(this.message);
    }

    /**
     * Инициализация применения изменений
     */
    public apply() {
        this.onApply.emit(this.message);
    }

    /**
     * Перевод сообщения в статус "Отправлен на обслужвание"
     */
    public complete() {
        const errors = [];
        if (!this.message.type) {
            errors.push('Не выбран вид оповещения');
        }
        if (!this.message.person) {
            errors.push('Не выбран участник дела');
        }
        if (!this.message.message) {
            errors.push('Не введен текст сообщения');
        }
        if (errors.length > 0) {
            if (errors.length === 1) {
                this.toaster.error(errors[0]);
            } else {
                this.toaster.html('<ul><li>' + errors.join('</li><li>') + '</li></ul>');
            }
        } else {
            const message = _.cloneDeep(this.message);
            message.status = this.statuses.find((item) => item.name === 'new');
            this.onApply.emit(message);
        }
    }

    /**
     * Удаление сообщения
     */
    public deleteEnvelope() {
        this.onDelete.emit(this.message);
    }

    /**
     * Отмена измнений
     */
    public cancel() {
        this.onApply.emit(false);
    }

    /**
     * Выбор вида оповещения
     * @param type
     */
    public selectType(type) {
        this.message.type = type;
        this.isProcessSelectMessageType = false;
        this.filterObjects();
        if (this.appealPersons.length === 1) {
            this.selectPerson(this.appealPersons[0]);
        } else {
            this.isProcessSelectMessagePerson = true;
        }
    }

    /**
     * Фильтрация объектов дела по типу сообщения
     */
    public filterObjects() {
        this.appealPersons = [];
        this.appeal.objects.forEach((object) => {
            // Если объект дела не является объектом недвижимости
            if (object.specialTypeId !== 'realty' && object.messaging) {
                const find = object.messaging.find((item) => item.code === this.message.type.code);
                if (find) {
                    this.appealPersons.push(object);
                }
            }
        });
    }

    /**
     * Выбор участника дела
     * @param person
     */
    public selectPerson(person) {
        this.message.person = {
            guid: person.guid,
            fio: person.data.person.lastName + ' ' + person.data.person.firstName + ' ' + person.data.person.middleName,
            birthDay: person.data.birthday ? person.data.birthday : null,
            mobile: person.data.person.mobile,
            phone: person.data.person.phone,
            workPhone: person.data.person.workPhone,
            email: person.data.person.email,
            header: person.header,
            shortHeader: person.shortHeader,
        };
        this.isProcessSelectMessagePerson = false;
    }

    /**
     * Выбор шаблона сообщения
     * @param template
     */
    public selectTemplate(template) {
        this.message.template = {
            id: template._id,
            name: template.name,
        };
        this.message.message = template.message;
        this.isProcessSelectMessageTemplate = false;
    }

    /**
     * Возвращает наименование статуса
     */
    public getStatusName() {
        const find = this.statuses.find((item) => item.name === this.message.status.name);
        return find ? find.displayName : '';
    }
}
