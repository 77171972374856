import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { CommonAppealDocumentCardComponent } from './components/document-card/common-appeal-document-card.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonAppealSubserviceDocumentGroupsComponent } from './components/document-groups/common-appeal-subservice-document-groups.component';
import { FilesService, RestService, ToasterService } from '@evolenta/core';
import { CommonAppealDocumentService } from './common-appeal-document.service';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonAppealSubservicesService } from '../../services/common-appeal-subservices.service';
import { PrintFormsService } from '../../../../common/services/print/print-forms.service';
import { PrintingService } from '@evolenta/printing';
import { CommonUtilities } from '@evolenta/utilities';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-documents',
    templateUrl: 'common-appeal-documents.component.html',
    styles: ['.number { padding: 0 5px; }'],
})
export class CommonAppealDocumentsComponent implements OnInit {
    @Input() public appeal: any; // Формируемое дело
    @Input() public subservice; // Услуга по которой формируется дело
    @Input() public printForms; // Массив печатных форм для дела
    @Input() public onlyGroups; // Выводить только группы указанные в этом массиве
    @Input() public task;
    @Input() public allowEdit = true;
    @Input() public byGroupTypes = false; // Выводить группы документов по типам
    @Input() public needSaveBeforePrint = false;
    @Input() public object;
    @Input() public subject;
    @Input() public showOnInit = true;
    @Input() public mode;

    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)
    @Output() public onUpdateAppealAfterSendStatusEnvelope = new EventEmitter<boolean>(); // Передача управления в родительский компонент для обновления информации по делу после отправки запроса со сменой статуса
    @Output() public isCheckFileSign = new EventEmitter<boolean>();
    @Output() public onNeedSaveBeforePrint = new EventEmitter<object>();

    public editedDocument = null; // Редактируемый документ дела
    public editedDocumentGroup = null; // Группа для редактируемого документа дела

    public activeTab; // Активная вкладка для передачи управления из режима просмотра в режим редактирования
    public prepareForPrintDocument = null; // Документ, по которому будет формироваться печатная форма

    public continueSendEnvelope = false; // Продолжить процесс отправки запроса из документа после сохранения дела

    @ViewChild('selectDocumentsModal', { static: false }) public selectDocumentsModal: TemplateRef<any>; // модальное окно для выбора документов дела
    @ViewChild('editCard', { static: false }) public editDocumentCardComponent: CommonAppealDocumentCardComponent; // Компонент - карточка редактирования документа дела
    @ViewChildren('subserviceDocumentGroups')
    public documentGroupsComponents: QueryList<CommonAppealSubserviceDocumentGroupsComponent>; // Группы документов услуг дела

    public groupsForModal; // группы документов для выбора из списка документов дела при формировании специальных печатных форм
    public modalRef: BsModalRef;

    // Параметры для выбора заявителя при формировании ПФ комплексной расписки
    public applicantsInComplexAppeal = []; // список заявителей в комплексном деле
    public selectedApplicant; // выбранный для использования в комплексной ПФ заявитель
    @ViewChild('selectApplicantModal', { static: false }) public selectApplicantModal: TemplateRef<any>; // модальное окно для выбора заявителей (доверителей)

    // Параметры для выбора данных для формирования ПФ - платежной квитанции
    public selectedPayer; // выбранный плательщик
    public selectedPayment; // выбранный платеж
    // Кастомная сумма оплаты
    public customPaymentSum = {
        rub: 0,
        kop: 0,
    };
    public rubMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    public kopMask = [/\d/, /\d/];
    @ViewChild('selectPaymentParamsModal', { static: false }) public selectPaymentParamsModal: TemplateRef<any>; // модальное окно для выбора платежа и плательщика

    public htmlPrintFormContent; // Содержимое сгенерированной html-формы
    @ViewChild('printHtmlFormModal', { static: false }) public printHtmlFormModal: TemplateRef<any>; // модальное окно для печати html-формы
    public editorOptions = {
        language: 'ru',
        height: 700,
        plugins: 'lists advlist print table code',
        toolbar: 'print | undo redo | bold italic | bullist numlist outdent indent | table | code',
    };

    public groupsByTypes = [];
    public showDocuments = true;

    public constructor(
        public documentService: CommonAppealDocumentService,
        public appealSubservicesService: CommonAppealSubservicesService,
        public appealService: CommonAppealService,
        private saveService: CommonAppealSaveService,
        private validateService: CommonAppealValidateService,
        private printFormService: PrintFormsService,
        private toaster: ToasterService,
        private filesService: FilesService,
        private progressService: NgProgress,
        private modalService: BsModalService,
        private rest: RestService,
        private printingService: PrintingService,
        private errorLoggingService: ErrorLoggingService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.printForms = this.appealService.printForms;
        this.initData();
        this.showDocuments = this.showOnInit;
    }

    public initData() {
        if (this.byGroupTypes) {
            this.initGroupsByTypes();
        }
    }

    public initGroupsByTypes() {
        this.groupsByTypes = [
            {
                code: 'isInput',
                name: 'Входящие документы',
                groups: [],
                showOnStatuses: ['draft', 'process'],
            },
            {
                code: 'isProcessing',
                name: 'Процессные документы',
                groups: [],
                showOnStatuses: ['process'],
                externalCodes: ['documentsRequests'],
            },
            {
                code: 'isOutput',
                name: 'Результирующие документы',
                groups: [],
                showOnStatuses: ['process'],
            },
        ];
        let usedGroups = this.documentService.appealSubservicesData[this.appeal.subservice.guid].appealDocumentGroups;
        if (this.object) {
            usedGroups = usedGroups.filter(item => item.appealRequirements && item.appealRequirements.byObject);
            if (
                this.subservice.documentsCommon &&
                this.subservice.documentsCommon.filterByMnemonicInObject &&
                this.object.mnemonics
            ) {
                this.filterGroupsByMnemonic(usedGroups, this.object.mnemonics);
            }
        } else if (this.subject) {
            if (
                // CIT-1479
                this.subject.parentEntries !== 'spoAppeals.subjects' &&
                this.subject.groupName !== 'Приказ на зачисление'
            ) {
                usedGroups = usedGroups.filter(
                    item =>
                        item.appealRequirements &&
                        item.appealRequirements.bySubjectType &&
                        item.appealRequirements.bySubjectType[this.subject.specialTypeId],
                );
            }

            if (
                this.subservice.documentsCommon &&
                this.subservice.documentsCommon.filterByMnemonicInSubject &&
                this.subject.mnemonics
            ) {
                this.filterGroupsByMnemonic(usedGroups, this.subject.mnemonics);
            }
        } else if (
            this.subservice.documentsCommon &&
            (!this.subservice.documentsCommon.showSubjectDocumentsInCommonList ||
                !this.subservice.documentsCommon.showObjectDocumentsInCommonList)
        ) {
            if (!this.subservice.documentsCommon.showSubjectDocumentsInCommonList) {
                usedGroups = usedGroups.filter(
                    item => item.appealRequirements && !item.appealRequirements.bySubjectType,
                );
            }
            if (!this.subservice.documentsCommon.showObjectDocumentsInCommonList) {
                usedGroups = usedGroups.filter(item => item.appealRequirements && !item.appealRequirements.byObject);
            }
        }
        this.groupsByTypes.forEach(group => {
            group.groups = usedGroups
                .filter(
                    item =>
                        (item[group.code] &&
                            (group.showOnStatuses.indexOf(this.appeal.status.code) !== -1 ||
                                this.appeal.documents.filter(document => document.groupCode === item.code).length >
                                    0)) ||
                        (group.externalCodes && group.externalCodes.indexOf(item.code) !== -1),
                )
                .map(item => item.code);
            group.allowEdit = group.showOnStatuses.indexOf(this.appeal.status.code) !== -1;
        });
    }

    public filterGroupsByMnemonic(groups, mnemonics) {
        groups = groups.filter(item => !item.mnemonic || mnemonics.indexOf(item.mnemonic) !== -1);
    }

    /**
     * Перевод отдельного документа в режим редактирования
     * @param data - {document: документ на редактирование, group: группа редактируемого документа, tab: активная вкладка}
     */
    public editDocument(data) {
        this.documentService.tempData = cloneDeep(this.documentService.data);
        this.editedDocument = data.document;
        this.editedDocumentGroup = data.group;
        if (data.tab) {
            this.activeTab = data.tab;
        }
    }

    /**
     * Применение / отмена изменений документа
     * @param data - объект вида {document: измененный документ, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - null в случае отмены изменений
     */
    public async applyDocument(data) {
        if (data) {
            // Применение изменений
            const documentIndex = this.appeal.documents.findIndex(item => item.guid === data.document.guid);
            const documentAppealData = this.documentService.generateDocumentDataToAppeal(data.document.guid);
            if (
                // CIT-1479
                data.document.parentEntries === 'spoAppeals.documents' &&
                data.document.groupName === 'Приказ на зачисление'
            ) {
                documentAppealData.groupGuid = documentAppealData.groupCode;
            }
            if (documentIndex !== -1) {
                this.appeal.documents[documentIndex] = documentAppealData; // Обновление данных документа
            } else {
                this.appeal.documents.push(documentAppealData); // Добавление документа в дело
                this.appealService.addElementGuidToMainElement(documentAppealData, 'documents');
            }
            // Передача управления в родительский компонент для продолжения операций с делом: сохранение, переход на новый статус
            if (data.continueProcessingAppeal) {
                this.onApply.emit(true);
            } else if (this.appeal._id) {
                // Сохранение данных на сервере

                try {
                    if (
                        // CIT-1479
                        data.document.parentEntries === 'spoAppeals.documents' &&
                        data.document.groupName === 'Приказ на зачисление'
                    ) {
                        this.saveService.appeal.documents = this.appeal.documents;
                        await this.saveService.saveAppeal();
                    }

                    if (data.applyForPrint) {
                        this.prepareSendPrintForm(documentAppealData);
                    } else {
                        this.toaster.success('Документ успешно сохранен');
                    }
                } catch (error) {
                    this.toaster.error(error);

                    await this.errorLoggingService.log(this.errorLoggingService.SPO, error, data);
                }
            }
        } else {
            // Отмена изменений
            this.documentService.data = cloneDeep(this.documentService.tempData);
        }

        // Если применение данных вызвано с целью печати из карточки редактирования, не закрываем ее
        if (!data || !data.applyForPrint) {
            this.documentService.tempData = null;
            this.validateService.validateAppeal(this.validateService.processValidate);
            this.editedDocument = null;
            this.editedDocumentGroup = null;
        }
    }

    /**
     * Создание печатной формы
     * @param printForm
     */
    public createDocumentFromPrintForm(printForm) {
        const docGuid = CommonUtilities.GenerateGuid();
        const document: any = {
            guid: docGuid,
            docGuid: docGuid,
            name: printForm.title || printForm.name,
            parentEntries: this.appealService.getAppealsCollection() + '.documents',
            subjects: [],
            printForm: { id: printForm._id, formType: printForm.formType, formTypeCode: printForm.formTypeCode },
        };
        if (this.appeal.status.code !== 'draft') {
            document.afterRegister = true;
        }
        this.documentService.initDocumentDataFromAppeal(document);
        this.appeal.documents.push(document);
        const documentInAppeal = this.appeal.documents.find(item => item.guid === docGuid);

        this.prepareSendPrintForm(documentInAppeal);
    }

    /**
     * КНМ необходимости выбора документов перед формированием печатной формы
     * @param document
     */
    public prepareSendPrintForm(document) {
        if (document.printForm && document.printForm.formTypeCode === 'payment' && !document.payment) {
            const needSelectPayment = this.appealService.appealPayments && this.appealService.appealPayments.length > 1;
            const needSelectPayer = this.appeal.subject.length > 1;
            //            console.log(needSelectPayment, needSelectPayer);
            if (this.appealService.appealPayments && this.appealService.appealPayments.length === 1) {
                document.payment = cloneDeep(this.appealService.appealPayments[0]);
            }
            // if (this.appeal.subjects && this.appeal.subjects.length === 1) {
            //     document.payerGuid = this.appeal.subjects[0].guid;
            // }
            if (needSelectPayment || needSelectPayer || (document.payment && document.payment.amountKop === 0)) {
                // Отображение модального окна для выбора платежей или плательщиков
                this.prepareForPrintDocument = document;
                this.selectedPayer = null;
                this.selectedPayment = null;
                this.modalRef = this.modalService.show(this.selectPaymentParamsModal, { backdrop: 'static' });
            } else {
                this.sendPrintForm(document);
            }
        } else if (this.checkPrintFormWithSelectedDocuments(document)) {
            this.prepareForPrintDocument = document;
            this.prepareSelectDocumentsForPrintForm();
        } else {
            const applicantsInComplexAppeal = this.checkMultipleApplicantsInAppeal(document);
            // Если формируется печатная форма комплексного дела и в деле несколько зявителей (доверителей)
            if (applicantsInComplexAppeal.length > 0) {
                if (applicantsInComplexAppeal.length > 1) {
                    this.prepareForPrintDocument = document; // сохраняем печатаемый документ в переменную
                    this.applicantsInComplexAppeal = this.appeal.subjects.filter(
                        item => applicantsInComplexAppeal.indexOf(item.guid) !== -1,
                    ); // выбираем из объектов дела заявителей
                    // Вызов модального окна
                    this.selectedApplicant = null;
                    this.modalRef = this.modalService.show(this.selectApplicantModal, { backdrop: 'static' });
                } else {
                    // Если один заявитель в деле, то модальное окно не нужен
                    this.sendPrintForm(document, [], applicantsInComplexAppeal[0]);
                }
            } else {
                this.sendPrintForm(document);
            }
        }
    }

    public async printAfterApplyPaymentParams() {
        const errors = [];
        if (this.appealService.appealPayments.length > 1 && !this.selectedPayment) {
            errors.push('Не выбран платеж!');
        }
        if (this.appeal.subjects.length > 1 && !this.selectedPayer) {
            errors.push('Не выбран плательщик!');
        }
        if (
            ((this.appealService.appealPayments.length === 1 && this.appealService.appealPayments[0].amountKop === 0) ||
                (this.selectedPayment && this.selectedPayment.amountKop === 0)) &&
            this.customPaymentSum.rub === 0 &&
            this.customPaymentSum.kop === 0
        ) {
            errors.push('Не заполнена сумма оплаты');
        }
        if (errors.length > 0) {
            const errorText = errors.join(' ');
            this.toaster.error(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText), this.appeal);
        } else {
            if (this.selectedPayment) {
                this.prepareForPrintDocument.payment = cloneDeep(this.selectedPayment);
            }
            if (this.selectedPayer) {
                this.prepareForPrintDocument.payerGuid = this.selectedPayer.guid;
            }
            if (this.customPaymentSum.rub !== 0 || this.customPaymentSum.kop !== 0) {
                this.prepareForPrintDocument.payment.amountKop =
                    Number(this.customPaymentSum.rub) * 100 + Number(this.customPaymentSum.kop);
            }
            this.modalRef.hide();
            await this.sendPrintForm(this.prepareForPrintDocument);
        }
    }

    /**
     * Выбор заявителя / доверителя для комплексной печатной формы
     * @param applicant
     */
    public selectApplicantForPrintForm(applicant) {
        this.selectedApplicant = applicant;
    }

    public async printAfterSelectApplicant() {
        if (!this.selectedApplicant) {
            const errorText = 'Не выбрано ни одного участника';
            this.toaster.error(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
        } else {
            this.modalRef.hide();
            await this.sendPrintForm(this.prepareForPrintDocument, [], this.selectedApplicant.guid);
        }
    }

    /**
     * Формирование файла печатной формы
     * @param document - документ
     * @param selectedDocuments - выбранные документы для спец. печатных форм (bringRegister, issueRegister)
     * @param applicantGuid - guid заявителя (доверителя) в комплексном деле (для которого формируется ПФ)
     * @returns void
     */
    public async sendPrintForm(document, selectedDocuments = [], applicantGuid = null, alwaysSend = false) {
        if (this.needSaveBeforePrint && !alwaysSend) {
            this.onNeedSaveBeforePrint.emit(document);
        } else {
            let printForm = this.printForms.find(item => item._id === document.printForm.id);
            if (!printForm) {
                printForm = document.printForm;
                printForm._id = printForm.id;
            }
            if (document.printForm && document.printForm.applicantGuid) {
                applicantGuid = document.printForm.applicantGuid;
            } else if (applicantGuid) {
                document.printForm.applicantGuid = applicantGuid;
            }
            const baseData = await this.printFormService.prepareAppealData(
                this.appeal,
                this.subservice,
                document,
                printForm,
                selectedDocuments,
                applicantGuid,
                this.task,
            );

            let responsibleOrganizationId = null;
            const additionalData = null;
            let variantData = null;
            const currentAppealSubservice = this.getServiceForPrintForm(document, printForm);
            if (currentAppealSubservice) {
                responsibleOrganizationId = currentAppealSubservice.responsibleOrganization
                    ? currentAppealSubservice.responsibleOrganization.id
                    : null;
                if (currentAppealSubservice.variantXsdData) {
                    variantData = currentAppealSubservice.variantXsdData;
                }
            }

            const checkBaseData = await this.checkEntitiesEmployeeAuthor(cloneDeep(baseData));

            this.progressService.start();
            try {
                const response: any = this.printingService.renderPrintForm(
                    printForm._id,
                    this.appeal._id,
                    'appeals',
                    checkBaseData,
                    additionalData,
                    variantData,
                    this.appeal.unit.id,
                    responsibleOrganizationId,
                );
                document.files = document.files || [];
                const findInFiles = document.files.find(item => item._id && item._id === response.file._id);
                if (!findInFiles) {
                    document.files.push(response.file);
                }
                const findInQuery = this.documentService.data[document.guid].queue.find(
                    item => item._id && item._id === response.file._id,
                );
                if (!findInQuery) {
                    this.documentService.data[document.guid].queue.push(response.file);
                }

                await this.saveService.saveAppeal();
                if (this.editedDocument) {
                    delete this.documentService.data[document.guid].isProcessGeneratePrintForm;
                    this.documentService.tempData = cloneDeep(this.documentService.data);
                } else {
                    this.toaster.success('Сформированный документ успешно добавлен в дело');
                }
                if (response.file.id.indexOf('.html') === -1) {
                    this.downloadFile(response.file._id, response.file.name);
                }
            } catch (error) {
                if (this.editedDocument) {
                    delete this.documentService.data[document.guid].isProcessGeneratePrintForm;
                    this.documentService.tempData = cloneDeep(this.documentService.data);
                }
                if (error.errorMessage) {
                    this.toaster.html(error.errorMessage);
                } else {
                    this.toaster.html(error);
                }
                await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
            } finally {
                this.progressService.done();
            }
        }
    }

    /**
     * Получение услуги, по которой формируется печатная форма
     * @param document - документ для которого формируется ПФ
     * @param printForm
     * @returns {any}
     */
    public getServiceForPrintForm(document, printForm) {
        let appealSubservice = null;
        if (this.subservice) {
            // Если дело создано по одной услуге
            appealSubservice = this.appeal.subservice;
        } else if (document && document.subserviceGuid && this.appeal.subservice.guid === document.subserviceGuid) {
            // Определение активной услуги по документу, для которого печатная форма формируется
            appealSubservice = this.appeal.subservice;
        } else if (printForm && printForm.serviceIds.length > 0) {
            // Информация о активных услугах берется из блока ServiceIds для печатной формы
            const printFormActiveServices = [];
            printForm.serviceIds.forEach(serviceId => {
                if (this.subservice.serviceId === serviceId) {
                    printFormActiveServices.push(this.subservice);
                }
            });
            if (printFormActiveServices.length === 1 && this.appeal.subservice.id === printFormActiveServices[0]._id) {
                appealSubservice = this.appeal.subservice;
            }
        }

        return appealSubservice;
    }

    /**
     * Получение числа заявителей в услуге
     * @param document
     * @returns {Array}
     */
    public checkMultipleApplicantsInAppeal(document) {
        // Если дело по комплексной услуге
        // TODO дописать поддержку комплексной услуги
        // if (!(this.appeal.subservices.length > 1 && this.printForms && this.printForms.length > 0)) {
        return [];
        // }

        /*const applicants = [];
        if (this.appeal.subservices.length > 1 && this.printForms && this.printForms.length > 0) {
            const documentPrintForm = this.printForms.find(item => item._id === document.printForm.id);
            // Если печатная форма для комплексной услуги
            if (documentPrintForm.forComplex) {
                // Выбираем заявителей (доверителей)
                this.appeal.subservices.forEach(appealSubservice => {
                    if (appealSubservice.subjects && appealSubservice.subjects.length > 0) {
                        appealSubservice.subjects.forEach(subject => {
                            if (subject.subKind && subject.subKind.type
                                && (subject.subKind.type.indexOf('applicant') !== -1 || subject.subKind.type.indexOf('principal') !== -1)
                                && applicants.indexOf(subject.guid) === -1) {
                                applicants.push(subject.guid);
                            }
                        });
                    }
                });
            }
        }

        return applicants;*/
    }

    /**
     * КНМ на то, что в при формировании печатной формы нужно выбирать документы из списка
     * @param document - документ со свойством printForm
     * @returns {boolean}
     */
    public checkPrintFormWithSelectedDocuments(document) {
        // Типы печатных форм для которых требуется выбор документов
        const types = ['bringRegister', 'issueRegister'];
        if (document.printForm && document.printForm.formType) {
            const docTypeArr = document.printForm.formType.split('.');

            return types.indexOf(docTypeArr[0]) !== -1;
        } else {
            return false;
        }
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public downloadFile(id, originalName) {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    /**
     * Подготовка групп документов и документов дела для выбора при формировании печатной формы
     */
    public prepareSelectDocumentsForPrintForm() {
        this.groupsForModal = this.documentService.prepareDocumentsForSelectInModal(this.prepareForPrintDocument);
        this.modalRef = this.modalService.show(this.selectDocumentsModal, { backdrop: 'static', class: 'modal-lg' });
    }

    /**
     * Настройка выбранных документов и передача в метод формирования печатной формы
     */
    public selectDocuments() {
        const selectedDocuments = this.documentService.getSelectedDocumentsForModal(this.groupsForModal);
        if (selectedDocuments.length === 0) {
            this.toaster.info('Не выбрано ни одного документа');
        }
        this.modalRef.hide();
        this.sendPrintForm(this.prepareForPrintDocument, selectedDocuments);
    }

    /**
     * Число выбранных из группы документов
     * @param group
     * @returns {number}
     */
    public countSelectedDocumentsInGroup(group) {
        const docs = group.documents.filter(item => item.selected);

        return docs.length;
    }

    /**
     * Удаление документа из дела
     * @param document
     */
    public deleteDocument(document) {
        const documentIndex = this.appeal.documents.findIndex(item => item.guid === document.guid);
        if (this.documentService.data[document.guid].envelope) {
            this.documentService.data[document.guid].envelope.status = 'deleted';
        }
        // Удаление документов из списка документов запроса
        Object.keys(this.documentService.data).forEach(documentGuid => {
            if (
                this.documentService.data[documentGuid].envelope &&
                this.documentService.data[documentGuid].envelope.appealDocuments &&
                this.documentService.data[documentGuid].envelope.appealDocuments.length > 0 &&
                this.documentService.data[documentGuid].envelope.appealDocuments.indexOf(document.guid) !== -1
            ) {
                this.documentService.data[documentGuid].envelope.appealDocuments.splice(
                    this.documentService.data[documentGuid].envelope.appealDocuments.indexOf(document.guid),
                    1,
                );
            }
        });
        this.appeal.documents.splice(documentIndex, 1);
        if (this.appeal._id) {
            this.saveService.saveAppeal().then(() => {
                this.afterDeleteDocument();
            });
        } else {
            this.afterDeleteDocument();
        }
    }

    /**
     * Процедуры после удаления документа из дела
     */
    public afterDeleteDocument() {
        // Корректировка данных сервисов
        this.saveService.correctServiceDataAfterDeleteAppealEntity().then(() => {
            this.toaster.success('Документ успешно удален');
        });
    }

    /**
     * Инициализация обновления дела после отправки запроса со сменой статуса
     */
    public updateAppealAfterSendStatusEnvelope() {
        this.editedDocument = null;
        this.editedDocumentGroup = null;
        this.onUpdateAppealAfterSendStatusEnvelope.emit(true);
    }

    /**
     * КНМ на возможные изменения в документе
     * @returns {boolean}
     */
    public checkDocumentChange() {
        return (
            this.editedDocument &&
            (!this.appeal.documents.find(item => item.guid === this.editedDocument.guid) ||
                !isEqual(
                    this.documentService.tempData[this.editedDocument.guid],
                    this.documentService.data[this.editedDocument.guid],
                ))
        );
    }

    public async checkEntitiesEmployeeAuthor(baseData) {
        const users = [];
        let entitieEmployeeAuthorData = [];
        baseData.Application.Entities.forEach(entitie => {
            if (get(entitie, 'Order.EmployeeAuthor', null)) {
                if (
                    !!entitie.Order.EmployeeAuthor.Name &&
                    (!entitie.Order.EmployeeAuthor.InspectorEmail || !entitie.Order.EmployeeAuthor.InspectorMobile)
                ) {
                    users.push(entitie.Order.EmployeeAuthor.Name);
                }
            }
        });
        if (users.length > 0) {
            entitieEmployeeAuthorData = await this.getEntitieEmployeeAuthorData(users);
        }
        if (entitieEmployeeAuthorData.length > 0) {
            baseData.Application.Entities.forEach(entitie => {
                if (get(entitie, 'Order.EmployeeAuthor', null)) {
                    if (
                        !!entitie.Order.EmployeeAuthor.Name &&
                        (!entitie.Order.EmployeeAuthor.InspectorEmail || !entitie.Order.EmployeeAuthor.InspectorMobile)
                    ) {
                        if (!entitie.Order.EmployeeAuthor.InspectorEmail) {
                            entitie.Order.EmployeeAuthor.InspectorEmail = entitieEmployeeAuthorData.find(
                                item => entitie.Order.EmployeeAuthor.Name === item.name,
                            ).email;
                        }
                        if (!entitie.Order.EmployeeAuthor.InspectorMobile) {
                            entitie.Order.EmployeeAuthor.InspectorMobile = entitieEmployeeAuthorData.find(
                                item => entitie.Order.EmployeeAuthor.Name === item.name,
                            ).mobile;
                        }
                    }
                }
            });
        }

        return baseData;
    }

    public async getEntitieEmployeeAuthorData(users) {
        const params = [{ field: 'name', operator: 'in', value: users }];

        return this.rest.search('users', { search: { search: params } });
    }

    public checkallowEdit(event) {
        this.isCheckFileSign.emit(event);
    }

    public get showDocumentsHeader() {
        return (
            (this.subject || this.object) &&
            this.groupsByTypes.filter(item => item.groups && item.groups.length > 0).length > 0
        );
    }
}
