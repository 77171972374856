import { Injectable } from '@angular/core';

@Injectable()
export class CommonAppealData {
    // TODO load from DB
    public static kndForms = [
        { code: '1', name: 'Выездная' },
        { code: '2', name: 'Документраная' },
        { code: '3', name: 'Документраная и выездная' },
    ];

    // TODO load from DB
    public static kndTypes = [
        { code: '0', name: 'Плановая' },
        { code: '1', name: 'Внеплановая' },
    ];

    // Статусы дела
    public static statuses = [
        {
            code: 'draft',
            name: 'Черновик',
            shortName: 'Черновик',
            theme: 'purple',
            labelText: 'ЧР',
            label: 'bg-purple-50 text-purple',
            background: 'bg-purple-300',
            border: 'border-purple-400',
            backgroundLite: 'bg-purple-50',
            borderTop: 'border-top-purple-400',
            borderLeft: 'border-left-purple-400',
            text: 'text-purple-400',
            icon: 'la la-pencil',
            img: 'pencil.svg',
        },
        {
            code: 'process',
            name: 'В обработке',
            shortName: 'В обработке',
            theme: 'primary',
            labelText: 'ОБ',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-500',
            text: 'text-primary-400',
            icon: 'la la-paper-plan-o',
            img: 'play.svg',
        },
        {
            code: 'complete',
            name: 'Завершена',
            shortName: 'Завершена',
            theme: 'success',
            labelText: 'ЗВ',
            label: 'bg-success-50 text-success',
            background: 'bg-success-300',
            backgroundLite: 'bg-success-50',
            borderTop: 'border-top-success-400',
            text: 'text-success-600',
            borderLeft: 'border-left-success-400',
            img: 'check-mark.svg',
        },
        {
            code: 'archive',
            name: 'В архиве',
            labelText: 'АР',
            shortName: 'В архиве',
            theme: 'slate',
            label: 'bg-brown-50 text-brown',
            background: 'bg-brown-300',
            backgroundLite: 'bg-brown-50',
            borderTop: 'border-top-brown-400',
            text: 'text-brown-400',
            borderLeft: 'border-left-brown-400',
        },
        {
            code: 'annulled',
            name: 'Аннулирована',
            labelText: 'АН',
            shortName: 'Аннулирована',
            theme: 'slate',
            label: 'bg-slate-50 text-slate',
            background: 'bg-slate-300',
            backgroundLite: 'bg-slate-50',
            borderTop: 'border-top-slate-400',
            text: 'text-slate-400',
            borderLeft: 'border-left-slate-400',
        },
    ];

    public static erpSendStatuses = [
        {
            code: 'processing',
            name: 'В обработке',
            background: 'bg-info-600',
        },
        {
            code: 'complete',
            name: 'Завершено',
            background: 'bg-teal-600',
        },
        {
            code: 'error',
            name: 'Ошибка',
            background: 'bg-danger-600',
        },
    ];

    /**
     * Статусы публикации паспорта КНМ на публичной витрине данных ФГИС ЕРП
     */
    public static erpPublishStatuses = [
        {
            code: 'PUBLISH',
            name: 'Опубликована',
            description: '',
        },
        {
            code: 'ASK_TO_PUBLISH',
            name: 'Ожидает публикации',
            description: '',
        },
        {
            code: 'UNPUBLISHED',
            name: 'Не опубликована',
            description: `В паспорте КНМ не заполнены обязательные для публикации поля.
                Со списком обязательных полей можно ознакомиться в руководстве пользователя ФГИС ЕРП или вида сведений СМЭВ по
                <a href="https://smev3.gosuslugi.ru/portal/inquirytype_one.jsp?id=222078&zone=fed&page=1&dTest=false" rel="nofollow noreferrer noopener" target="_blank">адресу</a>.
                Также можно обратиться в <a href="https://knd.gov.ru/help" rel="nofollow noreferrer noopener" target="_blank">Службу технической поддержки</a>, отправив обращение по электронной почте.`,
        },
        {
            code: 'ASK_TO_UNPUBLISH',
            name: 'Ожидает отзыва',
            description: '',
        },
    ];

    public static erpSendTypes = [
        {
            code: 'setPassportNotPlan',
            name: 'Первичное размещение паспорта проверки',
        },
        {
            code: 'setPassportPlan',
            name: 'Первичное размещение паспорта проверки',
        },
        {
            code: 'setPlan',
            name: 'Первичное размещение паспорта проверки',
        },
        {
            code: 'getPassportNotPlan',
            name: 'Получение паспорта проверки',
        },
        {
            code: 'getPassportNotPlanBeforeUpdate',
            name: 'Получение паспорта проверки',
        },
        {
            code: 'getPassportPlan',
            name: 'Получение паспорта проверки',
        },
        {
            code: 'updPassport',
            name: 'Обновление паспорта проверки',
        },
        {
            code: 'getPassportAfterUpdate',
            name: 'Получение паспорта проверки после обновления',
        },
    ];

    // Временные интервалы
    public static period = [
        {
            code: 'today',
            name: 'Сегодня',
        },
        {
            code: 'week',
            name: 'Неделя',
        },
        {
            code: 'month',
            name: 'Месяц',
        },
        {
            code: 'interval',
            name: 'Интервал',
        },
    ];

    // Настройка дела
    public static configurationAppeal = [
        {
            code: 'my',
            name: 'Мои дела',
        },
        {
            code: 'pr',
            name: 'Просроченные',
        },
        {
            code: 'is',
            name: 'Истекающие',
        },
    ];

    // Параметры сортировки
    public static sortElements = [
        {
            code: 'number',
            name: 'Номер',
        },
        {
            code: 'dateCreation',
            name: 'Дата создания',
        },
        {
            code: 'dateRegister',
            name: 'Дата регистрации',
        },
        {
            code: 'dateLastModification',
            name: 'Дата изменения',
        },
        {
            code: 'subServices.title',
            name: 'Услуга',
        },
    ];

    // Типы дат дела
    public static diffDates = [
        {
            id: 'dateRegister',
            text: 'Дата регистрации',
        },
        {
            id: 'dateFinish',
            text: 'Дата завершения',
        },
        {
            id: 'dateLastModification',
            text: 'Дата редактирования',
        },
        {
            id: 'dateCreation',
            text: 'Дата создания',
        },
    ];

    // Настройки отображения грида
    public static colDef = [
        {
            field: 'shortNumber',
            headerName: 'Номер',
            width: 100,
        },
        {
            field: 'dateCreation',
            headerName: 'Создано',
            filter: 'date',
        },
        {
            extType: 'buttons',
            buttons: {
                updateBtn: {
                    target: 'ais/appeal',
                    permissionEdit: true,
                    permissionView: true,
                },
                deleteBtn: {
                    permission: true,
                },
            },
        },
    ];

    // Структура панели фильтрации
    public static filtersPanelItems = [
        {
            groupId: 'main-group',
            icon: 'icon-grid-alt',
            show: true,
            data: [
                {
                    name: 'Номер КНМ',
                    filters: [
                        {
                            fields: ['shortNumber'],
                            type: 'input',
                            placeholder: 'Номер...',
                            format: {
                                byZero: true,
                                length: 8,
                            },
                        },
                    ],
                },
                {
                    name: 'Учетный номер КНМ (ЕРП)',
                    filters: [
                        {
                            fields: ['numberErp'],
                            type: 'input',
                            placeholder: 'Учетный номер КНМ (ЕРП)...',
                        },
                    ],
                },
                {
                    name: 'Процесс',
                    filters: [
                        {
                            fields: ['subservices.serviceId', 'subservices.title', 'subservices.shortTitle'],
                            type: 'input',
                            placeholder: 'Процесс или код процесса...',
                        },
                    ],
                },
                {
                    name: 'Период',
                    filters: [
                        {
                            type: 'period',
                            dates: CommonAppealData.diffDates,
                            itemName: 'text',
                            itemValue: 'id',
                            items: [
                                {
                                    code: 'today',
                                    name: 'Сегодня',
                                },
                                {
                                    code: 'week',
                                    name: 'Неделя',
                                },
                                {
                                    code: 'month',
                                    name: 'Месяц',
                                },
                                {
                                    code: 'interval',
                                    name: 'Интервал',
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Статус',
                    filters: [
                        {
                            code: 'appealStatus',
                            parent: [
                                {
                                    name: 'Активные',
                                    code: 'active',
                                    showChild: true,
                                    isDefault: true,
                                },
                                {
                                    name: 'В архиве',
                                    code: 'archive',
                                    showChild: false,
                                    isDefault: false,
                                },
                            ],
                            fields: ['status.code', 'status.mainStatusCode'],
                            type: 'checkboxes',
                            items: CommonAppealData.statuses,
                            hiddenProperty: 'isHidden',
                            itemStyleProperty: 'background',
                            itemName: 'name',
                            itemValue: 'code',
                            labelWidth: 140,
                        },
                    ],
                },
                {
                    name: 'Вид КНД',
                    filters: [
                        {
                            fields: ['subservices.kndInfo.kndKinds.code'],
                            type: 'dictionary',
                            labelField: 'name',
                            dictionary: {
                                name: 'nsiErpKnmKinds',
                                field: 'code',
                            },
                        },
                    ],
                },
                {
                    name: 'Вид КНМ',
                    filters: [
                        {
                            fields: ['subservices.kndInfo.kndType.code'],
                            type: 'checkboxes',
                            storageItem: 'nsiErpKnmTypes',
                        },
                    ],
                },
                {
                    name: 'Форма КНМ',
                    filters: [
                        {
                            fields: ['subservices.kndInfo.kndForm.code'],
                            type: 'checkboxes',
                            storageItem: 'nsiErpKnmForms',
                        },
                    ],
                },
                {
                    name: 'Контролер',
                    filters: [
                        {
                            fields: ['userCreation.login', 'userCreation.name'],
                            type: 'input',
                            placeholder: 'ФИО или логин',
                        },
                    ],
                },
                {
                    name: 'Субъект',
                    filters: [
                        {
                            fields: ['subjects.data.organization.inn', 'subjects.data.person.inn'],
                            type: 'input',
                            placeholder: 'ИНН...',
                        },
                        {
                            fields: ['subjects.data.organization.name', 'subjects.header'],
                            type: 'input',
                            placeholder: 'Наименование...',
                        },
                    ],
                },
                {
                    name: 'Объект',
                    filters: [
                        {
                            fields: ['objects.name'],
                            type: 'input',
                            placeholder: 'Наименование...',
                        },
                    ],
                },
            ],
        },
        {
            groupId: 'add-group',
            icon: 'icon-menu6',
            show: false,
            data: [
                {
                    name: 'Мои КНМ',
                    filters: [
                        {
                            fields: ['userCreation.id', 'userLastModification.id'],
                            type: 'checkbox',
                            placeholder: 'Только мои',
                            storageItem: 'user',
                            storageField: 'id',
                        },
                    ],
                },
                {
                    name: 'Под моим контролем',
                    filters: [
                        {
                            fields: ['controlOperator.id'],
                            type: 'checkbox',
                            shortName: 'Вкл.',
                            placeholder: 'КНМ под моим контролем',
                            storageItem: 'user',
                            storageField: 'id',
                        },
                    ],
                },
                // {
                //     name: 'Мои дела',
                //     filters: [
                //         {
                //             type: 'appeals',
                //             items: [
                //                 {
                //                     name: 'Только мои',
                //                     code: 'my'
                //                 },
                //                 {
                //                     name: 'Под моим контролем',
                //                     code: 'control'
                //                 }
                //             ]
                //         }
                //     ]
                // },
            ],
        },
    ];

    // Статусы, которые являются завершающими
    public static finishStatuses = [
        'archive',
        'annulled',
        'issued',
        'issuedPost',
        'issuedReturnToOGV',
        'issuedOther',
        'rejectedReceiptDocs',
    ];

    // TODO load from DB
    public static statusesTaken = [
        {
            code: '0',
            background: 'bg-purple-300',
            theme: 'purple',
        },
        {
            code: '1',
            background: 'bg-success-300',
            theme: 'success',
        },
    ];

    // Типы сведений
    public static entityTypes = {
        order: {
            code: 'order',
            name: 'Распоряжение',
        },
        act: {
            code: 'act',
            name: 'Акт КНМ',
        },
        notification: {
            code: 'notification',
            name: 'Уведомление о проведении проверки',
        },
        actNotImpossible: {
            code: 'actNotImpossible',
            name: 'Акт о невозможности проведения проверки',
        },
        requestMotivated: {
            code: 'requestMotivated',
            name: 'Мотивированный запрос',
        },
        requirementExplanation: {
            code: 'requirementExplanation',
            name: 'Требование о предоставлении пояснений',
        },
        regulation: {
            code: 'regulation',
            name: 'Предписание',
        },
        checkStatement: {
            code: 'checkStatement',
            name: 'Заявление о согласовании проведения внеплановой выездной проверки',
        },
        checkExamination: {
            code: 'checkExamination',
            name: 'Направление на экспертизу',
        },
        raidTask: {
            code: 'raidTask',
            name: 'Задание (Рейд)',
        },
        raidAct: {
            code: 'raidAct',
            name: 'Акт результатов  планового  (рейдового)  осмотра, обследования (Рейд)',
        },
        raidWarning: {
            code: 'raidWarning',
            name: 'Предостережение о недопустимости нарушения обязательных требований (Рейд)',
        },
        WarningCIPR: {
            code: 'WarningCIPR',
            name: 'Предостережение о недопустимости нарушения обязательных требований',
        },
        raidAnswer: {
            code: 'raidAnswer',
            name: 'Ответ субъекту на возражения (Рейд)',
        },
        observationDocument: {
            code: 'observationDocument',
            name: 'Организационно-распорядительный документ (Систематическое наблюдение)',
        },
        observationAct: {
            code: 'observationAct',
            name: 'Акт систематического наблюдения (Систематическое наблюдение)',
        },
        observationNotify: {
            code: 'observationNotify',
            name: 'Уведомление субъекта о систематическом наблюдении (Систематическое наблюдение)',
        },
        observationRequest: {
            code: 'observationRequest',
            name: 'Запрос документов и сведений (Систематическое наблюдение)',
        },
        observationWarning: {
            code: 'observationWarning',
            name: 'Предостережение о недопустимости нарушения обязательных требований (Систематическое наблюдение)',
        },
        observationPrescription: {
            code: 'observationPrescription',
            name: 'Предписание (Систематическое наблюдение)',
        },
        observationAnswer: {
            code: 'observationAnswer',
            name: 'Ответ субъекту на возражения (Систематическое наблюдение)',
        },
        buyOrder: {
            code: 'buyOrder',
            name: 'Приказ (распоряжение) (Контрольная закупка)',
        },
        buyNotice: {
            code: 'buyNotice',
            name: 'Заявление (извещение) прокуратуре (Контрольная закупка)',
        },
        buyAct: {
            code: 'buyAct',
            name: 'Акт проведении контрольной закупки (Контрольная закупка)',
        },
        buyActDistance: {
            code: 'buyActDistance',
            name: 'Акт о проведении дистанционной контрольной закупки (Контрольная закупка)',
        },
        proceedInitiation: {
            code: 'proceedInitiation',
            name: 'Определение о возбуждении дела об АП (Административное производство)',
        },
        proceedConducting: {
            code: 'proceedConducting',
            name: 'Определение о проведении АП (Административное производство)',
        },
        proceedInformation: {
            code: 'proceedInformation',
            name: 'Определение об истребовании сведений (Административное производство)',
        },
        proceedProtocol: {
            code: 'proceedProtocol',
            name: 'Протокол об АП (Административное производство)',
        },
        proceedProtocolMakeup: {
            code: 'proceedProtocolMakeup',
            name: 'Извещение о составлении протокола (Административное производство)',
        },
        proceedProtocolReview: {
            code: 'proceedProtocolReview',
            name: 'Извещение о рассмотрении протокола (Административное производство)',
        },
        proceedReferral: {
            code: 'proceedReferral',
            name: 'Определение о передачи дела об АП в суд (Административное производство)',
        },
        proceedDecree: {
            code: 'proceedDecree',
            name: 'Постановление по делу об АП (Административное производство)',
        },
        proceedAction: {
            code: 'proceedAction',
            name: 'Представление о принятии мер по устранению указанных причин и условий (Административное производство)',
        },
        proceedTermination: {
            code: 'proceedTermination',
            name: 'Постановление о прекращении АП (Административное производство)',
        },
        violationDecision: {
            code: 'violationDecision',
            name: 'Решение прокуратуры',
        },
        violationMeasure: {
            code: 'violationMeasure',
            name: 'Принятая мера',
        },
        damage: {
            code: 'damage',
            name: 'Ущерб охраняемой законом ценности',
        },
        nsiViolations: {
            code: 'nsiViolations',
            name: 'Нарушение',
        },
        riskEvent: {
            code: 'riskEvent',
            name: 'Событие риска',
        },
        incomingDocument: {
            code: 'incomingDocument',
            name: 'Входящий документ',
        },
        conformityConclusion: {
            code: 'conformityConclusion',
            name: 'Заключение о соответствии',
        },
        rejectConformityConclusion: {
            code: 'rejectConformityConclusion',
            name: 'Решение об отказе в выдаче заключения о соответствии',
        },
        cancellationTestResults: {
            code: 'cancellationTestResults',
            name: 'Отмена результатов проверки',
        },
        notificationVks: {
            code: 'notificationVks',
            name: 'Уведомление о назначении даты и времени проведения ВКС (проведение проверки)',
        },
        notificationVksInspectionResult: {
            code: 'notificationVksInspectionResult',
            name: 'Уведомление о назначении даты и времени проведения ВКС (обсуждение результатов проверки)',
        },
        notificationVksInspectionOpposition: {
            code: 'notificationVksInspectionOpposition',
            name: 'Уведомление о назначении даты и времени проведения ВКС (консультации по возражениям)',
        },
        actRefusalResultReview: {
            code: 'actRefusalResultReview',
            name: 'Акт об отказе ознакомления с результатами проверки',
        },
        consultationsProtocol: {
            code: 'consultationsProtocol',
            name: 'Протокол консультаций',
        },
        reasonedResponseObjection: {
            code: 'reasonedResponseObjection',
            name: 'Мотивированный ответ на возражение',
        },
        actAbsenceHead: {
            code: 'actAbsenceHead',
            name: 'Акт об отсутствии руководителя',
        },
        decisionToConductKNM: {
            code: 'decisionToConductKNM',
            name: 'Решение о проведении КНМ',
        },
        requirementToProvideDocuments: {
            code: 'requirementToProvideDocuments',
            name: 'Требование о предоставлении  документов',
        },
        subjectAct: {
            code: 'subjectAct',
            name: 'Акт о проведении КНМ',
        },
        subjectActCIPR: {
            code: 'subjectActCIPR',
            name: 'Акт о проведении КНМ ЦИПР',
        },
        informationAboutProvisionDocuments: {
            code: 'informationAboutProvisionDocuments',
            name: 'Сведения о предоставлении  документов',
        },
        resultDecision: {
            code: 'resultDecision',
            name: 'Решение по результатам проведения КНМ',
        },
        informationAboutExpertiseResults: {
            code: 'informationAboutExpertiseResults',
            name: 'Информация о результате экспертизы продукции (товаров), результатов выполненных работ, оказанных услуг',
        },
        noticeToConductKNM: {
            code: 'noticeToConductKNM',
            name: 'Уведомление о проведении КНМ',
        },
        orderToConductControlEvent: {
            code: 'orderToConductControlEvent',
            name: 'Приказ о проведении контрольного мероприятия',
        },
        requestToControlObject: {
            code: 'requestToControlObject',
            name: 'Запрос в адрес объекта контроля (Финконтроль)',
        },
        orderToChangePeriod: {
            code: 'orderToChangePeriod',
            name: 'Приказ об изменении проверяемого периода',
        },
        actToNonProvisionInfo: {
            code: 'actToNonProvisionInfo',
            name: 'Акт о непредоставлении информации',
        },
        orderToTerminateControlEvent: {
            code: 'orderToTerminateControlEvent',
            name: 'Приказ о прекращении контрольного мероприятия',
        },
        orderToSuspendControlEvent: {
            code: 'orderToSuspendControlEvent',
            name: 'Приказ о приостановлении контрольного мероприятия',
        },
        orderToExtendControlEvent: {
            code: 'orderToExtendControlEvent',
            name: 'Приказ о продлении контрольного мероприятия',
        },
        actToConductingControlEvent: {
            code: 'actToConductingControlEvent',
            name: 'Акт о проведении контрольного мероприятия',
        },
        surveyConclusion: {
            code: 'surveyConclusion',
            name: 'Заключение о проведении обследования',
        },
        orderToResumeControlEvent: {
            code: 'orderToResumeControlEvent',
            name: 'Приказ о возобновлении контрольного мероприятия',
        },
        actToConductingCounterCheck: {
            code: 'actToConductingCounterCheck',
            name: 'Акт о проведении встречной проверки',
        },
    };

    public static staticEvents = ['Назначение исполнителя', 'Отказаться', 'Взять в работу', 'Изменить исполнителя'];

    public static KnmKindEnum = {
        SAMPLING_CONTROL: 'Выборочный контроль',
        CONTROLLED_PURCHASE: 'Контрольная закупка',
        MONITORING_PURCHASE: 'Мониторинговая закупка',
        INSPECTION_VISIT: 'Инспекционный визит',
        RAID_INSPECTION: 'Рейдовый осмотр',
        VISITING_VERIFICATION: 'Выездная проверка',
        DOCUMENTARY_VERIFICATION: 'Документарная проверка',
    };
}
