<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Административное производство</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указан</em>
            <span class="text-muted ml-10">Дата: </span>
            <ng-container *ngIf="entity.date && !entity.date.formatted">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date || entity.date && entity.date.formatted">не указана</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Номер</label>
                                <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" #number="ngModel" required>
                                <div *ngIf="isProcessValidate && number.errors" class="validation-error-label">
                                    <span *ngIf="number.errors.required">Поле "Номер" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата</label>
                                <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата рассмотрения дела</label>
                                <evolenta-datepicker name="reviewDate" [large]="true" [(ngModel)]="entity.reviewDate" [valueAsString]="true" #reviewDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && reviewDate.errors" class="validation-error-label">
                                    <span *ngIf="reviewDate.errors.required">Поле "Дата рассмотрения дела" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-9">
                            <div class="form-group form-group-lg">
                                <label>Место рассмотрения дела</label>
                                <evolenta-address-gar
                                        name="reviewPlace"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.reviewPlace"
                                        required
                                        #reviewPlace="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && reviewPlace.errors" class="validation-error-label">
                                    <span *ngIf="reviewPlace.errors.required">Поле "Место рассмотрения дела" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Лицо, вынесшее постановление -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            property="employee"
                            [title]="'Лицо, вынесшее постановление'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                        <div *ngIf="isProcessValidate && employee.errors" class="validation-error-label">
                            <span *ngIf="employee.errors.nameRequired">Поле "Лицо, вынесшее постановление" обязательно для заполнения</span>
                            <span *ngIf="!employee.errors.nameRequired && employee.errors.positionRequired">Поле "Должность лица, вынесшего постановление" обязательно для заполнения</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <appeal-entity-catalogue
                                    [entity]="entity"
                                    [large]="true"
                                    [property]="'coap'"
                                    [catalogName]="'koap'"
                                    [title]="'Статьи КоАП'"
                                    [placeholder]="'Выберите статьи КоАП...'">
                                </appeal-entity-catalogue>
                                <div *ngIf="isProcessValidate && coap.errors" class="validation-error-label">
                                    <span *ngIf="coap.errors.required">Поле "Статьи КоАП" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Основные сведения</label>
                                <textarea name="basicInformation" class="form-control" [(ngModel)]="entity.basicInformation" #basicInformation="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && basicInformation.errors" class="validation-error-label">
                                    <span *ngIf="basicInformation.errors.required">Поле "Основные сведения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>ФИО Физического лица (должностного лица)</label>
                                <input type="text" name="individual" class="form-control" [(ngModel)]="entity.individual" #individual="ngModel" required>
                                <div *ngIf="isProcessValidate && individual.errors" class="validation-error-label">
                                    <span *ngIf="individual.errors.required">Поле "ФИО Физического лица (должностного лица)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Данные документа, удостоверяющего личность (серия)</label>
                                <input type="text" name="documentSeries" class="form-control" [(ngModel)]="entity.documentSeries" #documentSeries="ngModel" required>
                                <div *ngIf="isProcessValidate && documentSeries.errors" class="validation-error-label">
                                    <span *ngIf="documentSeries.errors.required">Поле "Данные документа, удостоверяющего личность (серия)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Данные документа, удостоверяющего личность (номер)</label>
                                <input type="text" name="documentNumber" class="form-control" [(ngModel)]="entity.documentNumber" #documentNumber="ngModel" required>
                                <div *ngIf="isProcessValidate && documentNumber.errors" class="validation-error-label">
                                    <span *ngIf="documentNumber.errors.required">Поле "Данные документа, удостоверяющего личность (номер)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата рождения</label>
                                <evolenta-datepicker name="birthday" [large]="true" [(ngModel)]="entity.birthday" [valueAsString]="true" #birthday="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && birthday.errors" class="validation-error-label">
                                    <span *ngIf="birthday.errors.required">Поле "Дата рождения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-9">
                            <div class="form-group form-group-lg">
                                <label>Место рождения</label>
                                <evolenta-address-gar
                                        name="placeBirth"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.placeBirth"
                                        required
                                        #placeBirth="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && placeBirth.errors" class="validation-error-label">
                                    <span *ngIf="placeBirth.errors.required">Поле "Место рождения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Адрес проживания (нахождения)</label>
                                <evolenta-address-gar
                                        name="locationAddress"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.locationAddress"
                                        required
                                        #locationAddress="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && locationAddress.errors" class="validation-error-label">
                                    <span *ngIf="locationAddress.errors.required">Поле "Адрес проживания (нахождения)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Место работы и адрес (для должностного лица)</label>
                                <evolenta-address-gar
                                        name="placeWork"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.placeWork"
                                        required
                                        #placeWork="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && placeWork.errors" class="validation-error-label">
                                    <span *ngIf="placeWork.errors.required">Поле "Место работы и адрес (для должностного лица)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Должность (для должностного лица)</label>
                                <input type="text" name="position" class="form-control" [(ngModel)]="entity.position" #position="ngModel" required>
                                <div *ngIf="isProcessValidate && position.errors" class="validation-error-label">
                                    <span *ngIf="position.errors.required">Поле "Должность (для должностного лица)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Отягчающие обстоятельства</label>
                                <textarea name="aggravating" class="form-control" [(ngModel)]="entity.aggravating" #aggravating="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && aggravating.errors" class="validation-error-label">
                                    <span *ngIf="aggravating.errors.required">Поле "Отягчающие обстоятельства" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Смягчающие обстоятельства</label>
                                <textarea name="emollients" class="form-control" [(ngModel)]="entity.emollients" #emollients="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && emollients.errors" class="validation-error-label">
                                    <span *ngIf="emollients.errors.required">Поле "Смягчающие обстоятельства" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Способы обжалования</label>
                                <textarea name="appealMethods" class="form-control" [(ngModel)]="entity.appealMethods" #appealMethods="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && appealMethods.errors" class="validation-error-label">
                                    <span *ngIf="appealMethods.errors.required">Поле "Способы обжалования" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Постановить:</label>
                                <textarea name="install" class="form-control" [(ngModel)]="entity.install" #install="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && install.errors" class="validation-error-label">
                                    <span *ngIf="install.errors.required">Поле "Постановить" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-2">
                            <div class="form-group form-group-lg">
                                <label>Размер штрафа (числом)</label>
                                <input type="number" name="fineSize" class="form-control" [(ngModel)]="entity.fineSize" #fineSize="ngModel" required>
                                <div *ngIf="isProcessValidate && fineSize.errors" class="validation-error-label">
                                    <span *ngIf="fineSize.errors.required">Поле "Размер штрафа (числом)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-10">
                            <div class="form-group form-group-lg">
                                <label>Размер штрафа (прописью)</label>
                                <input type="text" name="fineSize2" class="form-control" [(ngModel)]="entity.fineSize2" #fineSize2="ngModel" required>
                                <div *ngIf="isProcessValidate && fineSize2.errors" class="validation-error-label">
                                    <span *ngIf="fineSize2.errors.required">Поле "Размер штрафа (прописью)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
