<!-- Настройка дополнительных данных -->
<p *ngIf="metaData.commonData.allowCreateMainXsd">
    <span class="text-bold">Основной блок дополнительных полей {{!savedPlace.mainXsd ? 'не настроен' : 'настроен'}}</span>
    <span class="text-size-small anchor text-info ml-10" (click)="editXsd('mainXsd')"
          *ngIf="data.allowEdit || savedPlace.mainXsd">
                {{!savedPlace.mainXsd? 'настроить' : data.allowEdit ? 'изменить' : 'просмотреть'}}
            </span>
    <span class="text-size-small anchor text-slate ml-10" (click)="savedPlace.mainXsd = null"
          *ngIf="savedPlace.mainXsd && data.allowEdit">удалить</span>
    <evolenta-handlers
            [model]="savedPlace"
            [field]="'mainXsd'"
            [setStartEvent]="true"
    ></evolenta-handlers>
</p>
<p *ngIf="metaData.commonData.allowCreateXsd">
    <span class="text-bold">Дополнительные поля {{!savedPlace.xsd ? 'не настроены' : 'настроены'}}</span>
    <span class="text-size-small anchor text-info ml-10" (click)="editXsd('xsd')"
          *ngIf="data.allowEdit || savedPlace.xsd">
                {{!savedPlace.xsd? 'настроить' : data.allowEdit ? 'изменить' : 'просмотреть'}}
            </span>
    <span class="text-size-small anchor text-slate ml-10" (click)="savedPlace.xsd = null"
          *ngIf="savedPlace.xsd && data.allowEdit">удалить</span>
    <evolenta-handlers
            [model]="savedPlace"
            field="xsd"
            [setStartEvent]="true"
    ></evolenta-handlers>
</p>


<div class="row">
    <div class="col-md-8" *ngIf="metaData.commonData.allowTitlesSetting">
        <fieldset>
            <legend class="text-bold">Наименования</legend>
            <div class="row">
                <div class="col-md-6" *ngFor="let item of titles">
                    <div class="form-group form-group-lg">
                        <label>{{item.name}}</label>
                        <input type="text" class="form-control" [(ngModel)]="savedPlace.titles[item.code]">
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="col-md-4" *ngIf="metaData.commonData.allowMinMaxSetting">
        <fieldset>
            <legend class="text-bold">Число субъектов</legend>
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Минимум</label>
                            <input type="number" [(ngModel)]="savedPlace.min" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Максимум</label>
                            <input type="number" [(ngModel)]="savedPlace.max" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>


<fieldset *ngIf="metaData.commonData.allowSubjectTypesSettings">
    <legend class="text-bold no-margin-bottom">Используемые виды субъектов</legend>
    <table class="table table-mini">
        <thead>
        <tr>
            <td class="text-bold">Вид субъекта</td>
            <td class="text-bold text-center">разрешено добавление нового</td>
            <td class="text-bold text-center">разрешен выбор из реестра</td>
            <td class="text-bold text-center">разрешен выбор из глобального реестра</td>
            <td class="text-bold text-center">Доп. поля</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let type of subjectTypes">
            <td>
                <input type="checkbox" class="checkbox" name="subjectTypes" id="subjectType{{type.code}}"
                       [checked]="isSelectSubjectType(type)" (change)="toggleSubjectType(type)">
                <label for="subjectType{{type.code}}" class="text-bold">{{type.name}}</label>
            </td>
            <td class="text-center">
                <ng-container *ngIf="isSelectSubjectType(type)">
                    <input type="checkbox" class="checkbox" name="subjectType{{type.code}}" id="createNew{{type.code}}"
                           [checked]="isSelectSubjectType(type, true).allowCreateNew"
                           (change)="isSelectSubjectType(type, true).allowCreateNew = !isSelectSubjectType(type, true).allowCreateNew">
                    <label for="createNew{{type.code}}">&nbsp;</label>

                    <ng-container *ngIf="isSelectSubjectType(type) && isSelectSubjectType(type, true).allowCreateNew">
                        <a class="anchor text-info text-size-small" (click)="setFieldsRequirements(type)">обязательность</a>
                    </ng-container>
                </ng-container>
            </td>
            <td class="text-center">
                <ng-container *ngIf="isSelectSubjectType(type)">
                    <input type="checkbox" class="checkbox" name="subjectType{{type.code}}" id="selectFromRegistry{{type.code}}"
                           [checked]="isSelectSubjectType(type, true).allowSelectFromRegistry"
                           (change)="isSelectSubjectType(type, true).allowSelectFromRegistry = !isSelectSubjectType(type, true).allowSelectFromRegistry">
                    <label for="selectFromRegistry{{type.code}}">&nbsp;</label>
                    <a class="anchor text-info text-size-small" (click)="setRegisterFields(type)"
                       *ngIf="type !== 'individualApplicant' && isSelectSubjectType(type) && isSelectSubjectType(type, true).allowSelectFromRegistry">состав полей</a>
                </ng-container>
            </td>
            <td class="text-center">
                <ng-container *ngIf="isSelectSubjectType(type) && isSelectSubjectType(type, true).allowSelectFromRegistry">
                    <input type="checkbox" class="checkbox" name="subjectType{{type.code}}" id="selectOnlyFromGlobalRegistry{{type.code}}"
                           [checked]="isSelectSubjectType(type, true).selectOnlyFromGlobalRegistry"
                           (change)="isSelectSubjectType(type, true).selectOnlyFromGlobalRegistry = !isSelectSubjectType(type, true).selectOnlyFromGlobalRegistry">
                    <label for="selectOnlyFromGlobalRegistry{{type.code}}">&nbsp;</label>
                </ng-container>
            </td>
            <td class="text-center">
                <ng-container *ngIf="isSelectSubjectType(type)">
                    <span class="text-size-small anchor text-info ml-10" (click)="editXsd('xsd', isSelectSubjectType(type, true))"
                          *ngIf="data.allowEdit || isSelectSubjectType(type, true).xsd">
                        {{!isSelectSubjectType(type, true).xsd ? 'настроить' : data.allowEdit ? 'изменить' : 'просмотреть'}}
                    </span>
                    <span class="text-size-small anchor text-slate ml-10" (click)="isSelectSubjectType(type, true).xsd = null"
                          *ngIf="isSelectSubjectType(type, true).xsd && data.allowEdit">удалить</span>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</fieldset>

<hr class="no-margin-top" *ngIf="metaData.commonData.allowOnlyOneSetting || metaData.commonData.allowUseInMainBlockSetting">

<div class="form-group no-margin-bottom mt-20">
    <label class="text-bold text-size-small">Обработчики добавления нового субъекта:</label>
    <evolenta-handlers
            [model]="savedPlace"
            [field]="'afterAdd'"
            [setEndEvent]="true"
    ></evolenta-handlers>
</div>

<div class="form-group no-margin-bottom mt-20">
    <label class="text-bold text-size-small">Обработчики выбора субъекта из реестра: </label>
    <evolenta-handlers
            [model]="savedPlace"
            [field]="'afterSelect'"
            [setEndEvent]="true"
    ></evolenta-handlers>
</div>

<div class="checkbox-block" *ngIf="metaData.commonData.allowUseInMainBlockSetting">
    <input type="checkbox" class="checkbox" id="allowUseInMainBlockSetting"
           (change)="savedPlace.useInMainBlock = !savedPlace.useInMainBlock"
           [checked]="savedPlace.useInMainBlock">
    <label for="allowUseInMainBlockSetting">Выводить в блоке основных данных</label>
</div>

<fieldset class="mt-20">
    <legend class="text-bold no-margin-bottom">Настройки доступа для редактирования данных в различных статусах дела</legend>
    <common-standard-statuses-permissions [params]="permissionParams"
                                          [checkedTypes]="subjectTypes"
                                          [data]="savedPlace"></common-standard-statuses-permissions>
</fieldset>


<ng-template #fieldsSettingsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Настройка состава данных из реестра
            <span class="badge text-uppercase bg-primary-300 ml-10">{{getSubjectTypeName()}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <common-standard-form-fields-setting [subjectType]="usedSubjectType"
                                             [initFields]="usedSubjectTypeFields"
                                             #fieldsSettingComponent></common-standard-form-fields-setting>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="applyFields(fieldsSettingComponent)">Применить</button>
        <button type="button" class="btn btn-default" (click)="closeModal()">Отмена</button>
    </div>
</ng-template>

<ng-template #fieldsRequirementsSettingsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Настройка обязательности
            <span class="badge text-uppercase bg-primary-300 ml-10">{{getSubjectTypeName()}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <common-standard-fields-requirements [fieldsRequirements]="usedSubjectTypeFieldRequirements"
                                             [entityType]="usedSubjectType"></common-standard-fields-requirements>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="applyFieldsRequirements()">Применить</button>
        <button type="button" class="btn btn-default" (click)="closeModal()">Отмена</button>
    </div>
</ng-template>
