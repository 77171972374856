import { Component } from '@angular/core';

@Component({
    selector: 'smev-configuration-section',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['smev-configuration.component.less'],
    styles: [
        ':host { width: 100%; }',
    ],
})
export class SmevConfigurationSectionComponent {

}
