import { Injectable } from '@angular/core';
import { RestService } from '@evolenta/core';
import { FRONTEND_FILTERS_COLLECTION } from '../constants';
import { AppealData } from '../../modules/knm/appeals/appeal.data';

const mandatoryFilters = AppealData.mandatoryFilters;
@Injectable()
export class CustomFiltersService {
    public appealsActualityFilter = AppealData.appealsActualityFilter;

    public constructor(private restService: RestService) {
    }

    public async getFiltersList() {
        const allowedFilters = await this._getAllowedFilters();

        return mandatoryFilters.concat(allowedFilters);
    }

    private async _getAllowedFilters() {
        const params = {search: {search: []}};
        const filters = await this.restService.search(FRONTEND_FILTERS_COLLECTION, params);

        return filters;
    }
}
