<div class="p-20"
     *ngIf="!editedGroup && !editedCategory && !editedFieldRequirements && !editedPortalFieldsSetting">

    <ng-container *ngIf="tabs.length > 1">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of tabs">
                <li [ngClass]="{'active': activeTab == tab.code}"
                    (click)="activeTab = tab.code">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </ng-container>

    <!-- Общая информация по субъектам в виде кастомной формы  -->
    <div *ngIf="activeTab === 'subjectsInfo'">
        <common-standard-block-with-additional-data [block]="infoActiveData"
                                                    [showBlockTitle]="false"
                                                    [asPanel]="true">
        </common-standard-block-with-additional-data>
    </div>

    <!-- Группы и категории участников -->
    <ng-container *ngIf="activeTab == 'groupsAndCategories'">
        <common-standard-block-with-additional-data [block]="groupsActiveData"
                                                    [showBlockTitle]="false"
                                                    [useAdditionalData]="false">
        </common-standard-block-with-additional-data>
        <div *ngIf="groupsActiveData.entity.subjectGroups && groupsActiveData.entity.subjectGroups.length > 0">
            <ng-container *ngFor="let group of groupsActiveData.entity.subjectGroups">
                <div class="panel panel-shadow panel-small">
                    <div class="panel-heading">
                        <h5 class="panel-title pt-10"
                            [ngStyle]="{'max-width': '100%'}">
                            {{ group.name }}
                        </h5>
                        <div class="heading-elements">
                            <div class="heading-elements">
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        *ngIf="groupsActiveData.allowEdit"
                                        [title]="getAddCategoryInstance()"
                                        (click)="addCategory(group)">
                                    <i inlineSVG="assets/icon-new-item.svg"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        title="{{groupsActiveData.allowEdit ? localizations.common.edit : localizations.common.view }}"
                                        (click)="editGroup(group)">
                                    <i [inlineSVG]="groupsActiveData.allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        *ngIf="groupsActiveData.allowEdit"
                                        [title]="localizations.common.delete"
                                        (click)="deleteGroup(group)">
                                    <i inlineSVG="assets/icon-trash.svg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body no-padding"
                         *ngIf="group.children && group.children.length > 0">
                        <common-standard-subject
                            [xsdData]="xsdData"
                            [group]="group"
                            [level]="0"
                            (editCategoryClicked)="editedCategoryClicked($event)"
                            (editGroupClicked)="editedGroupClicked($event)">
                        </common-standard-subject>
                    </div>
                </div>
            </ng-container>
        </div>

        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
                *ngIf="groupsActiveData.allowEdit"
                (click)="addGroup()">
            <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
            {{ getAddGroupMessage() }}
        </button>
    </ng-container>

    <!-- Настройка условной обязательности полей -->
    <ng-container *ngIf="activeTab === 'fieldsRequirements'">
        <ng-container *ngIf="fieldsRequirementsActiveData.entity.fieldsRequirements && fieldsRequirementsActiveData.entity.fieldsRequirements.length > 0">
            <ng-container *ngFor="let field of fieldsRequirementsActiveData.entity.fieldsRequirements; let idx = index">
                <div class="panel panel-shadow panel-small">
                    <div class="panel-heading">
                        <h5 class="panel-title pt-10"
                            [ngStyle]="{'max-width': '100%'}">
                            {{ field.name }}
                            <span class="badge bg-success-300 text-uppercase bg-info ml-10">
                                {{field.subjectType === 'fl' ?
                                    localizations['appeals.subjects'].private_person :
                                        field.subjectType === 'ul' ? localizations['appeals.subjects'].legal_body :
                                            localizations['appeals.subjects'].individual_entrepreneur }}</span>
                        </h5>
                        <div class="heading-elements">
                            <div class="heading-elements">
                                <button class="btn btn-default btn-sxs"
                                        (click)="editFieldsRequirements(field)"
                                        type="button"
                                        title="{{fieldsRequirementsActiveData.allowEdit ? localizations.common.edit :
                                        localizations.common.view }}">
                                    <i [inlineSVG]="fieldsRequirementsActiveData.allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        *ngIf="fieldsRequirementsActiveData.allowEdit"
                                        [title]="localizations.common.delete"
                                        (click)="deleteFieldsRequirement(idx)">
                                    <i inlineSVG="assets/icon-trash.svg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
                *ngIf="fieldsRequirementsActiveData.allowEdit"
                (click)="addFieldsRequirements()">
            <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
            {{ localizations.standards.add_mandatory_setting }}
        </button>
    </ng-container>

    <!-- Настройка состава полей для портала  -->
    <ng-container *ngIf="activeTab === 'portalFields'">
        <ng-container *ngIf="portalFieldsActiveData.entity.portalFieldsSettings && portalFieldsActiveData.entity.portalFieldsSettings.length > 0">
            <ng-container *ngFor="let field of portalFieldsActiveData.entity.portalFieldsSettings; let idx = index">
                <div class="panel panel-shadow panel-small">
                    <div class="panel-heading">
                        <h5 class="panel-title pt-10"
                            [ngStyle]="{'max-width': '100%'}">
                            {{ field.name }}
                            <span class="badge bg-success-300 text-uppercase bg-info ml-10">
                                {{field.subjectType === 'fl' ? localizations['appeals.subjects'].private_person :
                                field.subjectType === 'ul' ? localizations['appeals.subjects'].legal_body :
                                    localizations['appeals.subjects'].individual_entrepreneur }}</span>
                        </h5>
                        <div class="heading-elements">
                            <div class="heading-elements">
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        title="{{portalFieldsActiveData.allowEdit ? localizations.common.edit: localizations.common.view }}"
                                        (click)="editPortalFieldsSetting(field)">
                                    <i [inlineSVG]="portalFieldsActiveData.allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        *ngIf="portalFieldsActiveData.allowEdit"
                                        [title]="localizations.common.delete"
                                        (click)="deletePortalFieldsSetting(idx)">
                                    <i inlineSVG="assets/icon-trash.svg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
                *ngIf="portalFieldsActiveData.allowEdit"
                (click)="addPortalFieldsSetting()">
            <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
            {{ localizations.standards.add_field_list_setting }}
        </button>
    </ng-container>

    <!-- Общие данные для субъектов -->
    <ng-container *ngIf="activeTab === 'subjectsCommon'">
        <common-standard-subjects-common [data]="commonActiveData"
                                         [metaData]="metaData"
                                         (onEditXsd)="editXsd('common', $event)">
        </common-standard-subjects-common>
    </ng-container>

</div>

<common-standard-subject-category-edit [category]="editedCategory"
                                       *ngIf="editedCategory"
                                       [allowEdit]="groupsActiveData.allowEdit"
                                       [data]="groupsActiveData.entity"
                                       (onSave)="applyCategory()"
                                       (onCancel)="cancelEdit()"
                                       (onEditXsd)="editXsd('category')">
</common-standard-subject-category-edit>

<ng-container *ngIf="editedGroup">
    <common-standard-subjects-group-edit [group]="editedGroup"
                                         [allowEdit]="groupsActiveData.allowEdit"
                                         *ngIf="editedGroup"
                                         (onSave)="applyGroup()"
                                         (onCancel)="cancelEdit()"
                                         (onEditXsd)="editXsd('group')">
    </common-standard-subjects-group-edit>
</ng-container>



<ng-container *ngIf="editedFieldRequirements">
    <common-standard-fields-requirements [fieldsRequirements]="editedFieldRequirements"
                                         [allowEdit]="fieldsRequirementsActiveData.allowEdit"
                                         (onSave)="applyFieldsRequirements()"
                                         (onCancel)="cancelEdit()">
    </common-standard-fields-requirements>
</ng-container>

<ng-container *ngIf="editedPortalFieldsSetting">
    <common-standard-form-fields-setting [setting]="editedPortalFieldsSetting"
                                           [allowEdit]="portalFieldsActiveData.allowEdit"
                                           (onSave)="applyPortalFieldsSetting()"
                                           (onCancel)="cancelEdit()">
    </common-standard-form-fields-setting>
</ng-container>
