import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppealEntityViolationDecision } from './models/appeal-entity-violation-decision';
import { AppealEntitiesService } from '../../appeal-entities.service';

/**
 * Решение прокуратуры
 */
@Component({
    selector: 'appeal-entity-violation-decision',
    templateUrl: 'appeal-entity-violation-decision.component.html',
})
export class AppealEntityViolationDecisionComponent implements OnInit {
    @Input() public entity: AppealEntityViolationDecision;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public statements = [];
    public currentStatement;

    public isSelectStatement = false;

    public constructor(
        private appealEntitiesService: AppealEntitiesService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.entity.isAgreed = !!this.entity.isAgreed;
        this.getStatements();
        this.getCurrentStatement();
    }

    public getStatements() {
        this.statements = this.appeal.subservice.entities.filter(item => item.type === 'checkStatement');
        if (!this.entity.statement && this.statements.length === 1) {
            this.entity.statement = this.statements[0].guid;
        }
    }

    public getCurrentStatement() {
        if (this.entity.statement) {
            this.currentStatement = this.statements.find(item => item.guid === this.entity.statement);
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }
}
