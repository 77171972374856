import { Injectable } from '@angular/core';
import { AccessService, HttpService, StorageService } from '@evolenta/core';
import { Config } from '../../../common/services/config';
import { CommonAppealData } from './common-appeal.data';
import { Permission } from '../../../common/services/permission';
import * as moment from 'moment';
import { settings } from '../../knm/appeals/appeals-settings';
import { SettingsService } from '../../../common/services/settings.service';

@Injectable()
export class CommonAppealStatusService {
    public appeal; // Формируемое дело
    public subservice; // Услуга, по которой формируется дело
    public complexSubservice; // Комплексная услуга
    public metaReglament;

    public activeAction; // Активное действие для дела

    public statuses = CommonAppealData.statuses; // возможные статусы

    public erpSendStatuses = CommonAppealData.erpSendStatuses;

    public defaultStatuses = this.storage.getItem('defaultStatuses'); // Набор статусов по умолчанию для дела
    public currentOrganization = this.storage.getItem('currentOrganization');

    // Настройки действий, которые требуют предварительной обработки (выбора значения из списка, заполнения данных и т.д.)
    public actionsWithAdditionalOperations = {
        rejectedReceiptDocs: {
            code: 'selectReasonOfReject',
            reference: 'rejectReasons',
            type: 'rejectReceiptDocs',
            title: 'Выбор причины отказа в приеме документов',
        },
        selectReasonOfReject: {
            code: 'selectReasonOfReject',
            reference: 'rejectReasons',
            type: 'rejectService',
            title: 'Выбор причины отказа в предоставлении услуги',
            field: 'rejectReason',
        },
        annulled: {
            code: 'selectReasonOfAnnul',
            reference: 'annulReasons',
            title: 'Выбор причины аннулирования',
        },
        consultation: {
            fields: [
                { code: 'consultationReason', name: 'Предмет консультации', type: 'input' },
                { code: 'consultationResult', name: 'Результат консультирования', type: 'textarea' },
            ],
            title: 'Параметры консультации',
        },
        selectIssuedOther: {
            fields: [{ code: 'selectIssuedOther', type: 'input', onlyValueReturn: true }],
            title: 'Особый вариант выдачи',
            field: 'issuedOther',
        },
        beforeIssued: {
            code: 'selectResultType',
            reference: 'resultTypes',
            title: 'Результат оказания услуги',
        },
        issued: {
            code: 'selectIssueType',
            reference: 'issueTypes',
            title: 'Выбор варианта выдачи результата дела',
        },
        unclaimed: {
            code: 'selectUnclaimedType',
            reference: 'unclaimedTypes',
            title: 'Действие при невостребованности',
        },
    };

    public permissions = Permission; // Описание всех полномочий системы

    // Соответствие кодов действий с полномочиями системы
    public actionsPermissions = {
        process: this.permissions.Appeal_Allow_Action_Process,
        rejectedReceiptDocs: this.permissions.Appeal_Allow_Action_RejectedReceiptDocs,
        consultation: this.permissions.Appeal_Allow_Action_Consultation,
        annulled: this.permissions.Appeal_Allow_Action_Annuled,
        beforeIssued: this.permissions.Appeal_Allow_Action_BeforeIssued,
        issued: this.permissions.Appeal_Allow_Action_Issued,
        archive: this.permissions.Appeal_Allow_Action_Archive,
        forwarded: this.permissions.Appeal_Allow_Action_Forwarded,
        returned: this.permissions.Appeal_Allow_Action_Returned,
        forwardedForIssue: this.permissions.Appeal_Allow_Action_ForwardedForIssue,
        unclaimed: this.permissions.Appeal_Allow_Action_Unclaimed,
    };

    // Статусы являющиеся конечными для дела / услуги
    public finishStatuses = [
        'consultation',
        'rejectedReceiptDocs',
        'issued',
        'annulled',
        'archive',
        'beforeIssued',
        'forwardedForIssue',
        'forwarded',
        'unclaimed',
    ];

    // Статусы выдачи дела (услуги)
    public issuedStatuses = ['issued', 'forwardedForIssue'];

    public allowEdit = true; // Признак доступности редактирования дела

    public currentExecuteAction = null; // Действие статуса, которое выполняется в данный момент (для межкомпонентного определения процесса перехода на новый статус в деле (услуге))

    public constructor(
        private storage: StorageService,
        private httpService: HttpService,
        private accessService: AccessService,
        private settingsService: SettingsService
    ) {
        this._getSettings();
    }

    private async _getSettings() {
        return await this.settingsService.load(settings, () => {});
    }

    /**
     * Очистка данных сервиса
     */
    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.complexSubservice = null;
    }

    /**
     * Инициализация данных сервиса
     * @param appeal - формируемое дело
     * @param subservice - услуга на основе которой формируется дело
     * @param complexSubservice - комплексная услуга, на основе которой формируется дело
     */
    public initData(appeal, subservice, complexSubservice = null) {
        this.activeAction = null;
        this.allowEdit = true;
        this.appeal = appeal;
        this.subservice = subservice;
        this.complexSubservice = complexSubservice;
    }

    public getStatuses() {
        if (this.metaReglament) {
            let appealStatuses;
            if (this.metaReglament.isOldVersion) {
                appealStatuses = this.metaReglament.appealStatuses;
            } else {
                const appealData = this.metaReglament.blocks.find((item) => item.code === 'appealData');
                if (appealData && appealData.appealStatuses && appealData.appealStatuses.length > 0) {
                    appealStatuses = appealData.appealStatuses;
                }
            }
            if (appealStatuses && appealStatuses.length > 0) {
                return appealStatuses.map((item) => ({
                    code: item.code,
                    name: item.name,
                    theme: item.theme,
                    background: 'bg-' + item.theme + '-300',
                }));
            }
        }

        return this.statuses;
    }

    /**
     * Инициализация массива действий по делу
     */
    public initAppealActions() {
        this.activeAction = null;
        if (!(this.appeal._id && this.accessService.hasAccess([this.permissions.Appeal_Execute_Action]))) {
            this.allowEdit = true;

            return [];
        }

        if (
            this.finishStatuses.indexOf(this.appeal.status.code) !== -1 &&
            !this.accessService.existPermission(Permission.No_Edit_Limits)
        ) {
            this.allowEdit = false;
        }

        const denyActions = ['beforeIssued', 'forwarded', 'returned', 'forwardedForIssue'];
        if (this.subservice && this.subservice.statusModel) {
            this.defaultStatuses = this.subservice.statusModel.status;
        } else {
            this.defaultStatuses = this.storage.getItem('defaultStatuses'); // Набор статусов по умолчанию для дела
        }

        const currentStatus = this.defaultStatuses.find((item) => item.code === this.appeal.status.code);
        const actions = currentStatus.actions.filter(
            (item) =>
                denyActions.indexOf(item.code) === -1 &&
                this.accessService.hasAccess([this.actionsPermissions[item.code]])
        );
        // КНМ возможности архивирования дела
        const findArchiveActionIndex = actions.findIndex((item) => item.code === 'archive');
        if (findArchiveActionIndex !== -1) {
            const waitingPeriodSendToArchive = this.storage.getItem('waitingPeriodSendToArchive');
            if (waitingPeriodSendToArchive > 0) {
                if (moment(this.appeal.status.dateStart).add(waitingPeriodSendToArchive, 'M') > moment()) {
                }
            }
            actions.splice(findArchiveActionIndex, 1);
        }
        if (actions.length) {
            this.activeAction = actions[0];
        }

        return actions;
    }

    /**
     * Инициализация массива действий для услуги дела
     * @param appealSubservice
     * @returns {Array}
     */
    public initAppealSubserviceActions(appealSubservice) {
        let actions = [];
        // Набор кодов статусов дела, при которых у дочерних услуг кнопки действий отображаться не будут
        const blockAppealStatuses = [
            'draft',
            'consultation',
            'rejectedReceiptDocs',
            'issued',
            'annulled',
            'archive',
            'unclaimed',
        ];
        // Если дело еще не зарегистрировано, действия услуг не отображаются

        if (
            blockAppealStatuses.indexOf(this.appeal.status.code) !== -1 ||
            !this.accessService.hasAccess([this.permissions.Appeal_Execute_Action])
        ) {
            return actions;
        } else {
            if (this.subservice._id !== appealSubservice.id) {
                throw new Error('Сабсервис не найден');
            }
            const statuses = this.subservice.statusModel.status;
            let currentStatus;
            // Если услуга находится в подстатусе
            if (appealSubservice.status.isSubstatus) {
                const mainStatus = statuses.find((item) => item.code === appealSubservice.status.mainStatusCode);
                // Действия основного статуса без того, который инициализирует переход в подстатусы
                const mainStatusActions = mainStatus.actions.filter((item) => !item.isStartSubstatus);
                currentStatus = mainStatus.subStatuses.find((item) => item.guid === appealSubservice.status.guid);
                actions = currentStatus.actions.concat(mainStatusActions);
                actions = this.filterActionsBySpecialParamsAndPermissions(appealSubservice, actions);
            } else {
                currentStatus = statuses.find((item) => item.code === appealSubservice.status.code);
                actions = this.filterActionsBySpecialParamsAndPermissions(appealSubservice, currentStatus.actions);
            }

            return actions;
        }
    }

    /**
     * Фильтрация действий по специальным параметрам и полномочиям (вариант выдачи результата услуги, действие "Архивирование"
     * @param appealSubservice - услуга дела
     * @param actions - массив действий
     */
    public filterActionsBySpecialParamsAndPermissions(appealSubservice, actions) {
        const result = [];

        if (actions && actions.length > 0) {
            actions.forEach((action) => {
                let allowAction = true;
                // Фильтр по полномочиям
                if (
                    (!this.actionsPermissions[action.code] &&
                        !this.accessService.hasAccess([this.permissions.Appeal_Allow_Action_Substatus])) ||
                    (this.actionsPermissions[action.code] &&
                        !this.accessService.hasAccess([this.actionsPermissions[action.code]]))
                ) {
                    allowAction = false;
                }
                // Действие архивирования возможно только для дела
                if (action.code === 'archive') {
                    allowAction = false;
                }
                // Если выдача результата оказания услуги в МФЦ и дело не в статусе "Направлено на обработку в ОГВ", то убираем action "Передача на обработку и выдачу в ОГВ"
                if (
                    appealSubservice.status.code !== 'forwarded' &&
                    appealSubservice.issueResultForm &&
                    appealSubservice.issueResultForm === 'currentUnit' &&
                    action.code === 'forwardedForIssue'
                ) {
                    allowAction = false;
                }
                // Если выдача результата оказания услуги в ОГВ, то убираем action "Передача на обработку в ОГВ" и "Возврат из ОГВ" и "Завершить рассмотрение и передать на выдачу"
                if (
                    appealSubservice.issueResultForm &&
                    appealSubservice.issueResultForm === 'otherUnit' &&
                    (action.code === 'forwarded' || action.code === 'returned' || action.code === 'beforeIssued')
                ) {
                    allowAction = false;
                }
                const find = result.find((item) => item.guid === action.guid);
                if (allowAction && !find) {
                    result.push(action);
                }
            });
        }

        return result;
    }

    /**
     * Получение параметра статуса
     * @param status - объект статуса
     * @param property - возвращаемое свойство
     * @param statuses - статусы дела
     * @returns {any}
     */
    public getStatusProperty(status: any, property: string, statuses?: any[]): any {
        statuses = statuses ? statuses : this.statuses;
        const statusCode = status.code ? status.code : status.mainStatusCode;
        const statusInfo = statuses.find((item) => item.code === statusCode);
        if (statusInfo) {
            if (property === 'background' && !statusInfo.background && statusInfo.theme) {
                return 'bg-' + statusInfo.theme + '-300';
            }
            if (property === 'shortName' && !statusInfo.shortName && statusInfo.name) {
                return statusInfo.name;
            }

            return statusInfo[property];
        } else {
            return '';
        }
    }

    public getErpSendStatusProperty(status, property) {
        const statusInfo = this.erpSendStatuses.find((item) => item.code === status.erpStatus);

        return statusInfo ? statusInfo[property] : '';
    }

    /**
     * Инициализация статуса дела
     * @param status - статус
     * @param subStatus - подстатус
     * @returns {any}
     */
    public initStatusData(status, subStatus = null) {
        const result: any = {
            guid: status.guid,
            code: status.code,
            name: status.name,
            description: status.description ? status.description : '',
            dateStart: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        };

        if (subStatus) {
            result.subStatusGuid = subStatus.guid;
            result.subStatusName = subStatus.name;
        } else if (status.subStatuses && status.subStatuses.length > 0) {
            result.subStatusGuid = null;
            result.subStatusName = null;
            // Проверяем на наличие подстатуса с автостартом
            const autoStart = status.subStatuses.find((item) => item.isAutoStart);
            if (autoStart) {
                result.subStatusGuid = autoStart.guid;
                result.subStatusName = autoStart.name;
            }
        }

        return result;
    }

    /**
     * Формирование уникального массива возможных вариантов (причин) для доп.действий смены статуса (отказ, аннулирование)
     * @param elements - список доступных для выбора элементов в поле references в описательных услугах
     * @param type - тип данных
     * @param processingSubservice - услуга дела (если переход на новый статус осуществляется для одной услуги, а не глобально для дела
     * @returns {Array}
     */
    public prepareReasonsForAction(elements, type, processingSubservice) {
        const items = [];
        if (
            !processingSubservice ||
            (processingSubservice && this.appeal.subservice.guid === processingSubservice.guid)
        ) {
            if (this.subservice._id !== this.appeal.subservice.id) {
                throw new Error('Сабсервис не найден');
            }
            if (this.subservice.references && this.subservice.references[elements]) {
                this.subservice.references[elements].forEach((reference) => {
                    if (!type || (type && reference.type === type)) {
                        const find = items.find((item) => item.code === reference.code);
                        if (!find) {
                            items.push(reference);
                        }
                    }
                });
            }
        }

        return items;
    }

    public getAppealsCollection() {
        return this.appeal.subservice.appealsCollection ? this.appeal.subservice.appealsCollection : 'appeals';
    }

    /**
     * Выполнение действия (переход на другой статус)
     * @param action - выполняемое действие
     * @param properties - объект содержащий настроечные параметры для действия (выбранные причины отказа, аннулирования и др.)
     * @param appealSubservice - услуга дела (в случае, если осуществляется выполнение действия в услуге, а не глобальное в деле)
     * @param ticket - данные талона ЭО
     */
    public executeAction(action, properties, appealSubservice = null, ticket = null) {
        const collectionName = this.getAppealsCollection();
        const params: any = {
            mainId: this.appeal._id,
            collectionName: this.getAppealsCollection(),
            unitId: this.currentOrganization['_id'],
            action: {
                nextStatusGuid: action ? action.nextStatusGuid : null,
                guidSubserviceInAppeal: appealSubservice ? appealSubservice.guid : null,
                additionActions: properties,
            },
            camundaProcessInitParams: this.appeal.dataForExecuteAction,
        };
        if (this.appeal.status.code === 'draft' && !appealSubservice && this.appeal.simplifyMode) {
            params.simplifiedExecution = true;
        }
        if (ticket) {
            params.ticket = ticket;
        }
        action.additionActions.forEach((item) => {
            if (item !== 'notValidate') {
                if (
                    ~item.indexOf('setDateField') ||
                    ~item.indexOf('moveToArchive') ||
                    ~item.indexOf('calculateIssueTerm')
                ) {
                    params.action.additionActions[item] = null;
                } else if (~item.indexOf('calculateTerm')) {
                    params.action.additionActions[item] = {};
                } else if (~item.indexOf('assignNumber')) {
                    const arr = item.split('#');
                    params.action.additionActions[arr[0]] = this.assignNumber(item);
                } else {
                    // Если это не причины отклонения (отказа)
                    //                    console.log('item', item);
                    if (!properties[item]) {
                        params.action.additionActions[item] = this[item]();
                    }
                }
            }
        });

        let urlNode = Config.mfc;
        if (collectionName !== 'appeals') {
            urlNode = 'legalbusiness/';
        }

        return this.httpService.post(Config.server + Config.api + urlNode + 'executeAction', params).then(
            (changedAppeal) => {
                if (collectionName !== 'appeals') {
                    return this.getNumber(collectionName, this.appeal._id).then(
                        (numberData) => {
                            changedAppeal = Object.assign(changedAppeal, numberData);

                            return Promise.resolve(changedAppeal);
                        },
                        (error) => {
                            return Promise.reject(error);
                        }
                    );
                } else {
                    return Promise.resolve(changedAppeal);
                }
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    public getNumber(collectionName, mainId, algorithm = 'basic') {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const params = {
            collectionName: collectionName,
            mainId: mainId,
            unitId: currentOrganization._id,
            numbersMeta: {
                number: {
                    algorithm: algorithm,
                    length: 8,
                    tag: currentOrganization._id,
                },
            },
        };

        return this.httpService.post(Config.server + Config.api + Config.mfc + 'assignNumbers', params);
    }

    /**
     * Присвоедние номера делу / консультации
     * @param item - additionalAction в action
     * @returns {any}
     */
    private assignNumber(item) {
        const params = {
            numbersMeta: {},
        };
        if (~item.indexOf('#')) {
            // Генерация номера отличного от номера дела
            const arr = item.split('#');
            const numberField = arr[1];
            params.numbersMeta[numberField] = {
                algorithm: 'basic',
                length: 8,
                tag: 'consultationNumber',
            };
        } else {
            // Генерация номера дела
            params.numbersMeta = {
                number: {
                    algorithm: 'RRYYOOOOONNNNNNNN',
                },
                shortNumber: {
                    algorithm: 'basic',
                    length: 8,
                },
            };
        }

        return params;
    }

    /**
     * Формирование объекта для блока additionalActions.calculateTerm при смене статуса
     */
    private calculateTerm() {
        const params = {
            unitId: this.currentOrganization['_id'],
            fieldName: 'datePlaneFinish',
            term: null,
        };
        if (this.subservice._id !== this.appeal.subservice.id) {
            throw new Error('Сабсервис не найден');
        }
        let condition = null; // Условие при котором действуют настройки сроков
        // КНМ условий в зависимости от выбранного варианта
        this.subservice.terms.condition.forEach((item) => {
            if (item.variantGuid) {
                if (~item.variantGuid.indexOf(this.appeal.subservice.variant.guid)) {
                    condition = item;
                }
            }
        });

        // Если условия для варианта не заданы, берем первое из списка
        if (!condition) {
            condition = this.subservice.terms.condition[0];
        }

        params.term = this.subservice.terms.term.find((item) => item.guid === condition.termGuid);

        return params;
    }
}
