import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { HandlersService } from '@evolenta/core';
import { InternalHandlersService } from '../../../../../common/services/internal-handlers.service';

@Component({
    selector: 'entity-process-element-additional-data-tab',
    template: `<dynamic-form
        #additionalData
        [xsd]="tab.xsd"
        [edit]="false"
        tabsMode="none"
        [model]="model"
        [saveInMainObject]="true"
        [textView]="textView"
        [externalData]="externalData"
        [useXsdService]="useXsdService"
        [initHandlers]="tab.xsdHandlers"
        (afterChangeForm)="runHandler()"
    >
    </dynamic-form>`,
})
export class EntityProcessElementAdditionaDataTabComponent implements OnInit {
    @Input() public savedPlace; // место, куда сохраняются доп. данные
    @Input() public tab;
    @Input() public textView = false;
    @Input() public processId;
    @Input() public externalData;
    @Input() public useXsdService = true;
    public model;
    public isProcessingValidate = false;

    @ViewChild('additionalData', { static: false }) public additionalData: DynamicFormComponent;

    public constructor(
        private handlerService: HandlersService,
        private internalHandlersService: InternalHandlersService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        let savedPlace;
        if (!this.savedPlace.xsdData) {
            this.savedPlace.xsdData = {};
        }
        savedPlace = this.savedPlace.xsdData;
        if (this.processId) {
            if (!savedPlace[this.processId]) {
                savedPlace[this.processId] = {};
            }
            savedPlace = savedPlace[this.processId];
        }
        if (!savedPlace[this.tab.tabCode ? this.tab.tabCode : this.tab.code]) {
            savedPlace[this.tab.tabCode ? this.tab.tabCode : this.tab.code] = {};
        }
        this.model = savedPlace[this.tab.tabCode ? this.tab.tabCode : this.tab.code];
        this.internalHandlersService.processingHandlers(
            this.tab.xsdHandlers,
            'startBefore',
            'onStart',
            this.externalData
        );
    }

    public checkValid() {
        this.additionalData.validate();

        if (!this.additionalData.isValid && !this.isProcessingValidate) {
            this.isProcessingValidate = true;
            // console.log('form with errors', this.additionalData.formRenderComponent.form);
            // console.log('errors', this.additionalData.getErrors());
        }

        return this.additionalData.isValid;
    }

    public runHandler() {
        if (this.tab.xsdHandlers && this.externalData) {
            const usedHandlers = this.tab.xsdHandlers.filter((item) => item.event === 'onChange');
            usedHandlers.forEach((handler) => {
                this.handlerService.runHandler(handler, this.externalData);
            });
        }
    }

    public getDataByParam(param) {
        if (this.additionalData && this.additionalData.formRenderComponent) {
            return this.additionalData.getDataByParam(param);
        }
        return null;
    }
}
