import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    RestService,
    SelectionService,
    SelectService,
    StorageService,
    ToasterService,
    UserMessagesService,
} from '@evolenta/core';
import { SelectItem } from 'ng2-select/select/select-item';
import { Router } from '@angular/router';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { CommonAppealService } from '../../../../services/common-appeal.service';
import { CommonAppealSubservicesService } from '../../../../services/common-appeal-subservices.service';
import { CommonUtilities } from '@evolenta/utilities';
import { forkJoin, from, Subscription } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';
import { ErrorLoggingService } from '../../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-subservice-common',
    templateUrl: './common-appeal-subservice-common.component.html',
    styles: ['.panel-heading .btn-sxs { margin-top: 4px; }', '.right-10 { right: 10px; }'],
})
export class CommonAppealSubserviceCommonComponent implements OnInit, OnDestroy {
    @Input() public appealSubservice; // Редактируемая услуга дела
    @Input() public subservice; // Описательная услуга для услуги дела

    @Output() public onAfterChangeVariant = new EventEmitter<boolean>();

    public data;

    public isProcessSelectReason = false;
    public isProcessSelectInspector = false;
    public isProcessSelectExpert = false;
    public isProcessSelectJointlyKno = false;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public inspectors = [];
    public organizations: SelectItem[] = [];
    public selectedJointlyKno;
    public selectedInspector;
    public selectedInspectorType;
    public inspectorTypeDefault;
    public expertTypeDefault;
    public baseSearchForInspectors = [
        {
            field: 'sprOrganizations.id',
            operator: 'eq',
            value: this.currentOrganization.id,
        },
        {
            field: 'linkRolesMnemonics.roleMnemonic',
            operator: 'eq',
            value: 'inspector',
        },
    ];
    public baseSearchForOrganizations = [];
    public editedExpert: any = {};
    public loading = false;
    public currentUser = this.storage.getItem('user');
    public subscription: Subscription;
    public externalExperts = [];

    public constructor(
        public validateService: CommonAppealValidateService,
        public appealService: CommonAppealService,
        private selectService: SelectService,
        private rest: RestService,
        private storage: StorageService,
        private userMessagesService: UserMessagesService,
        private toaster: ToasterService,
        private appealSubservicesService: CommonAppealSubservicesService,
        private selectionService: SelectionService,
        private errorLoggingService: ErrorLoggingService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];
        if (this.subservice.version === 'knd') {
            this.initKndData();
        }
    }

    public initKndData() {
        this.loading = true;
        this.subscription = forkJoin(
            from(this.rest.search('nsiErpFaceKnmType', null)),
            from(this.rest.search('organizations', { size: 3000 }))
        ).subscribe((response) => {
            const faceKnmTypeErp = response[0];
            if (faceKnmTypeErp && faceKnmTypeErp.length > 0) {
                this.inspectorTypeDefault = faceKnmTypeErp.find((item) => item.name === 'Проверяющий');
                this.expertTypeDefault = faceKnmTypeErp.find((item) => item.name === 'Эксперт');
            }
            this.organizations = this.selectService.transformForSelect('id', 'name', response[1]);
            this.loading = false;
        });

        if (
            this.appealService.appealLinksData &&
            this.appealService.appealLinksData['event'] &&
            this.appealService.appealLinksData['event'].inspectionPlansId
        ) {
            this.rest
                .find('inspectionPlans', this.appealService.appealLinksData['event'].inspectionPlansId)
                .then((result: any) => {
                    result.planProjects.forEach((project) => {
                        if (
                            this.appealService.appealLinksData['event'].planProjectsGuid === project.guid &&
                            project.externalExperts
                        ) {
                            project.externalExperts.forEach((element) => {
                                this.externalExperts.push(element);
                            });
                        }
                    });
                });
        }
    }

    public addReason(reason) {
        if (!this.appealSubservice.reasons) {
            this.appealSubservice.reasons = [];
        }
        this.appealSubservice.reasons.push({ code: reason.code, name: reason.name });
        this.isProcessSelectReason = false;
    }

    public deleteReason(reason) {
        const findIndex = this.appealSubservice.reasons.findIndex((item) => item.code === reason.code);
        if (findIndex !== -1) {
            this.appealSubservice.reasons.splice(findIndex, 1);
        }
    }

    public async applyInspector() {
        if (!this.appealService.appeal._id) {
            const errorText = 'Перед выбором инспектора нужно сохранить событие!';
            this.toaster.error(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

            return;
        }

        if (!this.selectedInspector || !this.selectedInspectorType) {
            if (!this.selectedInspector) {
                const errorText = 'Выберите инспектора';
                this.toaster.error(errorText);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
            }

            if (!this.selectedInspectorType) {
                const errorText = 'Выберите тип';
                this.toaster.error(errorText);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
            }

            return;
        }

        this.appealService.appeal.inspectors = this.appealService.appeal.inspectors || [];

        const inspectorIncluded = this.appealService.appeal.inspectors.some(
            (item) => item.id === this.selectedInspector._id
        );
        if (inspectorIncluded) {
            const errorText =
                'Инспектор ' + this.selectedInspector.name + ' уже включен в состав контролирующих инспекторов';
            this.toaster.error(errorText);
            this.selectedInspector = null;
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

            return;
        }

        this.sendMessage(this.selectedInspector);
        const length = this.appealService.appeal.inspectors.push({
            id: this.selectedInspector._id,
            name: this.selectedInspector.name,
            login: this.selectedInspector.login,
            position: this.selectedInspector.position,
        });
        this.appealService.appeal.inspectors[length - 1].type = this.selectedInspectorType;
        this.selectedInspector = null;
        this.selectedInspectorType = null;
        this.isProcessSelectInspector = false;
    }

    public addInspector(): void {
        this.selectedInspectorType = this.inspectorTypeDefault;
        this.isProcessSelectInspector = true;
    }

    public async applyJointlyKno() {
        if (!this.selectedJointlyKno) {
            const errorText = 'Выберите контрольно-надзорный орган';
            this.toaster.error(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

            return;
        }

        const jointlyKno = cloneDeep(this.selectedJointlyKno);
        if (!this.appealService.appeal.jointlyKno) {
            this.appealService.appeal.jointlyKno = [];
        }

        const knoAlreadySelected = this.appealService.appeal.jointlyKno.some((item) => {
            return item.id === jointlyKno.id;
        });
        if (knoAlreadySelected) {
            const errorText = 'Контрольно-надзорный орган ' + jointlyKno.name + ' уже выбран';
            this.toaster.error(errorText);
            this.selectedJointlyKno = null;
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

            return;
        }

        this.appealService.appeal.jointlyKno.push({
            _id: jointlyKno._id,
            id: jointlyKno.id,
            name: jointlyKno.name,
            code: jointlyKno.code,
        });
        this.selectedJointlyKno = null;
        this.isProcessSelectJointlyKno = false;
    }

    public deleteJointlyKno(index: number): void {
        this.appealService.appeal.jointlyKno.splice(index, 1);
    }

    public sendMessage(user) {
        if (this.currentUser.login === user.login) {
            return;
        }

        const params = [
            {
                name: 'userLogin',
                value: user.login,
            },
            {
                name: 'message',
                value: '',
            },
            {
                name: 'appealId',
                value: this.appealService.appeal._id,
            },
            {
                name: 'appealNumber',
                value: this.appealService.appeal.number || 'Черновик',
            },
            {
                name: 'appealSubservice',
                value: this.appealService.appeal.subservice.shortTitle,
            },
        ];

        this.userMessagesService.sendMessage('linkProcess', 'sendToUser', params);
    }

    public deleteInspector(index: number): void {
        this.appealService.appeal.inspectors.splice(index, 1);
    }

    public editExpert(editedExpert = null) {
        if (editedExpert) {
            this.editedExpert = cloneDeep(editedExpert);
        } else {
            this.editedExpert = { guid: CommonUtilities.GenerateGuid(), type: this.expertTypeDefault };
        }
        this.isProcessSelectExpert = true;
    }

    public applyExpert() {
        if (!this.appealService.appeal.experts) {
            this.appealService.appeal.experts = [];
        }
        const findIndex = this.appealService.appeal.experts.findIndex((item) => item.guid === this.editedExpert.guid);
        if (findIndex === -1) {
            this.appealService.appeal.experts.push(cloneDeep(this.editedExpert));
        } else {
            this.appealService.appeal.experts[findIndex] = cloneDeep(this.editedExpert);
        }
        this.editedExpert = {};
        this.isProcessSelectExpert = false;
    }

    public cancelExpert(): void {
        this.isProcessSelectExpert = false;
        this.editedExpert = {};
    }

    public deleteExpert(index: number): void {
        this.appealService.appeal.experts.splice(index, 1);
    }

    public toggleKndKinds(kndKind) {
        const findIndex = this.appealSubservice.kndInfo.kndKinds.findIndex((item) => item.code === kndKind.code);
        if (findIndex !== -1) {
            this.appealSubservice.kndInfo.kndKinds.splice(findIndex, 1);
        } else {
            this.appealSubservice.kndInfo.kndKinds.push(kndKind);
        }
    }

    public checkUsedKndKind(kndKind) {
        return !!this.appealSubservice.kndInfo.kndKinds.find((item) => item.code === kndKind.code);
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public async selectRegistry() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.appealService.appeal;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectPrevRegistry';
        this.selectionService.additionalData = {
            subserviceGuid: this.appealSubservice.guid,
            mainElement: this.appealSubservice.mainElement,
        };
        this.selectionService.selectType = 'one';
        await this.router.navigate(['licensing-activity', this.appealService.registersPath]);
    }
}
