import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService } from '@evolenta/core';

@Injectable()
export class StandardsResolve implements Resolve<any> {
    public constructor(
        private restService: RestService,
        private storage: StorageService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const standardId = route.params['id'];
        const isCreateAction = route.url.filter((item) => item.path === 'create');

        if (isCreateAction.length > 0) {
            // Создание
            return Promise.resolve({});
        } else if (standardId) {
            return this.restService.find('reglaments', standardId);
        } else {
            const currentOrganization = this.storage.getItem('currentOrganization');
            const params = [
                {
                    orSubConditions: [
                        {
                            field: 'unit.id',
                            operator: 'eq',
                            value: currentOrganization._id,
                        },
                        {
                            field: 'units.id',
                            operator: 'eq',
                            value: currentOrganization._id,
                        },
                    ],
                },
            ];

            return this.restService.search('reglaments', {
                search: { search: params },
                size: 20,
                sort: 'dateLastModification,DESC',
            });
        }
    }
}
