import { Component, Input, OnInit } from '@angular/core';
import { QuickRequestsService } from '../../../common/services/quick-requests.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'appeal-object-request-info',
    templateUrl: 'appeal-object-request-info.component.html',
})

export class AppealObjectRequestInfoComponent implements OnInit {
    @Input() public object;
    @Input() public requestCode;
    public localizations;

    public constructor(
        public quickRequestsService: QuickRequestsService,
        public translate: TranslateService,
    ) {}

    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'subjects',
                'standards',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

}
