import { NgModule } from '@angular/core';
import { PersonService } from '../../common/services/persons.service';
import { DeclineFioComponent } from './individual-object/components/decline-fio/decline-fio.component';
import { IndividualApplicantFormComponent } from './individual-object/components/individual-applicant-form/individual-applicant-form.component';
import { DocumentPartFormComponent } from './individual-object/components/individual-applicant-form/document-part-form/document-part-form.component';
import { AddressPartFormComponent } from './individual-object/components/individual-applicant-form/address-part-form/address-part-form.component';
import { ContactsPartFormComponent } from './individual-object/components/individual-applicant-form/contacts-part-form/contacts-part-form.component';
import { IndividualObjectOrganizationComponent } from './individual-object/components/organization/individual-object-organization.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { ModalUserComponent } from './modal-user/modal-user.component';
import { AppealObjectRequestInfoComponent } from './object-request-info/appeal-object-request-info.component';
import { EntitiesDataModule } from '../entities-data/entities-data.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ParamsComponent } from './individual-object/components/params/params.component';
import { ParamsDataSource } from './individual-object/components/params/params.datasource';
import { ParamPipe } from './individual-object/components/params/param.pipe';
import { SubjectsComponent } from './subjects.component';
import { SubjectEditComponent } from './subject-edit/subject-edit.component';
import { SubjectPreviewComponent } from './subject-preview/subject-preview.component';
import { SubjectObjectsComponent } from './subject-edit/subject-objects/subject-objects.component';
import { SubjectAppealsComponent } from './subject-edit/subject-appeals/subject-appeals.component';
import { SubjectVersionsComponent } from './subject-edit/subject-versions/subject-versions.component';
import { SubjectsResolve } from './subjects.resolve';
import { SubjectPreviewCardComponent } from './subject-preview-card/subject-preview-card.component';
import { ForeignOrganizationComponent } from './individual-object/components/foreign-organization/foreign-organization.component';
import { ControlActionsOnSubjectService } from '../../common/services/control-actions-on-subject.service';
import { SubjectDialogForControlActionsComponent } from './subject-dialog-for-control-actions/subject-dialog-for-control-actions.component';
import { ActivatedRouteService } from '../../common/services/activated-route.service';
import { SubjectFormComponent } from './subject-form/subject-form.component';
import { SubjectFormDocumentComponent } from './subject-form/document/subject-form-document.component';
import { SubjectFormAddressComponent } from './subject-form/address/subject-form-address.component';
import { SubjectFormContactsComponent } from './subject-form/contacts/subject-form-contacts.component';
import { SubjectFormOrganizationComponent } from './subject-form/organization/subject-form-organization.component';
import { SubjectFormForeignOrganizationComponent } from './subject-form/foreign-organization/subject-form-foreign-organization.component';
import { SubjectFormDeclineFioComponent } from './subject-form/decline-fio/subject-form-decline-fio.component';
import { CommonDataPartFormComponent } from './individual-object/components/individual-applicant-form/common-data-part-form/common-data-part-form.component';
import { AdditionalDataPartFormComponent } from './individual-object/components/individual-applicant-form/additional-data-part-form/additional-data-part-form.component';
import { CapitalizePipe } from '../../common/pipes/capitalize.pipe';
import { BadgeLinkComponent } from './individual-object/components/badge-link/badge-link.component';
import { AppealStatusService } from '../knm/appeals/appeal-status.service';
import { AppealSubservicesService } from '../knm/appeals/appeal-subservices.service';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        SharedElementsModule,
        EntitiesDataModule,
        InlineSVGModule,
        BreadcrumbsModule,
    ],
    declarations: [
        DeclineFioComponent,
        IndividualApplicantFormComponent,
        DocumentPartFormComponent,
        AddressPartFormComponent,
        ContactsPartFormComponent,
        IndividualObjectOrganizationComponent,
        ModalUserComponent,
        SubjectsComponent,
        SubjectEditComponent,
        SubjectPreviewComponent,
        SubjectObjectsComponent,
        AppealObjectRequestInfoComponent,
        SubjectAppealsComponent,
        SubjectVersionsComponent,
        ParamsComponent,
        ParamPipe,
        ForeignOrganizationComponent,
        SubjectDialogForControlActionsComponent,
        SubjectFormComponent,
        SubjectFormDocumentComponent,
        SubjectFormAddressComponent,
        SubjectFormContactsComponent,
        SubjectFormOrganizationComponent,
        SubjectFormForeignOrganizationComponent,
        SubjectFormDeclineFioComponent,
        CommonDataPartFormComponent,
        AdditionalDataPartFormComponent,
        BadgeLinkComponent,
        SubjectPreviewCardComponent,
    ],
    providers: [
        PersonService,
        SubjectsResolve,
        ParamsDataSource,
        ControlActionsOnSubjectService,
        ActivatedRouteService,
        CapitalizePipe,
        AppealSubservicesService,
        AppealStatusService,
    ],
    exports: [
        SubjectPreviewCardComponent,
        DocumentPartFormComponent,
        IndividualApplicantFormComponent,
        ModalUserComponent,
        SubjectPreviewComponent,
        ForeignOrganizationComponent,
        IndividualObjectOrganizationComponent,
        DeclineFioComponent,
        SubjectFormComponent,
        BadgeLinkComponent,
    ],
})
export class SubjectsModule {
}
