import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { RestService, StorageService, TranslateService } from '@evolenta/core';
import { EntityAdditionalDataComponent } from '@components/entity-additional-data/entity-additional-data.component';
import { EntityKndKindDataComponent } from '../entity-knd-kind-data/entity-knd-kind-data.component';

@Component({
    selector: 'entity-knd-kinds-data',
    templateUrl: 'entity-knd-kinds-data.component.html',
})
export class EntityKndKindsDataComponent implements OnInit {
    @Input() public entity; // объект сущности
    @Input() public kndKindIds;
    @Input() public kndKinds;
    @Input() public entityName; // коллекция, в которой хранятся данные сущности
    @Input() public entityType;

    public setupXsdData = {
        isActive: false,
        kndKind: null,
        isRegional: false,
    };

    public currentOrganization = this.storage.getItem('currentOrganization');
    public xsdModel = {};
    public localizations;

    @Output() public onReceiveKndKinds = new EventEmitter<any>();
    @Output() public onReceiveEntityType = new EventEmitter<any>();
    @Output() public onChangeData = new EventEmitter<boolean>();

    @ViewChildren('additionalData') public additionalDataComponents: QueryList<EntityAdditionalDataComponent>; // компоненты дополнительными данными для субъекта
    @ViewChild('entityKndKindData', { static: false }) public entityKndKindDataComponent: EntityKndKindDataComponent;

    public editedKndKind = null;

    public entityTypeId;
    public entityTypeName;

    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this._loadTranslations();
        this.entityTypeName = this.entityName === 'object' ? 'objectTypes' : 'subjectTypes';
        this.entityTypeId = this.entityType.id;
        if (!this.kndKinds) {
            this.getKndKinds();
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'entity-knd-kind-data']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getKndKinds() {
        if (!this.kndKinds) {
            if (
                this.currentOrganization.kndKinds &&
                this.currentOrganization.kndKinds.length > 0 &&
                this.entityTypeId
            ) {
                const kndKindIds = this.currentOrganization.kndKinds
                    .filter(
                        (item) =>
                            item[this.entityTypeName] &&
                            item[this.entityTypeName].length > 0 &&
                            item[this.entityTypeName].find((elm) => elm.id === this.entityTypeId)
                    )
                    .map((item) => item.id);
                if (kndKindIds.length > 0) {
                    this.rest
                        .search('nsiErpKnmKinds', {
                            search: { search: [{ field: '_id', operator: 'in', value: kndKindIds }] },
                        })
                        .then((kndKinds) => {
                            this.kndKinds = kndKinds;
                            this.onReceiveKndKinds.emit(this.kndKinds);
                        });
                } else {
                    this.kndKinds = [];
                    this.onReceiveKndKinds.emit(this.kndKinds);
                }
            }
        } else {
            this.kndKinds = this.kndKinds ? this.kndKinds : [];
            this.onReceiveKndKinds.emit(this.kndKinds);
        }
    }

    public edit(kndKind) {
        this.editedKndKind = kndKind;
    }

    public apply() {
        this.editedKndKind = null;
    }

    public save() {
        if (this.entityKndKindDataComponent) {
            return this.entityKndKindDataComponent.apply();
        } else {
            return Promise.resolve();
        }
    }

    public afterChangeData() {
        this.onChangeData.emit(true);
    }
}
