<ng-container *ngIf="entity[property] && multiple">
    <div class="row" >
        <div class="col-md-12">
            <label>{{title}}
                <span class="anchor text-info text-size-small ml-5" (click)="addInput()" *ngIf="!disabled">добавить</span>
            </label>
            <div class="form-group input-group" *ngFor="let item of entity[property]; index as idx; last as lst;" [ngClass]="{'input-group-lg': large, 'input-group-xs': small, 'no-margin': lst}">
                <input type="text" [(ngModel)]="item.text" class="form-control input-xs pr-30" [placeholder]="placeholder" [disabled]="disabled">
                <div class="input-group-btn">
                    <button class="btn btn-default" type="button" *ngIf="!idx" (click)="addInput()" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                    <button class="btn btn-default" type="button" *ngIf="idx" (click)="deleteInput(item)" [disabled]="disabled"><i class="icon-trash"></i></button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="entity[property] && !multiple">
    <div class="row">
        <div class="col-md-12">
            <label>{{title}}</label>
            <div class="form-group" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                <input type="text" [(ngModel)]="entity[property].text" class="form-control input-xs" [placeholder]="placeholder">
            </div>
        </div>
    </div>
</ng-container>
