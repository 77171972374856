import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@evolenta/core';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { DocumentService } from '../../document.service';

@Component({
    selector: 'appeal-document-amount',
    templateUrl: 'appeal-document-amount.component.html',
})
export class AppealDocumentAmountComponent implements OnInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документа

    public data; // описательный объект для документа
    public localizations;

    public constructor(
        public validateService: AppealValidateService,
        private documentService: DocumentService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.data = this.documentService.data[this.document.guid];
        if (!this.data.originals) {
            this.data.originals = 0;
        }
        if (!this.data.copies) {
            this.data.copies = 0;
        }
        if (!this.data.originalsSheets) {
            this.data.originalsSheets = 0;
        }
        if (!this.data.copiesSheets) {
            this.data.copiesSheets = 0;
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.documents']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getGroupOrigin(): string {
        return this.localizations['appeals.documents'].min_original.replace(
            '%s',
            this.group.origin ? this.group.origin : 0
        );
    }

    public getGroupCopy(): string {
        return this.localizations['appeals.documents'].min_original.replace(
            '%s',
            this.group.copy ? this.group.copy : 0
        );
    }

    /**
     * Изменение зависимого количества листов при изменении основного параметра
     * @param main - основной параметр: originals / copies
     * @param param - зависимый параметр: originalsSheets / copiesSheets
     */
    public change(main, param) {
        if (!this.data.isElectronicDocument) {
            if (this.data[param] < this.data[main] && this.data[param] === 0 && this.data[main] > 0) {
                this.data[param] = 1;
            }
        }
        this.updateErrors();
    }

    /**
     * Определение класса для подсказок минимального числа копий / оригиналов
     * @param type - вид копии или оригиналы
     * @param groupProperty - параметр группы, по которой определяется класс
     * @returns {string}
     */
    public getAmountClass(type, groupProperty) {
        let elementClass = this.group[groupProperty] && this.group[groupProperty] > 0 ? 'text-info' : 'text-muted';
        if (
            this.validateService.processValidate &&
            !this.validateService.validateElements.documents[this.document.guid].tabs.amount.valid
        ) {
            Object.keys(this.validateService.validateElements.documents[this.document.guid].tabs.amount.errors).forEach(
                (key) => {
                    if (key.indexOf(type) !== -1) {
                        elementClass = 'text-danger';
                    }
                }
            );
        }
        return elementClass;
    }

    /**
     * Обновление информации об ошибках
     */
    public updateErrors() {
        // Обновление информации об ошибках
        this.validateService.validateAppeal(this.validateService.processValidate);
    }
}
