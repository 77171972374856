<ng-container *ngIf="entity[property]">
    <div class="row">
        <div class="col-md-12">
            <label>
                {{title}}
                <span class="anchor text-info text-size-small ml-5" (click)="addItem()">добавить</span>
            </label>
            <div class="row" *ngFor="let item of entity[property]; index as idx; last as lst;">
                <div class="col-md-6">
                    <div class="form-group" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                        <catalogue
                            catalogName="mandatoryReqs"
                            [allowClear]="false"
                            [(ngModel)]="item.mandatoryReq"
                            [large]="large"
                            [small]="small"
                            [disabled]="true"
                            [placeholder]="'Обязательное требование или требование, установленное муниципальными правовыми актами'"
                            [title]="'Обязательное требование или требование, установленное муниципальными правовыми актами'">
                        </catalogue>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group input-group"  [ngClass]="{'input-group-lg': large, 'input-group-xs': small}">
                        <catalogue
                            catalogName="nPA"
                            [allowClear]="false"
                            [(ngModel)]="item.npa"
                            [large]="large"
                            [small]="small"
                            [disabled]="true"
                            [placeholder]="'НПА, предусматривающее установленное требование'"
                            [title]="'НПА, предусматривающее установленное требование'">
                        </catalogue>
                        <div class="input-group-btn">
                            <button class="btn btn-default" type="button" (click)="addItem()" *ngIf="!idx"><i class="icon-plus-circle2"></i></button>
                            <button class="btn btn-default" type="button" (click)="deleteItem(item)" *ngIf="idx"><i class="icon-trash"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
