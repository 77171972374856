<div class="panel panel-shadow mt-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ localizations.standards.stage_task_setting }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    *ngIf="allowEdit"
                    (click)="apply()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body">
        <!-- Основные данные -->
        <dynamic-form
            #mainData
            *ngIf="metaData.staticProcessData.taskXsd"
            [xsd]="metaData.staticProcessData.taskXsd"
            [model]="task"
            [saveInMainObject]="true"
            [disabled]="!allowEdit">
        </dynamic-form>
    </div>
</div>
