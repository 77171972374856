import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessService, StorageService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../appeal-entities.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-regulation',
    templateUrl: 'appeal-entity-regulation.component.html',
})
export class AppealEntityRegulationComponent implements OnInit {
    @Input() public entity; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public subservice; // описание услуги на основе которых формируется дело
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public isCheckPlan = false;
    public isCheckUnplan = false;
    public checkLists = [];
    public objectList = [];
    public subjectList = [];
    public editTabs = [];
    public eliminations = [];

    public acts = [];
    public currentAct;
    public currentViolation;

    public isChangeAct = false;
    public isChangeViolation = false;

    public constructor(
        public accessService: AccessService,
        public appealEntitiesService: AppealEntitiesService,
        private storage: StorageService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);

        if (this.mode === 'edit') {
            const isDemoStend = this.storage.getItem('demoStend').isDemo;
            if (isDemoStend) {
                this.initDemo();
            } else {
                this.init();
            }
            this.findViolations();
            this.initEditTabs();
            this.getActs();
            this.getCurrentAct();
            this.getCurrentViolation();
        }
    }

    public getCurrentAct() {
        if (this.entity.act) {
            this.currentAct = this.acts.find(item => item.guid === this.entity.act);
        }
    }

    public selectAct(act) {
        if (act.guid !== this.entity.act) {
            this.entity.violation = null;
            this.currentViolation = null;
        }
        this.entity.act = act.guid;
        this.getCurrentAct();
        this.isChangeAct = false;
    }

    public selectViolation(violation) {
        this.entity.violation = violation.guid;
        this.getCurrentViolation();
        this.isChangeViolation = false;
    }

    public getAddressLine(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public changeViolation() {
        this.isChangeViolation = true;
    }

    public getActs() {
        const acts = this.appeal.subservice.entities.filter(item => item.type === 'act');
        console.log('acts', acts);
        acts.forEach(act => {
            if (act.object) {
                const objectData = this.appeal.objects.find(item => item.guid === act.object);
                if (objectData) {
                    this.acts.push({
                        guid: act.guid,
                        number: act.number,
                        object: objectData,
                        violations: act.violations,
                    });
                }
            }
        });
    }

    public getCurrentViolation() {
        if (this.entity.violation) {
            this.currentViolation = this.currentAct.violations.find(item => item.guid === this.entity.violation);
        }
    }

    public getObjectByGuid(objectGuid) {
        let object;
        this.appeal.objects.forEach(subject => {
            if (subject.objects && subject.objects.length > 0) {
                const findObject = subject.objects.find(item => item.guid === objectGuid);
                if (findObject) {
                    object = findObject;
                }
            }
        });

        return object;
    }

    public initDemo() {
        if (!this.entity.number) {
            this.entity.number = this.appeal.number;
        }
        if (!this.entity.createDate) {
            this.entity.createDate = this.appeal.dateRegister;
        }
        if (!this.entity.createPlace) {
            this.entity.createPlace = this.appealEntitiesService.findObjectAddress(this.appeal);
        }
        if (!this.entity.dateNoticeElimination) {
            this.entity.dateNoticeElimination = '';
        }
    }

    public init() {
        if (!this.entity.createDate) {
            this.entity.createDate = '';
        }
        if (!this.entity.dateNoticeElimination) {
            this.entity.dateNoticeElimination = '';
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    /**
     * Установка активной вкладки
     * @param tab
     */
    public activateTab(tab) {
        this.activeTab = tab;
    }

    /**
     * Инициализация массива вкладок
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'entity',
                name: 'Данные предписания',
                active: true,
                visible: true,
            },
            {
                code: 'violations',
                name: 'Нарушения',
                active: false,
                visible: this.entity.checkLists.length > 0,
            },
        ];
    }

    /**
     * Возврат адреса в виде строки
     * @param objectGuid
     * @returns {string}
     */
    public getAddress(objectGuid) {
        const object = this.getObject(objectGuid);
        if (object.address) {
            return PrintUtilities.PrintAddressAsLine(object.address, true);
        }

        return '';
    }

    /**
     * Возврат данных субъекта
     * @param subjectGuid
     */
    public getSubject(subjectGuid) {
        const findIndex = this.subjectList.findIndex(item => item.guid === subjectGuid);
        if (findIndex !== -1) {
            return this.subjectList[findIndex];
        }
    }

    /**
     * Возврат данных объекта
     * @param objectGuid
     */
    public getObject(objectGuid) {
        const findIndex = this.objectList.findIndex(item => item.guid === objectGuid);
        if (findIndex !== -1) {
            return this.objectList[findIndex];
        }
    }

    /**
     * Получение списка субъектов рассматриваемой проверки
     */
    public getAppealSubjects() {
        this.subjectList = this.appeal.objects.map(item => {
            return {
                guid: item.guid,
                header: item.header,
                shortHeader: item.shortHeader,
            };
        });
    }

    /**
     * Получение списка объектов рассматриваемой проверки
     */
    public getAppealObjects() {
        this.appeal.objects.forEach(subject => {
            if (subject.objects && subject.objects.length) {
                subject.objects.forEach(object => {
                    this.objectList.push(object);
                });
            }
        });
    }

    /**
     * Поиск проваленных пунктов проверочного листа
     */
    public findViolations() {
        if (this.appeal.objects && this.appeal.objects.length > 0) {
            this.getAppealSubjects();
            this.getAppealObjects();
        }
        if (!this.entity.checkLists) {
            this.entity.checkLists = _
                .cloneDeep(this.appealEntitiesService.findCheckListFailure(this.appeal))
                .map(checkList => ({
                    ...checkList,
                    questions: checkList.questions.map(question => ({
                        ...question,
                        eliminationDate: question.eliminationDate || '',
                    })),
                }));
        }
    }

    public cancel() {
        this.onCancel.emit(true);
    }
}
