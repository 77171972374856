import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectService } from '@evolenta/core';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealEntityRequestMotivated } from './models/appeal-entity-request-motivated';
import { AppealData } from '../../../../appeal.data';

@Component({
    selector: 'appeal-entity-request-motivated',
    templateUrl: 'appeal-entity-request-motivated.component.html',
})
export class AppealEntityRequestMotivatedComponent implements OnInit {
    @Input() public entity: AppealEntityRequestMotivated;
    @Input() public mode;
    @Input() public appeal;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public entityTypes = AppealData.entityTypes;
    public isCheckPlan = false;
    public isCheckUnplan = false;

    public employees = [];
    public employeesForSelect = [];
    public usersList = [];
    public usersForSelect = [];

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        private selectService: SelectService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);

        this.appealEntitiesService.getUsers().then((data) => {
            this.usersList = data;

            if (this.usersList) {
                this.usersForSelect = this.selectService.transformForSelect('id', 'name', this.usersList);
            }
        });

        const order = this.appealEntitiesService.findEntity(this.appeal, this.entityTypes.order.code);
        if (order && order.employees) {
            order.employees.forEach((employee) => {
                this.employees.push({ name: employee.name, position: employee.position });
            });
            if (this.employees) {
                this.employeesForSelect = this.employees.map((item) => item.name[0]);
            }
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public selectEmployee(value) {
        const findIndex = this.usersForSelect.findIndex((item) => item.id === value.id);
        if (findIndex !== -1 && this.usersForSelect[findIndex]) {
            this.entity.employeePosition = this.usersForSelect[findIndex].position;
        } else {
            this.entity.employeePosition = '';
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
