import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService } from '@evolenta/core';
import { NsiService } from './nsi.service';

@Injectable()
export class NsiResolve implements Resolve<any> {
    public constructor(
        private nsiService: NsiService,
        private rest: RestService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.getNsiStructure(route);
    }

    /**
     * Получение основной структуры NSI справочников
     * @param route
     * @returns {any}
     */
    public getNsiStructure(route) {
        if (!this.nsiService.structure) {
            // Получение настроек структуры справочников
            return this.rest
                .search('settings', { search: { search: [{ field: 'name', operator: 'eq', value: 'nsiStructure' }] } })
                .then((data) => {
                    if (data && data.length > 0) {
                        this.nsiService.structureObject = data[0];
                    }
                    return this.getCollectionMeta(route);
                });
        } else {
            return this.getCollectionMeta(route);
        }
    }

    public getCollectionMeta(route) {
        if (!this.nsiService.collectionsMeta) {
            const params = [
                {
                    field: 'isDictionary',
                    operator: 'eq',
                    value: true,
                },
            ];
            return this.rest
                .search('collectionsMeta', {
                    search: { search: params },
                    size: 1000,
                    prj: 'dictionariesCollectionsMeta',
                })
                .then((data) => {
                    this.nsiService.collectionsMeta = [];
                    if (data && data.length > 0) {
                        this.nsiService.collectionsMeta = data;
                    }
                    if (route.url.length > 1) {
                        return this.getMainData(route);
                    } else {
                        return {};
                    }
                });
        } else {
            if (route.url.length > 1) {
                return this.getMainData(route);
            } else {
                return {};
            }
        }
    }

    public getMainData(route) {
        const collection = route.url[1].path !== 'edit' ? route.url[1].path : route.url[2].path;
        const isCreateDictionary = route.url[1].path === 'create';
        const isCreateElement = route.url[2] && route.url[2].path === 'create';
        const isEditDictionary = route.url[1].path === 'edit';
        const isEditElement = route.url[2] && route.url[2].path === 'edit';

        if (collection) {
            const elementId = route.params['elementId'];
            const versionId = route.params['versionId'];
            const parentId = route.params['parentId'];

            // Новый элемент справочника
            if (isCreateElement) {
                return Promise.resolve({ isOldVersion: false, element: {} });
            } else if (isEditElement && elementId && !versionId) {
                return this.rest.find(collection, elementId).then((element: any) => {
                    return {
                        isOldVersion: false,
                        element: element,
                    };
                });
            } else if (isCreateDictionary) {
                return {};
            } else if (isEditDictionary) {
                const dictionaryId = route.params['dictionary'];
                return this.nsiService.collectionsMeta.find((item) => item.collectionName === dictionaryId);
            } else if (!isEditDictionary && !isCreateDictionary && !isEditElement && !isEditElement) {
            }
        } else {
            return true;
        }
    }
}
