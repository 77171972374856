import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDialogComponent, RestService, SelectionService, ToasterService, TranslateService } from '@evolenta/core';
import { GridComponent } from '@evolenta/layout';
import { NsiService } from '../nsi.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'nsi-dictionary',
    templateUrl: 'nsi-dictionary.component.html',
    styleUrls: ['../../../elements-list.css'],
    styles: [
        '.navbar-title.wide, .navbar-menu.wide { width: calc(100% / 2); }',
        '.tree-item-buttons { width: 150px; }',
        '.tree-item-title { width: calc(100% - 200px); }',
    ],
})
export class NsiDictionaryComponent implements OnInit, AfterViewInit {
    public groupCode;
    public dictionary;
    public showGrid = false;

    public colDef;
    public isSelectMode = false;
    public rowSelection = '';
    public compareFields;
    public dictionaryData;
    public localizations;
    public baseUrl: string;

    @ViewChild('gridElement', { static: false }) public grid: GridComponent;
    @ViewChild('deleteModal', { static: false }) public modalDialogComponent: ModalDialogComponent;

    public deletedElement;
    public globalSearch;

    public constructor(
        public selectionService: SelectionService,
        private route: ActivatedRoute,
        private nsiService: NsiService,
        private router: Router,
        private restService: RestService,
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.url.subscribe((urlPath) => {
            this.baseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.url.subscribe((data) => {
            const groupCode = data[0].path;
            const collectionName = data[1].path;
            const group = this.nsiService.structure.find((item) => item.code === groupCode);

            this.dictionary = this.nsiService.collectionsMeta.find((item) => item.collectionName === collectionName);
            this.groupCode = group ? groupCode : this.dictionary.dictionaryParams.section;

            if (!group) {
                this.router.navigate([this.baseUrl, this.groupCode, collectionName], { replaceUrl: true });
            }

            if (!this.dictionary.dictionaryParams.isMultiLevelsDictionary) {
                this.colDef = this.dictionary.dictionaryParams.colDef
                    ? this.dictionary.dictionaryParams.colDef
                    : _.cloneDeep(this.nsiService.defaultGridColumnDefinition);
                if (this.selectionService.isProcessSelect) {
                    this.compareFields = this.selectionService.compareFields;
                    this.rowSelection = 'multiple';
                } else {
                    this.nsiService.addButtons(
                        this.colDef,
                        this.groupCode,
                        this.dictionary.collectionName,
                        this.dictionary.dictionaryParams.allowEdit,
                        this.dictionary.dictionaryParams.allowDelete
                    );
                }
            } else {
                this.getMultiLevelsDictionaryData();
            }
        });
    }
    private _loadTranslations() {
        this.translate.get(['common', 'dictionaries']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public ngAfterViewInit() {
        if (this.dictionary && !this.dictionary.dictionaryParams.isMultiLevelsDictionary) {
            setTimeout(() => {
                this.showGrid = true;
            }, 1000);
        }
    }

    public createElement() {
        this.router.navigate(['/nsi/' + this.groupCode + '/' + this.dictionary.collectionName + '/create']);
    }

    public backToOperation() {
        this.router.navigate(this.selectionService.transferBackUrl);
    }

    public getMultiLevelsDictionaryData() {
        this.restService.search(this.dictionary.collectionName, { size: 1000 }).then((data) => {
            this.dictionaryData = this.prepareMultiLevelsData(data);
            this.searchElements();
        });
    }

    public prepareMultiLevelsData(data, parentId = null) {
        const elements = data.filter(
            (item) => (parentId && item.parentId === parentId) || (!parentId && !item.parentId)
        );

        elements.forEach((element) => {
            element.children = this.prepareMultiLevelsData(data, element._id);
        });
        return elements;
    }

    public editElement(element) {
        this.router.navigate([
            '/nsi/' + this.groupCode + '/' + this.dictionary.collectionName + '/edit/' + element._id,
        ]);
    }

    public addChildElement(parent) {
        this.router.navigate([
            '/nsi/' + this.groupCode + '/' + this.dictionary.collectionName + '/create/' + parent._id,
        ]);
    }

    public deleteElement(element, list, index) {
        this.deletedElement = { element: element, list: list, index: index };
        this.modalDialogComponent.showModal();
    }

    public answerHandler(answer) {
        if (answer === 'yes') {
            this.restService.remove(this.dictionary.collectionName, this.deletedElement.element).then(() => {
                this.deletedElement.list.splice(this.deletedElement.index, 1);
                this.toaster.success('Элемент удален');
            });
        }
    }

    public searchElements() {
        this.checkFindedElements(this.dictionaryData);
        this.generateVisibility(this.dictionaryData);
    }

    /**
     * Отметка подходимости под поисковое значение
     * @param elements
     */
    public checkFindedElements(elements) {
        const searchText = this.globalSearch;
        elements.forEach((element) => {
            if (!searchText) {
                element.used = true;
            } else {
                element.used = element.name && element.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
            }
            if (element.used) {
                delete element.halfVisible;
            }
            if (element.children && element.children.length > 0) {
                this.checkFindedElements(element.children);
            }
        });
    }

    /**
     * Управление видимостью элементов
     * @param elements
     * @returns {boolean}
     */
    public generateVisibility(elements) {
        let existVisible = false;

        elements.forEach((element) => {
            element.visible = element.used;
            if (element.children && element.children.length > 0) {
                this.generateVisibility(element.children);
            }
            if (!element.visible && element.children && element.children.length > 0) {
                element.halfVisible = this.generateVisibility(element.children);
            }
            if (element.visible) {
                existVisible = true;
            }
        });

        return existVisible;
    }
}
