import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDialogComponent, RestService, ToasterService, TranslateService } from '@evolenta/core';
import { OrganizationsService } from '../organizations.service';

@Component({
    selector: 'organization-preview',
    templateUrl: './organization-preview.component.html',
    styles: ['.no-box-shadow { box-shadow: none; }', '.panel-preview .panel-heading { height: auto; }'],
})
export class OrganizationPreviewComponent implements OnInit {
    @Input() public organization: any;
    @Input() public mode = 'full';

    @Output() public onDelete = new EventEmitter();

    /** Родительская организация */
    public parentOrganization;
    /** Базовый аддрес */
    public baseAddress;
    /** Составные части базового адреса */
    public addressParts;
    /** Часть строки, которая осталась нераспознанной по структуре адреса */
    public unrecognizablePart = '';
    /** Состав панели предпросмотра */
    public panels = {
        common: true,
    };

    @ViewChild('deleteModal', { static: false }) public modalDialogComponent: ModalDialogComponent;
    public modalMessage;
    public localizations;

    public constructor(
        private restService: RestService,
        private orgService: OrganizationsService,
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        if (this.organization.parentId) {
            this.parentOrganization = this.getParentOrganization(this.organization.parentId);
        }
        if (this.organization.baseAddress && this.organization.baseAddress.addressParts.length > 0) {
            this.addressParts = this.organization.baseAddress.addressParts;
            this.baseAddress = this.parseBaseAddress(true);
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Поиск родительской организации
     * @param id
     */
    public getParentOrganization(id) {
        let parentOrganization;
        const searchParams = [{ field: '_id', operator: 'eq', value: id }];
        parentOrganization = this.restService.search('organizations', {
            search: { search: searchParams },
        });
        if (parentOrganization) {
            return parentOrganization.shortName;
        }
    }

    /**
     * Обработка базового адреса
     * @param addProblemPartToInput
     */
    public parseBaseAddress(addProblemPartToInput = false) {
        let parseStr = '';
        this.addressParts.forEach((item, index) => {
            const shortName = item.userShortName ? item.userShortName : item['shortname'] ? item['shortname'] : '';
            if (!item.invertOrder) {
                parseStr += shortName + ' ';
            }
            parseStr += item.offname;
            if (item.invertOrder) {
                parseStr += ' ' + shortName;
            }
            if (index !== this.addressParts.length - 1) {
                parseStr += ', ';
            }
        });

        if (addProblemPartToInput && this.unrecognizablePart) {
            if (parseStr !== '') {
                parseStr += ', ';
            }
            parseStr += this.unrecognizablePart;
        }
        return parseStr;
    }

    /**
     * Получение свойства статуса
     * @param status - код статуса
     * @param property - необходимое свойство
     */
    public getStatusProperty(status, property) {
        return this.orgService.getStatusProperty(status, property);
    }

    public deleteOrganization() {
        this.modalMessage = 'Вы уверены что хотите удалить организацию?';
        this.modalDialogComponent.showModal();
    }

    public answerHandler(action) {
        if (action === 'yes') {
            // Нажатие кнопки "Да"
            this.restService.remove('organizations', this.organization).then(
                (response) => {
                    console.log(response);
                    this.toaster.success('Элемент успешно удален.');
                    this.onDelete.emit();
                },
                (error) => {
                    console.log('ошибка', error);
                }
            );
        }
    }
}
