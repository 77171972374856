import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
    AccessService,
    EntityVersionsService,
    FiltersService,
    RestService,
    SelectionService,
    StorageService,
} from '@evolenta/core';
import { Permission } from '../../common/services/permission';
import * as _ from 'lodash-es';
import { SubjectsComponent } from './subjects.component';

@Injectable()
export class SubjectsResolve implements Resolve<any> {
    public permissions = Permission;

    public onlyKnoSubjectAction = false;
    public globalSubjectAction = false;
    public isCreateAction = false;

    public constructor(
        private restService: RestService,
        private entityVersionsService: EntityVersionsService,
        private accessService: AccessService,
        private storage: StorageService,
        private filtersService: FiltersService,
        private selectionService: SelectionService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.isCreateAction = route.url.filter((item) => item.path === 'create').length > 0;
        this.onlyKnoSubjectAction = route.parent.url.filter((item) => item.path === 'kno').length > 0;
        this.globalSubjectAction = route.parent.url.filter((item) => item.path === 'global').length > 0;
        return this.getSearchParams(route);
    }

    public getSearchParams(route) {
        const collection = this.onlyKnoSubjectAction ? 'subjectsKno' : 'subjects';
        if (!this.filtersService.collectionsSearchParams[collection]) {
            return this.restService
                .search('collectionsMeta', {
                    search: { search: [{ field: 'name', operator: 'eq', value: collection }] },
                })
                .then((data) => {
                    const collectionData = data[0];
                    this.filtersService.collectionsSearchParams[collection] = {
                        useExternalSearch: !!(collectionData.searchInfo && collectionData.searchInfo.enableSearch),
                        textSearchFields:
                            collectionData.searchInfo && collectionData.searchInfo.textSearchFields
                                ? collectionData.searchInfo.textSearchFields
                                : [],
                    };
                    return this.getRiskCategories(route);
                });
        } else {
            return this.getRiskCategories(route);
        }
    }

    public getRiskCategories(route) {
        const riskCategories = this.storage.getItem('riskCategories');
        if (!riskCategories) {
            return this.restService.search('nsiErpRiskCategories').then((settings) => {
                if (settings.length > 0) {
                    this.storage.setItem('riskCategories', settings);
                }
                return this.getData(route);
            });
        } else {
            return this.getData(route);
        }
    }

    public getData(route) {
        const subjectId = route.params['subjectId'];
        const versionId = route.params['versionId'];

        // Новый субъект
        if (this.isCreateAction) {
            return Promise.resolve({ isOldVersion: false, person: {}, appeals: [] });
        } else if (subjectId) {
            // Редактирование субъекта
            // Текущая версия субъекта
            const knoMode = route.parent.url.some((item) => item.path === 'kno');
            const collection = knoMode ? 'subjectsKno' : 'subjects';

            if (subjectId && !versionId) {
                return this.restService.find(collection, subjectId).then((subject: any) => {
                    if (
                        subject &&
                        subject.appeals &&
                        subject.appeals.length > 0 &&
                        this.accessService.hasAccess([this.permissions.Appeals_Search])
                    ) {
                        const appealIds = [];
                        subject.appeals.forEach((appeal) => {
                            appealIds.push(appeal.id);
                        });
                        return this.restService
                            .search('appeals', {
                                search: { search: [{ field: '_id', operator: 'in', value: appealIds }] },
                                prj: 'appealList',
                                size: 1000,
                                sort: 'dateLastModification,DESC',
                            })
                            .then((appeals) => {
                                return {
                                    isOldVersion: false,
                                    subject: subject,
                                    appeals: appeals,
                                };
                            });
                    } else {
                        return {
                            isOldVersion: false,
                            subject: subject,
                            appeals: [],
                        };
                    }
                });
            }
            // Историческая версия субъекта
            if (subjectId && versionId) {
                return this.entityVersionsService.find(collection, versionId).then((subject: any) => {
                    if (
                        subject.appeals &&
                        subject.appeals.length > 0 &&
                        this.accessService.hasAccess([this.permissions.Appeals_Search])
                    ) {
                        const appealIds = [];
                        subject.appeals.forEach((appeal) => {
                            appealIds.push(appeal.id);
                        });
                        return this.restService
                            .search('appeals', {
                                search: { search: [{ field: '_id', operator: 'in', value: appealIds }] },
                                prj: 'appealList',
                                size: 1000,
                                sort: 'dateLastModification,DESC',
                            })
                            .then((appeals) => ({
                                isOldVersion: true,
                                subject: subject,
                                appeals: appeals,
                            }));
                    } else {
                        return {
                            isOldVersion: true,
                            subject: subject,
                            appeals: [],
                        };
                    }
                });
            }
        } else {
            // Список всех субъектов
            return this.getSubjectsList();
        }
    }

    public getSubjectsList() {
        const organization = this.storage.getItem('currentOrganization');
        const params: any = this.onlyKnoSubjectAction
            ? [{ field: 'unit.id', operator: 'eq', value: organization._id }]
            : [];
        const collection = this.onlyKnoSubjectAction ? 'subjectsKno' : 'subjects';

        // if (this.selectionService.isProcessSelect && this.selectionService.baseSearch) {
        //     if (Array.isArray(this.selectionService.baseSearch)) {
        //         params = params.concat(this.selectionService.baseSearch)
        //     } else {
        //         params.push(this.selectionService.baseSearch)
        //     }
        // }

        if (this.onlyKnoSubjectAction) {
            const org = this.storage.getItem('currentOrganization');
            params.push({
                field: 'unit.id',
                operator: 'eq',
                value: org._id,
            });
        }

        return this.searchSubjectsList(collection, params);
    }

    private searchSubjectsList(collection, params) {
        const searchParameters = { search: { search: params }, prj: 'subjectsList' };

        return this.restService.search(collection, searchParameters).then((subjects) => {
            return this.filtersService.processingLinkedData(
                collection,
                subjects,
                'subjectLink',
                'globalSubjectId',
                'subjectsList'
            );
        });
    }
}
