<div class="p-20"
     *ngIf="isFullCard && subject.specialTypeId === 'ulApplicant'">
    <h4 class="text-bold page-title no-margin-top no-padding mb-5">
        {{subject.data.organization.name}}
    </h4>
    <div class="mb-20">
        <span *ngIf="!isOrganizationTerminated"
              class="badge badge-success text-uppercase">
            {{localizations['appeals.subjects'].active_organization }}
        </span>
        <span *ngIf="isOrganizationTerminated"
              class="badge badge-danger text-uppercase">
            {{localizations['appeals.subjects'].inactive_organization }}
        </span>
        <span class="badge bg-orange text-uppercase ml-10">
            {{localizations['appeals.subjects'].legal_body}}
        </span>
    </div>
        <h4>
            <!-- Физическое лицо / Индивидуальный предприниматель -->
            <ng-container *ngIf="subject.specialTypeId !== 'ulApplicant' && subject.specialTypeId !== 'foreignUlApplicant'">
                {{subject.data.person.lastName}} {{subject.data.person.firstName}} {{subject.data.person.middleName}}
                <div class="mb-10">
                    <ng-container *ngIf="subject.specialTypeId == 'ipApplicant'">
                        <span *ngIf="!isPersonTerminated" class="badge badge-success text-uppercase">
                            {{localizations['appeals.subjects'].active}}
                        </span>
                        <span *ngIf="isPersonTerminated" class="badge badge-danger text-uppercase">
                            {{localizations['appeals.subjects'].inactive}}
                        </span>
                        <span class="badge bg-teal text-uppercase ml-10">
                            {{localizations['appeals.subjects'].individual_entrepreneur}}
                        </span>
                    </ng-container>
                    <span class="badge bg-purple text-uppercase" *ngIf="subject.specialTypeId == 'individualApplicant'">
                        {{localizations['appeals.subjects'].private_person}}
                    </span>
                    <!-- Руководитель организации (для дел с ЕПГУ) -->
                    <span *ngIf="linkedXsdData?.representativeBlock?.representativePosition === 'Руководитель'"
                          class="badge bg-orange text-uppercase ml-10">
                        {{localizations['appeals.subjects'].director}}
                    </span>
                </div>
            </ng-container>
        </h4>
        <div class="heading-elements" *ngIf="allowEdit || allowDelete">
            <button class="btn btn-default btn-sxs" (click)="edit()" *ngIf="allowEdit"
                    type="button" [title]="allowEdit ? 'Редактировать' : 'Посмотреть'">
                <i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowDelete">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </button>
        </div>
    </div>
    <div
        [ngClass]="{'panel-body no-padding-left no-padding-right': allowEdit || allowDelete, 'no-padding-bottom': noPadding, 'no-padding-top': !showHeader}">
        <!-- Юридическое лицо -->
        <div class="row" *ngIf="subject.specialTypeId === 'ulApplicant'">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-10">
                            <p class="text-bold no-margin text-size-large">
                                {{ localizations.common.ogrn }}
                            </p>
                            <div>{{ subject.data.organization.ogrn }} <br>
                                <ng-template [ngIf]="subject?.data?.organization?.dateRegistrationOgrn">
                                    {{localizations.common.from}} {{subject.data.organization.dateRegistrationOgrn | date: 'mediumDate'}}</ng-template>&nbsp;
                            </div>
                        </div>

                        <div class="mb-10">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations.common.registration_date}}
                            </p>
                            <ng-template [ngIf]="subject.data?.organization?.registration?.dateWriteGrn"
                                         [ngIfElse]="notSpecified">{{ subject.data.organization.registration.dateWriteGrn | date: 'shortDate' }}</ng-template>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-10">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations['appeals.subjects'].inn_kpp}}
                            </p>
                            <span>{{subject.data.organization.inn}}<br>{{subject.data.organization.kpp}}</span>
                        </div>

                        <div class="mb-10">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations['appeals.subjects'].registered_capital}}
                            </p>
                            <ng-template [ngIf]="subject.data.organization.capital"
                                         [ngIfElse]="notSpecified">
                                {{ subject.data.organization.capital.sizeRub.low }} {{localizations.common.rub}}
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="mb-10">
                    <p class="text-bold no-margin text-size-large">
                        {{localizations['appeals.subjects'].juridical_address}}
                    </p>
                    <ng-template [ngIf]="subject.data.organization.registrationAddress"
                                 [ngIfElse]="notSpecified">{{ getAddress(subject.data.organization.registrationAddress) }}</ng-template>
                </div>

                <div class="mb-10">
                    <p class="text-bold no-margin text-size-large">
                        {{localizations['appeals.subjects'].trade_name}}
                    </p>
                    <ng-template [ngIf]="subject.data.organization.tradeName"
                                 [ngIfElse]="notSpecified">{{ subject.data.organization.tradeName }}</ng-template>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-10">
                    <p class="text-bold no-margin text-size-large">
                        {{localizations['appeals.subjects'].main_activity}}
                    </p>
                    <ng-template [ngIf]="subject?.data?.organization?.okved"
                                 [ngIfElse]="notSpecified">
                        <div class="display-flex direction-column align-items-start">
                            <ng-template
                                [ngIf]="subject.data.organization.okved?.name && subject.data.organization.okved?.code"
                                [ngIfElse]="notSpecified">
                                <span>{{ subject.data.organization.okved.name }}
                                    <span class="text-bold text-slate">
                                        ({{subject.data.organization.okved.code}})</span>
                                </span>
                            </ng-template>
                            <span *ngIf="subject?.data?.organization?.dopOkved?.length > 0"
                                  class="anchor text-info text-size-small"
                                  (click)="showDopOkved()">
                            {{localizations['appeals.subjects'].all_activities}} ({{subject.data.organization.dopOkved.length}})</span>
                        </div>
                    </ng-template>
                </div>

                <div class="mb-10">
                    <p class="no-margin">
                        <span class="text-bold text-size-large">
                            {{localizations['appeals.subjects'].tax_authority}}
                        </span>
                        <span class="text-size-small ml-10 text-slate"
                              *ngIf="subject?.data?.organization?.tax?.dateWriteGrn">
                            {{localizations.common.starting}} {{subject.data.organization.tax.dateWriteGrn | date: 'mediumDate'}}</span>
                    </p>
                    <ng-template [ngIf]="subject?.data?.organization?.tax?.nameTaxAuthority"
                                 [ngIfElse]="notSpecified">{{ subject.data.organization.tax.nameTaxAuthority }}</ng-template>
                </div>

                <div class="mb-10" *ngIf="subject?.data?.organization?.person?.length > 0">
                    <p class="no-margin">
                        <ng-template [ngIf]="subject.data.organization.person[0]?.personInOrg?.personInOrg">
                            <span class="text-bold">
                                {{ subject.data.organization.person[0].personInOrg.personInOrg }}</span>
                            <span class="text-size-small ml-10 text-slate"
                                  *ngIf="subject.data.organization.person[0].personInOrg?.dateWriteGrn">c {{subject.data.organization.person[0].personInOrg.dateWriteGrn | date: 'mediumDate'}}</span>
                        </ng-template>
                        <span class="text-bold"
                              *ngIf="!subject.data.organization.person[0]?.personInOrg?.personInOrg">Руководитель</span>
                    </p>
                    <ng-template [ngIf]="subject?.data?.organization?.person[0]?.person"
                                 [ngIfElse]="notSpecified">
                        {{ subject.data.organization.person[0].person.lastName }}
                        {{ subject.data.organization.person[0].person.firstName }}
                        {{ subject.data.organization.person[0].person.middleName }}
                    </ng-template>
                </div>
            </div>
        </div>

        <!-- Иностранное юридическое лицо -->
        <div class="row" *ngIf="subject.specialTypeId === 'foreignUlApplicant'">
            <div class="col-md-6">
                <div class="mb-10">
                    <p class="text-bold no-margin text-size-large">
                        {{localizations['appeals.subjects'].inn_accreditation}}
                    </p>
                    <div>{{subject.data.organization.inn}} / {{subject.data.organization.accreditationNumber}}</div>
                </div>
                <div class="mb-10">
                    <p class="text-bold no-margin text-size-large">{{localizations['appeals.subjects'].trade_name}}</p>
                    <ng-template [ngIf]="subject.data.organization.tradeName"
                                 [ngIfElse]="notSpecified">{{ subject.data.organization.tradeName }}</ng-template>
                </div>
            </div>
            <div class="col-md-6">
                <p class="text-bold no-margin text-size-large">
                    {{localizations['appeals.subjects'].juridical_address}}
                </p>
                <ng-template [ngIf]="subject?.data?.organization?.registrationAddress"
                             [ngIfElse]="notSpecified">{{ getAddress(subject.data.organization.registrationAddress) }}</ng-template>
            </div>
        </div>

        <!-- Индивидуальный предприниматель или физическое лицо -->
        <div class="row"
             *ngIf="subject.specialTypeId === 'individualApplicant' || subject.specialTypeId === 'ipApplicant'">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-10">
                            <p class="text-bold no-margin text-size-large">
                                {{ subject.specialTypeId === 'ipApplicant' ? localizations['appeals.subjects'].ogrn : localizations['appeals.subjects'].snils }}</p>
                            <ng-template [ngIf]="subject.specialTypeId === 'ipApplicant'">
                                <div class="display-flex direction-column">
                                    <ng-template [ngIf]="subject?.data?.person?.ogrn"
                                                 [ngIfElse]="notSpecified">{{ subject.data.person.ogrn }}</ng-template>
                                    <span *ngIf="subject?.data?.person?.dateOgrn">
                                        {{localizations.common.starting}} {{ subject.data.person.dateOgrn | date: 'mediumDate' }}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="subject.specialTypeId === 'individualApplicant'">
                                <ng-template [ngIf]="subject?.data?.person?.snils"
                                             [ngIfElse]="notSpecified">{{ subject.data.person.snils }}</ng-template>
                            </ng-template>
                        </div>

                        <div class="mb-10"
                             *ngIf="subject?.specialTypeId === 'ipApplicant'">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations.common.registration_date}}
                            </p>
                            <ng-template [ngIf]="subject?.data?.person?.registration?.dateRegistrationOgrn"
                                         [ngIfElse]="notSpecified">{{ subject.data.person.registration.dateRegistrationOgrn | date: 'shortDate' }}</ng-template>
                        </div>

                        <div class="mb-10"
                             *ngIf="subject?.specialTypeId === 'individualApplicant' || subject?.specialTypeId === 'ipApplicant'">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations.common.cell_phone}}
                            </p>
                            <ng-template [ngIf]="subject?.data?.person?.mobile"
                                         [ngIfElse]="notSpecified">{{ subject.data.person.mobile }}</ng-template>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-10">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations['appeals.subjects'].inn}}
                            </p>
                            <ng-template [ngIf]="subject?.data?.person?.inn"
                                         [ngIfElse]="notSpecified">{{ subject.data.person.inn }}</ng-template>
                        </div>

                        <div class="mb-10"
                             *ngIf="subject?.specialTypeId === 'individualApplicant' || subject?.specialTypeId === 'ipApplicant'">
                            <p class="text-bold no-margin text-size-large">
                                {{localizations.common.email}}
                            </p>
                            <ng-template [ngIf]="subject?.data?.person?.email"
                                         [ngIfElse]="notSpecified">{{ subject.data.person.email }}</ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">

                <ng-container *ngIf="subject?.specialTypeId === 'ipApplicant'">
                    <div class="mb-10">
                        <p class="text-bold no-margin">
                            {{localizations['appeals.subjects'].main_activity}}
                        </p>
                        <ng-template [ngIf]="subject?.data?.person?.okved"
                                     [ngIfElse]="notSpecified">
                            <div class="display-flex direction-column align-items-start">
                                <ng-template [ngIf]="subject.data.person.okved?.name && subject.data.person.okved?.code"
                                             [ngIfElse]="notSpecified">
                                <span>{{ subject.data.person.okved.name }}
                                    <span class="text-bold text-slate">
                                        ({{subject.data.person.okved.code}})</span>
                                </span>
                                </ng-template>
                                <span *ngIf="subject?.data?.person?.dopOkved?.length > 0"
                                      class="anchor text-info text-size-small"
                                      (click)="showDopOkved()">
                            {{localizations['appeals.subjects'].all_activities}} ({{subject.data.person.dopOkved.length}})</span>
                            </div>
                        </ng-template>
                    </div>

                    <div class="mb-10"
                         *ngIf="subject?.specialTypeId === 'ipApplicant'">
                        <p class="no-margin">
                            <span class="text-bold">
                                {{localizations['appeals.subjects'].tax_authority}}
                            </span>
                            <span class="text-size-small ml-10 text-slate"
                                  *ngIf="subject?.data?.person?.tax?.dateWriteGrn">
                            {{localizations.common.from}} {{ subject.data.person.tax.dateWriteGrn | date: 'mediumDate' }}</span>
                        </p>
                        <ng-template [ngIf]="subject?.data?.person?.tax?.nameTaxAuthority"
                                     [ngIfElse]="notSpecified">{{ subject.data.person.tax.nameTaxAuthority }}</ng-template>
                    </div>

                    <div class="mb-10"
                         *ngIf="subject.specialTypeId === 'ipApplicant'">
                        <p class="no-margin">
                            <span class="text-bold">
                                {{localizations['appeals.subjects'].pension_fund}}
                            </span>
                            <span class="text-size-small ml-10 text-slate"
                                  *ngIf="subject?.data?.person?.pensionFund?.dateWriteGrn">
                            {{localizations.common.from}} {{ subject.data.person.pensionFund.dateWriteGrn | date: 'mediumDate' }}</span>
                        </p>
                        <ng-template [ngIf]="subject?.data?.person?.pensionFund?.name"
                                     [ngIfElse]="notSpecified">
                            <span>{{ subject.data.person.pensionFund.name }}</span>
                            <span
                                class="text-slate text-bold text-size-small ml-5">({{ subject.data.person.pensionFund.regNum }}
                                )</span>
                        </ng-template>
                    </div>
                </ng-container>

                <ng-container *ngIf="subject.specialTypeId === 'individualApplicant' || subject.specialTypeId === 'ipApplicant'">
                    <div class="mb-10"
                         *ngIf="subject.specialTypeId === 'individualApplicant'
                                || (subject.specialTypeId === 'ipApplicant' && showDocumentData)">
                        <p class="text-bold no-margin">
                            {{localizations['appeals.subjects'].identity_document}}
                        </p>
                        <ng-template
                            [ngIf]="subject?.data?.person?.documentType || subject?.data?.person?.documentNumber"
                            [ngIfElse]="notSpecified">
                            <div class="child-mr-5">
                            <span *ngIf="subject?.data?.person?.documentType && subject?.data?.person?.documentType[0]">
                                {{ subject.data.person.documentType[0].text }}</span>
                                <span *ngIf="subject?.data?.person?.documentSeries">
                                {{ subject.data.person.documentSeries }}</span>
                                <span *ngIf="subject?.data?.person?.documentNumber">
                                {{ subject.data.person.documentNumber }}</span>
                                <span *ngIf="subject?.data?.person?.documentIssueDate">
                                {{localizations['common'].from}} {{ (subject.data.person.documentIssueDate.formatted) ? subject.data.person.documentIssueDate.formatted :  subject.data.person.documentIssueDate | date: 'mediumDate' }}</span>
                            </div>
                            <div class="child-mr-5" *ngIf="subject?.data?.person?.documentIssuer">
                            <span *ngIf="subject.data.person.documentIssuer.code">
                                {{ subject.data.person.documentIssuer.code }}</span>
                                <span *ngIf="subject.data.person.documentIssuer.name">
                                {{ subject.data.person.documentIssuer.name }}</span>
                            </div>
                        </ng-template>

                    </div>
                </ng-container>

                <div class="mb-10">
                    <p class="text-bold no-margin text-size-large">
                        {{localizations['appeals.subjects'].registration_address}}
                    </p>
                    <ng-template [ngIf]="subject?.data?.person?.registrationAddress"
                                 [ngIfElse]="notSpecified">{{ getAddress(subject.data.person.registrationAddress) }}</ng-template>
                </div>

                <ng-container *ngIf="subject?.data?.person?.birthPlace">
                    <div class="mb-10">
                        <p class="text-bold no-margin text-size-large">
                            {{ localizations['appeals.subjects'].birth_place }}
                        </p>
                        {{ getAddress(subject.data.person.birthPlace) }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

<div class="p-20" *ngIf="!isFullCard">
    <!-- Ошибки при валидации карточки объекта -->
    <ng-template #errorsTemplate><span class="text-semibold">
        {{localizations['appeals.subjects'].subject_errors}}:
    </span>
        <ul [innerHtml]="getErrorsList()" class="no-margin"></ul>
    </ng-template>
    <button class="btn btn-danger pull-right btn-badge"
            *ngIf="checkErrorsExist()"
            [popover]="errorsTemplate"
            [outsideClick]="true"
            placement="left">
        {{localizations.common.errors_small}}
    </button>

    <!-- Текст при добавлении нового объекта -->
    <p class="mb-3 text-info"
       *ngIf="checkShowNewObjectText()">
        {{localizations['appeals.subjects'].new}}
    </p>

    <!-- Информация по объекту -->
    <ng-container  *ngIf="!checkShowNewObjectText()">
        <!-- Краткая информация о участнике НЕ объекте недвижимости -->
        <ng-container *ngIf="subject.specialTypeId != globalParams.realtyCode">

            <!-- Краткая информация по ЮЛ (ОГРН + ИНН) -->
            <ng-container *ngIf="subject.specialTypeId == globalParams.legalPersonCode">
                <h4 class="no-margin-top"
                    *ngIf="showName">
                    {{subject.data.organization.shortName ? subject.data.organization.shortName : subject.data.ogranization.name}}
                </h4>
                <p class="mb-3">
                    <!-- ОГРН организации -->
                    <span class="text-muted">
                        {{ localizations.common.ogrn }}:
                    </span>
                    {{subject.data.organization.ogrn ? subject.data.organization.ogrn : localizations['appeals.subjects'].not_specified_m}}

                    <!-- ИНН организации -->
                    <span class="text-muted ml-10">
                        {{localizations['appeals.subjects'].inn}}:
                    </span>
                    {{subject.data.organization.inn ?
                        subject.data.organization.inn :
                        localizations['appeals.subjects'].not_specified_m}}

                    <!-- КПП организации -->
                    <span class="text-muted ml-10">
                        {{localizations['appeals.subjects'].kpp}}:
                    </span>
                    {{subject.data.organization.kpp ?
                        subject.data.organization.kpp :
                        localizations['appeals.subjects'].not_specified_m}}

                    <!-- Дата регистрации -->
                    <ng-container *ngIf="subject.data.organization.dateRegistrationOgrn">
                        <span class="text-muted ml-10">
                            {{localizations.common.registration_date}}:
                        </span>
                        {{subject.data.organization.dateRegistrationOgrn | date: 'mediumDate'}}
                    </ng-container>
                </p>
                <p class="mb-3"
                   *ngIf="subject.data.organization.person && subject.data.organization.person.length > 0">
                    <span class="text-muted">
                        {{subject.data.organization.person[0].personInOrg.personInOrg}}:
                    </span>
                    {{subject.data.organization.person[0].person.lastName}}
                    {{subject.data.organization.person[0].person.firstName}}
                    {{subject.data.organization.person[0].person.middleName}}
                </p>

                <!-- Юридический адрес организации -->
                <p class="mb-3">
                    <span class="text-muted">
                        {{localizations['appeals.subjects'].juridical_address}}:
                    </span>
                    {{subject.data.organization.registrationAddress ? getAddress(subject.data.organization.registrationAddress) : localizations['appeals.subjects'].not_specified_m}}
                </p>

                <!-- Вид деятельности -->
                <p class="mb-3" *ngIf="subject.data.organization.okved">
                    <span class="text-muted">
                        {{localizations['appeals.subjects'].activity}}:
                    </span>
                    {{subject.data.organization.okved.name}}
                </p>
            </ng-container>

            <!-- ФИО + ДР (для ФЛ (представителя организации)) -->
            <h4 *ngIf="showName && subject.data.person && subject.specialTypeId != 'ulApplicant' && checkShowPersonFio()" >
                {{getPersonFullName()}}
            </h4>

            <!-- Избранная детальная информация по ФЛ (ИП) (Документ + ИНН + СНИЛС)-->
            <p class="mb-3"
               *ngIf="subject.specialTypeId != 'ulApplicant'">
                <!-- Информация о ДУЛ ФЛ, ИП -->
                <ng-container *ngIf="subject.data.person && (subject.data.person.documentSeries || subject.data.person.documentNumber)">
                    <span class="text-muted">
                        {{localizations['appeals.subjects'].document}}:
                    </span>

                    <span
                        *ngIf="!subject.data.person.documentType"
                        [ngClass]="{
                            'text-danger': checkElementHasError('common', 'documentType') && parentComponent !== 'complaint-block',
                            'text-info': !checkElementHasError('common', 'documentType') && parentComponent !== 'complaint-block',
                            'anchor': parentComponent !== 'complaint-block'
                        }"
                        (click)="activateTab('common')"
                    >
                    {{localizations['appeals.subjects'].not_selected_m}}
                    </span>

                    <span class="mr-5"
                          *ngIf="subject.data.person.documentType">
                        {{subject.data.person.documentType[0]?.text}}
                    </span>

                    <span
                        *ngIf="subject.data.person.documentSeries"
                        [ngClass]="{
                            'text-info': allowEdit && parentComponent !== 'complaint-block',
                            'anchor': parentComponent !== 'complaint-block'
                        }"
                        (click)="activateTab('common')">
                        {{subject.data.person.documentSeries}}
                    </span>

                    <ng-container *ngIf="subject.data.person.documentNumber">
                        №
                        <span
                            [ngClass]="{
                                'text-info anchor': allowEdit && parentComponent !== 'complaint-block'
                            }"
                            class="mr-10"
                            (click)="activateTab('common')"
                        >
                            {{subject.data.person?.documentNumber}}
                        </span>
                    </ng-container>

                </ng-container>

                <!-- ИНН ФЛ -->
                <ng-container>
                    <span class="text-muted">
                        {{localizations['appeals.subjects'].inn}}:
                    </span>
                    {{subject.data.person.inn ?
                    subject.data.person.inn :
                    localizations['appeals.subjects'].not_specified_m}}
                </ng-container>

                <!-- СНИЛС ФЛ -->
                <ng-container *ngIf="subject.data.person && subject.data.person.snils">
                    <span class="text-muted ml-10">
                        {{localizations['appeals.subjects'].snils}}:
                    </span>
                    {{subject.data.person.snils ?
                        subject.data.person.snils :
                        localizations['appeals.subjects'].not_specified_m}}
                </ng-container>

                <!-- ОГРНИП для ИП -->
                <ng-container *ngIf="subject.specialTypeId == globalParams.entrepreneurPersonCode">
                    <span class="text-muted ml-10">
                        {{localizations['appeals.subjects'].ogrnip}}:
                    </span>
                    {{subject.data.person.ogrn ?
                        subject.data.person.ogrn :
                        localizations['appeals.subjects'].not_specified_m}}
                </ng-container>

                <!-- Дата регистрации -->
                <ng-container *ngIf="subject.data.person.dateOgrn">
                    <span class="text-muted ml-10">
                        {{localizations.common.registration_date}}:
                    </span>
                    {{subject.data.person.dateOgrn | date: 'mediumDate'}}
                </ng-container>
            </p>

            <!-- Адрес регистрации и фактического проживания -->
            <ng-container *ngIf="subject.specialTypeId != 'ulApplicant'">

                <!-- Адрес регистрации -->
                <p class="mb-3" *ngIf="subject.data.person.registrationAddress">
                    <span class="text-muted">
                        {{localizations['appeals.subjects'].registration_address}}:
                    </span>
                    {{getAddress(subject.data.person.registrationAddress)}}
                </p>

                <!-- Адрес фактического проживания -->
                <p class="mb-3" *ngIf="subject.data.person && subject.data.person.factAddress">
                    <span class="text-muted">
                        {{localizations['appeals.subjects'].residential_address}}:
                    </span>
                    {{getAddress(subject.data.person.factAddress)}}
                </p>
            </ng-container>

            <!-- Контактная информация -->
            <p class="mb-3"
               *ngIf="subject.specialTypeId != 'ulApplicant' && (subject.data.person.mobile || subject.data.person.email || subject.data.person.phone)">
                <span class="text-muted">
                    {{localizations.common.cell_phone}}:
                </span>
                <span
                    (click)="activateTab('common')"
                    [ngClass]="{
                        'anchor': parentComponent !== 'complaint-block',
                        'text-danger': checkElementHasError('common', 'mobile') && parentComponent !== 'complaint-block',
                        'text-info': !checkElementHasError('common', 'mobile') && allowEdit && parentComponent !== 'complaint-block'
                    }">
                    <ng-container *ngIf="subject.data.person.mobile">
                        {{subject.data.person.mobile}}
                    </ng-container>
                    <ng-container *ngIf="!subject.data.person.mobile">
                        {{localizations['common'].not_specified_m}}
                    </ng-container>
                </span>

                <ng-container *ngIf="subject.data.person.phone">
                    <span class="text-muted ml-10">
                        {{localizations.common.home_phone}}:
                    </span>
                    {{subject.data.person.phone}}
                </ng-container>

                <ng-container *ngIf="subject.data.person.workPhone">
                    <span class="text-muted ml-10">
                        {{localizations['appeals.subjects'].work_phone}}:
                    </span>
                    {{subject.data.person.workPhone}}
                </ng-container>

                <ng-container *ngIf="subject.data.person.email">
                    <span class="text-muted ml-10">
                        Email:
                    </span>
                    {{subject.data.person.email}}
                </ng-container>
            </p>

            <!-- Вид деятельности -->
            <p class="mb-3"
               *ngIf="subject.specialTypId == 'ipApplicant' && subject.data.person.okved">
                <span class="text-muted">
                    {{localizations['appeals.subjects'].activity}}:
                </span>
                {{subject.data.person.okved.name}}
            </p>
        </ng-container>
    </ng-container>


    <!-- Информация об участии в услугах -->
    <ng-container *ngIf="mode == 'view' && subjectSubservices.length > 0">
        <p class="text-muted no-margin">
            {{localizations['appeals.subjects'].participation}}:
        </p>
        <ul>
            <ng-container *ngFor="let subservice of subjectSubservices">
                <li *ngIf="subservice.subjectData.group
                            || subservice.subjectData.subKind
                            || subservice.subjectData.categories
                            || subservice.subjectData.principals?.length">
                    <p class="mb-3">
                        {{subservice.title}}
                        <ng-container *ngIf="subservice.subjectData.group">
                            <span class="text-muted ml-10">
                                {{localizations['appeals.subjects'].group}}:
                            </span>
                            {{subservice.subjectData.group}}
                        </ng-container>
                        <ng-container *ngIf="subservice.subjectData.subKind">
                            <span class="text-muted ml-10">
                                {{localizations['appeals.subjects'].participation_type}}:
                            </span>
                            {{subservice.subjectData.subKind}}
                            <ng-container *ngIf="subservice.subjectData.representative">
                                (<span class="text-muted">{{localizations['appeals.subjects'].representative}}: </span> {{subservice.subjectData.representative}}<ng-container *ngIf="subservice.subjectData.representativeType">- {{subservice.objectData.representativeType}}</ng-container>)
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="subservice.subjectData.categories">
                            <span class="text-muted ml-10">
                                {{ localizations.common.category }}:
                            </span>
                            <ng-container *ngFor="let category of subservice.subjectData.categories; let lst = last">
                                {{category.name}}<span *ngIf="!lst" class="ml-5 mr-5">/</span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="subservice.objects && subservice.objects.length > 0">
                            <span class="text-muted ml-10">
                                {{ localizations['appeals.subjects'].objects }}:
                            </span>
                            <ng-container *ngFor="let object of subservice.objects; let lst = last">
                                {{object}}<span *ngIf="!lst">, </span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="subservice.subjectData.principals?.length">
                            <ng-container *ngFor="let principal of subservice.subjectData.principals; let lst = last">
                                <span class="text-muted ml-10">Вид участия:</span>
                                {{ principal?.type ? principal.type.name : 'не выбран' }}
                                <span *ngIf="!lst">, </span>
                            </ng-container>
                        </ng-container>
                    </p>
                </li>
            </ng-container>
        </ul>
    </ng-container>

    <!-- Объекты -->
    <ng-container *ngIf="fromAppeal && subject.objects">
        <span class="text-muted">
            {{localizations['appeals.subjects'].objects}}:
        </span>
        <ul>
            <li *ngFor="let subObject of subject.objects">
                <strong>{{subObject.name}}</strong>
            </li>
        </ul>
    </ng-container>

    <!-- Наличие Дополнительных данных по услуге / варианту -->
    <p class="mb-3"
       *ngIf="fromAppeal && checkAdditionalDataExist()">
        <i class="icon-exclamation text-info"></i>
        <span class="text-semibold">
            {{localizations['appeals.subjects'].attention}}
        </span>
        {{localizations['appeals.subjects'].contains}}
        <span class="anchor text-info" (click)="activateTab('additionalData')">
            {{localizations['appeals.subjects'].additional_fields}}
        </span>
        <span class="text-success ml-10"
              *ngIf="checkAdditionalDataValid()">
            ({{localizations['appeals.subjects'].filled_corrected}})
        </span>
    </p>

</div>

<!-- модальное окно для выбора параметров платежа -->
<ng-template #dopOkvedModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations['appeals.subjects'].activity}}
            <ng-container *ngIf="subject.specialTypeId == 'ulApplicant'">
                {{subject.data.organization.shortName}}
            </ng-container>
            <ng-container *ngIf="subject.specialTypeId != 'ulApplicant'">
                {{subject.data.person.lastName}} {{subject.data.person.firstName}} {{subject.data.person.middleName}}
            </ng-container>
        </h4>
        <button type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul>
            <li *ngFor="let item of dopOkved">
                {{item.name}}
                <span class="text-slate">
                    ({{item.code}})
                </span>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="modalRef.hide()">
            {{localizations.common.close}}
        </button>
    </div>
</ng-template>

<ng-template #notSpecified><span class="text-muted">{{localizations['common'].not_specified}}</span></ng-template>
