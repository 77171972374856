import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService, ToasterService } from '@evolenta/core';
import { JsonFormsService } from '@evolenta/forms';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { NsiService } from '../nsi.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'nsi-dictionary-element',
    templateUrl: 'nsi-dictionary-element.component.html',
    styleUrls: ['../../../elements-list.css'],
    styles: ['.navbar-title.wide, .navbar-menu.wide{ width: calc(100% / 2); }'],
})
export class NsiDictionaryElementComponent implements OnInit {
    public element;
    public isOldVersion = false;
    public groupCode;
    public dictionary;

    public dictionaryData; // объект с данными процесса обработки справочника
    public schema;
    @ViewChild('saveBtn', { static: false }) public saveBtn;
    @ViewChild('additionalDataComponent', { static: false }) public additionalDataComponent: DynamicFormComponent;
    public xsd;

    public activeTab = 'common';
    public versionsTab = {
        code: 'versions',
        name: 'Версии объекта',
        active: false,
    };
    public tabs = [
        {
            code: 'common',
            name: 'Основные параметры',
            active: true,
        },
    ];

    public parentId;

    public dictionaryModel = {}; // для просмотра формы доп.полей

    public isEditXsd = false;

    public topPosition = 0;

    public constructor(
        private route: ActivatedRoute,
        private nsiService: NsiService,
        private jsonFormService: JsonFormsService,
        private rest: RestService,
        private toaster: ToasterService,
        private router: Router
    ) {}

    public ngOnInit() {
        this.route.params.subscribe((params) => {
            if (params.parentId) {
                this.parentId = params.parentId;
            }
        });
        this.route.url.subscribe((data) => {
            this.groupCode = data[0].path;
            this.dictionary = data[1].path;
            this.dictionaryData = this.nsiService.collectionsMeta.find(
                (item) => item.collectionName === this.dictionary
            );
        });
        this.route.data.subscribe((response: any) => {
            this.isOldVersion = response.resolves.isOldVersion;
            this.element = response.resolves.element;
            if (!this.element._id && this.parentId) {
                this.element.parentId = this.parentId;
            }
            if (this.element._id && this.dictionaryData.dictionaryParams.isVersioning) {
                this.tabs.push(this.versionsTab);
            }
            if (!this.element._id && this.dictionaryData.dictionaryParams.autoGenerateCode) {
                this.autoGenerateCode().then((number) => {
                    this.element.code = number;
                    this.getXsdData();
                });
            } else {
                this.getXsdData();
            }
        });
    }

    public getXsdData() {
        if (this.dictionaryData.xsd) {
            this.xsd = this.dictionaryData.xsd;
        } else {
            const propertiesInJson = this.dictionaryData.json
                ? this.dictionaryData.json
                : _.cloneDeep(this.nsiService.defaultItemJson);
            if (this.isOldVersion) {
                propertiesInJson.properties.code.readonly = true;
                propertiesInJson.properties.name.readonly = true;
            }
            this.schema = this.jsonFormService.schemaFromJson(propertiesInJson, this.dictionary);
            console.log('schema', this.schema);
            if (!this.schema.value) {
                this.schema.value = {};
            }
            this.schema.value[this.dictionary] = this.element;
        }
    }

    public autoGenerateCode() {
        const params = [];
        if (this.dictionaryData.dictionaryParams.codePrefix) {
            params.push({
                field: 'code',
                operator: 'like',
                value: this.dictionaryData.dictionaryParams.codePrefix,
            });
        }
        let initNumber = 0;
        if (this.dictionaryData.dictionaryParams.codeStart) {
            initNumber = this.dictionaryData.dictionaryParams.codeStart - 1;
        }
        return this.rest
            .search(this.dictionaryData.collectionName, { search: { search: params }, sort: 'code,DESC', size: 1 })
            .then((prevElements) => {
                if (prevElements.length > 0) {
                    const prevElement = prevElements[0];
                    initNumber = +prevElement.code.replace(this.dictionaryData.dictionaryParams.codePrefix, '');
                }
                initNumber = initNumber + 1;
                let codeNumber = initNumber.toString();
                if (this.dictionaryData.dictionaryParams.codeLength) {
                    codeNumber = initNumber.toString().padStart(this.dictionaryData.dictionaryParams.codeLength, '0');
                }
                codeNumber = this.dictionaryData.dictionaryParams.codePrefix + codeNumber;
                return Promise.resolve(codeNumber);
            });
    }

    /**
     * Возвратк к списку элементов справочника
     */
    public back() {
        this.router.navigate(['nsi', this.groupCode, this.dictionary]);
    }

    /**
     * Обработка значений формы и передача в метод сохранения данных
     * @param data
     */
    public complete(data) {
        if (data.errors.length === 0) {
            const formData = data.values[this.dictionary];
            this.save(formData);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    /**
     * Применение значений формы (эмуляция нажатия на кнопку)
     */
    public submit() {
        if (this.saveBtn) {
            // Небольшая задержка для выставления / снятия флага необходимости валидации
            if (this.saveBtn) {
                setTimeout(() => {
                    this.saveBtn.nativeElement.click();
                }, 100);
            } else {
                this.complete({ values: null, errors: [] });
            }
        } else {
            this.save();
        }
    }

    /**
     * Сохранение данных в БД
     * @param formData
     */
    public save(formData = null) {
        if (formData) {
            this.element = Object.assign(this.element, formData);
        }
        if (this.additionalDataComponent) {
            this.additionalDataComponent.validate();
            if (!this.additionalDataComponent.isValid) {
                this.toaster.error('На форме содержатся ошибки');
                return;
            }
        }
        if (this.element._id) {
            this.rest.update(this.dictionary, this.element).then(() => {
                this.toaster.success('Элемент справочника обновлен');
                this.router.navigate(['nsi', this.groupCode, this.dictionary]);
            });
        } else {
            this.rest.create(this.dictionary, this.element).then((data: any) => {
                this.toaster.success('Элемент справочника создан');
                this.router.navigate(['nsi', this.groupCode, this.dictionary]);
            });
        }
    }

    public deleteXsd() {
        this.element.xsd = null;
    }

    public completeEditXsd() {
        this.isEditXsd = false;
    }

    public afterCreateXsd(xsdId) {
        this.element.xsd = xsdId;
    }
}
