import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { ToasterService } from '@evolenta/core';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';
import { CommonAppealSubservicesService } from '../../services/common-appeal-subservices.service';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonUtilities } from '@evolenta/utilities';
import { CommonAppealEntityCardComponent } from './components/entity-card/common-appeal-entity-card.component';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import some from 'lodash-es/some';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-entities',
    templateUrl: 'common-appeal-entities.component.html',
})
export class CommonAppealEntitiesComponent implements OnInit {
    @Input() public appeal: any; // Формируемое дело
    @Input() public subservice; // Услуга, по которой формируется дело
    @Input() public task; // Задача процесса
    @Input() public allowEdit = true;

    public tempEntity;
    public activeEntity; // сведение, находящееся в данный момент на редактировании
    public activeAppealSubservice;

    @Output() public onStartEdit = new EventEmitter<boolean>(); // Переход в режим редактирования элемента
    @Output() public afterEndEdit = new EventEmitter<boolean>(); // Выход из режима редактирования элемента
    @Output() public afterCancelEntity = new EventEmitter<boolean>(); // Выход из режима редактирования элемента

    @ViewChild('activeEntityComponent', { static: false })
    public activeEntityComponent: CommonAppealEntityCardComponent; // редактируемое сведение
    @ViewChildren('entityCard') public entityCardComponents: QueryList<CommonAppealEntityCardComponent>;

    public activeEntities = [];
    public notAddedEntities = [];

    public uniqueEntities = [
        'order',
        'checkStatement',
        'raidTask',
        'buyOrder',
        'observationDocument',
        'decisionToConductKNM',
    ];
    public entityGroups = [];

    public constructor(
        public appealStatusService: CommonAppealStatusService,
        public appealSubservicesService: CommonAppealSubservicesService,
        public appealService: CommonAppealService,
        private appealSaveService: CommonAppealSaveService,
        private errorLoggingService: ErrorLoggingService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.appeal.subservice.entities) {
            this.appeal.subservice.entities = [];
        }
        this.correctActiveEntities();
        this.getEntityGroups();
    }

    public getEntityGroups() {
        let allEntities = this.subservice.entities || [];
        if (this.task) {
            allEntities = allEntities.filter((item) => this.task.entities.find((elm) => elm.sperId === item.sperId));
        }
        this.entityGroups = allEntities.map((item) => {
            const entityData: any = {
                code: item.code,
                name: item.name,
                guid: item.guid,
                isUnique: item.onlyOne || this.uniqueEntities.indexOf(item.code) !== -1,
            };
            if (item.kind) {
                entityData.kind = item.kind;
            }
            if (item.xsd) {
                entityData.xsd = item.xsd;
            }

            return entityData;
        });
    }

    public countEntitiesForGroup(group): number {
        return this.appeal && this.appeal.subservice
            ? this.appeal.subservice.entities.filter((item) => item.type === group.code).length
            : 0;
    }

    public correctActiveEntities() {
        this.activeEntities =
            this.appeal.subservice &&
            this.appeal.subservice.entities &&
            this.appeal.subservice.entities.filter((item) => !item.hasOwnProperty('display') || item.display);
        if (this.task) {
            this.activeEntities = this.activeEntities.filter((entity) =>
                this.task.params.entities.find((item) => item.code === entity.type)
            );
        }
        this.prepareNotAddedEntities();
    }

    public prepareNotAddedEntities() {
        this.notAddedEntities = [];
        let allEntities = this.subservice.entities || [];
        if (this.task) {
            allEntities = allEntities.filter((entity) =>
                this.task.params.entities.find((item) => item.sperId === entity.sperId)
            );
        }
        allEntities.forEach((entity) => {
            const find = this.appeal.subservice.entities.find((item) => item.type === entity.code);
            if (!find) {
                const requiredEntity = this.task
                    ? this.task.entities.find((item) => item.sperId === entity.sperId).isRequired
                    : false;
                this.notAddedEntities.push({
                    code: entity.code,
                    name: entity.name,
                    xsd: entity.xsd,
                    isRequired: requiredEntity,
                    guid: entity.guid,
                    kind: entity.kind,
                });
            }
        });
    }

    public addEntity(appealSubservice, entity = null) {
        console.log('add entity', entity);
        this.onStartEdit.emit(true);
        this.activeAppealSubservice = appealSubservice;
        this.activeEntity = {
            guid: CommonUtilities.GenerateGuid(),
            subserviceGuid: appealSubservice.guid,
            type: entity ? entity.code : null,
            name: entity ? entity.name : '',
            kind: entity.kind ? entity.kind : 'static',
            xsd: entity.xsd ? entity.xsd : null,
            entityGuid: entity.guid,
        };

        if (entity) {
            const entityInSubService = this.subservice.entities.find((item) => item.code === entity.code);

            if (entityInSubService.notFilterRequirementsByKndKinds) {
                this.activeEntity.notFilterRequirementsByKndKinds = true;
            }
        }
    }

    public async applyEntity(entity) {
        this._applyEntityToAppeal(entity);
        this.tempEntity = null;
        this.activeEntity = null;
        this.activeAppealSubservice = null;
        if (this.appeal._id) {
            try {
                await this.appealSaveService.saveAppeal();
                this.toaster.success('Сведение успешно сохранено');
                this.correctActiveEntities();
            } catch (error) {
                this.toaster.error(error);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, error, this.appeal);
            }
        }
        this.prepareNotAddedEntities();
        this.afterEndEdit.emit(true);
    }

    private _applyEntityToAppeal(entity) {
        if (
            this.appeal.subservice.guid === entity.subserviceGuid &&
            this.appeal.subservice.entities.some((item) => item.guid === entity.guid)
        ) {
            this.appeal.subservice.entities.push(entity);
        }
    }

    public editEntity(entity, appealSubservice) {
        this.activeAppealSubservice = appealSubservice;
        this.activeEntity = entity;
        this.tempEntity = cloneDeep(this.activeEntity);
        this.onStartEdit.emit(true);
    }

    public checkAllowAddEntity(appealSubservice, task) {
        if (this.subservice._id !== appealSubservice.id) {
            throw new Error('Сабсервис не найден');
        }
        let subserviceEntities = this.subservice.entities || [];
        if (task) {
            const usedInTaskCodes = task.params.entities.map((item) => item.code);
            subserviceEntities = subserviceEntities.filter((item) => usedInTaskCodes.indexOf(item.code) !== -1);
        }
        this.uniqueEntities.forEach((uniqueEntity) => {
            const find = appealSubservice.entities.find((item) => item.type === uniqueEntity);
            if (find) {
                subserviceEntities = subserviceEntities.filter((item) => item.code !== uniqueEntity);
            }
        });

        return subserviceEntities.length > 0;
    }

    public checkTaskEntity(entity) {
        return this.task.params.entities.find((item) => item.code === entity.type);
    }

    public checkVisibleEntity(entity) {
        return !entity.hasOwnProperty('display') || entity.display;
    }

    public deleteEntity(entity, appealSubservice) {
        const findIndex = appealSubservice.entities.findIndex((item) => item.guid === entity.guid);
        appealSubservice.entities.splice(findIndex, 1);
    }

    public cancelEntity() {
        this.activeEntity = null;
        this.afterCancelEntity.emit(false);
        this.afterEndEdit.emit(true);
    }

    public hasAllowEdit(): boolean {
        return !some(this.appealStatusService.finishStatuses, (item) => item === this.appeal.status.code);
    }

    public checkEntityChange() {
        return (
            this.activeEntity &&
            (!this.appeal.subservice.entities.find((item) => item.guid === this.activeEntity.guid) ||
                !isEqual(this.activeEntity, this.tempEntity))
        );
    }

    public isValid() {
        if (this.activeEntityComponent) {
            return this.activeEntityComponent.isValid();
        }

        return true;
    }

    public getTransferToProcessParams() {
        let params = {};
        if (this.entityCardComponents && this.entityCardComponents.length > 0) {
            this.entityCardComponents.forEach((entityCardComponent) => {
                const componentParams = entityCardComponent.getTransferToProcessParams();
                if (componentParams) {
                    params = Object.assign(params, componentParams);
                }
            });
        }

        return params;
    }

    public async processingSaveBeforePrint(printDocument) {
        this._applyEntityToAppeal(this.activeEntity);

        await this.appealSaveService.saveAppeal();
        this.activeEntityComponent.processingPrintFormAfterSave(printDocument);
    }
}
