import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { CommonStandardsService } from '../../common-standards.service';
// @ts-ignore
import { ToasterService, TranslateService } from '@evolenta/core';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-standard-check-list-edit',
    templateUrl: './common-standard-check-list-edit.component.html',
    styles: [':host { width: 100%; }'],
})
export class CommonStandardCheckListEditComponent implements OnInit {
    @Input() public checkList;
    @Input() public allowEdit = true;
    @Input() public entity;

    @Output() public onSave = new EventEmitter<boolean>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public metaData;
    public metaReglament;

    public activeTab = 'common';
    public isProcessValidate = false;

    public groups;

    public tabs = [
        {
            code: 'common',
            name: 'Описание',
        },
    ];
    public localizations;

    @ViewChild('mainData', { static: false }) public mainData: DynamicFormComponent;

    public constructor(
        public commonStandardsService: CommonStandardsService,
        private errorLoggingService: ErrorLoggingService,
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.metaData = this.commonStandardsService.metaReglament.blocks.find((item) => item.code === 'checkLists');
        this.metaReglament = this.commonStandardsService.metaReglament;

        if (
            this.metaData.checkListsData.questionData &&
            this.metaData.checkListsData.questionData.isBpmnTasksLinksSetting
        ) {
            this.tabs.push({
                code: 'bpmnTasksLinks',
                name: 'Задачи бизнес-процесса',
            });
            this.tabs.push({
                code: 'bpmnEventsLinks',
                name: 'События бизнес-процесса',
            });
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public async save() {
        this.mainData.validate();
        let errorText;
        if (this.mainData.isValid && this.validate()) {
            if (this.checkList.questions.length === 0) {
                errorText = 'Не выбраны вопросы для проверочного листа';
                this.toaster.error(errorText);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

                return;
            }

            this.onSave.emit(true);

            return;
        }

        errorText = 'На форме основных данных ошибки';
        this.toaster.error(errorText);
        await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
    }

    public validate() {
        this.isProcessValidate = true;
        const errors = [];

        return errors.length === 0;
    }

    public cancel() {
        this.onCancel.emit(true);
    }
}
