<!-- Группы документов, привязанные к сведению -->
<fieldset *ngIf="showDocumentsBlock" [ngStyle]="{'margin-top' : mode === 'view' ? '-15px': 0}">
    <legend class="text-bold pl-20 no-margin-bottom cursor-pointer text-slate pt-10" [ngClass]="{'text-size-small no-border-bottom': mode === 'view'}">
        ДОКУМЕНТЫ
        <a class="control-arrow mr-15" (click)="showDocuments = !showDocuments">
            <i [ngClass]="{'icon-arrow-down32': showDocuments, 'icon-arrow-left32': !showDocuments}"></i>
        </a>
    </legend>
    <div [hidden]="!showDocuments">
        <common-appeal-documents [appeal]="appealService.appeal"
                                 [subservice]="appealService.subservice"
                                 [onlyGroups]="[entityDocumentGroupId]"
                                 [task]="task"
                                 [mode]="mode"
                                 [allowEdit]="allowEdit"
                                 [needSaveBeforePrint]="needSaveBeforePrint"
                                 (isCheckFileSign)="checkAllowEdit($event)"
                                 (onNeedSaveBeforePrint)="processingSaveBeforePrint($event)"
                                 #appealDocuments>
        </common-appeal-documents>
    </div>
</fieldset>

