import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { TranslateService } from '@evolenta/core';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
    selector: 'common-standard-periods',
    templateUrl: './common-standard-periods.component.html',
    styleUrls: ['./common-standard-periods.component.less'],
})
export class CommonStandardPeriodsComponent implements OnInit, OnChanges {
    @Input() public initData: { fullPeriod; periods };
    @Input() public variantGuid: string;
    @Output() public confirmChanges = new EventEmitter<any>();
    @Output() public changeEditingState = new EventEmitter<boolean>();

    private _backup;

    public isEditing = false;
    public daysLeft: number;

    public localizations;

    public get currentGroups() {
        return this.initData.periods.groups.map((_, i) => i);
    }

    public constructor(
        private translate: TranslateService,
        private utility: UtilityService
    ) {
        this._loadTranslations();
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public ngOnInit() {
        this.calcDaysLeft();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes && changes['initData']) {
            this.calcDaysLeft();
        }
    }

    public calcDaysLeft() {
        const ungroupedLeft = this.initData.periods.ungroup.reduce((prev, curr) => prev + curr.period, 0);
        const groupedLeft = this.initData.periods.groups.reduce((prev, curr) => prev + curr.period, 0);

        this.daysLeft = this.initData.fullPeriod - (ungroupedLeft + groupedLeft);
    }

    public startEditing() {
        this._backup = cloneDeep(this.initData.periods);
        this.isEditing = true;
        this.changeEditingState.emit(this.isEditing);
    }

    public cancelEditing() {
        this.initData.periods = cloneDeep(this._backup);
        this._backup = null;
        this.isEditing = false;
        this.changeEditingState.emit(this.isEditing);
        this.calcDaysLeft();
    }

    public applyChanges() {
        this._backup = null;
        this.isEditing = false;
        this.confirmChanges.emit(this.initData.periods);
        this.changeEditingState.emit(this.isEditing);
    }

    public changeGroup({ taskGuid, targetGroup, prevGroup }) {
        if (prevGroup === null) {
            // перемещение ИЗ ungroup
            const task = { ...this.initData.periods.ungroup.find((item) => item.taskGuid === taskGuid) };
            delete task.period;

            this.initData.periods.ungroup = this.initData.periods.ungroup.filter((item) => item.taskGuid !== taskGuid);

            this.addToGroup(targetGroup, task);
        } else {
            // перемещение из существующей группы
            const task = {
                ...this.initData.periods.groups[prevGroup].tasks.find((item) => item.taskGuid === taskGuid),
            };
            this.initData.periods.groups[prevGroup].tasks = this.initData.periods.groups[prevGroup].tasks.filter(
                (item) => item.taskGuid !== taskGuid
            );

            targetGroup === null
                ? this.initData.periods.ungroup.push({ ...task, period: 0 })
                : this.addToGroup(targetGroup, task);

            if (this.initData.periods.groups[prevGroup].tasks.length === 0) {
                // удаление, если группа стала пустой
                this.initData.periods.groups.splice(prevGroup, 1);
            }
        }

        this.calcDaysLeft();
    }

    private addToGroup(targetGroup, task) {
        if (this.currentGroups.includes(targetGroup)) {
            // если группа существует
            this.initData.periods.groups[targetGroup].tasks.push(task);
        } else {
            // если группы еще нет
            this.initData.periods.groups.push({
                period: 0,
                tasks: [task],
            });
        }
    }

    public changePeriod({ taskGuid, period, groupIdx }) {
        if (taskGuid) {
            this.initData.periods.ungroup.find((item) => item.taskGuid === taskGuid).period = period || 0;
        } else {
            this.initData.periods.groups[groupIdx].period = period;
        }
        this.calcDaysLeft();
    }

    public getDaysText(type: 'summary' | 'left') {
        const dayTitle = this.utility.getDayTitle(type === 'summary' ? this.initData.fullPeriod : this.daysLeft, {
            days_one: this.localizations.common.days_one,
            days_several: this.localizations.common.days_several,
            days_many: this.localizations.common.days_many,
        });

        let mainTitle: string;

        if (type === 'summary') {
            mainTitle = this.localizations.standards.periods.summary_period.replace('%s', this.initData.fullPeriod);
        } else {
            mainTitle =
                this.daysLeft >= 0
                    ? this.localizations.standards.periods.warn_left_days.replace('%s', this.daysLeft)
                    : this.localizations.standards.periods.warn_excess_by.replace('%s', this.daysLeft * -1);
        }
        return `${mainTitle} ${dayTitle}`;
    }
}
