<div class="sidebar-content sidebar-compressed">
    <div class="sidebar-category">
        <div class="category-title mb-10">
            <span>
                {{ localizations['sorting-bar'].name }}
            </span>
        </div>

        <!-- Выбор поля сортировки -->
        <div>
            <ng-container *ngFor="let item of sortItems; let fidx = index; let fst = first">
                <div class="has-feedback has-feedback-left form-group-lg mb-10"
                    *ngIf="!filterIsDisabled(item)">
                    <input id="sortItem-{{fidx}}"
                           class="radio"
                           type="radio"
                           name="sortItem-{{fidx}}"
                           [value]="item.field"
                           [checked]="selectedField === item.field"
                           (click)="sortBy(item.field, selectedOrder)">
                    <label for="sortItem-{{fidx}}">
                        {{ localizations['sorting-bar'][item.title]}}
                    </label>
                </div>
            </ng-container>
        </div>
        <div class="category-title mb-10 mt-15">
            <span>
                {{ localizations['sorting-bar'].order.name }}
            </span>
        </div>

        <!-- Выбор порядка сортировки -->
        <div>
            <div class="has-feedback has-feedback-left form-group-lg mb-10">
                <input id="sortOrder-ASC"
                       class="radio"
                       type="radio"
                       name="sortOrder-ASC"
                       value="ASC"
                       [checked]="selectedOrder === 'ASC'"
                       (click)="sortBy(selectedField, 'ASC')">
                <label for="sortOrder-ASC">
                    {{ localizations['sorting-bar'].order.asc }}
                </label>
            </div>

            <div class="has-feedback has-feedback-left form-group-lg mb-10">
                <input id="sortOrder-DESC"
                       class="radio"
                       type="radio"
                       name="sortItem-DESC"
                       value="DESC"
                       [checked]="selectedOrder === 'DESC'"
                       (click)="sortBy(selectedField, 'DESC')">
                <label for="sortOrder-DESC">
                    {{ localizations['sorting-bar'].order.desc }}
                </label>
            </div>
        </div>
    </div>
</div>
