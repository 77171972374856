<div *ngIf="isProcessSelectEntityType"
     [ngClass]="{'bg-primary-50 pt-10 pb-10 pl-20 pr-20': availableForAddEntityTypes.length > 0, 'alert alert-warning': availableForAddEntityTypes.length == 0}">
    <ng-container *ngIf="availableForAddEntityTypes.length > 0">
        <div class="text-bold mb-10">Выберите вид сведения:</div>
        <div class="radio-block" *ngFor="let type of availableForAddEntityTypes">
            <input type="radio" class="radio"
                   id="type{{type.code}}"
                   [value]="type"
                   [checked]="entity.type && type.code == entity.type.code"
                   (click)="selectEntityType(type)">
            <label for="type{{type.code}}">
                {{type.name}}
            </label>
        </div>
    </ng-container>
    <ng-container *ngIf="availableForAddEntityTypes.length == 0">
        <span class="text-bold">Внимание!</span> В настройке КНМ отсутствуют сведения
    </ng-container>
</div>
<ng-container *ngIf="!isProcessSelectEntityType">

    <!-- Сведение в виде кастомной формы -->
    <ng-container *ngIf="entity.kind && entity.kind === 'custom'">
        <common-appeal-entity-custom
            [entity]="entity"
            [mode]="mode"
            [task]="task"
            [allowEdit]="allowEdit"
            [appeal]="appeal"
            (onApply)="applyEntity()"
            (onEdit)="editEntity($event)"
            (onDelete)="deleteEntity($event)"
            (onCancel)="cancelEntity()"
            (onNeedSaveBeforePrint)="processingSaveBeforePrint($event)"
            #customEntityComponent
        ></common-appeal-entity-custom>
    </ng-container>

    <ng-container *ngIf="!entity.kind || entity.kind === 'static'">
        <common-appeal-entity-static
            [entity]="entity"
            [mode]="mode"
            [task]="task"
            [allowEdit]="allowEdit"
            [appeal]="appeal"
            [subservice]="appealService.subservice"
            (onApply)="applyEntity()"
            (onEdit)="editEntity($event)"
            (onDelete)="deleteEntity($event)"
            (onCancel)="cancelEntity()"
            (onNeedSaveBeforePrint)="processingSaveBeforePrint($event)"
            #staticEntityComponent>
        </common-appeal-entity-static>
    </ng-container>

</ng-container>
