/**
 * Компонент страницы настройки статусов СМЭВ-адаптера в области превью
 * @author Sergey Maksimenko
 * @since 07.03.2023
 */
import { Component, Input, OnInit } from '@angular/core';
import { SmevConfigurationService } from '../../services/smev-configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { OFF, SMEVCLIENT, UNIVERSAL } from '../../../../common/constants';
import { ToasterService } from '@evolenta/core';

@Component({
    selector: 'smev-configuration-preview-edit',
    templateUrl: 'smev-configuration-preview-edit.component.html',
    styleUrls: ['./smev-configuration-preview-edit.component.less'],
})
export class SmevConfigurationPreviewEditComponent implements OnInit {

    @Input() public adaptor;

    public constructor(
        private smevConfigurationService: SmevConfigurationService,
        private toaster: ToasterService,
        private translate: TranslateService,
    ) {
    }

    public initialized;
    public localizations;

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'smev_configuration',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this.initialized = true;
        });
    }

    public getAllowedStatuses() {
        const allowedStatuses = this.adaptor.options.split(',');

        if (!allowedStatuses.includes('OFF')) {
            allowedStatuses.push('OFF');
        }

        return allowedStatuses;
    }

    public getStatusClass() {
        const initialClass = 'badge text-uppercase ';
        switch (this.adaptor.adapter) {
            case OFF:
                return initialClass + 'bg-danger-300';
            case SMEVCLIENT:
                return initialClass + 'bg-success-300';
            case UNIVERSAL:
                return initialClass + 'bg-warning-300';
            default:
                throw new Error('Неизвестный статус СМЭВ-адаптера: ' + this.adaptor.adapter);
        }
    }

    public getStatusName(status?) {
        if (status) {
            return this.localizations.smev_configuration.statuses[status.toLowerCase()];
        }

        return this.localizations.smev_configuration.statuses[this.adaptor.adapter.toLowerCase()];
    }

    public async setStatus(status) {
        try {
            this.adaptor.adapter = status;
            await this.smevConfigurationService.save(this.adaptor);
            this.toaster.success(this.localizations.smev_configuration.notifications.success);
        } catch (error) {
            this.toaster.error(this.localizations.smev_configuration.notifications.error);
        }
    }

}
