import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService } from '@evolenta/core';

@Injectable()
export class KnoInspectorsResolve implements Resolve<any> {
    public constructor(
        private restService: RestService,
        private storage: StorageService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const params = [
            {
                field: 'sprOrganizations.id',
                operator: 'eq',
                value: currentOrganization.id,
            },
            {
                field: 'linkRolesMnemonics.roleMnemonic',
                operator: 'eq',
                value: 'inspector',
            },
        ];

        return this.restService.search('users', { search: { search: params }, size: 100 });
    }
}
