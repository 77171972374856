<div class="m-20"
     *ngIf="!editedCheckList">
    <ng-container *ngIf="tabs.length > 1">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of tabs">
                <li (click)="activeTab = tab.code"
                    [ngClass]="{'active': activeTab == tab.code}">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </ng-container>

    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false">
    </common-standard-block-with-additional-data>

    <div class="panel panel-shadow panel-small"
         *ngIf="activeTab === 'checkLists'">
        <div class="panel-heading">
            <h4 class="panel-title pt-10">
                <span class="mr-10">
                    {{metaData.name}}
                </span>
                <common-standard-block-status [block]="activeData"
                                              [small]="true"
                                              *ngIf="standardsService.reglament && !!metaData.checkListsData.allowEditInReglament"
                                              (onToggleEntity)="standardsService.toggleReglamentBlockEntity(activeData)"
                                              (onDeleteReglamentData)="standardsService.deleteReglamentBlock(activeData)">
                </common-standard-block-status>
            </h4>
            <div class="heading-elements">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.standards.check_list.add"
                        *ngIf="activeData.allowEdit && (!activeData.entity.checkLists || activeData.entity.checkLists.length === 0)"
                        (click)="add()">
                    <i inlineSVG="assets/icon-new-item.svg"></i>
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <div class="alert alert-warning no-margin"
                 *ngIf="!activeData.entity.checkLists || activeData.entity.checkLists.length === 0">
                {{ localizations.standards['check_list'].not_added }}
            </div>

            <table class="table table-sm">
                <tbody>
                <tr *ngFor="let item of activeData.entity.checkLists; let fst=first">
                    <td [ngClass]="{'no-border-top': fst}">
                        {{item.name}}
                    </td>
                    <td class="text-right no-padding-top no-padding-bottom"
                        [ngClass]="{'no-border-top': fst}">
                        <button type="button"
                                class="btn btn-default btn-sxs"
                                [title]="activeData.allowEdit ?
                                    localizations.common.edit : localizations.common.view"
                                (click)="edit(item)">
                            <i [ngClass]="{
                                    'icon-pencil': activeData.allowEdit,
                                    'icon-eye': !activeData.allowEdit}">
                            </i>
                        </button>
                        <button class="btn btn-default btn-sxs"
                                type="button"
                                [title]="localizations.common.delete"
                                *ngIf="activeData.allowEdit"
                                (click)="delete(item)">
                            <i class="icon-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="papne panel-shadow"
         *ngIf="activeTab === 'common'">
        <div class="panel-body">
            <div class="checkbox-block no-padding"
                 *ngIf="metaData.commonData.allowSendToMPSetting">
                <input id="sendToMP"
                       class="checkbox"
                       type="checkbox"
                       [checked]="activeData.entity.checkListsCommon.sendToMP"
                       (change)="activeData.entity.checkListsCommon.sendToMP = !activeData.entity.checkListsCommon.sendToMP">
                <label for="sendToMP">
                    {{ localizations.standards.send_to_mobile_app }}
                </label>
            </div>
        </div>
    </div>
</div>


<!-- Редактирование проверочного листа -->
<common-standard-check-list-edit [checkList]="editedCheckList"
                                 [allowEdit]="activeData.allowEdit"
                                 [entity]="activeData.entity"
                                 (onSave)="apply()"
                                 (onCancel)="cancel()"
                                 *ngIf="editedCheckList">
</common-standard-check-list-edit>
