import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    TemplateRef,
} from '@angular/core';
import {
    BsModalRef,
    BsModalService,
} from 'ngx-bootstrap';

@Component({
    selector: 'reason-modal',
    templateUrl: 'reason-modal.component.html',
})

export class ReasonModalComponent {
    @Input() public items; // Элементы списка для выбора
    @Input() public fields; // Массив текстовых полей
    @Input() public title; // Заголовок модального окна

    public modalRef: BsModalRef;
    @ViewChild('modal', { static: false }) public modal: TemplateRef<any>;

    @Output() public onComplete = new EventEmitter<{}>();
    @Output() public onCancel = new EventEmitter<{}>();

    public selectedReason = null;
    public fieldsData = {};

    public constructor(
        private modalService: BsModalService,
    ) {}

    public showModal() {
        this.modalRef = this.modalService.show(this.modal, {backdrop: 'static'});
    }

    public checkFields() {
        let correct = true;
        this.fields.forEach(field => {
            if (!this.fieldsData[field.code]) {
                correct = false;
            }
        });

        return correct;
    }

    public hideModal() {
        this.onCancel.emit();
        this.modalRef.hide();
    }

    public complete() {
        let result;
        if (this.items) {
            result = this.selectedReason;
        }
        if (this.fields) {
            if (this.fields.length === 1 && this.fields[0].onlyValueReturn) {
                result = this.fieldsData[this.fields[0].code];
            } else {
                result = {};
                this.fields.forEach(field => {
                    result[field.code] = this.fieldsData[field.code];
                });
            }
        }
        this.modalRef.hide();
        this.onComplete.emit(result);
    }
}
