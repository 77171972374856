<h4 class="no-margin-top"
    *ngIf="showName && !smallName">
    {{object.name}}
</h4>
<p class="text-bold text-size-large"
   *ngIf="showName && smallName">
    {{object.name}}
</p>
<div class="mb-10"
     *ngIf="showLabels">
    <span class="badge text-uppercase mr-10 bg-purple-300 mr-10">
        {{ localizations.common.object }}
    </span>
    <span class="badge text-uppercase mr-10 bg-info-300"
          *ngIf="object.type">
        {{object.type.name}}
    </span>
</div>
<p *ngIf="showName && !showLabels">
    <span class="text-muted mr-5">
        {{ localizations.objects.type }}
    </span>
    <span class="text-size-large text-bold">
        {{object.type ? object.type.name : localizations.common.not_specified_m }}
    </span>
</p>
<p *ngIf="showAddress && object.address && (object.address | json) != '{}'">
    <span class="text-muted mr-5">
        {{ localizations.common.address }}:
    </span>
        {{ object.address.fullAddress ?
            object.address.fullAddress :
            PrintUtilities.PrintAddressAsLine(object.address, true)
    }}
    </p>

<p class="no-margin-bottom">
    <span class="text-muted mr-5">
        {{ localizations.events.cadastral_number }}:
    </span>
    {{
        object.cadastralNumber ?
            object.cadastralNumber :
            localizations.common.not_specified_m
    }}
    <span class="text-muted mr-5 ml-10">
        {{ localizations.objects.region }}:
    </span>
    {{
        object.region ?
            object.region.name :
            localizations.common.not_specified_m
    }}
    <span class="text-muted mr-5 ml-10">
        {{ localizations.objects.latitude }}:
    </span>
    <span *ngIf="object.latitude">
        {{object.latitude}}
    </span>
    <ng-container *ngIf="!object.latitude">
        {{ localizations.common.not_specified_f }}
    </ng-container>
    <span class="text-muted mr-5 ml-10">
        {{ localizations.events.longitude }}:
    </span>
    <span *ngIf="object.longitude">
        {{object.longitude}}
    </span>
    <ng-container *ngIf="!object.longitude">
        {{ localizations.common.not_specified_f }}
    </ng-container>
</p>

<!-- Дополнительные данные -->
<div *ngIf="objectXsd && showDetail">
    <dynamic-form
        tabsMode="hidden"
        [xsd]="objectXsd"
        [edit]="false"
        [model]="object.additionalData"
        [saveInMainObject]="true"
        [textView]="true">
    </dynamic-form>
</div>
