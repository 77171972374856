<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Рейд</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указано</em>

            <span class="text-muted ml-10">Дата: </span>
            <ng-container *ngIf="entity.date && !entity.date.formatted">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date || entity.date && entity.date.formatted">не указано</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Номер</label>
                                <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" placeholder="Введите номер" #number="ngModel" required>
                                <div *ngIf="isProcessValidate && number.errors" class="validation-error-label">
                                    <span *ngIf="number.errors.required">Поле "Номер" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата составления</label>
                                <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Место составления</label>
                                <evolenta-address-gar
                                        name="place"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.place"
                                        required
                                        #place="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && place.errors" class="validation-error-label">
                                    <span *ngIf="place.errors.required">Поле "Место составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Дата начала осмотра</label>
                                <evolenta-datepicker name="startDate" [large]="true" [(ngModel)]="entity.startDate" [valueAsString]="true" #startDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && startDate.errors" class="validation-error-label">
                                    <span *ngIf="startDate.errors.required">Поле "Дата начала осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Дата окончания осмотра</label>
                                <evolenta-datepicker name="endDate" [large]="true" [(ngModel)]="entity.endDate" [valueAsString]="true" #endDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && endDate.errors" class="validation-error-label">
                                    <span *ngIf="endDate.errors.required">Поле "Дата окончания осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Характеристика обследуемой территории</label>
                                <textarea name="characteristicTerritory" class="form-control" [(ngModel)]="entity.characteristicTerritory" #characteristicTerritory="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && characteristicTerritory.errors" class="validation-error-label">
                                    <span *ngIf="characteristicTerritory.errors.required">Поле "Характеристика обследуемой территории" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Должностное лицо -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            property="employee"
                            [title]="'Должностное лицо'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                        <div *ngIf="isProcessValidate && employee.errors" class="validation-error-label">
                            <span *ngIf="employee.errors.nameRequired">Поле "Должностное лицо" обязательно для заполнения</span>
                            <span *ngIf="!employee.errors.nameRequired && employee.errors.positionRequired">Поле "Должность должностного лица" обязательно для заполнения</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Мероприятия, проводимые в ходе планового (рейдового) осмотра</label>
                                <textarea name="raidEvents" class="form-control" [(ngModel)]="entity.raidEvents" #raidEvents="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && raidEvents.errors" class="validation-error-label">
                                    <span *ngIf="raidEvents.errors.required">Поле "Мероприятия, проводимые в ходе планового (рейдового) осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Модели технических средств, применявшихся в рамках мероприятий</label>
                                <textarea name="hardwareModels" class="form-control" [(ngModel)]="entity.hardwareModels" #hardwareModels="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && hardwareModels.errors" class="validation-error-label">
                                    <span *ngIf="hardwareModels.errors.required">Поле "Модели технических средств, применявшихся в рамках мероприятий" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Выявленные обстоятельства</label>
                                <textarea name="circumstances" class="form-control" [(ngModel)]="entity.circumstances" #circumstances="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && circumstances.errors" class="validation-error-label">
                                    <span *ngIf="circumstances.errors.required">Поле "Выявленные обстоятельства" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Выявленные нарушения -->
                    <div class="mb-15">
                        <appeal-entity-catalogue
                            [large]="true"
                            [entity]="entity"
                            [property]="'nsiViolations'"
                            [catalogName]="'nsiViolations'"
                            [title]="'Выявленные нарушения обязательных требований'"
                            [placeholder]="'Укажите обязательные требования'">
                        </appeal-entity-catalogue>
                        <div *ngIf="isProcessValidate && nsiViolations.errors" class="validation-error-label">
                            <span *ngIf="nsiViolations.errors.required">Поле "Выявленные нарушения обязательных требований" обязательно для заполнения</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Сведения о лицах, допустивших нарушения</label>
                                <textarea name="violators" class="form-control" [(ngModel)]="entity.violators" #violators="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && violators.errors" class="validation-error-label">
                                    <span *ngIf="violators.errors.required">Поле "Сведения о лицах, допустивших нарушения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Перечень документов, прилагаемых к акту</label>
                                <textarea name="attachedDocuments" class="form-control" [(ngModel)]="entity.attachedDocuments" #attachedDocuments="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && attachedDocuments.errors" class="validation-error-label">
                                    <span *ngIf="attachedDocuments.errors.required">Поле "Перечень документов, прилагаемых к акту" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
