import { Injectable } from '@angular/core';
import { RestService, StorageService } from '@evolenta/core';
import * as moment from 'moment';

@Injectable()
export class CalculateService {
    public methods = [
        {
            code: 'getUinByOrganizationUrn',
            name: 'Расчет УИН на основе УРН организации',
            params: [],
        },
    ];

    public constructor(
        private storage: StorageService,
        private rest: RestService,
    ) {
    }

    /**
     * Формирование УИН на основе УРН органзации
     */
    public getUinByOrganizationUrn() {
        const currentOrganization = this.storage.getItem('currentOrganization');
        if (currentOrganization.originatorId) {
            return Promise.resolve(this._generateUinByUrn(currentOrganization.originatorId));
        } else {
            return this.rest.search('unitsMeta', {search: {search: [{field: 'unitId', operator: 'eq', value: currentOrganization._id}]}}).then(data => {
                if (data && data.length > 0) {
                    const orgMeta = data[0];
                    const urnItem = orgMeta.items.find(item => item.code === 'originatorId');
                    if (urnItem && urnItem.value) {
                        currentOrganization.originatorId = urnItem.value;
                        this.storage.setItem('currentOrganization', currentOrganization);

                        return Promise.resolve(this._generateUinByUrn(urnItem.value));
                    } else {
                        return Promise.reject('В настройках организации отсутствует параметр УРН(originatorId). УИН не может быть сформирован.');
                    }
                }
            });
        }
    }

    private _generateUinByUrn(senderIdentifier) {
        const arr = senderIdentifier.split('');
        let result = 0;
        arr.forEach((symbol, index) => {
            result += this._getNum(symbol, arr.length - 1 - index);
        });
        const resultStr = result.toString().padStart(8, '0'); // A: УРН в цифровом формате 00218d -> 00008589
        const random = this._getRandomInt(100, 199); // случайное трехзначное число
        const currentDateInNumber = moment().format('x'); // Дата + время в миллисеккундах (13 символов)
        const customUniquePart = currentDateInNumber + random; // B: Уникальная часть сформированная по уникальному алгоритму (дата в миллисекундах + рандомное трехзначное число)
        const uin = resultStr + customUniquePart; // Основная часть УИН
        const uinParts = uin.split('');
        const controlDigit = this._getUinControlDigit(uinParts, 1);

        return uin + controlDigit;
    }

    private _getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min)) + min;
    }

    private _getUinControlDigit(arrayOfDigits, idx, isFinish = false) {
        let sum = 0;
        arrayOfDigits.forEach(symbol => {
            if (idx > 10) {
                idx = 1;
            }
            sum += parseInt(symbol, 10) * idx;
            idx ++;
        });
        let ostatok = sum % 11;
        if (ostatok === 10) {
            if (isFinish) {
                ostatok = 0;
            } else {
                ostatok = this._getUinControlDigit(arrayOfDigits, 3, true);
            }
        }

        return ostatok;
    }

    private _getNum(symbol, power) {
        let number;
        switch (symbol) {
            case 'A':
            case 'a':
                number = 10;
                break;
            case 'B':
            case 'b':
                number = 11;
                break;
            case 'C':
            case 'c':
                number = 12;
                break;
            case 'D':
            case 'd':
                number = 13;
                break;
            case 'E':
            case 'e':
                number = 14;
                break;
            case 'F':
            case 'f':
                number = 15;
                break;
            default:
                number = parseInt(symbol, 10);
                break;
        }
        const resultPower = Math.pow(16, power);

        return number * resultPower;
    }
}
