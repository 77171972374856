import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppealValidateService } from '../../../modules/knm/appeals/appeal-validate.service';

@Component({
    selector: 'element-edit-tabs',
    templateUrl: 'element-edit-tabs.component.html',
})

export class ElementEditTabsComponent implements OnInit, OnChanges {
    @Input() public tabs; // массив описаний вкладок
    @Input() public active;
    @Input() public elementId; // Идентификатор элемента (для валидации)
    @Input() public type; // Тип элемента: услуги, объекты, документы (для валидации)

    @Output() public onActivateTab = new EventEmitter<object>();

    public constructor(
        private validateService: AppealValidateService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        let firstIndex = null;
        let existActive = false;
        this.tabs.forEach((tab, idx) => {
            if (tab.visible && !firstIndex) {
                firstIndex = idx;
            }
            if (this.active && tab.code === this.active) {
                tab.active = true;
                tab.visible = true;
                existActive = true;
            }
        });
    }

    /**
     * Обработка изменения активной вкладки из родительского компонента
     * @param changes - массив изменений
     */
    public ngOnChanges(changes) {
        if (changes && changes.active) {
            // отслеживание изменения активной вкладки из родительского компонента
            if (changes.active.currentValue) {
                const tab = this.tabs.find(item => item.code === changes.active.currentValue);
                if (tab) {
                    this.setActive(tab);
                }
            }
        }
    }

    /**
     * Корректировка статуса активности для массива вкладок с учетом передаваемой активной вкладки
     * @param tab - активизируемая вкладка
     */
    public setActive(tab) {
        this.tabs.forEach(item => {
            item.active = item.code === tab.code;
        });
    }

    /**
     * Передача кода активной вкладки родительскому компоненту
     * @param tab
     */
    public activate(tab) {
        this.onActivateTab.emit(tab.code);
    }

    /**
     * Получение числа ошибок для инициализации их количества на вкладках
     * @param tab - обрабатываемая вкладка
     */
    public getErrorsCount(tab) {
        return this.validateService.getTabErrorsCount(this.elementId, this.type, tab.code);
    }
}
