import { Injectable } from '@angular/core';
import { HttpService, RestService, StorageService } from '@evolenta/core';
import { ObjectUtilities, PrintUtilities } from '@evolenta/utilities';
import { AppealData } from '../../appeal.data';
import { AppealService } from '../../appeal.service';
import { Config } from '../../../../../common/services/config';
import { Observable, from } from 'rxjs';

@Injectable()
export class AppealEntitiesService {
    public dictionaries = {};

    public entityTypes = AppealData.entityTypes;

    public currentOrganization = this.storage.getItem('currentOrganization');

    public registerDocParams;

    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private appealService: AppealService,
        private httpService: HttpService,
    ) {
    }

    public getUsers() {
        const params = [
            {
                field: 'sprOrganizations.id',
                operator: 'eq',
                value: this.currentOrganization.id,
            },
            {
                field: 'active',
                operator: 'eq',
                value: true,
            },
        ];

        return this.rest.search('users', { search: { search: params }, prj: 'usersList', sort: 'name,ASC', size: 200 });
    }

    public getDocumentTypes(): Observable<any> {
        const promise = this.rest.search('documentType');

        return from(promise);
    }

    /**
     * Сведения о журнале учета проверок
     */
    public getAuditLog() {
        return this.rest.search('auditLog');
    }

    /**
     * Меры по устранению нарушения
     */
    public getActionTakenItems(): Observable<any> {
        const promise = this.rest.search('actionTaken');

        return from(promise);
    }

    /**
     * Типы лиц допустившего нарушение
     */
    public getInfringerTypes(): Observable<any> {
        const promise = this.rest.search('infringerType');

        return from(promise);
    }

    /**
     * Возврат адреса в виде строки
     * @param addressItems
     * @returns {string}
     */
    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    /**
     * Поиск неустраненных нарушений в рассматриваемой проверке
     * @param appeal
     * @param objectId
     */
    public findCheckListFailure(appeal, objectId = null) {
        let checkLists = [];
        if (appeal.checkLists && appeal.checkLists.length > 0) {
            checkLists = appeal.checkLists.filter(checkList => {
                return checkList.questions.some(item => item.result && item.result.answer === 'no' && item.mandatoryReqs);
            });
            if (objectId) {
                checkLists = appeal.checkLists.filter(checkList => checkList.objectGuid === objectId);
            }

            return checkLists.map(check => ({
                subjectGuid: check.subjectGuid,
                objectGuid: check.objectGuid,
                questions: check.questions
                    .filter(item => item.result && item.result.answer === 'no' && item.mandatoryReqs)
                    .map(item => {
                        return {
                            text: item.text,
                            mandatoryReqs: item.mandatoryReqs ? item.mandatoryReqs : {},
                            nPA: item.nPA ? item.nPA : {},
                        };
                    }),
            }));
        }

        return checkLists;
    }

    /**
     * Внеплановая проверка
     * @param appeal
     */
    public isKndTypeUnplan(appeal) {
        return appeal.subservice && appeal.subservice.kndInfo.kndType.code === '1';
    }

    /**
     * Плановая проверка
     * @param appeal
     */
    public isKndTypePlan(appeal) {
        return appeal.subservice && appeal.subservice.kndInfo.kndType.code === '0';
    }

    /**
     * Поиск всех сведений по коду
     * @param appeal
     * @param entityCode
     */
    public findEntityAll(appeal, entityCode) {
        let entity = [];
        if (appeal.subservice && appeal.subservice.entities && this.entityTypes[entityCode]) {
            entity = appeal.subservice.entities.filter(item => item.type === this.entityTypes[entityCode].code);
        }

        return entity;
    }

    /**
     * Поиск последнего сведения по коду
     * @param appeal
     * @param entityCode
     */
    public findEntity(appeal, entityCode) {
        let entity;
        if (appeal.subservice && appeal.subservice.entities && this.entityTypes[entityCode]) {
            entity = appeal.subservice.entities.filter(item => item.type === this.entityTypes[entityCode].code);
            if (entity.length !== 0) {
                entity = entity[entity.length - 1];
            }
        }

        return entity;
    }

    /**
     * Возвращает адрес первого объекта первого субъекта
     * @param appeal
     */
    public findObjectAddress(appeal) {
        if (appeal.objects && appeal.objects.length > 0) {
            if (appeal.objects[0].objects && appeal.objects[0].objects.length > 0) {
                return appeal.objects[0].objects[0].address ? appeal.objects[0].objects[0].address : null;
            }
        }

        return null;
    }

    /**
     * Возвращает представителя субъекта
     * @param appeal
     */
    public findPerson(appeal) {
        let personInfo = {
            name: '',
            position: '',
        };
        if (appeal.objects) {
            const findIndex = appeal.objects.findIndex(object => object.specialTypeId === 'ulApplicant');
            if (findIndex !== -1 && appeal.objects[findIndex].data && appeal.objects[findIndex].data.person) {
                const objectData = appeal.objects[findIndex].data;
                personInfo = {
                    name: objectData.person.lastName + ' ' + objectData.person.firstName + ' ' + objectData.person.middleName,
                    position: objectData.personInOrg.position ? objectData.personInOrg.position : '',
                };
            }
        }

        return personInfo;
    }

    private async _getRegisterDocParams() {
        if (this.registerDocParams) {
            return this.registerDocParams;
        }

        const data = await this.rest.search('settings', {search: {search: [{field: 'name', operator: 'eq', value: 'registerDocParams'}]}});
        if (data && data.length > 0) {
            this.registerDocParams = data[0].value;
        } else {
            this.registerDocParams = {};
        }

        return this.registerDocParams;
    }

    public async processingEntitiesForSaveInReestr(taskData) {
        await this._getRegisterDocParams();
        const promises = [];
        const appeal = this.appealService.appeal;
        const subservice = this.appealService.subservice;
        const processingEntities = this.registerDocParams.map(item => item.code);
        if (!(taskData.entities && taskData.entities.length)) {
            return;
        }

        const entitiesSperIds = taskData.entities.map(item => item.sperId);
        const usedEntitiesCodes = subservice.entities.filter(item => entitiesSperIds.indexOf(item.sperId) !== -1 && processingEntities.indexOf(item.code) !== -1).map(item => item.code);
        if (!usedEntitiesCodes.length) {
            return;
        }

        const usedEntities = appeal.subservice.entities.filter(item => usedEntitiesCodes.indexOf(item.type) !== -1);
        if (usedEntities.length) {
            usedEntities.forEach(entity => {
                const data = this.prepareEntityDataForSaveInReestr(entity);
                promises.push(this.createRegistryDocument(data));
            });
        }

        return Promise.all(promises);
    }

    public createRegistryDocument(data) {
        return this.httpService.post(Config.server + Config.app + Config.api + 'registry/registerDoc', data);
    }

    public prepareEntityDataForSaveInReestr(entity) {
        const params = this.registerDocParams.find(item => item.code === entity.type);
        const data = {
            mainId: this.appealService.appeal._id,
            collectionName: 'appeals',
            entityId: entity.guid,
            entityName: entity.type,
            entityDescription: entity.name,
            unitId: this.appealService.appeal.unit.id,
            docSeria: null,
            docNumber: null,
            docDate: null,
            regNumber: null,
            params: {},
        };

        params.fields.forEach(field => {
            const savePlace = field.saveToParams ? data.params : data;
            let dataObject;

            if (field.fromAppeal) {
                const appealEntity = this.appealService.appeal[field.appealEntity];

                if (appealEntity && field.onlyFirstElement) {
                    dataObject = appealEntity[0];
                }
            } else if (field.fromAppealLinksData) {
                const appealLinksData = this.appealService.appealLinksData;

                if (appealLinksData && appealLinksData[field.linkEntity]) {
                    dataObject = appealLinksData[field.linkEntity];
                }
            } else {
                dataObject = entity;
            }

            let fieldValue = null;

            if (dataObject) {
                fieldValue = ObjectUtilities.GetPropertyByPath(dataObject, field.dataField);

                if (field.saveAsString) {
                    fieldValue = fieldValue.toString();
                }
            }

            savePlace[field.reestrField] = fieldValue;
        });

        return data;
    }
}
