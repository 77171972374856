import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestService, StorageService } from '@evolenta/core';
import { CommonUtilities, PrintUtilities } from '@evolenta/utilities';
import { AppealEntityCheckStatement } from './models/appeal-entity-check-statement';
import { AppealEntitiesService } from '../../appeal-entities.service';
import * as moment from 'moment';
import * as _ from 'lodash-es';

/**
 * Решение прокуратуры
 */
@Component({
    selector: 'appeal-entity-check-statement',
    templateUrl: 'appeal-entity-check-statement.component.html',
    styles: [
        '.input-group-spr { position: relative; font-size: 0; white-space: nowrap; }',
        '.input-group-spr > span { display: inline-block; font-weight: normal; white-space: nowrap; padding: 8px 8px; font-size: 13px; }',
    ],
})
export class AppealEntityCheckStatementComponent implements OnInit {
    @Input() public entity: AppealEntityCheckStatement;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public isCheckPlan = false;
    public isCheckUnplan = false;

    public currentUser = this.storage.getItem('user');
    public currentOrganization = this.storage.getItem('currentOrganization');

    public isChangeSubject = false;
    public isChangeObjects = false;
    public isChangeProsecutor = false;

    public currentSubject;
    public currentObjects = [];

    public orderData;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public restService: RestService,
        public storage: StorageService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.initCommon();
        const isDemoStend = this.storage.getItem('demoStend').isDemo;
        if (isDemoStend) {
            this.initDemo();
        } else {
            this.init();
        }
    }

    public initCommon() {
        const findOrder = this.appeal.subservice.entities.find(item => item.type === 'order');
        if (findOrder) {
            this.orderData = findOrder;
        }
        if (!this.entity.subject && this.appeal.objects.length === 1) {
            this.entity.subject = this.appeal.objects[0].guid;
        }
        if (!this.entity.objects) {
            this.entity.objects = [];
        }
        this._getCurrentSubject();
        this._getCurrentObjects();

        if (!this.entity.prosecutor) {
            this.restService.find('organizations', this.currentOrganization._id).then((data: any) => {
                if (data.prosecutor) {
                    this.entity.prosecutor = data.prosecutor;
                }
            });
        }
    }

    public init() {
        if (!this.entity.materials) {
            this.entity.materials = [];
        }
        if (!_.get(this.entity, 'employee.name.0.id', null)) {
            this.entity.employee = {
                name: [{id: this.currentUser.id, text: this.currentUser.name}],
                position: this.currentUser.position ? this.currentUser.position : '',
            };
        }
    }

    private _getCurrentSubject() {
        if (this.entity.subject) {
            this.currentSubject = this.appeal.objects.find(item => item.guid === this.entity.subject);
        }
    }

    private _getCurrentObjects() {
        if (this.entity.objects && this.entity.objects.length) {
            this.currentObjects = this.currentSubject.objects.filter(item => this.entity.objects.indexOf(item.guid) !== -1);
        }
    }

    public selectSubject(subject) {
        if (this.entity.subject && this.entity.subject.guid !== subject.guid) {
            this.entity.objects = [];
            this.currentObjects = [];
        }
        this.entity.subject = subject.guid;
        this._getCurrentSubject();
        this.isChangeSubject = false;
    }

    public checkUseObject(object) {
        return this.entity.objects && this.entity.objects.indexOf(object.guid) !== -1;
    }

    public changeSelectObject(object) {
        const findIndex = this.entity.objects.indexOf(object.guid);
        if (findIndex !== -1) {
            this.entity.objects.splice(findIndex, 1);
            const idx = this.currentObjects.findIndex(item => item.guid === object.guid);
            this.currentObjects.splice(idx, 1);
        } else {
            this.entity.objects.push(object.guid);
            this.currentObjects.push(object);
        }
    }

    public initDemo() {
        if (!this.entity.createDate) {
            this.entity.createDate = this.appeal.dateRegister;
        }
        if (!this.entity.createTime) {
            this.entity.createTime = moment(this.appeal.dateRegister).format('HH:mm');
        }
        if (!this.entity.prosecutor) {
            this.restService.search('nsiErpProsecutors', {search: {search: [{field: 'code', operator: 'in', value: ['1030350000']}]}}).then(data => {
                if (data && data.length > 0) {
                    this.entity.prosecutor = data[0];
                }
            });
        }
        if (!_.get(this.entity, 'materials.0.text', null)) {
            this.entity.materials = [{
                guid: CommonUtilities.GenerateGuid(),
                text: 'Распоряжение (приказ) о проведении внеплановой выездной проверки',
            }];
        }
        if (!_.get(this.entity, 'employee.name.0.id', null)) {
            this.entity.employee = {
                name: [{id: this.currentUser.id, text: this.currentUser.name}],
                position: this.currentUser.position ? this.currentUser.position : '',
            };
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }
}
