import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService } from '@evolenta/core';

@Injectable()
export class SettingsResolve implements Resolve<any> {
    public constructor(private rest: RestService) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const settingId = route.params['settingId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create');

        if (isCreateAction.length > 0) {
            return {};
        } else {
            return this.rest.find('settings', settingId);
        }
    }
}
