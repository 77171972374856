<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <!-- Функциональные кнопки-->
    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="selectionService.isProcessSelect"
            (click)="returnToOperation()">
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5">
            {{selectionService.selectedItems?.length}}
        </span>
    </button>

    <button class="btn btn-primary btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            *ngIf="allowAdd && !appealService.groupOperation && !appealService.isProcessSelectAppealsForPacket && !selectionService.isProcessSelect"
            [disabled]="!accessService.hasAccess([permissions.Appeal_Create, permissions.Appeal_Status_Draft], true) || currentOrganization.denyAppealCreate"
            (click)="createNewDeal()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ appealService.getElementTitle('createAppeal', localizations.appeals.new_knm )}}
    </button>

    <div class="navbar-title-menu-width mt-10 pt-10"
         *ngIf="!allowAdd">
        <h4>
            {{ appealService.getElementTitle('allAppeals', localizations.appeals.checks )}}
            <span class="badge badge-primary text-uppercase ml-10">
                {{totalAppealsCount}}
            </span>
        </h4>
    </div>

    <button class="btn btn-primary btn-main btn-labeled btn-labeled-right text-uppercase"
            type="button"
            *ngIf="appealService.groupOperation"
            (click)="createNewObjectByShortAppealList('groupOperation')">
        <b>
            <i class="icon-checkmark3"></i>
        </b>
        {{ localizations.appeals.group_operation }}
        <span class="badge bg-white ml-5 text-blue"
            *ngIf="appealService.groupOperation.appeals">
            {{appealService.groupOperation.appeals.length}}
        </span>
    </button>

    <button class="btn btn-primary btn-main btn-labeled btn-labeled-right text-uppercase"
            type="button"
            *ngIf="appealService.isProcessSelectAppealsForPacket"
            (click)="returnToPacket()">
        <b><i class="icon-arrow-right16"></i></b>
        {{ localizations.appeals.back_to_packet }}
        <span class="badge bg-white ml-5 text-blue"
            *ngIf="appealService.packet.appeals">
            {{appealService.packet.appeals.length}}
        </span>
    </button>

    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   [placeholder]="localizations.common.search_dots"
                   type="text"
                   [(ngModel)]="globalSearch"
                   (keypress)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData(REPLACE)">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <!-- Кнопки авторизации + доп.меню -->
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<!-- Основное содержание компонента -->
<div class="page-container">

    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category"
                 *ngIf="allowAdd">
                <div class="category-title no-padding-bottom no-border">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-bold">
                            {{ appealService.getElementTitle('allAppeals', localizations.appeals.checks )}}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{totalAppealsCount}}
                            </span>
                        </span>
                    </h4>
                </div>
            </div>

            <evolenta-scrollbar scrollClass="sidebar sidebar-secondary sidebar-default pb-20">
                <!-- Панель сортировки -->
                <sorting-bar collection="appeals"
                             [disabledFilters]="disabledFilters"
                             [selectedField]="DEFAULT_SPO_SORT_FIELD"
                             [selectedOrder]="DESCENDING_ORDER"
                             [defaultSortField]="DEFAULT_SPO_SORT_FIELD"
                             (fieldChange)="setSortOptsAndLoadData($event)">
                </sorting-bar>

                <!-- Панель фильтрации -->
                <filters-bar [filters]="filtersPanelItems"
                             [afterHeader]="true"
                             [base]="baseSearch"
                             [moduleBaseUrl]="moduleBaseUrl"
                             [filterName]="appealService.getAppealsCollection() + 'Filter'"
                             (onApplyFilters)="loadData(REPLACE)"
                             #filtersPanel>
                </filters-bar>
            </evolenta-scrollbar>
        </div>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat no-border-top">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData(ADD)">
                            <ng-container *ngIf="appeals.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of appeals; trackBy:indexedAppeals; let lst = last">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                            [ngClass]="{'active': appeal && appeal._id === item._id }">
                                            <div class="checkbox-block mt-20 mb-20 ml-20"
                                                 *ngIf="selectionService.isProcessSelect">
                                                <input class="checkbox"
                                                       type="checkbox"
                                                       id="selectAppeal{{item._id}}"
                                                       [checked]="item.selected"
                                                       (click)="dblclickByAppeal(item)">
                                                <label for="selectAppeal{{item._id}}"
                                                       style="top: -18px">
                                                </label>
                                            </div>
                                            <div class="list-item full-width"
                                                 (click)="clickByAppeal(item)"
                                                 (dblclick)="dblclickByAppeal(item)">
                                                <div style="align-self: flex-start">
                                                    <div class="list-item-status-rectangle text-uppercase display-inline-block"
                                                        [ngClass]="getStatusProperty(item, 'background')">
                                                        {{ getStatusName(item) }}
                                                    </div>
                                                    <div class="list-item-status-rectangle bg-danger-300 text-uppercase display-inline-block ml-5"
                                                         *ngIf="item.fromEpgu">
                                                        {{ localizations.common.epgu }}
                                                    </div>
                                                    <div class="list-item-status-rectangle bg-orange-300 text-uppercase display-inline-block ml-5"
                                                         *ngIf="item.fromFns">
                                                        {{ localizations.organizations.fns }}
                                                    </div>
                                                    <!-- Индикаторы: истекает, просрочен, есть результаты запросов -->
                                                    <div class="notify-label display-inline-block ml-5"
                                                        *ngIf="item.notify && item.notify.length > 0">
                                                        <ng-container *ngFor="let notify of item.notify">
                                                            <span class="badge {{notify.theme}}"
                                                                  *ngIf="!notify.icon"
                                                                [title]="notify.text">&nbsp;
                                                            </span>
                                                            <span class="{{notify.theme}}"
                                                                  *ngIf="notify.icon"
                                                                  [title]="notify.text">
                                                                <i class="{{notify.icon}}"></i>
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="list-item-data"
                                                     style="white-space: inherit">
                                                    <h6 class="text-bold mb-5 no-margin-top">
                                                        {{item.subservice ? item.subservice.shortTitle : ''}}
                                                    </h6>
                                                    <p class="no-margin">
                                                        <span class="text-slate">№ </span>
                                                        <span>
                                                            {{item.shortNumber ? item.shortNumber :
                                                                item.number ? item.number : 'n/a'}}
                                                        </span>

                                                        <ng-container *ngIf="item.dateRegister">
                                                            {{ localizations.common.from }} {{item.dateRegister | date:'shortDate'}}
                                                        </ng-container>

                                                        <ng-container *ngIf="item.dateCreation">
                                                            <span class="text-muted ml-10">
                                                                {{ localizations.common.date }}:
                                                            </span>
                                                            {{item.dateCreation | date: 'shortDate'}}
                                                        </ng-container>
                                                        <ng-container *ngIf="item.executor">
                                                            <span class="text-muted ml-10">
                                                                {{ localizations.common.executor }}:
                                                            </span>
                                                            {{item.executor.name}}
                                                        </ng-container>
                                                    </p>

                                                    <p class="no-margin-bottom"
                                                       *ngIf="item.subjects?.length">
                                                        <!-- Список объектов дела -->
                                                        <span class="objects-list text-semibold no-margin-top no-margin-bottom display-inline">
                                                            <ng-container
                                                                *ngFor="let object of item.subjects; let idx = index">
                                                                {{object.shortHeader}}
                                                                <span *ngIf="idx != item.subjects.length - 1">,</span>
                                                            </ng-container>
                                                        </span>
                                                    </p>

                                                    <ng-container *ngIf="item.subservices && item.subservice && item.subservice.kndInfo && item.subservice.kndInfo.kndType">
                                                        <p class="no-margin-bottom type">
                                                            {{ item.subservice.kndInfo.kndType.name}}
                                                        </p>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="panel panel-shadow m-15 p-15"
                                 *ngIf="appeals.length == 0">
                                <div class="panel-body text-center"
                                     *ngIf="!initialized">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        Загрузка...
                                    </h5>
                                </div>
                                <div class="panel-body text-center"
                                     *ngIf="initialized">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing }}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations.appeals.try_to_adjust_filters }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-warning alert-styled-left alert-bordered"
                         *ngIf="!appeal">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.appeals.select_element_on_the_left_for_details }}
                    </div>
                    <common-appeal-preview [appeal]="appeal"
                                           [metaReglament]="metaReglament"
                                           *ngIf="appeal && appeal.subservice">
                    </common-appeal-preview>
                </div>
            </div>
        </div>
    </div>
</div>
