import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService, StorageService, TranslateService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';

@Component({
    selector: 'subject-preview',
    templateUrl: 'subject-preview.component.html',
})
export class SubjectPreviewComponent implements OnInit {
    @Input() public subject: any;
    public objectsList = [];

    public subjectAppeals = [];

    public panels = {
        common: true,
        appeals: true,
        objects: true,
        kndKinds: true,
    };

    public userOrganizations = [];
    public moduleBaseUrl;
    public workModeUrl;
    public kndKinds = [];
    public currentOrganization = this.storage.getItem('currentOrganization');
    public localizations;

    public constructor(
        private rest: RestService,
        private route: ActivatedRoute,
        private router: Router,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe((urlPath) => {
            this.workModeUrl = urlPath[urlPath.length - 1].path;
        });
        this.rest
            .find('subjects', this.subject.globalSubjectId ? this.subject.globalSubjectId : this.subject._id)
            .then((person: any) => {
                this.subject = person;

                if (this.subject.appeals && this.subject.appeals.length > 0) {
                    this.subjectAppeals = this.subject.appeals;
                }
                this.getRiskCategories();
            });

        const params = {
            size: 500,
            search: {
                search: [
                    {
                        orSubConditions: [{ field: 'linkedSubjects._id', operator: 'eq', value: this.subject._id }],
                    },
                ],
            },
        };

        this.rest.search('objects', params).then((response) => (this.objectsList = response));
    }

    public _loadTranslations() {
        this.translate.get(['common', 'subjects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getRiskCategories() {
        if (this.subject.specialTypeId) {
            const kndKindIds = this.currentOrganization.kndKinds
                .filter(
                    (item) =>
                        item.subjectTypes && item.subjectTypes.find((elm) => elm.id === this.subject.specialTypeId)
                )
                .map((item) => item.id);
            if (kndKindIds.length > 0) {
                const search = [
                    {
                        field: '_id',
                        operator: 'in',
                        value: kndKindIds,
                    },
                ];
                this.rest
                    .search('nsiErpKnmKinds', { search: { search: search }, size: 100, prj: 'dictionaryCodeName' })
                    .then((kndKinds) => {
                        const params = [
                            {
                                field: 'entityId',
                                operator: 'eq',
                                value: this.subject._id,
                            },
                            {
                                field: 'unitId',
                                operator: 'eq',
                                value: this.currentOrganization._id,
                            },
                            {
                                field: 'entityName',
                                operator: 'eq',
                                value: 'subject',
                            },
                            {
                                field: 'kndKindId',
                                operator: 'in',
                                value: kndKindIds,
                            },
                        ];
                        this.rest
                            .search('entitiesKndKnoData', {
                                search: { search: params },
                                size: 100,
                                prj: 'knoKndKindRiskCategory',
                            })
                            .then((data) => {
                                kndKinds.forEach((kndKind) => {
                                    const find = data.find((item) => item.kndKindId === kndKind._id);
                                    if (find) {
                                        kndKind.riskCategory = find.riskCategory;
                                    }
                                    this.kndKinds.push(kndKind);
                                });
                            });
                    });
            }
        }
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public edit() {
        this.router.navigate([this.moduleBaseUrl, 'subjects', this.workModeUrl, 'edit', this.subject._id]);
    }
}
