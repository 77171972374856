import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService } from '@evolenta/core';

@Injectable()
export class RoleResolve implements Resolve<any> {
    public constructor(private restService: RestService) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const roleId = route.params['roleId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create');
        const promises = [];

        if (isCreateAction.length > 0) {
            // Создание
            promises.push({});
        } else if (roleId) {
            // Редактирование
            promises.push(this.restService.find('roles', roleId));
        }

        promises.push(this.restService.search('permissions', { size: 1000 }));

        return Promise.all(promises);
    }
}
