<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">

    <!-- Функциональные кнопки-->
    <button class="btn btn-primary btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            *ngIf="!selectionService.isProcessSelect"
            (click)="create()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations['knd-linds'].new }}
    </button>

    <button class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            *ngIf="selectionService.isProcessSelect"
            (click)="returnToOperation()">
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5 text-success">
            {{ selectionService.selectedItems.length }}
        </span>
    </button>

    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   type="text"
                   [placeholder]="localizations.common.search_dots"
                   [(ngModel)]="globalSearch"
                   (keypress)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    *ngIf="globalSearch"
                    (click)="clearSearch()">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData('replace')">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <!-- Кнопки авторизации + доп.меню -->
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-semibold">
                            {{ localizations.organizations.objects_kinds }}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{totalCount}}
                            </span>
                        </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li>
                            <i class="icon-cog3"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Панель фильтрации -->
            <filters-bar filterName="personsFilter"
                                  [filters]="filtersPanelItems"
                                  [afterHeader]="true"
                                  (onApplyFilters)="loadData('replace')"
                                  #filtersPanel></filters-bar>
        </div>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData('add')">
                            <ng-container *ngIf="objectTypes.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of objectTypes; let lst = last">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                            [ngClass]="{'active': objectType?._id === item._id }">
                                            <div class="checkbox-block mt-20 mb-20 ml-20"
                                                 *ngIf="selectionService.isProcessSelect">
                                                <input id="selectObjectType{{ item.guid }}"
                                                       class="checkbox"
                                                       type="checkbox"
                                                       [checked]="item.selected"
                                                       (click)="dblClick(item)">
                                                <label for="selectObjectType{{ item.guid }}"
                                                       style="top: -18px">
                                                </label>
                                            </div>
                                            <div class="list-item"
                                                 [style.width] = "selectionService.isProcessSelect ?
                                                 'calc(100% - 50px)' :
                                                 '100%'"
                                                 (click)="click(item)"
                                                 (dblclick)="dblClick(item)">
                                                    <div class="list-item-data"
                                                         [ngStyle]="{'width': 'calc(100% - 55px)', 'whiteSpace': 'normal'}">
                                                        <h6 class="text-bold no-margin">
                                                            <span class="text-slate mr-10">
                                                                {{item.code}}
                                                            </span>
                                                            {{item.name}}
                                                        </h6>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="panel panel-shadow m-20 p-15"
                                 *ngIf="objectTypes.length == 0">
                                <div class="panel-body text-center">
                                    <div class="icon-object border-primary text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing }}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations['knd-kinds'].try_to_adjust_filters }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-warning alert-styled-left alert-bordered"
                         *ngIf="!objectType">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.common.for_details_select_item_on_the_left }}
                    </div>
                    <object-type-preview [objectType]="objectType"
                                         *ngIf="previewMode && objectType">
                    </object-type-preview>
                </div>
            </div>
        </div>
    </div>
</div>
