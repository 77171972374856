import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService, UserMessagesService } from '@evolenta/core';

@Injectable()
export class NotificationsSettingsResolve implements Resolve<any> {
    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private userMessagesService: UserMessagesService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.userMessagesService.kinds) {
            return this.rest.search('notificationTypes').then((kinds) => {
                this.userMessagesService.kinds = kinds;
                return this.getData(route);
            });
        } else {
            return this.getData(route);
        }
    }

    public getData(route) {
        const isGlobal = !!route.data.global;
        const currentOrganization = this.storage.getItem('currentOrganization');
        const params = [];

        if (isGlobal) {
            params.push({
                field: 'isGlobal',
                operator: 'eq',
                value: true,
            });
        } else {
            params.push({
                field: 'unit.id',
                operator: 'eq',
                value: currentOrganization._id,
            });
        }

        return this.rest.search('notificationsSettings', { search: { search: params }, size: 1 }).then((data) => {
            if (data.length > 0) {
                return Promise.resolve(data[0]);
            } else {
                return Promise.resolve({});
            }
        });
    }
}
