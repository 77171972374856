<ng-template let-data="data"
             let-guid="guid"
             #conditionParams>
    <ng-container *ngIf="initialized">
        <td class="text-center"
            style="width: 150px;"
            [ngClass]="{'no-padding-top no-padding-bottom no-border': guid}">
            <input id="isRequired{{guid ? guid : ''}}"
                   class="checkbox"
                   type="checkbox"
                   [checked]="data.isRequired"
                   (change)="data.isRequired = !data.isRequired">
            <label for="isRequired{{guid ? guid : ''}}">&nbsp;</label>
        </td>
        <td [ngClass]="{
            'no-padding-top no-padding-bottom no-border': guid,
            'pt-5 pb-5': !guid
            }"
            style="width: 150px;">
            <input class="form-control input-xs text-center"
                   type="number"
                   [(ngModel)]="data.originals"
                   min="0">
        </td>
        <td style="width: 150px;"
            [ngClass]="{
            'no-padding-top no-padding-bottom no-border': guid,
            'pt-5 pb-5': !guid
            }">
            <input class="form-control input-xs text-center"
                   type="number"
                   [(ngModel)]="data.copies"
                   min="0">
        </td>
    </ng-container>
</ng-template>


<table class="table">
    <thead>
    <tr>
        <th class="text-bold">
            {{ localizations.standards.parameter }}
        </th>
        <th class="text-bold text-center"
            style="width: 150px">
            {{ localizations.standards.mandatory }}
        </th>
        <th class="text-bold text-center"
            style="width: 150px">
            {{ localizations.standards.original }}
        </th>
        <th class="text-bold text-center"
            style="width: 150px">
            {{ localizations.standards.copy }}
        </th>
    </tr>
    </thead>
    <tbody>

    <!-- Один на услугу -->
    <tr>
        <td [colSpan]="group.conditions.type && group.conditions.type === 'one' ? 1 : 4">
            <input id="conditionTypeOne"
                   class="radio"
                   type="radio"
                   name="conditionType"
                   [checked]="group.conditions.type && group.conditions.type === 'one'"
                   (change)="changeType('one')">
            <label for="conditionTypeOne">
                {{ localizations.standards.for_service }}
                <ng-container *ngIf="standardsService.standard.variants && standardsService.standard.variants.length > 0">
                    ({{ localizations.standards.for_service_variant }})
                </ng-container>
            </label>
        </td>
        <ng-container *ngIf="group.conditions.type && group.conditions.type === 'one'">
            <ng-container [ngTemplateOutlet]="conditionParams"
                          [ngTemplateOutletContext]="{data: group.conditions}"></ng-container>
        </ng-container>
    </tr>
    <tr *ngIf="group.conditions.type && group.conditions.type === 'one' && variants && variants.length > 0">
        <td colspan="4" class="no-padding">
            <ng-container [ngTemplateOutlet]="recursiveData"
                          [ngTemplateOutletContext]="{list: variants, level: 0, field: 'variants'}"></ng-container>
        </td>
    </tr>


    <ng-container *ngIf="subjectGroups && subjectGroups.length > 0">
        <!-- На категорию -->
        <tr>
            <td colspan="4">
                <input id="conditionTypeCategory"
                       class="radio"
                       type="radio"
                       name="conditionType"
                       [checked]="group.conditions.type && group.conditions.type === 'category'"
                       (change)="changeType('category')">
                <label for="conditionTypeCategory">
                    {{ localizations.standards.for_category }}
                </label>
            </td>
        </tr>
        <tr *ngIf="group.conditions.type && group.conditions.type === 'category' && subjectGroups && subjectGroups.length > 0">
            <td colspan="4" class="no-padding">
                <ng-container *ngFor="let subjectGroup of subjectGroups">
                    <div class="tree-line pl-20">
                        <div class="tree-item-title text-bold">
                            {{subjectGroup.name}}
                        </div>
                    </div>
                    <ng-container [ngTemplateOutlet]="recursiveData"
                                  [ngTemplateOutletContext]="{list: subjectGroup.children, level: 0, field: 'subjectCategories', itemTypesField: 'subjectTypes'}"></ng-container>
                </ng-container>
            </td>
        </tr>

        <!-- На представителя -->
        <tr>
            <td colspan="4">
                <input id="conditionTypeAgent"
                       class="radio"
                       type="radio"
                       name="conditionType"
                       [checked]="group.conditions.type && group.conditions.type === 'agent'"
                       (change)="changeType('agent')">
                <label for="conditionTypeAgent">
                    {{ localizations.standards.for_representative }}
                </label>
            </td>
        </tr>
        <tr *ngIf="group.conditions.type && group.conditions.type === 'agent'">
            <td colspan="4" class="no-padding">
                <div class="tree-line no-padding-right pt-5 pb-5 pl-20"
                     *ngFor="let agentType of agentTypes">
                    <div class="tree-item-title text-size-small text-slate">
                        {{agentType.name}}
                    </div>
                    <div class="tree-item-params">
                        <table class="table">
                            <tbody>
                            <tr>
                                <ng-container [ngTemplateOutlet]="conditionParams"
                                              [ngTemplateOutletContext]="{data: group.conditions[agentType.code], guid: 'agent' + agentType.code}"></ng-container>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="pl-20">
                    <div class="checkbox-inline"
                         *ngFor="let kind of agentKinds">
                        <input id="agentKind{{kind.code}}"
                               class="checkbox"
                               type="checkbox"
                               [checked]="checkSelectAgentKind(kind)"
                               (change)="toggleAgentKind(kind)">
                        <label for="agentKind{{kind.code}}">
                            {{kind.name}}
                        </label>
                    </div>
                </div>
            </td>
        </tr>
    </ng-container>


    <!-- На субъект -->
    <ng-container *ngIf="standardsService.standard.subjectsCommon && standardsService.standard.subjectsCommon.subjectTypes.length > 0">
        <tr>
            <td colspan="4">
                <input id="conditionTypeSubject"
                       class="radio"
                       type="radio"
                       name="conditionType"
                       [checked]="group.conditions.type && group.conditions.type === 'subject'"
                       (change)="changeType('subject')">
                <label for="conditionTypeSubject">
                    {{ localizations.standards.for_subject }}
                </label>
            </td>
        </tr>
        <ng-container *ngIf="group.conditions.type && group.conditions.type === 'subject'">
            <ng-container *ngFor="let subjectType of standardsService.standard.subjectsCommon.subjectTypes">
                <tr>
                    <td class="no-padding pl-30">
                        <div class="checkbox-block pt-20 pb-20">
                            <input id="subjectType{{subjectType.code}}"
                                   class="checkbox"
                                   type="checkbox"
                                   [checked]="!!group.conditions[subjectType.code]"
                                   (change)="toggleSubjectType(subjectType)">
                            <label for="subjectType{{subjectType.code}}">
                                {{subjectType.name}}
                            </label>
                        </div>
                    </td>
                    <td colspan="3">
                        <table class="table table-xs"
                               *ngIf="group.conditions[subjectType.code]">
                            <tbody>
                            <tr>
                                <ng-container [ngTemplateOutlet]="conditionParams"
                                              [ngTemplateOutletContext]="{data: group.conditions[subjectType.code], guid: 'subject' + subjectType.code}"></ng-container>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- На объект -->
    <ng-container *ngIf="standardsService.standard.objectsCommon">
        <tr>
            <td>
                <input id="conditionTypeObject"
                       class="radio"
                       type="radio"
                       name="conditionType"
                       [checked]="group.conditions.type && group.conditions.type === 'object'"
                       (change)="changeType('object')">
                <label for="conditionTypeObject">
                    {{ localizations.standards.for_object }}
                </label>
            </td>
            <ng-container *ngIf="group.conditions.type && group.conditions.type === 'object'">
                <ng-container [ngTemplateOutlet]="conditionParams"
                              [ngTemplateOutletContext]="{data: group.conditions}"></ng-container>
            </ng-container>
        </tr>
    </ng-container>

    </tbody>
</table>

<ng-template let-itemTypesField="itemTypesField"
             let-list="list"
             let-level="level"
             let-field="field"
             #recursiveData>
    <ng-container *ngFor="let item of list">
        <div class="tree-line no-padding-right pt-5 pb-5"
             [ngStyle]="{'padding-left': (level * 30) + 'px'}">
            <div class="tree-item-toggle-sign">
                <span class="cursor-pointer"
                      *ngIf="item.children && item.children.length > 0"
                      (click)="item.hideChildren = !item.hideChildren">
                    <i class="icon-plus2"
                       *ngIf="item.hideChildren">
                    </i>
                    <i class="icon-minus2"
                       *ngIf="!item.hideChildren">
                    </i>
                </span>
            </div>
            <div class="tree-checkbox">
                <input id="item{{item.guid}}"
                       class="checkbox"
                       type="checkbox"
                       [checked]="selectedData(item, field, true)"
                       (click)="toggleData(item, field, itemTypesField)">
                <label for="item{{item.guid}}">&nbsp;</label>
            </div>
            <div class="tree-item-title"
                 [ngStyle]="{'width': !itemTypesField ? 'calc(100% - 560px)' : 'calc(100% - 100px)'}">
                {{item.name}}
            </div>
            <div class="tree-item-params"
                 *ngIf="!itemTypesField">
                <table class="table"
                       *ngIf="selectedData(item, field, true)">
                    <tbody>
                        <tr>
                            <ng-container [ngTemplateOutlet]="conditionParams"
                                          [ngTemplateOutletContext]="{data: selectedData(item, field), guid: item.guid}"></ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ng-container *ngIf="itemTypesField && selectedData(item, field, true)">
            <div class="tree-line text-size-small"
                 *ngFor="let type of item[itemTypesField]"
                 [ngStyle]="{'padding-left': (level * 30 + 110) + 'px'}">
                <div class="tree-item-title text-slate">
                    {{type.name}}
                </div>
                <div class="tree-item-params">
                    <table class="table">
                        <tbody>
                        <tr>
                            <ng-container [ngTemplateOutlet]="conditionParams"
                                          [ngTemplateOutletContext]="{data: selectedData(item, field)[type.code], guid: item.guid + type.code}"></ng-container>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.children && item.children.length > 0 && !item.hideChildren">
            <ng-container [ngTemplateOutlet]="recursiveData"
                          [ngTemplateOutletContext]="{list: item.children, level: (level + 1), field: field, itemTypesField: itemTypesField}"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

