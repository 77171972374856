<div class="alert alert-warning no-border no-border-radius no-margin"
     *ngIf="type === 'userTasks' && processType == 'businessProcess'">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations['process-registry'].forbidden_to_run_user_tasks }}
</div>
<table class="table table-xxs table-hover">
    <thead>
    <tr>
        <th class="pl-20">
            {{ localizations.common.code }}
        </th>
        <th>
            {{ localizations.common.name }}
        </th>
        <th *ngIf="type == 'businessRuleTasks'">
            {{ localizations['process-registry'].dnm }}
        </th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let task of tasks; let fst = first; let lst = last">
        <td class="border-bottom border-bottom-default pl-20"
            width="200"
            [ngClass]="{'no-border-top': fst}">
            <span>
                {{ task.id }}
            </span>
        </td>
        <td class="border-bottom border-bottom-default"
            [ngClass]="{'no-border-top': fst}">
            <span>
                {{ task.name }}
            </span>
        </td>
        <td class="no-border-top border-bottom border-bottom-default "
            *ngIf="type == 'businessRuleTasks'">
            <ng-container *ngIf="task.dmn && dmnProcesses[task.dmn]">
                <a class="anchor"
                   [routerLink]="['/processes','business-rules','edit', task.dmn]">
                    {{dmnProcesses[task.dmn].title}}
                </a>
            </ng-container>
        </td>
        <td class="text-right border-bottom border-bottom-default">
            <button class="btn btn-sxs btn-default"
                    type="button"
                    [title]="localizations['process-registry'].select_dnm"
                    *ngIf="type == 'businessRuleTasks'"
                    (click)="selectDmn(task)">
                {{ localizations['process-registry'].dnm }}
            </button>
        </td>
    </tr>
    </tbody>
</table>
