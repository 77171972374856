import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealService } from '../../../../../appeal.service';

@Component({
    selector: 'appeal-entity-documents',
    templateUrl: 'appeal-entity-documents.component.html',
})
export class AppealEntityDocumentsComponent implements OnInit {
    @Input() public entity; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public task;
    @Input() public allowEdit = true;

    public entityDocumentGroupId;

    public constructor(
        public appealService: AppealService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        const find = this.appealService.subservice.entities.find(item => item.code === this.entity.type);
        if (find && find.docGroupId && find.docGroupId !== '0') {
            this.entityDocumentGroupId = find.docGroupId;
        }
    }
}
