<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Рейд</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <ng-container *ngIf="task && mode == 'view'">
                <span class="text-semibold text-uppercase">{{entity.name}}</span>
            </ng-container>
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер задания: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указано</em>

            <span class="text-muted ml-10">Дата задания: </span>
            <ng-container *ngIf="entity.date && !entity.date.formatted">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date || entity.date && entity.date.formatted">не указано</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Дата задания</label>
                                <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата задания" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Номер задания</label>
                                <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" placeholder="Введите номер" #number="ngModel" required>
                                <div *ngIf="isProcessValidate && number.errors" class="validation-error-label">
                                    <span *ngIf="number.errors.required">Поле "Номер задания" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Задачи рейдового осмотра</label>
                                <textarea name="raidTasks" class="form-control" [(ngModel)]="entity.raidTasks" #raidTasks="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && raidTasks.errors" class="validation-error-label">
                                    <span *ngIf="raidTasks.errors.required">Поле "Задачи рейдового осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Лица, ответственные за проведение проверки -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            [multiple]="true"
                            [property]="'employees'"
                            [title]="'Лица, ответственные за проведение проверки'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                        <div *ngIf="isProcessValidate && employees.errors" class="validation-error-label">
                            <span *ngIf="employees.errors.nameRequired">Поле "Лица, ответственные за проведение проверки" обязательно для заполнения</span>
                            <span *ngIf="!employees.errors.nameRequired && employees.errors.positionRequired">Поле "Должность лица, ответственного за проведение проверки" обязательно для заполнения</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Наименование экспертной организации</label>
                                <input type="text" name="expertOrganization" class="form-control" [(ngModel)]="entity.expertOrganization" #expertOrganization="ngModel" required>
                                <div *ngIf="isProcessValidate && expertOrganization.errors" class="validation-error-label">
                                    <span *ngIf="expertOrganization.errors.required">Поле "Наименование экспертной организации" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Свидетельство об аккредитации</label>
                                <input type="text" name="certificateAccreditation" class="form-control" [(ngModel)]="entity.certificateAccreditation" #certificateAccreditation="ngModel" required>
                                <div *ngIf="isProcessValidate && certificateAccreditation.errors" class="validation-error-label">
                                    <span *ngIf="certificateAccreditation.errors.required">Поле "Свидетельство об аккредитации" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Наименования органа по аккредитации</label>
                                <input type="text" name="accreditationOrgan" class="form-control" [(ngModel)]="entity.accreditationOrgan" #accreditationOrgan="ngModel" required>
                                <div *ngIf="isProcessValidate && accreditationOrgan.errors" class="validation-error-label">
                                    <span *ngIf="accreditationOrgan.errors.required">Поле "Наименования органа по аккредитации" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>ФИО эксперта</label>
                                <input type="text" name="expertName" class="form-control" [(ngModel)]="entity.expertName" #expertName="ngModel" required>
                                <div *ngIf="isProcessValidate && expertName.errors" class="validation-error-label">
                                    <span *ngIf="expertName.errors.required">Поле "ФИО эксперта" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Должность эксперта</label>
                                <input type="text" name="expertPosition" class="form-control" [(ngModel)]="entity.expertPosition" #expertPosition="ngModel" required>
                                <div *ngIf="isProcessValidate && expertPosition.errors" class="validation-error-label">
                                    <span *ngIf="expertPosition.errors.required">Поле "Должность эксперта" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Правовые основы -->
                    <div class="mb-15">
                        <appeal-entity-catalogue
                            [large]="true"
                            [entity]="entity"
                            [property]="'basisKNM'"
                            [catalogName]="'basisKNM'"
                            [title]="'Основания проведения'"
                            [placeholder]="'Укажите правовые основания'">
                        </appeal-entity-catalogue>
                        <div *ngIf="isProcessValidate && basisKNM.errors" class="validation-error-label">
                            <span *ngIf="basisKNM.errors.required">Поле "Основания проведения" обязательно для заполнения</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Место проведения планового (рейдового) осмотра, обследования, маршрут, территория, район</label>
                                <input type="text" name="location" class="form-control" [(ngModel)]="entity.location" #location="ngModel" required>
                                <div *ngIf="isProcessValidate && location.errors" class="validation-error-label">
                                    <span *ngIf="location.errors.required">Поле "Место проведения планового (рейдового) осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group  form-group-lg">
                                <label>Цель осмотра</label>
                                <catalogue catalogName="purposeKNM" name="purposeKNM" [(ngModel)]="entity.purposeKNM" [priority]="true" [large]="true" [placeholder]="'Укажите цель осмотра'" #purposeKNM="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && purposeKNM.errors" class="validation-error-label">
                                    <span *ngIf="purposeKNM.errors.required">Поле "Цель осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Дата начала проведения осмотра</label>
                                <evolenta-datepicker name="startDate" [large]="true" [(ngModel)]="entity.startDate" [valueAsString]="true" #startDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && startDate.errors" class="validation-error-label">
                                    <span *ngIf="startDate.errors.required">Поле "Дата начала проведения осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Дата окончания проведения осмотра</label>
                                <evolenta-datepicker name="endDate" [large]="true" [(ngModel)]="entity.endDate" [valueAsString]="true" #endDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && endDate.errors" class="validation-error-label">
                                    <span *ngIf="endDate.errors.required">Поле "Дата окончания проведения осмотра" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Срок проведения(кол-во рабочих дней)</label>
                                <input type="number" name="limitation" class="form-control" [(ngModel)]="entity.limitation" #limitation="ngModel" required>
                                <div *ngIf="isProcessValidate && limitation.errors" class="validation-error-label">
                                    <span *ngIf="limitation.errors.required">Поле "Срок проведения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
