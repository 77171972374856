import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityVersionsService, ToasterService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'object-versions',
    templateUrl: 'object-versions.component.html',
    styles: [
        '.version-block .go-to-version-button { display: none; }',
        '.version-block:hover .go-to-version-button { display: inline; }',
    ],
})
export class ObjectVersionsComponent implements OnInit {
    @Input() public object;

    public currentPage;
    public totalItems;
    public totalPages;
    public versions;

    public moduleBaseUrl;
    public workModeUrl;

    public collection;
    public localizations;

    public constructor(
        private entityVersionsService: EntityVersionsService,
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        this.route.parent.url.subscribe((urlPath) => {
            this.workModeUrl = urlPath[urlPath.length - 1].path;
            this.collection = this.workModeUrl === 'kno' ? 'objectsKno' : 'objects';
        });
        this.init();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'objects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public init() {
        this.currentPage = 0;
        this.totalItems = 0;
        this.totalPages = 0;
        this.versions = [];
        this.loadData(0);
    }

    public loadData(page = this.currentPage + 1) {
        if ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages) {
            const projection = {
                _id: 1,
                dateLastModification: 1,
                userLastModification: 1,
                version: 1,
            };

            const objectId = this.object.mainId || this.object._id;

            this.entityVersionsService
                .search(this.collection, objectId, 20, page, encodeURI(JSON.stringify(projection)))
                .then((versions: any) => {
                    versions.forEach((item) => {
                        this.versions.push(item);
                    });

                    this.totalItems = this.entityVersionsService.pagination.total;
                    this.currentPage = this.entityVersionsService.pagination.page;
                    this.totalPages = this.entityVersionsService.pagination.totalPages;
                });
        }
    }

    public goToVersion(version) {
        const objectId = this.object.mainId || this.object._id;
        this.router.navigate([this.moduleBaseUrl, 'objects', this.workModeUrl, 'edit', objectId, version._id]);
    }

    public goToCurrentVersion() {
        const objectId = this.object.mainId || this.object._id;
        this.router.navigate([this.moduleBaseUrl, 'objects', this.workModeUrl, 'edit', objectId]);
    }

    public restoreVersion(version) {
        this.entityVersionsService.restore(this.collection, version._id).then(
            () => {
                this.toaster.success('Версия восстановлена');
                this.goToCurrentVersion();
            },
            (error) => {
                console.log(error);
                this.toaster.error(error);
            }
        );
    }
}
