import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { TranslateService } from '@evolenta/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
    selector: 'print-form-xsl',
    templateUrl: './print-form-xsl.component.html',
})
export class PrintFormXslComponent implements OnInit {
    @Input() public xsl;
    @Input() public allowEdit = true;
    @Output() public updateXslList = new EventEmitter<any>();
    public isEdit = false;
    public xslFields;
    public selectedElement = null;
    public localizations;

    public constructor(
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.xslFields = _.cloneDeep(this.xsl);
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'knd-kinds',
                'print-forms',
                'organizations',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public addElement() {
        const guid = CommonUtilities.GenerateGuid();
        const element = {
            code: '',
            name: '',
            variablesBlock: '',
            serviceTemplate: '',
            templatesBlock: '',
            guid,
        };

        this.editElement(element);
    }

    public removeElement(guid) {
        const newList = _.cloneDeep(this.xslFields);
        this.xslFields = newList.filter(item => item.guid !== guid);
        this.updateXslList.emit(this.xslFields);

    }

    public editElement(item) {
        this.selectedElement = item;
        this.isEdit = true;
    }

    public saveXsl(xsl) {
        const isNewElement = this.xslFields.filter(item => item.guid === xsl.guid).length === 0;
        if (isNewElement) {
            this.xslFields.push(xsl);
        } else {
            this.xslFields = this.xslFields.map(item => item.guid === xsl.guid ? xsl : item);
        }
        this.updateXslList.emit(this.xslFields);
        this.selectedElement = null;
        this.isEdit = false;
    }

    public cancelXsl() {
        this.selectedElement = null;
        this.isEdit = false;
    }

    public saveElement(element) {
        const json = JSON.stringify(element);
        const blob = new Blob([json], {type: 'application/json'});
        FileSaver.saveAs(blob, 'print_form_xsl_block_' + element.code + '.json');
    }

    public loadFile(event) {
        const filesList = event.target.files;
        if (filesList.length > 0) {
            filesList[0].dateCreation = moment().format();
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const data = JSON.parse(e.target.result);
                data.guid = CommonUtilities.GenerateGuid();
                this.saveXsl(data);
            };
            reader.readAsText(filesList[0]);
        }
    }
}
