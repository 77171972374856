<!-- Список вариантов -->
<div class="m-20" *ngIf="!editedVariant">
    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false">
    </common-standard-block-with-additional-data>
    <div class="panel panel-shadow panel-small">

        <div class="panel-heading">
            <h4 class="panel-title pt-10">
                <span class="mr-10">
                    {{metaData.name}}
                </span>
                <common-standard-block-status [block]="activeData"
                                              [small]="true"
                                              *ngIf="standardsService.reglament && !!metaData.variantsData.allowEditInReglament"
                                              (onToggleEntity)="standardsService.toggleReglamentBlockEntity(activeData)"
                                              (onDeleteReglamentData)="standardsService.deleteReglamentBlock(activeData)">
                </common-standard-block-status>
            </h4>
            <div class="heading-elements">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.appeals.add_variant"
                        *ngIf="activeData.allowEdit"
                        (click)="add()">
                    <i inlineSVG="assets/icon-new-item.svg"></i>
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <common-standard-variant
                [list]="activeData.entity.variants"
                [level]="0"
                [xsdData]="xsdData"
                [allowEdit]="allowEdit"
                (editVariantClicked)="editedVariantClicked($event)"
                (dragStarted)="childDragStarted($event)">
            </common-standard-variant>

            <div class="alert alert-warning no-margin"
                 *ngIf="!activeData.entity.variants || activeData.entity.variants.length === 0">
                {{ localizations.standards.variants_not_added }}
            </div>
        </div>
    </div>
</div>

<!-- Редактирование варианта -->
<common-standard-variant-edit [variant]="editedVariant"
                              [allowEdit]="activeData.allowEdit"
                              [entity]="activeData.entity"
                              *ngIf="editedVariant"
                              (onSave)="apply()"
                              (onCancel)="cancel()"
                              (onEditXsd)="editXsd()">
</common-standard-variant-edit>
