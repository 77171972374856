import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonAppealData } from '../../services/common-appeal.data';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';

@Component({
    selector: 'common-appeal-send-erp-info',
    templateUrl: './common-appeal-send-erp-info.component.html',
})
export class CommonAppealSendErpInfoComponent implements OnInit {
    @Input() public appeal;

    public sendData;

    public erpSendTypes = CommonAppealData.erpSendTypes;

    public constructor(
        public appealStatusService: CommonAppealStatusService,
        private router: Router
    ) {}

    public ngOnInit() {
        this.sendData = this.appeal.erpEnvelopesInfo;
    }

    public getType(data) {
        const find = this.erpSendTypes.find((item) => item.code === data.erpType);
        return find ? find.name : '';
    }

    public goToEnvelope(envelope) {
        this.router.navigate(['kno-config', 'envelopes', 'edit', envelope.erpEnvelopeId]);
    }
}
