<div class="p-20">
    <ng-container *ngIf="tabs.length > 1">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of tabs">
                <li (click)="activeTab = tab.code"
                    [ngClass]="{'active': activeTab == tab.code}">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </ng-container>

    <!-- Список документов -->
    <div class="mt-15"
         *ngIf="activeTab === 'documentsInfo'">
        <ng-container *ngFor="let block of blocks">
            <common-standard-block-with-additional-data [block]="block"
                                                        [showBlockTitle]="false"
                                                        [asPanel]="true">
            </common-standard-block-with-additional-data>
        </ng-container>
    </div>

    <!-- Список документов -->
    <ng-container *ngIf="activeTab === 'groupsAndDocuments'">
        <common-standard-documents-list [xsdData]="xsdData"
                                        (onEditXsd)="editXsd($event)">
        </common-standard-documents-list>
    </ng-container>

    <!-- Запросы -->
    <ng-container *ngIf="activeTab === 'requests'">
        <common-standard-requests></common-standard-requests>
    </ng-container>

    <!-- Печатные формы -->
    <ng-container *ngIf="activeTab === 'printForms'">
        <common-standard-print-forms></common-standard-print-forms>
    </ng-container>

    <!-- Общие данные -->
    <ng-container *ngIf="activeTab === 'common'">
        <!-- Настройка дополнительных данных -->
        <p *ngIf="metaData.commonData.allowCreateCommonXsdSetting">
            <span class="text-bold">
                {{ localizations.standards.requisite_form }}
                {{ !activeData.entity.documentsCommon ?
                    localizations.common.not_set_up_f :
                    localizations.common.set_up_f }}
            </span>
            <span class="text-size-small anchor text-info ml-10"
                  (click)="editXsd({data: activeData.entity.documentsCommon, type: 'documents', activeTab: 'common', allowEdit: activeData.allowEdit})"
                  *ngIf="activeData.allowEdit || activeData.entity.documentsCommon.xsd">
                {{!activeData.entity.documentsCommon.xsd?
                    localizations.common.setup :
                        activeData.allowEdit ?
                            localizations.common.change_small :
                            localizations.common.view_small }}
            </span>
            <span class="text-size-small anchor text-slate ml-10"
                  (click)="activeData.entity.documentsCommon.xsd = null"
                  *ngIf="activeData.entity.documentsCommon.xsd && activeData.allowEdit">
                {{ localizations.common.delete_small }}
            </span>
            <evolenta-handlers
                    [model]="activeData.entity.documentsCommon"
                    field="xsd"
                    [setStartEvent]="true"
            ></evolenta-handlers>
        </p>

        <div class="checkbox-block">
            <input id="showSubjectDocumentsInCommonList"
                   class="checkbox"
                   type="checkbox"
                   (change)="activeData.entity.documentsCommon.showSubjectDocumentsInCommonList = !activeData.entity.documentsCommon.showSubjectDocumentsInCommonList"
                   [checked]="activeData.entity.documentsCommon.showSubjectDocumentsInCommonList">
            <label for="showSubjectDocumentsInCommonList">
                {{ localizations.standards.show_documents_in_all_documents_list }}
            </label>
        </div>

        <div class="checkbox-block">
            <input id="showObjectDocumentsInCommonList"
                   class="checkbox"
                   type="checkbox"
                   (change)="activeData.entity.documentsCommon.showObjectDocumentsInCommonList = !activeData.entity.documentsCommon.showObjectDocumentsInCommonList"
                   [checked]="activeData.entity.documentsCommon.showObjectDocumentsInCommonList">
            <label for="showObjectDocumentsInCommonList">
                {{ localizations.standards.show_object_documents_in_all_documents_list }}
            </label>
        </div>

        <fieldset class="mt-20"
                  *ngIf="metaData.commonData.allowFilterByMnemonicSetting">
            <legend class="text-bold no-margin">
                {{ localizations.standards.filter_document_groups_by_mnemonics }}
            </legend>
            <div class="checkbox-block">
                <input id="filterByMnemonicInSubject"
                       class="checkbox"
                       type="checkbox"
                       (change)="activeData.entity.documentsCommon.filterByMnemonicInSubject = !activeData.entity.documentsCommon.filterByMnemonicInSubject"
                       [checked]="activeData.entity.documentsCommon.filterByMnemonicInSubject">
                <label for="filterByMnemonicInSubject">
                    {{ localizations.standards.in_subjects_documents_list }}
                </label>
            </div>
            <div class="checkbox-block">
                <input id="filterByMnemonicInObject"
                       class="checkbox"
                       type="checkbox"
                       (change)="activeData.entity.documentsCommon.filterByMnemonicInObject = !activeData.entity.documentsCommon.filterByMnemonicInObject"
                       [checked]="activeData.entity.documentsCommon.filterByMnemonicInObject">
                <label for="filterByMnemonicInObject">
                    {{ localizations.standards.in_subjects_documents_list }}
                </label>
            </div>
            <div class="checkbox-block">
                <input id="filterByMnemonic"
                       class="checkbox"
                       type="checkbox"
                       (change)="activeData.entity.documentsCommon.filterByMnemonic = !activeData.entity.documentsCommon.filterByMnemonic"
                       [checked]="activeData.entity.documentsCommon.filterByMnemonic">
                <label for="filterByMnemonic">
                    {{ localizations.standards.in_general_list }}
                </label>
            </div>
        </fieldset>
    </ng-container>
</div>

