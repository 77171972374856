import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { RolesMnemonicsComponent } from './mnemonics/roles-mnemonics.component';
import { RoleMnemonicComponent } from './mnemonics/role-mnemonic.component';
import { RolesMnemonicsResolve } from './mnemonics/roles-mnemonics.resolve';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        BreadcrumbsModule,
    ],
    declarations: [
        RolesMnemonicsComponent,
        RoleMnemonicComponent,
    ],
    providers: [
        RolesMnemonicsResolve,
    ],
})

export class RolesMnemonicsModule {
}
