import { Injectable } from '@angular/core';
import { FilesService, ToasterService } from '@evolenta/core';

@Injectable()
export class FilesQueueService {
    private fileMap: Map<string, File> = new Map<string, File>();

    private idMap: Map<string, string> = new Map<string, string>();

    private enabled = false;

    public constructor(
        private fileService: FilesService,
        private toaster: ToasterService
    ) {}

    public open() {
        this.enabled = true;
    }

    public close() {
        this.clear();
        this.enabled = false;
    }

    public addFile(id: string, file: File) {
        if (this.enabled) {
            this.fileMap.set(id, file);
        }
    }

    public removeFile(id: string): File | null {
        const file = this.fileMap.get(id);
        this.fileMap.delete(id);

        return file || null;
    }

    public getFile(id: string): File | null {
        return this.fileMap.get(id) || null;
    }

    public clear() {
        this.fileMap.clear();
        this.idMap.clear();
    }

    public tryUploadFile(id: string, entryName: string, entryId: string): Promise<string> | null {
        const uploadId = this.idMap.get(id);
        if (uploadId) {
            return Promise.resolve(uploadId);
        }

        const file = this.fileMap.get(id);
        if (!file) {
            return null;
        }

        return this.fileService
            .uploadFile(entryName, entryId, file, file.name)
            .catch((error) => {
                this.toaster.error('Ошибка загрузки файла', error);
            })
            .then((result) => {
                const idUpload = result._id as string;
                this.idMap.set(id, idUpload);
                return idUpload;
            });
    }
}
