import { Injectable } from '@angular/core';
import { CONTROL_OPERATORS_COLLECTION, CONTROL_OPERATORS_URL, ORGANIZATIONS } from '../../../common/constants';
import { HttpService, RestService, StorageService, ToasterService } from '@evolenta/core';
import { CoreModuleConfig } from '../../../config/core-module.config';

@Injectable()
export class ControlOperatorsService {
    public controlOperator;

    public baseOrganizationsUrl = CoreModuleConfig.endpoints.server + `api/tatar/v1/organizations/`;

    public constructor(
        private restService: RestService,
        private httpService: HttpService,
        private storageService: StorageService,
        private toasterService: ToasterService
    ) {}

    public createEmptyControlOperator() {
        // хранит объект с данными об организации
        const currentOrganization = this.storageService.getItem('currentOrganization');
        const controlOperator = {
            service: {
                _id: '',
                name: '',
                standardCode: '',
            },
            user: {
                _id: '',
                login: '',
                name: '',
            },
            organizationId: currentOrganization._id,
        };

        return controlOperator;
    }

    public async getAutoassignmentStatus(organizationId) {
        const organization: any = await this.restService.find(ORGANIZATIONS, organizationId);

        return !!organization.isAutoAppointment;
    }
    /**
     * Получить список ответсвенных для указанной организации
     * @param organizationId
     */
    public get(organizationId) {
        const params = {
            search: {
                search: [
                    {
                        field: 'organizationId',
                        operator: 'eq',
                        value: organizationId,
                    },
                ],
            },
        };

        return this.restService.search(CONTROL_OPERATORS_COLLECTION, params);
    }

    /**
     * Добавить ответственного
     * @param controlOperator
     */
    public async add(controlOperator) {
        try {
            await this.restService.create(CONTROL_OPERATORS_COLLECTION, controlOperator);
        } catch (error) {
            this.toasterService.error(error);
        }
    }

    /**
     * Обновить существующего отвественного
     * @param controlOperator
     */
    public async update(controlOperator) {
        try {
            await this.restService.update(CONTROL_OPERATORS_COLLECTION, controlOperator);
        } catch (error) {
            this.toasterService.error(error);
        }
    }

    /**
     * Удалить ответственного
     * @param controlOperatorId
     */
    public async delete(controlOperator) {
        try {
            await this.restService.remove(CONTROL_OPERATORS_COLLECTION, controlOperator);
        } catch (error) {
            this.toasterService.error(error);
        }
    }

    /**
     * Включить или выключить функцию
     * автоматического выставления ответственного
     * согласно указанному параметру
     * @param value
     */
    public async setOperatorAutomaticallySetting(organizationId, value) {
        try {
            await this.httpService.post(this.baseOrganizationsUrl + organizationId + '/auto', !!value);
        } catch (error) {
            this.toasterService.error(error);
        }
    }
}
