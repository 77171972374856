import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { AccessService, RestService, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppealService } from '../../appeal.service';
import { AppealSaveService } from '../../appeal-save.service';
import { Permission } from '../../../../../common/services/permission';
import { AppealStatusService } from '../../appeal-status.service';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { AppealProcessService } from '../process/appeal-process.service';
import { RsoService } from '../../../../../common/services/rso.service';
import { getLastHourTimeAndOffset } from '../../../../../common/utils/misc.utils';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'appeal-common',
    templateUrl: 'appeal-common.component.html',
    styles: ['.date-info { min-width: 250px; }'],
})
export class AppealCommonComponent implements OnInit {
    @Input() public appeal; // Обрабатываемое дело
    @Input() public mode = 'view'; // Режим отображения: view - предпросмотр карточки в списке; edit - редактированные данных услуги
    public numberShowed = 5; // Число отображаемых по умолчанию пользователей (в списке)

    public cheqText = '';
    public filter = ''; // Поисковое значение по ФИО пользователя
    public isSelectedControl = false;
    public users = []; // Доступные для выбора пользователи системы
    public tempAppeal; // Объект обрабатываемого дела во временной переменной
    public permissions = Permission; // Набор прав системы
    public controlOperator; // ответственный по делу

    @Output() public onEdit = new EventEmitter<boolean>(); // Переход в режим редактирования
    @Output() public afterEdit = new EventEmitter<boolean>(); // Обработка события применения / отмены изменений после редактирования

    public isEditPlanDate = false;
    public newDatePlaneFinish;
    public commentDatePlaneFinish;
    public modalRef: BsModalRef;
    @ViewChild('datePlanFinishHistoryModal', { static: false }) public datePlanFinishHistoryModal: TemplateRef<any>;

    public localizations;
    public willChangeTaskTerm = false;
    public newTaskDatePlaneFinish = null;
    public activeTask;
    public allowChangeTaskTerm = false;
    public isDisabledByRso = false;

    public constructor(
        public appealService: AppealService,
        public accessService: AccessService,
        private toaster: ToasterService,
        private saveService: AppealSaveService,
        private appealStatusService: AppealStatusService,
        private storage: StorageService,
        private modalService: BsModalService,
        private translate: TranslateService,
        private appealProcessService: AppealProcessService,
        private restService: RestService,
        private rsoService: RsoService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        if (!this.appeal.controlOperator) {
            this.appeal.controlOperator = [];
        }

        if (this.appeal.controlOperator && !Array.isArray(this.appeal.controlOperator)) {
            this._getAssigneeUser();
        }

        this.activeTask = this.appealProcessService.activeTasks[0];
        this.allowChangeTaskTerm = !!(
            this.activeTask &&
            this.activeTask.due &&
            this.appeal.standardTasksPeriodInfo &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod.autoFill
        );
        this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.appeal);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals']).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация режима редактирования общих данных услуги (нажатие кнопки "Редактировать")
     */
    public edit() {
        this.tempAppeal = _.cloneDeep(this.appeal); // Сохранение объекта дела во временную переменную, для возможного использования при нажатии кнопки "Отменить"
        this.mode = 'edit';
        this.onEdit.emit(true);
    }

    /**
     * Применение изменений внесенных в общие данные услуги (нажатие кнопки "Применить")
     */
    public async apply() {
        this.mode = 'view';
        this.isSelectedControl = false;
        this.filter = '';
        this.numberShowed = 5;

        // Если дело уже было сохранено, производим сохранение дела на сервере
        if (this.appeal._id) {
            try {
                await this.saveService.saveAppeal();
                this.toaster.success('Изменения успешно сохранены');
            } catch (error) {
                this.toaster.error(error);
            }
        }
        this.afterEdit.emit(true);
    }

    /**
     * Отмена изменений внесенных в общие данные услуги (нажатие кнопки "Отменить")
     */
    public cancel() {
        this.mode = 'view';
        delete this.appeal.controlOperator;
        this.isSelectedControl = false;
        this.filter = '';
        this.numberShowed = 5;
        this.appeal = Object.assign(this.appeal, this.tempAppeal);
        this.afterEdit.emit(true);
        this.appealService.editing = false;
    }

    /**
     *  Открыть список пользователей
     */
    public openAllItemListUsers(array) {
        this.numberShowed = this.numberShowed === 5 ? array.length : 5;
    }

    public editPlanFinishDate() {
        this.newDatePlaneFinish = this.appeal.datePlaneFinish;
        this.commentDatePlaneFinish = '';
        this.isEditPlanDate = true;
    }

    public setNewTaskDatePlaneFinish() {
        if (!this.willChangeTaskTerm) {
            this.newTaskDatePlaneFinish = null;
        } else {
            const newDateString =
                this.appeal.datePlaneFinish === this.newDatePlaneFinish
                    ? this.newDatePlaneFinish
                    : this.newDatePlaneFinish + getLastHourTimeAndOffset();
            const momentOldDate = moment(
                moment(this.appeal.datePlaneFinish).format('YYYY-MM-DD') + getLastHourTimeAndOffset()
            );
            const momentNewDate = moment(newDateString);
            const diff = momentNewDate.diff(momentOldDate, 'days');

            this.newTaskDatePlaneFinish = moment(this.activeTask.due)
                .add(diff, 'days')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
        }
    }

    public async updatePlaneFinishDate() {
        if (!this.commentDatePlaneFinish) {
            this.toaster.error('Не заполнена причина изменения плановой даты');

            return;
        } else {
            this.appeal.datePlaneFinish = this.newDatePlaneFinish + getLastHourTimeAndOffset();
            this.saveService.appeal.datePlaneFinish = this.appeal.datePlaneFinish;
            this.appeal.subservice.datePlaneFinish = this.appeal.datePlaneFinish;

            const currentUser = this.storage.getItem('user');
            const historyItem = {
                date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                comment: this.commentDatePlaneFinish,
                user: {
                    login: currentUser.login,
                    id: currentUser._id,
                    name: currentUser.name,
                },
            };
            if (!this.appeal.datePlaneFinishChangeHistory) {
                this.appeal.datePlaneFinishChangeHistory = [];
                this.saveService.appeal.datePlaneFinishChangeHistory = [];
                this.saveService.appeal.datePlaneFinishChangeHistory.push(historyItem);
            }
            this.appeal.datePlaneFinishChangeHistory.push(historyItem);

            // Меняем дату для уведомления о просрочке
            this.appeal.datePlaneExpired = moment(this.appeal.datePlaneFinish)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ssZZ');
            this.cancelEditFinishDate();
            this.saveService.clearDatePlaneFinishInBaseAppeal();
            this.saveService.appeal = cloneDeep(this.appeal);

            await this.apply();
            if (this.newTaskDatePlaneFinish) {
                await this._updateTaskDatePlaneFinish(historyItem);
                this.newTaskDatePlaneFinish = null;
            }
        }
    }

    private async _updateTaskDatePlaneFinish(historyItem) {
        const camundaBusinessInfoData: any = await this.restService.find(
            'camundaBusinessInfo',
            this.activeTask.camundaBusinessInfoId
        );
        if (!camundaBusinessInfoData) {
            return;
        }

        const foundTaskIndex = camundaBusinessInfoData.userTasks.find((item) => item.id === this.activeTask.id);

        if (foundTaskIndex < 0) {
            return;
        }

        const updatedTasks = camundaBusinessInfoData.userTasks.map((task) => {
            if (task.id === this.activeTask.id) {
                const taskData = {
                    ...task,
                    due: this.newTaskDatePlaneFinish,
                };

                if (!taskData.datePlaneFinishChangeHistory) {
                    taskData.datePlaneFinishChangeHistory = [];
                }
                taskData.datePlaneFinishChangeHistory.push({
                    ...historyItem,
                    comment: 'Общий срок услуги был изменен: ' + historyItem.comment,
                });

                return taskData;
            }

            return task;
        });

        const dataForSave = {
            _id: camundaBusinessInfoData._id,
            userTasks: updatedTasks,
        };

        this.activeTask.due = this.newTaskDatePlaneFinish;
        await this.restService.update('camundaBusinessInfo', dataForSave);
    }

    public cancelEditFinishDate() {
        this.newDatePlaneFinish = null;
        this.commentDatePlaneFinish = '';
        this.isEditPlanDate = false;
        this.willChangeTaskTerm = false;
    }

    public isCanChangeAppealPlaneFinishDate() {
        return !this.appealStatusService.finishStatuses.includes(this.appeal.status.code);
    }

    public showDatePlaneChangeHistory() {
        this.modalRef = this.modalService.show(this.datePlanFinishHistoryModal, { backdrop: 'static' });
    }

    public _getAssigneeUser() {
        const currentOrganization = this.storage.getItem('currentOrganization');
        this.appealService.getUsersForControl(currentOrganization.guid).then((users) => {
            // @ts-ignore
            this.controlOperator = users.find((user) => user.login === this.appeal.controlOperator);
        });
    }
}
