<div class="panel panel-default"
     [ngClass]="{'no-border-top no-border-right no-border-left no-border-radius': mode == 'edit',
                 'border-left-lg': mode == 'view', 'border-left-primary-600': mode == 'view' && envelope.status == 'draft',
                 'border-left-slate-600': mode == 'view' && envelope.status != 'draft'}">
    <div class="panel-heading">
        <h6 class="panel-title cursor-pointer">
            Конверт # <span *ngIf="envelope.auid">{{envelope.auid}}</span><span class="text-muted" *ngIf="!envelope.auid">n/a</span>
            <span class="badge badge-flat ml-15 text-uppercase"
                  [ngClass]="{'border-purple text-purple-600': envelope.status == 'draft',
                              'border-primary text-primary-600': envelope.status == 'process',
                              'border-teal text-teal-600': envelope.status == 'send',
                              'border-success text-success-600': envelope.status == 'returnResult',
                              'border-warning text-warning-600': envelope.status == 'returnRevision',
                              'border-danger text-danger-600': !envelope.status}">{{getEnvelopeStatusName()}}</span>
        </h6>
        <div class="heading-elements">
            <button class="btn btn-primary heading-btn btn-labeled btn-labeled-right btn-xs"
                    (click)="edit()" *ngIf="mode == 'view' && envelope.status == 'draft'">
                <b><i [ngClass]="{'icon-pencil3' : allowEdit, 'icon-eye': !allowEdit}"></i></b>
                <span *ngIf="allowEdit">Редактировать</span>
                <span *ngIf="!allowEdit">Просмотреть</span>
            </button>
            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right btn-xs"
                    (click)="complete()" *ngIf="mode == 'view' && envelope.status == 'draft' && allowEdit">
                <b><i class="icon-file-check"></i></b>
                Сформировать
            </button>

            <ng-container *ngIf="mode == 'view' && envelope.status == 'process' && printForms.length > 0" >
                <button class="btn btn-info heading-btn action-btn btn-xs dropdown-toggle btn-labeled btn-labeled-right" data-toggle="dropdown"
                        [disabled]="isProcessingPrintForm">
                    <b><i [ngClass]="{'icon-spinner2 spinner': isProcessingPrintForm, 'icon-printer': !isProcessingPrintForm}"></i></b>
                    Печать
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let printForm of printForms">
                        <a (click)="print(printForm)">{{printForm.title}}</a>
                    </li>
                </ul>
            </ng-container>

            <button class="btn btn-danger heading-btn btn-labeled btn-labeled-right btn-xs"
                    [disabled]="!accessService.hasAccess([permissions.Appeal_Envelope_Delete])"
                    (click)="deleteEnvelope()" *ngIf="mode == 'view' && envelope.status == 'draft'">
                <b><i class="icon-trash"></i></b>
                Удалить
            </button>
            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right"
                    (click)="apply()" *ngIf="mode == 'edit' && allowEdit">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
            <button class="btn bg-orange heading-btn btn-labeled btn-labeled-right"
                    (click)="cancel()" *ngIf="mode == 'edit'">
                <b><i class="icon-cross2"></i></b>
                <ng-container *ngIf="allowEdit">Отменить</ng-container>
                <ng-container *ngIf="!allowEdit">Закрыть</ng-container>
            </button>
        </div>
    </div>
    <div class="panel-body no-padding">
        <!-- Выбор услуги -->
        <div class="pt-10 pl-20 pr-20 pb-5 bg-primary-50" *ngIf="isProcessSelectSubservice && mode === 'edit'">
            <div class="text-semibold mb-10">Выберите услугу:</div>
            <div class="radio-block" *ngFor="let subservice of subservices">
                <input type="radio" class="radio" name="kind"
                       [checked]="envelope.appeal && envelope.appeal.subservice && envelope.appeal.subservice.guid == subservice.guid"
                       (click)="selectSubservice(subservice)" id="subservice{{subservice.guid}}">
                <label for="subservice{{subservice.guid}}">{{subservice.shortTitle}}</label>
            </div>
        </div>

        <div class="pt-10" [ngClass]="{'pr-10 pl-10': mode == 'view', 'pr-20 pl-20': mode == 'edit'}" *ngIf="!isProcessSelectSubservice">

            <!-- Услуга дела -->
            <p class="mb-3">
                <span class="text-muted">Услуга: </span>
                <span *ngIf="!envelope.appeal.subservice && mode == 'view'">не определена</span>
                <span class="text-info anchor cursor-pointer" *ngIf="!envelope.appeal.subservice && mode == 'edit'"
                      (click)="isProcessSelectSubservice = true">не определена</span>
                <ng-container *ngIf="envelope.appeal && envelope.appeal.subservice">
                    <span>{{envelope.appeal.subservice.title}}</span>
                </ng-container>
            </p>

            <!-- Организация (ОГВ) -->
            <p class="mb-3" *ngIf="envelope.appeal && envelope.appeal.subservice">
                <span class="text-muted">Организация: </span>
                <span *ngIf="!envelope.responsibleOrganization && mode == 'view'">не определена</span>
                <span class="text-info anchor cursor-pointer" *ngIf="!envelope.responsibleOrganization && mode == 'edit'"
                      (click)="isProcessSelectResponsibleOrganization = true">не определена</span>
                <span *ngIf="envelope.responsibleOrganization">{{envelope.responsibleOrganization.name}}</span>
            </p>
        </div>
        <hr class="mt-10 no-margin-bottom" *ngIf="mode == 'edit' && !isProcessSelectSubservice">


        <!-- Выбор документов -->
        <ng-container *ngIf="envelope.responsibleOrganization && envelope.appeal && envelope.appeal.subservice && !isProcessSelectSubservice">
            <div *ngIf="mode == 'view'" class="pb-10"  [ngClass]="{'pr-10 pl-10': mode == 'view', 'pr-20 pl-20': mode == 'edit'}">
                <span class="text-muted mb-10">Документы:</span>
                <ol>
                    <li *ngFor="let document of envelope.documents" class="mb-5">
                        <p class="no-margin-bottom">
                            {{document.name}}
                            (<span class="text-muted text-size-small" *ngIf="document.originals > 0">оригиналов: </span> <span class="text-semibold" *ngIf="document.originals > 0">{{document.originals}}</span>
                            <span class="text-muted text-size-small" *ngIf="document.copies > 0">копий: </span> <span class="text-semibold" *ngIf="document.copies > 0">{{document.copies}}</span>)
                            <ng-container *ngIf="document.objects && document.objects.length > 0">
                                <span class="text-muted text-size-small">Объекты:</span>
                                <span *ngFor="let object of document.objects; let last = last">
                                {{getObjectHeaderByGuid(object)}}<ng-container *ngIf="!last">, </ng-container>
                            </span>
                            </ng-container>
                        </p>
                        <p class="mb-3 text-size-small">
                            <span class="text-muted">Группа:</span> {{document.groupName ? document.groupName : 'Прочие документы'}}
                        </p>
                    </li>
                </ol>
            </div>

            <table class="table" *ngIf="mode == 'edit'">
                <thead>
                <tr>
                    <th *ngIf="envelope.status == 'draft'">
                        <input type="checkbox" class="checkbox" id="selectAll"
                               [(ngModel)]="selectAll" [value]="true" (ngModelChange)="selectAllDocuments()"><label for="selectAll">&nbsp;</label>
                    </th>
                    <th>Группа документов</th>
                    <th>Документ</th>
                    <th>Владелец</th>
                    <th class="text-center">Оригиналов</th>
                    <th class="text-center">Копий</th>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let doc of appealDocuments; let idx = index">
                        <tr *ngIf="(!doc.subserviceId || doc.subserviceId) && !doc.deleted">
                            <td *ngIf="envelope.status == 'draft'">
                                <input type="checkbox" class="checkbox" id="doc{{doc.guid}}{{envelope.guid}}"
                                       (change)="selectDocument(doc)"
                                       [checked]="checkDocumentSelected(doc)"><label
                                    for="doc{{doc.guid}}{{envelope.guid}}">&nbsp; </label>
                            </td>
                            <td>{{doc.groupName ? doc.groupName : 'Прочие документы'}}</td>
                            <td>{{doc.name}}</td>
                            <td>
                                <span *ngIf="!doc.objects || doc.objects && doc.objects.length == 0">Общий</span>
                                <div *ngIf="doc.objects && doc.objects.length > 0"><span
                                        *ngFor="let object of doc.objects; let last = last">{{getObjectHeaderByGuid(object)}}<span
                                        *ngIf="!last">, </span></span></div>
                            </td>
                            <td class="text-center">
                                <span *ngIf="envelope.status != 'draft' || !doc.originals || doc.originals == 0">{{doc.originals ? doc.originals : 0}}</span>
                                <touch-spin [(ngModel)]="doc.originals" [max]="doc.originals" mode="small"
                                            *ngIf="envelope.status == 'draft' && doc.originals && doc.originals > 0"></touch-spin>
                            </td>
                            <td class="text-center">
                                <span *ngIf="envelope.status != 'draft' || !doc.copies || doc.copies == 0">{{doc.copies ? doc.copies : 0}}</span>
                                <touch-spin [(ngModel)]="doc.copies" [max]="doc.copies" mode="small"
                                            *ngIf="envelope.status == 'draft' && doc.copies && doc.copies > 0"></touch-spin>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </ng-container>

    </div>
</div>

