<ng-container *ngIf="!isEdit" >
    <table class="table">
        <thead>
        <tr>
            <th class="text-bold text-size-small">
                {{ localizations['person-checking-people'].basic_inspector }}
            </th>
            <th class="text-bold text-size-small">
                {{ localizations['person-checking-people'].replacement_person }}
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let persons of checkingPersons; let idx = index">
            <td>
                {{ persons.mainPerson[0].text }}
            </td>
            <td>
                {{ persons.replacingPerson ? persons.replacingPerson[0].text : localizations.common.not_selected_m }}
            </td>
            <td class="text-right">
                <button class="btn btn-sxs"
                        [title]="localizations.common.change"
                        (click)="edit(persons)">
                    <i class="icon-pencil3"></i>
                </button>
                <button class="btn btn-sxs"
                        [title]="localizations.common.delete"
                        (click)="delete(idx)">
                    <i class="icon-trash"></i>
                </button>
            </td>
        </tr>
        </tbody>
    </table>

    <div class="alert alert-warning no-border no-border-radius"
         *ngIf="checkingPersons.length == 0">
        <span class="text-semibold">
            {{ localizations.common.attention }}
        </span>
        {{ localizations['person-checking-people'].checking_persons_not_added }}
    </div>
    <button class="btn btn-primary ml-20 mb-20"
            (click)="addCheckingPerson()">
        {{ localizations.common.add }}
    </button>
</ng-container>

<div class="bg-primary-50 p-20"
     *ngIf="isEdit">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group form-group-lg">
                <label>
                    {{ localizations['person-checking-people'].checking_person }}
                </label>
                <ng-select
                    [allowClear]="false"
                    [items]="users"
                    [multiple]="false"
                    [(ngModel)]="checkingPerson.mainPerson"
                    [placeholder]="localizations.tasks.select_user"
                    required>
                </ng-select>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group form-group-lg">
                <label>Замещающее лицо</label>
                <ng-select
                    [allowClear]="false"
                    [(ngModel)]="checkingPerson.replacingPerson"
                    [multiple]="false"
                    [items]="users"
                    [placeholder]="localizations['person-checking-people'].replacing_person"
                    required>
                </ng-select>
            </div>
        </div>
    </div>

    <button class="btn btn-primary"
            (click)="apply()">
        {{ localizations.common.apply }}
    </button>
    <button class="btn btn-warning ml-15"
            (click)="cancel()">
        {{ localizations.common.do_cancel }}
    </button>
</div>

