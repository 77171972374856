import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntityCheckExamination } from './models/appeal-entity-check-examination';
import { AppealEntitiesService } from '../../appeal-entities.service';

/**
 * Решение прокуратуры
 */
@Component({
    selector: 'appeal-entity-check-examination',
    templateUrl: 'appeal-entity-check-examination.component.html',
})
export class AppealEntityCheckExaminationComponent implements OnInit {
    @Input() public entity: AppealEntityCheckExamination;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public currentUser = this.storage.getItem('user');

    public isCheckPlan = false;
    public isCheckUnplan = false;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public storage: StorageService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);

        const isDemoStend = this.storage.getItem('demoStend').isDemo;
        if (isDemoStend) {
            this.initDemo();
        } else {
            this.init();
        }
    }

    public initDemo() {
        if (!this.entity.date) {
            this.entity.date = this.appeal.dateRegister;
        }
        if (!this.entity.number) {
            this.entity.number = this.appeal.number;
        }
        if (!this.entity.brief) {
            this.entity.brief = 'О направлении на экспертизу';
        }
        const order = this.appealEntitiesService.findEntity(this.appeal, 'order');
        if (order && order.experts && order.experts.length > 0) {
            if (!this.entity.organization && order.experts[0].organization) {
                this.entity.organization = order.experts[0].organization;
            }
        }
        if (!this.entity.employeeSignatory) {
            this.entity.employeeSignatory = this.getСurrentUser();
        }
    }

    public init() {
        if (!this.entity.date) {
            this.entity.date = '';
        }
        if (!this.entity.brief) {
            this.entity.brief = 'О направлении на экспертизу';
        }
        if (!this.entity.employeeSignatory) {
            this.entity.employeeSignatory = this.getСurrentUser();
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public getСurrentUser() {
        return {
            guid: CommonUtilities.GenerateGuid(),
            name: [
                {
                    id: this.currentUser.id ? this.currentUser.id : '',
                    text: this.currentUser.name ? this.currentUser.name : '',
                },
            ],
            position: this.currentUser.position ? this.currentUser.position : '',
        };
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }
}
