import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionService, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { ProcessService } from '../../../../processes/process.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'organization-process',
    templateUrl: 'organization-process.component.html',
})
export class OrganizationProcessComponent implements OnInit {
    @Input() public organization;
    @Input() public organizationField;
    @Input() public processCode;
    @Input() public process;

    public editedProcess;
    public isCreateOdEdit = false;
    public taskId;

    public editedTab;

    public isProcessUploadProcess = false;
    public localizations;

    @Output() public onSaveOrganization = new EventEmitter<boolean>();
    @Output() public onSelectProcess = new EventEmitter<any>();
    @Output() public onChangeProcess = new EventEmitter<any>();

    public constructor(
        private toaster: ToasterService,
        private selectionService: SelectionService,
        private processService: ProcessService,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this._loadTranslations();
        if (this.selectionService.isProcessSelect) {
            this.completeSelectBpmn();
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public completeSelectBpmn() {
        if (this.selectionService.isProcessSelect) {
            if (this.selectionService.additionalData && this.selectionService.additionalData.taskId) {
                this.taskId = this.selectionService.additionalData.taskId;
            }

            if (this.selectionService.selectedItems.length > 0) {
                this.saveProcessFromEntity(this.selectionService.selectedItems[0]);
            }

            this.selectionService.clearData();
        }
    }

    public selectProcess(taskId = null) {
        this.onSelectProcess.emit({ type: this.processCode, taskId: taskId });
    }

    public editProcess(process) {
        this.editedProcess = _.cloneDeep(process);
        this.isCreateOdEdit = true;
    }

    /**
     * Удаление основного процесса
     */
    public deleteProcess() {
        this.process = null;
        this.organization[this.organizationField][this.processCode].process = null;
        delete this.organization[this.organizationField][this.processCode].processId;
        delete this.organization[this.organizationField][this.processCode].xsdBpmn;
        delete this.organization[this.organizationField][this.processCode].camundaDeploymentInfo;
    }

    /**
     * Удаление подчиненного процесса
     * @param taskGuid
     */
    public deleteSubProcess(taskGuid) {
        const task = this.process.tasks.subProcess.find((item) => item.guid === taskGuid);
        delete task.subProcess;
        const organizationTask = this.organization[this.organizationField][this.processCode].process.tasks.find(
            (item) => item.guid === taskGuid
        );
        delete organizationTask.subProcess;
    }

    public createSubProcess(taskId) {
        this.taskId = taskId;
        this.isCreateOdEdit = true;
    }

    public addNewProcess() {
        this.editedProcess = {};
        this.isCreateOdEdit = true;
    }

    public cancelEditProcess() {
        this.process = {};
        this.isCreateOdEdit = false;
    }

    /**
     * Создание процесса вручную, а не выбор из списка
     * @param process
     */
    public saveProcessFromEntity(process) {
        // Добавление подпроцесса
        if (this.taskId) {
            const findTask = this.process['tasks'].subProcess.find((item) => item.id === this.taskId);
            findTask.subProcess = process;
            const findTaskInOrganization = this.organization[this.organizationField][
                this.processCode
            ].process.tasks.find((item) => item.id === this.taskId);
            if (findTaskInOrganization) {
                findTaskInOrganization.subProcess = this.processService.generateProcessDataToEntity(process);
            }
            this.taskId = null;
            this.onChangeProcess.emit(this.process);
        } else {
            // Добавление основного процесса
            this.process = process;
            this.onChangeProcess.emit(this.process);
            if (!this.organization[this.organizationField][this.processCode]) {
                this.organization[this.organizationField][this.processCode] = {};
            }
            this.organization[this.organizationField][this.processCode].process =
                this.processService.generateProcessDataToEntity(this.process);
        }
        this.isCreateOdEdit = false;
    }

    public uploadProcess() {
        this.isProcessUploadProcess = true;
        this.processService
            .processingTasksVariables(
                this.organization[this.organizationField][this.processCode].process,
                this.organization[this.organizationField].additionalDataTabs
            )
            .then(() => {
                this.processService
                    .uploadCamundaProcess(this.process)
                    .then((uploadData) => {
                        this.isProcessUploadProcess = false;
                        if (uploadData) {
                            this.organization[this.organizationField][this.processCode].xsdBpmn = uploadData.xsdBpmn
                                ? uploadData.xsdBpmn
                                : null;
                            this.organization[this.organizationField][this.processCode].camundaDeploymentInfo =
                                uploadData.camundaDeploymentInfo ? uploadData.camundaDeploymentInfo : null;
                            this.organization[this.organizationField][this.processCode].processId = Object.keys(
                                uploadData.camundaDeploymentInfo.deployedProcessDefinitions
                            )[0];
                        }
                        this.toaster.success(this.localizations.organizations.process.successfully_uploaded);
                        this.onSaveOrganization.emit(true);

                        const organization = this.storage.getItem('currentOrganization');

                        if (organization._id === this.organization._id) {
                            organization[this.organizationField] = this.organization[this.organizationField];
                            this.storage.setItem('currentOrganization', organization);
                        }
                    })
                    .catch((error: any) => {
                        this.isProcessUploadProcess = false;
                        console.log('error', error);
                    });
            });
    }
}
