import { Injectable } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import * as _ from 'lodash-es';

@Injectable()
export class AppealEnvelopesService {
    public appeal; // формируемое дело
    public data = {}; // Описательный объект конвертов дела
    public tempData = {}; // Временный объект

    public responsibleOrganizations = []; // ОГВ в которые могут быть созданы конверты
    public currentOrganization = this.storage.getItem('currentOrganization');

    // Возможные статусы конвертов
    public envelopeStatuses = {
        draft : 'Черновик',
        process : 'Сформирован',
        send : 'Отправлен',
        returnRevision: 'Возвращен на доработку',
        returnResult : 'Получен ответ',
    };

    public constructor(
        private storage: StorageService,
    ) {
    }

    /**
     * Инициализация данных при активации компонента
     * @param appeal
     * @param envelopes
     */
    public initData(appeal, envelopes) {
        this.appeal = appeal;
        this.responsibleOrganizations = [];
        this.currentOrganization = this.storage.getItem('currentOrganization');
        if (envelopes) {
            envelopes.forEach(envelope => {
                this.data[envelope.guid] = envelope;
            });
        }
    }

    /**
     * Создание нового конверта
     */
    public createEnvelope() {
        const envelopeGuid = CommonUtilities.GenerateGuid();
        const envelope = {
            guid: envelopeGuid,
            status: 'draft',
            documents: [],
            responsibleOrganization: null,
            unit: {
                id: this.currentOrganization['_id'],
                name: this.currentOrganization['name'],
            },
            appeal: {
                id: this.appeal._id,
                number: this.appeal.number,
                shortNumber: this.appeal.shortNumber,
                dateRegister: this.appeal.dateRegister,
                subservice: null,
            },
        };

        // ОГВ
        envelope.responsibleOrganization = this.appeal.subservice.responsibleOrganization;

        // Дата регистрации
        envelope.appeal.dateRegister = this.appeal.subservice.dateRegister;

        // Услуга дела
        envelope.appeal.subservice = {
            id: this.appeal.subservice.id,
            title: this.appeal.subservice.shortTitle,
            serviceId: this.appeal.subservice.serviceId,
            guid: this.appeal.subservice.guid,
        };
        if (this.appeal.subservice.classificSubserviceId) {
            envelope.appeal.subservice.classificSubserviceId = this.appeal.subservice.classificSubserviceId;
            envelope.appeal.subservice.classificSubserviceName = this.appeal.subservice.classificSubserviceName;
        }

        return envelope;
    }
}
