import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard } from '@evolenta/core';
import { LicensingActivityDefaultComponent } from './components/licensing-activity-default.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'licensing-activity',
        component: SidebarComponent,
        data: {
            module: 'licensing-activity',
            breadcrumb: 'Лицензирование',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            {
                path: 'default',
                component: LicensingActivityDefaultComponent,
                data: {},
            },
            {
                path: '**',
                component: LicensingActivityDefaultComponent,
                data: {},
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class LicensingActivityRouting {
}
