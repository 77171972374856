import { NgModule } from '@angular/core';
import { SettingsRouting } from './settings.routing';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { SettingsComponent } from './settings/settings.component';
import { SettingEditComponent } from './settings/setting-edit.component';
import { SettingsSectionComponent } from './settings/settings-section.component';
import { SettingsResolve } from './settings/settings.resolve';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SettingsRouting,
        SharedComponentsModule,
        BreadcrumbsModule,
    ],
    declarations: [
        SettingsSectionComponent,
        SettingsComponent,
        SettingEditComponent,
    ],
    providers: [
        SettingsResolve,
    ],
    exports: [],
})

export class SettingsModule {
}
