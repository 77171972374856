import { Injector, Type } from '@angular/core';
import { CoreStaticConfigInterface, CoreDynamicConfigInterface, LogoTitleSourceEnum } from '@evolenta/core';
import { ServicesService } from '../modules/subservices/services/services.service';
import { environment } from '../../environments/environment';
import { Permission } from '../common/services/permission';

export const CoreModuleConfig: CoreStaticConfigInterface = {
    endpoints: {
        rootUrl: $('base').attr('href'),
        server: environment.url,
    },
    paths: {
        login: 'login',
        loginMobile: 'login-mobile',
        esiaLogout: 'esia-logout',
        esiaLogoutTargetHref: null,
        authResult: 'auth-result',
        selectBranch: 'select-organization',
        mainMenu: 'select-application',
        userProfile: 'user-profile',
        configureFilters: 'configure-filters',
        complaintsBase: 'complaints',
        inspectorBase: 'inspector',
        complaintsNotifications: '/complaints/notifications',
        inspectorNotifications: '/supervisor/notifications',
    },
    aboutConfig: {
        phone: '+7 843 264-72-63',
        email: 'helpt18@tatar.ru',
        serviceDeskLink: 'https://task.tatar.ru/servicedesk/customer/portal/24',
        platfrom: {
            name: 'Прикладная платформа «Государственные и муниципальные услуги»',
            description:
                'платформа для автоматизации процессов оказания государственных и муниципальных услуг, административных процессов министерств, ведомств, а также любых регламентированных процессов',
            developer: 'ГУП «Центр информационных технологий РТ»',
        },
        contacts: {
            phone: '+7 843 264-72-63, режим работы с 8:00 до 18:00 в рабочие дни',
            link: 'https://task.tatar.ru/servicedesk/customer/portal/24',
            email: 'helpt18@tatar.ru',
        },
    },
    collections: {
        catalogueDocuments: 'catalogueDocuments',
    },
    storageConfig: {
        externalAuthKey: 'isExternalAuth',
        externalAuthUrlKey: 'externalAuthUrl',
        esiaAuthKey: 'loginOnlyByEsia',
        esiaSessionIdKey: 'esiaSessionId',
        mobileAuthKey: 'isMobileAuth',
        activeUserKey: 'user',
        activeBranchKey: 'currentOrganization',
        applicationsKey: 'applications',
        userPermissionsKey: 'userPermissions',
        userDashboardsUrlSettingKey: 'userDashboardsHref',
        standNameKey: 'standName',
        standDescriptionKey: 'standDescription',
        standHelpKey: 'standHelp',
    },
    restConfig: {
        url: 'api/v1/',
        customUrl: 'api/tatar/v1/',
        search: 'search/',
        treeSearch: 'legalbusiness/treesearch/',
        create: 'create/',
        update: 'update/',
        find: 'find/',
        delete: 'delete/',
        searchUsersThreshlod: 1000,
    },
    appConfig: {
        title: 'ПП ГМУ',
        description: 'Региональная система межведомственного электронного взаимодействия',
        useLogo: false,
        showVersion: true,
        allowChangeLanguage: true,
        esiaLoginButtonTitle: 'Вход через ЕСИА',
        selectBranchScreenTitle: 'Выберите организацию',
        logoTitleSource: LogoTitleSourceEnum.APP_TITLE,
        delayForAutoNavigateToEsia: 0,
    },
    userConfig: {
        branchesPropertyName: 'sprOrganizations',
    },
    accessConfig: {
        allSystemPermission: Permission.All_System,
        createUserPermission: Permission.User_Create,
        updateUserPermission: Permission.User_Update,
        deleteUserPermission: Permission.User_Delete,
        statusToPermissionMap: [
            { status: 'draft', permission: Permission.Appeal_Status_Draft },
            { status: 'consultation', permission: Permission.Appeal_Status_Consultation },
            { status: 'beforeIssued', permission: Permission.Appeal_Status_BeforeIssued },
            { status: 'issued', permission: Permission.Appeal_Status_Issued },
            { status: 'issuedPost', permission: Permission.Appeal_Status_Issued },
            { status: 'issuedReturnToOGV', permission: Permission.Appeal_Status_Issued },
            { status: 'issuedOther', permission: Permission.Appeal_Status_Issued },
            { status: 'rejectedReceiptDocs', permission: Permission.Appeal_Status_RejectedReceiptDocs },
            { status: 'rejected', permission: Permission.Appeal_Status_Rejected },
            { status: 'annulled', permission: Permission.Appeal_Status_Annulled },
            { status: 'archive', permission: Permission.Appeal_Status_Archive },
            { status: 'process', permission: Permission.Appeal_Status_Process, checkAdditionalPermissions: true },
            { status: 'forwarded', permission: Permission.Appeal_Status_Forwarded },
            { status: 'returned', permission: Permission.Appeal_Status_Returned },
            { status: 'forwardedForIssue', permission: Permission.Appeal_Status_ForwardedForIssue },
            { status: 'unclaimed', permission: Permission.Appeal_Status_Unclaimed },
        ],
        searchUserPermission: Permission.Users_Search,
        searchOrganizationsPermission: Permission.Organizations_Search,
        createOrganizationPermission: Permission.Organization_Create,
        updateOrganizationPermission: Permission.Organization_Update,
        deleteOrganizationPermission: Permission.Organization_Delete,
        signFile: Permission.File_Sign,
        searchRolesPermission: Permission.Roles_Search,
        createRolePermission: Permission.Role_Create,
        updateRolePermission: Permission.Role_Update,
        deleteRolePermission: Permission.Role_Delete,
    },
    featuresConfig: {
        isFinancialControlActive: false,
        hideWorkPhoneOnUsersProfilePage: true,
        isRequiredWorkPhoneOnUserEditPage: false,
        isShowTelegramOnUsersProfilePage: true,
    },
};

export function CoreModuleDynamicDepsConfigFactory(injector: Injector): CoreDynamicConfigInterface {
    return {
        ...CoreModuleConfig,
        services: {
            correctSubserviceByCurrentUnit: (service: any) => {
                return injector
                    .get<ServicesService>(ServicesService as Type<ServicesService>)
                    .correctSubserviceByCurrentUnit(service);
            },
        },
    };
}
