<!-- Отображение при просмотре -->
<ng-container *ngIf="mode == 'view'">
    <div id="document{{document.guid}}"
         class="border-bottom border-bottom-default card-line pl-20 pr-20"
         #documentCard>
        <div class="display-inline-block card-preview">
            <appeal-document-preview mode="view"
                                     [document]="document"
                                     [group]="group"
                                     (onActivateTab)="activateTab($event)">
            </appeal-document-preview>
        </div>

        <div *ngIf="!isDisabledByRso" class="display-inline-block card-buttons mt-15 text-right"
             style="width: 180px">

            <!-- Кнопка "Загрузка файла" -->
            <input id="file-{{document.guid}}"
                   class="inputfile hide"
                   type="file"
                   name="file-{{document.guid}}"
                   (change)="fileChange($event)"/>
            <label class="btn btn-default btn-sxs"
                   for="file-{{document.guid}}"
                   *ngIf="task && allowEdit">
                <i class="icon-attachment"></i>
            </label>

            <!-- Кнопка "Копировать файлы" -->
            <button class="btn btn-default btn-sxs"
                    *ngIf="allowEdit && data.queue.length > 0 && (data.allowEdit || data.envelope)"
                    (click)="cloneFiles()"
                    type="button"
                    [title]="localizations['appeals.documents'].copy_files_to_other_groups">
                        <b>
                            <i inlineSVG="assets/icon-copy.svg"></i>
                        </b>
            </button>

            <!-- Кнопка "Печать документа" (при одной ПФ) -->
            <button class="btn btn-default btn-sxs"
                    [disabled]="!appeal._id || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])"
                    *ngIf="allowEdit && data.printForms && data.printForms.length == 1 && data.allowEdit && !data.isElectronicDocument" (click)="print()" type="button" title="Сформировать печатную форму">
                        <i class="icon-printer2"></i>
            </button>

            <!-- Кнопка "Печать документа" (при нескольких ПФ) -->
            <div class="btn-group"
                 *ngIf="allowEdit && data.printForms && data.printForms.length > 1 && data.allowEdit && !data.isElectronicDocument">
                <button class="btn btn-default btn-sxs dropdown-toggle"
                        data-toggle="dropdown"
                        [disabled]="!appeal._id || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])">
                            <i class="icon-printer2"></i>
                            <span class="caret ml-5"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let printForm of data.printForms">
                        <a (click)="print(printForm)">
                            {{printForm.name}}
                        </a>
                    </li>
                </ul>
            </div>

            <!-- Кнопка "Проверка ЭП" -->
            <button class="btn btn-default btn-sxs"
                    *ngIf="!task && allowEdit"
                    (click)="verifySignature()"
                    type="button"
                    title="Проверка ЭП">
                <b>
                    <i [inlineSVG]="'assets/icon-copy.svg'"></i>
                </b>
            </button>

            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs"
                    (click)="edit()"
                    *ngIf="!task && data.allowEdit || data.allowPartiallyEdit || data.queue.length > 0"
                    type="button"
                    title="{{data.allowEdit ? localizations.common.edit : localizations.common.view}}">
                <b>
                    <i [inlineSVG]="!allowEdit || !data.allowEdit && !data.allowPartiallyEdit ? 'assets/icon-eye.svg' : 'assets/icon-edit.svg'"></i>
                </b>
            </button>

            <!-- Кнопка "Обновить данные запроса" -->
            <button class="btn btn-default btn-sxs"
                    (click)="updateEnvelopeData()"
                    [disabled]="!accessService.hasAccess([permissions.Envelopes_Search], true, appeal.status)"
                    *ngIf="document.requestId && data.envelope && data.envelope.status && data.envelope.status == 'sended'"
                    type="button"
                    [title]="localizations['appeals.documents'].update_request_data">
                <i class="icon-loop"></i>
            </button>

            <!-- Кнопка "Удалить документ" -->
            <button class="btn btn-default btn-sxs"
                    (click)="delete()"
                    type="button"
                    [title]="localizations.common.delete"
                    [disabled]="!accessService.hasAccess([permissions.Appeal_Document_Delete], true, appeal.status, [{code: 'process', permission: this.permissions.Allow_Print_Registers_After_Appeal_Registration}])"
                    *ngIf="allowEdit && data.allowDelete">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </button>
        </div>
    </div>
    <!-- Файлы -->
    <appeal-document-files [document]="document"
                           [group]="group"
                           [task]="task"
                           (onDeleteFile)="afterDeleteFile()"
                           *ngIf="task"
                           [allowEdit]="allowEdit">
    </appeal-document-files>
</ng-container>


<!-- Редактирование отдельного документа -->
<div class="panel panel-shadow no-border m-20"
     *ngIf="mode == 'edit'"
     [ngClass]="{'no-border-top': !task}">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span *ngIf="!data.name && !data.doc">
                {{localizations['appeals.documents'].new}}
            </span>
            <span *ngIf="data.name || data.doc">
                {{data.name ? data.name : data.doc.name}}
            </span>
            <span class="badge badge-xs badge-flat text-uppercase ml-5 text-info border-info"
                  *ngIf="data.isForwardingRequest">
                {{localizations['appeals.documents'].send_to_ogv}}
            </span>
            <span *ngIf="((document.docGuid == 'other') || (document.docGuid && group && group.docs && group.docs.length > 1) || (!group.guid && !document.printForm)) && data.allowEdit && !isProcessSelectDocumentKind && !isProcessEditDocumentName && !isDisabledByRso">
                <span class="anchor text-info text-size-small ml-5"

                      (click)="changeDocument()">
                    {{localizations.common.change_small}}
                </span>

                <span *ngIf="!document.envelopeId">
                    <span class="ml-5">|</span>

                    <span class="anchor text-info text-size-small ml-5"
                          (click)="fillWithPreviousValues()">
                            {{ localizations['appeals.documents'].sed_praktika.use_previous_data }}
                    </span>

                    <span *ngIf="isEnableTemplates" class="ml-5">|</span>

                    <span
                        *ngIf="isEnableTemplates"
                        class="anchor text-info text-size-small ml-5"
                        (click)="getEnvelopTemplates()"
                    >
                        {{ localizations['appeals.documents'].sed_praktika.use_template }}
                    </span>

                    <span class="ml-5">|</span>

                    <span class="anchor text-info text-size-small ml-5"
                          (click)="clearAll()">
                            {{ localizations['appeals.documents'].sed_praktika.clean_all }}
                    </span>
                </span>
            </span>


        </h4>
        <!-- Управляющие кнопки -->
        <div *ngIf="!isDisabledByRso" class="heading-elements">

            <!-- Кнопка "Печать документа" (при одной ПФ) -->
            <button class="btn btn-primary heading-btn btn-labeled btn-labeled-right" [ngClass]="{'btn-xs': task}"
                    [disabled]="!appeal._id || data.isProcessGeneratePrintForm
                                || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])"
                    *ngIf="data.printForms && data.printForms.length == 1 && data.allowEdit && !data.isElectronicDocument"
                    (click)="print()"
                    type="button">
                <b>
                    <i [ngClass]="{'icon-spinner2 spinner': data.isProcessGeneratePrintForm, 'icon-printer': !data.isProcessGeneratePrintForm}"></i>
                </b>
                {{localizations.common.print}}
            </button>

            <!-- Кнопка "Печать документа" (при нескольких ПФ) -->
            <div class="btn-group"
                 *ngIf="data.printForms && data.printForms.length > 1 && data.allowEdit && !data.isElectronicDocument"
                 [ngClass]="{'btn-xs': task}">
                <button class="btn btn-primary heading-btn btn-labeled btn-labeled-right dropdown-toggle"
                        data-toggle="dropdown"
                        [disabled]="!appeal._id || data.isProcessGeneratePrintForm
                                || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])">
                    <b>
                        <i [ngClass]="{'icon-spinner2 spinner': data.isProcessGeneratePrintForm, 'icon-printer': !data.isProcessGeneratePrintForm}"></i>
                    </b>
                    {{localizations.common.print}}
                    <span class="caret ml-5"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let printForm of data.printForms">
                        <a (click)="print(printForm)">
                            {{printForm.name}}
                        </a>
                    </li>
                </ul>
            </div>

            <button class="btn btn-primary-overline heading-btn btn-labeled btn-labeled-right"
                    [ngClass]="{'btn-xs': task}"
                    [disabled]="!accessService.hasAccess([permissions.Envelope_Send])"
                    *ngIf="document.requestId && appeal._id
                           && data.allowEdit && appeal.dateRegister
                           && (data.envelope.status == 'created' || data.envelope.status == 'error')"
                    (click)="send()"
                    [disabled]="isProcessSendingEnvelope">
                <b>
                    <i [ngClass]="{'icon-spinner2 spinner': isProcessSendingEnvelope, 'icon-arrow-right16': !isProcessSendingEnvelope}"></i>
                </b>
                {{ localizations.common.send }}
            </button>

            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="apply()"
                    [ngClass]="{'btn-xs': task}"
                    [disabled]="isProcessSendingEnvelope"
                    *ngIf="data.allowEdit || data.allowPartiallyEdit">
                {{ localizations.common.apply }}
            </button>

            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()"
                    [ngClass]="{'btn-xs': task}">
                {{ data.allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>
    <div class="panel-body no-padding">
        <!-- Выбор вида документа (для документа группы) -->
        <div class="bg-primary-50 pt-10 pb-10 pl-20 pr-20"
             *ngIf="isProcessSelectDocumentKind && group.guid && document.guid !== 'other'">
            <div class="text-bold no-padding">
                {{ localizations['appeals.documents'].new }}:
            </div>
            <div class="radio-block"
                 *ngFor="let doc of group.docs | orderBy: ['displayOrder']">
                <input id="doc{{doc.guid}}{{document.guid}}"
                       class="radio"
                       type="radio"
                       name="doc{{document.guid}}"
                       [value]="doc"
                       [checked]="document.docGuid == doc.guid"
                       (click)="selectDocumentKind(doc)">
                <label for="doc{{doc.guid}}{{document.guid}}">
                    {{doc.name}}
                    <span class="badge badge-xs badge-flat text-uppercase ml-5 text-info border-info"
                          *ngIf="doc.isForwardingRequest">
                        {{localizations['appeals.documents'].send_to_ogv}}
                    </span>
                    <span class="help-block no-margin"
                          *ngIf="doc.description">
                        {{doc.description}}
                    </span>
                </label>
            </div>

            <span class="display-inline-block anchor text-info text-size-small mt-15"
                  (click)="selectDocumentKind(otherDocument)">
                {{localizations['appeals.documents'].other_document}}
            </span>
        </div>

        <!-- Ввод наименования произвольного документа -->
        <div class="input-group input-group-lg m-20"
             *ngIf="isProcessEditDocumentName">
            <input class="form-control"
                   type="text"
                   [placeholder]="localizations['appeals.documents'].enter_document_appellation"
                   [(ngModel)]="data.name">
            <span class="input-group-btn">
                <button class="btn btn-default"
                        type="button"
                        (click)="applyDocumentName(data.name)">
                    <i class="icon-checkmark3"></i>
                </button>
            </span>
        </div>

        <div *ngIf="!isProcessSelectDocumentKind && !isProcessEditDocumentName && !isProcessSimplifyAddDocumentToComplex && !data.link">

            <!-- Вкладки переключения блоков данных -->
            <div class="mt-20"
                 *ngIf="mode == 'edit'">
                <element-edit-tabs [tabs]="editTabs"
                                   [active]="activeTab"
                                   [elementId]="document.guid"
                                   [type]="'documents'"
                                   (onActivateTab)="activateTab($event)">
                </element-edit-tabs>
            </div>


            <!-- Общие данные (общая вкладка) -->
            <appeal-document-common [document]="document"
                                    [group]="group"
                                    [appeal]="appeal"
                                    (afterSaveEnvelope)="afterGetEnvelopeXsdData()"
                                    (afterSendEnvelope)="afterSendEnvelope()"
                                    [hidden]="activeTab != 'common'"
                                    #commonData>
            </appeal-document-common>

            <!-- Файлы (Ответ на запрос) -->
            <ng-container *ngIf="activeTab == 'files'">
                <fieldset *ngIf="document.requestId && data.envelope.resultXml">
                    <legend class="text-bold no-margin-bottom pl-20">
                        {{localizations['appeals.documents'].request_results}}
                    </legend>
                    <div class="p-20">
                        <dynamic-form [xsd]="data.envelope.request.resultXsd"
                                         [edit]="false"
                                         tabsMode="none"
                                         [model]="data.envelope.resultXml"
                                         [saveInMainObject]="true"
                                         [disabled]="true">
                        </dynamic-form>
                    </div>
                </fieldset>
                <fieldset>
                    <legend class="text-bold no-margin-bottom pl-20"
                            *ngIf="document.requestId && data.envelope.resultXml">
                        {{ localizations['appeals.documents'].files }}
                    </legend>
                    <appeal-document-files [document]="document">
                    </appeal-document-files>
                </fieldset>
            </ng-container>

            <!-- Реквизиты документа -->
            <appeal-document-properties [document]="document"
                                        (onAfterSave)="afterSaveDocumentProperties($event)"
                                        *ngIf="activeTab == 'properties'"
                                        #appealDocumentProperties>
            </appeal-document-properties>

        </div>
        <span
            *ngIf="isEnableTemplates && activeTab == 'common'"
            class="anchor text-info text-size-large ml-20"
            (click)="processSaveTemplate()"
        >
            {{ localizations['appeals.documents'].sed_praktika.save_template }}
        </span>
        <div class="mt-30"></div>
    </div>
</div>

<modal-dialog [message]="localizations['appeals.documents'].sure_want_to_remove_from_knm"
             [title]="localizations['appeals.documents'].removal"
             (onSelect)="sendDeleteRequest($event)"
             #deleteModal>
</modal-dialog>

<ng-template #saveTemplateModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Введите название сохраняемого шаблона</h4>
    </div>
    <div class="modal-body">
        <input
            _ngcontent-nyt-c33=""
            class="form-control ng-untouched ng-pristine ng-valid ng-star-inserted"
            [(ngModel)]="this.selectedTemplate.templateName"
            type="text"
            placeholder="Наименование шаблона"
        />
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" type="button" (click)="saveTemplate()" [disabled]="!selectedTemplate">
            {{ localizations.common.save }}
        </button>
        <button class="btn btn-default" type="button" (click)="modalRef.hide()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>

<ng-template #chooseTemplatesModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations['appeals.documents'].sed_praktika.choose_template }}
        </h4>
    </div>
    <div class="modal-body">
        <ng-container *ngFor="let template of templates">
            <div class="radio-block">
                <input
                    id="template{{ template.guid }}"
                    class="radio"
                    type="radio"
                    name="useTemplate"
                    (change)="selectedTemplate = template"
                />
                <label for="template{{ template.guid }}">
                    {{ template.templateName }}
                </label>
                <button
                    (click)="deleteTemplate(template)"
                    title="Удалить"
                    style="background-color: #fcfcfc"
                    class="btn btn-sxs">
                    <i inlineSVG="assets/icon-trash.svg"></i>
                </button>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-default"
            type="button"
            (click)="fillEnvelopeDataFromTemplate()"
            [disabled]="!selectedTemplate"
        >
            {{ localizations.common.apply }}
        </button>
        <button class="btn btn-default" type="button" (click)="modalRef.hide()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>

<ng-template #cloneFilesModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations['appeals.documents'].files_copying}}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <strong>
            {{localizations['appeals.documents'].select_group_and_document_receivers}}:
        </strong>
        <ng-container *ngFor="let cloneGroup of cloneFilesData.groups">
            <div class="radio-block">
                <input id="cloneGroup{{cloneGroup.guid}}"
                       class="radio"
                       type="radio"
                       name="cloneGroups"
                       [checked]="cloneFilesData.selectedGroup && cloneFilesData.selectedGroup.guid === cloneGroup.guid"
                       (change)="cloneFilesData.selectedGroup = cloneGroup">
                <label for="cloneGroup{{cloneGroup.guid}}">
                    {{cloneGroup.name}}
                </label>
            </div>
            <div class="ml-20"
                 *ngIf="cloneFilesData.selectedGroup && cloneFilesData.selectedGroup.guid === cloneGroup.guid">
                <div class="radio-block"
                     *ngFor="let doc of cloneFilesData.selectedGroup.documents">
                    <input id="cloneDocument{{doc.guid}}"
                           class="radio"
                           type="radio"
                           name="cloneDocuments"
                           [checked]="cloneFilesData.selectedDocument && cloneFilesData.selectedDocument.guid === doc.guid"
                           (change)="cloneFilesData.selectedDocument = doc">
                    <label for="cloneDocument{{doc.guid}}">
                        {{doc.name}}
                    </label>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="cloneFilesData.selectedGroup && cloneFilesData.selectedDocument">
            <hr>
            <strong>
                {{localizations['appeals.documents'].select_files_to_copy}}
            </strong>
            <div class="checkbox-block"
                 *ngFor="let file of cloneFilesData.files; let idx = index">
                <input id="cloneFile{{file.guid}}{{idx}}"
                       class="checkbox"
                       type="checkbox"
                       [checked]="checkFileSelectForClone(file)"
                       (change)="toggleFileSelectForClone(file)">
                <label for="cloneFile{{file.guid}}{{idx}}">
                    {{file.originalName}}
                    <small class="text-info"
                           *ngIf="file.mainResult">
                        {{localizations.common.result}}
                    </small>
                </label>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="processCloneFiles()"
                [disabled]="!cloneFilesData.selectedGroup || !cloneFilesData.selectedDocument || cloneFilesData.length === 0">
            {{localizations.common.copy}}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{localizations.common.cancel}}
        </button>
    </div>
</ng-template>

<modal-dialog [message]="localizations['appeals.documents'].sed_praktika.sure_want_to_remove_template"
              [title]="localizations.common.removal"
              (onSelect)="processDeleteTemplate($event)"
              #deleteTemplateModal>
</modal-dialog>
