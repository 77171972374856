<!-- Редактирование данных выбранного субъекта в деле -->
<ng-container *ngIf="editedSubject">
    <common-appeal-subject-card [subject]="editedSubject"
                                [availableKinds]="availableSubjectKinds"
                                [activeTab]="activeTab"
                                [appeal]="appeal"
                                [noMargin]="viewMode === 'oneWindow'"
                                [subservice]="subservice"
                                [params]="subjectsParams"
                                mode="edit"
                                (onApply)="onApplySubject($event)"
                                (onApplyError)="applyError()"
                                #editCard></common-appeal-subject-card>
</ng-container>

<!-- Список субъектов дела -->
<div *ngIf="!editedSubject">
    <!-- Режим отображения в виде блоков   -->
    <fieldset *ngIf="viewMode === 'oneWindow'">
        <legend class="text-bold cursor-pointer" [ngClass]="{'no-margin-bottom': appeal.subjects.length == 0}" (click)="showSubjects = !showSubjects">
            {{onlyOne ? subjectsParams.titles['single'] : subjectsParams.titles['plural']}}
            <span class="badge bg-info-300 ml-10">Всего: {{appeal.subjects.length}}</span>
            <a class="anchor mr-5 ml-10 text-normal" (click)="addSubject()" *ngIf="appeal.subjects.length > 0 && !onlyOne && allowEdit">добавить</a>
            <a class="control-arrow">
                <i [ngClass]="{'icon-arrow-down32': showSubjects, 'icon-arrow-left32': !showSubjects}"></i>
            </a>
        </legend>
        <div *ngIf="showSubjects">
            <div class="alert alert-info no-border" *ngIf="appeal.subjects.length == 0 && hasAllowEdit()">
                {{onlyOne ? subjectsParams.titles['single'] : subjectsParams.titles['plural']}} не добавлен{{onlyOne ? '' : 'ы'}}.
                <a class="anchor mr-5" (click)="addSubject()">добавить</a>
            </div>
            <div class="row" *ngIf="appeal.subjects.length > perPage">
                <div class="col-md-6">
                    <evolenta-pagination [items]="usedSubjects" [marginBottom]="true" [count]="perPage"
                                         (onChangePage)="pagination = $event"></evolenta-pagination>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-lg no-margin-bottom">
                        <input class='form-control' type="text" [(ngModel)]="searchText" (ngModelChange)="filterSubjects()"
                               placeholder="Введите часть наименования, ОГРН или ИНН...">
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let subject of usedSubjects; let idx = index; ">
                <ng-container *ngIf="!pagination || pagination && idx >= pagination.start - 1 && idx <= pagination.end - 1">
                    <common-appeal-subject-card [subject]="subject"
                                                mode="view"
                                                [appeal]="appeal"
                                                [subservice]="subservice"
                                                [noMargin]="true"
                                                [bottomMargin]="true"
                                                [params]="subjectsParams"
                                                (onEdit)="onEditSubject($event)"
                                                (onDelete)="onDeleteSubject($event)">
                    </common-appeal-subject-card>
                </ng-container>
            </ng-container>
        </div>
    </fieldset>

    <ng-container *ngIf="viewMode == 'default'">
        <div class="alert alert-warning alert-styled-left alert-bordered hide" *ngIf="appeal.subjects.length == 0 && viewMode === 'default'">
            <span class="text-semibold">Внимание!</span>
            В дело не добавлены {{subjectsParams.titles['plural']}} субъекты.
        </div>

        <ng-container *ngFor="let subject of appeal.subjects">
            <common-appeal-subject-card [subject]="subject"
                                 mode="view"
                                 [appeal]="appeal"
                                 [subservice]="subservice"
                                 [params]="subjectsParams"
                                 (onEdit)="onEditSubject($event)"
                                 (onDelete)="onDeleteSubject($event)">
            </common-appeal-subject-card>
        </ng-container>

        <button class="btn btn-primary-overline btn-labeled btn-labeled-right m-20"
                (click)="addSubject()"
                *ngIf="hasAllowEdit() && accessService.hasAccess([permissions.Appeal_Object_Create], true, appeal.status) && viewMode === 'default' && allowEdit
                       && (!onlyOne || appeal.subjects.length === 0)">
            Добавить
            <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
        </button>
    </ng-container>
</div>

<modal-dialog [title]="modalTitle" [message]="modalMessage" (onSelect)="processingModal($event)" #alertModal></modal-dialog>
