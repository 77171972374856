import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { EntityProcessElementDataComponent } from './data/entity-process-element-data.component';
import { CommonProcessService } from '../../common-process.service';
import { EntityProcessElementFooterComponent } from './footer/entity-process-element-footer.component';
import { RestService, StorageService, ToasterService } from '@evolenta/core';
import { ObjectUtilities } from '@evolenta/utilities';

@Component({
    selector: 'entity-process-element',
    templateUrl: './entity-process-element.component.html',
})
export class EntityProcessElementComponent implements OnInit {
    @Input() public element;
    @Input() public data;
    @Input() public isFirst = false;

    public allowApply = false; // разрешение на завершение задачи

    @ViewChild('elementData', { static: false }) public elementDataComponent: EntityProcessElementDataComponent;
    @ViewChild('elementFooter', { static: false }) public elementFooterComponent: EntityProcessElementFooterComponent;

    public elementData; // объект с параметрами задачи (события)

    public constructor(
        private storage: StorageService,
        private processService: CommonProcessService,
        private restService: RestService,
        private toaster: ToasterService
    ) {}

    public ngOnInit() {
        this.prepareElementRules();
    }

    public prepareElementRules() {
        if (this.element.type === 'USER_TASK' && this.element.status === 'ACTIVE') {
            this.elementData = this.processService.getElementDataFromProcessInEntity(
                this.element,
                'tasks',
                'taskDefinitionKey',
                this.data.processInEntity
            );
            this.allowApply = this.processService.getProcessElementRule(this.element, this.elementData, this.data);
        }
    }

    public complete() {
        if (!this.elementDataComponent || (this.elementDataComponent && this.elementDataComponent.isValid())) {
            this.data.processingElements.push(this.element.guid);
            if (this.elementDataComponent) {
                this.elementDataComponent.prepareCompleteData().then(() => {
                    this.processingComplete();
                });
            } else {
                this.processingComplete();
            }
        }
    }

    public processingComplete() {
        const user = this.storage.getItem('user');
        this.elementFooterComponent.saveExecutor(user.login).then(() => {
            this.getElementVariables().then((variables) => {
                this.processService
                    .completeProcessTask(
                        this.element.id,
                        this.data.entity._id,
                        this.data.processId,
                        this.data.processInfoId,
                        this.data.entity.parentEntries,
                        variables
                    )
                    .then(
                        () => {
                            this.data.saveEntity();
                            this.toaster.success('Задача успешно завершена');
                            this.element.status = 'COMPLETED';
                            this.allowApply = false;
                            this.removeElementFromProcessing();
                            const findIndex = this.data.elements.findIndex((item) => item.id === this.element.id);
                            this.data.elements.splice(findIndex, 1);
                            this.data.initProcess();
                        },
                        () => {
                            this.removeElementFromProcessing();
                        }
                    );
            });
        });
    }

    public removeElementFromProcessing() {
        const findIndex = this.data.processingElements.indexOf(this.element.guid);
        this.data.processingElements.splice(findIndex, 1);
    }

    public getElementVariables() {
        const variables = {};
        if (this.elementData && this.elementData.additionalDataTabs && this.elementData.additionalDataTabs.length > 0) {
            if (this.data.dataForProcessing && this.data.dataForProcessing.additionalDataTabs) {
                const additionalDataTabs = this.data.dataForProcessing.additionalDataTabs.filter((item) => {
                    return this.elementData.additionalDataTabs.find((elm) => {
                        return elm.guid === item.guid;
                    });
                });
                if (additionalDataTabs.length > 0) {
                    const xsdIds = additionalDataTabs.map((item) => item.xsd);
                    const params = [
                        {
                            field: '_id',
                            operator: 'in',
                            value: xsdIds,
                        },
                    ];
                    return this.restService.search('xsd', { search: { search: params } }).then((xsdData) => {
                        this.processService.completeTaskVariables(this.elementData, additionalDataTabs, xsdData);
                        if (this.elementData && this.elementData.variables && this.elementData.variables.length > 0) {
                            this.elementData.variables.forEach((processVariable) => {
                                // todo: разобраться
                                variables[processVariable.code] = ObjectUtilities.GetPropertyByPath(
                                    this.data.savedPlace.xsdData,
                                    processVariable.xpath
                                );
                            });
                        }
                        return Promise.resolve(variables);
                    });
                } else {
                    return Promise.resolve({});
                }
            } else {
                return Promise.resolve({});
            }
        } else {
            return Promise.resolve({});
        }
    }
}
