<div class="panel panel-shadow panel-small"
    [ngClass]="{
        'viewing': !isEditing
    }"
>
    <div class="panel-heading pt-10 pb-10">
        <h6 class="text-bold">
            {{ getGroupTitle() }}
        </h6>
    </div>
    <div class="panel-body no-padding-top no-padding-bottom">

        <ng-container *ngIf="isUngroup">    <!-- Блок для несгруппированных задач -->
            <ng-container *ngFor="let task of tasks">
                <div class="vcenter bordered">
                    <div
                        class="task-info pt-5 pb-5 vcenter"
                        [ngClass]="{ 'task-info-border': !isEditing }"
                    >
                        <div class="task-name">{{ task.taskName }}</div>
                        <div *ngIf="isEditing" class="task-select form-group form-group-lg no-margin-bottom">
                            <ng-select
                                [allowClear]="false"
                                [items]="groupOptions"
                                [multiple]="false"
                                [placeholder]="localizations.standards.periods.group"
                                (selected)="selectGroup(task.taskGuid, $event.id)"
                                [active]="getSelectedGroup()"
                                required>
                            </ng-select>
                        </div>
                    </div>
                    <div class="task-input form-group form-group-lg no-margin-bottom ml-20">
                        <input
                            *ngIf="isEditing"
                            class="form-control"
                            type="text"
                            [placeholder]="localizations.standards.periods.period"
                            [ngModel]="task.period"
                            (ngModelChange)="changePeriod($event, task.taskGuid)"
                        >
                        <div *ngIf="!isEditing" class="text-bold">{{ task.period }}</div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!isUngroup">   <!-- Блок для группы -->
            <div class="vcenter">
                <div
                    class="task-info"
                    [ngClass]="{ 'task-info-border': !isEditing }"
                >
                    <ng-container *ngFor="let task of tasks">
                        <div class="bordered vcenter pt-5 pb-5">
                            <div class="task-name">{{ task.taskName }}</div>
                            <div *ngIf="isEditing" class="task-select form-group form-group-lg no-margin-bottom">
                                <ng-select
                                    [allowClear]="false"
                                    [items]="groupOptions"
                                    [multiple]="false"
                                    [placeholder]="localizations.standards.periods.group"
                                    (selected)="selectGroup(task.taskGuid, $event.id)"
                                    [active]="getSelectedGroup()"
                                    required>
                                </ng-select>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="task-input form-group form-group-lg no-margin-bottom ml-20">
                    <input
                        *ngIf="isEditing"
                        class="form-control"
                        type="text"
                        placeholder="Срок"
                        [ngModel]="period"
                        (ngModelChange)="changePeriod($event)"
                    >
                    <div *ngIf="!isEditing" class="text-bold">{{ period }}</div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
