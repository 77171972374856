import { Injectable } from '@angular/core';
import { HttpService, RestService, StorageService } from '@evolenta/core';
import { CURRENT_ORGANIZATION, KOLIBRI_RECORDS } from '../../../../common/constants';
import { Config } from '../../../../common/services/config';

@Injectable()
export class ReportsOctonicaService {
    public constructor(
        private restService: RestService,
        private httpService: HttpService,
        private storageService: StorageService
    ) {}

    /**
     * Получить список типов отчетов
     */
    public getReportTypes() {
        return this.restService.search(KOLIBRI_RECORDS, { search: { search: [] } });
    }

    /**
     * Создать отчет с указанным id
     * @param octonicaId
     */
    public getReportUrl(octonicaId) {
        const organization = this.storageService.getItem(CURRENT_ORGANIZATION);
        // TODO получить organizationId из localStorage
        const url = `${Config.octonicaUrl}publication/${octonicaId}?Организация=${organization._id}`;

        return url;
    }
}
