import { NgModule } from '@angular/core';
import { UsersComponent } from './users/users.component';
import { UserEditComponent } from './users/user-edit.component';
import { UserPreviewComponent } from './users/components/user-preview/user-preview.component';
import { UsersResolve } from './users/users.resolve';
import { UsersSectionComponent } from './users/users-section.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { UserCardComponent } from './users/user-card/user-card.component';
import { KnoInspectorsComponent } from './kno-inspectors/kno-inspectors.component';
import { KnoInspectorsResolve } from './kno-inspectors/kno-inspectors.resolve';
import { RequestsModule } from '@evolenta/requests-module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        InlineSVGModule,
        RequestsModule,
        BreadcrumbsModule,
    ],
    declarations: [
        UsersSectionComponent,
        UsersComponent,
        UserEditComponent,
        UserPreviewComponent,
        UserCardComponent,
        KnoInspectorsComponent,
    ],
    providers: [
        UsersResolve,
        KnoInspectorsResolve,
    ],
    exports: [
        UserCardComponent,
    ],
})
export class UsersModule {
}
