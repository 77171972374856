import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild,
} from '@angular/core';
import {
    SUBSERVICES_COLLECTION,
    USERS_COLLECTION,
    VIEW_MODE,
    EDIT_MODE, SPECIALIST_ROLE,
} from '../../../../../common/constants';
import { ControlOperatorsService } from '../../../services/control-operators.service';
import { ServicesService } from '../../../../subservices/services/services.service';
import {
    RestService,
    StorageService,
    TranslateService,
} from '@evolenta/core';
import { DeleteConfirmationModalComponent } from '../../../../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.component';

@Component({
    selector: 'control-operator',
    templateUrl: './control-operator.component.html',
    styleUrls: ['./control-operator.less', '../../../../elements-list.css'],
})
export class ControlOperatorComponent implements OnInit {

    @Input() public controlOperator;
    @Output() public onDelete = new EventEmitter<any>();

    public preselectedService;
    public preselectedUser;

    public mode = VIEW_MODE;
    public localizations;
    public deleteConfirmationHeader;
    public deleteConfirmationText;
    public serviceSearchParams;
    public userSearchParams;
    @ViewChild('deleteConfirmationModal', { static: false }) public deleteConfirmationModalComponent: DeleteConfirmationModalComponent;

    // @ts-ignore
    public constructor(
        private restService: RestService,
        private servicesService: ServicesService,
        private controlOperatorsService: ControlOperatorsService,
        private storageService: StorageService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this._initSearchParams();
    }

    private _initSearchParams() {
        const currentOrganization = this.storageService.getItem('currentOrganization');
        this.serviceSearchParams = {
            andSubConditions: [
                {
                    field: 'units.id',
                    operator: 'eq',
                    value: currentOrganization._id,
                },
            ],
        };

        this.userSearchParams = {
            andSubConditions: [
                {
                    field: 'linkRolesMnemonics.sprOrganization',
                    operator: 'eq',
                    value: currentOrganization.guid,
                },
                {
                    field: 'linkRolesMnemonics.roleMnemonic',
                    operator: 'eq',
                    value: SPECIALIST_ROLE,
                },
            ],
        };
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'control_operator',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Переключить в режим редактирования,
     * при котором поля карточки становятся редактируемыми
     * @param control
     */
    public switchToEditMode() {
        this.controlOperator.service.previousStandartCode = this.controlOperator.service.standardCode;
        this.controlOperator.user.previousLogin = this.controlOperator.user.login;
        this._fetchPreselectedValues();
    }

    public async _fetchPreselectedValues() {
        await this._fetchSelectedService();
        await this._fetchSelectedUser();
        this.mode = EDIT_MODE;
    }

    public switchToViewMode() {
        this.mode = VIEW_MODE;
    }

    public async _fetchSelectedService() {
        const params = {
            search: {
                search: [{
                    field: '_id',
                    operator: 'eq',
                    value: this.controlOperator.service._id,
                }],
            },
            prj: 'subserviceListRgu',
        };
        const services = await this.restService.search(SUBSERVICES_COLLECTION, params);
        console.log('services', services);
        if (services && services.length) {
            this.preselectedService = this.servicesService.correctSubserviceByCurrentUnit(services[0]);
            if (!this.preselectedService.classificSubserviceName) {
                this.preselectedService.classificSubserviceName = this.preselectedService.titles.title;
            }
        }
    }

    public async _fetchSelectedUser() {
        const params = {
            search: {
                search: [{
                    field: '_id',
                    operator: 'eq',
                    value: this.controlOperator.user._id,
                }],
            },
            prg: 'usersList',
        };
        const users = await this.restService.search(USERS_COLLECTION, params);
        console.log('found users', users);
        if (users && users.length) {
            this.preselectedUser = users[0];
        }
    }

    public isViewMode() {
        return this.mode === VIEW_MODE;
    }

    public isEditMode() {
        return this.mode === EDIT_MODE;
    }

    public save() {
        this._save();
    }

    public async _save() {
        console.log('preselected service', this.preselectedService);
        this.controlOperator.service = {
            _id: this.preselectedService._id,
            name: this.preselectedService.titles.title,
            standardCode: this.preselectedService.standardCode,
        };
        delete this.controlOperator.service.previousStandartCode;

        this.controlOperator.user = {
            _id: this.preselectedUser._id,
            login: this.preselectedUser.login,
            name: this.preselectedUser.name,
        };
        delete this.controlOperator.user.previousLogin;

        await this.controlOperatorsService.update(this.controlOperator);
        this.mode = VIEW_MODE;
    }

    public delete() {
        this._delete();
    }

    public async _delete() {
        await this.controlOperatorsService.delete(this.controlOperator);
        this.onDelete.emit();
    }

    public showDeleteConfirmationModal() {
        this.deleteConfirmationHeader = 'Удаление ответственного';
        this.deleteConfirmationText = 'Вы действительно хотите удалить этого ответственного?';
        this.deleteConfirmationModalComponent.showModal();
    }
}
