import { Component, Input, OnInit } from '@angular/core';
import { RestService } from '@evolenta/core';
import { CommonAppealData } from '../../services/common-appeal.data';
import isArray from 'lodash-es/isArray';
import get from 'lodash-es/get';

@Component({
    selector: 'common-appeal-publish-erp-info',
    templateUrl: 'common-appeal-publish-erp-info.component.html',
})
export class CommonAppealPublishErpInfoComponent implements OnInit {
    @Input() public appeal;

    private erpPublishStatuses = CommonAppealData.erpPublishStatuses;
    public erpPublishStatus: any;

    public constructor(private restService: RestService) {}

    public ngOnInit(): void {
        const appealId = this.appeal._id;
        if (appealId) {
            this.restService
                .search('appealsPassports', {
                    search: {
                        search: [
                            {
                                field: 'appealId',
                                operator: 'eq',
                                value: appealId,
                            },
                        ],
                    },
                })
                .then((appealsPassports) => {
                    if (isArray(appealsPassports)) {
                        const appealPassport = appealsPassports.shift();
                        const erpPublishStatusCode = get(appealPassport, 'Inspection.IPublish.iPublishStatus');
                        this.erpPublishStatus = this.erpPublishStatuses.find(
                            (status) => status.code === erpPublishStatusCode
                        );
                    }
                });
        }
    }
}
