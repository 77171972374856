<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">

    <button class="btn btn-success btn-main no-border-radius btn-labeled btn-labeled-right"
            type="button"
            [disabled]="isLoading"
            (click)="save()">
        <b><i class="icon-checkmark3"
              [ngClass]="{
                'icon-checkmark3': !isLoading,
                 'icon-spinner2 spinner': isLoading
                 }">
            </i>
        </b>
        {{ localizations.common.save_big }}
    </button>

    <div class="ml-20 navbar-title">
        <h5>
            <i class="icon-arrow-left52 position-left cursor-pointer"
               [tooltip]="localizations.common.go_back"
               (click)="back()">
            </i>
            <span class="text-semibold"
                  *ngIf="method?._id">
                {{ localizations.methods }}
            </span>
            <span class="text-semibold"
                  *ngIf="!method?._id">
                {{ localizations.methods.creation }}
            </span>
        </h5>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<div class="page-container">
    <div class="panel panel-main wide panel-flat no-margin no-border-top">
        <div class="panel-body">
            <form name="entity">
                <div class="form-group">
                    <label class="control-label"
                           for="urlMethod">
                        URL
                        <span class="text-danger">*</span></label>
                    <input id="urlMethod"
                           class="form-control"
                           type="text"
                           name="urlMethod"
                           [(ngModel)]="method.url">
                </div>
                <div class="form-group">
                    <input id="httpGet"
                           class="checkbox"
                           type="checkbox"
                           name="httpGet"
                           [(ngModel)]="method.httpGet">
                    <label class="control-label"
                           for="httpGet">
                        GET
                    </label>
                </div>
                <div class="form-group">
                    <input id="httpPost"
                           class="checkbox"
                           type="checkbox"
                           name="httpPost"
                           [(ngModel)]="method.httpPost">
                    <label class="control-label"
                           for="httpPost">
                        POST
                    </label>
                </div>
                <div class="form-group">
                    <input id="httpPut"
                           class="checkbox"
                           type="checkbox"
                           name="httpPut"
                           [(ngModel)]="method.httpPut">
                    <label class="control-label"
                           for="httpPut">
                        PUT
                    </label>
                </div>
                <div class="form-group">
                    <input id="httpDelete"
                           class="checkbox"
                           type="checkbox"
                           name="httpDelete"
                           [(ngModel)]="method.httpDelete">
                    <label class="control-label"
                           for="httpDelete">
                        DELETE
                    </label>
                </div>
                <div class="form-group">
                    <input id="httpOptions"
                           class="checkbox"
                           type="checkbox"
                           name="httpOptions"
                           [(ngModel)]="method.httpOptions">
                    <label class="control-label"
                           for="httpOptions">
                        OPTIONS
                    </label>
                </div>
                <div class="form-group">
                    <input id="httpPatch"
                           class="checkbox"
                           type="checkbox"
                           name="httpPatch"
                           [(ngModel)]="method.httpPatch">
                    <label class="control-label"
                           for="httpPatch">
                        PATCH
                    </label>
                </div>
                <hr>
                <button class="btn btn-primary btn-labeled btn-labeled-right"
                        type="button"
                        [disabled]="isLoading"
                        (click)="save()">
                    {{ localizations.common.save }}
                    <b>
                        <i class="icon-checkmark4"
                           [ngClass]="{
                            'icon-checkmark4': !isLoading,
                            'icon-spinner2 spinner': isLoading
                            }">
                        </i>
                    </b>
                </button>
            </form>
        </div>
    </div>
</div>
<can-deactivate-modal></can-deactivate-modal>

