<div class="panel panel-shadow m-20 no-margin-top"
     *ngIf="mode == 'view'">
    <div class="panel-heading no-padding"
         [ngClass]="{'display-block': isEditPlanDate}">
        <div class="date-elements"
             *ngIf="!isEditPlanDate">
            <div class="p-20">
                <div>
                    <h5 class="date-text no-margin-top">
                        {{ localizations.common.creation_date }}
                    </h5>
                    <div class="date-value">
                        {{appeal.dateCreation | date}}
                    </div>
                </div>
            </div>
            <div class="p-20"
                 *ngIf="appeal.dateRegister">
                <div>
                    <h5 class="date-text no-margin-top">
                        {{ localizations.appeals.registration_date }}
                    </h5>
                    <div class="date-value">
                        {{appeal.dateRegister | date}}
                    </div>
                </div>
            </div>
            <div class="p-20"
                 *ngIf="appeal.datePlaneFinish && appeal.status.code == 'process'">
                <div>
                    <h5 *ngIf="!isDisabledByRso"
                        class="date-text no-margin-top">
                        {{ localizations.appeals.planned_completion_date }}
                        <i class="icon-pencil3 ml-5 cursor-pointer"
                           [title]="localizations.common.change"
                           (click)="editPlanFinishDate()"
                           *ngIf="isCanChangeAppealPlaneFinishDate()">
                        </i>
                    </h5>
                    <div class="date-value">
                        {{appeal.datePlaneFinish | date}}
                        <span class="anchor text-info text-size-small ml-5"
                              *ngIf="appeal.datePlaneFinishChangeHistory && appeal.datePlaneFinishChangeHistory.length > 0"
                              (click)="showDatePlaneChangeHistory()">
                            {{ localizations.common.history }}
                        </span>
                    </div>

                </div>
            </div>
        </div>

        <div class="p-20"
             *ngIf="isEditPlanDate">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group form-group-lg">
                        <label>
                            {{ localizations.appeals.planned_completion_date }}
                            <span class="text-danger">*</span>
                        </label>
                        <evolenta-datepicker [(ngModel)]="newDatePlaneFinish"
                                             format="YYYY-MM-DD"
                                             name="birthday"
                                             [large]="true"
                                             [valueAsString]="true"
                                             (ngModelChange)="setNewTaskDatePlaneFinish()">
                        </evolenta-datepicker>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group form-group-lg">
                        <label>
                            {{ localizations.common.comment }}
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="commentDatePlaneFinish">
                    </div>
                </div>
            </div>

            <div class="pb-10" *ngIf="allowChangeTaskTerm">
                <div class="form-group">
                    <input id="willChangeTaskTerm"
                           class="checkbox"
                           type="checkbox"
                           name="willChangeTaskTerm"
                           [(ngModel)]="willChangeTaskTerm"
                           (ngModelChange)="setNewTaskDatePlaneFinish()"
                    >
                    <label class="control-label text-bold"
                           for="willChangeTaskTerm">
                        {{ localizations.appeals.also_change_task_date }}
                    </label>
                </div>
                <div *ngIf="willChangeTaskTerm">
                    <div>
                        {{ localizations.appeals.current_date }}: <span class="text-semibold">{{ activeTask.due | date: 'shortDate' }}</span>
                    </div>
                    <div>
                        {{ localizations.appeals.date_after_change }}:
                        <span class="text-semibold">
                        {{ (newTaskDatePlaneFinish === null
                            ? activeTask.due
                            : newTaskDatePlaneFinish) | date: 'shortDate' }}
                    </span>
                    </div>
                </div>
            </div>

            <button class="btn btn-primary btn-xs pt-10 pb-10 mr-15"
                    (click)="updatePlaneFinishDate()"
                    type="button">
                {{ this.localizations.common.save }}
            </button>
            <button class="btn btn-xs btn-default pt-10 pb-10"
                    (click)="cancelEditFinishDate()"
                    type="button">
                {{ this.localizations.common.close }}
            </button>
        </div>
    </div>
    <div class="panel-body">
        <p>
            <span class="text-bold">
                {{ localizations.common.organization }}:
            </span>
            {{appeal.unit.shortName ? appeal.unit.shortName : appeal.unit.name}}
        </p>
        <!-- Причина аннулирования -->
        <p *ngIf="appeal.reasonAnnul">
            <span class="text-bold">
                {{ localizations.appeals.cancel_reason }}:
            </span>
            {{ appeal.reasonAnnul.title ? appeal.reasonAnnul.title : localizations.common.not_specified_f }}
        </p>

        <!-- Причина отказа -->
        <p *ngIf="appeal.reasonReject">
            <span class="text-bold">
                {{ localizations.appeals.decline_reason }}:
            </span>
            {{ appeal.reasonReject.title ? appeal.reasonReject.title : localizations.common.not_specified_f }}
        </p>

        <!-- Данные консультации -->
        <p *ngIf="appeal.status && appeal.status.code == 'consultation'">
            <span class="text-bold">
                {{ localizations.appeals.consultation_subject }}:
            </span>
            <span class="ml-3">
                {{appeal.consultationReason ? appeal.consultationReason : localizations.common.not_specified_m }}
            </span>
            <span class="text-bold">
                {{ localizations.appeals.consultation_result }}:
            </span>
            <span>
                {{appeal.consultationResult ? appeal.consultationResult : localizations.common.not_specified_m }}
            </span>
        </p>

        <!-- Ответственный по делу -->
        <p>
            <span class="text-bold">
                {{ localizations.appeals.responsible }}:
            </span>
            <span class="text-muted"
                  *ngIf="mode == 'view' && !controlOperator">
                {{ localizations.common.not_specified_m }}
            </span>
            <span *ngIf="mode == 'view' && controlOperator">
                {{controlOperator?.name}}
            </span>
        </p>

        <!-- Комментарий по делу -->
        <p>
            <span class="text-bold">
                {{ localizations.common.comment }}:
            </span>
            <span class="text-muted"
                  *ngIf="!appeal.comment">
                {{ localizations.common.missing }}
            </span>
        </p>
    </div>
</div>

<div class="panel panel-default no-border-top no-border-left no-border-right no-border-radius"
     *ngIf="mode == 'edit'">
    <div class="panel-heading no-border-top no-border-left no-border-right">
        <h6 class="panel-title">
            {{ localizations.appeals.common_params }}
        </h6>
        <div class="heading-elements">
            <button class="btn btn-info heading-btn btn-labeled btn-labeled-right btn-xs"
                    (click)="edit()"
                    *ngIf="mode == 'view'">
                <b>
                    <i class="icon-pencil"></i>
                </b>
                {{ localizations.common.edit }}
            </button>
            <button class="btn heading-btn btn-success btn-labeled btn-labeled-right"
                    (click)="apply()"
                    *ngIf="mode == 'edit'">
                <b>
                    <i class="icon-checkmark3"></i>
                </b>
                {{ localizations.common.apply }}
            </button>
            <button class="btn heading-btn bg-orange btn-labeled btn-labeled-right"
                    (click)="cancel()"
                    *ngIf="mode == 'edit'">
                <b>
                    <i class="icon-cross2"></i>
                </b>
                {{ localizations.common.cancel }}
            </button>
        </div>
    </div>
    <div class="panel-body">
        <!-- Дата создания -->
        <p class="mb-3"
           *ngIf="appeal.dateCreation">
            <span class="text-muted">
                {{ localizations.common.creation_date }}:
            </span>
            <span>
                {{appeal.dateCreation | date}}
            </span>
        </p>
        <!-- Дата регистрации -->
        <p class="mb-3"
           *ngIf="appeal.dateRegister">
            <span class="text-muted">
                {{ localizations.appeals.registration_date }}:
            </span>
            <span>
                {{appeal.dateRegister | date}}
            </span>
        </p>

        <!-- Дата выдачи -->
        <p class="mb-3"
           *ngIf="appeal.dateIssue">
            <span class="text-muted">
                {{ localizations.appeals.issue_date }}:
            </span>
            <span>
                {{appeal.dateIssue | date}}
            </span>
        </p>

        <!-- Причина отказа -->
        <p class="mb-3"
           *ngIf="appeal.reasonReject">
            <span class="text-muted">
                {{ localizations.appeals.decline_date }}:
            </span>
            <span>
                {{appeal.dateReject | date}}
            </span>
            <span class="text-muted">
                {{ localizations.appeals.decline_reason }}:
            </span>
            <span>
                {{ appeal.reasonReject.title ? appeal.reasonReject.title : localizations.common.not_specified }}
            </span>
        </p>

        <!--Причина аннулирования -->
        <p class="mb-3"
           *ngIf="appeal.reasonAnnul">
            <span class="text-muted">
                {{ localizations.appeals.cancel_date }}:
            </span>
            <span>
                {{appeal.dateAnnul | date}}
            </span>
            <span class="text-muted">
                {{ localizations.appeals.cancel_reason }}:
            </span>
            <span>
                {{appeal.reasonAnnul.title ? appeal.reasonAnnul.title : localizations.common.not_specified}}
            </span>
        </p>

        <!-- Данные консультации -->
        <p class="mb-3"
           *ngIf="appeal.status && appeal.status.code == 'consultation'">
            <span class="text-muted">
                {{ localizations.appeals.consultation_subject }}:
            </span>
            <span class="ml-3">
                {{ appeal.consultationReason ? appeal.consultationReason : localizations.common.not_specified_m }}
            </span>
            <span class="text-muted">
                {{ localizations.appeals.consultation_result }}:
            </span>
            <span>
                {{ appeal.consultationResult ? appeal.consultationResult : localizations.common.not_specified_m }}
            </span>
        </p>

        <!-- Контролирующие операторы -->
        <div class="mb-3">
            <span class="text-muted">
                {{ localizations.appeals.responsible }}:
            </span>
            <span class="anchor text-info text-size-small ml-5"
                  *ngIf="!controlOperator">
                {{ localizations.common.not_specified_m }}
            </span>

            <ng-container *ngIf="!controlOperator">
                {{controlOperator?.name}}
            </ng-container>
        </div>

        <!-- Комментарий -->
        <div class="form-group">
            <span class="text-muted">
                {{ localizations.common.comment}}:
            </span>
            <textarea class="form-control"
                      name="comment"
                      [(ngModel)]="appeal.comment">
            </textarea>
        </div>


    </div>
</div>


<!-- модальное окно для выбора параметров платежа -->
<ng-template #datePlanFinishHistoryModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.appeals.planned_date_change_history }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-feed">
            <li *ngFor="let item of appeal.datePlaneFinishChangeHistory">
                <div>
                    <span class="text-muted">
                        {{item.date | date: 'shortTime'}}, {{item.date | date: 'shortDate'}}
                    </span>
                    <span class="text-info ml-5">
                        {{item.user?.name}}
                    </span>
                </div>
                <div>
                    {{item.comment}}
                </div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>

