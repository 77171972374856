<ng-container *ngIf="entity[property]  && multiple">
    <div class="row">
        <div class="col-md-12">
            <label>
                {{title}}
                <span class="anchor text-info text-size-small ml-5" (click)="addItem()" *ngIf="!disabled">добавить</span>
            </label>
            <div class="row" *ngFor="let item of entity[property]; index as idx; last as lst;">
                <div class="col-md-{{columns}}" *ngIf="field.includes('name')">
                    <div [ngClass]="{'form-group': field[field.length-1] !== 'name',
                                     'input-group': field[field.length-1] === 'name',
                                     'input-group-xs': small && field[field.length-1] === 'name',
                                     'input-group-lg': large && field[field.length-1] === 'name',
                                     'form-group-lg': large && field[field.length-1] !== 'name',
                                     'form-group-xs': small && field[field.length-1] !== 'name',
                                     'no-margin': lst}">
                        <ng-select [allowClear]="true"
                                   [items]="userForSelect"
                                   [active]="activeItemSelect(userForSelect, item.name)"
                                   (selected)="selectValue($event, idx)"
                                   [(ngModel)]="entity[property][idx].name"
                                   [disabled]="disabled"
                                   placeholder="Выберите значение">
                        </ng-select>
                        <div class="input-group-btn" *ngIf="field[field.length-1] === 'name'">
                            <button class="btn btn-default" type="button" *ngIf="!idx" (click)="addItem()" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                            <button class="btn btn-default" type="button" *ngIf="idx" (click)="deleteItem(item)" [disabled]="disabled"><i class="icon-trash"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-{{columns}}" *ngIf="field.includes('position')">
                    <div [ngClass]="{'form-group': field[field.length-1] !== 'position',
                                     'input-group': field[field.length-1] === 'position',
                                     'input-group-xs': small && field[field.length-1] === 'position',
                                     'input-group-lg': large && field[field.length-1] === 'position',
                                     'form-group-lg': large && field[field.length-1] !== 'position',
                                     'form-group-xs': small && field[field.length-1] !== 'position',
                                     'no-margin': lst}">
                        <input type="text" [(ngModel)]="item.position" class="form-control input-xs" placeholder="Должность" title="Должность" [disabled]="disabled">
                        <div class="input-group-btn" *ngIf="field[field.length-1] === 'position'">
                            <button class="btn btn-default" type="button" *ngIf="!idx" (click)="addItem()" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                            <button class="btn btn-default" type="button" *ngIf="idx" (click)="deleteItem(item)"[disabled]="disabled"><i class="icon-trash"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-{{columns}}" *ngIf="field.includes('phone')">
                    <div [ngClass]="{'form-group': field[field.length-1] !== 'phone',
                                     'input-group': field[field.length-1] === 'phone',
                                     'input-group-xs': small && field[field.length-1] === 'phone',
                                     'input-group-lg': large && field[field.length-1] === 'phone',
                                     'form-group-lg': large && field[field.length-1] !== 'phone',
                                     'form-group-xs': small && field[field.length-1] !== 'phone',
                                     'no-margin': lst}">
                        <input type="text" [(ngModel)]="item.phone" class="form-control input-xs" placeholder="Телефон" title="Телефон" [disabled]="disabled">
                        <div class="input-group-btn" *ngIf="field[field.length-1] === 'phone'">
                            <button class="btn btn-default" type="button" *ngIf="!idx" (click)="addItem()" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                            <button class="btn btn-default" type="button" *ngIf="idx" (click)="deleteItem(item)" [disabled]="disabled"><i class="icon-trash"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-{{columns}}" *ngIf="field.includes('email')">
                    <div [ngClass]="{'form-group': field[field.length-1] !== 'email',
                                     'input-group': field[field.length-1] === 'email',
                                     'input-group-xs': small && field[field.length-1] === 'email',
                                     'input-group-lg': large && field[field.length-1] === 'email',
                                     'form-group-lg': large && field[field.length-1] !== 'email',
                                     'form-group-xs': small && field[field.length-1] !== 'email',
                                     'no-margin': lst}">
                        <input type="text" [(ngModel)]="item.email" class="form-control input-xs" placeholder="Электронный адрес" title="Электронный адрес" [disabled]="disabled">
                        <div class="input-group-btn" *ngIf="field[field.length-1] === 'email'">
                            <button class="btn btn-default" type="button" *ngIf="!idx" (click)="addItem()" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                            <button class="btn btn-default" type="button" *ngIf="idx" (click)="deleteItem(item)" [disabled]="disabled"><i class="icon-trash"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="entity[property] && !multiple">
    <div class="row">
        <div class="col-md-12">
            <label>{{title}}</label>
            <div class="row">
                <div class="col-md-{{columns}}" *ngIf="field.includes('name')">
                    <div class="form-group no-margin" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                        <ng-select [allowClear]="true"
                                   [items]="userForSelect"
                                   [active]="activeItemSelect(userForSelect, entity[property].name)"
                                   (selected)="selectValue($event)"
                                   [disabled]="disabled"
                                   [(ngModel)]="entity[property].name"
                                   placeholder="Выберите значение">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-{{columns}}" *ngIf="field.includes('position')">
                    <div class="form-group no-margin" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                        <input type="text" [(ngModel)]="entity[property].position" class="form-control input-xs" placeholder="Должность" title="Должность" [disabled]="disabled">
                    </div>
                </div>
                <div class="col-md-{{columns}}" *ngIf="field.includes('phone')">
                    <div class="form-group no-margin" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                        <input type="text" [(ngModel)]="entity[property].phone" class="form-control input-xs" placeholder="Телефон" title="Телефон" [disabled]="disabled">
                    </div>
                </div>
                <div class="col-md-{{columns}}" *ngIf="field.includes('email')">
                    <div class="form-group no-margin" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                        <input type="text" [(ngModel)]="entity[property].email" class="form-control input-xs" placeholder="Электронный адрес" title="Электронный адрес" [disabled]="disabled">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
