<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <button class="btn bg-blue btn-main no-border-radius btn-labeled btn-labeled-right"
            type="button"
            (click)="getNewPermission()">
        <b>
            <i class="icon-plus3"></i>
        </b>
        {{ localizations.permissions.create }}
    </button>

    <div class="ml-20 navbar-title">
        <h5>
            <span class="text-semibold">
                {{ localizations.permissions.name }}
            </span>
        </h5>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="panel-main wide">
        <div class="panel-body no-padding">
            <evolenta-grid [model]="model"
                           [colDef]="colDef"
                           *ngIf="showGrid"
                           #gridElement>
            </evolenta-grid>
        </div>
    </div>
</div>
