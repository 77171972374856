import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'object-modal',
    templateUrl: './object-modal.component.html',
})
export class ObjectModalComponent implements OnInit {
    @Input() public objects;
    @Output() public onSelect = new EventEmitter<object>();

    public selectedObject = null;

    public modalRef: BsModalRef;

    @ViewChild('selectObjectModal', { static: false }) public selectObjectModal: TemplateRef<any>;

    public constructor(private modalService: BsModalService) {}

    public ngOnInit() {}

    public showModal() {
        this.modalRef = this.modalService.show(this.selectObjectModal, { backdrop: 'static' });
    }

    public hideModal() {
        this.modalRef.hide();
    }

    public cancelModal() {
        this.hideModal();
    }

    /**
     * Выбор объекта из списка - передача объекта в компонент
     */
    public selectObject() {
        this.onSelect.emit(this.selectedObject);
        this.hideModal();
    }
}
