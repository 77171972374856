import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute , Router } from '@angular/router';
import { ToasterService, TranslateService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { AppealService } from '../../appeal.service';
import { AppealStatusService } from '../../appeal-status.service';
import { CopyService } from '../../../../../common/services/copy.service';

@Component({
    selector: 'appeal-preview',
    templateUrl: './appeal-preview.component.html',
})
export class AppealPreviewComponent implements OnChanges, OnInit {
    @Input() public appeal: any;
    @Input() public mode = 'full';
    @Input() public metaReglament;

    public groups = [];

    public tabs = {
        common: true,
        subservice: true,
        objects: true,
        subjects: true,
        documents: true,
        kndInfo: true,
    };
    public moduleBaseUrl;

    public localizations;

    public constructor(
        private appealService: AppealService,
        private appealStatusService: AppealStatusService,
        private copyService: CopyService,
        private toaster: ToasterService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.getDocumentGroups();
        this._completeSubserviceObjects();
        if (this.mode === 'card') {
            Object.keys(this.tabs).forEach(tab => {
                this.tabs[tab] = false;
            });
        }
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
                'services',
                'subjects',
                'organizations',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getConsultationNumberMessage(number, date) {
        const template = this.localizations.appeals.consultation_from;

        return template.replace('%a', number).replace('%b', date);
    }

    public ngOnChanges(changes) {
        if (changes.appeal.currentValue && this.appeal !== changes.appeal.currentValue) {
            this.appeal = changes.appeal.currentValue;
            if (this.appeal.status.code !== 'archive') {
                this.getDocumentGroups();
                this._completeSubserviceObjects();
            }
        }
    }

    private _completeSubserviceObjects() {
        if (!(this.appeal.subservice.objects && this.appeal.subservice.objects.length)) {
            return;
        }

        this.appeal.subservice.objects.forEach(object => {
            const foundObject = this.appeal.objects.find(item => item.guid === object.guid);
            if (foundObject) {
                object.header = foundObject.header;
            }
        });
    }

    public getDocumentGroups() {
        this.groups = [];
        const groups: any = {};
        this.appeal.documents.forEach(document => {
            if (!document.groupGuid) {
                document.groupGuid = 'other';
                document.groupName = 'Прочие документы';
            }
            if (groups[document.groupGuid]) {
                groups[document.groupGuid].documents.push(document);
            } else {
                groups[document.groupGuid] = {
                    name: document.groupName,
                    documents: [document],
                };
            }
        });

        Object.keys(groups).forEach(key => {
            this.groups.push(groups[key]);
        });
    }

    public colorLabelStatuses(code, additional?) {
        return code ? this.appealService.getColorForLabelStatuses(code, additional) : '';
    }

    /**
     * Получение значения для свойства статуса дела
     * @param property - обрабатываемое свойство
     * @param appealSubservice - услуга дела
     * @returns {any}
     */
    public getStatusProperty(property, appealSubservice = null, rejectProperty = null) {
        let status = this.appeal.status;
        if (appealSubservice) {
            status = appealSubservice.status;
        }

        if (!this.metaReglament) {
            return this.appealStatusService.getStatusProperty(status, property, rejectProperty);
        }

        const appealData = this.metaReglament.blocks.find(elm => elm.code === 'appealData');
        const statuses = appealData.appealStatuses;
        const st = statuses.find(item => this.appeal.subservice.status && item.code === this.appeal.subservice.status.code);
        if (st) {
            return property === 'background' ? 'bg-' + st.theme + '-300' : st[property];
        }
    }

    public getSubserviceCode(subservice) {
        const arr = subservice.serviceId.split('_');

        return arr[3];
    }

    /**
     * Копирование данных объекта в буфер
     */
    public copyToBuffer(object) {
        this.copyService.copyData(object.data, 'object');
        this.toaster.info('Данные успешно скопированы');
    }

    public edit() {
        this.router.navigate([this.moduleBaseUrl, 'appeals', 'edit', this.appeal._id]);
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public getSubjectHeaderByGuid(guid) {
        const subject = this.appeal.subjects.find(item => item.guid === guid);

        return (subject && subject.header) ? subject.header : '';
    }
}
