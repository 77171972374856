<ng-container *ngIf="elements && elements.length > 0">
    <ng-container *ngIf="!isProcessSelect">
        <p>
            <span class="text-bold">
                {{selectedDataName}}:
            </span>
            <em *ngIf="!entity[entityField] || entity[entityField].length == 0">
                {{ localizations.common.not_selected_plural }}
            </em>
            <span class="text-size-small anchor text-info ml-10"
                  *ngIf="allowEdit"
                  (click)="isProcessSelect = true">
                {{ localizations.common.select_small }}
            </span>
        </p>
        <table class="table table-xs mb-15"
               *ngIf="entity[entityField] && entity[entityField].length > 0">
            <tbody>
            <tr *ngFor="let elementId of entity[entityField]; let idx = index">
                <td>
                    {{ getElementNameById(elementId) }}
                </td>
                <td class="text-right pt-5 pb-5"
                    *ngIf="allowEdit">
                    <button class="btn btn-default btn-sxs"
                            (click)="deleteElement(idx)">
                        <i class="icon-trash"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>

    <div class="bg-primary-50 p-20 mb-15"
         *ngIf="isProcessSelect">
        <p class="text-bold no-margin">
            {{selectingText}}:
        </p>
        <div class="radio-block"
             *ngFor="let element of elements">
            <input id="element{{getElementField(element, 'id')}}"
                   class="checkbox"
                   type="checkbox"
                   name="selectedElement"
                   [checked]="checkSelectedElement(element)"
                   (change)="selectElement(element)">
            <label for="element{{getElementField(element, 'id')}}">
                {{getElementField(element, 'name')}}
            </label>
        </div>
        <button class="btn btn-info mt-15"
                (click)="isProcessSelect = false">
            {{ localizations.common.apply }}
        </button>
    </div>
</ng-container>
