<div id="fields-compliance-container"
     class="p-10"
     *ngIf="initialized">
    <div class="title mb-20">
        <h5>
            {{ localizations['epgu_integration'].name }}
        </h5>
    </div>
    <!-- Селектор стандарта -->
    <div class="ml-20 mr-20 row">
        <div class="col-md-1 mt-10">
            {{ localizations['epgu_integration'].standard }}
        </div>
        <div class="col-md-11">
            <catalogue
                preField="auid"
                catalogName="standards"
                name="standards"
                nameField="name"
                [(ngModel)]="standard"
                [priority]="true"
                [large]="true"
                (onSelect)="handleStandardSelected($event)"
                (onClear)="standardCleared()">
            </catalogue>
        </div>
    </div>
    <div class="mt-10 ml-20 mr-20 list-title"
         *ngIf="standard">
        {{ localizations.epgu_integration.selected_standard_with_code }} {{ standard.auid }}
    </div>

    <!-- Подробный список маппингов для выбранного стандарта -->
    <div class="ml-20 mr-20"
         *ngIf="mappingsForStandard && mappingsForStandard.length">
        <div *ngIf="mappingListOpened">
            <p class="list-title mt-10">
                {{ localizations.epgu_integration.mapping_list_for_selected_standard }}
                <a class="text-info anchor"
                   *ngIf="mapping"
                   (click)="toggleMappingListOpened()">
                    ({{ localizations.common.collapse }})
                </a>
                :
            </p>
            <div class="ml-10 mr-10">
                <div class="row mt-5 pb-5"
                     [ngClass]="{
                'selected-mapping': mapping === mappingForStandard,
                'bottom-border': i < mappingsForStandard.length - 1
             }"
                     *ngFor="let mappingForStandard of mappingsForStandard; let i = index">
                    <!-- id маппинга -->
                    <div class="col-md-3 mapping-id"
                         [ngClass]="{
                        'mapping-id': mappingForStandard.isValid,
                        'disabled-mapping-id': !mappingForStandard.isValid
                     }"
                         (click)="selectMapping(mappingForStandard)">
                        {{ mappingForStandard._id }}
                    </div>

                    <!-- versionXsd -->
                    <div class="col-md-3">
                        {{ mappingForStandard.versionXsd }}
                    </div>

                    <!-- дата создания маппинга -->
                    <div class="col-md-offset-1 col-md-3">
                        {{ mappingForStandard.creationDate }}
                    </div>

                    <!-- статус маппинга (активный/неакивный) -->
                    <div class="col-md-2">
                <span class="text-success"
                      *ngIf="mappingForStandard.isValid">
                    {{ localizations.common.active_m }}
                </span>
                        <span class="text-danger"
                              *ngIf="!mappingForStandard.isValid">
                    {{ localizations.common.inactive_m }}
                </span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!mappingListOpened">
            <p class="list-title mt-10">
                {{ localizations.epgu_integration.mapping_list_for_selected_standard }}
                <a class="text-info anchor"
                    (click)="toggleMappingListOpened()">
                    ({{ localizations.common.expand }})
                </a>
                :
            </p>
            <div class="ml-10 mr-10">
                <div class="row mt-5 pb-5 selected-mapping">
                    <!-- id маппинга -->
                    <div class="col-md-3 mapping-id"
                         [ngClass]="{
                        'mapping-id': mapping.isValid,
                        'disabled-mapping-id': !mapping.isValid
                     }"
                         (click)="selectMapping(mapping)">
                        {{ mapping._id }}
                    </div>

                    <!-- versionXsd -->
                    <div class="col-md-3">
                        {{ mapping.versionXsd }}
                    </div>

                    <!-- дата создания маппинга -->
                    <div class="col-md-offset-1 col-md-3">
                        {{ mapping.creationDate }}
                    </div>

                    <!-- статус маппинга (активный/неакивный) -->
                    <div class="col-md-2">
                <span class="text-success"
                      *ngIf="mapping.isValid">
                    {{ localizations.common.active_m }}
                </span>
                        <span class="text-danger"
                              *ngIf="!mapping.isValid">
                    {{ localizations.common.inactive_m }}
                </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <input id="load-xsd"
           class="hide"
           type="file"
           name="load-xsd"
           (change)="handleXsdFile($event)" />

    <!-- Кнопки действий -->
    <div class="row action-buttons panel">
        <!-- Создать маппинг -->
        <div class="col-md-1">
            <button class="btn bg-blue"
                    [disabled]="!standard"
                    (click)="triggerFileLoadToCreateMapping()">
                {{ localizations.epgu_integration.mapping.create }}
            </button>
        </div>

        <!-- Удалить маппинг -->
        <div class="col-md-2">
            <button class="btn bg-danger"
                    [disabled]="!mapping || !mapping._id"
                    (click)="showDeleteConfirmationModal(mapping)">
                {{ localizations.epgu_integration.mapping.delete }}
            </button>
        </div>

        <!-- Создать XSL-файл -->
        <div class="col-md-2">
            <button class="btn bg-blue"
                    [disabled]="!standard || !mapping || !mapping.isValid"
                    (click)="triggerFileLoadToGenerateXSL()">
                {{ localizations.epgu_integration.xsl_generation }}
            </button>
        </div>
        <a id="xsl-file" style="display: none;" target="_blank"></a>

        <!-- Активировать/деактивировать -->
        <div class="col-md-1">
            <button class="btn"
                    [disabled]="!mapping || !mapping._id || processing"
                    [ngClass]="{
                        'bg-danger': mapping && mapping.isValid,
                        'bg-green': mapping && !mapping.isValid
                    }"
                    (click)="toggleMapping()">
                {{ getCurrentToggleName() }}
            </button>
        </div>

        <!-- Экспорт/импорт -->
        <div class="col-md-2 import-export-buttons">
            <div class="row">
                <div class="col-md-6">
                    <button class="btn bg-green"
                            [disabled]="!standard || !mapping || processing"
                            (click)="exportMappings()">
                        {{ localizations.epgu_integration.mapping.export }}
                    </button>
                </div>

                <div class="col-md-6">
                    <button class="btn bg-green"
                            [disabled]="!standard || processing"
                            (click)="importMappings()">
                        {{ localizations.epgu_integration.mapping.import }}
                    </button>
                </div>
            </div>
        </div>


        <!-- Формирование PDF -->
        <div class="col-md-2">
            <button class="btn bg-danger"
                   [disabled]="!standard || !mapping || !mapping.isValid"
                   (click)="triggerFileLoadToGeneratePDF()">
                {{ localizations.epgu_integration.pdf_generation }}
            </button>
        </div>

        <!-- Кнопка "Сохранить" -->
        <div class="col-md-1">
            <button class="btn btn-success"
                    [disabled]="!mapping"
                    (click)="saveMapping()">
                {{ localizations.epgu_integration.save_all_fields }}
            </button>
        </div>
    </div>

    <div class="warning-text ml-20 mr-20"
         *ngIf="itemChanged">
        {{ localizations.epgu_integration.press_save_button_to_save_on_server_press }}
    </div>


    <!-- Настройка полей маппинга -->
    <div class="mapping-items-container-tall"
         *ngIf="!mappingListOpened && mapping && mapping.items">
        <mapping-item *ngFor="let item of mapping.items"
                      [inCreate]="inCreate"
                      [item]="item"
                      (changed)="someItemChanged()">
        </mapping-item>
    </div>

    <div class="mapping-items-container-short"
         *ngIf="mappingListOpened && mapping && mapping.items">
        <mapping-item *ngFor="let item of mapping.items"
                      [inCreate]="inCreate"
                      [item]="item"
                      (changed)="someItemChanged()">
        </mapping-item>
    </div>
</div>

<delete-confirmation-modal
    [title]="deleteConfirmationHeader"
    [text]="deleteConfirmationText"
    (onConfirm)="delete()"
    #deleteConfirmationModal>
</delete-confirmation-modal>

<input id="mappings-import-file"
       class="hide"
       type="file"
       (change)="processImportedFile($event)" />
