
<!-- Режим редактирования / просмотра отдельной задачи -->
<div *ngIf="!editedTask">
    <div class="panel panel-shadow" *ngIf="isActiveTasksState">
        <div class="panel-body no-padding">
            <div class="p-20 flex" style="flex-direction: row; align-items:stretch; justify-content: space-between">
                <div>
                    <!-- Информационный раздел -->
                    <p class="text-size-large"
                       [ngClass]="{
                        'mb-5': isShowAllInfoPanel,
                        'no-margin-bottom': !isShowAllInfoPanel
                        }">
                        <span class="text-slate">
                            {{localizations['appeals.tasks'].appeal}}:
                        </span>
                        {{appealService.appeal.subservice.shortTitle}}
                    </p>
                    <ng-container *ngIf="isShowAllInfoPanel">
                        <p class="text-size-large no-margin-bottom"
                           [ngClass]="{
                            'mb-5': isShowAllInfoPanel,
                            'no-margin-bottom': !isShowAllInfoPanel
                            }"
                           *ngIf="appealService.appeal.subjects && appealService.appeal.subjects.length > 0">
                            <span class="text-slate">
                                {{localizations.common.participants}}:
                            </span>
                            <ng-container *ngFor="let subject of appealService.appeal.subjects; let lst = last">
                                {{subject.shortHeader}}
                                <ng-container *ngIf="!lst">, </ng-container>
                            </ng-container>

                        </p>
                    </ng-container>
                    <p class="text-size-large"
                       [ngClass]="{
                        'mb-5': isShowAllInfoPanel,
                        'no-margin-bottom': !isShowAllInfoPanel
                        }">
                        <span class="text-slate">
                            {{ localizations['appeals.tasks'].responsible }}:
                        </span>
                        <span *ngIf="assigneeUser">
                            {{assigneeUser?.name}}
                        </span>
                        <span class="text-muted"
                              *ngIf="!assigneeUser">
                            {{ localizations['appeals.tasks'].not_assigned }}
                        </span>

                        <span class="text-muted anchor cursor-pointer"
                            (click)="showResponsibleForAppealModal()">
                            {{ localizations.common.change }}
                        </span>
                    </p>
                    <div class="mt-15"
                         *ngIf="eventSubscriptions.length > 0">
                        <ng-container *ngFor="let event of eventSubscriptions; let fst=first">
                            <button class="btn btn-primary-overline heading-btn mr-10"
                                    (click)="startEventSubscription(event)">
                                {{event.eventName}}
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="ml-20">
                    <ng-container *ngIf="globalEvents && globalEvents.length > 0">
                        <ng-container *ngIf="globalEvents.length == 1">
                            <button class="btn btn-primary-overline heading-btn"
                                    (click)="startEvent(globalEvents[0])">
                                {{globalEvents[0].name}}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="globalEvents.length > 1">
                            <div class="btn-group">
                                <button class="btn btn-primary-overline heading-btn dropdown-toggle"
                                        data-toggle="dropdown">
                                    {{localizations['appeals.tasks'].additional_action}}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-right">
                                    <li *ngFor="let event of globalEvents; let fst=first">
                                        <a (click)="startEvent(event)">
                                            {{event.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </ng-container>

                    <span class="control-arrow text-slate cursor-pointer ml-20"
                          (click)="isShowAllInfoPanel = !isShowAllInfoPanel">
                        <i class="icon-circle-left2"
                           [ngClass]="{'icon-circle-left2': !isShowAllInfoPanel, 'icon-circle-down2': isShowAllInfoPanel}">
                        </i>
                    </span>
                </div>
            </div>
            <div class="progress no-border-radius"
                 style="height: 10px"
                 *ngIf="processProgress">
                <div class="progress-bar progress-bar-striped bg-info no-border-radius"
                     [ngStyle]="{'width.%': processProgress}">
                </div>
            </div>
        </div>
    </div>

    <!-- Обратная связь от заявителя -->
    <ng-container *ngIf="feedbackMessages && feedbackMessages.length">
        <div class="panel panel-shadow m-20">
            <div class="panel-body no-padding">
                <legend class="text-bold pl-20 cursor-pointer text-slate no-margin-bottom pt-10"
                        [ngClass]="{ 'shadowed': feedbackBlockOpened }"
                        (click)="toggleFeedbackAndMarkMessagesRead()">
                    <i class="icon-grid3 mr-5"></i>
                    {{ localizations['appeals.widget_feedback'].name }}

                    <!-- Уведомление о непрочитанного ответа от пользователя -->
                    <i class="icon-bookmark2 ml-5 text-primary"
                       title="Непрочитанные ответы от пользователя"
                       *ngIf="checkHasUnreadUserMessage()">
                    </i>

                    <a class="control-arrow text-slate mr-20">
                        <i [ngClass]="{
                                'icon-circle-down2' : feedbackBlockOpened,
                                'icon-circle-left2' : !feedbackBlockOpened
                            }">
                        </i>
                    </a>
                </legend>
                <div class="collapse"
                     [ngClass]="{
                        'in': feedbackBlockOpened,
                        'feedback-messages-container': feedbackBlockOpened
                     }">
                    <div *ngFor="let message of feedbackMessages">
                        <!-- Сообщение оператора -->
                        <div class="feedback-message">
                            <div class="feedback-message-content">
                                <span class="text-slate">
                                    {{ getOperatorMessageDate(message) }}
                                </span>
                                <div>
                                    <span>
                                        {{ message.sendMessagePGMU || message.requestFeedbackPGMU }}
                                    </span>

                                    <span class="anchor text-info ml-5">
                                    <a href="/admin/users/edit/{{ message.userCompleted._id }}">
                                        {{ message.userCompleted.name }}
                                    </a>
                                </span>
                                </div>
                            </div>
                        </div>

                        <!-- Ответ пользователя -->
                        <div class="feedback-message"
                             *ngIf="message.userMessage">
                            <div class="feedback-message-content">
                                <span class="text-slate">
                                    {{ getUserMessageDate(message) }}
                                </span>

                                <div>
                                    <span>
                                        {{ message.userMessage }}
                                    </span>

                                    <span class="text-info feedback-appliciant">
                                        {{ localizations['appeals.widget_feedback'].appliciant }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngFor="let task of allTasks">
        <!--  Юзер-таск  -->
        <appeal-process-task [task]="task"
                             mode="edit"
                             *ngIf="task.type == 'USER_TASK'"
                             (onEdit)="editTask($event)"
                             (onComplete)="completeTask($event)"
                             (onStartEvent)="startEvent($event)"
                             #taskCard>
        </appeal-process-task>

        <!--  Сервис-таск  -->
        <ng-container *ngIf="task.type == 'SERVICE_TASK'">
            <div class="panel panel-shadow m-20 panel-small">
                <div class="panel-heading bg-primary-50">
                    <h4 class="panel-title">
                        <div class="mb-5">
                            <!-- Статус задачи -->
                            <label class="badge text-uppercase mr-10 no-margin-bottom bg-primary-400">
                                {{
                                    task.status === 'ACTIVE' ?
                                    localizations['appeals.tasks'].active_system :
                                    localizations['appeals.tasks'].closed_system
                                }}
                            </label>
                        </div>
                        {{task.activityName}}
                    </h4>
                </div>
                <div class="panel-footer no-padding">
                    <div class="date-elements">
                        <div class="p-20"
                             *ngIf="task.startTime">
                            <div>
                                <h5 class="date-text no-margin-top">
                                    {{ localizations.common.start }}
                                </h5>
                                <div class="date-value">
                                    <i inlineSVG="assets/icon-clock.svg"></i>
                                    {{task.startTime | date: 'medium'}}
                                </div>
                            </div>
                        </div>

                        <div class="p-20" *ngIf="task.endTime">
                            <div>
                                <h5 class="date-text">
                                    {{ localizations.common.completion }}
                                </h5>
                                <div class="date-value">
                                    <i inlineSVG="assets/icon-clock.svg"></i>
                                    {{task.endTime | date: 'medium'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!--  Таймер  -->
        <ng-container *ngIf="task.type == 'TIMER-TRANSITION'">
            <div class="panel panel-shadow m-20 panel-small">
                <div class="panel-heading bg-orange-50">
                    <h4 class="panel-title">
                        {{ localizations.common.waiting }}
                        <div class="text-size-small mt-10"
                             style="font-style: normal">
                            <span class="text-muted">
                                {{ localizations.common.start }}:
                            </span>
                            {{task.startTime | date: 'medium'}}
                            <span class="text-muted ml-10">
                                {{ localizations.common.waiting }}:
                            </span> {{task.endTime | date: 'medium'}}
                        </div>
                    </h4>
                    <div class="heading-elements">
                        <svg width="30"
                             height="30"
                             viewBox="0 0 16 16"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM9 5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5V8C7 8.55228 7.44772 9 8 9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7H9V5Z"
                                  fill="#FD9426"/>
                        </svg>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="!allTasks.length">
        <div class="centered-text mt-20">
            Нет {{ this.isActiveTasksState ? 'активных' : 'завершенных'}} задач
        </div>
    </ng-container>
</div>

<ng-container *ngIf="editedTask">
    <appeal-process-task [task]="editedTask"
                         mode="edit"
                         (onApply)="applyTask($event)"
                         (onComplete)="completeTask($event)"
                         (onCancel)="cancelTask()">
    </appeal-process-task>
</ng-container>

<ng-template #setResponsibleForAppealModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{modalTitle}}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body"
        *ngIf="controlOperatorsInitialized">
        <ng-container>
            <div class="checkbox-block"
                 *ngFor="let user of usersToSelect">
                <input type="radio"
                       class="radio"
                       name="assigneeUser"
                       id="user{{user.login}}"
                       [checked]="currentAssignee && currentAssignee == user.login"
                       (change)="currentAssignee = user.login">
                <label for="user{{user.login}}">
                    {{user.name}}
                </label>
            </div>
            <div class="alert alert-info no-border"
                 *ngIf="usersToSelect.length === 0">
                {{ localizations['appeals.tasks'].no_users_available_to_select }}
            </div>
            <div class="centered-text">
                <button type="button"
                        class="mt-10 btn btn-default"
                        *ngIf="!noMoreControlOperators"
                        (click)="loadMore()">
                    {{ localizations.common.load_more }}
                </button>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="setResponsibleForAppeal()"
                *ngIf="usersToSelect.length > 0">
            {{ localizations.common.apply }}
        </button>
        <button type="button"
                class="btn btn-default"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>
