import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TreeSelectService } from '../../services/tree-select.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'tree-select',
    templateUrl: 'tree-select.component.html',
})

export class TreeSelectComponent implements OnInit {
    @Input() public elements;
    @Input() public treeArray;
    @Input() public limitArray = [];
    @Input() public isEnd;
    @Input() public nodeName = 'name';
    @Input() public subNodes = 'subVariants';
    @Input() public additionalFields = [];
    @Output() public onEnd = new EventEmitter<boolean>();
    public treeElements = [];
    public localizations;

    public constructor(
        private treeSelect: TreeSelectService,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        if (this.treeArray.length) {
            // инициализация ранее выбранных значений
            this._initSelectedElements(this.elements);
        }
        this.treeElements = this.elements;
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'tree-select',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация ранее выбранных элементов
     * @param elements
     */
    private _initSelectedElements(allElements) {
        let existSelectedInLevel = false;
        // Выставление флага selected
        allElements.forEach(element => {
            const arrayContainsElement = this.treeArray.some(item => item.guid === element.guid);
            if (arrayContainsElement) {
                element.selected = true;
                existSelectedInLevel = true;
                if (element[this.subNodes] && element[this.subNodes].length > 1) {
                    this._initSelectedElements(element[this.subNodes]);
                }
            }
        });

        // Число выбранных элементов в текущем уровне
        let hasSelectedItemInCurrentLevel = false;

        allElements.forEach(element => {
            const arrayContainsElement = this.treeArray.some(item => item.guid === element.guid);
            if (arrayContainsElement) {
                hasSelectedItemInCurrentLevel = true;
            }
        });

        allElements.forEach(element => {
            element.hide = false;
            const arrayContainsElement = this.treeArray.some(item => item.guid === element.guid);
            if (hasSelectedItemInCurrentLevel) {
                if (!arrayContainsElement) {
                    element.hide = true;
                } else {
                    element.showBtn = true;
                }
            }
        });
    }

    // реагируем на изменения данных в родительском компоненте
    @Input()
    public set updateElements(elements) {
        this.treeElements = elements;
    }

    public checkSelected(element) {
        const find = this.treeArray.find(item => item.guid === element.guid);

        return !!find;
    }

    public toggle(element, elements) {
        let otherHide = false;
        elements.forEach(item => {
            if (item.guid !== element.guid && item.hide) {
                otherHide = true;
            }
        });
        this.treeArray = this.treeSelect.getTreeArray(element, elements, this.treeArray, this.subNodes, this.nodeName, this.additionalFields);
        if ((!element[this.subNodes] || element[this.subNodes].length === 0) && !otherHide) {
            this.onEnd.emit(true);
        }
    }

    public onEndSelect() {
        this.onEnd.emit(true);
    }
}
