<!-- Режим редактирования отдельного сообщения -->
<ng-container *ngIf="editMessage">
    <appeal-message [message]="editMessage" [appeal]="appeal" mode="edit" (onApply)="onApplyMessage($event)"></appeal-message>
</ng-container>

<!-- Режим просмотра списка сообщений -->
<div class="p-20" *ngIf="!editMessage && messages">
    <ng-container *ngFor="let message of messages">
        <appeal-message [message]="message" mode="view"
                         (onEdit)="onEditMessage($event)"
                         (onApply)="onApplyMessage($event)"
                         (onDelete)="onDeleteMessage($event)"></appeal-message>
    </ng-container>

    <div class="alert alert-info alert-styled-left alert-bordered" *ngIf="messages.length == 0">
        <span class="text-semibold">Внимание!</span>
        В деле не созданы сообщения.
        <a class="anchor" (click)="createMessage()" *ngIf="appealStatusService.allowEdit && allowCreate">создать</a>
    </div>

    <!-- Кнопка добавления сообщения -->
    <button class="btn btn-primary btn-labeled btn-labeled-right" (click)="createMessage()"
            *ngIf="appealStatusService.allowEdit && allowCreate"><b><i class="icon-plus3"></i></b> Добавить сообщение</button>

    <!-- Предупреждение о невозможности добавления сообщения -->
    <div class="alert alert-danger alert-styled-left alert-bordered" *ngIf="appealStatusService.allowEdit && !allowCreate">
        <span class="text-semibold">Внимание!</span>
        Сообщения не могут быть созданы так как в деле отсутствуют объекты для которых настроены параметры уведомлений.
    </div>
</div>

<!-- модальное окно для подтверждения удаления конверта -->
<modal-dialog [title]="'Удаление сообщения'" [message]="'Вы действительно хотите удалить сообщение?'" (onSelect)="processDeleteMessage()" #deleteModal></modal-dialog>
