<div class="panel panel-shadow mt-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ localizations.standards.group_setup }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="apply()" *ngIf="allowEdit">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body">
        <ng-container *ngIf="tabs.length > 1">
            <ul class="info-row no-margin">
                <ng-container *ngFor="let tab of tabs">
                    <li [ngClass]="{'active': activeTab == tab.code}"
                        (click)="activeTab = tab.code">
                        {{tab.name}}
                    </li>
                </ng-container>
            </ul>
        </ng-container>

        <div class="mt-20"
             [hidden]="activeTab !== 'common'">
            <dynamic-form
                #mainData
                *ngIf="metaData.groupsAndDocumentsData.groupData.xsd"
                [xsd]="metaData.groupsAndDocumentsData.groupData.xsd"
                [model]="group"
                [saveInMainObject]="true"
                [disabled]="!allowEdit">
            </dynamic-form>

            <p *ngIf="metaData.groupsAndDocumentsData.groupData.allowCreateXsd">
                <span class="text-bold">
                    {{ getAdditionalDataFormMessage() }}
                </span>
                <ng-container *ngIf="allowEdit">
                    <span class="text-size-small anchor text-info ml-10"
                          *ngIf="allowEdit || group.xsd"
                          (click)="editXsd()">
                        {{!group.xsd ?
                            localizations.common.setup :
                                allowEdit ? localizations.common.change_small :
                                    localizations.common.view_small }}
                    </span>
                    <span class="text-size-small anchor text-slate ml-10"
                          *ngIf="group.xsd && allowEdit"
                          (click)="group.xsd = null">
                        {{ localizations.common.delete_small }}
                    </span>
                </ng-container>
            </p>
        </div>

        <ng-container *ngIf="activeTab === 'provisionConditions'">
            <common-standard-document-group-conditions [group]="group"></common-standard-document-group-conditions>
        </ng-container>

        <ng-container *ngIf="activeTab === 'bpmnTasksLinks'">
            <common-bpmn-links [property]="group"
                        propertyName="documents"
                        elementType="tasks"
                        [processDataInEntity]="data.entity.process"
                        [process]="commonStandardsService.bpmnProcess"
                        [operations]="['used', 'required', 'sign']"
                        [allowEdit]="allowEdit"
            ></common-bpmn-links >
        </ng-container>

        <ng-container *ngIf="activeTab === 'bpmnEventsLinks'">
            <common-bpmn-links [property]="group"
                        propertyName="documents"
                        elementType="catchEvents"
                        [processDataInEntity]="data.entity.process"
                        [process]="commonStandardsService.bpmnProcess"
                        [operations]="['used', 'required', 'sign']"
                        [allowEdit]="allowEdit"
            ></common-bpmn-links >
        </ng-container>
    </div>
</div>
