<div *ngIf="!printForm">
    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false">
    </common-standard-block-with-additional-data>

    <ng-container *ngFor="let form of getPrintFormList()">
        <print-form-card [printForm]="form"
                         [allowEdit]="activeData.allowEdit"
                         (onDelete)="deletePrintForm($event)"
                         (onEdit)="editPrintForm($event)">
        </print-form-card>
    </ng-container>

    <ng-container *ngIf="activeData.allowEdit">
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right"
                (click)="selectPrintForms()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            {{ localizations.standards.print_form.select }}
        </button>
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20"
                (click)="createPrintForm()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            {{ localizations.standards.print_form.add }}
        </button>
    </ng-container>
</div>

<print-form-edit *ngIf="printForm"
                 [mode]="activeData.entityMode"
                 (onSaveFromStandard)="applyPrintForm($event)"
                 (onCancelFromStandard)="cancelEditPrintForm()"
                 [pf]="printForm">
</print-form-edit>
