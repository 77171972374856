import { NgModule } from '@angular/core';
import { CoreModule } from '@evolenta/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { SubjectsModule } from '../subjects/subjects.module';
import { ObjectsModule } from '../objects/objects.module';
import { CommonAppealObjectCardComponent } from './components/appeal-objects/appeal-object-card/common-appeal-object-card.component';
import { ObjectsByGroupFilterPipe } from './components/objects/objects-by-group-filter.pipe';
import { CommonAppealSubjectCardComponent } from './components/appeal-subjects/appeal-subject-card/common-appeal-subject-card.component';
import { CommonAppealSubjectsComponent } from './components/appeal-subjects/common-appeal-subjects.component';
import { ObjectsByRepresentativeFilterPipe } from './components/objects/objects-by-representative-filter.pipe';
import { CommonAppealObjectsComponent } from './components/appeal-objects/common-appeal-objects.component';
import { CommonAppealEntityAdditionalDataComponent } from './components/appeal-subjects-objects/appeal-entity-additional-data/common-appeal-entity-additional-data.component';
import { CommonAppealEntitySubservicesComponent } from './components/appeal-subjects-objects/appeal-entity-subservices/common-appeal-entity-subservices.component';
import { CommonAppealDocumentsModule } from './common-appeal-documents.module';

@NgModule({
    imports: [
        CoreModule,
        SharedComponentsModule,
        SharedElementsModule,
        InlineSVGModule,
        SubjectsModule,
        ObjectsModule,
        CommonAppealDocumentsModule,
    ],
    declarations: [
        CommonAppealObjectCardComponent,
        ObjectsByGroupFilterPipe,
        ObjectsByRepresentativeFilterPipe,
        CommonAppealSubjectCardComponent,
        CommonAppealSubjectsComponent,
        CommonAppealObjectsComponent,
        CommonAppealObjectCardComponent,
        CommonAppealEntityAdditionalDataComponent,
        CommonAppealEntitySubservicesComponent,
    ],
    exports: [CommonAppealSubjectsComponent, CommonAppealObjectsComponent],
})
export class CommonAppealSubjectsObjectsModule {}
