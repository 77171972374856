<!-- Настройка чек-листа -->
<div class="p-20" *ngIf="!task.isApplyCheckList">
    <div class="panel panel-shadow"
         *ngFor="let question of questions">
        <div class="panel-heading">
            <h4 class="panel-title">
                {{ question.text }}
            </h4>
            <div class="heading-elements">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.common.edit"
                        *ngIf="question.isCustom"
                        (click)="editQuestion(question)">
                    <i inlineSVG="assets/icon-edit.svg"></i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.common.delete"
                        (click)="deleteQuestion(question)">
                    <i inlineSVG="assets/icon-trash.svg"></i>
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!editedQuestion">
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-10"
                (click)="selectQuestions()">
            <b class="icon-plus3"></b>
            <span>
                {{ localizations.subjects.select_from_catalogue }}
            </span>
        </button>
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-10 mt-10" (click)="addQuestion()"><b class="icon-plus3"></b>
            <span>
                {{ localizations.tasks.add_arbitrary_question }}
            </span>
        </button>
        <button class="btn btn-success-overline btn-labeled btn-labeled-right ml-10 mt-10" (click)="applyCheckList()" *ngIf="questions.length > 0">
            <b class="icon-check2"></b>
            <span>
                {{ localizations.tasks.finish_settings }}
            </span>
        </button>
    </ng-container>

    <div class="panel panel-shadow" *ngIf="editedQuestion">
        <div class="panel-body">
            <h4>
                {{ localizations.tasks.set_question_params }}
            </h4>
            <div class="form-group">
                <label>
                    {{ localizations['check-list-templates'].question_text }}
                </label>
                <input class="form-control input-lg"
                       [(ngModel)]="editedQuestion.text">
            </div>
            <div class="checkbox-inline">
                <input id="answerYes"
                       class="checkbox"
                       type="checkbox"
                       [checked]="editedQuestion.answerYes"
                       (change)="editedQuestion.answerYes = !editedQuestion.answerYes">
                <label for="answerYes">
                    {{ localizations.tasks.using_yes_answer }}
                </label>
            </div>
            <div class="checkbox-inline">
                <input id="answerNo"
                       class="checkbox"
                       type="checkbox"
                       [checked]="editedQuestion.answerNo"
                       (change)="editedQuestion.answerNo = !editedQuestion.answerNo">
                <label for="answerNo">
                    {{ localizations.tasks.using_no_answer }}
                </label>
            </div>
            <div class="checkbox-inline">
                <input id="answerNotConcidered"
                       class="checkbox"
                       type="checkbox"
                       [checked]="editedQuestion.answerNotConcidered"
                       (change)="editedQuestion.answerNotConcidered = !editedQuestion.answerNotConcidered">
                <label for="answerNotConcidered">
                    {{ localizations.tasks.using_not_accepted_answer }}
                </label>
            </div>
            <br>
            <button class="btn btn-primary mt-20"
                    (click)="applyEditQuestion()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-default ml-20 mt-20"
                    (click)="cancelEditQuestion()">
                {{ localizations.common.cancel }}
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="task.isApplyCheckList">
    <div class="panel panel-shadow m-20">
        <div class="panel-heading">
            <h4 class="panel-title">
                {{ localizations.tasks.checklist }}
            </h4>
            <div class="heading-elements">
                <button class="btn btn-xs heading-btn bg-orange btn-labeled btn-labeled-right"
                        (click)="sendToMobile()">
                    <b>
                        <i class="icon-mobile"></i>
                    </b>
                    {{ localizations.tasks.pass_to_mobile }}
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <table class="table table-lg">
                <tbody>
                <ng-container *ngFor="let question of processingQuestions">
                    <tr>
                        <td width="20">
                            <i class="text-slate"
                               [ngClass]="{
                                    'icon-clipboard': !question.action,
                                    'icon-clipboard2':question.action
                                }"></i>
                        </td>
                        <td>
                            {{ question.text }}
                            <ng-container *ngIf="question.result && question.result.answer">
                                <input type="file"
                                       name="file-{{question.guid}}"
                                       id="file-{{question.guid}}" class="inputfile hide" (change)="fileChange($event, question)" />
                                <label for="file-{{question.guid}}" class="text-info anchor text-size-small ml-5 no-margin-bottom">прикрепить файл</label>
                            </ng-container>
                            <p class="no-margin-bottom mt-10"
                               *ngIf="question.result && question.result.additionalInfo">
                                <span class="text-muted">
                                    {{ localizations.common.information }}:
                                </span>
                                {{ question.result.additionalInfo }}
                            </p>
                        </td>
                        <td class="text-center"
                            width="70">
                            <button class="btn btn-success btn-icon btn-rounded btn-xs opacity-50"
                                    type="button"
                                    [ngClass]="{'opacity-50': !question.positiveHover && (!question.result || !question.result.answer || question.result.answer !== 'yes')}"
                                    (mouseenter)="question.positiveHover = true"
                                    (mouseleave)="question.positiveHover = false"
                                    (click)="selectAction(question, 'yes')">
                                <i class="icon-checkmark3"></i>
                            </button>
                        </td>
                        <td class="text-center"
                            width="70">
                            <button class="btn btn-danger btn-icon btn-rounded btn-xs opacity-50"
                                    type="button"
                                    [ngClass]="{'opacity-50': !question.negativeHover && (!question.result || !question.result.answer || question.result.answer !== 'no')}"
                                    (mouseenter)="question.negativeHover = true"
                                    (mouseleave)="question.negativeHover = false"
                                    (click)="selectAction(question, 'no')">
                                <i class="icon-cross2"></i>
                            </button>
                        </td>
                        <td class="text-center"
                            width="70">
                            <button class="btn btn-info btn-icon btn-rounded btn-xs opacity-50"
                                    type="button"
                                    [ngClass]="{'opacity-50': !question.notApplicableHover && (!question.result || !question.result.answer || question.result.answer !== 'not_applicable')}"
                                    (mouseenter)="question.notApplicableHover = true"
                                    (mouseleave)="question.notApplicableHover = false"
                                    (click)="selectAction(question, 'not_applicable')">
                                <i class="icon-minus3"></i>
                            </button>
                        </td>
                    </tr>
                    <ng-container *ngIf="question.result && question.result.files && question.result.files.length > 0">
                        <tr class="text-size-small"
                            *ngFor="let file of question.result.files">
                            <td class="pl-70"
                                colspan="4">
                                <a (click)="downloadFile(file._id, file.originalName)" class="anchor text-info">{{file.originalName}}</a>
                            </td>
                            <td class="text-right">
                                <button class="btn btn-sxs"
                                        [title]="localizations['appeals.requirements'].remove_file"
                                        (click)="deleteFile(checkList, question, file)">
                                    <i class="icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>

            <fieldset *ngIf="task.commonResult">
                <legend class="text-bold pl-20 no-margin-bottom">
                    {{ localizations['appeals.requirements'].total_results }}
                    <span class="label label-info text-uppercase ml-10"
                          *ngIf="task.commonResult.inspection_duration">
                        {{ localizations['appeals.requirements'].total_time }}:
                        {{ getTimeFromSeconds(task.commonResult.inspection_duration) }}
                    </span>
                </legend>
                <div class="p-20"
                     *ngIf="task.commonResult.report">
                    {{ task.commonResult.report }}
                </div>
            </fieldset>
        </div>
    </div>
</ng-container>


