import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityViolationMeasure } from './models/appeal-entity-violation-measure';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealData } from '../../../../appeal.data';

/**
 * Принятая мера
 */
@Component({
    selector: 'appeal-entity-violation-measure',
    templateUrl: 'appeal-entity-violation-measure.component.html',
})
export class AppealEntityViolationMeasureComponent implements OnInit {
    @Input() public entity: AppealEntityViolationMeasure;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    /** Статусы принятой меры */
    public statusesTaken = AppealData.statusesTaken;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public appealData: AppealData
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        console.log('appeal', this.appeal);
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public getProperty(property) {
        return this.statusesTaken[this.entity.sumAdmFineStatus.code][property];
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
