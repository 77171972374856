<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Контрольная закупка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер: </span>
            <ng-container *ngIf="entity.orderNumber">{{entity.orderNumber}}</ng-container>
            <em *ngIf="!entity.orderNumber">не указано</em>

            <span class="text-muted ml-10">Дата: </span>
            <ng-container *ngIf="entity.orderDate && !entity.orderDate.formatted">{{entity.orderDate | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.orderDate || entity.orderDate && entity.orderDate.formatted">не указано</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <label>Номер приказа</label>
                            <div class="form-group form-group-lg">
                                <input [(ngModel)]="entity.orderNumber" type="text" class="form-control input-xs" name="orderNumber" #orderNumber="ngModel" required>
                                <div *ngIf="isProcessValidate && orderNumber.errors" class="validation-error-label">
                                    <span *ngIf="orderNumber.errors.required">Поле "Номер приказа" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <label>Дата приказа</label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker [(ngModel)]="entity.orderDate" [large]="true" [valueAsString]="true" name="orderDate" #orderDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && orderDate.errors" class="validation-error-label">
                                    <span *ngIf="orderDate.errors.required">Поле "Дата приказа" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label>Место проведения (в случае проведения дистанционной контрольной закупки)</label>
                            <div class="form-group form-group-lg">
                                <input [(ngModel)]="entity.placeBuyDistance" type="text" class="form-control input-xs" name="placeBuyDistance" #placeBuyDistance="ngModel" required>
                                <div *ngIf="isProcessValidate && placeBuyDistance.errors" class="validation-error-label">
                                    <span *ngIf="placeBuyDistance.errors.required">Поле "Место проведения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <label>Дата проведения</label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker [(ngModel)]="entity.date" [large]="true" [valueAsString]="true" name="date" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата проведения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <label>Дата окончания</label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker [(ngModel)]="entity.dateFinish" [large]="true" [valueAsString]="true" name="dateFinish"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <label>Срок проведения КНМ (в рабочих часах)</label>
                            <div class="form-group form-group-lg">
                                <input [(ngModel)]="entity.periodHour" type="number" class="form-control input-xs" name="periodHour">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип места проведения КНМ</label>
                                <catalogue
                                    catalogName="nsiErpPlaceKnmType"
                                    name="placeKnmType"
                                    [(ngModel)]="entity.placeKnmType"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true">
                                </catalogue>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип объекта проведения КНМ</label>
                                <catalogue
                                    catalogName="nsiErpObjectKnmType"
                                    name="objectKnmType"
                                    [(ngModel)]="entity.objectKnmType"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true">
                                </catalogue>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Способ проведения КНМ</label>
                                <catalogue
                                    catalogName="nsiErpMethodKnm"
                                    name="methodKnm"
                                    [(ngModel)]="entity.methodKnm"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true">
                                </catalogue>
                            </div>
                        </div>
                    </div>

                    <!-- Инспектор -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            [multiple]="true"
                            [property]="'inspectors'"
                            [title]="'Инспектор'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип лица, уполномоченного на проведение КНМ</label>
                                <catalogue
                                    catalogName="nsiErpFaceKnmType"
                                    name="faceKnmType"
                                    [(ngModel)]="entity.faceKnmType"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true">
                                </catalogue>
                            </div>
                        </div>
                    </div>

                    <!-- Перечень мероприятий -->
                    <div class="mb-15">
                        <appeal-entity-catalogue
                            [large]="true"
                            [entity]="entity"
                            [property]="'knmEvents'"
                            [catalogName]="'kNMEvent'"
                            [title]="'Перечень мероприятий'"
                            [placeholder]="'Укажите мероприятие...'">
                        </appeal-entity-catalogue>
                        <div *ngIf="isProcessValidate && knmEvents.errors" class="validation-error-label">
                            <span *ngIf="knmEvents.errors.required">Поле "Перечень мероприятий" обязательно для заполнения</span>
                        </div>
                    </div>

                    <!-- Тип основания регистрации -->
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип основания регистрации</label>
                                <catalogue
                                    catalogName="nsiErpRegistrBaseType"
                                    name="registrationType"
                                    [(ngModel)]="entity.registrationType"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true"
                                    placeholder="Укажите тип основания">
                                </catalogue>
                            </div>
                        </div>
                    </div>

                    <!-- Документ о согласовании контрольной закупки -->
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Документ о согласовании контрольной закупки</label>
                                <catalogue
                                    catalogName="nsiErpDocsPlanCheck"
                                    name="docsPlanCheck"
                                    [(ngModel)]="entity.docsPlanCheck"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true"
                                    placeholder="Укажите документ">
                                </catalogue>
                            </div>
                        </div>
                    </div>

                    <!-- Тип федерального закона -->
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип федерального закона</label>
                                <catalogue
                                    catalogName="nsiErpFedLawType"
                                    name="typeFederalLaw"
                                    [(ngModel)]="entity.typeFederalLaw"
                                    [priority]="true"
                                    [large]="true"
                                    [disabled]="true"
                                    placeholder="Укажите тип федерального закона">
                                </catalogue>
                            </div>
                        </div>
                    </div>

                    <!-- Обязательные требования -->
                    <div class="mb-15">
                        <appeal-entity-catalogue
                            [large]="true"
                            [entity]="entity"
                            [property]="'mandatoryReqs'"
                            catalogName="mandatoryReqs"
                            [title]="'Обязательные требования'"
                            [placeholder]="'Укажите обязательные требования'">
                        </appeal-entity-catalogue>
                        <div *ngIf="isProcessValidate && mandatoryReqs.errors" class="validation-error-label">
                            <span *ngIf="mandatoryReqs.errors.required">Поле "Обязательные требования" обязательно для заполнения</span>
                        </div>
                    </div>

                    <!-- Правовые основы -->
                    <div class="mb-15">
                        <appeal-entity-catalogue
                            [large]="true"
                            [entity]="entity"
                            [property]="'basisKnm'"
                            [catalogName]="'basisKNM'"
                            [title]="'Правовые основания'"
                            [placeholder]="'Укажите основания проведения закупки...'">
                        </appeal-entity-catalogue>
                        <div *ngIf="isProcessValidate && basisKnm.errors" class="validation-error-label">
                            <span *ngIf="basisKnm.errors.required">Поле "Правовые основания" обязательно для заполнения</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Цель контрольной закупки</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.target" name="target" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Задача контрольной закупки</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.task" name="task" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Предмет контрольной закупки</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.products" name="products" class="form-control" #products="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && products.errors" class="validation-error-label">
                                    <span *ngIf="products.errors.required">Поле "Предмет контрольной закупки" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Способы закупки товаров (работ, услуг)</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.productsPurchase" name="productsPurchase" class="form-control" #productsPurchase="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && productsPurchase.errors" class="validation-error-label">
                                    <span *ngIf="productsPurchase.errors.required">Поле "Способы закупки товаров" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Способы оплаты товаров (работ, услуг)</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.productsPayment" name="productsPayment" class="form-control" #productsPayment="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && productsPayment.errors" class="validation-error-label">
                                    <span *ngIf="productsPayment.errors.required">Поле "Способы оплаты товаров" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Сведения о применении фото- и киносъемки, видеозаписи, иных способах фиксации</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.fixation" name="fixation" class="form-control" #fixation="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && fixation.errors" class="validation-error-label">
                                    <span *ngIf="fixation.errors.required">Поле "Сведения о применении фото- и киносъемки, видеозаписи, иных способах фиксации" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <label>Дата письма о согласовании</label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker [(ngModel)]="entity.procDataAgreement" [large]="true" [valueAsString]="true" name="procDataAgreement"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <label>Номер письма о согласовании</label>
                            <div class="form-group form-group-lg">
                                <input [(ngModel)]="entity.procNumberAgreement" type="text" class="form-control input-xs" placeholder="Введите номер" name="procNumberAgreement">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Иные сведения</label>
                            <div class="form-group form-group-lg">
                                <textarea [(ngModel)]="entity.otherInformation" name="buyItem" class="form-control" name="otherInformation" #otherInformation="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && otherInformation.errors" class="validation-error-label">
                                    <span *ngIf="otherInformation.errors.required">Поле "Иные сведения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Руководитель -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            [property]="'supervisor'"
                            [title]="'Руководитель'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                    </div>

                    <!-- Инспектор -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            property="inspector"
                            [title]="'Инспектор'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
