<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            <span>
                {{ element.title }}
            </span>
        </h6>
        <div class="heading-elements">
            <a [title]="localizations.common.edit"
               [routerLink]="['/', moduleBaseUrl, 'print-forms', 'edit', element._id]">
                <svg width="16"
                     height="16"
                     viewBox="0 0 16 16"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2 3C2 2.44772 2.44772 2 3 2H6C6.55228 2 7 1.55228 7 1C7 0.447715 6.55228 0 6 0H3C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V10C16 9.44771 15.5523 9 15 9C14.4477 9 14 9.44771 14 10V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM7.01347 7.5923L11.7773 2.82843L13.1916 4.24264L8.42768 9.00652L7.00379 9.0162L7.01347 7.5923ZM11.0702 0.707107C11.4608 0.316582 12.0939 0.316583 12.4845 0.707107L15.3129 3.53553C15.7034 3.92606 15.7034 4.55922 15.3129 4.94975L9.55181 10.7108C9.36594 10.8967 9.11436 11.0019 8.8515 11.0037L6.00372 11.023C5.44613 11.0268 4.99316 10.5739 4.99694 10.0163L5.0163 7.16848C5.01809 6.90563 5.1233 6.65404 5.30917 6.46817L11.0702 0.707107Z"
                          fill="#A7A7AB"/>
                </svg>
            </a>
            <a [title]="localizations.common.delete"
               (click)="showDeleteConfirmationModal()">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </a>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        {{ localizations.common.general_data }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.common,
                                 'icon-arrow-left12': !panels.common
                                 }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.common}">
                        <div class="flex flex-row mb-10"
                             *ngIf="element.docName">
                            <div class="text-muted">
                                {{ localizations.common.name }}:
                            </div>
                            <div>
                                {{ element.docName }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="element.format">
                            <div class="text-muted">
                                {{ localizations['print-forms'].format }}:
                            </div>
                            <div>
                                {{ element.format }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations['print-forms'].format }}:
                            </div>
                            <div>
                                {{ isPrintFormActive ?
                                    localizations.common.used :
                                    localizations.common.not_used
                                }}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>

<delete-confirmation-modal
    [title]="deleteConfirmationHeader"
    [text]="deleteConfirmationText"
    (onConfirm)="deleteForm()"
    #deleteConfirmationModal>
</delete-confirmation-modal>
