import { Injectable } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import * as _ from 'lodash-es';

@Injectable()
export class CopyService {
    public isCopiedToClientModule = false; // флаг того, что данные успешно скопированы в буфер клиентского модуля

    public constructor(
        private clipboardService: ClipboardService,
    ) {}

    /**
     * Копирование данных в буфер и в клиентский модуль
     * @param data - данные для копирования
     * @param dataType - тип данных
     */
    public copyData(data, dataType) {
        const copyData = {
            type: dataType,
            data: _.cloneDeep(data),
        };

        // копирование данных в буфер обмена
        this.clipboardService.copyFromContent(JSON.stringify(copyData));

        // this.clientModuleService.sendBufferData(copyData);
    }

    /**
     * Получение данных из буфера обмена
     * @param event - событие вставки
     * @param dataType - тип данных
     * @returns {any}
     */
    public getDataFromClipboard(event, dataType) {
        const data = event.clipboardData.getData('text/plain');
        if (this.checkIsStringJson(data)) {
            const copyData = JSON.parse(data);
            if (copyData.type && copyData.type === dataType) {
                return copyData.data;
            }
        }
        return false;
    }

    /**
     * Проверка на то, что строка является JSON строкой
     * @param str
     * @returns {boolean}
     */
    public checkIsStringJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
