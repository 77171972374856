<div class="calendar m-20">
    <div class="datepickers">
        <div class="row">
            <div class="col-md-4 text-center mb-20" *ngFor="let month of months">
                <bs-datepicker-inline
                        [minDate]="getMinDate(month.id)"
                        [maxDate]="getMaxDate(month.id)"
                        [bsConfig]="config"
                        [bsValue]="null"
                        [dateCustomClasses]="selectedDates"
                        (bsValueChange)="onValueChange($event)"
                ></bs-datepicker-inline>
            </div>
        </div>
    </div>
</div>
