<div class="p-20">
    <div class="panel panel-default margin">
        <div class="panel-body p-20">
            <div class="checkbox-block no-padding"
                 [ngClass]="{'no-margin': !data[appealSubservice.guid].correctVariant || !data[appealSubservice.guid][appealField][entity.guid].active,
                             'mb-10': data[appealSubservice.guid][appealField][entity.guid].active}">
                <input type="checkbox" class="checkbox"
                       [checked]="data[appealSubservice.guid][appealField][entity.guid] && data[appealSubservice.guid][appealField][entity.guid].active"
                       [disabled]="!data[appealSubservice.guid].correctVariant"
                       (change)="changeEntityPresentInfoInAppealService(appealSubservice)"
                       id="appealSubservice{{appealSubservice.guid}}">
                <label for="appealSubservice{{appealSubservice.guid}}">
                    <!-- Порядковый номер услуги -->
                    <!-- TODO пока не внедрили комплексную услугу - не нужен -->
                    <span *ngIf="false" class="badge bg-primary mr-5 number">{{data[appealSubservice.guid].number}}</span>
                    {{appealSubservice.shortTitle}}
                </label>
            </div>

            <div class="text-danger ml-30 text-size-small"
                 *ngIf="!data[appealSubservice.guid].correctVariant">
                <i class="icon-exclamation"></i> не выбран вариант услуги
            </div>

            <div class="ml-30" *ngIf="data[appealSubservice.guid].correctVariant && data[appealSubservice.guid][appealField][entity.guid].active">

                <!-- Выбранная группа участников -->
                <p class="mb-3">
                    <span class="text-muted mr-5">Группа:</span>
                    <span class="anchor text-info"
                          (click)="changeEntityGroup(appealSubservice)"
                          *ngIf="!data[appealSubservice.guid][appealField][entity.guid].group">не выбрана</span>
                    <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].group">
                        {{data[appealSubservice.guid][appealField][entity.guid].group.name}}
                        <i class="fa fa-info-circle text-slate cursor-pointer" *ngIf="data[appealSubservice.guid][appealField][entity.guid].group.comment"
                           [popover]="data[appealSubservice.guid][appealField][entity.guid].group.comment" placement="right"
                           [outsideClick]="true"></i>
                        <span class="anchor text-info text-size-small ml-5"
                              *ngIf="data[appealSubservice.guid][appealField][entity.guid].groups && data[appealSubservice.guid][appealField][entity.guid].groups.length > 1"
                              (click)="changeEntityGroup(appealSubservice)">изменить</span>
                    </ng-container>
                    <span class="anchor text-size-small text-info ml-10"
                          *ngIf="data[appealSubservice.guid][appealField][entity.guid].group && data[appealSubservice.guid][appealField][entity.guid].isAgent"
                          (click)="setOnlyAgentPresentInSubservice(appealSubservice)">оставить только участие в качестве представителя</span>
                </p>

                <!-- Выбранная категория объекта -->
                <p class="mb-3" *ngIf="data[appealSubservice.guid][appealField][entity.guid].group">
                    <span class="text-muted mr-5">Категория:</span>
                    <span class="anchor text-info"
                          (click)="changeSubjectCategory(appealSubservice)"
                          *ngIf="data[appealSubservice.guid][appealField][entity.guid].categories && data[appealSubservice.guid][appealField][entity.guid].categories.length == 0">не выбрана</span>
                    <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].categories && data[appealSubservice.guid][appealField][entity.guid].categories.length > 0">
                        <ng-container *ngFor="let category of data[appealSubservice.guid][appealField][entity.guid].categories; let lst = last">
                            {{category.name}}
                            <span *ngIf="!lst" class="ml-5 mr-5">/</span>
                        </ng-container>
                        <span class="anchor text-info text-size-small ml-5" (click)="changeSubjectCategory(appealSubservice)">изменить</span>
                    </ng-container>
                    <span class="alert alert-danger pt-5 pb-5 pl-10 pr-10 no-border ml-10 no-margin-bottom display-inline-block"
                          *ngIf="data[appealSubservice.guid][appealField][entity.guid].categoriesForSelect && data[appealSubservice.guid][appealField][entity.guid].categoriesForSelect.length > 0
                                     && data[appealSubservice.guid][appealField][entity.guid].categories && data[appealSubservice.guid][appealField][entity.guid].categories.length > 0
                                     && !validateService.checkObjectSelectCategoryFinish(data[appealSubservice.guid][appealField][entity.guid].categoriesForSelect, data[appealSubservice.guid][appealField][entity.guid].categories)">
                            <span class="text-bold">Внимание!</span> Категория участника не выбрана до конечного уровня.
                        </span>
                </p>

                <!-- Выбранный вид участия -->
                <p class="mb-3" *ngIf="data[appealSubservice.guid][appealField][entity.guid].subKind">
                    <span class="text-muted mr-5">Вид участия: </span>
                    <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].subKind">
                        {{entity.kind.subKind.type ? data[appealSubservice.guid][appealField][entity.guid].subKind.secondGroupName : data[appealSubservice.guid][appealField][entity.guid].subKind.firstGroupName}}
                    </ng-container>
                </p>
                <div class="alert alert-danger no-border pt-5 pb-5 pl-15 pr-15 mt-5 mb-5"
                     *ngIf="data[appealSubservice.guid][appealField][entity.guid].subKind && data[appealSubservice.guid][appealField][entity.guid].isAgent && data[appealSubservice.guid][appealField][entity.guid].subKind.type.indexOf('principal') !== -1">
                    <span class="text-bold">Внимание!</span> Участник не может быть доверителем, поскольку он является представителем другого участника в деле. Проверте выбранную группу и категорию участия
                </div>

                <!-- Представитель -->
                <div class="mb-3" *ngIf="data[appealSubservice.guid][appealField][entity.guid].subKind
                                           && (!data[appealSubservice.guid][appealField][entity.guid].isAgent || data[appealSubservice.guid][appealField][entity.guid].isAgent && data[appealSubservice.guid][appealField][entity.guid].subKind.type.indexOf('principal') !== -1)
                                           && data[appealSubservice.guid][appealField][entity.guid].allowAgent
                                           && !data[appealSubservice.guid][appealField][entity.guid].isProcessingAddAgent
                                           && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgent">
                    <span class="text-muted mr-5">Представитель:</span>

                    <!-- Представитель не выбран -->
                    <ng-container *ngIf="(!data[appealSubservice.guid][appealField][entity.guid].representative
                                             || !data[appealSubservice.guid][appealField][entity.guid].representative.guid)">
                        <div class="display-inline-block ml-5" [ngClass]="{'alert alert-danger pt-5 pb-5 pl-10 pr-10 no-border no-margin-bottom': data[appealSubservice.guid][appealField][entity.guid].requiredAgent}">
                            <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].requiredAgent"><span class="text-bold">Внимание!</span> Выбор представителя обязателен. </ng-container>
                            <ng-container *ngIf="subjectsForUseAsAgent[appealSubservice.guid] && subjectsForUseAsAgent[appealSubservice.guid].length > 0">
                                    <span class="anchor"
                                          [ngClass]="{'text-danger': data[appealSubservice.guid][appealField][entity.guid].requiredAgent, 'text-info': !data[appealSubservice.guid][appealField][entity.guid].requiredAgent}"
                                          (click)="selectAgent(appealSubservice)">выбрать</span> <span class="text-muted ml-5 mr-5">или</span>
                            </ng-container>
                            <span class="anchor"
                                  (click)="addAgent(appealSubservice)"
                                  [ngClass]="{'text-danger': data[appealSubservice.guid][appealField][entity.guid].requiredAgent, 'text-info': !data[appealSubservice.guid][appealField][entity.guid].requiredAgent}">добавить</span>
                        </div>
                    </ng-container>

                    <!-- Представитель выбран -->
                    <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].representative && data[appealSubservice.guid][appealField][entity.guid].representative.guid">
                        {{subjectsData[data[appealSubservice.guid][appealField][entity.guid].representative.guid].shortHeader}}

                        <span class="text-muted ml-5">Вид представительства: </span>
                        {{data[appealSubservice.guid][appealField][entity.guid].representative.type ? data[appealSubservice.guid][appealField][entity.guid].representative.type.name : 'не выбран'}}

                        <span class="text-muted ml-5">Документ основание: </span>
                        {{data[appealSubservice.guid][appealField][entity.guid].representative.authority ? data[appealSubservice.guid][appealField][entity.guid].representative.authority : 'не указан'}}

                        <span class="text-info anchor ml-5 text-size-small" (click)="changeAgent(appealSubservice)">изменить</span>
                        <span class="text-info anchor ml-10 text-size-small" (click)="deleteAgent(appealSubservice)">удалить</span>
                    </ng-container>
                </div>

                <!-- Доверители -->
                <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].isAgent && data[appealSubservice.guid][appealField][entity.guid].principals && data[appealSubservice.guid][appealField][entity.guid].principals.length > 0">
                    <span class="text-muted mr-5">Представляет интересы участников: </span>
                    <ul>
                        <ng-container *ngFor="let principal of data[appealSubservice.guid][appealField][entity.guid].principals">
                            <li *ngIf="!data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent
                                           || data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent && data[appealSubservice.guid][appealField][entity.guid].agentParams.guid != principal.guid">
                                {{subjectsData[principal.guid].shortHeader}}
                                <span class="text-muted ml-5 mr-5">Вид участия: </span>
                                {{principal.type ? principal.type.name : 'не выбран'}}
                                <span class="text-muted ml-5 mr-5">Документ-основание: </span>
                                {{principal.authority ? principal.authority : 'не заполнен'}}

                                <span class="text-info anchor ml-5 text-size-small" (click)="changePrincipalAgent(appealSubservice, principal)">изменить</span>
                                <span class="text-info anchor ml-10 text-size-small" (click)="deletePrincipal(appealSubservice, principal)">удалить</span>
                            </li>
                        </ng-container>
                    </ul>

                </ng-container>

                <!-- Ссылка для перехода на заполнение основных данных при добавлении объекта -->
                <ng-container *ngIf="checkAllowCommonTabLink(appealSubservice)">
                    <hr class="mt-10 mb-10">
                    <span class="anchor text-info" (click)="activateCommonTab()">заполнить основные данные </span><i class="icon-arrow-right8 text-info ml-5"></i>
                </ng-container>


                <!-- Выбор группы участников -->
                <div class="bg-primary-50 p-10 mt-10 mb-10"
                     *ngIf="data[appealSubservice.guid][appealField][entity.guid].editGroup && data[appealSubservice.guid].groups">
                    <div class="text-bold">Выберите группу участников из списка:</div>
                    <div class="radio-block" *ngFor="let group of data[appealSubservice.guid][appealField][entity.guid].groups">
                        <input type="radio" class="radio"
                               [checked]="data[appealSubservice.guid][appealField][entity.guid].group
                                              && data[appealSubservice.guid][appealField][entity.guid].group.guid == group.guid"
                               id="group{{group.guid}}{{appealSubservice.guid}}">
                        <label for="group{{group.guid}}{{appealSubservice.guid}}"
                               (click)="selectEntityGroup(appealSubservice, group)">
                            {{group.name}}
                        </label>
                        <div class="comment media-annotation ml-30" *ngIf="group.comment">{{group.comment}}</div>
                    </div>
                </div>

                <!-- Выбор категории объекта -->
                <div class="bg-primary-50 p-10 mt-10 mb-10" *ngIf="data[appealSubservice.guid][appealField][entity.guid].editCategory">
                    <div class="text-bold mb-15">Выберите категорию участника:</div>
                    <div style="margin-left: -30px">
                        <tree-select [treeArray]="data[appealSubservice.guid][appealField][entity.guid].categories"
                                     [elements]="data[appealSubservice.guid][appealField][entity.guid].categoriesForSelect"
                                     [limitArray]="data[appealSubservice.guid][appealField][entity.guid].group.categoryGuids"
                                     [updateElements]="data[appealSubservice.guid][appealField][entity.guid].categories"
                                     [additionalFields]="['xsd', 'xsdLink']"
                                     nodeName="name"
                                     subNodes="subCategory"
                                     (onEnd)="selectEntityCategories($event,appealSubservice)"></tree-select>
                    </div>
                </div>

                <!-- Выбор представителя -->
                <div class="bg-primary-50 pt-10 pb-10 pl-20 pr-20" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessSelectAgent">
                    <div class="text-bold">Выберите представителя:</div>
                    <div class="radio-block" *ngFor="let agent of subjectsForUseAsAgent[appealSubservice.guid]">
                        <input type="radio" class="radio" name="agents"
                               [checked]="data[appealSubservice.guid][appealField][entity.guid].representative
                                              && data[appealSubservice.guid][appealField][entity.guid].representative.guid == agent.guid"
                               id="agent{{agent.guid}}{{appealSubservice.guid}}">
                        <label for="agent{{agent.guid}}{{appealSubservice.guid}}"
                               (click)="applyAgent(appealSubservice, agent)">
                            {{agent.shortHeader}}
                        </label>
                    </div>
                </div>

                <div class="bg-primary-50 pt-10 pb-10 pl-20 pr-20" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent">
                    <div class="text-bold">Доверитель: <span class="text-size-large">{{subjectsData[data[appealSubservice.guid][appealField][entity.guid].agentParams.guid].shortHeader}}</span></div>
                </div>

                <!-- Добавление представителя -->
                <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingAddAgent">

                    <div class="bg-primary-50 pt-10 pb-10 pl-20 pr-20 mb-10" style="position: relative">
                        <div style="position: absolute; top: 10px; right: 10px" class="cursor-pointer" title="Отменить добавление представителя" (click)="cancelAddAgent(appealSubservice)"><i class="icon-cross"></i></div>


                        <div class="text-bold">Добавление представителя:</div>

                        <!-- Выбранный вид представителя: ФЛ/ЮЛ/ИП -->
                        <p class="mb-3 hide" *ngIf="subjectsData[data[appealSubservice.guid][appealField][entity.guid].representative.guid].kind.subKind && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentKind">
                            <span class="text-muted mr-5">Вид представителя:</span>
                            {{subjectsData[data[appealSubservice.guid][appealField][entity.guid].representative.guid].kind.subKind.name}}
                            <span class="anchor text-info text-size-small ml-5"
                                  *ngIf="data[appealSubservice.guid].agentTypes.length > 1"
                                  (click)="changeAgentProperty(appealSubservice, 'isProcessingChangeAgentKind')">изменить</span>
                        </p>

                        <!-- Название представителя -->
                        <p class="mb-3" *ngIf="subjectsData[data[appealSubservice.guid][appealField][entity.guid].representative.guid].shortHeader && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentName">
                            <span class="text-muted mr-5">ФИО представителя:</span>
                            {{subjectsData[data[appealSubservice.guid][appealField][entity.guid].representative.guid].shortHeader}}
                            <span class="anchor text-info text-size-small ml-5"
                                  (click)="changeAgentProperty(appealSubservice, 'isProcessingChangeAgentName')">изменить</span>
                        </p>

                        <!-- Выбранный вид представительства -->
                        <p class="mb-3" *ngIf="data[appealSubservice.guid][appealField][entity.guid].representative.type && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentType">
                            <span class="text-muted mr-5">Вид представительства:</span>
                            {{data[appealSubservice.guid][appealField][entity.guid].representative.type.name}}
                            <span class="anchor text-info text-size-small ml-5"
                                  *ngIf="data[appealSubservice.guid].agentTypes.length > 1"
                                  (click)="changeAgentProperty(appealSubservice, 'isProcessingChangeAgentType')">изменить</span>
                        </p>

                        <!-- Документ основание -->
                        <p class="mb-3" *ngIf="data[appealSubservice.guid][appealField][entity.guid].representative.authority && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentAuthority">
                            <span class="text-muted mr-5">Документ-основание:</span>
                            {{data[appealSubservice.guid][appealField][entity.guid].representative.authority}}
                            <span class="anchor text-info text-size-small ml-5"
                                  *ngIf="data[appealSubservice.guid].agentTypes.length > 1"
                                  (click)="changeAgentProperty(appealSubservice, 'isProcessingChangeAgentAuthority')">изменить</span>
                        </p>

                        <div class="mt-15" *ngIf="data[appealSubservice.guid][appealField][entity.guid].representative.authority && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentAuthority">
                            <button class="btn btn-xs btn-info"
                                    (click)="applyAddAgent(appealSubservice)">Применить</button>
                        </div>

                    </div>

                    <!-- Выбор вида представителя: ФЛ/ЮЛ/ИП -->
                    <div class="pt-10 pl-20 pr-20 pb-5 bg-primary-50" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentKind">
                        <div class="text-bold">Выберите вид представителя:</div>
                        <div class="radio-block" *ngFor="let agentKind of data[appealSubservice.guid].agentTypes">
                            <input type="radio" class="radio" name="agentKind" (click)="applyAgentSubKind(appealSubservice, agentKind)" id="agentKind{{appealSubservice.guid}}{{agentKind.type}}">
                            <label for="agentKind{{appealSubservice.guid}}{{agentKind.type}}">{{agentKind.name}}</label>
                        </div>
                    </div>

                    <!-- Заполнение Названия представителя -->
                    <div class="bg-primary-50 pt-15" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentName">
                        <div class="text-bold ml-20">Заполните данные представителя вручную или воспользовавшись поиском в реестре клиентов</div>
                        <subject-form [user]="subjectsData[data[appealSubservice.guid][appealField][entity.guid].representative.guid]"
                                      [appealMode]="true"></subject-form>
                        <button class="btn btn-xs btn-info mr-20 mb-20 ml-20"
                                (click)="applyAgentName(appealSubservice)">Применить</button>
                    </div>
                </ng-container>

                <!-- Выбор вида представительства -->
                <div class="bg-primary-50 pt-10 pb-10 pl-20 pr-20" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentType">
                    <div class="text-bold">Выберите вид представительства:</div>
                    <div class="radio-block" *ngFor="let agentType of representativeTypes">
                        <input type="radio" class="radio" name="agentType"
                               [checked]="data[appealSubservice.guid][appealField][entity.guid].agentParams && data[appealSubservice.guid][appealField][entity.guid].agentParams.type
                                              && data[appealSubservice.guid][appealField][entity.guid].agentParams.type.id == agentType.id"
                               id="agentType{{agentType.id}}{{appealSubservice.guid}}">
                        <label for="agentType{{agentType.id}}{{appealSubservice.guid}}"
                               (click)="applyAgentType(appealSubservice, agentType)">
                            {{agentType.name}}
                        </label>
                    </div>
                </div>

                <!-- Документ основание представителя  -->
                <div class="pt-10 pb-15 pl-20 pr-20 bg-primary-50" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgentAuthority">
                    <div class="text-bold">
                        Документ-основание представителя:
                        <span class="text-size-small anchor text-info ml-5" (click)="changeAgentAuthorityType(appealSubservice)">
                                {{data[appealSubservice.guid][appealField][entity.guid].agentParams.isComplexAuthority ? 'заполнить одной строкой' : 'заполнить раздельно'}}
                            </span>
                    </div>

                    <!-- Заполнение одной строкой -->
                    <ng-container *ngIf="!data[appealSubservice.guid][appealField][entity.guid].agentParams.isComplexAuthority">
                        <input type="text" class="form-control" [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.authority"
                               (change)="changeAgentPropertyInLinkObject(appealSubservice, 'authority')"
                               *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgent
                                      || data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent">
                        <div class="input-group input-group-xs" *ngIf="!data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgent
                                                                       && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent">
                            <input type="text" class="form-control" [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.authority"
                                   (keypress)="completeOnEnter($event, appealSubservice)"
                                   (change)="changeAgentPropertyInLinkObject(appealSubservice, 'authority')">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-default" (click)="applyAgentAuthority(appealSubservice)">
                                    <i class="icon-checkmark3"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data[appealSubservice.guid][appealField][entity.guid].agentParams.isComplexAuthority">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group form-group-xs mb-10">
                                    <label>Наименование документа</label>
                                    <input class="form-control" [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.complexAuthority.name">
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group form-group-xs mb-10">
                                    <label>Серия</label>
                                    <input class="form-control" [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.complexAuthority.series">
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group form-group-xs mb-10">
                                    <label>Номер</label>
                                    <input class="form-control" [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.complexAuthority.number">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group form-group-xs no-margin-bottom">
                                    <label>Орган, выдавший документ</label>
                                    <input class="form-control" [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.complexAuthority.issuer">
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group form-group-xs no-margin-bottom">
                                    <label>Дата выдачи</label>
                                    <evolenta-datepicker [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.complexAuthority.issueDate"
                                                         [small]="true"></evolenta-datepicker>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group form-group-xs no-margin-bottom">
                                    <label>Действителен до</label>
                                    <evolenta-datepicker [(ngModel)]="data[appealSubservice.guid][appealField][entity.guid].agentParams.complexAuthority.beforeDate"
                                                         [small]="true"></evolenta-datepicker>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-default btn-xs mt-10" (click)="applyAgentAuthority(appealSubservice)"
                                *ngIf="!data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgent && !data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent">Применить</button>
                    </ng-container>

                </div>

                <!-- Редактирование данных представителя -->
                <button class="btn btn-xs btn-info mt-20" *ngIf="data[appealSubservice.guid][appealField][entity.guid].isProcessingChangeAgent || data[appealSubservice.guid][appealField][entity.guid].isProcessingChangePrincipalAgent"
                        (click)="applyAgentChanges(appealSubservice)">Применить</button>

            </div>
        </div>
    </div>
</div>

