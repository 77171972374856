import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StorageService, ToasterService } from '@evolenta/core';
import { AppealEntityProceedInitiation } from './models/appeal-entity-proceed-initiation';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-proceed-initiation',
    templateUrl: 'appeal-entity-proceed-initiation.component.html',
})
export class AppealEntityProceedInitiationComponent implements OnInit {
    @Input() public entity: AppealEntityProceedInitiation;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public currentOrganization = this.storage.getItem('currentOrganization');
    public currentUser = this.storage.getItem('user');

    public isProcessValidate = false;
    public employee = {};

    public constructor(
        public storage: StorageService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.date) {
            this.entity.date = this.appeal.dateRegister;
        }
        if (!this.entity.employee) {
            this.entity.employee = {
                name: [{ id: this.currentUser.id, text: this.currentUser.name }],
                position: this.currentUser.position ? this.currentUser.position : '',
            };
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidEmployee = this.validateEmployee();

        return isValidForm && isValidEmployee;
    }

    public validateEmployee(): boolean {
        const isValidName = !!_.get(this.entity.employee, 'name.0.id', null);
        const isValidPosition = !!_.get(this.entity.employee, 'position', null);
        const isValid = isValidName && isValidPosition;

        this.employee = isValid
            ? { value: this.entity.employee }
            : {
                  value: this.entity.employee,
                  errors: { nameRequired: !isValidName, positionRequired: !isValidPosition },
              };

        return isValid;
    }
}
