<div class="m-20" *ngIf="!isEdit">

    <div class="panel panel-shadow panel-small"
         *ngFor="let item of xslFields">
        <div class="panel-heading">
            <h5 class="panel-title pt-10"
                [ngStyle]="{'max-width': '100%'}">
                {{ item.name }}
                <span class="badge bg-slate-300 bg-info ml-10">
                    {{ item.code }}
                </span>
            </h5>
            <div class="heading-elements">
                <div class="heading-elements">
                    <button class="btn btn-default btn-sxs"
                            title=""
                            type="button"
                            (click)="saveElement(item)">
                        <i inlineSVG="assets/icon-copy.svg"></i>
                    </button>
                    <button class="btn btn-default btn-sxs"
                            type="button"
                            [title]="localizations.common.edit"
                            (click)="editElement(item)">
                        <i inlineSVG="assets/icon-edit.svg"></i>
                    </button>
                    <button class="btn btn-default btn-sxs"
                            type="button"
                            [title]="localizations.common.delete"
                            (click)="removeElement(item.guid)">
                        <i inlineSVG="assets/icon-trash.svg"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
            (click)="addElement()">
        <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
        {{ localizations['knd-kinds'].add_element }}
    </button>

    <div>
        <input id="file"
               class="inputfile hide"
               type="file"
               name="file"
               (change)="loadFile($event)"/>
        <label class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
               for="file">
            {{ localizations.organizations.add_from_file }}
            <b><i inlineSVG="assets/icon-attach.svg"></i></b>
        </label>
    </div>
</div>
<ng-container *ngIf="isEdit">
    <print-form-xsl-edit [element]="selectedElement"
                         (onApply)="saveXsl($event)"
                         (onCancel)="cancelXsl()"
    ></print-form-xsl-edit>
</ng-container>
