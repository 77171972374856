<!-- Список групп документов -->
<div *ngIf="!editedDocument"
     [ngClass]="{'p-20': !onlyGroups}">
    <!-- Если в деле одна услуга, то просто выводим ее группы -->
    <ng-container *ngIf="appeal.subservice">
        <appeal-subservice-document-groups [appeal]="appeal"
                                           [appealSubservice]="appeal.subservice"
                                           [task]="task"
                                           [onlyGroups]="onlyGroups"
                                           [allowEdit]="allowEdit"
                                           (onEditDocument)="editDocument($event)"
                                           (onDeleteDocument)="deleteDocument($event)"
                                           (onApplyDocument)="applyDocument($event)"
                                           (onPrintDocument)="sendPrintForm($event)" #subserviceDocumentGroups>
        </appeal-subservice-document-groups>
    </ng-container>

    <!-- Добавляем группу "Прочие документы" -->
    <appeal-subservice-document-groups [appeal]="appeal"
                                       (onEditDocument)="editDocument($event)"
                                       (onPrintDocument)="sendPrintForm($event)"
                                       (onDeleteDocument)="deleteDocument($event)"
                                       (onApplyDocument)="applyDocument($event)" #subserviceDocumentGroups *ngIf="!onlyGroups || onlyGroups.length == 0">
    </appeal-subservice-document-groups>
</div>

<!-- Форма редактирования отдельного документа -->
<appeal-document-card *ngIf="editedDocument" mode="edit"
                      [activeTab]="activeTab"
                      [appeal]="appeal"
                      [subservice]="subservice"
                      [sendEnvelope]="continueSendEnvelope"
                      [document]="editedDocument" [group]="editedDocumentGroup"
                      (onPrint)="sendPrintForm($event)"
                      (updateAppealAfterSendStatusEnvelope)="updateAppealAfterSendStatusEnvelope()"
                      (onApply)="applyDocument($event)" #editCard>
</appeal-document-card>


<!-- Невидимая ссылка для корректной работы загрузки файлов -->
<a id='fileLink'
   download=''
   style="display:none;">
</a>


<!-- модальное окно для выбора документов с целью формирования специальных печатных форм -->
<ng-template #selectDocumentsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations['appeals.documents'].select_type}}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
            <tr>
                <th colspan="2">
                    {{localizations['appeals.documents'].appellation}}
                </th>
                <th class="p5 text-center"
                    width="100">
                    {{localizations['appeals.documents'].of_originals}}
                </th>
                <th class="p5 text-center"
                    width="100">
                    {{localizations['appeals.documents'].originals_sheets}}
                </th>
                <th class="p5 text-center"
                    width="100">
                    {{localizations['appeals.documents'].of_copies}}
                </th>
                <th class="p5 text-center"
                    width="100">
                    {{localizations['appeals.documents'].copies_sheets}}
                </th>
            </tr>
            </thead>
            <ng-container *ngFor="let subservice of groupsForModal">
                <tbody>
                <tr class="bg-slate-50"
                    *ngIf="subservice.title">
                    <td class="p-5"
                        colspan="6">
                        <span class="control-arrow text-slate cursor-pointer mr-10"
                              (click)="subservice.hide = !subservice.hide">
                            <i class=""
                               [ngClass]="{'icon-circle-down2': !subservice.hide, 'icon-circle-right2': subservice.hide}">
                            </i>
                        </span>
                        <span class="text-muted">
                            {{localizations['appeals.documents'].service_capital}}:
                        </span>
                        <span class="text-semibold">
                            {{subservice.title}}
                        </span>

                    </td>
                </tr>
                <ng-container *ngIf="!subservice.hide">
                    <ng-container *ngFor="let group of subservice.groups">
                        <tr>
                            <td class="pt-5 no-padding-right pb-5 pl-10"
                                colspan="6">
                                <span class="text-semibold">
                                    {{group.name}}
                                </span>
                            </td>
                        </tr>
                        <ng-container *ngIf="!group.hide">
                            <tr *ngFor="let doc of group.documents"
                                [ngClass]="{'bg-blue-50': doc.selected}">
                                <td class="text-center p-5">
                                    <input class="checkbox"
                                           type="checkbox"
                                           id="selectDocument{{doc.guid}}"
                                           [(ngModel)]="doc.selected">
                                    <label for="selectDocument{{doc.guid}}">&nbsp;</label>
                                </td>
                                <td class="no-padding-left pt-5 pr-5 pb-5">
                                    {{doc.name}}
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small"
                                                [(ngModel)]="doc.originals">
                                    </touch-spin>
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small"
                                                [(ngModel)]="doc.originalsSheets">
                                    </touch-spin>
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small"
                                                [(ngModel)]="doc.copies">
                                    </touch-spin>
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small"
                                                [(ngModel)]="doc.copiesSheets">
                                        >
                                    </touch-spin>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
                </tbody>
            </ng-container>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="selectDocuments()">
            Выбрать
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            Отмена
        </button>
    </div>
</ng-template>


<!-- модальное окно для выбора заявителя -->
<ng-template #selectApplicantModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations['appeals.documents'].select_applicant}}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="radio-block"
             *ngFor="let applicant of applicantsInComplexAppeal">
            <input type="radio"
                   id="applicant{{applicant.guid}}"
                   class="radio"
                   (click)="selectApplicantForPrintForm(applicant)">
            <label for="applicant{{applicant.guid}}">
                {{applicant.header}}
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="printAfterSelectApplicant()">
            {{localizations.common.select}}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{localizations.common.cancel}}
        </button>
    </div>
</ng-template>


<!-- модальное окно для выбора параметров платежа -->
<ng-template #selectPaymentParamsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations['appeals.documents'].select_payment_params}}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <!-- Выбор платежа -->
        <div  *ngIf="appealService.appealPayments.length > 1">
            <p class="text-semibold mb-10">
                {{localizations['appeals.documents'].select_payment}}:
            </p>
            <div class="radio-block"
                 *ngFor="let payment of appealService.appealPayments">
                <input type="radio"
                       name="paymentType"
                       id="payment{{payment.code}}"
                       class="radio"
                       (click)="selectedPayment = payment">
                <label for="payment{{payment.code}}">
                    {{payment.name}}
                </label>
            </div>
        </div>

        <hr *ngIf="appealService.appealPayments.length > 1 && appeal.objects.length > 1">

        <!-- Выбор плательщика -->
        <div  *ngIf="appeal.objects.length > 1">
            <p class="text-semibold mb-10">
                {{localizations['appeals.documents'].select_payer}}:
            </p>
            <div class="radio-block"
                 *ngFor="let payer of appeal.objects">
                <input id="payer{{payer.guid}}"
                       class="radio"
                       type="radio"
                       (click)="selectedPayer = payer">
                <label for="payer{{payer.guid}}">
                    {{payer.header}}
                </label>
            </div>
        </div>

        <!-- Заполнение суммы -->
        <hr *ngIf="(appealService.appealPayments.length == 1 && appealService.appealPayments[0].amountKop == 0 || selectedPayment && selectedPayment.amountKop == 0) && (appealService.appealPayments.length > 1 || appeal.objects.length > 1)">

        <div *ngIf="appealService.appealPayments.length == 1 && appealService.appealPayments[0].amountKop == 0 || selectedPayment && selectedPayment.amountKop == 0">
            <div class="text-semibold mb-10">
                {{localizations['appeals.documents'].enter_payment_amount}}:
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{localizations.common.roubles}}
                        </label>
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="customPaymentSum.rub"
                               min="0"
                               [textMask]="{mask: rubMask, guide: false}">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{localizations.common.kopecks}}
                        </label>
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="customPaymentSum.kop"
                               [textMask]="{mask: kopMask, guide: false}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="printAfterApplyPaymentParams()">
            {{localizations.common.select}}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{localizations.common.cancel}}
        </button>
    </div>
</ng-template>

<!-- модальное окно для выбора параметров платежа -->
<ng-template #printHtmlFormModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations.common.print}}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{localizations.common.close}}
        </button>
    </div>
</ng-template>
