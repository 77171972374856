import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AccessService, ToasterService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

import { Permission } from '../../../../../common/services/permission';
import { SubjectFormComponent } from '../../../../subjects/subject-form/subject-form.component';

@Component({
    selector: 'envelope-object',
    templateUrl: 'envelope-object.component.html',
})
export class EnvelopeObjectComponent implements OnInit {
    @Input() public envelope: any; // Формируемый запрос
    @Input() public appeal: any; // Дело, в котором был сформирован запрос
    @Output() public onApply = new EventEmitter<any>(); // Изменение параметров объекта

    public subject: any; // обрабатываемый объект запроса
    public mode = 'view'; // Режим редактирования / просмотра
    public isBadgeLink = false; // Отображение ссылки выбора типа субъекта
    public allowEdit = false; // Возможность редактирования
    public permissions = Permission; // Все полномочия системы
    public isProcessValidate = false;
    public editedSubject;

    @ViewChild('editSubject', { static: false }) public editSubjectComponent: SubjectFormComponent;

    public constructor(
        private accessService: AccessService,
        private toaster: ToasterService
    ) {}

    public ngOnInit(): void {
        // Флаг возможности редактирования данных
        this.allowEdit =
            !this.envelope.appealId &&
            ((this.envelope._id && this.accessService.hasAccess([this.permissions.Envelope_Update])) ||
                (!this.envelope._id && this.accessService.hasAccess([this.permissions.Envelope_Create])));

        if (this.envelope.appealId && this.envelope.object && this.appeal) {
            // Если запрос из дела - получаем объект из дела
            this.subject = this.appeal.subjects.find((item) => item.guid === this.envelope.object.guid);
        } else {
            // Запрос не из дела, все настройки объекта хранятся непосредственно в запросе
            this.subject = this.envelope.object;
        }
    }

    public selectSpecialType(event: string): void {
        if (event === 'individualApplicant' || event === 'ipApplicant') {
            this.editedSubject.data.person = {};
            delete this.editedSubject.data.organization;
        }

        if (event === 'ulApplicant') {
            this.editedSubject.data.organization = {};
            delete this.editedSubject.data.person;
        }

        this.editedSubject.specialTypeId = event;
        this.isBadgeLink = false;
        this.mode = 'edit';
    }

    public addSubject(): void {
        this.editedSubject = {
            data: {},
            specialTypeId: null,
        };
        this.mode = 'edit';
        this.isBadgeLink = true;
    }

    public editSubject(): void {
        this.editedSubject = this.envelope.object;
        this.mode = 'edit';
    }

    public removeSubject(): void {
        this.subject = this.envelope.object = null;

        if (this.envelope._id) {
            this.onApply.emit(true);
        }
    }

    public cancel(): void {
        if (!this.envelope.object) {
            this.editedSubject = null;
        }

        this.mode = 'view';
        this.isProcessValidate = false;
    }

    public apply(): void {
        this.isProcessValidate = true;
        if (this.editSubjectComponent && this.editSubjectComponent.isValid()) {
            this.envelope.object = cloneDeep(this.editedSubject);
            this.subject = cloneDeep(this.editedSubject);
            this.mode = 'view';
        } else {
            this.toaster.error('Ошибка заполнения карточки субъекта');
        }
    }

    public changeBadgeLink() {
        this.isBadgeLink = true;
    }

    public getFieldsRequirementsRules() {
        const fieldRequirements = {
            individualApplicant: ['person.firstName', 'person.lastName'],
            ulApplicant: ['organization.name', 'organization.ogrn', 'organization.inn'],
            ipApplicant: ['person.firstName', 'person.lastName', 'person.ogrn'],
        };
        return { andElement: { reqElements: fieldRequirements[this.editedSubject.specialTypeId] } };
    }
}
