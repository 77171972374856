import { Injectable } from '@angular/core';
import { CommonAppealStatusService } from './common-appeal-status.service';
import { Permission } from '../../../common/services/permission';
import { AccessService, RestService } from '@evolenta/core';
import { CommonUtilities, ObjectUtilities } from '@evolenta/utilities';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import clone from 'lodash-es/clone';
import uniq from 'lodash-es/uniq';
import * as moment from 'moment';

@Injectable()
export class CommonAppealSubservicesService {
    public representativeTypes = [
        {
            id: '356001000000',
            name: 'Опекун',
        },
        {
            id: '356002000000',
            name: 'Попечитель',
        },
        {
            id: '356003000000',
            name: 'Законный представитель',
        },
        {
            id: '356004000000',
            name: 'Доверенное лицо',
        },
        {
            id: '356005000000',
            name: 'Уполномоченное лицо',
        },
        {
            id: '356006000000',
            name: 'Организация-опекун/попечитель',
        },
        {
            id: '356099000000',
            name: 'Иное лицо',
        },
    ];

    // Основной массив с настройкой объектов в разрезе привязки к услуге
    public data = {};

    // Копия массива с настройками объектов и услуг (для режима редактирования элементов)
    public tempData = {};

    public entitiesData = {};
    public tempEntitiesData = {};

    // subjectsData = {};
    // tempSubjectsData = {};
    //
    // objectsData = {};
    // tempObjectsData = {};

    // objectsXsd = {};
    //
    // xsdData = {};

    public appeal; // Обрабатываемое дело
    public subservice; // Услуга, на основе которой формируется дело

    public permissions = Permission; // описание всех доступных прав доступа

    // Дефолтные настройки обязательности полей для участников
    public defaultRequiredFields = {
        'individualApplicant': [
            'person.lastName',
            'person.firstName',
            'person.birthday',
            'person.documentType',
            'person.documentNumber',
            'person.documentIssueDate',
            'person.documentIssuer',
            'person.mobile',
        ],
        'ulApplicant': [
            'organization.opf',
            'organization.shortName',
            'organization.name',
            'organization.ogrn',
            'organization.inn',
            'organization.kpp',
        ],
        'ipApplicant': [
            'person.ogrn',
            'person.lastName',
            'person.firstName',
        ],
        'foreignUlApplicant': [
            'person.name',
            'person.inn',
            'person.accreditationNumber',
            'person.accreditationDate',
        ],
    };

    public isProcessingAddSubjectToAppealSubserviceGroup = false; // процесс добавления участника в дело для определенной группы услуги дела
    public processingAddSubjectToAppealSubserviceGroupData = null; // данные для добавления нового участника: услуга и группа

    public constructor(
        private appealStatusService: CommonAppealStatusService,
        private accessService: AccessService,
        private restService: RestService,
    ) {
    }

    /**
     * Очистка данных сервиса
     */
    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.entitiesData = {};
        this.tempEntitiesData = {};
        this.data = {};
        this.tempData = {};
    }

    /**
     * Инициализация данных
     * @param appeal - дело
     * @param subservice - услуга
     */
    public initData(appeal = null, subservice = null) {
        this.appeal = appeal;
        this.subservice = subservice;

        // Информация о субъектах
        this.appeal.subjects.forEach(subject => {
            this.entitiesData[subject.guid] = cloneDeep(subject);
        });

        // Информация об объектах
        this.appeal.objects.forEach(object => {
            this.entitiesData[object.guid] = cloneDeep(object);
        });

        if (!this.data[this.appeal.subservice.guid] && this.subservice._id === this.appeal.subservice.id) {
            this.data[this.appeal.subservice.guid] = {
                number: 1,
                subservice: this.subservice,
                correctVariant: true,
                subjects: {},
                objects: {},
                groups: [],
                agentTypes: [],
            };
            if (this.appeal.subservice.mainId) {
                this.data[this.appeal.subservice.guid].mainId = this.appeal.subservice.mainId;
            }

            // Инициализация actions для услуги дела
            this.correctActions(this.appeal.subservice);

            // Проверка корректности выбора варианта услуги, если в услуге присутствуют варианты
            if (subservice.variants && !this.appeal.subservice.variant) {
                this.data[this.appeal.subservice.guid].correctVariant = false; // не выбран вариант услуги
            }

            // XSD - данные услуги
            if (this.appeal.subservice.xsdData) {
                this.data[this.appeal.subservice.guid].xsdData = this.appeal.subservice.xsdData;
            }

            // XSD - данные варианта услуги
            if (this.appeal.subservice.variantXsdData) {
                this.data[this.appeal.subservice.guid].variantXsdData = this.appeal.subservice.variantXsdData;
            }

            // Вариант выдачи результата услуги
            if (this.appeal.subservice.issueResultForm) {
                this.data[this.appeal.subservice.guid].issueResultForm = this.appeal.subservice.issueResultForm;
            }

            if (this.appeal.subservice.resultType) {
                this.data[this.appeal.subservice.guid].resultType = this.appeal.subservice.resultType;
            }

            if (this.appeal.subservice.issueType) {
                this.data[this.appeal.subservice.guid].issueType = this.appeal.subservice.issueType;
            }

            // Генерация групп объектов для услуг без учета объектов
            this.generateServiceGroups(this.appeal.subservice);

            this.appeal.subjects.forEach(subject => {
                if (!this.data[this.appeal.subservice.guid].subjects[subject.guid]) {
                    this.data[this.appeal.subservice.guid].subjects[subject.guid] = {guid: subject.guid, specialTypeId: subject.specialTypeId};
                }
                // Генерация групп для объекта
                this.generateServiceGroups(this.appeal.subservice, subject);
                this._restoreEntityDataFromAppealSubservice(this.appeal.subservice, subject);
                this.initSubjectFieldsRequirements(subject);
            });

            if (this.appeal.objects) {
                this.appeal.objects.forEach(object => {
                    if (!this.data[this.appeal.subservice.guid].objects[object.guid]) {
                        this.data[this.appeal.subservice.guid].objects[object.guid] = {guid: object.guid, specialTypeId: object.specialTypeId};
                    }

                    this.generateServiceGroups(this.appeal.subservice, object);
                    this._restoreEntityDataFromAppealSubservice(this.appeal.subservice, object);
                });
            }

            this._initRepresentativeDataFromAppealSubservice(this.appeal.subservice);
        }
        // Определение участников дела, которые согласны на опрос
        this.getAppealSubserviceSubjectsNotAgreeMkguInterview(this.appeal.subservice);
    }

    public processingAddEntityFromAppealSubserviceCard(appealSubservice, entity) {
        const field = entity.specialTypeId === 'realty' ? 'objects' : 'subjects';
        if (this.isProcessingAddSubjectToAppealSubserviceGroup
            && this.processingAddSubjectToAppealSubserviceGroupData.appealSubservice.guid === appealSubservice.guid) {
            this.data[appealSubservice.guid][field][entity.guid].active = true;
            this.addEntityPresentInSubservice(appealSubservice, entity);
        }
    }

    /**
     * Получение допустимых видов представителей для услуги
     * @param appealSubservice
     */
    public getSubserviceAgentTypes(appealSubservice) {
        const subservice = this.data[appealSubservice.guid].subservice;
        if (subservice.objects.objectKinds && subservice.objects.objectKinds.length > 0) {
            const agentTypes = subservice.objects.objectKinds[0].subKinds.filter(item => item.type.indexOf('agent') !== -1);
            agentTypes.forEach(type => {
                this.data[appealSubservice.guid].agentTypes.push({
                    name: type.secondGroupName,
                    specialTypeId: type.specialTypeId,
                    headerOptions: type.headerOptions,
                    shortHeaderOptions: type.shortHeaderOptions,
                    type: type.type,
                });
            });
        }
    }

    /**
     * Получение списка участников, согласных на опрос
     * @param appealSubservice - услуга дела
     * @returns {any}
     */
    public getAppealSubserviceSubjectsNotAgreeMkguInterview(appealSubservice) {
        this.data[appealSubservice.guid].notAgreeMkguObjects = [];
        const subjectGuids = [];
        if (this.data[appealSubservice.guid].subjects) {
            Object.keys(this.data[appealSubservice.guid].subjects).forEach(subjectGuid => {
                if (this.data[appealSubservice.guid].subjects[subjectGuid].active) {
                    subjectGuids.push(subjectGuid);
                    if (this.data[appealSubservice.guid].subjects[subjectGuid].representative) {
                        subjectGuids.push(this.data[appealSubservice.guid].subjects[subjectGuid].representative.guid);
                    }
                }
            });
        }
        if (subjectGuids.length > 0) {
            this.data[appealSubservice.guid].notAgreeMkguObjects = this.appeal.objects.filter(item => item.agreeMkgu && item.agreeMkguAnketa && subjectGuids.indexOf(item.guid) !== -1);
        }
    }

    /**
     * Инициализация параметров обязательности полей объекта
     * @param subject
     */
    public initSubjectFieldsRequirements(subject) {
        if (!this.entitiesData[subject.guid]) {
            this.entitiesData[subject.guid] = {};
        }
        this.entitiesData[subject.guid].fieldRequirements = this.calculateSubjectRequiredFields(cloneDeep(subject));
    }

    /**
     * Корректировка набора действий для услуги дела
     * @param appealSubservice - услуга дела
     */
    public correctActions(appealSubservice) {
        if (this.accessService.existPermission(this.permissions.No_Edit_Limits)) {
            // Активно полномочие с полным доступом для редактирования элементов системы
            this.data[appealSubservice.guid].allowEdit = true;
            this.data[appealSubservice.guid].allowEditAfterRegister = true;

            this.data[appealSubservice.guid].allowDelete = false;
        } else {
            // Возможность редактирования данных услуги
            // - услуга не в финальном статусе
            // - есть права доступа на редактирование услуги (если услуга уже сохранена на сервере)
            // - есть права доступа на создание услуги (если услуга еще не была сохранена на сервере)
            this.data[appealSubservice.guid].allowEdit = !(this.appealStatusService.finishStatuses.indexOf(appealSubservice.status.code) !== -1
                || !((appealSubservice.mainId && this.accessService.hasAccess([this.permissions.Appeal_Subservice_Update], true, appealSubservice.status))
                    || (!appealSubservice.mainId && this.accessService.hasAccess([this.permissions.Appeal_Subservice_Create], true, appealSubservice.status)))
                || appealSubservice.status.isSubstatus && appealSubservice.status.mainStatusCode === 'process'
                || !appealSubservice.status.isSubstatus && appealSubservice.status.code === 'process');

            // Настройка доступа для редактирования отдельных элементов дела в случае, если дело находится в активном статусе после регистрации
            if (!this.data[appealSubservice.guid].allowEdit) {
                if (this.accessService.hasAccess([this.permissions.Allow_Edit_Appeal_Data_After_Registration], true, appealSubservice.status)) {
                    this.data[appealSubservice.guid].allowEditAfterRegister = true;
                }
            } else {
                this.data[appealSubservice.guid].allowEditAfterRegister = true;
            }

            this.data[appealSubservice.guid].allowDelete = this.data[appealSubservice.guid].allowEdit
                && this.accessService.hasAccess([this.permissions.Appeal_Subservice_Delete], true, appealSubservice.status)
                && false;
        }

        // Проверяем, является ли услуга зависимой от других услуг
        let allowShowActions = true;
        if (appealSubservice.status.code === 'draft') {
            // если услуга зависимая, проверяем статусы родительских услуг
            if (appealSubservice.startAfterGuids && appealSubservice.startAfterGuids.length > 0) {
                appealSubservice.startAfterGuids.forEach(subserviceGuid => {
                    if (this.appeal.subservice.guid === subserviceGuid && this.appeal.subservice.statusHistory) {
                        // Проверка наличия статуса "На выдачу" в истории статусов родительской услуге
                        const finishStatusesInParentAppealSubservice = this.appeal.subservice.statusHistory.some(item => item.code === 'beforeIssued');
                        if (!finishStatusesInParentAppealSubservice) {
                            allowShowActions = false;
                        }
                    }
                });
            }
        }
        this.data[appealSubservice.guid].actions = allowShowActions ? this.appealStatusService.initAppealSubserviceActions(appealSubservice) : [];
        this.data[appealSubservice.guid].activeAction = null;
        if (this.data[appealSubservice.guid].actions.length) {
            // Активное действие
            this.data[appealSubservice.guid].activeAction = this.data[appealSubservice.guid].actions[0];
        }
    }

    /**
     * Формирует массив доступных групп по услуге с учетом выбранного варианта услуги
     * + группы для определенного объекта
     * @param appealSubservice - услуга дела
     * @param entity - субъект или объект дела
     */
    public generateServiceGroups(appealSubservice, entity = null) {
        const subserviceEntityGroups = [];
        const subservice = this.data[appealSubservice.guid].subservice;
        subservice.objects.objectGroups.forEach(group => {
            if (subservice.variants && appealSubservice.variant && group.variantGuids.indexOf(appealSubservice.variant.guid) !== -1 || !subservice.variants) {
                const groupKinds = [];
                subservice.objects.objectKinds.forEach(kind => {
                    const allowKind = group.objectKindGuids.find(item => {
                        return item.guid === kind.guid;
                    });
                    if (allowKind) {
                        const kindGroup = Object.assign({}, kind);
                        const subKinds = [];
                        kindGroup.subKinds.forEach(item => {
                            if (allowKind.subKindGuids.indexOf(item.guid) !== -1) {
                                subKinds.push(item);
                            }
                        });
                        kindGroup.subKinds = subKinds;
                        groupKinds.push(kindGroup);
                    }
                });
                group.kinds = groupKinds;
                group.subjectCount = 0;
                if (group.kinds.length > 0) {
                    subserviceEntityGroups.push(group);
                }
            }
        });
        // Настройки групп для субъекта
        if (entity) {
            const entityGroups = [];
            subserviceEntityGroups.forEach(group => {
                const filterKinds = group.kinds.filter(item => entity.kind && item.guid === entity.kind.guid);
                if (filterKinds.length > 0) {
                    group.kinds = filterKinds;
                    entityGroups.push(group);
                }
            });
            const entityField = entity.specialTypeId === 'realty' || entity.entityType && entity.entityType === 'objects' ? 'objects' : 'subjects';
            if (this.data[appealSubservice.guid][entityField] && this.data[appealSubservice.guid][entityField][entity.guid]) {
                this.data[appealSubservice.guid][entityField][entity.guid].groups = cloneDeep(entityGroups);
            }
        }
        this.data[appealSubservice.guid].groups = clone(subserviceEntityGroups);
    }

    /**
     * Включение участия объекта в услуге
     * @param appealSubservice
     * @param entity
     */
    public addEntityPresentInSubservice(appealSubservice, entity) {
        this.getAvailableServiceGroups(appealSubservice, entity);
    }

    private _getEntityData(appealSubservice, entity) {
        const field = entity.specialTypeId === 'realty' ? 'objects' : 'subjects';

        return this.data[appealSubservice.guid][field][entity.guid];
    }

    /**
     * Получение списка доступных групп для услуги с учетом выбранного варианта услуги и типа объекта
     * @param appealSubservice - услуга дела
     * @param entity - элемент дела
     * @param onlyInit
     */
    public getAvailableServiceGroups(appealSubservice, entity, onlyInit = false) {
        const data = this._getEntityData(appealSubservice, entity);
        data.specialTypeId = entity.specialTypeId;
        data.guid = entity.guid;
        this.generateServiceGroups(appealSubservice, entity);
        const groups = data.groups;
        console.log('GROUPS', groups);
        if (!onlyInit) {
            // Если группа одна, то автоматически выбираем ее
            if (this.isProcessingAddSubjectToAppealSubserviceGroup) {
                const group = this.processingAddSubjectToAppealSubserviceGroupData.group;
                const findGroupInGroups = groups.find(item => item.guid === group.guid);
                if (findGroupInGroups) {
                    this.selectEntityGroup(appealSubservice, entity, findGroupInGroups);
                }
            } else if (groups.length === 1) {
                this.selectEntityGroup(appealSubservice, entity, groups[0]);
            } else {
                // Очищаем ранее выбранные значения, которые не подходят под новые условия
                data.group = null;
                data.subKinds = null;
                data.otherSubKinds = null;
                data.otherSubKindsInfo = null;
                data.objectKindIndex = null;
                data.subKind = null;
                data.categories = null;
                data.categoriesForSelect = null;
                data.representative = null;

                // включаем режим редактирования групп
                data.editGroup = true;
            }
        }
    }

    /**
     * Обработка отдельного вида участия для определенной группы участников
     * @param data
     * @param group
     * @param type
     */
    public processingSubKind(data, group, type) {
        const subKindType = type + 'SubKind';
        data[subKindType] = data.subKinds.find(item => item.type.indexOf(type) !== -1);
        if (data[subKindType]) {
            data[subKindType].category = data[subKindType].category.filter(item => group.categoryGuids.indexOf(item.guid) !== -1);
            data[subKindType].category.forEach(cat => {
                const find = data.categoriesForSelect.find(item => item.guid === cat.guid);
                if (!find) {
                    data.categoriesForSelect.push(cat);
                }
            });
        }
    }

    public processingObjectSubKind(data, group, entity) {
        data.subKind = data.subKinds.find(item => item.type === entity.kind.subKind.type);
        if (data.subKind) {
            data.usedCategory = data.subKind.category.filter(item => group.categoryGuids.indexOf(item.guid) !== -1);
            data.usedCategory.forEach(cat => {
                const find = data.categoriesForSelect.find(item => item.guid === cat.guid);
                if (!find) {
                    data.categoriesForSelect.push(cat);
                }
            });
        }
    }

    /**
     * Выбор группы участников, переход к режиму выбора категории участника
     * @param appealSubservice - услуга дела
     * @param entity - элемент дела
     * @param group - выбранная группа участников
     * @param isInit - инициализация данных
     */
    public selectEntityGroup(appealSubservice, entity, group, isInit = false) {
        const data = this._getEntityData(appealSubservice, entity);
        data.group = group;
        if (group.xsd) {
            data.groupXsd = group.xsd;
            data.groupXsdRequired = group.xsdLink ? !!group.xsdLink.required : true;
        }
        data.editGroup = false;
        this.calculateEntityKindIndex(data, entity, group.kinds);
        data.subKinds = group.kinds[data.kindIndex].subKinds.filter(item => item.specialTypeId === entity.specialTypeId);

        // Категории участия
        data.categories = []; // выбранные категории
        data.categoriesForSelect = []; // категории для выбора

        if (entity.specialTypeId !== 'realty') {
            // Вид участия - заявитель
            this.processingSubKind(data, group, 'applicant');

            // Вид участия - доверитель
            this.processingSubKind(data, group, 'principal');

            // Вид участия - третье лицо
            this.processingSubKind(data, group, 'third');
        } else {
            this.processingObjectSubKind(data, group, entity);
        }

        if (!isInit) {
            // Определение режима работы с категориями участия: если категория одна, то она автоматически выбирается, в противном случае активируется режим выбора категории участников
            data.editCategory = !this.autoSelectCategories(appealSubservice, entity, data.categoriesForSelect);
        }
    }

    /**
     * Индекс вида участника в возможных видах участника для выбранной группы участников
     * @param data - данные по элементу
     * @param entity - субъект дела
     * @param kinds - массив возможных видов
     */
    public calculateEntityKindIndex(data, entity, kinds) {
        data.kindIndex = kinds.findIndex(item => item.guid === entity.kind.guid);
    }

    /**
     * Автовыбор категории участников
     * @param appealSubservice
     * @param entity
     * @param categoriesForSelect
     * @returns {boolean}
     */
    public autoSelectCategories(appealSubservice, entity, categoriesForSelect) {
        const data = this._getEntityData(appealSubservice, entity);
        let isCompleteSelect = false;
        if (categoriesForSelect.length === 1) {
            data.categories.push({
                guid: categoriesForSelect[0].guid,
                name: categoriesForSelect[0].name,
                xsd: categoriesForSelect[0].xsd,
                xsdLink: categoriesForSelect[0].xsdLink,
            });
            if (categoriesForSelect[0].subCategory && categoriesForSelect[0].subCategory.length > 0) {
                isCompleteSelect = this.autoSelectCategories(appealSubservice, entity, categoriesForSelect[0].subCategory);
            } else {
                isCompleteSelect = true;
            }
        }
        if (isCompleteSelect) {
            this.calculateEntityKind(appealSubservice, entity);
            this.initSubjectFieldsRequirements(data);
            this.selectEntityCategories(appealSubservice, entity);
        }

        return isCompleteSelect;
    }

    /**
     * Выбор категории объекта
     */
    public selectEntityCategories(appealSubservice, entity) {
        const data = this._getEntityData(appealSubservice, entity);
        const beforeXsd = data.xsd;

        let newXsd;
        let newXsdRequired;
        if (data.groupXsd) {
            newXsd = data.groupXsd;
            newXsdRequired = data.groupXsdRequired;
        }
        data.categories.forEach(category => {
            if (category.xsdLink && category.xsdLink.xsd || category.xsd) {
                data.categoryXsd = category.xsdLink ? category.xsdLink.xsd : category.xsd;
                data.categoryXsdRequired = category.xsdLink ? !!category.xsdLink.required : true;
                newXsd = data.categoryXsd;
                newXsdRequired = data.categoryXsdRequired;
            }
        });

        // Если при изменении группы и категории участника изменилось xsd, то удаляем схему JSON-формы и ранее заполненные данные
        if (beforeXsd && beforeXsd !== newXsd) {
            delete data.xsdData;
            delete data.schema;
        }
        data.xsd = null;
        delete data.xsdRequired;
        if (newXsd) {
            data.xsd = newXsd;
            data.xsdRequired = newXsdRequired;
        }
        data.editCategory = false;

        this.calculateEntityKind(appealSubservice, entity);

        // Определяем вид участия по выбранной категории 1-го уровня
        // this.correctExistXsdDataInEntity();
        if (entity.specialTypeId !== 'realty') {
            this.initSubjectFieldsRequirements(data);
        }
    }

    /**
     * Расчет параметров для видов участия
     * @param appealSubservice
     * @param entity
     */
    public calculateEntityKind(appealSubservice, entity) {
        const data = this._getEntityData(appealSubservice, entity);
        data.selectedCategoryForSubKinds = [];
        data.allowApplicant = false;
        data.allowPrincipal = false;
        data.allowThird = false;
        data.allowAgent = false;
        data.requiredAgent = false;
        if (data.applicantSubKind) {
            const findInApplicantCategories = data.applicantSubKind.category.find(item => item.guid === data.categories[0].guid);
            if (findInApplicantCategories) {
                data.allowApplicant = true;
                data.selectedCategoryForSubKinds.push('applicantSubKind');
            }
        }
        if (data.principalSubKind) {
            const findInPrincipalCategories = data.principalSubKind.category.find(item => item.guid === data.categories[0].guid);
            if (findInPrincipalCategories) {
                data.allowPrincipal = true;
                data.selectedCategoryForSubKinds.push('principalSubKind');
            }
        }
        if (data.thirdSubKind) {
            const findInThirdCategories = data.thirdSubKind.category.find(item => item.guid === data.categories[0].guid);
            if (findInThirdCategories) {
                data.allowThird = true;
                data.selectedCategoryForSubKinds.push('thirdSubKind');
            }
        }

        // Одновременно 3 вида участия быть не могут. Либо только 3-е лицо. Либо только заявитель. Либо только доверитель. Либо и заявитель и доверитель
        if (data.selectedCategoryForSubKinds.length === 1) {
            data.subKind = cloneDeep(data[data.selectedCategoryForSubKinds[0]]);
        } else if (data.allowApplicant) {
            data.subKind = cloneDeep(data.applicantSubKind);
        }

        // Если участник является доверителен, обязателен выбор представителя
        if (data.allowPrincipal) {
            data.allowAgent = true;
            if (!data.allowApplicant) {
                data.requiredAgent = true;
            }
        }
    }

    /**
     * Добавление представителя (не выбор, а добавление нового участника в дело, который будет являться представителем)
     * @param appealSubservice
     * @param subject
     */
    public addAgent(appealSubservice, subject) {
        const data = this._getEntityData(appealSubservice, subject);
        data.subKind = cloneDeep(data.principalSubKind);
        const kind = cloneDeep(this.entitiesData[subject.guid].kind); // Обертка для вида участия
        delete kind.subKind;

        // Добавление нового объекта
        const agentGuid = CommonUtilities.GenerateGuid();
        const agent = {
            guid: agentGuid,
            kind: kind,
            data: {person: {}, organization: {}},
            specialTypeId: null,
        };

        data.representative = {guid: agentGuid, type: null, authority: null};
        data.agentParams = data.representative;
        // Добавление информации об объекте в служебный объект
        this.entitiesData[agentGuid] = cloneDeep(agent);

        // Инициализация блока данных объекта в каждой услуге для работы внутри компонентов
        Object.keys(this.data).forEach(key => {
            if (key !== appealSubservice.guid) {
                this.data[key].subjects[agentGuid] = {active: false};
            } else {
                this.data[key].subjects[agentGuid] = {active: true, principals: [{guid: subject.guid, type: null, authority: null}], isAgent: true};
            }
        });

        data.isProcessingAddAgent = true;
        data.isAddingAgent = true;
        if (this.data[appealSubservice.guid].agentTypes && this.data[appealSubservice.guid].agentTypes.length === 1) {
            this.applyAgentSubKind(appealSubservice, subject, this.data[appealSubservice.guid].agentTypes[0]);
        } else {
            const flAgentType = this.data[appealSubservice.guid].agentTypes.find(item => item.specialTypeId === 'individualApplicant');
            if (flAgentType) {
                this.applyAgentSubKind(appealSubservice, subject, this.data[appealSubservice.guid].agentTypes[0]);
            } else {
                data.isProcessingChangeAgentKind = true;
            }
        }
    }

    /**
     * Выбор типа участника-представителя: ФЛ/ЮЛ/ИП
     * @param appealSubservice
     * @param subject
     * @param type
     */
    public applyAgentSubKind(appealSubservice, subject, type) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        const beforeType = this.entitiesData[data.representative.guid].specialTypeId;
        this.entitiesData[data.representative.guid].kind.subKind = type;
        this.entitiesData[data.representative.guid].specialTypeId = type.specialTypeId;
        this.getAvailableServiceGroups(appealSubservice, this.entitiesData[data.representative.guid], true); // определение доступных для выбора групп
        if (!data.isProcessingChangeAgent) {
            data.isProcessingChangeAgentKind = false;
        }
        if (beforeType && type.specialTypeId !== beforeType) {
            this.entitiesData[data.representative.guid].shortHeader = null;
            this.entitiesData[data.representative.guid].header = null;
            this.entitiesData[data.representative.guid].data = {person: {}, organization: {}};
        }
        if (!this.entitiesData[data.representative.guid].shortHeader) {
            data.isProcessingChangeAgentName = true;
        }
    }

    /**
     * Применение заполненного ФИО для ФЛ и ИП / Назыания организации для ЮЛ
     * @param appealSubservice
     * @param subject
     */
    public applyAgentName(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.isProcessingChangeAgentName = false;
        if (!data.representative.type) {
            data.isProcessingChangeAgentType = true;
        } else if (!data.representative.authority) {
            data.isProcessingChangeAgentAuthority = true;
        }
    }

    /**
     * Выбор вида представительства: Доверитель, Законный представитель и т.д.
     * @param appealSubservice
     * @param type - тип
     * @param subject
     */
    public applyAgentType(appealSubservice, subject, type) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.agentParams.type = type;
        if (!data.isProcessingChangeAgent && !data.isProcessingChangePrincipalAgent) {
            data.isProcessingChangeAgentType = false;
            data.isProcessingChangeAgentAuthority = true;
        }
        if (data.isProcessingChangeAgent) {
            // Добавление информации в данные представителя
            const findPrincipal = this.data[appealSubservice.guid].subjects[data.representative.guid].principals.find(item => item.guid === subject.guid);
            findPrincipal.type = type;
        }
        if (data.isProcessingChangePrincipalAgent) {
            this.data[appealSubservice.guid].subjects[data.agentParams.guid].representative.type = type;
        }
    }

    /**
     * Применение параметров документа-основания представителя
     * @param appealSubservice
     * @param subject
     */
    public applyAgentAuthority(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        this.applyAgentAuthorityParams(appealSubservice, subject);
        data.isProcessingChangeAgentAuthority = false;
    }

    /**
     * Перевод в режим редактирования отдельного свойства
     * @param appealSubservice
     * @param subject
     * @param property
     */
    public changeAgentProperty(appealSubservice, subject, property) {
        const allProperties = ['isProcessingChangeAgentKind', 'isProcessingChangeAgentName', 'isProcessingChangeAgentType', 'isProcessingChangeAgentAuthority'];
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        allProperties.forEach(prop => {
            data[prop] = prop === property;
        });
    }

    /**
     * Отмена добавления представителя для участника
     * @param appealSubservice
     * @param subject
     */
    public cancelAddAgent(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        this.deleteAgent(appealSubservice, subject);
        data.isProcessingAddAgent = false;
    }

    /**
     * Применение параметров добавляемого представителя
     * @param appealSubservice
     * @param subject
     */
    public applyAddAgent(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subject[subject.guid];
        data.isProcessingAddAgent = false;
    }

    /**
     * Удаление представителя
     * @param appealSubservice
     * @param subject
     */
    public deleteAgent(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        if (!data.requiredAgent) {
            data.subKind = cloneDeep(data.applicantSubKind);
        }
        const agentGuid = data.representative.guid;
        // если представитель был добавлен но еще не сохранен в деле
        if (data.isAddingAgent) {
            delete this.entitiesData[agentGuid];
        }
        const agentData = this.data[appealSubservice.guid].subjects[agentGuid];
        const principalIndex = agentData.principals.findIndex(item => item.guid === subject.guid);
        agentData.principals.splice(principalIndex, 1);
        if (agentData.principals.length === 0) {
            delete agentData.principals;
            agentData.isAgent = false;
            if (!agentData.group) {
                agentData.active = false;
            }
        }

        delete data.representative;
        delete data.isProcessingChangeAgentKind;
        delete data.isProcessingChangeAgentName;
        delete data.isProcessingChangeAgentType;
        delete data.isProcessingChangeAgentAuthority;
    }

    /**
     * Удаление доверителя
     * @param appealSubservice
     * @param subject
     * @param principal
     */
    public deletePrincipal(appealSubservice, subject, principal) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        const principalData = this.data[appealSubservice.guid].subjects[principal.guid];
        const principalIndex = data.principals.findIndex(item => item.guid === principal.guid);
        data.principals.splice(principalIndex, 1);
        if (data.principals.length === 0) {
            data.isAgent = false;
            delete data.principals;
        }
        delete principalData.representative;
    }

    public selectAgent(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.subKind = cloneDeep(data.principalSubKind);
        const kind = cloneDeep(this.entitiesData[subject.guid].kind); // Обертка для вида участия
        delete kind.subKind;
        data.isProcessSelectAgent = true;
    }

    public applyAgent(appealSubservice, subject, agent) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.representative = {guid: agent.guid};
        data.agentParams = data.representative;
        if (!this.data[appealSubservice.guid].subjects[agent.guid]) {
            this.data[appealSubservice.guid].subjects[agent.guid] = {};
        }
        const agentData = this.data[appealSubservice.guid].subjects[agent.guid];
        agentData.active = true;
        agentData.isAgent = true;
        if (!agentData.principals) {
            agentData.principals = [];
        }
        const findPrincipal = agentData.principals.find(item => item.guid === subject.guid);
        if (!findPrincipal) {
            agentData.principals.push({
                guid: subject.guid,
                type: null,
                authority: null,
            });
        }
        if (!data.isProcessingChangeAgent) {
            data.isProcessSelectAgent = false;
            data.isProcessingChangeAgentType = true;
        }
    }

    public changeAgentPropertyInLinkSubject(appealSubservice, subject, property) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        const linkData = this.data[appealSubservice.guid].subjects[data.agentParams.guid];
        let objectData;
        if (data.isProcessingChangeAgent || data.isProcessingAddAgent || data.representative && data.representative.guid) {
            // данные представителя
            objectData = linkData.principals.find(item => item.guid === subject.guid);
        } else if (data.isProcessingChangePrincipalAgent) {
            objectData = linkData.representative;
        }
        objectData[property] = data.agentParams[property];
    }

    public changeAgent(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.agentParams = data.representative;
        data.isProcessingChangeAgent = true;
        data.isProcessSelectAgent = true;
        data.isProcessingChangeAgentType = true;
        data.isProcessingChangeAgentAuthority = true;
    }

    public applyAgentAuthorityParams(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];

        if (data.agentParams && data.agentParams.isComplexAuthority) {
            const authorityItems = [];
            if (data.agentParams.complexAuthority.name) {
                authorityItems.push(data.agentParams.complexAuthority.name);
            }
            if (data.agentParams.complexAuthority.series) {
                authorityItems.push(data.agentParams.complexAuthority.series);
            }
            if (data.agentParams.complexAuthority.number) {
                authorityItems.push('№' + data.agentParams.complexAuthority.number);
            }
            if (data.agentParams.complexAuthority.issueDate || data.agentParams.complexAuthority.issuer) {
                authorityItems.push('выдан');
                if (data.agentParams.complexAuthority.issueDate) {
                    authorityItems.push((data.agentParams.complexAuthority.issueDate.formatted
                                         ? data.agentParams.complexAuthority.issueDate.formatted
                                         : moment(data.agentParams.complexAuthority.issueDate).format('DD.MM.YYYY')) + ' г.');
                }
                if (data.agentParams.complexAuthority.issuer) {
                    authorityItems.push(data.agentParams.complexAuthority.issuer);
                }
            }
            if (data.agentParams.complexAuthority.beforeDate) {
                authorityItems.push('действителен до ' +
                    (data.agentParams.complexAuthority.beforeDate.formatted
                        ? data.agentParams.complexAuthority.beforeDate.formatted
                        : moment(data.agentParams.complexAuthority.beforeDate).format('DD.MM.YYYY')) + ' г.');
            }
            if (authorityItems.length > 0) {
                data.agentParams.authority = authorityItems.join(' ');
            }
        }
        this.changeAgentPropertyInLinkSubject(appealSubservice, subject, 'authority');
        this.changeAgentPropertyInLinkSubject(appealSubservice, subject, 'isComplexAuthority');
        this.changeAgentPropertyInLinkSubject(appealSubservice, subject, 'complexAuthority');
    }

    public applyAgentChanges(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        this.applyAgentAuthorityParams(appealSubservice, subject);
        data.isProcessingChangeAgent = false;
        data.isProcessSelectAgent = false;
        data.isProcessingChangeAgentType = false;
        data.isProcessingChangeAgentAuthority = false;
        data.isProcessingChangePrincipalAgent = false;
    }

    public changePrincipalAgent(appealSubservice, subject, principal) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.agentParams = principal;
        data.isProcessingChangePrincipalAgent = true;
        data.isProcessingChangeAgentType = true;
        data.isProcessingChangeAgentAuthority = true;
        // console.log('change principal', data);
    }

    public changeAgentAuthorityType(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.agentParams.isComplexAuthority = !data.agentParams.isComplexAuthority;
        if (data.agentParams.isComplexAuthority && !data.agentParams.complexAuthority) {
            data.agentParams.complexAuthority = {};
        }
    }

    public setOnlyAgentPresentInSubservice(appealSubservice, subject) {
        const data = this.data[appealSubservice.guid].subjects[subject.guid];
        data.editGroup = false;
        data.editCategory = false;
        delete data.group;
        delete data.categoriesForSelect;
        delete data.categories;
        delete data.subKinds;
        delete data.subKind;
        delete data.applicantSubKind;
        delete data.principalSubKind;
        delete data.thirdSubKind;
        delete data.groupXsd;
        delete data.selectedCategoryForSubKinds;
        delete data.allowApplicant;
        delete data.allowPrincipal;
        delete data.allowThird;
        delete data.allowAgent;
        delete data.requiredAgent;
        delete data.groupXsdRequired;
        delete data.xsdRequired;
        delete data.xsd;
    }

    /**
     * Инициализация режима выбора группы участия
     * @param data - описательный объект для работы с визуальными составляющими
     */
    public changeEntityGroup(data) {
        data.editGroup = true;
        data.editCategory = false;
        data.editSubKind = false;
    }

    /**
     * Инициализация режима выбора категории объекта
     * @param data - описательный объект для работы с визуальными составляющими
     */
    public changeEntityCategory(data) {
        data.editSubKind = false;
        data.editCategory = true;
        data.editGroup = false;
    }

    /**
     * Удаление параметров участия в услуге (отключение участия)
     * @param appealSubservice
     * @param entity
     */
    public removeEntityPresentInSubservice(appealSubservice, entity) {
        let data = this._getEntityData(appealSubservice, entity);
        if (data.principals && data.principals.length > 0) {
            data.principals.forEach(principal => {
                data.representative = null;
            });
        }
        if (data.representative && data.representative.guid) {
            const findIndex = this.data[appealSubservice.guid].subjects[data.representative.guid].principals.findIndex(item => item.guid === entity.guid);
            if (findIndex !== -1) {
                this.data[appealSubservice.guid].subjects[data.representative.guid].principals.splice(findIndex, 1);
            }
        }
        data = {active: false};
    }

    /**
     * Проверка корректности выбранной группы (категории, вида участия) участников для объекта в деле, в случае измененения варианта услуги
     * @param appealSubservice - услуга в деле
     */
    public correctEntitiesDataAfterChangeAppealSubserviceVariant(appealSubservice) {
        this.correctEntityDataAfterChangeAppealSubserviceVariant(appealSubservice, 'subjects');
        this.correctEntityDataAfterChangeAppealSubserviceVariant(appealSubservice, 'objects');
    }

    public correctEntityDataAfterChangeAppealSubserviceVariant(appealSubservice, type) {
        Object.keys(this.data[appealSubservice.guid][type]).forEach(entityGuid => {
            const entityData = this.data[appealSubservice.guid][type][entityGuid];
            if (entityData.group) {
                let isCorrect = false;
                appealSubservice.variant.selectedVariants.forEach(variant => {
                    if (entityData.group.variantGuids.indexOf(variant.guid) !== -1) {
                        isCorrect = true;
                    }
                });
                // Если выбранная ранее группа не допустима для нового варианта услуги,
                if (!isCorrect) {
                    entityData.active = false; // Выставляем флаг неактивности объекта в услуге (обработка после нажатия на кнопку "Применить")
                }
            }
        });
    }

    private _correctEntityDataInSubserviceOnApply(appealSubservice, entityType, entityGuid) {
        const entityData = this.data[appealSubservice.guid][entityType][entityGuid];

        if (!appealSubservice[entityType]) {
            appealSubservice[entityType] = [];
        }

        const entityDataInAppealIndex = appealSubservice[entityType].findIndex(item => item.guid === entityGuid);
        const dataInAppeal: any = { guid: entityData.guid };

        entityData.isProcessingChangeAgent = false;
        entityData.isProcessingChangeAgentKind = false;
        entityData.isProcessingChangeAgentName = false;
        entityData.isProcessingChangeAgentAuthority = false;
        entityData.isProcessingChangeAgentType = false;
        entityData.isProcessingChangePrincipalAgent = false;

        entityData.isProcessSelectAgent = false;
        entityData.isProcessingAddAgent = false;
        entityData.editGroup = false;
        entityData.editCategory = false;
        entityData.editRepresentative = false;

        // Если включено участие объекта в обрабатываемую услугу
        if (entityData.active) {
            // Группа участников
            if (entityData.group) {
                dataInAppeal.groupGuid = entityData.group.guid;
            }

            // Вид участия
            if (entityData.subKind) {
                dataInAppeal.subKind = {
                    guid: entityData.subKind.guid,
                    firstGroupName: entityData.subKind.firstGroupName,
                    name: entityData.subKind.name,
                    type: entityData.subKind.type,
                };
            }

            // Категория участника
            if (entityData.categories && entityData.categories.length > 0) {
                dataInAppeal.category = {
                    guid: entityData.categories[entityData.categories.length - 1].guid,
                    name: entityData.categories[entityData.categories.length - 1].name,
                    xsd: entityData.categories[entityData.categories.length - 1].xsd,
                    selectedCategories: entityData.categories,
                };
            }

            // Представитель доверителя
            if (entityData.representative && Object.keys(entityData.representative).length > 0) {
                dataInAppeal.representative = {
                    guid: entityData.representative.guid,
                    type: entityData.representative.type,
                    authority: entityData.representative.authority,
                };

                if (entityData.representative.isComplexAuthority) {
                    dataInAppeal.representative.isComplexAuthority = true;
                    dataInAppeal.representative.complexAuthority = entityData.representative.complexAuthority;
                } else {
                    dataInAppeal.representative.isComplexAuthority = false;
                    dataInAppeal.representative.complexAuthority = null;
                }
            }

            if (entityData.xsd) {
                dataInAppeal.xsd = entityData.xsd;
                dataInAppeal.xsdRequired = entityData.xsdRequired;
            }

            if (entityData.xsdData) {
                dataInAppeal.xsdData = entityData.xsdData;
                dataInAppeal.xsdDataValid = entityData.xsdDataValid;
            }

            if (entityData.xsdRootTag) {
                dataInAppeal.xsdRootTag = entityData.xsdRootTag;
            }

            if (entityDataInAppealIndex === -1) {
                appealSubservice[entityType].push(dataInAppeal);
            } else {
                appealSubservice[entityType][entityDataInAppealIndex] = dataInAppeal;
            }
        } else {
            // Очищаем данные в служебном объекте
            this.data[appealSubservice.guid][entityType][entityGuid] = { active: false };
            // Удаляем данные объекта из услуги дела
            if (entityDataInAppealIndex !== -1) {
                appealSubservice[entityType].splice(entityDataInAppealIndex, 1);
            }
        }
    }

    /**
     * Применение изменений параметров настройки объекта в услугах дела
     */
    public correctEntitiesDataInSubserviceOnApply() {
        Object.keys(this.data[this.appeal.subservice.guid].subjects).forEach(subjectGuid => {
            this._correctEntityDataInSubserviceOnApply(this.appeal.subservice, 'subjects', subjectGuid);
        });
        Object.keys(this.data[this.appeal.subservice.guid].objects).forEach(objectGuid => {
            this._correctEntityDataInSubserviceOnApply(this.appeal.subservice, 'objects', objectGuid);
        });
    }

    /**
     * Добавление участника-представителя в состав объектов дела
     * @param subject
     */
    public checkExistAgentInAppeal(subject) {
        Object.keys(this.data).forEach(appealSubserviceGuid => {
            const data = this.data[appealSubserviceGuid].subjects[subject.guid];
            if (data.isAddingAgent && data.representative) {
                this.appeal.subjects.push(cloneDeep(this.entitiesData[data.representative.guid]));
                data.isAddingAgent = false;
            }
        });
    }

    /**
     * Получение данных из временного объекта данных (this.data)
     * @param appealSubservice
     * @param entity
     * @returns {{group: any; subKind: any; category: any; xsd: any}}
     */
    public getTempSubjectDataInAppealSubservice(appealSubservice, entity) {
        const dataInAppeal = {
            groupGuid: null,
            subKind: null,
            category: null,
            xsd: null,
        };
        const entityData = this._getEntityData(appealSubservice, entity);
        if (entityData.active && entityData.group) {
            // Группа участников
            dataInAppeal.groupGuid = entityData.group.guid;

            // Вид участия
            dataInAppeal.subKind = {
                guid: entityData.subKind.guid,
                firstGroupName: entityData.subKind.firstGroupName,
                name: entityData.subKind.name,
                type: entityData.subKind.type,
            };

            // Категория участника
            dataInAppeal.category = {
                guid: entityData.categories[entityData.categories.length - 1].guid,
                name: entityData.categories[entityData.categories.length - 1].name,
                xsd: entityData.categories[entityData.categories.length - 1].xsd,
                selectedCategories: entityData.categories,
            };
        }

        return dataInAppeal;
    }

    /**
     * Проверка изменений в разрезе привязки объекта к услуге (группа, вид участия, категория)
     * @param appealSubservice - услуга дела
     * @param entity - объект дела
     * @returns {boolean}
     */
    public checkChangeEntityDataInAppealSubservice(appealSubservice, entity) {
        const field = entity.specialTypeId === 'realty' ? 'objects' : 'subjects';
        let hasChange = false;
        const dataInAppealSubservice = appealSubservice[field].find(item => item.guid === entity.guid);
        const tempDataInAppealSubservice = this.getTempSubjectDataInAppealSubservice(appealSubservice, entity);
        if (!dataInAppealSubservice && tempDataInAppealSubservice.groupGuid
            || dataInAppealSubservice
            && (dataInAppealSubservice.groupGuid !== tempDataInAppealSubservice.groupGuid
                || !isEqual(dataInAppealSubservice.subKind, tempDataInAppealSubservice.subKind)
                || !isEqual(dataInAppealSubservice.category, tempDataInAppealSubservice.category))) {
            hasChange = true;
        }

        return hasChange;
    }

    /**
     * Восстановление данных в массиве this.data во всех услуга для объекта
     * @param entity
     */
    public _restoreEntityDataFromAppealSubservices(entity) {
        this._restoreEntityDataFromAppealSubservice(this.appeal.subservice, entity);
    }

    /**
     * Восстановление данных в массиве this.data по данным ранее сохраненным в деле
     * @param appealSubservice
     * @param entity
     */
    private _restoreEntityDataFromAppealSubservice(appealSubservice, entity) {
        let entityData;
        const appealField = entity.specialTypeId === 'realty' || entity.entityType && entity.entityType === 'objects' ? 'objects' : 'subjects';
        if (appealSubservice[appealField]) {
            entityData = appealSubservice[appealField].find(item => item.guid === entity.guid);
        }
        const data = this._getEntityData(appealSubservice, entity);
        if (data) {
            if (!entityData) {
                data.active = false;
                delete data.group;
                delete data.categoriesForSelect;
                delete data.categories;
                delete data.subKinds;
                delete data.subKind;
                delete data.subjectKindIndex;
            } else {
                // Флаг активности обрабатываемого объекта в обрабатываемой услуге
                data.active = true;

                // Группа объектов
                if (entityData.groupGuid) {
                    const group = data.groups.find(item => item.guid === entityData.groupGuid);
                    if (group) {
                        this.selectEntityGroup(appealSubservice, entity, group, true);
                    }

                    data.categories = [];
                    if (entityData.category) {
                        data.categories = entityData.category.selectedCategories;
                        this.selectEntityCategories(appealSubservice, entity);
                    }

                    if (entityData.subKind && data.subKinds) {
                        data.subKind = data.subKinds.find(item => item.guid === entityData.subKind.guid);
                    }
                }

                if (entityData.representative) {
                    const representative = this.appeal.subjects.find(item => item.guid === entityData.representative.guid);
                    if (representative) {
                        data.representative = cloneDeep(entityData.representative);
                    }
                }
                if (entityData.xsd) {
                    data.xsd = entityData.xsd;
                    data.xsdRequired = entityData.xsdRequired;
                    if (entityData.xsdData) {
                        data.xsdData = entityData.xsdData;
                    }
                    data.xsdDataValid = !!entityData.xsdDataValid;
                    if (entityData.xsdRootTag) {
                        data.xsdRootTag = entityData.xsdRootTag;
                    }
                }
            }
        }

    }

    private _initRepresentativeDataFromAppealSubservice(appealSubservice) {
        const subjectsData = this.data[appealSubservice.guid].subjects;
        Object.keys(subjectsData).forEach(subjectGuid => {
            const subjectData = subjectsData[subjectGuid];
            if (subjectData.representative) {
                if (!subjectsData[subjectData.representative.guid]) {
                    subjectsData[subjectData.representative.guid] = {
                        principals: [],
                    };
                } else if (!subjectsData[subjectData.representative.guid].principals) {
                    subjectsData[subjectData.representative.guid].principals = [];
                }
                subjectsData[subjectData.representative.guid].active = true;
                subjectsData[subjectData.representative.guid].isAgent = true;
                subjectsData[subjectData.representative.guid].principals.push({
                    guid: subjectGuid,
                    type: subjectData.representative.type,
                    authority: subjectData.representative.authority,
                    isComplexAuthority: !!subjectData.representative.isComplexAuthority,
                    complexAuthority: subjectData.representative.complexAuthority ? subjectData.representative.complexAuthority : null,
                });
            }
        });
    }

    /**
     * Очистка данных сервиса по тем объектам, которые отсутствуют в деле (при удалении, или нажатии кнопки "Отмена")
     */
    public correctSubjectsDataByAppeal(type = 'subjects') {
        if (Object.keys(this.entitiesData).length > 0) {
            Object.keys(this.entitiesData).forEach(entityGuid => {
                const find = this.appeal[type].find(item => item.guid === entityGuid);
                if (!find) {
                    delete this.entitiesData[entityGuid];
                    Object.keys(this.data).forEach(subserviceGuid => {
                        if (this.data[subserviceGuid][type]) {
                            delete this.data[subserviceGuid][type][entityGuid];
                        }
                    });
                }
            });
        }
    }

    // ------------------------------------------------------------------------------------------------ //
    // ---------------------------  УСЛОВНАЯ ОБЯЗАТЕЛЬНОСТЬ ПОЛЕЙ  ------------------------------------ //
    // ------------------------------------------------------------------------------------------------ //

    /**
     * Определение параметров обязательности полей участников
     * @param subject - обрабатываемый объект
     */
    public calculateSubjectRequiredFields(subject) {
        const subjectFieldsRequirements = {}; // настройки обязательности для объекта
        const requiredProperty = {condition: null, required: 'required'}; // Значение обязательного поля по умолчанию
        const subjectData = this.entitiesData[subject.guid]; // данные объекта (вне привязки к услуге - общие)
        Object.keys(this.data).forEach(appealSubserviceGuid => {
            const appealSubservice = this.data[appealSubserviceGuid]; // настройки услуги в деле
            const fieldRequirementsForSubservice = {}; // инициализация условий обязательности для текущей услуги и объекта
            const subservice = appealSubservice.subservice; // описательная услуга
            const subjectSubserviceData = appealSubservice.subjects[subject.guid]; // данные субъекта в услуге дела
            // Если в услуге определены особые условия обязательности
            if (subservice.fieldRequirements) {
                // Если для объекта выбрана категория участника
                if (subjectSubserviceData && subjectSubserviceData.categories && subjectSubserviceData.categories.length > 0) {
                    let requirementsForCategory = null;
                    // Определяем наличие условий обязательности для выбранной категории
                    subjectSubserviceData.categories.forEach(selectedCategory => {
                        const findFieldsRequirementsForCategory = subservice.fieldRequirements.find(item => {
                            return item.categoryGuids && item.categoryGuids.indexOf(selectedCategory.guid) !== -1 && item.kindGuids && item.kindGuids.indexOf(subjectSubserviceData.subKind.guid) !== -1;
                        });
                        if (findFieldsRequirementsForCategory) {
                            requirementsForCategory = findFieldsRequirementsForCategory;
                        }
                    });
                    // в услуге есть особые параметры обязательности для категории услуги
                    if (requirementsForCategory) {
                        this.generateFieldRequirementsStructure(requirementsForCategory.andElement, fieldRequirementsForSubservice);
                    }
                }
            }

            // Если не настроены особые условия обязательности, выставляем значения по умолчанию
            if (Object.keys(fieldRequirementsForSubservice).length === 0 && this.defaultRequiredFields[subject.specialTypeId]) {
                this.defaultRequiredFields[subject.specialTypeId].forEach(field => {
                    fieldRequirementsForSubservice[field] = requiredProperty;
                });
            }
            // Объединяем значения условий обязательности из разных услуг в общее значение
            this.combineFieldsRequirementsByAppealSubservice(fieldRequirementsForSubservice, subjectFieldsRequirements);
        });

        // Расчет значений обязательности на основании расчитанных условий обязательности
        this.calculateFieldsRequirements(subjectFieldsRequirements, subjectData);

        return subjectFieldsRequirements;
    }

    /**
     * Объединение значений обязательности полей участника с данными обязательности по другим услугам
     * @param serviceRequirements
     * @param totalRequirements
     */
    public combineFieldsRequirementsByAppealSubservice(serviceRequirements, totalRequirements) {
        Object.keys(serviceRequirements).forEach(field => {
            if (totalRequirements[field]) {
                if (!serviceRequirements[field].condition || !totalRequirements[field].condition) {
                    // Если хотя бы одно из значений условий обязательности - обязательно - ставим обязательность
                    totalRequirements[field] = {condition: null, required: 'required'};
                } else {
                    // Если на поля определена условная обязательность в обоих случаях
                    if (totalRequirements[field].condition.and) {
                        // если первый элемент в условие and, то просто добавляем дочерним элементом массива
                        totalRequirements[field].condition.and.push(serviceRequirements[field].condition);
                    } else {
                        // иначе объединяем условия разных услуг по принципу and
                        const conditions = [];
                        conditions.push(serviceRequirements[field].condition);
                        conditions.push(totalRequirements[field].condition);
                        totalRequirements[field].condition = {and: conditions};
                    }
                }
            } else {
                totalRequirements[field] = serviceRequirements[field];
            }
        });
    }

    /**
     * Формирование структуры обязательности по структуре обязательности из СПЭР
     * @param requirements - условия обязательности
     * @param {{}} resultFields - финальный объект с настройками обязательности (для работы с ним в СИЭР)
     * @param {any} requirementType - тип обязательности (or, and)
     * @param {any} parentElements - условия с учетом родительских элементов
     */
    public generateFieldRequirementsStructure(requirements, resultFields = {}, requirementType = null, parentElements = null) {
        // Конечные элементы
        const elements = [];
        if (requirements['reqElements']) {
            requirements['reqElements'].forEach(field => {
                elements.push(field); // добавление значений в массив конечных элементов текущего уровня для передачи значений на уровень ниже
                let condition = null;
                let resultCondition;
                if (requirementType) {
                    condition = this.generateConditionForField(requirements, field, requirementType); // определение условий обязательности для конечного элемента начиная с текущего уровня
                    // в случае, если есть родительские элементы
                    if (parentElements) {
                        resultCondition = cloneDeep(parentElements);
                        this.addConditionToLastLevel(resultCondition, condition); // добавление условий для текущего элемента в нижний уровень родительских элементов
                    }
                }
                resultFields[field] = {
                    condition: resultCondition ? resultCondition : condition,
                    required: null,
                };
            });
        }

        // Формирование объекта родительских элементов для передачи в дочерние уровни
        let object = null;
        if (elements && requirementType) {
            object = {};
            object[requirementType] = elements;
            if (parentElements) {
                const resultObject = cloneDeep(parentElements);
                this.addConditionToLastLevel(resultObject, object);
                object = resultObject;
            }
        }

        // OR элементы
        if (requirements['orElements']) {
            requirements['orElements'].forEach(node => {
                this.generateFieldRequirementsStructure(node, resultFields, 'or', object);
            });
        }

        // AND элементы
        if (requirements['andElements']) {
            requirements['andElements'].forEach(node => {
                this.generateFieldRequirementsStructure(node, resultFields, 'and', object);
            });
        }
    }

    /**
     * Добавление условий обязательности на последний уровень вложенности последним элементом
     * @param object
     * @param addedCondition
     */
    public addConditionToLastLevel(object, addedCondition) {
        Object.keys(object).forEach(key => {
            if (Array.isArray(object[key])) {
                let hasObject = false;
                object[key].forEach((childElement, idx) => {
                    if (typeof childElement === 'object') {
                        hasObject = true;
                        this.addConditionToLastLevel(childElement, addedCondition);
                    }
                });
                if (!hasObject) {
                    object[key].push(addedCondition);
                }
            }
        });
    }

    /**
     * Определение условий обязательности для поля начиная с текущего уровня
     * @param node
     * @param checkField
     * @param {any} parentType
     * @returns {any}
     */
    public generateConditionForField(node, checkField, parentType = null) {
        const conditionItems = [];
        Object.keys(node).forEach(key => {
            if (key === 'reqElements') {
                node[key].forEach(field => {
                    if (field !== checkField) {
                        conditionItems.push(field);
                    }
                });
            } else if (key === 'orElements') {
                node[key].forEach(childNode => {
                    // console.log('childItems', );
                    conditionItems.push({or: this.generateConditionForField(childNode, checkField)});
                });
            } else if (key === 'andElements') {
                node[key].forEach(childNode => {
                    conditionItems.push({and: this.generateConditionForField(childNode, checkField)});
                });
            }
        });

        if (parentType) {
            const object = {};
            object[parentType] = conditionItems;

            return object;
        } else {
            return conditionItems;
        }
    }

    /**
     * Расчет значения обязательности поля для каждого обязательного / условно обязательного поля
     * @param requirements - настройки обязательности всех полей для объекта
     * @param subject - данные объекта
     */
    public calculateFieldsRequirements(requirements, subject, getRequirementsFromSaved = false) {
        if (getRequirementsFromSaved) {
            // console.log('CHECK');
            requirements = this.entitiesData[subject.guid].fieldRequirements;
        }
        if (requirements) {
            Object.keys(requirements).forEach(field => {
                this.calculateFieldRequirements(requirements[field], subject);
            });
        }
    }

    /**
     * Вычисление свойства required по определенным ранее условиям обязательности
     * @param requirement - настройки обязательности
     * @param subject
     */
    public calculateFieldRequirements(requirement, subject) {
        if (requirement.condition) {
            const checkCondition = this.calculateRequirementByCondition(requirement.condition, subject);
            requirement.required = checkCondition ? 'not required' : 'conditional required';
        } else {
            requirement.required = true;
        }
    }

    /**
     * Определение обязательности в соответствии с условиями обязательности
     * @param condition
     * @param subject
     * @returns {boolean}
     */
    public calculateRequirementByCondition(condition, subject) {
        let result = false;
        Object.keys(condition).forEach(key => {
            result = key !== 'or';
            condition[key].forEach(item => {
                // Проверка на необходимость проверки следующего звена
                if (key === 'or' && !result || key === 'and' && result) {
                    let itemValue;
                    if (typeof item === 'string') {
                        // конечный элемент
                        itemValue = ObjectUtilities.GetPropertyByPath(subject, 'data.' + item);
                    } else {
                        itemValue = this.calculateRequirementByCondition(item, subject);
                    }
                    if (key === 'or' && itemValue) {
                        result = true;
                    } else if (key === 'and' && !itemValue) {
                        result = false;
                    }
                }

            });
        });

        return result;
    }

    /**
     * Проверка обязательности поля (для отработки в интерфейсе)
     * @param field
     * @param subject
     */
    public checkFieldRequired(field, subject) {
        const subjectFieldsRequirements = subject.fieldRequirements;
        if (field === 'person.birthday') {
            // console.log('!!TEST', cloneDeep(object), objectFieldsRequirements);
        }
        if (subjectFieldsRequirements) {
            if (field in subjectFieldsRequirements) {
                return subjectFieldsRequirements[field].required !== 'not required';
            }
        }

        return false;
    }

    /**
     * Перерасчет параметров обязательности при изменении значений полей
     * @param field - изменяемое поле
     * @param subject
     * @param {boolean} correctFieldRequirement - корректировать зависимые поля
     */
    public correctFieldRequirement(field, subject, correctFieldRequirement = false) {
        const subjectFieldsRequirements = subject.fieldRequirements;
        if (subjectFieldsRequirements) {
            if (field in subjectFieldsRequirements) {
                const fieldRequirements = subjectFieldsRequirements[field];
                this.calculateFieldRequirements(fieldRequirements, subject);
                if (fieldRequirements.condition && correctFieldRequirement) {
                    const dependedFields = this.getDependedFields(fieldRequirements.condition);
                    if (dependedFields.length > 0) {
                        dependedFields.forEach(dependField => {
                            this.correctFieldRequirement(dependField, subject);
                        });
                    }
                }
            }
        }
    }

    /**
     * Получение набора зависимых полей (в условиях обязательности)
     * @param condition
     * @returns {Array}
     */
    public getDependedFields(condition) {
        let elements = [];
        Object.keys(condition).forEach(key => {
            condition[key].forEach(item => {
                if (typeof item === 'string') {
                    elements.push(item);
                } else {
                    elements = elements.concat(this.getDependedFields(item));
                }
            });
        });

        return uniq(elements);
    }

    /**
     * Корректировка потерянных ссылок на удаленные объекты
     */
    public correctSubjectsDataInAppealSubservices() {
        this._correctEntityDataInAppealSubservices(this.appeal.subservice, 'subjects');
        this._correctEntityDataInAppealSubservices(this.appeal.subservice, 'objects');
    }

    private _correctEntityDataInAppealSubservices(appealSubservice, entityType) {
        const entitiesData = this.entitiesData;

        if (appealSubservice[entityType] && appealSubservice[entityType].length > 0) {
            const newData = [];
            let existLostLinks = false;
            appealSubservice[entityType].forEach(entityData => {
                if (entityData.guid) {
                    const find = this.appeal[entityType].find(item => item.guid === entityData.guid);
                    if (find) {
                        newData.push(entityData);
                    } else {
                        existLostLinks = true;
                        delete this.data[appealSubservice.guid][entityType][entityData.guid];
                        delete entitiesData[entityData.guid];
                    }
                }
            });
            if (existLostLinks) {
                appealSubservice[entityType] = newData;
            }
        }
    }

    public initEntitytDataOnAdd(entity, type, active = false) {
        const entityData = type === 'subjects' ? 'subjectsData' : 'objectsData';
        const otherType = type === 'subjects' ? 'objects' : 'subjects';
        // Добавление информации о субъекте в служебный объект
        this.entitiesData[entity.guid] = cloneDeep(entity);

        // Инициализация блока данных объекта в каждой услуге для работы внутри компонентов
        Object.keys(this.data).forEach(key => {
            this.data[key][type][entity.guid] = {active: active};
            this.data[key][type][entity.guid][otherType] = [];
        });
    }

    public getAppealFromRegistry(registry) {
        if (registry.appealEntryId) {
            return this.restService.find(this.appeal.subservice.appealsCollection ? this.appeal.subservice.appealsCollection : 'appeals', registry.appealEntryId);
        }

        return Promise.resolve(null);
    }
}
