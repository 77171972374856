<!-- Шаблон формирования списка вариантов -->
<ng-template let-level="level"
             let-group="group"
             let-allowEdit="allowEdit"
             #recursiveCategories>
    <ng-container *ngFor="let item of group.children">
        <div class="tree-line"
             [ngStyle]="{'padding-left': (level * 30 + 10) + 'px'}">
            <div class="tree-item-toggle-sign">
                <span class="cursor-pointer"
                      *ngIf="item.children && item.children.length > 0"
                      (click)="item.hide = !item.hide">
                    <i class="icon-plus2"
                       *ngIf="item.hide">
                    </i>
                    <i class="icon-minus2"
                       *ngIf="!item.hide">
                    </i>
                </span>
            </div>
            <div class="tree-item-title">
                <span>
                    {{item.name}}
                </span>
            </div>
            <div class="tree-item-buttons text-right pr-10">
                <button class="btn btn-default btn-sxs"
                        [title]="getAddCategoryMessage()"
                        *ngIf="allowEdit"
                        (click)="addCategory(item)">
                    <i class="icon-plus2"></i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="getForCategoryMessage()"
                        (click)="editCategory(item, group)">
                    <i [ngClass]="{
                        'icon-pencil': allowEdit,
                         'icon-eye': !allowEdit
                         }">
                    </i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="getRemoveCategoryMessage()"
                        *ngIf="allowEdit"
                        (click)="deleteCategory(item, group)">
                    <i class="icon-trash"></i>
                </button>
            </div>
        </div>
        <ng-container *ngIf="item.children && item.children.length > 0 && !item.hide">
            <ng-container *ngTemplateOutlet="recursiveCategories; context:{ group: item, level: (level + 1), allowEdit: allowEdit}">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>


<div class="p-20" *ngIf="!editedGroup && !editedCategory">
    <ng-container *ngIf="tabs.length > 1">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of tabs">
                <li [ngClass]="{'active': activeTab == tab.code}"
                    (click)="activeTab = tab.code">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </ng-container>

    <div *ngIf="activeTab === 'objectsInfo'">
        <common-standard-block-with-additional-data [block]="infoActiveData"
                                                    [showBlockTitle]="false"
                                                    [asPanel]="true">
        </common-standard-block-with-additional-data>
    </div>

    <ng-container *ngIf="activeTab == 'groupsAndCategories'">
        <common-standard-block-with-additional-data [block]="groupsActiveData"
                                                    [showBlockTitle]="false"
                                                    [useAdditionalData]="false">
        </common-standard-block-with-additional-data>
        <div *ngIf="groupsActiveData.entity.objectGroups && groupsActiveData.entity.objectGroups.length > 0">
            <ng-container *ngFor="let group of groupsActiveData.entity.objectGroups">
                <div class="panel panel-shadow panel-small">
                    <div class="panel-heading">
                        <h5 class="panel-title pt-10"
                            [ngStyle]="{'max-width': '100%'}">
                            {{ group.name }}
                        </h5>
                        <div class="heading-elements">
                            <div class="heading-elements">
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="getAddCategoryMessage()"
                                        *ngIf="groupsActiveData.allowEdit"
                                        (click)="addCategory(group)">
                                    <i inlineSVG="assets/icon-new-item.svg"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="getOfGroupMessage()"
                                        (click)="editGroup(group)">
                                    <i [inlineSVG]="groupsActiveData.allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        (click)="deleteGroup(group)"
                                        type="button"
                                        *ngIf="groupsActiveData.allowEdit"
                                        [title]="getRemoveGroupMessage()">
                                    <i inlineSVG="assets/icon-trash.svg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body no-padding"
                         *ngIf="group.children && group.children.length > 0">
                        <ng-container *ngTemplateOutlet="recursiveCategories; context:{ group: group, level: 0, allowEdit: groupsActiveData.allowEdit}">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
                *ngIf="groupsActiveData.allowEdit"
                (click)="addGroup()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            {{ getAddGroupMessage() }}
        </button>
    </ng-container>

    <!-- Общие данные для объектов -->
    <ng-container *ngIf="activeTab === 'objectsCommon'">
        <common-standard-objects-common [data]="commonActiveData"
                                        [metaData]="metaData"
                                        (onEditXsd)="editXsd('common', $event)">
        </common-standard-objects-common>
    </ng-container>
</div>

<ng-container *ngIf="editedGroup">
    <common-standard-objects-group-edit [group]="editedGroup"
                                        [allowEdit]="groupsActiveData.allowEdit"
                                        (onSave)="applyGroup()"
                                        (onCancel)="cancelEdit()"
                                        (onEditXsd)="editXsd('group')">
    </common-standard-objects-group-edit>
</ng-container>

<ng-container *ngIf="editedCategory">
    <common-standard-object-category-edit [category]="editedCategory"
                                          [allowEdit]="groupsActiveData.allowEdit"
                                          [activeBlocks]="{
                                            info: infoActiveData,
                                            groups: groupsActiveData
                                          }"
                                          [group]="editedCategoryGroup"
                                          (onSave)="applyCategory()"
                                          (onCancel)="cancelEdit()"
                                          (onEditXsd)="editXsd('category')">
    </common-standard-object-category-edit>
</ng-container>


