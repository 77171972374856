import { Component, ViewChild } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { ModalDirective } from 'ngx-bootstrap';
import { PersonService } from '../../../../../../../common/services/persons.service';

@Component({
    selector: 'reestr-modal',
    templateUrl: 'reestr-modal.component.html',
})
export class ReestrModalComponent {
    @ViewChild('reestrModal', { static: false }) public reestrModal: ModalDirective;
    public findedObjects = [];
    public processedObjects = [];
    public saveOptions: any = {};

    public constructor(
        private personService: PersonService,
        private storage: StorageService,
    ) {
    }

    public show(finded, saveOptions = null) {
        this.processedObjects = [];
        this.findedObjects = finded;
        this.saveOptions = saveOptions;
        this.findedObjects.forEach(item => {
            this.processedObjects[item.object.guid] = {action: 'leave'}; // инициализация действия по умолчанию
        });
        this.reestrModal.show();
    }

    public apply() {
        const promises = [];
        this.findedObjects.forEach(item => {
            let reestrObject = null;
            if (this.processedObjects[item.object.guid].action === 'update') {
                reestrObject = this.processedObjects[item.object.guid].object;
            }
            if (this.processedObjects[item.object.guid].action !== 'leave') {
                promises.push(this.personService.updateObjectInReestr(item.object, reestrObject, this.saveOptions.appeal, false, true));
            }
        });
        // После сохранения данных запускаем продолжение процесса сохранения дела
        Promise.all(promises).then(reestrResolves => {
                reestrResolves.forEach((item: any) => {
                    // Обновляем данные об объекте внутри дела
                    const appealObjectIndex = this.saveOptions.appeal.objects.findIndex(object => object.guid === item.object.guid);
                    this.saveOptions.appeal.objects[appealObjectIndex] = item.object;
                });
                this.storage.setItem('baseAppeal', this.saveOptions.appeal);
                this.reestrModal.hide();
            },
        );
    }

    public close() {
        this.storage.setItem('baseAppeal', this.saveOptions.appeal);
        this.reestrModal.hide();
    }
}
