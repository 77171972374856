import { Injectable } from '@angular/core';
import { RestService, SessionService, ToasterService } from '@evolenta/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../../common/services/config';
import cloneDeep from 'lodash-es/cloneDeep';
import find from 'lodash-es/find';

@Injectable()
export class PrintFormService {
    public static FiltersPanelItems = [
        {
            name: 'Номер',
            filters: [
                {
                    fields: ['auid'],
                    type: 'input',
                    isNumber: true,
                    shortName: 'Номер',
                    placeholder: 'Введите номер ...',
                },
            ],
        },
        {
            name: 'Наименование',
            filters: [
                {
                    fields: ['title', 'docName'],
                    type: 'input',
                    shortName: 'Наименование',
                    placeholder: 'Введите наименование ...',
                },
            ],
        },
    ];

    public printFormId;

    public constructor(
        private http: HttpClient,
        private rest: RestService,
        private session: SessionService,
        private toaster: ToasterService,
    ) {
    }

    public static PrepareFilterPanelData(formats: any[]): any[] {
        const filters: any[] = cloneDeep(PrintFormService.FiltersPanelItems);
        const formatFilter = PrintFormService.GetFilterItem('Формат', 'formType', formats);

        filters.push(formatFilter);

        return filters;
    }

    public static GetFilterItem(name: string, value: string, items: any[]): any {
        return {
            name,
            shortName: name,
            filters: [
                {
                    fields: [value],
                    type: 'checkboxes',
                    operator: 'like',
                    items,
                    countVisible: 5,
                },
            ],
        };
    }

    public static ParseFormListItem(item: any, formats: any[]): any {
        return {
            ...item,
            format: PrintFormService.GetItemFromList(PrintFormService.GetFormFormat(item), formats),
        };
    }

    public static GetItemFromList(value: string, list: any[]): string | null {
        const found = find(list, item => item.code.toLowerCase() === value);

        return found ? found.name : null;
    }

    public static GetFormFormat(printForm: any): string | null {
        if (printForm.formType) {
            const arr = printForm.formType.split('.');

            return arr[arr.length - 1].toLowerCase();
        } else {
            return null;
        }
    }

    public getCustomXslFile(xslFields, fileId = null) {
        if (xslFields.length > 0) {
            let variablesBlock = '';
            let serviceTemplate = '';
            let templatesBlock = '';

            xslFields.forEach(xsl => {
                variablesBlock += xsl.variablesBlock;
                serviceTemplate += xsl.serviceTemplate;
                templatesBlock += xsl.templatesBlock;
            });

            let template = this.getTemplateFile();

            template = template.replace('<!--{VariablesBlock}-->', variablesBlock);
            template = template.replace('<!--{ServiceTemplate}-->', serviceTemplate);
            template = template.replace('<!--{TemplatesBlock}-->', templatesBlock);

            const data = new File([template], 'file.xsl', { type: 'text/xml'});

            return this.upload(data, fileId).then(result => Promise.resolve(result));
        }

        return Promise.resolve(false);
    }

    public getTemplateFile() {
        const xhttp = new XMLHttpRequest();

        xhttp.open('GET', '../assets/template.xsl', false);
        xhttp.send('');

        return xhttp.response;
    }

    public upload(file, fileId?) {
        const uploadUrl = Config.server + Config.app + Config.api + 'storage/upload';

        // Заполнение дополнительных данных дела
        const formData = new FormData();

        formData.append('entryName', 'printForms');
        formData.append('entryId', this.printFormId);
        formData.append('file', file, file.name);

        if (fileId) {
            formData.append('fileId', fileId);
        }

        // Сохранение файла на сервере
        return this.session.check().then((session: any) => {
            // Формирование заголовков запроса
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + session.accessToken,
                    'Accept': '*',
                }),
            };

            return this.http.post(uploadUrl, formData, httpOptions).toPromise().then(
                savedFile => {
                    return Promise.resolve(savedFile);
                },
                () => {
                    return Promise.reject('При сохранении файлов произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!');
                },
            );
        });
    }

    public isValidPrintForm(printForm): boolean {
        if (!printForm.docName) {
            this.toaster.error('Заполните все обязательные поля!');

            return false;
        }

        if (!printForm.templateFile) {
            this.toaster.error('Необходимо прикрепить файл шаблона!');

            return false;
        }

        return true;
    }

    public preparePrintFormForSave(printForm: any, selectedFormat: string): Promise<any> {
        let pf = cloneDeep(printForm);

        const { isNeedReloadCustomXslFile, isNeedReloadTemplateFile } = pf;

        delete pf.isNeedReloadCustomXslFile;
        delete pf.isNeedReloadTemplateFile;

        pf.formType = pf.formType.split('.')[0] + '.' + selectedFormat;
        pf.isGlobal = true;

        // Если редактируем, то просто сохраняем файлы и возвращаем готовый
        // для сохранения

        if (pf['_id']) {
            if (isNeedReloadCustomXslFile || isNeedReloadTemplateFile) {
                this.printFormId = pf['_id'];
                const promisses = [];

                if (isNeedReloadTemplateFile) {
                    promisses.push(this.upload(pf.templateFile));
                } else {
                    promisses.push(null);
                }

                if (isNeedReloadCustomXslFile) {
                    promisses.push(this.getCustomXslFile(pf.xslFields, pf.customXSLFile ? pf.customXSLFile._id : null));
                } else {
                    promisses.push(null);
                }

                return Promise.all(promisses).then(
                    (results: any[]) => {
                        pf.templateFile = results[0] ? results[0] : pf.templateFile;
                        pf.customXSLFile = results[1] ? results[1] : pf.customXSLFile;

                        return Promise.resolve(pf);
                    },
                );
            } else {
                return Promise.resolve(pf);
            }
        } else {

            // Если создаем новую ПФ, то последовательно сначала создаем ПФ
            // Потом загружаем файл шаблона и возвращаем объект для сохранения

            return this.rest.create('printForms', pf).then(
                data => {
                    this.printFormId = data['_id'];

                    return this.upload(pf.templateFile).then(
                        template => {
                            pf = { ...data };
                            pf.templateFile = template;

                            return Promise.resolve(pf);
                        },
                    );
                },
            );
        }
    }
}
