<div [ngClass]="{'panel panel-shadow m-20': mode == 'default'}">
    <div [ngClass]="{'panel-body': mode == 'default'}">
        <fieldset *ngFor="let type of printFormTypes">
            <legend class="text-bold mb-10">{{type.name}}</legend>
            <ng-container *ngIf="getPrintFormByCode(type.code)">
                <print-form-card [printForm]="getPrintFormByCode(type.code)"
                                 [allowEdit]="false"
                                 [allowDelete]="true"
                                 (onDelete)="deletePrintForm(type.code)"></print-form-card>
            </ng-container>

            <button class="btn btn-default mt-10 mb-20"
                    (click)="selectPrintForms(type.code)">
                <span>
                    {{ localizations.requests.select_print_form }}
                </span>
            </button>
        </fieldset>

    </div>
</div>
