<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <button class="btn btn-success btn-main no-border-radius btn-labeled btn-labeled-right"
            type="button"
            [disabled]="isLoading"
            (click)="save()">
        <b>
            <i class="icon-checkmark3"
              [ngClass]="{
                'icon-checkmark3': !isLoading,
                'icon-spinner2 spinner': isLoading
                }">
            </i>
        </b>
        {{ localizations.common.save_big }}
    </button>

    <div class="ml-20 navbar-title">
        <h5>
            <i class="icon-arrow-left52 position-left cursor-pointer"
               (click)="back()"
               [tooltip]="localizations.common.go_back">
            </i>
            <span class="text-semibold"
                  *ngIf="permission?._id">
                {{ localizations.permissions.editing }}
            </span>
            <span class="text-semibold"
                  *ngIf="!permission?._id">
                {{ localizations.permissions.creation }}
            </span>
        </h5>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<div class="page-container">
    <div class="panel panel-main wide panel-flat no-margin no-border-top">
        <div class="panel-body">
            <form name="entity">
                <div class="form-group">
                    <label for="title">
                        {{ localizations.common.name }}
                        <span class="text-danger">*</span>
                    </label>
                    <input id="title"
                           class="form-control"
                           type="text"
                           name="title"
                           [(ngModel)]="permission.title">
                </div>
                <div class="form-group">
                    <label for="code">
                        {{ localizations}}
                        <span class="text-danger">*</span>
                    </label>
                    <input id="code"
                           class="form-control"
                           type="text"
                           name="code"
                           [(ngModel)]="permission.code">
                </div>

                <div class="mt-20">
                    <fieldset>
                        <legend class="text-semibold cursor-pointer">
                            <i class="icon-grid3 text-slate"></i>
                            <span class="ag-large-text">
                                {{ localizations.methods.name }}
                            </span>
                        </legend>
                    </fieldset>
                    <div class="collapse in">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="panel panel-body border-top-info border-top-lg no-padding">
                                    <div class="panel-header mb-10 pt-10 pl-10 pr-10">
                                        <span class="report-block-title">
                                            {{ localizations.methods.list }}
                                        </span>
                                    </div>
                                    <div class="panel-search pl-10 pr-10">
                                        <input class="form-control ng-pristine ng-valid"
                                               type="text"
                                               [placeholder]="localizations.permissions.search_by_reference_dots"
                                               name="searchParamsInput"
                                               (input)="searchParams(searchParamsMethods.value, methods, false)"
                                               #searchParamsMethods
                                        >
                                    </div>
                                    <div class="mt-10" style="height: 400px; overflow-y: auto">
                                        <ul class="list-unstyled no-margin-bottom">
                                            <ng-container *ngFor="let item of methods">
                                                <li class="pb-5 pt-5 pl-10 pr-10 cursor-pointer"
                                                    [ngClass]="{'bg-slate-50' : item.hover}"
                                                    *ngIf="!item.checked && !item.hidden"
                                                    (click)="item.checked = true"
                                                    (mouseenter)="item.hover = true"
                                                    (mouseleave)="item.hover = false">
                                                    {{item.url}}
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="panel panel-body border-top-info border-top-lg no-padding">
                                    <div class="panel-header mb-10 pt-10 pl-10 pr-10">
                                        <span class="report-block-title">
                                            {{ localizations.methods.selected }}
                                        </span>
                                    </div>
                                    <div class="panel-search pl-10 pr-10">
                                        <input class="form-control ng-pristine ng-valid"
                                               type="text"
                                               [placeholder]="localizations.permissions.search_by_reference_dots"
                                               name="searchParamsSelectedInput"
                                               (input)="searchParams(searchParamsSelectedMethods.value, methods, true)"
                                               #searchParamsSelectedMethods>
                                    </div>
                                    <div class="mt-10" style="height: 400px; overflow-y: auto">
                                        <ul class="list-unstyled no-margin-bottom">
                                            <ng-container *ngFor="let item of methods">
                                                <li class="pb-5 pt-5 pl-10 pr-10 cursor-pointer"
                                                    [ngClass]="{'bg-slate-50' : item.hover}"
                                                    *ngIf="item.checked && !item.hidden"
                                                    (click)="item.checked = false"
                                                    (mouseenter)="item.hover = true"
                                                    (mouseleave)="item.hover = false">
                                                    {{item.url}}
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<can-deactivate-modal></can-deactivate-modal>
