import { Injectable } from '@angular/core';

@Injectable()
export class WindowWidth {

    public constructor() {}

    public getWidth() {
        return document.body.clientWidth;
    }

    public getScrollPanelMaxWidth() {
        let width;
        width = this.getWidth() - $('.sidebar-secondary').width() - $('.sidebar-main').width();
        width = width / 2 | 0;

        return width;
    }
}
