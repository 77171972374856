import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { KndKindsResolve } from './knd-kinds/knd-kinds.resolve';
import { KndKindsSectionComponent } from './knd-kinds/knd-kinds-section.component';
import { KndKindsComponent } from './knd-kinds/knd-kinds.component';
import { KndKindComponent } from './knd-kinds/knd-kind.component';
import { KndKindEntityTypesComponent } from './knd-kinds/components/knd-kind-entity-types/knd-kind-entity-types.component';
import { KndKindSubjectsComponent } from './knd-kinds/components/knd-kind-subjects/knd-kind-subjects.component';
import { KndKindPreviewComponent } from './knd-kinds/components/knd-kind-preview/knd-kind-preview.component';

@NgModule({
    imports: [
        SharedComponentsModule,
    ],
    declarations: [
        KndKindsSectionComponent,
        KndKindsComponent,
        KndKindComponent,
        KndKindEntityTypesComponent,
        KndKindSubjectsComponent,
        KndKindPreviewComponent,
    ],
    providers: [
        KndKindsResolve,
    ],
})
export class KndKindsModule {
}
