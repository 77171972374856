<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>
<div class="page-container">
    <div class="panel-main wide">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
            <div class="display-grid grid-template-columns">
                <div class="panel panel-shadow m-20"
                     *ngFor="let reportType of reportTypes">
                    <div class="panel-heading no-border">
                        <div class="panel-title">
                            <h4 class="cursor-pointer no-margin-top"
                                (click)="create(reportType)">
                                {{ reportType.description }}
                            </h4>
                        </div>
                        <div>
                            <!-- TODO нужен ли этот блок? -->
                        </div>
                        <div class="heading-elements">
                            <button class="btn btn-default"
                                    [title]="localizations.common.edit"
                                    (click)="create(reportType)">
                                <b>
                                    <i inlineSVG="assets/icon-edit.svg"></i>
                                </b>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
