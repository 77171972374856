<div class="panel panel-shadow m-20"
     *ngIf="subject && mode === 'view'">
    <div class="panel-heading">
        <h4 class="panel-title">
            <!-- Заголовок субъекта -->
            {{subject?.header ? subject.header : 'Новый субъект'}}
            <div>
                <!-- Бэдж-ссылка на редактирование вида субъекта -->
                <span class="badge badge-info text-uppercase"
                      *ngIf="subject?.specialTypeId"
                      [ngClass]="{'bg-teal-300': subject.specialTypeId === 'ulApplicant',
                                  'bg-info-300': subject.specialTypeId === 'ipApplicant',
                                  'bg-orange-300': subject.specialTypeId === 'individualApplicant'}">
                        {{ subject?.specialTypeId === 'ulApplicant'? 'Юридическое лицо' : subject?.specialTypeId === 'ipApplicant' ? 'Индивидуальный предприниматель' : 'Физическое лицо' }}
                    </span>
            </div>
        </h4>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default"
                    [title]="allowEdit ? 'Редактировать' : 'Просмотр'"
                    (click)="editSubject()">
                <em [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></em>
            </button>
            <button class="btn btn-default"
                    title="Удалить"
                    (click)="removeSubject()"
                    *ngIf="allowEdit">
                <em inlineSVG="assets/icon-trash.svg"></em>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <!-- ФЛ, ЮЛ, ИП - тип: Участник -->
        <subject-preview-card *ngIf="subject?.specialTypeId"
                              [subject]="subject"
                              [mode]="mode"
                              [showName]="false"
                              [isFullCard]="true"
                              [showHeader]="false"
                              [noPadding]="true"
                              [showDocumentData]="true"
                              [fromAppeal]="false"></subject-preview-card>
    </div>
</div>

<button class="btn btn-primary-overline btn-labeled display-flex m-20"
        *ngIf="!editedSubject && !subject && allowEdit"
        (click)="addSubject()">
    Добавить субъект
    <em class="ml-10"
        inlineSVG="assets/icon-plus-round.svg"></em>
</button>

<div class="panel panel-shadow m-20" *ngIf="editedSubject && mode === 'edit'">
    <div class="panel-heading">
        <div class="panel-title">
            <!-- Заголовок объекта -->
            <h4 class="no-margin-top mb-5">{{editedSubject.header ? editedSubject.header : 'Новый субъект'}}</h4>
            <div>
                <span class="badge badge-info text-uppercase mr-10"
                      *ngIf="editedSubject?.kind">Субъект</span>

                <span class="badge badge-info text-uppercase cursor-pointer"
                      (click)="changeBadgeLink()"
                      [ngClass]="{'bg-teal-300': editedSubject?.specialTypeId === 'ulApplicant',
                                  'bg-info-300': editedSubject?.specialTypeId === 'ipApplicant',
                                  'bg-orange-300': editedSubject?.specialTypeId === 'individualApplicant'}">
                    {{ editedSubject?.specialTypeId === 'ulApplicant'? 'Юридическое лицо' : editedSubject?.specialTypeId === 'ipApplicant' ? 'Индивидуальный предприниматель' : 'Физическое лицо' }}
                </span>
            </div>
        </div>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn text-uppercase"
                    *ngIf="allowEdit"
                    (click)="apply()">Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase"
                    (click)="cancel()">{{ allowEdit ? 'Отменить' : 'Закрыть' }}
            </button>
        </div>
    </div>
    <div class="panel-body no-padding no-margin-bottom">

        <!-- Выбор типа субъекта -->
        <badge-link *ngIf="isBadgeLink"
                    [object]="editedSubject"
                    (onChangeSubKind)="selectSpecialType($event)"></badge-link>
        <!-- Форма данных -->
        <subject-form
            *ngIf="editedSubject && mode === 'edit' && !isBadgeLink"
            [user]="editedSubject"
            [appealMode]="false"
            [showInside]="true"
            [isProcessValidate]="isProcessValidate"
            [requirementsRules]="getFieldsRequirementsRules()"
            #editSubject
        ></subject-form>
    </div>
</div>
