import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityRaidWarning } from './models/appeal-entity-raid-warning';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash-es';
import { ToasterService } from '@evolenta/core';

@Component({
    selector: 'appeal-entity-raid-warning',
    templateUrl: 'appeal-entity-raid-warning.component.html',
})
export class AppealEntityRaidWarningComponent implements OnInit {
    @Input() public entity: AppealEntityRaidWarning;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;
    public employee = {};
    public requirements = {};

    public constructor(private toaster: ToasterService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.date) {
            this.entity.date = '';
        }
        if (!this.entity.deadline) {
            this.entity.deadline = '';
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidEmployee = this.validateEmployee();
        const isValidRequirements = this.validateRequirements();

        return isValidForm && isValidEmployee && isValidRequirements;
    }

    public validateEmployee(): boolean {
        const isValidName = !!_.get(this.entity.employee, 'name.0.id', null);
        const isValidPosition = !!_.get(this.entity.employee, 'position', null);

        const isValid = isValidName && isValidPosition;

        this.employee = isValid
            ? { value: this.entity.employee }
            : {
                  value: this.entity.employee,
                  errors: { nameRequired: !isValidName, positionRequired: !isValidPosition },
              };

        return isValid;
    }

    public validateRequirements(): boolean {
        const isValid = this.entity.requirements.every(
            (r) => !!_.get(r, 'mandatoryReq', null) && !!_.get(r, 'npa', null)
        );

        this.requirements = isValid
            ? { value: this.entity.requirements }
            : { value: this.entity.requirements, errors: { required: true } };

        return isValid;
    }
}
