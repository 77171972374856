<div class="p-20"
     *ngIf="!isEditSubTask">
    <div class="alert alert-warning alert-styled-left alert-bordered"
         *ngIf="!task.subTasks || task.subTasks.length === 0">
        <span class="text-semibold">
            {{ localizations.common.attention }}
        </span>
        {{ localizations['tasks.subtasks'].not_added }}
    </div>
    <ng-container *ngIf="task.subTasks && task.subTasks.length > 0">
        <ng-container *ngFor="let item of task.subTasks">
            <sub-tasks-card [usersForSelect]="usersForSelect"
                            [subTask]="item"
                            (onEdit)="editSubTask($event)"
                            (onRemove)="deleteSubTask($event)">
            </sub-tasks-card>
        </ng-container>
    </ng-container>
    <button class="btn btn-primary-overline btn-labeled btn-labeled-right"
            (click)="addSubTask()">
        <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
        {{ localizations['tasks.subtasks'].add }}
    </button>
</div>

<div *ngIf="isEditSubTask">
    <div class="panel panel-shadow m-20">
        <div class="panel-heading">
            <h4 class="panel-title">
                {{ localizations['tasks.subtasks'].entity }}
            </h4>
            <!-- Управляющие кнопки -->
            <div class="heading-elements">
                <button class="btn btn-success-overline heading-btn btn-labeled"
                        (click)="apply()">
                    {{ localizations.common.apply }}
                </button>
                <button class="btn btn-danger-overline heading-btn btn-labeled"
                        (click)="cancel()">
                    {{ localizations.common.cancel }}
                </button>
            </div>
        </div>
        <div>
            <task-form [task]="subTask"
                       [appeal]="null"
                       [usersForSelect]="usersForSelect"
                       [candidateGroups]="candidateGroups"
                       [camundaTask]="camundaTask">
            </task-form>
        </div>
    </div>
</div>
