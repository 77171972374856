import { NgModule } from '@angular/core';
import { ReportsRouting } from './reports.routing';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { ReportsComponent } from './reports/reports.component';
import { ReportsResolve } from './reports/reports.resolve';
import { ReportPreviewComponent } from './reports/report-preview/report-preview.component';
import { ReportComponent } from './reports/report/report.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ReportsDataSource } from '../../modules/reports/reports.datasource';
import { PeriodPipe } from './period.pipe';
import { EntityRegistersModule } from '../../modules/entity-registers/entity-registers.module';
import { RegistersComponent } from './reports/registers/registers.component';
import { ReportFieldsComponent } from './reports/registers/components/report-fields/report-fields.component';
import { ReportsService } from './reports/registers/reports.service';
import { ReportOrganizationsComponent } from './reports/registers/components/report-organizations/report-organizations.component';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';
import { ReportsOctonicaComponent } from './reports/octonica/reports-octonica.component';
import { ReportsOctonicaResolve } from './reports/octonica/reports-octonica.resolve';
import { ReportsOctonicaService } from './reports/octonica/reports-octonica.service';

@NgModule({
    imports: [
        ReportsRouting,
        SharedComponentsModule,
        InlineSVGModule,
        EntityRegistersModule,
        BreadcrumbsModule,
    ],
    declarations: [
        ReportsComponent,
        ReportPreviewComponent,
        ReportComponent,
        PeriodPipe,
        RegistersComponent,
        ReportsOctonicaComponent,
        ReportFieldsComponent,
        ReportOrganizationsComponent,

    ],
    providers: [
        ReportsResolve,
        ReportsDataSource,
        ReportsService,
        ReportsOctonicaResolve,
        ReportsOctonicaService,
    ],
})

export class ReportsModule {
}
