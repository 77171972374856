<div class="p-20">
    <ng-container *ngIf="tabs.length > 1">
        <ul class="info-row no-margin mb-20">
            <ng-container *ngFor="let tab of tabs">
                <li [ngClass]="{'active': activeTab == tab.code}"
                    (click)="activeTab = tab.code">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </ng-container>

    <ng-container *ngIf="activeTab == 'responsibleOrganizations'">
        <table class="table table-xs">
            <thead>
            <tr>
                <th class="text-size-small text-bold">
                    {{ localizations.standards.organization_name }}
                </th>
                <th class="text-size-small text-bold"
                    *ngIf="standard.status && standard.status.code === 'approved'">
                    {{ localizations.standards.reglament_status }}
                </th>
                <th class="text-right">
                    <button class="btn btn-default btn-sxs"
                            *ngIf="standardsService.allowEditStandard"
                            (click)="deleteOrganizations()">
                        <i class="icon-trash"></i>
                    </button>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let organization of standard.responsibleOrganizations">
                <td>
                    {{organization.name}}
                </td>
                <td *ngIf="standard.status && standard.status.code === 'approved'">
                    <span class="badge bg-{{getReglamentStatusProperty(organization, 'theme')}}-300 text-uppercase">
                        {{getReglamentStatusProperty(organization, 'name')}}
                    </span>
                </td>
                <td class="text-right">
                    <button class="btn btn-default btn-sxs"
                            *ngIf="organization.reglamentId"
                            (click)="goToReglament(organization.reglamentId)">
                        {{ localizations.common.proceed }}
                    </button>
                    <button class="btn btn-default btn-sxs"
                            *ngIf="standardsService.allowEditStandard"
                            (click)="deleteOrganization(organization)">
                        <i class="icon-trash"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="mt-15">
            <button type="button"
                    class="btn btn-default btn-lg"
                    *ngIf="standardsService.allowEditStandard"
                    (click)="selectOrganizations()">
                {{ localizations.requests.select_organizations }}
            </button>
            <button class="btn btn-default btn-lg ml-15"
                    type="button"
                    *ngIf="standardsService.allowEditStandard && standard.common && standard.common.externalNumber"
                    (click)="updateResponsibleOrganizationsByServiceCodes()">
                {{ localizations.standards.renew_organizations_list }}
            </button>
            <ng-container *ngIf="organizationTypes && organizationTypes.length > 0">
                <button class="btn btn-default btn-lg ml-15"
                        type="button"
                        *ngFor="let type of organizationTypes"
                        (click)="selectOrganizationsByType(type.code)">
                    {{ getOrganizationType(type.name) }}
                </button>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngFor="let tab of organizationTabs">
        <ng-container *ngIf="activeTab === tab">
            <table class="table table-xs">
                <thead>
                <tr>
                    <th class="text-size-small text-bold">
                        {{ localizations.standards.organization_name }}
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let organization of standard[tab]">
                    <td>
                        {{organization.name}}
                    </td>
                    <td class="text-right">
                        <button class="btn btn-default btn-xs"
                                (click)="deleteOrganization(organization)">
                            <i class="icon-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <button class="btn btn-default mt-15"
                    type="button"
                    *ngIf="standardsService.allowEditStandard || standardsService.reglament && standardsService.allowEditReglament"
                    (click)="selectOrganizations()">
                {{ localizations.requests.select_organizations }}
            </button>
        </ng-container>
    </ng-container>
</div>
