import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityVersionsService, ToasterService } from '@evolenta/core';

@Component({
    selector: 'nsi-dictionary-element-versions',
    templateUrl: 'nsi-dictionary-element-versions.component.html',
    styles: [
        '.version-block .go-to-version-button { display: none; }',
        '.version-block:hover .go-to-version-button { display: inline; }',
    ],
})
export class NsiDictionaryElementVersionsComponent implements OnInit {
    @Input() public element;
    @Input() public collection;

    public currentPage;
    public totalItems;
    public totalPages;
    public versions;

    public moduleBaseUrl;
    public nsiGroupPath;

    public constructor(
        private entityVersionsService: EntityVersionsService,
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService
    ) {}

    public ngOnInit() {
        this.route.url.subscribe((urlPath) => {
            this.nsiGroupPath = urlPath[0].path;
        });
        this.route.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.init();
    }

    public init() {
        this.currentPage = 0;
        this.totalItems = 0;
        this.totalPages = 0;
        this.versions = [];
        this.loadData(0);
    }

    public loadData(page = this.currentPage + 1) {
        if ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages) {
            const projection = {
                _id: 1,
                dateLastModification: 1,
                userLastModification: 1,
                version: 1,
            };
            const elementId = this.element.mainId || this.element._id;
            this.entityVersionsService
                .search(this.collection, elementId, 20, page, encodeURI(JSON.stringify(projection)))
                .then((versions: any) => {
                    versions.forEach((item) => {
                        this.versions.push(item);
                    });
                    this.totalItems = this.entityVersionsService.pagination.total;
                    this.currentPage = this.entityVersionsService.pagination.page;
                    this.totalPages = this.entityVersionsService.pagination.totalPages;
                });
        }
    }

    public goToVersion(version) {
        const elementId = this.element.mainId || this.element._id;
        this.router.navigate([this.moduleBaseUrl, this.nsiGroupPath, this.collection, 'edit', elementId, version._id]);
    }

    public goToCurrentVersion() {
        const elementId = this.element.mainId || this.element._id;
        this.router
            .navigate([this.moduleBaseUrl, this.nsiGroupPath, this.collection, 'edit', elementId])
            .then((result) => {
                if (!result) {
                    this.router
                        .navigateByUrl(`${this.moduleBaseUrl}/${this.nsiGroupPath}/${this.collection}`, {
                            skipLocationChange: true,
                        })
                        .then(() =>
                            this.router.navigate([
                                this.moduleBaseUrl,
                                this.nsiGroupPath,
                                this.collection,
                                'edit',
                                elementId,
                            ])
                        );
                }
            });
    }

    public restoreVersion(version) {
        this.entityVersionsService.restore(this.collection, version._id).then(
            () => {
                this.toaster.success('Версия восстановлена');
                this.goToCurrentVersion();
            },
            (error) => {
                console.log(error);
                this.toaster.error(error);
            }
        );
    }
}
