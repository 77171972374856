<!-- Краткая информация о данных заявления -->
<div class="panel panel-shadow" *ngIf="showHeader">
    <div class="panel-body no-padding">
        <div class="p-20">
            <ng-container *ngIf="registersModel">
                <p class="text-slate text-uppercase text-bold"
                   *ngIf="appealSubservice.mainElement && appealSubservice.mainElement.registryEntryType">
                    {{appealSubservice.mainElement.registryEntryType.name}}
                    <span class="badge bg-{{getOperationTypeProperty('theme')}}-300 ml-10">{{getOperationTypeProperty('name')}}</span>
                </p>
                <p class="no-margin-bottom">
                    <ng-container *ngIf="registersModel.selectMultipleLicenses && mainElement && mainElement.licenses">
                        {{licensesTitles.plural}}
                        <span class="badge bg-info-300 ml-10 text-uppercase">{{mainElement.licenses.length}}</span>
                    </ng-container>

                    <ng-container *ngIf="!registersModel.selectMultipleLicenses">
                        <span class="text-bold">
                        {{licensesTitles.single}} №
                        <ng-container *ngIf="mainElement && mainElement.license">
                            {{mainElement.license.number}} от {{mainElement.license.date | date: 'shortDate'}} г.
                        </ng-container>
                        <ng-container *ngIf="!mainElement || !mainElement.license">n/a</ng-container>
                    </span>
                        <span class="anchor text-info text-size-small ml-10"
                              *ngIf="mainElement && mainElement.licenseId" (click)="goToLicense()">перейти</span>
                    </ng-container>
                    <ng-container *ngIf="appeal.subjects.length > 0">
                        <span class="text-muted ml-10">
                            {{subservice.objects.subjectsData && subservice.objects.subjectsData.titles
                            ? (appeal.subjects.length === 1 ? subservice.objects.subjectsData.titles.single : subservice.objects.subjectsData.titles.plural)
                            : (appeal.subjects.length === 1 ? 'Лицензиат' : 'Лицензиаты')}}: </span>
                        <ng-container *ngFor="let subject of appeal.subjects; let lst = last; let idx = index">
                            <ng-container *ngIf="idx < 5">
                                {{subject.header}}<ng-container *ngIf="!lst && idx < 4">; </ng-container>
                            </ng-container>
                        </ng-container>
                        <span class="text-muted ml-5" *ngIf="appeal.subjects.length > 5"> и еще {{appeal.subjects.length - 5}}</span>

                    </ng-container>
                </p>
            </ng-container>

            <ng-container *ngIf="!registersModel">
                <p class="text-slate text-uppercase text-bold" [ngClass]="{'no-margin-bottom': appeal.subjects.length == 0 && appeal.objects.length == 0}">
                    {{appealSubservice.title}}
                </p>
                <ng-container *ngIf="appeal.subjects.length > 0">
                        <span class="text-muted">
                            {{subservice.objects.subjectsData && subservice.objects.subjectsData.titles
                            ? (appeal.subjects.length === 1 ? subservice.objects.subjectsData.titles.single : subservice.objects.subjectsData.titles.plural)
                            : (appeal.subjects.length === 1 ? 'Субъект' : 'Субъекты')}}: </span>
                    <ng-container *ngFor="let subject of appeal.subjects; let lst = last">{{subject.header}}<ng-container *ngIf="!lst">, </ng-container></ng-container>
                </ng-container>
                <ng-container *ngIf="appeal.objects.length > 0">
                        <span class="text-muted" [ngClass]="{'ml-10': appeal.subjects.length > 0}">
                            {{subservice.objects.objectsData && subservice.objects.objectsData.titles
                            ? (appeal.objects.length === 1 ? subservice.objects.objectsData.titles.single : subservice.objects.objectsData.titles.plural)
                            : (appeal.objects.length === 1 ? 'Объект проверки' : 'Объекты проверки')}}: </span>
                    <ng-container *ngFor="let object of appeal.objects; let lst = last">
                        {{object.name}}
                        <ng-container *ngIf="object.address && object.address.fullAddress"><span class="text-muted">Адрес: </span>{{object.address.fullAddress}}</ng-container>
                        <ng-container *ngIf="!lst">, </ng-container></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
