<div class="p-20">
    <ng-container *ngIf="subservice.xsd">
        <dynamic-form [xsd]="subservice.xsd"
                      [model]="data.xsdData"
                      [externalData]="externalData"
                      [disabled]="disabled"
                      #subserviceDataComponent>
        </dynamic-form>
    </ng-container>
    <ng-container *ngIf="appealSubservice.variantXsd">
        <dynamic-form [xsd]="appealSubservice.variantXsd"
                      [variantAndParentXsds]="appealSubservice.variantAndParentXsds"
                      [model]="data.variantXsdData"
                      [externalData]="externalData"
                      [edit]="false"
                      [disabled]="disabled"
                      #variantDataComponent>
        </dynamic-form>
    </ng-container>
</div>

