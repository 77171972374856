<!-- Редактирование данных выбранной услуги в деле -->
<ng-container *ngIf="editAppealSubservice">
    <common-appeal-subservice-card [appealSubservice]="editAppealSubservice"
                            [subjects]="appeal.subjects"
                            [activeTab]="activeTab"
                            mode="edit"
                            (onApply)="onApplyAppealSubservice($event)"
                            #editCard>
    </common-appeal-subservice-card>
</ng-container>

<!-- Список услуг дела -->
<div class="pt-20 pr-20 pl-20" *ngIf="!editAppealSubservice && !isEditAppealSettings">
    <common-appeal-subservice-card [appealSubservice]="appeal.subservice"
                                   [subjects]="appeal.subjects"
                                   mode="view"
                                   (onEdit)="onEditAppealSubservice($event)"
                                   (onDelete)="deleteSubservice($event)"
                                   (onExecuteAction)="executeAction($event)"
                                   (initEdit)="initEditAppealSubservice($event)">
    </common-appeal-subservice-card>
</div>

<ng-container *ngIf="appeal.erpEnvelopesInfo && appeal.erpEnvelopesInfo.length > 0">
    <!-- Статус публикации КНМ в ЕРП -->
    <common-appeal-publish-erp-info [appeal]="appeal"></common-appeal-publish-erp-info>
    <!-- Информация об отправке КНМ в ЕРП -->
    <common-appeal-send-erp-info [appeal]="appeal"></common-appeal-send-erp-info>
</ng-container>

<modal-dialog (onSelect)="processingModal($event)"
              #alertModal>

</modal-dialog>
