import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealEntityNotification } from './models/appeal-entity-notification';
import { AppealData } from '../../../../appeal.data';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-notification',
    templateUrl: 'appeal-entity-notification.component.html',
})
export class AppealEntityNotificationComponent implements OnInit {
    @Input() public entity: AppealEntityNotification;
    @Input() public mode;
    @Input() public appeal;
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public isCheckPlan = false;
    public isCheckUnplan = false;

    public currentUser = this.storage.getItem('user');
    public entityName = AppealData.entityTypes.notification.name;
    public localizations;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals', 'events']).subscribe((res: any) => {
            this.localizations = res;
            // Внеплановая проверка
            this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
            // Плановая проверка
            this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);

            const isDemoStend = this.storage.getItem('demoStend').isDemo;
            if (isDemoStend) {
                this._initDemo();
            } else {
                this.init();
            }
        });
    }

    private _initDemo() {
        if (!this.entity.place) {
            this.entity.place = this.appealEntitiesService.findObjectAddress(this.appeal);
        }
        if (!_.get(this.entity, 'materials.0.text', null)) {
            this.entity.materials = [
                {
                    guid: CommonUtilities.GenerateGuid(),
                    text: this.localizations.appeals.checkup_order,
                },
            ];
        }
        if (!this.entity.requestedDocumentDate) {
            this.entity.requestedDocumentDate = this.appeal.dateRegister;
        }
        if (!_.get(this.entity, 'employee.name.0.id', null)) {
            this.entity.employee = this.getСurrentUser();
        }
    }

    public init() {
        if (!this.entity.materials) {
            this.entity.materials = [];
        }
        if (!this.entity.requestedDocumentDate) {
            this.entity.requestedDocumentDate = '';
        }
        if (!_.get(this.entity, 'employee.name.0.id', null)) {
            this.entity.employee = this.getСurrentUser();
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public getСurrentUser() {
        return {
            guid: CommonUtilities.GenerateGuid(),
            name: [
                {
                    id: this.currentUser.id || '',
                    text: this.currentUser.name || '',
                },
            ],
            position: this.currentUser.position || '',
        };
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }

    public userNameTransform(user) {
        return user.position ? `${user.name} - ${user.position}` : user.name;
    }
}
