import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilesService, RestService, StorageService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'print-form-card',
    templateUrl: 'print-form-card.component.html',
})
export class PrintFormCardComponent implements OnInit {
    @Input() public printForm;
    @Input() public allowEdit = true;
    @Input() public allowDelete = true;

    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<any>();

    public printFormTypes;
    public localizations;

    public constructor(
        private storage: StorageService,
        private restService: RestService,
        private filesService: FilesService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this.printFormTypes = this.storage.getItem('printFormTypes');

        if (!this.printFormTypes) {
            this.restService.search('cataloguePrintFormKinds')
                .then(data => {
                    this.printFormTypes = data;
                    this.storage.setItem('printFormTypes', data);
                });
        }
    }

    private _loadTranslations(): void {
        this.translate.get(
            [
                'common',
                'print-forms',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public editPrintForm(): void {
        this.onEdit.emit(this.printForm);
    }

    public deletePrintForm(): void {
        this.onDelete.emit(this.printForm);
    }

    public getFormType(): string | null {
        const arr = this.printForm.formType.split('.');

        return this.printFormTypes ? this.printFormTypes.find(item => item.code === arr[1]).name : null;
    }

    public downloadFile(id: string, originalName: string): void {
        this.filesService.downloadAndSaveFile(id, originalName);
    }
}
