import { Component, OnInit, ViewChild, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonAppealCommonComponent } from '../appeal-common/common-appeal-common.component';
import { CommonAppealService } from '../../services/common-appeal.service';
import { SelectionService } from '@evolenta/core';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonAppealSubjectsComponent } from '../appeal-subjects/common-appeal-subjects.component';
import { CommonAppealObjectsComponent } from '../appeal-objects/common-appeal-objects.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonAppealDocumentsComponent } from '../appeal-documents/common-appeal-documents.component';

@Component({
    selector: 'common-appeal-blocks',
    templateUrl: 'common-appeal-blocks.component.html',
})
export class CommonAppealBlocksComponent implements OnInit {
    @Input() public appeal;
    @Input() public subservice;
    @Input() public metaReglament;
    @Input() public tabs = [];
    @Input() public workMode = 'default'; // режим работы
    @Input() public activeTab; // активная вкладка дела
    @Input() public processData;

    public activeBlock = 'common';
    public appealSubservice;

    public blocks = [];
    public moduleBaseUrl;

    public isEditSubjectSpecialData = false;

    public licensesTitles = {
        single: 'Лицензия',
        plural: 'Лицензии',
        prevSingle: 'Предыдущая лицензия',
    };

    public editedEntity;
    public editedEntityType;

    public processingAction;
    public nextTab;

    public allowEdit = true;

    public blockWithErrors;
    public modalRef: BsModalRef;
    @ViewChild('errorsListModal', { static: false }) public errorsListModal: TemplateRef<any>;

    @ViewChild('common', { static: false }) public commonBlock: CommonAppealCommonComponent;
    @ViewChild('appealSubjects', { static: false }) public appealSubjectsBlock: CommonAppealSubjectsComponent;
    @ViewChild('appealObjects', { static: false }) public appealObjectsBlock: CommonAppealObjectsComponent;
    @ViewChild('documents', { static: false }) public documentsBlock: CommonAppealDocumentsComponent;

    @Output() public onChangeTab = new EventEmitter<any>();
    @Output() public onErrorsApplyData = new EventEmitter<any>();
    @Output() public onApplyData = new EventEmitter<any>();
    @Output() public afterApplyData = new EventEmitter<any>();

    public constructor(
        public appealService: CommonAppealService,
        private route: ActivatedRoute,
        private selectionService: SelectionService,
        private saveService: CommonAppealSaveService,
        private modalService: BsModalService,
    ) {
    }

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.appealSubservice = this.appeal.subservice;
        this.blocks = this.tabs;
        if (this.subservice.registersModel && this.subservice.registersModel.titles) {
            this.licensesTitles.single = this.subservice.registersModel.titles.hasOwnProperty('single')
                                         ? this.subservice.registersModel.titles.single : this.licensesTitles.single;
            this.licensesTitles.plural = this.subservice.registersModel.titles.hasOwnProperty('plural')
                ? this.subservice.registersModel.titles.plural : this.licensesTitles.plural;
        }
        if (this.selectionService.isProcessSelect && ['selectObject', 'selectSubject'].indexOf(this.selectionService.transferOperation) !== -1) {
            this.editedEntity = this.selectionService.additionalData.editedObject ? this.selectionService.additionalData.editedObject : this.selectionService.additionalData.editedSubject;
            this.editedEntityType = this.selectionService.additionalData.entityType;
        }
        this.allowEdit = this.appeal.status.code !== 'complete';
    }

    public get mode() {
        return this.appeal.status.code === 'draft' ? 'edit' : 'view';
    }

    public isShowEntitiesInCommon(type) {
        const data = type === 'objects' ? 'objectsData' : 'subjectsData';

        return this.subservice.objects && this.subservice.objects[data] && this.subservice.objects[data].useInMainBlock;
    }

    /**
     * Обновление данных после изменения состава данных на сервере
     */
    public async updateData() {
        if (!(this.appeal.subservice.mainElement && this.commonBlock)) {
            return;
        }

        await this.commonBlock.updateData();
        await this.saveService.saveAppeal();
    }

    /**
     * Редактирование (создание) субъекта или объекта
     * @param data
     */
    public onEditEntity(data) {
        this.editedEntity = data.entity;
        this.editedEntityType = data.type;
    }

    /**
     * Успешное применение формы редактирования субъекта или объекта
     */
    public afterApplyEditedEntity() {
        this.editedEntity = null;
        this.editedEntityType = null;
        if (this.processingAction) {
            this._processApplyBlocksData();
        }
    }

    public activateTab(tab) {
        this.nextTab = tab;
        this.applyBlocksData('activateTab');
    }

    /**
     * Применение данных дела
     */
    public applyBlocksData(action) {
        this.processingAction = action;
        if (this.editedEntity) {
            this._startApplyEditedEntity();
        } else {

            this._processApplyBlocksData();
        }
    }

    /**
     * Запуск процедуры применения редактируемого элемента
     */
    private _startApplyEditedEntity() {
        if (this.editedEntity && this.isShowEntitiesInCommon(this.editedEntityType + 's')) {
            this.commonBlock.applyEditedEntity();
        } else if (this.editedEntityType === 'subject') {
            this.appealSubjectsBlock.applyEditedSubject();
        } else if (this.editedEntityType === 'object') {
            this.appealObjectsBlock.applyEditedObject();
        }
    }

    /**
     * Процесс обработки данных: статусы форм доп. данных, сохранение процессных переменных
     */
    private _processApplyBlocksData() {
        this.tabs.forEach(tab => {
            if (this.workMode !== 'default' || tab.code === this.activeTab) {
                if (tab.code === 'common' && this.commonBlock) {
                    this.commonBlock.processingBlockData();
                }
            }
        });
        switch (this.processingAction) {
            case 'activateTab':
                this.activeTab = this.nextTab;
                this.onChangeTab.emit(this.nextTab);
                break;
            default:
                this.afterApplyData.emit();
                break;
        }
        this.processingAction = null;
    }

    public errorsOnApply() {
        this.onErrorsApplyData.emit();
    }

    public showErrors(tab) {
        this.blockWithErrors = tab;
        this.modalRef = this.modalService.show(this.errorsListModal, { backdrop: 'static' });
    }

    public updateDataOnChange() {
        if (this.documentsBlock) {
            this.documentsBlock.initData();
        }
        if (this.commonBlock) {
            this.commonBlock.reInitData();
        }
    }
}
