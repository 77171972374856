<div class="display-flex align-items-center border-left-4 border-right-4"
     [ngClass]="{'active': selected }">
    <div class="list-item full-width"
         (click)="adaptorClickedHandler(adaptor)">
        <div style="align-self: flex-start">
            <div [class]="getStatusClass()">
                {{ getStatusName() }}
            </div>
        </div>

        <div class="list-item-data"
             style="white-space: inherit">
            <!-- TODO добавить стиль скрытого текста -->
            <h6 class="text-bold no-margin">
                {{ adaptor.uri }}
            </h6>
        </div>
    </div>
</div>
<div class="list-separator">&nbsp;</div>
