import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsLocaleService, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap';
import { ruLocale } from 'ngx-bootstrap/locale';
defineLocale('ru', ruLocale);
import * as moment from 'moment';

@Component({
    selector: 'widget-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.less'],
})
export class CalendarComponent implements OnInit, OnChanges {
    @Input() public year;
    @Input() public days;

    public minMode: BsDatepickerViewMode = 'day';
    public currentYear = moment().year();
    public config: Partial<BsDatepickerConfig>;
    public months = [
        { id: '1', text: 'Январь' },
        { id: '2', text: 'Февраль' },
        { id: '3', text: 'Март' },
        { id: '4', text: 'Апрель' },
        { id: '5', text: 'Май' },
        { id: '6', text: 'Июнь' },
        { id: '7', text: 'Июль' },
        { id: '8', text: 'Август' },
        { id: '9', text: 'Сентябрь' },
        { id: '10', text: 'Октябрь' },
        { id: '11', text: 'Ноябрь' },
        { id: '12', text: 'Декабрь' },
    ];

    public selectedDates = [];

    public constructor(private localeService: BsLocaleService) {}

    public ngOnInit() {
        this.config = {
            minMode: this.minMode,
            containerClass: 'theme-dark-blue',
            selectFromOtherMonth: true,
            // adaptivePosition: true
        };
        this.localeService.use('ru');
        this.setDays();
    }

    public setDays() {
        if (this.days) {
            this.selectedDates = [];
            Object.keys(this.days).forEach((key) => {
                this.selectedDates.push({
                    date: moment([this.days[key].year, this.days[key].month - 1, this.days[key].day]).toDate(),
                    classes: [this.days[key].isHoliday ? 'bg-custom-green' : 'bg-custom', 'text-custom'],
                });
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.setDays();
    }

    public getMinDate(index) {
        return new Date(`${this.year}/${index}/01`);
    }

    public getMaxDate(index) {
        return new Date(this.year, index, 0);
    }

    public onValueChange(value: Date) {
        if (value) {
            const month = moment(value).month() + 1;
            const day = moment(value).date();
            const key = this.year.toString() + month.toString().padStart(2, '0') + day.toString().padStart(2, '0');

            const findIndex = this.selectedDates.findIndex((item) => item.date.toDateString() === value.toDateString());
            if (findIndex === -1) {
                this.selectedDates.push({ date: value, classes: ['bg-custom', 'text-custom'] });
                this.days[key] = { year: this.year, month: month, day: day };
            } else if (findIndex >= 0 && !this.days[key].isHoliday) {
                this.selectedDates[findIndex].classes = ['bg-custom-green', 'text-custom'];
                this.days[key].isHoliday = true;
            } else {
                this.selectedDates.splice(findIndex, 1);
                delete this.days[key];
            }
        }
    }
}
