import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    HttpService,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
    UserMessagesService,
} from '@evolenta/core';
import { Config } from '../../../../../common/services/config';
import { UtilityService } from '../../../../../common/services/utility.service';
import { TasksService } from '../../tasks.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'sub-tasks',
    templateUrl: 'sub-tasks.component.html',
})
export class SubTasksComponent implements OnInit {
    @Input() public task;
    @Input() public appeal;
    @Input() public usersForSelect;
    @Input() public candidateGroups;
    @Input() public mainEntity;
    public isEditSubTask = false;
    public subTask: any = {};
    public camundaTask: any = {};
    public isProcessSave = false;

    public currentOrganization = this.storage.getItem('currentOrganization');
    public currentUser = this.storage.getItem('user');
    public localizations;

    @Output() public onChange = new EventEmitter<any>();

    public constructor(
        public httpService: HttpService,
        public utilityService: UtilityService,
        private toaster: ToasterService,
        private rest: RestService,
        private storage: StorageService,
        private taskService: TasksService,
        private userMessagesService: UserMessagesService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        if (!this.task.subTasks) {
            this.task.subTasks = [];
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'tasks.subtasks']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public addSubTask() {
        this.isEditSubTask = true;
        this.subTask = { status: 'ACTIVE' };
    }

    public editSubTask(subTask) {
        const params = { search: { search: [{ field: 'userTasks.id', operator: 'eq', value: subTask.id }] } };

        this.rest.search('camundaBusinessInfo', params).then((result: any) => {
            const task = result[0].userTasks.find((item) => item.id === subTask.id);
            this.camundaTask = _.cloneDeep(result[0]);
            this.isEditSubTask = true;
            this.subTask = _.cloneDeep(task);
        });
    }

    public deleteSubTask(subTask) {
        this.task.subTasks = this.task.subTasks.filter((item) => item.guid !== subTask.guid);
    }

    public cancel() {
        this.isEditSubTask = false;
        this.subTask = {};
    }

    public apply() {
        if (!this.subTask.parentTaskId) {
            this.subTask.parentTaskId = this.task.id;
        }
        this.isProcessSave = true;
        if (!this.subTask.id) {
            const url =
                Config.server + Config.app + Config.api + 'camunda/task/create?unitId=' + this.currentOrganization._id;
            return this.httpService.post(url, this.subTask).then(
                (result: any) => {
                    this.rest.find('camundaBusinessInfo', result.camundaBusinessInfoId).then((camundaTask: any) => {
                        this.camundaTask = camundaTask;
                        const task = camundaTask.userTasks.find((item) => item.id === result.taskId);
                        this.subTask = Object.assign(this.subTask, task);
                        this.task.subTasks.push(this.subTask);
                        if (this.subTask.assignee) {
                            this.taskService.setTaskAssignee(result.taskId, this.task.assignee).then(() => {
                                if (this.subTask.assignee !== this.currentUser.login) {
                                    const message = 'Вам назначена задача "' + this.subTask.name + '"';
                                    const user = this.usersForSelect.find(
                                        (item) => item.login === this.subTask.assignee
                                    );
                                    if (user) {
                                        this.userMessagesService.createMessage(
                                            message,
                                            'task',
                                            'newCustomTask',
                                            user,
                                            ['tasks', 'edit', this.camundaTask._id, this.subTask.id],
                                            true
                                        );
                                    }
                                }
                                this.apply();
                            });
                        } else {
                            this.apply();
                        }
                    });
                },
                (error) => {
                    this.toaster.error(error);
                    this.isProcessSave = false;
                }
            );
        } else {
            const userTasks = [this.subTask];
            const saveData = {
                ...this.camundaTask,
                userTasks,
            };

            this.rest.update('camundaBusinessInfo', saveData).then(() => {
                this.toaster.success('Изменения успешно сохранены');

                const findedIndex = this.task.subTasks.findIndex((item) => item.id === this.subTask.id);

                if (findedIndex !== -1) {
                    this.task.subTasks[findedIndex] = _.cloneDeep(this.subTask);
                }

                this.subTask = {};
                this.isEditSubTask = false;
                this.isProcessSave = false;
                this.onChange.emit(true);
            });
        }
    }
}
