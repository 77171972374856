import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ActivatedRouteService {
    private static UrlsWithChildrenInWorkingUrl = [
        'financial-support-volumes',
        'objects-of-control',
        'subjects',
        'result-plans',
        'objects-activities-indicators',
        'criteria',
        'global-subjects',
        'envelopes',
        'appeals',
    ];

    public moduleBaseUrl: string; // Базовый URL основного приложения
    public workModeUrl: string; // Рабочий URL основного приложения

    public processRoute(route: ActivatedRoute): void {
        route.pathFromRoot[1].url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;

            route.pathFromRoot[2].url.subscribe((urlPath0) => {
                const url = urlPath0[urlPath0.length - 1].path;

                if (ActivatedRouteService.UrlsWithChildrenInWorkingUrl.includes(url)) {
                    route.parent.url.subscribe((urlPath1) => {
                        this.workModeUrl = urlPath1[urlPath1.length - 1].path;
                    });
                } else {
                    route.url.subscribe((urlPath2) => {
                        this.workModeUrl = urlPath2 && urlPath2.length > 0 ? urlPath2[urlPath2.length - 1].path : '';
                    });
                }
            });
        });
    }
}
