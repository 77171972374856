import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'print-form-xsd',
    templateUrl: './print-form-xsd.component.html',
})
export class PrintFormXsdComponent implements OnInit {
    @Input() public xsd;
    @Input() public allowEdit = true;
    @Output() public saveXsd = new EventEmitter<any>();

    public xsdTypes = [];
    public xsdSchema = '';

    public selectedType = 'text';
    public localizations;

    public constructor(private translate: TranslateService) {}

    public ngOnInit() {
        this.xsdSchema = this.xsd;
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'print-forms']).subscribe((res: any) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.xsdTypes = [
            {
                id: 'text',
                text: this.localizations['print-forms'].xsd_as_text,
            },
            {
                id: 'form',
                text: this.localizations['print-forms'].xsd_as_form,
            },
        ];
    }

    public selectXsdType(type) {
        this.selectedType = type.id;
    }

    public getSelectedValue() {
        return _.filter(this.xsdTypes, (item) => item.id === this.selectedType);
    }

    public changeXsd() {
        this.saveXsd.emit(this.xsdSchema);
    }
}
