import { Component, Input, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { EntityProcessElementDataComponent } from '../element/data/entity-process-element-data.component';
import { CommonProcessService } from '../../common-process.service';
import { StorageService, ToasterService, TranslateService, UsersService } from '@evolenta/core';
import * as moment from 'moment';

@Component({
    selector: 'entity-process-events',
    templateUrl: './entity-process-events.component.html',
})
export class EntityProcessEventsComponent implements OnInit {
    @Input() public data;
    @Input() public onLeft = true;
    @Input() public mode = 'all'; // buttons, settings
    @Input() public showButtons = true;
    @Input() public showActiveEventData = true;

    @ViewChild('activeEventData', { static: false }) public activeEventDataComponent: EntityProcessElementDataComponent;

    @Output() public onApplyEvent = new EventEmitter<any>();
    @Output() public afterApplyEvent = new EventEmitter<any>();

    public currentUser = this.storage.getItem('user');
    public isProcessingEvent = false;
    public noData = false;
    public localizations;

    public constructor(
        private storage: StorageService,
        private processService: CommonProcessService,
        private usersService: UsersService,
        private toaster: ToasterService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getProcessingEventMessage(): string {
        const template = this.localizations.appeals.processing_event;

        return template.replace('%s', this.data.activeEvent && this.data.activeEvent.eventName ? this.data.activeEvent.eventName : '');
    }

    public activateEvent(event) {
        this.data.activeEvent = event;
    }

    public emitApplyEvent() {
        this.data.isProcessingCompleteElement = true;
        this.onApplyEvent.emit();
    }

    public async applyEvent(emptyData = false) {
        this.data.activeEventEmptyData = emptyData;
        if (!(emptyData || this.activeEventDataComponent.isValid())) {
            this.data.isProcessingCompleteElement = false;

            return;
        }

        this.isProcessingEvent = true;
        if (this.activeEventDataComponent) {
            try {
                await this.activeEventDataComponent.prepareCompleteData();
                this._completeApplyEvent();
            } catch (error) {
                this.data.isProcessingCompleteElement = false;
            }

            return;
        }

        this._completeApplyEvent();
    }

    private _completeApplyEvent() {
        this.data.activeEvent.dateCompleted = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
        const user = this.storage.getItem('user');
        this.usersService.getKnoUsers(false, false).then(() => {
            this.data.activeEvent.user = this.usersService.selectUser(user);
            this.data.completedEvents.push(this.data.activeEvent);
            this.data.elements.unshift(this.data.activeEvent);
            this.data.saveEntity();
            setTimeout(() => {this.processingSaveEntity(); }, 200);
        });
    }

    public finishApplyEvent() {
        this.processService.completeProcessEvent(this.data.activeEvent).then(() => {
            this.afterApplyEvent.emit(this.data.activeEvent);
            this.data.activeEvent = null;
            this.isProcessingEvent = false;
            this.data.events = [];
            this.data.activeEventEmptyData = false;
            this.data.isProcessingCompleteElement = false;
            this.toaster.success('Событие успешно завершено');
            this.data.initProcess();
        });
    }

    public processingSaveEntity() {
        if (this.data.isProcessSaveEntity) {
            setTimeout(() => {this.processingSaveEntity(); }, 200);
        } else {
            this.finishApplyEvent();
        }
    }

    public cancelEvent() {
        this.data.activeEvent = null;
    }

    public checkAvailable(event) {
        const data = this.processService.getElementDataFromProcessInEntity(event, 'catchEvents', 'activityId', this.data.processInEntity);

        return this.processService.getProcessElementRule(event, data, this.data);
    }
}
