<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button" class="btn btn-primary btn-main btn-labeled btn-labeled-right btn-xs"
            *ngIf="dictionary && dictionary.dictionaryParams.allowEdit && !selectionService.isProcessSelect"
            (click)="createElement()">
        <b><i class="icon-plus-circle2"></i></b>
        {{ localizations.dictionaries.add_value }}
    </button>

    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="selectionService.isProcessSelect"
            (click)="backToOperation()">
        {{ localizations.common.go_back }}
    </button>

    <div class="ml-20 navbar-title mt-20"
         [ngClass]="{'wide': !dictionary || !dictionary.dictionaryParams.allowEdit}">
        <h4>
            <span>
                {{dictionary?.description}}
            </span>
        </h4>
    </div>
    <div class="navbar-menu"
         [ngClass]="{'wide': !dictionary || !dictionary.dictionaryParams.allowEdit}">
        <user-tile></user-tile>
    </div>
</div>
<div class="page-container">
    <div class="panel no-margin no-border panel-main wide">
        <div class="panel-body no-padding">
            <evolenta-grid [model]="dictionary.collectionName"
                           [colDef]="colDef"
                           [rowSelection]="rowSelection"
                           [compareFields]="compareFields"
                           *ngIf="dictionary && !dictionary.dictionaryParams.isMultiLevelsDictionary && showGrid"
                           #gridElement>
            </evolenta-grid>


            <!-- Многоуровневый справочник -->
            <evolenta-scrollbar *ngIf="dictionary && dictionary.dictionaryParams.isMultiLevelsDictionary">
                <div class="input-group input-group-lg m-20">
                    <input class="form-control"
                           [placeholder]="localizations.common.search_dots"
                           type="text"
                           [(ngModel)]="globalSearch"
                           (keypress)="searchElements()">
                    <div class="input-group-btn">
                        <button class="btn btn-default text-muted"
                                type="button"
                                (click)="searchElements()">
                            <i class="icon-search4 text-muted"></i>
                        </button>
                    </div>
                </div>
                <hr class="no-margin">
                <ng-template let-list="list"
                             let-level="level"
                             #dictionaryLevel>
                    <ng-container *ngFor="let item of list; let idx = index">
                        <div class="tree-line"
                             [ngStyle]="{'padding-left': (level * 30 + 10) + 'px'}"
                             *ngIf="item.visible || item.halfVisible">
                            <div class="tree-item-toggle-sign">
                            <span class="cursor-pointer"
                                  *ngIf="item.children && item.children.length > 0"
                                  (click)="item.showChildren = !item.showChildren">
                                <i class="icon-plus2"
                                   *ngIf="!item.showChildren">
                                </i>
                                <i class="icon-minus2"
                                   *ngIf="item.showChildren">
                                </i>
                            </span>
                            </div>
                            <div class="tree-item-title">
                                <span [ngClass]="{'opacity-50': item.halfVisible}">
                                    {{item.name}}
                                </span>
                            </div>
                            <div class="tree-item-buttons text-right">
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="localizations.dictionaries.add_child_element"
                                        (click)="addChildElement(item)">
                                    <i class="icon-plus2"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="localizations.common.edit"
                                        (click)="editElement(item)">
                                    <i class="icon-pencil7"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="localizations.common.delete"
                                        (click)="deleteElement(item, list, idx)">
                                    <i class="icon-trash"></i>
                                </button>

                            </div>
                        </div>
                        <ng-container *ngIf="item.children && item.children.length > 0 && item.showChildren">
                            <ng-container *ngTemplateOutlet="dictionaryLevel; context:{ list: item.children, level: (level + 1)}"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>
                <ng-container *ngTemplateOutlet="dictionaryLevel; context:{ list: dictionaryData, level: 0}">
                </ng-container>
            </evolenta-scrollbar>
        </div>

    </div>
</div>

<modal-dialog
    #deleteModal
    [message]="localizations.dictionaries.are_you_sure_you_want_to_delete_element"
    [title]="localizations.dictionaries.element_deletion"
    (onSelect)="answerHandler($event)" >
</modal-dialog>
