<div  class="pt-20 pl-20 pr-20">
    <form #form="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group mb-10">
                    <label>
                        Организационно-правовая форма
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.opf', organization)">
                            *
                        </span>
                    </label>
                    <catalogue catalogName="catalogueOpf"
                                        name="opf"
                                        #opf="ngModel"
                                        [(ngModel)]="organization.data.organization.opf"
                                        [large]="true"
                                        [required]="fieldsRequirementsService.checkFieldRequired('organization.opf', organization)"
                                        [disabled]="isDisabled('organization.opf')"
                                        (change)="updateErrors('organization.opf')"
                    ></catalogue>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && opf.errors">
                        <span *ngIf="opf.errors.required">
                            Поле "Организационно-правовая форма" обязательно для заполнения
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        Краткое наименование
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.shortName', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationShortName"
                           class="form-control"
                           #organizationShortName="ngModel"
                           [(ngModel)]="organization.data.organization.shortName"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.shortName', organization)"
                           [disabled]="isDisabled('organization.shortName')"
                           (ngModelChange)="updateErrors('organization.shortName'); calculateHeader();">
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationShortName.errors">
                        <span *ngIf="organizationShortName.errors.required">
                            Поле "Краткое наименование" обязательно для заполнения
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        Полное наименование
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.name', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationName"
                           #organizationName="ngModel"
                           [(ngModel)]="organization.data.organization.name"
                           (ngModelChange)="updateErrors('organization.name')"
                           class="form-control"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.name', organization)"
                           [disabled]="isDisabled('organization.name')">
                    <div *ngIf="isProcessValidate && organizationName.errors"
                         class="validation-error-label">
                        <span *ngIf="organizationName.errors.required">
                            Поле "Наименование" обязательно для заполнения
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        ОГРН
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.ogrn', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationOgrn"
                           class="form-control"
                           #organizationOgrn="ngModel"
                           [(ngModel)]="organization.data.organization.ogrn"
                           [textMask]="{mask: globalParams.orgOgrnMask}"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.ogrn', organization)"
                           [disabled]="isDisabled('organization.ogrn')"
                           (ngModelChange)="updateErrors('organization.ogrn'); calculateHeader();"
                           evolentaValidateOgrn>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationOgrn.errors && organizationOgrn.errors.required">
                        Поле "ОГРН" обязательно для заполнения
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        ИНН
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.inn', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationInn"
                           class="form-control"
                           #organizationInn="ngModel"
                           [(ngModel)]="organization.data.organization.inn"
                           [textMask]="{mask: globalParams.orgInnMask}"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.inn', organization)"
                           [disabled]="isDisabled('organization.inn')"
                           (ngModelChange)="updateErrors('organization.inn')"
                           evolentaValidateOrgInn>
                    <ng-container *ngIf="checkEnableQuickRequest('quickEgrul')">
                        <a class="anchor text-info text-size-small"
                           *ngIf="!checkProcessingQuickRequest('quickEgrul')"
                           (click)="sendQuickRequest('quickEgrul')">
                            обновить данные из ЕГРЮЛ
                        </a>
                        <span class="text-size-small text-muted"
                              *ngIf="checkProcessingQuickRequest('quickEgrul')">
                            <i class="icon-spinner2 spinner"></i>
                            получение данных
                        </span>
                        <span class="text-info text-size-small anchor ml-5"
                              title="выполняемый запрос"
                              *ngIf="organization['quickRequests'] && organization['quickRequests']['quickEgrul'] && checkProcessingQuickRequest('quickEgrul')"
                              routerLink="/ais/envelopes/edit/{{organization['quickRequests']['quickEgrul'][0].id}}">
                            {{organization['quickRequests']['quickEgrul'][0].auid}}
                        </span>
                    </ng-container>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationInn.errors && organizationInn.errors.required">
                        Поле "ИНН" обязательно для заполнения
                    </div>
                    <div class="validation-error-label"
                         *ngIf="organizationInn.errors && organizationInn.errors.organizationINN">
                        {{ organizationKpp.errors.organizationINN }}
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        КПП
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.kpp', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationKpp"
                           maxlength="9"
                           class="form-control"
                           #organizationKpp="ngModel"
                           [(ngModel)]="organization.data.organization.kpp"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.kpp', organization)"
                           [disabled]="isDisabled('organization.kpp')"
                           (ngModelChange)="updateErrors('organization.kpp')"
                           evolentaValidateKpp>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationKpp.errors && organizationKpp.errors.required">
                        Поле "КПП" обязательно для заполнения
                    </div>
                    <div class="validation-error-label"
                         *ngIf="organizationKpp.errors && organizationKpp.errors.kpp">
                        {{organizationKpp.errors.kpp}}
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-lg-12">
                <div class="form-group">
                    <label>Вид деятельности</label>
                    <catalogue name="okved"
                                        catalogName="nsiKindOfEconomicActivity"
                                        [(ngModel)]="organization.data.organization.okved"
                                        [disabled]="isDisabled('organization.okved')"
                                        [priority]="true"
                                        [large]="true"
                    ></catalogue>
                </div>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <label>
                        Юридический адрес
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.registrationAddress', organization)">
                            *
                        </span>
                    </label>
                    <evolenta-address-gar
                            name="fiasRegistrationAddress"
                            [large]="true"
                            [required]="fieldsRequirementsService.checkFieldRequired('organization.registrationAddress', organization)"
                            [disabled]="isDisabled('organization.registrationAddress')"
                            [(ngModel)]="organization.data.organization.registrationAddress"
                    ></evolenta-address-gar>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('organization.registrationAddress', organization)
                                && !organization.data.organization.registrationAddress">
                        Поле "Юридический адрес" обязательно для заполнения
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
