<ng-container *ngIf="!editedAdditionalTab">
    <div class="pt-20 pl-20 pr-20">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of tabs">
                <li [ngClass]="{'active': activeTab && activeTab === tab.code}"
                    (click)="activeTab = tab.code">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </div>

    <!--  Настройка BPMN-процесса  -->
    <common-standard-bpmn-process *ngIf="activeTab == 'bpmn'">
    </common-standard-bpmn-process>

    <!--  Дополнительные данные  -->
    <ng-container *ngIf="activeTab == 'additionalDataTabs'">
        <div class="panel panel-shadow panel-small ml-20 mr-20">
            <div class="panel-heading">
                <h4 class="panel-title pt-10">
                    <span class="mr-10">
                        {{ localizations.standards.additional_data_forms }}
                    </span>
                </h4>
                <div class="heading-elements">
                    <button class="btn btn-default btn-sxs"
                            type="button"
                            [title]="localizations.appeals.add_variant"
                            *ngIf="activeData.allowEdit"
                            (click)="addAdditionalDataTab()">
                        <i inlineSVG="assets/icon-new-item.svg"></i>
                    </button>
                </div>
            </div>
            <div class="panel-body no-padding">
                <ng-container *ngIf="activeData.entity.additionalDataTabs && activeData.entity.additionalDataTabs.length > 0">
                    <table class="table">
                        <tbody>
                        <tr *ngFor="let item of activeData.entity.additionalDataTabs; let fst=first">
                            <td [ngClass]="{'no-border-top': fst}">
                                {{item.code}}
                            </td>
                            <td [ngClass]="{'no-border-top': fst}">
                                {{item.name}}
                            </td>
                            <td class="text-right"
                                [ngClass]="{'no-border-top': fst}">
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="activeData.allowEdit ?
                                            localizations.common.edit : localizations.common.view"
                                        (click)="editAdditionalDataTab(item)">
                                    <i [ngClass]="{
                                        'icon-pencil': activeData.allowEdit,
                                        'icon-eye': !activeData.allowEdit}"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="localizations.common.delete"
                                        *ngIf="activeData.allowEdit"
                                        (click)="deleteAdditionalDataTab(item)">
                                    <i class="icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </ng-container>

                <div class="alert alert-warning no-margin"
                     *ngIf="!activeData.entity.additionalDataTabs || activeData.entity.additionalDataTabs.length === 0">
                    {{ localizations.standards.additional_form_data_not_added }}
                </div>
            </div>
        </div>
    </ng-container>

    <!--  Процесс с этапами и задачами  -->
    <common-standard-stages-and-tasks-process *ngIf="activeTab == 'static'">
    </common-standard-stages-and-tasks-process>
</ng-container>

<!-- Форма редактирования -->
<div class="panel panel-shadow m-20"
     *ngIf="editedAdditionalTab">
    <div class="panel-heading">
        <h4 class="panel-title">
            {{ localizations.standards.additional_data_settings }}
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="applyAdditionalDataTab()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancelAdditionalDataTab()">
                {{ localizations.common.close }}
            </button>
        </div>
    </div>
    <div class="panel-body no-padding-left no-padding-right no-padding-bottom pt-20">
        <ul class="info-row ml-20 mr-20 no">
            <ng-container *ngFor="let tab of additionalDataTabs">
                <li (click)="activateTab(tab)"
                    [ngClass]="{'active': activeAdditionalDataTab && activeAdditionalDataTab === tab.code}">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
        <!-- Общие данные  -->
        <ng-container *ngIf="activeAdditionalDataTab === 'common'">
            <div class="p-20">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.common.code }}
                            </label>
                            <input class="form-control"
                                   type="text"
                                   [(ngModel)]="editedAdditionalTab.code">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.common.name }}
                            </label>
                            <input class="form-control"
                                   type="text"
                                   [(ngModel)]="editedAdditionalTab.name">
                        </div>
                    </div>
                    <div class="col-md-3 pt-30">
                        <evolenta-handlers
                                field="xsd"
                                [model]="editedAdditionalTab"
                                [setStartEvent]="true"
                                [setChangeEvent]="true"
                        ></evolenta-handlers>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>
                                {{ localizations.standards.uin_filling }}
                            </label>
                            <div class="checkbox-block">
                                <input id="isFillUin"
                                       class="checkbox"
                                       type="checkbox"
                                       [checked]="editedAdditionalTab.isFillUin"
                                       (change)="editedAdditionalTab.isFillUin = !editedAdditionalTab.isFillUin">
                                <label for="isFillUin">
                                    {{ localizations.standards.uin_filling_block }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="no-margin">
            <dynamic-form
                defaultAction="build"
                tabsMode="none"
                [xsd]="editedAdditionalTab.xsd"
                [edit]="true"
                [model]="model"
                [showTitle]="false"
                [params]="{
                    isSetVariable: true,
                    isInitDataFromExternalData: true,
                    isSetTransferToProcess: true
                }"
                [scrollTopPosition]="scrollTopPosition"
                (onCreateXsd)="createXsd($event)"
                #additionalData>
            </dynamic-form>
        </ng-container>

        <!-- Настройка связи с задачами процесса  -->
        <ng-container *ngIf="activeAdditionalDataTab === 'bpmnTasks'">
            <common-bpmn-links propertyName="additionalDataTabs"
                        elementType="tasks"
                        [property]="editedAdditionalTab"
                        [processDataInEntity]="activeData.entity.process"
                        [process]="standardsService.bpmnProcess"
                        [operations]="['used']"
                        [allowEdit]="activeData.allowEdit">
            </common-bpmn-links>
        </ng-container>

        <ng-container *ngIf="activeAdditionalDataTab === 'bpmnEvents'">
            <common-bpmn-links propertyName="additionalDataTabs"
                        elementType="catchEvents"
                        [property]="editedAdditionalTab"
                        [processDataInEntity]="activeData.entity.process"
                        [process]="standardsService.bpmnProcess"
                        [operations]="['used']"
                        [allowEdit]="activeData.allowEdit"
            ></common-bpmn-links>
        </ng-container>
    </div>
</div>
