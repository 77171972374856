<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            (click)="buildReport()"
            [disabled]="isProcessingReport">
        <b>
            <i class="icon-checkmark-circle"
               [ngClass]="{'flash': isProcessingReport}">
            </i>
        </b>
        {{ localizations.reports.create }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            {{ localizations.reports.standards_registry }}
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="panel-main wide">
        <evolenta-scrollbar class="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
            <div class="bg-primary-50 p-20"
                 *ngIf="isEditReportType">
                <p class="text-bold">
                    {{ localizations.reports.select_registry_type }}:
                </p>
                <div class="radio-block"
                     *ngFor="let type of reportsService.reportsData">
                    <input id="type{{type.collection.code}}"
                           class="radio"
                           type="radio"
                           (change)="selectReportType(type)">
                    <label for="type{{type.collection.code}}">
                        {{type.collection.name}}
                    </label>
                </div>
            </div>
            <div  *ngIf="!isEditReportType">
                <div class="pt-20 pl-20 pr-20 hide">
                    <div class="row hide">
                        <div class="col-md-7">
                            <div class="form-group form-lg">
                                <label>
                                    {{ localizations.reports.name }}
                                </label>
                                <input class="form-control"
                                       type="text"
                                       [(ngModel)]="report.name">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>
                                    {{ localizations.reports.type }}
                                </label>
                                <div class="text-bold text-uppercase">
                                    <span class="badge badge-primary cursor-pointer"
                                          (click)="isEditReportType = true"
                                          [title]="localizations.reports.change_type">
                                        {{reportData?.collection.name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Колонки отчета -->
                <div class="panel panel-shadow panel-small m-20">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            {{ localizations.reports.columns_parameters }}
                        </h4>
                    </div>
                    <div class="panel-body">
                        <report-fields [data]="report"
                                       [fields]="reportColumns">
                        </report-fields>
                    </div>
                </div>

                <!-- Параметры фильтрации -->
                <div class="panel panel-shadow panel-small m-20">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            {{ localizations.reports.search_parameters }}
                        </h4>
                    </div>
                    <div class="panel-body no-padding">
                        <!-- Настройка организаций -->
                        <div class="panel panel-shadow panel-small m-20">
                            <div class="panel-heading">
                                <h6 class="panel-title text-bold">
                                    <i class="icon-checkmark-circle text-success"
                                       [title]="localizations.reports.condition_equals">
                                    </i>
                                    {{ localizations.common.organizations }}
                                </h6>
                            </div>
                            <div class="panel-body no-padding">
                                <table class="table table-hover">
                                    <tbody>
                                    <tr *ngFor="let item of report.units; let fst = first; let idx = index; let lst = last">
                                        <td [ngClass]="{
                                            'no-border-top': fst,
                                            'border-bottom border-default': lst && report.units.length < allOrganizations.length
                                            }">
                                            {{item.shortName}}
                                        </td>
                                        <td class="text-right"
                                            *ngIf="report.units.length > 1"
                                            [ngClass]="{
                                                'no-border-top': fst,
                                                'border-bottom border-default': lst && report.units.length < allOrganizations.length
                                                }">
                                            <button class="btn btn-default btn-sxs"
                                                    type="button"
                                                    (click)="removeOrganization(idx)"
                                                    [title]="localizations.common.delete">
                                                <i class="icon-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="bg-primary-50 p-10"
                                     *ngIf="isProcessSelectOrganization">
                                    <p class="text-bold">
                                        {{ localizations.reports.select_organization_to_add }}
                                        <span class="text-info anchor"
                                              (click)="addAllUnits()">
                                            {{ localizations.reports.add_all }}
                                        </span>:
                                    </p>
                                    <div class="radio-block"
                                         *ngFor="let unit of organizationsForSelect">
                                        <input id="unit{{unit.id}}"
                                               class="radio"
                                               type="radio"
                                               name="units">
                                        <label for="unit{{unit.id}}"
                                               (click)="selectUnit(unit)">
                                            {{unit.shortName}}
                                        </label>
                                    </div>
                                </div>
                                <div class="m-20 mt-15"
                                     *ngIf="report.units.length < allOrganizations.length && !isProcessSelectOrganization">
                                    <a class="anchor text-info"
                                       (click)="addUnit()">
                                        {{ localizations.common.add_organization }}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-shadow panel-small m-20"
                             *ngFor="let condition of report.conditions; let idx = index">
                            <div class="panel-heading">
                                <div class="panel-title">
                                    <h6 class="panel-title">
                                        <!-- Формат условия: включает / не включает -->
                                        <ng-container *ngIf="condition.type && condition.type.code != 'date'">
                                            <i class="icon-checkmark-circle text-success cursor-pointer"
                                               [title]="localizations.reports.condition_equals"
                                               *ngIf="condition.compareType == 'in'"
                                               (click)="condition.compareType = 'nin'">
                                            </i>
                                            <i class="icon-cancel-circle2 text-danger cursor-pointer"
                                               [title]="localizations.reports.condition_not_equals"
                                               *ngIf="condition.compareType == 'nin'"
                                               (click)="condition.compareType = 'in'">
                                            </i>
                                        </ng-container>

                                        <!-- Название параметра -->
                                        <span class="text-bold"
                                              *ngIf="condition.type">
                                            {{condition.type.name}}
                                        </span>
                                        <em *ngIf="!condition.type"
                                            class="">
                                            {{ localizations.reports.parameter_not_selected }}
                                        </em>
                                        <a class="anchor text-info text-size-small ml-10"
                                           (click)="condition.isChangeConditionType = true">
                                            {{ localizations.common.change_small }}
                                        </a>
                                    </h6>
                                </div>
                                <div class="heading-elements">
                                    <ul class="icons-list no-margin mt-5">
                                        <li>
                                            <i class="icon-cross2"
                                               [title]="localizations.reports.remove_condition"
                                               (click)="removeCondition(idx)">
                                            </i>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="panel-body no-padding">
                                <!-- Выбор вида условия -->
                                <div class="bg-primary-50 pt-10 pb-10 pr-20 pl-20"
                                     *ngIf="condition.isChangeConditionType">
                                    <div class="text-bold mb-10">
                                        {{ localizations.reports.select_param_from_list }}:
                                    </div>
                                    <div class="radio-block"
                                         *ngFor="let conditionType of conditionTypes">
                                        <input id="conditionType{{conditionType.code}}{{idx}}"
                                               class="radio"
                                               type="radio"
                                               [checked]="condition.type && condition.type.code === conditionType.code">
                                        <label for="conditionType{{conditionType.code}}{{idx}}"
                                               (click)="selectConditionType(conditionType, condition)">
                                            {{conditionType.name}}
                                        </label>
                                    </div>
                                </div>

                                <div *ngIf="!condition.isChangeConditionType">

                                    <!-- Выбор из простых элементов -->
                                    <div class="no-border"
                                         *ngIf="condition.type.items && condition.type.code != 'date'">
                                        <div class="form-group form-group-lg">
                                            <ng-select  [multiple]="true"
                                                        [items]="selectService.transformForSelect('code', 'name', condition.type.items)"
                                                        (selected)="addConditionItem($event, condition)"
                                                        (removed)="removedConditionItem($event, condition)"
                                                        [placeholder]="localizations.common.select_value_from_list">
                                            </ng-select>
                                        </div>

                                    </div>

                                    <!-- Выбор из справочника -->
                                    <ng-container *ngIf="condition.type.dictionary">
                                        <catalogue [catalogName]="condition.type.dictionary"
                                                            [(ngModel)]="condition.processingItem"
                                                            [codeField]="condition.type.codeField"
                                                            [nameField]="condition.type.nameField"
                                                            [searchFields]="condition.type.searchFields"
                                                            [projection]="condition.type.projection"
                                                            [placeholder]="condition.type.placeholder"
                                                            [large]="true"
                                                            [showCode]="false"
                                                            (onSelect)="selectDictionaryItem($event, condition)"
                                                            [noBorder]="true"
                                                            [baseSearch]="condition.type.dictionary == 'subservices' ? subservicesBaseSearch : null">
                                        </catalogue>
                                        <table class="table table-xs table-hover">
                                            <tbody>
                                            <tr *ngFor="let item of condition.items; let fst = first; let idx = index">
                                                <td *ngIf="condition.type.codeField">
                                                    {{ ObjectUtilities.GetPropertyByPath(item, condition.type.codeField) }}
                                                </td>
                                                <td>
                                                    {{ ObjectUtilities.GetPropertyByPath(item, condition.type.resultNameField ? condition.type.resultNameField : condition.type.nameField) }}
                                                </td>
                                                <td class="text-right">
                                                    <button class="btn btn-default btn-sxs"
                                                            (click)="removedConditionItem(item, condition, idx)"
                                                            type="button"
                                                            [title]="localizations.common.delete">
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </ng-container>

                                    <!-- Добавление даты -->
                                    <ng-container *ngIf="condition.type.code == 'date'">
                                        <table class="table table-xs table-hover">
                                            <tbody>
                                            <tr *ngFor="let item of condition.items; let fst = first; let lst = last">
                                                <td width="450"
                                                    [ngClass]="{
                                                        'no-border-top': fst,
                                                        'border-bottom border-default': lst
                                                        }">
                                                    <div class="form-group no-margin form-group-lg">
                                                        <ng-select [multiple]="false"
                                                                   [(ngModel)]="item.dateType"
                                                                   [items]="selectService.transformForSelect('code', 'name', condition.type.items)"
                                                                   [placeholder]="localizations.common.select_value_from_list">
                                                        </ng-select>
                                                    </div>
                                                </td>
                                                <td width="300"
                                                    [ngClass]="{
                                                        'no-border-top': fst,
                                                        'border-bottom border-default': lst
                                                        }">
                                                    <evolenta-datepicker [(ngModel)]="item.dateFrom"
                                                                         [placeholder]="localizations.reports.from_dots">

                                                    </evolenta-datepicker>
                                                </td>
                                                <td width="300"
                                                    [ngClass]="{
                                                        'no-border-top': fst,
                                                        'border-bottom border-default': lst
                                                        }">
                                                    <evolenta-datepicker [(ngModel)]="item.dateTo"
                                                                         [placeholder]="localizations.reports.till_dots">
                                                    </evolenta-datepicker>
                                                </td>
                                                <td class="text-right"
                                                    [ngClass]="{
                                                        'no-border-top': fst,
                                                        'border-bottom border-default': lst
                                                        }">
                                                    <button class="btn btn-default btn-sxs"
                                                            (click)="removedConditionItem(item, condition, idx)"
                                                            type="button"
                                                            [title]="localizations.common.delete">
                                                        <i class="icon-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>
                                        <div class="mt-10 m1-20 ml-20 mb-10">
                                            <a class="anchor text-info"
                                               (click)="addDateItem(condition)">
                                                Добавить дату
                                                {{ localizations.reports.add_date }}
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-info m-20 mt-15"
                                (click)="addCondition()">
                            {{ localizations.reports.add_date }}
                        </button>
                    </div>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
