import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, StorageService, ToasterService } from '@evolenta/core';
import { AppealSaveService } from '../../../modules/knm/appeals/appeal-save.service';

@Component({
    selector: 'history',
    templateUrl: 'history.component.html',
})
export class HistoryComponent implements OnInit {
    @Input() public collection;
    @Input() public id;
    @Input() public items;

    @Output() public onGetHistory = new EventEmitter<any>();

    public historyItems = [];

    public constructor(
        private rest: RestService,
        private appealSaveService: AppealSaveService,
        private toaster: ToasterService,
        private storage: StorageService,
        private router: Router,
    ) {
    }

    public async ngOnInit() {
        this.storage.removeFromCache('nextRoute');
        this.appealSaveService.nextSection = 'history';
        if (this.items) {
            this.historyItems = this.items;
            /*
                Используется как встроенный в деле (appeal) и запросе (envelope)
                По задаче ЦИТ РТ PPGMUD-236 компонент дела мы разбили на отдельные стейты
                и перевели этот компонент в основной компонент стейта.
                В идеале нужно то же самое сделать и для envelope. Когда-нибудь. В светлом будущем.
                И тогда тут будет красивый код. И все будут счастливы
             */

            return;
        }

        if (this.collection && this.id) {
            await this._fetchHistory();

            return;
        }

        this.id = this.storage.getFromCache('currentAppealId');
        if (this.id) {
            this.collection = 'appeals';
            await this._fetchHistory();

            return;
        }

        this.storage.cacheItem('nextRoute', 'history');
        const baseUrl = this.router.url.split('/history');
        await this.router.navigate([baseUrl]);
    }

    private async _fetchHistory() {
        const params = [
            {
                field: 'mainId',
                operator: 'eq',
                value: this.id,
            },
        ];

        try {
            const items = await this.rest.search('history', {
                search: {search: params},
                prj: 'history',
                size: 1000,
                sort: 'dateCreation,DESC',
            });
            this.historyItems = items;
            this.onGetHistory.emit(items);
        } catch (error) {
            this.toaster.error('При запросе истории произошла ошибка: ' + error);
        }
    }

}
