<div [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit'}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i></b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()" *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()" *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div  [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <p class="mb-3">
                <span class="text-muted">Номер: </span>
                <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
                <em *ngIf="!entity.number">не указано</em>

                <span class="text-muted ml-10">Дата составления: </span>
                <ng-container *ngIf="entity.dateCreate">{{entity.dateCreate | date: 'shortDate'}}</ng-container>
                <em *ngIf="!entity.dateCreate">не указано</em>
            </p>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button"  [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <ng-container *ngIf="mode === 'edit'">
            <!-- Визуальные вкладки с данными -->
<!--            <div class="mt-20" *ngIf="mode === 'edit' && entity.checkLists.length > 0" >-->
<!--                <element-edit-tabs [tabs]="editTabs" [active]="activeTab" (onActivateTab)="activateTab($event)" *ngIf="mode === 'edit'"></element-edit-tabs>-->
<!--            </div>-->

            <!--  Общие данные -->
            <div  class="pt-15 pl-20 pr-20" *ngIf="activeTab === 'entity'">
                <p class="mb-20">
                    <span class="text-bold">Акт проверки: </span>
                    <ng-container *ngIf="currentAct">
                        <ng-container *ngIf="currentAct.number">№ {{currentAct.number}}</ng-container>
                        {{currentAct.object.name}}. <span class="text-muted">Адрес объекта: </span>{{getAddressLine(currentAct.object.address)}}
                    </ng-container>
                    <span class="anchor text-info text-size-small ml-5" *ngIf="!currentAct" (click)="isChangeAct = true">не выбран</span>
                    <span class="anchor text-info text-size-small ml-5" *ngIf="currentAct" (click)="isChangeAct = true">изменить</span>
                </p>
                <div class="bg-primary-50 p-20" *ngIf="isChangeAct">
                    <p class="text-bold no-margin-bottom">Выберите акт:</p>
                    <div class="radio-block" *ngFor="let act of acts">
                        <input type="radio" class="radio" name="act" id="act{{act.guid}}" [checked]="currentAct && currentAct.guid === act.guid" (change)="selectAct(act)">
                        <label for="act{{act.guid}}">
                            <ng-container *ngIf="act.number">№ {{act.number}}</ng-container>
                            {{act.object.name}}. <span class="text-muted">Адрес объекта: </span>{{getAddressLine(act.object.address)}}
                        </label>
                    </div>
                </div>
                <p class="mb-20" *ngIf="entity.act" class="mb-20">
                    <span class="text-bold">Нарушение:</span>
                    <span class="ml-5" *ngIf="entity.violation">{{currentViolation.content}}</span>
                    <span class="anchor text-info text-size-small ml-5" *ngIf="!entity.violation && allowEdit" (click)="changeViolation()">не выбрано</span>
                    <span class="anchor text-info text-size-small ml-5" *ngIf="entity.violation && allowEdit" (click)="isChangeViolation = true">изменить</span>
                </p>
                <div class="bg-primary-50 p-20" *ngIf="isChangeViolation">
                    <p class="text-bold">Выберите нарушение:</p>
                    <div class="radio-block" *ngFor="let violation of currentAct.violations">
                        <input type="radio" class="radio" name="violation" id="violation{{violation.guid}}" [checked]="currentViolation && currentViolation.guid === violation.guid"
                               (change)="selectViolation(violation)">
                        <label for="violation{{violation.guid}}">
                            {{violation.content}}
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Номер</label>
                        <div class="form-group form-group-lg">
                            <input [(ngModel)]="entity.number" type="text" name="executionDeadline" class="form-control" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Дата вынесения</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker [(ngModel)]="entity.dateCreate" name="creationDate" [large]="true" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Срок исполнения</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker [(ngModel)]="entity.dateComplete" name="creationDate" [large]="true" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Срок предоставления извещения</label>
                            <evolenta-datepicker [(ngModel)]="entity.dateNoticeElimination" name="creationDate" [large]="true" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Место составления</label>
                    <div class="form-group form-group-lg">
                        <evolenta-address-gar
                                [large]="true"
                                [showIndex]="false"
                                [disabled]="!allowEdit"
                                [(ngModel)]="entity.createPlace"
                        ></evolenta-address-gar>
                    </div>
                </div>
                <div class="form-group">
                    <label>Содержание предписания <span class="text-danger">*</span></label>
                    <textarea class="form-control input-lg" [(ngModel)]="entity.content" rows="5" required></textarea>
                </div>
                <div class="form-group form-group-lg">
                    <label>Статус исполнения предписания</label>
                    <catalogue catalogName="executionStatus" name="executionStatus"
                               projection="dictionaryCodeName"
                               [(ngModel)]="entity.executionStatus" [priority]="true" [large]="true"></catalogue>
                </div>
            </div>

            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity" [task]="task" [allowEdit]="allowEdit"></appeal-entity-documents>
        </ng-container>

    </div>
</div>
