<div class="panel panel-preview"
     [ngClass]="{'panel-shadow m-20': mode == 'card'}">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            <span>{{task.name}}</span>
            <div>
                <label
                    class="badge text-uppercase"
                    [ngClass]="getStatus('background')">
                    {{getStatus('name')}}
                </label>
            </div>
        </h6>
        <div class="heading-elements">
            <button class="btn btn-default btn-xs no-border"
                    type="button"
                    *ngIf="mode !== 'card'"
                    [title]="localizations.common.edit"
                    (click)="edit()">
                <i class="icon-pencil"></i>
            </button>
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="navigateToTask()"
                    *ngIf="mode === 'card'">
                {{ localizations.common.proceed }}
            </button>
        </div>
    </div>


    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body mb-20">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="cursor-pointer mb-10 pb-20 text-bold"
                            (click)="panels.common = !panels.common">
                        <span>
                            {{ localizations.common.general_data }}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{'icon-arrow-down12' : panels.common, 'icon-arrow-left12' : !panels.common}"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': panels.common}">
                        <div class="flex flex-row mb-10"
                             *ngIf="appeal">
                            <div class="text-muted">
                                {{ localizations.tasks.control_and_supervision_event }}:
                            </div>
                            <div>
                                {{appeal.subservice.shortTitle}}
                                <a class="text-info anchor text-size-small"
                                   [routerLink]="['/ais', 'appeals', 'edit', appeal._id]">
                                    {{ localizations.common.proceed }}
                                </a>
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.tasks.priority }}:
                            </div>
                            <div>
                                <span class="badge text-uppercase"
                                      [ngClass]="taskService.getStatusByProperty(task.priority, 'background')">
                                    {{taskService.getStatusByProperty(task.priority, 'name')}}
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.tasks.start_date }}:
                            </div>
                            <div>
                                {{ task.followUp ? (task.followUp | date: 'mediumDate') : localizations.common.not_set }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.tasks.completion_date }}:
                            </div>
                            <div>
                                {{task.due ? (task.due | date: 'mediumDate') : localizations.common.not_set }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.tasks.assigned_to }}:
                            </div>
                            <div>
                                {{ task.assignee ? getAssigneeUser(task.assignee) : localizations.tasks.assignee_is_missing }}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
