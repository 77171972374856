import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestService, SessionService, StorageService } from '@evolenta/core';
import { CommonUtilities, PrintUtilities } from '@evolenta/utilities';
import { Config } from '../../../common/services/config';
import { ServicesService } from '../../subservices/services/services.service';
import { AppealService } from '../../knm/appeals/appeal.service';
import { AppealSaveService } from '../../knm/appeals/appeal-save.service';
import { PrintUtilityService } from '../../../common/services/print/print-utility.service';
import chain from 'lodash-es/chain';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import concat from 'lodash-es/concat';

@Injectable()
export class EventsService {
    public isProcessingCreateAppeal = false;
    public event;
    public priorities = [
        { id: 25, text: 'Низкий' },
        { id: 50, text: 'Обычный' },
        { id: 75, text: 'Высокий' },
        { id: 100, text: 'Критичный'},
    ];

    public eventTypesOld = [
        { id: 'citizenRequests',  code: 'citizenRequests', name: 'Обращение (жалоба)', show: true, additionalData: true },
        { id: 'signsViolations',  code: 'signsViolations', name: 'Инциденты ИОТ', show: false, additionalData: true },
        { id: 'motivatedPerformance', code: 'motivatedPerformance', name: 'Мотивированное представление', show: true, additionalData: false },
        { id: 'planInspection', code: 'planInspection', name: 'Плановая проверка ', show: false, additionalData: false },
        { id: 'unscheduledInspection', code: 'unscheduledInspection', name: 'Внеплановая проверка ', show: false, additionalData: false },
        { id: 'knmInitiation', code: 'knmInitiation', name: 'Инициация КНМ из другой КНМ ', show: false, additionalData: false },
        { id: 'orderHead', code: 'orderHead', name: 'Поручение руководителя ', show: true, additionalData: false },
        { id: 'gsnNotice', code: 'gsnNotice', name: 'Извещение ГСН', show: true, additionalData: true, kinds: [
            {code: 'gsnStartBuildingNotice', name: 'Извещение о начале строительства или реконструкции объекта капитального строительства'},
            {code: 'gsnEliminationViolationsNotice', name: 'Извещение об устранении нарушений при строительстве, реконструкции объекта капитального строительства'},
            {code: 'gsnEmergencyOccurrenceNotice', name: 'Извещение о возникновении аварийной ситуации при строительстве, реконструкции объекта капитального строительства'},
        ]},
    ];

    public eventTypes = [];

    public applicantTypes = [
        { id: 'individualEntrepreneur', text: 'Индивидуальный предприниматель' },
        { id: 'individual', text: 'Физическое лицо' },
        { id: 'legal', text: 'Организация' },
    ];

    public statuses = [
        {
            code: 'draft',
            name: 'Черновик',
            background: 'bg-purple-300',
        },
        {
            code: 'created',
            name: 'Новое',
            background: 'bg-info-300',
        },
        {
            code: 'process',
            name: 'В работе',
            background: 'bg-primary-300',
        },
        {
            code: 'beforeCompleted',
            name: 'Требуется обработка',
            background: 'bg-orange-300',
        },
        {
            code: 'completed',
            name: 'Завершено',
            background: 'bg-success-300',
        },
    ];

    public documents = [];

    public createFromEventId;

    public moduleBaseUrl;

    public users = [];

    public constructor(
        private restService: RestService,
        private http: HttpClient,
        private session: SessionService,
        private servicesService: ServicesService,
        private appealService: AppealService,
        private storage: StorageService,
        private saveAppealService: AppealSaveService,
        private printUtilityService: PrintUtilityService,
    ) {
    }

    public getStatusProperty(status, property) {
        if (status) {
            const find = this.statuses.find(item => item.code === status);
            if (find) {
                return find[property] ? find[property] : '';
            }
        }

        return '';
    }

    public prepareEventForSave(event) {
        return {
            ...event,
            tasks: event.tasks ? event.tasks.map(item => item.id) : [],
        };
    }

    public loadTasks(event) {
        if (event.tasks && event.tasks.length > 0) {
            const params = {
                search: {
                    search: [
                        {
                            field: 'tasks.id',
                            operator: 'in',
                            value: event.tasks,
                        },
                    ],
                },
            };

            return this.restService.search('camundaBusinessInfo', params).then(response => {
                    console.log(response);

                    return;
                    chain(response)
                        .map(item => item.tasks)
                        .flatten()
                        .value();
                },
            );
        } else {
            return Promise.resolve([]);
        }
    }

    public uploadDocuments(data, eventId) {
        const list = cloneDeep(data);
        let filesToUpload = [];
        if (this.documents.length === 0) {
            list.forEach(item => {
                filesToUpload = concat(filesToUpload, item.files);
            });
        } else {
            const oldFiles = chain(this.documents)
                .map(item => item.files)
                .flatten()
                .map(item => item.guid)
                .value();
            list.forEach(item => {
                item.files.forEach(file => {
                        if (!oldFiles.includes(file.guid)) {
                            filesToUpload.push(file);
                        }
                    },
                );
            });

        }
        const promises = [];
        filesToUpload = filesToUpload.filter(item => !item.id );
        filesToUpload.forEach(item => {
                promises.push(this.saveFile(item, eventId));
            },
        );

        return Promise.all(promises).then(results => {
                return list.map(item => {
                        const files = item.files.map(file => {
                                const index = filesToUpload.findIndex(v => v.guid === file.guid);

                                return index !== -1 ? results[index] : file;
                            },
                        );

                        return {
                            ...item,
                            files,
                        };
                    },
                );
            },
            () =>  Promise.reject('При сохранении файлов произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!'),
        );
    }

    public saveFile(file, id) {
        const uploadUrl = Config.server + Config.app + Config.api + 'storage/upload';

        const formData = new FormData();
        formData.append('entryName', 'events');
        formData.append('entryId', id);
        formData.append('file', file, file.name);

        return this.session.check().then((session: any) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + session.accessToken,
                    'Accept': '*',
                }),
            };

            return this.http.post(uploadUrl, formData, httpOptions).toPromise().then(savedFile => {
                    return savedFile;
                },
                () => {
                    return Promise.reject('При сохранении файлов процесса произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!');
                },
            );
        });
    }

    public async checkCompleteAllProcessInEvent(eventId) {
        const event: any = await this.restService.find('events', eventId, true, 'eventLinkedEntities');
        const checkItems = [];
        const promises = [];

        if (event.mainTaskId) {
            checkItems.push('task');
            promises.push(this.restService.find('camundaBusinessInfo', event.mainTaskId));
        }

        if (event.appealId) {
            checkItems.push('appeal');
            promises.push(this.restService.find('appeals', event.appealId, true, 'onlyStatus'));
        }

        if (promises.length) {
            const linkItems = await Promise.all(promises);
            let completed = true;
            checkItems.forEach((checkItem, idx) => {
                if (checkItem === 'task') {
                    const findTask = linkItems[idx].tasks.find(item => item.id === event.taskId);
                    if (findTask && findTask.status === 'ACTIVE') {
                        completed = false;
                    }
                }
                if (checkItem === 'appeal') {
                    const appeal = linkItems[idx];
                    if (appeal.status.code !== 'complete') {
                        completed = false;
                    }
                }
            });
            if (completed) {
                if ((event.citizenRequest && !event.isSendedAnswerToSed) || event.signsViolations) {
                    event.status = 'beforeCompleted';
                } else {
                    event.status = 'completed';
                }
                const eventForUpdate = {
                    _id: event._id,
                    status: event.status,
                    parentEntries : 'events',
                };

                await this.restService.update('events', eventForUpdate);
            }
        }
    }

    public createAppeal() {
        this.storage.removeItem('baseAppeal');
        const promises = [];
        promises.push(this.restService.search('subservices', {search: {search: [{field: 'serviceId', operator: 'eq', value: this.event.serviceId}]}}));
        if (this.event.subjects && this.event.subjects.length > 0) {
            promises.push(this.getSubjects(this.event.subjects));
        } else {
            promises.push([]);
        }
        if (this.event.objects && this.event.objects.length > 0) {
            promises.push(this.getObjects(this.event.objects));
        } else {
            promises.push([]);
        }

        const currentUser = this.storage.getItem('user');

        Promise.all(promises).then(async data => {
            const subservice = this.servicesService.correctSubserviceByCurrentUnit(data[0][0]);
            const subjects = data[1];
            const objects = data[2];
            const appeal = this.appealService.initAppeal();
            appeal.eventId = this.event._id;
            appeal.subservice = {};
            const initiatedSubservice = await this.appealService.initSubserviceInAppeal(cloneDeep(subservice));
            if (appeal.subservice && Object.keys(appeal.subservice).length) {
                throw new Error(`Попытка переназначить subservice с ${ appeal.subservice.id } на ${ initiatedSubservice.id }`);
            }

            appeal.subservice = initiatedSubservice;

            // Обработка объектов
            if (objects && objects.length) {
                objects.forEach(object => {
                    const addedObject: any = {
                        reestrId: object._id,
                        auid: object.auid,
                        guid: object.guid ? object.guid : CommonUtilities.GenerateGuid(),
                        type: object.type,
                        name: object.name,
                        shortName: object.shortName,
                        longitude: object.longitude,
                        latitude: object.latitude,
                        region: object.region,
                        address: object.address,
                    };
                    if (object.additionalData) {
                        addedObject.additionalData = object.additionalData;
                    }
                    appeal.objects.push(addedObject);
                });

                appeal.subservice.objects = appeal.objects.map(item => item.guid);
            }

            // Обработка субъектов
            const kind = subservice.objects.objectKinds[0];
            const kindInfo = {
                description: kind.description,
                guid: kind.guid,
                name: kind.name,
                type: kind.type,
            };
            let ulInfo;
            let ipInfo;
            let flInfo;
            const ul = kind.subKinds.find(item => item.specialTypeId === 'ulApplicant');
            if (ul) {
                ulInfo = {
                    headerOptions: ul.headerOptions,
                    shortHeaderOptions: ul.shortHeaderOptions,
                    name: ul.secondGroupName,
                    type: null,
                    specialTypeId: 'ulApplicant',
                };
            }
            const ip = kind.subKinds.find(item => item.specialTypeId === 'ipApplicant');
            if (ip) {
                ipInfo = {
                    headerOptions: ip.headerOptions,
                    shortHeaderOptions: ip.shortHeaderOptions,
                    name: ip.secondGroupName,
                    type: null,
                    specialTypeId: 'ipApplicant',
                };
            }
            const fl = kind.subKinds.find(item => item.specialTypeId === 'individualApplicant');
            if (fl) {
                flInfo = {
                    headerOptions: fl.headerOptions,
                    shortHeaderOptions: fl.shortHeaderOptions,
                    name: fl.secondGroupName,
                    type: null,
                    specialTypeId: 'individualApplicant',
                };
            }
            subjects.forEach(appealSubject => {
                const objectType = appealSubject.specialTypeId;
                if (objectType === 'ulApplicant' && ulInfo || objectType === 'ipApplicant' && ipInfo || objectType === 'individualApplicant' && flInfo) {
                    appealSubject.kind = cloneDeep(kindInfo);

                    if (objectType === 'ulApplicant') {
                        appealSubject.kind.subKind = ulInfo;
                    } else if (objectType === 'ipApplicant') {
                        appealSubject.kind.subKind = ipInfo;
                    } else {
                        appealSubject.kind.subKind = flInfo;
                    }
                    const objectDataInSubservice: any = {
                        guid: appealSubject.guid,
                    };
                    const subjectGroups = subservice.objects.objectGroups.filter(item => item.type === 'subjects');
                    if (subjectGroups.length === 1) {
                        const group = subjectGroups[0];
                        objectDataInSubservice.groupGuid = group.guid;
                        const subKinds = subservice.objects.objectKinds[0].subKinds.filter(item => group.objectKindGuids[0].subKindGuids.indexOf(item.guid) !== -1 && item.specialTypeId === appealSubject.specialTypeId);
                        if (subKinds.length === 1) {
                            const subKind = subKinds[0];
                            objectDataInSubservice.subKind = {
                                guid: subKind.guid,
                                firstGroupName: subKind.firstGroupName,
                                name: subKind.name,
                                type: subKind.type,
                            };
                            if (subKind.category && subKind.category.length === 1 && (!subKind.subCategory || subKind.subCategory.length === 0)) {
                                const category = subKind.category[0];
                                objectDataInSubservice.category = {
                                    guid: category.guid,
                                    name: category.name,
                                    xsd: category.xsd,
                                    selectedCategories: [{
                                        guid: category.guid,
                                        name: category.name,
                                        xsd: category.xsd,
                                    }],
                                };
                            }
                        }
                    }
                    const addedSubject = {
                        guid: appealSubject.guid ? appealSubject.guid : CommonUtilities.GenerateGuid(),
                        data: appealSubject.data,
                        kind: appealSubject.kind,
                        specialTypeId: appealSubject.specialTypeId,
                        header: appealSubject.header,
                        shortHeader: appealSubject.shortHeader,
                    };
                    if (appealSubject.specialTypeId === 'ulApplicant') {
                        addedSubject.data.organization.reestrId = appealSubject._id;
                        addedSubject.data.organization.auid = appealSubject.auid ? appealSubject.auid : null;
                    } else if (appealSubject.specialTypeId === 'ipApplicant') {
                        addedSubject.data.person.reestrId = appealSubject._id;
                        addedSubject.data.person.auid = appealSubject.auid ? appealSubject.auid : null;
                    }
                    appeal.subjects.push(addedSubject);
                    objectDataInSubservice.objects = appeal.objects.map(item => item.guid);
                    appeal.subservice.subjects.push(objectDataInSubservice);
                }
            });

            appeal.inspectors = [];
            let appealUser = currentUser;
            if (!this.event.executor) {
                const user = this.users.find(item => item._id === item.id);
                if (user) {
                    appealUser = user;
                }
            }
            appeal.inspectors.push({id: appealUser._id, name: appealUser.name, login: appealUser.login, position: appealUser.position});
            console.log('this.appeal', cloneDeep(appeal));
            this.saveAppealService.appeal = cloneDeep(appeal);
            this.saveAppealService.isCreateAppealMode = true;
            this.saveAppealService.moduleBaseUrl = this.moduleBaseUrl;

            return this.saveAppealService.saveAppeal().then(
                () => {
                    this.event.appealId = this.saveAppealService.appeal._id;
                    this.event.status = 'process';
                    this.restService.update('events', this.event).then();
                },
            );
        });
    }

    public getSubjects(subjects) {
        let promises = [];
        subjects.forEach(subject => {
            if (subject._id) {
                // ЮЛ или ИП
                promises.push(this.restService.find('subjectsKno', subject._id));
            } else {
                promises.push(Promise.resolve(subject));
            }
        });

        return Promise.all(promises).then(knoSubjects => {
            promises = [];
            knoSubjects.forEach(subject => {
                if (subject.globalSubjectId) {
                    promises.push(this.restService.find('subjects', subject.globalSubjectId));
                }
            });

            return Promise.all(promises).then(globalSubjects => {
                knoSubjects.forEach(knoSubject => {
                    if (knoSubject.globalSubjectId) {
                        const findGlobalSubject = globalSubjects.find(item => item._id === knoSubject.globalSubjectId);
                        if (findGlobalSubject) {
                            knoSubject.data = findGlobalSubject.data;
                            knoSubject.header = findGlobalSubject.header;
                            knoSubject.shortHeader = findGlobalSubject.shortHeader;
                            knoSubject.specialTypeId = findGlobalSubject.specialTypeId;
                        }
                    }
                });

                return Promise.resolve(knoSubjects);
            });
        });
    }

    public getObjects(objects) {
        let promises = [];
        objects.forEach(object => {
            promises.push(this.restService.find('objectsKno', object._id));
        });

        return Promise.all(promises).then(knoObjects => {
            promises = [];
            knoObjects.forEach(object => {
                if (object.globalObjectId) {
                    promises.push(this.restService.find('objects', object.globalObjectId));
                }
            });

            return Promise.all(promises).then(globalObjects => {
                knoObjects.forEach(knoObject => {
                    if (knoObject.globalObjectId) {
                        const findGlobalObject = globalObjects.find(item => item._id === knoObject.globalObjectId);
                        if (findGlobalObject) {
                            knoObject.name = findGlobalObject.name;
                            knoObject.shortName = findGlobalObject.shortName;
                            knoObject.region = findGlobalObject.region;
                            knoObject.longitude = findGlobalObject.longitude;
                            knoObject.latidude = findGlobalObject.latitude;
                            knoObject.address = findGlobalObject.address;
                            if (findGlobalObject.additionalData) {
                                knoObject.additionalData = findGlobalObject.additionalData;
                            }
                        }
                    }
                });

                return Promise.resolve(knoObjects);
            });
        });
    }

    public prepareEventData(data) {
        let eventData: any = {
            Name: get(data, 'name', ''),
            CreatedAt: data.dateCreation ? this.printUtilityService.formatDateForPrintForm(data.dateCreation, false) : '',
            Type: get(data, 'eventType.name', ''),
            Kind: get(data, 'eventKind.name', ''),
            Executor: get(data, 'executor.name', ''),
            Subjects: (data.subjects || []).map(s => (this.prepareSubjectData(s))),
            Objects: (data.objects || []).map(o => (this.prepareObjectData(o))),
            Responsibles: (data.responsibles || []).map(r => (this.prepareUserData(r))),
        };

        if (data.gsnNotice) {
            eventData = {
                ...eventData,
                GsnNotice: {
                    Notice: {
                        Number: get(data, 'gsnNotice.numberNotice', ''),
                        Date: data.gsnNotice.dateNotice ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.dateNotice, false) : '',
                        Place: data.gsnNotice.placeNotice ? PrintUtilities.PrintAddressAsLine(data.gsnNotice.placeNotice, true) : '',
                        Case: get(data, 'gsnNotice.caseNotice', ''),
                    },
                    Object: {
                        Name: get(data, 'gsnNotice.nameObject', ''),
                        Spec: get(data, 'gsnNotice.specObject', ''),
                        Phase: get(data, 'gsnNotice.phaseObject', ''),
                        Number: get(data, 'gsnNotice.numberNotice', ''),
                        Address: data.gsnNotice.addressObject ? PrintUtilities.PrintAddressAsLine(data.gsnNotice.addressObject, true) : '',
                    },
                    Building: {
                        PeriodBegin: data.gsnNotice.periodBuildingBegin ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.periodBuildingBegin, false) : '',
                        PeriodEnd: data.gsnNotice.periodBuildingEnd ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.periodBuildingEnd, false) : '',
                    },
                    Permission: {
                        Number: get(data, 'gsnNotice.numberPermission', ''),
                        Date: data.gsnNotice.datePermission ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.datePermission, false) : '',
                        Issued: get(data, 'gsnNotice.issuedPermission', ''),
                        PeriodBegin: data.gsnNotice.periodPermissionBegin ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.periodPermissionBegin, false) : '',
                        PeriodEnd: data.gsnNotice.periodPermissionEnd ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.periodPermissionEnd, false) : '',
                    },
                    Expertise: {
                        Number: get(data, 'gsnNotice.numberExpertise', ''),
                        Date: data.gsnNotice.dateExpertise ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.dateExpertise, false) : '',
                        Issued: get(data, 'gsnNotice.issuedExpertise', ''),
                    },
                    Ecology: {
                        Number: get(data, 'gsnNotice.numberEcology', ''),
                        Date: data.gsnNotice.dateEcology ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.dateEcology, false) : '',
                        Issued: get(data, 'gsnNotice.issuedEcology', ''),
                        PeriodBegin: data.gsnNotice.periodEcologyBegin ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.periodEcologyBegin, false) : '',
                        PeriodEnd: data.gsnNotice.periodEcologyEnd ? this.printUtilityService.formatDateForPrintForm(data.gsnNotice.periodEcologyEnd, false) : '',
                    },
                },
            };
        }

        return {
            Event: eventData,
        };

    }

    public prepareObjectData(objectData) {
        return {
            Name: get(objectData, 'name', ''),
            Address: objectData.address ? PrintUtilities.PrintAddressAsLine(objectData.address, true) : '',
            Type: {
                Code: get(objectData, 'type.code', ''),
                Name: get(objectData, 'type.name', ''),
            },
        };
    }

    public prepareSubjectData(subject) {
        let result: any = {
            Header: get(subject, 'header', ''),
            ShortHeader: get(subject, 'shortHeader', ''),
            SpecialTypeId: get(subject, 'specialTypeId', ''),
        };

        if (get(subject, 'data.organization', null)) {
            result = {
                ...result,
                Name: get(subject, 'data.organization.name', ''),
                ShortName: get(subject, 'data.organization.shortName', ''),
                Inn: get(subject, 'data.organization.inn', ''),
                Kpp: get(subject, 'data.organization.kpp', ''),
                Ogrn: get(subject, 'data.organization.ogrn', ''),
                RegistrationAddress: subject.data.organization.registrationAddress
                    ? PrintUtilities.PrintAddressAsLine(subject.data.organization.registrationAddress, true)
                    : '',
            };
        }

        if (get(subject, 'data.person', null)) {
            result = {
                ...result,
                LastName: get(subject, 'data.person.lastName', ''),
                FirstName: get(subject, 'data.person.firstName', ''),
                MiddleName: get(subject, 'data.person.middleName', ''),
                Birthday: subject.data.person.birthday
                    ? this.printUtilityService.formatDateForPrintForm(subject.data.person.birthday, false)
                    : '',
                BirthPlace: subject.data.person.birthPlace
                    ? PrintUtilities.PrintAddressAsLine(subject.data.person.birthPlace, true)
                    : '',
                DocumentType: get(subject, 'data.person.documentType.text', ''),
                DocumentNumber: get(subject, 'data.person.documentNumber', ''),
                DocumentSeries: get(subject, 'data.person.documentSeries', ''),
                DocumentIssuer: get(subject, 'data.person.documentIssuer.name', ''),
                DocumentIssueDate: subject.data.person.documentIssueDate
                    ? this.printUtilityService.formatDateForPrintForm(subject.data.person.documentIssueDate, false)
                    : '',
                Inn: get(subject, 'data.person.inn', ''),
                Ogrn: get(subject, 'data.person.ogrn', ''),
                Mobile: get(subject, 'data.person.mobile', ''),
                Snils: get(subject, 'data.person.snils', ''),
                Sex: get(subject, 'data.person.sex', ''),
            };
        }

        return result;
    }

    public prepareUserData(user) {
        return {
            Name: get(user, 'name', ''),
            Login: get(user, 'login', ''),
            Position: get(user, 'position', ''),
        };
    }
}
