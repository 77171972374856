<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button" class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            (click)="save()">
        <b>
            <i class="icon-checkmark-circle"
              [ngClass]="{'flash': isLoading}"></i>
        </b>
        {{ localizations.common.save }}
    </button>
    <div class="ml-20 navbar-title pt-20">
        <h4>
            <ng-container *ngIf="!reglament">
                {{ getFunctionName() }}
                <ng-container *ngIf="standard.auid">
                    № {{standard.auid}}
                </ng-container>
                <label class="badge text-uppercase ml-15 mt-5 bg-{{standard.status.theme}}-300"
                       *ngIf="standard.status">
                    {{standard.status.name}}
                </label>
            </ng-container>

            <ng-container *ngIf="reglament">
                {{ localizations.standards.reglament_setup }}
                <label class="badge text-uppercase ml-15 mt-5 bg-{{reglament.status.theme}}-300"
                       *ngIf="reglament.status">
                    {{reglament.status.name}}
                </label>
            </ng-container>
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Выбор мета-регламента -->
    <div class="panel-main wide"  *ngIf="isProcessSelectingMetaReglament">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding" >
            <div class="bg-primary-50 p-20">
                <p class="text-bold no-margin-bottom">
                    {{ localizations.standards.select_meta_reglament }}:
                </p>
                <div class="radio-block"
                     *ngFor="let reglament of metaReglaments">
                    <input id="metaReglament{{reglament.code}}"
                           class="radio"
                           name="metaReglament"
                           type="radio"
                           (change)="selectMetaReglament(reglament)">
                    <label for="metaReglament{{reglament.code}}">
                        {{reglament.name}}
                    </label>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>

    <ng-container *ngIf="!isProcessSelectingMetaReglament && metaReglament">
        <common-standard-menu  [activeTab]="activeTab"
                               [actions]="actions"
                               [kcrId]="kcrId"
                               [standardId]="standard._id"
                               [status]="standardStatus"
                               (onActivateTab)="activeTab = $event"
                               (onChangeStatus)="initStatusData()"
                               (onStartBpmnProcess)="afterStartProcess($event)"
                               #standardMenu>
        </common-standard-menu>
        <common-standard-blocks [activeTab]="activeTab"
                                (onChangeStatus)="initStatusData()"
                                (onSignFile)="signFile($event)"
                                *ngIf="activeTab">
        </common-standard-blocks>
    </ng-container>
</div>



