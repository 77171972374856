<ng-template #modal>
    <div class="modal-header">
        <h3 class="modal-title pull-left">
            {{title}}
        </h3>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="hideModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{text}}
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="hideModal()">
            Нет
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="confirm()">
            Да
        </button>
    </div>
</ng-template>
