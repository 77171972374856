import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonStandardsService } from '../common-standards.service';
import { CommonUtilities } from '@evolenta/utilities';
import { TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-entities',
    templateUrl: './common-standard-entities.component.html',
})
export class CommonStandardEntitiesComponent implements OnInit {
    @Input() public xsdData;
    @Input() public allowEdit = true;

    public metaData;
    public activeData: any = {};

    public editedEntity;
    public localizations;

    @Output() public onEditXsd = new EventEmitter<object>();

    public constructor(
        public standardsService: CommonStandardsService,
        public translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.metaData = this.standardsService.metaReglament.blocks.find((item) => item.code === 'entities');
        this.activeData = {
            code: 'entities',
            name: this.metaData.entitiesData.name,
            allowEditInReglament: !this.metaData.entitiesData.allowEditInReglament,
        };
        this.standardsService.initBlockData(this.activeData);

        if (this.xsdData) {
            this.editedEntity = this.xsdData.data;
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public add() {
        if (!this.activeData.entity.entities) {
            this.activeData.entity.entities = [];
        }
        this.editedEntity = {
            guid: CommonUtilities.GenerateGuid(),
        };
    }

    public edit(entity) {
        this.editedEntity = cloneDeep(entity);
    }

    public delete(entity) {
        const findIndex = this.activeData.entity.entities.findIndex((item) => item.guid === entity.guid);
        this.activeData.entity.entities.splice(findIndex, 1);
        this.deleteEntityLinkedData(entity.guid);
        this.standardsService.saveEntity(this.activeData);
    }

    public apply() {
        const findIndex = this.activeData.entity.entities.findIndex((item) => item.guid === this.editedEntity.guid);
        if (findIndex === -1) {
            this.activeData.entity.entities.push(this.editedEntity);
        } else {
            this.activeData.entity.entities[findIndex] = this.editedEntity;
        }
        this.editedEntity = null;
        this.standardsService.saveEntity(this.activeData);
    }

    public cancel() {
        this.editedEntity = null;
    }

    public editXsd(data) {
        this.onEditXsd.emit(data);
    }

    public deleteEntityLinkedData(entityGuid) {
        if (this.activeData.entity.process) {
            this.standardsService.deleteElementLinksInProcess('entities', entityGuid, this.activeData.entity.process);
        }
    }
}
