import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccessService, ToasterService, TranslateService } from '@evolenta/core';
import { ModalDirective } from 'ngx-bootstrap';
import { UtilityService } from '../../../common/services/utility.service';
import { Permission } from '../../../common/services/permission';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: [
        '../../elements-list.css',
    ],
})
export class SettingsComponent implements OnInit, AfterViewInit {
    public settings = [];
    public setting = null;
    public model = 'settings';
    public buttonUpdateRequestDisabled = false;
    public buttonUpdateMetaDisabled = false;
    public buttonUpdateCalendarDisabled = false;

    public canCreate = this.access.hasAccess([Permission.Setting_Create]);
    public canUpdate = this.access.hasAccess([Permission.Setting_Update]);
    public canDelete = this.access.hasAccess([Permission.Setting_Delete]);

    public localizations;

    public colDef = [];
    public showGrid = false;

    @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;

    public constructor(
        private router: Router,
        private toaster: ToasterService,
        private location: Location,
        private utility: UtilityService,
        private access: AccessService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations(): void {
        this.translate.get(
            [
                'common',
                'settings',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData(): void {
        this.colDef = [
            {
                field: 'auid',
                headerName: 'ID',
                type: 'auidColumn',
                width: 20,
                filter: false,
            },
            {
                field: 'name',
                headerName: this.localizations.common.code,
            },
            {
                field: 'description',
                headerName: this.localizations.common.name,
            },
            {
                extType: 'buttons',
                buttons: {
                    updateBtn: {
                        target: 'admin/settings/edit',
                        permissionEdit: this.access.hasAccess([Permission.Setting_Update]),
                        permissionView: this.access.hasAccess([Permission.Settings_Search]),
                    },
                    deleteBtn: {
                        permission: this.access.hasAccess([Permission.Setting_Delete]),
                    },
                },
            },
        ];
    }

    public ngAfterViewInit(): void {
        setTimeout(() => { this.showGrid = true; }, 100);
    }

    public getNewSettings(): void {
        this.router.navigate(['/admin/settings/create']);
    }

    public back(): void {
        this.location.back();
    }

    public async updateRequestSper(): Promise<void> {
        this.buttonUpdateRequestDisabled = true;

        try {
            await this.utility.updateRequestSper();
            this.toaster.success(this.localizations.settings.notifications.successfully_updated);
        } catch (error) {
            this.toaster.error(this.localizations.settings.notifications.an_error_ocurred + error);
        }

        this.buttonUpdateRequestDisabled = false;
    }

    public async updateMetaSper(): Promise<void> {
        this.buttonUpdateMetaDisabled = true;

        try {
            await this.utility.updateMetaSper();
            this.toaster.success(this.localizations.settings.notifications.successfully_updated);
        } catch (error) {
            this.toaster.error(this.localizations.settings.notifications.an_error_ocurred + error);
        }

        this.buttonUpdateMetaDisabled = false;
    }

    public async updateCalendarSper(): Promise<void> {
        this.buttonUpdateCalendarDisabled = true;

        try {
            await this.utility.updateCalendarSper();
            this.toaster.success(this.localizations.settings.notifications.successfully_updated);
        } catch (error) {
            this.toaster.error(this.localizations.settings.notifications.an_error_ocurred + error);
        }

        this.buttonUpdateCalendarDisabled = false;
    }
}
