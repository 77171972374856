import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService, TranslateService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'object-type-preview',
    templateUrl: 'object-type-preview.component.html',
})
export class ObjectTypePreviewComponent implements OnInit {
    @Input() public objectType;

    public kndKinds = [];
    public panels = {
        common: true,
        kndKinds: true,
    };

    public moduleBaseUrl;
    public localizations;

    public constructor(
        private route: ActivatedRoute,
        private restService: RestService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        const kndKindsToFound = (this.objectType.kndKinds || [])
            .map(k => k.id)
            .filter(k => k);
        if (kndKindsToFound.length > 0) {
            const search = {search: {search: [{field: '_id', operator: 'in', value: kndKindsToFound}]}};
            this.restService
                .search('nsiErpKnmKinds', search)
                .then(result => {
                    this.kndKinds = _.cloneDeep(result);
                });
        }
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'objects',
                'knd-kinds',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getKndKind(id) {
        return this.kndKinds.find(k => k._id === id);
    }
}
