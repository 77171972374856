import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { ProcessRegistryService } from './process-registry/process-registry.service';
import { ProcessRegistryResolve } from './process-registry/process-registry.resolve';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { BpmnProcessEditComponent } from './process-registry/components/bpmn-process-edit/bpmn-process-edit.component';
import { BpmnTasksComponent } from './process-registry/components/bpmn-tasks/bpmn-tasks.component';
import { BpmnTaskListComponent } from './process-registry/components/bpmn-tasks/bpmn-task-list/bpmn-task-list.component';
import { PreviewProcessComponent } from './process-registry/components/preview-process/preview-process.component';
import { BpmnCardComponent } from './bpmn-card/bpmn-card.component';
import { BpmnCardTasksComponent } from './bpmn-card/bpmn-card-tasks/bpmn-card-tasks.component';
import { ProcessService } from './process.service';
import { BpmnLinksComponent } from './bpmn-links/bpmn-links.component';
import { EntityBpmnSettingsComponent } from './entity-bpmn-settings/entity-bpmn-settings.component';
import { EntityProcessTasksComponent } from './entity-process-tasks/entity-process-tasks.component';
import { EntityProcessTaskComponent } from './entity-process-tasks/entity-process-task.component';
import { BpmnProcessEditAdditionalDataComponent } from './process-registry/components/bpmn-process-edit/additional-data/bpmn-process-edit-additional-data.component';
import { BpmnProcessEditDocumentsDataComponent } from './process-registry/components/bpmn-process-edit/documents-data/bpmn-process-edit-documents-data.component';
import { DeleteConfirmationModalModule } from '../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        RouterModule,
        SharedComponentsModule,
        InlineSVGModule,
        DeleteConfirmationModalModule,
        BreadcrumbsModule,
    ],
    declarations: [
        BpmnProcessEditComponent,
        BpmnTasksComponent,
        BpmnTaskListComponent,
        PreviewProcessComponent,
        BpmnCardComponent,
        BpmnCardTasksComponent,
        BpmnLinksComponent,
        EntityBpmnSettingsComponent,
        EntityProcessTasksComponent,
        EntityProcessTaskComponent,
        BpmnProcessEditAdditionalDataComponent,
        BpmnProcessEditDocumentsDataComponent,
    ],
    exports: [
        BpmnProcessEditComponent,
        BpmnCardComponent,
        BpmnLinksComponent,
        EntityBpmnSettingsComponent,
        EntityProcessTasksComponent,
        EntityProcessTaskComponent,
    ],
    providers: [
        ProcessRegistryService,
        ProcessRegistryResolve,
        ProcessService,
    ],
    entryComponents: [
        PreviewProcessComponent,
    ],
})
export class ProcessesModule {
}
