import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonStandardFormFieldsSettingComponent } from '../../standard-form-fields-setting/common-standard-form-fields-setting.component';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-subjects-common',
    templateUrl: './common-standard-subjects-common.component.html',
})
export class CommonStandardSubjectsCommonComponent implements OnInit {
    @Input() public data;
    @Input() public metaData;
    @Output() public onEditXsd = new EventEmitter<any>();

    public subjectTypes = [
        {
            code: 'ulApplicant',
            name: 'Юридическое лицо',
            shortCode: 'ul',
        },
        {
            code: 'ipApplicant',
            name: 'Индивидуальный предприниматель',
            shortCode: 'ip',
        },
        {
            code: 'individualApplicant',
            name: 'Физическое лицо',
            shortCode: 'fl',
        },
        {
            code: 'foreignUlApplicant',
            name: 'Иностранное юридическое лицо',
            shortCode: 'ful',
        },
    ];

    public titles = [
        {
            name: 'Название (множественное значение)',
            code: 'plural',
        },
        {
            name: 'Название (единичное значение)',
            code: 'single',
        },
    ];

    @ViewChild('fieldsSettingsModal', { static: false }) public fieldsSettingsModal: TemplateRef<any>;
    @ViewChild('fieldsSettingComponent', { static: false })
    public fieldsSettingComponent: CommonStandardFormFieldsSettingComponent;
    @ViewChild('fieldsRequirementsSettingsModal', { static: false })
    public fieldsRequirementsSettingsModal: TemplateRef<any>;
    public modalRef: BsModalRef;
    public usedSubjectTypeData;
    public usedSubjectType;
    public usedSubjectTypeFields;
    public usedSubjectTypeFieldRequirements;
    public editedSubjectTypeFieldRequirements;

    public savedPlace;

    public permissionParams = [
        {
            code: 'add',
            name: 'Добавление (выбор из реестра)',
        },
        {
            code: 'edit',
            name: 'Редактирование',
            isSettingEditedFields: true,
        },
        {
            code: 'remove',
            name: 'Удаление',
        },
        {
            code: 'editTypeXsd',
            name: 'Редактирование данных на тип субъекта',
        },
    ];

    public constructor(private modalService: BsModalService) {}

    public ngOnInit() {
        if (!this.data.entity.subjectsCommon.titles) {
            this.data.entity.subjectsCommon.titles = {};
        }
        if (this.metaData.commonData.allowSubjectTypesSettings) {
            this.data.entity.subjectsCommon.changeSubject = true;
        }
        this.savedPlace = this.data.entity.subjectsCommon;
        if (this.metaData.commonData.allowCreateMainXsd) {
            this.permissionParams.push({
                code: 'editMainXsd',
                name: 'Редактирование основного блока дополнительных данных',
            });
        }
        if (this.metaData.commonData.allowCreateXsd) {
            this.permissionParams.push({
                code: 'editXsd',
                name: 'Редактирование дополнительных данных',
            });
        }
        this.savedPlace.afterAdd = true;
        this.savedPlace.afterSelect = true;
    }

    public editXsd(field, savedPlace = null) {
        this.onEditXsd.emit({ field: field, savedPlace: savedPlace });
    }

    public isSelectSubjectType(type, returnObject = false) {
        if (!this.data.entity.subjectsCommon.subjectTypes) {
            this.data.entity.subjectsCommon.subjectTypes = [];
        }
        const find = this.data.entity.subjectsCommon.subjectTypes.find((item) => item.code === type.code);
        return returnObject ? find : !!find;
    }

    public toggleSubjectType(type) {
        const findIndex = this.data.entity.subjectsCommon.subjectTypes.findIndex((item) => item.code === type.code);
        if (findIndex === -1) {
            this.data.entity.subjectsCommon.subjectTypes.push(type);
        } else {
            this.data.entity.subjectsCommon.subjectTypes.splice(findIndex, 1);
        }
    }

    public toggleSubjectSelectFromRegistry(type) {
        this.isSelectSubjectType(type, true).allowSelectFromRegistry = !this.isSelectSubjectType(type, true)
            .allowSelectFromRegistry;
        if (!this.isSelectSubjectType(type, true).allowSelectFromRegistry) {
            this.isSelectSubjectType(type, true).selectOnlyFromGlobalRegistry = false;
        }
    }

    public setRegisterFields(type) {
        this.usedSubjectType = this.convertType(type.code);
        this.usedSubjectTypeData = this.data.entity.subjectsCommon.subjectTypes.find((item) => item.code === type.code);
        if (!this.usedSubjectTypeData.fields) {
            this.usedSubjectTypeData.fields = [];
        }
        this.usedSubjectTypeFields = cloneDeep(this.usedSubjectTypeData.fields);
        this.modalRef = this.modalService.show(this.fieldsSettingsModal, { backdrop: 'static', class: 'modal-lg' });
    }

    public setFieldsRequirements(type) {
        const findData = this.savedPlace.subjectTypes.find((item) => item.code === type.code);
        this.usedSubjectType = this.convertType(type.code);
        if (!findData.fieldsRequirements) {
            this.usedSubjectTypeFieldRequirements = { subjectType: this.usedSubjectType };
        } else {
            this.usedSubjectTypeFieldRequirements = cloneDeep(findData.fieldsRequirements);
        }
        this.editedSubjectTypeFieldRequirements = findData;
        this.modalRef = this.modalService.show(this.fieldsRequirementsSettingsModal, {
            backdrop: 'static',
            class: 'modal-lg',
        });
    }

    public convertType(type) {
        switch (type) {
            case 'ulApplicant':
                return 'ul';
            case 'individualApplicant':
                return 'fl';
            case 'ipApplicant':
                return 'ip';
            case 'foreignUlApplicant':
                return 'ful';
        }
    }

    public closeModal() {
        this.modalRef.hide();
    }

    public applyFields(fieldsSettingComponent) {
        this.usedSubjectTypeData.fields = fieldsSettingComponent.setting.fields;
        this.modalRef.hide();
    }

    public applyFieldsRequirements() {
        this.editedSubjectTypeFieldRequirements.fieldsRequirements = this.usedSubjectTypeFieldRequirements;
        this.modalRef.hide();
    }

    public getSubjectTypeName() {
        return this.subjectTypes.find((item) => item.shortCode === this.usedSubjectType).name;
    }
}
