import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, SelectionService, TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'bpmn-task-list',
    templateUrl: './bpmn-task-list.component.html',
})
export class BpmnTaskListComponent implements OnInit {
    @Input() public tasks;
    @Input() public type;
    @Input() public process;
    @Input() public processType;

    public dmnProcesses = {};
    public localizations;

    public constructor(
        private selectionService: SelectionService,
        private router: Router,
        private rest: RestService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        if (this.type === 'businessRuleTasks') {
            if (this.selectionService.isProcessSelect) {
                console.log(cloneDeep(this.selectionService.transferObjectChild), this.tasks);
                const processingTask = this.tasks.find(
                    (item) => item.guid === this.selectionService.transferObjectChild.guid
                );
                if (this.selectionService.selectedItems.length > 0) {
                    processingTask.dmn = this.selectionService.selectedItems[0]._id;
                    this.dmnProcesses[processingTask.dmn] = this.selectionService.selectedItems[0];
                } else {
                    processingTask.dmn = null;
                }
                this.selectionService.clearData();
            } else {
                this.processingDmn();
            }
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'process-registry']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public processingDmn() {
        const dmnIds = this.tasks.filter((item) => item.dmn).map((item) => item.dmn);
        if (dmnIds.length > 0) {
            this.rest
                .search('sperDmn', { search: { search: [{ field: '_id', operator: 'in', value: dmnIds }] } })
                .then((dmn) => {
                    dmn.forEach((dmnItem) => {
                        this.dmnProcesses[dmnItem._id] = dmnItem;
                    });
                });
        }
    }

    public selectDmn(task) {
        this.selectionService.isProcessSelect = true;
        this.selectionService.selectedItems = task.dmn ? [this.dmnProcesses[task.dmn]] : [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectDmn';
        this.selectionService.transferObject = this.process;
        this.selectionService.transferObjectChild = task;
        this.selectionService.selectType = 'one';

        this.router.navigate(['processes', 'business-rules']);
    }
}
