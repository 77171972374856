import { Injectable } from '@angular/core';

@Injectable()
export class ClientModuleData {
    // Статусы завершения операции эквайринга
    public static payResultStatuses = {
        '000': 'УСПЕШНО',
        '001': 'УСПЕШНО',
        '002': 'УСПЕШНО',
        '003': 'УСПЕШНО',
        '004': 'УСПЕШНО',
        '005': 'УСПЕШНО',
        '006': 'УCПЕШНО',
        '007': 'УСПЕШНО',
        '008': 'УСПЕШНО',
        '009': 'УСПЕШНО',
        '050': 'ОТКАЗ',
        '051': 'КАРТА ПРОСРОЧЕНА',
        '052': 'ЛИМИТ ПИН ИСЧЕРПАН',
        '053': 'ЧУЖАЯ АДМ. КАРТА',
        '054': 'НЕТ БЛОКА СЕКРЕТНОСТИ',
        '055': 'НЕВЕРНАЯ ОПЕРАЦИЯ',
        '056': 'ОПЕРАЦИЯ НЕ ПОДДЕРЖИВАЕТСЯ БАНКОМ',
        '057': 'КАРТА ПОТЕРЯНА / УКРАДЕНА',
        '058': 'НЕВЕРНЫЙ СТАТУС КАРТЫ',
        '059': 'КАРТА ОГРАНИЧЕНА',
        '060': 'НЕТ СЧЕТА В ФАЙЛЕ',
        '061': 'НЕ НАЙДЕНА ЗАПИСЬ В ФАЙЛЕ',
        '062': 'ОШИБКА ЗАПИСИ',
        '063': 'НЕВЕРНЫЙ ТИП АВТОРИЗАЦИИ',
        '064': 'ПЛОХАЯ ДОРОЖКА КАРТЫ',
        '065': 'КОРРЕКЦИЯ ЗАПРЕЩЕНА',
        '066': 'ОШИБКА ВЫДАЧИ ДЕНЕГ',
        '067': 'НЕКОРРЕКТНАЯ ДАТА ОПЕРАЦИИ',
        '068': 'ОШИБКА ФАЙЛА',
        '069': 'НЕВЕРНЫЙ ФОРМАТ СООБЩЕНИЯ',
        '070': 'НЕ НАЙДЕНА ЗАПИСЬ',
        '071': 'НЕКОРРЕКТНЫЙ ТИП АВТОРИЗАЦИИ',
        '072': 'КАРТА В ЧЕРНОМ СПИСКЕ',
        '073': 'НЕКОРРЕКТНОЕ ПОЛЕ АВТОРИЗАЦИИ',
        '074': 'НЕВОЗМОЖНО АВТОРИЗОВАТЬ',
        '075': 'НЕКОРРЕКТНАЯ ДЛИНА НОМЕРА',
        '076': 'НЕДОСТАТОЧНО СРЕДСТВ',
        '077': 'ДОСТИГНУТ ЛИМИТ ПРЕДАВТОРИЗАЦИИ',
        '078': 'ДУБЛИРОВАННАЯ ТРАНЗАКЦИЯ',
        '079': 'ПРЕВЫШЕНИЕ ОНЛАЙН ЛИМИТА ВОЗВРАТА',
        '080': 'ПРЕВЫШЕНИЕ ОФФЛАЙН  ЛИМИТА ВОЗВРАТА',
        '081': 'ПРЕВЫШЕНИЕ КРЕДИТА ВОЗВРАТА',
        '082': 'ПРЕВЫШЕНИЕ ЧИСЛА ПОЛЬЗОВАНИЙ',
        '083': 'ПРЕВЫШЕНИЕ ЛИМИТА СУММЫ',
        '084': 'КАРТА НЕДЕЙСТВИТЕЛЬНА',
        '085': 'НЕВОЗМОЖНО ВЫДАТЬ БАЛАНС',
        '086': 'ПРЕВЫШЕНИЕ ОФФЛАЙН ЛИМИТА',
        '087': 'ПРЕВЫШЕНИЕ ЧИСЛА ИСПОЛЬЗОВАНИЙ КРЕДИТА',
        '088': 'ПОВОНИТЕ В БАНК',
        '089': 'КАРТА НЕ АКТИВНА',
        '090': 'НЕТ МЕСТА В ПРФ ФАЙЛЕ',
        '091': 'ПРОБЛЕМЫ С НЕГ ФАЙЛОМ',
        '092': 'СУММА СНЯТИЯ ДЕНЕГ МАЛА',
        '093': 'АРЕСТОВАННЫЙ СЧЕТ',
        '094': 'ПРЕВЫШЕНИЕ ЛИМИТА',
        '095': 'ПРЕВЫШЕНИЯ ЛИМИТА',
        '096': 'ТРЕБУЕТСЯ ПИН',
        '097': 'ОШИБКА КОНТР. СУММЫ',
        '098': 'НЕТ ДОСТУПА К ПРОЦЕССИНГУ',
        '099': 'ПЛОХОЙ ПБФ',
        '100': 'ПОЗВОНИТЕ В БАНК',
        '130': 'ПОЗВОНИТЕ В БАНК',
        '131': 'ПОЗВОНИТЕ В БАНК',
        '132': 'ПОЗВОНИТЕ В БАНК',
        '133': 'ПОЗВОНИТЕ В БАНК',
        '134': 'ПОЗВОНИТЕ В БАНК',
        '150': 'ПРОДАВЕЦ НЕ ЗАРЕГИСТРИРОВАН',
        '200': 'НЕПРАВИЛЬНЫЙ СЧЕТ',
        '201': 'НЕПРАВИЛЬНЫЙ ПИН, ПОВТОРИТЕ',
        '202': 'СУММА СНЯТИЯ МАЛА',
        '203': 'ТРЕБУЕТСЯ АДМ. КАРТА',
        '204': 'ОТКАЗ, ПОЗВОНИТЕ В БАНК',
        '205': 'НЕКОРРЕКТНАЯ СУММА',
        '206': 'НЕ НАЙДЕНА ЗАПИСЬ В КАФ ФАЙЛЕ',
        '207': 'НЕКОРРЕКТНАЯ ДАТА ОПЕРАЦИИ, ПОВТОРИТЕ',
        '208': 'НЕКОРРЕКТНЫЙ СРОК ДЕЙСТВИЯ КАРТЫ',
        '209': 'НЕВЕРНЫЙ КОД ОПЕРАЦИИ',
        '251': 'НЕХВАТКА СРЕДСТВ',
        '252': 'ДЕБЕТЫЕ КАРТЫ НЕ ПОДДЕРЖИВАЮТСЯ',
        '400': 'ОШИБКА ARQC',
        '401': 'ОШИБКА БЕЗОПАСНОСТИ',
        '402': 'ОШИБКА БЕЗОПАСНОСТИ',
        '403': 'НЕ НАЙДЕН КЛЮЧ СМАРТ КАРТЫ',
        '404': 'ОШИБКА ПРОВЕРКИ ATC',
        '405': 'ОТКАЗ ПРИ ПРОВЕРКЕ CVR',
        '406': 'ОТКАЗ ПРИ ПРОВЕРКЕ TVR',
        '407': 'ОТКАЗ ПРИ ПРОВЕРКЕ ПРИЧИНЫ ОНЛАЙН',
        '408': 'ОТКАЗ ПРИ ФОЛБЭК',
        '800': 'ОШИБКА ФОРМАТА',
        '801': 'ОШИБКА В ДАННЫХ',
        '802': 'ОШИБКА В КОДЕ КАССИРА',
        '809': 'ОШИБОЧНАЯ ОПЕРАЦИЯ ЗАКРЫТИЯ',
        '810': 'ТАЙМАУТ ТРАНЗАКЦИИ',
        '811': 'СИСТЕМНАЯ ОШИБКА',
        '820': 'ОШИБОЧНЫЙ НОМЕР ТЕРМИНАЛА',
        '821': 'ОШИБОЧНАЯ ДЛИНА ПАКЕТА',
        '870': 'ПОЧТА ДОСТАВЛЕНА',
        '871': 'ПОЧТА СОХРАНЕНА',
        '880': 'СООБЩЕНИЕ ПОЛУЧЕНО',
        '881': 'СООБЩЕНИЕ ПОЛУЧЕНО',
        '882': 'ЗАГРУЗКА ПАРАМЕТРОВ ПРЕРВАНА',
        '889': 'ОШИБКА МАК',
        '898': 'ОШИБКА МАК',
        '899': 'ОШИБКА ПОСЛЕДОВАТЕЛЬНОСТИ',
        '900': 'ПРЕВЫШЕНИЕ ЧИСЛА ВВОДА ПИН',
        '901': 'ПОСРОЧЕННАЯ КАРТА',
        '902': 'ОТБЕРИТЕ КАРТУ!',
        '903': 'ОТБЕРИТЕ КАРТУ!',
        '904': 'СУММА МАЛА',
        '905': 'ПРЕВЫШЕНО КОЛИЧЕСТВО ПОЛЬЗОВАНИЙ КАРТЫ',
        '906': 'АРЕСТОВАННЫЙ СЧЕТ',
        '907': 'ПРЕВЫШЕНИЕ ЛИМИТА. ОТБЕРИТЕ КАРТУ.',
        '908': 'СУММА БОЛЬШЕ МАКСИМУМА. ОТБЕРИТЕ КАРТУ',
        '909': 'ОТБЕРИТЕ КАРТУ',
        '910': 'ARQC ОТБЕРИТЕ КАРТУ',
        '911': 'CVR ОТБЕРИТЕ КАРТУ',
        '912': 'TVR ОТБЕРИТЕ КАРТУ',
        '950': 'НЕ АДМ. КАРТА',
        '951': 'НЕ ТА АДМ. КАРТА',
        '959': 'АДМ. ТРАНЗАКЦИИ НЕ ПОДДЕРЖИВАЮТСЯ',
        '952': 'АДМ. ОПЕРАЦИЯ РАЗРЕШЕНА',
        '953': 'АДМ. ОПЕРАЦИЯ РАЗРЕШЕНА',
        '954': 'АДМ. ОПЕРАЦИЯ РАЗРЕШЕНА',
        '955': 'ВОЗВРАТ ДЕНЕГ РАЗРЕШЕН',
        '956': 'ВОЗВРАТ ДЕНЕГ РАЗРЕШЕН',
        '957': 'ВОЗВРАТ ДЕНЕГ РАЗРЕШЕН, ПЛОХОЙ ПРЕФИКС',
        '958': 'ВОЗВРАТ ДЕНЕГ НЕ РАЗРЕШЕН',
        '960': 'ВОЗВРАТ ДЕНЕГ РАЗРЕШЕН',
        '961': 'ВОЗВРАТ ДЕНЕГ РАЗРЕШЕН',
        '962': 'ВОЗВРАТ ДЕНЕГ РАЗРЕШЕН',
        '992': 'ОПЕРАЦИЯ ПРЕРВАНА',
        '998': 'ОШИБКА СВЯЗИ, ПОЗВОНИТЕ В БАНК',
        '999': 'ПИН-ПАД НЕ ОТВЕЧАЕТ',
    };

    public static successPayResultStatuses = ['000', '001', '002', '003', '004', '005', '006', '007', '008', '009'];
}
