<div class="p-20">
    <!-- Документ запрос -->
    <ng-container *ngIf="document.requestId">
        <div class="radio-block"
             *ngFor="let subject of subjects">
            <input id="requestObject{{subject.guid}}"
                   class="radio"
                   type="radio"
                   [checked]="data.envelope.object && data.envelope.object.guid == subject.guid"
                   (change)="changeRequestSubject(subject)">
            <label for="requestObject{{subject.guid}}">
                {{subject.header}}
            </label>
        </div>
    </ng-container>

    <!-- Документ НЕ запрос -->
    <ng-container *ngIf="!document.requestId">
        <div class="no-padding-top"
             *ngFor="let entity of entities; let lst = last"
             [ngClass]="{'checkbox-block': !forAgent && !isIdentity, 'radio-block': forAgent || isIdentity, 'no-margin-bottom': lst}">
            <input [type]="forAgent || isIdentity ? 'radio' : 'checkbox'"
                   [ngClass]="forAgent || isIdentity ? 'radio' : 'checkbox'"
                   [name]="forAgent || isIdentity ? 'agent' : 'object' + entity.guid"
                   id="obj{{entity.guid}}{{document.guid}}"
                   [checked]="entity.selected"
                   (change)="changeDocumentEntity(entity)">
            <label for="obj{{entity.guid}}{{document.guid}}">
                {{entity.header ? entity.header : entity.data.realty.name}}
            </label>
        </div>
    </ng-container>

    <!-- Выбор доверителя -->
    <div class="mt-15"
         *ngIf="forAgent && data.useSubjects && data.useSubjects.length > 0 && principals && principals.length > 0 && needSelectPrincipal">
        <p class="mb-15">
            {{localizations['appeals.documents'].select_principal}}:
        </p>
        <div *ngFor="let principal of principals" class="radio-block">
            <input type="radio"
                   class="radio"
                   name="principal"
                   id="principal{{principal.guid}}{{document.guid}}"
                   [checked]="principal.selected"
                   (change)="changeDocumentPrincipal(principal)">
            <label for="principal{{principal.guid}}{{document.guid}}">
                {{principal.header}}
            </label>
        </div>
    </div>

</div>
