import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { EventPreviewComponent } from './events/event-preview/event-preview.component';
import { SharedComponentsModule } from '../../common/shared-components.module';

@NgModule({
    imports: [
        SharedComponentsModule,
        InlineSVGModule,
    ],
    declarations: [
        EventPreviewComponent,
    ],
    providers: [],
    exports: [
        EventPreviewComponent,
    ],
})
export class EventPreviewModule {
}
