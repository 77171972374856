import { NgModule } from '@angular/core';
import { LeafletMapModalComponent } from '@components/modal/leaflet-map-modal/leaflet-map-modal.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { ObjectTypeComponent } from './object-types/object-type.component';
import { ObjectTypesComponent } from './object-types/object-types.component';
import { ObjectTypesResolve } from './object-types/object-types.resolve';
import { ObjectTypesSectionComponent } from './object-types/object-types-section.component';
import { AisObjectSectionComponent } from './objects/ais-object-section.component';
import { AisObjectsComponent } from './objects/ais-objects.component';
import { AisObjectEditComponent } from './objects/ais-object-edit/ais-object-edit.component';
import { AisObjectPreviewComponent } from './objects/components/ais-object-preview/ais-object-preview.component';
import { SubjectListComponent } from './objects/components/subject-list/subject-list.component';
import { EntitiesDataModule } from '../entities-data/entities-data.module';
import { AisObjectsResolve } from './objects/ais-objects.resolve';
import { AisObjectsService } from './objects/ais-objects.service';
import { ObjectVersionsComponent } from './objects/components/object-versions/object-versions.component';
import { ObjectPreviewCardComponent } from './objects/components/object-preview-card/object-preview-card.component';
import { SubjectsModule } from '../subjects/subjects.module';
import { ObjectTypePreviewComponent } from './object-types/components/object-type-preview/object-type-preview.component';
import { ObjectEditComponent } from './objects/object-edit/object-edit.component';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        EntitiesDataModule,
        InlineSVGModule,
        SubjectsModule,
        BreadcrumbsModule,
    ],
    declarations: [
        ObjectTypesSectionComponent,
        ObjectTypeComponent,
        ObjectTypesComponent,
        AisObjectSectionComponent,
        AisObjectsComponent,
        AisObjectEditComponent,
        AisObjectPreviewComponent,
        SubjectListComponent,
        ObjectVersionsComponent,
        ObjectPreviewCardComponent,
        ObjectTypePreviewComponent,
        ObjectEditComponent,
    ],
    providers: [
        ObjectTypesResolve,
        AisObjectsResolve,
        AisObjectsService,
    ],
    exports: [
        ObjectPreviewCardComponent,
        ObjectEditComponent,

    ],
    entryComponents: [
        LeafletMapModalComponent,
    ],
})
export class ObjectsModule {
}
