import { Component, Input, OnInit } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'report-fields',
    templateUrl: 'report-fields.component.html',
})
export class ReportFieldsComponent implements OnInit {
    @Input() public data;
    @Input() public fields = [];

    public isShow = true;

    public constructor() {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.fields.forEach(group => {
            group.children.forEach(childFields => {
                childFields.items.forEach(field => {
                    const findDataField = this.data.fields.find(item => item.code === field.code);
                    if (findDataField) {
                        field.checked = true;
                    } else if (field.checked) {
                        const addField = cloneDeep(field);
                        delete addField.checked;
                        this.data.fields.push(addField);
                    }
                });
            });
        });
    }

    /**
     * Добавление, удаление поля из состава полей отчета
     * @param field
     */
    public toggleField(field) {
        if (field.checked) {
            const findIndex = this.data.fields.findIndex(item => item.code === field.code);
            this.data.fields.splice(findIndex, 1);
        } else {
            const addField = cloneDeep(field);
            delete addField.checked;
            this.data.fields.push(addField);
        }
        field.checked = !field.checked;
    }
}
