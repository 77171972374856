import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, SelectionService } from '@evolenta/core';

@Injectable()
export class EventTypesResolve implements Resolve<any> {
    public constructor(
        private restService: RestService,
        private selectionService: SelectionService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const eventTypeId = route.params['eventTypeId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create').length > 0;

        if (isCreateAction) {
            // Создание
            return Promise.resolve({});
        } else if (eventTypeId) {
            // Редактирование
            if (this.selectionService.isProcessSelect) {
                return {};
            } else {
                return this.restService.find('eventTypes', eventTypeId);
            }
        } else {
            // Список
            const params = [];

            if (this.selectionService.isProcessSelect) {
                params.push({ field: 'showOnCreate', operator: 'eq', value: true });
            }

            return this.restService.search('eventTypes', { search: { search: params }, sort: 'dateCreation,DESC' });
        }
    }
}
