<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-primary btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            [disabled]="!canCreate"
            (click)="getNewSettings()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations.settings.create }}
    </button>

    <div class="ml-20 navbar-title mt-5">
        <h4 class="page-title">
            {{ localizations.settings.plural }}
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container"
     style="flex-direction: column;">
    <div class="panel no-margin no-border-top no-border-left no-border-right panel-main wide"
         style="height: 100%">
        <div class="panel-body no-padding">
            <evolenta-grid [model]="model"
                           [colDef]="colDef"
                           *ngIf="showGrid"
                           #gridElement>
            </evolenta-grid>
        </div>
    </div>
</div>
