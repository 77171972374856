<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="selectionService.isProcessSelect"
            (click)="returnToOperation()">
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5">
            {{ selectionService.selectedItems.length }}
        </span>
    </button>

    <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="!selectionService.isProcessSelect"
            [disabled]="workModeUrl !== 'kno'"
            (click)="createObject()">
        <b><i class="icon-plus-circle2"></i></b>
        {{ localizations.objects.new }}
    </button>

    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   type="text"
                   [placeholder]="localizations.common.search_dots"
                   [(ngModel)]="globalSearch"
                   (keypress)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    *ngIf="globalSearch"
                    (click)="clearSearch()">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData('replace')">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>

</div>

<div class="page-container">
    <!-- Панель фильтрации + Заголовок страницы -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-semibold">
                            {{ localizations.objects.plural }}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{totalUsersCount}}
                            </span>
                        </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li>
                            <i class="icon-cog3"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="sidebar-category">
                <div class="category-title">
                    <div class="radio-block no-padding-top">
                        <input id="knoSubjects"
                               class="radio"
                               type="radio"
                               name="mode"
                               [checked]="workModeUrl == 'kno'"
                               (change)="navigateToOtherMode('kno')">
                        <label for="knoSubjects">
                            {{ localizations.objects.kno }}
                        </label>
                    </div>
                    <div class="radio-block"
                         *ngIf="moduleBaseUrl !== 'selecting'">
                        <input id="allSubjects"
                               class="radio"
                               name="mode"
                               type="radio"
                               [checked]="workModeUrl == 'global'"
                               (change)="navigateToOtherMode('global')">
                        <label for="allSubjects">
                            {{ localizations.objects.global }}
                        </label>
                    </div>
                </div>
            </div>

            <!-- Панель фильтрации -->
            <filters-bar filterName="aisObjectFilter"
                                  [filters]="filtersPanelItems"
                                  [afterHeader]="true"
                                  [base]="baseSearch"
                                  (onApplyFilters)="loadData('replace')"
                                  #filtersPanel>
            </filters-bar>
        </div>
    </div>

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData('add')">
                            <ng-container *ngIf="objects.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of objects">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                             [ngClass]="{'active': object && object._id === item._id }">
                                            <div class="checkbox-block mt-20 mb-20 ml-20"
                                                 *ngIf="selectionService.isProcessSelect">
                                                <input id="selectObject{{ item._id }}"
                                                       class="checkbox"
                                                       type="checkbox"
                                                       [checked]="item.checked"
                                                       (click)="dblClick(item)">
                                                <label for="selectObject{{ item._id }}"
                                                       style="top: -18px">
                                                </label>
                                            </div>
                                            <div class="list-item"
                                                 [style.width] = "selectionService.isProcessSelect ?
                                                 'calc(100% - 50px)' :
                                                 '100%'"
                                                 (click)="click(item)"
                                                 (dblclick)="dblClick(item)">
                                                <div class="list-item-status-rectangle text-uppercase bg-primary-300">
                                                    {{ item?.type?.name }}
                                                </div>
                                                <div class="list-item-data"
                                                     [ngStyle]="{'width': 'calc(100% - 55px)'}">
                                                    <h6 class="text-bold no-margin">
                                                        <span>
                                                            {{item.name}}
                                                        </span>
                                                    </h6>
                                                    <p class="no-margin-bottom ellipsis"
                                                       *ngIf="item.address && item.address.fullAddress">
                                                        <span class="text-muted text-uppercase text-size-small">
                                                            {{ localizations.common.address }}:
                                                        </span>
                                                        {{item.address.fullAddress}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="panel panel-shadow m-15 p-15"
                                 *ngIf="objects.length == 0">
                                <div class="panel-body text-center">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing }}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations.objects.try_to_adjust_filters }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-warning alert-styled-left alert-bordered"
                         *ngIf="!object">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.common.for_details_select_item_on_the_left }}
                    </div>
                    <div *ngIf="object">
                        <ais-object-preview [object]="object">
                        </ais-object-preview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
