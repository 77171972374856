<!-- Дерево файлов -->
<div *ngIf="documents.length > 0" class="mb-30">
    <div *ngFor="let group of documents; let fst = first; let lst = last"
         class="panel panel-default no-margin"
         [ngClass]="{'no-border-top no-border-top-radius': !fst, 'no-border-bottom': !group.show, 'no-border-bottom-radius': !lst}">
        <div class="panel-heading pt-10 pb-10 cursor-pointer" (click)="group.show = !group.show">
            <div class="width-100">
                <h6 class="panel-title">
                    <i class="text-slate mr-5"
                       [ngClass]="{'icon-folder-open3' : group.show, 'icon-folder6' : !group.show}"></i>
                    {{group.group}}
                </h6>
            </div>
            <div class="heading-elements pull-right">
                <i class="ml-20 mt-10 cursor-pointer"
                   [ngClass]="{'icon-arrow-left12' : !group.show, 'icon-arrow-down12': group.show}"></i>
            </div>
        </div>
        <div class="panel-body no-padding no-border-radius" *ngIf="group.show">
            <table class="table table-xxs">
                <tbody>
                <tr *ngFor="let document of group.files; let fst = first">
                    <td width="25" class="no-padding-right" [ngClass]="{'no-border-top': fst}"><i class="icon-file-text2"></i></td>
                    <td [ngClass]="{'no-border-top': fst}">
                        <a class="anchor" (click)="downloadFile(document._id, document.originalName)" title="Скачать файл"
                           *ngIf="document._id && document._id != ''">{{document.originalName}}</a>
                        <span *ngIf="!document._id || document._id == ''">{{document.originalName}}</span>
                        <span *ngIf="group.type == 'ответ на запрос'" class ="pl-10"> <i class="icon-printer2"></i>
                            <a class="anchor" (click)="envelopeService.generateResultPdfForResultDoc(envelope._id, document.groupGuid)" title="Скачать файл">
                                <span>Повторно сформировать PDF</span>
                            </a>
                        </span>
                        <ng-container *ngIf="document.certificate">
                            <i class="icon-medal text-danger cursor-pointer ml-10" (click)="openCertificate(document.certificate)" title="Открыть сертификат"></i>
                            <span class="text-size-small mr-10">{{getFileCertificateInfo(document)}}</span>
                        </ng-container>

                        <span class="anchor text-size-small text-info ml-20"
                              *ngIf="allowEdit && group.outgoing && document._id && !document.certificate && accessService.hasAccess([permissions.File_Sign])"
                              (click)="signFile(group, document)">подписать</span>
                    </td>
                    <td class="text-right" [ngClass]="{'no-border-top': fst}">
                        <button class="btn btn-default btn-sxs" (click)="deleteFile(document)" type="button" title="Удалить" *ngIf="group.outgoing && allowEdit">
                            <i class="icon-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="alert alert-warning alert-styled-left alert-bordered" *ngIf="documents.length == 0">
    <button class="close" type="button" data-dismiss="alert"><span>×</span></button>
    <span class="text-semibold">Внимание!</span>  Файлы не добавлены. Вы можете загрузить файл.
</div>


<!-- Загрузка файла -->
<div class="mt-15" *ngIf="allowEdit">
    <table class="table" *ngIf="uploader.queue.length > 0">
        <thead>
        <tr>
            <th>Наименование файла</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of uploader.queue">
            <td>
                {{ item?.file?.name }}
            </td>
            <td>
                <a style="float: right" (click)="item.remove()"><i inlineSVG="assets/icon-trash.svg"></i></a>
            </td>
        </tr>
        </tbody>
    </table>
    <div class="mt-15">
        <input type="file" name="addFile" #fileInput id="addFile" class="inputfile" ng2FileSelect [uploader]="uploader" style="display: none;"/>
        <label for="addFile" class="btn btn-primary-overline btn-labeled btn-labeled-right btn-lg">
            Выбрать файл
            <b><i inlineSVG="assets/icon-attach.svg"></i></b>
        </label>
        <button type="button" class="btn btn-success-overline btn-lg btn-labeled btn-labeled-right ml-20"
                (click)="updateEnvelopeDocuments()" *ngIf="uploader.queue.length > 0">
            Загрузить
            <b><i class="icon-download"></i></b>
        </button>
    </div>
</div>

<!-- Диалог для подписания файлов -->
<evolenta-cades-sign-modal (onSignCompletedEnvelope)="onSignatureComplete($event)"></evolenta-cades-sign-modal>

<modal-dialog message="Вы действительно хотите удалить документ?" title="Удаление файла" (onSelect)="processingDeleteDocument($event)" #modalDialog></modal-dialog>
