import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RestService, ToasterService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { ObjectModalComponent } from '../special-types/object-modal/object-modal.component';
import * as _ from 'lodash-es';
import {
    EDIT_MODE,
    VIEW_MODE,
} from '../../../../../../common/constants';

@Component({
    selector: 'appeal-subject-object-card',
    templateUrl: 'appeal-subject-object-card.component.html',
    styles: [
        '.number { padding: 0 5px; }',
    ],
})
export class AppealSubjectObjectCardComponent implements OnInit {
    @Input() public object: any; // Объект
    @Input() public subject: any; // Субъект
    @Input() public mode = VIEW_MODE; // Режим работы

    @Output() public onApply = new EventEmitter<any>();
    @Output() public onCancel = new EventEmitter<any>();
    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<any>();

    public editTabs; // вкладки редактирования информации
    public activeTab = 'common';
    public searchText;
    public findedObjects = []; // массив найденных объектов
    public isProcessSelectObjectType = false;

    @ViewChild(ObjectModalComponent, { static: false }) public modalObjectComponent: ObjectModalComponent; // модальное окно для выода списка найденных

    public constructor(
        private toaster: ToasterService,
        private rest: RestService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (this.mode === EDIT_MODE) {
            this.initEditTabs();
        }
    }

    public activateTab(event) {

    }

    public delete() {
        this.onDelete.emit(this.object);
    }

    public initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: 'Общие данные',
                visible: true,
            },
            // {
            //     code: 'additional',
            //     name: 'Дополнительная информация',
            //     visible: true,
            // }
        ];
        this.editTabs.forEach(tab => {
            tab.active = tab.code === this.activeTab;
        });
    }

    public apply() {
        this.onApply.emit(this.object);
    }

    public cancel() {
        this.onCancel.emit(true);
    }

    public edit() {
        this.onEdit.emit(_.cloneDeep(this.object));
    }

    /**
     * Возврат адреса в виде строки
     * @param addressItems
     * @returns {string}
     */
    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    public async keypress(event) {
        if (event.keyCode === 13) {
            await this.searchObject();
        }
    }

    public async searchObject() {
        if (this.searchText) {
            const params = {
                page: 0,
                size: 500,
                search: {
                    search: [
                        {
                            orSubConditions: [
                                {field: 'name', operator: 'like', value: this.searchText},
                                {field: 'shortName', operator: 'like', value: this.searchText},
                            ],
                        },
                    ],
                },
            };

            try {
                const objects = await this.rest.search('objects', params);
                if (objects.length) {
                    this.findedObjects = objects;
                    this.modalObjectComponent.showModal();
                } else {
                    this.toaster.info('Совпадения не найдены');
                }
            } catch (error) {
                this.toaster.error('Произошла ошибка' + error);
            }
        } else {
            this.toaster.info('Не введены параметры поиска объекта');
        }
    }

    public selectObject(object) {
        this.object = {
            name: object.name,
            guid: object.guid,
            address: object.baseAddress,
            reestrId: object._id,
            auid: object.auid,
        };
    }
}
