import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AccessService,
    ModalDialogComponent,
    SelectionService,
    ToasterService,
} from '@evolenta/core';
import { CommonAppealSubjectCardComponent } from './appeal-subject-card/common-appeal-subject-card.component';
import { PersonService } from '../../../../common/services/persons.service';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { Permission } from '../../../../common/services/permission';
import { CommonUtilities } from '@evolenta/utilities';
import {
    IndividualObjectData,
} from '../../../subjects/individual-object/components/individual-applicant-form/common/individual-object.data';
import cloneDeep from 'lodash-es/cloneDeep';
import some from 'lodash-es/some';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-subjects',
    templateUrl: 'common-appeal-subjects.component.html',
    styles: [
        '.text-normal { text-transform: none; font-weight: normal; }',
    ],
})
export class CommonAppealSubjectsComponent implements OnInit {
    @Input() public appeal: any; // Текущее дело
    @Input() public subservice; // Услуги, на основании которых формируется дело
    @Input() public viewMode = 'default'; // Режим отображения
    @Input() public externalParam;

    @Output() public onAfterSave = new EventEmitter<string>(); // Передача возможности перехода на другую вкладку после КНМ необходимости сохранения данных
    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)
    @Output() public onApplyError = new EventEmitter<boolean>();
    @Output() public onEdit = new EventEmitter<object>(); // событие перехода в режим редактирования субъекта

    public activeTab; // активная вкладка при редактировании пользователя
    public nextTab; // Следующая вкладка, на которую будет осуществлен переход в родительском компоненте после КНМ сохранения измененных данных

    public availableSubjectKinds = []; // Доступные для выбора виды субъектов
    public editedSubject = null; // Субъект, который находится в данный момент в статусе редактирования

    public modalTitle; // Заголовок модального окна
    public modalMessage; // Сообщение модального окна
    public modalOperation; // Функция компонента, осуществляющая обработку результатов отработки модального окна

    @ViewChild('alertModal', { static: false }) public alertModalComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', { static: false }) public editSubjectCardComponent: CommonAppealSubjectCardComponent; // Компонент - карточка редактирования субъекта дела

    public allowEdit = true; // Возможность редактирования субъектов дела

    public permissions = Permission; // Набор прав системы

    public showSubjects = true;

    public subjectsParams: any = {
        titles: {
            plural: 'Субъекты',
            single: 'Субъект',
        },
        permissions: {
            add: false,
            edit: false,
            remove: false,
            editMainXsd: false,
            editXsd: false,
            editTypeXsd: false,
        },
        editedFields: null,
    };

    public subjectsDataInSubservice;
    public applyWithoutSave = false;
    public isChangeExternalParam = false;

    public usedSubjects;
    public pagination;
    public searchText;
    public perPage = 5;

    public constructor(
        public appealStatusService: CommonAppealStatusService,
        public accessService: AccessService,
        private toaster: ToasterService,
        private validateService: CommonAppealValidateService,
        private saveService: CommonAppealSaveService,
        private appealService: CommonAppealService,
        private selectionService: SelectionService,
        private personsService: PersonService,
        private errorLoggingService: ErrorLoggingService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Запрещено редактирование, если дело находится в финальном статусе, или зарегистрировано
        this.allowEdit = this.appealStatusService.allowEdit && this.appeal.status.code === 'draft';
        // формирование массива уникальных видов/типов субъектов из услуг, по которым формируется дело
        this.getUniqueSubjectsKinds();
        this.prepareSubjectsParams();
        if (this.selectionService.isProcessSelect && this.selectionService.transferOperation === 'selectSubject') {
            this.completeSelectSubject();
        }
        this.usedSubjects = this.appeal.subjects;
    }

    public ngOnChanges(changes) {
        if (this.appeal.status.code === 'draft' && changes && changes.externalParam && !changes.externalParam.firstChange) {
            if (changes.externalParam.previousValue !== changes.externalParam.currentValue) {
                this.isChangeExternalParam = true;
                this.prepareSubjectsParams();
            }
        }
    }

    public reInitSubjects() {
        this.showSubjects = false;
        setTimeout(() => {
                this.showSubjects = true;
            }, 100);
    }

    public prepareSubjectsParams() {
        this.subjectsDataInSubservice = this.subservice.objects && this.subservice.objects.subjectsData ? this.subservice.objects.subjectsData : null;
        if (this.subjectsDataInSubservice) {
            if (this.subjectsDataInSubservice.titles) {
                this.subjectsParams.titles = Object.assign(this.subjectsParams.titles, this.subjectsDataInSubservice.titles);
            }
            if (this.subjectsDataInSubservice.xsd) {
                this.subjectsParams.xsd = this.subjectsDataInSubservice.xsd;
            }
            if (this.subjectsDataInSubservice.mainXsd) {
                this.subjectsParams.mainXsd = this.subjectsDataInSubservice.mainXsd;
            }
            const appealStatusCode = this.appeal.status.code;
            if (this.subjectsDataInSubservice.statusesPermissions) {
                Object.keys(this.subjectsParams.permissions).forEach(key => {
                    this.subjectsParams.permissions[key] = !!(this.subjectsDataInSubservice.statusesPermissions[appealStatusCode]
                        && this.subjectsDataInSubservice.statusesPermissions[appealStatusCode][key]);
                });
                if (this.subjectsDataInSubservice.statusesPermissions[appealStatusCode]
                    && this.subjectsDataInSubservice.statusesPermissions[appealStatusCode].editedFields) {
                    // Если в настройках статуса есть настройки для состава редактируемых полей для всех типов субъектов
                    this.subjectsParams.editedFields = this.subjectsDataInSubservice.statusesPermissions[appealStatusCode].editedFields;
                }
            } else if (appealStatusCode === 'draft') {
                Object.keys(this.subjectsParams.permissions).forEach(key => {
                    this.subjectsParams.permissions[key] = true;
                });
            }

            if (this.appeal.status.code === 'draft' && this.appeal.subservice.externalSubjectParam && this.appeal.subservice.externalSubjectPermissions) {
                const externalPermissions = this.appeal.subservice.externalSubjectPermissions;
                Object.keys(this.subjectsParams.permissions).forEach(key => {
                    if (externalPermissions.hasOwnProperty(key)) {
                        this.subjectsParams.permissions[key] = externalPermissions[key];
                    }
                });
                if (externalPermissions.allowedEditFields) {
                    this.subjectsParams.allowedEditFields = externalPermissions.allowedEditFields;
                }
            }
            if (this.isChangeExternalParam) {
                this.reInitSubjects();
                this.isChangeExternalParam = false;
            }
        }
    }

    public async completeSelectSubject() {
        if (this.selectionService.isProcessSelect) {
            if (this.selectionService.selectedItems.length > 0) {
                let subjectFields = null;
                if (this.subservice.objects && this.subservice.objects.subjectsData && this.subservice.objects.subjectsData.subjectTypes) {
                    const find = this.subservice.objects.subjectsData.subjectTypes.find(item => item.code === this.selectionService.additionalData.editedSubject.specialTypeId);
                    if (find && find.fields && find.fields.length > 0) {
                        subjectFields = find.fields.map(item => 'data.' + item);
                    }
                }
                console.log(this.selectionService.selectedItems[0]);
                const subjectData = await this.personsService.getSubjectData(this.selectionService.selectedItems[0], !!this.selectionService.additionalData.selectFromGlobal, subjectFields);
                delete subjectData.guid;
                this.editedSubject = Object.assign(this.selectionService.additionalData.editedSubject, subjectData);
            }
        }
        this.selectionService.clearData();
    }

    /**
     * Добавление нового субъекта в дело
     */
    public addSubject() {
        const subjectGuid = CommonUtilities.GenerateGuid();
        const subject = {
            guid: subjectGuid,
            kind: null,
            data: {},
            specialTypeId: null,
            parentEntries: this.appealService.getAppealsCollection() + '.subjects',
        };
        if (this.availableSubjectKinds.length === 1) {
            subject.kind = Object.assign({}, this.availableSubjectKinds[0]);
            if (subject.kind.subKinds.length === 1) {
                subject.kind.subKind = Object.assign({}, subject.kind.subKinds[0]);
                subject.specialTypeId = subject.kind.subKind.specialTypeId;
                if (subject.specialTypeId === 'ulApplicant') {
                    subject.data = {organization: {}};
                } else {
                    subject.data = {person: {}};
                }
                this.activeTab = 'subservices';
            } else {
                subject.kind.subKind = null;
                subject.specialTypeId = null;
            }
        }

        // Переход в режим редактирования добавляемого субъекта
        this.onEditSubject(subject);
    }

    /**
     * Формирование массива уникальных видов субъектов из данных услуг
     */
    public getUniqueSubjectsKinds() {
        let isExistPrincipal = false;  // флаг наличия в услуге доверителя
        let isExistIndividualApplicant = false; // флаг наличия ФЛ в составе видов участников
        let isExistUlApplicant = false; // флаг наличия ЮЛ в составе участников
        if (this.subservice.objects && this.subservice.objects.objectKinds.length) {
            this.subservice.objects.objectKinds.forEach(kind => {
                const findIndex = this.availableSubjectKinds.findIndex(item => item.guid === kind.guid);
                let editKind;
                if (findIndex === -1) {
                    editKind = {
                        guid: kind.guid,
                        type: kind.type,
                        name: kind.name,
                        description: kind.description,
                        subKinds: [],
                    };
                } else {
                    editKind = this.availableSubjectKinds[findIndex];
                }
                kind.subKinds.forEach(subKind => {
                    if (subKind.type.indexOf('principal') !== -1) {
                        isExistPrincipal = true; // В настройке услуги есть доверители
                    }

                    // Обрабатываем только не представителей
                    if (subKind.type.indexOf('agent') === -1) {
                        if (subKind.specialTypeId === 'ulApplicant') {
                            isExistUlApplicant = true;
                        }
                        if (subKind.specialTypeId === 'individualApplicant') {
                            isExistIndividualApplicant = true;
                        }
                        const findSubKind = editKind.subKinds.find(item => item.name === subKind.secondGroupName);
                        if (!findSubKind) {
                            const kindData = {
                                name: subKind.secondGroupName,
                                specialTypeId: subKind.specialTypeId,
                                headerOptions: subKind.headerOptions,
                                shortHeaderOptions: subKind.shortHeaderOptions,
                                type: subKind.specialTypeId === 'realty' ? subKind.type : null,
                                allowEditCommonData: subKind.specialTypeId === 'individualApplicant',
                            };
                            if (this.subservice.objects.subjectTypes) {
                                const find = this.subservice.objects.subjectTypes.find(item => item.code === subKind.specialTypeId);
                                if (find) {
                                    kindData.allowEditCommonData = !find.onlySelectFromRegistry;
                                }
                            }
                            editKind.subKinds.push(kindData);
                        }
                    }
                });
                if (!editKind.subKinds.length) {
                    editKind.subKinds.push({
                        name: 'Юридическое лицо',
                        specialTypeId: 'ulApplicant',
                        headerOptions: ['f|data.organization.shortName', 's|, ОГРН: ', 'f|data.organization.ogrn'],
                        shortHeaderOptions: ['f|data.organization.shortName'],
                        type: 'applicantUl',
                    });
                    editKind.subKinds.push({
                        name: 'Индивидуальный предприниматель',
                        specialTypeId: 'ipApplicant',
                        headerOptions: ['f|data.person.lastName', 's| ', 'f|data.person.firstName', 's| ', 'f|data.person.middleName', 's|, ', 'f|data.person.birthday.formatted', 's| г.р.'],
                        shortHeaderOptions: ['f|data.person.lastName', 's| ', 'f|data.person.firstName|1', 's|.', 'f|data.person.middleName|1', 's|.'],
                        type: 'applicantIp',
                    });
                }
                if (findIndex === -1) {
                    this.availableSubjectKinds.push(editKind);
                } else {
                    this.availableSubjectKinds[findIndex] = editKind;
                }
            });
        } else if (this.subservice.objects && this.subservice.objects.subjectsData && this.subservice.objects.subjectsData.subjectTypes) {
            const parentKind = {
                type: 'participant',
                name: 'Участник',
                subKinds: [],
            };
            this.subservice.objects.subjectsData.subjectTypes.forEach(specialType => {
                parentKind.subKinds.push(IndividualObjectData.subjectsData[specialType.code]);
            });
            this.availableSubjectKinds.push(parentKind);
        } else {
            // Добавляем ЮЛ
            this.availableSubjectKinds.push({
                name: 'Юридическое лицо',
                specialTypeId: 'ulApplicant',
                headerOptions: ['f|data.organization.shortName', 's|, ОГРН: ', 'f|data.organization.ogrn'],
                shortHeaderOptions: ['f|data.organization.shortName'],
                type: 'applicantUl',
            });
            // Добавляем ИП
            this.availableSubjectKinds.push({
                name: 'Индивидуальный предприниматель',
                specialTypeId: 'ipApplicant',
                headerOptions: ['f|data.person.lastName', 's| ', 'f|data.person.firstName', 's| ', 'f|data.person.middleName', 's|, ', 'f|data.person.birthday.formatted', 's| г.р.'],
                shortHeaderOptions: ['f|data.person.lastName', 's| ', 'f|data.person.firstName|1', 's|.', 'f|data.person.middleName|1', 's|.'],
                type: 'applicantIp',
            });
        }

        if (isExistPrincipal && (!isExistIndividualApplicant || !isExistUlApplicant)) {
            const participantIndex = this.availableSubjectKinds.findIndex(item => item.type === 'participant');
            if (!isExistIndividualApplicant) {
                this.availableSubjectKinds[participantIndex].subKinds.push({
                    name: 'Физическое лицо',
                    specialTypeId: IndividualObjectData.individualPersonCode,
                    headerOptions: IndividualObjectData.individualHeaderOptions.header,
                    shortHeaderOptions: IndividualObjectData.individualHeaderOptions.shortHeader,
                    type: null,
                    onlyForPrincipal: true,
                });
            }
            if (!isExistUlApplicant) {
                this.availableSubjectKinds[participantIndex].subKinds.push({
                    name: 'Юридическое лицо',
                    specialTypeId: 'ulApplicant',
                    headerOptions: IndividualObjectData.ulHeaderOptions.header,
                    shortHeaderOptions: IndividualObjectData.ulHeaderOptions.shortHeader,
                    type: null,
                    onlyForPrincipal: true,
                });
            }
        }
    }

    /**
     * Инициализация режима редактирования субъекта
     * @param subject
     */
    public onEditSubject(subject) {
        this.editedSubject = cloneDeep(subject);
        this.onEdit.emit({entity: this.editedSubject, type: 'subject'});
    }

    /**
     * Применение (отмена) изменений в субъекте, переход к режиму списка субъектов
     * @param data - объект формата {subject: измененный субъект, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public async onApplySubject(data) {
        if (data) {
            const subjectIndex = this.appeal.subjects.findIndex(item => item.guid === data.subject.guid);
            // Применение изменений в структуру дела в блок appeal.objects
            const subjectData = cloneDeep(data.subject);
            delete subjectData.fieldRequirements;
            if (subjectIndex !== -1) {
                this.appeal.subjects[subjectIndex] = cloneDeep(subjectData);
            } else {
                this.appeal.subjects.push(cloneDeep(subjectData));
            }
            if (!this.applyWithoutSave && this.appeal._id) {
                try {
                    await this.saveService.saveAppeal();
                    this.toaster.success('Субъект успешно сохранен');
                    this.validateService.validateAppeal(this.validateService.processValidate);
                } catch (error) {
                    this.toaster.error(error);
                    await this.errorLoggingService.log(this.errorLoggingService.SPO, error, data);
                }
            }
        } else {
            // сохранение дела
            if (this.appeal._id) {
                try {
                    await this.saveService.saveAppeal();
                } catch (error) {
                    this.toaster.error(error);
                    await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
                }
            }
        }
        this.editedSubject = null;
        this.onApply.emit();
    }

    /**
     * Удаление субъекта из дела
     * @param subject - удаляемый субъект
     */
    public async onDeleteSubject(subject) {
        // Удаление настроек удаляемого объекта в услугах из дела
        if (this.appeal.subservice.subjects && this.appeal.subservice.subjects.length) {
            this.appeal.subservice.subjects = this.appeal.subservice.subjects.filter(item => item.guid !== subject.guid);
            // Если удаляемый объект является представителем для другого объекта, удаляем настройки представительства
            this.appeal.subservice.subjects.forEach(sbj => {
                if (sbj.representative && sbj.representative.guid === subject.guid) {
                    sbj.representative = null;
                }
            });
        }

        // Удаление субъекта из массива объектов дела
        const subjectIndex = this.appeal.subjects.findIndex(item => item.guid === subject.guid);
        this.appeal.subjects.splice(subjectIndex, 1);

        // Сохранение дела
        if (!this.appeal._id) {
            return;
        }

        await this.saveService.saveAppeal();
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Передача ответа от модального окна методу, который активен для вызова текущего модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    public hasAllowEdit(): boolean {
        return !some(this.appealStatusService.finishStatuses, item => item === this.appeal.status.code);
    }

    public get isAllowAdd() {
        return this.subjectsDataInSubservice
            && (this.subjectsDataInSubservice.max ? this.subjectsDataInSubservice.max > this.appeal.subjects.length : true)
            && this.subjectsParams.permissions.add;
    }

    public get onlyOne() {
        return this.subjectsDataInSubservice && this.subjectsDataInSubservice.max === 1;
    }

    public applyEditedSubject() {
        this.applyWithoutSave = true;
        this.editSubjectCardComponent.apply();
    }

    public applyError() {
        this.onApplyError.emit();
    }

    public reInitSubjectsData() {
        this.prepareSubjectsParams();
    }

    public filterSubjects() {
        if (this.searchText) {
            const searchString = this.searchText.toLowerCase();
            this.usedSubjects = this.appeal.subjects.filter(subject => {
                return (
                    (
                        subject.data.orgnaization
                        && (
                            subject.data.organization.name && subject.data.organization.name.toLowerCase().indexOf(searchString) !== -1
                            || subject.data.organization.ogrn && subject.data.organization.ogrn.indexOf(searchString) !== -1
                            || subject.data.organization.inn && subject.data.organization.inn.indexOf(searchString) !== -1
                        )
                    )
                    ||
                    (
                        subject.data.person
                        && (
                            subject.data.person.lastName && subject.data.person.lastName.toLowerCase().indexOf(searchString) !== -1
                            || subject.data.person.firstName && subject.data.person.firstName.toLowerCase().indexOf(searchString) !== -1
                            || subject.data.person.middleName && subject.data.person.middleName.toLowerCase().indexOf(searchString) !== -1
                            || subject.data.person.ogrn && subject.data.person.ogrn.indexOf(searchString) !== -1
                            || subject.data.person.inn && subject.data.person.inn.indexOf(searchString) !== -1
                        )
                    )
                );
            });
        } else {
            this.usedSubjects = this.appeal.subjects;
        }
    }
}
