<div class="panel panel-shadow panel-small"
     *ngIf="tasks.length > 0">
    <div class="panel-heading cursor-pointer"
         (click)="showTasks = !showTasks">
        <h6 class="text-bold">
            {{tasksName}}
        </h6>
        <div class="heading-elements">
            <ul class="icons-list">
                <li>
                    <a class="control-arrow">
                        <i [ngClass]="{
                            'icon-arrow-down12': showTasks,
                            'icon-arrow-left12': !showTasks
                            }">
                        </i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="panel-body collapse no-padding"
         [ngClass]="{'in' : showTasks}">
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th class="text-bold">
                    {{ localizations.common.code }}
                </th>
                <th class="text-bold">
                    {{ localizations.common.name }}
                </th>
                <th class="text-bold text-center"
                    *ngIf="type && type == 'subProcess'">
                    {{ localizations['bpmn-card'].subprocess }}
                </th>
                <th class="text-bold"
                    *ngIf="type == 'businessRuleTasks'">
                    {{ localizations['bpmn-card'].dmn }}
                </th>
                <th *ngIf="type == 'businessRuleTasks'"></th>
                <th class="text-bold"
                    *ngIf="entityProcessData">
                    {{ localizations.common.settings }}
                </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let task of tasks; let fst = first; let lst = last">
                <tr>
                    <td (click)="toggleRow(task)"
                        width="64">
                        <span class="control-arrow text-slate"
                              *ngIf="isCanChange">
                            <i [ngClass]="{
                                    'icon-circle-down2': panels[task.id],
                                    'icon-circle-right2': !panels[task.id]
                                }">
                            </i>
                        </span>
                    </td>
                    <td width="200">
                        {{ task.id }}
                    </td>
                    <td>
                        {{ task.name }}
                    </td>
                    <td class="text-center"
                        *ngIf="type && type == 'subProcess'">
                        <span class="badge bg-success-300 text-uppercase ml-5"
                              *ngIf="task.subProcess">
                            {{ localizations['bpmn-card'].selected }}
                        </span>
                        <span class="badge bg-slate-300 text-uppercase ml-5"
                              *ngIf="isCanChange && !task.subProcess">
                            {{ localizations.common.not_selected_m }}
                        </span>
                    </td>
                    <td class="no-border-top border-bottom border-bottom-default "
                        *ngIf="type == 'businessRuleTasks'">
                        <ng-container *ngIf="task.dmn && dmnProcesses[task.dmn]">
                            <a class="anchor"
                               [routerLink]="['/processes','business-rules','edit', task.dmn]">
                                {{dmnProcesses[task.dmn].title}}
                            </a>
                        </ng-container>
                    </td>
                    <td class="text-right border-bottom border-bottom-default"
                        *ngIf="type == 'businessRuleTasks'">
                        <button class="btn btn-sxs btn-default"
                                type="button"
                                [title]="localizations['bpmn-card'].select_dmn"
                                *ngIf="type == 'businessRuleTasks'"
                                (click)="selectDmn(task)">
                            {{ localizations['bpmn-card'].dmn }}
                        </button>
                    </td>
                    <td>
                        <ng-container *ngIf="usedEntityProcessData">
                            <ng-container *ngIf="getTaskInfo(task)">
                                <div class="display-inline-block text-size-small"
                                     [innerHTML]="getTaskInfo(task)">
                                </div>
                                <br>
                            </ng-container>
                            <a class="text-size-small anchor text-info"
                               (click)="setupTask(task)">
                                {{ localizations['bpmn-card'].set_params }}
                            </a>
                            <br>
                            <evolenta-handlers
                                    [model]="getTaskData(task)"
                                    [setStartEvent]="true"
                                    [setEndEvent]="true"
                                    [noMargin]="true"
                            ></evolenta-handlers>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="type == 'subProcess'">
                    <td class="no-padding no-border"
                        [ngClass]="{'warning': !task.subProcess}"
                        colspan="5"
                        *ngIf="panels[task.id]">
                        <div class="p-20"
                             *ngIf="task.subProcess">
                            <common-bpmn-card [bpmn]="task.subProcess"
                                       [isSubProcess]="true"
                                       [entityProcessData]="entityProcessData ? getEntityProcessData(task) : null"
                                       (onDelete)="deleteProcess(task)"
                                       (onEdit)="editProcess(task)">
                            </common-bpmn-card>
                        </div>

                        <div class="p-10"
                             *ngIf="!task.subProcess">
                            <span class="text-bold">
                                  {{ localizations.common.attention }}
                            </span>
                            {{ localizations['bpmn-card'].subprocess_not_selected }}
                            <ng-container *ngIf="allowEdit">
                                <span class="anchor text-warning ml-10"
                                      (click)="searchProcess(task.id)">
                                    {{ localizations['bpmn-card'].select_process_from_registry }}
                                </span>
                                {{ localizations.common.or }}
                                <span class="anchor text-warning"
                                      (click)="addNew(task.id)">
                                    {{ localizations['bpmn-card'].add_process }}
                                </span>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-container>

            </tbody>
        </table>
    </div>
</div>

<!-- модальное окно для настройки операций по задачам -->
<ng-template #taskSettingsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations['bpmn-card'].task_setup }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group form-group-lg"
             *ngIf="roles.length > 0">
            <label class="text-bold">
                {{ localizations['bpmn-card'].access_for_roles }}
            </label>
            <ng-select multipleSelect
                       [items]="transformedRoles"
                       [multiple]="true"
                       [(ngModel)]="processingSettings.roles"
                       class="tree-select"
                       [placeholder]="localizations['bpmn-card'].select_value_dots">
            </ng-select>
        </div>
        <div class="form-group form-group-lg">
            <label class="text-bold">
                {{ localizations['bpmn-card'].special_component }}
            </label>
            <ng-select [items]="specialComponents"
                       [multiple]="false"
                       [(ngModel)]="processingSettings.specialComponent"
                       [placeholder]="localizations['bpmn-card'].select_value_dots">
            </ng-select>
        </div>

        <div class="form-group form-group-lg">
            <label>
                {{ localizations['bpmn-card'].value_in_history }}
            </label>
            <input class="form-control"
                   type="text"
                   [(ngModel)]="processingSettings.historyText">
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-info"
                type="button"
                (click)="applyTaskSettings()">
            {{ localizations.common.apply }}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>




