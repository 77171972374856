<ng-container *ngIf="data && data.events.length > 0">

    <!-- Обработка активного события -->
    <ng-container *ngIf="data.activeEvent && !data.activeEventEmptyData">
        <div class="pr-20 pl-20"
             *ngIf="mode === 'buttons'">
            <button class="btn bg-info-600 pl-15 pr-15 pt-10 pb-10 width-100 no-white-space mt-20 btn-extra-large text-left"
                    (click)="emitApplyEvent()"
                    [ngClass]="{'flash': data.isProcessingCompleteElement}"
                    [disabled]="data.isProcessingCompleteElement">
                {{ localizations.common.complete }}
            </button>

            <button class="btn bg-orange-300 pl-15 pr-15 pt-10 pb-10 width-100 no-white-space mt-20 btn-extra-large text-left"
                    (click)="cancelEvent()"
                    [disabled]="data.isProcessingCompleteElement">
                {{ localizations.common.cancel }}
            </button>
        </div>

        <div class="panel panel-shadow panel-small m-20"
             *ngIf="mode === 'all' || mode === 'settings'">
            <div class="panel-heading">
                <h4 [innerHTML]="getProcessingEventMessage()"></h4>
                <div class="heading-elements"
                     *ngIf="mode !== 'settings'">
                    <button class="btn btn-success-overline heading-btn btn-labeled"
                            (click)="applyEvent()"
                            [ngClass]="{'flash': data.isProcessingCompleteElement}"
                            [disabled]="data.isProcessingCompleteElement">
                        {{ localizations.common.apply }}
                    </button>
                    <button class="btn btn-danger-overline heading-btn btn-labeled"
                            (click)="cancelEvent()"
                            [disabled]="data.isProcessingCompleteElement">
                        {{ localizations.common.do_cancel }}
                    </button>
                </div>
            </div>
            <div class="panel-body no-padding">
                <entity-process-element-data [data]="data"
                                             [element]="data.activeEvent"
                                             (onEmptyData)="applyEvent(true)"
                                             #activeEventData>
                </entity-process-element-data>
            </div>
        </div>

    </ng-container>


    <!-- Действия по статусам и по событиям -->
    <div class="pr-20 pl-20"
         *ngIf="!data.activeEvent && mode !== 'settings'">
        <!-- Действия из событий -->
        <ng-container *ngIf="data.events && data.events.length">
            <ng-container *ngFor="let event of data.events">
                <button class="btn bg-info pl-15 pr-15 pt-10 pb-10 no-white-space btn-extra-large mr-10"
                        [disabled]="!checkAvailable(event)"
                        [ngClass]="{
                            'width-100  text-left mt-20 ': onLeft,
                            'mb-20': !onLeft
                        }"
                        (click)="activateEvent(event)">
                    {{event.eventName}}
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-container>



