<ng-container *ngFor="let block of blocks; let fst = first" >
    <div [ngClass]="{'mt-20': !fst && workMode !== 'default', 'hide': !block.visibleFunction(), 'panel-shadow m-20': workMode !== 'default' || block.code == 'common'}"
         *ngIf="block.useFunction()">
        <div class="panel-heading cursor-pointer" *ngIf="workMode !== 'default' && !editedEntity">
            <h5 class="panel-title">
                <span (click)="block.hide = !block.hide">{{block.name}}</span>
                <div *ngIf="block.count" class="ml-10 display-inline-block">
                    <span class="badge bg-primary-300 text-uppercase">{{block.count}}</span>
                </div>
                <span class="badge badge-danger badge-small ml-10 text-semibold cursor-pointer text-uppercase"
                      *ngIf="block?.errors?.length > 0" (click)="showErrors(block)">ошибок: {{block.errors.length}}</span>
            </h5>
            <div class="heading-elements" (click)="block.hide = !block.hide">
                <i class="text-size-xl"
                   [ngClass]="{'icon-arrow-down22': !block.hide, 'icon-arrow-left22': block.hide}"></i>
            </div>
        </div>
        <div [ngClass]="{'panel-body no-padding': workMode !== 'default' || block.code == 'common'}"
             *ngIf="workMode == 'default' && block.visibleFunction() || workMode !== 'default' && !block.hide">

            <div class="alert alert-warning no-border no-margin" [ngClass]="{'ml-20 mr-20': block.code != 'common'}" *ngIf="workMode === 'default' && block?.errors?.length > 0 && !editedEntity">
                <ul class="no-margin">
                    <li *ngFor="let err of block.errors" [innerHTML]="err"></li>
                </ul>
            </div>

            <!-- Активные задачи бизнес-процесса -->
            <entity-process-elements [data]="processData"
                                     mode="active"
                                     *ngIf="block.code === 'activeProcessElements' && !editedEntity"></entity-process-elements>

            <!-- Завершенные задачи (события) бизнес-процесса -->
            <entity-process-elements [data]="processData"
                                     mode="completed"
                                     *ngIf="block.code === 'completedProcessElements' && !editedEntity"></entity-process-elements>

            <!-- Общие данные -->
            <common-appeal-common [appeal]="appeal"
                                  [subservice]="subservice"
                                  [mode]="mode"
                                  *ngIf="block.code === 'common'"
                                  (onEditEntity)="onEditEntity($event)"
                                  (onApplyEntity)="afterApplyEditedEntity()"
                                  (onApplyError)="errorsOnApply()"
                                  #common></common-appeal-common>

            <!-- Услуги (мероприятия) -->
            <common-appeal-subservices [appeal]="appeal"
                                [subservice]="subservice"
                                *ngIf="block.code === 'subservices' && !editedEntity">
            </common-appeal-subservices>

            <!-- Документы с разбивкой по функциональным группам -->
            <common-appeal-documents [appeal]="appeal"
                                     [subservice]="subservice"
                                     [allowEdit]="allowEdit"
                                     [byGroupTypes]="workMode !== 'default'"
                                     [task]="workMode === 'default' ? null : {}"
                                     *ngIf="block.code === 'documents' && !editedEntity"
                                     #documents cdkDropListGroup>
            </common-appeal-documents>

            <!-- Субъекты -->
            <common-appeal-subjects [appeal]="appeal"
                                    [subservice]="subservice"
                                    (onEdit)="onEditEntity($event)"
                                    (onApply)="afterApplyEditedEntity()"
                                    *ngIf="block.code === 'subjects' && (!editedEntity || editedEntityType == 'subject')"
                                    #appealSubjects></common-appeal-subjects>

            <!-- Объекты -->
            <common-appeal-objects [appeal]="appeal"
                                   [subservice]="subservice"
                                   *ngIf="block.code === 'objects' && (!editedEntity || editedEntityType == 'object')"
                                   (onEdit)="onEditEntity($event)"
                                   (onApply)="afterApplyEditedEntity()"
                                   #appealObjects>
            </common-appeal-objects>

            <!-- Информация о заявителе -->
            <subject-preview-card [subject]="appeal.epguData.applicant" [isFullCard]="true" [showHeader]="true"
                                  *ngIf="block.code === 'epguApplicant'"></subject-preview-card>

            <!-- Сведения -->
            <common-appeal-entities [appeal]="appeal"
                             [subservice]="subservice"
                             *ngIf="block.code === 'entities' && !editedEntity"
                             #appealEntities>
            </common-appeal-entities>

            <!-- Чек-листы -->
            <common-appeal-check-lists [appeal]="appeal"
                                [subservice]="subservice"
                                *ngIf="block.code === 'checkLists' && subservice.checkLists && subservice.checkLists[0] && !editedEntity" #appealCheckLists></common-appeal-check-lists>

            <!-- История дела -->
            <history collection="appeals"
                     [id]="appeal._id"
                     *ngIf="block.code === 'history' && !editedEntity"></history>

        </div>
    </div>
</ng-container>

<!-- Ошибки при валидации документа -->
<ng-template #errorsListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Ошибки</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="no-margin">
            <li *ngFor="let err of blockWithErrors.errors" [innerHTML]="err"></li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Закрыть</button>
    </div>
</ng-template>
