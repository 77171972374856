<!-- Настройка дополнительных данных -->
<p *ngIf="metaData.commonData.allowCreateMainXsd">
    <span class="text-bold">
        {{ getMainBlockMessage() }}
    </span>
    <span class="text-size-small anchor text-info ml-10"
          (click)="editXsd('mainXsd')"
          *ngIf="data.allowEdit || data.entity.objectsCommon.mainXsd">
                {{!data.entity.objectsCommon.mainXsd?
                    localizations.common.setup : data.allowEdit ?
                        localizations.common.change_small :
                        localizations.common.view_small }}
            </span>
    <span class="text-size-small anchor text-slate ml-10"
          *ngIf="data.entity.objectsCommon.mainXsd && data.allowEdit"
          (click)="data.entity.objectsCommon.mainXsd = null">
        {{ localizations.common.delete_small }}
    </span>
    <evolenta-handlers
            [model]="data.entity.objectsCommon"
            field="mainXsd"
            [setStartEvent]="true"
    ></evolenta-handlers>
</p>

<p *ngIf="metaData.commonData.allowCreateXsd">
    <span class="text-bold">
        {{ getAdditionalFieldsSetUpStatusMessage() }}
    </span>
    <span class="text-size-small anchor text-info ml-10"
          (click)="editXsd('xsd')"
          *ngIf="data.allowEdit || data.entity.objectsCommon.xsd">
                {{!data.entity.objectsCommon.xsd?
                    localizations.common.setup : data.allowEdit ?
                        localizations.common.change_small :
                        localizations.common.view_small }}
            </span>
    <span class="text-size-small anchor text-slate ml-10"
          *ngIf="data.entity.objectsCommon.xsd && data.allowEdit"
          (click)="data.entity.objectsCommon.xsd = null">
        {{ localizations.common.delete_small }}
    </span>
    <evolenta-handlers
            [model]="data.entity.objectsCommon"
            field="xsd"
            [setStartEvent]="true"
    ></evolenta-handlers>
</p>

<div class="row">
    <div class="col-md-8"
         *ngIf="metaData.commonData.allowTitlesSetting">
        <fieldset>
            <legend class="text-bold">
                {{ localizations.common.name }}
            </legend>
            <div class="row">
                <div class="col-md-6"
                     *ngFor="let item of titles">
                    <div class="form-group form-group-lg">
                        <label>
                            {{item.name}}
                        </label>
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="data.entity.objectsCommon.titles[item.code]">
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="col-md-4"
         *ngIf="metaData.commonData.allowMinMaxSetting">
        <fieldset>
            <legend class="text-bold">
                {{ localizations.standards.objects_number }}
            </legend>
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.common.minimum }}
                            </label>
                            <input class="form-control"
                                   type="number"
                                   [(ngModel)]="data.entity.objectsCommon.min">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.common.maximum }}
                            </label>
                            <input class="form-control"
                                   type="number"
                                   [(ngModel)]="data.entity.objectsCommon.max">
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>

<div>
    <div class="checkbox-inline no-padding">
        <input id="allowCreateNew"
               class="checkbox"
               type="checkbox"
               [checked]="data.entity.objectsCommon.allowCreateNew"
               (change)="data.entity.objectsCommon.allowCreateNew = !data.entity.objectsCommon.allowCreateNew; afterAdd()">
        <label for="allowCreateNew">
            {{ localizations.standards.new_object_adding_permitted }}
        </label>
    </div>
    <ng-container *ngIf="data.entity.objectsCommon.allowCreateNew">
        <a class="anchor text-info text-size-small"
           (click)="setFieldsRequirements()">
            {{ localizations.standards.mandatory_small }}
        </a>
    </ng-container>
</div>

<div class="checkbox-block">
    <input id="allowSelectFromRegistry"
           class="checkbox"
           type="checkbox"
           [checked]="data.entity.objectsCommon.allowSelectFromRegistry"
           (change)="changeSelectFromRegistry(); afterSelect()">
    <label for="allowSelectFromRegistry">
        {{ localizations.standards.selection_from_registry_permitted }}
    </label>
</div>
<div class="checkbox-block"
     *ngIf="data.entity.objectsCommon.allowSelectFromRegistry">
    <input id="selectOnlyFromGlobalRegistry"
           class="checkbox"
           type="checkbox"
           [checked]="data.entity.objectsCommon.selectOnlyFromGlobalRegistry"
           (change)="data.entity.objectsCommon.selectOnlyFromGlobalRegistry = !data.entity.objectsCommon.selectOnlyFromGlobalRegistry">
    <label for="selectOnlyFromGlobalRegistry">
        {{ localizations.standards.selection_from_global_registry_permitted }}
    </label>
</div>

<div class="form-group no-margin-bottom mt-20"
     *ngIf="this.data.entity.objectsCommon.afterAdd">
    <label class="text-bold text-size-small">
        {{ localizations.standards.new_object_adding_handlers }}
    </label>
    <evolenta-handlers
            [model]="data.entity.objectsCommon"
            field="afterAdd"
            [setEndEvent]="true"
    ></evolenta-handlers>
</div>

<div class="form-group no-margin-bottom mt-20"
     *ngIf="this.data.entity.objectsCommon.afterSelect">
    <label class="text-bold text-size-small">
        {{ localizations.standards.select_from_registry_handlers }}
    </label>
    <evolenta-handlers
            [model]="data.entity.objectsCommon"
            field="afterSelect"
            [setEndEvent]="true"
    ></evolenta-handlers>
</div>

<div class="checkbox-block"
     *ngIf="data.entity.registersModel && data.entity.registersModel.useOtherRegionCheck">
    <input id="denyChangeOtherRegionObject"
           class="checkbox"
           type="checkbox"
           [checked]="data.entity.objectsCommon.denyChangeOtherRegionObject"
           (change)="data.entity.objectsCommon.denyChangeOtherRegionObject = !data.entity.objectsCommon.denyChangeOtherRegionObject">
    <label for="denyChangeOtherRegionObject">
        {{ localizations.standards.deny_editing }}
    </label>
</div>

<div class="checkbox-block"
     *ngIf="metaData.commonData.allowUseInMainBlockSetting">
    <input id="allowUseInMainBlockSetting"
           class="checkbox"
           type="checkbox"
           [checked]="data.entity.objectsCommon.useInMainBlock"
           (change)="data.entity.objectsCommon.useInMainBlock = !data.entity.objectsCommon.useInMainBlock">
    <label for="allowUseInMainBlockSetting">
        {{ localizations.standards.print_in_main_data_block }}
    </label>
</div>

<fieldset class="mt-20">
    <legend class="text-bold no-margin-bottom">
        {{ localizations.standards.access_settings }}
    </legend>
    <common-standard-statuses-permissions [params]="permissionParams"
                                          [checkedTypes]="objectTypes"
                                          [data]="data.entity.objectsCommon"></common-standard-statuses-permissions>
</fieldset>

<ng-template #fieldsRequirementsSettingsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.standards.mandatory_settings }}
            <span class="badge text-uppercase bg-primary-300 ml-10">
                {{ localizations.common.object }}
            </span>
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <common-standard-fields-requirements [fieldsRequirements]="usedFieldsRequirements"
                                             entityType="obj">
        </common-standard-fields-requirements>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="applyFieldsRequirements()">
            {{ localizations.common.apply }}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>
