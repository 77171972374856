import { Injectable } from '@angular/core';
import { ACTUALITY_STATUS, APPEALS_ACTUALITY, APPEALS_SOURCE, APPEALS_STATUS } from '../../../common/constants';

@Injectable()
export class AppealData {
    // TODO при вызове делать локализацию name и shortName
    // TODO load from DB
    public static kndForms = [
        { code: '1', name: 'registers.of_field' },
        { code: '2', name: 'registers.of_document' },
        { code: '3', name: 'registers.of_document_and_field' },
    ];

    // TODO load from DB
    public static kndTypes = [
        { code: '0', name: 'knd-types.planned' },
        { code: '1', name: 'knd-types.unplanned' },
    ];

    // Статусы дела
    public static statuses = [
        {
            code: 'draft',
            name: 'filters.statuses.draft',
            shortName: 'filters.statuses.draft',
            theme: 'purple',
            labelText: 'filters.statuses.draft_abbr',
            label: 'bg-purple-50 text-purple',
            background: 'bg-purple-300',
            border: 'border-purple-400',
            backgroundLite: 'bg-purple-50',
            borderTop: 'border-top-purple-400',
            borderLeft: 'border-left-purple-400',
            text: 'text-purple-400',
            icon: 'la la-pencil',
            img: 'pencil.svg',
        },
        {
            code: 'process',
            name: 'filters.statuses.processing',
            shortName: 'filters.statuses.processing',
            theme: 'primary',
            labelText: 'filters.statuses.processing_abbr',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-500',
            text: 'text-primary-400',
            icon: 'la la-paper-plan-o',
            img: 'play.svg',
        },
        {
            code: 'complete',
            name: 'filters.statuses.completed',
            shortName: 'filters.statuses.completed',
            theme: 'success',
            labelText: 'filters.statuses.completed_abbr',
            label: 'bg-success-50 text-success',
            background: 'bg-success-300',
            backgroundLite: 'bg-success-50',
            borderTop: 'border-top-success-400',
            text: 'text-success-600',
            borderLeft: 'border-left-success-400',
            img: 'check-mark.svg',
        },
        {
            code: 'archive',
            name: 'filters.statuses.archived',
            labelText: 'filters.statuses.archived_abbr',
            shortName: 'filters.statuses.archived',
            theme: 'slate',
            label: 'bg-brown-50 text-brown',
            background: 'bg-brown-300',
            backgroundLite: 'bg-brown-50',
            borderTop: 'border-top-brown-400',
            text: 'text-brown-400',
            borderLeft: 'border-left-brown-400',
        },
        {
            code: 'annulled',
            name: 'filters.statuses.annulled',
            labelText: 'filters.statuses.annulled_abbr',
            shortName: 'filters.statuses.annulled',
            theme: 'slate',
            label: 'bg-slate-50 text-slate',
            background: 'bg-slate-300',
            backgroundLite: 'bg-slate-50',
            borderTop: 'border-top-slate-400',
            text: 'text-slate-400',
            borderLeft: 'border-left-slate-400',
        },
    ];

    // Временные интервалы
    public static period = [
        {
            code: 'today',
            name: 'events.today',
        },
        {
            code: 'week',
            name: 'events.week',
        },
        {
            code: 'month',
            name: 'events.month',
        },
        {
            code: 'interval',
            name: 'events.interval',
        },
    ];

    // Настройка дела
    public static configurationAppeal = [
        {
            code: 'my',
            name: 'configuration_appeal.my_cases',
        },
        {
            code: 'pr',
            name: 'configuration_appeal.expired',
        },
        {
            code: 'is',
            name: 'configuration_appeal.expiring',
        },
    ];

    // Параметры сортировки
    public static sortElements = [
        {
            code: 'number',
            name: 'common.number',
        },
        {
            code: 'dateCreation',
            name: 'common.creation_date',
        },
        {
            code: 'dateRegister',
            name: 'common.registration_date',
        },
        {
            code: 'dateLastModification',
            name: 'common.change_date',
        },
        {
            code: 'subServices.title',
            name: 'common.service',
        },
    ];

    // Типы дат дела
    public static diffDates = [
        {
            id: 'dateRegister',
            text: 'Дата регистрации',
        },
        {
            id: 'dateFinish',
            text: 'Дата завершения',
        },
        {
            id: 'dateLastModification',
            text: 'Дата редактирования',
        },
        {
            id: 'dateCreation',
            text: 'Дата создания',
        },
    ];

    // Настройки отображения грида
    public static colDef = [
        {
            field: 'shortNumber',
            headerName: 'common.number',
            width: 100,
        },
        {
            field: 'dateCreation',
            headerName: 'common.created_big',
            filter: 'date',
        },
        {
            extType: 'buttons',
            buttons: {
                updateBtn: {
                    target: 'ais/appeal',
                    permissionEdit: true,
                    permissionView: true,
                },
                deleteBtn: {
                    permission: true,
                },
            },
        },
    ];

    public static EXECUTOR_FILTER_NAME = 'executor';
    public static CONTROL_OPERATOR_FILTER_NAME = 'controlOperator';

    // Структура панели фильтрации
    public static filtersPanelItems = [
        {
            groupId: 'main-group',
            icon: 'icon-grid-alt',
            show: true,
            data: [
                // TPPPGMU-62
                {
                    name: 'ФИО заявителя',
                    filters: [
                        {
                            fields: ['subjects.header'],
                            type: 'input',
                            placeholder: 'ФИО заявителя',
                        },
                    ],
                },
                // PPGMUD-283
                {
                    name: 'ФИО исполнителя',
                    filters: [
                        {
                            fields: [AppealData.EXECUTOR_FILTER_NAME],
                            type: 'input',
                            placeholder: 'ФИО исполнителя',
                        },
                    ],
                },
                // PPGMUD-279
                {
                    name: 'ФИО ответственного',
                    filters: [
                        {
                            fields: [AppealData.CONTROL_OPERATOR_FILTER_NAME],
                            type: 'input',
                            placeholder: 'ФИО ответственного',
                        },
                    ],
                },
                // TPPPGMU-136
                {
                    name: 'Автоматические запросы',
                    filters: [
                        {
                            fields: ['isHavingResult'],
                            type: 'checkbox',
                            shortName: 'Вкл.',
                            placeholder: 'Новые ответы по запросам',
                        },
                    ],
                },
                {
                    name: 'Номер дела',
                    filters: [
                        {
                            fields: ['shortNumber'],
                            type: 'input',
                            placeholder: 'Номер...',
                            format: {
                                byZero: true,
                                length: 8,
                            },
                        },
                    ],
                },
                {
                    name: 'Номер дела ЕПГУ',
                    filters: [
                        {
                            fields: ['epguOrderId'],
                            type: 'input',
                            placeholder: 'Номер...',
                        },
                    ],
                },
                {
                    name: 'Стандарт',
                    filters: [
                        {
                            fields: ['subservices.serviceId', 'subservices.title', 'subservices.shortTitle'],
                            type: 'input',
                            placeholder: 'Наименование или код стандарта...',
                        },
                    ],
                },
                {
                    name: 'Код услуги',
                    filters: [
                        {
                            fields: ['subservices.externalNumber'],
                            type: 'input',
                            placeholder: 'Код услуги...',
                        },
                    ],
                },
                {
                    name: 'Период',
                    filters: [
                        {
                            type: 'period',
                            dates: AppealData.diffDates,
                            itemName: 'text',
                            itemValue: 'id',
                            items: [
                                {
                                    code: 'today',
                                    name: 'Сегодня',
                                },
                                {
                                    code: 'week',
                                    name: 'Неделя',
                                },
                                {
                                    code: 'month',
                                    name: 'Месяц',
                                },
                                {
                                    code: 'interval',
                                    name: 'Интервал',
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Актуальность дел',
                    code: 'appealsActuality',
                    filters: [
                        {
                            fields: ['collection'],
                            type: 'radioboxes',
                            dependFilter: 'appealStatuses',
                            items: [
                                {
                                    name: 'Активные',
                                    code: 'appeals:actual',
                                    // блок связанных состояний, не обязательное свойство, является дочерним для на родительского свойства "dependFilter"
                                    showDependChild: [
                                        'draft',
                                        'process',
                                        'checking',
                                        'processing',
                                        'approveDate',
                                        'requestInfo',
                                        'paused',
                                        'prepareResult',
                                        'payment',
                                    ],
                                    showChild: true,
                                    isDefault: true,
                                },
                                {
                                    name: 'Завершенные',
                                    code: 'appeals:complete',
                                    showDependChild: ['complete', 'serviceReject', 'receiveDocsReject', 'annulled'],
                                    showChild: true,
                                    isDefault: false,
                                },
                                {
                                    name: 'В архиве',
                                    code: 'appealsPreArchive',
                                    showChild: false,
                                    isDefault: false,
                                },
                                {
                                    name: 'Обезличенные',
                                    code: 'appealsArchive',
                                    showChild: false,
                                    isDefault: false,
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Срок выполнения',
                    filters: [
                        {
                            type: 'checkboxes',
                            isInParentComponentProcessing: true,
                            code: 'actualType',
                            items: [
                                {
                                    name: 'Просрочено',
                                    code: 'expired',
                                    placeholder: 'Истек срок выполнения',
                                },
                                {
                                    name: 'Истекает',
                                    code: 'termEnding',
                                    placeholder: 'Истекает срок выполнения',
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'Статус',
                    code: 'appealStatuses',
                    filters: [
                        {
                            code: 'appealStatuses',
                            fields: ['subservices.status.code'],
                            type: 'checkboxes',
                            items: AppealData.statuses,
                            hiddenProperty: 'isHidden',
                            itemStyleProperty: 'background',
                            itemName: 'name',
                            itemValue: 'code',
                            labelWidth: 140,
                            linkFilter: 'appealActuality',
                            hiddenOnLinkFilterValueCodes: ['appealsPreArchive', 'appealsArchive'],
                        },
                    ],
                },

                {
                    name: 'Источник',
                    filters: [
                        {
                            type: 'checkboxes',
                            isInParentComponentProcessing: true,
                            code: 'source',
                            items: [
                                {
                                    code: 'fromEpgu',
                                    name: 'ЕПГУ',
                                },
                                {
                                    code: 'fromPgu',
                                    name: 'РПГУ',
                                },
                                {
                                    code: 'fromPgu2',
                                    name: 'РПГУ 2.0',
                                },
                                {
                                    code: 'fromMfc',
                                    name: 'МФЦ',
                                },
                                {
                                    code: 'fromStroyu',
                                    name: 'Ястрою',
                                },
                                {
                                    code: 'fromLocolo',
                                    name: 'Локоло',
                                },
                                {
                                    code: 'fromZabota',
                                    name: 'Забота',
                                },
                            ],
                        },
                    ],
                },
                // {
                //     name: 'Вид КНМ',
                //     filters: [{
                //         fields: ['subservices.kndInfo.kndType.code'],
                //         type: 'checkboxes',
                //         storageItem: 'nsiErpKnmTypes'
                //     }]
                // },
                // {
                //     name: 'Форма КНМ',
                //     filters: [
                //         {
                //             fields: ['subservices.kndInfo.kndForm.code'],
                //             type: 'checkboxes',
                //             storageItem: 'nsiErpKnmForms',
                //         }
                //     ],
                // },
                {
                    name: 'Создатель', // TPPPGMU-57
                    filters: [
                        {
                            fields: ['userCreation.login', 'userCreation.name'],
                            type: 'input',
                            placeholder: 'ФИО или логин',
                        },
                    ],
                },
            ],
        },
        // {
        //     groupId: 'add-group',
        //     icon: 'icon-menu6',
        //     show: false,
        //     data: [
        //         {
        //             name: 'Мои КНМ',
        //             filters: [
        //                 {
        //                     fields: ['userCreation.id', 'userLastModification.id'],
        //                     type: 'checkbox',
        //                     placeholder: 'Только мои',
        //                     storageItem: 'user',
        //                     storageField: 'id'
        //                 }
        //             ]
        //         },
        //         {
        //             name: 'Под моим контролем',
        //             filters: [
        //                 {
        //                     fields: ['controlOperator.id'],
        //                     type: 'checkbox',
        //                     shortName: 'Вкл.',
        //                     placeholder: 'КНМ под моим контролем',
        //                     storageItem: 'user',
        //                     storageField: 'id'
        //                 }
        //             ]
        //         },
        //         // {
        //         //     name: 'Мои дела',
        //         //     filters: [
        //         //         {
        //         //             type: 'appeals',
        //         //             items: [
        //         //                 {
        //         //                     name: 'Только мои',
        //         //                     code: 'my'
        //         //                 },
        //         //                 {
        //         //                     name: 'Под моим контролем',
        //         //                     code: 'control'
        //         //                 }
        //         //             ]
        //         //         }
        //         //     ]
        //         // },
        //     ]
        // }
    ];

    public static appealsActualityFilter = {
        name: 'filters.appeals_relevance',
        displayName: 'filters.appeals_relevance',
        code: APPEALS_ACTUALITY,
        fields: ['collection'],
        type: 'radioboxes',
        dependFilter: 'appealStatuses',
        items: [
            {
                name: 'filters.active',
                code: ACTUALITY_STATUS.ACTUAL,
                // блок связанных состояний, не обязательное свойство, является дочерним для на родительского свойства "dependFilter"
                showDependChild: [
                    'draft',
                    'process',
                    'checking',
                    'processing',
                    'approveDate',
                    'requestInfo',
                    'paused',
                    'prepareResult',
                    'payment',
                ],
                showChild: true,
                isDefault: true,
            },
            {
                name: 'filters.done',
                code: ACTUALITY_STATUS.COMPLETE,
                showDependChild: ['complete', 'serviceReject', 'receiveDocsReject', 'annulled'],
                showChild: true,
                isDefault: false,
            },
            {
                name: 'filters.archived',
                code: ACTUALITY_STATUS.PREARCHIVE,
                showChild: false,
                isDefault: false,
            },
            {
                name: 'filters.depersonalize',
                code: ACTUALITY_STATUS.ARCHIVE,
                showChild: false,
                isDefault: false,
            },
        ],
    };

    public static statusFilter = {
        name: 'filters.status',
        displayName: 'filters.status',
        code: APPEALS_STATUS,
        fields: ['subservices.status.code'],
        type: 'checkboxes',
        items: AppealData.statuses,
        hiddenProperty: 'isHidden',
        itemStyleProperty: 'background',
        itemName: 'name',
        itemValue: 'code',
        labelWidth: 140,
        linkFilter: 'appealActuality',
        hiddenOnLinkFilterValueCodes: ['appealsPreArchive', 'appealsArchive'],
    };

    public static sourceFilter = {
        name: 'filters.source.name',
        displayName: 'filters.source.name',
        code: APPEALS_SOURCE,
        type: 'checkboxes',
        fields: ['source'],
        items: [
            {
                name: 'filters.source.epgu',
                code: 'epgu',
                placeholder: 'filters.source.epgu',
            },
            {
                name: 'filters.source.mfc',
                code: 'mfc',
                placeholder: 'filters.source.mfc',
            },
            {
                name: 'filters.source.rpgu',
                code: 'pgu',
                placeholder: 'filters.source.rpgu',
            },
            {
                name: 'filters.source.rpgu2',
                code: 'usluginew',
                placeholder: 'filters.source.rpgu2',
            },
            {
                name: 'filters.source.ya_stroyu',
                code: 'stroyu',
                placeholder: 'filters.source.ya_stroyu',
            },
            {
                name: 'filters.source.locolo',
                code: 'locolo',
                placeholder: 'filters.source.locolo',
            },
            {
                name: 'filters.source.zabota',
                code: 'zabota',
                placeholder: 'filters.source.zabota',
            },
            {
                name: 'filters.source.arm',
                code: 'arm',
                placeholder: 'filters.source.arm',
            },
        ],
    };

    public static noFilterFilter = {
        name: 'filters.all_organizations',
        displayName: 'filters.all_organizations',
        type: 'checkboxes',
        isInParentComponentProcessing: true,
        code: 'noLimitByUnit',
        items: [
            {
                name: 'filters.do_not_filter',
                code: 'noFilter',
                placeholder: 'filters.all_organizations',
            },
        ],
    };

    public static mandatoryFilters = [
        AppealData.appealsActualityFilter,
        AppealData.statusFilter,
        AppealData.sourceFilter,
    ];

    // Статусы, которые являются завершающими
    public static finishStatuses = [
        'archive',
        'annulled',
        'issued',
        'issuedPost',
        'issuedReturnToOGV',
        'issuedOther',
        'rejectedReceiptDocs',
    ];

    // TODO load from DB
    public static statusesTaken = [
        {
            code: '0',
            background: 'bg-purple-300',
            theme: 'purple',
        },
        {
            code: '1',
            background: 'bg-success-300',
            theme: 'success',
        },
    ];

    // Типы сведений
    public static entityTypes = {
        order: {
            code: 'order',
            name: 'entity-types.order',
        },
        act: {
            code: 'act',
            name: 'entity-types.act',
        },
        notification: {
            code: 'notification',
            name: 'entity-types',
        },
        actNotImpossible: {
            code: 'actNotImpossible',
            name: 'entity-types.act_not_impossible',
        },
        requestMotivated: {
            code: 'requestMotivated',
            name: 'entity-types.request_motivated',
        },
        requirementExplanation: {
            code: 'requirementExplanation',
            name: 'entity-types.requirement_explanation',
        },
        regulation: {
            code: 'regulation',
            name: 'entity-types.prescription',
        },
        checkStatement: {
            code: 'checkStatement',
            name: 'entity-types.check_statement',
        },
        checkExamination: {
            code: 'checkExamination',
            name: 'entity-types.check_examination',
        },
        raidTask: {
            code: 'raidTask',
            name: 'entity-types.raid_task',
        },
        raidAct: {
            code: 'raidAct',
            name: 'entity-types.raid_act',
        },
        raidWarning: {
            code: 'raidWarning',
            name: 'entity-types.raid_warning',
        },
        raidAnswer: {
            code: 'raidAnswer',
            name: 'entity-types.raid_answer',
        },
        observationDocument: {
            code: 'observationDocument',
            name: 'entity-types.observation_document',
        },
        observationAct: {
            code: 'observationAct',
            name: 'entity-types.observation_act',
        },
        observationNotify: {
            code: 'observationNotify',
            name: 'entity-types.observation_notify',
        },
        observationRequest: {
            code: 'observationRequest',
            name: 'entity-types.observation_request',
        },
        observationWarning: {
            code: 'observationWarning',
            name: 'entity-types.observation_warning',
        },
        observationPrescription: {
            code: 'observationPrescription',
            name: 'entity-types.observation_prescription',
        },
        observationAnswer: {
            code: 'observationAnswer',
            name: 'entity-types.observation_answer',
        },
        buyOrder: {
            code: 'buyOrder',
            name: 'entity-types.buy_order',
        },
        buyNotice: {
            code: 'buyNotice',
            name: 'entity-types.buy_notice',
        },
        buyAct: {
            code: 'buyAct',
            name: 'entity-types.buy_act',
        },
        buyActDistance: {
            code: 'buyActDistance',
            name: 'entity-types.buy_act_distance',
        },
        proceedInitiation: {
            code: 'proceedInitiation',
            name: 'entity-types.proceed_initiation',
        },
        proceedConducting: {
            code: 'proceedConducting',
            name: 'entity-types.proceed_conducting',
        },
        proceedInformation: {
            code: 'proceedInformation',
            name: 'entity-types.proceed_information',
        },
        proceedProtocol: {
            code: 'proceedProtocol',
            name: 'entity-types.proceed_protocol',
        },
        proceedProtocolMakeup: {
            code: 'proceedProtocolMakeup',
            name: 'entity-types.proceed_protocol_makeup',
        },
        proceedProtocolReview: {
            code: 'proceedProtocolReview',
            name: 'entity-types.proceed_protocol_review',
        },
        proceedReferral: {
            code: 'proceedReferral',
            name: 'entity-types.proceed_referral',
        },
        proceedDecree: {
            code: 'proceedDecree',
            name: 'entity-types.proceed_decree',
        },
        proceedAction: {
            code: 'proceedAction',
            name: 'entity-types.proceed_action',
        },
        proceedTermination: {
            code: 'proceedTermination',
            name: 'entity-types.proceed_termination',
        },
        violationDecision: {
            code: 'violationDecision',
            name: 'entity-types.violation_decision',
        },
        violationMeasure: {
            code: 'violationMeasure',
            name: 'entity-types.violation_measure',
        },
        damage: {
            code: 'damage',
            name: 'entity-types.damage',
        },
        nsiViolations: {
            code: 'nsiViolations',
            name: 'entity-types.nsi_violations',
        },
        riskEvent: {
            code: 'riskEvent',
            name: 'entity-types.risk_event',
        },
        incomingDocument: {
            code: 'incomingDocument',
            name: 'entity-types.incoming_document',
        },
        conformityConclusion: {
            code: 'conformityConclusion',
            name: 'entity-types.conformity_conclusion',
            plugin: 'gsn',
        },
        rejectConformityConclusion: {
            code: 'rejectConformityConclusion',
            name: 'entity-types.reject_conformity_conclusion',
            plugin: 'gsn',
        },
    };
}
