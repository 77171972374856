import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    CORE_CONFIG,
    CoreDynamicConfigInterface,
    ModalDialogComponent,
    StorageService,
    TranslateService,
} from '@evolenta/core';
import { VersionService } from '../../services/version.service';

@Component({
    selector: 'version',
    templateUrl: 'version.component.html',
    styleUrls: [
        'version.component.less',
    ],
})
export class VersionComponent implements OnInit {
    public helpData: { [key: string]: any };
    public localizations;

    @ViewChild('aboutModal', { static: false }) public aboutModalComponent: ModalDialogComponent;
    public constructor(
        public versionService: VersionService,
        @Inject(CORE_CONFIG) private readonly config: CoreDynamicConfigInterface,
        private storage: StorageService,
        private translate: TranslateService,
    ) {
    }

    public get appConfig() {
        return this.config.appConfig;
    }

    public get aboutConfig() {
        return this.config.aboutConfig;
    }

    public ngOnInit() {
        this._loadTranslations();
        this.versionService.checkVersion();

        this.helpData = this.storage.getItem(this.config.storageConfig.standHelpKey);
    }

    public openAboutModal() {
        this.aboutModalComponent.showModal();
    }

    public goToServiceDesk() {
        location.replace(this.config.aboutConfig.serviceDeskLink);
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public reload() {
        this.versionService.changeCurrentVersionOnNew();
        location.reload();
    }
}
