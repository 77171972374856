import { NgModule } from '@angular/core';
import { CoreModule } from '@evolenta/core';
import { SubjectsModule } from '../subjects/subjects.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { CommonAppealDocumentsModule } from './common-appeal-documents.module';
import { CommonAppealEntitiesService } from './components/appeal-entities/common-appeal-entities.service';
import { CommonAppealEntitiesComponent } from './components/appeal-entities/common-appeal-entities.component';
import { CommonAppealEntityCustomComponent } from './components/appeal-entities/components/entity-custom/common-appeal-entity-custom.component';
import { CommonAppealEntityCardComponent } from './components/appeal-entities/components/entity-card/common-appeal-entity-card.component';
import { CommonAppealEntityDocumentsComponent } from './components/appeal-entities/components/entity-documents/common-appeal-entity-documents.component';
import { CommonAppealEntityStaticComponent } from './components/appeal-entities/components/entity-static/common-appeal-entity-static.component';

@NgModule({
    imports: [
        CoreModule,
        SharedComponentsModule,
        SharedElementsModule,
        InlineSVGModule,
        SubjectsModule,
        CommonAppealDocumentsModule,
    ],
    declarations: [
        CommonAppealEntitiesComponent,
        CommonAppealEntityCardComponent,
        CommonAppealEntityDocumentsComponent,
        CommonAppealEntityCustomComponent,
        CommonAppealEntityStaticComponent,
    ],
    providers: [
        CommonAppealEntitiesService,
    ],
    exports: [
        CommonAppealEntitiesComponent,
    ],
})
export class CommonAppealEntitiesModule {
}
