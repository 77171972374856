<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <!-- Заголовок страницы -->
    <div class="navbar-title-menu-width mt-10 display-flex align-items-center"
         *ngIf="!selectionService.isProcessSelect">
        <h4>
            {{ localizations.common.participants }}
            <span class="badge badge-primary text-uppercase ml-10">
                {{totalCount}}
            </span>
        </h4>
    </div>

    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="selectionService.isProcessSelect"
            (click)="returnToOperation()">
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5">{{selectionService.selectedItems?.length}}</span>
    </button>

    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   [placeholder]="localizations.common.search_dots"
                   type="text"
                   [(ngModel)]="globalSearch"
                   (keypress)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted" type="button" (click)="loadData('replace')"><i
                    class="icon-search4 text-muted"></i></button>
            </div>
        </div>
    </div>

    <!-- Кнопки авторизации + доп.меню -->
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Панель фильтрации -->
            <filters-bar [filters]="filtersPanelItems" [afterHeader]="false" filterName="subjectsFilter"
                                  [base]="baseSearch"
                                  (onApplyFilters)="loadData('replace')" #filtersPanel></filters-bar>
        </div>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData('add')">
                            <ng-container *ngIf="subjects.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of subjects">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                             [ngClass]="{'active': subject && subject._id === item._id }">
                                            <div class="checkbox-block mt-20 mb-20 ml-20"
                                                 *ngIf="selectionService.isProcessSelect">
                                                <input class="checkbox"
                                                       type="checkbox" id="selectSubject{{ item._id }}"
                                                       [checked]="item.selected"
                                                       (click)="dblClick(item)">
                                                <label for="selectSubject{{ item._id }}" style="top: -18px"></label>
                                            </div>
                                            <div class="list-item"
                                                 (click)="click(item)"
                                                 (dblclick)="dblClick(item)"
                                                 [style.width] = "selectionService.isProcessSelect ?
                                                 'calc(100% - 50px)' :
                                                 '100%'">
                                                <div class="list-item-status-rectangle text-uppercase"
                                                     [ngClass]="{'bg-primary-300': item.specialTypeId === 'ulApplicant', 'bg-info-300': item.specialTypeId === 'ipApplicant', 'bg-purple-300': item.specialTypeId == 'individualApplicant'}">
                                                    {{ item.specialTypeId === 'ulApplicant' ? 'Юридическое лицо' : item.specialTypeId === 'ipApplicant' ? 'Индивидуальный предприниматель' : 'Физическое лицо'}}
                                                </div>
                                                <div class="list-item-data"
                                                     [ngStyle]="{'width': 'calc(100% - 55px)'}">
                                                    <h6 class="text-bold no-margin">
                                                        <span *ngIf="item.specialTypeId != 'ulApplicant' && item.data && item.data.person">
                                                            {{item.data.person.lastName}}
                                                            {{item.data.person.firstName}}
                                                            {{item.data.person.middleName}}
                                                        </span>
                                                        <span *ngIf="item.specialTypeId == 'ulApplicant'">
                                                            <ng-container *ngIf="item.data && item.data.organization">
                                                                {{item.data.organization.shortName ? item.data.organization.shortName : item.data.organization.name}}
                                                            </ng-container>
                                                        </span>
                                                    </h6>
                                                    <p class="no-margin-bottom ellipsis"
                                                       *ngIf="item.specialTypeId != 'ulApplicant' && item.data.person">
                                                        <ng-container *ngIf="item.data.person.birthday">
                                                            <span class="text-muted text-uppercase text-size-small mr-10">
                                                                {{ localizations.common.date_of_birth }}: {{item.data.person.birthday.formatted}}
                                                            </span>
                                                        </ng-container>

                                                        <span class="text-muted text-uppercase text-size-small">
                                                            {{ localizations.common.document }}:
                                                        </span>
                                                        <span class="text-muted text-size-small"
                                                              *ngIf="item.data.person.documentType">
                                                            {{item.data.person.documentType[0].text}}
                                                        </span>
                                                        <span class="text-muted text-size-small"
                                                              *ngIf="item.data.person.documentSeries">
                                                            {{ localizations.common.series }} {{item.data.person.documentSeries}}
                                                        </span>
                                                        <span class="text-muted text-size-small"
                                                              *ngIf="item.data.person.documentNumber">
                                                            {{ localizations.subjects.number_sign }} {{item.data.person.documentNumber}}
                                                        </span>
                                                        <span class="text-muted text-size-small mr-10"
                                                              *ngIf="item.data.person.snils">
                                                            {{ localizations.subjects.snils }}: {{item.data.person.snils}}
                                                        </span>
                                                        <span class="text-muted text-size-small mr-10"
                                                              *ngIf="item.data.person.inn">
                                                            {{localizations.common.inn }}: {{item.data.person.inn}}
                                                        </span>
                                                    </p>
                                                    <p class="no-margin-bottom ellipsis"
                                                       *ngIf="item.specialTypeId == 'ulApplicant'">
                                                        <span class="text-muted text-uppercase text-size-small mr-10">
                                                            {{ localizations.common.ogrn }}: {{ item.data.organization.ogrn }}
                                                        </span>
                                                        <span class="text-muted text-uppercase text-size-small mr-10">
                                                            {{ localizations.common.inn }}: {{ item.data.organization.inn }}
                                                        </span>
                                                        <span class="text-muted text-uppercase text-size-small">
                                                            {{ localizations.subjects.kpp }}: {{ item.data.organization.kpp }}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="panel panel-shadow m-15 p-15" *ngIf="subjects.length == 0">
                                <div class="panel-body text-center">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing }}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations.subjects.try_to_change_filter }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-warning alert-styled-left alert-bordered no-margin-bottom"
                         *ngIf="!subject">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.subjects.for_detailed_view }}
                    </div>
                    <subject-preview [subject]="subject"
                                     *ngIf="previewMode && subject">
                    </subject-preview>
                </div>
            </div>
        </div>
    </div>
</div>
