import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard, UniversalCollectionPageComponent } from '@evolenta/core';
import { SectionComponent } from '@components/section/section.component';
import { UsersComponent } from '../../modules/users/users/users.component';
import { UsersResolve } from '../../modules/users/users/users.resolve';
import { UserEditComponent } from '../../modules/users/users/user-edit.component';
import { Permission } from '../../common/services/permission';
import { SettingsComponent } from '../../modules/settings/settings/settings.component';
import { SettingEditComponent } from '../../modules/settings/settings/setting-edit.component';
import { SettingsResolve } from '../../modules/settings/settings/settings.resolve';
import { OrganizationComponent } from '../../modules/organizations/organization/organizations.component';
import { OrganizationResolve } from '../../modules/organizations/organization/organization.resolve';
import { OrganizationEditComponent } from '../../modules/organizations/organization/organization-edit.component';
import { RolesMnemonicsComponent } from '../../modules/rolesMnemonics/mnemonics/roles-mnemonics.component';
import { RoleMnemonicComponent } from '../../modules/rolesMnemonics/mnemonics/role-mnemonic.component';
import { RolesMnemonicsResolve } from '../../modules/rolesMnemonics/mnemonics/roles-mnemonics.resolve';
import { GlobalCalendarComponent } from '../../modules/calendar/global-calendar.component';
import { GlobalCalendarResolve } from '../../modules/calendar/global-calendar.resolve';
import { SystemTaskEditComponent, SystemTasksResolve } from '@evolenta/system-tasks-module';
import { SystemTasksListOptions } from './option/system-tasks-list.options';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'admin',
        component: SidebarComponent,
        data: {
            module: 'admin',
            breadcrumb: 'Администрирование',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            // ----------------- Пользователи --------------- //
            {
                path: 'users',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Пользователи',
                    img: 'main-menu/users',
                    breadcrumb: 'Список пользователей',
                },
                children: [
                    {
                        path: '',
                        component: UsersComponent,
                        data: {
                            name: 'Список объектов',
                            breadcrumb: 'Список пользователей',
                        },
                        resolve: {
                            resolves: UsersResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: UserEditComponent,
                        data: {
                            breadcrumb: 'Создание пользователя',
                        },
                        resolve: {
                            resolves: UsersResolve,
                        },
                    },
                    {
                        path: 'edit/:userId',
                        component: UserEditComponent,
                        data: {
                            breadcrumb: 'Редактирование пользователя',
                        },
                        resolve: {
                            resolves: UsersResolve,
                        },
                    },
                ],
            },

            // ----------------- Роли --------------- //
            {
                path: 'roles',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Роли',
                    img: 'main-menu/roles',
                    breadcrumb: 'Роли',
                },
                children: [
                    {
                        path: '',
                        component: RolesMnemonicsComponent,
                        data: {
                            name: 'Список объектов',
                            breadcrumb: 'Роли',
                        },
                    },
                    {
                        path: 'create',
                        component: RoleMnemonicComponent,
                        data: {
                            breadcrumb: 'Создание роли',
                        },
                        resolve: {
                            resolves: RolesMnemonicsResolve,
                        },
                    },
                    {
                        path: 'edit/:roleMnemonicId',
                        component: RoleMnemonicComponent,
                        data: {
                            breadcrumb: 'Редактирование роли',
                        },
                        resolve: {
                            resolves: RolesMnemonicsResolve,
                        },
                    },
                ],
            },

            // ----------------- Организации --------------- //
            {
                path: 'organizations',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Организации',
                    img: 'main-menu/organizations',
                    breadcrumb: 'Список организаций',
                },
                children: [
                    {
                        path: '',
                        component: OrganizationComponent,
                        data: {
                            name: 'Список организаций',
                            breadcrumb: 'Список организаций',
                        },
                        resolve: {
                            resolves: OrganizationResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: OrganizationEditComponent,
                        data: {
                            breadcrumb: 'Создание организации',
                        },
                        resolve: {
                            resolves: OrganizationResolve,
                        },
                    },
                    {
                        path: 'edit/:organizationId',
                        component: OrganizationEditComponent,
                        data: {
                            breadcrumb: 'Редактирование организации',
                        },
                        resolve: {
                            resolves: OrganizationResolve,
                        },
                    },
                ],
            },

            // -------------------Настройки-----------------------//
            {
                path: 'settings',
                component: SectionComponent,
                data: {
                    name: 'Настройки',
                    img: 'main-menu/settings',
                    permissions: [Permission.Settings_Search],
                    breadcrumb: 'Список настроек',
                },
                children: [
                    {
                        path: '',
                        component: SettingsComponent,
                        data: {
                            permission: [Permission.Settings_Search],
                            breadcrumb: 'Список настроек',
                        },
                    },
                    {
                        path: 'edit/:settingId',
                        component: SettingEditComponent,
                        data: {
                            permission: [Permission.Setting_Update],
                            breadcrumb: 'Редактирование настройки',
                        },
                        resolve: { setting: SettingsResolve },
                    },
                    {
                        path: 'create',
                        component: SettingEditComponent,
                        data: {
                            permission: [Permission.Setting_Create],
                            breadcrumb: 'Создание настройки',
                        },
                        resolve: { setting: SettingsResolve },
                    },
                ],
            },

            // -------------------Производственный календарь-----------------------//
            {
                path: 'global-calendar',
                component: SectionComponent,
                data: {
                    name: 'Производственный календарь',
                    img: 'main-menu/calendar',
                    breadcrumb: 'Производственный календарь',
                },
                children: [
                    {
                        path: '',
                        component: GlobalCalendarComponent,
                        data: {
                            breadcrumb: 'Настройка производственного календаря',
                        },
                        resolve: { resolves: GlobalCalendarResolve },
                    },
                ],
            },

            // ------------------- Служебные задачи -----------------------//
            {
                path: 'system-tasks',
                component: SectionComponent,
                data: {
                    name: 'Служебные задачи',
                    breadcrumb: 'Служебные задачи',
                    img: 'main-menu/systemTasks',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            options: SystemTasksListOptions,
                        },
                        resolve: {
                            resolves: SystemTasksResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: SystemTaskEditComponent,
                        data: {
                            name: 'Создание служебной задачи',
                            breadcrumb: 'Создание служебной задачи',
                        },
                        resolve: {
                            resolves: SystemTasksResolve,
                        },
                    },
                    {
                        path: 'edit/:taskId',
                        component: SystemTaskEditComponent,
                        data: {
                            name: 'Редактирование служебной задачи',
                            breadcrumb: 'Редактирование служебной задачи',
                        },
                        resolve: {
                            resolves: SystemTasksResolve,
                        },
                    },
                ],
            },

            {
                path: '',
                data: {},
                redirectTo: 'users',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class AdminRouting {}
