import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintUtilities } from '@evolenta/utilities';
import { SelectionService, ToasterService, TranslateService } from '@evolenta/core';

import { CommonAppealService } from '../../services/common-appeal.service';
import { CopyService } from '../../../../common/services/copy.service';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';

@Component({
    selector: 'common-appeal-preview',
    templateUrl: './common-appeal-preview.component.html',
})
export class CommonAppealPreviewComponent implements OnChanges, OnInit {
    @Input() public appeal: any;
    @Input() public mode = 'full';
    @Input() public metaReglament;

    public groups = [];
    public statuses: any[];

    public tabs = {
        common: true,
        subservices: true,
        objects: true,
        subjects: true,
        documents: true,
        kndInfo: true,
    };
    public moduleBaseUrl;
    public baseUrl;
    public localizations;

    public constructor(
        public appealService: CommonAppealService,
        public selectionService: SelectionService,
        private appealStatusService: CommonAppealStatusService,
        private copyService: CopyService,
        private toaster: ToasterService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        this.route.parent.url.subscribe(urlPath => {
            this.baseUrl = urlPath[urlPath.length - 1].path;
        });

        this.getDocumentGroups();
        this.completeSubserviceObjects();

        if (this.mode === 'card') {
            Object.keys(this.tabs).forEach(tab => {
                this.tabs[tab] = false;
            });
        }

        this.appealStatusService.metaReglament = this.metaReglament;
        this.statuses = this.appealStatusService.getStatuses();
    }

    public getConsultaionFromMessage() {
        const template = this.localizations.appeals.consultation_from;

        return template.replace('%a', this.appeal.consultationNumber).replace('%b', this.appeal.dateConsultation);
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
                'appeals.subjects',
                'organizations',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public ngOnChanges(changes) {
        if (changes.appeal.currentValue && this.appeal !== changes.appeal.currentValue) {
            this.appeal = changes.appeal.currentValue;
            if (this.appeal.status.code !== 'archive') {
                this.getDocumentGroups();
                this.completeSubserviceObjects();
            }
        }
    }

    public completeSubserviceObjects() {
        if (!(this.appeal.subservice.objects && this.appeal.subservice.objects.length)) {
            return;
        }

        this.appeal.subservice.objects.forEach(object => {
            const foundObject = this.appeal.objects.find(item => item.guid === object.guid);
            if (foundObject) {
                object.header = foundObject.header;
            }
        });
    }

    public getDocumentGroups() {
        this.groups = [];
        const groups: any = {};
        this.appeal.documents.forEach(document => {
            if (!document.groupGuid) {
                document.groupGuid = 'other';
                document.groupName = 'Прочие документы';
            }
            if (groups[document.groupGuid]) {
                groups[document.groupGuid].documents.push(document);
            } else {
                groups[document.groupGuid] = {
                    name: document.groupName,
                    documents: [document],
                };
            }
        });
        Object.keys(groups).forEach(key => {
            this.groups.push(groups[key]);
        });
    }

    public colorLabelStatuses(code, additional?) {
        if (code) {
            return this.appealService.getColorForLabelStatuses(code, additional);
        } else {
            return '';
        }
    }

    /**
     * Получение значения для свойства статуса дела
     * @param property - обрабатываемое свойство
     * @param appealSubservice - услуга дела
     * @returns {any}
     */
    public getStatusProperty(property: string, appealSubservice?: any): any {
        const status = appealSubservice ? appealSubservice.status : this.appeal.status;

        return this.appealStatusService.getStatusProperty(status, property, this.statuses);
    }

    public getSubserviceCode(subservice) {
        const arr = subservice.serviceId.split('_');

        return arr[3];
    }

    /**
     * Копирование данных объекта в буфер
     */
    public copyToBuffer(object) {
        this.copyService.copyData(object.data, 'object');
        this.toaster.info('Данные успешно скопированы');
    }

    public edit(): void {
        this.router.navigate([
            this.moduleBaseUrl,
            this.isFinancialControl() ? 'control-activities' : this.appealService.appealsPath,
            'edit',
            this.appeal._id,
        ]);
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public getSubjectHeaderByGuid(guid) {
        const result = this.appeal.subjects.find(item => item.guid === guid);

        return result && result.header ? result.header : 'данные отсутствуют';
    }

    public isFinancialControl(): boolean {
        return this.appeal.parentEntries === 'financialControlAppeals';
    }
}
