<div class="panel panel-shadow m-20" *ngFor="let object of objects">
    <div class="panel-heading pl-20 pr-20">
        <div class="panel-title">
            <h4 class="no-margin-top mb-5">{{ object.name ? object.name : 'Новый объект' }}</h4>
            <div *ngIf="object.type">
                <span class="badge text-uppercase mr-10 bg-slate-300">
                    {{ object.type.name }}
                </span>
            </div>
        </div>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default"
                    [title] = "localizations.common.edit"
                    (click)="goToObject(object)">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>
            <button class="btn btn-default"
                    [title]="localizations.common.delete"
                    (click)="removeObject(object)"
                    [disabled]="!allowEdit">
                <b><i inlineSVG="assets/icon-trash.svg"></i></b>
            </button>
        </div>
    </div>
    <div class="panel-body p-20" *ngIf="object.address && (object.address | json) != '{}'">
        <span class="text-muted">
            {{ localizations.common.address }}:
        </span>
        {{ getAddress(object.address.fullAddress) }}
    </div>
</div>

<div class="alert alert-warning no-border no-border-radius"
     *ngIf="!objects || objects.length == 0">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations.subjects.attached_objects_missing}}
</div>

<button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20 mr-20"
        (click)="selectObjects()"
        [disabled]="!allowEdit">
    {{ localizations.subjects.add_object }}
    <b>
        <i inlineSVG="assets/icon-plus-round.svg"></i>
    </b>
</button>
