<div class="panel panel-shadow m-20 no-margin-right">
    <div class="panel-heading bg-success-50 pt-10 pb-10">
        <div class="panel-title">
            <h4 class="panel-title no-margin-right">
                {{ localizations.epgu_integration.mapping.item.name }}
            </h4>
        </div>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <div class="btn-group">
                <!-- Переключиться в режим редактирования -->
                <button class="btn btn-success btn-success-overline pt-5 pb-5"
                        (click)="switchToEditMode()"
                        *ngIf="isViewMode()">
                    <b>
                        <i class="icon-pencil">
                        </i>
                    </b>

                    {{ localizations.common.edit }}
                </button>

                <!-- Сохранение и выход из режима редактирования -->
                <button class="btn btn-success btn-success-overline"
                        (click)="switchToViewMode()"
                        *ngIf="isEditMode()">
                    {{ localizations.common.save }}
                </button>

                <!-- Сброс режима редактирования -->
                <button class="btn btn-success btn-success-overline"
                        (click)="switchToViewMode()"
                        *ngIf="isEditMode()">
                    {{ localizations.common.cancel }}
                </button>
            </div>
        </div>
    </div>

    <div class="panel-footer no-padding">
        <div class="date-elements flex flex-column mt-10">
            <div class="p-20 row full-width parameter-field"
                 [ngClass]="{
                    'bottom-border': i < getPreviousParamsList().length - 1
                 }"
                 *ngFor="let param of getPreviousParamsList(); let i = index">
                <!-- Старое значение -->
                <div class="col-md-6 pt-5 pb-5 row"
                     [ngClass]="{
                        'right-border col-md-6': inCreate,
                        'col-md-12': !inCreate
                     }">
                    <h5 class="date-text no-margin-top right-border"
                        [ngClass]="{
                            'col-md-3': !inCreate,
                            'col-md-5': inCreate
                        }">
                        {{ param }}
                    </h5>

                    <div class="date-value"
                         [ngClass]="{
                            'col-md-9': !inCreate,
                            'col-md-4': inCreate
                        }"
                         *ngIf="inCreate || isViewMode()">
                        {{ item[param] }}
                    </div>

                    <div class="col-md-4"
                         *ngIf="!inCreate && isEditMode()">
                        <input type="text"
                               class="form-control"
                               [(ngModel)]="item[param]">
                    </div>

                    <div class="col-md-3"
                         *ngIf="inCreate">
                        <button class="btn"
                            (click)="copy(param)">
                            {{ localizations.common.copy }}
                        </button>
                    </div>
                </div>

                <!-- Новое значение -->
                <div class="col-md-6 pt-5 pb-5 row"
                     *ngIf="inCreate">
                    <div class="col-md-4">
                        <h5 class="date-text no-margin-top pl-10">
                            {{ param.replace('current', 'new') }}
                        </h5>
                    </div>

                    <div class="col-md-7 col-md-offset-1 date-value"
                         [ngClass]="{
                            'text-danger': item[param.replace('current', 'new')] !== item[param]
                         }"
                         *ngIf="isViewMode()">
                        {{ item[param.replace('current', 'new')] }}
                    </div>

                    <div class="col-md-7 col-md-offset-1"
                         *ngIf="isEditMode()">
                        <input type="text"
                               class="form-control"
                               [(ngModel)]="item[param.replace('current', 'new')]">
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
