import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService, ToasterService, TranslateService } from '@evolenta/core';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { HolidaysComponent } from './holidays/holidays.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NEXT_YEARS_FOR_NEW_CALENDAR } from '../../config/constants';

@Component({
    selector: 'global-calendar',
    templateUrl: './global-calendar.component.html',
    styles: [
        ':host { width: 100%; display: flex; align-items: stretch; height: 100%; flex-direction: column; }',
        '.info-bar { border-bottom: 1px solid #E5E5EB; }',
    ],
})
export class GlobalCalendarComponent implements OnInit {
    public calendarData;
    public baseCalendarData;
    public activeCalendarTab;
    public isVisibleCalendarWidget = true;
    public localizations;
    public holidays;

    public activeTab; // код активной вкладки
    public calendarOptions = [];

    public calendarForm = {
        options: [],
        year: null,
        setDefault: true,
    };

    @ViewChild(HolidaysComponent, { static: false }) public holidaysComponent: HolidaysComponent;

    public modalRef: BsModalRef;
    @ViewChild('createCalendarModal', { static: false }) public createCalendarModal: TemplateRef<any>;

    public constructor(
        private route: ActivatedRoute,
        private restService: RestService,
        private toaster: ToasterService,
        private translate: TranslateService,
        private modalService: BsModalService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.data.subscribe((response) => {
            this.activeTab = 'calendar';
            this.prepareCalendarData(response.resolves.calendar);
            this.holidays = response.resolves.holidays;
            this.setOptionsCalendar();
            this.setOptionsModal();
        });
    }

    public setOptionsModal() {
        const currYear = moment().year();
        const yearsExists = this.calendarData.map((item) => item.year);

        this.calendarForm.options = [];
        for (let i = 0; i < NEXT_YEARS_FOR_NEW_CALENDAR; i++) {
            const year = currYear + i;
            if (!yearsExists.includes(year)) {
                this.calendarForm.options.push({
                    id: year,
                    text: `${year}`,
                });
            }
        }

        this.calendarForm.year = this.calendarForm.options[0].id;
    }

    public setOptionsCalendar() {
        this.calendarOptions = this.calendarData.map((yearData) => {
            return {
                id: yearData.year,
                text: `${yearData.year}`,
            };
        });
    }

    public selectYearModal(option) {
        this.calendarForm.year = option.id;
    }

    public selectYearWidget(year) {
        this.changeCalendarWidget(this.calendarData.find((item) => item.year === year));
    }

    public getSelectedWidget() {
        return this.calendarOptions.filter((item) => item.id === this.activeCalendarTab.year);
    }

    public getSelectedForCreating() {
        return this.calendarForm.options.filter((item) => item.id === this.calendarForm.year);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'global-calendar']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public prepareCalendarData(data) {
        this.calendarData = [];
        data.forEach((item) => {
            const findYearData = this.calendarData.find((elm) => elm.year === item.year);
            if (!findYearData) {
                this.calendarData.push({
                    id: item._id,
                    year: item.year,
                    days: item.days,
                });
            }
        });
        const currentYear = moment().year();
        const find = this.calendarData.find((item) => item.year === currentYear);
        if (!find) {
            this.calendarData.push({
                year: currentYear,
                days: {},
            });
        }
        this.baseCalendarData = _.cloneDeep(this.calendarData);
        this.activeCalendarTab = this.calendarData.find((item) => item.year === currentYear);
    }

    public changeCalendarWidget(data) {
        this.isVisibleCalendarWidget = false;
        setTimeout(() => {
            this.activeCalendarTab = data;
            this.isVisibleCalendarWidget = true;
        }, 100);
    }

    public back() {
        this.route.data.subscribe((response) => {});
    }

    public async saveByRoute() {
        this.activeTab === 'calendar' ? this.saveCalendar() : this.holidaysComponent.save();
    }

    public async saveCalendar() {
        const promises = [];
        const added = [];

        if (this.calendarData && this.calendarData.length > 0) {
            this.calendarData.forEach((yearData) => {
                const foundInBase = this.baseCalendarData.find((item) => item.year === yearData.year);
                if (foundInBase) {
                    if (JSON.stringify(foundInBase.days) !== JSON.stringify(yearData.days)) {
                        if (yearData.id) {
                            promises.push(
                                this.restService.update('unitsProductionCalendars', {
                                    _id: yearData.id,
                                    days: yearData.days,
                                })
                            );
                        }
                    }
                } else {
                    added.push(yearData.year);
                    promises.push(
                        this.restService.create('unitsProductionCalendars', {
                            days: yearData.days,
                            year: yearData.year,
                            unitId: null,
                            unitCode: null,
                            isDefault: true,
                        })
                    );
                }
            });
        }

        Promise.all(promises).then((result) => {
            result.forEach((resultItem) => {
                if (added.indexOf(resultItem.year) !== -1) {
                    const find = this.calendarData.find((item) => item.year === resultItem.year);
                    find.id = resultItem._id;
                }
            });
            this.baseCalendarData = _.cloneDeep(this.calendarData);
            this.toaster.success(this.localizations['global-calendar'].data_saved_success);
        });
    }

    public async holidaysChanged(data: any): Promise<void> {
        let updatedData;
        if (data._id) {
            updatedData = await this.restService.update('calendarHolidays', data);
        } else {
            updatedData = await this.restService.create('calendarHolidays', data);
        }
        this.toaster.success(this.localizations['global-calendar'].holidays_saved);
        this.holidays = updatedData;
    }

    public activateTab(tabCode) {
        if (tabCode) {
            this.activeTab = tabCode;
        }
    }

    public openCalendarModal() {
        this.modalRef = this.modalService.show(this.createCalendarModal, { backdrop: 'static' });
    }

    public async createCalendar() {
        const calendar: any = {
            year: this.calendarForm.year,
        };

        calendar.days = this.calendarForm.setDefault ? this.getDefaultWeekends(this.calendarForm.year) : {};

        this.calendarData.push(calendar);
        this.setOptionsModal();
        this.setOptionsCalendar();
        this.changeCalendarWidget(calendar);
        this.modalRef.hide();
    }

    public getDefaultWeekends(year) {
        const date = new Date(year, 0, 1);

        const firstDayOfYear = date.getDay();
        // если первый день года не суббота и не воскресенье
        if (0 < firstDayOfYear && firstDayOfYear < 6) {
            date.setDate(date.getDate() + (6 - firstDayOfYear));
        }

        const newDays = {};

        // добавляем выходные, пока не выходим за границы года
        while (date.getFullYear() === year) {
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const key = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;

            newDays[key] = { year, day, month };

            if (date.getDay() === 0) {
                date.setDate(date.getDate() + 6);
            } else if (date.getDay() === 6) {
                date.setDate(date.getDate() + 1);
            }
        }

        this.holidays.holidayList.forEach((holiday) => {
            const month = holiday.month;
            const day = holiday.day;
            const key = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
            newDays[key] = { year, day, month, isHoliday: true };
        });

        // проверка на существование 29го февраля
        const lastFebDay = `${year}0229`;
        if (newDays[lastFebDay]) {
            const isLeapYear = new Date(year, 1, 29).getMonth() === 1;
            if (!isLeapYear) {
                delete newDays[lastFebDay];
            }
        }

        return newDays;
    }

    public setDefaultWeekends() {
        this.activeCalendarTab.days = {
            ...this.activeCalendarTab.days,
            ...this.getDefaultWeekends(this.activeCalendarTab.year),
        };
    }
}
