import { NgModule } from '@angular/core';
import { ControlOperatorComponent } from './control-operator.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from '../../../../../common/shared-components.module';
import { DeleteConfirmationModalModule } from '../../../../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.module';

@NgModule({
    imports: [FormsModule, CommonModule, SharedComponentsModule, DeleteConfirmationModalModule],
    declarations: [ControlOperatorComponent],
    providers: [],
    exports: [ControlOperatorComponent],
})
export class ControlOperatorModule {}
