import { Injectable } from '@angular/core';
import { HttpService, RestService, StorageService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { Config } from '../../../common/services/config';

@Injectable()
export class OrganizationsService {
    /** Обрабатываемая организация */
    public organization;

    /** Статусы организаций */
    public statuses = [
        {
            code: true,
            name: 'Организация РСМЭВ',
            shortName: 'Организация РСМЭВ',
            theme: 'purple',
            labelText: 'ОР',
            label: 'bg-purple-50 text-purple',
            background: 'bg-purple-300',
            border: 'border-purple-400',
            backgroundLite: 'bg-purple-50',
            borderTop: 'border-top-purple-400',
            borderLeft: 'border-left-purple-400',
            text: 'text-purple-400',
        },
        {
            code: false,
            name: 'Внешняя организация',
            shortName: 'Внешняя организация',
            theme: 'primary',
            labelText: 'ВО',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-400',
            text: 'text-primary-400',
            icon: 'la la-paper-plan-o',
        },
    ];

    /** Настройки для панели фильтрации */
    public filtersPanelItems: any = [
        {
            name: 'Организация',
            filters: [
                {
                    fields: ['isAuthorized'],
                    type: 'checkboxes',
                    items: this.statuses,
                    isBoolean: true,
                    hiddenProperty: 'isHidden',
                    itemStyleProperty: 'background',
                    itemName: 'shortName',
                    itemValue: 'code',
                    labelWidth: 140,
                },
            ],
        },
        {
            name: 'Обновить коды услуг из .CSV файла',
            type: 'button',
            action: 'updateServiceCodes',
            isLoadFile: true,
        },
    ];

    public planApproveOptions = [
        { id: 'self', text: 'План согласовывается самостоятельно' },
        { id: 'byParentKNO', text: 'План согласовывается через вышестоящий КНО' },
    ];

    public constructor(
        private storage: StorageService,
        private restService: RestService,
        private httpService: HttpService
    ) {}

    /**
     * Получение свойства статуса
     * @param status - код статуса
     * @param property - необходимое свойство
     */
    public getStatusProperty(status, property) {
        const find = this.statuses.find((item) => {
            return item.code === !!status;
        });
        return find ? find[property] : '';
    }

    /**
     * Получение настроек панели фильтрации
     */
    public getFilterPanelData() {
        return this.filtersPanelItems;
    }

    public getKndKindsWithAutoCalculateRiskCategory(entityTypeId, entityTypeName) {
        let kndKindIds = [];
        const currentOrganization = this.storage.getItem('currentOrganization');
        if (currentOrganization.kndKinds && currentOrganization.kndKinds.length > 0) {
            const filterKndKinds = currentOrganization.kndKinds.filter((item) => {
                return (
                    item[entityTypeName] &&
                    item[entityTypeName].length > 0 &&
                    item[entityTypeName].find(
                        (elm) => elm.id === entityTypeId && elm.autoCalculateRiskCategoryOnChange && elm.bpmn
                    )
                );
            });
            if (filterKndKinds.length > 0) {
                kndKindIds = filterKndKinds.map((item) => item.id);
            }
        }
        return kndKindIds;
    }

    public startCalculateRiskCategory(kndKindIds, entity, entityTypeName) {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const calculateParams = [];
        const entityTypeId = entityTypeName === 'subjectTypes' ? entity.specialTypeId : entity.type.id;
        const collectionName = entityTypeName === 'subjectTypes' ? 'persons' : 'objects';
        const bpmnIds = [];

        currentOrganization.kndKinds.forEach((kndKind) => {
            if (kndKindIds.indexOf(kndKind.id) !== -1) {
                const entityData = kndKind[entityTypeName].find((item) => item.id === entityTypeId);

                if (entityData.bpmn) {
                    bpmnIds.push(entityData.bpmn);
                    calculateParams.push({
                        kndKindId: kndKind.id,
                        bpmn: entityData.bpmn,
                    });
                }
            }
        });

        const promises = [];

        return this.restService
            .search('sperBpmn', { search: { search: [{ field: '_id', operator: 'in', value: bpmnIds }] } })
            .then((bpmn) => {
                calculateParams.forEach((calculateParam) => {
                    const process = bpmn.find((item) => item._id === calculateParam.bpmn);

                    if (process && process.deploymentId) {
                        const dataForSend = {
                            mainId: entity._id,
                            guid: CommonUtilities.GenerateGuid(),
                            processDefinitionId: process.deploymentId,
                            entityName: collectionName,
                            unitId: currentOrganization._id,
                            kndKindId: calculateParam.kndKindId,
                        };

                        promises.push(
                            this.httpService.post(Config.server + Config.api + 'business/riskCalculations', dataForSend)
                        );
                    }
                });
                return Promise.resolve(promises);
            });
    }
}
