import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    RestService,
    ToasterService,
    TranslateService,
    UniversalCollectionPagePreviewComponentInterface,
} from '@evolenta/core';
import { ActivatedRoute } from '@angular/router';
import { DeleteConfirmationModalComponent } from '../../../../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.component';

@Component({
    selector: 'preview-process',
    templateUrl: './preview-process.component.html',
})
export class PreviewProcessComponent implements UniversalCollectionPagePreviewComponentInterface, OnInit {
    @Input() public element;
    @Output() public onDeleted = new EventEmitter<void>();
    public urlPart: string = '';
    private collection: string = '';

    public panels = {
        common: true,
        serviceTasks: false,
        userTasks: false,
        subProcess: false,
        catchEvents: false,
    };
    public localizations;

    public deleteConfirmationHeader;
    public deleteConfirmationText;
    @ViewChild('deleteConfirmationModal', { static: false })
    public deleteConfirmationModalComponent: DeleteConfirmationModalComponent;

    public constructor(
        private rest: RestService,
        private toaster: ToasterService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.parent.data.subscribe((parentData) => {
            this.collection = parentData.collection;
            this.urlPart =
                this.collection === 'sperBpmnProcesses'
                    ? 'process-registry'
                    : this.collection === 'sperBpmn'
                      ? 'risk-models'
                      : 'business-rules';
        });
    }

    private _loadTranslations() {
        this.translate.get(['common', 'process-registry']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public deleteProcess() {
        this.rest.remove(this.collection, this.element).then(
            (response) => {
                console.log(response);
                this.onDeleted.emit();
                this.toaster.success('Элемент успешно удален.');
            },
            (error) => {
                console.log('ошибка', error);
            }
        );
    }

    public showDeleteConfirmationModal() {
        this.deleteConfirmationHeader = 'Удаление сценария процесса';
        this.deleteConfirmationText = 'Вы действительно хотите удалить этот сценарий?';
        this.deleteConfirmationModalComponent.showModal();
    }
}
