<!-- Блок выбора жизненной ситуации -->
<div class="life-event-list p-20 bg-primary-50 mb-20"
     *ngIf="lifeEvents && isProcessSelectLifeEvent">
    <div class="no-margin-top text-bold pb-10">
        {{ localizations['anketa-life-event'].select_life_situation }}
    </div>
    <div class="radio-block"
         *ngFor="let lifeEvent of lifeEvents; let idx = index"
         (click)="selectLifeEvent(lifeEvent)">
        <input id="lifeEvnent{{idx}}"
               class="radio"
               type="radio"
               name="lifeEvent"
               value="{{idx}}">
        <label for="lifeEvnent{{idx}}">
            {{lifeEvent.title}}
        </label>
    </div>
</div>

<!-- Выбранная жизненная ситуация -->
<div class="panel panel-flat"
     *ngIf="!isProcessSelectLifeEvent && selectedLifeEvent">
    <div>
        <table class="table text-nowrap table-xxs">
            <tbody>
            <tr>
                <td class="no-border-top"
                    width="50">
                    <div class="btn bg-primary btn-rounded btn-xs btn-icon">
                        <i class="icon-users"></i>
                    </div>
                </td>
                <td class="no-border-top">
                    <span class="text-semibold">
                        {{selectedLifeEvent.title}}
                    </span>
                    <span class="text-size-small anchor text-info ml-5"
                          (click)="changeLifeEvent()">
                        {{ localizations.common.change_small }}
                    </span>
                    <div class="text-muted text-size-small">
                        {{ localizations['anketa-life-event'].life_situation }}
                    </div>
                    <div class="mt-5"
                         *ngIf="viewType == 'short' && !isProcessingAnketa">
                        <a (click)="showQuestsAndAnswers()"
                           [title]="localizations['anketa-life-event'].questions_and_answers">
                            <i class="icon-bubbles5"></i>
                            {{ localizations['anketa-life-event'].questions_and_answers }}
                        </a>
                    </div>
                </td>
                <td class="no-border-top"
                    *ngIf="!isProcessingAnketa && viewType == 'full'">

                </td>
                <td class="no-border-top"
                    *ngIf="!isProcessingAnketa && viewType == 'full'">
                    <a (click)="showQuestsAndAnswers()"
                       [title]="localizations['anketa-life-event'].questions_and_answers">
                        <i class="icon-bubbles5"></i>
                        {{ localizations['anketa-life-event'].questions_and_answers }}
                    </a>
                </td>
                <td class="no-border-top text-right text-muted">
                    <span class="status-mark border-blue position-left"
                          [ngClass]="{
                            'border-blue': isProcessingAnketa,
                            'border-green': !isProcessingAnketa
                            }">
                    </span>
                    <span *ngIf="isProcessingAnketa">
                        {{ localizations['anketa-life-event'].poll.active }}
                    </span>
                    <span *ngIf="!isProcessingAnketa">
                        {{ localizations['anketa-life-event'].poll.inactive }}
                    </span>
                    <div class="mt-5"
                         *ngIf="viewType == 'short' && !isProcessingAnketa">
                        <span class="label label-success">
                            {{ localizations['anketa-life-event'].of_services }}
                            1
                        </span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</div>

<!-- Список ответов -->
<div class="panel-group panel-group-control panel-group-control-right"
     *ngIf="currentQuestion">
    <div class="panel bg-primary-50">
        <div class="panel-heading">
            <h6 class="panel-title text-default">
                <a data-toggle="collapse"
                   href="#answers"
                   aria-expanded="true">
                    <i class="icon-help position-left text-slate text-semibold"></i>
                    {{currentQuestion.title}}
                </a>
            </h6>
        </div>
        <div id="answers"
             class="panel-collapse collapse in"
             aria-expanded="true">
            <div class="panel-body pt-10 pb-10">
                <div class="answers">
                    <div class="radio-block"
                         *ngFor="let answer of currentQuestion.answers; let idx = index">
                        <input id="answers{{idx}}"
                               class="radio"
                               type="radio"
                               name="answer"
                               (click)="setActiveAnswer(answer, idx)"
                               [checked]="currentQuestion.results && currentQuestion.results.answerIdx && currentQuestion.results.answerIdx === idx"
                               value="{{idx}}">
                        <label for="answers{{idx}}"
                               (click)="setActiveAnswer(answer, idx)">
                            {{answer.title}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Список вопросов -->
<div class="panel-group panel-group-control panel-group-control-right"
     *ngIf="questionPath && questionPath.length > 0 && isShowQuestsAndAnswers">
    <div class="panel panel-white"
         *ngFor="let question of questionPath; let idx = index; let lst = last"
         [ngClass]="{'no-margin-bottom': lst}">
        <div class="panel-heading">
            <h6 class="panel-title">
                <a data-toggle="collapse"
                   href="#question{{idx}}"
                   aria-expanded="false">
                    <i class="icon-help position-left text-slate"></i>
                    {{question.title}}
                </a>
            </h6>
        </div>
        <div id="question{{idx}}"
             class="panel-collapse collapse in"
             aria-expanded="false">
            <div class="panel-body pt-10 pb-10">
                {{question.results.activeAnswer.title}}
                <span class="anchor text-info text-size-small"
                      (click)="backToPreviousQuestion(idx)">
                    {{ localizations.common.change_small }}
                </span>
            </div>
        </div>
    </div>
</div>

<!-- Список отфильтрованных услуг -->
<div class="panel panel-default border-left-lg no-padding-bottom border-left-primary-600"
     *ngIf="isShowSubservices && subservice">
    <div class="panel-heading">
        <h6 class="panel-title">
            {{ localizations['anketa-life-event'].life_situation_services }}
        </h6>
    </div>
    <div class="panel-body no-padding">
        <ul class="list-unstyled pt-10">
            <div class="pl-20 pb-10">
                {{subservice.title}}
            </div>
        </ul>
    </div>
</div>
