<ng-template #recursiveVariants
             let-list="list"
             let-level="level">
    <ng-container *ngFor="let item of list">
        <div class="tree-line"
             [ngStyle]="{'padding-left': (level * 30 + 10) + 'px'}">
            <div class="tree-item-toggle-sign">
                <span class="cursor-pointer"
                      *ngIf="item.children && item.children.length > 0"
                      (click)="item.showChildren = !item.showChildren">
                    <i class="icon-plus2"
                       *ngIf="!item.showChildren">
                    </i>
                    <i class="icon-minus2"
                       *ngIf="item.showChildren">
                    </i>
                </span>
            </div>
            <div class="tree-checkbox">
                <input id="variant{{item.guid}}"
                       type="checkbox"
                       class="checkbox"
                       [checked]="isUseVarant(item)"
                       (change)="toggleVariant(item)">
                <label for="variant{{item.guid}}">&nbsp;</label>
            </div>
            <div class="tree-item-title">
                <label class="no-margin cursor-pointer"
                       for="variant{{item.guid}}">
                    {{item.name}}
                </label>
            </div>
        </div>
        <ng-container *ngIf="item.children && item.children.length > 0 && item.showChildren"><ng-container *ngTemplateOutlet="recursiveVariants; context:{ list: item.children, level: (level + 1)}">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>
<ng-container *ngTemplateOutlet="recursiveVariants; context:{ list: variants, level: 0}">
</ng-container>
