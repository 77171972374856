import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonProcessService } from '../../../common-process.service';
import { RestService, StorageService, ToasterService, UsersService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'entity-process-element-footer',
    templateUrl: './entity-process-element-footer.component.html',
})
export class EntityProcessElementFooterComponent implements OnInit {
    @Input() public element; // элемент процесса: задача, событие, таймер
    @Input() public data;
    @Input() public allowEdit = false;

    public allowEditParams = false;

    public usedUsers = [];
    public executor;

    public modalRef: BsModalRef;
    @ViewChild('setupPropertyModal', { static: false }) public setupPropertyModal: TemplateRef<any>;
    public processingData = {
        property: null,
        executor: null,
        date: null,
    };
    public modalTitle;

    public knoUsers;

    public constructor(
        private storage: StorageService,
        private usersService: UsersService,
        private modalService: BsModalService,
        private processService: CommonProcessService,
        private restService: RestService,
        private toaster: ToasterService
    ) {}

    public ngOnInit() {
        if (this.allowEdit) {
            const user = this.storage.getItem('user');
            const userRoles = user.currentRolesMnemonics;
            if (userRoles.indexOf('supervisor') !== -1) {
                this.allowEditParams = true;
            }
        }

        this.usersService.getKnoUsers(false, false).then((knoUsers) => {
            this.knoUsers = knoUsers;
            this.getExecutor();
            this.getUsedUsers();
        });
    }

    public get isActive() {
        return this.element.status === 'ACTIVE';
    }

    public getExecutor() {
        if (this.element.user) {
            this.executor = this.element.user;
        } else if (this.isActive) {
            if (this.element.assignee) {
                this.usersService.getKnoUsers(false, false).then((knoUsers) => {
                    this.executor = knoUsers.find((item) => item.login === this.element.assignee);
                });
            } else if (this.data.entity.executor) {
                this.executor = this.data.entity.executor;
            }
        }
    }

    public getUsedUsers() {
        this.usedUsers = [];
        if (this.element.candidateGroups && this.element.candidateGroups.length > 0) {
            this.usersService.getKnoUsers(false, false).then((knoUsers) => {
                knoUsers.forEach((user) => {
                    const find = user.linkRolesMnemonics.filter(
                        (item) => this.element.candidateGroups.indexOf(item) !== -1
                    );

                    if (find.length > 0) {
                        this.usedUsers.push(user);
                    }
                });
            });
        } else {
            this.usersService.getKnoUsers(false, false).then((knoUsers) => {
                this.usedUsers = cloneDeep(knoUsers);
            });
        }
    }

    public changeProperty(property) {
        if (this.allowEdit && this.allowEditParams) {
            this.modalTitle =
                property === 'assignee'
                    ? 'Выберите ответственного'
                    : property.due
                      ? 'Дата завершения'
                      : 'Дата начала выполнения';
            this.processingData.property = property;
            if (property !== 'assignee' && this.element[property]) {
                this.processingData = this.element[property];
            } else if (property === 'assignee') {
                this.processingData.executor = this.executor ? this.executor.login : null;
            }
            this.modalRef = this.modalService.show(this.setupPropertyModal, { backdrop: 'static' });
        }
    }

    public apply() {
        if (
            this.processingData.property === 'assignee' &&
            this.processingData.executor &&
            this.processingData.executor !== this.element.assignee
        ) {
            this.saveExecutor(this.processingData.executor).then(() => {
                this.modalRef.hide();
            });
        } else if (this.processingData.date) {
            this.processService.getProcessInfo(this.data.processInfoId).then((processInfo: any) => {
                const userTasks = processInfo.userTasks;
                this.element[this.processingData.property] = this.processingData.date;
                const findIndex = userTasks.findIndex((item) => item.id === this.element.id);
                if (findIndex !== -1) {
                    const currentTask = cloneDeep(this.element);
                    delete currentTask.camundaBusinessInfoId;
                    userTasks[findIndex] = currentTask;
                    const dataForSave = {
                        _id: this.data.processInfoId,
                        parentEntries: 'camundaBusinessInfo',
                        guid: processInfo.guid,
                        userTasks: userTasks,
                    };
                    this.restService.update('camundaBusinessInfo', dataForSave).then(() => {
                        this.modalRef.hide();
                        this.toaster.success('Дата успешно изменена');
                    });
                }
            });
        }
    }

    public saveExecutor(executor) {
        return this.processService.setProcessTaskExecutor(this.element.id, executor).then(() => {
            this.element.assignee = this.processingData.executor;
            this.getExecutor();
            this.toaster.success('Исполнитель успешно изменен');
            return Promise.resolve(true);
        });
    }
}
