import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard, UniversalCollectionPageComponent } from '@evolenta/core';
import { EmptyRoutingComponent } from '@components/empty-routing.component';
import { SectionComponent } from '@components/section/section.component';
import { Permission } from '../../common/services/permission';
import { AisObjectSectionComponent } from '../../modules/objects/objects/ais-object-section.component';
import { ObjectsData } from '../../modules/objects/objects.data';
import { NsiResolve } from '../../modules/dictionaries/nsi/nsi.resolve';
import { EnvelopesResolve } from '../../modules/envelopes/envelopes/envelopes.resolve';
import { EnvelopeEditComponent } from '../../modules/envelopes/envelopes/envelope-edit.component';
import { OrganizationsResolve } from '../../modules/envelopes/envelopes/organizations.resolve';
import { NsiDictionaryComponent } from '../../modules/dictionaries/nsi/dictionary/nsi-dictionary.component';
import { ServicesComponent } from '../../modules/subservices/services/services.component';
import { ServicesResolve } from '../../modules/subservices/services/services.resolve';
import { OrganizationComponent } from '../../modules/organizations/organization/organizations.component';
import { OrganizationResolve } from '../../modules/organizations/organization/organization.resolve';
import { PrintFormsResolve } from '../../modules/print-forms/print-form/print-forms.resolve';
import { ObjectTypesComponent } from '../../modules/objects/object-types/object-types.component';
import { ObjectTypesResolve } from '../../modules/objects/object-types/object-types.resolve';
import { SubjectsRoutingData } from '../../modules/subjects/subjects-routing.data';
import { RequestsResolve } from '@evolenta/requests-module';
import { RequestsListConfig } from './option/requests-list.config';
import { PrintFormsListConfig } from './option/print-forms-list.config';
import { EnvelopeGuard } from '../../common/guards/envelope.guard';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'selecting',
        component: SidebarComponent,
        data: {
            module: 'selecting',
            breadcrumb: 'Выбор данных',
        },
        children: [
            // ----------------- Субъекты --------------- //
            {
                path: 'subjects',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Субъекты',
                    img: 'main-menu/subjects',
                    breadcrumb: 'Субъекты',
                },
                children: [
                    {
                        path: 'kno',
                        component: EmptyRoutingComponent,
                        data: {
                            breadcrumb: 'Субъекты КНО',
                        },
                        children: SubjectsRoutingData.childRoutingWithEdit,

                    },
                    {
                        path: '',
                        redirectTo: 'kno',
                        pathMatch: 'full',
                    },
                ],
            },

            // ----------------- Объекты --------------- //
            {
                path: 'objects',
                component: AisObjectSectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Объекты',
                    img: 'main-menu/objects',
                    permissions: [Permission.Persons_Search],
                    breadcrumb: 'Объекты',
                },
                children: [
                    {
                        path: 'kno',
                        component: EmptyRoutingComponent,
                        data: {
                            breadcrumb: 'Объекты КНО',
                        },
                        children: ObjectsData.childRoutingWithEdit,
                    },
                    {
                        path: '',
                        redirectTo: 'kno',
                        pathMatch: 'full',
                    },
                ],
            },

            // ----------------- Справочники --------------- //
            {
                path: 'dictionary',
                component: AisObjectSectionComponent,
                data: {
                    module: 'dictionary',
                    img: 'main-menu/other',
                    breadcrumb: 'Справочники',
                },
                resolve: {
                    resolves: NsiResolve,
                },
                children: [
                    {
                        path: ':groupCode/:dictionary',
                        component: NsiDictionaryComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Справочник',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                ],
            },

            // ----------------- Запросы --------------- //
            {
                path: 'envelopes',
                component: SectionComponent,
                canActivateChild: [ AuthGuard, ModuleGuard ],
                data: {
                    name: 'Запросы',
                    breadcrumb: 'Запросы',
                    img: 'main-menu/requests',
                },
                children: [
                    {
                        path: 'edit/:envelopeId',
                        component: EnvelopeEditComponent,
                        canActivate: [EnvelopeGuard],
                        data: {
                            name: 'Редактирование запроса',
                            breadcrumb: 'Редактирование запроса',
                        },
                        resolve: {
                            organizations: OrganizationsResolve,
                            resolves: EnvelopesResolve,
                        },
                    },
                ],
            },

            // ----------------- Услуги --------------- //
            {
                path: 'services',
                component: SectionComponent,
                data: {
                    name: 'Виды КНМ',
                    img: 'main-menu/processes',
                    breadcrumb: 'Виды КНМ',
                },
                children: [
                    {
                        path: '',
                        component: ServicesComponent,
                        data: {
                            name: 'Виды КНМ',
                        },
                        resolve: {
                            resolves: ServicesResolve,
                        },
                    },
                ],
            },

            // ----------------- Организации --------------- //
            {
                path: 'organizations',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Организации',
                    img: 'main-menu/organizations',
                    breadcrumb: 'Организации',
                },
                children: [
                    {
                        path: '',
                        component: OrganizationComponent,
                        data: {
                            name: 'Список организаций',
                        },
                        resolve: {
                            resolves: OrganizationResolve,
                        },
                    },
                ],
            },

            // ----------------- Запросы --------------- //
            {
                path: 'requests',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Запросы',
                    img: 'main-menu/requests',
                    breadcrumb: 'Запросы',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            options: RequestsListConfig,
                        },
                        resolve: {
                            resolves: RequestsResolve,
                        },
                    },
                ],
            },

            // ----------------- Печатные формы --------------- //
            {
                path: 'print-forms',
                component: SectionComponent,
                canActivateChild: [AuthGuard],
                data: {
                    name: 'Печатные формы',
                    img: 'main-menu/printForms',
                    breadcrumb: 'Печатные формы',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Список печатных форм',
                            options: PrintFormsListConfig,
                        },
                        resolve: {
                            resolves: PrintFormsResolve,
                        },
                    },
                ],
            },

            // ----------------- Виды объектов --------------- //
            {
                path: 'object-types',
                component: SectionComponent,
                data: {
                    name: 'Виды объектов',
                    img: 'main-menu/objects',
                    breadcrumb: 'Виды объектов',
                },
                children: [
                    {
                        path: '',
                        component: ObjectTypesComponent,
                        data: {
                            name: 'Список видов объектов',
                        },
                        resolve: {
                            resolves: ObjectTypesResolve,
                        },
                    },
                ],
            },

            // ----------------- Глобальные субъекты --------------- //
            {
                path: 'global-subjects',
                component: SectionComponent,
                canActivateChild: [ AuthGuard, ModuleGuard ],
                data: {
                    name: 'Субъекты',
                    img: 'main-menu/subjects',
                    breadcrumb: 'Субъекты',
                },
                children: [
                    {
                        path: 'global',
                        component: EmptyRoutingComponent,
                        data: {
                            name: 'Глобальные субъекты',
                        },
                        children: SubjectsRoutingData.childRoutingWithEdit,
                    },
                    {
                        path: '',
                        redirectTo: 'global',
                        pathMatch: 'full',
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class SelectingRouting {
}
