import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard, UniversalCollectionPageComponent } from '@evolenta/core';
import { SectionComponent } from '@components/section/section.component';
import { PrintFormEditComponent } from '../../modules/print-forms/print-form/print-form-edit/print-form-edit.component';
import { PrintFormsResolve } from '../../modules/print-forms/print-form/print-forms.resolve';
import { ProcessRegistryResolve } from '../../modules/processes/process-registry/process-registry.resolve';
import { BpmnProcessEditComponent } from '../../modules/processes/process-registry/components/bpmn-process-edit/bpmn-process-edit.component';
import { CommonStandardsResolve } from '../../modules/common-standards/common-standards.resolve';
import { CommonStandardEditComponent } from '../../modules/common-standards/common-standard-edit.component';
import { CommonStandardsListConfig } from '../../modules/common-standards/common-standards-list.config';
import { RequestsListConfig } from './option/requests-list.config';
import { RequestEditComponent, RequestsResolve } from '@evolenta/requests-module';
import { NumberTemplatesListOptions } from './option/number-templates-list.options';
import { NumberTemplateEditComponent, NumberTemplatesResolve } from '@evolenta/number-templates-module';
import { ProcessScenariosListConfig } from '../../modules/processes/process-registry/process-scenarios-list.config';
import { EpguIntegrationSectionComponent } from '../../modules/epgu-integration/epgu-integration-section.component';
import { EpguIntegrationComponent } from '../../modules/epgu-integration/components/epgu-integration/epgu-integration.component';
import { FieldsComplienceComponent } from '../../modules/epgu-integration/components/fields-complience/fields-complience.component';
import { PrintFormsListConfig } from './option/print-forms-list.config';
import { SmevConfigurationSectionComponent } from '../../modules/smev-configuration/smev-configuration-section.component';
import {
    SmevConfigurationListComponent,
} from '../../modules/smev-configuration/components/smev-configuration-list/smev-configuration-list.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'metodolog',
        component: SidebarComponent,
        data: {
            module: 'metodolog',
            breadcrumb: 'Кабинет методолога',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            // ----------------- Стандарты --------------- //
            {
                path: 'standards',
                component: SectionComponent,
                data: {
                    name: 'Стандарты',
                    icon: 'icon-grid5',
                    img: 'main-menu/checkliststTemplates',
                    breadcrumb: 'Список стандартов',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Список стандартов',
                            options: CommonStandardsListConfig,
                            breadcrumb: 'Список стандартов',
                        },
                        resolve: {
                            resolves: CommonStandardsResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: CommonStandardEditComponent,
                        data: {
                            breadcrumb: 'Создание стандарта',
                        },
                        resolve: {
                            resolves: CommonStandardsResolve,
                        },
                    },
                    {
                        path: 'edit/:id',
                        component: CommonStandardEditComponent,
                        data: {
                            breadcrumb: 'Редактирование стандарта',
                        },
                        resolve: {
                            resolves: CommonStandardsResolve,
                        },
                    },
                    {
                        path: 'edit/:id/:reglamentId',
                        component: CommonStandardEditComponent,
                        resolve: {
                            response: CommonStandardsResolve,
                        },
                    },
                ],
            },

            // ----------------- Сценарии процессов (BPMN КНМ) --------------------- //
            {
                path: 'process-registry',
                component: SectionComponent,
                data: {
                    name: 'Сценарии процессов',
                    img: 'main-menu/processScript',
                    collection: 'sperBpmnProcesses',
                    breadcrumb: 'Сценарии процессов',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            options: ProcessScenariosListConfig,
                        },
                        resolve: {
                            resolves: ProcessRegistryResolve,
                        },
                    },
                    {
                        path: 'edit/:processId',
                        component: BpmnProcessEditComponent,
                        data: {
                            breadcrumb: 'Редактирование процесса',
                        },
                        resolve: { process: ProcessRegistryResolve },
                    },
                    {
                        path: 'create',
                        data: {
                            breadcrumb: 'Создание процесса',
                        },
                        component: BpmnProcessEditComponent,
                        resolve: { process: ProcessRegistryResolve },
                    },
                ],
            },

            // ----------------- Печатные формы --------------- //
            {
                path: 'print-forms',
                component: SectionComponent,
                data: {
                    icon: 'icon-files-empty2',
                    img: 'main-menu/printForms',
                    name: 'Печатные формы',
                    breadcrumb: 'Печатные формы',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Печатные формы',
                            options: {
                                ...PrintFormsListConfig,
                                filterName: 'metodologPrintFormsFilter',
                            },
                        },
                        resolve: {
                            resolves: PrintFormsResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: PrintFormEditComponent,
                        data: {
                            breadcrumb: 'Создание печатной формы',
                        },
                        resolve: {
                            resolves: PrintFormsResolve,
                        },
                    },
                    {
                        path: 'edit/:printFormId',
                        component: PrintFormEditComponent,
                        data: {
                            breadcrumb: 'Редактирование печатной формы',
                        },
                        resolve: {
                            resolves: PrintFormsResolve,
                        },
                    },
                ],
            },
            // ------------------- Запросы -----------------------//
            {
                path: 'requests',
                component: SectionComponent,
                data: {
                    name: 'Запросы',
                    breadcrumb: 'Запросы',
                    img: 'main-menu/requests',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            options: RequestsListConfig,
                            breadcrumb: 'Запросы',
                        },
                        resolve: {
                            resolves: RequestsResolve,
                        },
                    },
                    {
                        path: 'edit/:requestId',
                        component: RequestEditComponent,
                        data: {
                            name: 'Редактирование запроса',
                            breadcrumb: 'Редактирование запроса',
                        },
                        resolve: {
                            resolves: RequestsResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: RequestEditComponent,
                        data: {
                            name: 'Создание запроса',
                            breadcrumb: 'Создание запроса',
                        },
                        resolve: {
                            resolves: RequestsResolve,
                        },
                    },
                ],
            },

            // ----------------- Шаблоны номеров --------------- //
            {
                path: 'number-templates',
                component: SectionComponent,
                data: {
                    name: 'Шаблоны номеров',
                    breadcrumb: 'Шаблоны номеров',
                    icon: 'icon-files-empty2',
                    img: 'main-menu/numberTemplate',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            options: NumberTemplatesListOptions,
                        },
                        resolve: {
                            resolves: NumberTemplatesResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: NumberTemplateEditComponent,
                        data: {
                            name: 'Создание шаблона номера',
                            breadcrumb: 'Создание шаблона номера',
                        },
                        resolve: {
                            resolves: NumberTemplatesResolve,
                        },
                    },
                    {
                        path: 'edit/:templateId',
                        component: NumberTemplateEditComponent,
                        data: {
                            name: 'Редактирование шаблона номера',
                            breadcrumb: 'Редактирование шаблона номера',
                        },
                        resolve: {
                            resolves: NumberTemplatesResolve,
                        },
                    },
                ],
            },
            // ------------------- Интеграция с ЕПГУ -----------------------//
            {
                path: 'epgu-integration',
                component: EpguIntegrationSectionComponent,
                data: {
                    icon: 'icon-file-xml',
                    name: 'Интеграция с ЕПГУ',
                },
                children: [
                    {
                        path: '',
                        component: EpguIntegrationComponent,
                        children: [
                            {
                                path: '',
                                component: FieldsComplienceComponent,
                            },
                        ],
                    },
                ],
            },
            // ------------------- Настройка СМЭВ-адаптеров -----------------------//
            {
                path: 'smev-configuration',
                component: SmevConfigurationSectionComponent,
                data: {
                    icon: 'icon-folder-upload',
                    name: 'Настройка СМЭВ-адаптеров',
                },
                children: [
                    {
                        path: '',
                        component: SmevConfigurationListComponent,
                    },
                ],
            },
            {
                path: '',
                data: {},
                redirectTo: 'standards',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class MetodologRouting {
}
