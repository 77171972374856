import { Injectable } from '@angular/core';
import { RestService, StorageService } from '@evolenta/core';
import { CommonProcessService } from '../../modules/common-processes/common-process.service';
import { CommonUtilities } from '@evolenta/utilities';
import { PrintFormsService } from './print/print-forms.service';
import { PrintingService } from '@evolenta/printing';
import { QuickRequestsService } from './quick-requests.service';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable()
export class InternalHandlersService {

    public constructor(
        private restService: RestService,
        private processService: CommonProcessService,
        private storageService: StorageService,
        private printFormService: PrintFormsService,
        private printingService: PrintingService,
        private quickRequestsService: QuickRequestsService,
    ) {
    }

    public processingHandlers(handlers, type, eventType, externalData) {
        const promises = [];
        if (handlers && handlers.length > 0) {
            handlers.forEach(handler => {
                if (handler.event === eventType) {
                    promises.push(this.processingHandler(handler, type, externalData));
                }
            });
        }

        return Promise.all(promises);
    }

    public processingHandler(handler, type, externalData) {
        if (handler && handler.useInternalHandler && handler.internalHandler) {
            if (handler.internalHandler.event === type) {
                if (typeof this[handler.internalHandler.name] === 'function') {
                    if (type === 'startBefore') {
                        // запуск статической функции до ее выполнения, и передача данных выполнения функции в обработчик
                        return this[handler.internalHandler.name](externalData).then(data => {
                            externalData.internalHandlerData = data;

                            return Promise.resolve(data);
                        });
                    } else {
                        // Запуск функции после выполнения скрипта
                        return this[handler.internalHandler.name](externalData).then(data => {
                            return Promise.resolve(data);
                        });
                    }
                } else {
                    return Promise.resolve({data: {}});
                }
            } else {
                return Promise.resolve({data: {}});
            }
        } else {
            return Promise.resolve({data: {}});
        }
    }

    public setCamundaVariables(data) {
        if (data && data.appeal && data.appeal.dataForExecuteAction && Object.keys(data.appeal.dataForExecuteAction).length > 0) {
            const processId = data.appeal.subservice.camundaProcessInfo.id;

            return this.processService.setCamundaVariables(processId, data.appeal.dataForExecuteAction);
        } else {
            return Promise.resolve(true);
        }
    }

    public async createLicense(data) {
        console.log('RUN FUNC: createLicense', data);
        if (!(data && data.appeal)) {
            return true;
        }

        let licensesCollection = 'licenses';
        if (data.metaReglament) {
            const registersModel = data.metaReglament.blocks.find(item => item.code === 'registersModel');
            if (registersModel.licensesCollection) {
                licensesCollection = registersModel.licensesCollection;
            }
        }

        const orderData = data.appeal.subservice.xsdData.order;
        if (orderData.licenseId) {
            return true;
        }

        const license = {
            status: 'draft',
            number: orderData.licenseNumber ? orderData.licenseNumber : '',
            dateIssued: orderData.licenseDateIssued,
            unit: data.appeal.unit,
            units: [data.appeal.unit],
            historyData: data.appeal.historyData ? data.appeal.historyData : [],
        };

        const createdLicense: any = await this.restService.create(licensesCollection, license);

        orderData.licenseId = createdLicense._id;

        return true;
    }

    public findLicenseAndPrepareReceipt(data) {
        const unit = this.storageService.getItem('currentOrganization');
        const appeal = data.appeal;
        const mainElement = appeal.subservice.mainElement;
        const xsdDataBlock = mainElement.xsdData ? mainElement.xsdData : mainElement.mainXsdData;
        if (xsdDataBlock && xsdDataBlock.dataForReceiveReceipt) {
            const xsdData = xsdDataBlock.dataForReceiveReceipt;
            const params: any = [
                {
                    orSubConditions: [
                        {
                            field: 'unit.id',
                            operator: 'eq',
                            value: unit._id,
                        },
                        {
                            field: 'units.id',
                            operator: 'eq',
                            value: unit._id,
                        },
                    ],
                },
                {
                    field: 'registryEntryType.code',
                    operator: 'eq',
                    value: mainElement.registryEntryType.code,
                },
            ];
            if (xsdData.licenseNumber) {
                params.push({
                    field: 'number',
                    operator: 'like',
                    value: xsdData.licenseNumber.trim(),
                });
            } else if (xsdData.subjectData && xsdData.subjectData.param) {
                const field = xsdData.subjectData.param.inn ? 'inn' : 'ogrn';
                const fieldValue = xsdData.subjectData.param.inn ? xsdData.subjectData.param.inn : xsdData.subjectData.param.ogrn;
                const orgFieldName = 'subject.data.organization.' + field;
                const personFieldName = 'subject.data.person.' + field;
                params.push(
                    {
                        orSubConditions: [
                            {
                                field: orgFieldName,
                                operator: 'eq',
                                value: fieldValue.trim(),
                            },
                            {
                                field: personFieldName,
                                operator: 'eq',
                                value: fieldValue.trim(),
                            },
                        ],
                    },
                );
            }

            return this.findLicense(params).then(licenses => {
                if (!appeal.subservice.xsdData) {
                    appeal.subservice.xsdData = {};
                }
                if (licenses && licenses.length > 0) {
                    if (licenses.length === 1) {
                        return this.prepareLicenseData(data, licenses[0]);
                    } else {
                        const resultData = {
                            data: {
                                license: null,
                                currentRegister: null,
                                licenses: licenses,
                            },
                            itemsForSelect: licenses,
                            paramsForSelect: {
                                itemCode: '_id',
                                itemNameRule: 's#№|f#number|s# от |f#dateIssued#date',
                                functionAfterSelect: 'prepareLicenseData',
                            },
                        };

                        return Promise.resolve(resultData);
                    }
                } else {
                    // Лицензия не найдена
                    appeal.subservice.xsdData.processingData = {
                        noLicense: true,
                        licenseInfo: {},
                        licenseInfoChoice: 'noLicense',
                    };
                    appeal.subservice.mainElement.license = null;
                    appeal.subservice.mainElement.currentlicense = null;
                    appeal.subservice.mainElement.noLicense = true;
                    const resultData = {
                        data: {
                            license: null,
                            currentRegister: null,
                            noLicense: true,
                        },
                        itemsForSelect: licenses,
                        itemCode: '_id',
                        itemNameRule: 's#№|f#number|s# от |f#dateIssued#date',
                        functionAfterSelect: 'prepareLicenseData',
                    };

                    return Promise.resolve(resultData);
                }
            });
        } else {
            return Promise.reject('Ошибка данных');
        }
    }

    public prepareLicenseData(data, license) {
        const appeal = data.appeal;
        const metaReglament = data.metaReglament;
        const mainElement = appeal.subservice.mainElement;
        mainElement.existLicense = true;
        mainElement.licenseId = license._id;
        let statuses = [];
        if (metaReglament) {
            const findRegistersModel = metaReglament.blocks.find(item => item.code === 'registersModel');
            statuses = findRegistersModel.licenseStatuses;
        }

        mainElement.license = {
            number: license.number,
            date: license.dateIssued,
            status: license.status,
            statusName: statuses.find(item => item.code === license.status).name,
            orderData: license.orderData ? license.orderData : null,
            registryOrderData: null,
        };
        const resultType = mainElement.xsdData.receiptType;
        let resultChoice;
        if (resultType === 'receipt') {
            resultChoice = {
                receipt: {},
            };
        } else {
            resultChoice = {
                order: {
                    noOrder: false,
                },
            };
        }
        appeal.subservice.xsdData.processingData = {
            noLicense: false,
            licenseInfo: {
                existLicense: {
                    number: license.number,
                    dateIssued: license.dateIssued,
                    resultType: resultChoice,
                    resultTypeChoice: resultType,
                },
            },
            licenseInfoChoice: 'existLicense',
        };
        const registersParams = [
            {
                field: 'licenseId',
                operator: 'eq',
                value: license._id,
            },
        ];

        return this.findLicenseRegistry(registersParams).then(registers => {
            if (registers && registers.length > 0) {
                const currentRegister = registers[0];
                if (currentRegister.orderData) {
                    mainElement.license.registryOrderData = {
                        date: currentRegister.orderData.date,
                        number: currentRegister.orderData.number,
                        dateFrom: currentRegister.orderData.dateFrom ? currentRegister.orderData.dateFrom : '',
                    };
                }
                if (currentRegister.subject) {
                    appeal.subjects = [cloneDeep(currentRegister.subject)];
                    mainElement.subject = cloneDeep(currentRegister.subject);
                }
                if (currentRegister.objects) {
                    appeal.objects = cloneDeep(currentRegister.objects);
                    mainElement.objects = cloneDeep(currentRegister.objects);
                }
                mainElement.currentRegisterId = currentRegister._id;

                const resultData = {
                    data: {
                        license: license,
                        currentRegister: currentRegister,
                    },
                };
                // Если нужна копия приказа, то забираем из реестровой записи
                if (mainElement.xsdData && mainElement.xsdData.receiptType) {
                    const receiptType = mainElement.xsdData.receiptType;
                    if (receiptType === 'receipt') {
                        // Формирование печатной формы выписки
                        data.mnemonic = 'receipt';

                        return this.generatePrintFormByDocumentMnemonic(data).then(() => {
                            return resultData;
                        });
                    } else {
                        // Перенос документов приказа в соответствующую группу дела
                        data.register = currentRegister;
                        appeal.subservice.xsdData.processingData.licenseInfo.existLicense.resultType.order.noOrder = !this.transferRegistryOrderDocumentsToAppeal(data);

                        return Promise.resolve(resultData);
                    }
                } else {
                    return Promise.resolve(resultData);
                }
            } else {
                return Promise.resolve({
                    data: {
                        license: license,
                        currentRegister: null,
                        noLicense: false,
                    },
                });
            }
        });
    }

    public async generatePrintFormByDocumentMnemonic(data) {
        if (data.mnemonic && data.printForms) {
            const subservice = data.subservice;
            const appeal = data.appeal;
            const group = subservice.documentGroups.find(item => item.mnemonic && item.mnemonic === data.mnemonic);
            if (group) {
                const doc = group.docs[0];
                const printForm = data.printForms.find(item => item.docs && item.docs.find(elm => elm.docId === doc.code && elm.docGroupId === group.code));
                if (printForm) {
                    this.createDocumentInAppeal(group, doc, appeal, subservice, data.documentService, []);
                    const document = appeal.documents[appeal.documents.length - 1];
                    const baseData = await this.printFormService.prepareAppealData(appeal, subservice, document, printForm);

                    return this.printingService.renderPrintForm(
                        printForm._id,
                        appeal._id,
                        appeal.parentEntries,
                        baseData,
                        null,
                        null,
                        appeal.unit.id,
                    ).then(
                        (response: any) => {
                            const findInFiles = document.files.find(item => item._id && item._id === response.file._id);
                            if (!findInFiles) {
                                document.files.push(response.file);
                            }

                            return Promise.resolve(true);
                        },
                        () => {
                            return Promise.resolve(true);
                        },
                    );
                } else {
                    return Promise.resolve(true);
                }
            } else {
                return Promise.resolve(true);
            }
        } else {
            return Promise.resolve(false);
        }
    }

    public transferRegistryOrderDocumentsToAppeal(data) {
        const register = data.register;
        const registryOrder = register.orderData;
        if (registryOrder && registryOrder.documents && registryOrder.documents.length > 0) {
            const documentsWithFiles = registryOrder.documents.filter(item => {
                return item.files && item.files.length > 0;
            });
            if (documentsWithFiles.length > 0) {
                let files = [];
                documentsWithFiles.forEach(document => {
                    files = files.concat(document.files);
                });
                const group = data.subservice.documentGroups.find(item => {
                    return item.mnemonic && item.mnemonic === 'order';
                });
                if (group) {
                    this.createDocumentInAppeal(group, group.docs[0], data.appeal, data.subservice, data.documentService, files);

                    return true;
                }
            }
        }

        return false;
    }

    public createDocumentInAppeal(group, doc, appeal, subservice, documentService, files = []) {
        const documentGuid = CommonUtilities.GenerateGuid();
        const document: any = {
            guid : documentGuid,
            groupName: group.name,
            groupGuid: group.guid,
            subjects: [],
            parentEntries: appeal.parentEntries + '.documents',
            printForm: null,
            subserviceGuid: appeal.subservice.guid,
            afterRegister: true,
            files: files,
        };
        if (group.code) {
            document.groupCode = group.code;
        }
        if (documentService) {
            documentService.initDocumentDataFromAppeal(document);
            const documentData = documentService.data[documentGuid];
            documentService.selectDocumentKind(document, documentData, group.docs[0]);
        }
        appeal.documents.push(document);
    }

    public findLicenseRegistry(params) {
        return this.restService.search('licensingActivityRegistry', {search: {search: params}, sort: 'dateCreation,DESC', size: 1});
    }

    public findLicense(params) {
        return this.restService.search('licenses', {search: {search: params}, sort: 'dateCreation,DESC', size: 100});
    }

    public searchActualRegisterData(data) {
        console.log('RUN FUNC: searchActualRegisterData');
        const mainElement = data.appeal.subservice.mainElement;
        if (mainElement.licenseId && mainElement.prevRegisterId) {
            const params = {
                search: {
                    search: [
                        {
                            field: 'licenseId',
                            operator: 'eq',
                            value: mainElement.licenseId,
                        },
                        {
                            field: 'status',
                            operator: 'eq',
                            value: 'active',
                        },
                    ],
                },
                sort: 'dateCreation,DESC',
                size: 1,
            };

            return this.restService.search('licensingActivityRegistry', params).then(registers => {
                if (Array.isArray(registers) && registers[0]) {
                    const register = registers[0];
                    if (register._id !== mainElement.prevRegisterId) {
                        if (register.appealEntryId) {
                            return this.restService.find('licensingActivityRegistry', register.appealEntryId).then(appeal => {
                                return Promise.resolve({register: register, appeal: appeal});
                            });
                        }

                        return Promise.resolve({register: register});
                    }
                }

                return Promise.resolve(null);
            });
        }

        return Promise.resolve(null);
    }

    public updateLicense(data) {
        if (data && data.appeal && data.appeal.subservice.mainElement.dataForUpdateLicense) {
            return this.restService.update('licenses', data.appeal.subservice.mainElement.dataForUpdateLicense);
        } else {
            return Promise.resolve(null);
        }
    }

    public sendQuickRequest(data) {
        const element = data.element;

        return this.quickRequestsService.sendQuickRequest(element.quickRequestCode, element, true, 'event')
            .then(result => {
                console.log('quick request result', result);

                return Promise.resolve({data: result, saveResultToXsd: true});
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }
}
