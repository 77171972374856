import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityActNotImpossible } from './models/appeal-entity-act-not-impossible';
import { AppealEntitiesService } from '../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-act-not-impossible',
    templateUrl: 'appeal-entity-act-not-impossible.component.html',
})
export class AppealEntityActNotImpossibleComponent implements OnInit {
    @Input() public entity: AppealEntityActNotImpossible;
    @Input() public mode;
    @Input() public appeal;
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public isCheckPlan = false;
    public isCheckUnplan = false;

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);

        if (!this.entity.createDate) {
            this.entity.createDate = '';
        }

        if (!this.entity.dateNotify) {
            this.entity.dateNotify = '';
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }
}
