import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { AppealValidateService } from '../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../appeal-subservices.service';

@Component({
    selector: 'appeal-object-additional-data',
    templateUrl: 'appeal-object-additional-data.component.html',
})
export class AppealObjectAdditionalDataComponent implements OnInit, AfterViewInit {
    @Input() public object: any;

    public xsdData: any = [];

    public afterSaveNextTab; // Код вкладки, на которую нужно перейти после сохранения данных
    public afterSaveApply = false; // Флаг необходимости сохранения данных услуги в родительском компоненте (кнопка "Применить")

    @ViewChildren('additionalDataComponent') public additionalDataComponents: QueryList<DynamicFormComponent>;
    @Output() public onAfterSave = new EventEmitter<object>();

    public constructor(
        public validateService: AppealValidateService,
        private appealSubservicesService: AppealSubservicesService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Определение наличия xsd для обрабатываемого объекта по каждой услуге
        const subject = this.appealSubservicesService.data[this.appealSubservicesService.appeal.subservice.guid].subjects[this.object.guid];

        if (subject.active && subject.xsd) {
            if (!subject.xsdData) {
                subject.xsdData = {};
            }

            this.xsdData.push({ appealSubservice: this.appealSubservicesService.appeal.subservice, data: subject });
        }
    }

    /**
     * Запуск процесса сохранения формы для визуализации ошибок в случае если дело находится в статусе КНМ ошибок
     */
    public ngAfterViewInit() {
        if (this.validateService.processValidate) {
            setTimeout(() => {
                if (this.additionalDataComponents) {
                    this.additionalDataComponents.forEach(additionalDataComponent => {
                        additionalDataComponent.validate();
                    });
                }

                this.setValidState();
            }, 1000);
        }
    }

    /**
     * Запуск процедуры сохранения данных из родительского компонента (отдельное сохранение)
     * @param tab - код вкладки, на которую нужно перейти после сохранения (при клике на другую вкладку)
     * @param apply - после сохранения переход на метод apply в родительском компоненте (нажатие кнопки "Применить")
     */
    public save(tab = null, apply = false) {
        this.afterSaveNextTab = tab ? tab : null; // после сохранения переход на другую вкладку
        this.afterSaveApply = apply ? apply : false; // после сохранения сохранение данных (кнопка "Применить")

        this.setValidState();

        // Инициализация перехода на другую вкладку
        if (this.afterSaveNextTab) {
            this.onAfterSave.emit({ tab: this.afterSaveNextTab, apply: this.afterSaveApply });
        }
        // Инициализация сохранения изменений (кнопка "Применить")
        if (this.afterSaveApply) {
            this.onAfterSave.emit({ apply: true });
        }
    }

    private setValidState() {
        if (Array.isArray(this.xsdData)) {
            this.xsdData.forEach(item => {
                const component = this.additionalDataComponents.find(elm => elm.entityGuid === item.appealSubservice.guid);

                this.appealSubservicesService.data[item.appealSubservice.guid].subjects[this.object.guid].xsdDataValid = !component || component.isValid;
            });
        }
    }
}
