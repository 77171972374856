<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            {{ localizations.smev_configuration.single }}
            {{ localizations.smev_configuration.for_address }}:
            <p class="uri-field">
                {{ adaptor.uri }}
            </p>
            <div>
                <label [class]="getStatusClass()">
                    {{getStatusName()}}
                </label>
            </div>
        </h6>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body mb-20 center-content">
                {{ localizations.smev_configuration.select_status }}
                <div class="mt-10"
                    *ngFor="let status of getAllowedStatuses()">
                    <button class="btn btn-primary btn-xs btn-main btn-labeled"
                            type="button"
                            [disabled]="status===adaptor.adapter"
                            (click)="setStatus(status)">
                         {{ getStatusName(status) }}
                    </button>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
