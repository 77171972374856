<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            <span>
                {{object.name}}
            </span>
        </h6>
        <div class="heading-elements">
            <a [title]="localizations.common.edit"
               [routerLink]="['/', moduleBaseUrl, 'objects', workModeUrl, 'edit', object._id]">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </a>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        {{ localizations.common.general_data }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.common,
                                 'icon-arrow-left12': !panels.common
                                 }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.common}">
                        <ng-container>
                            <div class="mb-3">
                                <span class="text-muted">
                                    {{ localizations.common.short_name }}:
                                </span>
                                {{object.shortName}}
                            </div>
                            <div  class="mb-3"
                                  *ngIf="object.kind" >
                                <span class="text-muted">
                                    {{ localizations.objects.type }}:
                                </span>
                                {{object.kind.name}}
                            </div>
                            <div  class="mb-3"
                                  *ngIf="object.baseAddress">
                                <span class="text-muted">
                                    {{ localizations.common.address }}:
                                </span>
                                {{ getAddress(object.baseAddress) }}
                            </div>
                        </ng-container>
                    </div>
                </fieldset>
                <fieldset *ngIf="kndKinds && kndKinds.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.kndKinds = !panels.kndKinds">
                        {{ localizations.objects.knd_kinds }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.kndKinds,
                                 'icon-arrow-left12': !panels.kndKinds
                                 }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.kndKinds}">
                        <div class="flex flex-row mb-10"
                             *ngFor="let kndKind of kndKinds">
                            <div class="text-muted">
                                {{ kndKind.name }}:
                            </div>
                            <div>
                                {{ kndKind.riskCategory ?
                                    kndKind.riskCategory.name :
                                    localizations.objects.not_calculated
                                }}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
