import { Component, OnInit } from '@angular/core';
import { CommonStandardsService } from '../common-standards.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService, SelectService, StorageService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'common-standard-registers-model',
    templateUrl: './common-standard-registers-model.component.html',
    styles: [':host { width: 100%; }'],
})
export class CommonStandardRegistersModelComponent implements OnInit {
    public standard;
    public reglament;
    public metaData;
    public blocks;
    public isAllowEditMainElement = true;
    public registryTypes = [];

    public savedPlace;

    public titles;

    public blockTypes;

    public permissionParams;

    public basePath;
    public localizations;
    public tabs;
    public activeTab = 'common';

    public constructor(
        public standardsService: CommonStandardsService,
        private selectService: SelectService,
        private route: ActivatedRoute,
        private storage: StorageService,
        private translate: TranslateService,
        private applicationsService: ApplicationsService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.standard = this.standardsService.standard;
        this.reglament = this.standardsService.reglament;
        this.metaData = this.standardsService.metaReglament.blocks.find((item) => item.code === 'registersModel');
        if (!this.standard.registersModel) {
            this.standard.registersModel = {};
        }
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.basePath = urlPath[urlPath.length - 1].path;
        });
        this.savedPlace = this.standard.registersModel;
        this.prepareData();
        if (!this.savedPlace.titles) {
            this.savedPlace.titles = {};
        }
        if (!this.savedPlace.registryParams) {
            this.savedPlace.registryParams = {
                useChecksTab: true,
                structure: [],
            };
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.titles = [
            {
                name: this.localizations.standards.name_plural,
                code: 'plural',
            },
            {
                name: this.localizations.standards.name_single,
                code: 'single',
            },
        ];

        this.blockTypes = [
            {
                code: 'string',
                name: this.localizations.common.string,
            },
            {
                code: 'date',
                name: this.localizations.common.date,
            },
            {
                code: 'mainData',
                name: this.localizations.standards.application_data,
            },
        ];

        this.permissionParams = [
            {
                code: 'editObject',
                name: this.localizations.standards.other_region_objects_editing,
            },
            {
                code: 'removeObject',
                name: this.localizations.standards.other_region_objects_deleting,
            },
        ];

        this.tabs = [
            {
                code: 'common',
                name: this.localizations.common.general_data,
            },
            {
                code: 'registryItem',
                name: this.localizations.standards.registry_item_settings,
            },
        ];
    }

    public prepareData() {
        let registryTypes = this.metaData.registryTypes;
        if (this.route.snapshot.data && this.route.snapshot.data.applicationProperty) {
            const propertyName = this.route.snapshot.data.applicationProperty;
            const propertyValue = this.applicationsService.getApplicationPropertyByApplicationPath(
                this.basePath,
                propertyName
            );
            if (propertyValue) {
                if (propertyValue) {
                    if (propertyName === 'usedRegistryTypes') {
                        registryTypes = registryTypes.filter((item) => propertyValue.indexOf(item.code) !== -1);
                    } else if (propertyName === 'notUsedRegistryTypes') {
                        registryTypes = registryTypes.filter((item) => propertyValue.indexOf(item.code) === -1);
                    }
                }
            }
        }
        const currentUnit = this.storage.getItem('currentOrganization');
        const unitRegistryTypes = currentUnit.registryTypes ? currentUnit.registryTypes.map((item) => item.code) : [];
        registryTypes = registryTypes.filter((item) => unitRegistryTypes.indexOf(item.code) !== -1);
        if (registryTypes && registryTypes.length > 0) {
            this.registryTypes = this.selectService
                .transformForSelect('code', 'name', registryTypes)
                .map((item) => ({ ...item, text: item.text.length > 100 ? item.text.substring(0, 100) : item.text }));
            if (registryTypes.length === 1) {
                this.savedPlace.registryType = registryTypes[0];
                this.isAllowEditMainElement = false;
            }
        }
    }

    public getActiveRegistryType() {
        if (this.savedPlace && this.savedPlace.registryType) {
            const find = this.registryTypes.find((item) => item.id === this.savedPlace.registryType.code);
            return find ? [find] : [];
        } else {
            return [];
        }
    }

    public selectRegistryType(data) {
        this.savedPlace.registryType = this.metaData.registryTypes.find((item) => item.code === data.id);
    }
}
