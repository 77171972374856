import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
    AccessService,
    RestService,
    SelectService,
    SelectionService,
    StorageService,
    ToasterService,
    UserMessagesService,
    TranslateService,
} from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { CadesSignModalComponent } from '@evolenta/signing';
import { Permission } from '../../../common/services/permission';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import get from 'lodash-es/get';
import { emailMask } from 'text-mask-addons';

@Component({
    selector: 'user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['../../element-edit.css'],
})
export class UserEditComponent implements OnInit {
    public user; // обрабатываемый пользователь системы
    public applications; // массив модулей системы
    public roles; // массив доступных ролей
    public transformedRoles; // массив измененных ролей для выпадающих списков
    public organizations; // массив организаций для входа
    public baseOrganizations; // базовый массив организаций
    public globalSearch = '';

    public changePassword = {
        repeatPassword: '', // Повтор ввода пароля
        isChange: false, // Флаг процедуры смены пароля
    };
    public localizations;

    @ViewChild('userForm', { static: false }) public userForm: NgForm; // Форма для проверка валидности
    @ViewChild(CadesSignModalComponent, { static: false }) public cadesSignModalComponent: CadesSignModalComponent; // компонент для работы с сертификатами пользователя

    // Маска для ввода СНИЛС
    public snilsMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
    public mobilePhoneMask = [
        '+',
        '7',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
    ];
    public emailMask = emailMask;

    // Визуальные вкладки с данными пользователя
    public tabs = this.getTabs();

    public activeTab; // Код активной вкладки
    public permissions = Permission; // Описание всех полномочий системы

    public useEvolentaEo = false; // Использование ЭО эволена на стенде
    public useEnterEo = false; // Использование ЭО Enter
    public useCtoAsterisk = false; // Использование ЦТО Астериск на стенде
    public useAsterisk = false;
    public useQmaticEo = false; // Использование очереди Qmatic

    public showTree = true;

    public isProcessValidate = false; // Процесс проверки данных при попытке сохранения
    public isLoading = false;

    public globalRoles = []; // Глобальные роли (используется при глобальной настройке ролей)
    public generatePassword = false;

    public allowEdit = true;

    public authBySnils = this.storage.getItem('authBySnils');

    public rolesMnemonics = [];
    public transformedRolesMnemonics = [];
    public superAdminRole;
    public moduleBaseUrl;
    public isSuperAdminMode = false;
    public superAdminRoleGuid = '5efdb590-2788-4e43-85a7-1dd55b8c8055';

    public userEnvelopesMode = this.storage.getItem('userEnvelopesMode');
    public currentOrganization = this.storage.getItem('currentOrganization');

    public organizationsBaseSearch = [{ field: 'isAuthorized', operator: 'eq', value: true }];

    public organizationSearch;
    public isProcessingAddOrganization = false;

    public knoConfigNotApplicationsAccess = [];

    public requests = [];

    public userBackup: any;

    public constructor(
        public accessService: AccessService,
        public userMessagesService: UserMessagesService,
        private route: ActivatedRoute,
        private restService: RestService,
        private toaster: ToasterService,
        private selectService: SelectService,
        private router: Router,
        private storage: StorageService,
        private selectionService: SelectionService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate
            .get([
                'user',
                'users',
                'tasks',
                'common',
                'appeals',
                'subjects',
                'bpmn-card',
                'organizations',
                'notifications-preview',
            ])
            .subscribe((res: any) => {
                this.localizations = res;
                this._initData();
            });
    }

    private _initData() {
        this.knoConfigNotApplicationsAccess = [
            this.localizations.tasks.federal_metodolog_cabinet,
            this.localizations.tasks.security,
        ];
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
            if (this.moduleBaseUrl === 'admin') {
                this.isSuperAdminMode = true;
            }
        });
        this.activeTab = 'common'; // Код активной по умолчанию вкладки
        const passwordSettings = this.storage.getItem('passwordSettings');

        this.route.data.subscribe(
            async (response) => {
                this.user = response['resolves'].user;
                if (this.router.url.includes('kno-config')) {
                    this.applications = this.checkApplicationsAccess(response['resolves'].applications);
                } else {
                    this.applications = response['resolves'].applications; // модули системы
                }
                this.rolesMnemonics = response['resolves']['rolesMnemonics']; // функциональные роли

                // this.superAdminRole = this.roles.find(item => {return item.code === 'superAdmin'});
                this.transformedRolesMnemonics = this.selectService.transformForSelect(
                    'code',
                    'name',
                    this.rolesMnemonics
                );
                if (this.selectionService.isProcessSelect) {
                    this.user = this.selectionService.transferObject;
                    switch (this.selectionService.transferOperation) {
                        case 'selectRequests':
                            this.activeTab = 'requests';
                            break;
                    }
                }

                // создание пользователя
                if (!this.user._id) {
                    this.user.sprOrganizations = [];
                    this.user.linkRoles = [];
                    this.user.linkRolesMnemonics = [];
                    this.user.active = true; // активация пользователя
                    this.changePassword.isChange = true; // отображение полей ввода пароля
                    this.user.applications = [];
                    if (passwordSettings && passwordSettings.forceChangeOnCreate) {
                        this.user.forceChangePassword = true;
                    }
                }

                // если редактируется пользователь в кабинете администратора
                if (!this.isSuperAdminMode) {
                    // текущая организация
                    const findCurrentOrganization = this.user.sprOrganizations.find(
                        (item) => item.id === this.currentOrganization.guid
                    );
                    if (!findCurrentOrganization) {
                        this.user.sprOrganizations.push({
                            id: this.currentOrganization.guid,
                        });
                    }
                    // роль КПП СуперАдмин
                    const findSuperAdminRole = this.user.linkRoles.find(
                        (item) =>
                            item.role.id === this.superAdminRoleGuid &&
                            item.sprOrganization.id === this.currentOrganization.guid
                    );
                    if (!findSuperAdminRole) {
                        this.user.linkRoles.push({
                            id: CommonUtilities.GenerateGuid(),
                            role: { id: this.superAdminRoleGuid },
                            sprOrganization: {
                                id: this.currentOrganization.guid,
                            },
                        });
                    }
                }

                if (this.isSuperAdminMode) {
                    const organizations = await this.initUnits();
                    this.baseOrganizations = organizations;
                    this.organizations = this._prepareOrganizationTree(organizations); // организации с преобразованием в древовидную структуру
                    this.searchOrganizations(); // инициализация режима видимости элементов
                    // Добавление ролей к структуре организаций
                    this.processingOrganizationRoles(this.organizations);
                }

                // Инициализация флага "выбран" для приложений
                this.checkUsedApplications();

                // Инициализация настроек доступа к запросам
                if (this.userEnvelopesMode) {
                    this.initEnvelopeSettings();
                }

                this.userBackup = cloneDeep(this.user);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    public getUserActionMessage(user) {
        const template = this.localizations.tasks.user_action;
        if (user._id) {
            return template.replace('%s', this.localizations.common.editing);
        }

        return template.replace('%s', this.localizations.common.creation);
    }

    /**
     * Активация вкладки работы с определенным блоком данных
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        this.activeTab = tab.code;
    }

    /**
     * Инициализация нажатия на кнопку "Назад"
     */
    public back() {
        this.router.navigate(['/admin/users']);
    }

    public afterGeneratePassword(password) {
        this.user.password = password;
        this.changePassword.repeatPassword = password;
    }

    /**
     * Инициализация выбора приложения
     */
    public checkUsedApplications() {
        this.applications.forEach((application) => {
            if (this.user.applications) {
                const find = this.user.applications.find((item) => application._id === item);
                if (find) {
                    application.use = true;
                }
            }
        });
    }

    /**
     * Обработка выбора / отмены выбора приложения (модуля)
     * @param application - обрабатываемое приложения
     */
    public changeLinkApplications(application) {
        const appIndex = this.user.applications.findIndex((item) => item === application._id);
        if (appIndex === -1) {
            this.user.applications.push(application._id);
            application.use = true;
        } else {
            this.user.applications.splice(appIndex, 1);
            application.use = false;
        }
    }

    public changeNotification(kind, type) {
        if (!this.user.notificationSettings) {
            this.user.notificationSettings = [];
        }
        let findIndex = this.user.notificationSettings.findIndex((item) => item.kind === kind.code);
        if (findIndex === -1) {
            this.user.notificationSettings.push({ kind: kind.code, mobile: false, email: false });
            findIndex = this.user.notificationSettings.length - 1;
        }
        this.user.notificationSettings[findIndex][type] = !this.user.notificationSettings[findIndex][type];
    }

    public checkNotificationSelected(kind, type) {
        if (!this.user.notificationSettings) {
            this.user.notificationSettings = [];
        }
        const findIndex = this.user.notificationSettings.findIndex((item) => item.kind === kind.code);
        if (findIndex === -1) {
            return false;
        } else {
            return this.user.notificationSettings[findIndex][type];
        }
    }

    public getOrganizationTransformedRolesMnemonics(unitId) {
        const rolesInUnit = this.rolesMnemonics.filter((item) => !item.unitId || item.unitId === unitId);

        return this.selectService.transformForSelect('code', 'name', rolesInUnit);
    }

    /**
     * Поиск организаций
     */
    public searchOrganizations() {
        this._checkFindedOrganizations(this.organizations);
        this._generateVisibility(this.organizations);
    }

    /**
     * Отметка подходимости под поисковое значение
     * @param organizations
     */
    private _checkFindedOrganizations(organizations) {
        const searchText = this.globalSearch;
        organizations.forEach((org) => {
            if (!searchText) {
                org.used = true;
            } else {
                org.used =
                    (org.name && org.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) ||
                    (org.shortName && org.shortName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
            }
            if (org.used) {
                delete org.halfVisible;
            }
            if (org.children && org.children.length > 0) {
                this._checkFindedOrganizations(org.children);
            }
        });
    }

    /**
     * Управление видимостью элементов
     * @param orgs
     * @returns {boolean}
     */
    private _generateVisibility(orgs) {
        let existVisible = false;
        orgs.forEach((org) => {
            org.visible = org.used;
            if (org.children && org.children.length > 0) {
                this._generateVisibility(org.children);
            }
            if (!org.visible && org.children && org.children.length > 0) {
                org.halfVisible = this._generateVisibility(org.children);
            }
            if (org.visible) {
                existVisible = true;
            }
        });

        return existVisible;
    }

    private _processShowChildren(orgs) {
        orgs.forEach((org) => {
            if (org.children && org.children.length > 0) {
                org.showChildren = this.checkShowChildren(org.children);
            }
        });
    }

    public checkShowChildren(orgs) {
        let isShow = false;
        orgs.forEach((org) => {
            isShow = org.isProcessingEdit || org.showChildren;
            if (!isShow && org.children && org.children.length > 0) {
                const orgChildrenShow = this.checkShowChildren(org.children);
                if (orgChildrenShow) {
                    org.showChildren = true;
                    isShow = true;
                }
            }
        });

        return isShow;
    }

    /**
     * Формирование древовидной структуры организаций
     * @param organizations
     */
    private _prepareOrganizationTree(baseOrganizations) {
        baseOrganizations = baseOrganizations.map((item) => {
            delete item.children;

            return item;
        });
        const resultOrganizations = [];
        baseOrganizations.forEach((organization) => {
            organization.selected =
                organization.selected || !!this.user.sprOrganizations.find((item) => item.id === organization.id);
            if (organization.parentOrganizationId) {
                const parentOrganizationIndex = baseOrganizations.findIndex(
                    (item) => item.id === organization.parentOrganizationId
                );
                if (parentOrganizationIndex !== -1) {
                    if (!baseOrganizations[parentOrganizationIndex].children) {
                        baseOrganizations[parentOrganizationIndex].children = [];
                    }
                    baseOrganizations[parentOrganizationIndex].children.push(organization);
                }
            }
        });

        baseOrganizations.forEach((organization) => {
            if (
                !organization.parentOrganizationId &&
                ((organization.children && organization.children.length > 0) || organization.selected)
            ) {
                resultOrganizations.push(organization);
            }
        });

        this._sortOrganizations(resultOrganizations);

        return resultOrganizations;
    }

    private _sortOrganizations(organizations) {
        organizations.forEach((organization) => {
            if (organization.children && organization.children.length > 0) {
                this._sortOrganizations(organization.children);
            }
        });
        organizations.sort((a, b) => {
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();
            let comparison = 0;
            if (aName > bName) {
                comparison = 1;
            } else if (aName < bName) {
                comparison = -1;
            }

            return comparison;
        });
    }

    /**
     * Рекурсивное проставление ранее выбранных ролей для каждой организации в списке
     * @param organizations
     */
    public processingOrganizationRoles(organizations) {
        organizations.forEach((organization) => {
            organization.rolesMnemonics = [];
            if (this.user.linkRolesMnemonics) {
                this.user.linkRolesMnemonics.forEach((rolesMnemonic) => {
                    if (rolesMnemonic.sprOrganization === organization.id) {
                        const findItem = this.rolesMnemonics.find((item) => item.code === rolesMnemonic.roleMnemonic);
                        if (findItem) {
                            organization.rolesMnemonics.push({ id: findItem.code, text: findItem.name });
                        }
                    }
                });
            }
            if (organization.children && organization.children.length > 0) {
                this.processingOrganizationRoles(organization.children);
            }
        });
    }

    public changeOrganiztionRolesMnemonics(rolesMnemonics, organization) {
        organization.rolesMnemonics = rolesMnemonics;
    }

    public checkRolesMnemonicUse(roleMnemonic) {
        return (
            this.user.linkRolesMnemonics &&
            this.user.linkRolesMnemonics.find(
                (item) =>
                    item.sprOrganization === this.currentOrganization.guid && roleMnemonic.code === item.roleMnemonic
            )
        );
    }

    public changeRolesMnemonic(roleMnemonic) {
        const findIndex = this.user.linkRolesMnemonics.findIndex(
            (item) => item.sprOrganization === this.currentOrganization.guid && roleMnemonic.code === item.roleMnemonic
        );
        if (findIndex === -1) {
            this.user.linkRolesMnemonics.push({
                roleMnemonic: roleMnemonic.code,
                sprOrganization: this.currentOrganization.guid,
            });
        } else {
            this.user.linkRolesMnemonics.splice(findIndex, 1);
        }
    }

    public async initUnits() {
        const organizationIds = this.user.sprOrganizations.map((item) => item.id);
        if (organizationIds.length > 0) {
            const units = await this.restService.search('organizations', {
                search: { search: [{ field: 'id', operator: 'in', value: organizationIds }] },
                prj: 'unitsList',
                size: 150,
            });
            const parentIds = units
                .filter(
                    (item) => item.parentOrganizationId && !units.find((elm) => elm.id === item.parentOrganizationId)
                )
                .map((item) => item.parentOrganizationId);

            return await this.getParentUnits(units, parentIds);
        }

        return [];
    }

    public async getParentUnits(organizations, parentIds) {
        if (parentIds.length > 0) {
            const units = await this.restService.search('organizations', {
                search: { search: [{ field: 'id', operator: 'in', value: parentIds }] },
                prj: 'unitsList',
            });
            organizations = organizations.concat(units);
            const subParentIds = units
                .filter(
                    (item) =>
                        item.parentOrganizationId && !organizations.find((elm) => elm.id === item.parentOrganizationId)
                )
                .map((item) => item.parentOrganizationId);
            if (subParentIds.length > 0) {
                return this.getParentUnits(organizations, subParentIds);
            }
        }

        return organizations;
    }

    public removeOrganization(organization) {
        if (organization.children && organization.children.length > 0) {
            organization.selected = false;
            organization.rolesMnemonics = [];
        } else {
            const findIndex = this.baseOrganizations.findIndex((item) => item.id === organization.id);
            this.baseOrganizations.splice(findIndex, 1);
            this.organizations = this._prepareOrganizationTree(this.baseOrganizations);
            this.searchOrganizations(); // инициализация режима видимости элементов
        }
    }

    public async selectOrg(organization) {
        const find = this.baseOrganizations.find((item) => item.id === organization.id);
        if (!find || !find.selected) {
            if (!find) {
                organization.selected = true;
                organization.roles = [];
                organization.rolesMnemonics = [];
                organization.isProcessingEdit = true;
                this.baseOrganizations.push(organization);
                await this._recursivlyAddParentOrg(organization);
                this.organizations = this._prepareOrganizationTree(this.baseOrganizations);
                this.searchOrganizations(); // инициализация режима видимости элементов
                this._processShowChildren(this.organizations);
                this.isProcessingAddOrganization = false;
            } else {
                find.selected = true;
                find.rolesMnemonics = [];
                find.isProcessingEdit = true;
            }
        } else {
            const message = this._getOrganizationAlreadySelectedMessage(organization.shortName);
            this.toaster.info(message);
        }
    }

    private _getOrganizationAlreadySelectedMessage(name) {
        const template = this.localizations.tasks.organization_already_selected;

        return template.replace('%s', name);
    }
    private async _recursivlyAddParentOrg(organization) {
        if (organization.parentOrganizationId) {
            const foundOrganization = this.baseOrganizations.find(
                (item) => item.id === organization.parentOrganizationId
            );
            if (!foundOrganization) {
                const data = await this.restService.search('organizations', {
                    search: { search: [{ field: 'id', operator: 'eq', value: organization.parentOrganizationId }] },
                    prj: 'unitsList',
                });
                if (data && data.length) {
                    const parentOrg = data[0];
                    parentOrg.rolesMnemonics = [];
                    this.baseOrganizations.push(parentOrg);

                    return this._recursivlyAddParentOrg(parentOrg);
                } else {
                    organization.parentOrganizationId = null;
                }

                return;
            }

            return true;
        }

        return true;
    }

    public addOrganization() {
        this.organizationSearch = null;
        this.isProcessingAddOrganization = true;
    }

    /**
     * Формирование массива ролей пользователя при сохранении данных
     * @returns {Array}
     */
    public prepareForSaveRoles() {
        this.user.linkRoles = [];
        this.user.sprOrganizations.forEach((organization) => {
            this.user.linkRoles.push({
                role: { id: this.superAdminRoleGuid },
                sprOrganization: { id: organization.id },
            });
        });
    }

    /**
     * Получение массива выбранных организаций
     * @param organizations
     * @returns {Array}
     */
    public prepareForSaveOrganizations(organizations) {
        let result = [];
        organizations.forEach((organization) => {
            if (organization.selected && organization.rolesMnemonics.length > 0) {
                result.push({ id: organization.id });
            }
            if (organization.children) {
                result = result.concat(this.prepareForSaveOrganizations(organization.children));
            }
        });

        return result;
    }

    public prepareForSaveRolesMnemonics(organizations) {
        let result = [];
        organizations.forEach((organization) => {
            // снятие флага выбора организации, если не выбрана ни одна роль
            if (organization.selected && organization.rolesMnemonics.length === 0) {
                organization.selected = false;
            }
            if (organization.selected) {
                organization.rolesMnemonics.forEach((roleMnemonic) => {
                    result.push({
                        roleMnemonic: roleMnemonic.id,
                        sprOrganization: organization.id,
                    });
                });
            }
            if (organization.children) {
                result = result.concat(this.prepareForSaveRolesMnemonics(organization.children));
            }
        });

        return result;
    }

    /**
     * Сохранение данных пользователя на сервере
     */
    public async save() {
        this.isProcessValidate = true;
        if (this.userForm.valid) {
            this.user.login = this.user.login.toLowerCase().trim(); // приведение логина к нижнему регистру
            this.user.name = this.user.name.trim();
            if (this.user.snils) {
                this.user.snils = this.user.snils.trim();
            }
            if (this.user.position) {
                this.user.position = this.user.position.trim();
            }

            // записываем организации с выбранными ролями в объект пользователя
            if (this.isSuperAdminMode) {
                this.user.sprOrganizations = this.prepareForSaveOrganizations(this.organizations);
                this.prepareForSaveRoles();
                this.user.linkRolesMnemonics = this.prepareForSaveRolesMnemonics(this.organizations);

                if (this.userEnvelopesMode) {
                    this.prepareForSaveEnvelopeSettings();
                }
            }

            this.isLoading = true;
            if (this.user._id) {
                // Если осуществляется редактирование пользователя и не инициализирован функционал смены пароля, удаляем пароль
                if (this.user._id && !this.changePassword.isChange) {
                    delete this.user.password;
                }
                try {
                    await this.restService.update('users', this.user);
                    this.isLoading = false;
                    this.updateStorageUser();
                    this.userBackup = cloneDeep(this.user);
                    this.toaster.success('Данные пользователя обновлены');
                } catch (error) {
                    this.isLoading = false;
                    this.toaster.error(error);
                }
            } else {
                try {
                    const result = await this.restService.create('users', this.user);
                    this.toaster.success('Пользователь создан');
                    this.isLoading = false;
                    this.router.navigate([this.moduleBaseUrl, 'users', 'edit', result['_id']]);
                } catch (error) {
                    this.isLoading = false;
                    this.toaster.error(error);
                }
            }
        } else {
            this.toaster.error('На форме ошибки');
        }
    }

    public checkApplicationsAccess(applications) {
        return applications.filter((item) => !this.knoConfigNotApplicationsAccess.includes(item.name));
    }

    public getUsersBaseSearch(): any[] {
        const settingsUsers = Array.isArray(this.user.envelopeSettings.users) ? this.user.envelopeSettings.users : [];
        const userIds = [this.user, ...settingsUsers].filter((item) => item).map((item) => item._id);

        return [
            {
                field: 'sprOrganizations.id',
                operator: 'in',
                value: Array.isArray(this.user.sprOrganizations)
                    ? this.user.sprOrganizations.map((item) => item.id)
                    : [],
            },
            {
                field: '_id',
                operator: 'nin',
                value: userIds,
            },
        ];
    }

    public hasAdminAppAccess(): boolean {
        const findAdminApp = (this.applications || []).find((item) => item.code === 'admin');

        return findAdminApp ? findAdminApp.use : false;
    }

    private initEnvelopeSettings() {
        if (!this.user.envelopeSettings) {
            this.user.envelopeSettings = { allowAccess: true };
        }
    }

    private getTabs(): any[] {
        return [
            {
                code: 'common',
                name: 'Общее',
                show: () => true,
            },
            {
                code: 'organisations',
                name: 'Организации',
                show: () => this.isSuperAdminMode,
            },
            {
                code: 'envelopeSettings',
                name: 'Настройка запросов',
                show: () =>
                    this.userEnvelopesMode &&
                    this.isSuperAdminMode &&
                    !this.hasAdminAppAccess() &&
                    get(this.user, 'envelopeSettings.allowAccess'),
            },
        ];
    }

    private prepareForSaveEnvelopeSettings() {
        if (this.user.envelopeSettings && Array.isArray(this.user.envelopeSettings.users)) {
            this.user.envelopeSettings.users = this.user.envelopeSettings.users.filter((item) => item && item._id);
        }
    }

    private updateStorageUser() {
        const currentUser = this.storage.getItem('user');

        if (this.user._id === currentUser._id) {
            let isChangesExists = false;

            if (!isEqual(this.userBackup.envelopeSettings, this.user.envelopeSettings)) {
                currentUser.envelopeSettings = this.user.envelopeSettings;
                isChangesExists = true;
            }

            if (isChangesExists) {
                this.storage.setItem('user', currentUser);
            }
        }
    }
}
