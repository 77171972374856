import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessService, FilesService, ToasterService } from '@evolenta/core';
import { PrintingService } from '@evolenta/printing';
import { NgProgress } from 'ngx-progressbar';
import { AppealEnvelopesService } from './appeal-envelopes.service';
import { Permission } from '../../../../../common/services/permission';
import { AppealService } from '../../appeal.service';
import { PrintFormsService } from '../../../../../common/services/print/print-forms.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-envelope',
    templateUrl: 'appeal-envelope.component.html',
})
export class AppealEnvelopeComponent implements OnInit {
    @Input() public envelope; // обрабатываемый конверт
    @Input() public mode; // режим работы
    @Input() public printForms; // массив печатных форм для формирования реестра документов в конверте

    public envelopeData = {};
    public isProcessSelectResponsibleOrganization = false; // Флаг текущего процесса выбора организации конверта (ОГВ)
    public isProcessSelectSubservice = false; // Флаг процесса выбора услуги (комплексное дело)

    public appealDocuments = []; // массив доступных для выбора документов дела

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public permissions = Permission; // Описание всех полномочий системы

    public allowEdit = false; // возможность редактирования конверта

    public selectAll = false; // Выбор всех документов
    public isProcessingPrintForm = false; // Процесс формирования печатной формы

    public subservice: any = {};

    public constructor(
        public envelopesService: AppealEnvelopesService,
        public accessService: AccessService,
        private toaster: ToasterService,
        private appealService: AppealService,
        private printFormService: PrintFormsService,
        private printingService: PrintingService,
        private progressService: NgProgress,
        private filesService: FilesService,
    ) {
    }

    public ngOnInit() {
        this.subservice = this.envelopesService.appeal && this.envelopesService.appeal.subservice ? this.envelopesService.appeal.subservice : {};
        if (this.mode === 'edit') {
            if (this.envelope.appeal && !this.envelope.appeal.subservice) {
                this.isProcessSelectSubservice = true;
            } else {
                this.filterAppealDocuments();
            }
        }

        this.allowEdit = this.envelope.status === 'draft' && this.accessService.hasAccess([this.permissions.Appeal_Envelope_Update]);
    }

    /**
     * Инициализация режима редактирования
     */
    public edit() {
        this.onEdit.emit(this.envelope);
    }

    /**
     * Инициализация применения изменений в конверте
     */
    public apply() {
        if (this.envelope.documents.length === 0) {
            this.toaster.error('В конверт не добавлены документы!');
        } else {
            this.onApply.emit(this.envelope);
        }
    }

    /**
     * Перевод конверта в статус "Сформирован"
     */
    public complete() {
        if (this.envelope.documents.length === 0) {
            this.toaster.error('В конверт не добавлены документы!');
        } else {
            const envelope = _.cloneDeep(this.envelope);
            envelope.status = 'process';
            this.onApply.emit(envelope);
        }
    }

    /**
     * Удаление конверта
     */
    public deleteEnvelope() {
        this.onDelete.emit(this.envelope);
    }

    /**
     * Отмена измнений
     */
    public cancel() {
        this.onApply.emit(false);
    }

    /**
     * Возвращает наименование текущего статуса конверта
     * @returns {any}
     */
    public getEnvelopeStatusName() {
        return this.envelopesService.envelopeStatuses[this.envelope.status];
    }

    /**
     * Выбор организации конверта
     * @param organization
     */
    public selectOrganization(organization) {
        this.envelope.responsibleOrganization = organization;
        this.filterAppealDocuments();
        this.isProcessSelectResponsibleOrganization = false;
    }

    /**
     * Выбор услуги дела
     * @param subservice
     */
    public selectSubservice(subservice) {
        this.envelope.appeal.subservice = {
            id: subservice.id,
            title: subservice.shortTitle,
            serviceId: subservice.serviceId,
            guid: subservice.guid,
            classificSubserviceId: subservice.classificSubserviceId,
            classificSubserviceName: subservice.classificSubserviceName,
        };
        if (subservice.dateRegister) {
            this.envelope.appeal.dateRegister = subservice.dateRegister;
        }
        this.envelope.responsibleOrganization = subservice.responsibleOrganization;
        this.isProcessSelectSubservice = false;
        this.filterAppealDocuments();
    }

    /**
     * Фильтрация документов дела в соответствии с ОГВ конверта
     */
    public filterAppealDocuments() {
        const filteredDocuments = this.envelopesService.appeal.documents.filter(document => {
            return (!document.subserviceGuid && document.groupCode !== 'complex'
                   || document.subserviceGuid && document.subserviceGuid === this.envelope.appeal.subservice.guid) && !document.deleted;
        });
        // this.envelopesService.appeal.documents.forEach((document) => {
        //     if (!document.subserviceGuid && document.groupCode !== 'complex') {
        //         documents.push(document);
        //     } else {
        //         const find = this.envelopesService.appeal.subservices.find(item => {return item.guid === document.subserviceGuid});
        //         if (find && find.responsibleOrganization.id === this.envelope.responsibleOrganization.id) {
        //             documents.push(document);
        //         }
        //     }
        // });
        this.appealDocuments = _.cloneDeep(filteredDocuments);
    }

    /**
     * КНМ выбран документ или нет
     * @param document
     * @returns {boolean}
     */
    public checkDocumentSelected(document) {
        let checked = false;
        this.envelope.documents.forEach(doc => {
            if (doc.guid === document.guid) {
                checked = true;
            }
        });

        return checked;
    }

    /**
     * Выбор / отмена выбора документа
     * @param document - обрабатываемый документ
     */
    public selectDocument(document) {
        const findIndex = this.envelope.documents.findIndex(item => item.guid === document.guid);
        if (findIndex !== -1) {
            this.selectAll = false;
            this.envelope.documents.splice(findIndex, 1);
        } else {
            this.envelope.documents.push(document);
        }
    }

    /**
     * Возвращает наименование объекта по его GUID
     * @param guid - GUID объекта
     * @returns {any}
     */
    public getObjectHeaderByGuid(guid) {
        return this.envelopesService.appeal.objects.find(item => item.guid === guid).shortHeader;
    }

    /**
     * Выбор / отмена выбора всех документов одиним кликом
     */
    public selectAllDocuments() {
        if (this.selectAll) {
            this.appealDocuments.forEach(document => {
                const findIndex = this.envelope.documents.findIndex(item => item.guid === document.guid);
                if (findIndex === -1) {
                    this.envelope.documents.push(document);
                }
            });
        } else {
            this.envelope.documents = [];
        }
    }

    /**
     * Формирование реестра документов в конверте
     * @param printForm
     */
    public async print(printForm: any): Promise<void> {
        if ((this.envelope.appeal && this.envelope.appeal.subservice && this.appealService.subservice._id === this.envelope.appeal.subservice.id)) {
            throw new Error('Не найден сабсервис');
        }

        const appealData = await this.printFormService.prepareAppealData(this.appealService.appeal, this.appealService.subservice);
        const envelopeRegisterData = this.printFormService.prepareEnvelopeRegister(this.envelope, appealData);

        this.progressService.start();
        this.isProcessingPrintForm = true;

        try {
        const response = await this.printingService
            .renderPrintForm(printForm._id, this.envelope._id, 'appealEnvelopes', envelopeRegisterData, null, null, this.envelope.unit.id, this.envelope.responsibleOrganization.id);
        await this.filesService.downloadAndSaveFile(response.file._id, response.file.name);
        } catch (error) {
            this.toaster.html(error.errorMessage || error);
        } finally {
            this.isProcessingPrintForm = false;
            this.progressService.done();
        }
    }
}
