import { Injectable } from '@angular/core';

// коды хранятся в dev/permissions
@Injectable()
export class Permission {
    // --------------------------------------------------- //
    // ------------------ Общие полномочия --------------- //
    // --------------------------------------------------- //
    public static All_System = 'allSystem'; // неограниченный доступ ко всей системе
    public static No_Limit_By_Current_Unit = 'noLimitByCurrentUnit'; // отключение фильтрации по текущему МФЦ
    public static No_Edit_Limits = 'noEditLimits'; // отключение ограничений на редактирование элементов
    public static Change_Any_User_Profile = 'changeAnyUserProfile'; // изменение данных любого пользователя (администратор)

    // Печатные формы
    public static Print_Forms = 'printForms';

    // Файлы
    public static File_Operations = 'fileOperations'; // загрузка файлов на сервер, загрузка файлов с сервера
    public static File_Sign = 'fileSign'; // подписание файлов
    public static Documents_Scan = 'documentsScan'; // сканирование документов

    // --------------------------------------------------- //
    // ------------------- Модуль АИС -------------------- //
    // --------------------------------------------------- //

    // Полномочия определяющие возможность работы с элементами дела в определенных статусах
    public static Appeal_Status_Draft = 'aisAppealStatusDraft'; // черновик
    public static Appeal_Status_Process = 'aisAppealStatusProcess'; // в обработке
    public static Appeal_Status_Consultation = 'aisAppealStatusConsultation'; // консультация
    public static Appeal_Status_RejectedReceiptDocs = 'aisAppealStatusRejectedReceiptDocs'; // отказ в приеме документов
    public static Appeal_Status_Rejected = 'aisAppealStatusRejected'; // отказано в предоставлении услуги
    public static Appeal_Status_Annulled = 'aisAppealStatusAnnulled'; // аннулировано
    public static Appeal_Status_BeforeIssued = 'aisAppealStatusBeforeIssued'; // передано на выдачу
    public static Appeal_Status_Issued = 'aisAppealStatusIssued'; // выдано
    public static Appeal_Status_Archive = 'aisAppealStatusArchive'; // в архиве
    public static Appeal_Status_Forwarded = 'aisAppealStatusForwarded'; // передано на обработку в ОГВ
    public static Appeal_Status_Returned = 'aisAppealStatusReturned'; // возврат из обработки в ОГВ
    public static Appeal_Status_ForwardedForIssue = 'aisAppealStatusForwardedForIssue'; // передача в ОГВ на обработку и выдачу
    public static Appeal_Status_Unclaimed = 'aisAppealStatusUnclaimed'; // невостребовано

    // Полномочия определяющие возможность перевода дела на определенные статусы
    public static Appeal_Allow_Action_Process = 'aisAppealAllowActionProcess'; // перевод в статус "В обработке"
    public static Appeal_Allow_Action_RejectedReceiptDocs = 'aisAppealAllowActionRejectedReceiptDocs'; // перевод в статус "Отказано в приеме документов"
    public static Appeal_Allow_Action_Consultation = 'aisAppealAllowActionConsultation'; // перевод в статус "Консультация"
    public static Appeal_Allow_Action_Annuled = 'aisAppealAllowActionAnnuled'; // перевод в статус "Аннулировано"
    public static Appeal_Allow_Action_BeforeIssued = 'aisAppealAllowActionBeforeIssued'; // перевод в статус "На выдачу"
    public static Appeal_Allow_Action_Issued = 'aisAppealAllowActionIssued'; // перевод в статус "Выдано"
    public static Appeal_Allow_Action_Archive = 'aisAppealAllowActionArchive'; // перевод в статус "В архив"
    public static Appeal_Allow_Action_Substatus = 'aisAppealAllowActionSubstatus'; // возможность перевода дела в подстатус
    public static Appeal_Allow_Action_Forwarded = 'aisAppealAllowActionForwarded'; // возможность перевода дела в статус "Передано на обработку в ОГВ"
    public static Appeal_Allow_Action_Returned = 'aisAppealAllowActionReturned'; // возможность перевода дела в статус "Возврат из обработки в ОГВ"
    public static Appeal_Allow_Action_ForwardedForIssue = 'aisAppealAllowActionForwardedForIssue'; // возможность перевода дела в статус "Передача в ОГВ на обработку и выдачу"
    public static Appeal_Allow_Action_Unclaimed = 'aisAppealAllowActionUnclaimed'; // возможность перевода дела в статус "Невостребовано"

    // Дополнительные полномочия при работе с делом
    public static Allow_Print_Registers_After_Appeal_Registration = 'aisAllowPrintRegistersAfterAppealRegistration'; // возможность печати расписки после регистрации дела
    public static Allow_Print_Registers_Appeal_Before_Issued = 'aisAllowPrintRegistersAppealBeforeIssued'; // возможность печати расписки и редактировать ее параметры в статусе "На выдачу"
    public static Allow_Edit_Appeal_Data_After_Registration = 'aisAllowEditAppealDataAfterRegistration'; // возможность редактирования ОГВ, данных участника, данных документа после регистрации дела
    public static Allow_Add_Documents_On_Before_Issued = 'aisAllowAddDocumentsOnBeforeIssued'; // возможность добавления документов на статусе "На выдачу"
    public static Allow_Add_Documents_Into_Electronic_Archive = 'aisAllowAddDocsIntoElectronicArchive'; // возможность добавления документов в эдектронный архив

    // Дело
    public static Appeals_Search = 'aisAppealsSearch'; // список дел (просмотр карточки дела)
    public static Appeal_Create = 'aisAppealCreate'; // создание дела
    public static Appeal_Update = 'aisAppealUpdate'; // обновление дела
    public static Appeal_Execute_Action = 'aisAppealExecuteAction'; // перевод на другой статус

    // Объекты дела
    public static Appeal_Object_Create = 'aisAppealObjectCreate'; // добавление объекта дела
    public static Appeal_Object_Update = 'aisAppealObjectUpdate'; // обновление объекта дела
    public static Appeal_Object_Delete = 'aisAppealObjectDelete'; // удаление объекта дела

    // Документы дела
    public static Appeal_Document_Create = 'aisAppealDocumentCreate'; // добавление документа дела
    public static Appeal_Document_Update = 'aisAppealDocumentUpdate'; // обновление документа дела
    public static Appeal_Document_Delete = 'aisAppealDocumentDelete'; // удаление документа дела

    // Услуги дела
    public static Appeal_Subservice_Create = 'aisAppealSubserviceCreate'; // добавление услуги дела (комплексное дело)
    public static Appeal_Subservice_Update = 'aisAppealSubserviceUpdate'; // обновление данных услуги дела
    public static Appeal_Subservice_Delete = 'aisAppealSubserviceDelete'; // удаление услуги из дела (комплексное дело)

    // Работа с запросами-конвертами
    public static Envelopes_Search = 'smevEnvelopesSearch'; // список запросов (просмотр карточки запроса)
    public static Envelope_Create = 'smevEnvelopeCreate'; // добавление запроса
    public static Envelope_Update = 'smevEnvelopeUpdate'; // изменение данных запроса
    public static Envelope_Send = 'smevEnvelopeSend'; // отправка запроса

    // Конверты документов в деле (вкладка "Конверты")
    public static Appeal_Envelopes_Search = 'aisAppealEnvelopesSearch'; // список конвертов
    public static Appeal_Envelope_Create = 'aisAppealEnvelopeCreate'; // добавление конверта
    public static Appeal_Envelope_Update = 'aisAppealEnvelopeUpdate'; // изменение конверта
    public static Appeal_Envelope_Delete = 'aisAppealEnvelopeDelete'; // удаление конверта

    // Сообщения-уведомления в деле
    public static Appeal_Messages_Search = 'aisAppealMessagesSearch'; // список сообщений
    public static Appeal_Message_Create = 'aisAppealMessageCreate'; // добавление сообщения
    public static Appeal_Message_Update = 'aisAppealMessageUpdate'; // изменение сообщения
    public static Appeal_Message_Delete = 'aisAppealMessageDelete'; // удаление сообщения

    // Услуги (Регламенты)
    public static Subservices_Search = 'aisSubservicesSearch';

    // Клиенты - участники
    public static Persons_Search = 'aisPersonsSearch';
    public static Person_Create = 'aisPersonCreate';
    public static Person_Update = 'aisPersonUpdate';

    // Пакеты
    public static Packets_Search = 'aisPacketsSearch';
    public static Packet_Create = 'aisPacketCreate';
    public static Packet_Update = 'aisPacketUpdate';
    public static Packet_Send = 'aisPacketSend';

    // Групповые операции
    public static GroupOperations_Search = 'aisGroupOperationsSearch';
    public static GroupOperation_Create = 'aisGroupOperationCreate';
    public static GroupOperation_Update = 'aisGroupOperationUpdate';

    // События
    public static Events_Search = 'aisEventsSearch';
    public static Event_Create = 'aisEventCreate';
    public static Event_Update = 'aisEventUpdate';

    // --------------------------------------------------- //
    // ---------- Модуль Администрирования --------------- //
    // --------------------------------------------------- //

    // Пользователи
    public static Users_Search = 'adminUsersSearch';
    public static User_Create = 'adminUserCreate';
    public static User_Update = 'adminUserUpdate';
    public static User_Delete = 'adminUserDelete';

    // Роли
    public static Roles_Search = 'adminRolesSearch';
    public static Role_Create = 'adminRoleCreate';
    public static Role_Update = 'adminRoleUpdate';
    public static Role_Delete = 'adminRoleDelete';

    // Запросы
    public static Requests_Search = 'adminRequestsSearch';
    public static Request_Create = 'adminRequestCreate';
    public static Request_Update = 'adminRequestUpdate';
    public static Request_Delete = 'adminRequestDelete';

    // XSD
    public static Xsd_Search = 'adminXsdSearch';
    public static Xsd_Create = 'adminXsdCreate';
    public static Xsd_Update = 'adminXsdUpdate';
    public static Xsd_Delete = 'adminXsdDelete';

    // Организации
    public static Organizations_Search = 'adminOrganizationsSearch';
    public static Organization_Create = 'adminOrganizationCreate';
    public static Organization_Update = 'adminOrganizationUpdate';
    public static Organization_Delete = 'adminOrganizationDelete';

    // Статусы
    public static CtoStatuses_Search = 'adminCtoStatusesSearch';
    public static CtoStatus_Create = 'adminCtoStatusCreate';
    public static CtoStatus_Update = 'adminCtoStatusUpdate';
    public static CtoStatus_Delete = 'adminCtoStatusDelete';

    // Настройки уведомлений о статусе дела
    public static SettingMessaging_Search = 'adminSettingMessagingSearch';
    public static SettingMessaging_Create = 'adminSettingMessagingCreate';
    public static SettingMessaging_Update = 'adminSettingMessagingUpdate';
    public static SettingMessaging_Delete = 'adminSettingMessagingDelete';

    // Настройки системы
    public static Settings_Search = 'adminSettingsSearch';
    public static Setting_Create = 'adminSettingCreate';
    public static Setting_Update = 'adminSettingUpdate';
    public static Setting_Delete = 'adminSettingDelete';

    // --------------------------------------------------- //
    // ---------- Модуль Отчеты -------------------------- //
    // --------------------------------------------------- //
    // Отчеты
    public static Main_Reports = 'reportsWork';

    // --------------------------------------------------- //
    // ------------- Модуль ЦТО -------------------------- //
    // --------------------------------------------------- //
    public static Handlings_Search = 'handlingsSearch';
    public static Handling_Create = 'handlingCreate';
    public static Handling_Update = 'handlingUpdate';

    // --------------------------------------------------- //
    // ------------- Модуль Платежи ---------------------- //
    // --------------------------------------------------- //

    public static Payments_Search = 'paymentsSearch';
    public static Payment_Create = 'paymentCreate';
    public static Payment_Update = 'paymentUpdate';

    public static PaymentTemplates_Search = 'paymentTemplatesSearch';
    public static PaymentTemplate_Create = 'paymentTemplateCreate';
    public static PaymentTemplate_Update = 'paymentTemplateUpdate';

    public static PaymentTerminals_Search = 'paymentTerminalsSearch';
    public static PaymentTerminal_Create = 'paymentTerminalsCreate';
    public static PaymentTerminal_Update = 'paymentTerminalsUpdate';

    // --------------------------------------------------- //
    // ---------- Модуль Бизнесс Процессов --------------- //
    // --------------------------------------------------- //

    // Пользователи
    public static Processes_Registry_Search = 'processesRegistrySearch';
    public static Processes_Registry_Create = 'processesRegistryCreate';
    public static Processes_Registry_Update = 'processesRegistryUpdate';
    public static Processes_Registry_Delete = 'processesRegistryDelete';
}
