<div [ngClass]="{'m-20': !noMargin, 'no-margin': noMargin, 'panel panel-shadow': usePanel, 'mb-10': marginBottom}">
    <div class="panel-body" [ngClass]="{'panel-body': usePanel}">
        <ng-container *ngFor="let block of blocks; let lst = last">
            <ng-container *ngIf="block.itemsInRow">
               <p [ngClass]="{'no-margin': lst}">
                   <ng-container *ngFor="let childBlock of block.itemsInRow; let idx = index">
                       <registry-block [block]="childBlock"
                                       [dataSource]="dataSource"
                                       [isChild]="true"
                                       [childIndex]="idx"
                                       [parentData]="parentData"></registry-block>
                   </ng-container>
               </p>
            </ng-container>
            <ng-container *ngIf="!block.itemsInRow">
                <registry-block [block]="block"
                                [dataSource]="dataSource"
                                [parentData]="parentData"
                                [isLast]="lst"></registry-block>
            </ng-container>
        </ng-container>
    </div>
</div>
