import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { StorageService, TranslateService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'can-deactivate-modal',
    templateUrl: './can-deactivate-modal.component.html',
})
export class CanDeactivateModalComponent implements OnInit {
    public property = null;

    public modalRef: BsModalRef;
    @ViewChild('modal', { static: false }) public modal: TemplateRef<any>;
    public localizations;

    public constructor(
        private storage: StorageService,
        private modalService: BsModalService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
    }

    public open() {
        this.modalRef = this.modalService.show(this.modal, { backdrop: 'static' });
    }

    public onButtonClick(storageName?) {
        return new Promise((resolve, reject) => {
            this.open();
            document.getElementById('deactivateStateBtn').onclick = () => {
                resolve(true);
            };
            document.getElementById('deactivateSaveBtn').onclick = () => {
                this.storage.removeItem('storageName');
                resolve(false);
            };
            document.getElementById('cancel').onclick = () => {
                resolve('cancel');
            };
        });
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'can-deactivate-modal',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }
}
