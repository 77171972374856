<!--  Выбор лицензии  -->
<ng-container *ngIf="subservice.registersModel.selectLicense">
    <div class="mb-20">

        <!-- Множественные лицензии -->
        <ng-container *ngIf="subservice.registersModel.selectMultipleLicenses">
            <fieldset>
                <legend class="text-bold mb-5">
                    {{subservice.registersModel.titles && subservice.registersModel.titles['plural'] ? subservice.registersModel.titles['plural'] : 'Лицензии'}}
                    <span class="badge bg-info-300 ml-10">Всего: {{mainElement.licenses.length}}</span>
                    <span class="anchor text-info text-size-small ml-10 add-link"
                          *ngIf="allowEdit"
                          (click)="selectLicenses()">
                        выбрать
                    </span>
                    <a class="control-arrow" (click)="showLicenses = !showLicenses">
                        <i [ngClass]="{'icon-arrow-down32': showLicenses, 'icon-arrow-left32': !showLicenses}"></i>
                    </a>
                </legend>
                <ul *ngIf="showLicenses && mainElement.licenses && mainElement.licenses.length > 0">
                    <li class="mb-5" *ngFor="let license of mainElement.licenses">
                        {{subservice.registersModel.titles && subservice.registersModel.titles['single'] ? subservice.registersModel.titles['single'] : 'Лицензия'}}
                        <ng-container *ngIf="license.license">
                            № {{license.license.number}} от <ng-container *ngIf="license.license.date">{{license.license.date | date: 'shortDate'}} г.</ng-container>
                        </ng-container>

                        <a class="text-size-small anchor text-info ml-10" (click)="goToLicense(license)">перейти</a>
                        <a class="text-size-small anchor text-slate ml-10" (click)="removeLicense(license)" *ngIf="allowEdit">удалить</a>
                    </li>
                </ul>
            </fieldset>
        </ng-container>

        <!-- Одна лицензия -->
        <ng-container *ngIf="!subservice.registersModel.selectMultipleLicenses">
            <span class="text-bold mr-5 text-uppercase">{{subservice.registersModel.titles && subservice.registersModel.titles['single'] ? subservice.registersModel.titles['single'] : 'Лицензии'}}:</span>
            <em *ngIf="!mainElement.licenseId">не выбрана</em>
            <ng-container *ngIf="mainElement.license">
                № {{mainElement.license.number}} от <ng-container *ngIf="mainElement.license.date">{{mainElement.license.date | date: 'shortDate'}} г.</ng-container>
            </ng-container>
            <a class="text-size-small anchor text-info ml-10" *ngIf="allowEdit"
               (click)="selectLicenses()">{{mainElement.licenseId ? 'изменить' : 'выбрать'}}</a>
            <a class="text-size-small anchor text-info ml-10" *ngIf="mainElement.licenseId" (click)="goToLicense(mainElement)">перейти</a>
        </ng-container>

    </div>

    <!-- Выбор региона для изменения фильтрации списка лицензий по другому региону -->
    <div class="mb-10" *ngIf="allowEdit && subservice.registersModel.useOtherRegionCheck">
        <div class="checkbox-inline mb-10">
            <input type="checkbox" class="checkbox" id="licenseOtherRegion" [checked]="mainElement.otherRegionLicense"
                   (change)="mainElement.otherRegionLicense = !mainElement.otherRegionLicense">
            <label for="licenseOtherRegion">Лицензия другого региона</label>
        </div>
        <div style="width: 350px" class="form-group">
            <label>Выберите регион</label>
            <catalogue [large]="true" catalogName="catalogueRegions" name="region" *ngIf="mainElement.otherRegionLicense"
                                [showCode]="false"
                                [(ngModel)]="mainElement.otherRegion"
                                [returnFields]="['code','name']"></catalogue>
        </div>
    </div>
</ng-container>
