<div class="alert alert-info no-margin no-border no-border-radius"
     *ngIf="entityParams">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations.tasks.main_params_in_card}}
    <a class="anchor text-info"
       [routerLink]="entityParams.urlParts">
        {{entityParams.name}}
    </a>
</div>

<div class="pt-15"
     *ngIf="parentTask && task.parentTaskId">
    <p class="pl-20 pr-20 pb-15 no-margin"
       *ngIf="parentTask && task.parentTaskId">
        <span class="text-muted mr-5">
            {{ localizations.tasks.parental }}:
        </span>
        <span>
            {{parentTask.name}}
        </span>
        <span class="text-info anchor text-size-small ml-5"
              (click)="showParent()">
            {{ localizations.common.proceed }}
        </span>
    </p>
    <hr class="no-margin">
</div>

<!-- Общие данные -->
<div class="p-20">
    <h4 class="mb-10 no-margin-top"
        *ngIf="task.id">
        {{task.name}}
    </h4>
    <div class="form-group form-group-lg"
         *ngIf="!task.id">
        <label>
            {{ localizations.tasks.label }}
            <span class="text-danger">*</span></label>
        <input type="text"
               class="form-control"
               name="name"
               id="name"
               [(ngModel)]="task.name">
    </div>
    <div class="form-group form-group-lg"
         *ngIf="!appeal">
        <label>
            {{ localizations.tasks.content }}
        </label>
        <textarea id="content"
                  class="form-control"
                  name="content"
                  [(ngModel)]="task.content"
                  [readOnly]="task.status && task.status != 'ACTIVE'">
        </textarea>
    </div>

    <p *ngIf="entityParams">
        <span class="text-muted">
            {{entityParams.name}}:
        </span>
        {{entityParams.entityName}}
        <a class="text-info anchor text-size-small"
           [routerLink]="entityParams.urlParts">
            {{ localizations.tasks.label }}
        </a>
    </p>


    <!-- Приоритет -->
    <p>
        <span class="text-muted">
            {{ localizations.tasks.priority}}:
        </span>
        <span class="badge text-uppercase ml-5"
              [ngClass]="taskService.getStatusByProperty(task.priority, 'background')">
            {{taskService.getStatusByProperty(task.priority, 'name')}}
        </span>
        <span class="text-size-small anchor text-info ml-5"
              *ngIf="task.status && task.status === 'ACTIVE'"
              (click)="isEditPriority = !isEditPriority">
            {{ localizations.common.change_small }}
        </span>
    </p>
    <div class="bg-primary-50 p-20"
         *ngIf="isEditPriority">
        <p class="no-margin text-bold">
            {{ localizations.select_priority }}:
        </p>
        <div class="radio-block"
             *ngFor="let status of taskService.priorities">
            <input id="priority{{status.code}}"
                   class="radio"
                   type="radio"
                   name="priority"
                   [checked]="task.priority == status.priority">
            <label for="priority{{status.code}}"
                   (click)="changePriority(status)">
                <span class="badge text-uppercase priority-label {{status.background}}">
                    {{status.name}}
                </span>
            </label>
        </div>
    </div>

    <!-- Дата начала -->
    <p>
        <span class="text-muted">
            {{ localizations.tasks.start_date }}:
        </span>
        {{task.followUp ? (task.followUp | date: 'mediumDate') : localizations.common.not_set }}
        <span class="text-size-small anchor text-info ml-5"
              (click)="editFollowUpDate()"
              *ngIf="task.status && task.status === 'ACTIVE'">
            {{ localizations.common.change_small }}
        </span>
    </p>
    <div class="bg-primary-50 p-20"
         *ngIf="isEditFollowUpDate">
        <p class="text-bold mb-10">
            {{ localizations.tasks.select_start_date }}
        </p>
        <div class="date-block">
            <evolenta-datepicker [(ngModel)]="task.followUp"
                         name="followUp"
                         [large]="true"
                         [valueAsString]="true"
                         (ngModelChange)="changeFollowUpDate()">
            </evolenta-datepicker>
        </div>
    </div>

    <!-- Дата завершения -->
    <p>
        <span class="text-muted">
            {{ localizations.tasks.completion_date}}:
        </span>
        {{ task.due ? (task.due | date: 'mediumDate') : localizations.common.not_set }}
        <span class="text-size-small anchor text-info ml-5"
              (click)="editDueDate()"
              *ngIf="task.status && task.status === 'ACTIVE'">
            {{ localizations.common.change_small }}
        </span>
    </p>
    <div class="bg-primary-50 p-20"
         *ngIf="isEditDueDate">
        <p class="text-bold mb-10">
            {{ localizations.tasks.select_end_date }}
        </p>
        <div class="date-block">
            <evolenta-datepicker name="dueDate"
                         [(ngModel)]="task.due"
                         [large]="true"
                         [valueAsString]="true"
                         (ngModelChange)="changeDueDate()">
            </evolenta-datepicker>
        </div>
    </div>


    <!-- Ответственный за исполнение задачи -->
    <p *ngIf="!isEditUsers">
        <span class="text-muted mr-10">
            {{ localizations.tasks.assigned_to }}:
        </span>
        <ng-container *ngIf="task.assignee">
            {{getAssigneeName()}}
            <span class="ml-5 text-size-small cursor-pointer hide"
                  (click)="showUserInfo()">
                <i class="fa fa-info-circle text-slate cursor-pointer"></i>
            </span>
        </ng-container>
        <span class="ml-5"
              *ngIf="!task.assignee">
            {{ localizations.tasks.assignee_is_missing }}
        </span>
        <span class="text-size-small anchor text-info ml-5"
              (click)="isEditUsers = true"
              *ngIf="usersForSelect && usersForSelect.length > 0 && task.status && task.status === 'ACTIVE'">
            {{ localizations.common.change_small }}
        </span>
    </p>
    <div *ngIf="isEditUsers">
        <div class="alert alert-warning no-margin"
             *ngIf="candidateGroups && candidateGroups.length > 0">
            <span class="text-bold mr-20">
                {{ localizations.common.attention }}
            </span>
            <span>
                {{ getCanAssignToGroupOnlyMessage() }}:
                            <ng-container *ngFor="let group of candidateGroups; let lst = last">
                                {{group.name}}
                                <ng-container *ngIf="!lst">, </ng-container></ng-container>
                        </span>
        </div>
    </div>
    <div class="bg-primary-50 p-20"
         *ngIf="isEditUsers">
        <label class="text-bold">
            {{ localizations.tasks.select_user }}
        </label>
        <div class="radio-block"
             *ngFor="let user of usersForSelect">
            <input id="user{{user._id}}"
                   class="radio"
                   type="radio"
                   [checked]="task.assignee && task.assignee === user.login"
                   (change)="assigneeUser(user)">
            <label for="user{{user._id}}">
                {{user.name}}
                <span class="badge bg-info ml-10 no-padding-top no-padding-bottom pl-5 pr-5"
                      [title]="localizations.tasks.number_of_active">
                    {{user.tasksCount}}
                </span>
            </label>
        </div>
    </div>

    <!--  Трудозатраты  -->
    <fieldset class="mb-20">
        <legend class="text-bold">
            {{ localizations.tasks.costs_per_task }}
            <span class="label label-info text-uppercase ml-10">
                {{calculateTotalWorkTime()}}
            </span>
        </legend>
        <ul class="list-feed mb-20"
            *ngIf="task.workTimes && task.workTimes.length > 0">
            <li *ngFor="let item of task.workTimes">
                <div>
                    <span class="text-muted">
                        {{item.date | date: 'shortTime'}}, {{item.date | date: 'shortDate'}}
                    </span>
                    <span class="text-info ml-5">
                        {{item.user.name}}
                    </span>
                </div>
                <div>
                    {{item.period}} {{ item.periodType == 'hours' ? localizations.common.hour_dot : localizations.common.minute_dot }}
                </div>
            </li>
        </ul>
        <button class="btn btn-default btn-xs"
                *ngIf="!isEditWorkTime && task.status && task.status === 'ACTIVE'"
                (click)="addWorkTime()">
            {{ localizations.tasks.add_costs }}
        </button>
        <div class="bg-primary-50 p-20"
             *ngIf="isEditWorkTime">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group form-group-lg no-margin-bottom">
                        <label class="text-bold">
                            {{ localizations.common.duration }}
                        </label>
                        <input class="form-control"
                               type="number"
                               [(ngModel)]="newWorkTime.period">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-lg display-inline-block no-margin-bottom">
                        <label class="display-block text-bold">
                            {{ localizations.tasks.period_type }}
                        </label>
                        <div class="radio-inline">
                            <input id="hoursPeriod"
                                   class="radio"
                                   type="radio"
                                   [checked]="newWorkTime.type === 'hours'"
                                   (change)="newWorkTime.type = 'hours'">
                            <label for="hoursPeriod">
                                {{ localizations.common.hours }}
                            </label>
                        </div>
                        <div class="radio-inline">
                            <input id="minutesPeriod"
                                   class="radio"
                                   type="radio"
                                   [checked]="newWorkTime.type === 'minutes'"
                                   (change)="newWorkTime.type = 'minutes'">
                            <label for="minutesPeriod">
                                {{ localizations.common.minutes }}
                            </label>
                        </div>
                    </div>
                    <button class="btn btn-info ml-20"
                            type="button"
                            (click)="saveWorkTime()">
                        {{ localizations.common.apply }}
                    </button>
                </div>

            </div>
        </div>
    </fieldset>

    <!-- Комментарий -->
    <fieldset>
        <legend class="text-bold">
            {{ localizations.common.comments }}
        </legend>
        <ul class="list-feed mb-20"
            *ngIf="task.comments && task.comments.length > 0">
            <li *ngFor="let task of task.comments">
                <div>
                    <span class="text-muted">
                        {{task.date | date: 'shortTime'}}, {{task.date | date: 'shortDate'}}</span>
                    <span class="text-info ml-5">
                        {{task.user.name}}
                    </span>
                </div>
                <div>
                    {{task.comment}}
                </div>
            </li>
        </ul>
        <button class="btn btn-default btn-xs"
                *ngIf="!isEditComment && task.status && task.status === 'ACTIVE'"
                (click)="addComment()">
            {{ localizations.common.add_comment }}
        </button>
        <div class="bg-primary-50 p-20" *ngIf="isEditComment">
            <div class="form-group form-group-lg no-margin">
                <p class="mb-15 text-bold">
                    {{ localizations.common.comment }}:
                </p>
                <textarea id="comment"
                          class="form-control input-lg"
                          [(ngModel)]="newComment">
                </textarea>
                <button class="btn btn-info btn-xs mt-10 mb-15"
                        type="button"
                        (click)="saveComment()">
                    {{ localizations.common.apply }}
                </button>
            </div>
        </div>
    </fieldset>

    <ng-container *ngIf="entityParams">
        <button class="btn btn-primary mt-20"
                type="button"
                [routerLink]="entityParams.urlParts">
            {{ localizations.tasks.pass_to_in }} {{entityParams.shortName}}
        </button>
    </ng-container>
</div>

<!-- модальное окно для выбора параметров платежа -->
<ng-template #userInfoModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.tasks.inspector_info }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <user-card [userId]="getAssignee()._id"></user-card>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>
