import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonAppealDocumentService } from '../../common-appeal-document.service';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { EnvelopesService } from '../../../../../../common/services/envelopes.service';
import { Permission } from '../../../../../../common/services/permission';
import { AccessService } from '@evolenta/core';

@Component({
    selector: 'common-appeal-document-preview',
    templateUrl: 'common-appeal-document-preview.component.html',
})
export class CommonAppealDocumentPreviewComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public mode; // режим работы
    @Input() public allowEdit = true;

    @Output() public onActivateTab = new EventEmitter<any>(); // активация вкладки с данными для редактирования

    public data; // описательный объект обрабатываемого документа
    public allDocumentsData; // описательный объект всех документов дела

    public hasErrors = false;
    public permissions = Permission; // все полномочия системы

    public constructor(
        public documentService: CommonAppealDocumentService,
        public validateService: CommonAppealValidateService,
        public accessService: AccessService
    ) {}

    public ngOnInit() {
        this.data = this.documentService.data[this.document.guid];
        this.allDocumentsData = this.documentService.data;
    }

    public ngAfterViewInit() {
        this.updateErrors();
    }

    /**
     * Активация вкладки (переход в режим редактирования)
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        // Активация происходит только в случае, если доступно редактирование для дела, либо частичное редактирование для вкладки
        // либо вкладка "Файлы" и файлы есть
        // либо вкладка "Документы дела" и выбраны документы дела
        if (
            (this.allowEdit &&
                this.documentService.allowPartiallyEditTabs.indexOf(tab) !== -1 &&
                this.data.allowPartiallyEdit) ||
            this.data.allowEdit ||
            (((tab === 'files' && this.data.requestId) || (tab === 'common' && !this.data.requestId)) &&
                (this.data.queue.length > 0 || this.data.allowPartiallyEdit)) ||
            (tab === 'common' && this.data.envelope && this.data.envelope.appealDocuments.length > 0)
        ) {
            this.onActivateTab.emit(tab);
        }
    }

    /**
     * Получение параметров для документа-запроса
     * @param property - возвращаемое значение свойства
     * @returns {string}
     */
    public getEnvelopeStatusProperty(property) {
        const envelopeStatus = this.data.envelope.status ? this.data.envelope.status : 'created';
        return EnvelopesService.GetStatusProperty(envelopeStatus, property);
    }

    /**
     * КНМ наличия ошибок в документе
     */
    public updateErrors() {
        this.validateService.validateAppeal(this.validateService.processValidate);
        this.hasErrors = false; // !this.validateService.checkElementValid(this.document.guid, 'documents');
    }

    /**
     * Получение списка ошибок для карточки документа
     * @returns {string}
     */
    public getErrorsList() {
        return ''; // this.validateService.getElementErrorsList(this.document.guid, 'documents');
    }

    /**
     * КНМ наличия ошибок в услуге
     */
    public checkErrorsExist() {
        return false; // !this.validateService.checkElementValid(this.document.guid, 'documents');
    }
}
