import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { NotificationsPreviewComponent } from './notifications/components/notifications-preview/notifications-preview.component';
import { NotificationsPageService } from './notifications/notifications-page.service';
import { NotificationsResolve } from './notifications/notifications.resolve';
import { InlineSVGModule } from 'ng-inline-svg';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import { NotificationsSettingsResolve } from './notifications-settings/notifications-settings.resolve';

@NgModule({
    imports: [
        SharedComponentsModule,
        InlineSVGModule,
    ],
    declarations: [
        NotificationsPreviewComponent,
        NotificationsSettingsComponent,
    ],
    entryComponents: [
        NotificationsPreviewComponent,
    ],
    providers: [
        NotificationsPageService,
        NotificationsResolve,
        NotificationsSettingsResolve,
    ],
})
export class NotificationsModule {
}
