<div class="pt-20 pr-20 pl-20" [ngClass]="{'pb-20': !schema}">
    <div class="row">
        <div class="col-md-2">
            <div class="form-group form-group-lg">
                <label>Серия</label>
                <input class="form-control" [(ngModel)]="data.seria" type="text" [readonly]="!canEdit">
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group form-group-lg">
                <label>Номер</label>
                <input class="form-control" [(ngModel)]="data.number" type="text" [readonly]="!canEdit">
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group form-group-lg">
                <label>Дата выдачи</label>
                <evolenta-datepicker [(ngModel)]="data.dateIssue" [disabled]="!canEdit" [large]="true" ></evolenta-datepicker>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group form-group-lg">
                <label>Выдавший орган</label>
                <input class="form-control" [(ngModel)]="data.issuer" type="text" [readonly]="!canEdit">
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="data.xsd">
    <dynamic-form
        [xsd]="data.xsd"
        [edit]="false"
        tabsMode="none"
        [model]="data.additionalData"
        [saveInMainObject]="true">
    </dynamic-form>
</ng-container>
