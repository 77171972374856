import { Component, Input } from '@angular/core';

@Component({
    selector: 'entity-process-elements',
    templateUrl: 'entity-process-elements.component.html',
})
export class EntityProcessElementsComponent {
    @Input() public data;
    @Input() public mode = 'all'; // completed, active

    public activeEvent;

    public elements = [];

    public getElements() {
        if (this.mode === 'completed') {
            return this.data.elements.filter((item) => item.status === 'COMPLETED');
        } else if (this.mode === 'active') {
            return this.data.elements.filter((item) => item.status === 'ACTIVE');
        } else {
            return this.data.elements;
        }
    }
}
