import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UtilityService } from './utility.service';
import defaultTo from 'lodash-es/defaultTo';
import get from 'lodash-es/get';
import * as moment from 'moment';

@Injectable()
export class ValidatorsService {
    public constructor(private utilityService: UtilityService) {}

    /**
     * Проверяет просрочненный ли паспорт
     *
     * @param data доп. информация для полноценной валидации поля
     */
    public passport(data: any): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const documentIssueDate = control.value;

            if (documentIssueDate) {
                // если указана дата рождения и выбран ДУЛ - паспорт РФ
                // TODO это для старых данных, либо писать скрипт в БД get(data, 'documentType[0].id') === '29'
                if (
                    get(data, 'birthday') &&
                    (get(data, 'documentType.code') === '29' || get(data, 'documentType[0].id') === '29')
                ) {
                    const age = moment().diff(data.birthday, 'years');

                    if (
                        age >= 20 &&
                        age < 45 &&
                        moment(documentIssueDate).isBefore(moment(data.birthday).add(20, 'y'))
                    ) {
                        return { expiredUponReachingAgeOfTwenty: true };
                    } else if (age >= 45 && moment(documentIssueDate) < moment(data.birthday).add(45, 'y')) {
                        return { expiredUponReachingAgeOfFortyFive: true };
                    } else {
                        return null;
                    }
                }
            }

            return null;
        };
    }

    public inn(): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const error = this.utilityService.validateInn(control.value);

            return error ? { inn: true } : null;
        };
    }

    public snils(): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const error = this.utilityService.validateSnils(control.value);

            return error ? { snils: true } : null;
        };
    }

    public ipOGRN(): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const error = this.utilityService.validateIpOgrn(control.value);

            return error ? { ipOGRN: true } : null;
        };
    }

    /**
     * Валидатор на minDate, можно передать как значение, так и сразу валидировать по полю из formGroup
     *
     * @param object {value - значение, fieldName - названия контрола, comparison - сравнение}
     */
    public minDate(object: { value?: any; fieldName?: string; comparison?: string }): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const comparison = defaultTo(get(object, 'comparison', null), 'less');
            const currentDate = moment(control.value).format('MM/DD/YYYY');

            if (get(object, 'value')) {
                const incomingDate = moment(object.value).format('MM/DD/YYYY');

                return this.getErrorAfterComparison(currentDate, incomingDate, 'minDate', comparison);
            }

            if (get(object, 'fieldName')) {
                const form = control.parent ? this.getParent(control) : null;

                if (form) {
                    const incomingDate = moment(get(form.get(object.fieldName), 'value', null)).format('MM/DD/YYYY');

                    return this.getErrorAfterComparison(currentDate, incomingDate, 'minDate', comparison);
                }
            }

            return null;
        };
    }

    /**
     * Валидатор на maxDate, можно передать как значение, так и сразу валидировать по полю из formGroup
     *
     * @param object {value - значение, fieldName - названия контрола, comparison - сравнение}
     */
    public maxDate(object: { value?: any; fieldName?: string; comparison?: string }): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const comparison = defaultTo(get(object, 'comparison', null), 'less');
            const currentDate = moment(control.value).format('MM/DD/YYYY');

            if (get(object, 'value')) {
                const incomingDate = moment(object.value).format('MM/DD/YYYY');

                return this.getErrorAfterComparison(currentDate, incomingDate, 'maxDate', comparison);
            }

            if (get(object, 'fieldName')) {
                const form = control.parent ? this.getParent(control) : null;

                if (form) {
                    const incomingDate = moment(get(form.get(object.fieldName), 'value', null)).format('MM/DD/YYYY');

                    return this.getErrorAfterComparison(currentDate, incomingDate, 'maxDate', comparison);
                }
            }

            return null;
        };
    }

    /**
     * Проверяет если id в записях "Вида государственного контроля (надзора)" из регламента
     */
    public validateKNDKindId(): ValidatorFn | null {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!get(control, 'value._id')) {
                return {
                    noId: true,
                };
            }

            return null;
        };
    }

    private getParent(control: AbstractControl): AbstractControl {
        return get(control, 'parent') ? this.getParent(control.parent) : control;
    }

    private getErrorAfterComparison(
        currentDate: any,
        incomingDate: any,
        typeValidator: string,
        comparison: string
    ): ValidationErrors | null {
        switch (comparison) {
            case 'less':
                if (typeValidator === 'minDate' && moment(new Date(currentDate)).isBefore(new Date(incomingDate))) {
                    return { minDate: true };
                }

                if (typeValidator === 'maxDate' && moment(new Date(currentDate)).isAfter(new Date(incomingDate))) {
                    return { maxDate: true };
                }

                return null;
            case 'lessEquals':
                if (
                    typeValidator === 'minDate' &&
                    moment(new Date(currentDate)).isSameOrBefore(new Date(incomingDate))
                ) {
                    return { minDate: true };
                }

                if (
                    typeValidator === 'maxDate' &&
                    moment(new Date(currentDate)).isSameOrAfter(new Date(incomingDate))
                ) {
                    return { maxDate: true };
                }

                return null;
            default:
                return null;
        }
    }
}
