import { Component, Input, OnInit } from '@angular/core';
import { StorageService, ToasterService, UserMessagesService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealService } from '../../../../appeal.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-subservice-common',
    templateUrl: './appeal-subservice-common.component.html',
    styles: [
        '.panel-heading .btn-sxs { margin-top: 4px; }',
        '.right-10 { right: 10px; }',
    ],
})
export class AppealSubserviceCommonComponent implements OnInit {
    @Input() public appealSubservice; // Редактируемая услуга дела
    @Input() public subservice; // Описательная услуга для услуги дела

    public isProcessSelectReason = false;
    public isProcessSelectInspectors = false;
    public isProcessSelectExpert = false;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public inspectors = [];

    public selectedInspector;

    public baseSearchForInspectors = [
        {
            field: 'sprOrganizations.id',
            operator: 'eq',
            value: this.currentOrganization.id,
        },
        {
            field: 'linkRolesMnemonics.roleMnemonic',
            operator: 'eq',
            value: 'inspector',
        },
    ];

    public organizationsBaseSearch = [{ field: 'isAuthorized', operator: 'eq', value: true }];

    public editedExpert: any = {};

    public currentUser = this.storage.getItem('user');

    public organizationExecutor;

    public constructor(
        public validateService: AppealValidateService,
        public appealService: AppealService,
        private storage: StorageService,
        private userMessagesService: UserMessagesService,
        private toaster: ToasterService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (this.appealService.appeal.organizationExecutor) {
            this.organizationExecutor = _.cloneDeep(this.appealService.appeal.organizationExecutor);
        }
    }

    public addReason(reason) {
        if (!this.appealSubservice.reasons) {
            this.appealSubservice.reasons = [];
        }
        this.appealSubservice.reasons.push({code: reason.code, name: reason.name});
        this.isProcessSelectReason = false;
    }

    public deleteReason(reason) {
        const findIndex = this.appealSubservice.reasons.findIndex(item => item.code === reason.code);
        if (findIndex !== -1) {
            this.appealSubservice.reasons.splice(findIndex, 1);
        }
    }

    public selectInspector(inspector) {
        if (!this.appealService.appeal.inspectors) {
            this.appealService.appeal.inspectors = [];
        }
        const find = this.appealService.appeal.inspectors.find(item => item.id === inspector._id);
        if (find) {
            this.toaster.error('Инспектор ' + inspector.name + ' уже включен в состав контролирующих инспекторов');
            this.selectedInspector = null;
        } else {
            this.appealService.appeal.inspectors.push({
                id: inspector._id,
                name: inspector.name,
                login: inspector.login,
                position: inspector.position,
            });
            this.isProcessSelectInspectors = false;
            this.selectedInspector = null;
            this.sendMessage(inspector);
        }
    }

    public sendMessage(user) {
        if (this.currentUser.login !== user.login) {
            const params = [
                {
                    name: 'userLogin',
                    value: user.login,
                },
                {
                    name: 'message',
                    value: '',
                },
                {
                    name: 'appealId',
                    value: this.appealService.appeal._id,
                },
                {
                    name: 'appealNumber',
                    value: this.appealService.appeal.number,
                },
                {
                    name: 'appealSubservice',
                    value: this.appealService.appeal.subservice.shortTitle,
                },
            ];
            this.userMessagesService.sendMessage('linkProcess', 'sendToUser', params);
        }
    }

    public deleteInspector(inspector) {
        const findIndex = this.appealService.appeal.inspectors.find(item => item.id === inspector.id);
        this.appealService.appeal.inspectors.splice(findIndex, 1);
    }

    public editExpert(editedExpert = null) {
        if (editedExpert) {
            this.editedExpert = _.cloneDeep(editedExpert);
        } else {
            this.editedExpert = { guid: CommonUtilities.GenerateGuid() };
        }
        this.isProcessSelectExpert = true;
    }

    public applyExpert() {
        if (!this.appealService.appeal.experts) {
            this.appealService.appeal.experts = [];
        }
        const findIndex = this.appealService.appeal.experts.findIndex(item => item.guid === this.editedExpert.guid);
        if (findIndex === -1) {
            this.appealService.appeal.experts.push(_.cloneDeep(this.editedExpert));
        } else {
            this.appealService.appeal.experts[findIndex] = _.cloneDeep(this.editedExpert);
        }
        this.editedExpert = {};
        this.isProcessSelectExpert = false;
    }

    public deleteExpert(expert) {
        const findIndex = this.appealService.appeal.experts.findIndex(item => item.guid === expert.guid);
        this.appealService.appeal.experts.splice(findIndex, 1);
    }

    public toggleKndKinds(kndKind) {
        const findIndex = this.appealSubservice.kndInfo.kndKinds.findIndex(item => item.code === kndKind.code);
        if (findIndex !== -1) {
            this.appealSubservice.kndInfo.kndKinds.splice(findIndex, 1);
        } else {
            this.appealSubservice.kndInfo.kndKinds.push(kndKind);
        }
    }

    public checkUsedKndKind(kndKind) {
        return !!this.appealSubservice.kndInfo.kndKinds.find(item => item.code === kndKind.code);
    }

    public clearOrganization() {
        this.appealService.appeal.organizationExecutor = null;
    }

    public selectOrganization(org) {
        this.appealService.appeal.organizationExecutor = {id: org._id, name: org.name, shortName: org.shortName};
    }

}
