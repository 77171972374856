import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { RestService } from '@evolenta/core';
import { XsdJsonService } from '../../../../../../common/services/xsd-json.service';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { CommonAppealSubservicesService } from '../../../../services/common-appeal-subservices.service';

@Component({
    selector: 'common-appeal-subservice-additional-data',
    templateUrl: './common-appeal-subservice-additional-data.component.html',
})
export class CommonAppealSubserviceAdditionalDataComponent implements AfterViewInit, OnChanges {
    @Input() public appealSubservice; // Редактируемая услуга дела
    @Input() public subservice; // Описательная услуга
    @Input() public tab; // Обрабатываемая вкладка с дополнительными данными

    @Output() public onAfterSave = new EventEmitter<object>();

    public data; // Описательный объект текущей услуги
    public schema; // XSD-схема услуги
    public variantSchema; // XSD-схема варианта услуги

    public afterSaveNextTab; // Код вкладки, на которую нужно перейти после сохранения данных
    public afterSaveApply; // Флаг необходимости сохранения данных услуги в родительском компоненте (кнопка "Применить")
    public afterSaveCheckChanges; // Флаг необходимости КНМ изменения данных в родительском компоненте (при нажатии кнопки "Назад к списку")

    public processSavingData; // Дата для КНМ завершения всех этапов сохранения и xsd по услуги и xsd по варианту

    @ViewChild('saveBtn', { static: false }) public saveBtn;
    @ViewChild('saveVariantBtn', { static: false }) public saveVariantBtn;

    public constructor(
        public validateService: CommonAppealValidateService,
        private xsdJsonService: XsdJsonService,
        private rest: RestService,
        private appealSubservicesService: CommonAppealSubservicesService
    ) {}

    public ngOnChanges(changes) {
        if (changes.tab) {
            this.data = this.appealSubservicesService.data[this.appealSubservice.guid];
            this.initData();
        }
    }

    public initData() {
        this.schema = null;

        setTimeout(() => {
            if (this.tab) {
                if (this.subservice.xsdTabs && this.subservice.xsdTabs.length > 0) {
                    const currentTab = this.subservice.xsdTabs.find((item) => item.tabCode === this.tab.code);

                    if (!this.data.tabsSchema) {
                        this.data.tabsSchema = {};
                    }
                    if (!this.data.tabsSchema[currentTab.tabCode]) {
                        this.rest.find('xsd', currentTab.xsd).then((xsd) => {
                            this.schema = this.xsdJsonService.getSchemaAndFormDescription(xsd['content'], false);
                            this.data.tabsSchema[currentTab.tabCode] = this.schema;
                            if (this.data.xsdData && this.data.xsdData[currentTab.tabCode]) {
                                this.schema.value = this.data.xsdData[currentTab.tabCode];
                            }
                        });
                    } else {
                        this.schema = this.data.tabsSchema[currentTab.tabCode];
                        if (this.data.xsdData && this.data.xsdData[currentTab.tabCode]) {
                            this.schema.value = this.data.xsdData[currentTab.tabCode];
                        }
                    }
                }
            } else {
                // Доп.данные по варианту услуги
                if (this.appealSubservice.variant && this.appealSubservice.variantXsd && !this.data.variantSchema) {
                    this.rest.find('xsd', this.appealSubservice.variantXsd).then((xsd) => {
                        this.variantSchema = this.xsdJsonService.getSchemaAndFormDescription(xsd['content'], false);
                        this.data.variantSchema = this.variantSchema;
                        if (this.data.variantXsdData) {
                            this.variantSchema.value = this.data.variantXsdData;
                        }
                    });
                } else if (this.data.variantSchema) {
                    this.variantSchema = this.data.variantSchema;
                    if (this.data.variantXsdData) {
                        this.variantSchema.value = this.data.variantXsdData;
                    }
                }
            }
        }, 10);
    }

    public ngAfterViewInit() {
        // В случае, если осуществляется КНМ дела, эмулируем нажатие кнопки сохранить, чтобы подсветились ошибки
        if (this.validateService.processValidate) {
            this.save();
        }
    }

    /**
     * Инициализация переменных процесса сохранения
     */
    public initProcessSavingData() {
        this.processSavingData = {
            xsdData: true,
            variantXsdData: true,
        };
        // Если есть XSD по услуге
        if (this.schema) {
            this.processSavingData.xsdData = false;
        }
        // Если есть XSD по варианту услуги
        if (this.variantSchema) {
            this.processSavingData.variantXsdData = false;
        }
    }

    /**
     * Запуск процедуры сохранения данных из родительского компонента (отдельное сохранение)
     * @param tab - код вкладки, на которую нужно перейти после сохранения (при клике на другую вкладку)
     * @param apply - после сохранения переход на метод apply в родительском компоненте (нажатие кнопки "Применить")
     * @param check - после сохранения переход на метод checkChanges в родительском компоненте (нажатие кнопки "Назад к списку")
     */
    public save(tab = null, apply = false, check = false) {
        // Инициализация данных процесса сохранения
        this.initProcessSavingData();

        this.afterSaveNextTab = tab ? tab : null; // после сохранения переход на другую вкладку
        this.afterSaveApply = apply ? apply : false; // после сохранения сохранение данных (кнопка "Применить")
        this.afterSaveCheckChanges = check ? check : false; // после сохранения КНМ услуги на изменение (кнопка "Назад к списку")

        if (this.saveBtn || this.saveVariantBtn) {
            // Небольшая задержка для выставления / снятия флага необходимости валидации
            if (this.saveBtn) {
                setTimeout(() => {
                    this.saveBtn.nativeElement.click();
                }, 100);
            } else {
                this.complete({ values: null, errors: [] }, 'xsdData');
            }
            if (this.saveVariantBtn) {
                setTimeout(() => {
                    this.saveVariantBtn.nativeElement.click();
                }, 100);
            } else {
                this.complete({ values: null, errors: [] }, 'variantXsdData');
            }
        }
    }

    /**
     * Получение данных с JSON-формы
     * @param data - json-объект с данными формы
     * @param type - тип, данные по XSD услуги (xsdData) или по XSD варианта услуги (variantXsdData)
     */
    public complete(data, type) {
        if (data.errors && data.errors.length > 0) {
            console.log('ERRORS', data.errors);
        }
        if (data.values) {
            // console.log('XSD DATA VALUES', data.values);
            if (!this.appealSubservice[type]) {
                this.appealSubservice[type] = {};
            }
            this.appealSubservice[type][this.tab.code] = data.values; // значения формы
            if (!this.data[type]) {
                this.data[type] = {};
            }
            this.data[type][this.tab.code] = data.values; // сохранение данных в сервисе
            const validProperty = type + 'Valid';
            if (!this.data[validProperty]) {
                this.data[validProperty] = {};
            }
            this.data[validProperty][this.tab.code] = data.errors.length === 0; // сохранение данных об ошибках в сервисе
            if (!this.appealSubservice[validProperty]) {
                this.appealSubservice[validProperty] = {};
            }
            this.appealSubservice[validProperty][this.tab.code] = data.errors.length === 0; // статус валидации формы
        }

        this.processSavingData[type] = true; // статус завершения обработки определенного типа XSD-формы

        // Если все итерации сохранения пройдены
        if (this.processSavingData.xsdData && this.processSavingData.variantXsdData) {
            // Корректировка параметров валидации
            // this.validateService.validateSubserviceAdditionalData(this.appealSubservice);

            // Передача управления в родительский компонент
            this.onAfterSave.emit({
                tab: this.afterSaveNextTab,
                apply: this.afterSaveApply,
                checkChanges: this.afterSaveCheckChanges,
            });
        }
    }
}
