<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-primary btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            (click)="saveByRoute()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-5">
        <h4 class="page-title">
            {{ localizations['global-calendar'].name }}
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <calendar-menu  [active]="activeTab"
                    (onChangeTab)="activateTab($event)">
    ></calendar-menu>
     <div class="panel no-margin no-border-top no-border-left no-border-right panel-main wide">
        <ng-container *ngIf="activeTab === 'calendar'">
            <div class="panel-body no-padding">
                <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-radius no-margin no-padding">
                    <div  class="m-20">
                        <div class="row info-bar">
                            <div class="row col-md-6">
                                <div class="form-group form-group-lg col-md-9 mr-10">
                                    <ng-select
                                        class="text-bold"
                                        [allowClear]="false"
                                        [items]="calendarOptions"
                                        [multiple]="false"
                                        [placeholder]="localizations['global-calendar'].select_year"
                                        (selected)="selectYearWidget($event.id)"
                                        [active]="getSelectedWidget()"
                                        required>
                                    </ng-select>
                                </div>
                                <button class="btn btn-lg btn-primary" (click)="openCalendarModal()">
                                    <i class="icon-plus-circle2"></i>
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-primary btn-lg mr-5 pull-right" (click)="setDefaultWeekends()">
                                    {{ localizations['global-calendar'].default_weekends }}
                                </button>
                            </div>
                        </div>
                        <widget-calendar [year]="activeCalendarTab.year"
                                         [days]="activeCalendarTab.days"
                                         *ngIf="isVisibleCalendarWidget">
                        </widget-calendar>
                    </div>
                </evolenta-scrollbar>
            </div>
        </ng-container>

        <ng-container *ngIf="activeTab === 'holidays'">
            <holidays-settings
                [holidaysSrc]="holidays"
                (onHolidaysChanged)="holidaysChanged($event)"
            ></holidays-settings>
        </ng-container>
    </div>
</div>

<ng-template #createCalendarModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations['global-calendar'].new_calendar }}
        </h4>
        <button type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group form-group-lg">
            <label class="text-bold">{{ localizations['global-calendar'].select_year }}</label>
            <ng-select
                [allowClear]="false"
                [items]="calendarForm.options"
                [multiple]="false"
                [placeholder]="localizations['global-calendar'].select_year"
                (selected)="selectYearModal($event)"
                [active]="getSelectedForCreating()"
                required>
            </ng-select>
        </div>

        <div class="form-group">
            <input id="setDefault"
                   class="checkbox"
                   type="checkbox"
                   name="setDefault"
                   [(ngModel)]="calendarForm.setDefault">
            <label class="control-label text-bold"
                   for="setDefault">
                {{ localizations['global-calendar'].set_default_weekends }}
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="createCalendar()">{{ localizations.common.create }}</button>
        <button class="btn btn-primary-overline" (click)="modalRef.hide()">{{ localizations.common.cancel }}</button>
    </div>
</ng-template>
