import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionService } from '@evolenta/core';
import { NsiService } from './nsi.service';
import { NsiGroupDictionariesComponent } from './dictionary-group/nsi-group-dictionaries.component';
import { NsiEditDictionaryComponent } from './dictionary-edit/nsi-edit-dictionary.component';
import { NsiDictionaryComponent } from './dictionary/nsi-dictionary.component';
import { NsiDictionaryElementComponent } from './dictionary-element/nsi-dictionary-element.component';
import { NsiResolve } from './nsi.resolve';

@Component({
    selector: 'nsi-default',
    template: '',
})
export class NsiDefaultComponent implements OnInit {
    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private nsiService: NsiService,
        private selectionService: SelectionService,
    ) {
    }

    public ngOnInit(): void {
        const routes = this.router.config;

        this.correctNsiRoutesFromConfig(routes);

        if (this.selectionService.isProcessSelect) {
            this.router.navigate(this.selectionService.transferUrl);
        } else {
            this.route.url.subscribe(urls => {
                const urlItems = ['nsi'].concat(urls.map(item => item.path));

                this.router.navigate(urlItems);
            });
        }
    }

    public correctNsiRoutesFromConfig(routes): void {
        const findIndex = routes.findIndex(item => item.path === 'nsi');

        if (routes[findIndex].children.length === 4) {
            let newRoutes = [];

            this.nsiService.structure.forEach(group => {
                const partRoutes = [
                    {
                        path: group.code,
                        data: {
                            name: group.name,
                            img: 'main-menu/' + group.code,
                            breadcrumb: group.name,
                        },
                        component: NsiGroupDictionariesComponent,
                    },
                    {
                        path: group.code + '/create',
                        component: NsiEditDictionaryComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Создание справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                    {
                        path: group.code + '/edit/:dictionary',
                        component: NsiEditDictionaryComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Редактирование справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                    {
                        path: group.code + '/:dictionary',
                        component: NsiDictionaryComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Элементы справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                    {
                        path: group.code + '/:dictionary/create',
                        component: NsiDictionaryElementComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Создание элемента справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                    {
                        path: group.code + '/:dictionary/create/:parentId',
                        component: NsiDictionaryElementComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Создание справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                    {
                        path: group.code + '/:dictionary/edit/:elementId',
                        component: NsiDictionaryElementComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Редактирование элемента справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                    {
                        path: group.code + '/:dictionary/edit/:elementId/:versionId',
                        component: NsiDictionaryElementComponent,
                        data: {
                            name: 'Справочник',
                            breadcrumb: 'Версия элемента справочника',
                        },
                        resolve: {
                            resolves: NsiResolve,
                        },
                    },
                ];

                newRoutes = newRoutes.concat(partRoutes);
            });

            routes[findIndex].children = newRoutes.concat(routes[findIndex].children);
            this.router.resetConfig(routes);
        }
    }
}
