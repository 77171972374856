import { Component, Input, OnInit } from '@angular/core';
import { PrintUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-violation-statistic',
    templateUrl: 'appeal-entity-violation-statistic.component.html',
})
export class AppealEntityViolationStatisticComponent implements OnInit {
    @Input() public entity;
    @Input() public appeal;
    @Input() public property;
    @Input() public large;
    @Input() public small;

    public objectList = [];
    public subjectList = [];

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    public ngOnInit() {
        if (this.property && !this.entity[this.property]) {
            this.entity[this.property] = this.appealEntitiesService.findEntityAll(this.appeal, 'regulation');
        }
        if (this.appeal.objects && this.appeal.objects.length > 0) {
            this.getAppealSubjects();
            this.getAppealObjects();
        }
    }

    /**
     * Возврат адреса в виде строки
     * @param objectGuid
     * @returns {string}
     */
    public getAddress(objectGuid) {
        const object = this.getObject(objectGuid);
        if (object.address) {
            return PrintUtilities.PrintAddressAsLine(object.address, true);
        }
        return '';
    }
    /**
     * Возврат данных субъекта
     * @param subjectGuid
     */
    public getSubject(subjectGuid) {
        const findIndex = this.subjectList.findIndex((item) => item.guid === subjectGuid);
        if (findIndex !== -1) {
            return this.subjectList[findIndex];
        }
    }

    /**
     * Возврат данных объекта
     * @param objectGuid
     */
    public getObject(objectGuid) {
        const findIndex = this.objectList.findIndex((item) => item.guid === objectGuid);
        if (findIndex !== -1) {
            return this.objectList[findIndex];
        }
    }

    /**
     * Получение списка субъектов рассматриваемой проверки
     */
    public getAppealSubjects() {
        this.subjectList = this.appeal.objects.map((item) => {
            return {
                guid: item.guid,
                header: item.header,
                shortHeader: item.shortHeader,
            };
        });
    }

    /**
     * Получение списка объектов рассматриваемой проверки
     */
    public getAppealObjects() {
        this.appeal.objects.forEach((subject) => {
            if (subject.objects && subject.objects.length) {
                subject.objects.forEach((object) => {
                    this.objectList.push(object);
                });
            }
        });
    }
}
