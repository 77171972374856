import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'treeSelectFilter',
    pure: false,
})
@Injectable()
export class TreeSelectFilterPipe implements PipeTransform {
    public transform(elements, limits): any {
        if (!elements || !limits || limits.length === 0) {
            return elements;
        }
        return elements.filter((item) => limits.indexOf(item.guid) !== -1);
    }
}
