<label>
    {{title}}
    <span class="anchor text-info text-size-small ml-5" (click)="addItem()" *ngIf="!disabled && entity[property] && entity[property].length > 0">добавить</span>
</label>
<ng-container *ngIf="entity[property]">
    <div class="row" *ngFor="let item of entity[property]; index as idx; last as lst">
        <div class="col-md-6">
            <div class="form-group" [ngClass]="{'input-group-lg': large, 'input-group-xs': small, 'no-margin': lst}">
                <input type="text" [(ngModel)]="item.name" class="form-control input-xs" placeholder="Фамилия Имя Отчество" [disabled]="disabled">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group input-group" [ngClass]="{'input-group-lg': large, 'input-group-xs': small, 'no-margin': lst}">
                <input type="text" [(ngModel)]="item.position" class="form-control input-xs" placeholder="Должность" [disabled]="disabled">
                <div class="input-group-btn">
                    <button class="btn btn-default" type="button" (click)="deleteItem(item)" [disabled]="disabled"><i class="icon-trash"></i></button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="alert alert-info no-margin-bottom no-border no-border-radius p-10" *ngIf="!entity[property] || entity[property].length == 0">
    {{title}} не добавлены <a class="text-info anchor text-size-small" (click)="addItem()">добавить</a></div>
