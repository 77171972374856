import { NgModule } from '@angular/core';
import { CalendarComponent } from './calendar/calendar.component';
import { GlobalCalendarComponent } from './global-calendar.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { GlobalCalendarResolve } from './global-calendar.resolve';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';
import { CalendarMenuComponent } from './calendar-menu/calendar-menu.component';
import { HolidaysComponent } from './holidays/holidays.component';

@NgModule({
    imports: [SharedComponentsModule, BreadcrumbsModule],
    declarations: [CalendarComponent, GlobalCalendarComponent, CalendarMenuComponent, HolidaysComponent],
    providers: [GlobalCalendarResolve],
    exports: [CalendarComponent],
})
export class CalendarModule {}
