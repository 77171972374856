import { NgModule } from '@angular/core';
import { KndKindsModule } from '../../modules/knd-kinds/knd-kinds.module';
import { SubjectsModule } from '../../modules/subjects/subjects.module';
import { ObjectsModule } from '../../modules/objects/objects.module';
import { SelectingRouting } from './selecting.routing';

@NgModule({
    imports: [SelectingRouting, KndKindsModule, SubjectsModule, ObjectsModule],
    declarations: [],
    providers: [],
    exports: [],
})
export class SelectingModule {}
