import { Injectable } from '@angular/core';
import { CommonAppealService } from './common-appeal.service';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable()
export class CommonAppealCheckListsService {
    public appeal; // обрабатываемое дело
    public subservice; // регламенты, по которым создана КНМ

    public objects = [];

    public constructor(
        private appealService: CommonAppealService,
    ) {
    }

    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.objects = [];
    }

    public init() {
    }

    public preparingCheckLists() {
        const checkLists = cloneDeep(this.appeal.checkLists);
        checkLists.forEach(checkList => {
            if (checkList.subjectGuid) {
                const findSubject = this.appeal.subjects.find(item => item.guid === checkList.subjectGuid);
                if (findSubject) {
                    checkList.subject = {guid: findSubject.guid, auid: findSubject.auid, shortHeader: findSubject.shortHeader};
                }
            }
            if (checkList.objectGuid) {
                const findObject = this.appeal.objects.find(item => item && item.guid === checkList.objectGuid);
                if (findObject) {
                    checkList.object = {guid: findObject.guid, auid: findObject.auid, name: findObject.name, address: findObject.address};
                }
            }
        });

        return checkLists;
    }

    public prepareObjects() {
        const objects = this.appeal.objects.map(item => item.objects);
        let allObjects = [];
        objects.forEach(item => {
            allObjects = allObjects.concat(item);
        });

        return allObjects;
    }

    /**
     * Подготовка набора требований для каждого объекта в КНМ
     */
    public processingRequirementsForObject() {
        if (this.appeal.checkLists) {
            this.objects.forEach(object => {
                const find = this.appeal.checkLists.find(item => item.objectId === object.id);
                if (find) {
                    object.requirements = cloneDeep(find.questions);
                }
            });
        }
    }

    public processingRequirementAction(processingCheckList, processingQuestion) {
        const checkList = this.appealService.appeal.checkLists.find(item => item.guid === processingCheckList.guid);
        const question = checkList.questions.find(item => item.auid === processingQuestion.auid);
        if (!question.result) {
            question.result = {};
        }
        question.result.answer = processingQuestion.result.answer;
        if (processingQuestion.result.files) {
            question.result.files = cloneDeep(processingQuestion.result.files);
        }
    }

    public saveCheckListProperty(processingCheckList, savedField, savedValue) {
        const checkList = this.appealService.appeal.checkLists.find(item => item.guid === processingCheckList.guid);
        checkList[savedField] = cloneDeep(savedValue);
    }

    public deleteCheckListQuestion(processingCheckList, processingQuestion) {
        const checkList = this.appealService.appeal.checkLists.find(item => item.guid === processingCheckList.guid);
        const questionIndex = checkList.questions.findIndex(item => item.auid === processingQuestion.guid);
        checkList.questions.splice(questionIndex, 1);
    }
}
