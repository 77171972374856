import { NgModule } from '@angular/core';
import { SectionModule } from '@components/section/section.module';
import { DndModule } from 'ngx-drag-drop';
import { InlineSVGModule } from 'ng-inline-svg';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { StandardsComponent } from './standards/standards.component';
import { StandardEditComponent } from './standards/components/edit/standard-edit.component';
import { StandardEditMenuComponent } from './standards/components/edit/components/menu/standard-edit-menu.component';
import { StandardEditContainerComponent } from './standards/components/edit/components/container/standard-edit-container.component';
import { StandardDescriptionEditComponent } from './standards/components/edit/components/description/standard-description-edit.component';
import { StandardsPrintFormsComponent } from './standards/components/edit/components/print-forms/standards-print-forms.component';
import { StandardBpmnComponent } from './standards/components/edit/components/bpmn/standard-bpmn.component';
import { StandardBpmnCardComponent } from './standards/components/edit/components/bpmn/bpmn-card/standard-bpmn-card.component';
import { StandardBpmnCardTasksComponent } from './standards/components/edit/components/bpmn/bpmn-card/bpmn-card-tasks/standard-bpmn-card-tasks.component';
import { StandardBpmnLinksComponent } from './standards/components/edit/components/bpmn/bpmn-links/standard-bpmn-links.component';
import { StandardPreviewComponent } from './standards/components/preview/standard-preview.component';
import { StandardObjectsComponent } from './standards/components/edit/components/objects/standard-objects.component';
import { StandardSubjectsComponent } from './standards/components/edit/components/subjects/standard-subjects.component';
import { StandardCheckListsComponent } from './standards/components/edit/components/check-lists/standard-check-lists.component';
import { StandardDocumentsComponent } from './standards/components/edit/components/documents/standard-documents.component';
import { StandardEntitiesComponent } from './standards/components/edit/components/entities/standard-entities.component';
import { StandardEntitiesCardComponent } from './standards/components/edit/components/entities/components/entities-card/standard-entities-card.component';
import { StandardEntitiesEditComponent } from './standards/components/edit/components/entities/components/entities-edit/standard-entities-edit.component';
import { StandardCategoryComponent } from './standards/components/edit/components/categories/standard-category.component';
import { StandardCategoryEditComponent } from './standards/components/edit/components/categories/standard-category-edit.component';
import { StandardRequestsComponent } from './standards/components/edit/components/requests/standard-requests.component';
import { StandardAdditionalDataComponent } from './standards/components/edit/components/additional-data/standard-additional-data.component';
import { StandardCheckListEditComponent } from './standards/components/edit/components/check-lists/check-list-edit/standard-check-list-edit.component';
import { StandardsResolve } from './standards/standards.resolve';
import { OrderByPipe } from '../../common/pipes/orderby.pipe';
import { CategoriesTreeService } from './standards/components/edit/components/categories/categories-tree.service';
import { ProcessesModule } from '../processes/processes.module';
import { PrintFormsModule } from '../print-forms/print-forms.module';
import { StandardUploadService } from './standards/services/standard-upload.service';
import { StandardService } from './standards/services/standard.service';
import { StandardSaveService } from './standards/services/standard-save.service';
import { RequestsModule } from '@evolenta/requests-module';

@NgModule({
    imports: [
        SharedComponentsModule,
        SectionModule,
        InlineSVGModule,
        ProcessesModule,
        DndModule,
        PrintFormsModule,
        RequestsModule,
    ],
    declarations: [
        StandardsComponent,
        StandardEditComponent,
        StandardEditMenuComponent,
        StandardEditContainerComponent,
        StandardDescriptionEditComponent,
        StandardsPrintFormsComponent,
        StandardBpmnComponent,
        StandardBpmnCardComponent,
        StandardBpmnCardTasksComponent,
        StandardBpmnLinksComponent,
        StandardPreviewComponent,
        StandardObjectsComponent,
        StandardSubjectsComponent,
        StandardCheckListsComponent,
        StandardDocumentsComponent,
        StandardEntitiesComponent,
        StandardEntitiesCardComponent,
        StandardEntitiesEditComponent,
        StandardEntitiesComponent,
        StandardCategoryComponent,
        StandardCategoryEditComponent,
        StandardEntitiesComponent,
        StandardRequestsComponent,
        StandardAdditionalDataComponent,
        StandardCheckListEditComponent,
    ],
    providers: [
        StandardsResolve,
        OrderByPipe,
        CategoriesTreeService,
        StandardService,
        StandardUploadService,
        StandardSaveService,
    ],
})
export class StandardsModule {}
