<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            <span *ngIf="subject.data.person && subject.specialTypeId != 'ulApplicant'">
                {{subject.data.person.lastName}} {{subject.data.person.firstName}} {{subject.data.person.middleName}}
            </span>
            <span *ngIf="subject.specialTypeId == 'ulApplicant'">
                {{subject.data.organization.shortName}}
            </span>
        </h6>
        <div class="heading-elements">
            <a (click)="edit()">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </a>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">

                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        {{ localizations.common.general_data }}
                        <a class="control-arrow">
                            <i [ngClass]="{'icon-arrow-down12': panels.common, 'icon-arrow-left12': !panels.common}"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.common}">
                        <ng-container *ngIf="subject.specialTypeId != 'ulApplicant'">
                            <div class="flex flex-row mb-10">
                                <div class="text-muted"></div>
                                <div></div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.subjects.date_of_birth }}:
                                </div>
                                <div *ngIf="subject.data.person.birthday">
                                    {{subject.data.person.birthday.formatted}}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.common.document }}:
                                </div>
                                <div>
                                    <span *ngIf="subject.data.person.documentType">
                                        {{subject.data.person.documentType[0].text}}
                                    </span>
                                    <span *ngIf="subject.data.person.documentSeries">
                                        {{ localizations.common.series }} {{subject.data.person.documentSeries}}
                                    </span>
                                    <span *ngIf="subject.data.person.documentNumber">
                                        {{ localizations.subjects.number_sign }} {{subject.data.person.documentNumber}}
                                    </span>
                                    <span *ngIf="subject.data.person.documentIssuer && subject.data.person.documentIssuer.name">
                                        {{ localizations.subjects.issued }} {{subject.data.person.documentIssuer.name}}
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-row mb-10"
                                 *ngIf="subject.data.person.registrationAddress">
                                <div class="text-muted">
                                    {{ localizations.subjects.registration_address }}:
                                </div>
                                <div>{{getAddress(subject.data.person.registrationAddress)}}</div>
                            </div>
                            <div class="flex flex-row mb-10"
                                 *ngIf="subject.data.person.factAddress">
                                <div class="text-muted">
                                    {{ localizations.subjects.residential_address }}:
                                </div>
                                <div>
                                    {{getAddress(subject.data.person.factAddress)}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="subject.specialTypeId == 'ulApplicant'">
                            <div class="flex flex-row mb-10"
                                 *ngIf="subject.data.organization.name">
                                <div class="text-muted">
                                    {{ localizations.subjects.full_name }}:
                                </div>
                                <div>
                                    {{subject.data.organization.name}}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.common.ogrn }} :
                                </div>
                                <div>
                                    {{ subject.data.organization.ogrn }}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.common.inn }}:
                                </div>
                                <div>
                                    {{subject.data.organization.inn}}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.subjects.kpp }}:
                                </div>
                                <div>
                                    {{subject.data.organization.kpp}}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10"
                                 *ngIf="subject.data.organization.okved && (subject.data.organization.okved.code || subject.data.organization.okved.name)">
                                <div class="text-muted">
                                    {{ localizations.subjects.activity_type }}:
                                </div>
                                <div>
                                    {{subject.data.organization.okved.code}} {{subject.data.organization.okved.name}}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10"
                                 *ngIf="subject.data.organization.registrationAddress">
                                <div class="text-muted">
                                    {{ localizations.subjects.yuridical_address }}:
                                </div>
                                <div>
                                    {{subject.data.organization.registrationAddress.postalCode}}
                                    {{getAddress(subject.data.organization.registrationAddress)}}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10"
                                 *ngIf="subject.data.person && (subject.data.person.lastName || subject.data.person.firstName || subject.data.person.middleName)">
                                <div class="text-muted">
                                    {{ localizations.subjects.representative_full_name }}:
                                </div>
                                <div>
                                    {{subject.data.person.lastName}} {{subject.data.person.firstName}} {{subject.data.person.middleName}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </fieldset>

                <!-- Дела -->
                <fieldset *ngIf="subjectAppeals.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.appeals = !panels.appeals">
                        {{ localizations.common.appeals }}
                        <a class="control-arrow">
                            <i [ngClass]="{'icon-arrow-down12': panels.appeals, 'icon-arrow-left12': !panels.appeals}"></i>
                        </a>
                    </legend>
                    <div class="collapse pb-10"
                         [ngClass]="{'in' : panels.appeals}">
                        <p *ngFor="let appeal of subjectAppeals">
                            <ng-container *ngIf="appeal.shortNumber">
                                <span class="badge bg-blue-300 badge-xs mr-10">
                                    {{ localizations.common.knm }}
                                </span>
                                <span class="text-semibold">
                                    #{{appeal.shortNumber}}
                                    {{ localizations.common.from }}
                                    {{appeal.dateRegister | date:'shortDate'}}
                                    {{ localizations.common.year_small }}
                                </span>
                                <br>
                            </ng-container>
                            {{appeal.subserviceTitle}}
                            <a class="anchor text-size-small"
                               [routerLink]="['/ais/appeals/edit', appeal.id]">
                                {{ localizations.common.proceed_small }}
                            </a>
                        </p>
                    </div>
                </fieldset>

                <!-- Прикрепленные объекты -->
                <fieldset *ngIf="objectsList.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.objects = !panels.objects">
                        {{ localizations.subjects.attached_objects }}
                        <a class="control-arrow">
                            <i [ngClass]="{'icon-arrow-down12': panels.objects, 'icon-arrow-left12': !panels.objects}"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.objects}">
                        <p *ngFor="let object of objectsList">
                            {{object.name}}
                            <a class="anchor text-size-small ml-5"
                               [routerLink]="[moduleBaseUrl, 'objects', 'all', object._id]">
                                {{ localizations.common.proceed_small }}
                            </a>
                        </p>
                    </div>
                </fieldset>

            </div>
        </evolenta-scrollbar>
    </div>
</div>
