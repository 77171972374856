import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouteService } from '../../../../../common/services/activated-route.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'badge-link',
    templateUrl: './badge-link.component.html',
})
export class BadgeLinkComponent implements OnInit {
    @Input() public object: any;
    @Input() public subKinds: any[];
    @Output() public onChangeSubKind = new EventEmitter<any>();

    public localizations;

    public constructor(
        public activatedRouteService: ActivatedRouteService,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {
        this.activatedRouteService.processRoute(this.route);
    }

    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'subjects',
                'appeals.subjects',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }
}
