import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import Papa from 'papaparse';

const linkToValuesCsvText = '(см. selectValues.csv)';

function parseItems(items, names, selectElements, csvType, mapCallback) {
    if (!items.map) {
        items.map = `${csvType}.${items.id}`;
    }

    if (items.options && items.type !== 'fieldset') {
        const displayName =
            items.type === 'select' ? `${items.options.displayName} ${linkToValuesCsvText}` : items.options.displayName;
        names.push(displayName);
        mapCallback(items);
    }

    if (items.options && items.type === 'select') {
        selectElements.push({
            name: items.options.name,
            displayName: items.options.displayName,
            selectElements: items.options.selectElements,
        });
    }

    if (items.content) {
        const map = items.type === 'fieldset' ? `${items.map}.${items.options.name}` : items.map;
        items.content.forEach((item) => parseItems({ map, ...item }, names, selectElements, csvType, mapCallback));
        return;
    }

    if (items.cols) {
        items.cols.forEach((item) =>
            parseItems({ map: items.map, ...item }, names, selectElements, csvType, mapCallback)
        );
        return;
    }

    if (Array.isArray(items)) {
        items.forEach((item) => parseItems(item, names, selectElements, csvType, mapCallback));
        return;
    }
}

function findKeyByValueAndSet(value, string, newString) {
    const keys = Object.keys(value);
    // tslint:disable-next-line:forin
    for (const key in keys) {
        const item = keys[key];
        if (typeof value[item] === 'object') {
            findKeyByValueAndSet(value[item], string, newString);
        } else {
            if (value[item] === string) {
                value[item] = newString;
                return;
            }
        }
    }
}

@Injectable()
export class CsvService {
    public names = [];
    public selectElements = [];
    public fieldMapping = {};
    public xsdIds = [];
    public csvOptions = {
        filename: 'output',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };
    public defaultFields = {
        Наименование: '',
        'Краткое наименование': '',
        Регион: '',
        Широта: '',
        Долгота: '',
        'Адрес объекта': '',
    };
    public csvExporterData = new ExportToCsv(this.csvOptions);
    public csvExporterSelects = new ExportToCsv({ ...this.csvOptions, filename: 'selectValues' });

    public clearXsdIds() {
        this.xsdIds = [];
    }

    public setXsdIds(idsObj) {
        this.xsdIds = idsObj;
    }

    public checkXsdType(xsdIds): string {
        if (xsdIds.kndKindId === null && xsdIds.unitId === null) {
            return 'objectsKno';
        }
        if (xsdIds.kndKindId !== null && xsdIds.unitId === null) {
            return 'federalAdditionalData';
        }
        if (xsdIds.kndKindId !== null && xsdIds.unitId !== null) {
            return 'regionalAdditionalData';
        }
    }

    public parseNamesFromXsdTab(tab, csvType) {
        parseItems(tab, this.names, this.selectElements, csvType, (item) => {
            const inner = item.map.split('.');
            if (this.fieldMapping[inner[0]] === undefined) {
                this.fieldMapping[inner[0]] = {};
            }

            if (inner[1]) {
                if (this.fieldMapping[inner[0]][inner[1]] === undefined) {
                    this.fieldMapping[inner[0]][inner[1]] = {};
                }

                this.fieldMapping[inner[0]][inner[1]][item.options.name] = item.options.displayName;
            } else {
                this.fieldMapping[inner[0]][item.options.name] = item.options.displayName;
            }
        });
    }

    public getDataFromCsv(file): Promise<string[]> {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: (e) => {
                    resolve(e.data);
                },
                error: (e) => {
                    reject(e);
                },
                download: false,
            });
        });
    }

    public mapFieldsByCsvData(csv) {
        const names = csv[0];
        const values = csv[1];
        const mapFields = { ...this.fieldMapping };
        for (let i = 0; i < names.length; i++) {
            findKeyByValueAndSet(mapFields, names[i], values[i]);
        }

        return mapFields;
    }

    public buildDefaultDataByCsvData(csv) {
        const values = csv[1];
        return {
            address: {
                fullAddress: values[5],
                unrecognizablePart: values[5],
            },
            name: values[0],
            shortName: values[1],
            latitude: values[3],
            longitude: values[4],
            region: {
                name: values[2],
            },
        };
    }

    public exportDataToCsv(data) {
        this.csvExporterData.generateCsv([{ ...this.defaultFields, ...data }]);
        const selectElementsNames = [];
        const selectElementsValues = [];
        for (const [key, value] of Object.entries(this.selectElements)) {
            selectElementsNames.push(value.displayName);
            selectElementsValues.push(value.selectElements);
        }
        if (selectElementsNames.length) {
            // @ts-ignore
            const maxValuesLength = Math.max.apply(
                null,
                selectElementsValues.map((e) => e.length)
            );
            selectElementsValues.forEach((e) => {
                if (e.length < maxValuesLength) {
                    for (let i = 0; i <= maxValuesLength - e.length; i++) {
                        e.push({ name: '', value: '' });
                    }
                }
            });

            const exportSelectElementsValues = [];
            for (let i = 0; i < maxValuesLength; i++) {
                const object = {};
                selectElementsNames.forEach((value, index) => {
                    if (selectElementsValues[index][i]) {
                        object[value] = selectElementsValues[index][i].name;
                    }
                });
                exportSelectElementsValues.push(object);
            }

            this.csvExporterSelects.generateCsv(exportSelectElementsValues);
        }
        this.selectElements = [];
    }

    public getNames() {
        return this.names;
    }

    public clearNames() {
        this.names = [];
    }

    public clearMapping() {
        this.fieldMapping = {};
    }
}
