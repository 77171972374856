import { Injectable } from '@angular/core';
import { HttpService } from '@evolenta/core';
import { ObjectUtilities } from '@evolenta/utilities';
import { AppealService } from '../../appeal.service';
import { Config } from '../../../../../common/services/config';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

export interface ValidateSigFileResult {
    groupsWithoutSigFile: string[];
    groupsWithOnlySigFile: string[];
}

@Injectable()
export class AppealProcessService {
    public tasksData = {};
    public keyIndicators = [];

    public statuses = [
        {
            code: 'ACTIVE',
            name: 'Активна',
            theme: 'primary',
        },
        {
            code: 'COMPLETED',
            name: 'Завершена',
            theme: 'success',
        },
    ];

    public camundaProcessId;
    public tasks; // список задач
    public activeTasks = [];
    public completedTasks = [];
    public activeServiceTasks = [];
    public completedServiceTasks = [];
    public processTimers = [];
    public eventGateways = [];
    public isProcessUpdateTasks = false; // Осуществляется процесс обновления списка задач
    public isAutoUpdateTasks = true; // Автоматическое обновление списка задач
    public activeTask;
    public isProcessActive = true;
    public hasAnyActiveProcess = false;

    public updateTasksInterval;

    private _processTasksPollingStopped = new Subject<void>();
    public processTasksPollTriggered = new Subject<void>();

    private _lastProcessTasksEntryStatusChangedAt: Date = null;
    public processTasksEntryStatusChanged = new Subject<void>();

    private _processTasksPollingSubscription = null;

    public constructor(
        private appealService: AppealService,
        private http: HttpService
    ) {}

    public set lastProcessTasksEntryStatusChangedAt(actualDate: Date) {
        const isAppealDataChangedOnServer =
            (this._lastProcessTasksEntryStatusChangedAt == null && actualDate != null) ||
            (this._lastProcessTasksEntryStatusChangedAt != null && actualDate == null) ||
            moment(actualDate).isAfter(this._lastProcessTasksEntryStatusChangedAt);

        this._lastProcessTasksEntryStatusChangedAt = actualDate;

        if (isAppealDataChangedOnServer) {
            this.processTasksEntryStatusChanged.next();
        }
    }

    public initProcessTasksEntryStatusChangeDetection(initialDate: Date): void {
        this._lastProcessTasksEntryStatusChangedAt = initialDate;
    }

    public startProcessTasksPolling(): void {
        this.stopProcessTasksPolling();

        this._processTasksPollingSubscription = interval(10000)
            .pipe(takeUntil(this._processTasksPollingStopped))
            .subscribe(() => {
                this.processTasksPollTriggered.next();
            });
    }

    public stopProcessTasksPolling(): void {
        this._processTasksPollingStopped.next();
        if (this._processTasksPollingSubscription) {
            this._processTasksPollingSubscription.unsubscribe();
        }
    }

    public getTaskDataInSubserviceProcess(task, subservice = null) {
        if (!subservice) {
            subservice = this.appealService.subservice;
        }

        let currentTaskInfo;

        if (subservice.camundaProcess && subservice.camundaProcess.userTasks) {
            currentTaskInfo = this._getCurrentTaskData(task, subservice.camundaProcess.userTasks);
        }

        if (!currentTaskInfo && subservice.camundaSubProcesses) {
            subservice.camundaSubProcesses.forEach((subProcess) => {
                if (!currentTaskInfo && subProcess.userTasks) {
                    currentTaskInfo = this._getCurrentTaskData(task, subProcess.userTasks);
                }
            });
        }

        return currentTaskInfo;
    }

    public getTaskData(task) {
        if (!this.tasksData[task.id]) {
            this.tasksData[task.id] = this.getTaskDataInSubserviceProcess(task);
        }

        return this.tasksData[task.id];
    }

    private _getCurrentTaskData(task, processTasks) {
        return processTasks.find((item) => item.code === task.taskDefinitionKey);
    }

    private _validateTaskDocumentGroups(taskData, subservice) {
        const groupsForCurrentTasks = this._filterRequiredGroupsForCurrentTasks(taskData, subservice);
        const groupsWithErrors = [];

        if (groupsForCurrentTasks.length > 0) {
            const appealDocuments = this.appealService.appeal.documents;
            groupsForCurrentTasks.forEach((group) => {
                const documentsGroupInAppeal = appealDocuments.filter((item) => item.groupGuid === group.guid);
                if (documentsGroupInAppeal.length === 0) {
                    groupsWithErrors.push(group.name);
                }
            });
        }

        return groupsWithErrors;
    }

    private _validateTaskDocumentGroupsSigFilePresence(taskData, subservice): ValidateSigFileResult {
        const groupsForCurrentTasks = this._filterRequiredGroupsForCurrentTasks(taskData, subservice);
        const documentGroupsSigFilePresence: ValidateSigFileResult = {
            groupsWithoutSigFile: [],
            groupsWithOnlySigFile: [],
        };

        if (groupsForCurrentTasks.length > 0) {
            const appealDocuments = this.appealService.appeal.documents;
            groupsForCurrentTasks.forEach((group) => {
                if (group.checkSignatures) {
                    const documentsGroupInAppeal = appealDocuments.find((item) => item.groupGuid === group.guid);
                    if (documentsGroupInAppeal) {
                        const sigFile =
                            documentsGroupInAppeal &&
                            documentsGroupInAppeal.files.find(
                                (file) => file.originalName.endsWith('.sig') || file.originalName.endsWith('.p7s')
                            );
                        if (sigFile) {
                            if (documentsGroupInAppeal.files.length === 1) {
                                // no files signed, just single orphan signature
                                documentGroupsSigFilePresence.groupsWithOnlySigFile.push(group.name);
                            }
                        } else {
                            documentGroupsSigFilePresence.groupsWithoutSigFile.push(group.name);
                        }
                    }
                }
            });
        }

        return documentGroupsSigFilePresence;
    }

    private _filterRequiredGroupsForCurrentTasks(taskData, subservice) {
        const groupsForCurrentTasks = [];
        if (taskData.documents) {
            taskData.documents.forEach((docGroup) => {
                if (docGroup.isRequired) {
                    const group = subservice.documentGroups.find((item) => item.code === docGroup.sperId);
                    if (group) {
                        groupsForCurrentTasks.push({
                            guid: group.guid,
                            name: group.name,
                            checkSignatures: group.checkSignatures,
                        });
                    }
                }
            });
        }

        return groupsForCurrentTasks;
    }

    public validateTaskKeyIndicators(taskData, subserviceKeyIndicators) {
        const keyIndicatorsWithErrors = [];
        const appealSubservice = this.appealService.appeal.subservice;

        if (taskData.keyIndicators && taskData.keyIndicators.length > 0) {
            taskData.keyIndicators.forEach((keyIndicator) => {
                if (keyIndicator.isRequired) {
                    const find = subserviceKeyIndicators.find((item) => item.sperId === keyIndicator.sperId);
                    if (find) {
                        const keyIndicatorCode = find.value;
                        let emptyKeyIndicator = false;
                        if (!appealSubservice.keyIndicators) {
                            emptyKeyIndicator = true;
                        } else {
                            const indicatorWithValue = appealSubservice.keyIndicators.find(
                                (item) => item.code === keyIndicatorCode
                            );
                            if (!indicatorWithValue || (indicatorWithValue && !indicatorWithValue.value)) {
                                emptyKeyIndicator = true;
                            }
                        }
                        if (emptyKeyIndicator) {
                            const findKeyIndicator = this.keyIndicators.find((item) => item.code === keyIndicatorCode);
                            keyIndicatorsWithErrors.push(findKeyIndicator.name);
                        }
                    }
                }
            });
        }

        return keyIndicatorsWithErrors;
    }

    private _validateTaskEntities(taskData, serviceEntities) {
        const entitiesWithErrors = [];
        const appealSubservice = this.appealService.appeal.subservice;

        if (!(taskData.entities && taskData.entities.length)) {
            return entitiesWithErrors;
        }

        taskData.entities.forEach((taskEntity) => {
            if (taskEntity.isRequired) {
                const find = serviceEntities.find((item) => item.sperId === taskEntity.sperId);
                if (find) {
                    const addedEntity = appealSubservice.entities.find((item) => item.type === find.code);
                    if (!addedEntity) {
                        entitiesWithErrors.push(find.name);
                    }
                }
            }
        });

        return entitiesWithErrors;
    }

    private _checkSedAwaited(taskData, groups) {
        const groupsWithErrors = [];

        if (taskData.params && Array.isArray(taskData.params.xsdTabs) && taskData.params.xsdTabs.length > 0) {
            const tab = taskData.params.xsdTabs.find((item) => item.tabCode === 'approveAndSignResult');
            const value = tab ? tab.value : undefined;

            if (value && value.isApprove === 'no' && value.isSign === 'no') {
                return groupsWithErrors; // no problems - user selected both 'no'
            }
        }

        groups.forEach((group) => {
            if (group.mandatoryApproval && this.appealService.appeal.tmpDocs) {
                this.appealService.appeal.tmpDocs.forEach((documentData) => {
                    if (documentData.envelope && documentData.envelope.status !== 'closed') {
                        groupsWithErrors.push(group.guid);
                    }
                });
            }
        });

        return groupsWithErrors;
    }

    public validateTask(task, isValidAdditionalData) {
        const errors = {
            documentGroups: [] as string[],
            documentNeedSedApprove: [] as string[],
            documentGroupsSigFilePresence: {
                groupsWithoutSigFile: [] as string[],
                groupsWithOnlySigFile: [] as string[],
            } as ValidateSigFileResult,
            keyIndicators: [] as string[],
            entities: [] as string[],
        };
        const subservice = this.appealService.subservice;
        const taskData = this.getTaskData(task);

        if (taskData) {
            if (subservice.documentGroups && subservice.documentGroups.length > 0) {
                errors.documentGroups = this._validateTaskDocumentGroups(taskData, subservice);
                errors.documentGroupsSigFilePresence = this._validateTaskDocumentGroupsSigFilePresence(
                    taskData,
                    subservice
                );
                errors.documentNeedSedApprove = this._checkSedAwaited(taskData, subservice.documentGroups);
            }

            if (subservice.entities) {
                errors.entities = this._validateTaskEntities(taskData, subservice.entities);
            }
        }
        const resultErrors = [];

        if (errors.documentGroups.length > 0) {
            resultErrors.push(
                'Не добавлены обязательные документы в группах: "' + errors.documentGroups.join(', ') + '"'
            );
        } else if (errors.documentGroupsSigFilePresence.groupsWithoutSigFile.length > 0) {
            resultErrors.push(
                'Нет файла подписи c расширением .sig или .p7s в документах групп(ы): "' +
                    errors.documentGroupsSigFilePresence.groupsWithoutSigFile.join(', ') +
                    '"'
            );
        } else if (errors.documentGroupsSigFilePresence.groupsWithOnlySigFile.length > 0) {
            resultErrors.push(
                'В документах групп(ы) "' +
                    errors.documentGroupsSigFilePresence.groupsWithOnlySigFile.join(', ') +
                    '"присутствует только один файл подписи с расширением .sig или .p7s, без документов'
            );
        }
        if (errors.documentNeedSedApprove.length > 0) {
            resultErrors.push(
                'Ожидается согласование услуги в СЭД, дождитесь ответа. К запросу должны быть приложены документ(ы) в формате PDF и файл подписи c расширением .sig или .p7s'
            );
        }
        if (errors.keyIndicators.length > 0) {
            resultErrors.push(
                'Не заполнены обязательные ключевые показатели: "' + errors.keyIndicators.join(', ') + '"'
            );
        }
        if (errors.entities.length > 0) {
            resultErrors.push('Не добавлены обязательные сведения: "' + errors.entities.join(', ') + '"');
        }
        if (!isValidAdditionalData) {
            resultErrors.push('Некорректно заполнена дополнительная информация');
        }
        if (task.xsdData.HardCopy) {
           const resultGroupCode = subservice.documentGroups.filter(g => g.mnemonic === 'Result').map(g => g.code)[0];
           if (resultGroupCode) {
               if (!this.appealService.appeal.documents
                   .filter(d => resultGroupCode === d.groupCode)
                   .filter(d => d.files && d.files.length > 0)
                   .length
               ) {
                   resultErrors.push('Не приложен файл результата оказания услуги');
               }
           }
        }

        if (resultErrors.length > 0) {
            return resultErrors.join(' ');
        }

        return false;
    }

    /**
     * КНМ активности текущего процесса
     * @returns {Promise<T>}
     */
    public async checkProcessIsActive() {
        const isActive = await this.appealService.checkCamundaProcessActivity(this.camundaProcessId);
        this.isProcessActive = isActive;

        return this._updateTasksList(isActive);
    }

    /**
     * Обновление списка задач текущего процесса
     * @param {boolean} repeatIfNotExistActiveTasks - повторять поиск, если процесс активен
     * @returns {Promise<any>}
     */
    private async _updateTasksList(repeatIfNotExistActiveTasks = false) {
        await this.appealService.updateAppealCamundaProcessTasks();
        // Обновление списка задач
        this._getTasksList();
        if (repeatIfNotExistActiveTasks) {
            const activeTasks = this.tasks.filter((item) => item.status === 'ACTIVE');
            if (activeTasks.length === 0) {
                if (this.isAutoUpdateTasks) {
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(this._updateTasksList(true));
                        }, 10000);
                    });
                }
                this.isProcessUpdateTasks = false;

                return true;
            }
            this.isProcessUpdateTasks = false;

            return true;
        }

        this.isProcessUpdateTasks = false;

        return true;
    }

    private _getTasksList() {
        this.tasks = this.appealService.processTasks;
        this.activeTasks = this.tasks.filter((item) => item.status === 'ACTIVE');
        this.completedTasks = this.tasks.filter((item) => item.status !== 'ACTIVE');

        if (this.activeTasks.length > 0) {
            this.activeTask = this.activeTasks[0];
        }
    }

    public getTaskVariables(task) {
        const variables = {};
        const subservice = this.appealService.subservice; // Описание услуги
        const appealSubservice = this.appealService.appeal.subservice;
        const taskData = this.getTaskDataInSubserviceProcess(task, subservice);

        if (taskData && taskData.variables) {
            taskData.variables.forEach((variable) => {
                variables[variable.code] = ObjectUtilities.GetPropertyByPath(appealSubservice.xsdData, variable.xpath);
            });
        }

        return variables;
    }

    public startProcessEvent(event, processId) {
        const params = {
            messageName: event.name,
            processInstanceId: processId,
            resultEnabled: true,
        };

        return this.http.post(Config.server + Config.api + 'camunda/message', params);
    }
}
