import { Component, Input, ViewChild, Output, EventEmitter, TemplateRef, OnInit } from '@angular/core';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'common-appeal-tabs',
    templateUrl: 'common-appeal-tabs.component.html',
})
export class CommonAppealTabsComponent implements OnInit {
    @Input() public appeal;
    @Input() public tabs;
    @Input() public activeTab;

    @Output() public onActivateTab = new EventEmitter<string>();

    public modalRef: BsModalRef;
    @ViewChild('errorsListModal', { static: false }) public errorsListModal: TemplateRef<any>;

    public tabWithErrors;
    public localizations;

    public constructor(
        public validateService: CommonAppealValidateService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(['common']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public activateTab(tabCode) {
        this.onActivateTab.emit(tabCode);
    }

    public showErrors(tab) {
        this.tabWithErrors = tab;
        this.modalRef = this.modalService.show(this.errorsListModal, { backdrop: 'static' });
    }
}
