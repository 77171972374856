import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDialogComponent, RestService, StorageService, ToasterService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealStatusService } from '../../appeal-status.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-messages',
    templateUrl: 'appeal-messages.component.html',
})
export class AppealMessagesComponent implements OnInit {
    @Input() public appeal; // формируемое дело
    @Input() public messages; // массив сообщений дела

    public editMessage = null; // редактируемое в данный момент времени сообщение
    public deletedMessage = null; // удаляемое сообщение

    public currentOrganization = this.storage.getItem('currentOrganization'); // текущая организация

    @Output() public onChangeMessages = new EventEmitter<any>();
    @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDialogComponent;

    public allowCreate = true;
    public additionalMessage = '';

    public constructor(
        public appealStatusService: AppealStatusService,
        private rest: RestService,
        private storage: StorageService,
        private toaster: ToasterService,
        private saveService: AppealSaveService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.messages) {
            this.rest.search('appealMessages', {search: {search: [{field: 'appeal._id', operator: 'eq', value: this.appeal._id}]}, size: 100}).then(messages => {
                this.messages = messages;
                // this.onChangeMessages.emit(this.messages);
            });
        }
        // КНМ возможности добавления нового сообщения: в деле есть объекты у которых настроены параметры уведомлений
        const objectsWithMessaging = this.appeal.objects.filter(item => item.messaging && item.messaging.length > 0);
        if (objectsWithMessaging.length === 0) {
            this.allowCreate = false;
            this.additionalMessage = 'В деле отсутствуют объекты для которых установлены параметры уведомлений.';
        }
    }

    /**
     * Создание сообщения
     */
    public createMessage() {
        const message = {
            guid: CommonUtilities.GenerateGuid(),
            status: {name: 'draft', displayName: 'Черновик'},
            person: null,
            message: null,
            type: null,
            unitId: this.currentOrganization['_id'],
            appeal: {
                _id: this.appeal._id,
                number: this.appeal.number,
                shortNumber: this.appeal.shortNumber,
                dateRegister: this.appeal.dateRegister,
                subservice: null,
                statusName: this.appeal.status.name,
            },
        };
        if (this.appeal.complexSubservice) {
            message.appeal.subservice = this.appeal.complexSubservice.name;
        } else if (this.appeal.subservice) {
            message.appeal.subservice = this.appeal.subservice.shortTitle;
        }

        this.editMessage = message;
    }

    /**
     * Переход в режим редактирования отдельного сообщения
     */
    public onEditMessage(message) {
        this.editMessage = _.cloneDeep(message);
    }

    /**
     * Применение изменений
     * @param data
     */
    public onApplyMessage(data) {
        if (data) {
            const messageIndex = this.messages.findIndex(item => item.guid === data.guid);
            let beforeMessage = {};
            if (messageIndex !== -1) {
                beforeMessage = _.cloneDeep(this.messages[messageIndex]);
                this.messages[messageIndex] = data;
            } else {
                this.messages.push(data);
            }
            // Сохранение данных на сервере
            this.save(beforeMessage, data);
        }
        this.editMessage = null;
    }

    /**
     * Сохранение данных сообщения на сервере
     * @param before - объект до изменения
     * @param current - объект после изменения
     */
    public save(before, current) {
        this.saveService.saveAppealItem(before, current, 'appealMessages').then(message => {
            if (!message['noChange']) {
                const findIndex = this.messages.findIndex(item => item.guid === message['guid']);

                this.messages[findIndex] = Object.assign(this.messages[findIndex], message);
                // this.onChangeMessages.emit(this.messages);
                this.toaster.success('Данные успешно сохранены');
            }
        });
    }

    /**
     * Отображение модального окна для подтверждения удаления сообщения из дела
     * @param message - удаляемое сообщение
     */
    public onDeleteMessage(message) {
        this.deletedMessage = message;
        this.deleteModal.showModal();
    }

    /**
     * Удаление конверта из дела на сервере
     */
    public processDeleteMessage() {
        this.saveService.deleteAppealItem(this.deletedMessage, 'appealMessages').then(() => {
            const findIndex = this.messages.findIndex(item => item.guid === this.deletedMessage.guid);

            this.messages.splice(findIndex, 1);
            // this.onChangeMessages.emit(this.messages);
            this.toaster.success('Сообщение успешно удалено');
        });
    }
}
