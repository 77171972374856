<!-- Глобальный поиск -->
<div class="global-search pl-20 pr-20 full-width" *ngIf="params.length || globalSearch.length">
    <div class="input-group">
        <input class="form-control" placeholder="Поиск по группе..." type="text" [(ngModel)]="globalSearch"
               (keyup)="search()">
        <button class="btn btn-clear" type="button" (click)="clearSearch()" *ngIf="globalSearch"><i
            class="icon-cross3"></i></button>
        <div class="input-group-btn">
            <button class="btn btn-default text-muted" type="button" (click)="search()"><i
                class="icon-search4 text-muted"></i></button>
        </div>
    </div>
</div>

<div *ngFor="let group of params">
    <div class="panel-shadow m-20">
        <div class="panel-heading cursor-pointer" (click)="group.isOpened = !group.isOpened">
            <h5 class="panel-title">{{ group.groupName }}</h5>
            <div class="heading-elements">
                <i class="text-size-xl"
                   [ngClass]="{'icon-arrow-down22': group.isOpened, 'icon-arrow-left22': !group.isOpened}"></i>
            </div>
        </div>
        <div class="panel-body"
             *ngIf="group.isOpened">
            <div class="row">
                <ng-container *ngFor="let field of group.groupFields">
                    <div class="col-md-{{ field.size }} mt-10">
                        <ng-container *ngIf="!field.arrayParams">
                            <ng-template [ngTemplateOutlet]="baseField"
                                         [ngTemplateOutletContext]="{field: field}"></ng-template>
                        </ng-container>

                        <ng-container *ngIf="field.arrayParams">
                            <ng-template [ngTemplateOutlet]="arrayFieldsGroup"
                                         [ngTemplateOutletContext]="{group: field.arrayParams}"></ng-template>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #baseField
             let-field="field">
    <span class="text-bold">{{ field.name }}: </span>
    <div>{{ field.value | param:field.path:field.specialType }}</div>
</ng-template>

<ng-template #arrayFieldsGroup
             let-group="group">
    <fieldset>
        <legend class="text-bold cursor-pointer mb-10" (click)="group.isOpened = !group.isOpened">
            <span [ngClass]="{'text-slate': group.isOpened}">{{ group.groupName }}</span>
            <a class="control-arrow">
                <i [ngClass]="{'icon-arrow-down12' : group.isOpened, 'icon-arrow-left12' : !group.isOpened}"></i>
            </a>
        </legend>
        <div class="row mb-10"
             *ngIf="group.isOpened">
            <ng-container *ngFor="let field of group?.groupFields">
                <div class="col-md-{{ field.size }} mt-10">
                    <ng-template [ngTemplateOutlet]="baseField"
                                 [ngTemplateOutletContext]="{field: field}"></ng-template>
                </div>
            </ng-container>
        </div>
    </fieldset>
</ng-template>
