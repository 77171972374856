<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">

            <div class="row">
                <div class="col-xs-12 col-md-3">
                    <div class="form-group form-group-lg">
                        <label>Номер</label>
                        <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" placeholder="Введите номер">
                    </div>
                </div>
                <div class="col-xs-12 col-md-3">
                    <div class="form-group form-group-lg">
                        <label>Дата составления</label>
                        <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true"></evolenta-datepicker>
                    </div>
                </div>
            </div>

            <!-- Данные требования -->
            <div class="panel panel-shadow m-20 p-20">
                <div class="panel-body no-padding pt-10">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group form-group-lg">
                                    <label>В ходе проверки выявлено:</label>
                                    <textarea [(ngModel)]="entity.text" class="form-control" rows="7"></textarea>
                                </div>
                            </div>
                        </div>

                        <!-- Лица, уполномоченные на проведение проверки -->
                        <div class="mb-15">
                            <appeal-entity-employee
                                [large]="true"
                                [entity]="entity"
                                property="employee"
                                [title]="'Лица, уполномоченные на проведение проверки'"
                                [field]="['name','position']">
                            </appeal-entity-employee>
                        </div>
                    </div>
                </div>
            <div>
                <hr>
                <!-- Группы документов, привязанные к сведению -->
                <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
            </div>
        </div>
    </div>
</div>
