import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';

@Component({
    selector: 'appeal-entity-prescription-items',
    templateUrl: 'appeal-entity-prescription-items.component.html',
})
export class AppealEntityPerscriptionItemsComponent implements OnInit {
    @Input() public entity;
    @Input() public property;
    @Input() public title;
    @Input() public large;
    @Input() public small;

    public ngOnInit() {
        if (this.property && !this.entity[this.property]) {
            this.entity[this.property] = [this.newItem()];
        }
    }

    /**
     * Добавление нового элемента массива
     * @returns void
     */
    public addItem(): void {
        this.entity[this.property].push(this.newItem());
    }

    /**
     * Возвращает новый объект
     * @returns {object}
     */
    public newItem(): object {
        return {
            guid: CommonUtilities.GenerateGuid(),
            item: null,
            factFailure: null,
        };
    }

    /**
     * Удаление ранее добавленного элемента
     * @param item
     * @returns void
     */
    public deleteItem(item): void {
        const findIndex = this.entity[this.property].findIndex((f) => f.guid === item.guid);
        this.entity[this.property].splice(findIndex, 1);
    }
}
