import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, StorageService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Injectable()
export class StandardSaveService {
    public baseStandard;
    public standard;

    public moduleBaseUrl;

    public isProcessSavingStandard = false;

    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private router: Router
    ) {}

    /**
     * Основной метод сохранения стандарта
     */
    public saveStandard() {
        this.isProcessSavingStandard = false;
        this.baseStandard = this.storage.getItem('baseStandard');
        if (!this.baseStandard) {
            this.baseStandard = {};
        }

        // Сравнение основных полей дела для формирования объекта на сохранение
        const standardForSave: any = {};
        // Пробегаемся по полям стандарта сравнения с аналогичными полями базового стандарта
        Object.keys(this.standard).forEach((item) => {
            if (JSON.stringify(this.standard[item]) !== JSON.stringify(this.baseStandard[item])) {
                standardForSave[item] = this.standard[item];
            }
        });

        // Если есть изменения в основном наборе полей, сохраняем сначала основную сущность дела
        if (Object.keys(standardForSave).length > 0) {
            if (!this.standard._id) {
                // Создание дела на сервере
                return this.rest.create('reglaments', standardForSave).then(
                    (createdStandard: any) => {
                        // Объединяем поля измененного дела с текущим значением
                        this.standard = Object.assign(this.standard, createdStandard);

                        // Обновление данных стандарта в базовом объекте
                        this.baseStandard = Object.assign(this.baseStandard, createdStandard);
                        this.storage.setItem('baseStandard', this.baseStandard);
                        this.isProcessSavingStandard = false;
                        this.router.navigate([this.moduleBaseUrl, 'standards', 'edit', createdStandard._id]);
                        return Promise.resolve(createdStandard);
                    },
                    (error) => {
                        // Ошибка сохранения, обрыв процедуры сохранения дела
                        this.isProcessSavingStandard = false;
                        return Promise.reject(error);
                    }
                );
            } else {
                // Обновление дела на сервере
                // Добавление в сохраняемых объект ID и GUID дела
                standardForSave['_id'] = this.standard['_id'];
                standardForSave['guid'] = this.standard['guid'];
                return this.rest.update('reglaments', standardForSave).then(
                    (updatedStandard: any) => {
                        // Объединяем поля измененного дела с текущим значением
                        this.standard = Object.assign(this.standard, updatedStandard);

                        // Обновление данных дела в базовом объекте дела
                        this.baseStandard = Object.assign(this.baseStandard, updatedStandard);
                        this.storage.setItem('baseStandard', this.baseStandard);

                        this.isProcessSavingStandard = false;
                        return Promise.resolve(updatedStandard);
                    },
                    (error) => {
                        this.isProcessSavingStandard = false;
                        return Promise.reject(error);
                    }
                );
            }
        } else {
            this.isProcessSavingStandard = false;
            return Promise.resolve(this.standard);
        }
    }
}
