<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-primary btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            *ngIf="allowCreate"
            (click)="createDictionary()">
        <b><i class="icon-plus-circle2"></i></b>
        {{ localizations.common.add }}
    </button>

    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   [placeholder]="localizations.common.search_dots"
                   type="text"
                   [(ngModel)]="globalSearch"
                   (keyup)="search()">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="search()">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>
<div class="page-container">
    <div class="panel-main wide">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
            <div class="display-grid grid-template-columns">
                <div class="panel panel-shadow m-20"
                     *ngFor="let dictionary of dictionaries">
                    <div class="panel-heading no-border">
                        <div class="panel-title">
                            <h4 class="cursor-pointer no-margin-top"
                                (click)="goToDictionary(dictionary)">
                                {{ dictionary.description }}
                            </h4>
                            <div>
                                <label class="badge bg-slate-300 cursor-pointer"
                                       (click)="goToDictionary(dictionary)">
                                    {{ localizations.common.code }}:
                                    {{ dictionary.collectionName }}
                                </label>
                            </div>
                        </div>
                        <div class="heading-elements">
                            <button class="btn btn-default"
                                    [title]="localizations.common.edit">
                                <b>
                                    <i inlineSVG="assets/icon-edit.svg"></i>
                                </b>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
