<dl>

    <!-- Ответственная организация -->
    <dt class="text-bold">Организация</dt>
    <dd>
        <!-- Выбранная организация -->
        <div *ngIf="organization">
            {{organization.name}}
            <span class="anchor text-info ml-10 text-size-small" (click)="changeOrgForEnvelope()" *ngIf="allowEdit">изменить</span>
        </div>

        <!-- Блок выбора организации из списка -->
        <div *ngIf="!organization">
            <div class="row" *ngIf="numberShowed > 10">
                <div class="col-md-4 mb-15">
                    <input class="form-control"
                           placeholder="Поиск..."
                           type="text"
                           name ="search"
                           [(ngModel)]="filter"/>
                </div>
            </div>
            <div *ngFor="let organization of organizations | searchForNameFilter: filter; let i = index">
                <div class="media-heading text-semibold mb-10" [ngStyle]="{'display': ( i >= numberShowed ) ? 'none' : '' }">
                    <input  type="radio"
                            class="radio"
                            name="org{{organization._id}}"
                            [value]="organization"
                            [(ngModel)]="envelope.org" />
                    <label (click)="selectOrganization(organization)">
                        {{organization.name ? organization.name : 'организация без наименования'}}
                    </label>
                </div>
            </div>
            <span class="anchor text-info text-size-small"
                  *ngIf="organizations.length > numberShowed"
                  (click)="openAllItemList(organizations)">показать еще {{organizations.length - numberShowed}} вариантов</span>
        </div>
    </dd>

    <!-- Запрос -->
    <ng-container *ngIf="organization">
        <dt class="text-bold">Запрос</dt>
        <dd>
            <!-- Наименование запроса -->
            <div *ngIf="envelope.request">
                <span class="text-muted mr-5"
                      *ngIf="envelope.request.auid">
                    {{ envelope.request.auid }}
                </span>
                {{ envelope.request.name }}
                <span class="anchor text-info ml-10 text-size-small"
                      *ngIf="allowEdit"
                      (click)="envelope.request = null">
                    изменить
                </span>
            </div>

            <!-- Блок выбора запроса из списка -->
            <div *ngIf="requests && !envelope.request">
                <div *ngFor="let request of requests">
                    <div class="media-heading text-semibold mb-10">
                        <input  type="radio"
                                class="radio"
                                name="request{{request._id}}"
                                [value]="request"
                                [(ngModel)]="envelope.request"/>
                        <label (click)="selectRequestForEnvelope(request)">
                            {{request.name}}
                        </label>
                    </div>
                </div>
                <span class="anchor text-info"
                      *ngIf="requests.length > numberShowed"
                      (click)="openAllItemList(requests)">развернуть</span>
            </div>
        </dd>
    </ng-container>

    <ng-container *ngIf="appeal">
        <dt class="text-bold">Проверка</dt>
        <dd>
            <span class="text-semibold">№
                <ng-container *ngIf="appeal.shortNumber || appeal.number">{{appeal.shortNumber ? appeal.shortNumber : appeal.number}} от {{appeal.dateRegister | date: 'shortDate'}} {{appeal.dateRegister | date: 'shortTime'}}</ng-container>
                <ng-container *ngIf="!appeal.shortNumber && !appeal.number">n/a</ng-container> -
            </span> {{subserviceTitle}} <a class="anchor text-size-small text-info ml-10" (click)="goToAppeal()">перейти</a>
        </dd>

        <ng-container *ngIf="appealDocument">
            <dt class="text-bold">Документ дела</dt>
            <dd *ngIf="appealDocument"><span class="text-semibold">{{appealDocument.groupName}}: </span><span>{{appealDocument.name}}</span></dd>
            <dd *ngIf="!appealDocument" class="text-muted">документ удален из проверки</dd>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="envelope.dateSend">
        <dt class="text-bold">Дата отправки запроса</dt>
        <dd>{{envelope.dateSend | date: 'shortDate'}} г.</dd>
    </ng-container>

    <ng-container *ngIf="envelope.status === 'sended' && envelope.datePlaneFinish">
        <dt class="text-bold">Плановая дата получения ответа</dt>
        <dd>{{envelope.datePlaneFinish | date: 'shortDate'}} г.</dd>
    </ng-container>

    <ng-container *ngIf="envelope.status === 'closed' && envelope.dateHavingResult">
        <dt class="text-bold">Дата получения ответа</dt>
        <dd>{{envelope.dateHavingResult | date: 'shortDate'}} г.</dd>
    </ng-container>

</dl>
