import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectService, ToasterService } from '@evolenta/core';
import { AppealEntityObservationPrescription } from './models/appeal-entity-observation-prescription';
import { AppealEntitiesService } from '../../appeal-entities.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-observation-prescription',
    templateUrl: 'appeal-entity-observation-prescription.component.html',
})
export class AppealEntityObservationPrescriptionComponent implements OnInit {
    @Input() public entity: AppealEntityObservationPrescription;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    /** Справочники */
    public actionTakenItems = [];

    public isProcessValidate = false;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Меры по устранению нарушения
        // this.appealEntitiesService.getActionTakenItems().subscribe(data => {
        //     this.actionTakenItems = this.selectService.transformForSelect('code', 'name', data);
        // });
        this.actionTakenItems = [];

        if (!this.entity.date) {
            this.entity.date = '';
        }
        if (!this.entity.reportDeadline) {
            this.entity.reportDeadline = '';
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        return isValidForm;
    }
}
