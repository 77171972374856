<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            [disabled]="isLoading"
            (click)="save()">
        <b>
            <i class="icon-checkmark-circle"
               [ngClass]="{'flash': isLoading}">
            </i>
        </b>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-5">
        <h4 class="page-title">
            <i class="icon-arrow-left52 position-left cursor-pointer"
               (click)="back()">
            </i>
            {{
                roleMnemonic._id ?
                    localizations.roles.editing :
                    localizations.roles.creation
            }}
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="panel panel-main wide panel-flat no-margin no-border-top">
        <div class="panel-body">
            <div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label class="control-label"
                                   for="codeRole">
                                {{ localizations.roles.code }}
                                <span class="text-danger">*</span>
                            </label>
                            <input id="codeRole"
                                   class="form-control"
                                   type="text"
                                   name="nameRole"
                                   [(ngModel)]="roleMnemonic.code">
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label class="control-label"
                                   for="nameRole">
                                {{ localizations.common.save }}
                                <span class="text-danger">*</span>
                            </label>
                            <input id="nameRole"
                                   class="form-control"
                                   name="nameRole"
                                   type="text"
                                   [(ngModel)]="roleMnemonic.name">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
