import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RestService, SelectService, StorageService, ToasterService } from '@evolenta/core';
import { AppealEntityProceedProtocolReview } from './models/appeal-entity-proceed-protocol-review';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { UtilityService } from '../../../../../../../common/services/utility.service';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-proceed-protocol-review',
    templateUrl: 'appeal-entity-proceed-protocol-review.component.html',
})
export class AppealEntityProceedProtocolReviewComponent implements OnInit {
    @Input() public entity: AppealEntityProceedProtocolReview;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public currentOrganization = this.storage.getItem('currentOrganization');

    public isProcessValidate = false;
    public npa = {};

    public constructor(
        public storage: StorageService,
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService,
        public restService: RestService,
        public utilityService: UtilityService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.protocolReviewDate) {
            this.entity.protocolReviewDate = '';
        }
        if (!this.entity.commitDate) {
            this.entity.commitDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidNPA = this.validateNPA();

        return isValidForm && isValidNPA;
    }

    public validateNPA(): boolean {
        const isValid = !!_.get(this.entity.npa, '0.value', null);

        this.npa = isValid ? { value: this.entity.npa } : { value: this.entity.npa, errors: { required: true } };

        return isValid;
    }
}
