<div class="panel panel-shadow m-20">
    <div class="panel-body">

        <!-- Информационные блоки с кастомными формами -->
        <ng-container *ngFor="let block of blocks">
            <common-standard-block-with-additional-data [block]="block" *ngIf="block.xsd"></common-standard-block-with-additional-data>
        </ng-container>

    </div>
</div>
