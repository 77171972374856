import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { EnvelopesService } from '../../common/services/envelopes.service';
import { XsdJsonService } from '../../common/services/xsd-json.service';
import { EnvelopeEditComponent } from './envelopes/envelope-edit.component';
import { EnvelopeMainInfoComponent } from './envelopes/components/envelope-main-info/envelope-main-info.component';
import { EnvelopeDocumentsComponent } from './envelopes/components/envelope-documents/envelope-documents.component';
import { EnvelopeHistoryComponent } from './envelopes/components/envelope-history/envelope-history.component';
import { EnvelopePreviewComponent } from './envelopes/components/envelope-preview/envelope-preview.component';
import { EnvelopeSoapdumpsComponent } from './envelopes/components/envelope-soapdumps/envelope-soapdumps.component';
import { EnvelopeObjectComponent } from './envelopes/components/envelope-object/envelope-object.component';
import { EnvelopesResolve } from './envelopes/envelopes.resolve';
import { OrganizationsResolve } from './envelopes/organizations.resolve';
import { EnvelopeDataComponent } from './envelopes/components/envelope-data/envelope-data.component';
import { SubjectsModule } from '../subjects/subjects.module';
import { SelectRequestComponent } from './envelopes/requests/select-request.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { RequestsModule } from '@evolenta/requests-module';
import { EnvelopeGuard } from '../../common/guards/envelope.guard';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        SubjectsModule,
        RequestsModule,
        InlineSVGModule,
        BreadcrumbsModule,
    ],
    declarations: [
        EnvelopeEditComponent,
        EnvelopeMainInfoComponent,
        EnvelopeDocumentsComponent,
        EnvelopeHistoryComponent,
        EnvelopePreviewComponent,
        EnvelopeSoapdumpsComponent,
        EnvelopeObjectComponent,
        EnvelopeDataComponent,
        SelectRequestComponent,
    ],
    providers: [
        EnvelopesResolve,
        OrganizationsResolve,
        EnvelopesService,
        XsdJsonService,
        EnvelopeGuard,
    ],
    exports: [
        EnvelopeDataComponent,
        EnvelopePreviewComponent,
    ],
    entryComponents: [
        EnvelopePreviewComponent,
    ],
})

export class EnvelopesModule {
}
