import { ControlOperatorsSectionComponent } from './components/control-operators-section/control-operators-section.component';
import { ControlOperatorsComponent } from './components/control-operators/control-operators.component';
import { ControlOperatorsService } from './services/control-operators.service';
import { ControlOperatorModule } from './components/control-operators/control-operator/control-operator.module';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ControlOperatorsResolve } from './control-operators.resolve';
@NgModule({
    imports: [ControlOperatorModule, CommonModule, SharedComponentsModule],
    declarations: [ControlOperatorsSectionComponent, ControlOperatorsComponent],
    providers: [ControlOperatorsResolve, ControlOperatorsService],
    exports: [],
})
export class ControlOperatorsModule {}
