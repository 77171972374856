<div [ngClass]="{
            'panel panel-shadow no-margin-bottom': !task || mode == 'edit',
             'ml-20 mr-20 mt-20': mode == 'view' && !task,
             'm-20': !task && mode == 'edit'
         }">
    <div class="panel-heading"
         *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{ entity.name }}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">
                    {{ localizations.appeals.unplanned_checkup }}
                </span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">
                    {{ localizations.appeals.planned_checkup }}
                </span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn"
                    [title]="localizations.common.edit"
                    *ngIf="mode == 'view'"
                    (click)="edit()">
                <b>
                    <i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                </b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit' && allowEdit"
                    (click)="apply()">
                {{ localizations.common.apply }}
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase"
                    (click)="cancel()" *ngIf="mode == 'edit'">
                {{ localizations.common.close }}
            </button>
        </div>
    </div>
    <div [ngClass]="{
            'panel-body no-padding': !task || mode == 'edit',
            'card-line': task && mode == 'view'
         }">

        <div *ngIf="mode == 'view'"
             [ngClass]="{
                'display-inline-block card-preview': task,
                'p-15': !task
             }">
            <p class="mb-3">
                <span class="text-muted">
                    {{ localizations.appeals.documents_preparing_term }}:
                </span>
                <ng-container *ngIf="entity.requestedDocumentDate">
                    {{ entity.requestedDocumentDate | date: 'shortDate' }}
                </ng-container>
            </p>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right"
             *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs"
                    type="button"
                    [title]="allowEdit ? localizations.common.edit : localizations.common.view"
                    (click)="edit()">
                <i [ngClass]="{
                        'icon-pencil3': allowEdit,
                        'icon-eye': !allowEdit
                   }">
                </i>
            </button>
            <button class="btn btn-default btn-sxs"
                    type="button"
                    [title]="localizations.common.delete"
                    *ngIf="allowEdit"
                    (click)="delete()">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">

            <div  class="pt-15 pl-20 pr-20">
                <!-- Место проведения проверки -->
                <div class="row">
                    <div class="col-md-9">
                        <label>
                            {{ localizations.appeals.checkup_place }}
                        </label>
                        <evolenta-address-gar
                                [large]="true"
                                [showIndex]="false"
                                [disabled]="!allowEdit"
                                [(ngModel)]="entity.place">
                        </evolenta-address-gar>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.appeals.documents_preparing_term }}
                            </label>
                            <evolenta-datepicker [large]="true"
                                                 [valueAsString]="true"
                                                 [disabled]="!allowEdit"
                                                 [(ngModel)]="entity.requestedDocumentDate">
                            </evolenta-datepicker>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>
                        {{ localizations.appeals.notifications.way }}
                    </label>
                    <catalogue
                        catalogName="nsiErpNotifyWay"
                        name="nsiErpNotifyWay"
                        [(ngModel)]="entity.notifyWay"
                        [returnFields]="['_id', 'code', 'name']"
                        [large]="true"
                        [disabled]="!allowEdit">
                    </catalogue>
                </div>

                <!-- Прилагаемые документы -->
                <div class="mb-15">
                    <appeal-entity-documents-field
                        [appeal]="appeal"
                        [entity]="entity"
                        [property]="'materials'"
                        [disabled]="!allowEdit"
                        [title]="localizations.events.applied_documents"
                        [placeholder]="localizations.appeals.select_applied_documents">
                    </appeal-entity-documents-field>
                </div>

                <!-- Лицо, уполномоченное на проведение проверки -->
                <div class="mb-15">
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        property="employee"
                        [disabled]="!allowEdit"
                        [title]="localizations.appeals.person_responsible_for_checkup"
                        [field]="['name']"
                        [transformName]="userNameTransform">
                    </appeal-entity-employee>
                </div>
            </div>

            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"
                                     [task]="task"
                                     [allowEdit]="allowEdit">
            </appeal-entity-documents>
        </div>
    </div>
</div>
