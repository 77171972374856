<div class="sidebar sidebar-main">
    <div class="sidebar-content">
        <logo>
        </logo>
        <navigator [module]="module">
        </navigator>
    </div>
    <version></version>
</div>
<router-outlet></router-outlet>
