import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { SectionComponent, StorageService, UniversalCollectionPageComponent } from '@evolenta/core';
import { CommonAppealsComponent } from '../../../modules/common-appeals/components/appeals/common-appeals.component';
import { CommonAppealsResolve } from '../../../modules/common-appeals/common-appeals.resolve';
import { ServicesComponent } from '../../../modules/subservices/services/services.component';
import { ServicesResolve } from '../../../modules/subservices/services/services.resolve';
import { CommonAppealEditComponent } from '../../../modules/common-appeals/components/appeal-edit/common-appeal-edit.component';
import { RegistersListConfig } from '../../../modules/registers/registers-list.config';
import { RegistersResolve } from '../../../modules/registers/registers.resolve';
import { RegistryComponent } from '../../../modules/registers/registry.component';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'licensing-activity-default',
    template: '',
})
export class LicensingActivityDefaultComponent implements OnInit {
    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storage: StorageService,
    ) {
    }

    public ngOnInit(): void {
        this.route.parent.url.subscribe(urlPath => {
            const applications = this.storage.getItem('applications');
            const modulePath = urlPath[0].path;
            const application = applications.find(item => item.code === modulePath);

            if (application.routing) {
                this.prepareRoutes(application.routing, modulePath);
                this.route.url.subscribe((urls: UrlSegment[]) => {
                    const urlItems = [modulePath].concat(urls.map(item => item.path));

                    this.router.navigate(urlItems);
                });
            }
        });
    }

    private prepareRoutes(params, modulePath): void {
        const findIndex = this.router.config.findIndex(item => item.path === modulePath);

        if (!this.router.config[findIndex].data.defaultConfig) {
            this.router.config[findIndex].data.defaultConfig = cloneDeep(this.router.config[findIndex]);
        }

        if (this.router.config[findIndex].children.length === 2) {
            const newRoutes = [];

            params.forEach((param: any) => {
                let routesBlock;

                if (param.isAppealsBlock) {
                    routesBlock = {
                        path: param.path,
                        component: SectionComponent,
                        data: {
                            icon: 'icon-grid5',
                            name: param.name,
                            img: 'main-menu/processes',
                            breadcrumb: param.name,
                        },
                        children: [
                            {
                                path: '',
                                component: CommonAppealsComponent,
                                data: {
                                    name: param.name,
                                    breadcrumb: param.name,
                                    metaReglamentCode: param.metaReglamentCode,
                                },
                                resolve: {
                                    resolves: CommonAppealsResolve,
                                },
                            },
                            {
                                path: 'services',
                                component: ServicesComponent,
                                data: {
                                    name: 'Регламенты',
                                    breadcrumb: 'Регламенты ',
                                    metaReglamentCode: param.metaReglamentCode,
                                },
                                resolve: {
                                    resolves: ServicesResolve,
                                },
                            },
                            {
                                path: 'create/:subserviceId',
                                component: CommonAppealEditComponent,
                                data: {
                                    name: 'Новое заявление',
                                    breadcrumb: 'Новое заявление',
                                    metaReglamentCode: param.metaReglamentCode,
                                },
                                resolve: {
                                    resolves: CommonAppealsResolve,
                                },
                            },
                            {
                                path: 'edit/:appealId',
                                component: CommonAppealEditComponent,
                                data: {
                                    name: 'Редактировать заявление',
                                    breadcrumb: 'Редактирование заявления',
                                    metaReglamentCode: param.metaReglamentCode,
                                },
                                resolve: {
                                    resolves: CommonAppealsResolve,
                                },
                            },
                        ],
                    };
                } else if (param.isRegistryBlock) {
                    routesBlock = {
                        path: param.path,
                        component: SectionComponent,
                        data: {
                            name: param.name,
                            breadcrumb: param.name,
                            img: 'main-menu/reports',
                            metaReglamentCode: param.metaReglamentCode,
                            uploadListButtonAllowed: true,
                        },
                        children: [
                            {
                                path: '',
                                component: UniversalCollectionPageComponent,
                                data: {
                                    name: param.name,
                                    breadcrumb: param.name,
                                    metaReglamentCode: param.metaReglamentCode,
                                    options: RegistersListConfig,
                                    uploadListButtonAllowed: true,
                                },
                                resolve: {
                                    resolves: RegistersResolve,
                                },
                            },
                            {
                                path: 'edit/:licenseId',
                                component: RegistryComponent,
                                data: {
                                    name: 'Информация',
                                    breadcrumb: 'Информация',
                                    metaReglamentCode: param.metaReglamentCode,
                                    uploadListButtonAllowed: true,
                                },
                                resolve: {
                                    resolves: RegistersResolve,
                                },
                            },
                        ],
                    };
                }

                newRoutes.push(routesBlock);
            });

            this.router.config[findIndex].children = newRoutes;
            this.router.resetConfig(this.router.config);
        }
    }
}
