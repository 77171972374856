<div class="panel panel-shadow m-20 no-margin-right">
    <div class="panel-heading bg-success-50">
        <div class="panel-title">
            <h4 class="panel-title no-margin-right">
                <!-- Название услуги -->
                {{controlOperator.service.name}}
            </h4>
        </div>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <div class="btn-group">
                <!-- Редактировать -->
                <button class="btn btn-success btn-success-overline"
                        (click)="switchToEditMode()"
                        *ngIf="isViewMode()">
                    <b>
                        <i class="icon-pencil">
                        </i>
                    </b>

                </button>

                <!-- Сохранить -->
                <button class="btn btn-success btn-success-overline"
                        (click)="save()"
                        *ngIf="mode === 'edit'">
                    <b>
                        <i class="icon-checkmark3">
                        </i>
                    </b>
                    {{ localizations.common.save }}
                </button>

                <!-- Сброс режима редактирования -->
                <button class="btn btn-success btn-success-overline"
                        (click)="switchToViewMode()"
                        *ngIf="isEditMode()">
                    {{ localizations.common.cancel }}
                </button>

                <!-- Удалить -->
                <button class="btn btn-success btn-success-overline"
                        (click)="showDeleteConfirmationModal()">
                    <b>
                        <i class="icon-cross">
                        </i>
                    </b>
                    {{ localizations.common.delete }}
                </button>
            </div>
        </div>
    </div>

    <div class="panel-body no-padding">
        <!-- TODO нужен ли тут контент? -->
    </div>

    <div class="panel-footer no-padding">
        <div class="date-elements">

            <!-- Код услуги -->
            <div class="p-20">
                <h5 class="date-text no-margin-top">
                    {{ localizations.control_operator.service_code }}
                </h5>
                <div class="date-value">
                    {{controlOperator.service.standardCode}}
                </div>
            </div>

            <!-- Ответственный -->
            <div class="p-20">
                <h5 class="date-text no-margin-top"
                    *ngIf="isViewMode()">
                    {{ localizations.control_operator.name }}
                </h5>
                <h5 *ngIf="isEditMode()">
                    {{ localizations.control_operator.select }}
                </h5>
                <div class="date-value"
                     *ngIf="isViewMode()">
                    {{controlOperator.user.name}} ({{controlOperator.user.login}})
                </div>
                <div *ngIf="isEditMode()">
                    <catalogue catalogName="users"
                               name="users"
                               codeField="login"
                               [params]="userSearchParams"
                               [(ngModel)]="preselectedUser">
                    </catalogue>
                </div>
            </div>
        </div>
    </div>
</div>

<delete-confirmation-modal
    [title]="deleteConfirmationHeader"
    [text]="deleteConfirmationText"
    (onConfirm)="delete()"
    #deleteConfirmationModal>
</delete-confirmation-modal>
