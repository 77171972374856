<div class="panel panel-shadow panel-small">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span *ngIf="bpmn && bpmn.title">
                {{bpmn.title}}
            </span>
            <span class="text-muted"
                  *ngIf="!bpmn.title">
                {{ localizations.common.name_missing }}
            </span>
            <span class="text-slate ml-10 text-size-small">
                {{bpmn.processId}}
            </span>
            <div class="text-size-small mt-5">
                <a class="text-info anchor"
                   [title]="localizations.common.download"
                   *ngIf="bpmn.file"
                   (click)="downloadFile(bpmn.file._id, bpmn.file.originalName)">
                    {{ bpmn.file.originalName }}
                </a>
                <a class="ml-20 text-info anchor"
                   *ngIf="bpmn.image"
                   (click)="openImage(bpmn.image)">
                    {{ localizations['bpmn-card'].process_schema }}
                </a>
            </div>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-default"
                    [title]="localizations.common.edit"
                    *ngIf="allowEdit"
                    (click)="edit()">
                <b>
                    <i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                </b>
            </button>
            <button class="btn btn-default"
                    [title]="localizations['bpmn-card'].clone_process"
                    *ngIf="allowClone"
                    (click)="clone()"
                    [disabled]="isProcessingClone">
                <b>
                    <i inlineSVG="assets/icon-copy.svg"
                       *ngIf="!isProcessingClone">
                    </i>
                    <i class="icon-spinner2 spinner"
                       *ngIf="isProcessingClone">
                    </i>
                </b>
            </button>
            <button
                    class="btn btn-default"
                    title="Удалить"
                    (click)="delete()" *ngIf="allowDelete">
                <b><i inlineSVG="assets/icon-trash.svg"></i></b>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <div class="hide">
            <p class="mb-3" >
                <ng-container *ngIf="bpmn.processId">
                    <span class="text-muted">
                        {{ localizations['bpmn-card'].process_id }}
                    </span>
                    {{bpmn.processId}}
                </ng-container>
            </p>
            <p class="mb-3" >
                <ng-container *ngIf="bpmn.title">
                    <span class="text-muted">
                        {{ localizations['bpmn-card'].full_name }}
                    </span>
                    {{bpmn.title}}
                </ng-container>
            </p>
            <p class="mb-3" >
                <ng-container *ngIf="bpmn.level">
                    <span class="text-muted">
                        {{ localizations['bpmn-card'].level }}
                    </span>
                    {{bpmn.level}}
                </ng-container>
            </p>
            <p class="mb-3" >
                <ng-container *ngIf="bpmn.knmKind">
                    <span class="text-muted">
                        {{ localizations['bpmn-card'].control_kind }}
                    </span>
                    {{bpmn.knmKind}}
                </ng-container>
            </p>
        </div>

        <!-- Подпроцессы -->
        <ng-container *ngIf="bpmn.tasks && bpmn.tasks.subProcess.length > 0">
            <common-bpmn-card-tasks
                [tasks]="bpmn.tasks.subProcess"
                type="subProcess"
                [allowEdit]="allowEdit"
                [tasksName]="localizations['bpmn-card'].subprocesses"
                [isCanChange]="true"
                [entityProcessData]="entityProcessData"
                (onCreateSubProcess)="createSubProcess($event)"
                (onSearchSubProcess)="searchSubProcess($event)"
                (onDeleteSubProcess)="deleteSubProcess($event)"
                (onEditSubProcess)="editSubProcess($event)">
            </common-bpmn-card-tasks>
        </ng-container>

        <!-- Пользовательские задачи -->
        <ng-container *ngIf="bpmn.tasks && bpmn.tasks.userTasks.length > 0">
            <common-bpmn-card-tasks [tasks]="bpmn.tasks.userTasks"
                                    type="userTasks"
                                    [tasksName]="localizations['bpmn-card'].user_tasks"
                                    [entityProcessData]="entityProcessData">
            </common-bpmn-card-tasks>
        </ng-container>

        <!-- События -->
        <ng-container *ngIf="bpmn.catchEvents && bpmn.catchEvents.length > 0">
            <common-bpmn-card-tasks [tasks]="bpmn.catchEvents"
                                    type="catchEvents"
                                    [tasksName]="localizations['bpmn-card'].events"
                                    [entityProcessData]="entityProcessData">
            </common-bpmn-card-tasks>
        </ng-container>

        <!-- Сервисные задачи -->
        <ng-container *ngIf="bpmn.tasks && bpmn.tasks.serviceTasks.length > 0">
            <common-bpmn-card-tasks [tasks]="bpmn.tasks.serviceTasks"
                                    type="serviceTasks"
                                    [tasksName]="localizations['bpmn-card'].service_tasks">
            </common-bpmn-card-tasks>
        </ng-container>

    </div>
</div>

<ng-template #imageModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations['bpmn-card'].process_schema }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img [src]="imageToShow"
             width="980">
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>



