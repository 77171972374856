import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    HttpService,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
    UserMessagesService,
} from '@evolenta/core';
import { CommonUtilities, PrintUtilities } from '@evolenta/utilities';
import { EntityAdditionalDataComponent } from '@components/entity-additional-data/entity-additional-data.component';
import { Config } from '../../../common/services/config';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
    selector: 'entity-knd-kind-data',
    templateUrl: 'entity-knd-kind-data.component.html',
})
export class EntityKndKindDataComponent implements OnInit {
    @Input() public entity;
    @Input() public entityName;
    @Input() public kndKind;
    @Input() public collection;
    @Input() public entityType;

    public entityTypeId;
    public entityTypeName;
    public entityTypesName;
    public entityCollection;

    public currentOrganization = this.storage.getItem('currentOrganization');
    public xsdModel = {};

    @Output() public onApply = new EventEmitter<any>();
    @Output() public onReceiveEntityType = new EventEmitter<any>();

    @ViewChild('additionalData', { static: false }) public additionalDataComponents: EntityAdditionalDataComponent; // Компонент - карточка редактирования документа дела

    public editedKndKind = null;
    public data: any = {};

    public isProcessingCalculateRisk = false;
    public isProcessingLoadData = true;

    public baseData;
    public entityFederalXsd;
    public entityRegionalXsd;

    public isEditRiskCategory = false;
    public isApproveRiskCategory = false;
    public riskCategoryTheme = ['danger', 'warning', 'orange', 'violet', 'teal', 'green'];
    public usedRiskCategories = [];
    public riskCategories = this.storage.getItem('riskCategories');
    public showRiskChangeHistory = false;
    public newCalculatedValue;
    public localizations;

    public constructor(
        private rest: RestService,
        private toaster: ToasterService,
        private storage: StorageService,
        private httpService: HttpService,
        private userMessagesService: UserMessagesService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this._loadTranslations();
        this.entityTypeName = this.entityName === 'object' ? 'objectType' : 'subjectType';
        this.entityTypesName = this.entityName === 'object' ? 'objectTypes' : 'subjectTypes';
        this.entityCollection = this.entityName === 'object' ? 'objectsKno' : 'subjectsKno';
        this.entityTypeId = this.entityType.id;

        this.riskCategories.sort((a, b) => {
            if (a.code > b.code) {
                return 1;
            } else if (a.code < b.code) {
                return -1;
            }
            return 0;
        });
        this.prepareRiskCategories();
        const params = [
            {
                field: 'entityId',
                operator: 'eq',
                value: this.entity._id,
            },
            {
                field: 'entityName',
                operator: 'eq',
                value: this.entityName,
            },
            {
                field: 'kndKindId',
                operator: 'eq',
                value: this.kndKind._id,
            },
            {
                field: 'unitId',
                operator: 'eq',
                value: this.currentOrganization._id,
            },
        ];
        this.rest.search('entitiesKndKnoData', { search: { search: params } }).then((result) => {
            if (result && result.length > 0) {
                this.data = result[0];
            } else {
                this.data = {
                    entityId: this.entity._id,
                    entityName: this.entityName,
                    kndKindId: this.kndKind._id,
                    unitId: this.currentOrganization._id,
                };
            }
            if (!this.data.riskCalculationHistory) {
                this.data.riskCalculationHistory = [];
            }
            if (!this.data.riskChangeHistory) {
                this.data.riskChangeHistory = [];
            }
            if (this.data.riskChangeApproval) {
                this.isApproveRiskCategory = true;
            }
            this.isProcessingLoadData = false;
            this.baseData = _.cloneDeep(this.data);
            this.getXsd();
            this.checkNeedApproveCalculatedValue();
        });
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals.documents', 'entity-knd-kind-data']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public prepareRiskCategories() {
        if (this.entity.type && this.currentOrganization.kndKinds) {
            const findKndKind = this.currentOrganization.kndKinds.find((item) => item.id === this.kndKind._id);
            const param = this.entityName === 'object' ? 'objectTypes' : 'subjectTypes';
            if (findKndKind[param]) {
                const findEntity = findKndKind[param].find((item) => item.id === this.entity.type.id);
                if (findEntity && findEntity.riskCategories && findEntity.riskCategories.length > 0) {
                    this.usedRiskCategories = this.riskCategories.filter(
                        (item) => findEntity.riskCategories.indexOf(item.code) !== -1
                    );
                }
            }
        }
        if (this.usedRiskCategories.length === 0) {
            this.usedRiskCategories = this.riskCategories;
        }
    }

    public getXsd() {
        const params = [
            {
                field: 'entityId',
                operator: 'eq',
                value: this.entityTypeId,
            },
            {
                field: 'entityName',
                operator: 'eq',
                value: this.entityTypeName,
            },
            {
                field: 'unitId',
                operator: 'in',
                value: [null, this.currentOrganization._id],
            },
            {
                field: 'kndKindId',
                operator: 'eq',
                value: this.kndKind._id,
            },
        ];
        this.rest.search('entitiesXsd', { search: { search: params } }).then((response) => {
            if (response && response.length > 0) {
                const findFederal = response.find((item) => !item.unitId);
                if (findFederal) {
                    if (!this.data.federalAdditionalData) {
                        this.data.federalAdditionalData = {};
                    }
                    this.entityFederalXsd = findFederal.xsd;
                }
                const findRegional = response.find((item) => item.unitId);
                if (findRegional) {
                    if (!this.data.regionalAdditionalData) {
                        this.data.regionalAdditionalData = {};
                    }
                    this.entityRegionalXsd = findRegional.xsd;
                }
            }
        });
    }

    /**
     * Расчет категории риска
     * @param isCheckResponse - проверять ответ от сервера
     */
    public calculateRisk(isCheckResponse = true) {
        return this.save().then(() => {
            this.isProcessingCalculateRisk = true;
            const findKndKind = this.currentOrganization.kndKinds.find((item) => item.id === this.kndKind._id);
            const findEntityType = findKndKind[this.entityTypesName].find((item) => item.id === this.entityTypeId);
            return this.rest.find('sperBpmn', findEntityType.bpmn).then((bpmn: any) => {
                if (bpmn.deploymentId) {
                    const processCalculateGuid = CommonUtilities.GenerateGuid();
                    const params = {
                        mainId: this.entity._id,
                        guid: processCalculateGuid,
                        processDefinitionId: bpmn.deploymentId,
                        entityName: this.collection,
                        unitId: this.currentOrganization._id,
                        kndKindId: this.kndKind._id,
                        collectionName: this.entityCollection,
                        operationType: 'risks',
                    };
                    if (isCheckResponse) {
                        this.httpService.post(Config.server + Config.api + 'business/riskCalculations', params).then(
                            (result: any) => {
                                this.checkCompleteCalculateRisk(result.camundaBusinessInfoId, processCalculateGuid);
                            },
                            (error) => {
                                this.isProcessingCalculateRisk = false;
                                this.toaster.error(error.description);
                            }
                        );
                    } else {
                        return this.httpService.post(Config.server + Config.api + 'business/riskCalculations', params);
                    }
                }
            });
        });
    }

    public checkCompleteCalculateRisk(camundaBusinessInfoId, guid) {
        this.rest.find('camundaBusinessInfo', camundaBusinessInfoId).then((result: any) => {
            if (result) {
                const camundaEndEvents = result.endEvents;
                const findProcess = camundaEndEvents.find((item) => item.parameters && item.parameters.guid === guid);
                if (findProcess) {
                    this.processingCalculateRiskResult(true);
                } else {
                    setTimeout(() => {
                        this.checkCompleteCalculateRisk(camundaBusinessInfoId, guid);
                    }, 3000);
                }
            } else {
                setTimeout(() => {
                    this.checkCompleteCalculateRisk(camundaBusinessInfoId, guid);
                }, 3000);
            }
        });
    }

    public processingCalculateRiskResult(notificateComplete = false) {
        this.rest.find('entitiesKndKnoData', this.data._id).then((updatedData: any) => {
            this.data.riskCalculationHistory = updatedData.riskCalculationHistory;
            this.checkNeedApproveCalculatedValue();
            if (notificateComplete) {
                this.isProcessingCalculateRisk = false;
                this.toaster.success(
                    this.localizations['entity-knd-kind-data'].notifications.hazardous_class_calculated
                );
            }
        });
    }

    public saveCheckingPersons(persons) {
        this.data.checkingPersons = persons;
        this.save();
    }

    public checkAllowCalculateRisk() {
        if (this.currentOrganization.kndKinds) {
            const type = this.entityTypesName;
            const find = this.currentOrganization.kndKinds.find((item) => item.id === this.kndKind._id);
            if (find[type] && find[type].length > 0) {
                const findEntityType = find[type].find((item) => item.id === this.entityTypeId);
                return findEntityType && findEntityType.bpmn;
            }
        }
        return false;
    }

    public save() {
        let dataForSave = _.cloneDeep(this.data);
        if (this.data._id) {
            dataForSave = {};
            // Пробегаемся по полям стандарта сравнения с аналогичными полями базового стандарта
            Object.keys(this.data).forEach((item) => {
                if (JSON.stringify(this.data[item]) !== JSON.stringify(this.baseData[item])) {
                    dataForSave[item] = this.data[item];
                }
            });
            if (Object.keys(dataForSave).length > 0) {
                dataForSave._id = this.data._id;
            }
        }
        if (!this.data._id || Object.keys(dataForSave).length > 0) {
            const method = dataForSave._id ? 'update' : 'create';
            return this.rest[method]('entitiesKndKnoData', dataForSave).then((savedData) => {
                this.data = Object.assign(this.data, savedData);
                this.baseData = _.cloneDeep(this.data);
                return Promise.resolve(this.data);
            });
        } else {
            return Promise.resolve(this.data);
        }
    }

    public apply() {
        return this.save().then(() => {
            return this.checkAutoCalculateRisk().then(() => {
                this.notifyInspectors();
                this.onApply.emit(true);
                return Promise.resolve(this.data);
            });
        });
    }

    private _getOfCheckObjectMessage() {
        const template = this.localizations['entity-knd-kind-data'].of_check_object;
        return template.replace('%a', this.entity.name).replace('%b', this.getAddress(this.entity.baseAddress));
    }

    private _getOfCheckSubjectMessage() {
        const template = this.localizations['entity-knd-kind-data'].of_check_subject;
        return template.replace('%s', this.entity.shortHeader);
    }

    private _getAssignedAsInspectorMessage(text: string) {
        const template = this.localizations['entity-knd-kind-data'].you_assigned_as_inspector_for;
        return template.replace('%s', text);
    }

    private _getAssignedAsInpectorOfInpectorMessage(textA: string, textB: string) {
        const template = this.localizations['entity-knd-kind-data'].you_assigned_as_inspector_of_inspector;
        return template.replace('%a', textA).replace('%b', textB);
    }

    public notifyInspectors() {
        let text;
        let routeNode;
        if (this.entityName === 'object') {
            text = this._getOfCheckObjectMessage();
            routeNode = 'objects';
        } else {
            text = this._getOfCheckSubjectMessage();
            routeNode = 'persons';
        }
        if (this.data.checkingPersons && this.data.checkingPersons.length > 0) {
            const beforeInspectors = this.baseData.checkingPersons ? this.baseData.checkingPersons : [];
            this.data.checkingPersons.forEach((data) => {
                const findMain = beforeInspectors.find((item) => item.mainPerson[0].id === data.mainPerson[0].id);
                if (!findMain) {
                    const messageOne = this._getAssignedAsInspectorMessage(text);
                    this.userMessagesService.createMessage(
                        messageOne,
                        'registers',
                        'linkEntity',
                        { id: data.mainPerson[0].id, name: data.mainPerson[0].text },
                        ['registers', routeNode, 'edit', this.entity._id],
                        false
                    );
                }
                if (data.replacingPerson && data.replacingPerson[0]) {
                    const findReplacing = beforeInspectors.find(
                        (item) =>
                            item.mainPerson[0].id === data.mainPerson[0].id &&
                            item.replacingPerson[0].id === data.replacingPerson[0].id
                    );
                    if (!findReplacing) {
                        const messageTwo = this._getAssignedAsInpectorOfInpectorMessage(data.mainPerson[0].text, text);
                        this.userMessagesService.createMessage(
                            messageTwo,
                            'registers',
                            'linkEntity',
                            { id: data.replacingPerson[0].id, name: data.replacingPerson[0].text },
                            ['registers', routeNode, 'edit', this.entity._id],
                            false
                        );
                    }
                }
            });
        }
    }

    public checkAutoCalculateRisk() {
        const kndKindInOrganization = this.currentOrganization.kndKinds.find((item) => item.id === this.kndKind._id);
        const entityInOrganization = kndKindInOrganization[this.entityTypesName].find(
            (item) => item.id === this.entityTypeId
        );
        if (entityInOrganization.autoCalculateRiskCategoryOnChange) {
            return this.calculateRisk(false);
        } else {
            return Promise.resolve({});
        }
    }

    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    public getRiskCategoryBackground(categoryCode) {
        if (typeof categoryCode === 'string') {
            categoryCode = parseInt(categoryCode, 10);
        }
        return 'bg-' + this.riskCategoryTheme[categoryCode - 1] + '-600';
    }

    public selectRiskCategory(category) {
        const currentUser = this.storage.getItem('user');

        this.isApproveRiskCategory = true;

        this.data.riskChangeApproval = {
            value: category.code,
            date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            user: {
                id: currentUser._id,
                name: currentUser.name,
                login: currentUser.login,
            },
        };

        this.isEditRiskCategory = false;
        this.save();
    }

    public approveRiskCategory() {
        this.isApproveRiskCategory = false;

        this.data.riskCategory = this.riskCategories.find((item) => item.code === this.data.riskChangeApproval.value);
        this.addRiskChangeHistory('manually', this.data.riskChangeApproval);

        this.data.riskChangeApproval = null;
        this.save();
    }

    public discardRiskCategory() {
        this.isApproveRiskCategory = false;
        this.data.riskChangeApproval = null;
        this.save();
    }

    public addRiskChangeHistory(type, changeData) {
        const currentUser = this.storage.getItem('user');
        this.data.riskChangeHistory.push({
            guid: CommonUtilities.GenerateGuid(),
            date: changeData ? changeData.date : moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            dateApprove: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            user: changeData
                ? changeData.user
                : {
                      id: currentUser._id,
                      name: currentUser.name,
                      login: currentUser.login,
                  },
            userApprove: {
                id: currentUser._id,
                name: currentUser.name,
                login: currentUser.login,
            },
            type: type,
            value: changeData.value,
        });
    }

    public getRiskCategoryNameByCode(code) {
        return this.riskCategories.find((item) => item.code === code.toString()).name;
    }

    public checkNeedApproveCalculatedValue() {
        if (this.data.riskCalculationHistory && this.data.riskCalculationHistory.length > 0) {
            const lastItem = this.data.riskCalculationHistory[this.data.riskCalculationHistory.length - 1];
            if (!lastItem.hasOwnProperty('isApprove') && !lastItem.hasOwnProperty('isCancel')) {
                this.newCalculatedValue = lastItem;
            }
        }
    }

    public applyCalculatedRisk(isApply) {
        if (isApply) {
            this.newCalculatedValue.isApprove = true;
            this.data.riskCategory = this.riskCategories.find(
                (item) => item.code === this.newCalculatedValue.value.toString()
            );
            this.addRiskChangeHistory(this.newCalculatedValue.type, this.newCalculatedValue);
            this.newCalculatedValue = null;
        } else {
            this.newCalculatedValue.isCancel = true;
            this.newCalculatedValue = null;
        }

        this.save();
    }
}
