<div class="panel panel-white" [ngClass]="{'no-border-top no-border-right no-border-left no-border-radius': mode == 'edit'}">
    <div class="panel-heading">
        <h6 class="panel-title cursor-pointer">
            Сообщение # <span *ngIf="message.auid">{{message.auid}}</span><span class="text-muted" *ngIf="!message.auid">n/a</span>
            <span class="badge badge-flat ml-15 text-uppercase border-primary text-primary-600" *ngIf="statuses">{{getStatusName()}}</span>
        </h6>
        <div class="heading-elements">
            <button class="btn btn-primary heading-btn btn-labeled btn-labeled-right"
                    (click)="edit()" *ngIf="mode == 'view'">
                <b><i [ngClass]="{'icon-pencil3' : message.status.name == 'draft', 'icon-eye': message.status.name != 'draft'}"></i></b>
                <span *ngIf="message.status.name == 'draft'">Редактировать</span>
                <span *ngIf="message.status.name != 'draft'">Просмотреть</span>
            </button>
            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right"
                    (click)="complete()" *ngIf="mode == 'view' && message.status.name == 'draft'">
                <b><i class="icon-file-check"></i></b>
                Сформировать
            </button>
            <button class="btn btn-danger heading-btn btn-labeled btn-labeled-right"
                    (click)="deleteEnvelope()" *ngIf="mode == 'view' && message.status.name == 'draft'">
                <b><i class="icon-trash"></i></b>
                Удалить
            </button>
            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right"
                    (click)="apply()" *ngIf="mode == 'edit' && message.status.name == 'draft'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
            <button class="btn bg-orange heading-btn btn-labeled btn-labeled-right"
                    (click)="cancel()" *ngIf="mode == 'edit'">
                <b><i class="icon-cross2"></i></b>
                <ng-container *ngIf="message.status.name == 'draft'">Отменить</ng-container>
                <ng-container *ngIf="message.status.name != 'draft'">Закрыть</ng-container>
            </button>
        </div>
    </div>
    <div class="panel-body no-padding">
        <div class="pt-10 pr-20 pl-20 pb-10">
            <p class="mb-3">
                <span class="text-muted">Вариант оповещения: </span>
                <ng-container *ngIf="mode == 'edit'">
                    <span class="text-info anchor cursor-pointer"
                          *ngIf="!message.type" (click)="isProcessSelectMessageType = true">не выбран</span>
                    <span *ngIf="message.type">{{message.type.name}}</span>
                    <span class="text-info anchor cursor-pointer ml-5 text-size-small"
                          *ngIf="message.type" (click)="isProcessSelectMessageType = true">изменить</span>
                </ng-container>
                <ng-container *ngIf="mode == 'view'">
                    <span *ngIf="!message.type">не выбран</span>
                    <span *ngIf="message.type">{{message.type.name}}</span>
                </ng-container>
            </p>

            <!-- Выбор варианта оповещения -->
            <div class="mt-10 pt-10 pl-20 pr-20 pb-5 bg-primary-50" *ngIf="isProcessSelectMessageType">
                <div class="text-semibold mb-10">Выберите вариант оповещения:</div>
                <div class="radio-block" *ngFor="let type of notificationTypes">
                    <input type="radio" class="radio" name="kind"
                           [checked]="message.type && message.type.code == type.code"
                           (click)="selectType(type)" id="type{{type.code}}">
                    <label for="type{{type.code}}">{{type.name}}</label>
                </div>
            </div>

            <ng-container *ngIf="message.type">

                <!-- Участник -->
                <p class="mb-3" [ngClass]="{}">
                    <span class="text-muted">Участник: </span>
                    <ng-container *ngIf="mode == 'edit'">
                        <span class="text-info anchor cursor-pointer"
                          *ngIf="!message.person" (click)="isProcessSelectMessagePerson = true">не выбран</span>
                        <span *ngIf="message.person">{{message.person.header}}</span>
                        <span class="text-info anchor cursor-pointer ml-5 text-size-small" (click)="isProcessSelectMessagePerson = true"
                              *ngIf="message.person && appealPersons.length > 1">изменить</span>
                    </ng-container>
                    <ng-container *ngIf="mode == 'view'">
                        <span *ngIf="!message.person">не выбран</span>
                        <span *ngIf="message.person">{{message.person.header}}</span>
                    </ng-container>
                </p>

                <!-- Выбор участника дела -->
                <div class="mt-10 pt-10 pl-20 pr-20 pb-5 bg-primary-50" *ngIf="isProcessSelectMessagePerson && mode === 'edit'">
                    <div class="text-semibold mb-10">Выберите участника дела:</div>
                    <div class="radio-block" *ngFor="let person of appealPersons">
                        <input type="radio" class="radio" name="person"
                               [checked]="message.person && message.person.guid == person.guid"
                               (click)="selectPerson(person)" id="person{{person.guid}}">
                        <label for="person{{person.guid}}">{{person.header}}</label>
                    </div>
                </div>

                <!-- Шаблон -->
                <p class="mb-3" *ngIf="mode == 'edit' && message.person">
                    <span class="text-muted">Шаблон сообщения: </span>
                    <span class="text-info anchor cursor-pointer"
                          *ngIf="!message.template" (click)="isProcessSelectMessageTemplate = true">не выбран</span>
                    <span *ngIf="message.template">{{message.template.name}}</span>
                    <span class="text-info anchor cursor-pointer ml-5 text-size-small"
                          (click)="isProcessSelectMessageTemplate = true"
                          *ngIf="message.template && templates.length > 1">изменить</span>
                </p>
                <!-- Выбор шаблона сообщения -->
                <div class="mt-10 pt-10 pl-20 pr-20 pb-5 bg-primary-50" *ngIf="isProcessSelectMessageTemplate && mode === 'edit'">
                    <div class="text-semibold mb-10">Выберите шаблон сообщения:</div>
                    <div class="radio-block" *ngFor="let template of templates">
                        <input type="radio" class="radio" name="template"
                               [checked]="message.template && message.template.id == template._id"
                               (click)="selectTemplate(template)" id="template{{template._id}}">
                        <label for="template{{template._id}}">{{template.name}}</label>
                    </div>
                </div>

                <!-- Текст сообщения -->
                <ng-container *ngIf="message.person">
                    <div class="form-group" *ngIf="mode == 'edit'">
                        <label>Текст сообщения</label>
                        <textarea [(ngModel)]="message.message" class="form-control"></textarea>
                    </div>
                    <ng-container *ngIf="mode == 'view'">
                        <span class="text-muted">Текст сообщения</span>
                        <span *ngIf="!message.message">не введен</span>
                        <blockquote *ngIf="message.message">{{message.message}}</blockquote>
                    </ng-container>
                </ng-container>

            </ng-container>
        </div>
    </div>
</div>
