<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">Отправка в ФГИС ЕРП <span *ngIf="appeal.numberErp">№ {{appeal.numberErp}}</span></h4>
    </div>
    <div class="panel-body no-padding">
        <table class="table">
            <thead>
                <tr>
                    <th class="text-bold">Запрос</th>
                    <th class="text-bold">Дата отправки</th>
                    <th class="text-bold">Дата получения результата</th>
                    <th class="text-bold">Статус</th>
                    <th class="text-bold">Вид операции</th>
                    <th class="text-bold">Описание</th>
                </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of sendData">
                <td>
                    <span class="anchor text-info" (click)="goToEnvelope(item)">{{item.erpEnvelopeAuid ? item.erpEnvelopeAuid : 'запрос'}}</span>
                </td>
                <td>
                    <ng-container *ngIf="item.dateSend">{{item.dateSend | date: 'shortDate'}} {{item.dateSend | date: 'shortTime'}}</ng-container>
                </td>
                <td>
                    <ng-container *ngIf="item.dateResult">{{item.dateResult | date: 'shortDate'}} {{item.dateResult | date: 'shortTime'}}</ng-container>
                </td>
                <td>
                    <span class="badge text-uppercase" [ngClass]="appealStatusService.getErpSendStatusProperty(item, 'background')">{{appealStatusService.getErpSendStatusProperty(item, 'name')}}</span>
                </td>
                <td>
                    <ng-container *ngIf="item.erpType">{{getType(item)}}</ng-container>
                </td>
                <td>
                    <ng-container *ngIf="item.description">{{item.description}}</ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
