import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class Config {
    public applicationRoutes = {
        devtools: 'dev',
        ais: 'ais',
    };

    public static get app(): string {
        return environment.base;
    }

    /**
     * @returns {string}
     */
    public static get api(): string {
        return 'api/v1/';
    }

    public static get tatarApi(): string {
        return 'api/tatar/v1/';
    }

    public static get smev(): string {
        return 'smev-client/';
    }

    public static get mfc(): string {
        return 'mfc/';
    }

    /**
     *
     * @returns {string}
     */
    public static get ctoAsterisk(): string {
        return 'cto-asterisk';
    }

    /**
     * @returns {string}
     */
    public static get server(): string {
        return environment.url;
    }

    public static get octonicaUrl(): string {
        return 'https://cit-front.demo.octonica.com/';
    }

    public static get rootUrl(): string {
        return $('base').attr('href');
    }

    public static get crypto(): string {
        // return 'http://192.168.1.74:8090/scanmodule';
        return 'http://127.0.0.1:8090/scanmodule';
    }

    // методы
    public static get search(): string {
        return 'search/';
    }

    public static get create(): string {
        return 'create/';
    }

    public static get update(): string {
        return 'update/';
    }

    public static get find(): string {
        return 'find/';
    }

    public static get delete(): string {
        return 'delete/';
    }

    public static get assignNumbers(): string {
        return 'assignNumbers/';
    }

    public static get calculateDate(): string {
        return 'calculateDate/';
    }

    // константы
    public static get collectionsMeta(): string {
        return 'collectionsMeta';
    }

    public static get entitiesMeta(): string {
        return 'entitiesMeta';
    }

    public static get projections(): string {
        return 'projections';
    }

    public static get methods(): string {
        return 'methods';
    }

    public static get permissions(): string {
        return 'permissions';
    }

    public static get applications(): string {
        return 'applications';
    }

    public getApp(app) {
        return this.applicationRoutes[app];
    }

    // Методы плагина подписания для Оренбурга
    // Подписание файлов - получение статуса по ping
    public static get signApplicationSignFiles(): string {
        return 'signFiles';
    }

    // Подписание части xml пользовательской подписью - получение статуса по ping
    public static get signApplicationSignXml(): string {
        return 'signXml';
    }

    // Получение состояния подписываемой задачи
    public static get signApplicationPing(): string {
        return 'ping';
    }

    // Подписание ЭП-ОВ - получение статуса по ping
    public static get signApplicationSignSystemXml(): string {
        return 'signSystemXml';
    }

    // Авторизация пользовательским сертификатом
    public static get signApplicationAuth(): string {
        return 'auth';
    }

    // Расширенное подписание xml - получение статуса по ping
    public static get signApplicationSignXmlDsig(): string {
        return 'signXml';
    }

    // Подписание хэша файла
    public static get signApplicationSignHash(): string {
        return 'signHash';
    }

    // Проверка открепленной подписи - получение статуса по ping
    public static get signApplicationVerify(): string {
        return 'verify';
    }

    // Проверка подписей xml-документа
    public static get signApplicationVerifyDocument(): string {
        return 'verifyDocument';
    }

    // Валидация открепленной подписи
    public static get signApplicationValidateFileSignature(): string {
        return 'validateFileSignature';
    }

    // Режим демонстрации
    public static get demoMode(): boolean {
        return true;
    }
}
