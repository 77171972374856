import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TasksService } from '../../../tasks.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'sub-tasks-card',
    templateUrl: 'sub-task-card.component.html',
})
export class SubTaskCardComponent implements OnInit {
    @Input() public subTask;
    @Input() public usersForSelect;
    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onRemove = new EventEmitter<any>();

    public localizations;

    public constructor(
        public taskService: TasksService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
    }

    public _loadTranslations() {
        this.translate.get(['common', 'tasks']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public removeTask(task) {
        this.onRemove.emit(task);
    }

    public editTask(task) {
        this.onEdit.emit(task);
    }

    public getAssigneeName() {
        const item = this.usersForSelect.find((v) => v.login === this.subTask.assignee);
        return item ? item.name : '-';
    }
}
