import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService, SelectionService } from '@evolenta/core';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { RegistersService } from '../../../registers/registers.service';

@Component({
    selector: 'common-appeal-licenses',
    templateUrl: 'common-appeal-licenses.component.html',
    styles: [
        'legend .anchor { text-transform: none; font-weight: normal; }',
        'legend .text-size-mini { text-transform: none; font-weight: normal; }',
        '.text-normal { text-transform: none; font-weight: normal; }',
    ],
})
export class CommonAppealLicensesComponent implements OnInit {
    @Input() public appeal;
    @Input() public subservice;
    @Input() public allowEdit = true;
    @Input() public savedPlace;

    public mainElement;
    public registersModel;
    public appealSubservice;
    public externalData;
    public moduleBaseUrl;

    public licenses = [];

    public showLicenses = false;

    public constructor(
        public commonAppealService: CommonAppealService,
        public validateService: CommonAppealValidateService,
        private selectionService: SelectionService,
        private router: Router,
        private saveService: CommonAppealSaveService,
        private restService: RestService,
        private route: ActivatedRoute,
        private registersService: RegistersService,
    ) {
    }

    /**
     * Инициализация компонента, получение данных
     */
    public async ngOnInit() {
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.appealSubservice = this.appeal.subservice;

        this.getRegistryModel();
        this.initRegistersData();

        if (this.selectionService.isProcessSelect && this.selectionService.transferOperation === 'selectLicenses') {
            await this.__completeSelectLicenses();
        }

        this.allowEdit = this.appeal.status.code === 'draft';
    }

    public getRegistryModel() {
        if (this.subservice.registersModel) {
            this.registersModel = this.subservice.registersModel;
        } else if (this.subservice.mainElement) {
            this.registersModel = {
                registryType: this.subservice.mainElement,
                operationType: this.subservice.additionalParams ? this.subservice.additionalParams.operationType : null,
            };
        }
    }

    public initRegistersData() {
        this.appealSubservice = this.appeal.subservice;
        this.mainElement = this.appealSubservice.mainElement;

        if (Object.keys(this.savedPlace).length === 0) {
            const registryEntryType = this.registersModel.registryType;
            delete registryEntryType._id;
            const mainElement: any = {
                registryEntryType: registryEntryType, // вид лицензионной деятельности
                operationType: this.registersModel.operationType.code,
                titles: this.prepareTitles(),
                xsd: this.subservice.xsd ? this.subservice.xsd : null, // xsd основных данных
                xsdData: {}, // данные по основной XSD
                objectXsd: this.subservice.objects.objectsData && this.subservice.objects.objectsData.xsd ? this.subservice.objects.objectsData.xsd : null, // xsd для объектов
                objectMainXsd: this.subservice.objects.objectsData && this.subservice.objects.objectsData.mainXsd ? this.subservice.objects.objectsData.mainXsd : null, // xsd для объектов
                subjectXsd: this.subservice.objects.subjectsData && this.subservice.objects.subjectsData.xsd ? this.subservice.objects.subjectsData.xsd : null, // xsd для субъекта
                subjectMainXsd: this.subservice.objects.subjectsData && this.subservice.objects.subjectsData.mainXsd ? this.subservice.objects.subjectsData.mainXsd : null, // xsd для субъекта
            };

            if (this.registersModel.selectMultipleLicenses) {
                mainElement.licenses = [];
            }

            if (this.registersModel.registryParams) {
                mainElement.registryParams = this.registersModel.registryParams;
            }

            if (this.registersModel.registryUrlForQR) {
                mainElement.registryUrlForQR = this.registersModel.registryUrlForQR;
            }

            this.mainElement = Object.assign(this.mainElement, mainElement);
        } else {
            if (this.mainElement.mainXsd && !this.mainElement.xsd) {
                this.mainElement.xsd = this.mainElement.mainXsd;
            }
            if (this.mainElement.mainXsdData && !this.mainElement.xsdData) {
                this.mainElement.xsdData = this.mainElement.mainXsdData;
            }

            if (this.registersModel.registryParams && !this.mainElement.registryParams) {
                this.mainElement.registryParams = this.registersModel.registryParams;
            }

            if (this.registersModel.registryUrlForQR) {
                this.mainElement.registryUrlForQR = this.registersModel.registryUrlForQR;
            }

            if (this.mainElement.licenseId && !this.mainElement.noLicense
                || this.mainElement.licenses && this.mainElement.licenses.length > 0) {
                this.getLicensesInfo();
            }
        }
    }

    public prepareTitles() {
        return {
            common: this.registersModel.titles,
            subject: this.subservice.objects.subjectsData ? this.subservice.objects.subjectsData.titles : {},
            object: this.subservice.objects.objectsData ? this.subservice.objects.objectsData.titles : {},
        };
    }

    public getLicensesInfo() {
        const promises = [];

        if (this.mainElement.licenses && this.mainElement.licenses.length > 0) {
            this.mainElement.licenses.forEach(license => {
                if (license.licenseId && !license.license) {
                    promises.push(this.getLicenseInfo(license));
                }
            });
        } else if (this.mainElement.licenseId && !this.mainElement.license) {
            promises.push(this.getLicenseInfo(this.mainElement));
        }

        return Promise.all(promises);
    }

    public getLicenseInfo(licenseData) {
        const licensesCollection = this.registersService.getRegistryCollection(this.commonAppealService.metaReglament, 'licensesCollection');
        // const registersCollection = this.registersService.getRegistryCollection(this.commonAppealService.metaReglament, 'registersCollection');
        const registersModel = this.commonAppealService.metaReglament.blocks.find(item => item.code === 'registersModel');
        const statuses = registersModel.licenseStatuses;

        return this.restService.find(licensesCollection, licenseData.licenseId).then((licenseInfo: any) => {
            if (licenseInfo.status !== 'draft') {
                const status = statuses.find(item => item.code === licenseInfo.status);

                licenseData.license = {
                    number: licenseInfo.number,
                    date: licenseInfo.dateIssued,
                    status: licenseInfo.status,
                    statusName: status ? status.name : '',
                    orderData: licenseInfo.orderData ? licenseInfo.orderData : null,
                };

                return Promise.resolve(true);
            } else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        console.log('repeat get license info');
                        resolve(this.getLicenseInfo(licenseData));
                    }, 2000);
                });
            }
        });
    }

    public async goToLicense(license) {
        await this.router.navigate([this.moduleBaseUrl, this.commonAppealService.registersPath, 'edit', license.licenseId]);
    }

    public async selectLicenses() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.commonAppealService.appeal;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectLicenses';
        this.selectionService.selectType = this.registersModel.selectMultipleLicenses ? 'many' : 'one';

        if (this.mainElement.operationType === 'annul') {
            this.selectionService.baseSearch = [
                {
                    field: 'status',
                    operator: 'in',
                    value: ['active', 'paused'],
                },
            ];
        }

        if (this.mainElement.operationType === 'renewal' && this.mainElement.otherRegionLicense && this.mainElement.otherRegion) {
            this.selectionService.baseSearch = [
                {
                    field: 'units.region.code',
                    operator: 'eq',
                    value: this.mainElement.otherRegion.code,
                },
            ];
        }

        if (this.mainElement.operationType === 'renewal') {
            const params = {
                field: 'status',
                operator: 'neq',
                value: 'merged',
            };

            if (Array.isArray(this.selectionService.baseSearch) && this.selectionService.baseSearch.length) {
                this.selectionService.baseSearch.push(params);
            } else {
                this.selectionService.baseSearch = [params];
            }
        }

        await this.router.navigate([this.moduleBaseUrl, this.commonAppealService.registersPath]);
    }

    private async __completeSelectLicenses() {
        if (this.selectionService.selectedItems && this.selectionService.selectedItems.length > 0) {
            if (this.registersModel.selectMultipleLicenses) {
                for (const selectedLicense of this.selectionService.selectedItems) {
                    const find = this.mainElement.licenses.find(item => item.licenseId === selectedLicense._id);
                    if (!find) {
                        const licenseData = await this._completeSelectLicense(selectedLicense);
                        this.mainElement.licenses.push(licenseData);
                        this._updateSubjectsObjectsInAppealAfterSelectLicense(licenseData);
                    }
                }
            } else {
                const licenseData = await this._completeSelectLicense(this.selectionService.selectedItems[0]);
                this.mainElement = Object.assign(this.mainElement, licenseData);
                this._updateSubjectsObjectsInAppealAfterSelectLicense(licenseData);
            }
        }
        console.log('after select', this.mainElement);
        this.selectionService.clearData();
    }

    private _updateSubjectsObjectsInAppealAfterSelectLicense(licenseData) {
        if (licenseData.prevRegister) {
            if (licenseData.prevRegister.subject) {
                this.appeal.subjects.push(licenseData.prevRegister.subject);
            }

            if (licenseData.prevRegister.objects) {
                this.appeal.objects = this.appeal.objects.concat(licenseData.prevRegister.objects);
            }
        }
    }

    private async _completeSelectLicense(license) {
        const registersModel = this.commonAppealService.metaReglament.blocks.find(item => item.code === 'registersModel');
        const registersCollection = registersModel.registersCollection;
        const statuses = registersModel.licenseStatuses;
        const status = statuses.find(item => item.code === license.status);
        const licenseData: any = {
            licenseId: license._id,
            license: {
                number: license.number,
                date: license.dateIssued,
                status: license.status,
                statusName: status ? status.name : '',
                orderData: license.orderData ? license.orderData : null,
            },
        };
        const params = [{
            field: 'licenseId',
            operator: 'eq',
            value: license._id,
        }];

        return this.restService.search(registersCollection, {
            search: { search: params },
            sort: 'dateCreation,DESC',
            size: 1,
        }).then(registers => {
            const prevRegister = registers[0];

            licenseData.prevRegisterId = prevRegister._id;
            licenseData.prevRegister = {
                subject: prevRegister.subject ? prevRegister.subject : null,
                objects: prevRegister.objects ? prevRegister.objects : null,
            };

            if (prevRegister.orderData) {
                licenseData.license.registryOrderData = prevRegister.orderData;
            }

            return Promise.resolve(licenseData);
        });
    }

    public async saveAppeal() {
        if (this.appeal._id) {
            await this.saveService.saveAppeal();
        }
    }

    public removeLicense(license) {
        const idx = this.mainElement.licenses.findIndex(item => item.licenseId === license.licenseId);

        if (idx !== -1) {
            if (license.prevRegister) {
                if (license.prevRegister.subject) {
                    const findSubjectIdx = this.appeal.subjects.find(item => item.guid === license.prevRegister.subject);
                    if (findSubjectIdx !== -1) {
                        this.appeal.subjects.splice(findSubjectIdx, 1);
                    }
                }

                if (license.prevRegister.objects && license.prevRegister.objects.length > 0) {
                    license.prevRegister.objects.forEach(object => {
                        const findObjectIdx = this.appeal.objects.find(item => item.guid === object.guid);

                        if (findObjectIdx !== -1) {
                            this.appeal.objects.splice(findObjectIdx, 1);
                        }
                    });
                }
            }

            this.mainElement.licenses.splice(idx, 1);
        }
    }
}
