import { Injectable } from '@angular/core';
import { RestService, ToasterService } from '@evolenta/core';

@Injectable()
export class ObjectsService {
    public copiedObjectData = null;

    public constructor(
        private rest: RestService,
        private toaster: ToasterService
    ) {}

    /**
     * Возвращает список связанных пользователей из реестра граждан и текущего обращения
     * @param appeal - обрабатываемое дело
     * @param relatedPersons - связанные пользователи
     * @param handling - текущее обращение
     * @returns {any}
     */
    public setListUsersForOperator(appeal, relatedPersons, handling) {
        let resultUsers = [];
        const promises = [];
        if (handling) {
            if (handling.persons) {
                handling.persons.forEach((person) => {
                    if (!relatedPersons.find((user) => user.guid === person.guid)) {
                        relatedPersons.push(person);
                    }
                });
            }
        }

        // не показываем тех, кто уже добавлен в дело
        appeal.objects.forEach((object) => {
            const userIndex = relatedPersons.findIndex((user) => user.guid === object.guid);
            if (userIndex !== -1) {
                relatedPersons.splice(userIndex, 1);
            }
        });

        // фильтруем тех чуваков, по которым у нас неполные данные
        relatedPersons.forEach((user, index) => {
            if (!user.data) {
                promises.push(this.rest.find('persons', user._id));
            } else {
                resultUsers.push(user);
            }
        });

        if (promises.length > 0) {
            return Promise.all(promises).then(
                (response) => {
                    resultUsers = resultUsers.concat(response);
                    return resultUsers;
                },
                (error) => {
                    Promise.reject(error);
                }
            );
        } else {
            return Promise.resolve(resultUsers);
        }
    }

    /**
     * Добавление линка на текущее дело при сохранении данных по пользователю в реестре
     * @param appeal - обрабатываемое дело
     * @param person - сохраняемый в реестре граждан клиент
     */
    public linkAppealToPerson(appeal, person) {
        const linkAppeal = {
            id: appeal._id,
            subServiceTitle: appeal.subServices ? appeal['subServices'][0]['title'] : appeal.subServiceTitle,
            dateCreation: appeal.dateCreation,
        };

        if (person.appeals) {
            if (!person.appeals.find((item) => item.id === appeal._id)) {
                person.appeals.push(linkAppeal);
            }
        } else {
            person.appeals = [];
            person.appeals.push(linkAppeal);
        }
    }

    /**
     * Сохранение объекта (клиента) в реестре граждан
     * @param appeal - обрабатываемое дело
     * @param object - сохраняемый объект
     */
    public savePersonToReestr(appeal, object) {
        // Параметры поиска по паспортным данным
        const identityDocumentParams = [
            {
                field: 'data.documentType.id',
                operator: 'eq',
                value: object.data.documentType[0].id,
            },
            {
                field: 'data.documentSeries',
                operator: 'eq',
                value: object.data.documentSeries,
            },
            {
                field: 'data.documentNumber',
                operator: 'eq',
                value: object.data.documentNumber,
            },
        ];
        // Параметры поиска по ФИО + ДР
        const fioAndBirthdayParams = [
            {
                field: 'data.lastName',
                operator: 'eq',
                value: object.data.lastName,
            },
            {
                field: 'data.firstName',
                operator: 'eq',
                value: object.data.firstName,
            },
            {
                field: 'data.middleName',
                operator: 'eq',
                value: object.data.middleName,
            },
            {
                field: 'data.birthday.formatted',
                operator: 'eq',
                value: object.data.formatted,
            },
        ];

        let findedUsers = [];

        // Сохраняемый объект
        let saveObject = {
            guid: object.guid,
            data: object.data,
            header: object.header ? object.header : null,
            shortHeader: object.shortHeader ? object.shortHeader : null,
            specialTypeId: object.specialTypeId,
        };
        // Ищем в базе клиента по паспортным данным
        this.rest.search('persons', { search: { search: identityDocumentParams } }).then((findedByPassport: any) => {
            if (findedByPassport.length === 0) {
                // Поиск не дал результатов, ищем по ФИО + ДР
                this.rest
                    .search('persons', { search: { search: fioAndBirthdayParams } })
                    .then((findedByFIOAndBirthday: any) => {
                        if (findedByFIOAndBirthday.length === 0) {
                            // Поиск не дал результатов, создаем объект в реестре
                            this.linkAppealToPerson(appeal, saveObject);
                            this.rest.create('persons', saveObject).then(
                                (createdPerson) => {
                                    console.log('Создан клиент в реестре: ' + createdPerson['shortHeader']);
                                },
                                (error) => {
                                    this.toaster.error(error);
                                }
                            );
                        } else {
                            // Есть совпадения, выводим модальное окно для подтверждения оператору (ФИО + ДР не уникальные данные)
                            this.linkAppealToPerson(appeal, saveObject);
                            findedUsers = findedByFIOAndBirthday;
                        }
                    });
            } else {
                // Найден клиент, обновляем его данные (считаем, что паспортные данные являются уникальным показателем клиента)
                saveObject = Object.assign(findedByPassport[0], saveObject);
                this.updatePersonInReestr(appeal, saveObject, false);
            }
        });
    }

    /**
     * Функция обновления данных участника-клиента в разделе "Клиенты"
     * @param appeal - обрабатываемое дело
     * @param object - обрабатываемый объект
     * @param compare
     */
    public updatePersonInReestr(appeal, object, compare) {
        if (compare) {
            // object = Object.assign(this.selectedFindUser, object);
        }
        this.linkAppealToPerson(appeal, object);
        this.rest.update('persons', object).then(
            () => {
                console.log('Данные клиент успешно обновлены в реестре');
            },
            (error) => {
                this.toaster.error(error);
            }
        );
    }
}
