<div class="panel panel-shadow m-20">
    <div class="panel-body">
        <fieldset>
            <legend class="text-bold">
                {{ localizations.appeals.status_processing }}
            </legend>
            <table class="table table-xs">
                <thead>
                <tr>
                    <td class="text-bold text-size-small">
                        {{ localizations.common.name }}
                    </td>
                    <td class="text-bold text-size-small">
                        {{ localizations.appeals.action_handler }}
                    </td>
                    <td class="text-bold text-size-small"
                        *ngIf="standard.appealData.saveOperationsHistory">
                        {{ localizations.appeals.history_value }}
                    </td>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let status of standard.appealData.statuses; let idx = index">
                    <tr>
                        <td>
                            <span class="badge bg-{{status.theme}}-300 text-uppercase">
                                {{status.name}}
                            </span>
                        </td>
                        <td>
                            <ng-container *ngIf="status.actions && status.actions.length > 0">
                                <ul>
                                    <li class="text-size-small"
                                        *ngFor="let action of status.actions">
                                        <span class="text-slate mr-10 text-bold">
                                            {{action.name}}
                                        </span>
                                        <evolenta-handlers
                                                [model]="action"
                                                [setStartEvent]="true"
                                                [noMargin]="true"
                                        ></evolenta-handlers>
                                    </li>
                                </ul>
                            </ng-container>
                        </td>
                        <td *ngIf="standard.appealData.saveOperationsHistory">
                            <input class="form-control"
                                   type="text"
                                   [(ngModel)]="status.historyText">
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </fieldset>

        <div class="checkbox-inline">
            <input id="saveOperationsHistory"
                   class="checkbox"
                   type="checkbox"
                   (change)="standard.appealData.saveOperationsHistory = !standard.appealData.saveOperationsHistory"
                   [checked]="standard.appealData.saveOperationsHistory">
            <label for="saveOperationsHistory">
                {{ localizations.appeals.save_operations_history }}
            </label>
        </div>

    </div>
</div>
