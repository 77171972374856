import { Injectable } from '@angular/core';
import { Permission } from '../../common/services/permission';
import { SubjectsComponent } from './subjects.component';
import { SubjectsResolve } from './subjects.resolve';
import { SubjectEditComponent } from './subject-edit/subject-edit.component';

@Injectable()
export class SubjectsRoutingData {
    public static childRoutingWithEdit = [
        {
            path: '',
            component: SubjectsComponent,
            data: {},
            resolve: {
                resolves: SubjectsResolve,
            },
        },
        {
            path: 'create',
            component: SubjectEditComponent,
            data: {
                name: 'Создать субъект',
                breadcrumb: 'Создать субъект',
            },
            resolve: {
                resolves: SubjectsResolve,
            },
        },
        {
            path: 'edit/:subjectId',
            component: SubjectEditComponent,
            data: {
                name: 'Настройка субъекта',
                breadcrumb: 'Настройка субъекта',
            },
            resolve: {
                resolves: SubjectsResolve,
            },
        },
        {
            path: 'edit/:subjectId/:versionId',
            component: SubjectEditComponent,
            data: {
                permissions: [Permission.Appeals_Search],
            },
            resolve: {
                resolves: SubjectsResolve,
            },
        },
    ];

    public static childRoutingSelect = [
        {
            path: '',
            component: SubjectsComponent,
            data: {
                name: 'Список субъектов',
            },
            resolve: {
                resolves: SubjectsResolve,
            },
        },
    ];
}
