import { Injectable } from '@angular/core';

@Injectable()
export class NsiService {
    public structureObject; // Объект, содержащий структуру справочников модуля NSI.
    // collectionsMeta = {}; // Описане коллекций: набор полей, json-cхема
    public collectionsMeta;

    // Структура справочников
    public get structure() {
        return this.structureObject ? this.structureObject.value : undefined;
    }

    // Описание колонок для грида по умолчанию
    public defaultGridColumnDefinition = [
        {
            field: 'code',
            headerName: 'Код',
            width: 40,
        },
        {
            field: 'name',
            headerName: 'Наименование',
        },
    ];

    // Схема по умолчанию для построения JSON-формы элемента справочника
    public defaultItemJson = {
        type: 'object',
        properties: {
            code: {
                type: 'string',
                title: 'Код',
                required: true,
                placeholder: 'Код элемента',
                columns: 3,
            },
            name: {
                type: 'string',
                required: true,
                title: 'Значение элемента',
                placeholder: 'Значение элемента',
                columns: 9,
            },
        },
    };

    // Схема по умолчанию для построения JSON-формы справочника
    public defaultDictionaryJson = {
        type: 'object',
        properties: {
            code: {
                type: 'string',
                title: 'Код',
                required: true,
                pattern: '^[A-Za-z]+$',
                placeholder: 'Код справочника',
                columns: 3,
                readonly: false,
            },
            name: {
                type: 'string',
                required: true,
                pattern: '^[A-Za-zА-ЯЁа-яё ]+$',
                title: 'Наименование',
                placeholder: 'Наименование справочника',
                columns: 9,
            },
            allowEdit: {
                type: 'boolean',
                title: 'Разрешить редактирование элементов',
                columns: 3,
                default: true,
            },
            allowDelete: {
                type: 'boolean',
                title: 'Разрешить удаление элементов',
                columns: 3,
                default: true,
            },
            isVersioning: {
                type: 'boolean',
                title: 'Версионируемый',
                columns: 3,
                default: true,
            },
        },
    };

    public addButtons(colDef, groupCode, collection, allowEdit, allowDelete) {
        const find = colDef.find((item) => item.extType && item.extType === 'buttons');
        if (!find) {
            colDef.push({
                extType: 'buttons',
                width: 30,
                buttons: {
                    updateBtn: {
                        target: '/nsi/' + groupCode + '/' + collection + '/edit',
                        permissionEdit: allowEdit,
                        permissionView: true,
                    },
                    deleteBtn: {
                        permission: allowDelete,
                    },
                },
            });
        }
    }

    public addCheckButton(colDef) {
        colDef.push({
            extType: 'buttons',
            width: 30,
            buttons: {
                checkBtn: {},
            },
        });
    }
}
