<div [ngClass]="{'panel panel-shadow m-20': !noPadding}">
    <div class="panel-body"
        *ngIf="activeRegistry">
        <p *ngIf="activeRegistry.unit">
            <span class="text-muted">
                {{ localizations.registers.licensing_authority }}:
            </span>
            {{ activeRegistry.unit.name }}
        </p>
        <p *ngIf="activeRegistry.responsiblePerson">
            <span class="text-muted">
                {{ localizations.registers.official_who_made_changes }}
            </span>
            {{ activeRegistry.responsiblePerson.name }}
            <span class="text-slate">
                ({{ activeRegistry.responsiblePerson.position }})
            </span>
        </p>

        <p>
            <ng-container *ngIf="activeRegistry.appealEntry">
                <span class="text-muted">
                    {{ localizations.registers.application }}:
                </span>
                № {{activeRegistry.appealEntry.number}}
                {{ localizations.common.from }}
                {{activeRegistry.appealEntry.date | date: 'shortDate'}}
                <span class="text-size-small anchor text-info ml-10"
                      (click)="goToAppeal()">
                    {{ localizations.common.proceed }}
                </span>
            </ng-container>

            <span class="text-muted ml-10"
                  [ngClass]="{'ml-10': activeRegistry.appealEntry}">
                {{ localizations.registers.entry }}:
            </span>
            <ng-container *ngIf="activeRegistry.auid">
                № {{activeRegistry.auid}}
            </ng-container>
            {{ localizations.common.from }}
            {{activeRegistry.dateCreation | date: 'shortDate'}}

            <!-- тип операции -->
            <span class="badge {{RegistersService.GetStatusProperty(activeRegistry.operationType, 'background', 'OperationTypes')}} ml-10 text-uppercase">
                {{ RegistersService.GetStatusProperty(activeRegistry.operationType, 'registerName', 'OperationTypes') }}
            </span>
            <!-- статус -->
            <span class="badge {{RegistersService.GetStatusProperty(activeRegistry.status, 'background', 'RegisterStatuses')}} text-uppercase ml-10">
                {{ RegistersService.GetStatusProperty(activeRegistry.status, 'name', 'RegisterStatuses') }}
            </span>
        </p>

        <p *ngIf="license.oldLicense">
            <span class="text-muted">
                {{ localizations.registers.manually_assigned_license_number }}:
            </span>
            {{ license.oldLicense.number }}
            {{ localizations.common.from }}
            {{ license.oldLicense.dateIssued | date: 'shortDate' }}
        </p>

        <fieldset *ngIf="license.mergedLicenses && license.mergedLicenses.length > 0">
            <legend class="text-bold cursor-pointer mb-10"
                    (click)="blocks.licenses = !blocks.licenses">

            </legend>
            <div *ngIf="blocks.licenses">
                <ul class="list">
                    <li *ngFor="let mergedLicense of license.mergedLicenses">
                        <span class="text-semibold">
                            № {{mergedLicense.number}}
                            {{ localizations.common.from }}
                            {{mergedLicense.dateIssued | date: 'shortDate'}}
                        </span>
                    </li>
                </ul>
            </div>
        </fieldset>

        <fieldset *ngIf="activeRegistry.mainXsd || activeRegistry.xsd">
            <legend class="text-bold text-uppercase cursor-pointer mb-10"
                    (click)="blocks.mainData = !blocks.mainData">
                {{ localizations.registers.application_data }}
                <a class="control-arrow">
                    <i [ngClass]="{
                            'icon-arrow-down32': blocks.mainData,
                            'icon-arrow-left32': !blocks.mainData
                         }">
                    </i>
                </a>
            </legend>
            <div [hidden]="!blocks.mainData">
                <dynamic-form
                    [model]="activeRegistry.mainXsd ? activeRegistry.mainXsdData : activeRegistry.xsdData"
                    [saveInMainObject]="true"
                    [xsd]="activeRegistry.mainXsd ? activeRegistry.mainXsd : activeRegistry.xsd"
                    [textView]="true">
                </dynamic-form>
            </div>
        </fieldset>

        <!--  Субъект  -->
        <fieldset *ngIf="activeRegistry.subject || activeRegistry.subjects && activeRegistry.subjects.length > 0">
            <legend class="text-bold cursor-pointer"
                    (click)="blocks.subject = !blocks.subject"
                    [ngClass]="{'mb-10': !blocks.subject}">
                {{ activeRegistry.subject ? registersService.getTitle(activeRegistry.titles, 'subject', 'single', 'Лицензиат') : registersService.getTitle(activeRegistry.titles.subject, 'plural', 'Лицензиаты') }}
                <span class="text-slate text-normal ml-10"
                      *ngIf="!blocks.subject">
                    {{ activeRegistry.subject.shortHeader }}
                </span>
                <a class="control-arrow">
                    <i [ngClass]="{
                        'icon-arrow-down32': blocks.subject,
                        'icon-arrow-left32': !blocks.subject
                        }">
                    </i>
                </a>
            </legend>
            <ng-container *ngIf="blocks.subject">
                <ng-container *ngIf="activeRegistry.subject">
                    <ng-container *ngTemplateOutlet="subjectCard; context:{ subject: activeRegistry.subject, activeRegistry: activeRegistry}"></ng-container>
                </ng-container>
                <ng-container *ngIf="activeRegistry.subjects">
                    <ng-container *ngFor="let subject of activeRegistry.subjects">
                        <ng-container *ngTemplateOutlet="subjectCard; context:{ subject: subject, activeRegistry: activeRegistry}"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </fieldset>

        <!--  Объекты  -->
        <fieldset *ngIf="activeRegistry.objects && activeRegistry.objects.length > 0">
            <legend class="text-bold cursor-pointer"
                    (click)="toggleObjects()"
                    [ngClass]="{'mb-10': !blocks.objects}">
                {{ registersService.getTitle(activeRegistry.titles, 'object', 'plural', localizations.registers.licensing_activity_places) }}
                <span class="badge bg-info-300 text-uppercase ml-10">Всего: {{activeRegistry.objects.length}}</span>
                <a class="control-arrow">
                    <i [ngClass]="{
                        'icon-arrow-down32': blocks.objects,
                        'icon-arrow-left32': !blocks.objects
                        }">
                    </i>
                </a>
            </legend>
            <div *ngIf="blocks.objects">
                <ng-container *ngIf="showObjects">
                    <div class="row"
                         *ngIf="usedObjects.length > 5">
                        <div class="col-md-7">
                            <evolenta-pagination [items]="usedObjects"
                                                 (onChangePage)="changeObjectPage($event)">
                            </evolenta-pagination>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group form-group-lg no-margin-bottom">
                                <input class='form-control'
                                       type="text"
                                       [placeholder]="localizations.registers.licensing_activity_places"
                                       [(ngModel)]="searchObjectByName"
                                       (ngModelChange)="filterObjects()">
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let object of usedObjects; let fst = first; let idx = index">
                            <div class="panel panel-shadow panel-small mt-20"
                                 *ngIf="!objectsPagination || objectsPagination && idx >= objectsPagination.start - 1 && idx <= objectsPagination.end - 1">

                                <div class="pl-20 pt-10">
                                    <h4 class="text-bold page-title no-margin-top no-padding mb-5">
                                        {{ object.name ? object.name : '' }}
                                        <div>
                                            <span class="badge text-uppercase mr-10 bg-purple-300 mr-10">Объект</span>
                                            <span class="badge text-uppercase mr-10 bg-info-300"
                                                  *ngIf="object.type">
                                                {{ object.type.name }}
                                            </span>
                                            <span
                                                class="badge text-uppercase mr-10 {{getObjectStatus(object, 'background')}}">
                                                {{ getObjectStatus(object, 'name') }}
                                            </span>
                                        </div>
                                    </h4>
                                </div>
                                <div class="panel-body">
                                    <object-preview-card [object]="object"
                                                         [showName]="false"
                                                         [showDetail]="false">
                                    </object-preview-card>
                                    <div class="mt-10"
                                         *ngIf="activeRegistry.objectMainXsd">
                                        <dynamic-form
                                            [model]="object.xsdData"
                                            [saveInMainObject]="true"
                                            [xsd]="activeRegistry.objectMainXsd"
                                            [textView]="true">
                                        </dynamic-form>
                                    </div>
                                    <fieldset class="mt-10"
                                              *ngIf="object.xsdData">
                                        <legend class="text-bold text-size-small no-margin-bottom"
                                                (click)="objectsAdditionalDataBlocks[object.guid] = !objectsAdditionalDataBlocks[object.guid]">
                                            {{ localizations['appeals.tasks'].additional_info }}
                                            <a class="control-arrow">
                                                <i [ngClass]="{
                                                    'icon-arrow-down32': objectsAdditionalDataBlocks[object.guid],
                                                    'icon-arrow-left32': !objectsAdditionalDataBlocks[object.guid]}">
                                                </i>
                                            </a>
                                        </legend>
                                        <div class="mt-15"
                                             *ngIf="objectsAdditionalDataBlocks[object.guid]">
                                            <dynamic-form
                                                *ngIf="activeRegistry.objectXsd"
                                                [model]="object.xsdData"
                                                [saveInMainObject]="true"
                                                [xsd]="activeRegistry.objectXsd"
                                                [textView]="true">
                                            </dynamic-form>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                    </ng-container>
                </ng-container>
            </div>
        </fieldset>

        <!-- Данные приказа -->
        <fieldset *ngIf="activeRegistry.orderData">
            <legend class="text-bold cursor-pointer"
                    (click)="blocks.order = !blocks.order"
                    [ngClass]="{'mb-10': !blocks.order}">
                {{ localizations.registers.order_data }}
                <a class="control-arrow">
                    <i [ngClass]="{
                        'icon-arrow-down32': blocks.order,
                         'icon-arrow-left32': !blocks.order
                         }">
                    </i>
                </a>
            </legend>
            <div *ngIf="blocks.order">
                <div class="panel panel-shadow">
                    <div class="panel-body">
                        <registry-data [data]="activeRegistry.orderData"
                                       [registry]="activeRegistry">
                        </registry-data>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset *ngIf="activeRegistry.checks && activeRegistry.checks.length > 0">
            <legend class="text-bold cursor-pointer"
                    [ngClass]="{'mb-10': !blocks.checks}"
                    (click)="blocks.checks = !blocks.checks">
                {{ localizations.appeals.checks }}
                <span class="badge bg-info-300 text-uppercase ml-10">
                    {{ localizations.common.total }}:
                    {{activeRegistry.checks.length}}
                </span>
                <a class="control-arrow">
                    <i [ngClass]="{
                            'icon-arrow-down32': blocks.checks,
                            'icon-arrow-left32': !blocks.checks
                        }">
                    </i>
                </a>
            </legend>
            <div *ngIf="blocks.checks">
                <div class="panel panel-shadow"
                     *ngFor="let check of activeRegistry.checks">
                    <div class="panel-body">
                        <registry-data [data]="check"
                                       [registry]="activeRegistry">
                        </registry-data>
                    </div>
                </div>
            </div>
        </fieldset>

    </div>
</div>

<ng-template let-subject="subject"
             let-activeRegistry="activeRegistry"
             #subjectCard>
    <div class="panel panel-shadow">
        <div class="panel-body">
            <subject-preview-card [subject]="subject"
                                  [isFullCard]="true"
                                  [showHeader]="true"
                                  [noPadding]="true">
            </subject-preview-card>
            <div class="mt-10"
                 *ngIf="activeRegistry.subjectMainXsd">
                <dynamic-form
                    [model]="subject.xsdData"
                    [saveInMainObject]="true"
                    [xsd]="activeRegistry.subjectMainXsd"
                    [textView]="true">
                </dynamic-form>
            </div>
            <fieldset *ngIf="activeRegistry.subjectXsd">
                <legend class="text-bold text-size-small no-margin-bottom"
                        (click)="objectsAdditionalDataBlocks[activeRegistry.subject.guid] = !objectsAdditionalDataBlocks[activeRegistry.subject.guid]">
                    {{ localizations['appeals.tasks'].additional_info }}
                    <a class="control-arrow">
                        <i [ngClass]="{'icon-arrow-down32': objectsAdditionalDataBlocks[activeRegistry.subject.guid], 'icon-arrow-left32': !objectsAdditionalDataBlocks[activeRegistry.subject.guid]}"></i>
                    </a>
                </legend>
                <div class="mt-15"
                     [hidden]="!objectsAdditionalDataBlocks[activeRegistry.subject.guid]">
                    <dynamic-form
                        [model]="activeRegistry.subject.xsdData"
                        [saveInMainObject]="true"
                        [xsd]="activeRegistry.subjectXsd"
                        [textView]="true">
                    </dynamic-form>
                </div>
            </fieldset>
        </div>
    </div>
</ng-template>

<ng-template let-data="data"
             #dataInfo>
    <p class="no-margin">
        <ng-container *ngIf="data.type !== 'order'">
            <span class="badge bg-primary-300 text-uppercase">
                {{ data.type == 'documentsCheck' ?
                    localizations.registers.document_check :
                    localizations.registers.document_check }}
            </span>
        </ng-container>
        <span class="text-muted"
              [ngClass]="{'ml-10': data.type !== 'order'}">
            {{ localizations.common.number }}:
        </span>
        {{data.number}}
        <span class="text-muted ml-10">
            {{ localizations.common.date }}:
        </span>
        {{data.date | date: 'shortDate'}}
    </p>
    <fieldset *ngIf="data.documents && data.documents.length > 0">
        <legend class="text-size-mini text-bold no-margin">
            {{ localizations.common.documents }}
        </legend>
        <table class="table table-xs">
            <tbody>
            <ng-container *ngFor="let document of data.documents; let fstDocument = first; let lstDocument = last">
                <tr>
                    <td class="no-"
                        [ngClass]="{'no-border-top': fstDocument}">
                            {{document.name}}
                        <ul>
                            <li *ngFor="let file of document.files; let fst = first">
                                <a class="anchor"
                                   (click)="registersService.downloadFile(file)">
                                    {{ file.originalName }}
                                </a>
                                <div class="display-inline-block ml-20"
                                     *ngIf="file.certificate">
                                    <i class="icon-medal text-danger cursor-pointer"
                                       [title]="localizations.common.open_certificate"
                                       (click)="registersService.openCertificate(file.certificate)">
                                    </i>
                                    <span class="text-size-small mr-10"
                                          *ngIf="file.certificate">
                                        {{ registersService.getFileCertificateInfo(file) }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </fieldset>
</ng-template>
