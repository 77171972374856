<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            (click)="save()">
        <b><i class="icon-checkmark-circle"
              [ngClass]="{'flash': isLoading}"></i></b>
        {{ localizations.common.save }}
    </button>
    <div class="ml-20 navbar-title mt-20">
        <h4>
            <i class="icon-arrow-left52 position-left cursor-pointer"
               (click)="back()">
            </i>
            {{ getApplicationSettingsMessage() }}
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="panel panel-main wide no-border no-border-radius">
        <div class="panel-body">
            <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label for="code"
                                   class="control-label">
                                {{ localizations.common.code }}
                            </label>
                            <input id="code"
                                   type="text"
                                   class="form-control"
                                   name="code"
                                   [(ngModel)]="application.code">
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group form-group-lg">
                            <label for="name"
                                   class="control-label">
                                {{ localizations.common.name }}
                                <span class="text-danger">*</span>
                            </label>
                            <input id="name"
                                   type="text"
                                   class="form-control"
                                   name="name"
                                   [(ngModel)]="application.name">
                        </div>
                    </div>
                    <div class="col-md-2 form-group-lg">
                        <div class="form-group">
                            <label class="control-label">
                                {{ localizations.filters.order_number }}
                            </label>
                            <input id="order"
                                   type="number"
                                   class="form-control"
                                   name="order"
                                   [(ngModel)]="application.order">
                        </div>
                    </div>
                    <div class="col-md-2 form-group-lg">
                        <div class="form-group">
                            <label for="icon"
                                   class="control-label">
                                {{ localizations.filters.image }}
                            </label>
                            <input id="icon"
                                   type="text"
                                   class="form-control"
                                   name="icon"
                                   [(ngModel)]="application.icon">
                        </div>
                    </div>
                    <div class="col-md-12 form-group-lg">
                        <div class="form-group">
                            <label for="description"
                                   class="control-label">
                                {{ localizations.common.description }}
                            </label>
                            <input id="description"
                                   type="text"
                                   class="form-control"
                                   name="description"
                                   [(ngModel)]="application.description">
                        </div>
                    </div>
                    <div class="col-md-6 form-group-lg">
                        <div class="form-group">
                            <label for="way"
                                   class="control-label">
                                {{ localizations['application-edit'].default_relative_url }}
                            </label>
                            <input id="way"
                                   type="text"
                                   class="form-control"
                                   name="way"
                                   [(ngModel)]="application.way">
                        </div>
                    </div>
                    <div class="col-md-6 form-group-lg">
                        <div class="form-group">
                            <label for="externalWay"
                                   class="control-label">
                                {{ localizations['application-edit'].external_system_url }}
                            </label>
                            <input id="externalWay"
                                   type="text"
                                   class="form-control"
                                   name="externalWay"
                                   [(ngModel)]="application.externalWay">
                        </div>
                    </div>
                </div>
                <div class="checkbox-inline">
                    <input type="checkbox"
                           class="checkbox"
                           id="isDisabled"
                           [checked]="application.disabled"
                           (change)="application.disabled = !application.disabled">
                    <label for="isDisabled">
                        {{ localizations.common.not_active }}
                    </label>
                </div>
                <div class="checkbox-inline">
                    <input type="checkbox"
                           class="checkbox"
                           id="notDisabledForSystemUser"
                           [checked]="application.notDisabledForSystemUser"
                           (change)="application.notDisabledForSystemUser = !application.notDisabledForSystemUser">
                    <label for="notDisabledForSystemUser">
                        {{ localizations['application-edit'].always_available_for_system_user }}
                    </label>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <fieldset class="mt-20">
                            <legend class="text-bold no-margin-bottom">
                                {{ localizations['application-edit'].used_licensing_activity_types }}
                            </legend>
                            <table class="table table-xs">
                                <thead>
                                <tr>
                                    <th class="text-bold text-size-small">
                                        {{ localizations.common.code }}
                                    </th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody *ngIf="application.usedRegistryTypes">
                                <tr *ngFor="let x of application.usedRegistryTypes; let i = index;trackBy:trackByIdx">
                                    <td>
                                        <input type="text"
                                               class="form-control"
                                               [(ngModel)]="application.usedRegistryTypes[i]">
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-default btn-sxs"
                                                (click)="application.usedRegistryTypes.splice(i, 1)">
                                            <i class="icon-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <button type="button"
                                    class="btn btn-default mt-20"
                                    (click)="addProperty('usedRegistryTypes')">
                                {{ localizations.common.add }}
                            </button>
                        </fieldset>
                    </div>
                    <div class="col-md-6">
                        <fieldset class="mt-20">
                            <legend class="text-bold no-margin-bottom">
                                {{ localizations['application-edit'].not_used_licensing_activity_types }}
                            </legend>
                            <table class="table table-xs">
                                <thead>
                                <tr>
                                    <th class="text-bold text-size-small">
                                        {{ localizations.common.code }}
                                    </th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody *ngIf="application.notUsedRegistryTypes">
                                <tr *ngFor="let x of application.notUsedRegistryTypes; let i = index;trackBy:trackByIdx">
                                    <td>
                                        <input type="text"
                                               class="form-control"
                                               [(ngModel)]="application.notUsedRegistryTypes[i]">
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-default btn-sxs"
                                                (click)="application.notUsedRegistryTypes.splice(i, 1)">
                                            <i class="icon-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <button type="button"
                                    class="btn btn-default mt-20"
                                    (click)="addProperty('notUsedRegistryTypes')">
                                {{ localizations.common.add }}
                            </button>
                        </fieldset>
                    </div>
                </div>

                <fieldset class="mt-20">
                    <legend class="text-bold no-margin-bottom">
                        {{ localizations['application-edit'].routing_params_for_menu }}
                    </legend>
                    <table class="table table-xs">
                        <thead>
                        <tr>
                            <th class="text-bold text-size-small">
                                {{ localizations['application-edit'].path }}
                            </th>
                            <th class="text-bold text-size-small">
                                {{ localizations.common.name }}
                            </th>
                            <th class="text-bold text-size-small">
                                {{ localizations['application-edit'].appeals_module_code }}
                            </th>
                            <th class="text-bold text-size-small">
                                {{ localizations['application-edit'].registers_module_code }}
                            </th>
                            <th class="text-bold text-size-small">
                                {{ localizations['application-edit'].meta_reglament_code }}
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody *ngIf="application.routing">
                        <tr *ngFor="let route of application.routing; let idx = index;">
                            <td>
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="route.path">
                            </td>
                            <td>
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="route.name">
                            </td>

                            <!-- Код раздела дел -->
                            <td>
                                <input type="checkbox"
                                       class="checkbox"
                                       id="isAppealsBlock{{idx}}"
                                       [checked]="route.isAppealsBlock"
                                       (change)="route.isAppealsBlock = !route.isAppealsBlock">
                                <label for="isAppealsBlock{{idx}}">&nbsp;</label>
                            </td>

                            <!-- Код раздела реестров -->
                            <td>
                                <input type="checkbox"
                                       class="checkbox"
                                       id="isRegistryBlock{{idx}}"
                                       [checked]="route.isRegistryBlock"
                                       (change)="route.isRegistryBlock = !route.isRegistryBlock">
                                <label for="isRegistryBlock{{idx}}">&nbsp;</label>
                            </td>
                            <td>
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="route.metaReglamentCode">
                            </td>
                            <td class="text-right">
                                <button class="btn btn-default btn-sxs"
                                        (click)="application.routing.splice(idx, 1)">
                                    <i class="icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button type="button"
                            class="btn btn-default mt-20"
                            (click)="addRoute()">
                        {{ localizations.common.add }}
                    </button>
                </fieldset>
            </evolenta-scrollbar>
        </div>

    </div>
</div>
