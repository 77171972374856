import { Injectable } from '@angular/core';
import { RestService } from '@evolenta/core';

@Injectable()
export class TicketService {
    // Информация о текущем талоне
    public ticket: any = {
        id: '',
        fullNumber: '',
        pinCode: '',
        seansId: '',
        talonTypeId: '',
        talonArrivalTime: '',
        seansStartWaitTime: '',
        seansUnblockWaitTime: '',
        seansCallTime: '',
        seansStartServTime: '',
        seansFinishTime: '',
        applicantFio: '',
        applicantMobilePhone: '',
        unitId: '',
        unitSperId: '',
        unitShortName: '',
        windowId: '',
        windowNumber: '',
        talon: {},
    };

    public constructor(private rest: RestService) {}

    public init() {
        this.ticket = {
            id: '',
            fullNumber: '',
            pinCode: '',
            seansId: '',
            talonTypeId: '',
            talonArrivalTime: '',
            seansStartWaitTime: '',
            seansUnblockWaitTime: '',
            seansCallTime: '',
            seansStartServTime: '',
            seansFinishTime: '',
            applicantFio: '',
            applicantMobilePhone: '',
            unitId: '',
            unitSperId: '',
            unitShortName: '',
            windowId: '',
            windowNumber: '',
            talon: {},
        };
    }

    public updateTicket(talon, currentSeansId, unit, window) {
        this.ticket.talon = talon;
        talon.seanses.forEach((seans) => {
            if (seans.id === currentSeansId) {
                this.ticket.seansId = seans.id;
                this.ticket.seansCallTime = seans.callTime;
                this.ticket.seansFinishTime = seans.finishTime;
                this.ticket.seansStartWaitTime = seans.startWaitTime;
                this.ticket.seansUnblockWaitTime = seans.unblockWaitTime;
                this.ticket.seansStartServTime = seans.startServTime;
            }
        });

        this.ticket.id = talon.id;
        this.ticket.fullNumber = talon.fullNumber;
        this.ticket.pinCode = talon.pin;
        this.ticket.talonArrivalTime = talon.arrivalTime;
        this.ticket.talonTypeId = talon.talonType.id;
        this.ticket.applicantFio = talon.fio;
        this.ticket.applicantMobilePhone = talon.mobilePhone;
        this.ticket.unitId = unit.id;
        this.ticket.unitSperId = unit.sperId;
        this.ticket.unitShortName = unit.shortName;
        this.ticket.windowId = window.id;
        this.ticket.windowNumber = window.number;
        if (this.ticket._id) {
            this.rest.update('tickets', this.ticket).then(
                (success) => {},
                (error) => {
                    console.log('error: ', error);
                }
            );
        } else {
            this.rest.create('tickets', this.ticket).then((ticket) => {
                this.ticket._id = ticket['_id'];
            });
        }
    }
}
