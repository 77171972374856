<ng-container *ngIf="!activeEntity">

    <!-- Если в деле одна услуга, то просто выводим ее сведения -->
    <ng-container *ngIf="entityGroups.length > 0 && task">
        <table class="table">
            <tbody>
            <ng-container *ngFor="let group of entityGroups; let fst = first">
                <tr>
                    <td class="text-uppercase text-bold no-border-top" [ngClass]="{'no-border-top': fst}">
                        {{group.name}}
                        <span class="badge bg-info-300 ml-10">{{countEntitiesForGroup(group)}}</span>
                    </td>
                    <td class="text-right" [ngClass]="{'no-border-top': fst}">
                        <button class="btn btn-primary-overline"  *ngIf="allowEdit && (!group.isUnique || countEntitiesForGroup(group) == 0)"
                                (click)="addEntity(appeal.subservice, group)">Добавить</button>
                    </td>
                </tr>
                <ng-container *ngFor="let entity of appeal?.subservice?.entities">
                    <tr *ngIf="entity.type === group.code">
                        <td colspan="2" class="no-padding">
                            <common-appeal-entity-card [appeal]="appeal"
                                                [appealSubservice]="appeal.subservice"
                                                [entity]="entity"
                                                mode="view"
                                                [task]="task"
                                                [allowEdit]="allowEdit"
                                                (onEdit)="editEntity(entity, appeal.subservice)"
                                                (onDelete)="deleteEntity(entity, appeal.subservice)"
                                                #entityCard
                            ></common-appeal-entity-card>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </table>
    </ng-container>


    <ng-container *ngIf="appeal?.subservices?.length == 1 && appeal.subservice.entities && !task">
        <ng-container *ngIf="appeal.subservice.entities">
            <ng-container *ngFor="let entity of appeal.subservice.entities">
                <ng-container *ngIf="checkVisibleEntity(entity) && (!task || checkTaskEntity(entity))">
                    <common-appeal-entity-card [appeal]="appeal"
                                        [appealSubservice]="appeal.subservice"
                                        [entity]="entity"
                                        mode="view"
                                        [task]="task"
                                        (onEdit)="editEntity(entity, appeal.subservice)"
                                        (onDelete)="deleteEntity(entity, appeal.subservice)"
                    ></common-appeal-entity-card>
                </ng-container>
            </ng-container>
        </ng-container>


        <ng-container *ngFor="let entity of notAddedEntities">

            <div class="panel panel-shadow m-20 panel-small">
                <div class="panel-heading">
                    <h4 class="panel-title">{{entity.name}}</h4>
                    <div class="heading-elements">
                        <button type="button" class="btn heading-btn btn-labeled pt-5 pb-5 pl-10 pr-10"
                                [ngClass]="{'btn-success-overline': !entity.isRequired, 'btn-danger-overline': entity.isRequired}"
                                *ngIf="hasAllowEdit()"
                                (click)="addEntity(appeal.subservice, entity)" title="Добавить сведение"><i class="icon-plus3"></i></button>
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="hasAllowEdit() && !task && checkAllowAddEntity(appeal.subservice, task)">
            <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20 mr-20"
                    (click)="addEntity(appeal.subservice)"
                    *ngIf="!task">Добавить сведение
                <b>
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" fill="#0F65D6" r="12"></circle>
                        <path clip-rule="evenodd" d="M13 6.5C13 5.94772 12.5523 5.5 12 5.5C11.4477 5.5 11 5.94772 11 6.5V11H6.5C5.94771 11 5.5 11.4477 5.5 12C5.5 12.5523 5.94771 13 6.5 13H11V17.5C11 18.0523 11.4477 18.5 12 18.5C12.5523 18.5 13 18.0523 13 17.5V13H17.5C18.0523 13 18.5 12.5523 18.5 12C18.5 11.4477 18.0523 11 17.5 11H13V6.5Z" fill="white" fill-rule="evenodd"></path>
                    </svg>
                </b>
            </button>

            <button class="btn btn-primary ml-20 mt-10" (click)="addEntity(appeal.subservice)" *ngIf="task">Добавить сведение</button>
        </ng-container>

    </ng-container>
</ng-container>

<!-- Форма редактирования отдельного сведения -->
<common-appeal-entity-card [appeal]="appeal"
                    [appealSubservice]="activeAppealSubservice"
                    [entity]="activeEntity"
                    [task]="task"
                    [allowEdit]="allowEdit"
                    mode="edit"
                    *ngIf="activeEntity"
                    (onApply)="applyEntity($event)"
                    (onNeedSaveBeforePrint)="processingSaveBeforePrint($event)"
                    (onCancel)="cancelEntity()" #activeEntityComponent></common-appeal-entity-card>


