<div dndEffectAllowed="copyMove">
    <div [dndDropzone]="['zone', 'element']" (dndDrop)="onDrop($event)" [dndHorizontal]="false"
         class="border-top border-left border-right border-bottom border-lg"
         [ngClass]="{'border-orange-300': zone.operator === 'and', 'border-success-300': zone.operator === 'or'}">
        <div class="pl-20 pr-20 pt-10 pb-10 bg-orange-300 text-white"
             [ngClass]="{'bg-orange-300': zone.operator === 'and', 'bg-success-300': zone.operator === 'or'}">
            &laquo;{{zone.operator === 'and' ? 'И' : 'ИЛИ'}}&raquo; элементы
        </div>
        <div class="p-5">
            <ul class="list-unstyled no-margin-bottom">
                <li *ngFor="let field of zone.fields; let idx = index; let lst = last"
                    class="display-block"
                    [ngClass]="{'bg-primary-50 pt-5 pb-5 pl-20 pr-20 ': field.code, 'mb-5': !lst, 'no-margin-bottom': lst}">
                    <div class="delete-action cursor-pointer">
                        <i class="icon-trash" (click)="removeField(idx)" title="Удалить"></i>
                    </div>
                    <ng-container *ngIf="field.operator">
                        <common-standard-fields-requirements-dropzone [zone]="field" (onRemoveFields)="removeFields($event)"></common-standard-fields-requirements-dropzone>
                    </ng-container>
                    <span *ngIf="field.code" [ngClass]="{'text-primary': field.code}">{{field.name}}</span>
                </li>
            </ul>
            <div class="dndPlaceholder m-5 p-20" dndPlaceholderRef></div>
        </div>
    </div>

</div>

