import { Injectable } from '@angular/core';
import { FilesService, HttpService, RestService, StorageService } from '@evolenta/core';
import { ByteUtilities, CommonUtilities } from '@evolenta/utilities';
import { Config } from '../../../../common/services/config';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash-es';
import { SUBSERVICES_ARCHIVE_COLLECTION, SUBSERVICES_COLLECTION } from '../../../../common/constants';

@Injectable()
export class StandardUploadService {

    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private filesService: FilesService,
        private httpService: HttpService,
    ) {
    }

    /**
     * Преобразование данных стандарта до регламента, используемого в модуле КНД
     * @param standard
     * @param process
     */
    public applyStandardToSubservice(standard, process) {
        const convertedStandard = _.cloneDeep(standard); // преобразумый стандарт
        const errors = this.validateStandard(convertedStandard);
        if (errors.length > 0) {
            const resultErrors = '<ul><li>' + errors.join('</li><li>') + '</li></ul>';

            return Promise.reject({validateErrors: resultErrors});
        } else {
            console.log('for convert', convertedStandard);

            return this.prepareDataForApplyStandard(convertedStandard, process).then(data => {
                return this.processingApplyStandardToSubservice(convertedStandard, process, data);
            });
        }
    }

    /**
     * Проверка стандарта на ошибки
     * @param standard
     */
    public validateStandard(standard) {
        const errors = [];
        if (!standard.data.kndKinds || standard.data.kndKinds.length === 0) {
            errors.push('Не выбраны виды КНД');
        }
        if (!standard.data.kndForm || !standard.data.kndForm.code) {
            errors.push('Не выбрана форма проведения КНМ');
        }
        if (!standard.data.kndType || !standard.data.kndType.code) {
            errors.push('Не выбран тип КНМ');
        }
        if (!standard.data.duration) {
            errors.push('Не задан срок проведения КНМ');
        }
        if (!standard.data.durationType) {
            errors.push('Не задан вид срока проведения КНМ');
        }
        if (!standard.data.name && !standard.data.shortName) {
            errors.push('Не задано наименование КНМ');
        }
        if (!standard.process) {
            errors.push('Не определен бизнес процесс КНМ');
        }
        if (!standard.process) {
            errors.push('Не определен бизнес процесс КНМ');
        }
        if (standard.subjectGroups) {
            const subjectGroupsWithoutCategories = this.checkNoCategories(standard.subjectGroups);
            if (subjectGroupsWithoutCategories.length > 0) {
                errors.push('Не добавлены категории для групп субъектов: ' + subjectGroupsWithoutCategories.join(', '));
            }
            const subjectsWithoutTypesErrors = [];
            standard.subjectGroups.forEach(group => {
                if (group.categories && group.categories.length > 0) {
                    this.checkCategoriesWithoutTypes(group.categories, 'subjects', [group.name], subjectsWithoutTypesErrors);
                }
            });
            if (subjectsWithoutTypesErrors.length > 0) {
                errors.push('Не выбраны виды для следующих категорий субъектов: ' + subjectsWithoutTypesErrors.join('; '));
            }
        }
        if (standard.objectGroups) {
            const objectGroupsWithoutCategories = this.checkNoCategories(standard.objectGroups);
            if (objectGroupsWithoutCategories.length > 0) {
                errors.push('Не добавлены категории для групп объектов: ' + objectGroupsWithoutCategories.join(', '));
            }
            const objectsWithoutTypesErrors = [];
            standard.objectGroups.forEach(group => {
                if (group.categories && group.categories.length > 0) {
                    this.checkCategoriesWithoutTypes(group.categories, 'objects', [group.name], objectsWithoutTypesErrors);
                }
            });
            // if (objectsWithoutTypesErrors.length > 0) {
            //     errors.push('Не выбраны виды для следующих категорий объектов: ' + objectsWithoutTypesErrors.join('; '))
            // }
        }
        if (standard.documentGroups) {
            const documentGroupsWithoutDocs = standard.documentGroups.filter(item => !item.docs || item.docs.length === 0).map(item => item.name);
            if (documentGroupsWithoutDocs.length > 0) {
                errors.push('Не добавлены виды для следующих групп документов: ' + documentGroupsWithoutDocs.join(', '));
            }
        }
        if (standard.additionalDataTabs) {
            const tabsWithoutXsd = standard.additionalDataTabs.filter(item => !item.xsd).map(item => item.name);
            if (tabsWithoutXsd.length > 0) {
                errors.push('Не добавлена настройка дополнительных полей для следующих форм: ' + tabsWithoutXsd.join(', '));
            }
        }
        if (standard.entities && standard.entities.length > 0) {
            const entitiesWithoutType = standard.entities.filter(item => !item.type).map(item => item.title);
            if (entitiesWithoutType.length > 0) {
                errors.push('Не выбран вид для следующих сведений: ' + entitiesWithoutType.join(', '));
            }
        }
        return errors;
    }

    public checkNoCategories(groups) {
        return groups.filter(item => item.categories.length === 0).map(item => item.name);
    }

    public checkCategoriesWithoutTypes(categories, type, path, errors) {
        categories.forEach(category => {
            const currentPath = _.cloneDeep(path);
            currentPath.push(category.name);
            if ((type === 'subjects' && !category.isUl && !category.isIp && !category.isFl)
                || (type === 'objects' && (!category.objectTypes || category.objectTypes && category.objectTypes.length === 0))) {
                errors.push('&laquo;' + currentPath.join(' / ') + '&raquo;');
            }
            if (category.categories && category.categories.length > 0) {
                this.checkCategoriesWithoutTypes(category.categories, type, currentPath, errors);
            }
        });
    }

    /**
     * Получение с сервера данных, необходимых для утверждения стандарта
     */
    public prepareDataForApplyStandard(standard, process) {
        standard = _.cloneDeep(standard);
        process = _.cloneDeep(process);
        const promises = [];
        const processingItems = [];
        const resultData = {};
        // статусы
        const defaultStatuses = this.storage.getItem('defaultStatuses');
        if (!defaultStatuses) {
            processingItems.push('defaultStatuses');
            promises.push(this.rest.search('settings', {search: {search: [{field: 'name', operator: 'eq', value: 'defaultStatuses'}]}}));
        }
        // запросы
        if (standard.requests && standard.requests.length > 0) {
            processingItems.push('requests');
            promises.push(this.rest.search('requests', {search: {search: [{field: '_id', operator: 'in', value: standard.requests}]}}));
        }
        // содержимое файлов бизнес процессов
        if (standard.process) {
            let fileId = process.file._id;
            processingItems.push('file' + fileId);
            promises.push(this.filesService.downloadFile(fileId, 'text'));
            if (process.tasks.subProcess && process.tasks.subProcess.length > 0) {
                const tasksWithSubProcess = process.tasks.subProcess.filter(item => item.subProcess);

                if (tasksWithSubProcess.length > 0) {
                    tasksWithSubProcess.forEach(subProcess => {
                        fileId = subProcess.subProcess.file._id;
                        processingItems.push('file' + fileId);
                        promises.push(this.filesService.downloadFile(fileId, 'text'));
                    });
                }
            }
        }
        // xsd файлы
        if (standard.additionalDataTabs && standard.additionalDataTabs.length > 0) {
            standard.additionalDataTabs.forEach(tab => {
                if (tab.xsd) {
                    processingItems.push('tabXsd' + tab.xsd);
                    promises.push(this.rest.find('xsd', tab.xsd));
                }
            });
        }

        return Promise.all(promises).then(result => {
            processingItems.forEach((processingItem, idx) => {
                if (processingItem === 'defaultStatuses' && result[idx].length > 0) {
                    this.storage.setItem('defaultStatuses', result[idx][0].value);
                }
                if (processingItem === 'requests' && result[idx].length > 0) {
                    resultData['requests'] = result[idx];
                }
                if (processingItem === 'processes' && result[idx].length > 0) {
                    resultData['processes'] = result[idx];
                }
                if (processingItem.indexOf('file') !== -1) {
                    const fileId = processingItem.replace('file', '');
                    if (!resultData['files']) {
                        resultData['files'] = {};
                    }
                    resultData['files'][fileId] = result[idx];
                }
                if (processingItem.indexOf('tabXsd') !== -1) {
                    const xsdId = processingItem.replace('tabXsd', '');
                    if (!resultData['tabXsds']) {
                        resultData['tabXsds'] = {};
                    }
                    resultData['tabXsds'][xsdId] = result[idx];
                }
            });
            return Promise.resolve(resultData);
        });
    }

    /**
     * Основная обработка данных стандарта для выгрузки в коллекцию subservices
     * @param standard
     * @param process
     * @param processingData
     */
    public processingApplyStandardToSubservice(standard, process, processingData) {
        return this.prepareSubserviceCheckLists(standard).then(checkLists => {
            const subservice: any = {
                standardId: standard._id,
                guid: CommonUtilities.GenerateGuid(),
                version: 'knd',
                sendToErp: !!standard.data.sendToErp,
                parentEntries: 'subservices',
                serviceId: 'S_00_000_' + standard.auid.toString().padStart(6, '0') + '_000_01_01',
                standardCode: standard.auid.toString(),
                units: this.prepareSubserviceUnits(standard),
                titles: this.createBranchNode({title: standard.data.name, shortTitle: standard.data.shortName}),
                objects: this.prepareSubserviceObjects(standard),
                variants: null,
                documentGroups: this.prepareSubserviceDocumentGroups(standard, processingData),
                terms: this.prepareSubserviceTerms(standard),
                requirements: {},
                checkLists: checkLists,
                statusModel: {status: this.storage.getItem('defaultStatuses')},
                additionalInfo: this.prepareAdditionalData(standard),
                xsdTabs: this.prepareSubserviceAdditionalDataTabs(standard),
                entities: this.prepareSubserviceEntities(standard),
                camundaProcess: {},
                kndInfo: this.processingKndInfo(standard),
            };

            return this.preparePrintFormData(subservice, standard).then(() => {
                return this.prepareSubserviceCamundaProcess(subservice, standard, process, processingData);
            });
        });
    }

    public processingKndInfo(standard) {
        const kndData: any = {
            kndTypeCode: standard.data.kndType ? standard.data.kndType.code : null,
            kndFormCode: standard.data.kndForm ? standard.data.kndForm.code : null,
            kndKindCode: standard.data.kndKinds ? standard.data.kndKinds[0].code : null,
            kndKindName: standard.data.kndKind ? standard.data.kndKind.name : null,
        };
        if (standard.data.kndKinds && standard.data.kndKinds.length > 0) {
            kndData.kndKinds = standard.data.kndKinds;
        }
        if (standard.data.typeFederalLaw) {
            kndData.typeFederalLaw = standard.data.typeFederalLaw;
        }
        if (standard.data.basisKNM && standard.data.basisKNM.length > 0) {
            kndData.basisKnm = _.cloneDeep(standard.data.basisKNM);
        }
        if (standard.data.npa && standard.data.npa.length > 0) {
            kndData.npa = _.cloneDeep(standard.data.npa);
        }
        return kndData;
    }

    public prepareAdditionalData(standard) {
        const additionalInfo = {rgu: [], notUseCheckLists: !!standard.data.notUseCheckLists};
        if (standard.data.frgu && standard.data.frgu.length > 0) {
            standard.data.frgu.forEach(frguItem => {
                additionalInfo.rgu.push({
                    rguServiceId: frguItem.code,
                    rguServiceName: frguItem.name,
                });
            });
        }
        return this.createBranchNode(additionalInfo);
    }

    /**
     * Обработка печатных форм
     * @param subservice
     * @param standard
     */
    public preparePrintFormData(subservice, standard) {
        const serviceId = subservice.serviceId;
        const subservicePrintFormIds = standard.printForms ? standard.printForms : [];
        let params;
        if (subservicePrintFormIds && subservicePrintFormIds.length > 0) {
            params = [{
                andSubConditions: [
                    {
                        field: 'serviceIds',
                        operator: 'eq',
                        value: serviceId,
                    },
                    {
                        field: '_id',
                        operator: 'nin',
                        value: subservicePrintFormIds,
                    },
                ],
            }];
        } else {
            params = [{
                field: 'serviceIds',
                operator: 'eq',
                value: serviceId,
            }];
        }

        // удаление ссылок на удаленные формы
        return this.rest.search('printForms', {search: {search: params}, size: 100}).then(printForms => {
            const promises = [];

            if (printForms.length > 0) {
                printForms.forEach(printForm => {
                    if (!printForm.serviceIds) {
                        printForm.serviceIds = [];
                    }
                    const findIndex = printForm.serviceIds.indexOf(serviceId);
                    if (findIndex !== -1) { printForm.serviceIds.splice(findIndex, 1); }
                    promises.push(this.rest.update('printForms', {_id: printForm._id, serviceIds: printForm.serviceIds}));
                });
            }

            return Promise.all(promises).then(() => {
                if (subservicePrintFormIds.length > 0) {
                    return this.addSubserviceToPrintForms(subservice, standard, subservicePrintFormIds);
                }
            });
        });
    }

    /**
     * Добавление выгружаемой услуги в состав полей serviceIds печатной формы
     * @param subservice
     * @param standard
     * @param subservicePrintFormIds
     */
    public addSubserviceToPrintForms(subservice, standard, subservicePrintFormIds) {
        const params = [{
            field: '_id',
            operator: 'in',
            value: subservicePrintFormIds,
        }];
        return this.rest.search('printForms', {search: {search: params}}).then(standardPrintForms => {
            const promises = [];
            standardPrintForms.forEach(printForm => {
                if (!printForm.serviceIds) {
                    printForm.serviceIds = [];
                }
                if (!printForm.docs) {
                    printForm.docs = [];
                }
                const savedData: any = {};
                const standardDocsWithPrintForm = this.getDocsForPrintForm(printForm, standard);
                if (standardDocsWithPrintForm.length > 0) {
                    printForm.docs = printForm.docs.concat(standardDocsWithPrintForm);
                    savedData.docs = printForm.docs;
                }
                const findIndex = printForm.serviceIds.indexOf(subservice.serviceId);
                if (findIndex === -1) {
                    printForm.serviceIds.push(subservice.serviceId);
                    savedData.serviceIds = printForm.serviceIds;
                }
                if (Object.keys(savedData).length > 0) {
                    savedData._id = printForm._id;
                    promises.push(this.rest.update('printForms', savedData));
                }
            });
            return Promise.all(promises);
        });
    }

    /**
     * Определение использования ПФ в документах стандарта
     * @param printForm
     * @param standard
     */
    public getDocsForPrintForm(printForm, standard) {
        const docsForAdd = [];
        if (!printForm.docs) {
            printForm.docs = [];
        }
        if (standard.documentGroups && standard.documentGroups.length > 0) {
            standard.documentGroups.forEach(group => {
                const findDocWithPrintForm = group.docs.find(item => item.printForms && item.printForms.length > 0 && item.printForms.indexOf(printForm._id) !== -1);
                if (findDocWithPrintForm) {
                    const findLinkInPrintForm = printForm.docs.find(item => item.docGroupId === group.guid && item.docId === findDocWithPrintForm.guid);
                    if (!findLinkInPrintForm) {
                        docsForAdd.push({docGroupId: group.guid, docId: findDocWithPrintForm.guid});
                    }
                }
            });
        }
        return docsForAdd;
    }

    /**
     * Подготовка данных организаций, в которых может оказываться услуга
     * @param standard
     */
    public prepareSubserviceUnits(standard) {
        const units = [];
        if (standard.units && standard.units.length > 0) {
            standard.units.forEach(unit => {
                units.push({
                    id: unit.id,
                    name: unit.name,
                    shortName: unit.shortName ? unit.shortName : '',
                    code: unit.code ? unit.code : unit.auid,
                });
            });
        } else {
            units.push({
                id: standard.unit.id,
                name: standard.unit.name,
                shortName: standard.unit.shortName,
                code: standard.unit.code ? standard.unit.code : standard.unit.auid,
            });
        }
        return units;
    }

    /**
     * Подготовка вкладок с дополнительными данными
     * @param standard
     */
    public prepareSubserviceAdditionalDataTabs(standard) {
        const tabs = [];
        if (standard.additionalDataTabs && standard.additionalDataTabs.length > 0) {
            standard.additionalDataTabs.forEach(tab => {
                tabs.push({
                    code: tab.guid,
                    tabCode: tab.code,
                    name: tab.name,
                    xsd: tab.xsd,
                    xsdField: 'xsdContent',
                });
            });
        }
        return tabs;
    }

    /**
     * Подготовка субъектов/объектов
     * @param standard
     */
    public prepareSubserviceObjects(standard) {
        const standardObjects = {
            objectKinds: [],
            preferences: null,
            objectGroups: [],
        };

        const mainKind = {
            guid: CommonUtilities.GenerateGuid(),
            type: 'participant',
            name: 'Участник',
            description: 'Физическое, либо юридическое лицо',
            subKinds: [],
        };

        // Вид участия - Юридическое лицо
        const ulSubKind = {
            guid: CommonUtilities.GenerateGuid(),
            firstGroupName: 'Субъект',
            secondGroupName: 'Юридическое лицо',
            name: 'Субъект: Юридическое лицо',
            specialTypeId: 'ulApplicant',
            type: 'kndSubjectUl',
            headerOptions : [
                'f|data.organization.shortName',
                's|, ОГРН: ',
                'f|data.organization.ogrn',
            ],
            shortHeaderOptions : [
                'f|data.organization.shortName',
            ],
            category: [],
        };

        // Вид участия - Индивидуальный предприниматель
        const ipSubKind = {
            guid: CommonUtilities.GenerateGuid(),
            firstGroupName: 'Субъект',
            secondGroupName: 'Индивидуальный предприниматель',
            name: 'Субъект: Индивидуальный предприниматель',
            specialTypeId: 'ipApplicant',
            type: 'kndSubjectIp',
            headerOptions : [
                'f|data.person.lastName',
                's| ',
                'f|data.person.firstName',
                's| ',
                'f|data.person.middleName',
                's|, ',
                'f|data.person.ogrn',
            ],
            shortHeaderOptions : [
                'f|data.person.lastName',
                's| ',
                'f|data.person.firstName|1',
                's|.',
                'f|data.person.middleName|1',
                's|.',
            ],
            category: [],
        };

        // Вид участия - Физическое лицо
        const flSubKind = {
            guid: CommonUtilities.GenerateGuid(),
            firstGroupName: 'Субъект',
            secondGroupName: 'Физическое лицо',
            name: 'Субъект: Физическое лицо',
            specialTypeId: 'individualApplicant',
            type: 'kndSubjectFl',
            headerOptions : [
                'f|data.person.lastName',
                's| ',
                'f|data.person.firstName',
                's| ',
                'f|data.person.middleName',
                's|, ',
                'f|data.person.birthday.formatted',
                's| г.р.',
            ],
            shortHeaderOptions : [
                'f|data.person.lastName',
                's| ',
                'f|data.person.firstName|1',
                's|.',
                'f|data.person.middleName|1',
                's|.',
            ],
            category: [],
        };

        // Вид участия - объект КНД
        const objectSubKind = {
            guid: CommonUtilities.GenerateGuid(),
            firstGroupName: 'Объект',
            secondGroupName: 'Объект КНД',
            name: 'Объект: Объект КНД',
            specialTypeId: 'individualApplicant',
            type: 'kndObject',
            headerOptions : [],
            shortHeaderOptions : [],
            category: [],
        };

        const groups = [];
        if (standard.subjectGroups) {
            standard.subjectGroups.forEach(subjectGroup => {
                const group = {
                    guid: subjectGroup.guid,
                    name: subjectGroup.name,
                    code: subjectGroup.guid,
                    type: 'subjects',
                    categoryGuids: [],
                    subKindGuids: [],
                    categories: this.getSubjectCategories(subjectGroup.categories),
                };
                groups.push(group);
            });
        }

        // определение видов участия по категориям
        groups.forEach(group => {
            group.categoryGuids = this.getCategoriesGuids(group.categories);
            group.categories.forEach(category => {
                if (category.isUl) {
                    if (group.subKindGuids.indexOf(ulSubKind.guid) === -1) {
                        group.subKindGuids.push(ulSubKind.guid);
                    }
                    ulSubKind.category.push(category);
                }
                if (category.isIp) {
                    if (group.subKindGuids.indexOf(ipSubKind.guid) === -1) {
                        group.subKindGuids.push(ipSubKind.guid);
                    }
                    ipSubKind.category.push(category);
                }
                if (category.isFl) {
                    if (group.subKindGuids.indexOf(flSubKind.guid) === -1) {
                        group.subKindGuids.push(flSubKind.guid);
                    }
                    flSubKind.category.push(category);
                }
            });
        });

        if (ulSubKind.category.length > 0) {
            mainKind.subKinds.push(ulSubKind);
        }
        if (ipSubKind.category.length > 0) {
            mainKind.subKinds.push(ipSubKind);
        }
        if (flSubKind.category.length > 0) {
            mainKind.subKinds.push(flSubKind);
        }
        standardObjects.objectKinds.push(this.createBranchNode(mainKind));
        groups.forEach(group => {
            group.objectKindGuids = [{guid: mainKind.guid, subKindGuids: group.subKindGuids}];
            delete group.subKindGuids;
            standardObjects.objectGroups.push(this.createBranchNode(group));
        });
        return standardObjects;
    }

    public getCategoriesGuids(categories) {
        let guids = [];
        categories.forEach(cat => {
            guids.push(cat.guid);
            if (cat.subCategory) {
                guids = guids.concat(this.getCategoriesGuids(cat.subCategory));
            }
        });
        return guids;
    }

    public getSubjectCategories(categories) {
        const result = [];
        if (categories.length > 0) {
            categories.forEach(category => {
                const cat = {
                    guid: category.guid,
                    code: category.code,
                    name: category.name,
                    subCategory: category.categories ? this.getSubjectCategories(category.categories) : [],
                    isUl: category.isUl,
                    isIp: category.isIp,
                    isFl: category.isFl,
                };
                result.push(cat);
            });
        }
        return result;
    }

    public getParamsFromCategories(categories, property) {
        let propertyValue = false;
        if (categories && categories.length > 0) {
            categories.forEach(category => {
                if (!propertyValue) {
                    propertyValue = !!category[property];
                }
                if (!propertyValue && category.categories) {
                    propertyValue = !!this.getParamsFromCategories(category.categories, property);
                }
            });
        }
        return propertyValue;
    }

    /**
     * Подготовка данных о сроках проведения проверки
     * @param standard
     */
    public prepareSubserviceTerms(standard) {
        let term = null;
        if (standard.data && standard.data.duration) {
            term = {
                term: [],
                condition: [],
            };
            const mainTermGuid = CommonUtilities.GenerateGuid();
            const mainTerm = {
                guid: mainTermGuid,
                name: 'Основной срок исполнения услуги',
                description: 'Основной срок исполнения услуги (не зависящий от выбранного варианта, либо используемый в услугах, не имеющих вариантов, либо в услугах, где не заданы регламентные сроки для вариантов))',
                times: [{
                    serialNumber: 3,
                    typeTimeCode: 'resp_processing',
                    processingTimeType: 'working',
                    processingTime: standard.data.duration,
                }],
            };
            term.term.push(this.createBranchNode(mainTerm));
            const mainCondition = {
                guid: CommonUtilities.GenerateGuid(),
                termGuid: mainTermGuid,
            };
            term.condition.push(this.createBranchNode(mainCondition));
        }
        return term;
    }

    /**
     * Формирование групп документов
     * @param standard
     * @param processingData
     */
    public prepareSubserviceDocumentGroups(standard, processingData) {
        const documentGroups = [];
        if (standard.documentGroups) {
            standard.documentGroups.forEach(group => {
                const documentGroup = {
                    guid: group.guid,
                    name: group.name,
                    code: group.guid,
                    isOutput: false,
                    requirements: [{}],
                    docs: [],
                };
                if (group.docs) {
                    group.docs.forEach(doc => {
                        const standardDoc: any = {
                            guid: doc.guid,
                            name: doc.name,
                            code: doc.guid,
                            sendToSed: doc.sendToSed,
                        };
                        if (doc.request) {
                            standardDoc.requestId = doc.request;
                        }
                        if (doc.xsd) {
                            standardDoc.xsd = doc.xsd;
                        }
                        if (doc.sendToSed && doc.typeForFinishTask) {
                            standardDoc.typeForFinishTask = doc.typeForFinishTask;
                        }
                        documentGroup.docs.push(standardDoc);
                    });
                }
                documentGroups.push(this.createBranchNode(documentGroup));
            });
        }
        if (standard.requests && processingData['requests']) {
            const requestGroup = {
                guid: CommonUtilities.GenerateGuid(),
                code: 'documentsRequests',
                name: 'Межвед-запросы',
                requirements: [{}],
                docs: [],
            };

            standard.requests.forEach(request => {
                const findRequest = processingData['requests'].find(item => item._id === request);
                if (findRequest) {
                    requestGroup.docs.push({
                        guid: CommonUtilities.GenerateGuid(),
                        name: findRequest.name,
                        code: findRequest.auid.toString(),
                        requestId: findRequest._id,
                    });
                }
            });

            documentGroups.push(this.createBranchNode(requestGroup));
        }
        return documentGroups;
    }

    /**
     * Формирование блока сведений
     * @param standard
     */
    public prepareSubserviceEntities(standard) {
        const entities = [];
        if (standard.entities) {
            standard.entities.forEach(entity => {
                entities.push({
                    code: entity.type,
                    sperId: entity.guid,
                    name: entity.title,
                    docGroupId: entity.group,
                });
            });
        }
        return entities;
    }

    public prepareSubserviceCamundaProcess(subservice, standard, process, processingData) {
        const camundaProcess = {};
        console.log('process', process);
        if (standard.process) {
            const filesContent = processingData['files'];
            const bpmn = filesContent[process.file._id];
            const subProcess = {};
            if (process.tasks.subProcess) {
                const tasksWithSubProcess = process.tasks.subProcess.filter(item => item.subProcess);
                if (tasksWithSubProcess.length > 0) {
                    tasksWithSubProcess.forEach(task => {
                        subProcess[task.id] = filesContent[task.subProcess.file._id];
                    });
                }
            }
            const resultBpmn = this.correctBpmn(bpmn, subProcess);
            const xsdData = {
                name: 'bpmnXML',
                contentXsd: resultBpmn,
                parentEntries: 'xsd',
            };
            return this.rest.create('xsd', xsdData)
                .then((xsd: any) => {
                    return this.httpService
                        .post(Config.server + Config.api + 'camunda/deployment/create', { bpmn: ByteUtilities.B64Encode(resultBpmn) })
                        .then(camundaResult => {
                            subservice.camundaProcess = {
                                xsdBpmn: xsd._id,
                                camundaDeploymentInfo: camundaResult,
                                userTasks: this.processingProcessTasksInfo(process, standard, processingData['tabXsds']),
                                messages: this.prepareMessagesFromProcess(process),
                            };

                            return this.uploadSubservice(subservice);
                        });
                });
        } else {
            return camundaProcess;
        }
    }

    public prepareMessagesFromProcess(process) {
        let messages = [];
        if (process.messages && process.messages.length > 0) {
            messages = process.messages;
        }
        if (process.tasks && process.tasks && process.tasks.subProcess && process.tasks.subProcess.length > 0) {
            process.tasks.subProcess.forEach(subProcess => {
                if (subProcess.subProcess && subProcess.subProcess.messages && subProcess.subProcess.messages.length > 0) {
                    messages = messages.concat(subProcess.subProcess.messages);
                }
            });
        }
        return messages;
    }

    public prepareSubserviceCheckLists(standard) {
        // получение метаданных коллеции controlListQuestions
        const loadCollectionMetadataPromise = this.rest.search('collectionsMeta', {search: {search: [{field: 'name', operator: 'eq', value: 'controlListQuestions'}]}});

        return Promise.all([loadCollectionMetadataPromise]).then(response => {
            const metadata = response[0][0];

            // получение необходимых элементов из коллекции controlListQuestions
            const checkListQuestionsToLoad = [];
            (standard.checkLists || []).forEach(checkList => {
                (checkList.questions || []).forEach(question => {
                    if (question.id && !checkListQuestionsToLoad.includes(question.id)) {
                        checkListQuestionsToLoad.push(question.id);
                    }
                });
            });

            const loadQuestionsPromise = checkListQuestionsToLoad.length
                ? this.rest.search('controlListQuestions', {search: {search: [{field: '_id', operator: 'in', value: checkListQuestionsToLoad}]}})
                : Promise.resolve([]);

            // получение xsd-схемы коллекции controlListQuestions
            const loadCollectionXsdPromise = metadata && metadata.xsd
                ? this.rest.find('xsd', metadata.xsd)
                : Promise.resolve(null);

            return Promise.all([loadCollectionXsdPromise, loadQuestionsPromise]).then(responses => {
                const xsd = responses[0];
                const questions = responses[1];

                // формирование карты связанных коллекций
                const dictionariesMap = [];
                const populateDictionariesMap = (xsdElementsArray, basePath) => {
                    xsdElementsArray.forEach(xsdElement => {
                        const elementPath = basePath.concat([xsdElement.options.name]);
                        switch (xsdElement.type) {
                            case 'fieldset': {
                                populateDictionariesMap(xsdElement.content || [], elementPath);
                                break;
                            }
                            case 'dictionary': {
                                const code = xsdElement.options.dictionaryParams.code;
                                if (!dictionariesMap.some(d => d.code === code)) {
                                    dictionariesMap.push({
                                        code: xsdElement.options.dictionaryParams.code,
                                        paths: [elementPath],
                                        loadCodes: [],
                                    });
                                } else {
                                    const dictionaryToLoad = dictionariesMap.find(d => d.code === code);
                                    if (!dictionaryToLoad.paths.some(p => _.isEqual(p, elementPath))) {
                                        dictionaryToLoad.paths.push(elementPath);
                                    }
                                }
                                break;
                            }
                        }
                    });
                };
                populateDictionariesMap(xsd.xsdContent || [], []);

                // формирование выходной коллекции чек листов
                const checkLists = [];
                (standard.checkLists || []).forEach(checkList => {
                    checkLists.push({
                        ...checkList,
                        questions: checkList.questions
                            .filter(q => q.id)
                            .map(q => questions.find(qq => qq._id === q.id)),
                    });
                });

                // вычисление кодов необходимых элементов связанных коллекций
                checkLists.forEach(checkList => {
                    checkList.questions.forEach(question => {
                        dictionariesMap.forEach(mapElement => {
                            mapElement.paths.forEach(path => {
                                let questionProperty = question;
                                path.forEach((segment, segmentIndex) => {
                                    if (questionProperty) {
                                        if (Array.isArray(questionProperty)) {
                                            questionProperty.forEach(questionArrayPropertyElement => {
                                                if (!!questionArrayPropertyElement[segment] && !!questionArrayPropertyElement[segment].code) {
                                                    if (!mapElement.loadCodes.some(c => c === questionArrayPropertyElement[segment].code)) {
                                                        mapElement.loadCodes.push(questionArrayPropertyElement[segment].code);
                                                    }
                                                }
                                            });
                                            questionProperty = undefined;
                                        } else if (!!questionProperty[segment]) {
                                            if (segmentIndex === path.length - 1 && !!questionProperty[segment].code) {
                                                if (!mapElement.loadCodes.some(c => c === questionProperty[segment].code)) {
                                                    mapElement.loadCodes.push(questionProperty[segment].code);
                                                }
                                            }
                                            questionProperty = questionProperty[segment];
                                        } else {
                                            questionProperty = undefined;
                                        }
                                    }
                                });
                            });
                        });
                    });
                });

                // получение необходимых элементов из связанных коллекций
                const loadDictionariesPromises = [];
                dictionariesMap.forEach(dictionaryToLoad => {
                    loadDictionariesPromises.push(dictionaryToLoad.loadCodes.length
                        ? this.rest.search(dictionaryToLoad.code, {search: {search: [{field: 'code', operator: 'in', value: dictionaryToLoad.loadCodes}]}})
                        : Promise.resolve([]));
                });

                return Promise.all(loadDictionariesPromises).then(results => {

                    // обновление вопросов выходной коллекции чек листов данными связанных справочников
                    checkLists.forEach(checkList => {
                        checkList.questions.forEach(question => {
                            dictionariesMap.forEach((mapElement, mapElementIndex) => {
                                mapElement.paths.forEach(path => {
                                    let questionProperty = question;
                                    path.forEach((segment, segmentIndex) => {
                                        if (questionProperty) {
                                            if (Array.isArray(questionProperty)) {
                                                questionProperty.forEach(questionArrayPropertyElement => {
                                                    if (!!questionArrayPropertyElement[segment] && !!questionArrayPropertyElement[segment].code) {
                                                        if (results[mapElementIndex].some(r => r.code === questionArrayPropertyElement[segment].code)) {
                                                            questionArrayPropertyElement[segment] = {
                                                                ...questionArrayPropertyElement[segment],
                                                                ...results[mapElementIndex].find(r => r.code === questionArrayPropertyElement[segment].code),
                                                            };
                                                        }
                                                    }
                                                });
                                                questionProperty = undefined;
                                            } else if (!!questionProperty[segment]) {
                                                if (segmentIndex === path.length - 1 && !!questionProperty[segment].code) {
                                                    if (results[mapElementIndex].some(r => r.code === questionProperty[segment].code)) {
                                                        questionProperty[segment] = {
                                                            ...questionProperty[segment],
                                                            ...results[mapElementIndex].find(r => r.code === questionProperty[segment].code),
                                                        };
                                                    }
                                                }
                                                questionProperty = questionProperty[segment];
                                            } else {
                                                questionProperty = undefined;
                                            }
                                        }
                                    });
                                });
                            });
                        });
                    });
                    checkLists.forEach(checkList => {
                        checkList.questions.forEach(question => {
                            delete question.dateCreation;
                            delete question.userCreation;
                            delete question.dateLastModification;
                            delete question.userLastModification;
                            delete question.entityType;
                            delete question.parentEntries;
                            if (question.mandatoryReqs) {
                                delete question.mandatoryReqs.dateCreation;
                                delete question.mandatoryReqs.userCreation;
                                delete question.mandatoryReqs.dateLastModification;
                                delete question.mandatoryReqs.userLastModification;
                                delete question.mandatoryReqs.parentEntries;
                                delete question.mandatoryReqs.entityType;
                                if (question.mandatoryReqs.elementsBlock) {
                                    question.mandatoryReqs.elementsBlock = question.mandatoryReqs.elementsBlock.filter(item => !!item);
                                }
                            }
                        });
                    });

                    return Promise.resolve(checkLists);
                });
            });
        });
    }

    public uploadSubservice(subservice) {
        // Ищем в коллекции subservices запись с идентификатором выгружаемого регламента
        return this.rest.search(SUBSERVICES_COLLECTION, {search: {search: [{field: 'serviceId', operator: 'eq', value: subservice.serviceId}]}}).then(existSubservice => {
            if (existSubservice.length > 0) {
                return this.rest.create(SUBSERVICES_ARCHIVE_COLLECTION, existSubservice[0]).then(
                    () => {
                        return this.rest.remove(SUBSERVICES_COLLECTION, {_id: existSubservice[0]._id}).then(() => {
                            return this.createSubservice(subservice);
                        });
                    },
                    () => {
                        return this.rest.remove(SUBSERVICES_COLLECTION, {_id: existSubservice[0]._id}).then(() => {
                            return this.createSubservice(subservice);
                        });
                    },
                );
            } else {
                return this.createSubservice(subservice);
            }
        });
    }

    public createSubservice(subservice) {
        return this.rest.create(SUBSERVICES_COLLECTION, subservice).then(result => {
            console.log('result upload', result);
        });
    }

    public processingProcessTasksInfo(process, standard, xsds) {
        let tasks = [];
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    const taskData = {
                        standardId: task.guid,
                        sperId: task.guid,
                        code: task.id,
                        type: taskType === 'userTasks' ? 'user' : taskType === 'serviceTasks' ? 'service' : 'subProcess',
                        name: task.name,
                        roles: [],
                        variables: [],
                        xsdTabs: [],
                        documents: [],
                        entities: [],
                        keyIndicators: [],
                        events: [],
                    };
                    const taskParams = this.getTaskProperties(task.guid, standard);
                    if (xsds && taskParams['xsdTabs'] && taskParams['xsdTabs'].length > 0) {
                        taskData.variables = this.getTaskVariables(task, taskParams['xsdTabs'], xsds, standard);
                    }
                    tasks.push(Object.assign(taskData, taskParams));
                    if (task.subProcess) {
                        const childTasks = this.processingProcessTasksInfo(task.subProcess, standard, xsds);
                        tasks = tasks.concat(childTasks);
                    }
                });
            });
        }
        return tasks;
    }

    public getTaskVariables(task, tabs, xsds, standard) {
        const variables = [];
        tabs.forEach(tab => {
            const tabInfo = standard.additionalDataTabs.find(item => item.guid === tab.sperId);
            if (tabInfo && tabInfo.xsd) {
                const xsdFile = xsds[tabInfo.xsd];
                if (xsdFile) {
                    const xsdContent = xsdFile.xsdContent;
                    const variablesItems = {};
                    this.getVariablesPath(xsdContent, [], variablesItems);
                    if (Object.keys(variablesItems).length > 0) {
                        Object.keys(variablesItems).forEach(variableCode => {
                            variables.push({
                                code: variableCode,
                                xpath: tabInfo.code + '.' + variablesItems[variableCode].join('.'),
                            });
                        });
                    }
                }
            }
        });
        return variables;
    }

    public getVariablesPath(data, paths = [], resultVariables = {}) {
        data.forEach(dataItem => {
            if (dataItem.cols) {
                dataItem.cols.forEach(column => {
                    this.getVariablesPath(column.content, _.cloneDeep(paths), resultVariables);
                });
            } else {
                const currentPaths = _.cloneDeep(paths);
                currentPaths.push(dataItem.options.name);
                if (dataItem.options.processVariable) {
                    // currentPaths.push(dataItem.options.processVariable);
                    resultVariables[dataItem.options.processVariable] = currentPaths;
                    if (dataItem.content && dataItem.content.length > 0) {
                        this.getVariablesPath(dataItem.content, _.cloneDeep(currentPaths), resultVariables);
                    }
                } else if (dataItem.content && dataItem.content.length > 0) {
                    this.getVariablesPath(dataItem.content, _.cloneDeep(currentPaths), resultVariables);
                }
            }
        });
    }

    public findTaskInStandard(taskGuid, standard) {
        let task = standard.process.tasks.find(item => item.guid === taskGuid);
        if (!task) {
            const taskWitSubProcess = standard.process.tasks.filter(item => item.subProcess);
            taskWitSubProcess.forEach(parentTask => {
                if (!task) {
                    task = parentTask.subProcess.tasks.find(item => item.guid === taskGuid);
                }
            });
        }
        return task;
    }

    public getTaskProperties(taskGuid, standard) {
        const properties = [
            {code: 'xsdTabs', value: 'additionalDataTabs'},
            {code: 'entities', value: 'entities'},
            {code: 'documents', value: 'documents'},
        ];
        const task = this.findTaskInStandard(taskGuid, standard);
        const resultData = {};
        if (task) {
            properties.forEach(property => {
                if (task[property.value]) {
                    resultData[property.code] = [];
                    task[property.value].forEach(propertyItem => {
                        const data: any = {
                            sperId: propertyItem.guid,
                            isUsed: !!propertyItem.isUse,
                            isRequired: !!propertyItem.isRequired,
                        };
                        if (data.isUsed || data.isRequired) {
                            resultData[property.code].push(data);
                        }
                    });
                } else {
                    resultData[property.code] = [];
                }
            });
        }
        return resultData;
    }

    /**
     * Корректировка BPMN (внедрение подпроцессов в структуру основного процесса)
     * @param bpmn
     * @param subProcess
     */
    public correctBpmn(bpmn, subProcess) {
        const mainParser = new DOMParser();
        const nameSpace = 'http://www.omg.org/spec/BPMN/20100524/MODEL';
        const nameSpaceDi = 'http://www.omg.org/spec/BPMN/20100524/DI';
        const mainDoc = mainParser.parseFromString(bpmn, 'application/xml');
        const mainRoot = mainDoc.documentElement;
        const mainProcess = mainRoot.getElementsByTagNameNS(nameSpace, 'process')[0];
        const di = mainRoot.getElementsByTagNameNS(nameSpaceDi, 'BPMNDiagram')[0];
        const subProcesses = mainRoot.getElementsByTagNameNS(nameSpace, 'subProcess');
        const nodesForDelete = [];
        for (let i = 0; i <= subProcesses.length - 1; i++) {
            const subProcessNode = subProcesses[i];
            const taskId = subProcessNode.getAttribute('id');
            const eventSubProcess = subProcessNode.getAttribute('triggeredByEvent');
            if (!eventSubProcess) {
                if (subProcess[taskId]) {
                    // Если выбран подпроцесс для subProcess
                    const subParser = new DOMParser();
                    const subDoc = subParser.parseFromString(subProcess[taskId], 'application/xml');
                    const subRoot = subDoc.documentElement;
                    const sub = subRoot.getElementsByTagNameNS(nameSpace, 'process')[0];
                    const childNodes = sub.childNodes;
                    for (let j = 0; j <= childNodes.length - 1; j++) {
                        const childNode = sub.childNodes[j];
                        subProcessNode.appendChild(mainDoc.importNode(childNode, true));
                    }
                    const escalations = subRoot.getElementsByTagNameNS(nameSpace, 'escalation');
                    if (escalations && escalations.length > 0) {
                        for (let j = 0; j <= escalations.length - 1; j++) {
                            const escalationNode = escalations[j];
                            di.parentNode.insertBefore(escalationNode.cloneNode(true), di);
                            // mainProcess.parentNode.appendChild(subDoc.importNode(escalationNode, true))
                        }
                    }
                    const errors = subRoot.getElementsByTagNameNS(nameSpace, 'error');
                    if (errors && errors.length > 0) {
                        for (let j = 0; j <= errors.length - 1; j++) {
                            const errorNode = errors[j];
                            di.parentNode.insertBefore(errorNode.cloneNode(true), di);
                        }
                    }

                    const subProcessMessages = subRoot.getElementsByTagNameNS(nameSpace, 'message');
                    if (subProcessMessages.length > 0) {
                        for (let k = 0; k <= subProcessMessages.length - 1; k++) {
                            const messageNode = subProcessMessages[k];
                            di.parentNode.insertBefore(messageNode.cloneNode(true), di);
                        }
                    }

                } else {
                    // если подпроцесс не выбран преобразуем subProcess в userTask
                    const newNode = mainDoc.createElementNS(nameSpace, 'userTask');
                    newNode.setAttribute('id', taskId);
                    newNode.setAttribute('name', subProcessNode.getAttribute('name'));
                    const defaultAttr = subProcessNode.getAttribute('default');
                    if (defaultAttr) {
                        newNode.setAttribute('default', subProcessNode.getAttribute('default'));
                    }
                    const subProcessNodeChilds = subProcessNode.childNodes;
                    for (let k = 0; k <= subProcessNodeChilds.length - 1; k++) {
                        const childNode = subProcessNodeChilds[k];
                        if (childNode.nodeName === 'bpmn:incoming' || childNode.nodeName === 'bpmn:outgoing') {
                            const childNodeElement = mainDoc.createElement(childNode.nodeName);
                            childNodeElement.textContent = childNode.firstChild.nodeValue;
                            newNode.appendChild(childNodeElement);
                        }
                    }
                    subProcessNode.parentNode.insertBefore(newNode, subProcessNode);
                    nodesForDelete.push(subProcessNode);
                }
            }
        }
        if (nodesForDelete.length > 0) {
            for (let l = 0; l <= nodesForDelete.length - 1; l++) {
                const deletedNode = nodesForDelete[l];
                // console.log('delete node', deletedNode);
                deletedNode.parentNode.removeChild(deletedNode);
            }
        }
        return mainDoc.documentElement.outerHTML;
    }

    /**
     * Метод обертывания данных в branch
     * @param data
     */
    public createBranchNode(data) {
        data.unitId = null;
        return {
            inheritance: '1',
            preferences: null,
            branch: [
                data,
            ],
        };
    }

    public cloneStandard(standard) {
        const newStandard = _.cloneDeep(standard);
        newStandard.data.name = 'КОПИЯ ' + newStandard.data.name;
        this.clearStandard(newStandard);
        return this.rest.create('reglaments', newStandard).then((createdStandard: any) => {
            const promises = [];
            promises.push(this.cloneStandardProcess(standard, createdStandard));
            if (standard.additionalDataTabs && standard.additionalDataTabs.length > 0) {
                promises.push(this.cloneAdditionalData(standard, createdStandard));
            }
            return Promise.all(promises).then(() => {
                return this.rest.update('reglaments', createdStandard);
            }, error => Promise.reject(error));
        }, error => Promise.reject(error));
    }

    public clearStandard(standard) {
        delete standard.process;
        delete standard.additionalDataTabs;
        delete standard.dateCreation;
        delete standard.dateLastUpload;
        delete standard.userCreation;
        delete standard.dateLastModification;
        delete standard.userLastModification;
        delete standard._id;
        delete standard.auid;
        delete standard.guid;
    }

    public cloneStandardProcess(standard, createdStandard) {
        let processIds = [];
        processIds.push(standard.process.id);
        const subProcess = standard.process.tasks.filter(item => item.subProcess).map(item => item.subProcess.id);
        if (subProcess.length > 0) {
            processIds = processIds.concat(subProcess);
        }
        const processesForClone = [];
        const processesIds = {};
        return this.rest.search('sperBpmnProcesses', {search: {search: [{field: '_id', operator: 'in', value: processIds}]}}).then(processes => {
            const promises = [];
            processes.forEach(item => {
                if (item.entityId) {
                    promises.push(this.cloneProcess(item, createdStandard._id));
                    processesForClone.push(item._id);
                } else {
                    processesIds[item._id] = item._id;
                }
            });
            return Promise.all(promises).then(createdProcesses => {
                processesForClone.forEach((process, idx) => {
                    processesIds[process] = createdProcesses[idx]._id;
                });
                const processData = _.cloneDeep(standard.process);
                processData.id = processesIds[processData.id];

                processData.tasks.forEach(task => {
                    if (task.subProcess) {
                        task.subProcess.id = processesIds[task.subProcess.id];
                    }
                });
                createdStandard.process = processData;
                return Promise.resolve();
            }, error => Promise.reject(error));
        }, error => Promise.reject(error));
    }

    public cloneProcess(process, standardId) {
        const newProcess = _.cloneDeep(process);
        newProcess.entityId = standardId;
        delete newProcess._id;
        delete newProcess.auid;
        delete newProcess.dateCreation;
        delete newProcess.userCreation;
        delete newProcess.dateLastModification;
        delete newProcess.userLastModification;
        delete newProcess.guid;
        delete newProcess.file;
        delete newProcess.image;
        return this.rest.create('sperBpmnProcesses', newProcess).then((createdProcess: any) => {
            const promises = [];
            promises.push(this.cloneFile(process.file, createdProcess._id));
            if (process.image) {
                promises.push(this.cloneFile(process.image, createdProcess._id));
            }
            return Promise.all(promises).then(uploadedFiles => {
                createdProcess.file = uploadedFiles[0];
                if (uploadedFiles[1]) {
                    createdProcess.image = uploadedFiles[1];
                }
                return this.rest.update('sperBpmnProcesses', createdProcess);
            }, error => Promise.reject(error));
        }, error => Promise.reject(error));
    }

    public cloneFile(file, processId) {
        const uploadUrl = Config.server + Config.app + Config.api + 'storage/copy';
        return this.httpService.post(uploadUrl + '?entryName=sperBpmnProcesses&entryId=' + processId + '&fileId=' + file._id , {}).then(result => {
                return Promise.resolve(result);
            },
            () => {
                return this.cloneFile(file, processId);
            },
        );
    }

    public cloneAdditionalData(standard, createdStandard) {
        const xsdIds = standard.additionalDataTabs.map(item => item.xsd);
        const cloneXsd = [];
        const newXsd = {};
        return this.rest.search('xsd', {search: {search: [{field: '_id', operator: 'in', value: xsdIds}]}}).then(xsds => {
            const promises = [];
            xsds.forEach(xsd => {
                const xsdForCreate = {
                    parentEntries: 'xsd',
                    xsdContent: xsd.xsdContent,
                };
                cloneXsd.push(xsd._id);
                promises.push(this.rest.create('xsd', xsdForCreate));
            });
            return Promise.all(promises).then(createdXsd => {
                cloneXsd.forEach((xsdId, idx) => {
                    newXsd[xsdId] = createdXsd[idx];
                });
                const additionalData = _.cloneDeep(standard.additionalDataTabs);
                additionalData.forEach(tab => {
                    tab.xsd = newXsd[tab.xsd]._id;
                });
                createdStandard.additionalDataTabs = additionalData;
                return Promise.resolve(true);
            }, error => Promise.reject(error));
        }, error => Promise.reject(error));
    }

    public exportStandard(standard) {
        const standardData: any = {
            standard: standard,
        };
        const promises = [];
        promises.push(this.getStandardPrintFormsData(standard));
        promises.push(this.getStandardAdditionalData(standard));
        promises.push(this._getStandardRequestData(standard));
        promises.push(this.getStandardProcessData(standard));
        return Promise.all(promises).then(data => {
            // Печатные формы
            if (data[0]) {
                standardData.printForms = data[0];
            }
            // XSD
            if (data[1]) {
                standardData.xsd = data[1];
            }
            // Запросы
            if (data[2]) {
                standardData.requests = data[2];
            }
            if (data[3]) {
                standardData.processes = data[3];
            }
            const json = JSON.stringify(standardData);
            const blob = new Blob([json], {type: 'application/json'});
            FileSaver.saveAs(blob, 'standard' + standard.auid + '.json');
        });
    }

    public getStandardPrintFormsData(standard) {
        if (standard.printForms && standard.printForms.length > 0) {
            return this.rest.search('printForms', {search: {search: [{field: '_id', operator: 'in', value: standard.printForms}]}}).then(printForms => {
                let filesIds = printForms.filter(item => item.templateFile).map(item => item.templateFile._id);
                const customXSLFilesIds = printForms.filter(item => item.customXSLFile).map(item => item.customXSLFile._id);
                filesIds = filesIds.concat(customXSLFilesIds);
                return this.getFilesContent(filesIds).then(filesContent => {
                    return {
                        printForms: printForms,
                        filesContent: filesContent,
                    };
                });

            });
        } else {
            return false;
        }
    }

    public getStandardAdditionalData(standard) {
        if (standard.additionalDataTabs && standard.additionalDataTabs.length > 0) {
            const xsdIds = standard.additionalDataTabs.filter(item => item.xsd).map(item => item.xsd);
            return this.rest.search('xsd', {search: {search: [{field: '_id', operator: 'in', value: xsdIds}]}});
        } else {
            return false;
        }
    }

    public _getStandardRequestData(standard) {
        if (standard.requests && standard.requests.length > 0) {
            return this.rest.search('requests', {search: {search: [{field: '_id', operator: 'in', value: standard.requests}]}}).then(requests => {
                const promises = [];
                const xsdIds = requests.filter(item => item.xsd).map(item => item.xsd);
                promises.push(this.rest.search('xsd', {search: {search: [{field: '_id', operator: 'in', value: xsdIds}]}}));
                let xslIds = [];
                requests.forEach(request => {
                    if (request.xsls) {
                        const ids = request.xsls.map(item => item.fileId);
                        xslIds = xslIds.concat(ids);
                    }
                });
                if (xslIds.length > 0) {
                    promises.push(this.getFilesContent(xslIds));
                }
                return Promise.all(promises).then(data => {
                    return {
                        requests: requests,
                        xsd: data[0],
                        xsl: data[1],
                    };
                });
            });
        }
    }

    public getStandardProcessData(standard) {
        if (standard.process) {
            let processIds = [standard.process.id];
            const subProcessIds = standard.process.tasks.filter(item => item.subProcess).map(item => item.subProcess.id);
            processIds = processIds.concat(subProcessIds);
            return this.rest.search('sperBpmnProcesses', {search: {search: [{field: '_id', operator: 'in', value: processIds}]}}).then(processData => {
                let fileIds = processData.filter(item => item.file).map(item => item.file._id);
                const imageIds = processData.filter(item => item.image).map(item => item.image._id);
                fileIds = fileIds.concat(imageIds);
                return this.getFilesContent(fileIds).then(filesContent => {
                    return {
                        processes: processData,
                        filesContent: filesContent,
                    };
                });
            });
        } else {
            return false;
        }
    }

    public getFilesContent(fileIds) {
        const files = {};
        const promises = [];
        fileIds.forEach(fileId => {
            promises.push(this.getFileContent(fileId));
        });
        return Promise.all(promises).then(filesContent => {
            fileIds.forEach((fileId, idx) => {
                files[fileId] = filesContent[idx];
            });
            return Promise.resolve(files);
        });
    }

    public getFileContent(fileId) {
        return this.filesService
            .downloadFileInBase64(fileId, 'text')
            .then(fileContent => {
                    return Promise.resolve(fileContent);
                },
                () => {
                    return Promise.resolve(null);
                },
            );
    }

    public importStandard(standardData) {
        let printForms = {};
        let xsds = {};
        if (standardData.printForms) {
            printForms = this.createPrintForms(standardData.printForms);
        }
        if (standardData.xsd) {
            xsds = this.createXsd(standardData.xsd);
        }

    }

    public createPrintForms(printFormsData) {
        const printFormIds = {};
        const printForms = printFormsData.printForms;
        const filesContent = printFormsData.filesContent;
        let promises = [];
        const filesForUpload = [];
        printForms.forEach(printForm => {
            const createdPrintForm = _.cloneDeep(printForm);
            this.clearElement(createdPrintForm);
            createdPrintForm.serviceIds = [];
            createdPrintForm.orgIds = [];
            createdPrintForm.notUseServiceIds = [];
            createdPrintForm.docs = [];
            createdPrintForm.notUseOrgIds = [];
            delete createdPrintForm.customXSLFile;
            delete createdPrintForm.templateFile;
            promises.push(this.rest.create('printForms', createdPrintForm));
        });
        return Promise.all(promises).then(createdPrintForms => {
            promises = [];
            printForms.forEach((printForm, index) => {
                printFormIds[printForm._id] = createdPrintForms[index]._id;
                if (printForm.templateFile) {
                    const fileContent = filesContent[printForm.templateFile._id];

                    if (fileContent) {
                        filesForUpload.push(printForm.templateFile._id);
                        const fileBlob = ByteUtilities.B64ToBlob(fileContent);
                        promises.push(this.filesService.uploadFile('printForms', createdPrintForms[index]._id, fileBlob, printForm.templateFile.name, false));
                    }
                }
                if (printForm.customXSLFile) {
                    const fileContent = filesContent[printForm.customXSLFile._id];

                    if (fileContent) {
                        filesForUpload.push(printForm.customXSLFile._id);
                        const fileBlob = ByteUtilities.B64ToBlob(fileContent);
                        promises.push(this.filesService.uploadFile('printForms', createdPrintForms[index]._id, fileBlob, printForm.customXSLFile.name, false));
                    }
                }
            });

            return Promise.all(promises).then(createdFiles => {
                promises = [];
                filesForUpload.forEach((fileId, index) => {
                    const findPrintFormWithTemplateFile = printForms.find(item => item.templateFile && item.templateFile._id === fileId);
                    if (findPrintFormWithTemplateFile) {
                        const findCreatedPrintForm = createdPrintForms.find(item => item._id === printFormIds[findPrintFormWithTemplateFile._id]);
                        const printFormForSave = {_id: findCreatedPrintForm._id, guid: findCreatedPrintForm.guid, templateFile: createdFiles[index]};
                        promises.push(this.rest.update('printForms', printFormForSave));
                    } else {
                        const findPrintFormWithCustomXSLFile = printForms.find(item => item.customXSLFile && item.customXSLFile._id === fileId);
                        const findCreatedPrintForm = createdPrintForms.find(item => item._id === printFormIds[findPrintFormWithCustomXSLFile._id]);
                        const printFormForSave = {_id: findCreatedPrintForm._id, guid: findCreatedPrintForm.guid, customXSLFile: createdFiles[index]};
                        promises.push(this.rest.update('printForms', printFormForSave));
                    }
                });

                return Promise.all(promises).then(() => {
                    return printFormIds;
                });
            });

        });
    }

    public createXsd(xsds) {
        const xsdIds = {};
        const promises = [];
        xsds.forEach(xsd => {
            const xsdForCreate = _.cloneDeep(xsd);
            this.clearElement(xsdForCreate);
            promises.push(this.rest.create('xsd', xsdForCreate));
        });
        return Promise.all(promises).then(createdXsds => {
            xsds.forEach((xsd, index) => {
                xsdIds[xsd._id] = createdXsds[index]._id;
            });
            return xsdIds;
        });
    }

    public createRequests(requestsData) {
        const requests = requestsData.requests;
        const  promises = [];

        requests.forEach(request => {
            if (request.agencies && request.agencies.length > 0) {
                const params = [
                    {field: 'agencies.templateName', operator: 'eq', value: request.agencies[0].templateName},
                    {field: 'agencies.organization.name', operator: 'eq', value: request.agencies[0].organization.name},
                ];
                promises.push(this.rest.search('requests', {search: {search: params}}));
            } else {
                promises.push([]);
            }
        });

        return Promise.all(promises).then(findedRequests => {
            requests.forEach((request, index) => {
            });
        });
    }

    public clearElement(element) {
        delete element._id;
        delete element.auid;
        delete element.dateCreation;
        delete element.userCreation;
        delete element.dateLastModification;
        delete element.userLastModification;
        delete element.guid;
    }
}
