import { NgModule } from '@angular/core';
import { MetodologRouting } from './metodolog.routing';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { KndKindsModule } from '../../modules/knd-kinds/knd-kinds.module';
import { ObjectsModule } from '../../modules/objects/objects.module';
import { CheckListTemplatesModule } from '../../modules/check-list-templates/check-list-templates.module';
import { PrintFormsModule } from '../../modules/print-forms/print-forms.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { RequestsModule } from '@evolenta/requests-module';
import { NumberTemplatesModule } from '@evolenta/number-templates-module';
import { EpguIntegrationModule } from '../../modules/epgu-integration/epgu-integration.module';
import { SmevConfigurationModule } from '../../modules/smev-configuration/smev-configuration.module';

@NgModule({
    imports: [
        MetodologRouting,
        SharedComponentsModule,
        SharedElementsModule,
        KndKindsModule,
        ObjectsModule,
        CheckListTemplatesModule,
        PrintFormsModule,
        EpguIntegrationModule,
        SmevConfigurationModule,
        RequestsModule,
        NumberTemplatesModule,
    ],
})
export class MetodologModule {
}
