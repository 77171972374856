import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, SelectionService, StorageService } from '@evolenta/core';
import { TasksService } from './tasks.service';
import { RsoService } from '../../../common/services/rso.service';

@Injectable()
export class TasksResolve implements Resolve<any> {
    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private tasksService: TasksService,
        private selectionService: SelectionService,
        private rsoService: RsoService
    ) {}

    public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const taskId = route.params['taskId'];
        const mainId = route.params['mainId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create').length > 0;
        const isAllTasks =
            route.parent.url.filter((item) => item.path === 'all-tasks' || item.path === 'schedule').length > 0;
        const isSchedule = route.parent.url.filter((item) => item.path === 'schedule').length > 0;
        const currentOrganization = this.storage.getItem('currentOrganization');

        if (isCreateAction) {
            // Создание
            return {};
        } else if (mainId) {
            if (this.selectionService.isProcessSelect) {
                return {};
            } else {
                // Редактирование
                return this.rest.find('camundaBusinessInfo', mainId).then((camundaBusinessInfo: any) => {
                    if (camundaBusinessInfo.mainId) {
                        const collection =
                            camundaBusinessInfo.collectionName === 'appeals.subservices'
                                ? 'appeals'
                                : camundaBusinessInfo.collectionName;
                        return this.rest.find(collection, camundaBusinessInfo.mainId).then((mainEntity) => {
                            return {
                                appeal: camundaBusinessInfo.mainId ? camundaBusinessInfo : null,
                                task: camundaBusinessInfo.userTasks.find((item) => item.id === taskId),
                                camundaTask: camundaBusinessInfo,
                                mainEntity: mainEntity,
                            };
                        });
                    } else {
                        return {
                            appeal: camundaBusinessInfo.mainId ? camundaBusinessInfo : null,
                            task: camundaBusinessInfo.userTasks.find((item) => item.id === taskId),
                            camundaTask: camundaBusinessInfo,
                            mainEntity: null,
                        };
                    }
                });
            }
        } else {
            // Список
            let search: any = [
                {
                    field: 'userTasks',
                    operator: 'neq',
                    value: [],
                },
                {
                    field: 'userTasks.status',
                    operator: 'eq',
                    value: 'ACTIVE',
                },
            ];

            if (!this.rsoService.isRsoUser()) {
                search.push({ field: 'unit.id', operator: 'eq', value: currentOrganization._id });
            }

            if (!isAllTasks) {
                const login = this.storage.getItem('user').login;
                if (this.rsoService.isRsoOperatorUser()) {
                    search.push({
                        orSubConditions: [
                            {
                                field: 'userTasks.assignee',
                                operator: 'eq',
                                value: login,
                            },
                            {
                                field: 'userTasks.candidateGroups',
                                operator: 'in',
                                value: [this.rsoService.getRsoOperatorRole()],
                            },
                        ],
                    });
                } else if (this.rsoService.isRsoAnySupervisorUser()) {
                    const users = await this.tasksService.getUsers();
                    search.push({
                        field: 'userTasks.assignee',
                        operator: 'in',
                        value: users.map((user) => user.login),
                    });
                } else {
                    search.push({
                        field: 'userTasks.assignee',
                        operator: 'eq',
                        value: login,
                    });
                }
            }

            if (isSchedule) {
                const dates = this.tasksService.getSchedulerGantParams(false);
                search = [...search, ...dates];
            }

            return this.rest.search('camundaBusinessInfo', {
                search: { search: search },
                sort: 'dateLastModification,DESC',
            });
        }
    }
}
