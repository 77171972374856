import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessService, SelectService, StorageService, TranslateService } from '@evolenta/core';
import { Permission } from '../../../../../../../common/services/permission';
import { AppealService } from '../../../../appeal.service';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealEntityOrder } from './models/appeal-entity-order';
import { UtilityService } from '../../../../../../../common/services/utility.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-order',
    templateUrl: 'appeal-entity-order.component.html',
})
export class AppealEntityOrderComponent implements OnInit {
    @Input() public entity: AppealEntityOrder; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public subservices; // описания услуг на основе которых формируется дело
    @Input() public task; // задача процесса
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public editTabs = [];
    public permissions = Permission; // Набор прав системы

    public isCheckPlan = false;
    public isCheckUnplan = false;
    public isChangeNumberAndDate = false;

    /** Справочники */
    public documentTypes = [];

    public usersList = [];
    public usersForSelect = [];

    public currentUser = this.storage.getItem('user');
    public currentOrganization = this.storage.getItem('currentOrganization');

    public localizations;

    /** Предмет проверки */
    public themes = [
        {
            id: '0',
            text: 'соблюдение обязательных требований и (или) требований, установленных муниципальными правовыми актами',
        },
        {
            id: '1',
            text:
                'соответствие сведений, содержащихся в заявлении и документах юридического лица или индивидуального предпринимателя о предоставлении правового ' +
                'статуса, специального разрешения (лицензии) на право осуществления отдельных видов деятельности или разрешения (согласования) на осуществление ' +
                'иных юридически значимых действий, если проведение соответствующей внеплановой проверки юридического лица, индивидуального предпринимателя ' +
                'предусмотрено правилами предоставления правового статуса, специального разрешения (лицензии), выдачи разрешения (согласования) обязательным ' +
                'требованиям, а также данным об указанных юридических лицах и индивидуальных предпринимателях, содержащимся в едином государственном реестре ' +
                'юридических лиц, едином государственном реестре индивидуальных предпринимателей и других федеральных информационных ресурсах',
        },
        {
            id: '2',
            text: 'выполнение предписаний органов государственного контроля (надзора), органов муниципального контроля',
        },
        {
            id: '3',
            text:
                'проведение мероприятий по предотвращению причинения вреда жизни, здоровью граждан, вреда животным, растениям, окружающей среде, ' +
                'объектам культурного наследия (памятникам истории и культуры) народов Российской Федерации, музейным предметам и музейным коллекциям, ' +
                'включенным в состав Музейного фонда Российской Федерации, особо ценным, в том числе уникальным, документам Архивного фонда Российской Федерации, ' +
                'документам, имеющим особое историческое, научное, культурное значение, входящим в состав национального библиотечного фонда',
        },
        {
            id: '4',
            text: 'проведение мероприятий по предупреждению возникновения чрезвычайных ситуаций природного и техногенного характера',
        },
        {
            id: '5',
            text: 'проведение мероприятий по обеспечению безопасности государства',
        },
        {
            id: '6',
            text:
                'проведение мероприятий по ликвидации последствий причинения жизни, здоровью граждан, вреда животным, растениям, окружающей среде, ' +
                'объектам культурного наследия (памятникам истории и культуры) народов Российской Федерации, музейным предметам и музейным коллекциям, ' +
                'включенным в состав Музейного фонда Российской Федерации, особо ценным, в том числе уникальным, документам Архивного фонда Российской Федерации, ' +
                'документам, имеющим особое историческое, научное, культурное значение, входящим в состав национального библиотечного фонда',
        },
    ];

    public constructor(
        public accessService: AccessService,
        public appealService: AppealService,
        public appealEntitiesService: AppealEntitiesService,
        public utilityService: UtilityService,
        public storage: StorageService,
        private selectService: SelectService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);

        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);

        // Типы документов
        this.appealEntitiesService.getDocumentTypes().subscribe((data) => {
            this.documentTypes = this.selectService.transformForSelect('code', 'name', data);
        });

        // Пользователи
        this.appealEntitiesService.getUsers().then((data) => {
            this.usersList = data;

            if (this.usersList) {
                this.usersForSelect = this.selectService.transformForSelect('id', 'name', this.usersList);
            }
            const isDemoStend = this.storage.getItem('demoStend').isDemo;
            if (isDemoStend) {
                this.initDemo();
            } else {
                this.init();
            }
        });
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.entities']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public init() {
        if (!this.entity.number || !this.entity.date) {
            this.isChangeNumberAndDate = true;
        }
        if (!this.entity.themes) {
            this.entity.themes = [];
        }
        if (!this.entity.mandatoryReqs) {
            this.entity.mandatoryReqs = [];
        }
        if (!this.entity.eventKnm) {
            this.entity.eventKnm = [];
        }
        if (!this.entity.requestedDocument) {
            this.entity.requestedDocument = [];
        }
        if (!this.entity.employeeAuthor) {
            const currentInspector = this.usersList.find((item) => item._id === this.currentUser._id);
            this.entity.employeeAuthor = _.cloneDeep(currentInspector);
        }
    }

    public initDemo() {
        if (!this.entity.date) {
            this.entity.date = this.appeal.dateRegister;
        }
        if (!this.entity.number) {
            this.entity.number = this.appeal.number;
        }
        if (!this.entity.purposeKnm) {
            this.entity.purposeKnm = {
                _id: '5ea5acc7dd84960001f02266',
                code: '3',
                name:
                    'Проверка информации из обращений и заявлений граждан, юридических лиц, индивидуальных предпринимателей, ' +
                    'а также сведений об информации, поступившей от органов государственной власти и органов местного самоуправления, из средств массовой информации',
            };
        }
        if (!this.entity.themes) {
            this.entity.themes = [
                {
                    id: '0',
                    text: 'соблюдение обязательных требований и (или) требований, установленных муниципальными правовыми актами',
                },
            ];
        }
        if (!this.entity.dateStart) {
            this.entity.dateStart = this.appeal.dateRegister;
        }
        if (!this.entity.dateEnd && this.appeal.datePlaneFinish) {
            this.entity.dateEnd = this.appeal.datePlaneFinish;
        }
        if (!this.entity.mandatoryReqs) {
            this.entity.mandatoryReqs = [
                {
                    guid: '0f974692-a95b-f90c-c7b4-9888c072ce31',
                    value: {
                        _id: '5ec644a2fcff1000014e15ab',
                        elementsBlock: [
                            {
                                nPA: {
                                    code: 'НПА РЖН0000200',
                                    name: 'п. 4.8.10  Правил и норм технической эксплуатации жилищного фонда, утвержденных Постановлением Госстроя РФ от 27.09.2003 N 170',
                                },
                            },
                        ],
                        code: 'ОТ ГЖН0000201',
                        name:
                            'Входные крыльца должны отвечать требованиям:  осадка стен и пола крылец не допускается более чем на 0,1 м; стены крылец, ' +
                            'опирающиеся на отдельно стоящие фундаменты, не должны иметь жесткой связи со стенами здания;  проветриваемое подполье или ' +
                            'пространство под крыльцами должно быть открыто для осмотра;  козырьки над входами и ступени крылец следует очищать при снегопадах ' +
                            'не допуская сползания снега;  не допускается попадание воды в подвал или техподполье из-за неисправности отмостки или водоотводящих' +
                            ' устройств под крыльцами',
                        guid: '8640e5a8-ef77-4fde-875d-6734934223f6',
                        auid: 266,
                        parentEntries: 'mandatoryReqs',
                        dateCreation: '2020-05-21T09:06:42.053+0000',
                        userCreation: {
                            _id: '5e1f0dd1a435060001f80365',
                            login: 'bars',
                            name: 'БАРС груп',
                            id: '13f00b7b-8d78-49fb-a709-9c98034f6f41',
                        },
                        dateLastModification: '2020-05-21T09:06:42.053+0000',
                        userLastModification: {
                            _id: '5e1f0dd1a435060001f80365',
                            login: 'bars',
                            name: 'БАРС груп',
                            id: '13f00b7b-8d78-49fb-a709-9c98034f6f41',
                        },
                        entityType: null,
                    },
                },
            ];
        }
        if (!this.entity.employeeSignatory) {
            const supervisor = this.usersList.find((u) =>
                u.linkRolesMnemonics.some(
                    (m) => m.roleMnemonic === 'supervisor' && m.sprOrganization === this.currentOrganization.id
                )
            );
            if (supervisor) {
                this.entity.employeeSignatory = {
                    id: supervisor._id,
                    name: supervisor.name,
                    position: supervisor.position,
                    login: supervisor.login,
                    mobile: supervisor.mobile,
                    email: supervisor.email,
                };
            }
        }
        if (!this.entity.eventKnm) {
            this.entity.eventKnm = [
                {
                    guid: 'c07c9bf3-296e-bf87-b724-31870e3668b5',
                    event: {
                        _id: '5ec61d38fcff1000014e14f2',
                        code: '3',
                        name: 'Обследование здания при осуществлении деятельности ',
                        guid: 'da2ab83e-1e27-4127-af65-82d9dcd954a4',
                        auid: 7,
                        parentEntries: 'kNMEvent',
                        dateCreation: '2020-05-21T06:18:32.378+0000',
                        userCreation: {
                            _id: '57e389b2219bc9bd22fb9ab8',
                            id: 'f63aa091-784d-44c4-883a-c2f8346cbe25',
                            login: 'admin',
                            name: 'Шубин Николай Васильевич',
                        },
                        dateLastModification: '2020-05-21T06:18:32.378+0000',
                        userLastModification: {
                            _id: '57e389b2219bc9bd22fb9ab8',
                            id: 'f63aa091-784d-44c4-883a-c2f8346cbe25',
                            login: 'admin',
                            name: 'Шубин Николай Васильевич',
                        },
                        entityType: null,
                    },
                    date: null,
                },
            ];
        }
        if (!this.entity.employeeAuthor) {
            const currentInspector = this.usersList.find((item) => item._id === this.currentUser._id);
            this.entity.employeeAuthor = _.cloneDeep(currentInspector);
        }
        if (!_.get(this.entity, 'requestedDocument.0.value', null)) {
            this.entity.requestedDocument = [
                {
                    guid: '9a3f2e11-22af-cb73-54cc-4b8d9b68e989',
                    value: {
                        _id: '5e9259e7d70396000158f804',
                        code: '0',
                        name: 'Устав организации',
                        guid: '6a0a203d-ab63-4fb7-9fa3-26a93849db1d',
                        auid: 1,
                        parentEntries: 'requestedDocument',
                        dateCreation: '2020-04-11T23:59:35.634+0000',
                        userCreation: {
                            _id: '57e389b2219bc9bd22fb9ab8',
                            login: 'admin',
                            name: 'Иванов Иван Муратович',
                            id: 'f63aa091-784d-44c4-883a-c2f8346cbe25',
                        },
                        dateLastModification: '2020-04-27T23:09:46.938+0000',
                        userLastModification: {
                            _id: '57e389b2219bc9bd22fb9ab8',
                            login: 'admin',
                            name: 'Иванов Иван Муратович',
                            id: 'f63aa091-784d-44c4-883a-c2f8346cbe25',
                        },
                        entityType: null,
                    },
                },
            ];
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }
    public getProperty(property) {
        return '';
    }

    /**
     * Поиск ранее выбранного элемента списка
     * @param items
     * @param property
     * @returns {any}
     */
    public activeItemSelect(items, property): any {
        return this.entity[property] ? [{ id: this.entity[property].id, text: this.entity[property].name }] : [];
    }

    public selectValue(value, property) {
        if (!this.entity[property]) {
            this.entity[property] = {};
        }
        const find = this.usersList.find((item) => item.id === value.id);
        if (find) {
            this.entity[property] = {
                id: find._id,
                name: find.name,
                login: find.login,
                position: find.position,
                email: find.email,
                mobile: find.mobile,
            };
        }
    }

    public clearValue(event, property) {
        this.entity[property] = null;
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }

    public changeSubjectLicenseActual() {
        this.entity.isSubjectLicenseNotActual = !this.entity.isSubjectLicenseNotActual;
        if (this.entity.isSubjectLicenseNotActual) {
            this.entity.subjectLicenseNotActualProperties = {
                date: null,
                documentProperties: null,
                lastAppealDate: null,
            };
        } else {
            this.entity.subjectLicenseNotActualProperties = null;
        }
    }
}
