import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/*
"Упрощенное" решение форсированной полной перезагрузки компонента редактирования дела,
чтобы перезапустить всю логику инициализации и очистки, заложенную в
resolve, init, destroy этапах у компонента AppealEditComponent,
без необходимости выносить её еще в один отдельный сервис с сопутствующими существенными доработками.
Использование часто применяемых для этого настроек onSameUrlNavigation и runGuardsAndResolvers нежелательно,
т.к. местами "ломает" логику работы сохранения (бывают потери введенных данных).
Вариант с полной перезагрузкой страницы повлечет за собой увеличенную задержку работы в интерфейсе.
 */
@Component({
    selector: 'appeal-edit-reload',
    template: '<div></div>',
})
export class AppealEditReloadComponent implements OnInit {
    public constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    public ngOnInit() {
        const moduleBaseUrl = this.route.snapshot.parent.parent.url.reverse()[0].path;
        const appealId = this.route.snapshot.url.reverse()[0].path;
        console.log(`reload edit appeal component for ${appealId}`);
        this.router.navigate([moduleBaseUrl, 'appeals', 'edit', appealId], {replaceUrl: true});
    }
}
