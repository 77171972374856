import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService, TranslateService } from '@evolenta/core';
import { NsiService } from '../nsi.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'nsi-group-dictionaries',
    templateUrl: 'nsi-group-dictionaries.component.html',
    styleUrls: ['../../../elements-list.css'],
    styles: ['.navbar-title, .navbar-menu { width: calc(100% / 2); }'],
})
export class NsiGroupDictionariesComponent implements OnInit {
    public groupCode; // код группы справочников
    public group; // текущая группа
    public globalSearch;

    public isRegionalDictionary = false;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public dictionaries = [];
    private _dictionaries = [];
    public localizations;

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private nsiService: NsiService,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.url.subscribe((data) => {
            this.groupCode = data[0].path;
            this.group = this.nsiService.structure.find((item) => item.code === this.groupCode);
            this.dictionaries = this.nsiService.collectionsMeta.filter((item) => {
                return (
                    item.dictionaryParams &&
                    item.dictionaryParams.section === this.groupCode &&
                    (!this.group.isRegional || item.dictionaryParams.unitId === this.currentOrganization._id)
                );
            });
        });
    }

    private _loadTranslations() {
        this.translate.get(['common']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public goToDictionary(dictionary) {
        this.router.navigate(['/nsi/' + this.groupCode + '/' + dictionary.collectionName]);
    }

    public goToEditDictionary(dictionary) {
        this.router.navigate(['/nsi/' + this.groupCode + '/edit/' + dictionary.collectionName]);
    }

    public createDictionary(): void {
        this.router.navigate(['/nsi/' + this.groupCode + '/create']);
    }

    public get allowCreate(): boolean {
        // TODO: Выяснить признак досутпности кнопки создания нового справочника.
        return true;
    }

    public search() {
        if (this.globalSearch !== '') {
            this.dictionaries = this._dictionaries.filter((e) => {
                return new RegExp(`${this.globalSearch.toLowerCase()}`).test(e.name.toLowerCase());
            });
        } else {
            this.dictionaries = this._dictionaries;
        }
    }

    public clearSearch() {
        this.globalSearch = '';
        this.search();
    }
}
