<!-- Экранная форма информации по лицензии и реестру: переход с QR-кода -->
<registry-receipt *ngIf="mode === 'print'"
                  [registryId]="registryId">
</registry-receipt>

<!-- Основной интерфейс -->
<ng-container *ngIf="mode != 'print'">

    <div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
        <breadcrumbs></breadcrumbs>
        <button class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs hide"
                type="button"
                disabled>
            <b><i class="icon-checkmark3"></i></b>
            {{ localizations.common.save }}
        </button>
        <div class="ml-20 navbar-title pt-20"
            *ngIf="activeRegistry">
            <h4>
                {{ registersService.getTitle(activeRegistry.titles, 'common', 'single', localizations.processes.license) }}
                <label class="text-slate text-bold ml-5">
                    № {{ license.number }}
                    {{ localizations.common.from }}
                    {{ license.dateIssued | date: 'shortDate' }}
                </label>

                <!-- Статус -->
                <label
                    class="badge text-uppercase ml-15 no-margin-bottom"
                    [ngClass]="RegistersService.GetPropertyByStatus(license, 'background')"
                    *ngIf="license.status">
                    {{ RegistersService.GetPropertyByStatus(license, 'name') }}

                    <ng-container *ngIf="license.dateAnnulled">
                        - {{license.dateAnnulled | date: 'shortDate'}}
                    </ng-container>

                    <ng-container *ngIf="license?.status === 'merged' && license?.mergedLicense?.number">
                        {{ localizations.processes.with_license }} №
                        <span class="text-normal">
                            {{ license.mergedLicense.number }}
                        </span>
                    </ng-container>
                </label>

            </h4>
        </div>
        <div class="navbar-menu">
            <user-tile></user-tile>
        </div>
    </div>
    <div class="page-container">
        <!-- Меню разделов -->
        <div class="sidebar sidebar-secondary sidebar-default">
            <div class="sidebar-content">
                <ng-container *ngFor="let tab of tabs">
                    <div class="sidebar-category"
                         *ngIf="tab.code !== 'checks' || registryParams.useChecksTab"
                         (click)="getActiveTab(tab.code)"
                         [ngClass]="{
                            'active': activeTab == tab.code,
                             'cursor-pointer' : activeTab != tab.code
                         }">
                        <div class="category-title">
                            <span>
                                {{ tab.name }}
                            </span>
                        </div>
                    </div>
                </ng-container>

                <div class="m-20 mt-30"
                     *ngIf="activeTab !== 'registers' && registryPrintForm && activeRegistry">
                    <button class="btn no-white-space bg-blue-700 width-100 text-size-large"
                            [ngClass]="{'flash': isProcessingRegisterReceipt}"
                            (click)="generateReceipt()">
                        {{ localizations.processes.form_current_statement }}
                    </button>
                </div>

                <div class="center-button"
                     *ngIf="activeTab === 'registers' && uploadListButtonAllowed">
                    <button class="btn btn-primary"
                            [disabled]="reportsService.reportCreationIsInProgress"
                            (click)="createReport()">
                        {{ localizations.reports.create }}
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-main">
            <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-radius no-margin no-padding">
                <div class="panel panel-shadow no-margin">
                    <div class="panel-heading">
                        <h5 class="panel-title">
                            {{ license.registryEntryType.name }}
                        </h5>
                    </div>
                </div>

                <!-- Основные данные объекта -->
                <ng-container *ngIf="activeTab == 'common'">
                    <registry-blocks
                        [blocks]="registryParams.structure"
                        [dataSource]="activeRegistry"
                        *ngIf="registryParams.structure && registryParams.structure.length > 0">
                    </registry-blocks>
                    <registry-element
                        [license]="license"
                        [activeRegistry]="activeRegistry"
                        *ngIf="!registryParams.structure || registryParams.structure.length == 0">
                    </registry-element>
                </ng-container>

                <!-- Реестровые записи -->
                <ng-container *ngIf="activeTab == 'registers'">

                    <!-- Детальная информация о лицензионной записи -->
                    <ng-container *ngIf="viewedRegistry">
                        <div class="panel panel-shadow m-20 panel-small">
                            <div class="panel-heading">
                                <h4 class="panel-title pt-15">
                                    <span>
                                        {{ localizations.processes.info_about_registry_record }}
                                    </span>
                                </h4>
                                <div class="heading-elements">
                                    <button class="btn btn-danger-overline heading-btn btn-labeled"
                                            (click)="close()">
                                        {{ localizations.common.close }}
                                    </button>
                                </div>
                            </div>

                            <div class="panel-body no-padding">
                                <registry-blocks
                                    [blocks]="registryParams.structure"
                                    [dataSource]="viewedRegistry"
                                    *ngIf="registryParams.structure && registryParams.structure.length > 0">
                                </registry-blocks>
                                <registry-element
                                    [license]="license"
                                    [activeRegistry]="viewedRegistry"
                                    [noPadding]="true"
                                    *ngIf="!registryParams.structure || registryParams.structure.length == 0">
                                </registry-element>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Список лицензионных записей -->
                    <div class="p-20"
                         *ngIf="!viewedRegistry">
                        <div class="panel panel-shadow panel-small"
                             *ngFor="let item of registers">
                            <div class="panel-heading">
                                <h5 class="panel-title no-padding-top">
                                    № {{ item.auid ? item.auid : 'n/a' }} {{ localizations.common.from }} {{item.dateCreation | date: 'shortDate'}}
                                    <div>
                                        <span class="badge {{RegistersService.GetStatusProperty(item.operationType, 'background', 'OperationTypes')}} text-uppercase">
                                            {{RegistersService.GetStatusProperty(item.operationType, 'registerName', 'OperationTypes')}}
                                        </span>
                                        <span class="badge {{RegistersService.GetStatusProperty(item.status, 'background', 'RegisterStatuses')}} text-uppercase ml-10">
                                            {{RegistersService.GetStatusProperty(item.status, 'name', 'RegisterStatuses')}}
                                        </span>
                                    </div>
                                </h5>
                                <div class="heading-elements">
                                    <div class="heading-elements">
                                        <button class="btn btn-default btn-sxs"
                                                type="button"
                                                style="padding: 13px 15px"
                                                [disabled]="isProcessingRegisterReceipt && processingPrintRegistryId == item._id"
                                                [title]="localizations.processes.form_statement"
                                                (click)="generateReceipt(item)">
                                            <i class="icon-printer4 text-muted"
                                               [ngClass]="{
                                                            'icon-printer4': !isProcessingRegisterReceipt || processingPrintRegistryId != item._id,
                                                            'icon-spinner2 spinner': isProcessingRegisterReceipt && processingPrintRegistryId == item._id
                                                            }">
                                            </i>
                                        </button>
                                        <button class="btn btn-default btn-sxs"
                                                (click)="view(item)"
                                                type="button" [title]="'Подробная информация'">
                                            <i inlineSVG="assets/icon-eye.svg" *ngIf="!item.isProcessing"></i>
                                            <i class="icon-spinner spinner2" *ngIf="item.isProcessing"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <!-- Все проверки -->
                <div class="p-20" *ngIf="activeTab == 'checks'">
                    <div class="panel panel-shadow" *ngFor="let check of license.checks">
                        <div class="panel-body">
                            <registry-data [data]="check"></registry-data>
                        </div>
                    </div>
                </div>

                <!-- История -->
                <div class="p-20" *ngIf="activeTab == 'history'">
                    <ul class="list-feed no-margin">
                        <li *ngFor="let data of historyData">
                            <div>
                                <p class="no-margin" *ngIf="data.description"><span class="text-muted">Действие:</span> {{data.description }}</p>
                            </div>
                            <div class="text-size-small">
                                <span class="text-muted">{{data.dateViewed | date: 'medium'}}</span>
                                <span class="text-info ml-5">{{data.userViewed.name}} <span *ngIf="'data.userViewed.position'" class="ml-5 text-slate">({{data.userViewed.position}})</span></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </evolenta-scrollbar>
        </div>
    </div>
</ng-container>
