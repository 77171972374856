<div [ngClass]="{'panel panel-shadow m-20': !showInside}">
    <form #subjectForm="ngForm">
        <!-- Данные организации -->
        <fieldset *ngIf="user.specialTypeId == 'ulApplicant'">
            <subject-form-organization
                [organization]="user"
                [appealMode]="appealMode"
                [isProcessValidate]="isProcessValidate"
                [additionalUsers]="additionalUsers"
                [allowedEditFields]="allowedEditFields"
                #organizationForm>
            </subject-form-organization>
        </fieldset>

        <ng-container *ngIf="user.specialTypeId === 'foreignUlApplicant'">
            <subject-form-foreign-organization
                [organization]="user"
                [isProcessValidate]="isProcessValidate"
                [allowedEditFields]="allowedEditFields"
                #foreignOrganizationForm>
            </subject-form-foreign-organization>
        </ng-container>

        <!-- ФИО + Дата рождения + Пол-->
        <fieldset *ngIf="user.specialTypeId == 'individualApplicant' || user.specialTypeId == 'ipApplicant'">
            <hr class="no-margin"
                *ngIf="user.specialTypeId == 'ulApplicant'">
            <div class="pl-20 pr-20 pb-20"
                 [ngClass]="{
                    'pt-15': checkIsAddNewUser(),
                     'pt-20': !checkIsAddNewUser()
                 }">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"
                             [ngClass]="{'no-margin-bottom': user.specialTypeId != 'ulApplicant'}"
                             *ngIf="(user.specialTypeId == 'individualApplicant' || user.specialTypeId == 'ipApplicant') && user.data.person && !user.data.person.isComlpexFio">
                            <label>
                                {{ localizations.subjects.fio }}
                                <span class="text-danger"
                                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.lastName', user)
                                             || fieldsRequirementsService.checkFieldRequired('person.firstName', user)
                                             || fieldsRequirementsService.checkFieldRequired('person.middleName', user)">
                                    *
                                </span>
                                <span class="anchor text-info text-size-small ml-5"
                                      *ngIf="!user.data.person.isComlpexFio"
                                      (click)="user.data.person.isComlpexFio = true">
                                    {{ localizations.subjects.fill_separately }}
                                </span>
                            </label>
                            <ng-container>
                                <div class="input-group input-group-lg" >
                                    <input class="form-control"
                                           name="fio"
                                           [placeholder]="localizations.subjects.enter_fio_dots"
                                           [required]="fieldsRequirementsService.checkFieldRequired('person.lastName', user)
                                                       || fieldsRequirementsService.checkFieldRequired('person.firstName', user)
                                                       || fieldsRequirementsService.checkFieldRequired('person.middleName', user)"
                                           [disabled]="isDisabled('person.lastName') && isDisabled('person.firstName') && isDisabled('person.middleName')"
                                           [(ngModel)]="fio"
                                           (keypress)="checkOnlyCyrillicLetters($event);"
                                           (ngModelChange)="initCompareData(); changeFio()"
                                           (change)="updateErrors('person.lastName'); updateErrors('person.firstName'); updateErrors('person.middleName')">
                                    <span class="input-group-btn">
                                        <button type="button"
                                                class="btn btn-default"
                                                (click)="declensionDetailWide = !declensionDetailWide">
                                            <i class="icon-menu"></i>
                                        </button>
                                    </span>
                                </div>
                                <div class="validation-error-label"
                                     *ngIf="isProcessValidate
                                            && (fieldsRequirementsService.checkFieldRequired('person.lastName', user) && !user.data.person.lastName
                                            || fieldsRequirementsService.checkFieldRequired('person.firstName', user) && !user.data.person.firstName)">
                                    {{ getFioError() }}
                                </div>
                            </ng-container>
                        </div>

                        <div class="row"
                             *ngIf="user.data.person && user.data.person.isComlpexFio">
                            <div class="col-md-4">
                                <div class="form-group form-group-lg no-margin-bottom">
                                    <label>
                                        {{ localizations.subjects.lastname }}
                                        <span class="text-danger"
                                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.lastName', user)">
                                            *
                                        </span>
                                        <span class="anchor text-info text-size-small ml-5"
                                              *ngIf="user.specialTypeId !== 'individualApplicant'"
                                              (click)="user.data.person.isComlpexFio = false; generateFio()">
                                            {{ localizations.subjects.one_line }}
                                        </span>
                                    </label>
                                    <input class="form-control"
                                           type="text"
                                           name="lastName"
                                           [required]="fieldsRequirementsService.checkFieldRequired('person.lastName', user)"
                                           [disabled]="isDisabled('person.lastName')"
                                           (keypress)="checkOnlyCyrillicLetters($event)"
                                           [(ngModel)]="user.data.person.lastName"
                                           (ngModelChange)="initCompareData(); calculateHeader(); declensionFIO();"
                                           #lastName="ngModel"
                                           ngxTrim>
                                    <div class="validation-error-label"
                                         *ngIf="lastName.errors && isProcessValidate">
                                        <span *ngIf="lastName.errors.required">
                                            {{ localizations.subjects.last_name_is_required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group form-group-lg no-margin-bottom">
                                    <label>
                                        {{ localizations.subjects.firstname }}
                                        <span class="text-danger"
                                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.firstName', user)">
                                            *
                                        </span>
                                    </label>
                                    <input class="form-control"
                                           type="text"
                                           name="firstName"
                                           #firstName="ngModel"
                                           [required]="fieldsRequirementsService.checkFieldRequired('person.firstName', user)"
                                           [(ngModel)]="user.data.person.firstName"
                                           [disabled]="isDisabled('person.firstName')"
                                           (keypress)="checkOnlyCyrillicLetters($event)"
                                           (ngModelChange)="initCompareData(); calculateHeader(); declensionFIO()"
                                           ngxTrim>
                                    <div class="validation-error-label"
                                         *ngIf="firstName.errors && isProcessValidate">
                                        <span *ngIf="firstName.errors.required">
                                            {{ localizations.subjects.first_name_is_required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group form-group-lg no-margin-bottom">
                                    <label>
                                        {{ localizations.subjects.middlename }}
                                        <span class="text-danger"
                                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.middleName', user)">
                                            *
                                        </span>
                                        <span class="text-info anchor text-size-small ml-5"
                                              (click)="declensionDetailWide = !declensionDetailWide">
                                            {{ localizations.subjects.full_name_declensions }}
                                        </span>
                                    </label>
                                    <input class="form-control"
                                           type="text"
                                           name="middleName"
                                           [required]="fieldsRequirementsService.checkFieldRequired('person.middleName', user)"
                                           [disabled]="isDisabled('person.middleName')"
                                           [(ngModel)]="user.data.person.middleName"
                                           (keypress)="checkOnlyCyrillicLetters($event)"
                                           (ngModelChange)="initCompareData(); calculateHeader(); declensionFIO()"
                                           #middleName="ngModel"
                                           ngxTrim>
                                    <div class="validation-error-label"
                                         *ngIf="isProcessValidate && middleName.errors">
                                        <span *ngIf="middleName.errors.required">
                                            {{ localizations.subjects.middle_name_is_required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="collapse dropdown-block arrow-right mt-10"
                             [ngClass]="{'in': declensionDetailWide}">
                            <div class="dropdown-block-heading cursor-pointer"
                                 (click)="declensionDetailWide = !declensionDetailWide">
                                Склонение ФИО участника
                                <span class="pull-right">
                                    <strong class="icon-cross3"></strong>
                                </span>
                            </div>
                            <div class="dropdown-block-content pb-15">
                                <evolenta-declension-by-case name="declineFio"
                                                             [targetObject]="{firstName: currentFirstName, lastName: currentLastName, middleName: currentMiddleName}"
                                                             [gender]="user?.data?.person?.sex"
                                                             [ngModel]="user.data.person.declensionOfFIO"
                                                             [fields]="[{columnTitleName: localizations.subjects.lastname, fieldName: 'lastName'},
                                                                        {columnTitleName: localizations.subjects.name, fieldName: 'firstName'},
                                                                        {columnTitleName: localizations.subjects.middlename, fieldName: 'middleName'}]"
                                                             *ngIf="user.data && user.data.person"
                                                             (ngModelChange)="declension($event)">
                                </evolenta-declension-by-case>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2"
                         *ngIf="user.specialTypeId != 'ulApplicant'">
                        <div class="form-group no-margin-bottom">
                            <label>
                                {{ localizations.subjects.date_of_birth }}
                                <span class="text-danger"
                                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.birthday', user)">
                                    *
                                </span>
                            </label>
                            <evolenta-datepicker name="birthday"
                                                 [large]="true"
                                                 [valueAsString]="false"
                                                 [required]="fieldsRequirementsService.checkFieldRequired('person.birthday', user)"
                                                 [disabled]="isDisabled('person.birthday')"
                                                 [(ngModel)]="user.data.person.birthday"
                                                 (ngModelChange)="initCompareData(); calculateHeader(); updateErrors('person.birthday')"
                                                 #birthday="ngModel">
                            </evolenta-datepicker>
                            <div class="validation-error-label"
                                 *ngIf="isProcessValidate && birthday.errors">
                                <span *ngIf="birthday.errors.required">
                                    {{ localizations.subjects.date_of_birth_is_required }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2"
                         *ngIf="user.specialTypeId != 'ulApplicant'">
                        <label class="display-block">
                            {{ localizations.subjects.sex }}
                            <span class="text-danger"
                                  *ngIf="fieldsRequirementsService.checkFieldRequired('person.sex', user)">
                                *
                            </span>
                        </label>
                        <div class="radio-inline pt-10">
                            <input type="radio"
                                   class="radio"
                                   id="maleWide{{user.guid}}"
                                   name="sexWide"
                                   [checked]="user.data.person.sex == 'male'"
                                   [disabled]="isDisabled('person.sex')"
                                   (click)="changeSex('male')">
                            <label for="maleWide{{user.guid}}"
                                   (click)="changeSex('male')">
                                <i class="fa fa-male text-size-large"
                                   [title]="localizations.subjects.male">
                                </i>
                            </label>
                        </div>
                        <div class="radio-inline no-margin-left pt-10">
                            <input id="femaleWide{{user.guid}}"
                                   class="radio"
                                   type="radio"
                                   name="sexWide"
                                   [checked]="user.data.person.sex == 'female'"
                                   [disabled]="isDisabled('person.sex')"
                                   (click)="changeSex('female')">
                            <label for="femaleWide{{user.guid}}"
                                   (click)="changeSex('female')">
                                <i class="fa fa-female text-size-large"
                                   [title]="localizations.subjects.female">
                                </i>
                            </label>
                        </div>
                        <div class="validation-error-label"
                             *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.sex', user) && !user.data.person.sex">
                            {{ localizations.subjects.sex_field_is_required }}
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

        <!-- Документ удостоверяющий личность -->
        <fieldset *ngIf="user.specialTypeId == 'individualApplicant' || user.specialTypeId == 'ipApplicant'">
            <hr class="no-margin">
            <subject-form-document
                [user]="user"
                [appealMode]="appealMode"
                [allowedEditFields]="allowedEditFields"
                [isProcessValidate]="isProcessValidate"
                #documentForm>
            </subject-form-document>
        </fieldset>

        <!-- Адрес -->
        <fieldset *ngIf="user.specialTypeId == 'individualApplicant' || user.specialTypeId == 'ipApplicant'">
            <hr class="no-margin">
            <!-- Адреса регистрации и проживания -->
            <subject-form-address
                [user]="user"
                [appealMode]="appealMode"
                [isProcessValidate]="isProcessValidate"
                [allowedEditFields]="allowedEditFields"
                #addressForm>
            </subject-form-address>
        </fieldset>

        <!-- Контактные данные -->
        <fieldset *ngIf="user.specialTypeId == 'individualApplicant'
                         || user.specialTypeId == 'ipApplicant'
                         || user.specialTypeId == 'ulApplicant'">
            <hr class="no-margin">
            <subject-form-contacts
                [user]="user"
                [appealMode]="appealMode"
                [allowedEditFields]="allowedEditFields"
                [isProcessValidate]="isProcessValidate"
                #contactsForm>
            </subject-form-contacts>
        </fieldset>

        <!-- ОГРН для ИП -->
        <fieldset *ngIf="user.specialTypeId == 'ipApplicant'">
            <hr class="no-margin">
            <div class="pl-20 pr-20 pt-15 pb-20">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group form-group-lg no-margin-bottom">
                            <label>
                                {{ localizations.common.ogrn }}
                                <span class="text-danger"
                                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.ogrn', user)">
                                    *
                                </span>
                            </label>
                            <input type="text"
                                   class="form-control"
                                   name="ogrnIp"
                                   [(ngModel)]="user.data.person.ogrn"
                                   [required]="fieldsRequirementsService.checkFieldRequired('person.ogrn', user)"
                                   [textMask]="{mask: globalParams.ipOgrnMask}"
                                   [disabled]="isDisabled('person.ogrn')"
                                   (ngModelChange)="updateErrors('person.ogrn')"
                                   #ogrnIp="ngModel"
                                   evolentaValidateIpOgrn>
                            <div class="validation-error-label"
                                 *ngIf="isProcessValidate && ogrnIp.errors && ogrnIp.errors.required">
                                {{ localizations.subjects.ogrn_field_is_required }}
                            </div>
                            <div class="validation-error-label"
                                 *ngIf="ogrnIp.errors && ogrnIp.errors.validateIpOgrn">
                                {{ogrnIp.errors.validateIpOgrn}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

         <!-- признак МСП -->
         <fieldset *ngIf="(user.specialTypeId == 'ulApplicant' || user.specialTypeId == 'ipApplicant')">
            <hr class="no-margin">
            <div class="msp pl-20 pr-20 pt-15 pb-20">
                <input class="mr-10" type="checkbox" id="msp" name="msp" (input)="onMspChange($event)" [checked]="user.data.organization?.msp || user.data.person?.msp"/>
                <label for="msp">Заявитель является представителем МСП</label>
            </div>
        </fieldset>
    </form>
</div>
