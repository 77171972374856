<ng-container *ngIf="entity[property]">
    <div class="row">
        <div class="col-md-12">
            <ng-container  *ngFor="let item of entity[property]; index as idx; last as lst;">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <label>
                            {{title}}
                            <span class="anchor text-info text-size-small ml-5" (click)="addItem()" *ngIf="!idx">добавить</span>
                            <span class="anchor text-info text-size-small ml-5" (click)="deleteItem(item)" *ngIf="idx">удалить</span>
                        </label>
                        <div class="row">
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group"  [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                                    <catalogue
                                        [catalogName]="'nsiViolations'"
                                        [(ngModel)]="item.requirement"
                                        [priority]="true"
                                        [large]="large"
                                        [small]="small"
                                        [disabled]="true"
                                        [title]="'Нарушенное обязательное требование'"
                                        [placeholder]="'Укажите обязательное требование'">
                                    </catalogue>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group"  [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                                    <catalogue
                                        catalogName="nPA"
                                        [(ngModel)]="item.npa"
                                        [priority]="true"
                                        [large]="large"
                                        [small]="small"
                                        [disabled]="true"
                                        [title]="'Нарушенный НПА'"
                                        [placeholder]="'Укажите нарушенный НПА'">
                                    </catalogue>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-9">
                        <div class="form-group"  [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                            <input type="text" class="form-control input-xs" [(ngModel)]="item.actionTaken" placeholder="Введите меру по устранению нарушения" title="Мера по устранению нарушения">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group" [ngClass]="{'form-group-lg': large, 'form-group-xs': small}">
                            <evolenta-datepicker [valueAsString]="true" [large]="large" [(ngModel)]="item.date" [placeholder]="'Срок устранения до'" [title]="'Срок устранения до'"></evolenta-datepicker>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
