<div class="panel-footer no-padding">
    <div class="date-elements">
        <div class="pt-10 pb-10 pl-20 pr-20" *ngIf="element.created || element.startTime">
            <div>
                <h5 class="date-text no-margin-top">Дата создания</h5>
                <div class="date-value"><i inlineSVG="assets/icon-clock.svg" class="text-slate"></i>
                    {{(element.created ? element.created : element.startTime) | date: 'medium'}}
                </div>
            </div>
        </div>

        <div class="pt-10 pb-10 pl-20 pr-20">
            <div>
                <h5 class="date-text no-margin-top">{{isActive ? 'Ответственный' : 'Исполнитель'}}</h5>
                <div class="date-value">
                    <i class="icon-user-tie text-slate-400"></i>
                    <span [ngClass]="{'anchor text-info cursor-pointer': allowEdit && allowEditParams}"
                          *ngIf="executor" (click)="changeProperty('assignee')">{{executor.name}}</span>
                    <span class="text-muted" [ngClass]="{'anchor cursor-pointer': allowEdit && allowEditParams}"
                          *ngIf="!executor" (click)="changeProperty('assignee')">не назначен</span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="element.type == 'USER_TASK'">
            <div class="pt-10 pb-10 pl-20 pr-20" *ngIf="isActive || element.followUp">
                <div>
                    <h5 class="date-text no-margin-top">Дата начала</h5>
                    <div class="date-value">
                        <i inlineSVG="assets/icon-clock.svg"></i>
                        <span [ngClass]="{'anchor text-info cursor-pointer': allowEdit && allowEditParams}"
                              *ngIf="element.followUp" (click)="changeProperty('followUp')">{{element.followUp | date: 'mediumDate'}}</span>
                        <span class="text-muted" [ngClass]="{'anchor cursor-pointer': allowEdit && allowEditParams}"
                              *ngIf="!element.followUp" (click)="changeProperty('followUp')">не выбрана</span>
                    </div>
                </div>
            </div>

            <div class="pt-10 pb-10 pl-20 pr-20" *ngIf="isActive || element.due">
                <div>
                    <h5 class="date-text no-margin-top">Плановая дата завершения</h5>
                    <div class="date-value">
                        <i inlineSVG="assets/icon-clock.svg"></i>
                        <span [ngClass]="{'anchor text-info cursor-pointer': allowEdit && allowEditParams}"
                              *ngIf="element.due" (click)="changeProperty('due')">{{element.due | date: 'mediumDate'}}</span>
                        <span class="text-muted" [ngClass]="{'anchor cursor-pointer': allowEdit && allowEditParams}"
                              *ngIf="!element.due" (click)="changeProperty('due')">не выбрана</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="pt-10 pb-10 pl-20 pr-20" *ngIf="element.dateCompleted || element.endTime">
            <div>
                <h5 class="date-text">Дата завершения</h5>
                <div class="date-value"><i inlineSVG="assets/icon-clock.svg"></i>
                    {{(element.dateCompleted ? element.dateCompleted : element.endTime) | date: 'medium'}}
                </div>
            </div>
        </div>

    </div>
</div>


<ng-template #setupPropertyModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{modalTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="processingData.property === 'assignee'">
            <div class="checkbox-block" *ngFor="let user of usedUsers">
                <input type="radio" class="radio" name="assigneeUser" id="user{{user.login}}"
                       [checked]="processingData.executor && processingData.executor == user.login" (change)="processingData.executor = user.login">
                <label for="user{{user.login}}">{{user.name}}</label>
            </div>
            <div class="alert alert-info no-border" *ngIf="usedUsers.length === 0">Отсутствуют пользователи, доступные для выбора</div>
        </ng-container>
        <ng-container *ngIf="processingData.property !== 'assignee'">
            <div class="form-group form-group-lg">
                <evolenta-datepicker [large]="true" [(ngModel)]="processingData.date" [valueAsString]="true"></evolenta-datepicker>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="apply()"
                *ngIf="processingData.property == 'assignee' && processingData.executor
                      || processingData.property != 'assignee' && processingData.date">Применить</button>
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Отмена</button>
    </div>
</ng-template>
