import { Injectable } from '@angular/core';

@Injectable()
export class AisObjectsData {
    // Структура панели фильтрации
    public static filtersPanelItems = [
        {
            name: 'Наименование',
            filters: [
                {
                    fields: [],
                    fieldsBySpace: ['name', 'shortName', 'objectLink.name', 'objectLink.shortName'],
                    type: 'input',
                    shortName: 'Наименование',
                    placeholder: 'Наименование',
                },
            ],
        },
        // {
        //     name: 'Вид объекта',
        //     filters: [
        //         {
        //             fields: ['type.id'],
        //             type: 'dictionary',
        //             labelField: 'name',
        //             dictionary: {
        //                 name: 'objectTypes',
        //                 field: 'id',
        //             }
        //         }
        //     ]
        // }
    ];
}
