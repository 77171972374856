<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Административное производство</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted">Дата составления: </span>
            <ng-container *ngIf="entity.date && !entity.date.formatted">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date || entity.date && !entity.date.formatted">не указано</em>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Дата составления</label>
                                <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" [valueAsString]="true" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-8">
                            <div class="form-group form-group-lg">
                                <label>Место составления</label>
                                <evolenta-address-gar
                                        name="place"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.place"
                                        required
                                        #place="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && place.errors" class="validation-error-label">
                                    <span *ngIf="place.errors.required">Поле "Место составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Лицо, составившее определение -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            property="employee"
                            title="Лицо, составившее определение"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                        <div *ngIf="isProcessValidate && employee.errors" class="validation-error-label">
                            <span *ngIf="employee.errors.nameRequired">Поле "Лицо, составившее определение" обязательно для заполнения</span>
                            <span *ngIf="!employee.errors.nameRequired && employee.errors.positionRequired">Поле "Должность лица, составившего определение" обязательно для заполнения</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Повод для возбуждения дела</label>
                                <textarea name="case" class="form-control" [(ngModel)]="entity.case" #case="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && case.errors" class="validation-error-label">
                                    <span *ngIf="case.errors.required">Поле "Повод для возбуждения дела" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Данные о наличии события правонарушения</label>
                                <textarea name="offenseEvent" class="form-control" [(ngModel)]="entity.offenseEvent" #offenseEvent="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && offenseEvent.errors" class="validation-error-label">
                                    <span *ngIf="offenseEvent.errors.required">Поле "Данные о наличии события правонарушения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Статья КоАП РФ</label>
                                <catalogue catalogName="koap" name="coap" [(ngModel)]="entity.coap" [priority]="true" [large]="true" #coap="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && coap.errors" class="validation-error-label">
                                    <span *ngIf="coap.errors.required">Поле "Статья КоАП РФ" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>ФИО физического лица или законного представителя юридического лица</label>
                                <input type="text" name="individual" class="form-control" [(ngModel)]="entity.individual" #individual="ngModel" required>
                                <div *ngIf="isProcessValidate && individual.errors" class="validation-error-label">
                                    <span *ngIf="individual.errors.required">Поле "ФИО физического лица или законного представителя юридического лица" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>ФИО защитника</label>
                                <input type="text" name="defender" class="form-control" [(ngModel)]="entity.defender" #defender="ngModel" required>
                                <div *ngIf="isProcessValidate && defender.errors" class="validation-error-label">
                                    <span *ngIf="defender.errors.required">Поле "ФИО защитника" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Реквизиты документа, подтверждающего полномочия защитника</label>
                                <input type="text" name="detailsDefender" class="form-control" [(ngModel)]="entity.detailsDefender" #detailsDefender="ngModel" required>
                                <div *ngIf="isProcessValidate && detailsDefender.errors" class="validation-error-label">
                                    <span *ngIf="detailsDefender.errors.required">Поле "Реквизиты документа, подтверждающего полномочия защитника" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
