<!-- Редактирование данных выбранного объекта в деле -->
<ng-container *ngIf="editedSubject">
    <appeal-subject-card [subject]="editedSubject"
                         [availableKinds]="availableSubjectKinds"
                         [activeTab]="activeTab"
                         [appeal]="appeal"
                         [subservice]="subservice"
                         mode="edit"
                         (onApply)="onApplySubject($event)" #editCard>
    </appeal-subject-card>
</ng-container>

<!-- Список объектов дела -->
<div *ngIf="!editedSubject">
    <div class="alert alert-warning alert-styled-left alert-bordered"
         *ngIf="appeal.subjects.length == 0">
            <span class="text-semibold">
                {{localizations.common.attention}}
            </span>
        {{localizations['appeals.subjects'].absent}}
    </div>

    <ng-container *ngFor="let subject of appeal.subjects">
        <appeal-subject-card [subject]="subject"
                             mode="view"
                             [appeal]="appeal"
                             (onEdit)="onEditSubject($event)"
                             (onDelete)="onDeleteSubject($event)"
                             (onInitEdit)="initEdit($event)"></appeal-subject-card>
    </ng-container>



    <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20 mr-20"
            (click)="addSubject()"
            *ngIf="accessService.hasAccess([permissions.Appeal_Object_Create], true, appeal.status)">
        {{localizations['appeals.subjects'].add}}
        <b>
            <i inlineSVG="assets/icon-plus-round.svg"></i>
        </b>
    </button>
</div>

<modal-dialog #applyModal (onSelect)="processingModal($event)"></modal-dialog>
