import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HandlersService, StorageService, ToasterService } from '@evolenta/core';
import { InternalHandlersService } from '../../../../common/services/internal-handlers.service';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-actions',
    templateUrl: 'common-appeal-actions.component.html',
})
export class CommonAppealActionsComponent implements OnInit {
    @Input() public appeal;
    @Input() public subservices;
    @Input() public actions = [];
    @Input() public serviceActions = [];
    @Input() public processData;

    @Output() public onExecuteAction = new EventEmitter();
    @Output() public onActivateEvent = new EventEmitter();
    @Output() public onApplyEvent = new EventEmitter();
    @Output() public onCancelEvent = new EventEmitter();

    public activeEvent;

    public isProcessingAction;

    public currentUser = this.storage.getItem('user');
    public externalData;

    public constructor(
        private storage: StorageService,
        private internalHandlersService: InternalHandlersService,
        private handlerService: HandlersService,
        private errorLoggingService: ErrorLoggingService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this.externalData = { appeal: this.appeal, entity: this.appeal };
    }

    public async executeAction(actionParams) {
        this.isProcessingAction = true;

        if (!actionParams.action.handlers) {
            this.onExecuteAction.emit(actionParams);

            return;
        }

        const endHandlers = actionParams.action.handlers.filter((item) => item.event === 'onStart');

        await this.internalHandlersService.processingHandlers(endHandlers, 'startBefore', 'onStart', this.externalData);
        endHandlers.forEach((handler) => {
            this.handlerService.runHandler(handler, this.externalData);
        });

        await this.internalHandlersService.processingHandlers(endHandlers, 'startAfter', 'onStart', this.externalData);

        if (!this.appeal.validationErrors || !this.appeal.validationErrors.length) {
            this.onExecuteAction.emit(actionParams);
        }

        this.isProcessingAction = false;

        if (this.appeal.validationErrors && this.appeal.validationErrors.length) {
            const errorText = '<ul><li>' + this.appeal.validationErrors.join('</li><li>') + ' </li></ul>';
            this.toaster.html(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText), this.appeal);
        }
    }
}
