import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AccessService,
    FiltersBarComponent,
    FiltersService,
    RestService,
    SelectionService,
    StorageService,
    TranslateService,
} from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { ModalDirective } from 'ngx-bootstrap';
import { Permission } from '../../../common/services/permission';
import { AisObjectsService } from './ais-objects.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-ais-objects',
    templateUrl: './ais-objects.component.html',
})
export class AisObjectsComponent implements OnInit {
    @ViewChild('previewModal', { static: false }) public previewModal: ModalDirective;
    @ViewChild('filtersPanel', { static: false }) private filtersPanel: FiltersBarComponent; // панель фильтров

    public canCreate = this.access.hasAccess([Permission.User_Create]);
    public objects = [];
    public filtersPanelItems = []; // Настройки панели фильтрации
    public mode = 'short';

    // Параметры пагинации
    public totalUsersCount = 0; // Общее число дел
    public currentPage = 0;
    public totalPages: number;

    // Параметры предпросмотра
    public object = null; // Объект, отображаемый в режиме предпросмотра
    public prevent = false;
    public previewMode = false;
    public timer = null;
    public delay = 300;

    public baseSearch = []; // Параметы базового поиска
    public globalSearch;
    public riskCategories = [];
    public searchBySubject = [];

    public moduleBaseUrl;
    public workModeUrl;
    public localizations;

    public constructor(
        public selectionService: SelectionService,
        private access: AccessService,
        private router: Router,
        private storage: StorageService,
        private rest: RestService,
        private aisObjectService: AisObjectsService,
        private route: ActivatedRoute,
        private filtersService: FiltersService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe((urlPath) => {
            this.workModeUrl = urlPath[urlPath.length - 1].path;
        });

        if (this.workModeUrl === 'kno') {
            this.baseSearch.push({
                field: 'unit.id',
                operator: 'eq',
                value: this.storage.getItem('currentOrganization')['_id'],
            });
        }
        this.route.data.subscribe((response) => {
            this.objects = response.resolves;
            if (this.selectionService.isProcessSelect) {
                this.objects = this.objects.map((object) => ({
                    ...object,
                    selected:
                        this.selectionService.selectedItems.filter((item) => item._id === object._id).length !== 0,
                }));
            }
            this.totalUsersCount = this.rest.pagination.total;
        });
        this.filtersPanelItems = _.cloneDeep(this.aisObjectService.filtersPanelItems);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'objects', 'appeals.objects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Создание нового объекта
     */
    public getNewObject() {
        this.router.navigate([this.moduleBaseUrl + '/objects/create']);
    }

    /**
     * Загрузка данных
     * @param type
     */
    public loadData(type) {
        if (
            type === 'replace' ||
            (type === 'add' && ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages))
        ) {
            // Параметры настроенных фильтров
            const search = this.filtersPanel.prepareFilters();
            this.object = null;

            // Страница
            let page = 0;
            if (type === 'add') {
                page = this.currentPage + 1;
            }
            const params = {
                search: { search: [...search, ...this.searchBySubject] },
                size: 20,
                page: page,
                prj: 'objectsList',
            };

            const collection = this.workModeUrl === 'kno' ? 'objectsKno' : 'objects';

            // Обработка параметров глобального поиска
            if (this.globalSearch) {
                if (this.filtersService.collectionsSearchParams[collection].useExternalSearch) {
                    const textSearchOrSubConditions = this.filtersService.prepareDataForExternalSearchFromTextSearch(
                        collection,
                        this.globalSearch
                    );
                    if (textSearchOrSubConditions.length > 0) {
                        params.search.search.push({
                            orSubConditions: textSearchOrSubConditions,
                        });
                    }
                } else {
                    params.search['textSearch'] = this.globalSearch;
                }
            }

            // Получение списка объектов с сервера
            this.rest.search(collection, params).then((objects: any) => {
                this.totalUsersCount = this.rest.pagination.total;
                this.currentPage = this.rest.pagination.page;
                this.totalPages = this.rest.pagination.totalPages;

                this.filtersService
                    .processingLinkedData(collection, objects, 'objectLink', 'globalObjectId', 'objectsList')
                    .then((resultObjects) => {
                        if (type === 'replace') {
                            this.objects = resultObjects;
                        } else {
                            resultObjects.forEach((item) => {
                                this.objects.push(item);
                            });
                        }
                    });
            });
        }
    }

    /**
     * Поиск по нажатию клавиши Enter после ввода поискового запроса
     */
    public search(event?) {
        if (event) {
            if (event.keyCode === 13) {
                this.loadData('replace');
            }
        }
    }

    public clearSearch() {
        this.globalSearch = null;
        this.loadData('replace');
    }

    public clearFilters() {
        this.filtersPanel.clearFilters();
    }

    public click(object) {
        this.object = null;
        setTimeout(() => {
            this.object = object;
        }, 100);
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public dblClick(object) {
        if (this.selectionService.isProcessSelect) {
            object.selected = !object.selected;
            if (object.selected) {
                this.selectionService.selectedItems.push(object);
            } else {
                const findIndex = this.selectionService.selectedItems.findIndex((item) => {
                    return item._id === object._id;
                });
                if (findIndex !== -1) {
                    this.selectionService.selectedItems.splice(findIndex, 1);
                }
            }
        } else {
            clearTimeout(this.timer);
            this.prevent = true;
            this.router.navigate([this.moduleBaseUrl, 'objects', this.workModeUrl, 'edit', object._id]);
        }
    }

    public returnToOperation() {
        // TODO: Обратить внимание, действительно ли нужно передавать additionalData в качеастве аргумента типа NavigationExtras в метод navigate?
        if (this.selectionService.isProcessSelect) {
            if (this.selectionService.additionalData) {
                this.router.navigate(this.selectionService.transferBackUrl, this.selectionService.additionalData);
            } else {
                this.router.navigate(this.selectionService.transferBackUrl);
            }
        }
    }

    public getStylePropery(object, property) {
        const code = object.riskCategory ? object.riskCategory.code : null;
        const defaultProperty = {
            background: 'bg-slate-300',
            borderTop: 'border-top-slate-400',
            backgroundLite: 'bg-slate-50',
        };
        if (code) {
            const find = this.riskCategories.find((item) => item.code === code);
            if (find) {
                return find[property] ? find[property] : '';
            }
        }
        return defaultProperty[property] ? defaultProperty[property] : '';
    }

    public navigateToOtherMode(type) {
        this.router.navigate([this.moduleBaseUrl, 'objects', type]);
    }

    public createObject() {
        this.router.navigate([this.moduleBaseUrl, 'objects', this.workModeUrl, 'create']);
    }
}
