<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            {{ element.name ? element.name : localizations['print-forms'].new_element }}
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="apply()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{ localizations.common.do_cancel }}
            </button>
        </div>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-lg-4 col-sm-4 col-xs-12">
                <div class="form-group form-group-lg">
                    <label class="control-label">
                        {{ localizations['print-forms'].element.code }}
                    </label>
                    <input class="form-control"
                           type="text"
                           [(ngModel)]="element.code">
                </div>
            </div>
            <div class="col-lg-8 col-xs-12 form-group-lg">
                <div class="form-group">
                    <label class="control-label">
                        {{ localizations['print-forms'].element.description }}
                    </label>
                    <input class="form-control"
                           type="text"
                           [(ngModel)]="element.name">
                </div>
            </div>
        </div>
        <legend class="text-bold no-margin-bottom">
            {{ localizations['print-forms'].element.forming_code }}
        </legend>
        <div class="border-bottom border-right border-left border-default">
            <ngx-codemirror
                [options]="options"
                [(ngModel)]="element.serviceTemplate">
            </ngx-codemirror>
        </div>
        <legend class="text-bold no-margin-bottom">
            {{ localizations['print-forms'].element.xsl_variables_block }}
        </legend>
        <div class="border-bottom border-right border-left border-default">
            <ngx-codemirror
                [options]="options"
                [(ngModel)]="element.variablesBlock">
            </ngx-codemirror>
        </div>
        <legend class="text-bold no-margin-bottom">
            {{ localizations['print-forms'].element.xsl_templates_block }}
        </legend>
        <div class="border-bottom border-right border-left border-default">
            <ngx-codemirror
                [options]="options"
                [(ngModel)]="element.templatesBlock">
            </ngx-codemirror>
        </div>
    </div>
</div>
