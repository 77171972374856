import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService, StorageService, UserMessagesService } from '@evolenta/core';

@Injectable()
export class UsersResolve implements Resolve<any> {
    public constructor(
        private restService: RestService,
        private storage: StorageService,
        private userMessagesService: UserMessagesService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const userId = route.params['userId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create').length > 0;
        const isAdminPath = route.parent.parent.url[0].path === 'admin';

        const currentOrganization = this.storage.getItem('currentOrganization');
        const promises = [];

        if (!isCreateAction && !userId) {
            // Получение списка пользователей
            const baseSearch = [];
            if (!isAdminPath) {
                baseSearch.push({
                    field: 'sprOrganizations.id',
                    operator: 'eq',
                    value: currentOrganization.id,
                });
            }
            return this.restService.search('users', { search: { search: baseSearch } });
        } else {
            if (isCreateAction) {
                // Создание
                promises.push({});
            } else if (userId) {
                // Редактирование
                promises.push(this.restService.find('users', userId));
            }

            // Список доступных модулей
            promises.push(this.restService.search('applications'));

            // Функциональные Роли
            const searchParams = [];
            if (!isAdminPath) {
                searchParams.push({ field: 'unitId', operator: 'in', value: [null, currentOrganization._id] });
            }
            promises.push(this.restService.search('catalogueRolesMnemonics', { search: { search: searchParams } }));

            // Виды уведомлений
            if (!this.userMessagesService.kinds) {
                promises.push(this.restService.search('notificationTypes'));
            } else {
                promises.push(null);
            }

            // Режим доступа к запросам
            if (this.storage.getItem('userEnvelopesMode') === null) {
                promises.push(
                    this.restService.search('settings', {
                        search: { search: [{ field: 'name', operator: 'eq', value: 'userEnvelopesMode' }] },
                    })
                );
            } else {
                promises.push(null);
            }

            return Promise.all(promises).then((response) => {
                if (response[3]) {
                    this.userMessagesService.kinds = response[3];
                }

                if (response[4]) {
                    this.storage.setItem('userEnvelopesMode', response[4][0] ? response[4][0].value : false);
                }

                return {
                    user: response[0],
                    applications: response[1],
                    rolesMnemonics: response[2],
                };
            });
        }
    }
}
