import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { HttpService, RestService, StorageService, ToasterService, TranslateService, UserMessagesService, UsersService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppealProcessService } from '../process/appeal-process.service';
import { AppealService } from '../../appeal.service';
import { Config } from '../../../../../common/services/config';
import { TaskAdditionalDataTabComponent } from '../additional-data/task-additional-data-tab.component';
import { AppealProcessTaskXsdDataComponent } from './components/task-xsd-data/task-xsd-data/appeal-process-task-xsd-data.component';
import { AppealSaveService } from '../../appeal-save.service';
import cloneDeep from 'lodash-es/cloneDeep';
import * as moment from 'moment';
import { CommonUtilities } from '@evolenta/utilities';
import { UtilityService } from '../../../../../common/services/utility.service';
import { RsoService } from '../../../../../common/services/rso.service';
import { TasksService } from '../../../../tasks/tasks/tasks.service';
import { getLastHourTimeAndOffset } from '../../../../../common/utils/misc.utils';
import { ICustomParams } from '../../../../../interfaces/components/dynamic-forms.interface';

@Component({
    selector: 'appeal-process-task',
    templateUrl: './appeal-process-task.component.html',
    styleUrls: ['appeal-process-task.component.less'],
})
export class AppealProcessTaskComponent implements OnInit, AfterViewInit {
    @Input() public task; // текущая задача
    @Input() public mode; // режим работы

    public taskData; // объект с параметрами задачи
    public appeal = this.appealService.appeal; // обрабатываемое дело
    public subservice = this.appealService.subservice; // описательная услуга
    public appealSubservice = this.appealService.appeal.subservice; // услуга дела

    // вкладки с элементами
    public tabs = {
        documents: false,
        entities: false,
        xsdTabs: false,
        checkLists: false,
    };

    public editedProperty; // Редактируемый элемент
    public allowApply = false; // доступ к завершению задачи
    public isProcessCompleteTask = false; // Процесс завершения задачи
    public currentOrganization = this.storage.getItem('currentOrganization');
    public allowEdit = true;
    public allowStartTimeEdit = true;
    public allowEditParams = true;
    public usersForSelect;
    public currentAssignee;
    public assigneeUser;
    public localizations;
    public events;
    public newDatePlaneFinish;
    public commentTaskDatePlaneFinish;
    public isEditPlanDate = false;
    public attachedTasks; // таски входящие в этап с текущей
    public willChangeFullTerm = false;
    public newAppealDatePlaneFinish = null;
    public allowChangeAppealDatePlaneFinish = false;
    public completedTaskDiffDays = 0;
    public currentDate;
    private calendars;
    private isAutofill = false;

    @ViewChildren('xsdData') public xsdDataComponents: QueryList<AppealProcessTaskXsdDataComponent> ; // Дополнительные данные
    @ViewChildren('additionalDataTabComponent') public additionalDataTabComponents: QueryList<TaskAdditionalDataTabComponent> ; // Дополнительные данные

    @Output() public onApply = new EventEmitter<boolean>();
    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onCancel = new EventEmitter<any>();
    @Output() public onComplete = new EventEmitter<any>();
    @Output() public onStartEvent = new EventEmitter<any>();

    public modalRef: BsModalRef;
    @ViewChild('datePlanFinishHistoryModal', { static: false }) public datePlanFinishHistoryModal: TemplateRef<any>;
    @ViewChild('setupTaskParamsModal', { static: false }) public setupTaskParamsModal: TemplateRef<any>;
    @ViewChild('changeDateCompleteModal', { static: false }) public changeDateCompleteModal: TemplateRef<any>;
    public processingParam;
    public modalTitle;

    public currentUser = this.storage.getItem('user');
    public isProcessValidate = false;

    public roles = [];
    public feedbackMessageGuid;

    public isDisabledByRso = false;

    public constructor(
        public appealService: AppealService,
        private saveService: AppealSaveService,
        private appealProcessService: AppealProcessService,
        private restService: RestService,
        private storage: StorageService,
        private httpService: HttpService,
        private toaster: ToasterService,
        private usersService: UsersService,
        private modalService: BsModalService,
        private userMessagesService: UserMessagesService,
        private translate: TranslateService,
        private utility: UtilityService,
        private rsoService: RsoService,
        private taskService: TasksService,
    ) {
    }

    public async ngOnInit() {
        this._loadTranslations();
        await this._loadCalendar();
        this.feedbackMessageGuid = CommonUtilities.GenerateGuid();
        this.taskData = this.appealProcessService.getTaskData(this.task);
        if (!this.taskData) {
            this.taskData = {};
        }
        if (!this.taskData.params) {
            this.taskData.params = {};
        }
        this._initDocuments(); // Документы
        this._initEntities(); // Сведения
        this._initAdditionalDataTabs(); // Дополнительные данные
        this._checkTaskVisible(); // Определение параметров видимости задачи
        this.allowEdit = this.task.status === 'ACTIVE';
        this.isAutofill = this.appeal.standardTasksPeriodInfo &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod.autoFill;
        this.allowStartTimeEdit = this.allowEdit && !this.isAutofill;
        await this.usersService.getKnoUsers(false, false);
        if (this.task.assignee) {
            await this._getAssigneeUser();
        }
        await this._getUsersForSelect();
        this.currentAssignee = this.task.assignee;
        if (this.task.status === 'ACTIVE') {
            this._getEvents();
        }

        this.allowEditParams = this.taskData.allowApply || this.currentUser.currentRolesMnemonics && this.currentUser.currentRolesMnemonics.indexOf('supervisor') !== -1;
        this.roles = await this.usersService.getRolesMnemonics();
        this.taskData.allowApply = true;
        if (this.task.status === 'ACTIVE') {
            await this._getAttachedTasks();
        }
        this.allowChangeAppealDatePlaneFinish = Boolean(
            this.task.due &&
            this.appeal.datePlaneFinish &&
            this.appeal.status.code === 'process' &&
            this.appeal.standardTasksPeriodInfo &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod.autoFill,
        );
        let users = null;
        if (this.rsoService.isRsoAnySupervisorUser()) {
           users = await this.taskService.getUsers();
        }
        this.isDisabledByRso = this.rsoService.canNotEditTask(this.appeal, this.task, users);
    }

    public isExpired(): boolean {
        if (this.task.status === 'ACTIVE' && this.task.due) {
            const startOfDueDate = moment(this.task.due).startOf('day');
            const startOfCurrentDate = moment().startOf('day');

            return startOfDueDate < startOfCurrentDate;
        }

        return false;
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.tasks',
                'tasks',
                'appeals',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    private async _loadCalendar() {
        if (this.task.due) {
            const taskYear = moment(this.task.due).year();
            const years = [taskYear - 1, taskYear, taskYear + 1];  // prev, current, next year calendars

            this.calendars = await this.restService.search('unitsProductionCalendars', {
                search: {
                    search: [
                        { field: 'year', operator: 'in', value: years },
                        { field: 'isDefault', operator: 'eq', value: true },
                    ],
                },
            });
        }
    }

    private _isEnvelopeStatusClosedAndFilesAttached(envelopes) {
        let status = false;
        envelopes.forEach(doc => {
            if (doc.envelope && doc.envelope.status === 'closed') {

                const sigFile = doc.files && doc.files.find(file => file.id.endsWith('.sig') || file.id.endsWith('.p7s'));
                const pdfFile = doc.files && doc.files.find(file => file.id.endsWith('.pdf'));

                if (sigFile && pdfFile) {
                    status = true;

                    return;
                }
            }
        });

        return status;
    }

    private _checkTaskVisible() {
        this.taskData.isApproveSignOptionsDisabled = false;
        if (!this.taskData.isCompleteCheckVisible) {
            const currentUser = this.storage.getItem('user');
            this.taskData.isVisible = false;
            this.taskData.allowApply = false;
            let noLimits = true;
            if (this.task.candidateGroups && this.task.candidateGroups.length > 0) {
                noLimits = false;
                const userRolesInCurrentOrganization = currentUser.rolesMnemonics[this.currentOrganization.id];
                const groups = this.task.candidateGroups.filter(item => userRolesInCurrentOrganization.indexOf(item) !== -1);
                if (groups.length > 0) {
                    this.taskData.isVisible = true;
                    this.taskData.allowApply = true;
                }
            }
            if (!this.taskData.isVisible && this.task.candidateUsers && this.task.candidateUsers.length > 0) {
                noLimits = false;
                if (this.task.candidateUsers.indexOf(currentUser.login) !== -1) {
                    this.taskData.isVisible = true;
                }
            }
            if (this.task.assignee) {
                this.taskData.allowApply = this.task.assignee === currentUser.login;
                this.taskData.isVisible = this.task.assignee === currentUser.login;
            } else if (this.taskData.isVisbile) {
                this.taskData.allowApply = true;
            }

            // CIT-1029 - проверка получения результатов услуги СЭД для некоторых стандартов
            this.taskData.params.documentGroups.forEach(document => {
                if (document.mandatoryApproval) {
                    if (!this._isEnvelopeStatusClosedAndFilesAttached(this.appeal.tmpDocs)) {
                        this.taskData.isApproveSignOptionsDisabled = true;
                    }

                    return;
                }
            });

            if (noLimits) {
                this.taskData.isVisible = true;
                this.taskData.allowApply = true;
            }
            this.taskData.isCompleteCheckVisible = true;
        }
    }

    public ngAfterViewInit() {
        if (this.mode === 'edit' && this.checkTaskNotHaveParams()) {
            setTimeout(() => {
                this.onApply.emit(this.task);
            }, 100);
        }
    }

    public checkTaskNotHaveParams() {
        return this.taskData
                && this.taskData.params
                && this.taskData.params.entities
                && this.taskData.params.entities.length === 0
                && this.taskData.params.xsdTabs
                && this.taskData.params.xsdTabs.length === 0
                && this.taskData.params.documentGroups
                && this.taskData.params.documentGroups.length === 0;
    }

    /**
     * Инициализация групп документов, привязанных к задаче
     */
    private _initDocuments() {
        if (this.taskData.params.documentGroups) {
            return;
        }

        this.taskData.params.documentGroups = [];
        this.taskData.params.documentGroupsCodes = [];
        if (!this.taskData.documents || !this.taskData.documents.length) {
            return;
        }

        this.taskData.documents.forEach(document => {
            if (!document.isUsed && !document.isRequired) {
                return;
            }

            const documentGroup = this.subservice.documentGroups.find(item => item.code === document.sperId);
            if (!documentGroup) {
                return;
            }

            this.taskData.params.documentGroups.push(documentGroup);
            this.taskData.params.documentGroupsCodes.push(document.sperId);
        });
    }

    /**
     * Получение визуального параметра задачи по его статусу
     * @param status
     * @param property
     * @returns {any}
     */
    public getPropertyByStatus(status, property) {
        const find = this.appealProcessService.statuses.find(item => item.code === status);
        if (find) {
            return find[property];
        } else {
            return '';
        }
    }

    /**
     * Инициализация набора вкладок с дополнительными данными
     */
    private _initAdditionalDataTabs() {
        if (!this.appealSubservice.xsdData) {
            this.appealSubservice.xsdData = {};
        }
        if (!this.taskData.params.xsdTabs) {
            this.taskData.params.xsdTabs = [];
            if (this.taskData && this.taskData.xsdTabs) {
                this.taskData.xsdTabs.forEach(xsdTabInTaskData => {
                    const xsdTab = this.subservice.xsdTabs.find(xsdTabInSubservice => xsdTabInSubservice.code === xsdTabInTaskData.sperId);
                    if (xsdTab) {
                        if (this.appealSubservice.xsdData && this.appealSubservice.xsdData[xsdTab.tabCode]) {
                            xsdTab.value = this.appealSubservice.xsdData[xsdTab.tabCode];
                        }
                        this.taskData.params.xsdTabs.push(xsdTab);
                    }
                });
            }
        }
    }

    private _initEntities() {
        if (!this.taskData.params.entities) {
            this.taskData.params.entities = [];
            if (this.taskData && this.taskData.entities) {
                this.taskData.entities.forEach(entity => {
                    const find = this.subservice.entities.find(item => item.sperId === entity.sperId);
                    if (find) {
                        if (find.code.toLowerCase() === 'act') {
                            find.isAct = true;
                        }
                        this.taskData.params.entities.push(find);
                        if (find.docGroupId && this.taskData.params.documentGroups) {
                            const findGroupIndex = this.taskData.params.documentGroups.findIndex(item => item.code === find.docGroupId);
                            if (findGroupIndex !== -1) {
                                this.taskData.params.documentGroups.splice(findGroupIndex, 1);
                            }
                            const findIndex = this.taskData.params.documentGroupsCodes.findIndex(item => item === find.docGroupId);
                            if (findIndex !== -1) {
                                this.taskData.params.documentGroupsCodes.splice(findIndex);
                            }
                        }
                    }
                });
            }
        }
    }

    public applyAdditionalData(data) {
        if (!this.appealSubservice.xsdData) {
            this.appealSubservice.xsdData = {};
        }
        this.appealSubservice.xsdData[data.tabCode] = data.value;

        this.onApply.emit(true);
    }

    public prepareDataForSave() {
        if (this.taskData.params && this.taskData.params.xsdTabs && this.taskData.params.xsdTabs.length > 0) {
            this.taskData.processingXsdData = [];
        }
    }

    public apply() {
        if (this.xsdDataComponents && this.xsdDataComponents.length > 0) {
            this.xsdDataComponents.forEach(xsdDataComponent => {
                xsdDataComponent.save();
            });
        } else {
            this._saveUinData();
            this.onApply.emit(true);
        }
        this.appealService.editing = false;
    }

    public edit() {
        this.onEdit.emit(this.task);
        this.appealService.editing = true;
    }

    private _saveUinData() {
        if (this.taskData.xsdTabs && this.taskData.xsdTabs.length > 0) {
            let uins = [];
            this.taskData.params.xsdTabs.forEach(tab => {
                if (tab.isFillUin) {
                    const data = this.appealService.appeal.subservice.xsdData[tab.tabCode];
                    uins = uins.concat(this.getFieldInTreeStructure(data, 'uin'));
                }
            });
            if (uins.length > 0) {
                this.appealService.appeal.subservice.uins = uins;
            } else if (this.appealService.appeal.subservice.uins) {
                this.appealService.appeal.subservice.uins = null;
            }
        }
    }

    public getFieldInTreeStructure(structure, field) {
        let fields = [];
        if (typeof structure === 'object' && structure) {
            Object.keys(structure).forEach(key => {
                if (key === field && structure[key]) {
                    fields.push(structure[key]);
                } else if (typeof structure[key] === 'object' || Array.isArray(structure[key])) {
                    fields = fields.concat(this.getFieldInTreeStructure(structure[key], field));
                }
            });
        } else if (Array.isArray(structure)) {
            structure.forEach(item => {
                fields = fields.concat(this.getFieldInTreeStructure(item, field));
            });
        }

        return fields;
    }

    public checkAllowChangeAppealDatePlaneFinish(): boolean {
        return !!(
            this.task.due &&
            this.appeal.datePlaneFinish &&
            this.appeal.status.code === 'process' &&
            this.appeal.standardTasksPeriodInfo &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod &&
            this.appeal.standardTasksPeriodInfo.tasksPeriod.autoFill
        );
    }

    public async checkForTaskExpiring() {
        if (this.checkAllowChangeAppealDatePlaneFinish()) {
            const periodType = this.appeal.standardTasksPeriodInfo.periodType;

            // приводим даты к началу дня
            const planned = moment(this.task.due).startOf('day');
            const current = moment().startOf('day');

            if (periodType === 'workDays' && this._getCalendar('current')) {
                this.completedTaskDiffDays = this.getWorkDaysAmount(planned, current);
            } else {
                this.completedTaskDiffDays = moment(current).diff(planned, 'days');
            }

            if (this.completedTaskDiffDays !== 0) {
                this.modalRef = this.modalService.show(this.changeDateCompleteModal, {backdrop: 'static'});
            } else {
                await this.complete('common');
            }
        } else {
            await this.complete('common');
        }
    }

    private _getCalendar(type: 'prev' | 'current' | 'next') {
        const taskYear = moment(this.task.due).year();

        if (type === 'prev') {
            return this.calendars.find(cal => cal.year === taskYear - 1);
        }

        if (type === 'current') {
            return this.calendars.find(cal => cal.year === taskYear);
        }

        return this.calendars.find(cal => cal.year === taskYear + 1);
    }

    private _getCalendarDays() {
        const prevCalendar = this._getCalendar('prev');
        const currCalendar = this._getCalendar('current');
        const nextCalendar = this._getCalendar('next');
        let calendarDays = currCalendar.days;
        if (prevCalendar) { calendarDays = { ...calendarDays, ...prevCalendar.days }; }
        if (nextCalendar) { calendarDays = { ...calendarDays, ...nextCalendar.days }; }

        const daysNumArr = Object.keys(calendarDays)
            .map(date => Number(date));

        return daysNumArr;
    }

    // формирует массив ключей дат (в формате YYYYMMDD) заданной длины от указанной даты
    private _getDayKeysList(startDate: moment.MomentInput, diffDays: number) {
        return [...Array(Math.abs(diffDays))]
            .map((_, i) => {
                const el = diffDays + (diffDays < 0 ? i : -i);
                const date = moment(startDate).add(el, 'days');
                const year = date.year();
                const month = date.month() + 1;
                const day = date.date();

                return Number(`${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`);
            });
    }

    public getWorkDaysAmount(planned: moment.MomentInput, current: moment.MomentInput) {
        const diffDays = moment(current).diff(planned, 'days');
        const calendarDays = this._getCalendarDays();
        const workDaysList = this._getDayKeysList(planned, diffDays).filter(el => !calendarDays.includes(el));

        return diffDays < 0 ? -workDaysList.length : workDaysList.length;
    }

    private _calculateAppealPlaneFinishDate() {
        const periodType = this.appeal.standardTasksPeriodInfo.periodType;

        if (periodType === 'calendarDays') {
            this.newAppealDatePlaneFinish = moment(this.appeal.datePlaneFinish)
                .add(this.completedTaskDiffDays, 'days')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
        } else {
            const datePlaneFinish = this.appeal.datePlaneFinish;

            const daysList = this._getDayKeysList(datePlaneFinish, this.completedTaskDiffDays);

            const calendarDays = this._getCalendarDays();
            const daysIntersection = daysList.filter(el => calendarDays.includes(el));
            const daysToAdd = daysIntersection.length;

            const result = this.completedTaskDiffDays < 0
                ? this.completedTaskDiffDays - daysToAdd    // преждевременное завершение
                : this.completedTaskDiffDays + daysToAdd;   // просрочено

            this.newAppealDatePlaneFinish = moment(this.appeal.datePlaneFinish)
                .add(result, 'days')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
        }
    }

    public async complete(type: 'common' | 'expired' = 'common') {
        if (type === 'expired') {
            this._calculateAppealPlaneFinishDate();
        }

        if (this.modalRef) {
            this.modalRef.hide();
        }

        this.isProcessCompleteTask = true;
        this.isProcessValidate = true;

        this.additionalDataTabComponents.forEach(additionalDataComponent => {
            additionalDataComponent.startValidate();
        });

        this._saveUinData();
        // Сохранение доп. данных в задаче для исключения перезаписи при повторе задачи
        await this._saveTaskXsdData();
        // this.task.xsdData
        this._fillAppealXsdData();

        const isValid = this.validateAdditionalData();

        this.onComplete.emit({ isValidAdditionalData: isValid, task: this.task });

        if (isValid && this.newAppealDatePlaneFinish) {
            const currentUser = this.storage.getItem('user');
            const reason = this.completedTaskDiffDays < 0
                ? 'преждевременным выполнением'
                : 'просрочкой';
            const now = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');

            // TODO возможное пересохранение
            await this._updateAppealDatePlaneFinish({
                date: now,
                comment: `Срок был изменен в связи с ${reason} задачи`,
                user: {
                    login: currentUser.login,
                    id: currentUser._id,
                    name: currentUser.name,
                },
            });
            this.newAppealDatePlaneFinish = null;
        }
    }

    private _fillAppealXsdData() {
        this.saveService.appeal.subservice.xsdData = this.saveService.appeal.subservice.xsdData || {};
        this.saveService.appeal.subservice.xsdData = Object.assign(this.saveService.appeal.subservice.xsdData, this.task.xsdData);
    }

    /**
     * Переход в режим редактирования параметра задачи: сведения, документа и т.д.
     * @param property - параметр, который в данный момент редактируется
     */
    public startEditProperty(property) {
        this.editedProperty = property;
    }

    /**
     * Выход из режима редактирования параметра задачи
     */
    public endEditProperty() {
        this.editedProperty = null;
    }

    public cancel() {
        this.onCancel.emit(this.task);
    }

    public startProcessEvent(event) {
        this.onStartEvent.emit(event);
    }

    public checkLinkCheckListEntity() {
        return this.taskData && this.taskData.params.entities && this.taskData.params.entities.find(item => item.isAct)
               && this.appealService.appeal.checkLists && this.appealService.appeal.checkLists.length > 0;
    }

    public async sendToMobile() {
        await this.httpService.get(Config.server + Config.api + 'mp/generateDataForMp?mainId=' + this.appealService.appeal._id + '&tenantId=10');
        this.toaster.success('Данные успешно отправлены');
    }

    private async _getUsersForSelect(): Promise<void> {
        this.usersForSelect = [];
        let knoUsers;

        if (this.task.candidateGroups && this.task.candidateGroups.length > 0) {
            knoUsers = await this.usersService.getKnoUsers(false, false);
            knoUsers.forEach(user => {
                const find = user.linkRolesMnemonics.filter(item => this.task.candidateGroups.indexOf(item) !== -1);
                if (find.length > 0) {
                    this.usersForSelect.push(user);
                }
            });

            return;
        } else if (this.task.candidateUsers.length > 0) {
            knoUsers = await this.usersService.getKnoUsers(false, false);
            this.usersForSelect = knoUsers.filter(item => this.task.candidateUsers.indexOf(item.login) !== -1);

            return;
        }

        knoUsers = await this.usersService.getKnoUsers(false, false);
        this.usersForSelect = cloneDeep(knoUsers);
    }

    public showDatePlanFinishChangeHistory() {
        this.modalRef = this.modalService.show(this.datePlanFinishHistoryModal, {backdrop: 'static'});
    }

    public editPlanFinishDate() {
        this.newDatePlaneFinish = this.task['due'];
        this.commentTaskDatePlaneFinish = '';
        this.isEditPlanDate = true;
    }

    public getNewAppealDatePlaneFinish(difference?: number) {
        let diff;
        if (difference) {
            diff = difference;
        } else {
            const newDateString = this.task.due === this.newDatePlaneFinish
                ? this.newDatePlaneFinish
                : this.newDatePlaneFinish + getLastHourTimeAndOffset();
            const momentOldDate = moment(moment(this.task.due).format('YYYY-MM-DD') + getLastHourTimeAndOffset());
            const momentNewDate = moment(newDateString);
            diff = momentNewDate.diff(momentOldDate, 'days');
        }

        return moment(this.appeal.datePlaneFinish)
            .add(diff, 'days')
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    }

    public setNewAppealDatePlaneFinish() {
        if (!this.willChangeFullTerm) {
            this.newAppealDatePlaneFinish = null;
        } else {
            this.newAppealDatePlaneFinish = this.getNewAppealDatePlaneFinish();
        }
    }

    private async _updateAppealDatePlaneFinish(historyItem) {
        this.appeal.datePlaneFinish = this.newAppealDatePlaneFinish;

        if (!this.appeal.datePlaneFinishChangeHistory) {
            this.appeal.datePlaneFinishChangeHistory = [];
        }
        this.appeal.datePlaneFinishChangeHistory.push(historyItem);

        this.saveService.appeal = this.appeal;

        await this.saveService.saveAppeal();
    }

    public async updatePlaneFinishDate() {
        if (!this.commentTaskDatePlaneFinish) {
            this.toaster.error(this.localizations.tasks.not_filled_reason_change_plane_date);

            return;
        }

        this.task['due'] = this.newDatePlaneFinish  + getLastHourTimeAndOffset();

        if (!this.task.datePlaneFinishChangeHistory) {
            this.task.datePlaneFinishChangeHistory = [];
        }
        const currentUser = this.storage.getItem('user');
        const historyItem = {
            date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            comment: this.commentTaskDatePlaneFinish,
            user: {
                login: currentUser.login,
                id: currentUser._id,
                name: currentUser.name,
            },
        };

        this.task.datePlaneFinishChangeHistory.push(historyItem);

        // обновить задачу в camundaBusinessInfo
        await this._updateTask();

        if (this.newAppealDatePlaneFinish) {
            await this._updateAppealDatePlaneFinish({
                ...historyItem,
                comment: 'Срок был изменен в задаче: ' + historyItem.comment,
            });
        }
        this.cancelEditFinishDate();

        if (this.assigneeUser) {
            const message = `${this.localizations.tasks.changed_finish_date_to} `
                + moment(this.newDatePlaneFinish).format('DD.MM.YYYY');
            this._sendMessage(this.assigneeUser, message, false);
        }
    }

    public cancelEditFinishDate() {
        this.newDatePlaneFinish = null;
        this.commentTaskDatePlaneFinish = '';
        this.isEditPlanDate = false;
        this.willChangeFullTerm = false;
        this.newAppealDatePlaneFinish = null;
    }

    public changeTaskParam(param) {
        if (this.allowEdit) {
            this.modalTitle = param === 'assignee'
                ? this.localizations['appeals.tasks'].select_assignee
                : this.localizations['appeals.tasks'].start_date;
            this.processingParam = param;
            if (param !== 'assignee' && this.task[param]) {
                this.currentDate = this.task[param];
            }
            this.modalRef = this.modalService.show(this.setupTaskParamsModal, {backdrop: 'static'});
        }
    }

    public async applyTaskParam() {
        if (this.processingParam === 'assignee' && this.currentAssignee && this.currentAssignee !== this.task.assignee) {
            this.httpService.post(Config.server + Config.api + 'camunda/task/' + this.task.id + '/assignee?userId=' + this.currentAssignee, {}).then(async () => {
                this.task.assignee = this.currentAssignee;
                await this._getAssigneeUser();
                this.modalRef.hide();
                this._sendMessage(this.assigneeUser);
                await this.ngOnInit();
            });
        } else if (this.currentDate) {
            this.task[this.processingParam] = this.currentDate;
            await this._updateTask();

            this.modalRef.hide();
            this.currentDate = null;

            if (this.assigneeUser) {
                const param = this.processingParam === 'due' ? 'дата завершения' : 'дата начала исполнения';
                const message = 'изменена ' + param + ' на ' + moment(this.currentDate).format('DD.MM.YYYY');
                this._sendMessage(this.assigneeUser, message, false);
            }
        }
    }

    private async _getAssigneeUser(): Promise<void> {
        const knoUsers = await this.usersService.getKnoUsers(false, false);
        this.assigneeUser = knoUsers.find(item => item.login === this.task.assignee);
    }

    private _getEvents() {
        if (this.appealService.subservice.camundaProcess.messages && this.appealService.subservice.camundaProcess.messages.length > 0) {
            const taskEvents = this.appealService.subservice.camundaProcess.messages.filter(item => item.tasks.indexOf(this.task.taskDefinitionKey) !== -1);
            if (taskEvents.length > 0) {
                this.events = taskEvents;
            }
        }
    }

    private _sendMessage(user, message = '', isAssignee = true) {
        if (this.currentUser.login !== user.login) {
            const taskType = isAssignee ? 'newProcessTask' : 'changeProcessTask';
            const params = [
                {
                    name: 'userLogin',
                    value: user.login,
                },
                {
                    name: 'message',
                    value: message,
                },
                {
                    name: 'camundaTaskId',
                    value: this.task.camundaBusinessInfoId,
                },
                {
                    name: 'taskId',
                    value: this.task.id,
                },
                {
                    name: 'taskName',
                    value: this.task.name,
                },
                {
                    name: 'appealNumber',
                    value: this.appealService.appeal.number,
                },
                {
                    name: 'appealSubservice',
                    value: this.appealService.appeal.subservice.shortTitle,
                },
                {
                    name: 'appealId',
                    value: this.appeal._id,
                },
            ];
            this.userMessagesService.sendMessage(taskType, 'sendToUser', params);
        }
    }

    public validateAdditionalData() {
        let isValid = true;
        if (this.additionalDataTabComponents && this.additionalDataTabComponents.length > 0) {
            this.additionalDataTabComponents.forEach(additionalDataComponent => {
                isValid = !additionalDataComponent.checkValid() ? false : isValid;
            });
        }

        return isValid;
    }

    public getRoleName(roleCode) {
        const find = this.roles.find(item => item.code === roleCode);
        if (roleCode && find) {
            return find.name;
        }

        return roleCode;
    }

    public getXsdDataSavedPlace(): any {
        return this.task.status !== 'ACTIVE' && this.task.xsdData ? this.task.xsdData : this.appeal.subservice.xsdData;
    }

    public getCustomParams(): ICustomParams {
        return this.task.status !== 'ACTIVE' || !this.taskData.allowApply || this.taskData.isApproveSignOptionsDisabled ? {
            disabledOptions: {
                isApprove: 'yes',
                isSign: 'yes',
            },
        } : {};
    }

    private async _saveTaskXsdData(): Promise<any> {
        // TODO сохраняется здесь
        if (this.taskData.params && Array.isArray(this.taskData.params.xsdTabs) && this.appeal.subservice.xsdData) {
            this.task.xsdData = {};
            this.taskData.params.xsdTabs.forEach(tab => {
                const tabCode = tab.tabCode ? tab.tabCode : tab.code;

                if (this.appeal.subservice.xsdData[tabCode]) {
                    this.task.xsdData[tabCode] = this.appeal.subservice.xsdData[tabCode];
                    this.task.xsdData[tabCode].messageGuid = this.feedbackMessageGuid;
                    this.task.xsdData[tabCode].createdAt = new Date().getTime();
                }
            });

            if (this.appeal.subservice.xsdData.confirmDocumentation) {
                this.appeal.subservice.xsdData = cloneDeep(this.appeal.subservice.xsdData);
                this.appeal.subservice.xsdData.confirmDocumentation.otvetRSO = null;
            }

            return this._updateTask();
        }

        return null;
    }

    /**
     * Обновить задачу в camundaBusinessInfo
     */
    private async _updateTask(): Promise<any> {
        const camundaBusinessInfoData: any = await this.restService.find('camundaBusinessInfo', this.task.camundaBusinessInfoId);
        if (!camundaBusinessInfoData) {
            return null;
        }

        const usersTasks = camundaBusinessInfoData.userTasks;
        const findTaskIndex = usersTasks.findIndex(item => item.id === this.task.id);

        if (findTaskIndex !== -1) {
            const currentTask = cloneDeep(this.task);

            delete currentTask.camundaBusinessInfoId;
            usersTasks[findTaskIndex] = currentTask;

            const dataForSave = {
                _id: this.task.camundaBusinessInfoId,
                parentEntries: 'camundaBusinessInfo',
                guid: camundaBusinessInfoData.guid,
                userTasks: usersTasks,
            };

            return this.restService.update('camundaBusinessInfo', dataForSave);
        }

        return null;
    }

    private async _getAttachedTasks() {
        const taskDefinitionKey = this.task.taskDefinitionKey;

        if (!this.isAutofill) { return; }

        const standard: any = await this.restService.find('standards', this.subservice.standardId);
        if (!standard.tasksPeriod.autoFill) {
            return;
        }

        // цепочка вариантов
        const variantsGuid: string[] = this.appealSubservice.variant.selectedVariants.map(el => el.guid);

        // ищем процесс
        let processGuid: string;

        // если есть несколько подпроцессов
        if (standard.process.tasks.find(el => el.type === 'subProcess')) {

            // если текущая задача первая - определить процесс не удастся
            const firstTask = standard.process.tasks.find(el => el.type === 'userTasks');
            if (firstTask && firstTask.id === taskDefinitionKey) {
                return;
            }

            // ищем guid процесса исходя из текущей задачи
            standard.process.tasks
                .filter(proc => proc.type === 'subProcess')
                .forEach(el => {
                    const taskInProcess = el.subProcess.tasks.find(currentElement => currentElement.id === taskDefinitionKey);
                    if (taskInProcess) {
                        processGuid = el.guid;
                    }
                });
            if (!processGuid) {
                return;
            }
        } else {
            // если в стандарте один процесс, берем его guid из sperBpmnProcesses
            const sperBpmnProcess: any = await this.restService.find('sperBpmnProcesses', standard.process.id);
            processGuid = sperBpmnProcess.guid;
        }

        let currentProcessTasks = [];

        // ищем группы тасков в варианте/процессе и его надпроцессах
        if (standard.tasksPeriod.variants) {
            for (const variant of variantsGuid) {
                const currentVariant = standard.tasksPeriod.variants.find(el => el.guid === variant);
                if (currentVariant) {
                    const currentProcess = currentVariant.processes.find(el => el.guid === processGuid);
                    if (currentProcess) {
                        currentProcessTasks = currentProcess.tasks;
                        break;
                    }
                }
            }
        }

        // если не нашли с вариантами - ищем просто в процессах
        if (!currentProcessTasks.length && standard.tasksPeriod.processes) {
            const currentProcess = standard.tasksPeriod.processes.find(el => el.guid === processGuid);
            if (currentProcess) {
                currentProcessTasks = currentProcess.tasks;
            }
        }

        // если ничего не нашли, значит для данного процесса вообще нет группмровки по этапам
        if (!currentProcessTasks.length) {
            return;
        }

        // получаем уже завершенные задачи, чтобы не включать их в список
        const camundaBusinessInfo: any =
            await this.restService.find('camundaBusinessInfo', this.appealSubservice.camundaProcessInfo.camundaBusinessInfoId);
        const activeAndCompletedTasksId = camundaBusinessInfo.userTasks.map(el => el.taskDefinitionKey);

        // ищем задачи из текущей группы
        const currentTask = currentProcessTasks.find(el => el.taskId === taskDefinitionKey);

        if (!currentTask || typeof currentTask === 'undefined') {
            return;
        }

        const currentTaskGroup = currentTask.group;
        if (currentTaskGroup === null) {
            return;
        }

        // формируем список невыполненных задач входящих в данный этап
        const attachedTasks = currentProcessTasks
            .filter(el => el.group === currentTaskGroup)
            .filter(el => !activeAndCompletedTasksId.includes(el.taskId));

        this.attachedTasks = attachedTasks;
    }

    public getDaysLeftText() {
        const dayTitle = this.utility.getDayTitle(
            this.completedTaskDiffDays,
            {
                days_one: this.localizations.common.days_one,
                days_several: this.localizations.common.days_several,
                days_many: this.localizations.common.days_many,
            },
        );

        const daysAbs = Math.abs(this.completedTaskDiffDays);

        const mainTitle = this.completedTaskDiffDays > 0
            ? this.localizations.tasks.task_completed_later_by.replace('%s', daysAbs)
            : this.localizations.tasks.task_completed_earlier_by.replace('%s', daysAbs);

        let periodTypeTitle;

        if (this.appeal.standardTasksPeriodInfo.periodType === 'calendarDays') {
            periodTypeTitle = Math.abs(this.completedTaskDiffDays) === 1
                ? this.localizations.tasks.calendarAdjectiveOne
                : this.localizations.tasks.calendarAdjectiveMany;
        } else {
            periodTypeTitle = Math.abs(this.completedTaskDiffDays) === 1
                ? this.localizations.tasks.workAdjectiveOne
                : this.localizations.tasks.workAdjectiveMany;
        }

        return periodTypeTitle
            ? `${mainTitle} ${periodTypeTitle} ${dayTitle}`
            : `${mainTitle} ${dayTitle}`;
    }

    public toggleAdditionalInfoOpened() {
        this.tabs.xsdTabs = !this.tabs.xsdTabs;
    }
}
