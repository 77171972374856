import { Component, Input } from '@angular/core';
import { UtilityService } from '../../../../../common/services/utility.service';

@Component({
    selector: 'simple-tree-variant',
    templateUrl: './simple-tree-variant.component.html',
})
export class SimpleTreeVariantComponent {
    @Input() public variants;
    @Input() public mainIndex;
    @Input() public terms;

    public constructor(public utility: UtilityService) {}

    /**
     * Получение текстового описания срока оказания услуги для вариант
     * @param variant - обрабатываемый вариант
     */
    public getTermText(variant) {
        const variantTerm = this.terms.find(
            (item) => item.variantGuids && item.variantGuids.indexOf(variant.guid) !== -1
        );
        return variantTerm ? ' - ' + variantTerm.textValue : '';
    }
}
