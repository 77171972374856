import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'print-form-xsl-edit',
    templateUrl: './print-form-xsl-edit.component.html',
})
export class PrintFormXslEditComponent implements OnInit {
    @Input() public element;
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onCancel = new EventEmitter<any>();
    public content;
    public options = {
        lineNumbers: true,
        theme: 'default',
        mode: 'markdown',
    };
    public localizations;

    public constructor(private translate: TranslateService) {}

    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'print-forms']).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public apply() {
        this.onApply.emit(this.element);
    }

    public cancel() {
        this.onCancel.emit();
    }
}
