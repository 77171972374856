<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ localizations.standards.info.edit }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="save()"
                    *ngIf="allowEdit">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{ allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body">

        <ng-container *ngIf="tabs.length > 1">
            <ul class="info-row no-margin">
                <ng-container *ngFor="let tab of tabs">
                    <li (click)="activeTab = tab.code"
                        [ngClass]="{'active': activeTab == tab.code}">
                        {{tab.name}}
                    </li>
                </ng-container>
            </ul>
        </ng-container>

        <div class="mt-20"
             [hidden]="activeTab !== 'common'">
            <!-- Основные данные -->
            <dynamic-form
                #mainData
                *ngIf="metaData.entitiesData.xsd"
                [xsd]="metaData.entitiesData.xsd"
                [model]="entity"
                [saveInMainObject]="true"
                [disabled]="!allowEdit">
            </dynamic-form>

            <div class="checkbox-block no-padding-top mb-20">
                <input id="onlyOne"
                       class="checkbox"
                       type="checkbox"
                       [checked]="entity.onlyOne"
                       (change)="entity.onlyOne = !entity.onlyOne">
                <label for="onlyOne">
                    {{ localizations.standards.info.only_one_type }}
                </label>
            </div>

            <div class="mb-15"
                 *ngIf="metaData.entitiesData && metaData.entitiesData.useStaticEntities && metaData.entitiesData.useCustomEntities">
                <strong class="mr-10">
                    {{ localizations.standards.info.kind }}
                    <span class="text-danger">*</span>
                </strong>
                <div class="radio-inline no-padding-top">
                    <input id="static"
                           class="radio"
                           type="radio"
                           name="entityType"
                           [checked]="entity.kind && entity.kind === 'static'"
                           (change)="changeKind('static')">
                    <label for="static">
                        {{ localizations.standards.info.special }}
                    </label>
                </div>
                <div class="radio-inline no-padding-top">
                    <input id="custom"
                           class="radio"
                           type="radio"
                           name="entityType"
                           [checked]="entity.kind && entity.kind === 'custom'"
                           (change)="changeKind('custom')">
                    <label for="custom">
                        {{ localizations.standards.info.customizable }}
                    </label>
                </div>
            </div>

            <div class="form-group form-group-lg"
                 *ngIf="entity.kind === 'static'">
                <label>
                    {{ localizations.standards.info.type }}
                    <span class="text-danger">*</span>
                </label>
                <catalogue [large]="true"
                                    [(ngModel)]="entity.type"
                                    catalogName="entityTypes"
                                    [placeholder]="localizations.standards.info.select_type"
                                    [returnFields]="['code', 'name', 'useStaticComponent', 'xsd', 'xsdHandler']"
                ></catalogue>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && !entity.type">
                    {{ localizations.standards.info.type_is_required }}
                </div>
            </div>

            <div class="row"
                 *ngIf="entity.kind === 'custom'">
                <div class="col-md-3">
                    <div class="form-group form-group-lg">
                        <label>
                            {{ localizations.standards.info.code }}
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="entity.code"
                               required
                               #entityCode="ngModel">
                        <div class="validation-error-label"
                             *ngIf="isProcessValidate && entityCode.errors">
                            {{ localizations.standards.info.code_is_required }}
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group form-group-lg">
                        <label>&nbsp;</label>
                        <div class="mt-10">
                            <span class="text-bold">
                                {{ getInfoFormMessage() }}
                            </span>
                            <span class="text-size-small anchor text-info ml-10"
                                  *ngIf="commonStandardsService.allowEditStandard || entity.xsd"
                                  (click)="editXsd()">
                                {{!entity.xsd ? localizations.common.setup : commonStandardsService.allowEditStandard ?
                                    localizations.common.change_small : localizations.common.view_small }}
                            </span>
                            <span class="text-size-small anchor text-slate ml-10"
                                  *ngIf="entity.xsd && commonStandardsService.allowEditStandard"
                                  (click)="entity.xsd = null">
                                {{ localizations.common.delete_small }}
                            </span>
                            <evolenta-handlers
                                    [model]="entity"
                                    field="xsd"
                                    [setChangeEvent]="true"
                                    [setStartEvent]="true"
                            ></evolenta-handlers>

                            <div class="validation-error-label"
                                 *ngIf="isProcessValidate && !entity.xsd">
                                {{ localizations.standards.info.form_not_set_up }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group form-group-lg"
                 *ngIf="groups && metaData.entitiesData.isDocumentGroupsLinksSetting">
                <label>
                    {{ localizations.standards.linked_document_group }}
                </label>
                <ng-select [allowClear]="false"
                           [items]="groups"
                           [active]="getActiveGroup()"
                           (selected)="selectGroup($event)"
                           [placeholder]="localizations.standards.select_from_list">
                </ng-select>
            </div>
        </div>

        <ng-container *ngIf="activeTab === 'bpmnTasksLinks'">
            <common-bpmn-links [property]="entity"
                        propertyName="entities"
                        elementType="tasks"
                        [processDataInEntity]="activeData.entity.process"
                        [process]="commonStandardsService.bpmnProcess"
                        [operations]="['used', 'required']"
                        [allowEdit]="allowEdit"
            ></common-bpmn-links >
        </ng-container>

        <ng-container *ngIf="activeTab === 'bpmnEventsLinks'">
            <common-bpmn-links [property]="entity"
                        propertyName="entities"
                        elementType="catchEvents"
                        [processDataInEntity]="activeData.entity.process"
                        [process]="commonStandardsService.bpmnProcess"
                        [operations]="['used', 'required']"
                        [allowEdit]="allowEdit">
            </common-bpmn-links>
        </ng-container>
    </div>
</div>
