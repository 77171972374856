import { UniversalCollectionPageOptionsInterface } from '@evolenta/core';
import { DatePipe } from '@angular/common';
import { MetaReglamentPreviewComponent } from '@evolenta/meta-reglaments-module';
import get from 'lodash-es/get';

export const MetaReglamentsListConfig /*: UniversalCollectionPageOptionsInterface*/ = {
    elementsName: 'Мета-регламенты',
    isSelectingAllowed: false,
    isExternalSearchAllowed: false,
    isMarkingAllowed: false,
    isUseItemButtonAllowed: false,
    isCreateButtonAllowed: true,
    createElementText: 'Добавить',
    getCanCreateElementFunc: () => true,
    getCreateElementRouteFunc: (moduleUrl) => `/${moduleUrl}/meta-reglaments/create`,
    getEditElementRouteFunc: (moduleUrl, sectionUrl, element) => `/${moduleUrl}/meta-reglaments/edit/${element._id}`,
    getCollectionNameFunc: () => 'metaReglaments',
    defaultSort: 'dateLastModification,DESC',
    getFilterNameFunc: () => 'metaReglamentsFilter',
    getFilterItemsFunc: () => [],
    getBaseSearchFunc: () => [],
    getPreviewComponentFactoryFunc: (resolver) => resolver.resolveComponentFactory(MetaReglamentPreviewComponent),
    listItemOptions: {
        status: {
            isShownPrimary: true,
            getPrimaryPropertyFunc: (element, property) =>
                property === 'background'
                    ? `bg-${get(element, 'status.theme', '')}-300`
                    : get(element, 'status.name', ''),
            isShownSecondary: false,
        },
        title: {
            isShownId: false,
            isShownTitle: true,
            getTitleFunc: (element) => element.name,
        },
        details: [
            { title: 'Номер', getValueFunc: (element) => element.auid },
            { title: 'Код', getValueFunc: (element) => element.code },
            {
                title: 'Дата создания',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateCreation, 'shortDate'),
            },
            {
                title: 'Дата утверждения',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateApproved, 'shortDate'),
            },
        ],
    },
};
