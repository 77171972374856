<div class="panel panel-shadow panel-small">
    <div class="panel-heading">
        <h5 class="panel-title">
            <!-- Заголовок объекта -->
            {{printForm.title}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info"
                      *ngIf="printForm.formType">{{getFormType()}}</span>
                <a class="text-info anchor text-size-small"
                   [title]="localizations.common.download"
                   [ngClass]="{'ml-20': printForm.formType}"
                   *ngIf="printForm.templateFile"
                   (click)="downloadFile(printForm.templateFile._id, printForm.templateFile.originalName)">
                    {{ printForm.templateFile.originalName }}
                </a>
            </div>
        </h5>
        <div class="heading-elements">
            <button class="btn btn-default btn-sxs"
                    type="button"
                    [title]="localizations.common.edit"
                    *ngIf="allowEdit"
                    (click)="editPrintForm()">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </button>
            <button class="btn btn-default btn-sxs"
                    type="button"
                    [title]="localizations.common.delete"
                    *ngIf="allowDelete"
                    (click)="deletePrintForm()">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </button>
        </div>
    </div>
</div>



