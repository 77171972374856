import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { CommonAppealService } from '../../../../services/common-appeal.service';
import { CommonAppealSubservicesService } from '../../../../services/common-appeal-subservices.service';

@Component({
    selector: 'common-appeal-subservice-preview',
    templateUrl: './common-appeal-subservice-preview.component.html',
})
export class CommonAppealSubservicePreviewComponent implements OnInit {
    @Input() public appealSubservice; // Обрабатываемая услуга дела
    @Input() public activeTab; // Активная вкладка
    @Input() public mode = 'view'; // Режим отображения: view - предпросмотр карточки в списке; edit - редактированные данных услуги
    @Input() public subjects = []; // Массив субъектов дела

    @Output() public onActivateTab = new EventEmitter<string>();

    public subservice; // Описательная услуга
    public data; // описательный объект настройки объектов внутри текущей услуги
    public appeal; // Дело
    public isProcessEditIssuerResultForm = false; // режим выбора варианта выдачи результата оказания услуги заявителю
    public isExistAdditionalData = false;
    public tabWithAdditionalData;
    private currentOrganization: any; // cipr

    public constructor(
        public appealSubservicesService: CommonAppealSubservicesService,
        public validateService: CommonAppealValidateService,
        public appealService: CommonAppealService,
        protected storageService: StorageService, // cipr
    ) {
        this.currentOrganization = this.storageService.getItem('currentOrganization'); // cipr
    }

    public ngOnInit() {
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];
        this.subservice = this.appealSubservicesService.data[this.appealSubservice.guid].subservice;
        this.appeal = this.appealService.appeal;
        if (this.subservice.issueResultForm && !this.appealSubservice.issueResultForm && this.data.allowEdit && this.mode === 'edit') {
            this.isProcessEditIssuerResultForm = true;
        }

        if (this.subservice.xsdTabs && this.subservice.xsdTabs.length > 0) {
            const globalForm = this.subservice.xsdTabs.find(item => item.isGlobal && item.xsd);
            if (globalForm) {
                this.isExistAdditionalData = true;
                this.tabWithAdditionalData = globalForm.tabCode;
            }
        }
    }

    public activateTab(tab) {
        this.onActivateTab.emit(tab);
    }

    /**
     * КНМ наличия ошибок в услуге
     */
    public checkErrorsExist() {
        return false; // !this.validateService.checkElementValid(this.appealSubservice.guid, 'subservices');
    }

    public getErrorsList() {
        return ''; // this.validateService.getElementErrorsList(this.appealSubservice.guid, 'subservices');
    }

    /**
     * КНМ корректности заполнения определенного параметра услуги
     * @param tab - вкладка, на которой обрабатывается параметр услуги
     * @param property - проверяемое свойство
     * @returns {boolean}
     */
    public checkElementHasError(tab, property) {
        let hasError = false;
        if (this.validateService.processValidate
            && this.validateService.validateElements.subservice.tabs[tab].errors
            && this.validateService.validateElements.subservice.tabs[tab].errors[property]) {
            hasError = true;
        }

        return hasError;
    }

    /**
     * Возвращает число активных объектов в услуге
     * @returns {number}
     */
    public getSubjectsCount() {
        let countSubjects = 0;
        Object.keys(this.data.subjects).forEach(subjectGuid => {
            if (this.data.subjects[subjectGuid].active) {
                countSubjects++;
            }
        });

        return countSubjects;
    }

    /**
     * Возврат адреса в виде строки
     * @param addressItems
     * @returns {string}
     */
    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    /**
     * Хардкод для ципра
     */
    public get hideSubjectField() {
        return this.appeal.eventId && ['6013cf15cd52b10001e012b2', '6285fc143196760001e97c78'].includes(this.currentOrganization._id);
    }
}
