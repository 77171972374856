<ng-container *ngIf="element && type === 'preview'">
    <div class="panel panel-preview">
        <div class="panel-heading no-border-radius no-border-left">
            <h6 class="panel-title">
                {{ localizations['notifications-preview'].name }}
                <div *ngIf="element.type">
                    <label class="badge text-uppercase"
                           [ngClass]="userMessagesService.getTypeProperty(element.type, 'background')">
                        {{ userMessagesService.getTypeProperty(element.type, 'name') }}
                    </label>
                </div>
            </h6>
        </div>
        <div class="panel-preview-container">
            <evolenta-scrollbar>
                <div class="panel-body">
                    <!-- Общие данные -->
                    <fieldset>
                        <legend class="text-bold cursor-pointer mb-10 pb-20 hide"
                                (click)="panels.common = !panels.common">
                            {{ localizations.common.general_data }}
                            <a class="control-arrow">
                                <i [ngClass]="{
                                    'icon-arrow-down12': panels.common,
                                    'icon-arrow-left12': !panels.common
                                    }">
                                </i>
                            </a>
                        </legend>
                        <div class="collapse pb-10"
                             [ngClass]="{'in' : panels.common}">
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations['notifications-preview'].data }}
                                </div>
                                <div>
                                    {{ element.dateCreation | date: 'shortDate' }}
                                </div>
                            </div>
                            <div *ngIf="element.title"
                                 class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations['notifications-preview'].kind }}
                                </div>
                                <div>
                                    {{ element.title }}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10 mt-10"
                                 *ngIf="element.text">
                                <blockquote>
                                    {{ element.text }}
                                </blockquote>
                            </div>
                            <button class="btn btn-primary mt-20 pull-right"
                                    type="button"
                                    *ngIf="element.routeParams && element.routeParams.length > 0"
                                    (click)="goToRoute()">
                                {{ localizations.common.proceed }}
                            </button>
                            <button class="btn btn-primary mt-20 pull-right"
                                    type="button"
                                    *ngIf="checkCanRoute()"
                                    (click)="goToNotificationRoute()">
                                {{ localizations.common.proceed }}
                            </button>
                        </div>
                    </fieldset>
                </div>
            </evolenta-scrollbar>
        </div>
    </div>
</ng-container>
