import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { CommonStandardsService } from '../common-standards.service';
import { TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-variants',
    templateUrl: './common-standard-variants.component.html',
    styles: [
        ':host { width: 100%; }',
        '.tree-item-buttons { width: 150px; }',
        '.tree-item-title { width: calc(100% - 210px); }',
    ],
})
export class CommonStandardVariantsComponent implements OnInit {
    @Input() public xsdData;
    @Input() public allowEdit = true;

    public metaData;
    public activeData: any = {};

    public editedVariant;
    public editedVariantList;

    @Output() public onEditXsd = new EventEmitter<object>();
    public localizations;
    private draggableLevel;
    private draggableIndex;
    private draggableItem;
    private oldList;
    public toggled;

    public constructor(
        public standardsService: CommonStandardsService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.metaData = this.standardsService.metaReglament.blocks.find(item => item.code === 'variants');
        this.activeData = {
            code: 'variants',
            name: this.metaData.variantsData.name,
            allowEditInReglament: !this.metaData.variantsData.allowEditInReglament,
        };
        this.standardsService.initBlockData(this.activeData);
        if (this.xsdData) {
            this.editedVariant = this.xsdData.data;
            this.editedVariantList = this.xsdData.list;
        }
    }

    public setDraggableOldList(oldList) {
        this.oldList = oldList;
    }

    public setDraggableIndex(draggableIndex) {
        this.draggableIndex = draggableIndex;
    }

    public setDraggableItem(draggableItem) {
        this.draggableItem = draggableItem;
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
                'standards',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public childDragStarted() {
        this.toggled = false;
    }

    public onDragEnd() {
        this.toggled = false;
    }

    public add(variant = null) {
        if (variant && !variant.children) {
            variant.children = [];
        }
        if (!variant && !this.activeData.entity.variants) {
            this.activeData.entity.variants = [];
        }
        this.editedVariantList = variant ? variant.children : this.activeData.entity.variants;
        this.editedVariant = {
            guid: CommonUtilities.GenerateGuid(),
        };
    }

    public edit(variant, list) {
        this.editedVariantList = list;
        this.editedVariant = cloneDeep(variant);
    }

    public delete(variant, list) {
        const findIndex = list.findIndex(item => item.guid === variant.guid);
        list.splice(findIndex, 1);
        this.deleteVariantLinkedData(variant.guid);
        this.standardsService.saveEntity(this.activeData);
    }

    public apply() {
        const findIndex = this.editedVariantList.findIndex(item => item.guid === this.editedVariant.guid);
        if (findIndex === -1) {
            this.editedVariantList.push(this.editedVariant);
        } else {
            this.editedVariantList[findIndex] = this.editedVariant;
        }
        this.editedVariant = null;
        this.editedVariantList = null;
        this.standardsService.saveEntity(this.activeData);
    }

    public cancel() {
        this.editedVariant = null;
        this.editedVariantList = null;
    }

    public editXsd() {
        this.onEditXsd.emit({data: this.editedVariant, type: 'variant', list: this.editedVariantList, allowEdit: this.activeData.allowEdit});
    }

    public deleteVariantLinkedData(variantGuid) {
        const entity = this.activeData.entity;
        if (entity.documentGroups && entity.documentGroups.length > 0) {
            entity.documentGroups.variants.forEach(group => {
                if (group.variants && group.variants.length > 0) {
                    const deletedVariantIndex = group.variants.findIndex(item => item.guid === variantGuid);
                    if (deletedVariantIndex !== -1) {
                        group.variants.splice(deletedVariantIndex, 1);
                    }
                }
            });
        }
        if (entity.subjectGroups && entity.subjectGroups.length > 0) {
            entity.subjectGroups.forEach(group => {
                if (group.variants && group.variants.length > 0) {
                    const deletedVariantIndex = group.variants.indexOf(variantGuid);
                    if (deletedVariantIndex !== -1) {
                        group.variants.splice(deletedVariantIndex, 1);
                    }
                }
            });
        }
    }

    public editedVariantClicked(event) {
        this.editedVariant = event.variant;
        this.editedVariantList = event.list;
    }
}
