<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ localizations.standards['check_list'].editing }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="save()"
                    *ngIf="allowEdit">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{ allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body">
        <ng-container *ngIf="tabs.length > 1">
            <ul class="info-row no-margin">
                <ng-container *ngFor="let tab of tabs">
                    <li (click)="activeTab = tab.code"
                        [ngClass]="{'active': activeTab == tab.code}">
                        {{tab.name}}
                    </li>
                </ng-container>
            </ul>
        </ng-container>

        <div class="mt-20"
             [hidden]="activeTab !== 'common'">

            <!-- Основные данные -->
            <dynamic-form
                #mainData
                *ngIf="metaData.checkListsData.xsd"
                [xsd]="metaData.checkListsData.xsd"
                [model]="checkList"
                [saveInMainObject]="true"
                [disabled]="!allowEdit">
            </dynamic-form>

            <fieldset>
                <legend class="text-bold pl-20 no-margin">
                    {{ localizations.standards['check_list'].questions }}
                </legend>
                <entity-questions [entity]="checkList"
                                  [showNpa]="false"
                                  [setFillingType]="metaData.checkListsData.questionData && metaData.checkListsData.questionData.isSettingFillingType"
                                  [setHandlers]="metaData.checkListsData.questionData && metaData.checkListsData.questionData.isSettingHandlers"
                                  [xsd]="metaData.checkListsData.questionData && metaData.checkListsData.questionData.xsd"
                                  [addingManually]="metaData.checkListsData.questionData && metaData.checkListsData.questionData.allowAddingManually"
                                  [selectFromDictionary]="metaData.checkListsData.questionData && metaData.checkListsData.questionData.allowSelectFromDictionary">
                </entity-questions>
            </fieldset>
        </div>

        <ng-container *ngIf="activeTab === 'bpmnTasksLinks'">
            <common-bpmn-links [property]="checkList"
                               propertyName="checkLists"
                               elementType="tasks"
                               [processDataInEntity]="entity.process"
                               [process]="commonStandardsService.bpmnProcess"
                               [operations]="['used']"
                               [allowEdit]="allowEdit">
            </common-bpmn-links>
        </ng-container>

        <ng-container *ngIf="activeTab === 'bpmnEventsLinks'">
            <common-bpmn-links [property]="checkList"
                               propertyName="checkLists"
                               elementType="catchEvents"
                               [processDataInEntity]="entity.process"
                               [process]="commonStandardsService.bpmnProcess"
                               [operations]="['used']"
                               [allowEdit]="allowEdit">
            </common-bpmn-links>
        </ng-container>

    </div>
</div>
