<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button"
            class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            (click)="save()"
            [disabled]="isOldVersion">
        <b><i class="icon-checkmark-circle"
              [ngClass]="{'flash': isLoading}"></i></b>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            <span>
                <!-- Ссылка возврата к списку -->
                <i class="icon-arrow-left52 position-left cursor-pointer"
                   [title]="localizations.common.go_back"
                   (click)="back()">
                </i>
                {{
                    object?._id ?
                        localizations.objects.editing :
                        localizations.objects.creation
                }}
            </span>
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">

    <!-- Меню разделов -->
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content">
            <div class="sidebar-category"
                 *ngFor="let tab of tabs"
                 [ngClass]="{
                    'active': activeTab == tab.code,
                    'cursor-pointer' : activeTab != tab.code
                    }"
                 (click)="activeTab = tab.code">
                <div class="category-title">
                    <span>{{tab.name}}</span>
                </div>
            </div>

            <div class="status-action ml-15 mr-15 mt-20"
                 *ngIf="!object.isGlobal && object.type && !object.globalObjectId">
                <button class="btn no-white-space btn-info full-width"
                        *ngIf="!existKnoObject"
                        (click)="createObjectInCurrentKno()">
                    {{ localizations.objects.take_under_control }}
                </button>
                <button class="btn no-white-space btn-info full-width"
                        *ngIf="objectKnoId"
                        (click)="navigateToKnoObject()">
                    {{ localizations.objects.under_control }}
                </button>
            </div>
        </div>
    </div>

    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-margin no-padding"
                            *ngIf="activeTab !== 'versions'">
            <!-- Основные данные объекта -->
            <div class="panel panel-shadow m-20"
                 *ngIf="activeTab == 'common'">

                <!--  Превью объекта + доп.полей на вид объекта -->
                <div class="p-20"
                     *ngIf="isGlobalObjectMode || globalObject || isOldVersion">
                    <object-preview-card [object]="isGlobalObjectMode || isOldVersion ? object : globalObject">
                    </object-preview-card>
                </div>

                <!--  Редактирование основных данных объекта и основных доп.полей на вид объекта -->
                <div class="panel-body"
                     *ngIf="!isGlobalObjectMode && !object.globalObjectId && !isOldVersion">
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                <span class="text-muted mb-5">
                                    {{ localizations.objects.type }}
                                    <span class="text-danger">*</span>
                                </span>
                                <span *ngIf="object.type" class="ml-10">
                                    {{ object.type.name }}
                                </span>
                                <span class="anchor text-info ml-10 text-size-small"
                                      *ngIf="object.type && !isOldVersion"
                                      (click)="selectObjectType()">
                                    {{ localizations.common.change_small }}
                                </span>
                                <span class="anchor text-info ml-10 text-size-small"
                                      *ngIf="!object.type && !isOldVersion"
                                      (click)="selectObjectType()">
                                    {{ localizations.common.not_selected_m }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group form-group-lg">
                                <label class="control-label">
                                    {{ localizations.common.name }}
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="controls">
                                    <input class="form-control"
                                           type="text"
                                           name="orgName"
                                           [placeholder]="localizations.common.name"
                                           [(ngModel)]="object.name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-lg">
                                <label class="control-label">
                                    {{ localizations.common.short_name }}
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="controls">
                                    <input class="form-control"
                                           type="text"
                                           name="shortName"
                                           [placeholder]="localizations.common.name"
                                           [(ngModel)]="object.shortName"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations.objects.region }}
                                </label>
                                <catalogue catalogName="catalogueRegions"
                                                    name="region"
                                                    [large]="true"
                                                    [(ngModel)]="object.region"
                                                    [returnFields]="['code','name']"></catalogue>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations.objects.latitude }}
                                    <span class="anchor text-info text-size-small ml-5"
                                          (click)="showOnMap()">
                                        {{ localizations.events.on_map }}
                                    </span>
                                </label>
                                <input class="form-control"
                                       type="text"
                                       name="latitude"
                                       [placeholder]="localizations.objects.enter_latitude"
                                       [(ngModel)]="object.latitude"/>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations.objects.longitude }}
                                    <span class="anchor text-info text-size-small ml-5"
                                          (click)="showOnMap()">
                                        {{ localizations.events.on_map }}
                                    </span>
                                </label>
                                <input class="form-control"
                                       type="text"
                                       name="longitude"
                                       [placeholder]="localizations.objects.enter_longitude"
                                       [(ngModel)]="object.longitude"/>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations.objects.address }}
                                </label>
                                <evolenta-address-gar
                                        [large]="true"
                                        [(ngModel)]="object.address"
                                ></evolenta-address-gar>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group form-group-lg"
                                 [ngClass]="{'no-margin-bottom' : !objectXsd}">
                                <label>
                                    {{ localizations.events.cadastral_number }}
                                </label>
                                <input class="form-control"
                                       type="text"
                                       name="cadastralNumber"
                                       [placeholder]="localizations.objects.enter_cadastral_number"
                                       [(ngModel)]="object.cadastralNumber"/>
                            </div>
                        </div>
                    </div>

                    <!-- Дополнительные данные -->
                    <div *ngIf="objectXsd">
                        <dynamic-form tabsMode="panel"
                                               [xsd]="objectXsd"
                                               [edit]="false"
                                               [model]="object.additionalData"
                                               [saveInMainObject]="true"></dynamic-form>
                    </div>
                </div>
            </div>

            <!-- Привязанные объекты -->
            <subject-list *ngIf="activeTab == 'subjects'"
                          [object]="object"
                          [subjects]="linkedSubjects"
                          [baseObject]="baseObject"
                          [allowEdit]="!isOldVersion"
                          (onChange)="changeSubjectList($event)"></subject-list>

            <!-- Информация на виды КНД-->
            <ng-container *ngIf="activeTab === 'kndKinds' && object._id">
                <div class="alert alert-warning no-border no-border-radius"
                     *ngIf="!objectType">
                    {{ localizations.objects.attention_not_selected }}
                </div>
                <ng-container *ngIf="objectType">
                    <entity-knd-kinds-data entityName="object"
                                           [entity]="object"
                                           [kndKinds]="kndKinds"
                                           [entityType]="objectType"
                                           (onReceiveKndKinds)="kndKinds = $event"
                                           #kndKindsData></entity-knd-kinds-data>
                </ng-container>
            </ng-container>
        </evolenta-scrollbar>

        <!-- Версии объекта -->
        <ng-container *ngIf="object._id && activeTab === 'versions'">
            <object-versions [object]='object'
                             #objectVersions></object-versions>
        </ng-container>
    </div>
</div>

<ng-template #map></ng-template>
