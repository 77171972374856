import { NgModule } from '@angular/core';
import { PrintFormEditComponent } from './print-form/print-form-edit/print-form-edit.component';
import { PrintFormXsdComponent } from './print-form/print-form-xsd/print-form-xsd.component';
import { PrintFormXslComponent } from './print-form/print-form-xsl/print-form-xsl.component';
import { PrintFormXslEditComponent } from './print-form/print-form-xsl/print-form-xsl-edit/print-form-xsl-edit.component';
import { PrintFormPreviewComponent } from './print-form/print-form-preview/print-form-preview.component';
import { PrintFormsResolve } from './print-form/print-forms.resolve';
import { PrintFormService } from './print-form/print-form.service';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { PrintFormsService } from '../../common/services/print/print-forms.service';
import { PrintAppealService } from '../../common/services/print/print-appeal.service';
import { PrintObjectsService } from '../../common/services/print/print-objects.service';
import { PrintUtilityService } from '../../common/services/print/print-utility.service';
import { PrintReglamentService } from '../../common/services/print/print-reglament.service';
import { PrintDocumentsService } from '../../common/services/print/print-documents.service';
import { PrintPackReestrService } from '../../common/services/print/print-pack-reestr.service';
import { PrintFormCardComponent } from './print-form/print-form-card/print-form-card.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { DeleteConfirmationModalModule } from '../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        InlineSVGModule,
        DeleteConfirmationModalModule,
        BreadcrumbsModule,
    ],
    declarations: [
        PrintFormEditComponent,
        PrintFormXsdComponent,
        PrintFormXslComponent,
        PrintFormXslEditComponent,
        PrintFormPreviewComponent,
        PrintFormCardComponent,
    ],
    entryComponents: [
        PrintFormPreviewComponent,
    ],
    providers: [
        PrintFormsResolve,
        PrintFormService,
        PrintFormsService,
        PrintAppealService,
        PrintObjectsService,
        PrintUtilityService,
        PrintReglamentService,
        PrintDocumentsService,
        PrintPackReestrService,
    ],
    exports: [
        PrintFormEditComponent,
        PrintFormCardComponent,
    ],
})

export class PrintFormsModule {
}
