import {
    Component,
    OnInit, ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EpguIntegrationService } from '../../services/epgu-integration.service';
import { MODES } from '../../../../common/constants';
import { ComplexEntitiesExportService, ToasterService } from '@evolenta/core';
import { DeleteConfirmationModalComponent } from '../../../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.component';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'fields-complience',
    templateUrl: './fields-complience.component.html',
    styleUrls: ['./fields-complience.component.less'],
})
export class FieldsComplienceComponent implements OnInit {

    public constructor(
        private translate: TranslateService,
        private epguIntegrationService: EpguIntegrationService,
        private complexEntitiesExportService: ComplexEntitiesExportService,
        private router: Router,
        private toaster: ToasterService,
    ) {
    }

    public mode;
    public standard;
    public mapping: any;
    public fileName;
    public localizations;
    public processing;
    public inCreate = false;
    public mappingsForStandard;
    public initialized;
    public itemChanged;
    public deleteConfirmationHeader;
    public deleteConfirmationText;
    public mappingToDelete;
    private imported;
    public mappingListOpened = true;
    private MAX_FILE_SIZE = 10 * 1024 * 1024;

    @ViewChild('deleteConfirmationModal', { static: false }) public deleteConfirmationModalComponent: DeleteConfirmationModalComponent;
    public handleXsdFile: ($event) => void = () => {};

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'epgu_integration',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
            this.initialized = true;
        });
    }

    public toggleMappingListOpened() {
        this.mappingListOpened = !this.mappingListOpened;
    }

    public async handleStandardSelected(standard) {
        this.inCreate = false;
        this.itemChanged = false;
        this.imported = false;
        this.mappingsForStandard = await this.epguIntegrationService.getMappingForStandard(standard);
        delete this.mapping;
        if (this.mappingsForStandard && this.mappingsForStandard.length === 1) {
            this.selectMapping(this.mappingsForStandard[0]);
        }
    }

    public standardCleared() {
        delete this.mapping;
        this.imported = false;
    }

    public selectMapping(mapping) {
        this.imported = false;
        this.mapping = mapping;
    }

    public toggleMapping() {
        this.imported = false;
        this.mapping.isValid = !this.mapping.isValid;

        return this.epguIntegrationService.saveMapping(this.mapping);
    }

    public getCurrentToggleName() {
        if (!this.mapping) {
            return this.localizations.epgu_integration.mapping.activate;
        }

        if (this.mapping.isValid) {
            return this.localizations.epgu_integration.mapping.deactivate;
        }

        return this.localizations.epgu_integration.mapping.activate;
    }

    public async saveMapping() {
        try {
            if (this.imported || this.mapping._id) {
                await this.epguIntegrationService.saveMapping(this.mapping);
            } else {
                this.epguIntegrationService.standardNumber = this.standard.auid;
                this.epguIntegrationService.fields = this.mapping.items;
                this.epguIntegrationService.versionXsd = this.mapping.versionXsd;
                this.mapping = await this.epguIntegrationService.createMapping();
                this.mapping._id = this.mapping.id;
            }
            this.handleStandardSelected(this.standard);

            this.itemChanged = false;
            this.inCreate = false;
            this.toaster.success(this.localizations.epgu_integration.mapping.item.successfully_saved);
        } catch (error) {
            this.toaster.error(this.localizations.epgu_integration.mapping.item.saving_failed);
            console.error(error);
        }
    }

    public triggerFileLoadToGenerateXSL()  {
        this.handleXsdFile = this._generateXslFile;
        $('#load-xsd').trigger('click');
    }

    public triggerFileLoadToGeneratePDF() {
        this.handleXsdFile = this._generatePdfFile;
        $('#load-xsd').trigger('click');
    }

    public triggerFileLoadToCreateMapping() {
        this.handleXsdFile = this.createMapping;
        $('#load-xsd').trigger('click');
    }

    public showDeleteConfirmationModal(mapping) {
        this.mappingToDelete = mapping;
        this.deleteConfirmationHeader = this.localizations.common.confirm_delete;
        this.deleteConfirmationText = this.localizations.epgu_integration.are_you_sure_you_want_to_delete_mapping;
        this.deleteConfirmationModalComponent.showModal();
    }

    public async delete() {
        await this.epguIntegrationService.deleteMapping(this.mappingToDelete);
        delete this.mapping;
        this.handleStandardSelected(this.standard);
    }

    public async createMapping(event) {
        this.imported = false;
        const {standardNumber, file} = this._getStandardNumberAndFile(event);

        try {
            const mappingsData: any = await this.epguIntegrationService.uploadXSDFileForCompareFields(standardNumber, file);
            if (mappingsData.fields) {
                this.mapping = {
                    items: mappingsData.fields,
                    versionXsd: mappingsData.versionxsd,
                    isValid: true,
                };
                this.epguIntegrationService.fields = this.mapping.items;
            }

            this.inCreate = true;
            this.mode = MODES.EDIT;
        } catch (error) {
            if (error && error.error && error.error.error) {
                this.toaster.error(error.error.error);
            } else {
                this.toaster.error(this.localizations.epgu_integration.mapping.item.creation_failed);
            }
            console.error(error);
        }
    }

    public someItemChanged() {
        this.itemChanged = true;
        this.imported = false;
    }

    /**
     * Сохранить загруженные данные маппинга в файл
     */
    public exportMappings() {
        const mappingToSave = cloneDeep(this.mapping);
        delete mappingToSave._id;
        this.complexEntitiesExportService.saveToJsonFile(mappingToSave, this.standard.auid, 'mapping');
    }

    /**
     * Открыть диалог загрузки файла маппинга
     */
    public importMappings() {
        $('#mappings-import-file').trigger('click');
    }

    /**
     * Применить маппинг из загруженного файла
     */
    public processImportedFile(event) {
        const filesList = event.target.files;
        if (!filesList.length) {
            return;
        }

        const file = filesList[0];
        if (file.size > this.MAX_FILE_SIZE) {
            this.toaster.error('Размер прикрепляемого файла должен быть меньше ' + (this.MAX_FILE_SIZE / 1024 / 1024).toFixed(2) + ' Мб');

            return;
        }

        const nameArr = file.name.split('.');
        if (!nameArr.length || nameArr.length < 2 || nameArr[nameArr.length - 1] !== 'json') {
            this.toaster.error('Маппинг можно импортировать только из json-файла');

            return;
        }

        const reader = new FileReader();
        reader.onload = (ev: any) => {
            const fileContent = ev.target.result;

            try {
                this.mapping = JSON.parse(fileContent);
                this.imported = true;
            } catch (err) {
                this.toaster.error('Загруженный JSON файл невалиден');
            }
        };

        reader.readAsText(file);
    }

    private async _generateXslFile(event) {
        this.processing = true;
        const {standardNumber, file} = this._getStandardNumberAndFile(event);

        try {
            const fileContent: any = await this.epguIntegrationService.generateXsl(standardNumber, file);
            const notice = fileContent.info;
            const decodedFile = decodeURI(fileContent.file);
            const element: any = document.getElementById('xsl-file');
            const blob = new Blob([decodedFile], {
                type: 'text/xml',
            });
            const url = URL.createObjectURL(blob);
            element.href = url;

            let xsdName = '';
            let xsdVersion = '';
            if (this.mapping.versionXsd) {
                const arr = this.mapping.versionXsd.split('/');
                xsdName = arr[3];
                xsdVersion = arr[4].replace('.', '');
            }

            let name = standardNumber;

            if (xsdName.length) {
                name += '-' + xsdName;
            }

            if (xsdVersion.length) {
                name += '-' + xsdVersion;
            }

            element.setAttribute('download', name + '-xsl.xsl');
            // @ts-ignore
            document.body.appendChild(element);
            element.click();
            this.inCreate = false;
            if (notice) {
                this.toaster.info(notice);
            }
        } catch (error) {
            if (error && error.error && error.error.error) {
                this.toaster.error(error.error.error);
            } else {
                this.toaster.error(this.localizations.epgu_integration.xsl_generation_error);
            }
            console.error(error);
        } finally {
            this.processing = false;
        }
    }

    private async _generatePdfFile(event) {
        this.processing = true;
        const {standardNumber, file} = this._getStandardNumberAndFile(event);
        try {
            const fileContent: any = await this.epguIntegrationService.generatePdf(file);
            const notice = fileContent.info;
            const decodedFile = decodeURI(fileContent.file);
            const element: any = document.getElementById('xsl-file');
            const blob = new Blob([decodedFile], {
                type: 'text/xml',
            });
            const url = URL.createObjectURL(blob);
            element.href = url;
            let xsdName = '';
            let xsdVersion = '';
            if (this.mapping.versionXsd) {
                const arr = this.mapping.versionXsd.split('/');
                xsdName = arr[3];
                xsdVersion = arr[4].replace('.', '');
            }

            let name = standardNumber;

            if (xsdName.length) {
                name += '-' + xsdName;
            }

            if (xsdVersion.length) {
               name += '-' + xsdVersion;
            }

            element.setAttribute('download', name + '-pdf.xsl');
            // @ts-ignore
            document.body.appendChild(element);
            element.click();
            this.inCreate = false;
            if (notice) {
                this.toaster.info(notice);
            }
        } catch (error) {
            if (error && error.error && error.error.error) {
                this.toaster.error(error.error.error);
            } else {
                this.toaster.error(this.localizations.epgu_integration.pdf_generation_error);
            }
            console.error(error);
        } finally {
            this.processing = false;
        }
    }

    private _getStandardNumberAndFile(event) {
        const filesList = event.target.files;

        if (!filesList.length) {
            return;
        }

        const file = filesList[0];
        this.fileName = file.name;

        const standardNumber = this.standard.auid.toString();

        this.epguIntegrationService.standardNumber = standardNumber;

        return {standardNumber, file};
    }

}
