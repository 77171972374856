import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService, TranslateService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';

@Component({
    selector: 'user-card',
    templateUrl: './user-card.component.html',
})
export class UserCardComponent implements OnInit, OnChanges {
    @Input() public user;
    @Input() public userId;
    @Input() public dateFrom;
    @Input() public dateTo;
    @Input() public onlyActive = false;

    public dateStart;
    public dateEnd;

    public tasksTabs = [];
    public activeTasksTab = 'active';
    public tasks = {
        active: [],
        completed: [],
    };

    public appealsTabs = [];
    public activeAppealsTab = 'active';
    public appeals = {
        active: [],
        created: [],
        completed: [],
    };

    public entitiesTabs = [];
    public activeEntitiesTab = 'subjects';
    public entities = {
        subjects: [],
        objects: [],
    };

    public moduleBaseUrl;
    public localizations;

    public constructor(
        private route: ActivatedRoute,
        private restService: RestService,
        private router: Router,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.dateStart = this.dateFrom;
        this.dateEnd = this.dateTo;
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        if (!this.user && this.userId) {
            this.restService.find('users', this.userId).then(user => {
                this.user = user;
            });
        }
        this.getLinkEntities();
        this.getDateDependendData();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'users',
                'common',
                'appeals',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.tasksTabs = [
            {
                code: 'active',
                name: this.localizations.appeals.edit.active_tasks,
            },
            {
                code: 'completed',
                name: this.localizations.appeals.edit.completed_tasks,
            },
        ];

        this.appealsTabs = [
            {
                code: 'active',
                name: this.localizations.users.knm.in_process,
            },
            {
                code: 'created',
                name: this.localizations.users.knm.new,
            },
            {
                code: 'completed',
                name: this.localizations.users.knm.completed,
            },
        ];

        this.entitiesTabs = [
            {
                code: 'subjects',
                name: this.localizations.users.controlled_subjects,
            },
            {
                code: 'objects',
                name: this.localizations.users.controlled_objects,
            },
        ];
    }

    public ngOnChanges(changes) {
        if (changes && (changes.dateFrom || changes.dateEnd)) {
            if (changes.dateFrom && changes.dateFrom.currentValue !== this.dateStart) {
                this.dateStart = changes.dateFrom.currentValue;
            }
            if (changes.dateTo && changes.dateTo.currentValue !== this.dateEnd) {
                this.dateEnd = changes.dateTo.currentValue;
            }
            this.getDateDependendData();
        }
    }

    public getDateDependendData() {
        this.getTasks();
        this.getAppeals();
    }

    public getTasks() {
        const params = [
            {
                field: 'userTasks.assignee',
                operator: 'eq',
                value: this.user.login,
            },
            {
                field: 'userTasks.created',
                operator: 'ge',
                value: this.dateStart,
            },
            {
                field: 'userTasks.created',
                operator: 'le',
                value: this.dateEnd,
            },
        ];
        if (this.onlyActive) {
            params.push({
                field: 'userTasks.status',
                operator: 'eq',
                value: 'ACTIVE',
            });
        }
        this.restService.search('camundaBusinessInfo', {search: {search: params}, size: 1000}).then(camundaInfos => {
            let tasks = [];
            camundaInfos.forEach(camundaInfo => {
                const userTasks = camundaInfo.userTasks.filter(item => item.assignee && item.assignee === this.user.login).map(item => ({...item, camundaTaskId: camundaInfo._id}));
                tasks = tasks.concat(userTasks);
            });
            this.tasks.active = tasks.filter(item => item.status === 'ACTIVE');
            this.tasks.completed = tasks.filter(item => item.status === 'COMPLETED');
        });
    }

    public goToTask(task) {
        this.router.navigate([this.moduleBaseUrl, 'all-tasks', 'edit', task.camundaTaskId, task.id]);
    }

    public async getLinkEntities() {
        const params = [
            {
                orSubConditions: [
                    {
                        field: 'checkingPersons.mainPerson.id',
                        operator: 'eq',
                        value: this.userId,
                    },
                    {
                        field: 'checkingPersons.replacingPerson.id',
                        operator: 'eq',
                        value: this.userId,
                    },
                ],
            },
        ];
        const data: any = this.restService.search('entitiesKndKnoData', {search: {search: params}, size: 100});
        const subjectIds = data
            .filter(item => item.entityName === 'person')
            .map(item => item.entityId);

        const objectIds = data
            .filter(item => item.entityName === 'object')
            .map(item => item.entityId);

        const promises = [];
        const entities = [];
        if (subjectIds.length > 0) {
            entities.push('subjects');
            promises.push(this.restService.search('persons', {search: {search: [{field: '_id', operator: 'in', value: subjectIds}]}, size: 100, prj: 'subjectsList'}));
        }
        if (objectIds.length > 0) {
            entities.push('objects');
            promises.push(this.restService.search('objects', {search: {search: [{field: '_id', operator: 'in', value: objectIds}]}, size: 100, prj: 'objectsList'}));
        }
        if (promises.length > 0) {
            const result = await Promise.all(promises);
            entities.forEach((entity, idx) => {
                this.entities[entity] = result[idx];
            });
        }
    }

    public getControlledInstancesMessage() {
        const template = this.localizations.users.controlled_missing;

        if (this.activeEntitiesTab === 'subjects') {
            return template.replace('%s', this.localizations.organizations.subjects);
        }

        return template.replace('%s', this.localizations.organizations.objects);
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public goToEntity(entity, entityType) {
        this.router.navigate(['registers', entityType, 'all', 'edit', entity._id]);
    }

    public getAppeals() {
        const params = [
            {
                field: 'inspectors.id',
                operator: 'eq',
                value: this.userId,
            },
            {
                field: 'dateCreation',
                operator: 'gt',
                value: this.dateStart,
            },
            {
                field: 'dateCreation',
                operator: 'le',
                value: this.dateEnd,
            },
        ];
        this.restService.search('appeals', {search: {search: params}, size: 100, prj: 'appealInfo'}).then(appeals => {
            this.appeals.active = appeals
                .filter(item => item.status.code === 'process');

            this.appeals.completed = appeals
                .filter(item => item.status.code === 'complete');

            this.appeals.created = appeals
                .filter(item => item.status.code === 'draft');
        });
    }

    public goToAppeal(appeal) {
        this.router.navigate([this.moduleBaseUrl, 'appeals', 'edit', appeal._id]);
    }

}
