import { NgModule } from '@angular/core';
import { AdminRouting } from './admin.routing';
import { UsersModule } from '../../modules/users/users.module';
import { RolesModule } from '../../modules/roles/roles.module';
import { OrganizationsModule } from '../../modules/organizations/organizations.module';
import { SettingsModule } from '../../modules/settings/settings.module';
import { RolesMnemonicsModule } from '../../modules/rolesMnemonics/rolesMnemonics.module';
import { CalendarModule } from '../../modules/calendar/calendar.module';
import { SystemTasksModule } from '@evolenta/system-tasks-module';

@NgModule({
    imports: [
        AdminRouting,
        UsersModule,
        RolesModule,
        RolesMnemonicsModule,
        OrganizationsModule,
        SettingsModule,
        CalendarModule,
        SystemTasksModule,
    ],
    declarations: [],
    providers: [],
    entryComponents: [],
})
export class AdminModule {}
