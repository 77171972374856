<div class="p-20" *ngIf="!loading">
    <ng-container *ngIf="subservice.version === 'knd'">
        <div class="form-group" *ngIf="subservice.kndInfo.kndKinds && subservice.kndInfo.kndKinds.length > 1">
            <p class="text-bold mb-15">Виды КНД</p>
            <div class="checkbox-block" *ngFor="let kndKind of subservice.kndInfo.kndKinds">
                <input type="checkbox" class="checkbox" id="kndKind{{kndKind.code}}" [checked]="checkUsedKndKind(kndKind)" (change)="toggleKndKinds(kndKind)">
                <label for="kndKind{{kndKind.code}}">{{kndKind.name}}</label>
            </div>
        </div>

        <!-- Контрольно-надзорные органы, совместно с которыми производится проверка -->
        <div class="form-group">
            <p class="text-bold mb-15">Контрольно-надзорные органы, совместно с которыми производится проверка(ЕРП)</p>
            <table class="table table-hover table-xs">
                <tbody>
                <tr *ngFor="let kno of appealService.appeal.jointlyKno; let idx = index">
                    <td>{{ kno.name }}</td>
                    <td class="text-right">
                        <button class="btn btn-default btn-sxs" (click)="deleteJointlyKno(idx)"><i class="icon-trash"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
            <button class="btn btn-default mt-10"
                    (click)="isProcessSelectJointlyKno = true"
                    *ngIf="!isProcessSelectJointlyKno">Добавить контрольно-надзорный орган</button>

            <div class="bg-primary-50 p-20" *ngIf="isProcessSelectJointlyKno && organizations && organizations.length > 0">
                <div class="form-group form-group-lg">
                    <catalogue catalogName="organizations"
                               [large]="true"
                               placeholder="Выберите контрольно-надзорный орган..."
                               [(ngModel)]="selectedJointlyKno"
                               [baseSearch]="baseSearchForOrganizations"
                               [returnFields]="['_id', 'id', 'name', 'code']"></catalogue>
                </div>

                <button class="btn btn-primary" (click)="applyJointlyKno()">Применить</button>
                <button class="btn btn-default ml-10" (click)="isProcessSelectJointlyKno = false;">Отмена</button>
            </div>
        </div>

        <div class="form-group">
            <p class="text-bold mb-15">Ответственные инспекторы(ЕРП)</p>
            <table class="table table-hover table-xs">
                <tbody>
                <tr *ngFor="let inspector of appealService.appeal.inspectors; let idx = index">
                    <td>{{ inspector.name }}</td>
                    <td>{{ inspector.position }}</td>
                    <td>{{ inspector.type ? inspector.type.name : '' }}</td>
                    <td class="text-right">
                        <button class="btn btn-default btn-sxs" (click)="deleteInspector(idx)"><i class="icon-trash"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
            <button class="btn btn-default mt-10"
                    (click)="addInspector()"
                    *ngIf="!isProcessSelectInspector">Выбрать инспектора</button>

            <div class="bg-primary-50 p-20" *ngIf="isProcessSelectInspector">
                <div class="form-group form-group-lg">
                    <catalogue catalogName="users"
                               [large]="true"
                               projection="usersList"
                               placeholder="Выберите инспектора..."
                               [(ngModel)]="selectedInspector"
                               [baseSearch]="baseSearchForInspectors"
                               [returnFields]="['_id', 'name', 'login', 'position']"></catalogue>
                </div>
                <div class="form-group form-group-lg">
                    <catalogue catalogName="nsiErpFaceKnmType"
                               [large]="true"
                               placeholder="Выберите тип лица, уполномоченного на проведение КНМ ..."
                               [(ngModel)]="selectedInspectorType"></catalogue>
                </div>

                <button class="btn btn-primary" (click)="applyInspector()">Применить</button>
                <button class="btn btn-default ml-10" (click)="isProcessSelectInspector = false;">Отмена</button>
            </div>
        </div>

        <!-- Привлекаемые внешние эксперты ПО ПЛАНУ-->
        <div *ngIf="externalExperts.length > 0" class="form-group">
            <p class="text-bold mb-15">Плановые эксперты</p>
            <table class="table table-hover table-xs">
                <tbody>
                <tr *ngFor="let expert of externalExperts; let idx = index">
                    <td>{{ expert.name }}</td>
                    <td>{{ expert.position }}</td>
                    <td>{{ expert.type.name }}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <!-- Привлекаемые эксперты -->
        <div class="form-group">
            <p class="text-bold mb-15">Привлеченные эксперты(ЕРП)</p>
            <table class="table table-hover table-xs">
                <tbody>
                <tr *ngFor="let expert of appealService.appeal.experts; let idx = index">
                    <td>{{ expert.name }}</td>
                    <td>{{ expert.position }}</td>
                    <td>{{ expert.type.name }}</td>
                    <td class="text-right">
                        <button class="btn btn-default btn-sxs" (click)="editExpert(expert)"><i class="icon-pencil3"></i></button>
                        <button class="btn btn-default btn-sxs" (click)="deleteExpert(idx)"><i class="icon-trash"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
            <button class="btn btn-default mt-10"
                    (click)="editExpert()"
                    *ngIf="!isProcessSelectExpert">Добавить эксперта</button>

            <div class="bg-primary-50 p-20" *ngIf="isProcessSelectExpert">
                <p class="text-bold mb-15">Добавление эксперта</p>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>ФИО эксперта</label>
                            <input type="text" [(ngModel)]="editedExpert.name" class="form-control" placeholder="Фамилия Имя Отчество">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Должность эксперта</label>
                            <input type="text" [(ngModel)]="editedExpert.position" class="form-control" placeholder="Должность">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Наименование экспертной организации</label>
                        <div class="form-group form-group-lg">
                            <input type="text" [(ngModel)]="editedExpert.organization" class="form-control" placeholder="Наименование экспертной организации">
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-lg">
                    <label>Свидетельство об аккредитации</label>
                    <textarea [(ngModel)]="editedExpert.accreditationOrganization" class="form-control" placeholder="Свидетельство об аккредитации"></textarea>
                </div>
                <div class="form-group form-group-lg">
                    <catalogue catalogName="nsiErpFaceKnmType"
                               [large]="true"
                               placeholder="Выберите тип лица, уполномоченного на проведение КНМ ..."
                               [(ngModel)]="editedExpert.type"></catalogue>
                </div>

                <button class="btn btn-primary" (click)="applyExpert()">Применить</button>
                <button class="btn btn-default ml-10" (click)="cancelExpert()">Отмена</button>

            </div>
        </div>
    </ng-container>


    <!-- Основной элемент  -->
    <fieldset *ngIf="subservice.mainElement">
        <legend class="text-bold">{{subservice.mainElement.name}}</legend>
        <p class="mb-20" *ngIf="appealSubservice.mainElement.operationType !== 'registration'">
            <span class="text-bold mr-5">Предыдущая лицензия:</span>
            <em *ngIf="!appealSubservice.mainElement.registerId">не выбрана</em>
            <ng-container *ngIf="appealSubservice.mainElement.registerInfo">№{{appealSubservice.mainElement.registerInfo.auid}} от {{appealSubservice.mainElement.registerInfo.dateCreation | date: 'shortDate'}}</ng-container>
            <a class="text-size-small anchor text-info ml-10" (click)="selectRegistry()">{{appealSubservice.mainElement.registerId ? 'изменить' : 'выбрать'}}</a>
            <a class="text-size-small anchor text-info ml-10" *ngIf="appealSubservice.mainElement.registerId"
               [routerLink]="['/', 'licensing-activity', appealService.registersPath, 'edit', appealSubservice.mainElement.registerId]">перейти</a>
        </p>
        <dynamic-form
            *ngIf="data.mainElementXsd"
            [model]="appealSubservice.mainElement"
            [xsd]="data.mainElementXsd">
        </dynamic-form>
    </fieldset>
</div>
