<div
    [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit'}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i></b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()"
                    *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()"
                    *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div *ngIf="mode == 'view'" [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}">
            <span class="text-muted">Номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указан</em>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button"
                    [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <ng-container *ngIf="mode === 'edit'">

            <!-- Визуальные вкладки с данными -->
            <div class="mt-20">
                <element-edit-tabs [tabs]="editTabs" [active]="activeTab" (onActivateTab)="activateTab($event)" *ngIf="mode === 'edit'"></element-edit-tabs>
            </div>

            <!-- Основные данные -->
            <div class="pt-15 pl-20 pr-20" *ngIf="activeTab === 'entity'">

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group form-group-lg">
                            <label>Номер</label>
                            <input [(ngModel)]="entity.number" type="text" class="form-control input-xs"
                                   placeholder="Введите номер" [disabled]="!allowEdit" name="number">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group form-group-lg">
                            <label>Объект проверки</label>
                            <ng-select
                                [allowClear]="true"
                                [items]="objects"
                                [active]="getSelected(objects, 'object')"
                                (selected)="select($event, 'object')"
                                [disabled]="!allowEdit"
                                placeholder="Выберите значение"
                                name="object"
                            ></ng-select>
                        </div>
                    </div>

                    <div class="col-md-4 col-xs-12">
                        <div class="form-group form-group-lg">
                            <label>Тип адреса</label>
                            <catalogue
                                catalogName="typeAddress"
                                name="typeAddress"
                                [(ngModel)]="entity.typeAddress"
                                [priority]="true"
                                [large]="true"
                            ></catalogue>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group form-group-lg">
                            <label>Дата составления</label>
                            <evolenta-datepicker [(ngModel)]="entity.createDate" [large]="true" [placeholder]="'Выберите'"
                                         [valueAsString]="true" [disabled]="!allowEdit" name="createDate"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>Время составления</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group input-group form-group-lg">
                                    <input [(ngModel)]="entity.createHours" type="number" min="0" max="24"
                                           class="form-control input-xs" placeholder="ч" [disabled]="!allowEdit" name="createHours">
                                    <div class="input-group-spr"><span>:</span></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group form-group-lg">
                                    <input [(ngModel)]="entity.createMinutes" type="number" min="0" max="60"
                                           class="form-control input-xs" placeholder="мин" [disabled]="!allowEdit" name="createMinutes">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Место составления</label>
                            <evolenta-address-gar
                                    name="createPlace"
                                    [large]="true"
                                    [showIndex]="false"
                                    [disabled]="!allowEdit"
                                    [(ngModel)]="entity.createPlace"
                            ></evolenta-address-gar>
                        </div>
                    </div>
                </div>

                <!-- Место проведения проверки -->
                <appeal-entity-check-place
                    [large]="true"
                    [entity]="entity"
                    [property]="'places'"
                    [disabled]="!allowEdit"
                    [title]="" >
                </appeal-entity-check-place>


                <!-- Инспекторы -->
                <p class="mb-15">
                    <span class="text-bold">Проверяющие инспекторы: </span>
                    <span class="ml-5" *ngIf="entity.inspectors && entity.inspectors.length > 0"><ng-container *ngFor="let inspector of entity.inspectors; let lst = last">{{inspector.name}} <ng-container *ngIf="!lst">, </ng-container>   </ng-container></span>
                    <span class="anchor text-info text-size-small ml-5" *ngIf="!entity.inspectors || entity.inspectors.length == 0" (click)="isSelectInspectors = true">не выбраны</span>
                    <span class="anchor text-info text-size-small ml-5" *ngIf="entity.inspectors && entity.inspectors.length > 0" (click)="isSelectInspectors = true">изменить</span>
                </p>

                <div class="bg-primary-50 p-20" *ngIf="isSelectInspectors">
                    <p class="text-bold">Выберите инспекторов:</p>
                    <div class="checkbox-block" *ngFor="let inspector of appeal.inspectors">
                        <input type="checkbox" class="checkbox" id="inspector{{inspector.login}}" [checked]="checkUsedInspector(inspector)"
                               (change)="selectInspector(inspector)">
                        <label for="inspector{{inspector.login}}">
                            {{inspector.name}}
                        </label>
                    </div>

                    <button type="button" class="btn btn-info btn-xs mt-15" (click)="isSelectInspectors = false">Применить</button>
                </div>

                <!-- Эксперты -->
                <ng-container *ngIf="appeal.experts && appeal.experts.length > 0">
                    <p class="mb-15">
                        <span class="text-bold">Привлеченные эксперты: </span>
                        <span class="ml-5" *ngIf="entity.experts && entity.experts.length > 0"><ng-container *ngFor="let expert of entity.experts; let lst = last">{{expert.name}} <ng-container *ngIf="!lst">, </ng-container>   </ng-container></span>
                        <span class="anchor text-info text-size-small ml-5" *ngIf="!entity.experts || entity.experts.length == 0" (click)="isSelectExperts = true">не выбраны</span>
                        <span class="anchor text-info text-size-small ml-5" *ngIf="entity.experts && entity.experts.length > 0" (click)="isSelectExperts = true">изменить</span>
                    </p>

                    <div class="bg-primary-50 p-20" *ngIf="isSelectExperts">
                        <p class="text-bold">Выберите эксперта:</p>
                        <div class="checkbox-block" *ngFor="let expert of appeal.experts">
                            <input type="checkbox" class="checkbox" id="expert{{expert.guid}}" [checked]="checkUsedExpert(expert)"
                                   (change)="selectExpert(expert)">
                            <label for="expert{{expert.guid}}">
                                {{expert.name}}
                            </label>
                        </div>

                        <button type="button" class="btn btn-info btn-xs mt-15" (click)="isSelectExperts = false">Применить</button>
                    </div>
                </ng-container>

                <div class="pb-15">
                    <label>
                        Временные этапы проверки
                        <span class="anchor text-info text-size-small ml-5" (click)="addWorkInterval()" *ngIf="allowEdit && entity.workIntervals && entity.workIntervals.length > 0">добавить</span>
                    </label>
                    <div class="row" *ngFor="let interval of entity.workIntervals">
                        <div class="col-md-4">
                            <div class="form-group input-group-lg">
                                <evolenta-datepicker [(ngModel)]="interval.date" [large]="true" [placeholder]="'Выберите дату'"
                                             [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="flex">
                                <div>
                                    <div class="form-group form-group-lg">
                                        <input [(ngModel)]="interval.hours" type="number" min="0" max="24"
                                               class="form-control input-xs" placeholder="ч" [disabled]="!allowEdit">
                                    </div>
                                </div>
                                <div class="p-5 pt-10">ч.</div>
                                <div>
                                    <div class="form-group form-group-lg">
                                        <input [(ngModel)]="interval.minutes" type="number" min="0" max="60"
                                               class="form-control input-xs" placeholder="мин" [disabled]="!allowEdit">
                                    </div>
                                </div>
                                <div class="p-5 pt-10">мин.</div>
                            </div>
                        </div>
                        <div class="col-md-3"><span class="text-muted">Продолжительность:</span> <br>{{calculateWorkTime(interval)}}</div>
                        <div class="col-md-1 text-right">
                            <div class="form-group form-group-lg">
                                <button class="btn btn-default" style="padding: 9px 15px" type="button" (click)="deleteWorkInterval(interval)" [disabled]="!allowEdit"><i class="icon-trash"></i></button>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="entity.workIntervals && entity.workIntervals.length > 0">
                        <hr class="no-margin">
                        <p class="mb-15 mt-10"><span class="text-muted mr-5">Общая продолжительность проверки:</span> <span class="text-slate text-bold">{{calculateWorkTime()}}</span></p>
                    </ng-container>
                    <div class="alert alert-info no-margin-bottom no-border no-border-radius p-10" *ngIf="!entity.workIntervals || entity.workIntervals.length == 0">
                        Временные этапы проверки не добавлены <a class="text-info anchor text-size-small" (click)="addWorkInterval()">добавить</a></div>
                </div>


                <div class="mb-15">
                    <appeal-entity-persons
                        [large]="true"
                        [entity]="entity"
                        [property]="'subjectPersons'"
                        [title]="'Представители проверяемого лица'"
                        [disabled]="!allowEdit">
                    </appeal-entity-persons>
                </div>

                <!-- Лица, уполномоченного на проведение проверки -->
                <div class="mb-15">
                    <appeal-entity-persons
                        [large]="true"
                        [entity]="entity"
                        [property]="'otherPersons'"
                        [title]="'Лица, присутствующие при проведении проверки'"
                        [disabled]="!allowEdit">
                    </appeal-entity-persons>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label>Сведения о Журнале учёта проверок</label>
                        <div class="form-group form-group-lg">
                            <ng-select
                                [items]="auditLogsForSelect"
                                [(ngModel)]="entity.informationAuditLog"
                                [disabled]="!allowEdit"
                                placeholder="Выберите значение из списка..."
                                name="informationAuditLog">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4 hide">
                        <div class="form-group form-group-lg">
                            <label class="form-label display-block">
                                Сведения об ознакомлении с актом проверки
                            </label>
                            <div class="checkbox-inline">
                                <input id="isAcquainted" class="checkbox" type="checkbox"
                                        [(ngModel)]="entity.informationFamiliarization" [disabled]="!allowEdit"
                                        name="informationFamiliarization">
                                <label for="isAcquainted">Ознакомлен</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Прилагаемые документы -->
                <div class="mb-15">
                    <appeal-entity-field-input
                        [multiple]="true"
                        [large]="true"
                        [entity]="entity"
                        [property]="'materials'"
                        [title]="'Прилагаемые документы'"
                        [disabled]="!allowEdit"
                        [placeholder]="'Введите наименование прилагаемого документа'">
                    </appeal-entity-field-input>
                </div>
            </div>

            <div class="pt-15 pl-20 pr-20" *ngIf="activeTab === 'violations'">

                <fieldset class="mb-30">
                    <legend class="text-bold no-margin-bottom">Результаты проверки</legend>
                    <table class="table table-hover mb-20">
                        <tbody>
                        <tr *ngFor="let result of entity.results">
                            <td class="border-bottom border-bottom-default">
                                <div class="form-group">
                                    <label>Вид результата проверки <span class="anchor text-info ml-5 text-size-small" (click)="deleteResult(result)">удалить результат</span></label>
                                    <catalogue
                                        catalogName="nsiErpResultInformNature"
                                        name="violationNature"
                                        [(ngModel)]="result.type"
                                        [large]="true"
                                    ></catalogue>
                                </div>
                                <div class="form-group no-margin-bottom">
                                    <label>Результат</label>
                                    <textarea rows="3" [(ngModel)]="result.content" class="form-control input-lg"></textarea>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <button type="button" class="btn btn-default" (click)="addResult()">Добавить результат</button>
                </fieldset>

                <fieldset class="mb-20">
                    <legend class="text-bold no-margin no-border">Выявленные нарушения</legend>
                    <table class="table table-hover mb-20">
                        <tbody>
                        <tr *ngFor="let violation of entity.violations">
                            <td class="border-bottom border-bottom-default">
                                <div class="form-group">
                                    <label>Нарушено обязательное требование <span class="anchor text-info ml-5 text-size-small" (click)="deleteViolation(violation)">удалить нарушение</span></label>
                                    <catalogue catalogName="mandatoryReqs"
                                               name="mandatoryReqs"
                                               [(ngModel)]="violation.mandatoryReq"
                                               [returnFields]="['_id', 'code', 'name', 'nPA']"
                                               [large]="true"
                                    ></catalogue>
                                </div>
                                <div class="form-group">
                                    <label>Вид нарушения</label>
                                    <catalogue
                                        catalogName="nsiErpViolationNature"
                                        name="violationNature"
                                        [(ngModel)]="violation.type"
                                        [priority]="true"
                                        [large]="true"
                                    ></catalogue>
                                </div>
                                <div class="form-group no-margin-bottom">
                                    <label>Текст нарушения</label>
                                    <textarea rows="3" [(ngModel)]="violation.content" class="form-control input-lg"></textarea>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <button type="button" class="btn btn-default" (click)="addViolation()">Добавить нарушение</button>
                </fieldset>
            </div>

            <appeal-entity-documents [entity]="entity" [task]="task" [allowEdit]="allowEdit"></appeal-entity-documents>

        </ng-container>
    </div>
</div>
