import { Injectable } from '@angular/core';
import { MAPPING_FIELDS_COLLECTION } from '../../../common/constants';
import { Config } from '../../../common/services/config';
import { HttpService, RestService } from '@evolenta/core';

@Injectable()
export class EpguIntegrationService {

    public baseUrl = Config.server + Config.api;
    public xslGeneratorBaseUrl = Config.server + Config.api + 'xsl-generator/';
    public standardNumber;
    public fields;
    public versionXsd;

    public constructor(
        private rest: RestService,
        private httpService: HttpService,
    ) {
    }

    /**
     * Сохранить обновленную сущность маппинга
     * @param mapping
     */
    public save(mapping) {
        return this.rest.update(MAPPING_FIELDS_COLLECTION, mapping);
    }

    /**
     * Получить список маппингов для указанного стандарта
     * @param standard
     */
    public getMappingForStandard(standard) {
        const params = {
            search:
                {search: [
                        {
                            field: 'standardId',
                            operator: 'eq',
                            value: standard.auid.toString(),
                        },
                    ],
                },
            prg: 'mappingsListForStandards',
        };

        return this.rest.search('MappingFields', params);
    }

    /**
     * Создаёт новый маппинг
     */
    public createMapping() {
        const data = {
            standardNumber: this.standardNumber,
            fields: this.fields,
            versionXsd: this.versionXsd,
        };

        return this.httpService.post(this.xslGeneratorBaseUrl + 'saveFields', data, false);
    }

    /**
     * Удаляет маппинг
     */
    public deleteMapping(mapping) {
        return this.httpService.remove(`${this.xslGeneratorBaseUrl}mapping/${mapping._id}`);
    }

    /**
     * Обновить сущность мапинга
     * @param mapping
     */
    public saveMapping(mapping) {
        if (mapping._id) {
            return this.httpService.put(`${this.baseUrl}update/MappingFields?mainId=${mapping._id}`, mapping);
        }

        return this.httpService.post(`${this.baseUrl}create/MappingFields`, mapping);
    }

    /**
     * Загрузить XSD-файл и получить маппинг полей
     * @param file
     */
    public uploadXSDFileForCompareFields(standardNumber, file) {
        const formData = this._createFormData(file, standardNumber);

        return this.httpService.post(this.xslGeneratorBaseUrl + 'compareFields', formData, true);
    }

    public generateXsl(standardNumber, file) {
        const formData = this._createFormData(file, standardNumber);

        return this.httpService.post(this.xslGeneratorBaseUrl + 'generateXslSMEVtoPPGMU', formData, true);
    }

    public generatePdf(file) {
        const formData = this._createFormData(file);

        return this.httpService.post(this.xslGeneratorBaseUrl + 'generateXslPdf', formData, true);
    }

    private _createFormData(file, standardNumber= null) {
        const formData = new FormData();
        formData.append('xsdSchemaFile', file);

        if (standardNumber) {
            formData.append('standardNumber', standardNumber);
        }

        return formData;
    }
}
