<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <!-- Функциональные кнопки-->
    <button class="btn btn-primary btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            *ngIf="!appealService.groupOperation && !appealService.isProcessSelectAppealsForPacket"
            [disabled]="!accessService.hasAccess([permissions.Appeal_Create, permissions.Appeal_Status_Draft], true) || currentOrganization.denyAppealCreate"
            (click)="createNewDeal()">
        <b><i class="icon-plus-circle2"></i></b>
        {{ localizations.appeals.new }}
    </button>

    <button type="button"
            class="btn btn-primary btn-main btn-labeled btn-labeled-right text-uppercase"
            *ngIf="appealService.groupOperation"
            (click)="createNewObjectByShortAppealList('groupOperation')">
        <b><i class="icon-checkmark3"></i></b>
        {{ localizations.appeals.group_operation }}
        <span class="badge bg-white ml-5 text-blue"
              *ngIf="appealService.groupOperation.appeals">
            {{appealService.groupOperation.appeals.length}}
        </span>
    </button>

    <button type="button"
            class="btn btn-primary btn-main btn-labeled btn-labeled-right text-uppercase"
            *ngIf="appealService.isProcessSelectAppealsForPacket"
            (click)="returnToPacket()">
        <b><i class="icon-arrow-right16"></i></b>
        {{ localizations.appeals.back_to_packet }}
        <span class="badge bg-white ml-5 text-blue"
              *ngIf="appealService.packet.appeals">
            {{appealService.packet.appeals.length}}
        </span>
    </button>

    <!-- Выбрать все -->
    <div class="select-all"
         *ngIf="appealService.groupOperation || appealService.isProcessSelectAppealsForPacket"
         [title]="localizations.common.select_all"
         (click)="selectAllAppeals()"
         [ngClass]="{
            'bg-grey-300': !isAllAppealsSelected,
             'bg-blue-600 checked': isAllAppealsSelected
          }">
        <i class="icon-checkmark3"></i>
    </div>

    <!-- Глобальный поиск -->
    <div class="global-search"
         [ngClass]="{
            'short': appealService.groupOperation || appealService.isProcessSelectAppealsForPacket
         }">
        <div class="input-group">
            <input class="form-control"
                   type="text"
                   [placeholder]="localizations.common.search_dots"
                   [(ngModel)]="globalSearch"
                   (ngModelChange)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                    <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData(ADD)">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <!-- Кнопки авторизации + доп.меню -->
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<!-- Основное содержание компонента -->
<div class="page-container">

    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title no-padding-bottom no-border">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-bold">
                            {{ localizations.common.appeals }}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{ totalAppealsCount }}
                            </span>
                        </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li><i class="icon-cog3"></i></li>
                    </ul>
                </div>
            </div>

            <evolenta-scrollbar scrollClass="sidebar sidebar-secondary sidebar-default pb-20">
                <!-- Панель сортировки -->
                <sorting-bar collection="appeals"
                             [disabledFilters]="disabledFilters"
                             [selectedField]="selectedSortField"
                             [selectedSortOrder]="selectedSortOrder"
                             (fieldChange)="setSortOptsAndLoadData($event)">
                </sorting-bar>

                <!-- Панель фильтрации -->
                <new-filters-bar [filters]="filtersPanelItems"
                             [afterHeader]="true"
                             [base]="baseSearch"
                             (onApplyFilters)="loadData(REPLACE)"
                             (insideFiltersEmitter)="saveFilters($event)"
                             #filtersPanel>
                </new-filters-bar>
            </evolenta-scrollbar>
        </div>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat no-border-top">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData(ADD)">
                            <ng-container *ngIf="appeals.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let appeal of appeals; trackBy:indexedAppeals; let lst = last">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                             [ngClass]="{'active': previewedAppeal && previewedAppeal._id === appeal._id }">
                                            <div class="list-item full-width"
                                                 (click)="clickByAppeal(appeal)"
                                                 (dblclick)="dblclickByAppeal(appeal)">
                                                <div style="align-self: flex-start">
                                                    <div class="list-item-status-rectangle text-uppercase display-inline-block"
                                                         [ngClass]="getStatusProperty(appeal, 'background')">
                                                        {{ getStatusProperty(appeal, 'name') }}
                                                    </div>
                                                    <!-- Источник дела -->
                                                    <div class="list-item-status-rectangle text-uppercase display-inline-block ml-10"
                                                         [ngClass]="{
                                                            'bg-danger-300': appeal.source === 'pgu',
                                                            'bg-teal-300': appeal.source === 'usluginew',
                                                            'bg-brown-300': appeal.source == 'mfc',
                                                            'bg-green-300': appeal.source === 'stroyu',
                                                            'bg-orange-300': appeal.source === 'locolo',
                                                            'bg-success-300': appeal.source === 'zabota',
                                                            'bg-purple-300': appeal.source === 'epgu',
                                                            'bg-blue-300': appeal.source === 'arm'
                                                        }">
                                                        <!-- TODO условие временное, убрать после миграции -->
                                                        {{ appeal.source ? localizations.appeals.source[appeal.source] : '' }}
                                                    </div>
                                                    <!-- Индикаторы: истекает, просрочен, есть результаты запросов -->
                                                    <div class="notify-label display-inline-block ml-5">
                                                        <div *ngIf="appeal.notify && appeal.notify.length > 0">
                                                            <ng-container *ngFor="let notify of appeal.notify" >
                                                            <span class="badge {{notify.theme}}"
                                                                  *ngIf="!notify.icon"
                                                                  [title]="notify.text">&nbsp;
                                                            </span>
                                                                <span class="{{notify.theme}}"
                                                                      *ngIf="notify.icon"
                                                                      [title]="notify.text">
                                                                <i class="{{notify.icon}}"></i>
                                                            </span>
                                                            </ng-container>
                                                        </div>

                                                        <!-- Индикатор непросмотренных документов от пользователя -->
                                                        <div class="unread-feedback-document-label">
                                                            <i class="icon-bookmark2 ml-5 text-primary"
                                                               *ngIf="checkAppealHasUnreadUserDocuments(appeal)">
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="list-item-data"
                                                     style="white-space: inherit">
                                                    <h6 class="text-bold no-margin">
                                                        {{appeal.subservice ? appeal.subservice.shortTitle : ''}}
                                                        <ng-container *ngIf="appeal.shortNumber">
                                                            <br>
                                                            <span>
                                                                № {{appeal.shortNumber ? appeal.shortNumber : 'n/a'}}
                                                            </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="appeal.dateRegister">
                                                            {{ localizations.common.from }} {{appeal.dateRegister | date:'shortDate'}}
                                                        </ng-container>
                                                    </h6>

                                                    <p class="no-margin-bottom"
                                                       *ngIf="appeal.subservice.externalNumber || appeal.subjects?.length">
                                                        <ng-container *ngIf="appeal.subservice.externalNumber">
                                                            <span class="text-muted mr-5">
                                                                {{ localizations.common.external_number }}:
                                                            </span>
                                                            <span class="text-bold mr-10">
                                                                {{appeal.subservice.externalNumber}}
                                                            </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="appeal.subjects?.length">
                                                            <span class="objects-list text-semibold no-margin-top no-margin-bottom display-inline">
                                                                <ng-container
                                                                    *ngFor="let object of appeal.subjects; let idx = index">
                                                                    {{object.shortHeader}}
                                                                    <span *ngIf="idx != appeal.subjects.length - 1">,</span>
                                                                </ng-container>
                                                            </span>
                                                        </ng-container>
                                                    </p>
                                                    <p class="no-margin-bottom">
                                                        <ng-container>
                                                            <span class="text-muted mr-5">
                                                                {{ localizations.appeals.responsible }}:
                                                            </span>
                                                            <span class="text-muted" *ngIf="!appeal.controlOperator || !appeal.controlOperator.length">
                                                                {{ localizations.common.not_specified_m }}
                                                            </span>
                                                            <span class="text-bold mr-10" *ngIf="appeal.controlOperator && appeal.controlOperator.length">
                                                                {{ controlOperator(appeal) }}
                                                            </span>
                                                        </ng-container>
                                                    </p>
                                                 </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="panel panel-shadow m-15 p-15"
                                 *ngIf="appeals.length == 0">
                                <div class="panel-body text-center"
                                     *ngIf="!initialized">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        Загрузка...
                                    </h5>
                                </div>
                                <div class="panel-body text-center"
                                     *ngIf="initialized">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing }}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations.appeals.try_to_adjust_filters }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-warning alert-styled-left alert-bordered"
                         *ngIf="!previewedAppeal">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.appeals.for_detailed_view_select_appeal }}
                    </div>
                    <appeal-preview [appeal]="previewedAppeal"
                                    [metaReglament]="metaReglament"
                                    *ngIf="previewedAppeal">
                    </appeal-preview>
                </div>
            </div>
        </div>
    </div>
</div>

