<div class="ml-20 mr-20 mb-20 p-20 mt-20 border-top border-right border-bottom border-left border-grey-120">
    <h4 class="no-margin">
        {{ localizations.subjects.select_type}}:
    </h4>

    <ng-template [ngIf]="activatedRouteService.workModeUrl === 'appeals'">
        <div class="radio-inline"
             *ngFor="let sub of subKinds">
            <input id="subKind-{{ sub?.specialTypeId }}-{{ sub?.type ? sub.type : '' }}"
                   class="radio"
                   type="radio"
                   name="subKind"
                   [checked]="object?.kind?.subKind?.name === sub?.name"
                   (click)="onChangeSubKind.emit(sub)">
            <label for="subKind-{{ sub?.specialTypeId }}-{{ sub?.type ? sub.type : '' }}">
                {{ sub?.name }}
                <span *ngIf="sub?.onlyForPrincipal"
                      class="text-size-small text-muted">
                ({{localizations['appeals.subjects'].only_as_a_representative}})
                </span>
            </label>
        </div>
    </ng-template>

    <ng-template [ngIf]="activatedRouteService.workModeUrl === 'envelopes'">
        <div class="radio-inline">
            <input id="typeFL"
                   class="radio"
                   type="radio"
                   name="personType"
                   value="individualApplicant"
                   [checked]="object?.specialTypeId === 'individualApplicant'"
                   (click)="onChangeSubKind.emit('individualApplicant')">
            <label for="typeFL">
                {{ localizations['appeals.subjects'].private_person }}
            </label>
        </div>
        <div class="radio-inline">
            <input id="typeIP"
                   class="radio"
                   type="radio"
                   name="personType"
                   value="individualApplicant"
                   [checked]="object?.specialTypeId === 'ipApplicant'"
                   (click)="onChangeSubKind.emit('ipApplicant')">
            <label for="typeIP">
                {{ localizations['appeals.subjects'].individual_entrepreneur }}
            </label>
        </div>
        <div class="radio-inline">
            <input id="typeUL"
                   class="radio"
                   type="radio"
                   name="personType"
                   value="ulApplicant"
                   [checked]="object?.specialTypeId === 'ulApplicant'"
                   (click)="onChangeSubKind.emit('ulApplicant')">
            <label for="typeUL">
                {{ localizations['appeals.subjects'].legal_body }}
            </label>
        </div>
    </ng-template>
</div>
