<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Контрольная закупка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <ng-container *ngIf="task && mode == 'view'">
                <span class="text-semibold text-uppercase">{{entity.name}}</span>
            </ng-container>
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Орган прокуратуры: </span>
            <ng-container *ngIf="entity.prosecutor">{{entity.prosecutor.name}}</ng-container>
            <em *ngIf="!entity.prosecutor">не указано</em>

            <span class="text-muted ml-10">Территориальная юрисдикция: </span>
            <ng-container *ngIf="entity.territoryDirectory">{{entity.territoryDirectory.name}}</ng-container>
            <em *ngIf="!entity.territoryDirectory">не указано</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">

                <!-- Наименование органа прокуратуры -->
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group">
                            <label>Наименование органа прокуратуры</label>
                            <catalogue
                                catalogName="prosecutor"
                                name="prosecutor"
                                [(ngModel)]="entity.prosecutor"
                                [priority]="true"
                                [large]="true"
                                placeholder="Укажите орган прокуратуры">
                            </catalogue>
                        </div>
                    </div>
                </div>

                <!-- Территориальная юрисдикция -->
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group">
                            <label>Территориальная юрисдикция</label>
                            <catalogue
                                catalogName="nsiErpTerritory"
                                name="territoryDirectory"
                                [(ngModel)]="entity.territoryDirectory"
                                [priority]="true"
                                [large]="true">
                            </catalogue>
                        </div>
                    </div>
                </div>

                <!-- Правовые основы -->
                <div class="mb-15">
                    <appeal-entity-catalogue
                        [large]="true"
                        [entity]="entity"
                        [property]="'controlBase'"
                        [catalogName]="'basisKNM'"
                        [title]="'Правовые основания'"
                        [placeholder]="'Укажите основания...'">
                    </appeal-entity-catalogue>
                </div>

                <!-- Дата проведения/составления -->
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <div class="row">
                            <div class="col-xs-12 col-md-8">
                                <label>Дата проведения</label>
                                <div class="form-group form-group-lg">
                                    <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true"></evolenta-datepicker>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-4">
                                <label>Время проведения</label>
                                <div class="row">
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group input-group form-group-lg">
                                            <input type="number" name="hourBuy" class="form-control" [(ngModel)]="entity.hourBuy" min="0" max="23" placeholder="ч">
                                            <div class="input-group-spr"><span>:</span></div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group form-group-lg">
                                            <input type="number" name="minBuy" class="form-control" [(ngModel)]="entity.minBuy" min="0" max="59" placeholder="мин">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <div class="row">
                            <div class="col-xs-12 col-md-8">
                                <label>Дата составления</label>
                                <div class="form-group form-group-lg">
                                    <evolenta-datepicker name="dateNotice" [large]="true" [(ngModel)]="entity.dateNotice" [valueAsString]="true"></evolenta-datepicker>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-4">
                                <label>Время составления</label>
                                <div class="row">
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group input-group form-group-lg">
                                            <input type="number" name="hourTimeNotice" class="form-control" [(ngModel)]="entity.hourTimeNotice" min="0" max="23" placeholder="ч">
                                            <div class="input-group-spr"><span>:</span></div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group form-group-lg">
                                            <input type="number" name="minTimeNotice" class="form-control" [(ngModel)]="entity.minTimeNotice" min="0" max="59" placeholder="мин">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Приложения -->
                <div class="mb-15">
                    <appeal-entity-field-input
                        [multiple]="true"
                        [large]="true"
                        [entity]="entity"
                        [property]="'scrollDoc'"
                        [title]="'Приложения'"
                        [placeholder]="'Введите наименование прилагаемого документа'">
                    </appeal-entity-field-input>
                </div>

                <!-- Должностное лицо -->
                <div class="mb-15">
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        [property]="'executive'"
                        [title]="'Должностное лицо'"
                        [field]="['name','position']">
                    </appeal-entity-employee>
                </div>

            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
