<div class="panel panel-preview">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>{{element.title}}</span>
            <span class="label heading-text status-label ml-15">
        </span>
        </h4>

        <div class="heading-elements">
            <a [title]="localizations.common.edit"
               routerLink="/processes/process-registry/edit/{{element._id}}">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </a>
            <a [title]="localizations.common.delete"
               (click)="showDeleteConfirmationModal()">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </a>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">

                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        {{ localizations.common.general_data }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.common,
                                'icon-arrow-left12': !panels.common
                                }"></i></a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.common}">
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.common.name }}:
                            </div>
                            <div>
                                {{ element.title }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="element.processId">
                            <div class="text-muted">
                                {{ localizations['process-registry'].process_id }}:
                            </div>
                            <div>
                                {{ element.processId }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="element.level">
                            <div class="text-muted">
                                {{ localizations.common.level }}:
                            </div>
                            <div>
                                {{ element.level }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="element.knmKind">
                            <div class="text-muted">
                                {{ localizations['process-registry'].control_type }}:
                            </div>
                            <div>
                                {{ element.knmKind }}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset *ngIf="element.tasks && element.tasks.userTasks.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.userTasks = !panels.userTasks">
                        {{ localizations['process-registry'].user_tasks }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.userTasks,
                                'icon-arrow-left12': !panels.userTasks
                                }"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.userTasks}">
                        <ng-container>
                            <bpmn-task-list [tasks]="element.tasks.userTasks">
                            </bpmn-task-list>
                        </ng-container>
                    </div>
                </fieldset>
                <fieldset  *ngIf="element.tasks && element.tasks.serviceTasks.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.serviceTasks = !panels.serviceTasks">
                        {{ localizations['process-registry'].service_tasks }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.serviceTasks,
                                'icon-arrow-left12': !panels.serviceTasks}">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.serviceTasks}">
                        <ng-container>
                            <bpmn-task-list [tasks]="element.tasks.serviceTasks">
                            </bpmn-task-list>
                        </ng-container>
                    </div>
                </fieldset>
                <fieldset  *ngIf="element.catchEvents && element.catchEvents.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.catchEvents = !panels.catchEvents">
                        {{ localizations['bpmn-card'].subprocesses }}
                        <a class="control-arrow">
                            <i [ngClass]="{'icon-arrow-down12': panels.catchEvents, 'icon-arrow-left12': !panels.catchEvents}"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.catchEvents}">
                        <ng-container>
                            <bpmn-task-list [tasks]="element.catchEvents"></bpmn-task-list>
                        </ng-container>
                    </div>
                </fieldset>
                <fieldset  *ngIf="element.tasks && element.tasks.subProcess.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.subProcess = !panels.subProcess">
                        {{ localizations['bpmn-card'].subprocesses }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                    'icon-arrow-down12': panels.subProcess,
                                    'icon-arrow-left12': !panels.subProcess
                                }"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10" [ngClass]="{'in' : panels.subProcess}">
                        <ng-container>
                            <bpmn-task-list [tasks]="element.tasks.subProcess"></bpmn-task-list>
                        </ng-container>
                    </div>
                </fieldset>

            </div>
        </evolenta-scrollbar>
    </div>
</div>

<delete-confirmation-modal
    [title]="deleteConfirmationHeader"
    [text]="deleteConfirmationText"
    (onConfirm)="deleteProcess()"
    #deleteConfirmationModal>
</delete-confirmation-modal>
