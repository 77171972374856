import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NsiDictionaryComponent } from './nsi/dictionary/nsi-dictionary.component';
import { NsiDefaultComponent } from './nsi/nsi-default.component';
import { NsiDictionaryElementComponent } from './nsi/dictionary-element/nsi-dictionary-element.component';
import { NsiEditDictionaryComponent } from './nsi/dictionary-edit/nsi-edit-dictionary.component';
import { NsiGroupDictionariesComponent } from './nsi/dictionary-group/nsi-group-dictionaries.component';
import { NsiService } from './nsi/nsi.service';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { NsiResolve } from './nsi/nsi.resolve';
import { NsiDictionaryElementVersionsComponent } from './nsi/dictionary-element-versions/nsi-dictionary-element-versions.component';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        SharedComponentsModule,
        InlineSVGModule,
        BreadcrumbsModule,
    ],
    declarations: [
        NsiDictionaryComponent,
        NsiDefaultComponent,
        NsiDictionaryElementComponent,
        NsiEditDictionaryComponent,
        NsiGroupDictionariesComponent,
        NsiDictionaryElementVersionsComponent,
    ],
    providers: [
        NsiResolve,
        NsiService,
    ],
    entryComponents: [
        NsiGroupDictionariesComponent,
        NsiDictionaryComponent,
        NsiEditDictionaryComponent,
        NsiDictionaryElementComponent,
        NsiDictionaryElementVersionsComponent,
    ],
})
export class DictionariesModule {
}
