import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FilesService, RestService, TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-bpmn-card',
    templateUrl: 'common-bpmn-card.component.html',
})
export class CommonBpmnCardComponent implements OnInit {
    @Input() public bpmn;
    @Input() public entityId = null;
    @Input() public isSubProcess = false;
    @Input() public allowEdit = true;
    @Input() public entityProcessData = null;
    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<any>();
    @Output() public onCreateSubProcess = new EventEmitter<any>();
    @Output() public onSearchSubProcess = new EventEmitter<any>();
    @Output() public onDeleteSubProcess = new EventEmitter<any>();
    @Output() public onEditSubProcess = new EventEmitter<any>();
    @Output() public onCloneProcess = new EventEmitter<any>();

    public allowDelete = true;
    public allowClone = false;

    public panels = {
        common: true,
        serviceTasks: false,
        userTasks: false,
        subProcess: true,
    };
    public isProcessingClone = false;
    public localizations;

    public imageToShow;
    public modalRef: BsModalRef;
    @ViewChild('imageModal', { static: false }) public imageModal: TemplateRef<any>; // модальное окно для отображения схемы процесса

    public constructor(
        private filesService: FilesService,
        private modalService: BsModalService,
        private restService: RestService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        this.allowDelete = this.allowEdit;
        this.allowEdit = !this.entityId || (this.entityId && this.bpmn.entityId === this.entityId);
        this.allowClone = this.entityId && this.bpmn.entityId !== this.entityId;
    }

    private _loadTranslations() {
        this.translate.get(['common', 'bpmn-card']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public edit() {
        this.onEdit.emit(this.bpmn);
    }

    public delete() {
        this.onDelete.emit(this.bpmn);
    }

    public createSubProcess(taskId) {
        this.onCreateSubProcess.emit(taskId);
    }

    public searchSubProcess(taskId) {
        this.onSearchSubProcess.emit(taskId);
    }

    public deleteSubProcess(data) {
        this.onDeleteSubProcess.emit(data);
    }

    public editSubProcess(data) {
        this.onEditSubProcess.emit(data);
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public downloadFile(id, originalName) {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    public openImage(img) {
        if (img) {
            this.filesService.downloadFile(img._id, 'text').then((image) => {
                const reader = new FileReader();
                reader.addEventListener(
                    'load',
                    () => {
                        this.imageToShow = reader.result;
                        this.modalRef = this.modalService.show(this.imageModal, {
                            backdrop: 'static',
                            class: 'modal-xlg',
                        });
                    },
                    false
                );
                if (image) {
                    const blob = new Blob([image]);
                    reader.readAsDataURL(blob);
                }
            });
        }
    }

    // Клонирование процесса
    public clone() {
        this.isProcessingClone = true;
        const processCopy = cloneDeep(this.bpmn);
        delete processCopy.dateCreation;
        delete processCopy.dateLastModification;
        delete processCopy.userCreation;
        delete processCopy.userLastModification;
        delete processCopy.auid;
        delete processCopy._id;
        delete processCopy.file;
        delete processCopy.guid;
        processCopy.entityId = this.entityId;
        this.restService.create('sperBpmnProcesses', processCopy).then((createdProcess: any) => {
            this.filesService.copyFile(this.bpmn.file, 'sperBpmnProcess', createdProcess._id).then((createdFile) => {
                createdProcess.file = createdFile;
                this.restService.update('sperBpmnProcesses', createdProcess).then((resultProcess) => {
                    this.isProcessingClone = false;
                    this.onCloneProcess.emit(resultProcess);
                });
            });
        });
    }
}
