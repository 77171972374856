<evolenta-scrollbar [scrollClass]="'panel panel-default no-border no-margin no-padding main'" (onScrollEvent)="topPosition = $event">
    <div *ngIf="!isEditXsd">
        <ng-container *ngFor="let block of metaReglament.blocks">

            <ng-container *ngIf="isCustomBlock() && activeTab === block.code">
                <common-standard-block [blockCode]="activeTab"></common-standard-block>
            </ng-container>

            <ng-container *ngIf="!isCustomBlock()">


                <!-- Общие данные -->
                <common-standard-common *ngIf="block.code == 'common' && activeTab == 'common'"
                                        (onEditXsd)="editXsd($event)"
                                        #commonData>
                </common-standard-common>
                <!-- Процессы -->
                <common-standard-processes *ngIf="block.code == 'processes' && activeTab == 'processes'"></common-standard-processes>

                <!-- Параметры -->
                <common-standard-parameters *ngIf="block.code == 'parameters' && activeTab == 'parameters'"></common-standard-parameters>

                <!-- Документы -->
                <common-standard-documents [xsdData]="xsdData && xsdData.type === 'documents' ? xsdData : null"
                                           (onEditXsd)="editXsd($event)"
                                           *ngIf="block.code == 'documents' && activeTab == 'documents'"></common-standard-documents>
                <!-- Варианты -->
                <common-standard-variants [xsdData]="xsdData && xsdData.type === 'variant' ? xsdData : null"
                                          (onEditXsd)="editXsd($event)"
                                          *ngIf="block.code == 'variants' && activeTab == 'variants'"></common-standard-variants>
                <!-- Сведения -->
                <common-standard-entities *ngIf="block.code == 'entities' && activeTab == 'entities'"
                                          [xsdData]="xsdData && xsdData.type === 'entities' ? xsdData : null"
                                          (onEditXsd)="editXsd($event)"></common-standard-entities>
                <!-- Проверочные листы -->
                <common-standard-check-lists *ngIf="block.code == 'checkLists' && activeTab == 'checkLists'"></common-standard-check-lists>

                <!-- Субъекты -->
                <common-standard-subjects [xsdData]="xsdData && xsdData.type === 'subject' ? xsdData : null"
                                          (onEditXsd)="editXsd($event)"
                                          *ngIf="block.code == 'subjects' && activeTab == 'subjects'"></common-standard-subjects>
                <!-- Объекты -->
                <common-standard-objects [xsdData]="xsdData && xsdData.type === 'object' ? xsdData : null"
                                         (onEditXsd)="editXsd($event)"
                                         *ngIf="block.code == 'objects' && activeTab == 'objects'"></common-standard-objects>
                <!-- Организации -->
                <common-standard-organizations *ngIf="activeTab == 'organizations' && block.code == 'organizations'"></common-standard-organizations>

                <!-- Приложения -->
                <common-standard-attachments *ngIf="activeTab == 'attachments' && block.code == 'attachments'"></common-standard-attachments>

                <!-- Реестровая модель -->
                <common-standard-registers-model *ngIf="activeTab == 'registersModel' && block.code == 'registersModel'"></common-standard-registers-model>



            </ng-container>

        </ng-container>

        <!-- Настройки дела -->
        <common-standard-appeal *ngIf="activeTab === 'appeal'"></common-standard-appeal>

        <!-- История изменений -->
        <history [collection]="standardsService.reglament ? 'responsibleOrganizationsReglaments' : 'standards'"
                 [id]="standardsService.reglament ? standardsService.reglament._id : standardsService.standard._id"
                 *ngIf="activeTab == 'history'"></history>

        <!-- Задачи процессов согласования и внесения правок -->
        <ng-container *ngIf="activeTab === 'tasks' && entity.camundaProcessInfo && entity.camundaProcessInfo.length > 0">
            <ng-container *ngFor="let process of entity.camundaProcessInfo; let fst = first; let idx = index; trackBy: trackedCamundaProcesses">
                <entity-process [processId]="process.id"
                                [processInfoId]="process.camundaBusinessInfoId"
                                [checkProcess]="process.status === 'ACTIVE'"
                                [process]="standardsService.bpmnProcess"
                                [processInEntity]="process.process"
                                [dataForProcessing]="process.dataForProcessing"
                                [entity]="entity"
                                [savedPlace]="process"
                                [completedEvents]="process.events"
                                [idx]="idx"
                                (onSaveEntity)="saveEntity()"
                                (afterCompleteProcess)="afterCompleteProcess(process)"
                                #processData></entity-process>
                <entity-process-container [number]="entity.camundaProcessInfo.length - idx"
                                          [isFirst]="fst"
                                          [commentsPlace]="process"
                                          [data]="processData.data">
                </entity-process-container>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="isEditXsd">
        <dynamic-form-constructor
            [xsdId]="xsdData.xsdField ? xsdData.data[xsdData.xsdField] : xsdData.data.xsd"
            [scrollTopPosition]="topPosition"
            [allowEdit]="xsdData.allowEdit"
            [variants]="entity.variants"
            [params]="xsdData.params"
            [excludeElements]="[]"
            (onSave)="completeEditXsd()"
            (onCreateXsd)="afterCreateXsd($event)">
        </dynamic-form-constructor>
    </ng-container>
</evolenta-scrollbar>
