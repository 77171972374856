import {
    AccessService,
    HttpService,
    RestService,
    SelectionService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import uniq from 'lodash-es/uniq';

import { CommonAppealSubservicesService } from '../../services/common-appeal-subservices.service';
import { CommonAppealActionsComponent } from '../appeal-actions/common-appeal-actions.component';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';
import { CommonAppealDocumentService } from '../appeal-documents/common-appeal-document.service';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { Permission } from '../../../../common/services/permission';
import { EntityProcessComponent } from '../../../common-processes/entity-process/entity-process.component';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonAppealBlocksComponent } from '../appeal-blocks/common-appeal-blocks.component';
import {
    EntityProcessEventsComponent,
} from '../../../common-processes/entity-process/events/entity-process-events.component';
import { EventsService } from '../../../events/events/events.service';
import { AppealService } from '../../../knm/appeals/appeal.service';
import { ErrorLoggingService } from '../../../knm/error-logging.service';
import { COMPLETED_TASK_STATUS, SUBSERVICES_COLLECTION } from '../../../../common/constants';

@Component({
    selector: 'common-appeal',
    templateUrl: 'common-appeal-edit.component.html',
    styleUrls: [
        '../../../element-edit.css',
    ],
})
export class CommonAppealEditComponent implements OnInit {
    public mode; // режим работы: редактирование, создание на основе услуги, создание на основе комплексной услуги
    public appeal: any = {}; // обрабатываемое дело
    public subservice: any = {}; // услуга на основе которой формируется дело
    public metaReglament; // мета-регламент, на основе которого создавалась услуга дела

    public printForms = []; // Массив печатных форм
    public printFormsWithoutDocs = []; // Массив печатных форм без тех, которые привязаны к видам документов дела

    public actions = []; // действия для дела
    public appealSubserviceActions = []; // действия для услуги дела
    public activeAction;
    public processingAction;

    public activeTab = 'common'; // Активная вкладка
    public currentOrganization = this.storage.getItem('currentOrganization'); // Текущая активная организация
    public currentUser = this.storage.getItem('user');

    @ViewChild('appealActions', { static: false }) public appealActionsComponent: CommonAppealActionsComponent; // действия дела
    @ViewChild('appealBlocks', { static: false }) public appealBlocksComponent: CommonAppealBlocksComponent; // действия дела

    @ViewChild('processData', { static: false }) public processData: EntityProcessComponent; // компонент с данными бизнес-процесса
    @ViewChild('processEvents', { static: false }) public processEventsComponent: EntityProcessEventsComponent; // компонент - события бизнес-процесса

    public permissions; // набор прав доступа к элементам системы

    public moduleBaseUrl;
    public baseUrl;

    public updateProcessTasksInterval;
    public requirementsCount = 0;

    public tabs = [];

    public workMode = 'default';
    public activeEvent;
    public denyInitActiveTab = false;

    public processingOperation;
    public localizations;

    public constructor(
        public validateService: CommonAppealValidateService,
        public appealService: AppealService,
        public commonAppealService: CommonAppealService,
        public commonAppealSaveService: CommonAppealSaveService,
        public accessService: AccessService,
        public httpService: HttpService,
        private route: ActivatedRoute,
        private appealSubservicesService: CommonAppealSubservicesService,
        private storage: StorageService,
        private toaster: ToasterService,
        private documentService: CommonAppealDocumentService,
        private rest: RestService,
        private appealStatusService: CommonAppealStatusService,
        private selectionService: SelectionService,
        private eventsService: EventsService,
        private location: Location,
        private translate: TranslateService,
        private errorLoggingService: ErrorLoggingService,
        private cdref: ChangeDetectorRef,
    ) {}

    /**
     * Инициализация компонента: обработка данных, полученных по resolve
     */
    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe(urlPath => {
            this.baseUrl = urlPath[urlPath.length - 1].path;
        });
        this.permissions = Permission; // описание всех доступных прав доступа
        // Определение режима работы: редактирование, создание на основе услуги, создание на основе комплексной услуги
        this.route.params.subscribe((params: any) => {
            if (params.appealId) {
                this.mode = 'edit';
            } else if (params.subserviceId) {
                this.mode = 'createOnSubservice';
            } else if (params.complexSubserviceId) {
                this.mode = 'createOnComplexSubservice';
            }
        });

        if (this.selectionService.isProcessSelect) {
            this.appeal = this.commonAppealService.appeal;
            this.subservice = this.commonAppealService.subservice;
            this.metaReglament = this.commonAppealService.metaReglament;

            if (this.selectionService.additionalData && this.selectionService.additionalData.viewMode) {
                this.workMode = this.selectionService.additionalData.viewMode;
            }

            this._initWorkMode();
            this._initAppealTabs();

            this.actions = this.appealStatusService.initAppealActions();
        } else {
            this._baseInitServiceData(); // Очистка данных сервиса (чтобы не было хвостов от работы с другими делами)
            // Получение и обработка первоначальных данных
            this.route.data.subscribe(
                async (response: any) => {
                    await this._initAppeal(response);
                },
                error => {
                    console.log(error);
                },
            );
        }
        this.cdref.detectChanges();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
                'organizations',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Получение и обработка первоначальных данных appeal
     */
    private async _initAppeal(response: any): Promise<void> {
        // Удаление базового дела
        this.commonAppealSaveService.baseAppeal = null;

        if (response.resolves.metaReglament) {
            this.metaReglament = response.resolves.metaReglament;
            this.commonAppealService.metaReglament = this.metaReglament;
            const appealData = this.metaReglament.blocks.find(item => item.code === 'appealData');
            this.commonAppealService.useXsdService = !!(!appealData || appealData && appealData.useXsdService);
        }

        this._initWorkMode();
        this.commonAppealService.initRoutingPaths(this.moduleBaseUrl);

        if (this.mode === 'createOnSubservice') {
            // Создание дела на основе отдельной услуги
            // Корректировка услуги в соответствии с текущей организацией
            const subservice = this.commonAppealService.correctSubserviceByCurrentUnit(response.resolves.subservice);

            this.subservice = subservice;
            this.commonAppealService.subservice = this.subservice;

            // Базовая инициализация дела
            this.appeal = this.commonAppealService.initAppeal();
            // Добавление каркаса описания объектов внутри услуги
            this.appeal.subservice = this.commonAppealService.initSubserviceInAppeal(subservice);

            // Инициализация данных в сервисах для работы с сервисом
            this._initServicesData();

            return;
        }

        if (this.mode !== 'edit') {
            return;
        }

        // Корректировка услуг дела в соответствии с текущей организацией
        if (response.resolves.subservice) {
            this.subservice = this.commonAppealService.correctSubserviceByCurrentUnit(response.resolves.subservice);

            this.commonAppealService.subservice = this.subservice;
        }

        /**
         * Редактирование дела
         */
        // Получение объекта дела
        this.appeal = response.resolves.appeal;
        this.commonAppealService.appeal = this.appeal;
        this.commonAppealSaveService.baseAppeal = cloneDeep(this.appeal);

        if (!this.appeal.events) {
            this.appeal.events = [];
        }

        if (response.resolves.printForms && response.resolves.printForms.length > 0) {
            this._processPrintForms(response.resolves.printForms, this.appeal, this.subservice);
        }

        // Инициализация данных в сервисах для работы с сервисом
        this._initServicesData();

        if (this.commonAppealService.activeTabInAppealBeforeCreate) {
            this.activeTab = this.commonAppealService.activeTabInAppealBeforeCreate;
            this.commonAppealService.activeTabInAppealBeforeCreate = null;
        }
    }

    private _initWorkMode() {
        if (this.metaReglament) {
            if (!this.metaReglament.isOldVersion) {
                const findAppealBlock = this.metaReglament.blocks.find(item => item.code === 'appealData');
                this.workMode = findAppealBlock && findAppealBlock.viewMode ? findAppealBlock.viewMode : 'default';
            } else {
                this.workMode = 'blocks';
            }
        } else {
            this.workMode = 'default';
        }
    }

    /**
     * Очистка (обнуление) данных сервисов для работы с делом
     */
    private _baseInitServiceData() {
        this.commonAppealService.clearData();
        this.appealStatusService.clearData();
        this.appealSubservicesService.clearData();
        this.documentService.clearData();
        this.validateService.clearData();
        this.commonAppealSaveService.clearData();
    }

    /**
     * Инициализация данных сервисов
     */
    private _initServicesData() {
        // Общие данные по делу: услуги, комплексные услуги

        this.commonAppealService.appeal = this.appeal;
        this.commonAppealService.subservice = this.subservice;
        this.commonAppealService.metaReglament = this.metaReglament;
        this.commonAppealService.allKnoUsers = null;
        this.commonAppealService.initAppealLinksData();

        // Сервис статусов дела и услуг внутри дела
        this.appealStatusService.initData(this.appeal, this.subservice);
        this.appealStatusService.metaReglament = this.metaReglament;
        this.actions = this.appealStatusService.initAppealActions();

        // Информация о настройке объектов в услугах
        this.appealSubservicesService.initData(this.appeal, this.subservice);

        // Сервис документов
        this.documentService.initData(this.appeal, this.subservice, this.metaReglament);

        // Сервис сохранения данных
        this.commonAppealSaveService.appeal = this.appeal;
        this.commonAppealSaveService.isProcessSavingAppeal = false;
        this.commonAppealSaveService.moduleBaseUrl = this.moduleBaseUrl;
        this.commonAppealSaveService.baseUrl = this.baseUrl;
        this.commonAppealSaveService.metaReglament = this.metaReglament;

        // Сервис валидации дела
        this.validateService.appeal = this.appeal;
        this.validateService.subservice = this.subservice;
        this.validateService.metaReglament = this.metaReglament;

        this._initAppealTabs();
        this.validateService.tabs = this.tabs;
    }

    // Данные активного процесса
    public get processInfo() {
        return this.appeal.subservice && this.appeal.subservice.camundaProcessInfo ? this.appeal.subservice.camundaProcessInfo : null;
    }

    // Описание бизнес-процесса в услуге
    public get processDataInSubservice() {
        const data = this.subservice.camundaProcess;
        data.tasks = data.userTasks;

        return data;
    }

    // Данные для исполнения процесса: услуги, вкладки доп.данных, группы документов
    public get processParamsInSubservice() {
        return {
            subservice: this.subservice,
            additionalDataTabs: this.subservice.xsdTabs,
            documentGroups: this.subservice.documentGroups,
            printForms: this.printForms,
            metaReglament: this.metaReglament,
            documentService: this.documentService,
        };
    }

    // Действие завершения бизнес-процесса
    public async afterCompleteProcess() {
        this.appeal.subservice.camundaProcessInfo.status = COMPLETED_TASK_STATUS;

        await this.commonAppealSaveService.saveAppeal();
    }

    /**
     * Переход дела на новый статус
     * @param data - объект вида {action: активируемое действие}
     */
    public executeAction(data) {
        // Обработка для конвертированных дел
        if (this.appeal.converted && !this.appeal.correctUnit) {
            this.currentOrganization = this.storage.getItem('currentOrganization');
            // Присваиваем значение unit
            this.appeal.correctUnit = true;
            this.appeal.unitId = this.currentOrganization._id;
            this.appeal.unit = {
                id: this.currentOrganization._id,
                name: this.currentOrganization.name,
                shortName: this.currentOrganization.shortName,
            };
        }
        this.processingAction = data.action;
        const needAppealValidate = this.processingAction.additionActions && this.processingAction.additionActions.indexOf('notValidate') === -1;
        this.validateService.validateAppeal(needAppealValidate);
        this._applyAppealData('executeAction');
    }

    /**
     * Продолжение процесса сохранения дела после применения редактируемых элементов дела
     */
    public async continueExecuteAction() {
        const needAppealValidate = this.processingAction.additionActions && this.processingAction.additionActions.indexOf('notValidate') === -1;
        const isAppealValid = this.validateService.validateAppeal(needAppealValidate);

        if (isAppealValid) {
            this.validateService.processValidate = false;

            try {
                await this.commonAppealSaveService.saveAppeal();
                await this._processExecuteAction();
            } catch (error) {
                this.toaster.error(error);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
            }
        } else {
            this._clearProcessExecuteActionData();
            const allErrors = this.validateService.getErrors();
            this.toaster.html(allErrors);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(allErrors));
        }
    }

    /**
     * Очистка элементов, отвечающих за визуальное отображение процесса выполнения действия
     */
    private _clearProcessExecuteActionData(clearProcessingAction = true) {
        this.processingAction = null;
        this.appealStatusService.currentExecuteAction = null;

        if (clearProcessingAction && this.appealActionsComponent) {
            this.appealActionsComponent.isProcessingAction = false;
        }
    }

    private _processPrintForms(printForms, appeal, subservice) {
        // Корректировка печатных форм в соответствии с типом дела (комплексное или нет) и формирование массива ПФ не привязанных к документам
        const filteredPrintForms = this.documentService.filterPrintForms(printForms, appeal, subservice);
        this.printForms = filteredPrintForms.printForms; // все используемые печатные формы
        this.commonAppealService.printForms = this.printForms;
        this.printFormsWithoutDocs = this.filterPrintFormsByAppealStatus(filteredPrintForms.printFormsWithoutDocuments); // печатные формы без документов
    }

    /**
     * Фильтрация списка печатных форм формируемых по кнопке "Печать" по статусу дела
     * @param printForms - начальный массив печатных форм
     */
    public filterPrintFormsByAppealStatus(printForms) {
        const result = [];
        let formTypes = ['dealHistory'];

        if (this.appeal.status && this.appeal.status.code === 'consultation') {
            formTypes.push('consultation');
        } else {
            if (this.appeal.status.code === 'draft') {
                formTypes.push('appeal');
            }

            if (this.appeal.status.code === 'process' || (this.appeal.status.mainCode && this.appeal.status.mainCode === 'process')) {
                formTypes.push('appeal');
                formTypes.push('bringRegister');
            }

            if (['beforeIssued', 'issued', 'issuedReturnToOGV', 'issuedOther', 'rejected'].indexOf(this.appeal.status.code) !== -1) {
                formTypes.push('issueRegister');
            }
        }

        if (this.commonAppealService.appealPayments && this.commonAppealService.appealPayments.length > 0) {
            formTypes.push('payment');
        }

        if (formTypes.length > 0) {
            formTypes = uniq(formTypes);

            printForms.forEach(form => {
                formTypes.forEach(type => {
                    if (form.formTypeCode === type) {
                        result.push(form);
                    }
                });
            });
        }

        return result;
    }

    /**
     * Подготовка к переходу на новый статус, заполнение дополнительных параметров для некоторых видов действий
     */
    public async prepareExecuteAction() {
        // Запуск процедуры обработки действия
        await this._processExecuteAction();
    }

    public prepareDataForExecuteAction() {
        if (this.appealBlocksComponent) {
            // return this.appealBlocksComponent.prepareDataForTransferToProcess();
        } else {
            return {};
        }
    }

    /**
     * Переход дела на новый статус
     * @param properties - объект с настройками доп.обработки действия
     */
    public async _processExecuteAction(properties: any = {}) {
        if (this.appeal.status.code === 'draft' && this.appeal.subjects.length > 0) {
            this.appeal.dataForExecuteAction.subjectType = this.appeal.subjects[0].specialTypeId;
        }

        try {
            const changeAppeal: any = await this.appealStatusService.executeAction(this.processingAction, properties);
            this.appeal.dataForExecuteAction = {};
            this._clearProcessExecuteActionData(false); // Очистка визуальных компонентов процесса выполнения действия

            // Если было осуществлено архивирование дела
            if (changeAppeal.status && changeAppeal.status.code === 'archive') {
                // Запрашиваем дело с сервера
                this.appeal = await this.rest.find('appealsArchive', changeAppeal._id);
                this._baseInitServiceData();
                this._initServicesData();
                this.commonAppealSaveService.baseAppeal = cloneDeep(this.appeal);
                this.toaster.success('Статус успешно изменен');
            } else {
                const changedAppealSubservice = cloneDeep(changeAppeal.subservice || changeAppeal.subservices[0]);
                this.documentService.appealSubservicesData[changedAppealSubservice.guid] = cloneDeep(changedAppealSubservice);

                delete changeAppeal.subservice;

                this.appeal = Object.assign(this.appeal, changeAppeal);
                this.actions = this.appealStatusService.initAppealActions();
                this.activeAction = null;
                await this.commonAppealService.saveHistoryItem(this.appeal.status, true);

                if (this.actions.length) {
                    this.activeAction = this.actions[0];
                }

                if (this.appeal.subservice) {
                    const appealSubService = this.appeal.subservice;

                    this.appeal.subservice = Object.assign(this.appeal.subservice, changedAppealSubservice);
                    this.appealSubservicesService.data[appealSubService.guid].isProcessExecuteAction = false;
                    // Корректировка доступных действий
                    this.appealSubservicesService.correctActions(appealSubService);
                }

                // Повторная инициализация документов
                this.documentService.initData(this.appeal, this.subservice, this.metaReglament);

                // Если дело переведено в статус "В обработке" или "Консультация" запускаем сохранение объектов в реестре клиентов
                if (this.appeal.status.code === 'process' || this.appeal.status.code === 'consultation') {
                    await this.commonAppealSaveService.saveObjectsInReestr();
                }

                if (this.appeal.status.code === 'complete') {
                    if (this.appeal.eventId) {
                        await this.eventsService.checkCompleteAllProcessInEvent(this.appeal.eventId);
                    }

                    if (Array.isArray(this.appeal.eventIds) && this.appeal.eventIds.length > 0) {
                        const processesCompleted = this.appeal.eventIds.map(eventId => this.eventsService.checkCompleteAllProcessInEvent(eventId));

                        await Promise.all(processesCompleted);
                    }
                }

                this.commonAppealSaveService.baseAppeal = cloneDeep(this.appeal);

                this.appeal = await this.commonAppealSaveService.saveAppeal();
                this.toaster.success('Статус успешно изменен');
                this._fetchCamundaBusinessInfo();

                // Обновление данных базового дела
                this.commonAppealSaveService.baseAppeal = cloneDeep(this.appeal);
            }

            if (this.appealActionsComponent) {
                this.appealActionsComponent.isProcessingAction = false;
            }

            if (this.appealBlocksComponent) {
                this.appealBlocksComponent.updateDataOnChange();
            }
        } catch (error) {
            this._clearProcessExecuteActionData(); // Очистка визуальных компонентов процесса выполнения действия
            this.toaster.error('При попытки смены статуса произошла ошибка. Статус не был изменен. Попробуйте совершить операцию еще раз!');
            await this.errorLoggingService.log(this.errorLoggingService.SPO, error, this.appeal);
        }
    }

    private async _fetchCamundaBusinessInfo() {
        let retries = 10;
        while (!this.appeal.subservice.camundaProcessInfo && retries) {
            retries--;
            this.appeal = await this.rest.find(this.appeal.subservice.appealsCollection || 'appeals', this.appeal._id);
            await new Promise(resolve => setTimeout(() => resolve(), 500));
        }
    }

    /**
     * Получение параметров для статуса дела
     * @param property
     */
    public getStatusProperty(property) {
        return this.appeal.status ? this.appealStatusService.getStatusProperty(this.appeal.status, property, this.appealStatusService.getStatuses()) : '';
    }

    public _initAppealTabs() {
        this.tabs = [];

        if (this.metaReglament) {
            // Настройки вкладок из мета-регламента
            if (this.metaReglament.isOldVersion && this.metaReglament.appealTabs) {
                this.tabs = this.metaReglament.appealTabs.map(item => ({ code: item.code, name: item.name }));
            } else {
                const find = this.metaReglament.blocks.find(item => item.code === 'appealData');
                if (find && find.appealTabs && find.appealTabs.length > 0) {
                    // find.appealTabs.push({code: 'subjects', name: 'Субъекты'}, {code: 'objects', name: 'Объекты'})
                    this.tabs = find.appealTabs.map(item => ({ code: item.code, name: item.name }));
                }
            }
        }

        // Вкладки дела по умолчанию
        if (this.tabs.length === 0) {
            this.tabs = [
                {
                    code: SUBSERVICES_COLLECTION,
                    name: 'Мероприятия',
                },
                {
                    code: 'subjects',
                    name: 'Субъекты',
                },
                {
                    code: 'objects',
                    name: 'Объекты',
                },
                {
                    code: 'entities',
                    name: 'Сведения',
                },
                {
                    code: 'documents',
                    name: 'Документы',
                },
                {
                    code: 'checkLists',
                    name: 'Проверочные листы',
                },
            ];
        }

        this.tabs.forEach(tab => {
            tab.errors = [];

            if (['subservices', 'subjects', 'objects', 'documents'].indexOf(tab.code) !== -1) {
                tab.checkErrors = true;
            }

            if (tab.code === 'subjects') {
                tab.useFunction = () => {
                    return this.appeal.status.code !== 'archive';
                };
            }

            if (tab.code === 'objects') {
                tab.useFunction = () => {
                    return this.appeal.status.code !== 'archive';
                };
            }

            if (tab.code === 'documents') {
                tab.useFunction = () => {
                    return this.appeal.status.code !== 'archive';
                };
                tab.additionalParams = {
                    bookmark: {
                        property: !!this.appeal.isHavingResult,
                        name: 'Необработанные ответы на запросы',
                    },
                };
            }

            if (tab.code === 'checkLists') {
                tab.useFunction = () => {
                    return this.subservice.additionalInfo.notUseCheckLists;
                };
            }
        });

        const epguApplicantBlock = {
            code: 'epguApplicant',
            name: 'Информация о заявителе',
            hide: true,
            useFunction: () => {
                return this.appeal.epguData && this.appeal.epguData.applicant;
            },
            visibleFunction: () => {
                return this.checkVisibleForActiveTab('epguApplicant');
            },
        };
        const completeElementsBlock = {
            code: 'completedProcessElements',
            name: 'Завершенные операции',
            hide: true,
            useFunction: () => {
                return this.visibleProcessElementsBlock('COMPLETED');
            },
            visibleFunction: () => {
                return this.checkVisibleForActiveTab('completedProcessElements');
            },
        };
        const historyBlock = {
            code: 'history',
            name: 'История',
            hide: true,
            useFunction: () => {
                return !!this.appeal._id;
            },
            visibleFunction: () => {
                return this.checkVisibleForActiveTab('history');
            },
        };

        this.tabs.forEach(tab => {
            if (!tab.hasOwnProperty('useFunction')) {
                tab.useFunction = () => {
                    return true;
                };
            }
            if (!tab.hasOwnProperty('visibleFunction')) {
                tab.visibleFunction = () => {
                    return this.checkVisibleForActiveTab(tab.code);
                };
            }
        });

        this.tabs = [
            {
                code: 'activeProcessElements',
                name: 'Активные операции',
                hide: true,
                useFunction: () => {
                    return this.visibleProcessElementsBlock('ACTIVE');
                },
                visibleFunction: () => {
                    return this.checkVisibleForActiveTab('activeProcessElements');
                },
            },
        ].concat(this.tabs);

        const findIndex = this.tabs.findIndex(item => item.code === 'common');

        if (findIndex !== -1) {
            this.tabs.splice(findIndex + 1, 0, epguApplicantBlock);
        }

        this.tabs.push(completeElementsBlock);
        this.tabs.push(historyBlock);

        if (!this.processData) {
            this.initActiveTab();
        }
    }

    public initActiveTab() {
        if (!this.denyInitActiveTab) {
            // определение активной вкладки
            if (this.selectionService.isProcessSelect) {
                this.denyInitActiveTab = true;
                if (this.selectionService.transferOperation === 'selectSubject') {
                    this.activeTab = 'subjects';
                } else if (this.selectionService.transferOperation === 'selectObject') {
                    this.activeTab = 'objects';
                } else if (this.selectionService.transferOperation === 'selectLicense') {
                    this.activeTab = 'common';
                }
            } else {
                if (this.visibleProcessElementsBlock('ACTIVE')) {
                    this.activeTab = 'activeProcessElements';
                } else if (this.tabs.find(item => item.code === 'common')) {
                    this.activeTab = 'common';
                } else if (this.tabs.find(item => item.code === 'subservices')) {
                    this.activeTab = 'subservices';
                }
            }
        }
    }

    public checkVisibleForActiveTab(blockCode): boolean {
        return this.workMode !== 'default' || this.activeTab === blockCode;
    }

    public visibleProcessElementsBlock(status): boolean {
        return this.processData
            && this.processData.data
            && this.processData.data.elements.filter(item => item.status === status).length > 0;
    }

    public async applyEvent() {
        if (this.processEventsComponent) {

            // Инициализация данных в сервисах для работы с сервисом
            await this.processEventsComponent.applyEvent();
        }
    }

    public async updateAppeal() {
        const appeal = await this.rest.find(this.appeal.subservice.appealsCollection || 'appeals', this.appeal._id);
        if (appeal) {
            this.appeal = merge(this.appeal, appeal);

            if (this.appealBlocksComponent) {
                await this.appealBlocksComponent.updateData();
            }

            this.appealStatusService.initAppealActions();
        }

        const savedData = {
            _id: this.processData.processInfoId,
            isEntryStatusChanged: false,
        };

        await this.rest.update('camundaBusinessInfo', savedData);
    }

    public back() {
        this.location.back();
    }

    public changeActiveTab(tab) {
        this.activeTab = tab;
    }

    public get externalSystemName(): string {
        return this.appeal.subservice
        && this.appeal.subservice
        && this.appeal.subservice.xsdData
        && this.appeal.subservice.xsdData.sendToERKNM ? 'ЕРКНМ' : 'ЕРП';
    }

    /**
     * Переключение активной вкладки
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        this.appealBlocksComponent.activateTab(tab);
    }

    /**
     * Сохранение дела (без валидации)
     */
    public saveAppeal() {
        this.commonAppealSaveService.appeal = this.appeal;
        this.commonAppealSaveService.appeal.subservice = this.commonAppealSaveService.appeal.subservice || this.subservice;
        this._applyAppealData('save');
    }

    /**
     * Применение данных дела:
     * - применение карточек редактируемых данных субъекта или объект
     * - сохранение статуса валидности форм дополнительных данных
     * - сохранение данных для передачи в бизнес процесс
     */
    private _applyAppealData(operation) {
        this.processingOperation = operation;
        this.appealBlocksComponent.applyBlocksData(operation);
    }

    public async afterApplyAppealData() {
        if (this.processingOperation === 'save') {

            await this.commonAppealSaveService.saveAppeal();
            this.toaster.success('Дело успешно сохранено');
        } else if (this.processingOperation === 'executeAction') {
            await this.continueExecuteAction();
        }
    }

    public onErrorsApplyAppealData() {
        if (this.processingOperation === 'executeAction') {
            this._clearProcessExecuteActionData(true);
        }
    }

    public async processingSaveAppeal() {
        this.commonAppealService.appeal.subservice = cloneDeep(this.appeal.subservice);
        this.commonAppealService.subservice = cloneDeep(this.subservice);
        await this.commonAppealSaveService.saveAppeal();
        this.processData.data.isProcessSaveEntity = false;
    }
}
