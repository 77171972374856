<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Административное производство</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указано</em>

            <span class="text-muted ml-10">Дата вынесения: </span>
            <ng-container *ngIf="entity.date && !entity.date.formatted">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date || entity.date && entity.date.formatted">не указано</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Номер</label>
                                <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" #number="ngModel" required>
                                <div *ngIf="isProcessValidate && number.errors" class="validation-error-label">
                                    <span *ngIf="number.errors.required">Поле "Номер" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата вынесения</label>
                                <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата вынесения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Место вынесения</label>
                                <evolenta-address-gar
                                        name="place"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.place"
                                        required
                                        #place="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && place.errors" class="validation-error-label">
                                    <span *ngIf="place.errors.required">Поле "Место вынесения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Причины, способствующие совершению административного правонарушения</label>
                                <textarea name="causesCrime" class="form-control" [(ngModel)]="entity.causesCrime" #causesCrime="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && causesCrime.errors" class="validation-error-label">
                                    <span *ngIf="causesCrime.errors.required">Поле "Причины, способствующие совершению административного правонарушения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
