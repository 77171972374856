import { SettingInterface } from '../../interfaces/setting/setting.interface';

export const settings: SettingInterface[] = [
    {
        name: 'filters',
        storageKey: 'filters.appeals',
        default: [],
        value: setting => setting.value.appeals,
    },
    {
        name: 'catalogueResultTypes',
        storageKey: 'resultTypes',
        default: [],
        value: setting => setting.value,
        isDictionary: true,
    },
    {
        name: 'catalogueIssueTypes',
        storageKey: 'issueTypes',
        default: [],
        value: setting => setting.value,
        isDictionary: true,
    },
    {
        name: 'nsiErpKnmForms',
        storageKey: 'nsiErpKnmForms',
        default: [],
        value: setting => setting.value,
        isDictionary: true,
    },
    {
        name: 'nsiErpKnmTypes',
        storageKey: 'nsiErpKnmTypes',
        default: [],
        value: setting => setting.value,
        isDictionary: true,
    },
    {
        name: 'defaultStatuses',
        storageKey: 'defaultStatuses',
        default: [],
        value: setting => setting.value,
    },
    {
        name: 'isDemoStend',
        storageKey: 'demoStend',
        default: { 'isDemo': false },
        value: setting => ({ 'isDemo': setting.value }),
    },
    {
        name: 'period-waiting-send-to-archive-appeal',
        storageKey: 'waitingPeriodSendToArchive',
        default: 0,
        value: setting => setting.value,
    },
    {
        name: 'integration.mkgu.application',
        storageKey: 'mkguInterviewApplication',
        default: 'none',
        value: setting => setting.value,
    },
    {
        name: 'complex.appeal.simplified.mode',
        storageKey: 'complexAppealSimplifiedMode',
        default: null,
        value: setting => setting.value,
    },
    {
        name: 'integration.quickrequests.settings',
        storageKey: 'quickRequestsSettings',
        default: null,
        value: setting => setting.value,
    },
];
