import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersBarComponent, RestService, SelectionService, StorageService, TranslateService } from '@evolenta/core';
import { Permission } from '../../../common/services/permission';

@Component({
    selector: 'object-types',
    templateUrl: 'object-types.component.html',
    styleUrls: ['../../elements-list.css'],
})
export class ObjectTypesComponent implements OnInit {
    @ViewChild('filtersPanel', { static: false }) private filtersPanel: FiltersBarComponent; // панель фильтров
    public objectTypes = []; // список видов объектов

    // Параметры просмотра
    public objectType; // выбранный для предспросмотра клиент
    public timer = null;
    public delay = 300;
    public prevent = false;
    public previewMode = false;

    public globalSearch;

    public filtersPanelItems = [];

    // Настройки сортировки
    public sort = {
        field: {
            code: 'dateCreation',
            name: '',
        },
        direction: 'DESC',
    };

    // Параметры пагинации
    public currentPage = 0; // текущая страница
    public totalCount = 0; // общее число клиентов в базе
    public totalPages = 0; // число страниц
    public permission;

    public currentOrganization = this.storage.getItem('currentOrganization');
    public moduleBaseUrl;
    public localizations;

    public constructor(
        public selectionService: SelectionService,
        private route: ActivatedRoute,
        private router: Router,
        private rest: RestService,
        private ref: ApplicationRef,
        private storage: StorageService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.permission = Permission;

        this.route.data.subscribe((response: any) => {
            if (this.selectionService.isProcessSelect) {
                response.resolves.forEach((item) => {
                    item.selected =
                        this.selectionService.selectedItems.filter((selectedItem) => {
                            return selectedItem._id === item._id;
                        }).length !== 0;
                    this.objectTypes.push(item);
                });
            } else {
                this.objectTypes = response.resolves;
            }
            this.totalCount = this.rest.pagination.total;
        });
    }

    private _loadTranslations() {
        this.translate.get(['common', 'events', 'objects', 'knd-kinds', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.filtersPanelItems = [
            {
                name: this.localizations.common.code,
                filters: [
                    {
                        fields: ['code'],
                        type: 'input',
                        placeholder: this.localizations.events.enter_code,
                    },
                ],
            },
        ];

        this.sort = {
            field: {
                code: 'dateCreation',
                name: this.localizations.common.creation_date,
            },
            direction: 'DESC',
        };
    }

    /**
     * Определение стилей оформления блока отдельного клиента с учетом того, выбран ли элемент для предпросмотра или нет
     * @param item - обрабатываемый элемент
     * @param last - признак последнего элемента
     * @returns {any} - набор классов для оформления
     */
    public blockStyle(item, last) {
        let classes = 'border-top border-top-primary short';
        if (last) {
            classes += ' border-bottom border-bottom-default';
        }
        return classes;
    }

    /**
     * Выбор клиента для отображения информации на панели просмотра
     * @param objectType
     */
    public click(objectType) {
        this.objectType = null;
        setTimeout(() => {
            this.objectType = objectType;
            this.timer = setTimeout(() => {
                if (!this.prevent) {
                    this.previewMode = true;
                    this.ref.tick();
                }
                this.prevent = false;
            }, this.delay);
        }, 500);
    }

    /**
     * Двойной клик на карточке - перевод в режим редактирования клиента
     * @param objectType
     */
    public dblClick(objectType) {
        if (this.selectionService.isProcessSelect) {
            objectType.selected = !objectType.selected;
            if (objectType.selected) {
                if (this.selectionService.selectType && this.selectionService.selectType === 'one') {
                    // сбрасываем флаг выбора у других элементов
                    this.objectTypes = this.objectTypes.map((item) => ({
                        ...item,
                        selected: item._id === objectType._id,
                    }));
                    this.selectionService.selectedItems = [objectType];
                } else {
                    this.selectionService.selectedItems.push(objectType);
                }
            } else {
                const findIndex = this.selectionService.selectedItems.findIndex((item) => item._id === objectType._id);

                if (findIndex !== -1) {
                    this.selectionService.selectedItems.splice(findIndex, 1);
                }
            }
        } else {
            clearTimeout(this.timer);
            this.prevent = true;
            this.router.navigate([this.moduleBaseUrl + '/object-types/edit', objectType._id]);
        }
    }

    /**
     * Поиск по нажатию клавиши Enter после ввода поискового запроса
     */
    public search(event?) {
        if (event) {
            if (event.keyCode === 13) {
                this.loadData('replace');
            }
        }
    }

    public returnToOperation() {
        if (this.selectionService.isProcessSelect) {
            this.router.navigate(this.selectionService.transferBackUrl);
        }
    }

    public clearSearch() {
        this.globalSearch = null;
        this.loadData('replace');
    }

    /**
     * Метод получения дел после фильтрации, либо скроллинга
     * @param type: replace - полное обновление списка; add - добавление записей в конец списка (скроллинг)
     */
    public loadData(type) {
        if (
            type === 'replace' ||
            (type === 'add' && ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages))
        ) {
            // Параметры настроенных фильтров
            const search = this.filtersPanel.prepareFilters();

            // Страница
            let page = 0;
            if (type === 'add') {
                page = this.currentPage + 1;
            }

            // Параметры сортировки
            const sort = this.sort.field.code + ',' + this.sort.direction;

            const params = {
                search: { search: search },
                size: 20,
                page: page,
                sort: sort,
            };

            // Обработка параметров глобального поиска
            if (this.globalSearch) {
                params.search['textSearch'] = this.globalSearch;
            }

            // Получение списка дел с сервера
            this.rest.search('objectTypes', params).then((objectTypes: any) => {
                if (type === 'replace') {
                    this.objectTypes = objectTypes;
                } else {
                    objectTypes.forEach((item) => {
                        this.objectTypes.push(item);
                    });
                }
                this.totalCount = this.rest.pagination.total;
                this.currentPage = this.rest.pagination.page;
                this.totalPages = this.rest.pagination.totalPages;
            });
        }
    }

    public create() {
        this.router.navigate([this.moduleBaseUrl + '/object-types/create']);
    }
}
