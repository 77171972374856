import { Injectable } from '@angular/core';
import { StorageService, RestService, UserMessagesService } from '@evolenta/core';
import { UniversalCollectionPageResolveInterface, UniversalCollectionPageResolveDataInterface } from '@evolenta/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Permission } from '../../../common/services/permission';

@Injectable()
export class NotificationsResolve implements UniversalCollectionPageResolveInterface {
    public permissions = Permission;

    public constructor(
        private restService: RestService,
        private storage: StorageService,
        private userMessagesService: UserMessagesService
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Promise<UniversalCollectionPageResolveDataInterface> {
        if (!this.userMessagesService.kinds) {
            return this.restService.search('notificationTypes').then((types) => {
                this.userMessagesService.kinds = types;
                return this.getData(route);
            });
        } else {
            return this.getData(route);
        }
    }

    public getData(route): Promise<UniversalCollectionPageResolveDataInterface> {
        const notificationId = route.params['notificationId'];

        if (notificationId) {
            return this.restService
                .find('notifications', notificationId)
                .then((notification) => Promise.resolve({ data: notification }));
        } else {
            const currentUser = this.storage.getItem('user');

            const searchNotificationsParams = [
                {
                    orSubConditions: [
                        { field: 'to.login', operator: 'eq', value: currentUser.login },
                        { field: 'to.id', operator: 'eq', value: currentUser._id },
                    ],
                },
                {
                    andSubConditions: [
                        {
                            orSubConditions: [
                                { field: 'notificationType', operator: 'eq', value: 'portal' },
                                { field: 'notificationType', operator: 'eq', value: null },
                            ],
                        },
                        { field: 'status', operator: 'neq', value: 'error' },
                        { field: 'status', operator: 'neq', value: 'readyToSend' },
                    ],
                },
            ];

            const searchMarkedNotificationsParams = [
                ...searchNotificationsParams,
                { field: 'status', operator: 'neq', value: 'read' },
                { field: 'status', operator: 'neq', value: 'error' },
                { field: 'status', operator: 'neq', value: 'readyToSend' },
            ];

            return this.restService
                .search('notifications', {
                    search: { search: searchMarkedNotificationsParams },
                    size: 1,
                    prj: 'onlyCode',
                })
                .then(() => {
                    const markedElementsCount = this.restService.pagination.total;

                    return this.restService
                        .search('notifications', {
                            search: { search: searchNotificationsParams },
                            sort: 'dateCreation,DESC',
                        })
                        .then((notifications) =>
                            Promise.resolve({
                                data: notifications,
                                markedElementsCount: markedElementsCount,
                            })
                        );
                });
        }
    }
}
