import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToasterService } from '@evolenta/core';
import { AppealEntityDamage } from './models/appeal-entity-damage';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-damage',
    templateUrl: 'appeal-entity-damage.component.html',
})
export class AppealEntityDamageComponent implements OnInit {
    @Input() public entity: AppealEntityDamage;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;

    public activeTab = 'common';
    public editTabs = [];

    public constructor(private toaster: ToasterService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.initEditTabs();
    }

    /**
     * Инициализация массива вкладок
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: 'Общие данные',
                active: true,
                visible: true,
            },
            {
                code: 'violations',
                name: 'Нарушения',
                active: false,
                visible: true,
            },
        ];
    }

    /**
     * Установка активной вкладки
     * @param tab
     */
    public activateTab(tab) {
        this.activeTab = tab;
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        return this.entityForm.valid;
    }
}
