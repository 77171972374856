<!-- Выбрать тип объекта для "Финконтроля" -->
<div class="modal fade"
     bsModal
     tabindex="-1"
     role="dialog"
     aria-hidden="true"
     (onHide)="controlActionsOnSubjectService.setControlAction(null)"
     #selectingObjectTypeModal='bs-modal'>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title pull-left">
                    {{ localizations.subjects.control_object_data }}
                </h3>
                <button type="button"
                        class="close pull-right"
                        aria-label="Close"
                        (click)="selectingObjectTypeModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #form="ngForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations.subjects.object_control_type }}
                                    <span class="text-danger">*</span>
                                </label>
                                <catalogue catalogName="fCObjectTypes"
                                                    name="typeControlObject"
                                                    required
                                                    [placeholder]="localizations.subjects.object_control_type"
                                                    [priority]="true"
                                                    [large]="true"
                                                    [(ngModel)]="currentTypeOfControlObject"
                                                    #typeControlObject="ngModel"></catalogue>
                                <div class="validation-error-label"
                                     *ngIf="typeControlObject.invalid && (typeControlObject.touched || typeControlObject.dirty)">
                                    <span *ngIf="typeControlObject.hasError('required')">
                                        {{ localizations.processes.field_is_required }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations.subjects.higher_authority }}
                                </label>
                                <catalogue nameField="header"
                                                    [catalogName]="'subjects'"
                                                    [projection]="'subjectsList'"
                                                    [returnFields]="['_id', 'header', 'shortHeader']"
                                                    [name]="'parentControlObject'"
                                                    [baseSearch]="getParentControlObjectBaseSearch()"
                                                    [(ngModel)]="parentOfControlObject"
                                                    [placeholder]="localizations.subjects.higher_authority"
                                                    [priority]="true"
                                                    [showCode]="false"
                                                    [large]="true">
                                </catalogue>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button"
                        (click)="controlActions(form)"
                        class="btn btn-primary ml-5">
                    {{ localizations.common.save }}
                </button>
                <button type="button"
                        class="btn btn-default pull-right"
                        (click)="selectingObjectTypeModal.hide()">
                    {{ localizations.common.cancel }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Действия с субъектом -->
<div class="modal fade"
     bsModal
     tabindex="-1"
     role="dialog"
     aria-hidden="true"
     (onHide)="controlActionsOnSubjectService.resetSearch(); controlActionsOnSubjectService.setControlAction(null)"
     #relatedObjectsModal='bs-modal'>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="controlActionsOnSubjectService?.relatedObjects?.length"
                    class="modal-title pull-left">
                    {{ localizations.subjects.disable_control }}
                </h4>
                <h4 class="modal-title pull-left"
                    *ngIf="!controlActionsOnSubjectService?.relatedObjects?.length">
                    {{ getDisableControlMessage() }}
                </h4>
                <button class="close pull-right"
                        type="button"
                        aria-label="Close"
                        (click)="relatedObjectsModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="controlActionsOnSubjectService?.relatedObjects?.length">
                    <h5 class="modal-title mb-10">
                        {{ localizations.subjects.connected }}
                    </h5>
                    <div class="form-group mb-10"
                         *ngIf="controlActionsOnSubjectService?.relatedObjects?.length > 1">
                        <div class="input-group input-group-lg">
                            <input class="form-control input-lg"
                                   type="text"
                                   [placeholder]="localizations.objects.enter_name_dots"
                                   [(ngModel)]="controlActionsOnSubjectService.searchString">
                            <div class="input-group-btn">
                                <button class="btn btn-default"
                                        type="button"
                                        (click)="controlActionsOnSubjectService.resetSearch()">
                                    <strong class="icon-cross2"></strong>
                                </button>
                            </div>
                        </div>
                    </div>
                    <evolenta-scrollbar [maxHeight]="'650px'">
                        <div class="list-2">
                            <ng-template ngFor
                                         let-object
                                         [ngForOf]="controlActionsOnSubjectService.searchObjects()">
                                <div class="mb-10">
                                    <h6 class="">
                                        {{ object.name }}
                                    </h6>
                                </div>
                                <div class="list-separator ml-0 mr-0">&nbsp;</div>
                            </ng-template>
                        </div>
                    </evolenta-scrollbar>
                </div>
            </div>
            <div class="modal-footer">
                <div class="display-flex justify-space-between">
                    <div>
                        <button class="btn btn-info mr-10"
                                type="button"
                                (click)="controlActionsOnSubjectService.removeEntityFromControl(true)">
                            {{ getTargetMessage() }}
                        </button>
                        <button *ngIf="controlActionsOnSubjectService?.relatedObjects?.length"
                                type="button"
                                class="btn btn-success"
                                (click)="controlActionsOnSubjectService.removeEntityFromControl(false)">
                            {{ getRelatedTargetMessage() }}
                        </button>
                    </div>

                    <button type="button"
                            class="btn btn-default"
                            (click)="relatedObjectsModal.hide()">
                        {{ localizations.common.cancel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
