<div [ngClass]="{'pl-20 pr-20': mode == 'edit'}">
    <!-- Ошибки при валидации карточки услуги -->
    <ng-template #errorsTemplate><span class="text-semibold">Ошибки услуги:</span><ul [innerHtml]="getErrorsList()" class="no-margin"></ul></ng-template>
    <button class="btn btn-danger pull-right btn-badge"
            *ngIf="checkErrorsExist()"
            [popover]="errorsTemplate"
            [outsideClick]="true"
            placement="left">ошибки</button>

    <!-- Активный подстатус -->
    <p class="mb-3" *ngIf="appealSubservice.status.isSubstatus">
        <span class="text-muted">Активный подстатус: </span> {{appealSubservice.status.name}}
        <span class="text-size-small" *ngIf="appealSubservice.status.description">({{appealSubservice.status.description}})</span>
    </p>

    <ng-container *ngIf="subservice.version === 'knd'">
        <ng-container *ngIf="appealSubservice.kndInfo">
            <p class="hide">
                <span class="text-muted">Тип КНМ: </span> {{appealSubservice.kndInfo.kndType?.name}}
                <span class="text-muted ml-10">Форма проведения: </span> {{appealSubservice.kndInfo.kndForm?.name}}
                <ng-container *ngIf="appealSubservice.kndInfo.typeFederalLaw">
                    <span class="text-muted ml-10">Федеральный закон: </span> {{appealSubservice.kndInfo.typeFederalLaw.name}}
                </ng-container>
            </p>
            <ng-container *ngIf="appealSubservice.kndInfo.kndKinds">
                <p>
                    <span class="text-muted">Виды КНД: </span>
                    <span *ngIf="appealSubservice.kndInfo.kndKinds.length == 0" class="text-muted text-size-small">не выбраны</span>
                    <span *ngIf="appealSubservice.kndInfo.kndKinds.length == 1">{{appealSubservice.kndInfo.kndKinds[0].name}}</span>
                </p>
                <ul *ngIf="appealSubservice.kndInfo.kndKinds.length > 1">
                    <li *ngFor="let item of appealSubservice.kndInfo.kndKinds">{{item.name}}</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="appealSubservice.sendToZkh && appealSubservice.kndInfo.nsiZkhKnmKinds_65">
                <p>
                    <span class="text-muted">Вид КНД ЖКХ: </span>
                    <span>{{appealSubservice.kndInfo.nsiZkhKnmKinds_65[0].name}}</span>
                </p>
            </ng-container>
            <!--        <p *ngIf="!appealSubservice.kndInfo.kndKinds && appealSubservice.kndInfo.kndKind">-->
            <!--            <span class="text-muted">Вид КНД: </span> {{appealSubservice.kndInfo.kndKind?.name}}-->
            <!--        </p>-->
            <ng-container *ngIf="!subservice.additionalInfo.notUseCheckLists">
                <ng-container *ngIf="appealSubservice.kndInfo.basisKnm && appealSubservice.kndInfo.basisKnm.length > 0">
                    <p>
                        <span class="text-muted">Административные регламенты по осуществлению КНД: </span>
                        <span *ngIf="appealSubservice.kndInfo.basisKnm.length == 1">{{appealSubservice.kndInfo.basisKnm[0].name}}</span>
                    </p>
                    <ul *ngIf="appealSubservice.kndInfo.basisKnm.length > 1">
                        <li *ngFor="let item of appealSubservice.kndInfo.basisKnm">{{item.name}}</li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="appealSubservice.kndInfo.npa && appealSubservice.kndInfo.npa.length > 0">
                    <p>
                        <span class="text-muted">Правовые основания проведения проверки: </span>
                        <span *ngIf="appealSubservice.kndInfo.npa.length == 1">{{appealSubservice.kndInfo.npa[0].name}}</span>
                    </p>
                    <ul *ngIf="appealSubservice.kndInfo.npa.length > 1">
                        <li *ngFor="let item of appealSubservice.kndInfo.npa">{{item.name}}</li>
                    </ul>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container>
            <div>
                <span class="text-muted">Контрольно-надзорные органы, совместно с которыми производится проверка: </span>
                <span *ngIf="!appealService.appeal.jointlyKno || appealService.appeal.jointlyKno.length == 0"
                      class="text-slate text-size-small">не выбраны</span>
                <span *ngIf="appealService.appeal.jointlyKno && appealService.appeal.jointlyKno.length == 1">{{appealService.appeal.jointlyKno[0].name}}</span>
            </div>
            <ul *ngIf="appealService.appeal.jointlyKno && appealService.appeal.jointlyKno.length > 1">
                <li *ngFor="let item of appealService.appeal.jointlyKno">{{item.name}}</li>
            </ul>
        </ng-container>

        <p>
            <span class="text-muted">Инспекторы: </span>
            <span class="text-slate text-size-small" *ngIf="!appealSubservicesService.appeal.inspectors || appealSubservicesService.appeal.inspectors.length == 0">не назначены</span>
            <ng-container *ngFor="let inspector of appealSubservicesService.appeal.inspectors; let lst = last">{{inspector.name}} <span class="ml-5 text-slate" *ngIf="inspector.position">({{inspector.position}})</span><ng-container *ngIf="!lst">, </ng-container></ng-container>
        </p>

        <p>
            <span class="text-muted">Эксперты (экспертные организации): </span>
            <span class="text-slate text-size-small" *ngIf="!appealSubservicesService.appeal.experts || appealSubservicesService.appeal.experts.length == 0">не назначены</span>
            <ng-container *ngFor="let expert of appealSubservicesService.appeal.experts; let lst = last">{{expert.name}} <ng-container *ngIf="expert.organization">({{expert.organization}})</ng-container><ng-container *ngIf="!lst">, </ng-container></ng-container>
        </p>
    </ng-container>

    <ng-container *ngIf="subservice.mainElement">
        <fieldset>
            <legend class="text-bold">{{subservice.mainElement.name}}</legend>
            <p *ngIf="appealSubservice.mainElement && appealSubservice.mainElement.operationType !== 'registration'">
                <span class="text-bold mr-5">Предыдущая лицензия:</span>
                <em *ngIf="!appealSubservice.mainElement.registerId">не выбрана</em>
                <ng-container *ngIf="appealSubservice.mainElement.registerInfo">№{{appealSubservice.mainElement.registerInfo.auid}} от {{appealSubservice.mainElement.registerInfo.dateCreation | date: 'shortDate'}}</ng-container>
                <a class="text-size-small anchor text-info ml-10" *ngIf="appealSubservice.mainElement.registerId"
                   [routerLink]="['/', 'licensing-activity', appealService.registersPath, 'edit', appealSubservice.mainElement.registerId]">перейти</a>
            </p>
            <dynamic-form
                *ngIf="data.mainElementXsd && appealSubservice.mainElement"
                [model]="appealSubservice.mainElement"
                [xsd]="data.mainElementXsd"
                [disabled]="true">
            </dynamic-form>
            <p class="text-muted" *ngIf="data.mainElementXsd && !appealSubservice.mainElement">данные не заполнены</p>
        </fieldset>
    </ng-container>


    <!-- Наличие Дополнительных данных по услуге / варианту -->
    <p *ngIf="(tabWithAdditionalData || appealSubservice.variantXsd) && mode == 'view'">
        <i class="icon-exclamation text-info"></i> <span class="text-semibold">Внимание!</span> КНМ содержит <span class="anchor text-info" (click)="activateTab(tabWithAdditionalData)">дополнительные поля</span>
        <span class="text-success ml-10"
              *ngIf="(appealSubservice.xsd && appealSubservice.xsdDataValid || !appealSubservice.xsd)
                      && (appealSubservice.variantXsd && appealSubservice.variantXsdDataValid || !appealSubservice.variantXsd)">(данные заполнены корректно)</span>
    </p>



    <!-- Участники / объекты -->
    <ng-container *ngIf="getSubjectsCount() > 0 && !hideSubjectField">
        <p class="mb-3 text-muted">Субъекты:</p>

        <ng-container *ngIf="subjects.length > 0">
            <ul class="no-margin-bottom">
                <ng-container *ngFor="let subject of subjects">
                    <li *ngIf="data.subjects[subject.guid].active">

                        <!-- Header Объекта, вид участия, представитель -->
                        <div class="display-inline-block mb-3">

                            <!-- Наименование объекта, вид участия -->
                            <span class="text-semibold">{{subject.header}}</span>
                            <!--<span *ngIf="data.objects[object.guid].subKind"> - {{data.objects[object.guid].subKind.firstGroupName}}</span>-->

                            <!-- Группа, категория -->
                            <div class="text-size-small display-inline-block ml-5" *ngIf="data.subjects[subject.guid].group">
                                <span class="text-muted">(</span>
                                <span class="text-muted">Группа: </span> {{data.subjects[subject.guid].group?.name}}
                                <span class="text-muted ml-5">Категория: </span>
                                <ng-container *ngIf="data.subjects[subject.guid].categories">
                                    <ng-container *ngFor="let category of data.subjects[subject.guid].categories; let lst = last">
                                        {{category.name}}<span *ngIf="!lst" class="ml-5 mr-5">/</span>
                                    </ng-container>
                                </ng-container><span class="text-muted">)</span>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </ng-container>

    <!-- Учетный номер, дата КНМ (ЕРП)  -->
    <ng-container *ngIf="subservice.version === 'knd'">
        <div *ngIf="appeal && appeal.numberErp">
            <span class="text-muted">Учетный номер КНМ (ЕРП): </span>
            <span>{{appeal.numberErp}}</span>
        </div>
        <div *ngIf="appeal && appeal.receiveDateErp">
            <span class="text-muted">Дата получения учетного номера КНМ (ЕРП): </span>
            <span>{{appeal.receiveDateErp | date: 'mediumDate'}}</span>
        </div>
    </ng-container>
</div>
