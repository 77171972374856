import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { CommonAppealValidateService } from '../../../services/common-appeal-validate.service';
import { CommonAppealSubservicesService } from '../../../services/common-appeal-subservices.service';
import { CommonAppealService } from '../../../services/common-appeal.service';
import { InternalHandlersService } from '../../../../../common/services/internal-handlers.service';
import { HandlersService } from '@evolenta/core';

@Component({
    selector: 'common-appeal-entity-additional-data',
    templateUrl: 'common-appeal-entity-additional-data.component.html',
})
export class CommonAppealEntityAdditionalDataComponent implements OnInit, AfterViewInit {
    @Input() public entity: any; // Субъект
    @Input() public type; // Субъект или объект
    @Input() public mode = 'view'; // Режим работы: просмотр карточки, редактирование данных
    @Input() public appeal; // Формируемое дело
    @Input() public subservice;
    @Input() public permissions;

    public appealSubservice;
    public subservicesData;
    public showAdditionalData = false;
    public subserviceXsd;
    public subserviceData;

    public subserviceEntityData;
    public isProcessValidate = false;
    public externalData;

    public subjectTypeXsd;
    public subjectTypeSavedPlace;

    @ViewChild('mainAdditionalData', { static: false }) public mainAdditionalData: DynamicFormComponent;
    @ViewChild('additionalData', { static: false }) public additionalData: DynamicFormComponent;
    @ViewChild('subserviceAdditionalData', { static: false }) public subserviceAdditionalData: DynamicFormComponent;
    @ViewChild('subjectTypeAdditionalData', { static: false }) public subjectTypeAdditionalData: DynamicFormComponent;

    public constructor(
        public validateService: CommonAppealValidateService,
        public appealService: CommonAppealService,
        private appealSubservicesService: CommonAppealSubservicesService,
        private internalHandlerService: InternalHandlersService,
        private handlersService: HandlersService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (this.mode === 'edit') {
            this.showAdditionalData = true;
        }
        if (this.type === 'subject' && this.subservice.objects.subjectsData) {
            this.subserviceEntityData = this.subservice.objects.subjectsData;
        } else if (this.type === 'object' && this.subservice.objects.objectsData) {
            this.subserviceEntityData = this.subservice.objects.objectsData;
        }

        this.appealSubservice = this.appeal.subservice;
        if (
            this.appealSubservicesService.data[this.appealSubservice.guid] &&
            this.appealSubservicesService.data[this.appealSubservice.guid].xsd
        ) {
            this.subserviceXsd = this.appealSubservicesService.data[this.appealSubservice.guid].xsd;
            if (!this.appealSubservicesService.data[this.appealSubservice.guid].xsdData) {
                this.appealSubservicesService.data[this.appealSubservice.guid].xsdData = {};
            }
            this.subserviceData = this.appealSubservicesService.data[this.appealSubservice.guid].xsdData;
        }
        this.subservicesData = this.appealSubservicesService.data;
        if (
            this.subserviceEntityData &&
            !this.entity.xsdData &&
            (this.subserviceEntityData.mainXsd || this.subserviceEntityData.xsd)
        ) {
            this.entity.xsdData = {};
        }
        this.externalData = { appeal: this.appeal, entity: this.entity };

        if (this.subserviceEntityData) {
            if (this.subserviceEntityData.xsd && this.subserviceEntityData.mainXsdHandlers) {
                this.runHandlers(this.subserviceEntityData.mainXsdHandlers);
            }
            if (this.subserviceEntityData.xsd && this.subserviceEntityData.xsdHandlers) {
                this.runHandlers(this.subserviceEntityData.xsdHandlers);
            }

            if (this.type === 'subject') {
                const findSubjectTypeData = this.subserviceEntityData.subjectTypes.find(
                    (item) => item.code === this.entity.specialTypeId
                );
                if (findSubjectTypeData && findSubjectTypeData.xsd) {
                    this.subjectTypeXsd = findSubjectTypeData.xsd;
                    this.subjectTypeSavedPlace =
                        ['ulApplicant', 'foreignUlApplicant'].indexOf(this.entity.specialTypeId) !== -1
                            ? this.entity.data.organization
                            : this.entity.data.person;
                }
            }
        }
    }

    public ngAfterViewInit() {
        if (this.validateService.processValidate) {
            setTimeout(() => {
                this.startValidate();
            }, 1000);
        }
    }

    public runHandlers(handlers) {
        const usedHandlers = handlers.filter((item) => item.event === 'onStart');
        if (usedHandlers.length > 0) {
            this.internalHandlerService
                .processingHandlers(usedHandlers, 'startBefore', 'onStart', this.externalData)
                .then(() => {
                    usedHandlers.forEach((handler) => {
                        this.handlersService.runHandler(handler, this.externalData);
                    });
                });
        }
    }

    public startValidate() {
        if (this.mainAdditionalData) {
            this.mainAdditionalData.validate();
        }

        if (this.additionalData) {
            this.additionalData.validate();
        }
        if (this.subjectTypeAdditionalData) {
            this.subjectTypeAdditionalData.validate();
        }
    }

    public validate() {
        this.isProcessValidate = true;
        this.startValidate();
        this.entity.mainXsdDataValid =
            (this.mainAdditionalData && this.mainAdditionalData.isValid) || !this.mainAdditionalData;
        this.entity.xsdDataValid = (this.additionalData && this.additionalData.isValid) || !this.additionalData;
        this.entity.subjectTypeXsdDataValid =
            (this.subjectTypeAdditionalData && this.subjectTypeAdditionalData.isValid) ||
            !this.subjectTypeAdditionalData;
    }

    public isValid() {
        return this.entity.mainXsdDataValid && this.entity.xsdDataValid && this.entity.subjectTypeXsdDataValid;
    }
}
