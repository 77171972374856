import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AccessService,
    FiltersService,
    ModalDialogComponent,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { AppealValidateService } from '../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../appeal-subservices.service';
import { AppealStatusService } from '../../../appeal-status.service';
import { Permission } from '../../../../../../common/services/permission';
import { CopyService } from '../../../../../../common/services/copy.service';
import { PersonService } from '../../../../../../common/services/persons.service';
import { ModalUserComponent } from '../../../../../subjects/modal-user/modal-user.component';
import { AppealObjectAdditionalDataComponent } from '../../objects/object-additional-data/appeal-object-additional-data.component';
import { AppealService } from '../../../appeal.service';
import { IndividualObjectData } from '../../../../../subjects/individual-object/components/individual-applicant-form/common/individual-object.data';
import { SubjectFormComponent } from '../../../../../subjects/subject-form/subject-form.component';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';

@Component({
    selector: 'appeal-subject-card',
    templateUrl: 'appeal-subject-card.component.html',
})
export class AppealSubjectCardComponent implements OnInit {
    @Input() public subject: any; // Субъект
    @Input() public compareSubject: any; // Субъект дела (для сравнения)
    @Input() public availableKinds; // Доступные для выбора виды объектов
    @Input() public mode = 'view'; // Режим работы: просмотр карточки, редактирование данных
    @Input() public activeTab; // Активная вкладка
    @Input() public appeal; // Формируемое дело
    @Input() public subservice: any = {}; // описание услуг, на основании которых формируется дело

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onInitEdit = new EventEmitter<object>();
    @Output() public onDelete = new EventEmitter<object>();
    @ViewChild(ModalDialogComponent, { static: false }) public modalDialogComponent: ModalDialogComponent;

    public modalMessage; // Текст сообщения для модального окна
    public modalTitle; // Заголовок сообщения для модального окна
    public modalOperation; // Вид операции (возврат к списку или удаление объекта)

    public editTabs; // Массив вкладок для доступа к редактируемым блокам

    public isEditObjectKind = false; // Флаг режима выбора вида объекта
    public isEditObjectSubKind = false; // Флаг режима выбора типа объекта
    public globalParams = IndividualObjectData; // Общие константы для работы с объектом
    public continueProcessingAppeal; // флаг продолжения операции сохранения (перехода на другой статус) дела после применения изменений в услуги

    @ViewChild('subjectForm', { static: false }) public subjectFormComponent: SubjectFormComponent;
    @ViewChild('additionalData', { static: false })
    public appealObjectAdditionalDataComponent: AppealObjectAdditionalDataComponent;

    public allowEdit = true; // Возможность редактирования объекта
    public allowDelete = true; // Возможность удаления объекта

    public permissions = Permission; // Набор прав системы

    public searchText;
    public foundSubjects = [];
    public localizations;
    @ViewChild(ModalUserComponent, { static: false }) public modalUserComponent: ModalUserComponent; // модальное окно для выода списка найденных

    public constructor(
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        private appealSubservicesService: AppealSubservicesService,
        private storageService: StorageService,
        public validateService: AppealValidateService,
        private elementRef: ElementRef,
        private copyService: CopyService,
        private toaster: ToasterService,
        private personsService: PersonService,
        private restService: RestService,
        private appealService: AppealService,
        private translate: TranslateService,
        private filtersService: FiltersService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        // Если нет полномочия на редактирование в любом статусе
        if (!this.accessService.existPermission(this.permissions.No_Edit_Limits)) {
            // Запрещено редактирование, если дело находится в финальном статусе, или зарегистрировано
            this.allowEdit =
                this.appealStatusService.allowEdit &&
                ((this.subject.mainId &&
                    this.accessService.hasAccess([this.permissions.Appeal_Object_Update], false, this.appeal.status)) ||
                    (!this.subject.mainId &&
                        this.accessService.hasAccess(
                            [this.permissions.Appeal_Object_Create],
                            false,
                            this.appeal.status
                        )));
            this.allowDelete =
                this.appealStatusService.allowEdit &&
                this.appealStatusService.appeal.status.code !== 'process' &&
                this.accessService.hasAccess([this.permissions.Appeal_Object_Delete], true, this.appeal.status);
        }

        // Если у объекта не выбран вид, то инициализация режима выбора вида объекта
        if (!this.subject.kind) {
            this.isEditObjectKind = true;
        }
        // Если не выбран тип объекта, то инициализация режима выбора типа объекта
        if (this.subject.kind && !this.subject.kind.subKind && this.subject.kind.subKinds) {
            this.isEditObjectSubKind = true;
        } else if (!this.subject.data.person) {
            this.subject.data.person = {};
        }

        // Инициализация вкладки по умолчанию с блоками редактирования информации
        if (!this.activeTab) {
            if (this.subject.specialTypeId === 'realty') {
                this.activeTab = 'realty'; // объект недвижимости
            } else {
                this.activeTab = 'common'; // объект - физическое лицо или ИП
            }
            if (this.mode === 'edit' && !this.allowEdit && this.checkAdditionalDataExist()) {
                this.activeTab = 'additionalData';
            }
        }

        // Инициализация табов с блоками для редактирования информации объекта
        this.initEditTabs();

        // Прослушивание события вставки данных
        this.elementRef.nativeElement.addEventListener('paste', (event) => {
            this.pasteFromBuffer(event);
        });
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.subjects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация массива вкладок
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'realty',
                name: 'Настройки объекта',
                visible: this.subject.specialTypeId === 'realty' && this.allowEdit,
            },
            {
                code: 'subservice',
                name: 'Услуга',
                visible:
                    this.allowEdit &&
                    this.subject.kind &&
                    ((this.subject.kind.subKinds &&
                        this.subject.kind.subKind &&
                        !this.subject.kind.subKind.onlyForPrincipal) ||
                        !this.subject.kind.subKinds),
            },
            {
                code: 'common',
                name: 'Общие данные',
                visible: this.subject.specialTypeId !== 'realty' && this.allowEdit,
            },

            {
                code: 'additionalData',
                name: 'Доп.данные',
                visible: this.checkAdditionalDataExist(),
            },
        ];
        this.editTabs.forEach((tab) => {
            tab.active = tab.code === this.activeTab;
        });
    }

    /**
     * КНМ события, что в данный момент осуществляется добавление нового пользователя, а не редактируется имеющийся (режим работы "Дело")
     * @returns {boolean}
     */
    public checkIsAddNewSubject() {
        return (
            (this.subject.specialTypeId === 'ulApplicant' && !this.subject.data.organization.name) ||
            (this.subject.specialTypeId !== 'ulApplicant' &&
                !(
                    this.subject.data.person &&
                    (this.subject.data.person.lastName ||
                        this.subject.data.person.firstName ||
                        this.subject.data.person.middleName)
                ))
        );
    }

    /**
     * Обработка события нажания на Enter при поиске
     * @param event
     */
    public async keypress(event?) {
        if (event && event.keyCode === 13) {
            await this.searchSubject(true);
        }
    }

    public async searchSubject(fromGlobalRegistry = false) {
        const subjects = await this.personsService.searchSubject(
            this.subject.specialTypeId,
            this.searchText,
            fromGlobalRegistry
        );
        const transformedSubjects = await this.filtersService.processingLinkedData(
            'subjectsKno',
            subjects,
            'subjectLink',
            'globalSubjectId',
            'subjectsList'
        );
        if (!transformedSubjects.length) {
            this.toaster.info('Совпадения не найдены');

            return;
        }

        this.foundSubjects = transformedSubjects;
        this.modalUserComponent.showModal();
    }

    public async selectSubject(subject) {
        const foundSubject: any = await this.restService.find('subjects', subject._id);
        const selectedSubject = {
            reestrId: foundSubject._id,
            auid: foundSubject.auid,
            data: foundSubject.data,
            header: foundSubject.header,
            shortHeader: foundSubject.shortHeader,
        };
        this.subject = Object.assign(this.subject, selectedSubject);
    }

    /**
     * КНМ наличия дополнительных данных для объекта
     * @returns {boolean}
     */
    public checkAdditionalDataExist() {
        let isAdditionalDataExist = false;
        Object.keys(this.appealSubservicesService.data).forEach((appealSubserviceGuid) => {
            if (
                this.appealSubservicesService.data[appealSubserviceGuid].subjects &&
                this.appealSubservicesService.data[appealSubserviceGuid].subjects[this.subject.guid] &&
                this.appealSubservicesService.data[appealSubserviceGuid].subjects[this.subject.guid].xsd
            ) {
                isAdditionalDataExist = true;
            }
        });

        return isAdditionalDataExist;
    }

    /**
     * Функция формирования массива подвидов объектов
     * @param kind
     * @returns {Array}
     */
    public getSubKinds(kind) {
        const find = this.availableKinds.find((item) => item.guid === kind.guid);

        return find.subKinds;
    }

    /**
     * Выбор вида объекта
     * @param kind - вид объекта из списка доступных для выбора
     */
    public selectKind(kind) {
        this.subject.kind = Object.assign({}, kind);
        if (this.subject.kind && this.subject.kind.subKinds) {
            if (this.subject.kind.subKinds.length === 1) {
                // Если в выбранном виде объекта только один доступный для выбора тип, то сразу инициализируем его
                this.subject.kind.subKind = Object.assign({}, this.subject.kind.subKinds[0]);
                this.subject.specialTypeId = this.subject.kind.subKind.specialTypeId;
                this.initTabsData(this.subject.kind.subKinds[0]);
                this.isEditObjectKind = false;
            } else {
                // Инициализация режима выбора типа объекта
                this.isEditObjectKind = false;
                this.isEditObjectSubKind = true;
                this.subject.kind.subKind = null;
            }
            // Корректировка данных объекта в услуге дела (группа, вид участия, категория)
            this.checkCorrectObjectDataInAppealSubservices();
            delete this.subject.kind.subKinds;
        } else {
            this.subject.specialTypeId = kind.specialTypeId;
            this.isEditObjectKind = false;
        }
    }

    public initTabsData(subKind) {
        if (this.subject.specialTypeId === IndividualObjectData.legalPersonCode && !this.subject.data.organization) {
            this.subject.data.organization = {};
            this.subject.data.personInOrg = { position: null, authority: null }; // Инициализация представителя организации
        } else if (this.subject.specialTypeId === 'realty') {
            this.subject.data.realty = {};
        } else if (!subKind.onlyForPrincipal) {
            delete this.subject.data.organization;
            delete this.subject.data.personInOrg;
        }

        this.activeTab = this.prepareActiveTab(subKind);
        this.initEditTabs();
    }

    /**
     * КНМ корректности ранее выбранных данных (группы, вида участия, категории)
     */
    public checkCorrectObjectDataInAppealSubservices() {
        if (!(this.appeal.subservice.objects && this.appeal.subservice.objects.length)) {
            return;
        }

        const currentObjectDataIndex = this.appeal.subservice.objects.findIndex(
            (item) => item.guid === this.subject.guid
        );
        if (currentObjectDataIndex === -1) {
            return;
        }

        const currentObjectData = this.appeal.subservice.objects[currentObjectDataIndex];
        if (this.subservice._id !== this.appeal.subservice.id) {
            throw new Error('Сабсервис не найден');
        }
        if (!currentObjectData.groupGuid) {
            return;
        }

        const group = this.subservice.objects.objectGroups.find((item) => item.guid === currentObjectData.guid);
        const kind = group.objectKindGuids.find((item) => item.guid === this.subject.kind.guid);
        // Был выбран вид объекта, который не подходит для ранее выбранной группы участника

        if (kind) {
            return;
        }

        // очищаем все настройки участия (группа, категория, вид участия), оставляя только включение участия
        this.appeal.subservice.objects[currentObjectDataIndex] = { guid: currentObjectData.guid };
    }

    /**
     * Выбор типа объекта: ФЛ, ЮЛ, ИП
     */
    public changeSubKind(sub) {
        const beforeSubKind = cloneDeep(this.subject.kind.subKind);
        this.subject.kind.subKind = sub;
        if (this.subject.kind && this.subject.kind.subKind) {
            this.isEditObjectSubKind = false;
            // В случае изменения
            if (!beforeSubKind || beforeSubKind.name !== this.subject.kind.subKind.name) {
                this.subject.specialTypeId = this.subject.kind.subKind.specialTypeId;
                if (!this.subject.data.person) {
                    this.subject.data.person = {};
                }
                this.initTabsData(sub);
                this.reInitObjectPresentInfoInAppealService();
                this.appealSubservicesService.initSubjectFieldsRequirements(this.subject);
            }
        } else {
            this.subject.specialTypeId = null;
        }
    }

    /**
     * Переинициализация данных по участию в услугах
     */
    public reInitObjectPresentInfoInAppealService() {
        Object.keys(this.appealSubservicesService.data).forEach((appealSubserviceGuid) => {
            if (
                this.appealSubservicesService.data[appealSubserviceGuid].objects &&
                this.appealSubservicesService.data[appealSubserviceGuid].objects[this.subject.guid] &&
                this.appealSubservicesService.data[appealSubserviceGuid].objects[this.subject.guid].active &&
                this.appeal.subservice.guid === appealSubserviceGuid
            ) {
                this.appealSubservicesService.getAvailableServiceGroups(this.appeal.subservice, this.subject);
            }
        });
    }

    /**
     * КНМ возможности редактирования вида объекта
     * @returns {boolean}
     */
    public canEditKind() {
        return this.mode === 'edit' && this.availableKinds.length > 1;
    }

    /**
     * Инициализация режима редактирования вида объекта
     */
    public editKind() {
        if (this.canEditKind()) {
            this.isEditObjectKind = true;
        }
    }

    /**
     * КНМ возможности редактирования типа объекта
     * @returns {boolean}
     */
    public canEditSubKind() {
        if (this.mode === 'edit') {
            const kind = this.availableKinds.find((item) => item.type === this.subject.kind.type);
            if (kind && kind.subKinds.length > 1) {
                return true;
            }
        }

        return false;
    }

    /**
     * Инициализация режима редактирования типа объекта
     */
    public editSubKind() {
        if (this.canEditSubKind()) {
            this.isEditObjectSubKind = true;
        }
    }

    /**
     * Передача инициализации режима редактирования объекта в родительский компонент (переход из режима просмотра в режим редактирования)
     * @param tab - активируемая вкладка
     */
    public initEdit(tab) {
        this.activeTab = tab;
        this.onInitEdit.emit({ subjectGuid: this.subject.guid, tab: this.activeTab });
    }

    /**
     * Инициализация режима редактирования услуги в деле (нажатие кнопки "Редактировать")
     */
    public edit() {
        this.onEdit.emit(this.subject);
        this.appealService.editing = true;
    }

    /**
     * Применение изменений внесенных в объект (нажатие кнопки "Применить")
     * @param continueProcessingAppeal - продолжить сохранение (или перевод на другой статус) дела после применения изменений
     */
    public apply(continueProcessingAppeal = false) {
        this.continueProcessingAppeal = continueProcessingAppeal;
        // если текущая вкладка "Доп.информация", то нужно сохранить данные
        if (this.activeTab === 'additionalData') {
            this.appealObjectAdditionalDataComponent.save(null, true);
        } else {
            this.onApply.emit({ subject: this.subject, continueProcessingAppeal: continueProcessingAppeal });
        }

        this.appealService.editing = false;
    }

    /**
     * Отмена изменений внесенных в услугу дела (нажатие кнопки "Отменить")
     */
    public cancel() {
        this.onApply.emit(false);
        this.appealService.editing = false;
    }

    /**
     * Возврат к списку объектов - КНМ на возможные изменения
     */
    public back() {
        if (!isEqual(this.subject, this.compareSubject)) {
            this.modalMessage = this.localizations['appeals.subjects'].apply_changes_list;
            this.modalTitle = this.localizations['common'].save_changes;
            this.modalOperation = 'processingBack';
            this.modalDialogComponent.showModal();
        } else {
            this.apply();
        }
    }

    /**
     * Обработка действий из модального окна: применение изменений, либо отмена
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingBack(data) {
        if (data === 'yes') {
            this.apply();
        } else if (data === 'no') {
            this.cancel();
        }
    }

    /**
     * Активация определенной вкладки при редактировании объекта
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        if (this.activeTab === 'additionalData') {
            // Если текущая вкладка "Доп. инфо", то перед переходом на новую сохраняем значения
            this.appealObjectAdditionalDataComponent.save(tab);
        } else {
            this.activeTab = tab;
            if (this.mode === 'view') {
                this.onInitEdit.emit({ subjectGuid: this.subject.guid, tab: this.activeTab });
            }
        }
    }

    /**
     * Передача управления ответа на нажатие кнопок модального окна нужному методу
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Инициализация вызова модального окна для подтверждения удаления объекта
     */
    public deleteSubject() {
        const header = this.subject.header || this.localizations['appeals.subjects'].default_header;

        this.modalMessage = this.localizations['appeals.subjects'].confirm_delete.replace('%s', header);
        this.modalTitle = this.localizations['appeals.subjects'].deletion;
        this.modalOperation = 'processingDelete';
        this.modalDialogComponent.showModal();
    }

    /**
     * Обработка результата нажатия кнопок модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingDelete(data) {
        if (data === 'yes') {
            this.onDelete.emit(this.subject);
        }
    }

    /**
     * Совершение дополнительных действий после сохранения json-формы на вкладке "Доп.данные"
     * @param data - объект {tab: активация определенной вкладки; apply: переход к режиму сохранения}
     */
    public afterSaveAdditionalData(data) {
        // Переход на другую вкладку
        if (data.tab) {
            this.activeTab = data.tab;
        }
        // Сохранение изменений
        if (data.apply) {
            this.onApply.emit({ subject: this.subject, continueProcessingAppeal: this.continueProcessingAppeal });
        }
    }

    /**
     * КНМ валидности объекта
     * @returns {boolean}
     */
    public checkObjectValid() {
        return this.validateService.checkElementValid(this.subject.guid, 'subjects');
    }

    /**
     * Копирование данных объекта в буфер
     */
    public copyToBuffer() {
        this.copyService.copyData(this.subject.data, 'object');
    }

    /**
     * Вставка данных из буфера, замена данных объекта данными из буфера
     * @param event
     */
    public pasteFromBuffer(event) {
        if (this.mode === 'edit') {
            const copyData = this.copyService.getDataFromClipboard(event, 'object');
            if (copyData) {
                this.subject.data = cloneDeep(copyData);
            }
        }
    }

    public getFieldsRequirementsRules(): any {
        let result = null;

        if (this.subservice.fieldRequirements && this.subservice.fieldRequirements.length > 0) {
            const foundSubKind = this.subservice.objects.objectKinds[0].subKinds.find(
                (item) => item.specialTypeId === this.subject.specialTypeId
            );

            if (foundSubKind && foundSubKind.category && foundSubKind.category.length) {
                const foundReq = this.subservice.fieldRequirements.find(
                    (item) => item.guid === foundSubKind.category[0].fieldsRequirements
                );

                result = foundReq ? foundReq : null;
            }
        }

        return result;
    }

    private prepareActiveTab(subKind: any): string {
        if (this.subject.specialTypeId === 'realty') {
            return 'realty';
        }

        if (subKind.onlyForPrincipal) {
            return 'common';
        }

        return 'subservice';
    }
}
