import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard } from '@evolenta/core';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'system-settings',
        component: SidebarComponent,
        data: { module: 'system-settings' },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class SettingsRouting {
}
