<ng-container>
    <div class="pt-20 pl-20 pr-20">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of globalTabs">
                <li [ngClass]="{'active': activeGlobalTab == tab.code}"
                    (click)="activateGlobalTab(tab.code)">
                    {{tab.name}}
                </li>
            </ng-container>
        </ul>
    </div>
</ng-container>

<div class="panel panel-shadow m-20">
    <ng-container *ngIf="activeGlobalTab === 'common'">
        <div class="panel-body">

            <!--  Организация регламента  -->
            <p *ngIf="standardsService.reglament">
                <span class="text-muted">
                    {{ localizations.common.organization }}:
                </span>
                <span class="text-bold">
                    {{standardsService.reglament.unit.name}}
                </span>
            </p>

            <!--  Наименование стандарта / регламента  -->
            <div class="form-group form-group-lg">
                <label class="width-100">
                    {{ localizations.common.name }}
                    <!--  Статус блока в регламенте -->
                    <common-standard-block-status *ngIf="standardsService.reglament && nameBlock.allowEditInReglament"
                                                  [block]="nameBlock"
                                                  [small]="true">
                    </common-standard-block-status>

                    <!--  Комментарии к процессу согласования -->
                    <common-standard-process-comments [block]="nameBlock"
                                                      (saveEntity)="standardsService.saveEntity(nameBlock)">
                    </common-standard-process-comments>
                </label>
                <input class="form-control"
                       type="text"
                       [(ngModel)]="nameBlock.entity.name"
                       [disabled]="!nameBlock.allowEdit">
            </div>
            <div class="form-group form-group-lg">
                <label>
                    {{ localizations.common.short_name }}
                </label>
                <input class="form-control"
                       type="text"
                       [(ngModel)]="nameBlock.entity.shortName"
                       [disabled]="!nameBlock.allowEdit">
            </div>

            <!-- Информационные блоки с кастомными формами -->
            <ng-container *ngFor="let block of blocks">
                <ng-container *ngIf="!block.notUseInList">
                    <common-standard-block-with-additional-data *ngIf="block.xsd"
                                                                [block]="block">
                    </common-standard-block-with-additional-data>
                </ng-container>
            </ng-container>

            <div class="checkbox-block no-padding-top pb-20">
                <input id="allowAutoCreateAppeal"
                       class="checkbox"
                       type="checkbox"
                       [checked]="standard.allowAutoCreateAppeal"
                       (change)="standard.allowAutoCreateAppeal = !standard.allowAutoCreateAppeal">
                <label for="allowAutoCreateAppeal">Разрешено автоматическое создание дел (например, с ЕПГУ)</label>
            </div>

            <!-- Настройка дополнительных данных -->
            <div *ngIf="metaData.commonData && metaData.commonData.allowShowInListSetting"
                 class="checkbox-block">
                <input id="notShowInList"
                       class="checkbox"
                       type="checkbox"
                       [checked]="standard.notShowInList"
                       (change)="standard.notShowInList = !standard.notShowInList">
                <label for="notShowInList">Не отображать в списке услуг</label>
            </div>

            <!-- Настройка дополнительных данных -->
            <p *ngIf="metaData.commonData && metaData.commonData.allowCreateXsd"
               class="mt-20" >
                <span class="text-bold">
                    {{ getAdditionalFieldsSetUpStatusMessage(standard.xsd) }}
                </span>
                <span *ngIf="standardsService.allowEditStandard || standard.xsd"
                      class="text-size-small anchor text-info ml-10"
                      (click)="editXsd('xsd')">
                    {{ !standard.xsd ?
                    localizations.common.setup :
                    standardsService.allowEditStandard ?
                        localizations.common.change_small :
                        localizations.common.view_small }}
                </span>
                <span *ngIf="standard.xsd && standardsService.allowEditStandard"
                      class="text-size-small anchor text-slate ml-10"
                      (click)="standard.xsd = null">
                    {{ localizations.common.delete_small }}
                </span>
                <evolenta-handlers [model]="standard"
                          field="xsd"
                          [setStartEvent]="true"
                          [setChangeEvent]="true"></evolenta-handlers>
            </p>

            <!-- Настройка дополнительных данных для дела -->
            <p *ngIf="metaData.commonData && metaData.commonData.allowCreateAppealXsd"
               class="mt-20" >
                <span class="text-bold">
                     {{ getAdditionalFieldsSetUpStatusMessage(standard.appealXsd) }}
                </span>
                <span *ngIf="standardsService.allowEditStandard || standard.appealXsd"
                      class="text-size-small anchor text-info ml-10"
                      (click)="editXsd('appealXsd')">
                     {{ !standard.xsd ?
                    localizations.common.setup :
                    standardsService.allowEditStandard ?
                        localizations.common.change_small :
                        localizations.common.view_small }}
                </span>
                <span *ngIf="standard.appealXsd && standardsService.allowEditStandard"
                      class="text-size-small anchor text-slate ml-10"
                      (click)="standard.appealXsd = null">
                    {{ localizations.common.delete_small }}
                </span>
                <evolenta-handlers [model]="standard"
                          [field]="'appealXsd'"
                          [setStartEvent]="true"
                          [setChangeEvent]="true"></evolenta-handlers>
            </p>
        </div>
    </ng-container>

    <ng-container *ngIf="activeGlobalTab === 'stages'">
        <div class="panel-heading">
            <h4 class="panel-title">
                <span>{{ localizations.standards.periods.periods_settings }}</span>
            </h4>
            <div class="heading-elements">
                <button
                    class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="savePeriods()"
                    [disabled]="periodsIsEditing"
                >{{ localizations.common.apply }}</button>
            </div>
        </div>

        <div class="panel-body">
            <div class="form-group">
                <input id="autoFill"
                       class="checkbox form-control"
                       type="checkbox"
                       name="setDefault"
                       [(ngModel)]="periodsData.periods.autoFill">
                <label class="control-label"
                       for="autoFill">
                    {{ localizations.standards.periods.execution_control }}
                </label>
            </div>

            <ng-container *ngIf="periodsData.processOptions.length">
                <div
                    class="form-group form-group-lg"
                    style="width: 700px;">
                    <label class="text-bold">{{ localizations.standards.periods.select_subprocess }}</label>
                    <ng-select
                        [allowClear]="false"
                        [items]="periodsData.processOptions"
                        [multiple]="false"
                        (selected)="selectCurrentProcess($event.id)"
                        [disabled]="periodsIsEditing"
                        [active]="getSelectedProcess()"
                        required>
                    </ng-select>
                </div>
            </ng-container>

            <common-standard-periods
                [initData]="periodsData"
                (confirmChanges)="editPeriodsLocal($event)"
                (changeEditingState)="periodsIsEditing = $event"
            ></common-standard-periods>
        </div>
    </ng-container>
</div>
