import { UniversalCollectionPageOptionsInterface } from '@evolenta/core';
import { DatePipe } from '@angular/common';
import { SystemTasksService, SystemTaskPreviewComponent } from '@evolenta/system-tasks-module';
import cloneDeep from 'lodash-es/cloneDeep';

export const SystemTasksListOptions: UniversalCollectionPageOptionsInterface = {
    elementsName: 'Служебные задачи',
    isSelectingAllowed: false,
    isExternalSearchAllowed: false,
    isMarkingAllowed: false,
    isUseItemButtonAllowed: false,
    isCreateButtonAllowed: true,
    createElementText: 'Новая задача',
    getCanCreateElementFunc: () => true,
    getCreateElementRouteFunc: (moduleUrl) => `/${moduleUrl}/system-tasks/create`,
    getEditElementRouteFunc: (moduleUrl, sectionUrl, element) => `/${moduleUrl}/system-tasks/edit/${element._id}`,
    getCollectionNameFunc: () => 'systemTasks',
    defaultSort: 'dateCreation,DESC',
    getFilterNameFunc: () => 'systemTasksFilter',
    getFilterItemsFunc: () => cloneDeep(SystemTasksService.FiltersPanelItems),
    getBaseSearchFunc: (context) => [
        { field: 'unit.id', operator: 'eq', value: context.storage.getItem('currentOrganization')._id },
    ],
    getPreviewComponentFactoryFunc: (resolver) => resolver.resolveComponentFactory(SystemTaskPreviewComponent),
    listItemOptions: {
        status: {
            isShownPrimary: true,
            getPrimaryPropertyFunc: (element, property) =>
                SystemTasksService.GetStatusProperty(element.status, property),
            isShownSecondary: false,
        },
        title: {
            isShownId: true,
            getIdFunc: (element) => `№ ${element.auid}`,
            isShownTitle: true,
            getTitleFunc: (element) => element.name,
        },
        details: [
            {
                title: 'Дата создания',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateCreation, 'shortDate'),
            },
        ],
    },
};
