<div bsModal #reestrModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title pull-left">Сохранение клиентов в реестре</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="alert alert-info">
                    <span class="text-semibold">ВНИМАНИЕ!</span>
                    При сохранении данных в реестре клиентов были найдены множественные записи для <span *ngIf="findedObjects.length == 1"> участника</span><span *ngIf="findedObjects.length > 1">{{findedObjects.length}}-x участников</span>.
                    Для корректной обработки данных в реестре <span class="text-semibold">выберите нужное действие</span><span *ngIf="findedObjects.length > 1"> для каждого из них</span>:
                </div>

                <div class="panel panel-default text-size-small" *ngFor="let item of findedObjects">
                    <div class="panel-heading p-10">
                        <h6 class="panel-title">
                            {{item.object.data.person.lastName}} {{item.object.data.person.firstName}} {{item.object.data.person.middleName}}, {{item.object.data.person.birthday.formatted}} г.р.
                            <br><small>{{item.object.data.person.documentType[0].text}}
                            <span *ngIf="item.object.data.person.documentSeries">серия {{item.object.data.person.documentSeries}}</span>
                            № {{item.object.data.person.documentNumber}}, выдан {{item.object.data.person.documentIssueDate.formatted}} <span *ngIf="item.object.data.person.documentIssuer">{{item.object.data.person.documentIssuer.name}}</span></small>
                        </h6>
                    </div>
                    <div class="panel-body p-10">
                        <div class="radio-block" *ngFor="let find of item.findedPersons">
                            <input type="radio" name="object{{item.object.guid}}" class="radio" id="object{{item.object.guid}}{{find.guid}}" [value]="find" [(ngModel)]="processedObjects[item.object.guid].object">
                            <label for="object{{item.object.guid}}{{find.guid}}">
                                <span class="text-semibold">{{find.data.person.lastName}} {{find.data.person.firstName}} {{find.data.person.middleName}}</span>, {{find.data.person.birthday.formatted}} г.р. <br>
                                <span *ngIf="find.data.person.documentType"> {{find.data.person.documentType[0].text}} серия {{find.data.person.documentSeries}} № {{find.data.person.documentNumber}}, выдан {{find.data.person.documentIssueDate.formatted}} <span *ngIf="find.data.person.documentIssuer">{{find.data.person.documentIssuer.name}}</span></span>
                            </label>
                        </div>
                    </div>
                    <div class="panel-footer no-padding-top no-padding-bottom pl-10 pr-10">
                        <div class="heading-elements">
                            <div class="heading-text no-margin">
                                <div class="radio-inline">
                                    <input type="radio" class="radio" name="object-action{{item.object.guid}}" id="object-action{{item.object.guid}}-update" value="update"
                                           [disabled]="!processedObjects[item.object.guid].object" [(ngModel)]="processedObjects[item.object.guid].action">
                                    <label for="object-action{{item.object.guid}}-update"> обновить данными из текущего дела</label>
                                </div>
                                <div class="radio-inline">
                                    <input type="radio" class="radio" name="object-action{{item.object.guid}}" id="object-action{{item.object.guid}}-create" value="create"
                                           [disabled]="!processedObjects[item.object.guid].object" [(ngModel)]="processedObjects[item.object.guid].action">
                                    <label for="object-action{{item.object.guid}}-create"> создать нового клиента</label>
                                </div>
                                <div class="radio-inline">
                                    <input type="radio" class="radio" name="object-action{{item.object.guid}}" id="object-action{{item.object.guid}}-leave" value="leave"
                                           [(ngModel)]="processedObjects[item.object.guid].action">
                                    <label for="object-action{{item.object.guid}}-leave"> не обрабатывать</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">Отмена</button>
                <button type="button" class="btn btn-default" (click)="apply()">Применить</button>
            </div>
        </div>
    </div>
</div>