import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealEntityBuyActDistance } from './models/appeal-entity-buy-act-distance';
import { SelectService } from '@evolenta/core';

@Component({
    selector: 'appeal-entity-buy-act-distance',
    templateUrl: 'appeal-entity-buy-act-distance.component.html',
    styleUrls: ['appeal-entity-buy-act-distance.component.css'],
})
export class AppealEntityBuyActDistanceComponent implements OnInit {
    @Input() public entity: AppealEntityBuyActDistance;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.inspector) {
            this.entity.inspector = this.getCreationUser();
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    /**
     * Автор КНМ
     */
    public getCreationUser() {
        return {
            guid: CommonUtilities.GenerateGuid(),
            name: [
                {
                    id: this.appeal.userCreation.id,
                    text: this.appeal.userCreation.name,
                },
            ],
            position: null,
        };
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
