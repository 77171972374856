<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-primary btn-xs btn-main no-border-radius btn-labeled btn-labeled-right"
            type="button"
            (click)="createNewTask()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations.tasks.new }}
    </button>

    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   type="text"
                   [placeholder]="localizations.common.search_dots"
                   [(ngModel)]="globalSearch"
                   (keypress)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData('replace')">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<div class="page-container">
    <!-- Secondary sidebar -->
    <!-- Заголовок страницы -->
    <div class="sidebar sidebar-secondary sidebar-default">
        <evolenta-scrollbar scrollClass="sidebar-content no-padding">
            <div class="category-title">
                <h4 class="display-inline-block no-margin">
                    <span class="text-semibold">
                        {{ localizations.tasks.plural }}
                        <span class="badge badge-primary text-uppercase ml-10">
                            {{tasks.length}}
                        </span>
                    </span>
                </h4>
                <ul class="icons-list hide">
                    <li>
                        <i class="icon-cog3"></i>
                    </li>
                </ul>
            </div>

            <filters-bar [filters]="filtersPanelItems" [filterName]="'tasksFilter'" [base]="baseSearch" [afterHeader]="true"
                           (onApplyFilters)="loadData('replace')" #filtersPanel></filters-bar>

        </evolenta-scrollbar>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                            (scrolled)="loadData('add')">
                            <ng-container *ngIf="tasks.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of tasks; let lst = last">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                            [ngClass]="blockStyle(item, lst)">
                                            <div class="list-item full-width"
                                                 (click)="click(item)"
                                                 (dblclick)="dblClick(item)">

                                                <badge
                                                    *ngIf="item.notification"
                                                    [title]="item.notification.title"
                                                    [type]="item.notification.type"
                                                ></badge>

                                                <div class="text-left">
                                                    <div class="list-item-status-rectangle text-uppercase display-inline-block"
                                                         *ngIf="item.priority"
                                                         [ngClass]="getPropertyByStatus(item, 'background')">
                                                        {{ getPropertyByStatus(item, 'name') }}
                                                    </div>
                                                    <div class="list-item-status-rectangle text-uppercase display-inline-block ml-10"
                                                         [ngClass]="{'ml-10': item.priority, 'bg-info-300': item.status === 'ACTIVE', 'bg-success-300': 'COMPLETED'}">
                                                        {{ item.status === 'ACTIVE' ? localizations.common.active : localizations.common.completed }}
                                                    </div>
                                                </div>

                                                <div class="list-item-data">
                                                    <h6 class="text-bold no-margin">
                                                        {{item.name}}
                                                    </h6>
                                                    <p class="no-margin-bottom ellipsis">
                                                        <span class="text-muted mr-5"
                                                              *ngIf="item.created">
                                                            {{ localizations.common.created_f }}
                                                        </span>
                                                        {{item.created | date}}
                                                        <ng-container *ngIf="item.due">
                                                            <span class="text-muted mr-5 ml-10">
                                                                {{ localizations.tasks.complete_till }}
                                                            </span>
                                                            <ng-container>
                                                                {{item.due | date}}
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.assignee">
                                                            <span class="text-muted mr-5 ml-10">
                                                                {{ localizations.tasks.responsible_for_tasks }}
                                                            </span>
                                                            <ng-container>
                                                                {{getAssigneeUser(item.assignee)}}
                                                            </ng-container>
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="panel m-15 p-15"
                                 *ngIf="tasks.length == 0">
                                <div class="panel-body text-center">
                                    <div class="icon-object border-info-400 text-info">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.tasks.not_found }}
                                    </h5>
                                    <ng-container *ngIf="setFilter">
                                        <p class="mg-15">
                                            {{ localizations.common.try_to_adjust_filter }}
                                        </p>
                                        <button class="btn bg-info-400"
                                                (click)="search(true)">
                                            {{ localizations.common.reset_filters }}
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-info alert-styled-left alert-bordered no-margin-bottom m-30"
                         *ngIf="!task">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.tasks.click_element_in_list_for_details}}
                    </div>
                    <tasks-preview *ngIf="task"
                                   [task]="task"
                                   [knoUsers]="knoUsers">
                    </tasks-preview>
                </div>
            </div>
        </div>
    </div>
</div>
