<div [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit'}">
    <div class="panel-heading"
         *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}} № {{entity.number}}
            <ng-container *ngIf="entity.date">
                {{localizations.common.from}} {{entity.date | date:'shortDate'}}
            </ng-container>
            <span class="text-info anchor text-size-small ml-5"
                  *ngIf="mode == 'edit' && allowEdit"
                  (click)="isChangeNumberAndDate = true">
                {{ localizations.common.change_small }}
            </span>
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">
                    {{ localizations['appeals.entities'].unscheduled_inspection }}
                </span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">
                    {{ localizations['appeals.entities'].scheduled_inspection }}
                </span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn"
                    [title]="localizations.common.edit"
                    *ngIf="mode == 'view'"
                    (click)="edit()">
                <b>
                    <i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                </b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit' && allowEdit"
                    (click)="apply()">
                {{ localizations.common.apply }}
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit'"
                    (click)="cancel()">
                {{ localizations.common.close }}
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">
        <!-- Карточка предпросмотра -->
        <div [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}"
             *ngIf="mode == 'view'">
            <p class="mb-3">
                <span class="text-muted">
                    {{ localizations.entities.document_number }}:
                </span>
                <ng-container *ngIf="entity.number">
                    {{entity.number}}
                </ng-container>
                <em *ngIf="!entity.number">
                    {{ localizations.common.not_specified_m }}
                </em>

                <span class="text-muted ml-10">
                    {{ localizations.entities.date }}:
                </span>
                <ng-container *ngIf="entity.date && !entity.date.formatted">
                    {{entity.date | date: 'shortDate'}}
                </ng-container>
                <em *ngIf="!entity.date">
                    {{ localizations.common.not_specified_f }}
                </em>
            </p>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right"
             *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs"
                    type="button"
                    (click)="edit()"
                    [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}">
                </i>
            </button>
            <button class="btn btn-default btn-sxs"
                    type="button"
                    title="Удалить"
                    *ngIf="allowEdit"
                    (click)="delete()">
                <i class="icon-trash"></i>
            </button>
        </div>

        <ng-container *ngIf="mode == 'edit'">

            <div  class="pt-15 pl-20 pr-20">

                <!-- Данные сведения -->
                <!-- Дата/номер -->
                <ng-container *ngIf="isChangeNumberAndDate">
                    <div class="row">
                        <div class="col-md-3">
                            <label>
                                {{ localizations.common.date }}
                            </label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker [large]="true"
                                             [(ngModel)]="entity.date"
                                             [valueAsString]="true">
                                </evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>
                                {{ localizations.common.number }}
                            </label>
                            <div class="form-group form-group-lg">
                                <div class="input-group input-group-lg">
                                    <input class="form-control input-xs"
                                           type="text"
                                           [(ngModel)]="entity.number"
                                           placeholder="Введите номер">
                                    <div class="input-group-btn">
                                        <button class="btn btn-default"
                                                type="button"
                                                (click)="isChangeNumberAndDate = false">
                                            <i class="icon-checkmark4"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </ng-container>


                <div class="row">
                    <div class="col-md-3">
                        <label>
                            {{ localizations['appeals.entities'].inspection_start_date}}
                        </label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker [large]="true" [(ngModel)]="entity.dateStart" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>
                            {{ localizations['appeals.entities'].inspection_end_date}}
                        </label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker [large]="true"
                                         [(ngModel)]="entity.dateEnd"
                                         [valueAsString]="true"
                                         [disabled]="!allowEdit">
                            </evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3 hide">
                        <label>
                            {{ localizations['appeals.entities'].knm_duration }}
                        </label>
                        <div class="form-group form-group-lg">
                            <input class="form-control input-xs"
                                   type="text"
                                   [(ngModel)]="entity.liadTimeInHourse"
                                   [disabled]="!allowEdit">
                        </div>
                    </div>
                </div>

                <!-- Цель проверки -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations['appeals.entities'].inspection_aim }}
                            </label>
                            <catalogue
                                catalogName="purposeKNM"
                                name="purposeKnm"
                                [(ngModel)]="entity.purposeKnm"
                                [returnFields]="['_id', 'code', 'name']"
                                [priority]="true"
                                [large]="true"
                                [disabled]="!allowEdit">
                            </catalogue>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>
                            {{ localizations['appeals.entities'].inspection_subject }}
                        </label>
                        <div class="form-group form-group-lg">
                            <ng-select
                                [items]="themes"
                                [(ngModel)]="entity.themes"
                                [disabled]="!allowEdit"
                                [placeholder]="localizations.common.select_value_from_list">
                            </ng-select>
                        </div>
                    </div>
                </div>

                <!-- Задачи проверки -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations['appeals.entities'].inspection_tasks }}
                            </label>
                            <textarea class="form-control"
                                      [(ngModel)]="entity.objective"
                                      [disabled]="!allowEdit">
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>
                        Способ проведения
                    </label>
                    <catalogue
                        catalogName="nsiErpMethodKnm"
                        name="methodKnm"
                        [(ngModel)]="entity.methodKnm"
                        [returnFields]="['_id', 'code', 'name']"
                        [large]="true"
                        [disabled]="!allowEdit">
                    </catalogue>
                </div>

                <div class="form-group form-group-lg">
                    <label>
                        {{ localizations['appeals.entities'].way_of_conducting }}
                    </label>
                    <textarea class="form-control"
                              [(ngModel)]="entity.methodKnmContent">
                    </textarea>
                </div>

                <!-- Мероприятия по контролю -->
                <div class="mb-15">
                    <appeal-entity-events
                        [large]="true"
                        [entity]="entity"
                        [property]="'eventKnm'"
                        [catalogName]="'kNMEvent'"
                        [title]="localizations['appeals.entities'].events_for_control"
                        [placeholderCatalog]="localizations['appeals.entities'].specify_event"
                        [disabled]="!allowEdit"
                        [placeholderDate]="localizations['appeals.entities'].event_period">
                    </appeal-entity-events>
                </div>

                <!-- Перечень документов, подлежащих проверке -->
                <div class="mb-15">
                    <appeal-entity-catalogue
                        [entity]="entity"
                        [large]="true"
                        [property]="'requestedDocument'"
                        [catalogName]="'requestedDocument'"
                        [title]="localizations['appeals.entities'].list_of_documents_to_inspect"
                        [disabled]="!allowEdit"
                        [placeholder]="localizations['appeals.entities'].select_document">
                    </appeal-entity-catalogue>
                </div>

                <!-- Обязательные требования -->
                <div class="mb-15">
                    <appeal-entity-catalogue
                        [large]="true"
                        [entity]="entity"
                        [property]="'mandatoryReqs'"
                        catalogName="mandatoryReqs"
                        [title]="localizations['appeals.entities'].mandatory_requirements"
                        [disabled]="!allowEdit"
                        [placeholder]="localizations['appeals.entities'].select_mandatory_requirement">
                    </appeal-entity-catalogue>
                </div>

                <div class="mb-15">
                    <multiple-catalogue
                        [large]="true"
                        [(ngModel)]="entity.jointlyKno"
                        catalogName="organizations"
                        projection="dictionaryCodeName"
                        [returnFields]="['_id', 'shortName', 'name']"
                        [title]="localizations['appeals.entities'].kno_to_perform_inspection_with"
                        [placeholder]="localizations['appeals.entities'].select_kno">
                    </multiple-catalogue>
                </div>

                <div class="checkbox-block mt-10 mb-15">
                    <input id="subjectLicenseNotActual"
                           class="checkbox"
                           type="checkbox"
                           [checked]="entity.isSubjectLicenseNotActual"
                           (change)="changeSubjectLicenseActual()">
                    <label for="subjectLicenseNotActual">
                        {{ localizations['appeals.entities'].license_suspended }}
                    </label>
                </div>
                <div *ngIf="entity.isSubjectLicenseNotActual">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations['appeals.entities'].date_of_entry_in_legal_force }}
                                </label>
                                <evolenta-datepicker [large]="true"
                                             [(ngModel)]="entity.subjectLicenseNotActualProperties.date"
                                             [valueAsString]="true">
                                </evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>
                                    {{ localizations['appeals.entities'].date_of_inspection_end_with_decision }}
                                </label>
                                <evolenta-datepicker [large]="true"
                                             [(ngModel)]="entity.subjectLicenseNotActualProperties.lastAppealDate"
                                             [valueAsString]="true">
                                </evolenta-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-group form-group-lg">
                        <label>
                            {{ localizations['appeals.entities'].resolution_props }}
                        </label>
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="entity.subjectLicenseNotActualProperties.documentProperties">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations['appeals.entities'].person_who_issued_order }}
                            </label>
                            <ng-select [allowClear]="true"
                                       [items]="usersForSelect"
                                       [active]="activeItemSelect(usersForSelect, 'employeeSignatory')"
                                       (selected)="selectValue($event, 'employeeSignatory')"
                                       (removed)="clearValue($event, 'employeeSignatory')"
                                       [disabled]="!allowEdit"
                                       [placeholder]="localizations.common.select_value">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations['appeals.entities'].person_who_prepared_project }}
                            </label>
                            <ng-select [allowClear]="true"
                                       [items]="usersForSelect"
                                       [active]="activeItemSelect(usersForSelect, 'employeeAuthor')"
                                       (selected)="selectValue($event, 'employeeAuthor')"
                                       (removed)="clearValue($event, 'employeeAuthor')"
                                       [disabled]="!allowEdit"
                                       [placeholder]="localizations.common.select_value">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"
                                     [task]="task"
                                     [allowEdit]="allowEdit">
            </appeal-entity-documents>

        </ng-container>
    </div>
</div>
