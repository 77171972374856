import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { CommonStandardsService } from '../../common-standards.service';
// @ts-ignore
import { SelectService, ToasterService, TranslateService } from '@evolenta/core';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-standard-entity-edit',
    templateUrl: './common-standard-entity-edit.component.html',
    styles: [':host { width: 100%; }'],
})
export class CommonStandardEntityEditComponent implements OnInit {
    @Input() public entity;
    @Input() public activeData;
    @Input() public allowEdit = true;

    @Output() public onSave = new EventEmitter<boolean>();
    @Output() public onCancel = new EventEmitter<boolean>();
    @Output() public onEditXsd = new EventEmitter<object>();

    public metaData;
    public metaReglament;

    public tabs = [
        {
            code: 'common',
            name: 'Описание',
        },
    ];
    public activeTab = 'common';
    public isProcessValidate = false;

    public groups;
    public localizations;

    @ViewChild('mainData', { static: false }) public mainData: DynamicFormComponent;

    public constructor(
        public commonStandardsService: CommonStandardsService,
        private errorLoggingService: ErrorLoggingService,
        private toaster: ToasterService,
        private selectService: SelectService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.metaData = this.commonStandardsService.metaReglament.blocks.find((item) => item.code === 'entities');
        if (
            !this.entity.kind &&
            this.metaData.entitiesData &&
            (!this.metaData.entitiesData.useStaticEntities || !this.metaData.entitiesData.useCustomEntities)
        ) {
            this.entity.kind = this.metaData.entitiesData.useStaticEntities ? 'static' : 'custom';
        }
        this.metaReglament = this.commonStandardsService.metaReglament;
        if (this.metaData.entitiesData.isBpmnTasksLinksSetting) {
            this.tabs.push({
                code: 'bpmnTasksLinks',
                name: 'Задачи бизнес-процесса',
            });
            this.tabs.push({
                code: 'bpmnEventsLinks',
                name: 'События бизнес-процесса',
            });
        }
        this.groups = this.selectService.transformForSelect('guid', 'name', this.activeData.entity.documentGroups);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getInfoFormMessage(): string {
        const template = this.localizations.standards.info.form;
        const action = !this.entity.xsd ? this.localizations.common.not_set_up_f : this.localizations.common.set_up_f;

        return template.replace('%s', action);
    }

    public async save() {
        this.mainData.validate();
        if (this.mainData.isValid && this.validate()) {
            this.onSave.emit(true);

            return;
        }

        const errorText = 'На форме основных данных ошибки';
        this.toaster.error(errorText);
        await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
    }

    public validate() {
        this.isProcessValidate = true;
        const errors = [];
        if (!this.entity.kind) {
            errors.push('Не выбран вид сведения');
        } else {
            if (this.entity.kind === 'custom') {
                if (!this.entity.code) {
                    errors.push('Не заполнен код');
                }
                if (!this.entity.xsd) {
                    errors.push('Не заполнена форма сведения');
                }
            } else if (!this.entity.type) {
                errors.push('Не выбран тип сведения');
            }
        }
        if (errors.length > 0) {
            const errorsText = errors.join(' ');
            this.toaster.error(errorsText);
            this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorsText));
        }

        return errors.length === 0;
    }

    public cancel() {
        this.onCancel.emit(true);
    }

    public changeKind(kind) {
        this.entity.kind = kind;
        if (this.entity.kind === 'custom') {
            delete this.entity.code;
            delete this.entity.type;
        } else {
            delete this.entity.xsd;
        }
    }

    public editXsd() {
        this.onEditXsd.emit({
            data: this.entity,
            type: 'entities',
            allowEdit: this.allowEdit,
            params: { isInitDataFromExternalData: true, isSetTransferToProcess: true },
        });
    }

    public getActiveGroup() {
        if (this.entity.group) {
            const group = this.groups.find((elm) => elm.id === this.entity.group);

            return group ? [group] : null;
        }

        return null;
    }

    public selectGroup(value) {
        this.entity.group = value.id;
    }
}
