<div class="pb-20"
     *ngIf="!isProcessAddingQuestion">
    <table class="table table-xs no-margin"
           *ngIf="!onlyAdd">
        <thead>
        <tr>
            <th class="text-bold text-uppercase"
                rowspan="2"
                style="width: auto">
                {{ localizations.common.question }}
            </th>
            <th class="text-center text-bold"
                colspan="2"
                style="width: 30%"
                 *ngIf="showNpa">
                {{ localizations['entity-question'].npa_link }}
            </th>
            <th class="text-center text-bold"
                colspan="3">
                {{ localizations['entity-question'].responses_availability }}
            </th>
            <th class="text-bold"
                rowspan="2"
                style="width: 100px"
                *ngIf="setFillingType">
                {{ localizations['entity-question'].way_of_filling }}
            </th>
            <th class="text-bold"
                rowspan="2"
                *ngIf="setHandlers">
                {{ localizations['entity-question'].handlers }}
            </th>
            <th rowspan="2"
                style="width: 100px"
                class="text-right">
            </th>
        </tr>
        <tr>
            <th class="text-center text-bold"
                style="width: 5%"
                *ngIf="showNpa">
                {{ localizations.common.code }}
            </th>
            <th class="text-center text-bold"
                style="width: 25%"
                *ngIf="showNpa">
                {{ localizations.common.name }}
            </th>
            <th class="text-center text-bold pl-10 pr-10"
                style="width: 5%">
                {{ localizations.common.yes }}
            </th>
            <th class="text-center text-bold pl-10 pr-10"
                style="width: 5%">
                {{ localizations.common.no }}
            </th>
            <th class="text-center text-bold pl-10 pr-10"
                style="width: 10%">
                {{ localizations.common.not_acceptable }}
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let question of entity.questions; let idx = index">
            <tr>
                <td [rowSpan]="question.nPA?.length > 0 ? question.nPA.length : 1">
                    {{question.name}}
                </td>
                <td class="pl-10 pr-10"
                    *ngIf="showNpa">
                    <ng-template [ngIf]="question.nPA?.length && question.nPA[0]?.code"
                                 [ngIfElse]="notSpecified">
                        {{question.nPA[0].code}}
                    </ng-template>
                </td>
                <td class="pl-10 pr-10"
                    *ngIf="showNpa">
                    <ng-template [ngIf]="question.nPA?.length && question.nPA[0]?.name"
                                 [ngIfElse]="notSpecified">
                        {{question.nPA[0].name}}
                    </ng-template>
                </td>
                <td class="text-center pl-10 pr-10"
                    [rowSpan]="question.nPA?.length > 0 ? question.nPA.length : 1">
                    <em [ngClass]="{
                        'icon-plus2': question.answerYes,
                        'icon-minus2': !question.answerYes
                        }">
                    </em>
                </td>
                <td class="text-center pl-10 pr-10"
                    [rowSpan]="question.nPA?.length > 0 ? question.nPA.length : 1">
                    <em [ngClass]="{
                        'icon-plus2': question.answerNo,
                        'icon-minus2': !question.answerNo
                        }"></em>
                </td>
                <td class="text-center pl-10 pr-10"
                    [rowSpan]="question.nPA?.length > 0 ? question.nPA.length : 1">
                    <em [ngClass]="{
                        'icon-plus2': question.answerNotConcidered,
                        'icon-minus2': !question.answerNotConcidered
                        }">
                    </em>
                </td>
                <td class="text-center pl-10 pr-10"
                    *ngIf="setFillingType">
                    <ng-select [items]="fillingTypes"
                               [active]="selectedFillingType(question)"
                               (selected)="selectingFillingType($event, question)"
                               [placeholder]="localizations.common.select_value">
                    </ng-select>
                </td>
                <td class="text-center pl-10 pr-10"
                    *ngIf="setHandlers">
                    <evolenta-handlers
                            [model]="question"
                            [setStartEvent]="true"
                    ></evolenta-handlers>
                </td>
                <td class="text-right pl-10"
                    [rowSpan]="question.nPA?.length > 0 ? question.nPA.length : 1">
                    <button class="btn btn-sxs btn-default"
                            *ngIf="!question._id"
                            [title]="localizations.common.edit"
                            (click)="editQuestion(question)">
                        <em class="icon-pencil3"></em>
                    </button>
                    <button class="btn btn-sxs btn-default"
                            [title]="localizations['entity-question'].remove_questions"
                            (click)="entity.questions.splice(idx, 1)">
                        <em class="icon-trash"></em>
                    </button>
                </td>
            </tr>
            <ng-container *ngIf="question.nPA?.length > 1">
                <ng-container *ngFor="let nPA of question.nPA; let fst = first">
                    <tr *ngIf="!fst">
                        <td class="pl-10 pr-10">
                            <ng-template [ngIf]="nPA?.code"
                                         [ngIfElse]="notSpecified">
                                {{nPA.code}}
                            </ng-template>
                        </td>
                        <td class="pl-10 pr-10">
                            <ng-template [ngIf]="nPA?.name"
                                         [ngIfElse]="notSpecified">
                                {{nPA.name}}
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
        </tbody>
    </table>

    <button class="btn btn-info ml-20 mt-20"
            (click)="addQuestion('dictionary')">
        {{ localizations['entity-question'].add_from_reference }}
    </button>
    <button class="btn btn-info ml-20 mt-20"
            (click)="addQuestion('manually')">
        {{ localizations.common.add }}
    </button>
</div>


<div class="p-20" *ngIf="isProcessAddingQuestion">
    <ng-container *ngIf="addingQuestionType === 'dictionary'">
        <p class="text-bold">
            {{ localizations['entity-question'].select_request_from_reference }}:
        </p>
        <catalogue [large]="true"
                            [(ngModel)]="processingQuestion"
                            [catalogName]="'controlListQuestions'"
                            [placeholder]="localizations['entity-question'].select_questions"
                            [returnFields]="usedFields"
                            [baseSearch]="baseSearch"
                            required>
        </catalogue>
    </ng-container>
    <ng-container *ngIf="addingQuestionType === 'manually' && xsd">
        <dynamic-form
            #mainData
            [xsd]="xsd"
            [model]="processingQuestion"
            [saveInMainObject]="true">
        </dynamic-form>
    </ng-container>

    <div class="mt-20">
        <button class="btn btn-info btn-lg mr-10"
                type="button"
                (click)="completeAddQuestion()">
            {{ localizations.common.apply }}
        </button>
        <button class="btn btn-default btn-lg"
                type="button"
                (click)="cancelAddQuestion()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</div>

<ng-template #notSpecified>
    <div class="text-center">-</div>
</ng-template>
