/**
 * Компонент списка СМЭВ-адаптеров
 * @author Sergey Maksimenko
 * @since 06.03.2023
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SmevConfigurationService } from '../../services/smev-configuration.service';
import { RestService, TranslateService } from '@evolenta/core';
import { CustomFiltersBarComponent } from '@evolenta/core/lib/components/custom-filters-bar/custom-filters-bar.component';

@Component({
    selector: 'smev-configuration-list',
    templateUrl: 'smev-configuration-list.component.html',
    styleUrls: ['./smev-configuration-list.component.less'],
})
export class SmevConfigurationListComponent implements OnInit {

    public constructor(
        private route: Router,
        private smevConfigurationService: SmevConfigurationService,
        private restService: RestService,
        private translate: TranslateService,
    ) {
    }

    @ViewChild('filtersPanel', { static: true }) private filtersPanel: CustomFiltersBarComponent; // панель фильтров

    public initialized;
    public localizations;
    public adaptors;

    public globalSearch;
    public filtersPanelItems = [
        {
            groupId: 'main-group',
            icon: 'icon-grid-alt',
            show: true,
            data: [
                {
                    // Фильтр по uri
                    name: 'По URI',
                    filters: [
                        {
                            fields: ['uri'],
                            type: 'input',
                            placeholder: 'URI',
                        },
                    ],
                },
                {
                    // Фильтр по статусу
                    name: 'Статус',
                    code : 'appealStatuses',
                    filters: [
                        {
                            code : 'appealStatuses',
                            fields: ['adapter'],
                            type: 'checkboxes',
                            items: [
                                {
                                    code: 'OFF',
                                    name: 'Выключен',
                                    shortName: 'Выключен',
                                    theme: 'danger',
                                    labelText: 'Выключен',
                                    label: 'bg-danger-50 text-danger',
                                    background: 'bg-danger-300',
                                    border: 'border-danger-400',
                                    backgroundLite: 'bg-danger-50',
                                    borderTop: 'border-top-danger-400',
                                    borderLeft: 'border-left-danger-400',
                                    text: 'text-danger-400',
                                    icon: 'la la-pencil',
                                    img: 'pencil.svg',
                                },
                                {
                                    code: 'SMEVCLIENT',
                                    name: 'СМЭВ - клиент',
                                    shortName: 'СМЭВ - клиент',
                                    theme: 'success',
                                    labelText: 'СМЭВ - клиент',
                                    label: 'bg-success-50 text-success',
                                    background: 'bg-success-300',
                                    border: 'border-success-400',
                                    backgroundLite: 'bg-success-50',
                                    borderTop: 'border-top-success-400',
                                    borderLeft: 'border-left-success-400',
                                    text: 'text-success-400',
                                    icon: 'la la-pencil',
                                    img: 'pencil.svg',
                                },
                                {
                                    code: 'UNIVERSAL',
                                    name: 'Универсальный',
                                    shortName: 'smev_configuration.statuses.universal',
                                    theme: 'warning',
                                    labelText: 'smev_configuration.statuses.universal',
                                    label: 'bg-purple-50 text-warning',
                                    background: 'bg-warning-300',
                                    border: 'border-warning-400',
                                    backgroundLite: 'bg-warning-50',
                                    borderTop: 'border-top-warning-400',
                                    borderLeft: 'border-left-warning-400',
                                    text: 'text-warning-400',
                                    icon: 'la la-pencil',
                                    img: 'pencil.svg',
                                },
                            ],
                            hiddenProperty: 'isHidden',
                            itemStyleProperty: 'background',
                            itemName: 'name',
                            itemValue: 'code',
                            labelWidth: 140,
                            linkFilter: 'appealActuality',
                            hiddenOnLinkFilterValueCodes: [
                                'appealsPreArchive',
                                'appealsArchive',
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    public baseSearch = []; // Параметры базового поиска
    public totalAdaptorsCount = 0; // Общее число элементов
    public selected;
    private page = 0;
    private totalPages;
    private sortOpts;

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'smev_configuration',
            ],
        ).subscribe(async (res: string) => {
            this.localizations = res;
            await this.fetchAdaptors();
            // TODO перенести в БД

            this.initialized = true;
        });
    }

    /**
     * Обработчик глобального поиска
     */
    public search(uri) {
        this.fetchAdaptors(uri);
    }

    /**
     * Очистка поискового запроса
     */
    public clearSearch(): void {
        this.fetchAdaptors();
    }

    /**
     * Возвращает id адаптора для индекса в списке
     * @param index
     * @param appeal - дело
     */
    public indexedAdaptors(index, adaptor): string {
        return adaptor._id;
    }

    public checkIsSelected(adaptor) {
        return this.selected && adaptor._id === this.selected._id;
    }

    public adaptorClickedHandler(adaptor) {
        this.selected = adaptor;
    }

    public clearFilters() {
        this.clearSearch();
        this.filtersPanel.clearFilters();
    }

    public async fetchAdaptors(uri?, filterChanged?) {
        if (filterChanged) {
            this.page = 0;
        }

        if (!filterChanged && this.restService.pagination && this.restService.pagination.totalPages && this.page > this.restService.pagination.totalPages) {
            return;
        }

        let search;
        if (uri) {
            search = [{
                field: 'uri',
                operator: 'likeAnd',
                value: uri,
            }];
        } else {
            search = this.filtersPanel ? this.filtersPanel.prepareFilters() : [];
        }
        const newAdaptors = await this.smevConfigurationService.getServices(search, this.page++, this.sortOpts);
        if (filterChanged) {
            delete this.adaptors;
        }

        setTimeout(() => {
            this.adaptors = this.adaptors ? this.adaptors.concat(newAdaptors) : newAdaptors;
            this.totalAdaptorsCount = this.restService.pagination.total;
        });

    }

    public setSortOptsAndLoadData(sortOpts) {
        this.sortOpts = sortOpts;
        this.fetchAdaptors(null, true);
    }
}
