import { UniversalCollectionPageOptionsInterface } from '@evolenta/core';
import { UserMessagesService } from '@evolenta/core';
import { DatePipe } from '@angular/common';
import { NotificationsPageService } from './notifications-page.service';
import { NotificationsPreviewComponent } from './components/notifications-preview/notifications-preview.component';
import cloneDeep from 'lodash-es/cloneDeep';

export const NotificationsListConfig: UniversalCollectionPageOptionsInterface = {
    elementsName: 'Уведомления',
    isSelectingAllowed: false,
    isExternalSearchAllowed: false,
    isMarkingAllowed: true,
    getMarkedElementsSearchFunc: () => [
        { field: 'status', operator: 'neq', value: 'read' },
        { field: 'status', operator: 'neq', value: 'error' },
        { field: 'status', operator: 'neq', value: 'readyToSend' },
    ],
    isUseItemButtonAllowed: false,
    isCreateButtonAllowed: false,
    getCollectionNameFunc: () => 'notifications',
    defaultSort: 'dateCreation,DESC',
    getFilterNameFunc: () => 'notificationsFilter',
    getFilterItemsFunc: () => cloneDeep(NotificationsPageService.FiltersPanelItems),
    getBaseSearchFunc: (context) => [
        {
            orSubConditions: [
                { field: 'to.login', operator: 'eq', value: context.storage.getItem('user').login },
                { field: 'to.id', operator: 'eq', value: context.storage.getItem('user')._id },
            ],
        },
        {
            andSubConditions: [
                {
                    orSubConditions: [
                        { field: 'notificationType', operator: 'eq', value: 'portal' },
                        { field: 'notificationType', operator: 'eq', value: null },
                    ],
                },
                { field: 'status', operator: 'neq', value: 'error' },
                { field: 'status', operator: 'neq', value: 'readyToSend' },
            ],
        },
    ],
    getPreviewComponentFactoryFunc: (resolver) => resolver.resolveComponentFactory(NotificationsPreviewComponent),
    getPatchPreviewComponentFunc: (component) => {
        component.type = 'preview';
    },
    listItemOptions: {
        status: {
            isShownPrimary: true,
            getPrimaryPropertyFunc: (element, property) =>
                new UserMessagesService(null, null, null, null).getTypeProperty(element.type, property),
            isShownSecondary: false,
        },
        marker: {
            getCheckIsMarkedFunc: (element) => !['read', 'error', 'readyToSend'].includes(element.status),
            getPropertyFunc: (...[, property]) => (property === 'name' ? ' ' : 'bg-orange'),
        },
        title: {
            isShownId: true,
            getIdFunc: (element) => (!['unread', 'sent'].includes(element.status) ? element.text : null),
            isShownTitle: true,
            getTitleFunc: (element) => (['unread', 'sent'].includes(element.status) ? element.text : null),
        },
        details: [
            {
                title: 'Дата',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateCreation, 'shortDate'),
            },
            { title: 'Вид', getValueFunc: (element) => element.title },
        ],
    },
};
