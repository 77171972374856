<div
    [ngClass]="{'panel panel-shadow m-20 panel-small': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading"
         *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
        </h4>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn"
                    title="Редактировать"
                    *ngIf="mode == 'view'"
                    (click)="edit()">
                <strong [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></strong>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit' && allowEdit"
                    (click)="apply()">Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit'"
                    (click)="cancel()">Закрыть
            </button>
        </div>
    </div>

    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line no-padding-top no-padding-bottom': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div class="pt-15 pb-15" *ngIf="mode == 'view'" [ngClass]="{'card-preview': task, 'pl-15 pr-15': !task}">
            <p class="mb-3">
                <span class="text-muted">Номер документа: </span>
                <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
                <em *ngIf="!entity.number">не указано</em>

                <span class="text-muted ml-10">Дата: </span>
                <ng-container *ngIf="entity.creationDate || entity.date">
                    <ng-container *ngIf="entity.creationDate">{{entity.creationDate | date: 'shortDate'}}</ng-container>
                    <ng-container *ngIf="!entity.creationDate">{{entity.date | date: 'shortDate'}}</ng-container>
                </ng-container>
                <em *ngIf="!entity.creationDate && !entity.date">не указано</em>
            </p>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right pt-15 pb-15" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" [title]="allowEdit ? 'Редактировать' : 'Посмотреть'">
                <i class="icon-pencil" *ngIf="allowEdit"></i>
                <i class="icon-eye" *ngIf="!allowEdit"></i>
            </button>

            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <ng-container *ngIf="mode === 'edit'">

        </ng-container>

    </div>

    <!-- Группы документов, привязанные к сведению -->
    <common-appeal-entity-documents [entity]="entity"
                                    [task]="task ? task : {}"
                                    [mode]="mode"
                                    [needSaveBeforePrint]="true"
                                    [allowEdit]="mode === 'edit'"
                                    (onNeedSaveBeforePrint)="processingSaveBeforePrint($event)"
                                    #entityDocuments></common-appeal-entity-documents>
</div>

