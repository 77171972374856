import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsModule as EvolentaFormsModule } from '@evolenta/forms';
import { RouterModule } from '@angular/router';
import { CoreModule, SelectService } from '@evolenta/core';
import { FormsConstructorModule } from '@evolenta/forms-constructor';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { BsDatepickerModule, ModalModule, PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { FileUploadComponent } from './directives/file-upload.directive';
import { CanDeactivateModalComponent } from '@components/modal/can-deactivate-modal.component';
import { TouchSpinComponent } from '@components/touch-spin/touch-spin.component';
import { HistoryComponent } from '@components/history/history.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ClickOutsideModule } from 'ng-click-outside';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { TreeviewModule } from 'ngx-treeview';
import { TreeSelectComponent } from '@components/tree-select/tree-select.component';
import { TreeSelectFilterPipe } from '@components/tree-select/tree-select-filter.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DateTimePickerDirective } from './directives/datetimepicker.directive';
import { SafePipe } from './pipes/safe.pipe';
import { ElementEditTabsComponent } from '@components/element-edit-tabs/element-edit-tabs.component';
import { SearchForNamePipe } from './pipes/searchForName.pipe';
import { AnketaLifeEventComponent } from '@components/anketa-life-event/anketa-life-event.component';
import { AnketaSubserviceComponent } from '@components/anketa-subservice/anketa-subservice.component';
import { TaskPingService } from './services/task-ping.service';
import { NgxTrimModule } from 'ngx-trim';
import { ObjectNgForPipe } from './pipes/objectNgFor.pipe';
import { QuickRequestsService } from './services/quick-requests.service';
import { XsdJsonService } from './services/xsd-json.service';
import { GeneratePasswordComponent } from '@components/generate-password/generate-password.component';
import { EqService } from './services/eq.service';
import { SelectModule } from 'ng2-select';
import { SelectWithInputComponent } from '@components/select-with-input/select-with-input.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CopyService } from './services/copy.service';
import { DndModule } from 'ngx-drag-drop';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { EntityAdditionalDataComponent } from '@components/entity-additional-data/entity-additional-data.component';
import { SharedElementsModule } from './shared-elements.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { TransformTimePipe } from './pipes/transformTime.pipe';
import { LeafletMapModalComponent } from '@components/modal/leaflet-map-modal/leaflet-map-modal.component';
import { ClientModuleService } from './services/client-module.service';
import { CsvService } from './services/csv.service';
import { TaskAdditionalDataTabComponent } from '../modules/knm/appeals/components/additional-data/task-additional-data-tab.component';
import { EmptyRoutingComponent } from '@components/empty-routing.component';
import { CodeValidator } from './directives/code-validator.directive';
import { MultipleRouteComponent } from '@components/multiple-route/multiple-route.component';
import { CalculateService } from './services/calculate.service';
import { SettingsService } from './services/settings.service';
import { FiltersService } from './services/filters.service';
import { CustomValidatorsService } from './services/custom-validators.service';
import { UinValidatorDirective } from './directives/uin-validator.directive';
import { CertificatesService } from './services/certificates.service';
import { LayoutModule } from '@evolenta/layout';
import { CataloguesModule } from '@evolenta/catalogues';
import { AddressesModule } from '@evolenta/addresses';
import { PrintingModule } from '@evolenta/printing';
import { OpenLinkModalComponent } from '@components/modal/open-link-modal/open-link-modal.component';
import { SetExecutorComponent } from '@components/set-executor/set-executor.component';
import { TransferToUnitComponent } from '@components/transfer-to-unit/transfer-to-unit.component';
import { ValidatorsService } from './services/validators.service';
import { EntityFieldsRequirementsService } from './services/entity-fields-requirements.service';
import { InternalHandlersService } from './services/internal-handlers.service';
import { SortingBarComponent } from '@components/sorting-bar/sorting-bar.component';
import { ValidatingModule } from '@evolenta/validating';
import { BadgeComponent } from '@components/badge/badge.component';
import { CustomFiltersService } from './services/custom-filters.service';
import { SigningModule } from '@evolenta/signing';
import { SpecificCustomValidatorsService } from './services/specific-custom-validators.service';
import { RsoService } from './services/rso.service';
import { FilesQueueService } from './services/filesQueue.service';
import { VersionComponent } from '@components/version/version.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { AboutModalDialogComponent } from '@components/about-modal-dialog/about-modal-dialog.component';
import { VersionService } from './services/version.service';
import { NewFiltersBarComponent } from '@components/filters-bar/filters-bar.component';

@NgModule({
    imports: [
        CoreModule,
        LayoutModule,
        CataloguesModule,
        FormsModule,
        EvolentaFormsModule,
        FormsConstructorModule,
        AddressesModule,
        PrintingModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        TextMaskModule,
        FormsModule,
        SelectModule,
        FileUploadModule,
        ClickOutsideModule,
        NgxMyDatePickerModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        UiSwitchModule,
        NguiDatetimePickerModule,
        JSONEditorModule,
        TreeviewModule.forRoot(),
        NgxTrimModule,
        ClipboardModule,
        DndModule,
        ClipboardModule,
        CodemirrorModule,
        SharedElementsModule,
        InlineSVGModule,
        BsDatepickerModule.forRoot(),
        ValidatingModule,
        SigningModule,
    ],
    declarations: [
        DateTimePickerDirective,
        FileUploadComponent,
        CanDeactivateModalComponent,
        TouchSpinComponent,
        HistoryComponent,
        TreeSelectComponent,
        TreeSelectFilterPipe,
        CapitalizePipe,
        SafePipe,
        ObjectNgForPipe,
        SearchForNamePipe,
        AnketaLifeEventComponent,
        AnketaSubserviceComponent,
        ElementEditTabsComponent,
        GeneratePasswordComponent,
        SelectWithInputComponent,
        EntityAdditionalDataComponent,
        TransformTimePipe,
        TaskAdditionalDataTabComponent,
        TransformTimePipe,
        LeafletMapModalComponent,
        EmptyRoutingComponent,
        CodeValidator,
        MultipleRouteComponent,
        UinValidatorDirective,
        OpenLinkModalComponent,
        SetExecutorComponent,
        TransferToUnitComponent,
        SortingBarComponent,
        NewFiltersBarComponent,
        BadgeComponent,
        VersionComponent,
        SidebarComponent,
        AboutModalDialogComponent,
    ],
    exports: [
        CoreModule,
        LayoutModule,
        CataloguesModule,
        TextMaskModule,
        FormsModule,
        EvolentaFormsModule,
        FormsConstructorModule,
        AddressesModule,
        PrintingModule,
        CommonModule,
        ReactiveFormsModule,
        SelectModule,
        NgxMyDatePickerModule,
        RouterModule,
        BsDatepickerModule,
        ModalModule,
        TooltipModule,
        PopoverModule,
        FileUploadComponent,
        CanDeactivateModalComponent,
        TouchSpinComponent,
        HistoryComponent,
        FileUploadModule,
        ClickOutsideModule,
        UiSwitchModule,
        NguiDatetimePickerModule,
        JSONEditorModule,
        TreeviewModule,
        TreeSelectComponent,
        TreeSelectFilterPipe,
        CapitalizePipe,
        ElementEditTabsComponent,
        SafePipe,
        SearchForNamePipe,
        AnketaLifeEventComponent,
        AnketaSubserviceComponent,
        NgxTrimModule,
        ObjectNgForPipe,
        GeneratePasswordComponent,
        SelectWithInputComponent,
        EntityAdditionalDataComponent,
        CodemirrorModule,
        TaskAdditionalDataTabComponent,
        LeafletMapModalComponent,
        CodeValidator,
        DndModule,
        UinValidatorDirective,
        OpenLinkModalComponent,
        SetExecutorComponent,
        TransferToUnitComponent,
        SortingBarComponent,
        NewFiltersBarComponent,
        ValidatingModule,
        SigningModule,
        BadgeComponent,
    ],
    entryComponents: [
        OpenLinkModalComponent,
    ],
    providers: [
        CertificatesService,
        SelectService,
        TaskPingService,
        XsdJsonService,
        QuickRequestsService,
        EqService,
        CopyService,
        DatePipe,
        CsvService,
        ClientModuleService,
        CalculateService,
        SettingsService,
        FiltersService,
        CustomFiltersService,
        VersionService,
        CustomValidatorsService,
        ValidatorsService,
        EntityFieldsRequirementsService,
        InternalHandlersService,
        SpecificCustomValidatorsService,
        RsoService,
        FilesQueueService,
    ],
})
export class SharedComponentsModule {
}
