<div class="panel panel-shadow panel-small">
    <div class="panel-heading">
        <h4 class="panel-title">
            {{ subTask.name ? subTask.name : localizations.tasks.new }}
            <div>
                <span class="badge text-uppercase"
                      [ngClass]="taskService.getStatusByProperty(subTask.priority, 'background')">
                    {{taskService.getStatusByProperty(subTask.priority, 'name')}}
                </span>
            </div>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-default mr-5"
                    [title]="localizations.common.edit"
                    (click)="editTask(subTask)">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>
            <button class="btn btn-default"
                    type="button"
                    [title]="localizations.common.delete"
                    (click)="removeTask(subTask)">
                <b><i inlineSVG="assets/icon-trash.svg"></i></b>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <p>
            <span class="text-muted">
                {{ localizations.tasks.start_date }}:
            </span>
            {{ subTask.followUp ? (subTask.followUp | date: 'mediumDate') : localizations.common.not_set }}
        </p>
        <p>
            <span class="text-muted">
                {{ localizations.tasks.completion_date }}
            </span>
            {{ subTask.due ? (subTask.due | date: 'mediumDate') : localizations.common.not_set }}
        </p>
        <p>
            <span class="text-muted mr-5">
                {{ localizations.tasks.assigned_to }}:
            </span>
            <ng-container *ngIf="subTask.assignee">
                {{getAssigneeName()}}
            </ng-container>
            <span class="ml-5"
                  *ngIf="!subTask.assignee">
                {{ localizations.tasks.assignee_is_missing }}
            </span>
        </p>
        <ng-container *ngIf="subTask.content">
            <p class="text-muted">
                {{ localizations.tasks.content }}:
            </p>
            <blockquote>
                {{subTask.content}}
            </blockquote>
        </ng-container>

    </div>
</div>
