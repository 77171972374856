<!-- Режим отображения в "Задаче" -->
<ng-container *ngIf="task">
    <table class="table">
        <tbody>
        <ng-container *ngFor="let group of groups; let fst = first; let lst = last">
            <ng-container *ngIf="!onlyNotEmpty || countDocumentsInGroup(group) > 0">
                <tr *ngIf="!mode || mode === 'edit'">
                    <td class="text-uppercase text-bold"
                        [ngClass]="{'no-border-top': fst, 'border-bottom border-bottom-default': !lst}">
                        {{group.name}}
                        <span class="badge bg-primary-300 text-uppercase ml-10">
                            {{countDocumentsInGroup(group)}}
                        </span>
                    </td>
                    <td class="text-right"
                        [ngClass]="{'no-border-top': fst, 'border-bottom border-bottom-default': !lst}">
                        <button class="btn btn-primary-overline"
                                *ngIf="allowEdit"
                                (click)="addDocument(group)">
                            Добавить
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"
                        class="no-padding">
                        <ng-container *ngFor="let document of appeal.documents">
                            <common-appeal-document-card [document]="document"
                                                  [appeal]="appeal"
                                                  [group]="group"
                                                  [task]="task"
                                                  [allowEdit]="allowEdit"
                                                  mode="view"
                                                  *ngIf="isShowDocument(group, document)"
                                                  (onEdit)="editDocument($event)"
                                                  (onDelete)="deleteDocument($event)"
                                                  (isCheckFileSign)="checkallowEdit($event)"
                                                  (onPrint)="printDocument($event)">
                            </common-appeal-document-card>
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
        </tbody>
    </table>
</ng-container>

<!-- Режим отображения в общем списке -->
<ng-container *ngIf="!task">
    <ng-container *ngFor="let group of groups; let lst = last; let fst = first">
        <div cdkDropList
             (cdkDropListDropped)="dropDocument($event, group)"
             [ngClass]="{'no-margin-bottom': lst, 'panel panel-shadow': !task, 'mt-20': group.name == 'Прочие документы'}">
            <div [ngClass]="{'panel-heading': !task}">
                <h4 class="panel-title" [ngStyle]="{'max-width': allowAddDocument ? '80%' : '100%'}">
                    {{group.name}}
                    <!-- Индикатор числа добавленных и необходимых документов внутри группы -->
                    <div>
                        <span class="badge bg-primary-300 text-uppercase">Документов: {{countDocumentsInGroup(group)}}</span>
                    </div>
                </h4>
                <div class="heading-elements" *ngIf="checkAllowAddDocument(group)">
                    <button type="button" class="btn btn-success-overline heading-btn btn-labeled pt-10 pb-10"
                            [disabled]="!accessService.hasAccess([permissions.Appeal_Document_Create], true, appeal.status)"
                            (click)="addDocument(group)" title="Добавить документ"><i class="icon-plus3"></i></button>
                </div>
            </div>
            <div class="panel-body no-padding">
                <ng-container *ngFor="let document of appeal.documents">
                    <common-appeal-document-card cdkDrag
                                                 [cdkDragData]="document"
                                                 [document]="document"
                                                 [appeal]="appeal"
                                                 [group]="group"
                                                 [task]="task"
                                                 mode="view"
                                                 *ngIf="isShowDocument(group, document)"
                                                 (onEdit)="editDocument($event)"
                                                 (onDelete)="deleteDocument($event)"
                                                 (isCheckFileSign)="checkallowEdit($event)"
                                                 (onPrint)="printDocument($event)">
                        <button cdkDragHandle dragButton
                                class="btn btn-default btn-sxs"
                                title="Переместить">
                            <i class="icon-move"></i>
                        </button>
                    </common-appeal-document-card>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>

