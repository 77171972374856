<fieldset>
    <legend class="text-bold"
            [ngClass]="{
                'no-margin': allowEdit,
                 'mb-10': !allowEdit
                 }">
        {{ localizations['set-executor'].name }}
    </legend>
    <ng-container *ngIf="allowEdit">
        <div class="alert alert-info no-margin no-border pt-10 pb-10">
            {{ localizations['set-executor'].select_from_list }}
            <span class="ml-10 text-bold"
                  *ngIf="selectedUser">
                {{ localizations['set-executor'].selected }}: {{selectedUser.name}}
            </span>
        </div>
        <div class="form-group form-group-lg no-margin mt-15"
             *ngIf="users.length > max">
            <input class="form-control"
                   type="text"
                   [(ngModel)]="searchText"
                   [placeholder]="localizations['set-executor'].executors_name_contains"
                   (ngModelChange)="prepareUsers()">
        </div>
        <ng-container *ngFor="let user of usedUsers; let idx = index; let fst = first">
            <div class="radio-block">
                <input id="user{{user.id}}"
                       class="radio"
                       type="radio"
                       name="user"
                       (change)="selectedUser = user">
                <label for="user{{user.id}}">
                    {{user.name}}
                    <span class="text-size-small text-slate display-block"
                          *ngIf="user.position">
                        {{user.position}}
                    </span>
                </label>
            </div>
        </ng-container>
        <div class="mt-20"
             *ngIf="showLink">
            <span class="anchor text-info cursor-pointer pt-20"
                  (click)="showAll = !showAll; prepareUsers()">
                {{showAll ?
                    localizations.common.hide :
                    localizations.common.show_all
                }}
            </span>
        </div>
    </ng-container>

    <p *ngIf="!allowEdit">
      <span class="text-muted">
          {{ localizations['set-executor'].executor_selected }}:
      </span>
      {{element.executor?.name}}
      <span class="text-slate"
            *ngIf="element.executor?.position">
          ({{element.executor.position}})
      </span>
    </p>
</fieldset>
