import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccessService, RestService, ToasterService, TranslateService } from '@evolenta/core';
import { Permission } from '../../../common/services/permission';

@Component({
    selector: 'setting-edit',
    templateUrl: './setting-edit.component.html',
    styleUrls: [
        '../../element-edit.css',
    ],
})
export class SettingEditComponent implements OnInit {
    public termTypeId: any;
    public setting: any = {};
    public isLoading = false;
    public permission = Permission;
    public isValueObject = false;
    public localizations;

    @ViewChild('valueSettingObjectType', { static: false })
    public valueSettingObjectTypeInputRef: ElementRef<HTMLInputElement>;

    public constructor(
        public access: AccessService,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        private location: Location,
        private router: Router,
        private restService: RestService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.data.subscribe(
            response => {
                if (response['setting']) {
                    this.setting = response['setting'];
                    console.log('setting', this.setting.value);

                    if (typeof this.setting.value === 'object') {
                        this.isValueObject = true;
                    }
                }
            },
            error => {
                this.toaster.error(error);
            },
        );
    }

    private _loadTranslations(): void {
        this.translate.get(
            [
                'common',
                'settings',
                'organizations',
            ],
        ).subscribe(res => {
            this.localizations = res;
        });
    }

    public back(): void {
        this.location.back();
    }

    public async save(): Promise<void> {
        this.isLoading = true;

        try {
            const method = this.setting._id ? 'update' : 'create';
            const notification = this.setting._id ? 'successfully_updated' : 'successfully_created';

            this.setting = await this.restService[method]('settings', this.setting);
            this.toaster.success(this.localizations.settings.notifications[notification]);
            await this.router.navigate(['admin', 'settings']);
        } catch (error) {
            this.toaster.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    public externalChangedValue(newValue): void {
        this.setting.value = newValue;
    }

    public onValueSettingObjectTypeChanged() {
        const inputElement = this.valueSettingObjectTypeInputRef.nativeElement;
        const isChecked = inputElement.checked;
        try {
            if (isChecked !== this.isValueObject) {
                if (this.isValueObject) {
                    this.setting.value = JSON.stringify(this.setting.value);
                } else {
                    this.setting.value = JSON.parse(this.setting.value || '{}');
                }
                this.isValueObject = isChecked;
            }
        } catch (error) {
            console.error(error);
            inputElement.checked = !isChecked;
        }
    }
}
