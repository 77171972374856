import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: [
        'sidebar.component.less',
    ],
})
export class SidebarComponent implements OnInit {
    public module: string;

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnInit() {
        this.module = this.route.snapshot.data['module'];

        if (this.route.snapshot['_urlSegment'].segments.length === 1) {
            this.redirectToFirstChild();
        }
    }

    private redirectToFirstChild() {
        const routes = this.router.config;
        const route = routes.find(item => item.path === this.module);
        const url = '/' + this.module + '/' + route.children[0].path;

        this.router.navigate([url]);
    }

}
