<div class="p-20">
    <div class="form-group">
        <label>Организация - исполнитель: </label>
        <catalogue catalogName="organizations"
                   projection="unitsList"
                   placeholder="Введите название организации"
                   [baseSearch]="organizationsBaseSearch"
                   [(ngModel)]="organizationExecutor"
                   [allowClear]="true"
                   [showCode]="false" (onSelect)="selectOrganization($event)" (onClear)="clearOrganization()">
        </catalogue>
    </div>
    <div class="form-group" *ngIf="subservice.kndInfo.kndKinds.length > 1">
        <p class="text-bold mb-15">Виды КНД</p>
        <div class="checkbox-block" *ngFor="let kndKind of subservice.kndInfo.kndKinds">
            <input type="checkbox" class="checkbox" id="kndKind{{kndKind.code}}" [checked]="checkUsedKndKind(kndKind)" (change)="toggleKndKinds(kndKind)">
            <label for="kndKind{{kndKind.code}}">{{kndKind.name}}</label>
        </div>
    </div>
    <div class="form-group">
        <p class="text-bold mb-15">Ответственные инспекторы</p>
        <table class="table table-hover table-xs">
            <tbody>
            <tr *ngFor="let inspector of appealService.appeal.inspectors">
                <td>{{inspector.name}}</td>
                <td>{{inspector.position}}</td>
                <td class="text-right">
                    <button class="btn btn-default btn-sxs" (click)="deleteInspector(inspector)"><i class="icon-trash"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
        <button class="btn btn-default mt-10" (click)="isProcessSelectInspectors = true" *ngIf="!isProcessSelectInspectors">Выбрать инспектора</button>

        <div class="bg-primary-50 p-20" *ngIf="isProcessSelectInspectors">
            <catalogue catalogName="users" [large]="true" (onSelect)="selectInspector($event)" [projection]="'usersList'" placeholder="Выберите инспектора..."
                       [(ngModel)]="selectedInspector"
                       [baseSearch]="baseSearchForInspectors" [returnFields]="['_id', 'name', 'login', 'position']"></catalogue>
        </div>
    </div>

    <!-- Привлекаемые эксперты -->
    <div class="form-group">
        <p class="text-bold mb-15">Привлеченные эксперты</p>
        <table class="table table-hover table-xs">
            <tbody>
            <tr *ngFor="let expert of appealService.appeal.experts">
                <td>{{expert.name}}</td>
                <td>{{expert.position}}</td>
                <td class="text-right">
                    <button class="btn btn-default btn-sxs" (click)="editExpert(expert)"><i class="icon-pencil3"></i></button>
                    <button class="btn btn-default btn-sxs" (click)="deleteExpert(expert)"><i class="icon-trash"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
        <button class="btn btn-default mt-10" (click)="editExpert()" *ngIf="!isProcessSelectExpert">Добавить эксперта</button>

        <div class="bg-primary-50 p-20" *ngIf="isProcessSelectExpert">
            <p class="text-bold mb-15">Добавление эксперта</p>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group form-group-lg">
                        <label>ФИО эксперта</label>
                        <input type="text" [(ngModel)]="editedExpert.name" class="form-control" placeholder="Фамилия Имя Отчество">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group form-group-lg">
                        <label>Должность эксперта</label>
                        <input type="text" [(ngModel)]="editedExpert.position" class="form-control" placeholder="Должность">
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Наименование экспертной организации</label>
                    <div class="form-group form-group-lg">
                        <input type="text" [(ngModel)]="editedExpert.organization" class="form-control" placeholder="Наименование экспертной организации">
                    </div>
                </div>
            </div>
            <div class="form-group form-group-lg">
                <label>Свидетельство об аккредитации</label>
                <textarea [(ngModel)]="editedExpert.accreditationOrganization" class="form-control" placeholder="Свидетельство об аккредитации"></textarea>
            </div>

            <button class="btn btn-primary" (click)="applyExpert()">Применить</button>
            <button class="btn btn-default ml-10" (click)="isProcessSelectExpert = false; addedExpert = {}">Отмена</button>

        </div>
    </div>
</div>
