import { NgModule } from '@angular/core';
import { EpguIntegrationSectionComponent } from './epgu-integration-section.component';
import { RouterModule } from '@angular/router';
import { FieldsComplienceComponent } from './components/fields-complience/fields-complience.component';
import { EpguIntegrationComponent } from './components/epgu-integration/epgu-integration.component';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { MappingItemComponent } from './components/mapping-item/mapping-item.component';
import { EpguIntegrationService } from './services/epgu-integration.service';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';
import { DeleteConfirmationModalModule } from '../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.module';

@NgModule({
    imports: [
        RouterModule,
        SharedComponentsModule,
        BreadcrumbsModule,
        DeleteConfirmationModalModule,
    ],
    declarations: [
        EpguIntegrationComponent,
        MappingItemComponent,
        EpguIntegrationSectionComponent,
        FieldsComplienceComponent,
    ],
    providers: [EpguIntegrationService],
    exports: [],
})

export class EpguIntegrationModule {

}
