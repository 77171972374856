import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AccessService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

import { CommonAppealDocumentService } from '../../common-appeal-document.service';
import { Permission } from '../../../../../../common/services/permission';
import { CommonAppealStatusService } from '../../../../services/common-appeal-status.service';

@Component({
    selector: 'common-appeal-subservice-document-groups',
    templateUrl: 'common-appeal-subservice-document-groups.component.html',
})
export class CommonAppealSubserviceDocumentGroupsComponent implements OnInit {
    @Input() public appeal; // формируемое дело
    @Input() public appealSubservice; // Настройки услуги в деле
    @Input() public groupCode = ''; // Код группы
    @Input() public task; // задача процесса
    @Input() public onlyGroups; // обработка только определенных групп
    @Input() public allowEdit = true;
    @Input() public onlyNotEmpty = false;
    @Input() public mode;

    @Output() public onEditDocument = new EventEmitter<object>(); // Перевод в режим редактирования документа (нажатие кнопки "Редактировать")
    @Output() public onPrintDocument = new EventEmitter<object>(); // Передача управления в родительский компонент для формирования печатной формы
    @Output() public onDeleteDocument = new EventEmitter<object>(); // Передача управления в родительский компонент для удаления документа дела
    @Output() public onApplyDocument = new EventEmitter<object>(); // Передача управления в родительский компонент для удаления документа дела
    @ViewChildren('documentLink') public documentLinks;
    @Output() public isCheckFileSign = new EventEmitter<boolean>();

    public groups = [];

    public allowAddDocument = true; // Возможность добавления документов в группы документов
    public permissions = Permission; // Набор прав системы

    public constructor(
        public accessService: AccessService,
        private documentService: CommonAppealDocumentService,
        private appealStatusService: CommonAppealStatusService,
    ) {
    }

    /**
     * Инициализация компонента - получение списка груп для услуги дела
     */
    public ngOnInit() {
        this.initGroups();
    }

    /**
     * Инициализация данных групп документов
     */
    public initGroups() {
        if (this.appealSubservice) {
            this.groups = this.documentService.appealSubservicesData[this.appealSubservice.guid].appealDocumentGroups;
            if (this.onlyGroups && this.onlyGroups.length) {
                this.groups = this.groups.filter(group => this.onlyGroups.includes(group.code));
            }
            this.allowAddDocument = !this.appealStatusService.finishStatuses.includes(this.appealSubservice.status.code);

            return;
        }

        // Определение параметров дополнительных групп документов
        let groupName = 'Прочие документы';
        if (this.groupCode) {
            if (this.groupCode === 'complex') {
                groupName = 'Документы комплексного запроса';
            }
        }
        const findOtherGroup = this.groups.find(item => item.name === groupName);
        if (!findOtherGroup) {
            this.groups.push({guid: null, name: groupName, min: 0, isRequired: false, code: this.groupCode});
        }
        this.allowAddDocument = this.appealStatusService.allowEdit;
    }

    /**
     * КНМ доступности кнопки "Добавить документ"
     * @param group
     * @returns {boolean}
     */
    public checkAllowAddDocument(group) {
        return this.allowAddDocument
               || this.appealSubservice && this.appealSubservice.status.code === 'beforeIssued'
                  && group.code === 'documentsRequests' && this.accessService.hasAccess([this.permissions.Envelope_Create]);
    }

    /**
     * Добавление документа в дело
     * @param group - группа, в которую осуществляется добавление
     */
    public addDocument(group) {
        const documentGuid = CommonUtilities.GenerateGuid();
        const document: any = {
            guid : documentGuid,
            groupName: group.name,
            subjects: [],
            parentEntries: (this.appeal.subservice.appealsCollection ? this.appeal.subservice.appealsCollection : 'appeals') + '.documents',
            printForm: null,
            mnemonic: group.mnemonic,
        };
        if (group.guid) {
            document.groupGuid = group.guid;
        }
        if (this.appealSubservice) {
            document.subserviceGuid = this.appealSubservice.guid;
        }
        if (group.code) {
            document.groupCode = group.code;
        }

        if (this.task && this.task.currentEntityGuid) {
            document.entityGuid = this.task.currentEntityGuid;
        }

        // Если документ добавляется после регистрации дела / услуги, то к нему добавляется специальное свойство
        if (this.appealSubservice && this.appealSubservice.status.code !== 'draft' || !this.appealSubservice && this.appeal.status.code !== 'draft') {
            document.afterRegister = true;
        }
        this.documentService.tempData = cloneDeep(this.documentService.data);

        // Инициализация данных в сервисе
        this.documentService.initDocumentDataFromAppeal(document);

        // this.validateService.initValidateElement('documents', documentGuid);
        if (!group.docs) {
            group.docs = [];
        }
        if (group.docs && group.docs.length === 1) {
            const documentData = this.documentService.data[documentGuid];
            this.documentService.selectDocumentKind(document, documentData, group.docs[0]);
            this.onApplyDocument.emit({document: document});
        } else {
            this.onEditDocument.emit({document: document, group: group});
        }
    }

    /**
     * Перевод отдельного документа в режим редактирования (нажатие кнопки "Редактировать")
     * @param data - {document: редактируемый документ; group: группа документа}
     */
    public editDocument(data) {
        this.onEditDocument.emit(data);
    }

    /**
     * Определение числа документов дела в группе
     * @param group - обрабатываемая группа
     * @returns {number}
     */
    public countDocumentsInGroup(group) {
        return this.appeal.documents.filter(item => {
            return (item.groupGuid && item.groupGuid === group.guid && item.subserviceGuid === this.appealSubservice.guid)
                    || (!item.groupGuid && !group.guid && !group.code && !item.groupCode)
                    || (group.code === 'complex' && item.groupCode === 'complex');
        }).length;
    }

    /**
     * Получение списка ошибок для группы документов
     * @param group - проверяемая группа
     * @returns {string}
     */
    public getErrorsList(group) {
        return; // this.validateService.getElementErrorsList(group.guid, 'documentGroups', this.appealSubservice.guid);
    }

    /**
     * КНМ наличия ошибок в группе документов
     * @param group - проверяемая группа
     */
    public checkGroupValid(group) {
        if (group.guid) {
            return; // this.validateService.checkElementValid(group.guid, 'documentGroups', this.appealSubservice.guid);
        } else {
            return true;
        }
    }

    /**
     * Отправка на формирование печатной формы
     * @param document
     */
    public printDocument(document) {
        this.onPrintDocument.emit(document);
    }

    /**
     * Передача управления в родительский компонент для удаления документа дела
     * @param document
     */
    public deleteDocument(document) {
        this.onDeleteDocument.emit(document);
    }

    public checkallowEdit(event) {
        this.isCheckFileSign.emit(event);
    }

    public dropDocument(event, droppedGroup: any): void {
        const draggedDocument = event.item.data;

        if (draggedDocument.groupName !== droppedGroup.name) {
            draggedDocument.groupName = droppedGroup.name;

            if (this.appealSubservice) {
                draggedDocument.subserviceGuid = this.appealSubservice.guid;
            }

            if (droppedGroup.name === 'Прочие документы') {
                delete draggedDocument.groupCode;
                delete draggedDocument.groupGuid;
            } else {
                draggedDocument.groupCode = droppedGroup.code;
                draggedDocument.groupGuid = droppedGroup.guid;
            }

            this.documentService.initDocumentDataFromAppeal(draggedDocument);

            if (Array.isArray(droppedGroup.docs) && droppedGroup.docs.length === 1) {
                const documentData = this.documentService.data[draggedDocument.guid];
                this.documentService.selectDocumentKind(draggedDocument, documentData, droppedGroup.docs[0]);
            }
        }
    }

    public isShowDocument(group, document) {
        return (document.groupGuid && document.groupGuid === group.guid
                && document.subserviceGuid === this.appealSubservice.guid
                && (!this.task || !this.task.currentEntityGuid || !document.entityGuid || document.entityGuid === this.task.currentEntityGuid))
                || (!document.groupGuid && !group.guid && !group.code && !document.groupCode)
                || (group.code === 'complex' && document.groupCode === 'complex');
    }
}
