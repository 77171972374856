import { Injectable } from '@angular/core';
import { RestService } from '@evolenta/core';
import { from, Observable } from 'rxjs';
import chain from 'lodash-es/chain';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import map from 'lodash-es/map';
import omit from 'lodash-es/omit';
import concat from 'lodash-es/concat';

@Injectable()
export class StandardService {
    public invalid = false;

    public data = {
        serviceName: '',
        unit: {
            name: '',
        },
    };

    public baseStandard;
    public standard;
    public standardProcess;
    public standardPrintForms = [];
    public standardRequests = [];

    public constructor(
        private rest: RestService,
    ) {
    }

    public async save() {
        if (!this.invalid) {
            await this.rest.create('reglaments', this.data);
        }
    }

    public prepareListAfterSelect = (oldList = [], newList) =>
        chain(oldList)
        .concat(newList)
        .uniqBy('_id')
        .value()

    public updateStandardEntryList = (list, value, key = '_id') => {
        const copy = cloneDeep(list);
        const isExist = copy.find(item => item[key] === value[key]);
        if (isExist) {
            return map(copy, item => item[key] === value[key] ? value : item);
        } else {
            copy.push(value);

            return copy;
        }
    }

    /**
     * Удаление данных привязки элементов стандарта к задачам процесса при удалении соответствующего элемента
     * @param entityGuid
     */
    public removeEntityDataFromTaskOnDelete(entityGuid) {
        this.clearEntityDataInTasks(this.standard.process.tasks, entityGuid);
    }

    /**
     * Очистка данных о привязке к задачам процесса
     * @param tasks
     * @param entityGuid
     */
    public clearEntityDataInTasks(tasks, entityGuid) {
        const fields = ['additionalDataTabs', 'entities', 'documents'];
        tasks.forEach(task => {
            fields.forEach(field => {
                if (task[field]) {
                    const findIndex = task[field].findIndex(item => {
                        return item.guid === entityGuid;
                    });
                    if (findIndex !== -1) {
                        task[field].splice(findIndex, 1);
                    }
                }
            });
            if (task.subProcess) {
                this.clearEntityDataInTasks(task.subProcess.tasks, entityGuid);
            }
        });
    }

    public generateProcessDataToStandard(process, standardProcess) {
        let tasks = [];
        if (standardProcess) {
            tasks = standardProcess.tasks;
        }
        const actualTasks = [];
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    const find = tasks.find(item => item.guid === task.guid);
                    if (!find) {
                        tasks.push({guid: task.guid, id: task.id, type: taskType});
                    }
                });
            });
            tasks.forEach(standardTask => {
                let taskIsFind = false;
                Object.keys(process.tasks).forEach(taskType => {
                    const findTask = process.tasks[taskType].find(item => item.guid === standardTask.guid);
                    taskIsFind = findTask ? true : taskIsFind;
                });
                if (taskIsFind) {
                    actualTasks.push(standardTask);
                }
            });
        }

        return {id: process._id, tasks: actualTasks};
    }

    public correctProcessDataOnInit() {
        if (this.standardProcess) {
            if (this.standardProcess.tasks && this.standardProcess.tasks.subProcess && this.standardProcess.tasks.subProcess.length > 0) {
                this.standardProcess.tasks.subProcess.forEach(subProcess => {
                    if (subProcess.subProcess) {
                        const findTaskInStandard = this.standardProcess.tasks.find(item => item.guid === subProcess.guid);
                        findTaskInStandard.subProcess = this.generateProcessDataToStandard(subProcess.subProcess, findTaskInStandard);
                    }
                });
            }
            this.standard.process = this.generateProcessDataToStandard(this.standardProcess, this.standard.process);
        }
    }

    public prepareStandardForSave(standard) {
        const copy = cloneDeep(standard);
        copy.printForms = map(standard.printForms, item => item._id);

        return copy;

    }

    public loadSubProcesses(processes) {
        const subProcesses = processes && processes.length > 0 ? cloneDeep(processes[0].subProcesses) : [];
        const subIds = subProcesses.map(item => item.id);
        const processParams = {sear: {search: [{field: '_id', operator: 'in', value: subIds}]}};

        return this.rest.search('sperBpmnProcesses', processParams);

    }

    public prepareSubProcessAfterSelect(processes = [], newList, additionalData) {
        const {taskId} = additionalData;
        const copy = cloneDeep(processes);

        if (copy.length > 0) {
            const subProcesses = newList.map(item => ({...item, taskId}));
            if (copy[0].subProcesses) {
                copy[0].subProcesses = concat(copy[0].subProcesses, subProcesses);
            } else {
                copy[0].subProcesses = subProcesses;
            }
        }

        return copy;
    }

    /**
     * Связь сущностей (сведения, документы и т.д) с задачами подпроцессов
     * @param list - список подпроцессов
     * @param entity - сущность (сведения, документы и т.д)
     * @param key - Наименование сущности (сведения, документы и т.д)
     */
    public prepareEntitiesProcessForSave(list, entity, key) {
        return chain(list)
            .map(item => {
                const tasks = item.linkedUserTasks ? cloneDeep(item.linkedUserTasks) : cloneDeep(item.tasks.userTasks);
                const linkedUserTasks = tasks.map(ut => {
                        let entities = ut[key] ? ut[key] : [];
                        const userTask = item.tasks.userTasks.find(v => v.id === ut.id);
                        const newEntity = {
                            guid: entity.guid,
                            isUse: !!userTask.isUse,
                            isRequired: !!userTask.isRequired,
                        };
                        entities = this.updateStandardEntryList(entities, newEntity, 'guid');
                        const data = {id: ut.id};
                        data[key] = entities;

                        return data;
                    },
                );

                return {
                    ...item,
                    linkedUserTasks,
                };
            })
            .map(item => ({
                ...item,
                tasks: {
                    ...item.tasks,
                    userTasks: map(item.tasks.userTasks, task => omit(task, ['isRequired', 'isUse'])),
                },
            }))
            .value();
    }

    public parseLinkedUserTasks(subProcesses, guid, key) {
        const copy = cloneDeep(subProcesses);

        return map(copy, item => ({
            ...item,
            tasks: {
                ...item.tasks,
                userTasks: map(item.tasks.userTasks, task => {
                    const filteredList = chain(copy)
                        .map(v => v.linkedUserTasks)
                        .flatten()
                        .map(v => {
                            const data = {...v};
                            data[key] = data[key] && data[key].filter(vv => vv.guid === guid);

                            return data;
                        })
                        .value();
                    const linkedTask = filteredList.find((v: any) => v.id === task.id);

                    return {
                        ...task,
                        isUse: linkedTask && linkedTask[key] ? linkedTask[key][0].isUse : false,
                        isRequired: linkedTask && linkedTask[key] ? linkedTask[key][0].isRequired : false,
                    };
                }),
            },
        }));
    }

    public findTaskInStandard(taskGuid, standard) {
        let task = standard.process.tasks.find(item => {
            return item.guid === taskGuid;
        });
        if (!task) {
            const taskWitSubProcess = standard.process.tasks.filter(item => {
                return item.subProcess;
            });
            taskWitSubProcess.forEach(parentTask => {
                if (!task) {
                    task = parentTask.subProcess.tasks.find(item => {
                        return item.guid === taskGuid;
                    });
                }
            });
        }

        return task;
    }

    public getLinkedUserTasks(subProcesses, guid, key) {
        const tasks = this.parseLinkedUserTasks(subProcesses, guid, key);

        return chain(tasks)
            .map(item => item.tasks.userTasks)
            .flatten()
            .filter(item => item.isUse)
            .value();
    }

    public getAuthorizedOrganizations(): Observable<any> {
        const searchCriterias = [{field: 'isAuthorized', operator: 'eq', value: true}];
        const promise = this.rest.search('organizations', {search: {search: searchCriterias}});

        return from(promise);
    }

    public getNsiKnmNpas(): Observable<any> {
        const promise = this.rest.search('nsiKnmNpa');

        return from(promise);
    }
}
