<div class="panel panel-shadow m-20">
    <div class="panel-heading"
         [ngClass]="{'pl-20 pr-20': mode == 'view'}">
        <div class="panel-title">
            <!-- Заголовок объекта -->
            <h4 class="no-margin-top mb-5">
                {{object.name ? object.name : 'Новый объект'}}
            </h4>
            <div>
                <span class="badge badge-info text-uppercase mr-10">
                    {{localizations['appeals.objects'].real_estate}}
                </span>
                <span class="badge bg-orange-300 text-uppercase"
                      *ngIf="object.kind.subKind"
                      [ngClass]="{'cursor-pointer': mode === 'edit' && availableKinds && availableKinds[0].subKinds.length > 1}"
                      (click)="changeObjectSubKind()">
                    {{object.kind.subKind.name}}
                </span>
            </div>
        </div>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default"
                    [title] = "allowEdit ? 'Редактировать' : 'Просмотреть'"
                    (click)="edit()"
                    *ngIf="mode == 'view' && allowEdit">
                <b>
                    <i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : ''"></i>
                </b>
            </button>
            <button class="btn btn-default"
                    [title]="localizations.common.delete"
                    (click)="deleteObject()"
                    *ngIf="mode == 'view' && allowDelete">
                <b>
                    <i inlineSVG="assets/icon-trash.svg"></i>
                </b>
            </button>
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="apply()"
                    *ngIf="mode == 'edit' && allowEdit
                           && accessService.hasAccess([permissions.Appeal_Object_Update, permissions.Appeal_Object_Create], false, appeal.status)">
                {{localizations.common.apply}}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()"
                    *ngIf="mode == 'edit'">
                <span *ngIf="allowEdit && accessService.hasAccess([permissions.Appeal_Object_Update], true, appeal.status)">
                    {{localizations.common.do_cancel}}
                </span>
                <span *ngIf="!allowEdit || !accessService.hasAccess([permissions.Appeal_Object_Update], true, appeal.status)">
                    {{localizations.common.close}}
                </span>
            </button>
        </div>
    </div>
    <div class="panel-body no-padding no-margin-bottom">

        <!-- Выбор типа объекта -->
        <div class="ml-20 mr-20 mb-20 p-20 mt-20 border-top border-right border-bottom border-left border-grey-120"
             *ngIf="isEditObjectSubKind && mode === 'edit'">
            <p class="text-bold no-margin">
                {{localizations['appeals.objects'].select_object_type}}:
            </p>
            <div class="radio-inline"
                 *ngFor="let sub of availableKinds[0].subKinds">
                <input type="radio"
                       class="radio"
                       name="subkind"
                       [checked]="object.kind && object.kind.subKind && object.kind.subKind.name == sub.name"
                       (click)="changeSubKind(sub)"
                       id="subkind{{sub.specialTypeId}}{{sub.type ? sub.type : ''}}">
                <label for="subkind{{sub.specialTypeId}}{{sub.type ? sub.type : ''}}">
                    {{sub.name}}
                </label>
            </div>
        </div>

        <!-- Формы спецобъектов -->
        <ng-container *ngIf="!isEditObjectSubKind">

            <div class="m-20"
                 *ngIf="mode === 'edit'">
                <ng-container *ngIf="object.kind.subKind.xsd">
                    <dynamic-form [xsd]="object.kind.subKind.xsd"
                                     [model]="object.data.realty"
                                     [saveInMainObject]="true">
                    </dynamic-form>
                </ng-container>
            </div>


            <!-- Карточка предпросмотра объекта -->
            <div class="p-20"
                 *ngIf="mode == 'view' || object.name">
                <object-preview-card [object]="object"
                                     [showName]="false">
                </object-preview-card>

<!--                <ng-container *ngIf="mode == 'view' && objectSubservices.length > 0">-->
<!--                    <p class="text-muted">Участие в КНМ:</p>-->
<!--                    <ul>-->
<!--                        <li *ngFor="let subservice of objectSubservices">-->
<!--                            <p class="mb-3">-->
<!--                                {{subservice.title}}-->
<!--                                <ng-container *ngIf="subservice.subjects.length > 0">-->
<!--                                    <span class="text-muted ml-10">Субъекты: </span>-->
<!--                                    <ng-container *ngFor="let subject of subservice.subjects; let lst = last">{{subject}}<ng-container *ngIf="!lst">, </ng-container></ng-container>-->
<!--                                </ng-container>-->
<!--                            </p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </ng-container>-->
            </div>

            <!-- Привязка к услугам -->
            <appeal-subject-subservices [entity]="object"
                                        [appeal]="appeal"
                                        [subservice]="subservice"
                                        *ngIf="mode == 'edit' && !isEditObjectSubKind">
            </appeal-subject-subservices>
        </ng-container>
    </div>
</div>

<modal-dialog [message]="modalMessage"
             [title]="modalTitle"
             (onSelect)="processingModal($event)">
</modal-dialog>

<!-- модальное окно для выбора заявителя -->
<ng-template #selectObjectModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{localizations['appeals.objects'].select_object}}
        </h4>
        <button type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="radio-block"
             *ngFor="let object of findedObjects">
            <input type="radio"
                   id="object{{object._id}}"
                   class="radio"
                   (click)="selectedObject = object">
            <label for="object{{object._id}}">
                <p class="text-bold mb-3">
                    {{object.name}}
                </p>
                <p>
                    <span class="text-muted">
                        {{localizations['appeals.objects'].select_object}}:
                    </span>
                    {{getAddress(object.address)}}
                </p>
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="selectObject()"
                [disabled]="!this.selectedObject">
            {{localizations.common.select}}
        </button>
        <button type="button"
                class="btn btn-default"
                (click)="modalRef.hide()">
            {{localizations.common.cancel}}
        </button>
    </div>
</ng-template>
