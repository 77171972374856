<table class="table table-xxs table-hover">
    <tbody>
    <tr *ngFor="let file of data.queue; let fst = first; let lst = last">
        <td class="pl-20" [ngClass]="{'no-border-top': fst,  'border-bottom border-bottom-default': !lst && !data.allowEdit && !data.allowPartiallyEdit}">
            <ng-container *ngIf="file._id">
                <span *ngIf="!accessService.hasAccess([permissions.File_Operations]) || !allowFileAccess(file)">
                    {{ file?.file?.name || file.originalName }}
                </span>
                <a *ngIf="accessService.hasAccess([permissions.File_Operations]) && allowFileAccess(file)"
                   class="anchor"
                   (click)="downloadFile(file._id, file.originalName)">
                    {{ file?.file?.name || file.originalName }}
                </a>
            </ng-container>
            <span *ngIf="!file._id"
                  class="anchor text-info"
                  (click)="openFile(file)">{{ file.name }}</span>
            <span *ngIf="allowEdit && file._id && !file.certificate && (data.allowEdit || data.allowPartiallyEdit) && accessService.hasAccess([permissions.File_Sign])"
                  class="anchor text-size-small text-info ml-20"
                  (click)="signFile(file)">подписать</span>
            <div class="display-inline-block ml-10" *ngIf="file.certificate">
                <i class="icon-medal text-danger cursor-pointer"
                   (click)="openCertificate(file.certificate)"
                   title="Открыть сертификат"></i>
                <span *ngIf="file.certificate"
                      class="text-size-small mr-10">{{getFileCertificateInfo(file)}}</span>
                <span class="anchor text-size-small text-info"
                      (click)="verifySignFile(file)">проверить</span>
                <span *ngIf="file.signature"
                      class="anchor text-size-small text-info ml-10"
                      (click)="createSignFile(file)">файл подписи</span>
                <span *ngIf="file.signature && isAllowCreatePdfWithSignature(file)"
                      class="anchor text-size-small text-info ml-10"
                      (click)="createPdfWithSignatureFile(file)">скачать PDF c подписью</span>
            </div>
            <span *ngIf="allowEdit && file.certificate && (data.allowEdit || data.allowPartiallyEdit) && accessService.hasAccess([permissions.File_Sign])"
                  class="anchor text-size-small text-info ml-10"
                  (click)="signFile(file)">переподписать</span>
        </td>
        <td class="text-right pr-20" [ngClass]="{'no-border-top': fst, 'border-bottom border-bottom-default': !lst && !data.allowEdit && !data.allowPartiallyEdit}">
            <ul class="icons-list">
                <li *ngIf="document.requestId && data.envelope && file.envelopeDocGroupGuid">
                    <button type="button" class="btn btn-default btn-xs btn-sxs"
                            (click)="generateResultPdfForResultDoc(file.envelopeDocGroupGuid)"
                            title="Сформировать PDF">
                        <i class="icon-file-pdf"></i>
                    </button>
                </li>
                <li *ngIf="allowEdit">
                    <button type="button" class="btn btn-default btn-xs btn-sxs" [disabled]="!allowEdit"
                            title="Открепить файл"
                            *ngIf="data.allowEdit || data.allowPartiallyEdit"
                            (click)="removeFile(file)">
                        <i class="icon-trash"></i>
                    </button>
                </li>
            </ul>
        </td>
    </tr>
    </tbody>
</table>

<div class="m-15"
     *ngIf="showButtonsForWorkingWithFiles()">
    <div class="display-flex">
        <div class="width-auto">
            <input type="file"
                   name="file-{{document.guid}}"
                   id="file-{{document.guid}}"
                   [multiple]="multiple"
                   class="inputfile hide"
                   (change)="fileChange($event)"/>
            <label for="file-{{document.guid}}"
                   class="btn btn-primary-overline "
                   *ngIf="accessService.hasAccess([permissions.File_Operations])">
                <span>
                    Прикрепить
                    <ng-template [ngIf]="group?.scanFormat">
                        *.{{group.scanFormat}}
                    </ng-template>
                </span>
            </label>
        </div>

        <button type="button"
                class="btn btn-primary-overline ml-10"
                [disabled]="isProcessingScan"
                *ngIf="accessService.hasAccess([permissions.Documents_Scan]) && !isFinancialControl()"
                (click)="scanFiles()">
            Сканировать
        </button>
    </div>

    <div class="text-size-small text-muted mt-10"
         *ngIf="uploadedFileMaxSize > 0">
        <span class="text-semibold">Внимание!</span> Файл не должен превышать {{uploadedFileMaxSizeInMb}} Мб
    </div>
</div>

<!-- Диалог для подписания файлов -->
<evolenta-cades-sign-modal
        (onSignCompletedEnvelope)="onSignatureComplete($event)"
        (onVerifyCadesBesSignCompleted)="onVerifyCadesBesSignComplete($event)"
></evolenta-cades-sign-modal>
