import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToasterService, TranslateService } from '@evolenta/core';

interface DateOptionsType {
    days: { id: number; text: string }[];
    months: { id: number; text: string }[];
}

@Component({
    selector: 'holidays-settings',
    templateUrl: './holidays.component.html',
})
export class HolidaysComponent implements OnInit {
    @Input() public holidaysSrc: any;
    @Output() public onHolidaysChanged = new EventEmitter<any>();
    public holidays: { name: string; day: number; month: number }[] = [];
    public errors: { idx: number; fields: ('name' | 'date')[] }[] = [];

    public dateOptions: DateOptionsType;

    public localizations;

    public constructor(
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        this._initDateOptions();

        this.holidays = JSON.parse(JSON.stringify(this.holidaysSrc.holidayList));
    }

    private _loadTranslations() {
        this.translate.get(['common', 'global-calendar']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    private _getFilledArray(length: number): number[] {
        return new Array(length).fill(null).map((_, idx) => idx + 1);
    }

    private _initDateOptions(): void {
        const months = this._getFilledArray(12).map((month) => {
            const monthKey = `${month}`.padStart(2, '0');
            return {
                id: month,
                text: this.localizations['global-calendar'].months[monthKey],
            };
        });

        const days = this._getFilledArray(31).map((day) => ({ id: day, text: day.toString() }));

        this.dateOptions = {
            months,
            days,
        };
    }

    public getDayOptions(month: number): DateOptionsType['days'] {
        const shortMonths = [2, 4, 6, 9, 11];

        if (month === 2) {
            return this.dateOptions.days.slice(0, 29);
        } else if (shortMonths.includes(month)) {
            return this.dateOptions.days.slice(0, 30);
        }

        return this.dateOptions.days;
    }

    public getSelectedDateValue(val: number, type: 'day' | 'month'): { id: number; text: string }[] {
        return type === 'day'
            ? this.dateOptions.days.filter((day) => day.id === val)
            : this.dateOptions.months.filter((month) => month.id === val);
    }

    private _correctDay(idx: number) {
        const days = this.getDayOptions(this.holidays[idx].month);
        const foundDay = days.find((day) => day.id === this.holidays[idx].day);
        if (!foundDay) {
            this.holidays[idx].day = days[days.length - 1].id;
        }
    }

    public selectDayValue(val: number, idx: number): void {
        this.holidays[idx].day = val;
        this._correctDay(idx);
    }

    public selectMonthValue(val: number, idx: number) {
        if (val !== this.holidays[idx].month) {
            this.holidays[idx].month = val;
            this._correctDay(idx);
        }
    }

    public addHoliday(): void {
        this.holidays.push({ name: '', month: 1, day: 1 });
    }

    public removeHoliday(idx: number): void {
        this.holidays.splice(idx, 1);
    }

    private isUpdated(daysSrc: any[], daysCurr: any[]): boolean {
        const sortCb = (a, b) => {
            const month = a.month - b.month;
            return month === 0 ? a.day - b.day : month;
        };
        const src = daysSrc.slice().sort(sortCb);
        const curr = daysCurr.slice().sort(sortCb);
        return JSON.stringify(src) !== JSON.stringify(curr);
    }

    public async save(): Promise<void> {
        const isError = this.holidays.some((item) => !item.month || !item.day || !item.name);

        if (!this.isUpdated(this.holidaysSrc.holidayList, this.holidays)) {
            this.toaster.info(this.localizations['global-calendar'].holidays_not_changed);
            return;
        }

        if (isError) {
            this.toaster.error(this.localizations['global-calendar'].fields_not_changed);
        } else {
            const savedData = {
                _id: this.holidaysSrc._id,
                holidayList: this.holidays,
            };
            this.onHolidaysChanged.emit(savedData);
        }
    }
}
