<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h5 class="panel-title">
            {{kndKind.name}}
        </h5>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn text-uppercase"
                    type="btn"
                    (click)="apply()">
                {{ localizations.common.apply }}
            </button>
        </div>
    </div>
    <div class="panel-body no-padding">

        <!-- Категория риска -->
        <div class="p-20"
             *ngIf="!isEditRiskCategory">
            <p class="no-margin">
                <span class="text-bold mr-10 text-uppercase">
                    {{ localizations['entity-knd-kind-data'].class_risk_category }}
                </span>
                <span class="badge bg-slate-300 text-uppercase"
                      *ngIf="!data.riskCategory">
                    {{ localizations.common.no_small }}
                </span>
                <span class="badge text-uppercase"
                      [ngClass]="getRiskCategoryBackground(data.riskCategory.code)"
                      *ngIf="data.riskCategory">
                    {{ data.riskCategory.name }}
                </span>
                <span class="anchor text-info text-size-small ml-15"
                      *ngIf="!isProcessingCalculateRisk"
                      (click)="isEditRiskCategory = true">
                    {{ localizations.common.change_small }}
                </span>
                <span class="anchor text-info text-size-small ml-15"
                      *ngIf="!isProcessingCalculateRisk && checkAllowCalculateRisk()"
                      (click)="calculateRisk()">
                    {{ localizations['entity-knd-kind-data'].calculate }}
                </span>
                <span class="text-muted ml-10"
                      *ngIf="isProcessingCalculateRisk">
                    <i class="icon-spinner2 spinner"></i>
                    {{ localizations['entity-knd-kind-data'].calculation_is_in_process }}
                </span>
            </p>
            <ng-container *ngIf="data.riskChangeHistory && data.riskChangeHistory.length > 0">
                <p class="text-size-small text-muted pt-10 no-margin-bottom">
                    <ng-container *ngIf="data.riskChangeHistory[data.riskChangeHistory.length - 1].type == 'manually'">
                        {{ localizations['entity-knd-kind-data'].assigned_manually_by_user }} {{data.riskChangeHistory[data.riskChangeHistory.length - 1].user?.name}} {{data.riskChangeHistory[data.riskChangeHistory.length - 1].date | date: 'medium'}}
                    </ng-container>
                    <ng-container  *ngIf="data.riskChangeHistory[data.riskChangeHistory.length - 1].type != 'manually'">
                        {{ localizations['entity-knd-kind-data'].calculated_with }}
                        <span *ngIf="data.riskChangeHistory[data.riskChangeHistory.length - 1].type == 'single'">
                            {{ localizations['entity-knd-kind-data'].single_calc }}
                        </span>
                        <span class="text-info anchor"
                              [routerLink]="['/', 'registers', 'risk-calculations', 'edit', data.riskChangeHistory[data.riskChangeHistory.length - 1].groupOperationsRisksId]"
                              *ngIf="data.riskChangeHistory[data.riskChangeHistory.length - 1].type == 'group'">
                            {{ localizations['entity-knd-kind-data'].mass_calc }}
                        </span>
                        {{ localizations['entity-knd-kind-data'].user_calculation }}
                        {{data.riskChangeHistory[data.riskChangeHistory.length - 1].user?.name}}
                        {{data.riskChangeHistory[data.riskChangeHistory.length - 1].date | date: 'medium'}},
                        {{ localizations['entity-knd-kind-data'].approved_by_user }}
                        {{data.riskChangeHistory[data.riskChangeHistory.length - 1].userApprove.name}}
                        {{data.riskChangeHistory[data.riskChangeHistory.length - 1].dateApprove | date: 'medium'}}
                    </ng-container>

                    <span class="text-info anchor text-size-small ml-10"
                          (click)="showRiskChangeHistory = true">
                        {{ localizations.common.history }}
                    </span>
                </p>
                <div *ngIf="showRiskChangeHistory">
                    <ul class="list-feed mt-20 mb-20 mr-20 no-margin-left">
                        <li *ngFor="let item of data.riskChangeHistory">
                            <div>
                                <span class="text-muted">
                                    {{item.date | date: 'medium'}}
                                </span>
                                <span class="text-info ml-5">
                                    {{item.user?.name}}
                                </span>
                            </div>
                            <div>
                                <span class="text-muted">
                                    {{ localizations['entity-knd-kind-data'].value }}
                                </span>
                                {{getRiskCategoryNameByCode(item.value)}}
                                <span class="text-muted ml-10">{{ localizations['entity-knd-kind-data'].type }}</span>
                                {{item.type === 'manually' ?
                                    localizations['entity-knd-kind-data'].set_manually :
                                    item.type === 'single' ?
                                        localizations['entity-knd-kind-data'].single_calculation :
                                        localizations['entity-knd-kind-data'].mass_calculation }}
                                <span class="anchor text-info ml-10 text-size-small"
                                      [routerLink]="['/', 'registers', 'risk-calculations', item.groupCalculationId]" *ngIf="item.groupCalculationId">
                                    {{ localizations.common.proceed_small }}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <button class="btn btn-default btn-xs"
                            (click)="showRiskChangeHistory = false">
                        {{ localizations.common.close }}
                    </button>
                </div>
            </ng-container>

            <div class="mt-15 bg-primary-50 p-20"
                 *ngIf="newCalculatedValue">
                <p class="text-bold">
                    {{ localizations['entity-knd-kind-data'].new_value_calculated }}
                </p>
                <span class="badge text-uppercase"
                      [ngClass]="getRiskCategoryBackground(newCalculatedValue.value)">
                    {{ getRiskCategoryNameByCode(newCalculatedValue.value) }}
                </span>
                <p class="mt-10 text-size-small">
                    <span *ngIf="newCalculatedValue.type === 'single'">
                        {{ localizations['entity-knd-kind-data'].single }}
                    </span>
                    <span class="anchor text-info"
                          *ngIf="newCalculatedValue.type === 'group'"
                          [routerLink]="['/', 'registers', 'risk-calculations', newCalculatedValue.groupCalculationId]">
                        {{ localizations['entity-knd-kind-data'].mass }}
                    </span>
                    {{ localizations['entity-knd-kind-data'].calculation_was_made_by_user }}
                    {{newCalculatedValue.user?.name}}
                    {{newCalculatedValue.date | date: 'medium'}}
                </p>
                <button class="btn btn-success"
                        (click)="applyCalculatedRisk(true)">
                    {{ localizations['entity-knd-kind-data'].approve }}
                </button>
                <button class="btn btn-danger ml-15"
                        (click)="applyCalculatedRisk(false)">
                    {{ localizations['entity-knd-kind-data'].decline }}
                </button>
            </div>

            <div class="mt-15 bg-primary-50 p-20"
                 *ngIf="isApproveRiskCategory">
                <p class="text-bold">
                    {{ localizations['entity-knd-kind-data'].new_value_selected }}
                </p>
                <span class="badge text-uppercase"
                      [ngClass]="getRiskCategoryBackground(data.riskChangeApproval.value)">
                    {{ getRiskCategoryNameByCode(data.riskChangeApproval.value) }}
                </span>
                <p class="mt-10 text-size-small">
                    {{ localizations['entity-knd-kind-data'].change_was_made_by_user }}
                    {{data.riskChangeApproval.user.name}}
                    {{data.riskChangeApproval.date | date: 'medium'}}
                </p>
                <button class="btn btn-success"
                        (click)="approveRiskCategory(true)">
                    {{ localizations['entity-knd-kind-data'].approve }}
                </button>
                <button class="btn btn-danger ml-15"
                        (click)="discardRiskCategory(false)">
                    {{ localizations['entity-knd-kind-data'].decline }}
                </button>
            </div>
        </div>

        <div class="bg-primary-50 p-20"
             *ngIf="isEditRiskCategory">
            <p class="text-bold">
                {{ localizations['entity-knd-kind-data'].select_class_category_of_risk }}
            </p>
            <div class="radio-block"
                 *ngFor="let category of usedRiskCategories">
                <input id="category{{category.code}}"
                       class="radio"
                       type="radio"
                       [checked]="data.riskCategory && data.riskCategory.code === category.code">
                <label for="category{{category.code}}"
                       (click)="selectRiskCategory(category) ">
                    <span class="badge text-uppercase"
                          [ngClass]="getRiskCategoryBackground(category.code)">
                        {{category.name}}
                    </span>
                </label>
            </div>
        </div>

        <hr class="no-margin">

        <!-- Ответственные лица -->
        <fieldset *ngIf="!isProcessingLoadData">
            <legend class="text-bold pl-20 pt-15 no-margin">
                {{ localizations['entity-knd-kind-data'].responsible_persons }}
            </legend>
            <app-person-checking-people [persons]="data.checkingPersons"
                                        (onSave)="saveCheckingPersons($event)">
            </app-person-checking-people>
        </fieldset>

        <!-- Дополнительные поля -->
        <fieldset *ngIf="entityFederalXsd || entityRegionalXsd">
            <legend class="text-bold pl-20 no-margin">
                {{ localizations['appeals.documents'].additional_data }}
            </legend>
            <div class="p-20">
                <dynamic-form tabsMode="hidden"
                                       [xsd]="entityFederalXsd"
                                       [edit]="false"
                                       [model]="data.federalAdditionalData"
                                       [saveInMainObject]="true"
                                       *ngIf="entityFederalXsd">
                </dynamic-form>
                <dynamic-form tabsMode="hidden"
                                       [xsd]="entityRegionalXsd"
                                       [edit]="false"
                                       [model]="data.regionalAdditionalData"
                                       [saveInMainObject]="true"
                                       *ngIf="entityRegionalXsd">
                </dynamic-form>
            </div>
        </fieldset>
    </div>
</div>


