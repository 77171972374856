import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard } from '@evolenta/core';
import { NsiDefaultComponent } from '../../modules/dictionaries/nsi/nsi-default.component';
import { NsiResolve } from '../../modules/dictionaries/nsi/nsi.resolve';
import { NsiDictionaryComponent } from '../../modules/dictionaries/nsi/dictionary/nsi-dictionary.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'nsi',
        component: SidebarComponent,
        data: {
            module: 'nsi',
            breadcrumb: 'Справочники',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        resolve: {
            resolves: NsiResolve,
        },
        children: [
            // Переход к справочнику из компонента catalogue
            {
                path: 'dictionaries/:dictionary/items',
                component: NsiDictionaryComponent,
                data: {
                    name: 'Справочник',
                },
                resolve: {
                    resolves: NsiResolve,
                },
            },
            {
                path: 'default',
                component: NsiDefaultComponent,
                data: {},
            },
            {
                path: '',
                redirectTo: 'external',
                pathMatch: 'full',
                data: {},
            },
            {
                path: '**',
                component: NsiDefaultComponent,
                data: {},
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class NsiRouting {
}
