import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HandlersService, RestService, SelectionService } from '@evolenta/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { BsModalRef } from 'ngx-bootstrap';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { RegistersService } from '../../../registers/registers.service';
import { InternalHandlersService } from '../../../../common/services/internal-handlers.service';
import { CommonAppealSubjectsComponent } from '../appeal-subjects/common-appeal-subjects.component';
import { CommonAppealObjectsComponent } from '../appeal-objects/common-appeal-objects.component';
import { CommonAppealLicensesComponent } from '../appeal-licenses/common-appeal-licenses.component';

@Component({
    selector: 'common-appeal-common',
    templateUrl: 'common-appeal-common.component.html',
    styles: [
        'legend .anchor { text-transform: none; font-weight: normal; }',
        'legend .text-size-mini { text-transform: none; font-weight: normal; }',
        '.text-normal { text-transform: none; font-weight: normal; }',
    ],
})
export class CommonAppealCommonComponent implements OnInit, OnChanges {
    @Input() public appeal;
    @Input() public subservice;
    @Input() public mode = 'edit';
    @Input() public license;
    @Input() public prevRegister;
    @Input() public currentRegister;

    public mainElement;
    public registersModel;
    public appealSubservice;
    public savedPlace;
    public externalData;
    public moduleBaseUrl;
    public currentLicense;

    public editedEntity;
    public editedEntityType;

    public modalRef: BsModalRef;
    public showAdditionalData = true;

    @ViewChild('mainAdditionalData', { static: false }) public mainAdditionalData: DynamicFormComponent;
    @ViewChild('appealAdditionalData', { static: false }) public appealAdditionalData: DynamicFormComponent;
    @ViewChild('appealSubjects', { static: false }) public appealSubjectsBlock: CommonAppealSubjectsComponent;
    @ViewChild('appealObjects', { static: false }) public appealObjectsBlock: CommonAppealObjectsComponent;
    @ViewChild('licenses', { static: false }) public licensesBlock: CommonAppealLicensesComponent;

    @Output() public onEditEntity = new EventEmitter();
    @Output() public onApplyEntity = new EventEmitter();
    @Output() public afterGetLicense = new EventEmitter();
    @Output() public onApplyBlock = new EventEmitter();
    @Output() public onApplyError = new EventEmitter();

    public constructor(
        public appealService: CommonAppealService,
        public validateService: CommonAppealValidateService,
        private selectionService: SelectionService,
        private router: Router,
        private saveService: CommonAppealSaveService,
        private restService: RestService,
        private route: ActivatedRoute,
        private registersService: RegistersService,
        private handlersService: HandlersService,
        private internalHandlersService: InternalHandlersService,
    ) {
    }

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.appealSubservice = this.appeal.subservice;
        this.getRegistryModel();
        if (this.registersModel) {
            if (!this.appealSubservice.mainElement) {
                this.appealSubservice.mainElement = {};
            }
            this.savedPlace = this.appealSubservice.mainElement;
        } else {
            this.savedPlace = this.appealSubservice;
        }
        this.externalData = {appeal: this.appeal, subservice: this.subservice};
        if (this.isAllowEdit) {
            if (this.subservice.xsdHandlers) {
                this.internalHandlersService.processingHandlers(this.subservice.xsdHandlers, 'startBefore', 'onStart', this.externalData);
            }
            if (this.subservice.appealXsdHandlers) {
                this.internalHandlersService.processingHandlers(this.subservice.appealXsdHandlers, 'startBefore', 'onStart', this.externalData);
            }
        }

        if (this.selectionService.isProcessSelect && ['selectObject', 'selectSubject'].indexOf(this.selectionService.transferOperation) !== -1) {
            this.editedEntity = this.selectionService.additionalData.editedObject ? this.selectionService.additionalData.editedObject : this.selectionService.additionalData.editedSubject;
            this.editedEntityType = this.selectionService.additionalData.entityType;
        }
    }

    public getRegistryModel() {
        if (this.subservice.registersModel) {
            this.registersModel = this.subservice.registersModel;
        } else if (this.subservice.mainElement) {
            this.registersModel = {
                registryType: this.subservice.mainElement,
                operationType: this.subservice.additionalParams ? this.subservice.additionalParams.operationType : null,
            };
        }
    }

    public ngOnChanges(changes) {
        if (changes && changes.mode && !changes.mode.firstChange) {
            if (changes.mode.previousValue !== changes.mode.currentValue) {
                this.reInitData();
            }
        }
    }

    public saveAppeal() {
        if (this.appeal._id) {

            this.saveService.saveAppeal();
        }
    }

    public closeModal() {
        this.modalRef.hide();
    }

    public get isAllowEdit() {
        return this.appealSubservice.status.code === 'draft';
    }

    public get isAllowAppealDataEdit() {
        return this.appealSubservice.status.code !== 'complete';
    }

    public get editOnRenewalAndRegistration(): boolean {
        return (this.mainElement.operationType === 'registration' || this.mainElement.operationType === 'renewal') && this.appeal.status.code !== 'complete';
    }

    public reInitData() {
        this.showAdditionalData = false;
        setTimeout(() => {
            this.showAdditionalData = true;
        }, 200);
        if (this.appealSubjectsBlock) {
            this.appealSubjectsBlock.reInitSubjectsData();
        }
        if (this.appealObjectsBlock) {
            this.appealObjectsBlock.reInitObjectsData();
        }
    }

    public checkErrorsExist() {
        return this.validateService.processValidate;
    }

    public isAllowEditSubject(subject) {
        return this.isAllowEdit && (subject.specialTypeId === 'foreignUlApplicant' || this.subservice.objects.subjectXsd || this.subservice.objects.subjectMainXsd);
    }

    public isAllowEditObject(object) {
        return this.isAllowEdit && (object.reestrId || this.subservice.objects.objectXsd || this.subservice.objects.objectMainXsd);
    }

    /**
     * Разрешение редактирования данных субъектов и объектов
     */
    public get allowEditSubjectsAndObjects() {
        return ['annul', 'tech', 'pause', 'continue'].indexOf(this.mainElement.operationType) === -1;
    }

    /**
     * Разрешение на изменение состава субъектов и объектов
     */
    public get allowChangeSubjectsAndObjectsComposition() {
        return ['tech', 'annul', 'pause', 'continue'].indexOf(this.mainElement.operationType) === -1;
    }

    public get allowEditObject() {
        return ['tech', 'annul', 'pause', 'continue'].indexOf(this.mainElement.operationType) === -1;
    }

    public get isShowAddressInObjectPreviewCard(): boolean {
        return !this.isAllowEdit
            || this.mainElement.operationType !== 'tech'
            || !this.mainElement.mainXsdData
            || !this.mainElement.mainXsdData.changeReasonChoice
            || this.mainElement.mainXsdData.changeReasonChoice !== 'changeObjects';
    }

    public isShowEntities(type) {
        const data = type === 'objects' ? 'objectsData' : 'subjectsData';

        return this.appealService.metaReglament && this.appealService.metaReglament.isOldVersion || this.subservice.objects && this.subservice.objects[data] && this.subservice.objects[data].useInMainBlock;
    }

    public editEntity(entity, type) {
        this.editedEntity = entity;
        this.editedEntityType = type;
        this.onEditEntity.emit({entity: entity, type: type});
    }

    public applyEntity() {
        this.editedEntity = null;
        this.editedEntityType = null;
        this.onApplyEntity.emit();
    }

    private _prepareValidate() {
        if (this.validateService.processValidate) {
            // Если запущен процесс валидации, то отображаем ошибки, если они есть
            if (this.mainAdditionalData) {
                this.mainAdditionalData.validate();
            }
            if (this.appealAdditionalData) {
                this.appealAdditionalData.validate();
            }
        }

        // Формируем параметры, по которым будет осуществляться валидация
        this.appealSubservice.xsdDataValid = !this.mainAdditionalData || this.mainAdditionalData && this.mainAdditionalData.isValid;
        this.appealSubservice.appealXsdDataValid = !this.appealAdditionalData || this.appealAdditionalData && this.appealAdditionalData.isValid;
    }

    private _prepareDataForTransferToProcess() {
        if (this.appeal.status.code === 'draft') {
            if (!this.appeal.dataForExecuteAction) {
                this.appeal.dataForExecuteAction = {};
            }
        }
        if (this.mainAdditionalData) {
            const mainDataParams = this.mainAdditionalData.getDataByParam('isTransferToProcess');
            this.appeal.dataForExecuteAction = Object.assign(this.appeal.dataForExecuteAction, mainDataParams);
        }
        if (this.appealAdditionalData) {
            const appealDataParams = this.appealAdditionalData.getDataByParam('isTransferToProcess');
            this.appeal.dataForExecuteAction = Object.assign(this.appeal.dataForExecuteAction, appealDataParams);
        }
    }

    public applyEditedEntity() {
        if (this.editedEntityType === 'subject') {
            this.appealSubjectsBlock.applyEditedSubject();
        } else if (this.editedEntityType === 'object') {
            this.appealObjectsBlock.applyEditedObject();
        }
    }

    public processingBlockData() {
        this._prepareValidate();
        this._prepareDataForTransferToProcess();
    }

    public applyError() {
        this.onApplyError.emit();
    }

    public changeAdditionalForm(type, handlers, model) {
        if (this.validateService.processValidate) {
            if (type === 'appealAdditionalData') {
                this.appealSubservice.appealXsdDataValid = !this.appealAdditionalData || this.appealAdditionalData && this.appealAdditionalData.isValid;
            } else {
                this.appealSubservice.xsdDataValid = !this.mainAdditionalData || this.mainAdditionalData && this.mainAdditionalData.isValid;
            }
            this.validateService.validateAppeal(true);
        }

        if (handlers) {
            const usedHandlers = handlers.filter(item => item.event === 'onChange');
            if (usedHandlers.length > 0) {
                usedHandlers.forEach(handler => {
                    this.handlersService.runHandler(handler, {model: model, appeal: this.appeal});
                });
            }
        }
    }

    public updateData() {
        const promises = [];
        if (this.licensesBlock) {
            promises.push(this.licensesBlock.getLicensesInfo());
        }

        return Promise.all(promises);
    }
}
