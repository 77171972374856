<p [ngClass]="{'no-margin': data.type == 'order'}">
    <span class="text-bold text-uppercase">
        <span class="text-slate">
            {{ data.type === 'order' ?
                localizations.registers.order :
                localizations.appeals.checkup }}
        </span>
        № {{ data.number }}
    </span>
    <span class="ml-5 mr-5">
        {{ localizations.common.from }}
    </span>
    <span class="text-bold">
        {{data.date | date: 'shortDate'}} {{ localizations.common.year_small }}
    </span>
    <ng-container *ngIf="data.type !== 'order'">
        <span class="badge bg-primary-300 text-uppercase ml-10">
            {{ data.type == 'documentsCheck' ?
                localizations.registers.of_document :
                localizations.registers.of_field }}
        </span>
    </ng-container>
</p>
<ng-container *ngIf="data.type !== 'order'">
    <p *ngIf="data.reason">
        <span class="text-muted">
            {{ localizations.registers.audit_basis }}:
        </span>
        {{ data.reason }}
    </p>
    <p *ngIf="data.dateBegin || data.dateEnd">
        <ng-container *ngIf="data.dateBegin">
            <span class="text-muted">
                {{ localizations.registers.checkup_start_date }}:
            </span>
            {{ data.dateBegin | date: 'shortDate' }}
        </ng-container>
        <ng-container *ngIf="data.dateEnd">
            <span class="text-muted"
                  [ngClass]="{'ml-10': data.dateBegin}">
                {{ localizations.registers.checkup_end_date }}:
            </span>
            {{ data.dateEnd | date: 'shortDate' }}
        </ng-container>
    </p>
</ng-container>
<ng-container *ngIf="data.eliminationViolationsPrescription">
    <p>
        <span class="text-muted mr-5">
            {{ localizations.registers.violation_eliminate_prescription }}:
        </span>
        № {{ data.eliminationViolationsPrescription.number }}
        {{ localizations.common.from }}
        {{ data.eliminationViolationsPrescription.date | date: 'shortDate' }}
        {{ localizations.common.year_small }}
        <span class="text-slate ml-5">
            {{ getViolationsEliminatedMessage() }}
        </span>
    </p>
</ng-container>
<ng-container *ngIf="data.violationsDecree">
    <p>
        <span class="text-muted mr-5">
            {{ localizations.registers.resolution_for_penalties }}
        </span>
        № {{data.violationsDecree.number}}
        {{ localizations.common.from }}
        {{data.violationsDecree.date | date: 'shortDate'}}
        {{ localizations.common.year_small }}
    </p>
</ng-container>
<ng-container *ngIf="data.type === 'order' && data.dateFrom">
    <p>
        <span class="text-muted mr-5">
            {{registry.operationType === 'pause' ? localizations.registers.license_suspend_date :
                registry.operationType === 'continue' ?
                localizations.registers.license_starting_date : ''
            }}:
        </span>
        {{data.dateFrom | date: 'dateShort'}}
    </p>

</ng-container>

<fieldset *ngIf="data.documents && data.documents.length > 0">
    <legend class="text-size-mini text-bold mb-10">
        {{ localizations['appeals.documents'].files }}
    </legend>
    <ul class="no-margin">
        <ng-container *ngFor="let document of data.documents; let fstDocument = first; let lstDocument = last">
            <li *ngFor="let file of document.files; let fst = first">
                <a class="anchor"
                   (click)="registersService.downloadFile(file)">
                    {{ file.originalName }}
                </a>
                <div class="display-inline-block ml-20"
                     *ngIf="file.certificate">
                    <i class="icon-medal text-danger cursor-pointer"
                       [title]="localizations.common.open_certificate"
                       (click)="registersService.openCertificate(file.certificate)">
                    </i>
                    <span class="text-size-small mr-10"
                          *ngIf="file.certificate">
                        {{ registersService.getFileCertificateInfo(file) }}
                    </span>
                </div>
            </li>
        </ng-container>
    </ul>
</fieldset>
