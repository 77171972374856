import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ReportsOctonicaService } from './reports-octonica.service';

@Injectable()
export class ReportsOctonicaResolve implements Resolve<any> {

    public constructor(
        private reportsOctonicaService: ReportsOctonicaService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.reportsOctonicaService.getReportTypes();
    }
}
