<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ getCategoryActionMessage() }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="save()"
                    *ngIf="allowEdit">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{allowEdit ? localizations.common.do_cancel : localizations.common.close }}</button>
        </div>
    </div>

    <div class="panel-body">
        <!-- Основные данные -->
        <dynamic-form
            #mainData
            *ngIf="metaData.groupsAndCategoriesData.categoryData.xsd"
            [xsd]="metaData.groupsAndCategoriesData.categoryData.xsd"
            [model]="category"
            [saveInMainObject]="true"
            [disabled]="!allowEdit">
        </dynamic-form>

        <!-- Настройка дополнительных данных -->
        <p *ngIf="metaData.groupsAndCategoriesData.categoryData.allowCreateXsd">
            <span class="text-muted">
                {{ localizations.common.additional_fields }}
            </span>
            <em *ngIf="!category.xsd">
                {{ localizations.common.not_set_up_plural }}
            </em>
            <span *ngIf="category.xsd">
                {{ localizations.common.set_up_plural }}
            </span>
            <span class="text-size-small anchor text-info ml-10"
                  *ngIf="allowEdit || category.xsd"
                  (click)="editXsd()">
                    {{
                        !category.xsd ?
                            localizations.common.setup : allowEdit ?
                            localizations.common.change_small :
                            localizations.common.view_small
                    }}
            </span>
            <span class="text-size-small anchor text-slate ml-10"
                  *ngIf="category.xsd && allowEdit"
                  (click)="category.xsd = null">
                {{ localizations.common.delete_small }}
            </span>
        </p>

        <!-- Выбор вида объекта -->
        <ng-container *ngIf="metaData.groupsAndCategoriesData.categoryData.isObjectTypesSetting">
            <p>
                <span class="text-muted">
                    {{ getSubjectKindsMessage() }}
                </span>
                <em *ngIf="!category.objectTypes || category.objectTypes.length == 0">
                    {{ localizations.common.not_selected_plural }}
                </em>
            </p>
            <table class="table table-xs"
                   *ngIf="category.objectTypes && category.objectTypes.length > 0">
                <tbody>
                <tr *ngFor="let type of category.objectTypes; let idx = index">
                    <td>
                        {{type.name}}
                    </td>
                    <td class="text-right pt-5 pb-5"
                        *ngIf="allowEdit">
                        <button class="btn btn-default btn-sxs"
                                (click)="deleteObjectType(idx)">
                            <i class="icon-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <button class="btn btn-default mt-15"
                    *ngIf="allowEdit"
                    (click)="selectObjectTypes()">
                {{ localizations.standards.select_object_kinds }}
            </button>
        </ng-container>
    </div>
</div>
