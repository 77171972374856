import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToasterService } from '@evolenta/core';
import { AppealEntityRiskEvent } from './models/appeal-entity-risk-event';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-risk-event',
    templateUrl: 'appeal-entity-risk-event.component.html',
})
export class AppealEntityRiskEventComponent implements OnInit {
    @Input() public entity: AppealEntityRiskEvent;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;

    public activeTab = 'common';
    public editTabs = [];

    public constructor(private toaster: ToasterService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.initEditTabs();

        if (!this.entity.eventDate) {
            this.entity.eventDate = '';
        }
    }

    /**
     * Инициализация массива вкладок
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: 'Общие данные',
                active: true,
                visible: true,
            },
            {
                code: 'violations',
                name: 'Нарушения',
                active: false,
                visible: true,
            },
        ];
    }

    /**
     * Установка активной вкладки
     * @param tab
     */
    public activateTab(tab) {
        this.activeTab = tab;
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        return isValidForm;
    }
}
