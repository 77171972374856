import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AccessService,
    ModalDialogComponent,
    RestService,
    SelectionService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { Router } from '@angular/router';
import {
    CommonAppealSubserviceCardComponent,
} from './components/subservice-card/common-appeal-subservice-card.component';
import { CommonAppealDocumentService } from '../appeal-documents/common-appeal-document.service';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { Permission } from '../../../../common/services/permission';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSubservicesService } from '../../services/common-appeal-subservices.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonUtilities } from '@evolenta/utilities';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';
import { AppealService } from '../../../knm/appeals/appeal.service';
import { ErrorLoggingService } from '../../../knm/error-logging.service';
import { SUBSERVICES_ARCHIVE_COLLECTION, SUBSERVICES_COLLECTION } from '../../../../common/constants';

@Component({
    selector: 'common-appeal-subservices',
    templateUrl: './common-appeal-subservices.component.html',
    styles: [
        '.drop-zone { border: 2px dashed transparent; padding: 10px; }',
        '.drop-zone.over { border-color: #bbd2dd }',
    ],
})
export class CommonAppealSubservicesComponent implements OnInit {
    @Input() public complexSubservice; // Комплексная услуга
    @Input() public appeal: any; // Дело
    @Input() public subservice: any = {}; // Услуга дела

    @Output() public onAfterSave = new EventEmitter<string>(); // Передача возможности перехода на другую вкладку после КНМ необходимости сохранения данных
    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)
    @Output() public onExecuteAction = new EventEmitter<object>(); // Инициализация перехода услуги на новый статус
    @Output() public afterSelectSubservice = new EventEmitter<boolean>(); // Обновление списка печатных форм после выбора услуги

    @ViewChild('alertModal', { static: false }) public alertModalComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', { static: false }) public editAppealSubserviceCardComponent: CommonAppealSubserviceCardComponent; // Компонент - карточка редактирования услуги дела

    public editAppealSubservice = null; // Редактируемая услуга дела
    public isEditAppealSettings = false; // Флаг того, что компонент находится в режиме редактирования настроек дела
    public isProcessSetupSubservicesInAppeal = false; // Режим настройки услуг в деле
    public currentAppealSubservicesGuids = []; // Текущие услуги дела (перед процессом настройки порядка и последовательности)

    public activeTab; // Активная вкладка
    public nextTab; // Следующая вкладка, на которую будет осуществлен переход в родительском компоненте после КНМ сохранения измененных данных

    public modalOperation; // Функция компонента, осуществляющая обработку результатов отработки модального окна

    public permissions = Permission; // описание всех доступных прав доступа
    public subservicesStructure; // древовидная структура услуг дела (с последовательно-параллельным режимом выполнения)

    public tempAppealPart;
    public appealProps = ['jointlyKno', 'inspectors', 'experts'];

    public localizations;

    public constructor(
        private accessService: AccessService,
        private appealService: AppealService,
        private commonAppealService: CommonAppealService,
        private rest: RestService,
        private router: Router,
        private appealSubservicesService: CommonAppealSubservicesService,
        private validateService: CommonAppealValidateService,
        private documentService: CommonAppealDocumentService,
        private commonAppealSaveService: CommonAppealSaveService,
        private toaster: ToasterService,
        private selectionService: SelectionService,
        private errorLoggingService: ErrorLoggingService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();

        if (this.selectionService.isProcessSelect) {
            this.completeSelectPrevRegistry();
        } else {
            if (!this.subservice && this.commonAppealService.subservice) {
                this.subservice = this.commonAppealService.subservice;
            }
            if (this.commonAppealService.isProcessSetupAppealSubservices || !this.appeal.subservice && this.appeal.complexSubservice) {
                this.isProcessSetupSubservicesInAppeal = true;
                this._generateAppealSubservicesStructure();
            }
        }
    }

    public completeSelectPrevRegistry() {
        if (this.appeal.subservice.guid !== this.selectionService.additionalData.subserviceGuid) {
            return;
        }
        const selected = this.selectionService.selectedItems[0];
        this.appeal.subservice.mainElement = this.selectionService.additionalData.mainElement;
        this.appeal.subservice.mainElement.registerId = selected._id;
        this.appeal.subservice.mainElement.registerInfo = {auid: selected.auid, dateCreation: selected.dateCreation};
        this.appealSubservicesService.data[this.appeal.subservice.guid].prevRegistry = selected;
        const registryAppeal = this.appealSubservicesService.getAppealFromRegistry(selected);
        if (!registryAppeal) {
            return;
        }

        const processingItems = ['subjects', 'objects', 'documents'];
        processingItems.forEach(entity => {
            if (selected[entity] && selected[entity].length > 0) {
                this.appeal[entity] = registryAppeal[entity];
                this.appeal[entity].forEach(entityItem => {
                    if (entity === 'subjects') {
                        const subjectKind = this.subservice.objects.objectKinds.find(item => item.type === 'participant');
                        entityItem.kind.guid = subjectKind.guid;
                        this.appealSubservicesService.initEntitytDataOnAdd(entityItem, entity, true);
                        this.appealSubservicesService.getAvailableServiceGroups(this.appeal.subservice, entityItem);
                        this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
                    } else if (entity === 'objects') {
                        this.appealSubservicesService.initEntitytDataOnAdd(entityItem, entity, true);
                    }
                });
            }
        });
        if (selected.services && selected.services.length > 0) {
            this.appeal.subservice.mainElement.services = selected.services;
        }

        this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
        this.documentService.correctSubserviceDocGroups();
        this.onEditAppealSubservice(this.appeal.subservice);
        this.selectionService.clearData();
    }

    /**
     * Инициализация режима редактирования услуги
     * @param appealSubservice - услуга дела
     */
    public onEditAppealSubservice(appealSubservice) {
        this.appealSubservicesService.tempData = cloneDeep(this.appealSubservicesService.data);
        this.editAppealSubservice = cloneDeep(appealSubservice);
        this.tempAppealPart = this._extractAppealPartByProps();
    }

    /**
     * Выборка части данных дела
     */
    private _extractAppealPartByProps(): any {
        const appealPart = cloneDeep(pick(this.commonAppealService.appeal, this.appealProps));
        this.appealProps.forEach(prop => {
            if (!appealPart[prop]) {
                appealPart[prop] = [];
            }
        });

        return appealPart;
    }

    /**
     * Применение (отмена) изменений в деле, переход к режиму списка услуг дела
     * @param data - объект формата {appealSubservice: измененная услуга, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public async onApplyAppealSubservice(data) {
        if (data && this.appeal.subservice.guid === data.appealSubservice.guid) {
            this.appeal.subservice = cloneDeep(data.appealSubservice);
            // Обработка данных объектов в услуге
            this.appeal.objects.forEach(object => {
                // Применение изменений привязки объекта к услугам дела
                this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
            });
            // Переинициализация состава документов в соответствии с произведенными настройками (возможно в процессе настройки были изменения варианта услуги)
            this.documentService.reInitSubserviceData(); // обновление настроек услуг в сервисе документов
            this.documentService.correctSubserviceDocGroups(); // корректировка состава групп документов

            // Если применение данных инициализировано при нажатии кнопок "Сохранить дело" или "Действие (переход на новый статус)
            if (data.continueProcessingAppeal) {
                this.onApply.emit(true);
            } else if (this.appeal._id) {
                try {
                    await this.commonAppealSaveService.saveAppeal();
                    this.toaster.success('Данные услуги успешно сохранены');
                } catch (error) {
                    this.toaster.error(error);
                    await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
                }
            }
        } else {
            // Обновляем служебный объект данными после изменения
            this.appealSubservicesService.data = cloneDeep(this.appealSubservicesService.tempData);
        }

        // Запускаем перевалидацию услуги
        this.validateService.validateAppeal(this.validateService.processValidate);

        this.tempAppealPart = null;
        this.editAppealSubservice = null;

        // Если есть активная следующая вкладка, то инициируем в родительском компоненте переход на нее
        if (this.nextTab) {
            this.onAfterSave.emit(this.nextTab);
        }
    }

    /**
     * Инициализация режима редактирования услуги дела
     * @param data - данные для инициализации {tab: активная вкладка, appealSubserviceId: ID услуги дела для редактирования}
     */
    public initEditAppealSubservice(data) {
        this.activeTab = data.tab;
        if (this.appeal.subservice.id === data.appealSubserviceId) {
            this.onEditAppealSubservice(this.appeal.subservice);
        }
    }

    /**
     * КНМ на необходимость сохранения изменений в редактируемую в данный момент времени услугу дела
     * @param nextTab - вкладка, на которую будет осуществлен переход после КНМ
     */
    public checkSaveData(nextTab) {
        this.nextTab = nextTab;
        // Если в данный момент редактируется услуга
        if (this.editAppealSubservice) {
            if (this.checkAppealSubserviceChange()) {
                this.modalOperation = 'afterCheckSaveData';
                this.alertModalComponent.title = this.localizations['common'].confirm_changes;
                this.alertModalComponent.message = this.localizations['appeals.subservices'].apply_changes;
                this.alertModalComponent.showModal();
            } else {
                this.onAfterSave.emit(this.nextTab);
            }
        } else {
            this.onAfterSave.emit(this.nextTab);
        }
    }

    /**
     * КНМ наличия изменений
     * @returns {boolean}
     */
    public checkAppealSubserviceChange() {
        let hasChange = false;
        if (this.editAppealSubservice && this.appeal.subservice.id === this.editAppealSubservice.id) {
            hasChange = !isEqual(this.editAppealSubservice, this.appeal.subservice);
            if (!hasChange) {
                hasChange = !isEqual(this.tempAppealPart, this._extractAppealPartByProps());
            }
        }

        return hasChange;
    }

    /**
     * Передача ответа от модального окна методу, который активен для вызова текущего модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Обработка выбранного в модальном окне решения по изменениям в услуге
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public afterCheckSaveData(data) {
        if (data === 'yes') {
            // Сохранение данных
            this.onApplyAppealSubservice({
                appealSubservice: this.editAppealSubservice,
                continueProcessingAppeal: false,
            });
        } else if (data === 'no') {
            // Отмена внесенных изменений
            this.onAfterSave.emit(this.nextTab);
        } else {
            // Отмена перехода на другую вкладку
            this.nextTab = null;
        }
    }

    /**
     * Удаление услуги из состава услуг комплексного дела
     * @param appealSubservice
     */
    public async deleteSubservice(appealSubservice) {
        // Удаление документов, которые относятся к удаляемой услуге
        const documents = [];
        this.appeal.documents.forEach(document => {
            if (!document.subserviceGuid || document.subserviceGuid && document.subserviceGuid !== appealSubservice.guid) {
                documents.push(document);
            }
        });
        this.appeal.documents = documents;

        // Удаление в документах ссылки на другую услугу (если она как раз удаляется)
        this.appeal.documents.forEach(document => {
            if (document.resultSubserviceLink && document.resultSubserviceLink.guid === appealSubservice.guid) {
                document.resultSubserviceLink = null;
            }
        });

        if (this.appeal.subservice.startAfterGuids && this.appeal.subservice.startAfterGuids.length) {
            this.appeal.subservice.startAfterGuids = this.appeal.subservice.startAfterGuids.filter(guid => guid !== appealSubservice.guid);
        }

        // Сохранение дела с учетом изменений
        if (this.appeal._id) {
            try {
                await this.commonAppealSaveService.saveAppeal();
                await this._afterDeleteSubservice();
            } catch (error) {
                this.toaster.error(error);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
            }
        } else {
            await this._afterDeleteSubservice();
        }
    }

    /**
     * Операции после процедуры удаления услуги из дела
     */
    private async _afterDeleteSubservice() {
        // Корректировка данных сервисов
        await this.commonAppealSaveService.correctServiceDataAfterDeleteAppealEntity();
        this.toaster.success('Услуга успешно удалена из состава дела');
    }

    /**
     * Инициализация перехода на новый статус из услуги
     * @param data - объект вида {action: активируемое действие, appealSubservice: услуга, в которой активируется дело}
     */
    public executeAction(data) {
        this.onExecuteAction.emit(data);
    }

    /**
     * Переход в режим редактирования настроек дела (контролирующие операторы, комментарий и др.)
     */
    public editAppealSettings() {
        this.isEditAppealSettings = true;
    }

    /**
     * Выход из режима редактирования настроек дела
     */
    public afterEditAppealSettings() {
        this.isEditAppealSettings = false;
    }

    /**
     * Выбор дополнительных услуг для формирования комплексного дела
     */
    public selectOtherSubservices() {
        this.commonAppealService.isProcessSetupAppealSubservices = true;
        this.commonAppealService.selectedSubservicesForSetupInAppeal = [];
        this.router.navigate(['/ais/appeals/services']);
    }

    // ------------------------------------------------------------------- //
    // --------------- Настройка параметров услуг ------------------------ //
    // ------------------------------------------------------------------- //

    /**
     * Переход в режим настройки услуг в деле
     */
    public setupAppealSubservices() {
        this._generateAppealSubservicesStructure();
        this.isProcessSetupSubservicesInAppeal = true;
    }

    /**
     * Формирование структуры услуг в деле для настройки порядка отображения и последовательности исполнения
     */
    private _generateAppealSubservicesStructure() {
        this.subservicesStructure = []; // настройки услуг в деле
        this.currentAppealSubservicesGuids = [];

        // 1. Обработка услуг ранее добавленных в дело
        let displayOrder = 0;
        if (this.appeal.subservice) {
            // Формирование основных параметров
            displayOrder++;
            this.subservicesStructure.push({
                id: this.appeal.subservice.id,
                guid: CommonUtilities.GenerateGuid(), // временный guid, который будет являться идентификатором при работе внутри блока настройки
                guidInAppeal: this.appeal.subservice.guid, // guid услуги в деле
                title: this.appeal.subservice.shortTitle,
                after: this.appeal.subservice.startAfterGuids, // заданная в услуге зависимость
                afterSubservices: [],
                displayOrder: displayOrder,
            });
            // Формирование массива guid-ов услуг, которые есть в деле (для обработки возможно удаленных в процессе настройки услуг)
            this.currentAppealSubservicesGuids.push(this.appeal.subservice.guid);

            // Добавление зависимостей от других услуг
            if (this.appeal.subservice.startAfterGuids && this.appeal.subservice.startAfterGuids.length) {
                const correctSubservice = this.subservicesStructure.find(item => item.guidInAppeal === this.appeal.subservice.guid);
                this.appeal.subservice.startAfterGuids.forEach(appealGuid => {
                    const findParent = this.subservicesStructure.find(item => item.guidInAppeal === appealGuid);
                    correctSubservice.afterSubservices.push({
                        guid: findParent.guid,
                        title: findParent.title,
                        selected: true,
                    });
                });
            }
        } else if (this.appeal.complexSubservice) {
            // 2. Случай создания дела на основе комплексной услуги
            // Добавление базовой информации об объекте
            this.commonAppealService.complexSubservice.subservices.forEach(subservice => {
                displayOrder++;
                this.subservicesStructure.push({
                    id: subservice.id,
                    guid: CommonUtilities.GenerateGuid(),
                    guidInAppeal: null,
                    title: subservice.name,
                    after: subservice.startAfter, // заданные в комплексной услуги ID услуг от которых зависит текущая
                    afterSubservices: [], // массив элементов для отображения услуг, от которых зависит текущая
                    displayOrder: displayOrder,
                });
            });
            // Добавление информации об услугах, от которых зависит текущая, для последующей настройки
            this.subservicesStructure.forEach(subservice => {
                if (subservice.after && subservice.after.length > 0) {
                    subservice.after.forEach(parentSubserviceId => {
                        const find = this.subservicesStructure.find(item => item.id === parentSubserviceId);
                        if (find) {
                            subservice.afterSubservices.push({
                                guid: find.guid,
                                title: find.title,
                                displayOrder: find.displayOrder,
                            });
                        }
                    });
                }
            });
        }

        // Обработка услуг, дополнительно добавленных из общего списка (помещаются в конец общего списка)
        if (this.commonAppealService.isProcessSetupAppealSubservices && this.commonAppealService.selectedSubservicesForSetupInAppeal.length > 0) {
            this.commonAppealService.selectedSubservicesForSetupInAppeal.forEach(subservice => {
                displayOrder++;
                this.subservicesStructure.push({
                    title: subservice.title,
                    id: subservice.id,
                    guid: CommonUtilities.GenerateGuid(),
                    guidInAppeal: null,
                    after: [],
                    afterSubservices: [],
                    displayOrder: displayOrder,
                });
            });
        }

        // Определение услуг, которые могут быть выбраны как родительские
        this.subservicesStructure.forEach(subservice => {
            this.getSubservicesForParent(subservice);
        });

        this.commonAppealService.isProcessSetupAppealSubservices = false;
        this.commonAppealService.selectedSubservicesForSetupInAppeal = [];
    }

    /**
     * Применение изменений в настройках порядка вывода и зависимостей услуг дела
     */
    public applySubserviceStructure() {
        let promises = [];
        const subservicesForFind = [];

        // Формирование массива промисов для запроса услуг с сервера
        this.subservicesStructure.forEach(subservice => {
            const hasSubservice = this.commonAppealService.subservice && this.commonAppealService.subservice._id === subservice.id;
            if (!hasSubservice && subservicesForFind.indexOf(subservice.id) === -1) {
                subservicesForFind.push(subservice.id);
                promises.push(this.rest.find(SUBSERVICES_COLLECTION, subservice.id));
            }
        });

        // Получение данных из основной коллекции subservices
        Promise.all(promises).then(subservices => {
            promises = [];
            subservices.forEach((subservice, index) => {
                if (!subservice) {
                    // Услуга не найдена в основной коллекции, требуется доступ в архивной коллекции
                    promises.push(this.rest.find(SUBSERVICES_ARCHIVE_COLLECTION, subservicesForFind[index]));
                } else {
                    const correctedSubservice = this.commonAppealService.correctSubserviceByCurrentUnit(subservice);

                    if (this.appeal.subservice && Object.keys(this.appeal.subservice).length) {
                        throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ correctedSubservice.id }`);
                    }

                    this.subservice = correctedSubservice;
                }
            });

            // Получение данных из архивной коллекции subservicesArchive
            if (promises.length > 0) {
                Promise.all(promises).then(archiveSubservices => {
                    archiveSubservices.forEach(subservice => {
                        const correctSubservice = this.commonAppealService.correctSubserviceByCurrentUnit(subservice);

                        if (this.subservice && Object.keys(this.subservice).length) {
                            throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ correctSubservice.id }`);
                        }

                        this.subservice = correctSubservice;
                    });
                    // Финальная обработка: проставление свойств displayOrder и startAfterGuids
                    this._generateResultSubserviceStructure();

                    // Переключение флагов завершения обработки и переход к отображению карточек услуг
                    return this._afterCompleteSelectSubservices();
                });
            } else {
                // Финальная обработка: проставление свойств displayOrder и startAfterGuids
                this._generateResultSubserviceStructure();

                // Переключение флагов завершения обработки и переход к отображению карточек услуг
                return this._afterCompleteSelectSubservices();
            }
        });
    }

    /**
     * Добавление параметров displayOrder и startAfterGuids в соответствии с произведенными настройками
     */
    private _generateResultSubserviceStructure() {
        this.subservicesStructure.forEach(async subserviceData => {
            if (this.subservice._id === subserviceData.id) {
                // Новая услуга, добавляем в структуру дела
                const initiatedSubservice = this.commonAppealService.initSubserviceInAppeal(this.subservice);

                if (this.appeal.subservice && Object.keys(this.appeal.subservice).length) {
                    throw new Error(`Попытка переназначить subservice с ${ this.appeal.subservice.id } на ${ initiatedSubservice.id }`);
                }

                this.appeal.subservice = initiatedSubservice;
                subserviceData.guidInAppeal = this.appeal.subservice.guid;
            }
            this.appeal.subservice.displayOrder = subserviceData.displayOrder;
        });

        this.subservicesStructure.forEach(subserviceData => {
            if (this.appeal.subservice.guid === subserviceData.guidInAppeal && subserviceData.afterSubservices && subserviceData.afterSubservices.length > 0) {
                this.appeal.subservice.startAfterGuids = [];
                subserviceData.afterSubservices.forEach(parent => {
                    const findParent = this.subservicesStructure.find(item => item.guid === parent.guid);
                    this.appeal.subservice.startAfterGuids.push(findParent.guidInAppeal);
                });
            } else {
                delete this.appeal.subservice.startAfterGuids;
            }
        });

        // Обработка удаленных
        this.currentAppealSubservicesGuids.forEach(async subserviceGuid => {
            const subserviceToDelete = this.subservicesStructure.some(item => item.guidInAppeal === subserviceGuid);
            if (!subserviceToDelete && this.appeal.subservice.guid === subserviceGuid) {
                await this.deleteSubservice(this.appeal.subservice);
            }
        });
    }

    /**
     * Удаление услуги при настройке параметров услуг
     * @param subservice
     */
    public setupDeleteSubservice(subservice) {
        // Удаляем все ссылки в зависимостях на эту услугу
        const deletedGuid = subservice.guid;
        const findIndex = this.subservicesStructure.findIndex(item => item.guid === subservice.guid);
        this.subservicesStructure.splice(findIndex, 1);
        // Перерасчет номеров услуг в деле
        let displayOrder = 0;
        this.subservicesStructure.forEach(item => {
            displayOrder++;
            item.displayOrder = displayOrder;
            // Удаление услуги из списка зависимых
            if (item.afterSubservices && item.afterSubservices.length > 0) {
                const findLinkIndex = item.afterSubservices.findIndex(elm => elm.guid === deletedGuid);
                if (findLinkIndex !== -1) {
                    item.afterSubservices.splice(findLinkIndex, 1);
                }
            }
        });
    }

    /**
     * Инициализация данных в сервисах после завершения режима настройки услуг в деле
     */
    private async _afterCompleteSelectSubservices() {
        // Сортируем услуги в деле в соответствии с порядком вывода
        this.isProcessSetupSubservicesInAppeal = false;
        // Сбрасываем параметры выбора услуг со вкладки "Все услуги"
        this.commonAppealService.isProcessSetupAppealSubservices = false;
        this.commonAppealService.selectedSubservicesForSetupInAppeal = [];
        // Переинициализируем данные сервисов
        this.appealSubservicesService.initData(this.appeal, this.subservice);
        this.commonAppealService.subservice = this.subservice;
        this.documentService.initData(this.appeal, this.subservice, this.commonAppealService.metaReglament);
        // Сохраняем данные
        if (this.appeal._id) {
            await this.commonAppealSaveService.saveAppeal();
        }
        this.afterSelectSubservice.emit(true);
    }

    /**
     * Событие начала перетаскивания элемента для сортировки
     * @param event
     * @param item
     */
    public dragStart(event, item) {
        event.dataTransfer.setData('text', item.guid);
        item.isDrag = true;
    }

    /**
     * Инициализация элемента над которым находится перетаскиваемый элемент
     * @param event
     * @param item
     */
    public dragOver(event, item) {
        event.preventDefault();
        item.isOver = true;
    }

    /**
     * Событие потери фокуса перетаскиваемого элемента над обрабатываемым элементом
     * @param event
     * @param item
     */
    public dragLeave(event, item) {
        event.preventDefault();
        item.isOver = false;
    }

    /**
     * Событие финального выбора позиции элемента при перетаскивании
     * @param event
     * @param subservice
     */
    public drop(event, subservice) {
        event.preventDefault();
        const droppedGuid = event.dataTransfer.getData('text'); // GUID перетаскиваемого элемента
        const droppedSubservice = this.subservicesStructure.find(item => item.guid === droppedGuid); // перетаскиваемый объект услуги
        const indexToMove = this.subservicesStructure.findIndex(item => item.guid === subservice.guid);
        const resultItems = [];

        this.subservicesStructure.forEach((item, idx) => {
            item.isDrag = false;
            item.isOver = false;
            // Случай когда элемент остался в той же позиции
            if (droppedGuid === subservice.guid) {
                resultItems.push(item);
            } else if (item.guid !== droppedGuid) {
                if (idx === indexToMove) {
                    resultItems.push(droppedSubservice);
                }
                resultItems.push(item);
            }
        });

        resultItems.forEach((item, idx) => {
            item.displayOrder = idx + 1;
        });

        // Корректируем номера зависимых услуг
        resultItems.forEach(resultSubservice => {
            if (resultSubservice.afterSubservices && resultSubservice.afterSubservices.length > 0) {
                resultSubservice.afterSubservices.forEach(afterSubservice => {
                    const find = resultItems.find(item => item.guid === afterSubservice.guid);
                    afterSubservice.displayOrder = find.displayOrder;
                });
            }
        });

        this.subservicesStructure = resultItems;
    }

    /**
     * Определение набора услуг, которые можно выбрать в качестве родительской (после которой будет стартовать обрабатываемая услуга)
     * @param checkSubservice - обрабатываемая услуга
     */
    public getSubservicesForParent(checkSubservice) {
        const result = [];
        this.subservicesStructure.forEach(subservice => {
            // Если это не обрабатываемая услуга
            if (checkSubservice.guid !== subservice.guid) {
                // Проверяем, отображать ли услугу в списке для выбора или нет
                let canUse = true;

                if (subservice.afterSubservices && subservice.afterSubservices.length > 0) {
                    const find = subservice.afterSubservices.find(item => item.guid === checkSubservice.guid);
                    if (find) {
                        // Не отображать услуги, которые являются подчиненными для текущей услуги (учитывая все дочерние)
                        canUse = false;
                    } else {
                        // Услуга не является напрямую зависимой от проверяемой, проверяем по дереву вверх
                        canUse = !this.checkChildSubservices(checkSubservice.guid, subservice.afterSubservices);
                    }
                }

                if (canUse) {
                    result.push({
                        guid: subservice.guid,
                        title: subservice.title,
                        displayOrder: subservice.displayOrder,
                        selected: checkSubservice.afterSubservices.findIndex(item => item.guid === subservice.guid) !== -1,
                    });
                }
            }
        });
        checkSubservice.parentSubservicesForSelect = result;
    }

    /**
     * КНМ на то, что услуга может быть родительской для определенной услуги
     * @param compareGuid - GUID - искомой услуги
     * @param checkSubservices - массив услуг, которая проверяется в данный момент
     */
    public checkChildSubservices(compareGuid, checkSubservices) {
        let isUse = false;
        checkSubservices.forEach(checkSubservice => {
            const subservice = this.subservicesStructure.find(item => item.guid === checkSubservice.guid);
            if (subservice.afterSubservices && subservice.afterSubservices.length > 0) {
                const findCompareInChecked = subservice.afterSubservices.find(item => item.guid === compareGuid);
                if (findCompareInChecked) {
                    isUse = true;
                } else {
                    // Поиск наверх
                    const useInCheck = this.checkChildSubservices(compareGuid, subservice.afterSubservices);
                    isUse = useInCheck ? useInCheck : isUse;
                }
            }
        });

        return isUse;
    }

    /**
     * Применение настроек зависимости услуги от других услуг
     * @param subservice - обрабатываемая услуга
     */
    public applyParentSubservices(subservice) {
        subservice.afterSubservices = subservice.parentSubservicesForSelect.filter(item => item.selected);
        subservice.isProcessSelectParent = false;
        this.subservicesStructure.forEach(item => {
            this.getSubservicesForParent(item);
        });
    }

    /**
     * Включение режима настройки зависимости услуг
     * @param subservice
     */
    public changeParentSubservices(subservice) {
        this.getSubservicesForParent(subservice);
        subservice.isProcessSelectParent = true;
    }

    private _loadTranslations(): void {
        this.translate.get(
            [
                'common',
                'appeals.subservices',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }
}
