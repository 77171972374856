import { Component, Input, OnInit } from '@angular/core';
import { ProcessService } from '../process.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'bpmn-links',
    templateUrl: './bpmn-links.component.html',
    styles: ['.pl-60{padding-left: 60px;}'],
})
export class BpmnLinksComponent implements OnInit {
    @Input() public entity; // элемент стандарта, для которого настраиваются задачи
    @Input() public entityName; // наименование (код) элемента стандарта, для которого настраиваются задачи
    @Input() public entityProcess; // структура процесса
    @Input() public process; // процесс
    @Input() public useRequired = true;
    @Input() public operations = ['used', 'required'];

    public tasks = [];
    public localizations;

    public operationTypes = [];

    public constructor(
        private processService: ProcessService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        if (this.process) {
            this.tasks = this.prepareTasks(this.process);
        }
        if (!this.useRequired) {
            this.operations.splice(this.operations.indexOf('required'), 1);
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'bpmn-card', 'organizations']).subscribe((res: any) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.operationTypes = [
            {
                code: 'used',
                name: this.localizations.common.used,
                field: 'isUse',
            },
            {
                code: 'required',
                name: this.localizations.common.obligatory,
                field: 'isRequired',
            },
            {
                code: 'print',
                name: this.localizations['bpmn-card'].document_forming,
                field: 'isPrint',
            },
            {
                code: 'sign',
                name: this.localizations.organizations.signing,
                field: 'isSign',
            },
        ];
    }

    /**
     * Формирование структуры задач, для которым может быть выставлены флаги использования и обязательности
     * @param process
     */
    public prepareTasks(process) {
        let tasks;
        if (process.tasks.userTasks) {
            tasks = process.tasks.userTasks.map((item) => this.getTaskParams(item));
        }
        if (process.tasks.subProcess) {
            tasks = tasks.concat(process.tasks.subProcess.map((item) => this.getTaskParams(item)));
        }

        return tasks;
    }

    /**
     * Свойство отдельной задачи
     * @param item
     */
    public getTaskParams(item) {
        return {
            id: item.id,
            guid: item.guid,
            name: item.name,
            isUse: this.initTaskParams(item.guid, 'isUse'),
            isRequired: this.initTaskParams(item.guid, 'isRequired'),
            isPrint: this.initTaskParams(item.guid, 'isPrint'),
            isSign: this.initTaskParams(item.guid, 'isSign'),
            children: item.subProcess ? this.prepareTasks(item.subProcess) : null,
        };
    }

    /**
     * Поиск задачи в настройках процесса в стандарте
     * @param taskGuid
     */
    public findTaskInEntity(taskGuid) {
        return this.processService.findTaskInEntityProcess(taskGuid, this.entityProcess);
    }

    /**
     * Инициализация обрабатываемых компонентом параметров
     * @param taskGuid
     * @param property
     */
    public initTaskParams(taskGuid, property) {
        const task = this.findTaskInEntity(taskGuid);

        if (task && task[this.entityName]) {
            const findCurrentEntityParams = task[this.entityName].find((item) => item.guid === this.entity.guid);

            return findCurrentEntityParams ? findCurrentEntityParams[property] : false;
        }

        return false;
    }

    /**
     * Изменение параметров для определенной задачи
     * @param taskGuid
     * @param property
     */
    public changeTaskProperty(taskGuid, property) {
        const task = this.findTaskInEntity(taskGuid);
        if (!task[this.entityName]) {
            task[this.entityName] = [];
        }
        let find = task[this.entityName].find((item) => item.guid === this.entity.guid);
        if (!find) {
            task[this.entityName].push({ guid: this.entity.guid });
            find = task[this.entityName][task[this.entityName].length - 1];
        }
        find[property] = !find[property];
    }

    public getOperation(operation, field) {
        const data = this.operationTypes.find((item) => item.code === operation);

        return data ? data[field] : '';
    }
}
