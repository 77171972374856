import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService } from '@evolenta/core';

@Injectable()
export class RolesMnemonicsResolve implements Resolve<any> {
    public constructor(private restService: RestService) {}
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const roleId = route.params['roleMnemonicId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create');

        if (isCreateAction.length > 0) {
            // Создание
            return {};
        } else if (roleId) {
            // Редактирование
            return this.restService.find('catalogueRolesMnemonics', roleId);
        }
    }
}
