<div [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit'}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i></b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()" *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()" *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div *ngIf="mode == 'view'" [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}">
            <p class="mb-3">
                <span class="text-muted">Номер: </span>
                <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
                <em *ngIf="!entity.number">не указан</em>

                <span class="text-muted ml-10">Дата составления: </span>
                <ng-container *ngIf="entity.createDate">{{entity.createDate | date: 'shortDate'}}</ng-container>
                <em *ngIf="!entity.createDate">не указана</em>
            </p>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">

            <!-- Данные акта -->
            <div  class="pt-15 pl-20 pr-20">

                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Номер акта</label>
                            <input [(ngModel)]="entity.number" type="text" class="form-control input-xs" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Дата составления</label>
                            <evolenta-datepicker [(ngModel)]="entity.createDate" name="createDate" [large]="true" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Время составления (часы)</label>
                            <input [(ngModel)]="entity.createHours" type="number" min="0" max="23" class="form-control input-xs" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Время составления (минуты)</label>
                            <input [(ngModel)]="entity.createMinutes" type="number" min="0" max="59" class="form-control input-xs" [disabled]="!allowEdit">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group form-group-lg">
                            <label>Место составления</label>
                            <evolenta-address-gar
                                    [large]="true"
                                    [showIndex]="false"
                                    [disabled]="!allowEdit"
                                    [(ngModel)]="entity.createPlace"
                            ></evolenta-address-gar>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group form-group-lg">
                            <label>Тип адреса</label>
                            <catalogue catalogName="typeAddress" name="typeAddress" [(ngModel)]="entity.typeAddress" [priority]="true" [large]="true" [disabled]="!allowEdit"></catalogue>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group form-group-lg">
                            <label>Дата уведомления проверяемого лица о проведении КНМ</label>
                            <evolenta-datepicker [(ngModel)]="entity.dateNotify" name="createDate" [large]="true" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group form-group-lg">
                            <label>Место проведения проверки</label>
                            <evolenta-address-gar
                                    [large]="true"
                                    [showIndex]="false"
                                    [disabled]="!allowEdit"
                                    [(ngModel)]="entity.place"
                            ></evolenta-address-gar>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Характер сведений о результате</label>
                            <catalogue
                                catalogName="nsiErpResultInformNature"
                                name="resultInformNature"
                                [(ngModel)]="entity.resultInformNature"
                                [priority]="true"
                                [large]="true"
                                [disabled]="!allowEdit">
                            </catalogue>
                        </div>
                    </div>
                </div>

                <!-- Причины невозможности проведения проверки -->
                <div class="mb-15">
                    <appeal-entity-catalogue
                        [large]="true"
                        [entity]="entity"
                        [property]="'reasonImpossibility'"
                        [catalogName]="'reasonsImpossibility'"
                        [title]="'Причины невозможности проведения проверки'"
                        [disabled]="!allowEdit"
                        [placeholder]="'Выберите причину...'">
                    </appeal-entity-catalogue>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Информация о причинах невозможности проведения проверки</label>
                            <textarea [(ngModel)]="entity.checkControlRestrict" class="form-control" [disabled]="!allowEdit"></textarea>
                        </div>
                    </div>
                </div>
                <!-- Представитель проверяемого лица -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>ФИО представителя проверяемого лица</label>
                            <input [(ngModel)]="entity.personSubjectName" type="text" class="form-control input-xs" placeholder="Фамилия Имя Отчество" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Должность представителя проверяемого лица</label>
                            <input [(ngModel)]="entity.personSubjectPosition" type="text" class="form-control input-xs" placeholder="Должность" [disabled]="!allowEdit">
                        </div>
                    </div>
                </div>
                <!-- Лицо, свидетельствующее фактические обстоятельства -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>ФИО лица, свидетельствующего фактические обстоятельства</label>
                            <input [(ngModel)]="entity.personWitnessName" type="text" class="form-control input-xs" placeholder="Фамилия Имя Отчество" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Адрес лица, свидетельствующего фактические обстоятельства</label>
                            <evolenta-address-gar
                                    [large]="true"
                                    [showIndex]="false"
                                    [disabled]="!allowEdit"
                                    [(ngModel)]="entity.personWitnessAddress"
                            ></evolenta-address-gar>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Паспортные данные лица, свидетельствующего фактические обстоятельства</label>
                            <textarea [(ngModel)]="entity.personWitnessPasport" class="form-control" [disabled]="!allowEdit"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Прилагаемые документы -->
                <div class="mb-15">
                    <appeal-entity-field-input
                        [multiple]="true"
                        [large]="true"
                        [entity]="entity"
                        [property]="'materials'"
                        [title]="'Прилагаемые документы'"
                        [disabled]="!allowEdit"
                        [placeholder]="'Введите наименование прилагаемого документа'">
                    </appeal-entity-field-input>
                </div>
            </div>

            <appeal-entity-documents [entity]="entity" [task]="task" [allowEdit]="allowEdit"></appeal-entity-documents>
        </div>
    </div>
</div>
