import { Component } from '@angular/core';

@Component({
    selector: 'epgu-integration-section',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['epgu-integration.component.less'],
    styles: [
        ':host { width: 100%; }',
        ],
})
export class EpguIntegrationSectionComponent {

}
