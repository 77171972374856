import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@evolenta/core';
import { DocumentService } from '../../document.service';
import { AppealValidateService } from '../../../../appeal-validate.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-document-participants',
    templateUrl: 'appeal-document-participants.component.html',
})
export class AppealDocumentParticipantsComponent implements OnInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public appeal; // формируемео дело
    @Input() public type = 'subjects';

    public data; // описательный массив
    public subjects; // объекты дела
    public forAgent = false; // документ на представителя
    public principals = []; // доверители
    public needSelectPrincipal = false; // необходимость выбора доверителя для документа
    public isIdentity = false; // документ является документом удостоверяющим личность
    public entities;
    public property;
    public requirementProperty;
    public localizations;

    public constructor(
        private documentService: DocumentService,
        private validateService: AppealValidateService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.requirementProperty = this.type === 'subjects' ? 'bySubjects' : 'byObjects';
        this.property = this.type === 'subjects' ? 'useSubjects' : 'useObjects';
        this.subjects = _.cloneDeep(this.appeal.subjects);
        this.data = this.documentService.data[this.document.guid]; // информация по текущему документу в сервисе
        if (this.data && this.data.group && this.data.group.isIdentity) {
            this.isIdentity = true;
        }
        this.entities = this.type === 'objects' ? _.cloneDeep(this.appeal.objects) : _.cloneDeep(this.appeal.subjects);
        if (this.group && this.group.appealRequirements && this.group.appealRequirements[this.requirementProperty]) {
            // Фильтрация участников дела для простых документов в зависимости от условий обязательности для группы документов
            this.entities = this.entities.filter(item => Object.keys(this.group.appealRequirements[this.requirementProperty]).indexOf(item.guid) !== -1);
        } else if (this.data.requestId && this.data.doc && this.data.doc.participantCategories) {
            // Фильтрация участников дела для документов-запросов в зависимости от настроек документов-запросов в стандартах
            const usedSubjects = [];
            this.data.appealSubservice.subjects.forEach(subject => {
                if (subject.category && subject.category.selectedCategories) {
                    const find = subject.category.selectedCategories.find(item => this.data.doc.participantCategories.indexOf(item.guid) !== -1);
                    if (find) {
                        const findSubject = this.subjects.find(item => item.guid === subject.guid);
                        usedSubjects.push(_.cloneDeep(findSubject));
                    }
                }
            });
            this.entities = _.cloneDeep(usedSubjects);
        }
        // Если документ относится к группе, описанной в структуре услуги
        if (this.data.group && this.data.group.requirements) {
            // Условия предоставления на представителя
            const requirementByAgent = this.data.group.requirements.find(item => item.byAgent);
            // Если группа на представителя
            if (requirementByAgent) {
                this.forAgent = true;
                // получение списка представителей для выбора
                this.getAgents();
                // Если в группу требуется добавление документов для каждого доверителя
                if (requirementByAgent.byAgent[0].forEachPrincipal) {
                    this.needSelectPrincipal = true;
                    // Формирование списка доверителей
                    this.getPrincipals();
                }
            }
        }
        // Если для документа ранее уже были выбраны объекты, инициализируем флаг "Выбран" (selected)
        if (this.data[this.property] && this.data[this.property].length > 0) {
            this.entities.forEach(entity => {
                const find = this.data[this.property].find(item => item.guid === entity.guid);
                if (find) {
                    entity.selected = true;
                }
            });
        } else if (this.entities.length === 1) {
            if (this.data.requestId) {
                this.changeRequestSubject(this.entities[0]);
            } else if (this.group.code) {
                // Выбор участника автоматически, если он один в деле
                this.changeDocumentEntity(this.entities[0]);
            }
        }
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.documents',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Формирование списка представителей
     */
    public getAgents() {
        // Если у документа есть услуга
        if (!(this.data.subserviceGuid && this.appeal.subservice.subjects && this.appeal.subservice.subjects.length)) {
            return;
        }

        // Фильтрация объектов дела по наличию представителей
        const objectsWithRepresentative = this.appeal.subservice.subjects.filter(item => item.representative && item.representative.guid);
        const agents = [];
        objectsWithRepresentative.forEach(subject => {
            const agentSubject = this.appeal.subjects.find(item => item.guid === subject.representative.guid);
            const agentInList = agents.find(item => item.guid === subject.representative.guid);
            if (!agentInList) {
                agents.push(_.cloneDeep(agentSubject));
            }
        });
        this.subjects = agents; // замена списка объектов для выбора набором представителей
    }

    /**
     * Выбор / отмена выбора привязки к документу объекта дела
     * @param entity - обрабатываемый объект
     */
    public changeDocumentEntity(entity) {
        entity.selected = !entity.selected;
        if (this.forAgent || this.isIdentity) {
            this.data.useSubjects = [];
        }
        const findIndex = this.data[this.property].findIndex(item => item.guid === entity.guid);
        if (findIndex === -1) {
            if (this.group.isIdentity) {
                this.data.seria = entity.data.person.documentSeries;
                this.data.number = entity.data.person.documentNumber;
                this.data.issuer = entity.data.person.documentIssuer ? entity.data.person.documentIssuer.name : '';
                this.data.dateIssue = entity.data.person.documentIssueDate;
            }
            this.data[this.property].push(entity);
        } else {
            console.log('splice', this.property);
            this.data[this.property].splice(findIndex, 1);
        }
        if (this.forAgent && this.needSelectPrincipal) {
            // Обновление списка доверителей для выбранного представителя
            delete this.data.principal;
            this.getPrincipals();
        }

        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Получение списка доверителей
     */
    public getPrincipals() {
        // Если выбран представитель
        if (!(this.data.useSubjects && this.data.useSubjects.length)) {
            return;
        }

        const agent = this.data.useSubjects[0];
        // Выбираем доверителей, для выбранного представителя
        this.principals = [];
        if (!(this.appeal.subservice.subjects && this.appeal.subservice.subjects.length)) {
            return;
        }

        const objectsWithRepresentative = this.appeal.subservice.subjects.filter(item => item.representative && item.representative.guid === agent.guid);
        objectsWithRepresentative.forEach(subject => {
            const appealSubject = this.appeal.subjects.find(item => item.guid === subject.guid);
            this.principals.push(_.cloneDeep(appealSubject));
        });
        this.principals.forEach(item => {
            // Выставление флага "Выбран"
            item.selected = this.data.principal && this.data.principal.guid === item.guid;
        });
    }

    /**
     * Выбор доверителя
     * @param principal
     */
    public changeDocumentPrincipal(principal) {
        this.data.principal = principal;
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Выбор участника/объекта для документа-запроса
     * @param subject - обрабатываемый объект
     */
    public changeRequestSubject(subject) {
        this.data.envelope.object = {guid: subject.guid};
        this.data.envelopeSubject = subject;
    }

    /**
     * КНМ использования объекта в документа
     * @param subject - проверяемый объект
     * @returns {boolean}
     */
    public checkUseObject(subject) {
        return this.data.subjects.findIndex(item => item.guid === subject.guid) !== -1;
    }
}
