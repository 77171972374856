import { Injectable } from '@angular/core';
import { ObjectUtilities } from '@evolenta/utilities';
import cloneDeep from 'lodash-es/cloneDeep';
import uniq from 'lodash-es/uniq';

@Injectable()
export class EntityFieldsRequirementsService {
    // Дефолтные настройки обязательности полей для участников
    public defaultRequiredFields = {
        'individualApplicant': [
            'person.lastName',
            'person.firstName',
            'person.birthday',
            'person.documentType',
            'person.documentNumber',
            'person.documentIssueDate',
            'person.documentIssuer',
            'person.mobile',
        ],
        'ulApplicant': [
            'organization.opf',
            'organization.shortName',
            'organization.name',
            'organization.ogrn',
            'organization.inn',
            'organization.kpp',
        ],
        'ipApplicant': [
            'person.ogrn',
            'person.lastName',
            'person.firstName',
        ],
        'foreignUlApplicant': [
            'person.name',
            'person.inn',
            'person.accreditationNumber',
            'person.accreditationDate',
        ],
        'object': [
            'name',
            'address',
        ],
    };

    public entitiesRequirements = {};

    public initData() {
        this.entitiesRequirements = {};
    }

    /**
     * Определение параметров обязательности полей участников
     * @param entity - обрабатываемый элемент
     * @param rules - правила обязательности
     */
    public calculateRequiredFields(entity, rules = null) {
        this.entitiesRequirements[entity.guid] = null;
        const entityType = entity.specialTypeId ? entity.specialTypeId : 'object';
        const fieldsRequirements: any = {}; // настройки обязательности для объекта
        const requiredProperty = {condition: null, required: 'required'}; // Значение обязательного поля по умолчанию
        if (rules) {
            this.generateFieldRequirementsStructure(rules.andElement, fieldsRequirements);
        } else {
            this.defaultRequiredFields[entityType].forEach(field => {
                fieldsRequirements[field] = requiredProperty;
            });
        }

        // Расчет значений обязательности на основании расчитанных условий обязательности
        this.calculateFieldsRequirements(fieldsRequirements, entity);
        this.entitiesRequirements[entity.guid] = fieldsRequirements;
    }

    /**
     * Формирование структуры обязательности по структуре обязательности из СПЭР
     * @param requirements - условия обязательности
     * @param {{}} resultFields - финальный объект с настройками обязательности (для работы с ним в СИЭР)
     * @param {any} requirementType - тип обязательности (or, and)
     * @param {any} parentElements - условия с учетом родительских элементов
     */
    public generateFieldRequirementsStructure(requirements, resultFields = {}, requirementType = null, parentElements = null) {
        // Конечные элементы
        const elements = [];
        if (requirements['reqElements']) {
            requirements['reqElements'].forEach(field => {
                elements.push(field); // добавление значений в массив конечных элементов текущего уровня для передачи значений на уровень ниже
                let condition = null;
                let resultCondition;
                if (requirementType) {
                    condition = this.generateConditionForField(requirements, field, requirementType); // определение условий обязательности для конечного элемента начиная с текущего уровня
                    // в случае, если есть родительские элементы
                    if (parentElements) {
                        resultCondition = cloneDeep(parentElements);
                        this.addConditionToLastLevel(resultCondition, condition); // добавление условий для текущего элемента в нижний уровень родительских элементов
                    }
                }
                resultFields[field] = {
                    condition: resultCondition ? resultCondition : condition,
                    required: null,
                };
            });
        }

        // Формирование объекта родительских элементов для передачи в дочерние уровни
        let object = null;
        if (elements && requirementType) {
            object = {};
            object[requirementType] = elements;
            if (parentElements) {
                const resultObject = cloneDeep(parentElements);
                this.addConditionToLastLevel(resultObject, object);
                object = resultObject;
            }
        }

        // OR элементы
        if (requirements['orElements']) {
            requirements['orElements'].forEach(node => {
                this.generateFieldRequirementsStructure(node, resultFields, 'or', object);
            });
        }

        // AND элементы
        if (requirements['andElements']) {
            requirements['andElements'].forEach(node => {
                this.generateFieldRequirementsStructure(node, resultFields, 'and', object);
            });
        }
    }

    /**
     * Добавление условий обязательности на последний уровень вложенности последним элементом
     * @param object
     * @param addedCondition
     */
    public addConditionToLastLevel(object, addedCondition) {
        Object.keys(object).forEach(key => {
            if (Array.isArray(object[key])) {
                let hasObject = false;
                object[key].forEach((childElement, idx) => {
                    if (typeof childElement === 'object') {
                        hasObject = true;
                        this.addConditionToLastLevel(childElement, addedCondition);
                    }
                });
                if (!hasObject) {
                    object[key].push(addedCondition);
                }
            }
        });
    }

    /**
     * Определение условий обязательности для поля начиная с текущего уровня
     * @param node
     * @param checkField
     * @param {any} parentType
     * @returns {any}
     */
    public generateConditionForField(node, checkField, parentType = null) {
        const conditionItems = [];
        Object.keys(node).forEach(key => {
            if (key === 'reqElements') {
                node[key].forEach(field => {
                    if (field !== checkField) {
                        conditionItems.push(field);
                    }
                });
            } else if (key === 'orElements') {
                node[key].forEach(childNode => {
                    conditionItems.push({or: this.generateConditionForField(childNode, checkField)});
                });
            } else if (key === 'andElements') {
                node[key].forEach(childNode => {
                    conditionItems.push({and: this.generateConditionForField(childNode, checkField)});
                });
            }
        });

        if (parentType) {
            const object = {};
            object[parentType] = conditionItems;

            return object;
        }

        return conditionItems;
    }

    /**
     * Расчет значения обязательности поля для каждого обязательного / условно обязательного поля
     * @param requirements - настройки обязательности всех полей для объекта
     * @param entity - данные элемента
     */
    public calculateFieldsRequirements(requirements, entity) {
        if (requirements) {
            Object.keys(requirements).forEach(field => {
                this.calculateFieldRequirements(requirements[field], entity);
            });
        }
    }

    /**
     * Вычисление свойства required по определенным ранее условиям обязательности
     * @param requirement - настройки обязательности
     * @param entity
     */
    public calculateFieldRequirements(requirement, entity) {
        if (requirement.condition) {
            const checkCondition = this.calculateRequirementByCondition(requirement.condition, entity);
            requirement.required = checkCondition ? 'not required' : 'conditional required';
        } else {
            requirement.required = true;
        }
    }

    /**
     * Определение обязательности в соответствии с условиями обязательности
     * @param condition
     * @param entity
     */
    public calculateRequirementByCondition(condition, entity) {
        let result = false;
        Object.keys(condition).forEach(key => {
            result = key !== 'or';
            condition[key].forEach(item => {
                // Проверка на необходимость проверки следующего звена
                if (key === 'or' && !result || key === 'and' && result) {
                    let itemValue;
                    if (typeof item === 'string') {
                        // конечный элемент
                        itemValue = ObjectUtilities.GetPropertyByPath(entity, entity.specialTypeId ? ('data.' + item) : item);
                    } else {
                        itemValue = this.calculateRequirementByCondition(item, entity);
                    }
                    if (key === 'or' && itemValue) {
                        result = true;
                    } else if (key === 'and' && !itemValue) {
                        result = false;
                    }
                }

            });
        });

        return result;
    }

    /**
     * Проверка обязательности поля (для отработки в интерфейсе)
     * @param field
     */
    public checkFieldRequired(field, entity) {
        const entityRequirements = this.entitiesRequirements[entity.guid];
        if (entityRequirements) {
            if (entityRequirements && entityRequirements[field]) {
                return entityRequirements[field].required !== 'not required';
            }
        }

        return false;
    }

    /**
     * Перерасчет параметров обязательности при изменении значений полей
     * @param field - изменяемое поле
     * @param entity
     * @param {boolean} correctFieldRequirement - корректировать зависимые поля
     */
    public correctFieldRequirement(field, entity, correctFieldRequirement = false) {
        if (this.entitiesRequirements[entity.guid]) {
            const entityRequirements = this.entitiesRequirements[entity.guid];
            if (field in entityRequirements) {
                const fieldRequirements = entityRequirements[field];
                this.calculateFieldRequirements(fieldRequirements, entity);
                if (fieldRequirements.condition && correctFieldRequirement) {
                    const dependedFields = this.getDependedFields(fieldRequirements.condition);
                    if (dependedFields.length > 0) {
                        dependedFields.forEach(dependField => {
                            this.correctFieldRequirement(dependField, entity);
                        });
                    }
                }
            }
        }
    }

    /**
     * Получение набора зависимых полей (в условиях обязательности)
     * @param condition
     * @returns {Array}
     */
    public getDependedFields(condition) {
        let elements = [];
        Object.keys(condition).forEach(key => {
            condition[key].forEach(item => {
                if (typeof item === 'string') {
                    elements.push(item);
                } else {
                    elements = elements.concat(this.getDependedFields(item));
                }
            });
        });

        return uniq(elements);
    }
}
