<div  class="pt-20 pl-20 pr-20">
    <form #form="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.subjects.full_name }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.name', organization)">
                            *
                        </span>
                    </label>
                    <input class="form-control"
                           type="text"
                           name="organizationName"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.name', organization)"
                           [disabled]="isDisabled('organization.name')"
                           [(ngModel)]="organization.data.organization.name"
                           (change)="generateHeader(); updateErrors('organization.name')"
                           #organizationName="ngModel">
                    <div *ngIf="isProcessValidate && organizationName.errors"
                         class="validation-error-label">
                        <span *ngIf="organizationName.errors.required">
                            {{ localizations.common.name_field_is_required }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.subjects.subjects }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.shortName', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationShortName"
                           class="form-control"
                           [(ngModel)]="organization.data.organization.shortName"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.shortName', organization)"
                           [disabled]="isDisabled('organization.shortName')"
                           (ngModelChange)="updateErrors('organization.shortName')"
                           #organizationShortName="ngModel">
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationShortName.errors">
                        <span *ngIf="organizationShortName.errors.required">
                            {{ localizations.subjects.short_name_is_required }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.common.inn }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.inn', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="organizationInn"
                           class="form-control"
                           #organizationInn="ngModel"
                           [(ngModel)]="organization.data.organization.inn"
                           [textMask]="{mask: globalParams.orgInnMask}"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.inn', organization)"
                           [disabled]="isDisabled('organization.inn')"
                           (change)="generateHeader()"
                           (ngModelChange)="updateErrors('organization.inn')"
                           evolentaValidateOrgInn>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationInn.errors && organizationInn.errors.required">
                        {{ localizations.subjects.inn_field_is_required }}
                    </div>
                    <div class="validation-error-label"
                         *ngIf="organizationInn.errors && organizationInn.errors.organizationINN">
                        {{organizationInn.errors.organizationINN}}
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group form-group-lg">
                    <label>
                        {{ localizations.subjects.accreditation_record }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.accreditationNumber', organization)">
                            *
                        </span>
                    </label>
                    <input type="text"
                           name="accreditationNumber"
                           class="form-control"
                           [(ngModel)]="organization.data.organization.accreditationNumber"
                           [required]="fieldsRequirementsService.checkFieldRequired('organization.accreditationNumber', organization)"
                           [disabled]="isDisabled('organization.accreditationNumber')"
                           (ngModelChange)="updateErrors('organization.accreditationNumber')"
                           #organizationAccreditationNumber="ngModel">
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationAccreditationNumber.errors && organizationAccreditationNumber.errors.required">
                        {{ localizations.subjects.accreditation_record_field_is_required }}
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group form-group-lg">
                    <label>
                        {{ localizations.subjects.accreditation_record_date }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('organization.accreditationDate', organization)">
                            *
                        </span>
                    </label>
                    <evolenta-datepicker name="accreditationDate"
                                         [large]="true"
                                         [valueAsString]="true"
                                         [disabled]="isDisabled('organization.accreditationDate')"
                                         [required]="fieldsRequirementsService.checkFieldRequired('organization.accreditationDate', organization)"
                                         (ngModelChange)="updateErrors('organization.accreditationDate')"
                                         [(ngModel)]="organization.data.organization.accreditationDate"
                                         #organizationAccreditationDate="ngModel">
                    </evolenta-datepicker>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && organizationAccreditationDate.errors && organizationAccreditationDate.errors.required">
                        {{ localizations.subjects.accreditation_record_date_field_is_required }}
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>
                {{ localizations.subjects.rf_address }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired('organization.registrationAddress', organization)">
                    *
                </span>
            </label>
            <evolenta-address-gar
                    name="registrationAddress"
                    [large]="true"
                    [required]="fieldsRequirementsService.checkFieldRequired('organization.registrationAddress', organization)"
                    [disabled]="isDisabled('organization.registrationAddress')"
                    [(ngModel)]="organization.data.organization.registrationAddress"
                    (change)="updateErrors('organization.registrationAddress')"
                    #organizationRegistrationAddress="ngModel">
            </evolenta-address-gar>
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && organizationRegistrationAddress.errors && organizationRegistrationAddress.errors.required">
                {{ localizations.subjects.rf_address_field_is_required }}
            </div>
        </div>
    </form>
</div>
