import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectService, ToasterService } from '@evolenta/core';
import { AppealEntityRaidAct } from './models/appeal-entity-raid-act';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-raid-act',
    templateUrl: 'appeal-entity-raid-act.component.html',
})
export class AppealEntityRaidActComponent implements OnInit {
    @Input() public entity: AppealEntityRaidAct;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;
    public employee = {};
    public nsiViolations = {};

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.date) {
            this.entity.date = '';
        }
        if (!this.entity.startDate) {
            this.entity.startDate = '';
        }
        if (!this.entity.endDate) {
            this.entity.endDate = '';
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidEmployee = this.validateEmployee();
        const isValidNSIViolations = this.validateNSIViolations();

        return isValidForm && isValidEmployee && isValidNSIViolations;
    }

    public validateEmployee(): boolean {
        const isValidName = !!_.get(this.entity.employee, 'name.0.id', null);
        const isValidPosition = !!_.get(this.entity.employee, 'position', null);

        const isValid = isValidName && isValidPosition;

        this.employee = isValid
            ? { value: this.entity.employee }
            : {
                  value: this.entity.employee,
                  errors: { nameRequired: !isValidName, positionRequired: !isValidPosition },
              };

        return isValid;
    }

    public validateNSIViolations(): boolean {
        const isValid = this.entity.nsiViolations.every((r) => !!_.get(r, 'value', null));

        this.nsiViolations = isValid
            ? { value: this.entity.nsiViolations }
            : { value: this.entity.nsiViolations, errors: { required: true } };

        return isValid;
    }
}
