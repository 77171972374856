/**
 * Компонент одной записи СМЭВ-адаптеров
 * @author Sergey Maksimenko
 * @since 06.03.2023
 */

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmevConfigurationService } from '../../services/smev-configuration.service';
import { OFF, SMEVCLIENT, UNIVERSAL } from '../../../../common/constants';

@Component({
    selector: 'smev-configuration',
    templateUrl: 'smev-configuration.component.html',
    styleUrls: ['./smev-configuration.component.less'],
})
export class SmevConfigurationComponent implements OnInit {

    @Input() public adaptor;
    @Input() public selected;

    public constructor(
        private smevConfigurationService: SmevConfigurationService,
        private translate: TranslateService,
    ) {
    }

    public initialized;
    public localizations;

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'smev_configuration',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this.initialized = true;
        });
    }

    /**
     * Обработчик нажатия на адапрто
     * @param adaptor
     */
    public adaptorClickedHandler(adaptor) {
        this.selected = adaptor;
    }

    public getStatusClass() {
        const initialClass = 'list-item-status-rectangle text-uppercase display-inline-block ';
        switch (this.adaptor.adapter) {
            case OFF:
                return initialClass + 'bg-danger-300';
            case SMEVCLIENT:
                return initialClass + 'bg-success-300';
            case UNIVERSAL:
                return initialClass + 'bg-warning-300';
            default:
                throw new Error('Неизвестный статус СМЭВ-адаптера: ' + this.adaptor.adapter);
        }
    }

    public getStatusName() {
        return this.localizations.smev_configuration.statuses[this.adaptor.adapter.toLowerCase()];
    }

}
