import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { CommonStandardsService } from '../../common-standards.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'common-standard-variant',
    templateUrl: './common-standard-variant.component.html',
    styles: [
        ':host { width: 100%; }',
        '.tree-item-buttons { width: 150px; }',
        '.tree-item-title { width: calc(100% - 210px); }',
    ],
    styleUrls: ['./common-standard-variant.component.less'],
})
export class CommonStandardVariantComponent implements OnInit {
    @Input() public rootItem;
    @Input() public list;
    @Input() public level;

    @Input() public xsdData;
    @Input() public allowEdit = true;
    @Input() public parentToggled;

    @Output() public dragStarted = new EventEmitter<any>();

    public metaData;
    public activeData: any = {};

    public editedVariant;
    public editedVariantList;

    @Output() public onEditXsd = new EventEmitter<object>();
    @Output() public editVariantClicked = new EventEmitter<object>();
    public localizations;
    public toggled;

    public constructor(
        public standardsService: CommonStandardsService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this._clearDraggableTemp();
        this.metaData = this.standardsService.metaReglament.blocks.find(item => item.code === 'variants');
        this.activeData = {
            code: 'variants',
            name: this.metaData.variantsData.name,
            allowEditInReglament: !this.metaData.variantsData.allowEditInReglament,
        };
        this.standardsService.initBlockData(this.activeData);
        if (this.xsdData) {
            this.editedVariant = this.xsdData.data;
            this.editedVariantList = this.xsdData.list;
        }
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
                'standards',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public onDrop(event, index, newList, item?, posIndex = 0) {
        if (!this.parentToggled) {
            setTimeout(() => {
                if (newList) {
                    this.standardsService.oldList.splice(this.standardsService.draggableIndex, 1);
                } else {
                    if (item === this.standardsService.draggableItem) {
                        console.log('Попытка поместить элемент сам в себя!');
                        this._clearDraggableTemp();

                        return;
                    }

                    this.standardsService.oldList.splice(this.standardsService.draggableIndex, 1);
                }
                setTimeout(() => {
                    if (newList) {
                        newList.splice(this._correctIndex(index, newList, posIndex), 0, this.standardsService.draggableItem);
                        this._clearDraggableTemp();
                    } else {
                        item.children = item.children || [];
                        setTimeout(() => {
                            item.children.splice(this._correctIndex(index, item.children, posIndex), 0, this.standardsService.draggableItem);
                            this._clearDraggableTemp();
                        });
                    }
                });
            });
        } else {
            console.log('Попытка поместить элемент сам в себя!');
            this._clearDraggableTemp();
        }
    }

    private _correctIndex(index, arr, postIndex): number {
        let result;
        if (index < (arr.length - 1) &&
            index < this.standardsService.draggableIndex) {
            result = index + 1;
        } else {
            result = index;
        }

        if (this.level !== this.standardsService.draggableLevel) {
            result += postIndex;
        }

        return result;
    }

    private _clearDraggableTemp() {
        delete this.standardsService.draggableIndex;
        delete this.standardsService.draggableItem;
        delete this.standardsService.oldList;
        delete this.standardsService.draggableLevel;
        this.toggled = false;
    }

    public childDragStarted(event) {
        this.onDragged(event.draggableIndex, event.draggableItem, event.oldList, event.draggableLevel);
        this.parentToggled = false;
        this.toggled = false;
    }

    public onDragged(draggableIndex, draggableItem, oldList, draggableLevel) {
        this.toggled = true;
        this.standardsService.oldList =  oldList;
        this.standardsService.draggableIndex = draggableIndex;
        this.standardsService.draggableItem =  draggableItem;
        this.standardsService.draggableLevel = draggableLevel;

        this.dragStarted.emit({draggableIndex, draggableItem, oldList, draggableLevel});
    }

    public onDragCanceled() {
        this.toggled = false;
    }

    public onDragEnd() {
        this.toggled = false;
    }

    public add(variant = null) {
        if (variant && !variant.children) {
            variant.children = [];
        }
        if (!variant && !this.activeData.entity.variants) {
            this.activeData.entity.variants = [];
        }
        // this.editedVariantList = variant ? variant.children : this.activeData.entity.variants;
        /*this.editedVariant = {
            guid: CommonUtilities.GenerateGuid(),
        };*/

        const editedVariant = {
            guid: CommonUtilities.GenerateGuid(),
        };
        const list = variant ? variant.children : this.activeData.entity.variants;
        this.editVariantClicked.emit({variant: editedVariant, list});
    }

    public edit(variant, list) {
        this.editVariantClicked.emit({variant, list});
    }

    public delete(variant, list) {
        const findIndex = list.findIndex(item => item.guid === variant.guid);
        list.splice(findIndex, 1);
        this._deleteVariantLinkedData(variant.guid);
        this.standardsService.saveEntity(this.activeData);
    }

    public apply() {
        const findIndex = this.editedVariantList.findIndex(item => item.guid === this.editedVariant.guid);
        if (findIndex === -1) {
            this.editedVariantList.push(this.editedVariant);
        } else {
            this.editedVariantList[findIndex] = this.editedVariant;
        }
        this.editedVariant = null;
        this.editedVariantList = null;
        this.standardsService.saveEntity(this.activeData);
    }

    public cancel() {
        this.editedVariant = null;
        this.editedVariantList = null;
    }

    public editXsd() {
        this.onEditXsd.emit({data: this.editedVariant, type: 'variant', list: this.editedVariantList, allowEdit: this.activeData.allowEdit});
    }

    public editedVariantClicked(event) {
        this.editVariantClicked.emit(event);
    }

    private _deleteVariantLinkedData(variantGuid) {
        const entity = this.activeData.entity;
        if (entity.documentGroups && entity.documentGroups.length && entity.documentGroups.variants && entity.documentGroups.variants.length) {
            entity.documentGroups.variants.forEach(group => {
                if (group.variants && group.variants.length > 0) {
                    const deletedVariantIndex = group.variants.findIndex(item => item.guid === variantGuid);
                    if (deletedVariantIndex !== -1) {
                        group.variants.splice(deletedVariantIndex, 1);
                    }
                }
            });
        }
        if (entity.subjectGroups && entity.subjectGroups.length) {
            entity.subjectGroups.forEach(group => {
                if (group.variants && group.variants.length) {
                    const deletedVariantIndex = group.variants.indexOf(variantGuid);
                    if (deletedVariantIndex !== -1) {
                        group.variants.splice(deletedVariantIndex, 1);
                    }
                }
            });
        }
    }
}
