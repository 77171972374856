import { SettingInterface } from '../../../interfaces/setting/setting.interface';

export const settings: SettingInterface[] = [
    {
        name: 'defaultStatuses',
        storageKey: 'defaultStatuses',
        default: null,
        value: setting => setting.value,
    },
    {
        name: 'archive.notcleanup.deal',
        storageKey: 'notCleanupArchiveAppeals',
        default: false,
        value: setting => setting.value,
    },
    {
        name: 'userEnvelopesMode',
        storageKey: 'userEnvelopesMode',
        default: false,
        value: setting => setting.value,
    },
    {
        name: 'period-waiting-send-to-archive-appeal',
        storageKey: 'waitingPeriodSendToArchive',
        default: 0,
        value: setting => setting.value,
    },
    {
        name: 'integration.mkgu.application',
        storageKey: 'mkguInterviewApplication',
        default: 'none',
        value: setting => setting.value,
    },
    {
        name: 'isDemoStend',
        storageKey: 'demoStend',
        default: { 'isDemo': false },
        value: setting => ({ 'isDemo': setting.value }),
    },
    {
        name: 'complex.appeal.simplified.mode',
        storageKey: 'complexAppealSimplifiedMode',
        default: null,
        value: setting => setting.value,
    },
    {
        name: 'integration.quickrequests.settings',
        storageKey: 'quickRequestsSettings',
        default: null,
        value: setting => setting,
    },
    {
        name: 'uploadedFileMaxSize',
        storageKey: 'uploadedFileMaxSize',
        default: 0,
        value: setting => {
            const sizeInMb = parseInt(setting.value, 10);

            return sizeInMb * 1024 * 1024;
        },
    },
];
