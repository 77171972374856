import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../../appeal-subservices.service';
import { RestService, ToasterService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'appeal-subservice-variant',
    templateUrl: './appeal-subservice-variant.component.html',
})
export class AppealSubserviceVariantComponent implements OnInit {
    @Input() public appealSubservice;
    @Input() public subservice = null;
    @Output() public onAfterChangeVariant = new EventEmitter<boolean>();

    public serviceVariants = []; // Массив вариантов из описания услуги доступных для выбора
    public selectedVariants = []; // Массив ранее выбранных вариантов

    public data; // описательный объект по услуге
    public localizations;

    public constructor(
        private appealSubservicesService: AppealSubservicesService,
        private validateService: AppealValidateService,
        private restService: RestService,
        private translate: TranslateService,
        private toaster: ToasterService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];

        // Обработка вариантов услуги
        if (this.subservice.variants) {
            this.subservice.variants.forEach(item => {
                this.serviceVariants.push(_.cloneDeep(item));
            });
        }
        // Инициализация ранее выбранного значения
        if (this.appealSubservice.variant) {
            this.selectedVariants = this.appealSubservice.variant.selectedVariants;
        }
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.subservices',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    /**
     * Метод выполняющийся при завершении выбора варианта услуги (сохранение выбранных данных в структуру услуги)
     */
    public async onEndSelectVariant() {
        const before = this.appealSubservice.variantXsd;
        this.appealSubservice.variant = this.selectedVariants[this.selectedVariants.length - 1];
        this.appealSubservice.variant.selectedVariants = JSON.parse(JSON.stringify(this.selectedVariants));

        // Флаг корректности установки варианта
        this.data.correctVariant = true;

        // XSD-варианта услуги
        let isExistXsdInSelectedVariant = false;
        let variantAndParentXsds: string[];
        this.selectedVariants.forEach(variant => {
            if (variant.xsdLink || variant.xsd) {
                this.data.variantXsd = variant.xsdLink ? variant.xsdLink.xsd : variant.xsd;
                this.data.variantXsdRequired = variant.xsdLink ? variant.xsdLink.required : true;
                variantAndParentXsds = variantAndParentXsds || [];
                variantAndParentXsds.push(this.data.variantXsd);

                isExistXsdInSelectedVariant = true;
            }
        });

        // TODO проверить поля в родительских вариантах
        const fieldsAreUnique = await this._checkSubFieldsAreUnique(variantAndParentXsds);
        if (!fieldsAreUnique) {
            this.toaster.alert(this.localizations['appeals.subservices'].fields_are_not_unique);

        }
        this.data.variantAndParentXsds = variantAndParentXsds;

        // Если в новом варианте нет XSD
        if (before && !isExistXsdInSelectedVariant) {
            delete this.data.variantXsd;
            delete this.data.variantSchema;
            delete this.data.variantXsdData;
            delete this.data.variantAndParentXsds;
            this.appealSubservice.variantXsd = null;
            this.appealSubservice.variantXsdRequired = false;
            this.appealSubservice.variantXsdValid = true;
            this.appealSubservice.variantXsdData = null;
            this.appealSubservice.variantAndParentXsds = null;
            this.onAfterChangeVariant.emit(true);
        }

        // Генерация групп объектов в соответствии с выбранным вариантом
        this.appealSubservicesService.generateServiceGroups(this.appealSubservice);

        // Если изменилась XSD, то удаляем связанные данные
        if (this.data.variantXsd && this.data.variantXsd !== this.appealSubservice.variantXsd) {
            delete this.data.variantSchema;
            delete this.data.variantXsdData;
            delete this.appealSubservice.variantXsdValid;
            delete this.appealSubservice.variantXsdData;
            this.appealSubservice.variantXsd = this.data.variantXsd;
            this.appealSubservice.variantXsdRequired = this.data.variantXsdRequired;
            this.appealSubservice.variantAndParentXsds = this.data.variantAndParentXsds;
            // Уведомляем родительский элемент о изменении варианта услуги с целью переинициализации набора вкладок, если у варианта есть xsd
            this.onAfterChangeVariant.emit(true);
        }

        // Корректировка параметров валидации
        this.validateService.validateAppeal(this.validateService.processValidate);

        // КНМ корректности настройки объектов внутри услуги (группа участников, вид участия, категория)
        this.appealSubservicesService.correctEntitiesDataAfterChangeAppealSubserviceVariant(this.appealSubservice);
    }

    /**
     * КНМ наличия ошибок
     * @returns {number}
     */
    public checkErrorExist() {
        return this.validateService.getTabErrorsCount(this.appealSubservice.id, 'subservices', 'variant');
    }

    /**
     * Проверяет, чтобы родительские и дочерние поля
     * были прикпреплены к разным переменным
     */
    private async _checkSubFieldsAreUnique(xsdsIds: string[]): Promise<boolean> {
        const fieldIds = new Set();
        if (!xsdsIds) {
            return true;
        }

        for (const xsdId of xsdsIds) {
            const xsd: any = await this.restService.find('xsd', xsdId);
            if (xsd.xsdContent && typeof xsd.xsdContent === 'object') {
                for (const section of xsd.xsdContent) {
                    if (section.cols) {
                        for (const column of section.cols) {
                            if (column.content) {
                                for (const item of column.content) {
                                    if (item.options) {
                                        if (item.options.name && fieldIds.has(item.options.name)) {

                                            return false;
                                        }

                                        fieldIds.add(item.options.name);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return true;
    }
}
