import { Injectable } from '@angular/core';
import {
    FilesService,
    HttpService,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealData } from './appeal.data';
import { UtilityService } from '../../../common/services/utility.service';
import { AppealStatusService } from './appeal-status.service';
import { Config } from '../../../common/services/config';
import { AppealSaveService } from './appeal-save.service';
import { ServicesService } from '../../subservices/services/services.service';
import * as moment from 'moment';
import chain from 'lodash-es/chain';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import sortBy from 'lodash-es/sortBy';
import { SPECIALIST_ROLE } from '../../../common/constants';
import get from 'lodash-es/get';
import uniq from 'lodash-es/uniq';
import { TaskPingService } from '../../../common/services/task-ping.service';

@Injectable()
export class AppealService {
    public appeal; // обрабатываемое дело
    public complexSubservice; // комплексная услуга, на основе которой формируется дело
    // TODO поменять во всех сервисах, которые используют это поле
    public subservice; // массив услуг, на основе которых формируется дело
    public currentUser = this.storage.getItem('user');
    public currentOrganization = this.storage.getItem('currentOrganization'); // текущая организация

    public statuses = []; // возможные статусы
    public filtersPanelItems = AppealData.filtersPanelItems; // параметры панели фильтров в списке дел
    public finishStatuses = AppealData.finishStatuses; // набор кодов статусов являющихся финальными для дела
    // mandatoryFilters = AppealData.mandatoryFilters;
    // appealsActualityFilter = AppealData.actualityStatus;
    public noFilterFilter = this._localizeBlock(AppealData.noFilterFilter);

    public baseAppeal = null;
    public appealStatus: any = {};
    public appealSubstatus: any = {};
    public activeAppealAction;
    public appealActions;
    public appealPayments; // Платежи в деле
    public metaReglament;

    public printForms = []; // печатные формы дела

    // Параметры для работы с групповыми операциями
    public groupOperation = null; // Текущая групповая операция

    public userPermissions = this.storage.getItem('userPermissions');

    public isProcessSetupAppealSubservices = false; // Режим настройки услуг в деле
    public selectedSubservicesForSetupInAppeal = []; // Массив выбранных услуг для добавления в дело

    public isProcessSelectSubserviceForCopyAppeal = false; // Режим замены услуги в копируемом деле на другую услугу
    public copyAppealParams; // Данные для копирования дела (возможно измененные в процессе обработки)
    public changeAppealSubserviceFromCopyAppeal; // Услуга, которая заменяется в деле
    public selectedSubserviceForCopyAppeal; // Выбранная услуга для замены в копируемом деле

    public appealCopy; // Дело сформированное процедурой копирования
    // TODO изменить кто связан с этим полем
    public subserviceFromAppealCopy; // Услуги скопированного дела

    public isProcessSelectAppealsForPacket = false; // Режим выбора дел для пакета (перевода дел в другое МФЦ)
    public packet; // пакет, в который добавляются дела

    public activeTabInAppealBeforeCreate;

    public isActiveProcessInAppeal = false;

    public processTasks = [];

    public allKnoUsers;

    public appealLinksData: any = {}; // объекты связанных с делом сущностей: событие, план проверки, программа проверки, надзорное дело

    public COMPLETE_STATUS = 'complete';
    public DRAFT_STATUS = 'draft';
    public SERVICE_REJECT_STATUS = 'serviceReject';
    public RECEIVE_DOCS_REJECT_STATUS = 'receiveDocsReject';
    public ANNULED_STATUS = 'annulled';
    public ARCHIVE_STATUS = 'archive';

    public editing;
    private localizations;

    public constructor(
        private httpService: HttpService,
        private rest: RestService,
        private storage: StorageService,
        private utility: UtilityService,
        private servicesService: ServicesService,
        private statusService: AppealStatusService,
        private appealSaveService: AppealSaveService,
        private translate: TranslateService,
        private taskPingService: TaskPingService,
        private filesService: FilesService,
        private toaster: ToasterService
    ) {
        this._loadTranslations();
    }

    private _localizeBlocks(blocks) {
        blocks = blocks.map(this._localizeBlock.bind(this));

        return blocks;
    }

    private _localizeBlock(block) {
        if (block.name && block.name.length) {
            block.name = get(this.localizations, block.name, '');
        }

        if (block.shortName && block.shortName.length) {
            block.shortName = get(this.localizations, block.shortName, '');
        }

        if (block.labelText && block.labelText.length) {
            block.labelText = get(this.localizations, block.labelText, '');
        }

        return block;
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals', 'filters']).subscribe((res: string) => {
            this.localizations = res;
            this.statuses = this._localizeBlocks(AppealData.statuses);
        });
    }

    /**
     * Очистка данных сервиса при редактировании дела
     */
    public clearData(): void {
        this.appeal = null;
        this.complexSubservice = null;
        this.subservice = null;
        this.appealPayments = null;
        this.metaReglament = null;
        this.appealLinksData = {};
    }

    /**
     * Корректировка комплексной услуги, пришедшей с сервера в соответствии с текущей организацией
     * @param complexSubservice
     */
    public correctComplexSubserviceByCurrentUnit(complexSubservice): any {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const result: any = {
            _id: complexSubservice._id,
            code: complexSubservice.code,
            title: complexSubservice.title,
            description: complexSubservice.description,
            lifeEvent: { id: complexSubservice.lifeEvent.id, name: complexSubservice.lifeEvent.title },
            subservice: {},
        };

        complexSubservice.standards.forEach((standard) => {
            standard.reglaments.forEach((reglament) => {
                const findUnit = reglament.units.find((item) => item.id === currentOrganization._id);

                if (findUnit) {
                    const regl = {
                        id: reglament.id,
                        name: reglament.title,
                        displayOrder: standard.displayOrder,
                        startAfter: null,
                    };

                    // Определяем наличие возможности старта услуги только после выполнения зависимых услуг
                    if (standard.startAfter && standard.startAfter.length) {
                        const startAfterReglaments = [];

                        standard.startAfter.forEach((code) => {
                            const parentStandard = complexSubservice.standards.find((item) => item.code === code);
                            parentStandard.reglaments.forEach((parentReglament) => {
                                const reglamentUnit = parentReglament.units.find(
                                    (item) => item.id === currentOrganization._id
                                );

                                if (reglamentUnit) {
                                    startAfterReglaments.push(parentReglament.id);
                                }
                            });
                        });

                        if (startAfterReglaments.length) {
                            regl.startAfter = startAfterReglaments;
                        } else {
                            delete regl.startAfter;
                        }
                    }

                    if (result.subservice && Object.keys(result.subservice).length) {
                        throw new Error(
                            `Попытка переназначить subservice с ${this.subservice.id} на ${result.subservice.id}`
                        );
                    } else {
                        result.subservice = regl;
                    }
                }
            });
        });

        this.complexSubservice = result;

        return result;
    }

    /**
     * Корректировка услуги с учетом организации, под которой работает пользователь
     * @param service - обрабатываемая услуга
     */
    public correctSubserviceByCurrentUnit(service): any {
        return this.servicesService.correctSubserviceByCurrentUnit(service);
    }

    public async makeUploadRequest(appeal) {
        const url = `${Config.server}${Config.app}${Config.api}storage/beginCreateAppealZipFiles?appealId=${appeal._id}`;
        const response = await this.httpService.get(url);
    }

    public async getAppealsFilesInZIP(appeal) {
        const beginCreateAppealZipFilesUrl = `${Config.server}${Config.app}${Config.api}storage/beginCreateAppealZipFiles?appealId=`;

        const response: any = await this.httpService.get(beginCreateAppealZipFilesUrl + appeal._id);
        const task: any = await this.taskPingService.startPingTask(response._id, 1000);
        if (task.state === 'error') {
            throw task.result;
        }

        await this.filesService.downloadAndSaveFile(task.result.fileId, task.result.fileName);
    }

    /**
     * Базовая инциализация дела при создании
     */
    public initAppeal(): any {
        const currentOrganization = this.storage.getItem('currentOrganization');

        this.appeal = {
            unitId: currentOrganization._id,
            unit: {
                id: currentOrganization._id,
                name: currentOrganization.name,
                shortName: currentOrganization.shortName,
                rguId: currentOrganization.rguId ? currentOrganization.rguId : '',
                code: currentOrganization.code ? currentOrganization.code : '',
            },
            pin: Math.floor(1000 + Math.random() * 9000).toString(),
            subservice: {},
            subjects: [],
            objects: [],
            documents: [],
            controlOperator: [],
        };

        if (currentOrganization.region) {
            this.appeal.unit.region = {
                code: currentOrganization.region.code,
                name: currentOrganization.region.name,
            };
        }

        if (this.complexSubservice) {
            this.appeal.complexSubservice = {
                id: this.complexSubservice._id,
                name: this.complexSubservice.title,
            };
        }

        const defaultStatuses = this.storage.getItem('defaultStatuses');
        const startStatus = defaultStatuses.find((item) => item.isStart);

        this.appeal.status = this.statusService.initStatusData(startStatus); // Инициализация данных статуса в деле
        this.appeal.statusHistory = [this.appeal.status];

        return this.appeal;
    }

    /**
     * Инициализация услуги при создании дела (при выборе услуг из комплексной услуги)
     * @param subservice
     */
    public async initSubserviceInAppeal(subservice) {
        const result: any = {
            id: subservice._id,
            variant: null,
            title: subservice.titles.title,
            shortTitle: subservice.titles.shortTitle,
            serviceId: subservice.serviceId,
            parentEntries: 'appeals.subservices',
            guid: CommonUtilities.GenerateGuid(),
            subjects: [],
            objects: [],
            entities: [],
            xsdData: {},
            sendToErp: !!subservice.sendToErp,
        };
        // Присваиваем actualResultFinishNotificationPeriod
        const standart: any = await this.rest.find('standards', subservice.standardId);
        result.actualResultFinishNotificationPeriod = standart.variants;

        if (subservice.classificSubserviceId) {
            result.classificSubserviceId = subservice.classificSubserviceId;
            result.classificSubserviceName = subservice.classificSubserviceName;
        }

        if (subservice.version) {
            result.version = subservice.version;
        }

        if (subservice.externalNumber) {
            result.externalNumber = subservice.externalNumber;
        }

        const currentOrganization = this.storage.getItem('currentOrganization');

        result.responsibleOrganization = {
            id: currentOrganization._id,
            name: currentOrganization.name,
            shortName: currentOrganization.shortName,
            rguId: currentOrganization.rguId ? currentOrganization.rguId : '',
            code: currentOrganization.code ? currentOrganization.code : '',
        };

        // XSD по услуге
        if (subservice.xsd) {
            result.xsd = subservice.xsd;
            result.xsdRequired = subservice.xsdLink ? subservice.xsdLink.required : true; // обязательность заполнения XSD (по умолчанию обязательно)
        }

        // Данные ФРГУ
        if (subservice.additionalInfo && subservice.additionalInfo.rgu && subservice.additionalInfo.rgu.length > 0) {
            result.rgu = subservice.additionalInfo.rgu;
        }

        // Инициализация статуса
        const startStatus = subservice.statusModel.status.find((item) => item.isStart);

        result.status = this.statusService.initStatusData(startStatus);
        result.statusHistory = [result.status];

        // Вариант выдачи результата оказания услуги (в МФЦ, в ОГВ)
        if (subservice.issueResultForm) {
            result.issueResultForm = null;
            // Если вариант выдачи определен в услуге
            if (subservice.issueResultForm !== 'anyUnit') {
                result.issueResultForm = subservice.issueResultForm;
            }
        }

        return result;
    }

    public processingKndData(subservice): any {
        const kndInfo: any = {};

        if (subservice.kndInfo) {
            if (subservice.kndInfo.kndTypeCode) {
                const code = subservice.kndInfo.kndTypeCode;
                const kndTypeDict = this.storage.getItem('nsiErpKnmTypes');
                const typeItem = kndTypeDict.filter((type) => type.code === code)[0];

                if (typeItem) {
                    kndInfo.kndType = { code: code, name: typeItem.name };
                }
            }

            if (subservice.kndInfo.kndFormCode) {
                const code = subservice.kndInfo.kndFormCode;
                const kndFormDict = this.storage.getItem('nsiErpKnmForms');
                const formItem = kndFormDict.filter((form) => form.code === code)[0];

                if (formItem) {
                    kndInfo.kndForm = { code: code, name: formItem.name };
                }
            }
            // Виды КНД
            kndInfo.kndKinds = [];

            if (subservice.kndInfo.kndKinds && subservice.kndInfo.kndKinds.length === 1) {
                kndInfo.kndKinds = [
                    {
                        code: subservice.kndInfo.kndKinds[0].code,
                        name: subservice.kndInfo.kndKinds[0].name,
                    },
                ];
            }

            if (subservice.kndInfo.basisKnm) {
                kndInfo.basisKnm = subservice.kndInfo.basisKnm;
            }

            if (subservice.kndInfo.typeFederalLaw) {
                kndInfo.typeFederalLaw = subservice.kndInfo.typeFederalLaw;
            }

            if (subservice.kndInfo.npa) {
                kndInfo.npa = subservice.kndInfo.npa;
            }
        }

        return kndInfo;
    }

    /**
     * Обработка дел для выставления дополнительных свойств дела: просрочен, истекает и т.д.
     * @param appeals - массив дел
     */
    public processAppealsProperties(appeals): any[] {
        appeals.forEach((appeal) => {
            delete appeal.notify;
            // Определение статусов "Просрочено", "Истекает"
            let isExpiredProcess = false; // флаг того что период оказания хотя бы по одной услуги дела истек
            let isExpireProcess = false; // флаг того, что период оказания хотя бы одной услуги дела истекат
            let isExpiredIssue = false; // флаг того, что время хранения результата хотя бы по одной услуге истек

            // В случае просрочки проверяем статус каждой услуги - нам не нужно помечать успешно завершенные дела, черновики и т. д.
            isExpiredProcess =
                appeal.subservice.datePlaneFinish &&
                moment(appeal.subservice.datePlaneFinish) < moment().startOf('day') &&
                appeal.subservice.status.code !== this.COMPLETE_STATUS &&
                appeal.subservice.status.code !== this.DRAFT_STATUS &&
                appeal.subservice.status.code !== this.SERVICE_REJECT_STATUS &&
                appeal.subservice.status.code !== this.RECEIVE_DOCS_REJECT_STATUS &&
                appeal.subservice.status.code !== this.ANNULED_STATUS &&
                appeal.subservice.status.code !== this.ARCHIVE_STATUS;

            // Если до конца периода оказания услуги осталось меньше двух дней, то помечаем желтой меткой (если услуга незавершенная, не черновик...)
            isExpireProcess =
                appeal.datePlaneFinish &&
                appeal.datePlaneExpired &&
                moment(appeal.datePlaneFinish) > moment().startOf('day') &&
                moment(appeal.datePlaneExpired).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') &&
                appeal.subservice.status.code !== this.COMPLETE_STATUS &&
                appeal.subservice.status.code !== this.DRAFT_STATUS &&
                appeal.subservice.status.code !== this.SERVICE_REJECT_STATUS &&
                appeal.subservice.status.code !== this.RECEIVE_DOCS_REJECT_STATUS &&
                appeal.subservice.status.code !== this.ANNULED_STATUS &&
                appeal.subservice.status.code !== this.ARCHIVE_STATUS;

            isExpiredIssue =
                appeal.subservice.dateMaxIssue &&
                (appeal.subservice.status.code === 'beforeIssued' || appeal.subservice.status.code === 'rejected') &&
                moment(appeal.subservice.dateMaxIssue) < moment().startOf('day');

            if (isExpiredProcess || isExpireProcess || isExpiredIssue) {
                appeal.notify = [];

                if (isExpiredProcess) {
                    appeal.notify.push({
                        icon: null,
                        theme: 'bg-danger',
                        text: this.localizations.appeals['processing-time'].expired,
                    });
                }

                if (isExpireProcess) {
                    appeal.notify.push({
                        icon: null,
                        theme: 'bg-orange',
                        text: this.localizations.appeals['processing-time'].expires,
                    });
                }

                if (isExpiredIssue) {
                    appeal.notify.push({
                        icon: null,
                        theme: 'bg-pink',
                        text: this.localizations.appeals['processing-time'].storage_time_expires,
                    });
                }
            }

            if (appeal.isHavingResult) {
                if (!appeal.notify) {
                    appeal.notify = [];
                }

                appeal.notify.push({
                    icon: 'icon-bookmark2',
                    theme: 'text-primary-600',
                    text: this.localizations.appeals['processing-time'].cases_results,
                });
            }

            // Расчет процента прогресса дела от даты регистрации дела
            appeal.progressInProcent = 0; // значение по умолчанию

            if (
                appeal.dateFinish ||
                appeal.dateReject ||
                appeal.status.code === 'rejectedReceiptDocs' ||
                appeal.dateAnnul ||
                appeal.dateConsultation
            ) {
                appeal.progressInProcent = 100;
            } else if (appeal.datePlaneFinish) {
                const termFinish = Math.ceil(
                    (new Date(appeal.datePlaneFinish).getTime() - new Date(appeal.dateCreation).getTime()) /
                        1000 /
                        60 /
                        60 /
                        24
                );
                const calcValue =
                    ((termFinish -
                        Math.ceil(
                            (new Date(appeal.datePlaneFinish).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
                        )) /
                        termFinish) *
                    100;

                appeal.progressInProcent = calcValue > 100 ? 100 : calcValue;
            }
        });

        return appeals;
    }

    public refreshAppealStatusData(status, service): void {
        let mainAction = null;
        let actions;
        const statusGuid = status.isSubstatus ? status.mainStatusGuid : status.guid;
        // Объект текущего статуса дела
        this.appealStatus = service.statusModel.status.find((item) => item.guid === statusGuid);

        if (status.isSubstatus) {
            // Объект подстатуса дела
            this.appealSubstatus = this.appealStatus.subStatuses.find((item) => item.guid === status.guid);
            actions = this.appealSubstatus.actions;
            mainAction = actions.find((item) => item.isMain);

            if (!mainAction) {
                mainAction = actions[0];
            }

            if (this.appealSubstatus.canFinish) {
                actions = actions.concat(this.appealStatus.actions.filter((item) => !item.isStartSubstatus));
            }
        } else {
            actions = this.appealStatus.actions;
        }

        this.appealActions = actions;

        if (!mainAction) {
            mainAction = actions.find((item) => item.isMain);
        }

        if (!mainAction) {
            mainAction = actions[0];
        }

        if (mainAction) {
            this.activeAppealAction = mainAction;
        }
    }

    public getStatusProperty(code, property): any {
        if (!code) {
            return '';
        }

        const status = this.statuses.find((item) => item.code === code);

        if (status) {
            return status[property];
        }

        return '';
    }

    public calculateProgressBar(appeals): any[] {
        appeals.forEach((appeal) => {
            if (appeal.dateFinish || appeal.dateAnnul || appeal.dateConsultation) {
                appeal.progressInProcent = 100;
            } else if (appeal.datePlaneFinish) {
                const termFinish = Math.ceil(
                    (new Date(appeal.datePlaneFinish).getTime() - new Date(appeal.dateCreation).getTime()) /
                        1000 /
                        60 /
                        60 /
                        24
                );
                const calcValue =
                    ((termFinish -
                        Math.ceil(
                            (new Date(appeal.datePlaneFinish).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
                        )) /
                        termFinish) *
                    100;

                appeal.progressInProcent = calcValue > 100 ? 100 : calcValue;
            } else {
                appeal.progressInProcent = 0;
            }
        });

        return appeals;
    }

    /**
     * Цвет для label статусов
     * @param code
     * @param additional - приставка к цвету, например text-
     */
    public getColorForLabelStatuses(code, additional?): string {
        let classes = this.getStatusProperty(code, 'background');

        if (additional) {
            classes = classes + ' ' + additional + classes.slice(2);
        }

        return classes;
    }

    /**
     * Определение статуса согласия на обработку персональных данных для клиента
     * @param appealStatus
     * @param param - параметр, который нужно вернуть
     */
    public getAppealStatusColor(appealStatus, param?): any {
        const result = this.statuses.find((element) => {
            return element.code === appealStatus;
        });

        return param && result ? result[param] : result;
    }

    /**
     * Запрет на редактиррование дела с данными статусами (блокируем кнопки сохранения и удаления)
     */
    public checkCanChangeAppealByStatus(): boolean {
        return !this.finishStatuses.includes(this.appealStatus.code);
    }

    /**
     * Получение пользователей для контроля над делом
     */
    public async getUsersForControl(organizationId, page = 0) {
        const params = [
            {
                andSubConditions: [
                    {
                        field: 'linkRolesMnemonics.sprOrganization',
                        operator: 'eq',
                        value: organizationId,
                    },
                    {
                        field: 'linkRolesMnemonics.roleMnemonic',
                        operator: 'eq',
                        value: SPECIALIST_ROLE,
                    },
                ],
            },
        ];

        const users = await this.rest.search('users', { page, search: { search: params } });

        return this.utility.getDataForProperties(users, ['id', 'login', 'name']);
    }

    public async getUserFormLogin(login, organizationId) {
        const params = [
            {
                andSubConditions: [
                    {
                        field: 'linkRolesMnemonics.sprOrganization',
                        operator: 'eq',
                        value: organizationId,
                    },
                    {
                        field: 'linkRolesMnemonics.roleMnemonic',
                        operator: 'eq',
                        value: SPECIALIST_ROLE,
                    },
                    {
                        field: 'login',
                        operator: 'eq',
                        value: login,
                    },
                ],
            },
        ];

        const users = await this.rest.search('users', { search: { search: params } });

        return this.utility.getDataForProperties(users, ['id', 'login', 'name']);
    }

    public getAppealPayments(): void {
        const payments = [];

        const appealSubservice = this.appeal.subservice;

        if (this.subservice.payments) {
            this.subservice.payments.forEach((subservicePayment) => {
                if (
                    (appealSubservice.variant &&
                        subservicePayment.variantGuids.indexOf(appealSubservice.variant.guid) !== -1) ||
                    (!appealSubservice.variant && !subservicePayment.variantGuids.length)
                ) {
                    const paymentsExists = payments.some((item) => item.code === subservicePayment.code);

                    if (!paymentsExists) {
                        const paymentToAdd = cloneDeep(subservicePayment);

                        delete paymentToAdd.variantGuids;
                        delete paymentToAdd.unitId;

                        payments.push(paymentToAdd);
                    }
                }
            });
        }

        if (payments.length) {
            this.appealPayments = cloneDeep(payments);
        }
    }

    /**
     * Получение списка участников, согласных на опрос
     * @param appealSubservice - услуга дела
     */
    public getAppealSubserviceObjectsAgreeMkguInterview(appealSubservice): any[] {
        const objectGuids = [];

        appealSubservice.objects.forEach((object) => {
            objectGuids.push(object.guid);

            if (object.representative) {
                objectGuids.push(object.representative.guid);
            }
        });

        if (objectGuids.length > 0) {
            return this.appeal.objects.filter(
                (item) => item.agreeMkguInterview && objectGuids.indexOf(item.guid) !== -1
            );
        }

        return [];
    }

    public completeCamundaTask(taskId, mainId, guid, params): Promise<any> {
        const url = Config.server + Config.app + Config.api;

        return this.httpService.post(`${url}camunda/task/${taskId}/complete?mainId=${mainId}&guid=${guid}`, params);
    }

    /**
     * КНМ активности процесса камунды
     * @param id - ID процесса
     */
    public async checkCamundaProcessActivity(id = null) {
        if (!id && this.appeal.subservice.camundaProcessInfo) {
            id = this.appeal.subservice.camundaProcessInfo.id;
        }

        if (!id) {
            throw false;
        }

        const url = Config.server + Config.app + Config.api + 'camunda/process-instance/' + id;
        try {
            const result: any = await this.httpService.get(url);
            this.isActiveProcessInAppeal = result.status !== 'NOT_FOUND';

            return this.isActiveProcessInAppeal;
        } catch (error) {
            if (!(error.status === 404 && error.error.status === 'NOT_FOUND')) {
                throw false;
            }

            this.isActiveProcessInAppeal = false;

            return false;
        }
    }

    public async updateAppealCamundaProcessTasks() {
        const search = [
            {
                andSubConditions: [
                    {
                        field: 'mainId',
                        operator: 'eq',
                        value: this.appeal._id,
                    },
                    {
                        field: 'guid',
                        operator: 'eq',
                        value: this.appeal.subservice.guid,
                    },
                ],
            },
        ];
        const camundaBusinessInfos: any = await this.rest.search('camundaBusinessInfo', { search: { search } });
        if (camundaBusinessInfos.length) {
            this.processTasks = camundaBusinessInfos[0].tasks.map((item) => ({
                ...item,
                camundaBusinessInfoId: camundaBusinessInfos[0]._id,
            }));
        }

        return true;
    }

    public getCamundaTasks(camunda): any[] {
        const arr = chain(camunda)
            .values()
            .map((item) => ({
                ...item,
                startDate: moment(item.created).format('DD.MM.YYYY HH:mm'),
                finishDate: moment(item.created).format('DD.MM.YYYY'),
            }))
            .value();

        return orderBy(arr, ['created'], ['desc']);
    }

    public completeTaskInAppealProcess(task): Promise<any> {
        this.appeal.subservice.camunda.tasks[task.id].status = 'COMPLETED';

        return this.appealSaveService.saveAppeal();
    }

    public getAppealsCollection(): string {
        if (this.metaReglament) {
            const find = this.metaReglament.blocks.find((item) => item.code === 'appealData');

            if (find && find.appealsCollection) {
                return find.appealsCollection;
            }
        }

        return 'appeals';
    }

    public getRegistersCollection(): string {
        if (!(this.metaReglament && this.metaReglament.collections)) {
            return null;
        }

        const find = this.metaReglament.collections.find((item) => item.code === 'registersCollection');

        return find ? find.value : null;
    }

    public correctCheckLists(): void {
        if (!(this.subservice.checkLists && this.subservice.checkLists.length)) {
            return;
        }

        this.appeal.objects.forEach((subject) => {
            if (subject.objects && subject.objects.length) {
                subject.objects.forEach((object) => {
                    const objectId = object.reestrId;

                    if (
                        !(this.appeal.checkLists && this.appeal.checkLists.some((item) => item.objectId === objectId))
                    ) {
                        this._createCheckList(subject.guid, object.guid);
                    }
                });
            }
        });
    }

    /**
     * Добавление чек листа для объекта
     * @param subjectGuid
     * @param objectGuid
     */
    private _createCheckList(subjectGuid, objectGuid): void {
        if (!this.subservice.checkLists) {
            return;
        }

        if (!this.appeal.checkLists) {
            this.appeal.checkLists = [];
        }

        const subject = this.appeal.objects.find((item) => item.guid === subjectGuid);
        const object = subject.objects.find((item) => item.guid === objectGuid);
        const npaLink = this.subservice.checkLists.length ? this.subservice.checkLists[0].npaLink : null;

        const objectCheckList = {
            guid: CommonUtilities.GenerateGuid(),
            subjectId:
                subject.specialTypeId === 'ulApplicant'
                    ? subject.data.organization.reestrId
                    : subject.data.person.reestrId,
            subjectGuid: subject.specialTypeId === 'ulApplicant' ? subject.guid : subject.guid,
            subjectAuid:
                subject.specialTypeId === 'ulApplicant' ? subject.data.organization.auid : subject.data.person.auid,
            objectId: object.reestrId,
            objectGuid: object.guid,
            objectAuid: object.auid,
            questions: [],
            npaLink,
        };

        let questionIndex = 1;

        this.subservice.checkLists.forEach((checkList) => {
            if (checkList.questions && checkList.questions.length) {
                checkList.questions.forEach((question) => {
                    const questionExists = objectCheckList.questions.some((item) => item.id === question.id);
                    if (!questionExists) {
                        objectCheckList.questions.push({
                            auid: questionIndex,
                            id: question.id,
                            text: question.name,
                            answerYes: question.answerYes,
                            answerNo: question.answerNo,
                            answerNotConcidered: question.answerNotConcidered,
                            mandatoryReqs: question.mandatoryReqs,
                            nPA: question.nPA,
                        });
                        questionIndex++;
                    }
                });
            }
        });

        this.appeal.checkLists.push(objectCheckList);
    }

    public deleteCheckList(objectId): void {
        if (!this.appeal.checkLists) {
            return;
        }

        this.appeal.checkLists = this.appeal.checkLists.filter((item) => item.objectId === objectId);
    }

    public async initAppealLinksData() {
        this.appealLinksData = {};

        if (!this.appeal.eventId) {
            return;
        }

        const event: any = await this.rest.find('events', this.appeal.eventId);
        if (!event) {
            return;
        }
        this.appealLinksData.event = event;
        const promises = [];
        const linksItems = [];
        if (event.inspectionProgramId) {
            promises.push(this.rest.find('inspectionProgramGsn', event.inspectionProgramId));
            linksItems.push('inspectionProgramGsn');
        }
        if (event.supervisoryCaseId) {
            promises.push(this.rest.find('supervisoryCase', event.supervisoryCaseId));
            linksItems.push('supervisoryCase');
        }

        const result = await Promise.all(promises);
        linksItems.forEach((link, idx) => {
            this.appealLinksData[link] = result[idx];
        });
    }

    public async refreshAppealAfterChangeStatus() {
        const appeal: any = await this.rest.find('appeals', this.appeal._id);

        delete appeal.subjects;
        delete appeal.objects;
        delete appeal.documents;

        this.appeal = Object.assign(this.appeal, appeal);
        this.appeal.subservice = Object.assign(this.appeal.subservice, appeal.subservice);

        this.storage.setItem('baseAppeal', this.appeal);
        this.appealSaveService.baseAppeal = cloneDeep(this.appeal);
    }

    public async fetchCurrentAppeal() {
        if (this.appeal) {
            this.appeal = await this.rest.find('appeals', this.appeal._id);
        }
    }

    // TODO преобразовать subservicesArr в один subservice
    public subserviceBelongsToAppeal(correctSubservice, appeal) {
        if (!correctSubservice) {
            return false;
        }

        if (!appeal) {
            return true;
        }

        if (correctSubservice.mainId) {
            // происходит обновление существующего subservice
            return correctSubservice.mainId === appeal._id;
        }

        if (!appeal.subservice) {
            // добавляем первый (создаём) subservice в деле
            return true;
        }

        return correctSubservice.guid === appeal.subservice.guid;
    }

    public sendSetControlOperatorRequest(appeal) {
        const url = Config.server;

        return this.httpService.post(`${url}api/tatar/v1/appeal/${appeal._id}/assignee/${appeal.controlOperator}`, {});
    }

    /**
     * Добавление поиска по коду услуги
     * @param search - перечень элементов поиска
     */
    public prepareSearchBySubservices(search: any[]): void {
        if (!(this.currentUser.externalPermissions && !this.currentUser.isSuperAdmin)) {
            return;
        }

        const specialistPermission = this.currentUser.externalPermissions.find((item) => item.code === 'specialist');
        const supervisorPermission = this.currentUser.externalPermissions.find((item) => item.code === 'supervisor');
        const specialistServices =
            specialistPermission && Array.isArray(specialistPermission.values) ? specialistPermission.values : [];
        const supervisorServices =
            supervisorPermission && Array.isArray(supervisorPermission.values) ? supervisorPermission.values : [];
        const services = uniq([...specialistServices, ...supervisorServices]);

        if (services.length) {
            search.push({
                field: 'subservices.externalNumber',
                operator: 'in',
                value: services,
            });
        }
    }

    public prepareExternalNumberFilters(search: any[]) {
        const currentRolesMnemonics = this.storage.getItem('user').currentRolesMnemonics;
        if (currentRolesMnemonics && currentRolesMnemonics.includes('operator_eks')) {
            const condition = search.find(
                (s) => s.orSubConditions && s.orSubConditions.find((c) => c.field === 'organizationExecutor.id')
            );
            if (condition) {
                condition.orSubConditions.push({
                    field: 'subservices.externalNumber',
                    operator: 'likeAnd',
                    value: 'R-',
                });
            }
        }
    }
}
