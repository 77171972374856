import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonProcessService } from '../common-process.service';
import { ToasterService } from '@evolenta/core';
import * as moment from 'moment';

@Component({
    selector: 'entity-process',
    template: '',
})
export class EntityProcessComponent implements OnInit, OnDestroy {
    @Input() public processId; // идентификатор процесса в камунде
    @Input() public processInfoId; // идентификатор из коллекции camundaBusinessInfo
    @Input() public checkProcess = true; // необходимость проверки активности процесса в зависимости от статуса родительской сущности
    @Input() public process; // описательный процесс
    @Input() public processInEntity; // настройки процесса из родительской сущности
    @Input() public entity; // основная сущность, из которой вызывается компонент
    @Input() public parentEntity; // родительская сущность (стандарт для регламента)
    @Input() public savedPlace; // место, где сохраняются данные по процессу
    @Input() public completedEvents; // место, где сохраняются данные по процессу
    @Input() public saveEntity; // функция сохранения основной сущности
    @Input() public dataForProcessing; // данные для обработки: доп. данные, группы документов и т.д.
    @Input() public idx;
    @Input() public useXsdService = true;
    @Input() public isOldVersion = false; // услуга спроектирована по старой схеме

    public data = {
        processId: null,
        processInfoId: null,
        isProcessActive: false, // статус активности процесса
        isProcessUpdateData: false, // осуществление процесса обновления информации по элементам процесса
        isProcessSaveEntity: false,
        activeTasks: [],
        completedTasks: [],
        activeServiceTasks: [],
        completedServiceTasks: [],
        allTimers: [],
        timers: [],
        endEvents: [],
        events: [],
        completedEvents: [],
        elements: [],
        activeEvent: null,
        activeEventEmptyData: false,
        process: this.process,
        processInEntity: this.processInEntity,
        entity: this.entity,
        parentEntity: this.parentEntity,
        savedPlace: this.savedPlace,
        processingElements: [],
        dataForProcessing: {},
        isOldVersion: false,
        initProcess: () => {
            this.initProcess();
        },
        saveEntity: () => {
            this.data.isProcessSaveEntity = true;
            this.onSaveEntity.emit();
        },
        useXsdService: this.useXsdService,
        isProcessingCompleteElement: false,
    };

    @Output() public onSaveEntity = new EventEmitter<boolean>();
    @Output() public afterCompleteProcess = new EventEmitter<boolean>();
    @Output() public needUpdateEntity = new EventEmitter<boolean>();
    @Output() public afterInitProcessData = new EventEmitter<boolean>();

    public activeEvent;
    public updateTasksInterval;
    public isInitProcessData = true;

    public constructor(
        private processService: CommonProcessService,
        private toaster: ToasterService,
    ) {
    }

    public ngOnInit() {
        this.data.processId = this.processId;
        this.data.processInfoId = this.processInfoId;
        this.data.process = this.process;
        this.data.processInEntity = this.processInEntity;
        this.data.entity = this.entity;
        this.data.savedPlace = this.savedPlace;
        this.data.completedEvents = this.completedEvents;
        this.data.parentEntity = this.parentEntity;

        if (this.dataForProcessing) {
            this.data.dataForProcessing = this.dataForProcessing;
        }

        this.data.isOldVersion = this.isOldVersion;

        this.initProcess();
    }

    public initProcess() {
        if (this.processId) {
            if (this.checkProcess) {
                this.data.isProcessUpdateData = true;
                this.processService.checkProcessIsActive(this.processId).then(isActiveProcess => {
                    this.data.isProcessActive = isActiveProcess;
                    if (!isActiveProcess) {
                        this.afterCompleteProcess.emit();
                        this.clearProcessingInterval();
                        this.toaster.success('Процесс успешно завершен');
                    }
                    this.updateTasksList(isActiveProcess);
                });
            } else {
                this.data.isProcessActive = false;
                this.data.isProcessUpdateData = false;
                this.updateTasksList(false);
            }
        }
    }

    public clearProcessingInterval() {
        if (this.updateTasksInterval) {
            clearInterval(this.updateTasksInterval);
        }
    }

    public checkActiveProcess() {
        return this.processService.checkProcessIsActive(this.processId);
    }

   public updateTasksList(isProcessActive = false) {
        this.processService.updateProcessData(this.processInfoId, this.processId, isProcessActive).then((resultData: any) => {
            if (resultData.isEntryStatusChanged) {
                this.needUpdateEntity.emit(true);
            }
            this.prepareProcessData(resultData);
            if (this.data.activeTasks.length > 0 || this.data.events.length > 0) {
                this.clearProcessingInterval();
                this.data.isProcessUpdateData = false;
                this.completeInitProcessData();
            } else if (isProcessActive && resultData.isProcessActive) {
                if (!this.updateTasksInterval) {
                    this.updateTasksInterval = setInterval(() => {
                        this.updateTasksList(resultData.isProcessActive);
                    }, 7000);
                }
            } else if (!isProcessActive || !resultData.isProcessActive) {
                if (!resultData.isProcessActive && isProcessActive) {
                    this.afterCompleteProcess.emit();
                    this.clearProcessingInterval();
                    this.toaster.success('Процесс успешно завершен');
                    this.updateTasksList(false);
                }
                this.data.isProcessUpdateData = false;
                this.completeInitProcessData();
            }
        });
    }

    public completeInitProcessData() {
        if (this.isInitProcessData) {
            this.afterInitProcessData.emit(true);
            this.isInitProcessData = false;
        }
    }

    public prepareProcessData(data) {
        this.data.activeTasks = data.userTasks.activeTasks ? data.userTasks.activeTasks : [];
        this.data.completedTasks = data.userTasks.completedTasks ? data.userTasks.completedTasks : [];
        this.data.activeServiceTasks = data.serviceTasks.activeTasks ? data.serviceTasks.activeTasks : [];
        this.data.completedServiceTasks = data.serviceTasks.completedTasks ? data.serviceTasks.completedTasks : [];
        this.data.endEvents = data.endEvents ? data.endEvents : [];
        this.data.timers = data.timers ? data.timers : [];
        this.data.events = data.events ? data.events : [];
        this.prepareTasks();
    }

    public prepareTasks() {
        const elements = this.data.activeTasks
            .concat(this.data.completedTasks)
            .concat(this.data.activeServiceTasks)
            .concat(this.data.completedServiceTasks)
            .concat(this.data.timers.map(item => ({...item, status: 'ACTIVE'})))
            .concat(this.data.completedEvents.map(item => ({...item, status: 'COMPLETED', startTime: item.created, type: 'EVENT'})));
        elements.sort((a, b) => {
            const aCompareField = a.type === 'USER_TASK' ? moment(a.created).unix() : moment(a.startTime).unix();
            const bCompareField = b.type === 'USER_TASK' ? moment(b.created).unix() : moment(b.startTime).unix();
            if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
                return -1;
            } else if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
                return 1;
            } else if (aCompareField < bCompareField) {
                return 1;
            } else if (aCompareField > bCompareField) {
                return -1;
            }

            return 0;
        });
        const before = JSON.stringify(this.data.elements);
        const after = JSON.stringify(elements);
        if (before !== after) {
            this.data.elements = elements;
        }
    }

    public ngOnDestroy(): void {
        clearInterval(this.updateTasksInterval);
    }
}
