<fieldset>
    <legend class="text-bold" [ngClass]="{'no-padding-top no-margin no-border': !showBlockTitle, 'pb-15': !showBlockTitle && isExistData}">
        <span *ngIf="showBlockTitle">{{block.name}}</span>
        <!--  Статус блока в регламенте -->
        <common-standard-block-status [block]="block" [small]="true" *ngIf="standardsService.reglament && block.allowEditInReglament"></common-standard-block-status>

        <!--  Комментарии к процессу согласования -->
        <common-standard-process-comments [block]="block" [process]="activeProcess" (saveEntity)="standardsService.saveEntity(block)"
                                   *ngIf="isShowComments"></common-standard-process-comments>

        <a class="control-arrow" (click)="hideBlock = !hideBlock" *ngIf="useAdditionalData && showBlockTitle">
            <i [ngClass]="{'icon-arrow-down12' : !hideBlock, 'icon-arrow-left12' : hideBlock}"></i>
        </a>
    </legend>
    <div class="collapse" [ngClass]="{'in': !hideBlock, 'panel panel-shadow': asPanel}" *ngIf="useAdditionalData">
        <div [ngClass]="{'panel-body': asPanel}">
            <dynamic-form
                #blockData
                [xsd]="block.xsd"
                [model]="getBlockEditedProperty()"
                [externalData]="{organization: organization}"
                [saveInMainObject]="true"
                [disabled]="!block.allowEdit">
            </dynamic-form>
        </div>
    </div>
</fieldset>
