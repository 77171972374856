import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RestService } from '@evolenta/core';

@Injectable()
export class ControlOperatorsResolve implements Resolve<any> {
    public constructor(private rest: RestService) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        // TODO дописать сюда получение списка ответственных
    }
}
