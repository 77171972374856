import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AccessService,
    FilesService,
    HttpService,
    RestService,
    SelectionService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { PrintingService } from '@evolenta/printing';
import { NgProgress } from 'ngx-progressbar';
import { PrintFormsService } from '../../../../common/services/print/print-forms.service';
import { DocumentsComponent } from '../components/documents/documents.component';
import { ObjectsComponent } from '../components/objects/objects.component';
import { AppealService } from '../appeal.service';
import { DocumentService } from '../components/documents/document.service';
import { AppealValidateService } from '../appeal-validate.service';
import { AppealSaveService } from '../appeal-save.service';
import { AppealStatusService } from '../appeal-status.service';
import { ReasonModalComponent } from '../components/objects/special-types/reason-modal/reason-modal.component';
import { AppealSubservicesService } from '../appeal-subservices.service';
import { ReestrModalComponent } from '../components/objects/special-types/reestr-modal/reestr-modal.component';
import { Permission } from '../../../../common/services/permission';
import { TicketService } from '../../../../common/services/ticket.service';
import { QuickRequestsService } from '../../../../common/services/quick-requests.service';
import { AppealRequirementsService } from '../appeal-requirements.service';
import { AppealProcessService } from '../components/process/appeal-process.service';
import { ProcessService } from '../../../processes/process.service';
import { EventsService } from '../../../events/events/events.service';
import { Config } from '../../../../common/services/config';
import { AppealSubservicesCreateComponent } from '../components/subservices/create/appeal-subservices-create.component';
import { AppealSubjectsCreateComponent } from '../components/appeal-subjects/create/appeal-subjects-create.component';
import { TaskPingService } from '../../../../common/services/task-ping.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { FilesQueueService } from '../../../../common/services/filesQueue.service';
import { ErrorLoggingService } from '../../error-logging.service';

@Component({
    selector: 'appeal',
    templateUrl: 'appeal-create.component.html',
    styleUrls: [
        '../../../element-edit.css',
    ],
})
export class AppealCreateComponent implements OnInit, OnDestroy {
    public mode; // режим работы: редактирование, создание на основе услуги, создание на основе комплексной услуги
    public appeal: any = {}; // обрабатываемое дело
    public complexSubservice = null; // комплексная услуга, на основе которой формируется дело
    public subservice: any = {}; // услуга на основе которой формируется дело

    public appealSubservicesData = []; // описательный массив для услуг (для хранения единоразово запрашиваемой с сервера информации)

    public printForms = []; // Массив печатных форм
    public printFormsWithoutDocs = []; // Массив печатных форм без тех, которые привязаны к видам документов дела

    public historyItems = null; // Массив элементов истории по делу
    public envelopes = null; // Конверты в деле
    public messages = null; // Сообщения в деле

    public activeTab = 'subservice'; // Активная вкладка
    public currentOrganization = this.storage.getItem('currentOrganization'); // Текущая активная организация

    public appealProcessingType; // Вид операции с делом: save - сохранение, changeStatus - выполнение действия (изменение статуса)
    public appealProcessingSubservice; // Услуга дела, в которой в данный момент осуществляется переход на другой статус
    public processingAction; // Действие, которое выполняется для перевода дела (услуги) в другой статус
    public actions; // массив доступных действий для дела
    public activeAction; // активное действие
    public isRollBack = false; // режим отката статуса
    public reasonItem; // выбранный элемент доп действия для перехода на другой статус
    public reasonItems; // массив элементов для отображения в диалоговом окне при выполнении специальных действий (отказ, аннулирование и т.д.)
    public reasonFields; // массив описаний текстовых полей
    public reasonTitle; // заголовок модального окна со спец действиями для action-а дела
    public parentReason; // значение родительского элемента в случае наличия дочерних действий
    public reasonReturnOnlyCode = false; // возвращать только код из объекта элементов
    @ViewChild('reasonModal', { static: false }) public reasonModalComponent: ReasonModalComponent; // модальное окно для выбора причины отказа, аннулирования и т.д.

    // Переменные-компоненты, для доступа к внутренним функциям (КНМ сохранения)
    @ViewChild('appealSubservices', { static: false }) public appealSubservicesCreateComponent: AppealSubservicesCreateComponent; // услуги дела
    @ViewChild('appealSubjects', { static: false }) public appealSubjectsCreateComponent: AppealSubjectsCreateComponent; // объекты дела
    @ViewChild('appealObjects', { static: false }) public appealObjectsComponent: ObjectsComponent; // объекты дела
    @ViewChild('appealDocuments', { static: false }) public appealDocumentsComponent: DocumentsComponent; // документы дела
    @ViewChild('reestrModal', { static: false }) public reestrModal: ReestrModalComponent; // модальное окно для выбора соответствия объекта дела и клиента в реестре клиентов

    public permissions; // набор прав доступа к элементам системы

    public currentTicket = null; // текущий талон ЭО
    public appealSubserviceActions = [];
    public moduleBaseUrl;

    public updateProcessTasksInterval;
    public address = null;

    public isProcessingCleanupAndMoveToArchive = false; // процесс очистки персональных данных и переноса дела в архив из преархива
    public isProcessingRollbackFromPreArchive = false; // процесс переноса дела из преархива в основную коллекцию

    public localizations;

    private processTasksEntryStatusChangedSubscription: Subscription = null;
    private processTasksPollTriggeredSubscription: Subscription = null;
    public initialized;

    public constructor(
        public validateService: AppealValidateService,
        public appealSaveService: AppealSaveService,
        public accessService: AccessService,
        public appealProcessService: AppealProcessService,
        private route: ActivatedRoute,
        private appealSubservicesService: AppealSubservicesService,
        private storage: StorageService,
        private appealService: AppealService,
        private printFormService: PrintFormsService,
        private printingService: PrintingService,
        private progressService: NgProgress,
        private toaster: ToasterService,
        private documentService: DocumentService,
        private filesService: FilesService,
        private restService: RestService,
        private appealStatusService: AppealStatusService,
        private ticketService: TicketService,
        private quickRequestsService: QuickRequestsService,
        private appealRequirementsService: AppealRequirementsService,
        private selectionService: SelectionService,
        private processService: ProcessService,
        private eventsService: EventsService,
        private httpService: HttpService,
        private errorLoggingService: ErrorLoggingService,
        private router: Router,
        private translate: TranslateService,
        private taskPingService: TaskPingService,
        private filesQueueService: FilesQueueService,
    ) {
    }

    /**
     * Инициализация компонента: обработка данных, полученных по resolve
     */
    public async ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.permissions = Permission; // описание всех доступных прав доступа
        // Определение режима работы: редактирование, создание на основе услуги, создание на основе комплексной услуги
        this.route.params.subscribe((params: any) => {
            if (params.appealId) {
                this.mode = 'edit';
            } else if (params.subserviceId) {
                this.mode = 'createOnSubservice';
            } else if (params.complexSubserviceId) {
                this.mode = 'createOnComplexSubservice';
            }

            this.filesService.setEntryData(null, null);
        });

        if (this.selectionService.isProcessSelect) {
            await this._processingSelectQuestionsForCheckList();

            return;
        }

        if (!this.appealService.isProcessSetupAppealSubservices) {
            this._baseInitServiceData(); // Очистка данных сервиса (чтобы не было хвостов от работы с другими делами)
            // Получение и обработка первоначальных данных
            this.route.data.subscribe(async (response: any) => {
                    // Удаление базового дела
                    this.storage.removeItem('baseAppeal');

                    if (this.mode === 'createOnComplexSubservice') {
                        /**
                         * Создание дела на основе комплексной услуги
                         */
                        // Корректировка комплексной услуги в соответствии с текущей организацией
                        this.complexSubservice = this.appealService.correctComplexSubserviceByCurrentUnit(response.resolves);
                        // Базовая инициализация дела
                        this.appeal = this.appealService.initAppeal();

                        // Инициализация данных в сервисах для работы с сервисом
                        await this._initServicesData();

                    } else if (this.mode === 'createOnSubservice') {
                        // Если дело формируется на основе копируемого дела
                        if (this.appealService.appealCopy) {
                            this.appeal = _.cloneDeep(this.appealService.appealCopy); // сформированное при копировании дело
                            this.subservice = _.cloneDeep(this.appealService.subserviceFromAppealCopy); // услуги, по которым сформировано дело
                            // Очистка данных сервиса
                            this.appealService.appealCopy = null;
                            this.appealService.subserviceFromAppealCopy = null;
                        } else {
                            /**
                             * Создание дела на основе отдельной услуги
                             */
                                // Корректировка услуги в соответствии с текущей организацией
                            const subservice = this.appealService.correctSubserviceByCurrentUnit(response.resolves);
                            if (this.subservice && Object.keys(this.subservice).length) {
                                throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ subservice.id }`);
                            } else {
                                this.subservice = this.appealService.correctSubserviceByCurrentUnit(response.resolves);
                            }

                            this.appealService.subservice = this.subservice;

                            // Базовая инициализация дела
                            this.appeal = this.appealService.initAppeal();
                            // Добавление каркаса описания объектов внутри услуги
                            const subs = await this.appealService.initSubserviceInAppeal(subservice);

                            if (this.appeal.subservice && Object.keys(this.appeal.subservice).length) {
                                throw new Error(`Попытка переназначить subservice с ${ this.appeal.subservice.id } на ${ subs.id }`);
                            } else {
                                this.appeal.subservice = subs;
                            }
                        }
                        // Инициализация данных в сервисах для работы с сервисом
                        await this._initServicesData();

                        // Получение печатных форм
                        if (this.accessService.hasAccess([this.permissions.Print_Forms])) {
                            await this._updateListPrintForms(true);
                        }
                    } else if (this.mode === 'edit') {
                        /**
                         * Редактирование дела
                         */
                        // Получение объекта дела
                        this.appeal = response.resolves.appeal;
                        this.appealService.appeal = this.appeal;
                        if (this.appeal.status.code !== 'draft') {
                            this.activeTab = 'process';
                        }

                        this.storage.setItem('baseAppeal', this.appeal);

                        // Корректировка услуг дела в соответствии с текущей организацией
                        response.resolves.subservice.forEach(subservice => {

                        });

                        this.appealSubservicesData.push({id: response.resolves.subservice._id});
                        if (this.subservice) {
                            throw new Error(`Попытка переназначить subservice с ${ this.subservice.id } на ${ response.resolves.subservice.id }`);
                        } else {
                            this.subservice = this.appealService.correctSubserviceByCurrentUnit(response.resolves.subservice);
                        }
                        this.appealService.subservice = this.subservice;

                        // Корректировка комплексной услуги в соответствии с текущей организацией
                        if (response.resolves.complexSubservice) {
                            this.complexSubservice = this.appealService.correctComplexSubserviceByCurrentUnit(response.resolves.complexSubservice);
                        }

                        // Инициализация данных в сервисах для работы с сервисом
                        await this._initServicesData();

                        // Получение печатных форм
                        if (this.accessService.hasAccess([this.permissions.Print_Forms])) {
                            await this._updateListPrintForms(true);
                        }

                        // Добавляем дату для уведомления о просрочке
                        if (this.appeal.datePlaneFinish && !this.appeal.datePlaneExpired) {
                            let dayExpired = 2;
                            if (Array.isArray(this.appeal.subservice.actualResultFinishNotificationPeriod)) {
                                const foundDate = this.appeal.subservice.actualResultFinishNotificationPeriod
                                    .find(item => item.guid === this.appeal.subservice.variant.guid);
                                dayExpired = (foundDate && foundDate.actualResultFinishNotificationPeriod) ? foundDate.actualResultFinishNotificationPeriod : 2;
                            }
                            this.appeal.datePlaneExpired =
                                moment(this.appeal.datePlaneFinish)
                                    .add(-dayExpired, 'days')
                                    .startOf('day')
                                    .format('YYYY-MM-DDTHH:mm:ssZZ');

                            this.appealSaveService.case = this.errorLoggingService.APPEAL;
                            await this.appealSaveService.saveAppeal();
                        }

                        if (this.appealService.activeTabInAppealBeforeCreate) {
                            this.activeTab = this.appealService.activeTabInAppealBeforeCreate;
                            this.appealService.activeTabInAppealBeforeCreate = null;
                        }
                    }

                    // Инициализация данных для валидации дела
                    this.validateService.appeal = this.appeal;
                    this.validateService.subservice = this.subservice;

                    // Добавление информации об обслуживаемом талоне в дело (при работе с пультом)
                    if (this.ticketService.ticket && this.ticketService.ticket.id) {
                        if (!this.appeal.tickets) {
                            this.appeal.tickets = [];
                        }
                        const existingTicket = this.appeal.tickets.some(item => item.id === this.ticketService.ticket.id);
                        if (!existingTicket) {
                            this.currentTicket = {
                                id: this.ticketService.ticket._id,
                                externalId: this.ticketService.ticket.id,
                                seansId: this.ticketService.ticket.seansId,
                            };
                            this.appeal.tickets.push(this.currentTicket);
                        }
                    }

                    // Задание упрощенного режима работы с комплексным делом при приеме документов
                    // this._initComplexAppealMode();

                    this.initialized = true;
                },
                error => {
                    console.log(error);
                },
            );
        } else {
            // Режим дополнительного выбора услуг в состав комплексного дела
            this.appeal = this.appealService.appeal;
            this.subservice = this.appealService.subservice;
            this.complexSubservice = this.appealService.complexSubservice;
            // this._initComplexAppealMode();
            this.initialized = true;
        }
        this.filesQueueService.open();
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals',
                'services',
                'organizations',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Определение параметра упрощенного режима работы с комплексным делом при приеме документов (до регистрации дела)
     */
    private _initComplexAppealMode() {
        if (this.appeal.status.code === 'draft' && this.appeal.subservice && !this.appeal.hasOwnProperty('simplifyMode')) {
            const complexAppealSimplifiedMode = this.storage.getItem('complexAppealSimplifiedMode');
            if (complexAppealSimplifiedMode) {
                this.appeal.simplifyMode = complexAppealSimplifiedMode;
            }
        }
    }

    private async _processingSelectQuestionsForCheckList() {
        this.appeal = this.selectionService.transferObject;
        // TODO заменить в этом сервисе
        this.subservice = this.selectionService.additionalData.subservice;
        const foundCheckList = this.appeal.checkLists.find(item => item.guid === this.selectionService.additionalData.checkList.guid);
        if (foundCheckList) {
            this.selectionService.selectedItems.forEach(question => {
                const existingQuestion = foundCheckList.questions.some(item => item.id === question._id);
                if (!existingQuestion) {
                    foundCheckList.questions.push({
                        id: question._id,
                        text: question.name,
                        answerYes: question.answerYes,
                        answerNo: question.answerNo,
                        answerNotConcidered: question.answerNotConcidered,
                        auid: foundCheckList.questions.length,
                        mandatoryReqs: question.mandatoryReqs,
                        nPA: question.nPA,
                    });
                }
            });
        }
        this.selectionService.clearData();
        this.activeTab = 'requirements';
        await this._initServicesData();
    }

    /**
     * Очистка (обнуление) данных сервисов для работы с делом
     */
    public _baseInitServiceData() {
        this.appealService.clearData();
        this.appealStatusService.clearData();
        this.appealSubservicesService.clearData();
        this.documentService.clearData();
        this.validateService.clearData();
        this.quickRequestsService.clearData();
        this.appealRequirementsService.clearData();
    }

    /**
     * Инициализация данных сервисов
     */
    private async _initServicesData() {
        // Общие данные по делу: услуги, комплексные услуги
        this.appealService.appeal = this.appeal;
        this.appealService.subservice = this.subservice;
        this.appealService.allKnoUsers = null;
        await this.appealService.initAppealLinksData();

        // Сервис статусов дела и услуг внутри дела
        this.appealStatusService.initData(this.appeal, this.subservice, this.complexSubservice);
        this.actions = this.appealStatusService.initAppealActions();
        this.activeAction = this.appealStatusService.activeAction;

        // Информация о настройке объектов в услугах
        this.appealSubservicesService.initData(this.appeal, this.subservice);

        // Сервис документов
        this.documentService.initData(this.appeal, this.subservice);

        // Сервис сохранения данных
        this.appealSaveService.appeal = this.appeal;
        this.appealSaveService.reestrModal = this.reestrModal;
        this.appealSaveService.isProcessSavingAppeal = false;
        this.appealSaveService.moduleBaseUrl = this.moduleBaseUrl;
        // Определение режима работы с делом редактирование / создание
        this.appealSaveService.isCreateAppealMode = this.mode !== 'edit';

        // Сервис валидации дела
        this.validateService.appeal = this.appeal;
        this.validateService.subservice = this.subservice;

        // Получение платежей
        this.appealService.getAppealPayments();

        // Сервис работы с требованиями
        this.appealRequirementsService.appeal = this.appeal;
        this.appealRequirementsService.subservice = this.subservice;

        // Инициализация информации о процессах камунды
        await this._initProcessInfo();
    }

    /**
     * Инициализация задач процесса
     */
    private async _initProcessInfo() {
        // Инициализация данных
        this.appealProcessService.tasks = [];
        this.appealProcessService.activeTasks = [];
        this.appealProcessService.completedTasks = [];
        this.appealProcessService.isProcessUpdateTasks = false;
        this.appealProcessService.isAutoUpdateTasks = true;
        this.appealProcessService.camundaProcessId = null;
        this.appealProcessService.tasksData = {};

        if (!(this.subservice.camundaProcess && Object.keys(this.subservice.camundaProcess).length > 0 && this.appeal.subservice.camundaProcessInfo)) {
            this.appealProcessService.isProcessActive = false;
            this.initTabAfterGetProcessInfo();

            return;
        }

        if (this.appeal.status.code === 'draft') {
            return;
        }

        const camundaProcessId = this.appeal.subservice.camundaProcessInfo.id;

        if (this.appeal.subservice.status.code === 'complete') {
            this.appealProcessService.isProcessActive = false;
            const tasksData: any = await this.processService
                .updateCamundaProcessTasks(
                    this.appeal._id,
                    this.appeal.subservice.guid,
                    this.appeal.subservice.camundaProcessInfo.camundaBusinessInfoId,
                );
            this.updateCamundaProcessTasks(tasksData);
            this.initTabAfterGetProcessInfo();
        }

        const isActiveProcess = await this.processService.checkProcessIsActive(camundaProcessId);
        this.appealProcessService.isProcessActive = isActiveProcess;
        await this._updateTasksList(
            this.appeal._id,
            this.appeal.subservice.guid,
            this.appeal.subservice.camundaProcessInfo.camundaBusinessInfoId,
        );

        if (!isActiveProcess) {
            this.appealProcessService.stopProcessTasksPolling();

            if (this.appealProcessService.activeTasks.length === 0) {
                if (this.appealProcessService.updateTasksInterval) {
                    clearInterval(this.appealProcessService.updateTasksInterval);
                }

                this.appealProcessService.isProcessUpdateTasks = false;
            }
        }

        this.appealProcessService.startProcessTasksPolling();

        // подписываемся на триггер обновления списка задач
        this.processTasksPollTriggeredSubscription = this.appealProcessService.processTasksPollTriggered
            .subscribe(async () => {
                const tasksData = await this.processService
                    .updateCamundaProcessTasks(
                        this.appeal._id,
                        this.appeal.subservice.guid,
                        this.appeal.subservice.camundaProcessInfo.camundaBusinessInfoId,
                    );

                this.appealProcessService.lastProcessTasksEntryStatusChangedAt = tasksData.entryStatusChangedAt;

                if (!this.appealProcessService.activeTasks.length) {
                    this.updateCamundaProcessTasks(tasksData);
                }
            });
    }

    public updateCamundaProcessTasks(tasksData: any): void {
        const resultTasks: any = this.processService.getTasksList(tasksData.userTasks);
        const serviceTasks = this.processService.getTasksList(tasksData.serviceTasks);

        this.appealProcessService.activeTasks = resultTasks.activeTasks;
        this.appealProcessService.completedTasks = resultTasks.completedTasks;
        this.appealProcessService.activeServiceTasks = serviceTasks.activeTasks;
        this.appealProcessService.completedServiceTasks = serviceTasks.completedTasks;
        this.appealProcessService.processTimers = tasksData.timers;
        this.appealProcessService.eventGateways = tasksData.eventGateways;
        this.appealProcessService.tasks = resultTasks.activeTasks.concat(resultTasks.completedTasks);
    }

    private _updateTasksList(
        mainId: string,
        guid: string,
        camundaBusinessInfoId: string,
    ) {
        return this.processService
            .updateTasksList(
                mainId,
                guid,
                camundaBusinessInfoId,
            )
            .then((result: any) => {
                this.appealProcessService.activeTasks = result.userTasks.activeTasks;
                this.appealProcessService.completedTasks = result.userTasks.completedTasks;
                this.appealProcessService.activeServiceTasks = result.serviceTasks.activeTasks;
                this.appealProcessService.completedServiceTasks = result.serviceTasks.completedTasks;
                this.appealProcessService.processTimers = result.timers;
                this.appealProcessService.eventGateways = result.eventGateways;
                this.appealProcessService.tasks = result.userTasks.activeTasks.concat(result.userTasks.completedTasks);

                // так как было произведено первоначальное считывание данных коллекции camundaBusinessInfo
                // запоминаем значение поля entryStatusChangedAt для его дальнейшего отслеживания
                this.appealProcessService.initProcessTasksEntryStatusChangeDetection(result.entryStatusChangedAt);

                // подписываемся на изменения поля entryStatusChangedAt
                this.processTasksEntryStatusChangedSubscription = this.appealProcessService.processTasksEntryStatusChanged
                    .subscribe(() => {
                        this.appealService.refreshAppealAfterChangeStatus().then(() => {
                            this.documentService.correctSubserviceDocGroups();
                        });
                    });

                if (this.appealProcessService.activeTasks.length > 0 || result.eventGateways.some(item => item.status === 'ACTIVE')) {
                    clearInterval(this.appealProcessService.updateTasksInterval);
                }

                this.initTabAfterGetProcessInfo();

                return Promise.resolve(result);
            });
    }

    /**
     * Активация вкладки связанной с задачами процесса
     */
    public initTabAfterGetProcessInfo() {
        if (!this.subservice.camundaProcess || Object.keys(this.subservice.camundaProcess).length === 0 || !this.appeal._id || this.appeal.status.code === 'draft') {
            this.activateTab('subservice');
        } else if (this.appealProcessService.isProcessActive) {
            this.activateTab('activeTasks');
        } else {
            this.activateTab('completedTasks');
        }
        if (!this.appealProcessService.isProcessActive && Object.keys(this.subservice).length > 0) {
            this.appealService.isActiveProcessInAppeal = false;
            this._getFirstAppealSubserviceActiveActions();
        }
    }

    private _getFirstAppealSubserviceActiveActions() {
        this.appealSubserviceActions = [];

        // Если не активны процессы камунды, отображаем действия услуги
        if (!this.appealService.isActiveProcessInAppeal && this.appeal.status.code !== 'complete') {
            const appealSubserviceData = this.appealSubservicesService.data[this.appeal.subservice.guid];
            this.appealSubserviceActions = appealSubserviceData.actions;
        }
    }

    /**
     * Активность вкладки Объекты
     */
    public get disabledObjectsTab() {
        return this.appeal.status.code === 'archive' && !this.appeal.inPreArchive;
    }

    /**
     * Активность вкладки Субъекты
     */
    public get disabledSubjectsTab() {
        return this.appeal.status.code === 'archive' && !this.appeal.inPreArchive;
    }

    /**
     * Активность вкладки Документы
     */
    public get disabledDocumentTab() {
        return this.appeal.status.code === 'archive' && !this.appeal.inPreArchive;
    }

    /**
     * Активность вкладки Конверты
     */
    public get disabledEnvelopesTab() {
        return this.appeal.status.code === 'draft' || this.appeal.documents.length === 0;
    }

    /**
     * Активность вкладки Сообщения
     * @returns {boolean}
     */
    public get disabledMessagesTab() {
        const objectsWithMessaging = this.appeal.objects.filter(item => item.messaging && item.messaging.length > 0);

        return !(objectsWithMessaging.length > 0 && this.appeal._id);
    }

    /**
     * Наличие файлов в документах дела
     */
    public get filesExist() {
        const documentsWithFiles = this.appeal.documents.filter(item => item.files && item.files.length > 0);

        return documentsWithFiles.length > 0;
    }

    public get existCamundaProcess() {
        return this.subservice.camundaProcess && Object.keys(this.subservice.camundaProcess).length > 0;
    }

    /**
     * КНМ сохранения данных при попытке активации другой вкладки при клике на нее
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        if (this.activeTab === 'subservice' && this.appealSubservicesCreateComponent) {
            this.appealSubservicesCreateComponent.checkSaveData(tab);
        } else if (this.activeTab === 'objects') {
            this.appealObjectsComponent.checkSaveData(tab);
        } else {
            const disabled = tab === 'objects' && this.disabledObjectsTab || tab === 'documents' && this.disabledDocumentTab || tab === 'envelopes' && this.disabledEnvelopesTab;
            if (!disabled) {
                this._changeHiddenPrintForms(tab);
                this.activeTab = tab;
            }
        }
    }

    /**
     * Изменение режима отображения / скрытия печатных форм в зависимости о того какая вкладка активируется
     * @param tab - активируемая вкладка
     */
    private _changeHiddenPrintForms(tab) {
        if (this.printFormsWithoutDocs) {
            this.printFormsWithoutDocs.forEach(item => {
                if (tab === 'history') {
                    item.hide = item.formType.indexOf('dealHistory') === -1;
                } else if (tab === 'documents') {
                    item.hide = item.formType.indexOf('dealHistory') !== -1;
                }
            });
        }
    }

    /**
     * Определение наличия видимых ПФ в выпдающем списке
     * @returns {boolean}
     */
    public existsVisiblePrintFormsWithoutDocs() {
        return this.printFormsWithoutDocs && this.printFormsWithoutDocs.filter(item => !item.hide).length > 0;
    }

    /**
     * Активация вкладки после КНМ необходимости сохранения данных в отдельных компонентах дела (случай открытия отдельного элемента на редактирование)
     * @param tab - активируемая вкладка
     */
    public activateTabAfterCheckSave(tab) {
        const disabled = tab === 'objects' && this.disabledObjectsTab || tab === 'documents' && this.disabledDocumentTab || tab === 'envelopes' && this.disabledEnvelopesTab;
        if (!disabled) {
            this._changeHiddenPrintForms(tab);
            this.activeTab = tab;
        }
    }

    /**
     * Сохранение дела (без валидации)
     */
    public async saveAppeal() {
        this.appealProcessingType = 'save';
        if (!this.appeal._id) {
            this.appealService.activeTabInAppealBeforeCreate = this.activeTab; // сохранение активной вкладки
        }

        await this._applyEditedElements();

        // TODO разобраться, почему без задержки не срабатывает. Скорее всего, где-то стоят лишние таймауты
        setTimeout(() => {
            this.filesService.uploadFilesToUpload('appeals', this.appeal._id);
            this.appealStatusService.appeal = this.appealSaveService.appeal;
            this.actions = this.appealStatusService.initAppealActions();
        }, 500);
    }

    /**
     * Активация определенного действия
     * @param action - активируемое действие
     * @param appealSubservice - услуга дела, в которой инициализируется действие (null если действие из дела, а не услуги)
     */
    public activateAction(action, appealSubservice = null) {
        // Если осуществляется переход на новый статус, а не откат статуса
        if (!action) {
            return;
        }

        if (!appealSubservice) {
            this.activeAction = action;
            this.appealStatusService.activeAction = action;
        }

        this.documentService.correctSubserviceDocGroups(!appealSubservice);
        if (this.appealDocumentsComponent) {
            this.appealDocumentsComponent.documentGroupsComponents.forEach(item => {
                item.initGroups();
            });
        }
        this.validateService.validateAppeal(true);
    }

    /**
     * Переход дела на новый статус
     * @param data - объект вида {action: активируемое действие,
     *                            appealSubservice: услуга в деле, для которой выполняется переход на новый статус (необязательный параметр при глобальном действии)
     *                            isRollBack: операция по откату предыдущего статуса}
     */
    public async executeAction(data) {
        // Обработка для конвертированных дел
        if (this.appeal.converted && !this.appeal.correctUnit) {
            this.currentOrganization = this.storage.getItem('currentOrganization');
            // Присваиваем значение unit
            this.appeal.correctUnit = true;
            this.appeal.unitId = this.currentOrganization._id;
            this.appeal.unit = {
                id: this.currentOrganization._id,
                name: this.currentOrganization.name,
                shortName: this.currentOrganization.shortName,
            };
        }
        this.processingAction = data.action;
        this.appealStatusService.currentExecuteAction = data.action;
        this.appealProcessingType = 'changeStatus';
        this.isRollBack = data.isRollBack;
        this.appealProcessingSubservice = data.appealSubservice ? data.appealSubservice : null;
        this.activateAction(this.processingAction, this.appealProcessingSubservice);
        await this._applyEditedElements(); // применение данных редактируемых в данный момент сущностей
        if (!(this.appeal && this.appeal._id)) {
            return;
        }

        await this.router.navigate([this.moduleBaseUrl, 'appeals', 'edit', this.appeal._id]);
    }

    /**
     * Применение изменений в редактируемых в текущий момент времени элементах дела
     * (в любой момент времени таким может быть только один элемент: услуга, объект или документ дела)
     */
    private _applyEditedElements() {
        if (this.appealSubservicesCreateComponent && this.appealSubservicesCreateComponent.editAppealSubserviceCardComponent && this.appealSubservicesCreateComponent.editAppealSubserviceCardComponent.mode === 'edit') {
            this.appealSubservicesCreateComponent.editAppealSubserviceCardComponent.apply(true);
        } else if (this.appealObjectsComponent && this.appealObjectsComponent.editObjectCardComponent && this.appealObjectsComponent.editObjectCardComponent.mode === 'edit') {
            this.appealObjectsComponent.editObjectCardComponent.apply(true);
        } else if (this.appealSubjectsCreateComponent && this.appealSubjectsCreateComponent.editSubjectCardComponent && this.appealSubjectsCreateComponent.editSubjectCardComponent.mode === 'edit') {
            this.appealSubjectsCreateComponent.editSubjectCardComponent.apply(true);
        } else if (this.appealDocumentsComponent && this.appealDocumentsComponent.editDocumentCardComponent && this.appealDocumentsComponent.editDocumentCardComponent.mode === 'edit') {
            this.appealDocumentsComponent.editDocumentCardComponent.apply(true);
        } else {
            return this.continueProcessingAppealAfterApplyEditedElement();
        }
    }

    /**
     * Продолжение процесса сохранения дела после применения редактируемых элементов дела
     */
    public async continueProcessingAppealAfterApplyEditedElement() {
        if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
            // Если инициализирован режим добавления участника из карточки услуги
            if (this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete) {
                // возврат после добавления / отмены добавления объекта
                this._afterAddNewSubjectToAppealSubserviceGroup();
            } else {
                this._processAddNewSubjectToAppealSubserviceGroup();
            }
        } else {
            const validateAppeal = this.appealProcessingType !== 'save' && this.processingAction.additionActions.indexOf('notValidate') === -1;
            const appealValid = this.validateService.validateAppeal(validateAppeal);

            if (!appealValid) {
                this.clearProcessExecuteActionData();
                this.toaster.error(this.localizations.appeals.notifications.error_while_saving);
            }

            // Сбрасываем флаг валидации в деле, чтобы он не переходил на последующие после сохранения / перехода на новый статус действия с делом
            this.validateService.processValidate = this.validateService.processValidate = false;
            // Если дело еще не было сохранено на сервере
            this.appealSaveService.case = this.errorLoggingService.APPEAL;
            if (!this.appeal._id) {
                return await this.appealSaveService.saveAppeal();
            }

            try {
                await this.appealSaveService.saveAppeal();
                if (this.appealProcessingType === 'changeStatus') {
                    return this._prepareExecuteAction();
                }

                this.toaster.success('Дело успешно сохранено');
            } catch (error) {
                await this.errorLoggingService.log(this.errorLoggingService.APPEAL, error);
                this.toaster.error(error);
            }
        }
    }

    private _afterAddNewSubjectToAppealSubserviceGroup() {
        this.activeTab = 'subservice';
    }

    public _processAddNewSubjectToAppealSubserviceGroup() {
        this.activeTab = 'subjects';
        setTimeout(() => {
            this.appealSubjectsCreateComponent.addSubject();
        }, 100);
    }

    /**
     * Очистка элементов, отвечающих за визуальное отображение процесса выполнения действия
     */
    public clearProcessExecuteActionData() {
        this.processingAction = null;
        this.parentReason = null;
        this.reasonItems = null;
        this.reasonFields = null;
        this.appealStatusService.currentExecuteAction = null;
        if (this.appealProcessingSubservice) {
            this.appealSubservicesService.data[this.appealProcessingSubservice.guid].isProcessExecuteAction = false;
        }
    }

    /**
     * Получение печатных форм с сервера
     */
    public async getPrintForms() {
        const search = [];
        // Получение только не удаленных записей
        search.push({
            field: 'isDeleted',
            operator: 'neq',
            value: true,
        });
        // Определение организация МФЦ + ОГВ
        const orgs = [];
        orgs.push(this.appeal.unit.id);
        if (this.appeal.subservice.responsibleOrganization) {
            orgs.push(this.appeal.subservice.responsibleOrganization.id);
        }
        // Настройка других параметров поиска
        search.push({
            orSubConditions: [
                {
                    field: 'serviceIds',
                    operator: 'in',
                    value: [this.subservice.serviceId],
                },
                {
                    field: 'orgIds',
                    operator: 'in',
                    value: orgs,
                },
            ],
        });
        search.push({
            andSubConditions: [
                {
                    field: 'notUseServiceIds',
                    operator: 'nin',
                    value: [this.subservice.serviceId],
                },
                {
                    field: 'notUseOrgIds',
                    operator: 'neq',
                    value: this.appeal.unit.id,
                },
            ],
        });

        try {
            const response = await this.restService.search('printForms', {search: {search: search}, size: 100});
            // Корректировка печатных форм в соответствии с типом дела (комплексное или нет) и формирование массива ПФ не привязанных к документам
            const filteredPrintForms = this.documentService.filterPrintForms(response);
            this.printForms = filteredPrintForms.printForms; // все используемые печатные формы
            this.appealService.printForms = this.printForms;
            this.printFormsWithoutDocs = this.filterPrintFormsByAppealStatus(filteredPrintForms.printFormsWithoutDocuments); // печатные формы без документов
        } catch (error) {
            this.toaster.error('Произошла ошибка при получени печатных форм ' + error);
        }
    }

    /**
     * Фильтрация списка печатных форм формируемых по кнопке "Печать" по статусу дела
     * @param printForms - начальный массив печатных форм
     * @returns {any}
     */
    public filterPrintFormsByAppealStatus(printForms) {
        const result = [];
        let formTypes = ['dealHistory'];
        if (this.appeal.status && this.appeal.status.code === 'consultation') {
            formTypes.push('consultation');
        } else {
            if (this.appeal.status.code === 'draft') {
                formTypes.push('appeal');
            }
            if (this.appeal.status.code === 'process' || (this.appeal.status.mainCode && this.appeal.status.mainCode === 'process')) {
                formTypes.push('appeal');
                formTypes.push('bringRegister');
            }
            if (['beforeIssued', 'issued', 'issuedReturnToOGV', 'issuedOther', 'rejected'].indexOf(this.appeal.status.code) !== -1) {
                formTypes.push('issueRegister');
            }
        }

        if (this.appealService.appealPayments && this.appealService.appealPayments.length > 0) {
            formTypes.push('payment');
        }

        if (formTypes.length > 0) {
            formTypes = _.uniq(formTypes);

            printForms.forEach(form => {
                formTypes.forEach(type => {
                    if (form.formTypeCode === type) {
                        result.push(form);
                    }
                });
            });
        }

        return result;
    }

    /**
     * Обновление списка печатных форм
     * @param whateverUpdate - обновлять список в любом случае, а не только при их отсутствии
     */
    private async _updateListPrintForms(whateverUpdate = false) {
        if (this.printForms && !whateverUpdate) {
            return;
        }

        await this.getPrintForms();
    }

    /**
     * Создание документа на основе печатной формы
     * @param printForm - печатная форма
     */
    public async createDocumentFromPrintForm(printForm) {
        if (printForm.formType.indexOf('dealHistory') !== -1) {
            await this._sendHistoryPrintForm(printForm); // Формирование печатной формы по истории дела

            return;
        }

        await this.appealDocumentsComponent.createDocumentFromPrintForm(printForm);
    }

    /**
     * Формирование печатной формы по истории дела
     * @param printForm
     */
    private async _sendHistoryPrintForm(printForm: any): Promise<void> {
        const data = this.printFormService.prepareAppealHistoryData(this.appeal, this.historyItems);

        this.progressService.start();

        const appealData = await this.printFormService.prepareAppealData(this.appeal, this.subservice, null, printForm);

        try {
            const response: any = await this.printingService
                .renderPrintForm(printForm._id, this.appeal._id, 'appeals', appealData, data, null, this.appeal.unit.id, this.appeal.subservice.responsibleOrganization.id);
            await this.filesService.downloadFile(response.file._id, response.file.name);
        } catch (error) {
            this.toaster.html(error.errorMessage || error);
        } finally {
            this.progressService.done();
        }
    }

    /**
     * Возвращает класс для отображения иконки для типа печатной формы
     * @param printForm - обрабатываемая печатная форма
     * @returns {any}
     */
    public getPrintFormIcon(printForm) {
        const icons = {
            docx: 'fa-file-word-o',
            doc: 'fa-file-word-o',
            xlsx: 'fa-file-excel-o',
            pdf: 'fa-file-pdf-o',
            other: 'fa-file-text-o',
        };
        const arr = printForm.formType.split('.');
        const extension = arr[1].toLowerCase();

        return icons[extension] ? icons[extension] : icons['other'];
    }

    /**
     * Сохранение истории по делу после инициализации компонента
     * @param historyItems - массив элементов истории по делу
     */
    public afterGetHistory(historyItems) {
        this.historyItems = historyItems;
    }

    /**
     * Сохранение измененного состава конвертов дела в переменную
     * (для того, чтобы не запрашивать повторно данные с сервера при повторной инициализации компонента работы с конвертами)
     * @param envelopes
     */
    public changeAppealEnvelopes(envelopes) {
        this.envelopes = envelopes;
    }

    /**
     * Сохраненнного измененного состава сообщений дела в переменную
     * @param messages
     */
    public changeAppealMessages(messages) {
        this.messages = messages;
    }

    /**
     * Подготовка к переходу на новый статус, заполнение дополнительных параметров для некоторых видов действий
     */
    private async _prepareExecuteAction() {
        // Если действие требует дополнительной обработки
        if (this.processingAction && this.appealStatusService.actionsWithAdditionalOperations[this.processingAction.code]) {
            return this._prepareReasonsData(this.processingAction.code);
        }

        // Запуск процедуры обработки действия
        await this._processExecuteAction();
    }

    private async _prepareReasonsData(code) {
        this.reasonItem = this.appealStatusService.actionsWithAdditionalOperations[code]; // текущее действие
        if (this.reasonItem.reference) {
            this.reasonItems = this.appealStatusService.prepareReasonsForAction(this.reasonItem.reference, this.reasonItem.type, this.appealProcessingSubservice); // набор уникальных элементов из всех услуг дела (либо одной услуги)
        }

        if (this.reasonItem.fields) {
            this.reasonFields = this.reasonItem.fields;
        }
        if (this.reasonItem.items) {
            this.reasonItems = this.reasonItem.items;
        }
        if (this.reasonItem.reference &&  this.reasonItems.length === 0) {
            // Если в услугах нет элементов для выбора из списка, обрабатываем пустым объектом
            await this.completeReasons({});
        } else {
            this.reasonTitle = this.reasonItem.title; // заголовок для модального окна
            this.reasonModalComponent.showModal(); // отображение модального окна
        }
    }

    /**
     * Обработка выбранных (заполненных) данных в модальном окне для доп.обработки выполняемого действия
     * @param data - данные
     */
    public async completeReasons(data) {
        let params = {};
        if (!this.parentReason) {
            if (this.reasonItem.code) {
                params[this.reasonItem.field && this.parentReason ? this.reasonItem.field : this.reasonItem.code] = data;
            }
            if (this.reasonItem.fields) {
                params = data;
            }
        }
        // Если есть дочерние действия для выбранного элемента, которые обрабатываются в модальном окне
        if (data.additionalActions && data.additionalActions.length > 0) {
            this.parentReason = {additionalActionCode: data.additionalActions[0], parentCode: this.reasonItem.code, params: data};
            this.reasonItem = null;
            this.reasonItems = null;
            this.reasonFields = null;
            await this._prepareReasonsData(data.additionalActions[0]);
        } else {
            if (this.parentReason) {
                // Если обрабатываются вложенные действия, то значения объединяются
                this.parentReason.params[this.reasonItem.field] = data;
                params = {};
                params[this.parentReason.parentCode] = this.parentReason.params;
            }
            await this._processExecuteAction(params); // запуск процедуры перехода на новый статус с учетом выбранных (заполненных) параметров
        }
    }

    /**
     * Переход дела на новый статус
     * @param properties - объект с настройками доп.обработки действия
     */
    public async _processExecuteAction(properties: any = {}) {
        if (this.isRollBack) {
            properties = {rollback: null};
        }

        try {
            const changedAppeal: any = await this.appealStatusService.executeAction(this.processingAction, properties, this.appealProcessingSubservice, this.currentTicket);
            this.clearProcessExecuteActionData(); // Очистка визуальных компонентов процесса выполнения действия
            // Если было осуществлено архивирование дела
            if (changedAppeal.status && changedAppeal.status.code === 'archive') {
                // Запрашиваем дело с сервера
                await this.restService.find('appealsArchive', changedAppeal._id);
                // Запрашиваем дело с сервера
                const notCleanupArchiveAppeals = this.storage.getItem('notCleanupArchiveAppeals');
                let archiveCollection = 'appealsArchive';
                if (notCleanupArchiveAppeals) {
                    archiveCollection = 'appealsPreArchive';
                }
                const appeal: any = await this.restService.find(archiveCollection, changedAppeal._id);
                if (archiveCollection === 'appealsArchive' && appeal.subjects.length > 0) {
                    appeal.subjects = [];
                    appeal.subservice.subjects = [];
                }
                this.processingAfterArchiveAppeal(appeal);

                return;
            }

            const changeAppealSubservice = _.cloneDeep(changedAppeal.subservice);
            delete changedAppeal.subservice;
            this.appeal = Object.assign(this.appeal, changedAppeal);
            this.actions = this.appealStatusService.initAppealActions();
            this.activeAction = null;
            if (this.actions.length > 0) {
                this.activeAction = this.actions[0];
            }

            this.appeal.subservice = Object.assign(this.appeal.subservice, changeAppealSubservice);
            this.appealSubservicesService.data[changeAppealSubservice.guid].isProcessExecuteAction = false;
            // Корректировка доступных действий
            this.appealSubservicesService.correctActions(changeAppealSubservice);
            // Если в деле есть процессы камунды
            if (changeAppealSubservice.camundaProcessInfo) {
                setTimeout(async () => {
                    const isActive = await this.appealService.checkCamundaProcessActivity(changeAppealSubservice.camundaProcessInfo.id);
                    if (!isActive) {
                        this._getFirstAppealSubserviceActiveActions();
                    }
                }, 3000);
            }

            // Перенастройка параметров документов дела
            this.documentService.calculateDocumentsPermissions();

            // Обновляем список печатных форм
            await this._updateListPrintForms(true);

            // Если дело переведено в статус "В обработке" или "Консультация" запускаем сохранение объектов в реестре клиентов
            if ((this.appeal.status.code === 'process' || this.appeal.status.code === 'consultation') && !this.appealProcessingSubservice) {
                // this.appealSaveService.saveObjectsInReestr();
                await this._initProcessInfo();
                this.createFakeHistoryItem();
            }

            if (this.appeal.status.code === 'complete' && this.appeal.eventId) {
                this.eventsService.checkCompleteAllProcessInEvent(this.appeal.eventId);
            }

            // Обновление данных базового дела
            this.storage.setItem('baseAppeal', this.appeal);

            this.toaster.success('Статус успешно изменен');
        } catch (error) {
            this.clearProcessExecuteActionData(); // Очистка визуальных компонентов процесса выполнения действия
            this.toaster.error('При попытки смены статуса произошла ошибка. Статус не был изменен. Попробуйте совершить операцию еще раз!');
        }
    }

    public async processingAfterArchiveAppeal(appeal) {
        this.appeal = appeal;
        this._baseInitServiceData();
        await this._initServicesData();

        this.storage.setItem('baseAppeal', this.appeal);
        this.appealService.baseAppeal = _.cloneDeep(this.appeal);
        if (!this.appeal.inPreArchive && this.appeal.subjects.length > 0) {
            this.appeal.subjects = [];
            this.appeal.subservice.subjects = [];
            this.appealSaveService.case = this.errorLoggingService.APPEAL;
            await this.appealSaveService.saveAppeal();
        }

        this.toaster.success('Статус успешно изменен');
    }

    public async createFakeHistoryItem() {
        const historyItem = {
            collectionName: 'appeals',
            mainId: this.appeal._id,
            subEntryName: null,
            subEntryGuid: null,
            parentEntries: 'history',
            code: 'sendRequest',
            subCode: null,
            type: 'entry',
            description: 'Отправлен запрос "Размещение сведений в реестре электронных паспортов контрольно-надзорных мероприятий"',
            entityType: null,
            data: null,
        };
        await this.restService.create('history', historyItem);
    }

    /**
     * Получение параметров для статуса дела
     * @param property
     */
    public getStatusProperty(property) {
        const statuses = this.subservice.statusModel.status;
        const status = statuses.find(item => this.appeal.subservice && this.appeal.subservice.status && item.code === this.appeal.subservice.status.code);
        if (status) {
            return property === 'background' ? 'bg-' + status.theme + '-300' : status[property];
        }

        return this.appealStatusService.getStatusProperty(this.appeal.status, property);
    }

    /**
     * Получение всех приложенных файлов обращения в zip, включая подписи и сертификаты
     */
    public async getAppealsFilesInZIP() {
        const  url = `${Config.server}${Config.app}${Config.api}storage/beginCreateAppealZipFiles?appealId=`;

        const response: any = await this.httpService.get(url + this.appeal._id);
        const task: any = await this.taskPingService.startPingTask(response._id, 1000);
        if (task.state === 'error') {
            this.toaster.error(task.result);

            return;
        }

        await this.filesService.downloadAndSaveFile(task.result.fileId, task.result.fileName);
    }

    /**
     * Изменение режима регистрации дела с неполным составом документов
     */
    public changeAppealIncompleteSetOfDocuments() {
        if (this.checkExistSubservicesInDraftStatus()) {
            this.appeal.incompleteSetOfDocuments = !this.appeal.incompleteSetOfDocuments;
        }
    }

    /**
     * Обновление информации по делу (после выполнения запроса с переводом статуса)
     */
    public async updateAppeal() {
        this.appeal = await this.restService.find('appeals', this.appeal._id);
        await this._initServicesData();
    }

    // Смена роутинга
    public ngOnDestroy() {
        if (this.processTasksEntryStatusChangedSubscription) {
            this.processTasksEntryStatusChangedSubscription.unsubscribe();
        }

        if (this.processTasksPollTriggeredSubscription) {
            this.processTasksPollTriggeredSubscription.unsubscribe();
        }

        this.quickRequestsService.clearData();
        this.appealProcessService.stopProcessTasksPolling();
        this.filesQueueService.close();
    }

    /**
     * КНМ наличия услуг в деле в статусе "Черновик"
     * @returns {boolean}
     */
    public checkExistSubservicesInDraftStatus() {
        return this.appeal.subservice.some(item => item.status.code === 'draft');
    }

    public async onSelectSubservice() {
        this.actions = this.appealStatusService.initAppealActions();
        this.activeAction = this.appealStatusService.activeAction;

        await this._updateListPrintForms(true);
    }

    public afterCompleteCamundaTasks() {

    }

    /**
     * Переход в режим добавления нового участника для определенной группы услуги дела
     * @param data
     */
    public addNewSubjectToAppealSubserviceGroup(data) {
        this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup = true;
        this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData = data;
        this.appealSubservicesCreateComponent.editAppealSubserviceCardComponent.apply(true);
    }

    public checkAllowAction(action) {
        switch (action.code) {
            case 'annulled':
                return this.appeal.status.code === 'draft';
            case 'archive':
                const user = this.storage.getItem('user');

                return !user.externalPermissions || user.isSuperAdmin;
            default:
                return true;
        }
    }

    public get isAllowAppealActions() {
        const allowStatuses = ['draft', 'complete', 'receiveDocsReject', 'serviceReject'];

        return this.appeal._id && allowStatuses.indexOf(this.appeal.status.code) !== -1;
    }

    /**
     * Перевод дела из преархива в архив - обезличивание
     */
    public sendToArchive() {
        this.isProcessingCleanupAndMoveToArchive = true;
        this.httpService.post(Config.server + Config.api + 'mfc/cleanupAndMoveToArchive', {appealId: this.appeal._id}).then(() => {
                this.isProcessingCleanupAndMoveToArchive = false;
                this.restService.find('appealsArchive', this.appeal._id).then(archivedAppeal => {
                    this.processingAfterArchiveAppeal(archivedAppeal);
                });
            }, error => {
                this.isProcessingCleanupAndMoveToArchive = false;
                this.toaster.error(error);
            },
        );
    }

    /**
     * Возврат дела из преархива
     */
    public async returnFromArchive() {
        this.isProcessingRollbackFromPreArchive = true;
        try {
            await this.httpService.post(`${Config.server}${Config.api}mfc/rollbackFromPreArchive`, { appealId: this.appeal._id });
            this.appealSaveService.appeal = this.appeal = await this.restService.find('appeals', this.appeal._id);
            this.appealService.baseAppeal = this.appeal;
            this.storage.setItem('baseAppeal', this.appeal);

            this.appealStatusService.appeal = this.appeal;
            this.actions = this.appealStatusService.initAppealActions();
            this.activeAction = this.appealStatusService.activeAction;
            this.appealSubservicesService.correctActions(this.appeal.subservice);
        } catch (error) {
            error = (typeof error === 'object' && error.error ? error.error.description : error);
            this.toaster.error(error);
        } finally {
            this.isProcessingRollbackFromPreArchive = false;
        }
    }

}
