import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'entity-process-element-header',
    templateUrl: './entity-process-element-header.component.html',
})
export class EntityProcessElementHeaderComponent implements OnInit {
    @Input() public element;
    @Input() public data;
    @Input() public allowApply = false;

    @Output() public onComplete = new EventEmitter<any>();

    public elementData; // объект с параметрами задачи (события)

    public isExpired = false;

    public visibleParams = {
        USER_TASK: {
            theme: 'success',
            nameField: 'name',
            completedName: 'Завершенная пользовательская задача',
            activeName: 'Активная пользовательская задача',
        },
        SERVICE_TASK: {
            theme: 'primary',
            completedName: 'Завершенная сервисная задача',
            activeName: 'Активная сервисная задача',
            nameField: 'activityName',
        },
        'TIMER-TRANSITION': {
            theme: 'orange',
            activeName: 'Активный таймер',
            name: 'Таймер',
        },
        'TIMER-INTERMEDIATE-TRANSITION': {
            theme: 'orange',
            activeName: 'Активный таймер',
            name: 'Таймер',
        },
        EVENT: {
            theme: 'purple',
            nameField: 'eventName',
            completedName: 'Завершенное событие',
        },
    };

    public params;

    public ngOnInit() {
        this.isExpired =
            this.element.status &&
            this.element.status === 'ACTIVE' &&
            this.element.due &&
            moment(this.element.due) < moment();
        this.params = this.visibleParams[this.element.type];
    }

    public getElementProperty(property) {
        if (this.params) {
            if (property === 'background' || property === 'text') {
                const theme = this.isExpired ? this.params.errorTheme : this.params.theme;
                return property === 'background' ? 'bg-' + theme + '-50' : 'text-' + theme + '-600';
            } else {
                return this.params[property] ? this.params[property] : '';
            }
        }
        return '';
    }

    public getElementSubName() {
        if (this.params) {
            const property = this.element.status === 'ACTIVE' ? 'activeName' : 'completedName';
            return this.params[property];
        }
        return '';
    }

    public getElementName() {
        if (this.params) {
            return this.params.nameField ? this.element[this.params.nameField] : this.params.name;
        }
        return '';
    }

    public complete() {
        this.onComplete.emit(true);
    }

    public get isProcessing() {
        return this.data.processingElements.indexOf(this.element.guid) !== -1;
    }
}
