<ng-template let-task="task"
             let-isSub="isSub"
             #taskTemplate>
    <td [ngClass]="{
            'text-size-small pl-60': isSub,
            'text-semibold': task.children
        }">
        {{ task.id }}
    </td>
    <td [ngClass]="{
            'text-size-small pt-5': isSub,
            'text-semibold': task.children
        }">
        {{ task.name }}
    </td>
    <td class="text-center"
        *ngFor="let operation of operations">
        <ng-container *ngIf="!task.children">
            <input id="{{task.id}}{{operation}}"
                   class="checkbox"
                   type="checkbox"
                   name="{{operation}}"
                    [checked]="task[getOperation(operation, 'field')]"
                   (change)="changeTaskProperty(task.guid, getOperation(operation, 'field'))">
            <label for="{{task.id}}{{operation}}">&nbsp;</label>
        </ng-container>
    </td>
</ng-template>

<div class="alert alert-info no-border no-border-radius no-margin"
     *ngIf="!process">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations['bpmn-card'].process_didnt_added_to_reglament }}
</div>
<table class="table table-mini table-hover"
       *ngIf="process">
    <thead>
        <tr>
            <th class="text-size-small text-bold">
                {{ localizations.common.code }}
            </th>
            <th class="text-size-small text-bold">
                {{ localizations.common.name }}
            </th>
            <ng-container *ngFor="let operation of operations">
                <th class="text-size-small text-bold text-center">
                    {{ getOperation(operation, 'name') }}
                </th>
            </ng-container>
        </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let task of tasks">
        <tr>
            <ng-container *ngTemplateOutlet="taskTemplate; context:{task: task}">
            </ng-container>
        </tr>
        <ng-container *ngIf="task.children">
            <tr *ngFor="let childTask of task.children">
                <ng-container *ngTemplateOutlet="taskTemplate; context:{task: childTask, isSub: true}"></ng-container>
            </tr>
        </ng-container>
    </ng-container>
    </tbody>
</table>

