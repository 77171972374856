<ng-container>
    <div>
        {{ getDaysText('summary') }}
    </div>
    <div [ngClass]="{
        'text-danger': daysLeft < 0,
        'text-primary': daysLeft > 0,
        'text-success': daysLeft === 0
    }">
        {{ getDaysText('left') }}
    </div>
    <div class="panel-heading mb-20 pb-10">
        <div class="head-title mr-30">
            <div class="text-bold">{{ localizations.common.name }}</div>
            <div class="text-bold">{{ localizations.standards.periods.execution_period }}</div>
        </div>
        <ng-container *ngIf="isEditing">
            <button class="btn btn-success-overline mr-10" (click)="applyChanges()">
                <i class="icon-check"></i>
            </button>
            <button class="btn btn-danger-overline" (click)="cancelEditing()">
                <i class="icon-cross"></i>
            </button>
        </ng-container>
        <ng-container *ngIf="!isEditing">
            <button class="btn btn-primary-overline" (click)="startEditing()">
                <i class="icon-pencil"></i>
            </button>
        </ng-container>
    </div>


    <ng-container *ngIf="initData.periods.ungroup.length > 0">
        <common-standard-periods-group
            [isEditing]="isEditing"
            [tasks]="initData.periods.ungroup"
            [isUngroup]="true"
            [currentGroups]="currentGroups"
            (changedTaskGroup)="changeGroup($event)"
            (changedPeriod)="changePeriod($event)"
        ></common-standard-periods-group>
    </ng-container>

    <ng-container *ngIf="currentGroups.length > 0">
        <ng-container *ngFor="let group of initData.periods.groups; let i = index;">
            <common-standard-periods-group
                [groupIdx]="i"
                [isEditing]="isEditing"
                [tasks]="group.tasks"
                [period]="group.period"
                [currentGroups]="currentGroups"
                (changedTaskGroup)="changeGroup($event)"
                (changedPeriod)="changePeriod($event)"
            ></common-standard-periods-group>
        </ng-container>
    </ng-container>
</ng-container>
