import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectService, FilesService, RestService, ToasterService, TranslateService } from '@evolenta/core';
import { PrintFormService } from '../print-form.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
    selector: 'print-form-edit',
    templateUrl: './print-form-edit.component.html',
})
export class PrintFormEditComponent implements OnInit {
    @Input() public mode = 'default';
    @Input() public pf;
    @Input() public allowEdit = true;
    @Output() public onSaveFromStandard = new EventEmitter<any>();
    @Output() public onCancelFromStandard = new EventEmitter<any>();

    public printForm: any = {
        active: true,
        showInMainList: false,
        templateFile: null,
        formType: 'appeal',
        docName: '',
        xsd: '',
        xslFields: [],
        customXSLFile: null,
        isNeedReloadTemplateFile: false,
        isNeedReloadCustomXslFile: false,
        isPdfWithImages: false,
    };

    public formats = [];

    public selectedFormat = 'docx';

    public tabs = [];

    public activeTab = 'common'; // Код активной вкладки
    public isLoading = false;
    public localizations;

    public constructor(
        private route: ActivatedRoute,
        private restService: RestService,
        private toaster: ToasterService,
        private selectService: SelectService,
        private router: Router,
        private filesService: FilesService,
        private printFormService: PrintFormService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.activeTab = 'common'; // Код активной по умолчанию вкладки

        if (this.mode === 'default') {
            this.route.data.subscribe(
                response => {
                    const printForm = response['resolves'].data;

                    if (!printForm.hasOwnProperty('active')) {
                        printForm.active = true;
                    }

                    this.formats = this.selectService.transformForSelect('code', 'name', response['resolves'].kinds);

                    if (printForm['_id']) {
                        this.setInitialData(printForm);
                    }
                },
                error => {
                    console.log(error);
                },
            );
        } else {
            const promisses = [];

            promisses.push(this.restService.search('cataloguePrintFormKinds'));

            Promise.all(promisses)
                .then(response => {
                    this.formats = this.selectService.transformForSelect('code', 'name', response[0]);
                    this.setInitialData(this.pf);
                });
        }
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'subjects',
                'print-forms',
                'appeals.requirements',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.tabs = [
            {
                code: 'common',
                name: this.localizations.common.general_data,
                active: true,
                visible: true,
            },
        ];
    }

    public getPrintFormActionsMessage() {
        const template = this.localizations['print-forms'].actions;
        const action = this.printForm._id ?
            this.localizations.common.editing :
            this.localizations.common.creation;

        return template.replace('%s', action);
    }

    public setInitialData(pf) {
        this.printForm = { ...this.printForm, ...pf };
        this.selectedFormat = PrintFormService.GetFormFormat(pf);
        this.showXslTab();
    }

    public showXslTab() {
        if (!this.tabs.some(t => t.code === 'xsl')) {
            this.tabs.push({
                code: 'xsl',
                name: this.localizations['print-forms'].element.additional_plural,
                active: false,
                visible: true,
            });
        }
    }

    public save() {
        if (this.printFormService.isValidPrintForm(this.printForm)) {
            this.isLoading = true;
            this.printFormService.preparePrintFormForSave(this.printForm, this.selectedFormat)
                .then(result => {
                    this.printForm = result;
                    this.updatePrintForm();
                });
        }
    }

    public saveFromStandard() {
        this.save();
    }

    public cancel() {
        this.onCancelFromStandard.emit();
    }

    public updatePrintForm() {
        this.restService.update('printForms', this.printForm)
            .then(result => {
                this.toaster.success(this.localizations['print-forms'].notifications.updated);
                this.printForm.isNeedReloadCustomXslFile = false;
                this.printForm.isNeedReloadTemplateFile = false;
                this.selectedFormat = PrintFormService.GetFormFormat(this.printForm);
                this.onSaveFromStandard.emit(result);
                this.showXslTab();
            })
            .catch(error => {
                this.toaster.error(error);

            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public activateSidebarTab(tab) {
        this.activeTab = tab.code;
        this.tabs.forEach(item => {
            item.active = item.code === tab.code;
        });
    }

    public activateTab(tab) {
        this.activeTab = tab;
    }

    public back() {
        this.router.navigate(['/admin/printForms']);
    }

    public selectFormat(data) { this.selectedFormat = data.id; }

    public fileChange(event) {
        const filesList = event.target.files;
        if (filesList.length > 0) {
            let fileExt;
            const file = filesList[0];
            const nameArr = file.name.split('.');
            if (nameArr.length > 1) {
                fileExt = nameArr[nameArr.length - 1].toLowerCase();
                if (fileExt !== this.selectedFormat) {
                    const errorMessage = `${this.localizations['print-forms'].notifications.wrong_file_format}
                    "${fileExt}"
                    ${this.localizations['print-forms'].notifications.allowed_file_formats_are}
                    ${this.selectedFormat}`;
                    this.toaster.error(errorMessage);

                    return;
                }
            }
            // Если указан допустимый формат файла
            filesList[0].dateCreation = moment().format();
            this.printForm.templateFile = filesList[0];
        }
    }

    public removeFile() {
        this.printForm.templateFile = null;
        this.printForm.isNeedReloadTemplateFile = true;
    }

    public downloadFile(id, originalName) {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    public openFile(file) {
        FileSaver.saveAs(file, file.name);
    }

    public getSelectedValue(items, value) {
        return _.filter(items, item => item.id === value);
    }

    public saveXsd(xsd) {
        this.printForm.xsd = xsd;
    }

    public updateXslList(list) {
        this.printForm.xslFields = list;
        this.printForm.isNeedReloadCustomXslFile = true;
    }

    public get isPrintFormInactive(): boolean {
        return !this.printForm.active;
    }

    public set isPrintFormInactive(value: boolean) {
        this.printForm.active = !value;
    }

    public get isPdfWithImages(): boolean {
        return !!this.printForm.isPdfWithImages;
    }

    public set isPdfWithImages(value: boolean) {
        this.printForm.isPdfWithImages = !!value;
    }
}
