import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchForNameFilter',
    pure: false,
})
@Injectable()
export class SearchForNamePipe implements PipeTransform {
    public transform(array: any, filter: string, field?: string): any {
        filter = filter.toLowerCase();
        if (field) {
            return array.filter((item) => item[field] && ~item[field].toLowerCase().indexOf(filter));
        } else {
            return array.filter((item) => item.name && ~item.name.toLowerCase().indexOf(filter));
        }
    }
}
