<div class="pt-20 pb-20">
    <div class="alert alert-danger no-border ml-20 mr-20"
         *ngIf="checkErrorExist()">
        <span class="text-semibold">
            Внимание!
        </span>
        Не выбран вариант услуги
    </div>
    <tree-select [treeArray]="selectedVariants"
                 [elements]="serviceVariants"
                 [additionalFields]="['code', 'xsd', 'mnemonic', 'rguProcedureId', 'rguProcedureName', 'rguTargetId', 'rguTargetName']"
                 (onEnd)="onEndSelectVariant()">
    </tree-select>
</div>
