import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityProceedTermination } from './models/appeal-entity-proceed-termination';

@Component({
    selector: 'appeal-entity-proceed-termination',
    templateUrl: 'appeal-entity-proceed-termination.component.html',
})
export class AppealEntityProceedTerminationComponent implements OnInit {
    @Input() public entity: AppealEntityProceedTermination;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public constructor() {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {}

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
