<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="entity.sumAdmFineStatus?.code">
                <span class="badge text-uppercase"
                      [ngClass]="getProperty('background')">{{entity.sumAdmFineStatus.name}}
                </span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <ng-container *ngIf="task && mode == 'view'">
                <span class="text-semibold text-uppercase">{{entity.name}}</span>
            </ng-container>

            <p class="mb-3">
                <span class="text-muted ml-10">Тип принятой меры: </span>
                <ng-container *ngIf="entity.typeActionTaken">{{entity.typeActionTaken.name}}</ng-container>
                <em *ngIf="!entity.typeActionTaken">не указано</em>

                <span class="text-muted ml-10">Дата начала действия меры: </span>
                <ng-container *ngIf="entity.dateStart">{{entity.dateStart.formatted}}</ng-container>
                <em *ngIf="!entity.dateStart">не указано</em>

                <span class="text-muted ml-10">Дата окончания действия меры: </span>
                <ng-container *ngIf="entity.dateEnd">{{entity.dateEnd.formatted}}</ng-container>
                <em *ngIf="!entity.dateEnd">не указано</em>
            </p>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">

            <div class="panel panel-shadow m-20 p-20">
                <div class="panel-body no-padding pt-10">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Контрольно-надзорное мероприятие</label>
                                <catalogue catalogName="kNMEvent" name="KNM" [(ngModel)]="entity.knm" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group form-group-lg">
                                <label>Нарушение</label>
                                <catalogue catalogName="nsiViolations" name="Violations" [(ngModel)]="entity.violations" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Статус принятой меры</label>
                                <catalogue catalogName="statusActionTaken" name="SumAdmFineStatus" [(ngModel)]="entity.sumAdmFineStatus" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group form-group-lg">
                                <label>Проверочные листы</label>
                                <input type="text" [(ngModel)]="entity.controlLists" class="form-control input-xs">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Нанесенный ущерб</label>
                                <input type="text" [(ngModel)]="entity.damageType" class="form-control input-xs">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group form-group-lg">
                                <label>Наименование меры</label>
                                <input type="text" [(ngModel)]="entity.punishmentType" class="form-control input-xs">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group form-group-lg">
                                <label>Величина штрафа</label>
                                <input type="text" [(ngModel)]="entity.sumAdmFine" class="form-control input-xs">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group form-group-lg">
                                <label class="form-label display-block">Отметка об уплате</label>
                                <div class="checkbox-inline">
                                    <input type="checkbox" [(ngModel)]="entity.isPaid" id="IsPaid" class="checkbox">
                                    <label for="IsPaid">Оплачено</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Тип принятой меры</label>
                                <catalogue catalogName="typeActionTaken" [(ngModel)]="entity.typeActionTaken" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group form-group-lg">
                                <label>Величина меры</label>
                                <input type="text" [(ngModel)]="entity.valueActionTaken" class="form-control input-xs">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата начала действия меры</label>
                                <evolenta-datepicker [(ngModel)]="entity.dateStart" [large]="true" [valueAsString]="true"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата окончания действия меры</label>
                                <evolenta-datepicker [(ngModel)]="entity.dateEnd" [large]="true" [valueAsString]="true"></evolenta-datepicker>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>УИН</label>
                                <input type="text" [(ngModel)]="entity.win" class="form-control input-xs">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Данные получателя платежа</label>
                                <textarea [(ngModel)]="entity.payeeDetails" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- Прилагаемые документы -->
                    <div class="mb-15">
                        <appeal-entity-field-input
                            [multiple]="true"
                            [large]="true"
                            [entity]="entity"
                            [property]="'materials'"
                            [title]="'Прилагаемые документы'"
                            [placeholder]="'Введите наименование прилагаемого документа'">
                        </appeal-entity-field-input>
                    </div>

                </div>
            </div>

        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
