<ng-container *ngFor="let tab of tabs">
    <div class="sidebar-category"
         (click)="activateTab(tab.code)"
         *ngIf="tab.useFunction()"
         [ngClass]="{
            'active': activeTab == tab.code,
            'cursor-pointer' : activeTab != tab.code
            }">
        <div class="category-title">
            <span>
                {{tab.name}}
                <i class="icon-bookmark2 ml-5 text-primary"
                   title="{{tab.additionalParams.bookmark.name}}"
                   *ngIf="tab.additionalParams && tab.additionalParams.bookmark && tab.additionalParams.bookmark.property">
                </i>
                <span class="badge badge-danger badge-small ml-5 text-semibold cursor-pointer"
                      *ngIf="tab.errors && tab.errors.length > 0"
                      (click)="showErrors(tab)">
                    {{tab.errors.length}}
                </span>
            </span>
        </div>
    </div>
</ng-container>

<!-- Ошибки при валидации документа -->
<ng-template #errorsListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.common.errors }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="no-margin">
            <li *ngFor="let err of tabWithErrors.errors"
                [innerHTML]="err">
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>
