<div class="m-20"
     *ngIf="!editedAttachment">
    <div class="panel panel-shadow panel-small">
        <div class="panel-heading">
            <h4 class="panel-title pt-10">
                <span class="mr-10">
                    {{metaData.name}}
                </span>
                <common-standard-block-status [block]="activeData"
                                              [small]="true"
                                              *ngIf="commonStandardsService.reglament && !!metaData.attachmentsData.allowEditInReglament"
                                              (onToggleEntity)="commonStandardsService.toggleReglamentBlockEntity(activeData)"
                                              (onDeleteReglamentData)="commonStandardsService.deleteReglamentBlock(activeData)">
                </common-standard-block-status>
            </h4>
            <div class="heading-elements">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.appeals.add_variant"
                        (click)="add()"
                        *ngIf="activeData.allowEdit">
                    <i inlineSVG="assets/icon-new-item.svg"></i>
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <table class="table"
                   *ngIf="activeData.entity.attachments">
                <thead>
                <tr>
                    <th class="text-bold text-size-small">
                        {{ localizations.common.name }}
                    </th>
                    <th class="text-bold text-size-small">
                        {{ localizations.common.file }}
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let attachment of activeData.entity.attachments">
                        <td>
                            {{attachment.name}}
                        </td>
                        <td>
                            <span class="anchor text-info"
                                  *ngIf="attachment.file"
                                  (click)="downloadFile(attachment.file)">
                                {{attachment.file.originalName}}
                            </span>
                            <em class="text-size-small"
                                *ngIf="!attachment.file">
                                {{ localizations.appeals.file_not_added }}
                            </em>
                        </td>
                        <td class="text-right">
                            <button class="btn btn-sxs"
                                    type="button"
                                    (click)="delete(attachment)">
                                <i class="icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-warning no-margin"
                 *ngIf="!activeData.entity.attachments || activeData.entity.attachments.length === 0">
                {{ localizations.appeals.apps_not_added }}
            </div>
        </div>
    </div>
</div>

<div class="panel panel-shadow m-20"
     *ngIf="editedAttachment">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ localizations.appeals.editing }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    *ngIf="activeData.allowEdit"
                    (click)="apply()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{
                    activeData.allowEdit ?
                        localizations.common.do_cancel :
                        localizations.common.close
                }}
            </button>
        </div>
    </div>

    <div class="panel-body">
        <div class="form-group form-group-lg">
            <label>
                {{ localizations.common.name }}
                <span class="text-danger">*</span>
            </label>
            <input class="form-control"
                   type="text"
                   [(ngModel)]="editedAttachment.name">
            <div class="validation-error-label"
                 *ngIf="isValidate && !editedAttachment.name">
                <span>
                    {{ localizations.common.name_field_is_required }}
                </span>
            </div>
        </div>
        <div class="form-group form-group-lg">
            <label>
                {{ localizations.common.name }}
            </label>
            <textarea class="form-control"
                      [(ngModel)]="editedAttachment.comment">
            </textarea>
        </div>
        <div>
            <span class="text-bold">
                {{ localizations.common.file }}:
            </span>
            <em class="text-muted"
                *ngIf="!editedAttachment.file && !editedAttachment.newFile">
                {{ localizations.common.not_added_f }}
            </em>
            <a class="anchor"
               *ngIf="editedAttachment.file && !editedAttachment.newFile"
               (click)="downloadFile(editedAttachment.file)">
                {{ editedAttachment.file.originalName }}
            </a>
            <span class="anchor text-info"
                  *ngIf="editedAttachment.newFile"
                  (click)="openFile()">
                {{ editedAttachment.newFile.name }}
            </span>
        </div>
        <div class="validation-error-label"
             *ngIf="isValidate && !editedAttachment.file && !editedAttachment.newFile">
            <span>
                {{ localizations.appeals.file_is_required_for_addition}}
            </span>
        </div>

        <input id="file"
               class="inputfile hide"
               type="file"
               name="file"
               (change)="fileChange($event)"/>
        <label class="btn btn-primary-overline btn-labeled btn-labeled-right btn-lg mt-15"
               for="file">
            {{ localizations.appeals.attach_file }}
            <b>
                <i inlineSVG="assets/icon-attach.svg"></i>
            </b>
        </label>
    </div>
</div>

