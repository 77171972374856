import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ToasterService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealSubservicesService } from '../../appeal-subservices.service';
import { AppealService } from '../../appeal.service';
import { AppealSaveService } from '../../appeal-save.service';

@Component({
    selector: 'appeal-entities',
    templateUrl: 'appeal-entities.component.html',
})
export class AppealEntitiesComponent implements OnInit, OnDestroy {
    @Input() public appeal: any; // Формируемое дело
    @Input() public subservice; // услуга по которой формируется дело
    @Input() public task; // Задача процесса
    @Input() public allowEdit = true;

    public activeEntity; // сведение, находящееся в данный момент на редактировании
    public activeAppealSubservice;

    @Output() public onStartEdit = new EventEmitter<boolean>(); // Переход в режим редактирования элемента
    @Output() public afterEndEdit = new EventEmitter<boolean>(); // Выход из режима редактирования элемента

    public activeEntities = [];
    public notAddedEntities = [];

    public uniqueEntities = ['order', 'checkStatement'];
    public entityGroups = [];
    public leave;
    public stay;
    public allowedToLeave = new Promise((resolve, reject) => {
        this.leave = resolve;
        this.stay = reject;
    });

    public localizations;

    public constructor(
        public appealSubservicesService: AppealSubservicesService,
        public appealService: AppealService,
        private appealSaveService: AppealSaveService,
        private toaster: ToasterService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this._correctActiveEntities();
        this.getEntityGroups();
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.entities',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public async ngOnDestroy() {
        await this.allowedToLeave;
    }

    public getEntityGroups() {
        let allEntities = this.subservice.entities;
        if (this.task) {
            allEntities = allEntities.filter(item => this.task.entities.find(elm => elm.sperId === item.sperId));
        }
        this.entityGroups = allEntities.map(item => ({code: item.code, name: item.name, isUnique: this.uniqueEntities.indexOf(item.code) !== -1}));
    }

    public countEntitiesForGroup(group) {
        return this.appeal.subservice.entities.filter(item => item.type === group.code).length;
    }

    private _correctActiveEntities() {
        this.activeEntities = this.appeal.subservice.entities.filter(item => !item.hasOwnProperty('display') || item.display);
        if (this.task) {
            this.activeEntities = this.activeEntities.filter(entity => this.task.params.entities.find(item => item.code === entity.type));
        }
        this._prepareNotAddedEntities();
    }

    private _prepareNotAddedEntities() {
        this.notAddedEntities = [];
        let allEntities = this.subservice.entities;
        if (this.task) {
            allEntities = allEntities.filter(entity => this.task.params.entities.find(item => item.sperId === entity.sperId));
        }
        allEntities.forEach(entity => {
            const find = this.appeal.subservice.entities.find(item => item.type === entity.code);
            if (!find) {
                const requiredEntity = this.task ? this.task.entities.find(item => item.sperId === entity.sperId).isRequired : false;
                this.notAddedEntities.push({
                    code: entity.code,
                    name: entity.name,
                    isRequired: requiredEntity,
                });
            }
        });
    }

    public addEntity(appealSubservice, entity = null) {
        this.onStartEdit.emit(true);
        this.activeAppealSubservice = appealSubservice;
        this.activeEntity = {
            guid: CommonUtilities.GenerateGuid(),
            subserviceGuid: appealSubservice.guid,
            type: entity ? entity.code : null,
            name: entity ? entity.name : '',
        };
    }

    public async applyEntity(entity) {
        if (this.appeal.subservice.guid !== entity.subserviceGuid) {
            return;
        }

        const entityExists = this.appeal.subservice.entities.some(item => item.guid === entity.guid);
        if (!entityExists) {
            this.appeal.subservice.entities.push(entity);
        }
        this.activeEntity = null;
        this.activeAppealSubservice = null;
        if (this.appeal._id) {
            await this.appealSaveService.saveAppeal();
            this.toaster.success('Сведение успешно сохранено');
            this._correctActiveEntities();
        }
        this._prepareNotAddedEntities();
        this.afterEndEdit.emit(true);
    }

    public editEntity(entity, appealSubservice) {
        this.activeAppealSubservice = appealSubservice;
        this.activeEntity = entity;
        this.onStartEdit.emit(true);
    }

    public checkAllowAddEntity(appealSubservice, task) {
        if (this.appealService.subservice._id !== appealSubservice.id) {
            return false;
        }
        let subserviceEntities = this.appealService.subservice.entities;
        if (task) {
            const usedInTaskCodes = task.params.entities.map(item => item.code);
            subserviceEntities = subserviceEntities.filter(item => usedInTaskCodes.indexOf(item.code) !== -1);
        }
        this.uniqueEntities.forEach(uniqueEntity => {
            const find = appealSubservice.entities.find(item => item.type === uniqueEntity);
            if (find) {
                subserviceEntities = subserviceEntities.filter(item => item.code !== uniqueEntity);
            }
        });

        return subserviceEntities.length > 0;
    }

    public checkTaskEntity(entity) {
        return this.task.params.entities.find(item => item.code === entity.type);
    }

    public checkVisibleEntity(entity) {
        return !entity.hasOwnProperty('display') || entity.display;
    }

    public deleteEntity(entity, appealSubservice) {
        const findIndex = appealSubservice.entities.findIndex(item => item.guid === entity.guid);
        appealSubservice.entities.splice(findIndex, 1);
    }

    public cancelEntity() {
        this.activeEntity = null;
    }
}
