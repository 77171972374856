import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../../appeal-subservices.service';
import { DocumentService } from '../../../documents/document.service';
import { RestService } from '@evolenta/core';

@Component({
    selector: 'appeal-subservice-subjects',
    templateUrl: './appeal-subservice-subjects.component.html',
    styles: ['.panel-heading .btn-sxs{margin-top: 4px;} .right-10{right: 10px;}'],
})
export class AppealSubserviceSubjectsComponent implements OnInit {
    @Input() public appealSubservice; // Редактируемая услуга дела
    @Input() public subservice; // Описательная услуга для услуги дела
    @Input() public subjects; // Список субъектов дела

    public data = []; // Массив информации по услугам дела для визуального отображения
    public groups = []; // Массив групп объектов

    public representativeTypes = this.appealSubservicesService.representativeTypes;

    public subjectsData = this.appealSubservicesService.subjectsData;

    @Output() public onAddNewSubjectToAppealSubserviceGroup = new EventEmitter<object>();

    public constructor(
        private appealSubservicesService: AppealSubservicesService,
        public validateService: AppealValidateService,
        private documentService: DocumentService
        // private restService: RestService,
    ) {}

    /**
     * Инициализация компонента
     */
    public /*async*/ ngOnInit() {
        // Группы участников
        this.groups = this.appealSubservicesService.data[this.appealSubservice.guid].groups;

        this.data = this.appealSubservicesService.data[this.appealSubservice.guid].subjects;
        /*if (!Object.keys(this.data).length && this.appealSubservice.appeal) {
            const appeal = await this.restService.search('appeals', this.appealSubservice.appeal._id);
            appeal.subjects.forEach(subject => {
                this.data[subject.guid] = subject;
            });
        }*/

        // Генерация доступных для выбора объектов
        this.generateAvailableSubjectsForGroups();

        // Сброс переменных для инициализации режима создания участника
        if (
            this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup &&
            this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete
        ) {
            this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup = false;
            this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData = null;
        }

        /*this.groups.forEach(group => {
            if (group.availableSubjects && group.availableSubjects.length === 1) {
                this.selectSubject(group.availableSubjects[0], group);
            }
        });*/
    }

    /**
     * Возвращает число объектов для группы участников
     * @param group - обрабатываемая группа
     */
    public getCountSubjectsInGroup(group) {
        let countSubjects = 0;
        Object.keys(this.data).forEach((subjectGuid) => {
            if (this.data[subjectGuid].group && this.data[subjectGuid].group.guid === group.guid) {
                countSubjects++;
            }
        });

        return countSubjects;
    }

    /**
     * Проверка доступных для выбора объектов для групп участников
     */
    public generateAvailableSubjectsForGroups() {
        this.groups.forEach((group) => {
            group.availableSubjects = [];
            this.subjects.forEach((subject) => {
                // Если есть объект без выбранной группы
                if (!this.data[subject.guid].group) {
                    group.availableSubjects.push(subject);
                }
            });
        });
    }

    /**
     * Добавление нового объекта в группу участников
     * @param group - обрабатываемая группа
     */
    public addSubject(group) {
        if (group.availableSubjects && group.availableSubjects.length > 0) {
            console.log('####');
            // Если есть участники, не привязанные к группам услуги, то добавляем возможность выбора участника или добавление нового
            group.isSelectOrAddSubject = true;
        } else {
            // Добавление нового
            this.addNewSubjectToGroup(group);
        }
    }

    public selectSubjectFromAvailable(group) {
        group.isSelectOrAddSubject = false;
        if (group.availableSubjects.length === 1) {
            this.data[group.availableSubjects[0].guid].active = true;
            this.selectSubjectGroup(group.availableSubjects[0], group);
            this.generateAvailableSubjectsForGroups();
            // Обновление информации об ошибках
            this.updateErrors();
        } else {
            group.editObject = true;
        }
    }

    /**
     * Добавление участия объекта для определенной группы участников
     * @param subject - выбранный объект
     * @param group - обрабатываемая группа
     */
    public selectSubject(subject, group) {
        group.editSubject = false;
        this.data[subject.guid].active = true;
        this.selectSubjectGroup(subject, group);
        this.generateAvailableSubjectsForGroups();
        // Обновление информации об ошибках
        this.updateErrors();
    }

    /**
     * Определяет число объектов в деле, исключая обрабатываемый
     * @param subject - обрабатываемый объект
     */
    public getOtherSubjectsCount(subject) {
        const otherSubjects = this.subjects.filter((item) => item.guid !== subject.guid);

        return otherSubjects.length;
    }

    /**
     * Удаление участия объекта в услуге
     * @param subject
     */
    public deleteSubjectData(subject) {
        this.data[subject.guid] = { active: false };
        this.generateAvailableSubjectsForGroups();
        // Обновление информации об ошибках
        this.updateErrors();
    }

    /**
     * Выбор группы участников, переход к режиму выбора вида участия
     * @param subject
     * @param group
     */
    public selectSubjectGroup(subject, group) {
        this.appealSubservicesService.selectEntityGroup(this.appealSubservice, subject, group);
        // Обновление информации об ошибках
        this.updateErrors();
    }

    /**
     * Выбор участия
     * @param subject
     * @param subKind
     */
    public selectSubjectSubKind(subject, subKind) {
        //        this.appealSubservicesService.selectObjectSubKind(this.appealSubservice, object, subKind);
        // Обновление информации об ошибках
        this.updateErrors();
    }

    /**
     * Выбор категории объекта
     * @param categories
     * @param subject - обрабатываемый объект
     */
    public selectSubjectCategories(categories, subject) {
        this.appealSubservicesService.selectEntityCategories(this.appealSubservice, subject);
        // Корректировка набора документов
        this.documentService.correctSubserviceDocGroups();
        // Обновление информации об ошибках
        this.updateErrors();
    }

    /**
     * Инициализация режима выбора вида участия объекта
     * @param object
     */
    public changeSubjectSubKind(object) {
        // this.appealSubservicesService.changeObjectSubKind(this.data[object.guid]);
    }

    /**
     * Инициализация режима выбора категории объекта
     * @param subject - обрабатываемый объект
     */
    public changeSubjectCategory(subject) {
        this.appealSubservicesService.changeEntityCategory(this.data[subject.guid]);
    }

    /**
     * Инициализация режима выбора представителя заявителя
     * @param object
     */
    public changeRepresentative(object) {
        // this.appealSubservicesService.changeRepresentative(this.data[object.guid]);
    }

    /**
     * Инициализация режима выбора представителя
     * @param object
     * @param representative - объект-представитель
     */
    public selectRepresentative(object, representative) {
        // this.appealSubservicesService.selectRepresentative(representative, this.data, object);
    }

    /**
     * Инициализация режима выбора типа представителя: опекун, попечитель и т.д.
     * @param object
     */
    public changeRepresentativeType(object) {
        // this.appealSubservicesService.changeRepresentativeType(this.data[object.guid]);
    }

    /**
     * Выбор типа представительства
     * @param object
     * @param type - объект-представитель
     */
    public selectRepresentativeType(object, type) {
        // this.appealSubservicesService.selectRepresentativeType(type, this.data, object);
    }

    /**
     * Обновление статуса ошибок
     */
    public updateErrors() {
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    public addNewSubjectToGroup(group) {
        group.isSelectOrAddSubject = false;
        this.onAddNewSubjectToAppealSubserviceGroup.emit({ appealSubservice: this.appealSubservice, group: group });
    }

    public isRepresentive(subject) {
        const representativeSubject = this.data[subject.guid];

        return (
            representativeSubject.subKind.type.indexOf('principal') !== -1 ||
            (representativeSubject.subKind.type === 'applicantPerson' &&
                representativeSubject.representative &&
                representativeSubject.representative.type)
        );
    }
}
