import { Injectable } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { AppealData } from '../../modules/knm/appeals/appeal.data';

@Injectable()
export class FiltersService {
    public appealsActualityFilter = AppealData.appealsActualityFilter;
    public localizations;

    private _isMark(key: any): boolean {
        return typeof key === 'string' && key.indexOf('@') === 0;
    }

    public constructor(
        private storage: StorageService,
    ) {
    }

    public getFilters(name: string, data?: {} | null, filtersStructure?: [] | null) {
        let filters;
        if (filtersStructure) {
            filters = [{
                groupId: 'main-group',
                show: true,
                data: filtersStructure,
            }];
        } else {
            filters = this.storage.getItem(`filters.${name}`);
        }

        if (!filters) {
            return;
        }

        // replace marks on values and return filters
        filters.forEach(tab => {
            tab.data.forEach(group => {
                group.filters.forEach(filter => {
                    Object.keys(filter).forEach(filterKey => {
                            const value = filter[filterKey];
                            if (this._isMark(value)) {
                                const mark = value.replace('@', '');

                                if (data[mark]) {
                                    filter[filterKey] = data[mark];
                                } else {
                                    console.error(`FILTER SERVICE: "${mark}" was expected in DATA but was not provided.`);
                                }
                            }
                        },
                    );
                });
            });
        });

        return filters ? filters : [];
    }

}
