import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@evolenta/layout';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'roles-mnemonics',
    templateUrl: './roles-mnemonics.component.html',
    styleUrls: [
        '../../elements-list.css',
    ],
})
export class RolesMnemonicsComponent implements OnInit, AfterViewInit {
    public model = 'catalogueRolesMnemonics';

    public colDef;

    public showGrid = false;

    public moduleBaseUrl;
    public baseFilter;
    public localizations;

    @ViewChild('gridElement', { static: false }) public grid: GridComponent;

    public constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();

    }

    public _initData() {
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
            this.colDef = [
                {
                    field: 'code',
                    headerName: this.localizations.roles.code,
                },
                {
                    field: 'name',
                    headerName: this.localizations.common.name,
                },
                {
                    extType: 'buttons',
                    buttons: {
                        updateBtn: {
                            target: this.moduleBaseUrl + '/roles/edit',
                            permissionEdit: true,
                            permissionView: true,
                        },
                        deleteBtn: {
                            permission: true,
                        },
                    },
                },
            ];
        });
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'roles',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    public back() {
        this.location.back();
    }

    public getNewRole() {
        this.router.navigate([this.moduleBaseUrl, 'roles', 'create']);
    }

    public ngAfterViewInit() {
        setTimeout(() => { this.showGrid = true; }, 100);
    }

}
