<fieldset *ngFor="let item of entity[property]">
    <div class="pr-20 pl-20" *ngIf="item.checkLists.length > 0">
        <div class="panel panel-shadow" *ngFor="let checkList of item.checkLists">
            <div class="panel-heading p-20 bg-primary-10 border-grey-120">
                <h6 class="panel-title">
                    <!-- Заголовок объекта -->
                    <p class="text-bold text-size-large" *ngIf="checkList.subjectGuid">
                        {{getSubject(checkList.subjectGuid)?.shortHeader}}
                    </p>
                    <ng-container *ngIf="checkList.objectGuid">
                        {{getObject(checkList.objectGuid)?.name}} -
                        <span class="text-muted">
                            {{getAddress(checkList.objectGuid)}}
                        </span>
                    </ng-container>
                </h6>
            </div>
            <ng-container *ngIf="checkList.questions.length > 0">
                <table class="table">
                    <tbody>
                    <tr class="text-size-small">
                        <td width="20"></td>
                        <td><span class="text-bold">Обязательное требование</span></td>
                        <td width="450"><span class="text-bold">Мероприятия по устранению нарушения</span></td>
                        <td width="250"><span class="text-bold">Срок устранения нарушения</span></td>
                    </tr>

                    <ng-container *ngFor="let question of checkList.questions">
                        <tr>
                            <td><i class="text-slate icon-clipboard"></i></td>
                            <td>{{question.mandatoryReqs ? question.mandatoryReqs.name : 'не указано'}}</td>
                            <td>{{question.eliminationMeasure ? question.eliminationMeasure : 'не указано'}}</td>
                            <td>{{question.eliminationDate ? question.eliminationDate.formatted : 'не указано'}}</td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>
</fieldset>
