<div class="row">
    <div class="col-lg-4 col-sm-4 col-xs-12">
        <div class="form-group form-group-lg">
            <label class="control-label">
                {{ localizations['print-forms'].format }}
            </label>
            <ng-select [allowClear]="false"
                       [items]="xsdTypes"
                       [active]="getSelectedValue()"
                       (selected)="selectXsdType($event)">
            </ng-select>
        </div>
    </div>
    <div class="col-md-12"
         *ngIf="selectedType === 'text'">
        <div class="form-group form-group-lg">
            <label class="control-label">
                XSD
            </label>
            <textarea id="xsd"
                      name="xsd"
                      class="form-control"
                      [(ngModel)]="xsdSchema"
                      (change)="changeXsd()">
            </textarea>
        </div>
    </div>
    <div class="col-md-12"
         *ngIf="selectedType === 'form'">
        FORM
    </div>
</div>
