import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersBarComponent, RestService, StorageService, TranslateService, UsersService } from '@evolenta/core';
import { TasksService } from './tasks.service';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { RsoService } from '../../../common/services/rso.service';

@Component({
    selector: 'tasks',
    templateUrl: 'tasks.component.html',
    styleUrls: ['../../elements-list.css'],
})
export class TasksComponent implements OnInit {
    @ViewChild('filtersPanel', { static: false }) private filtersPanel: FiltersBarComponent; // панель фильтров
    public tasks: any = []; // Список задач
    // Структура панели фильтрации
    public filtersPanelItems: any = [];

    public searchFocus = false;
    public globalSearch;

    // Настройки пагинации
    public currentPage = 0;
    public totalPages: number;
    public totalCount = 0;

    // Параметры для отображения предпросмотра
    public task = null; // выбранная для предпросмотра задача
    public appeal = null;
    public timer = null;
    public delay = 300;
    public prevent = false;
    public previewMode = false;

    // Массив базовых настроек поиска
    public baseSearch = [];

    public statuses = [
        {
            priority: 25,
            code: 'low',
            name: 'Низкий',
            shortName: 'Низкий',
            theme: 'purple',
            labelText: 'ЧР',
            label: 'bg-purple-50 text-purple',
            background: 'bg-purple-300',
            border: 'border-purple-400',
            backgroundLite: 'bg-purple-50',
            borderTop: 'border-top-purple-400',
            borderLeft: 'border-left-purple-400',
            text: 'text-purple-400',
        },
        {
            priority: 50,
            code: 'normal',
            name: 'Обычный',
            shortName: 'Обычный',
            theme: 'primary',
            labelText: 'ЧР',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-400',
            text: 'text-primary-400',
        },
        {
            priority: 75,
            code: 'important',
            name: 'Важный',
            shortName: 'Важный',
            theme: 'warning',
            labelText: 'ЧР',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-400',
            text: 'text-primary-400',
        },
        {
            priority: 100,
            code: 'critical',
            name: 'Критичный',
            shortName: 'Критичный',
            theme: 'danger',
            labelText: 'ЧР',
            label: 'bg-danger-50 text-danger',
            background: 'bg-danger-300',
            border: 'border-danger-400',
            backgroundLite: 'bg-danger-50',
            borderTop: 'border-top-danger-400',
            borderLeft: 'border-left-danger-400',
            text: 'text-danger-400',
        },
    ];
    public basePath;
    public moduleBaseUrl;
    public knoUsers = [];
    public localizations;

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private tasksService: TasksService,
        private rest: RestService,
        private storage: StorageService,
        private usersService: UsersService,
        private translate: TranslateService,
        private rsoService: RsoService
    ) {}

    /**
     * Инициализация компонента
     */
    public async ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe((parentUrl) => {
            this.basePath = parentUrl[0].path;
        });
        const users = await this.tasksService.getUsers();
        this.route.data.subscribe((response) => {
            this.tasks = _.cloneDeep(this.tasksService.processingTasks(response.resolves));
            if (this.basePath !== 'all-tasks') {
                const assignee = this.storage.getItem('user').login;
                this.tasks = this.tasks.filter(
                    (task) =>
                        task.assignee === assignee ||
                        (this.rsoService.isRsoOperatorUser() &&
                            task.candidateGroups.includes(this.rsoService.getRsoOperatorRole())) ||
                        (this.rsoService.isRsoAnySupervisorUser() && this.rsoService.isAssigneeAnyOfUser(task, users))
                );
            }
            this.tasks = this.tasks.map((task) => this._addToTaskExpireNotification(task));
        });
        this.usersService.getKnoUsers(false).then((usersData) => {
            this.knoUsers = usersData;
        });

        this.prepareDataForFiltersPanel();
    }

    public _loadTranslations() {
        this.translate.get(['common', 'tasks']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    private _addToTaskExpireNotification(task) {
        if (!task.due) {
            return task;
        }

        if (moment(task.due) > moment() && moment(task.due).diff(moment(), 'hours') < 24) {
            // Срок исполнения истекает завтра
            task.notification = { title: this.localizations.tasks.expire.tomorrow, type: 'warning' };
        } else if (moment(task.due) < moment() && moment(task.due).diff(moment(), 'days') === 0) {
            // Срок исполнения истекает сегодня
            task.notification = { title: this.localizations.tasks.expire.today, type: 'warning' };
        } else if (moment(task.due) < moment()) {
            // Срок исполнения истек
            task.notification = { title: this.localizations.tasks.expire.expired, type: 'error' };
        }

        return task;
    }

    public prepareDataForFiltersPanel() {
        this.filtersPanelItems = _.cloneDeep(this.tasksService.filtersPanelItems);

        this.filtersPanelItems.forEach((data) => {
            const statusFilter = data.filters.find((item) => item.code === 'taskStatus');
            if (statusFilter) {
                const findValue = statusFilter.items.find((item) => item.code === 'ACTIVE');
                findValue.isChecked = true;
                statusFilter.value = [findValue];
            }
        });
        if (this.basePath !== 'all-tasks') {
            this.filtersPanelItems.forEach((data) => {
                const findFilterIndex = data.filters.findIndex((item) => item.code === 'notAssignee');
                if (findFilterIndex !== -1) {
                    data.filters.splice(findFilterIndex, 1);
                }
                const findAssignee = data.filters.find((item) => item.code === 'taskAssignee');
                if (findAssignee) {
                    findAssignee.value = this.storage.getItem('user').login;
                    findAssignee.disabled = true;
                }
            });
        }
    }

    public createNewTask() {
        this.router.navigate([this.moduleBaseUrl, this.basePath, 'create']);
    }

    public blockStyle(item, last) {
        let classes = '';
        classes += this.tasksService.getStatusByProperty(item.priority, 'borderTop');
        if (last) {
            classes += ' border-bottom border-bottom-default';
        }
        return classes;
    }

    public getPropertyByStatus(item, property) {
        return this.tasksService.getStatusByProperty(item.priority, property);
    }

    public click(task) {
        this.task = task;
    }

    /**
     * Обработка двойного клика по карточке - переход к редактированию задачи
     * @param task - обрабатываемая задача
     */
    public dblClick(task) {
        clearTimeout(this.timer);
        this.prevent = true;
        this.router.navigate([this.moduleBaseUrl, this.basePath, 'edit', task.camundaId, task.id]);
    }

    /**
     * Поиск по нажатию клавиши Enter после ввода поискового запроса
     */
    public search(event?) {
        if (event) {
            if (event.keyCode === 13) {
                this.loadData('replace');
            }
        }
    }

    public clearSearch() {
        this.globalSearch = null;
        this.loadData('replace');
    }

    public prepareFilterByActualProperty(data) {
        const arr = [];
        let usedArr = arr;
        if (data.length > 1) {
            arr.push({
                orSubConditions: [],
            });
            usedArr = arr[0].orSubConditions;
        }

        const findExpire = data.find((item) => item.code === 'expired');
        if (findExpire) {
            usedArr.push({ field: 'userTasks.due', operator: 'lt', value: moment().format('YYYY-MM-DDTHH:mm:ssZZ') });
        }

        const findEnding = data.find((item) => item.code === 'termEnding');
        if (findEnding) {
            usedArr.push({
                andSubConditions: [
                    {
                        field: 'userTasks.due',
                        operator: 'le',
                        value: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZZ'),
                    },
                    {
                        field: 'userTasks.due',
                        operator: 'ge',
                        value: moment().startOf('day').add(-2, 'days').format('YYYY-MM-DDTHH:mm:ssZZ'),
                    },
                ],
            });
        }
        return arr;
    }

    /**
     * Получение списка пакетов
     * @param type - add или replace (добавление в конец или обновление списка)
     */
    public loadData(type) {
        if (
            type === 'replace' ||
            (type === 'add' && ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages))
        ) {
            const currentOrganization = this.storage.getItem('currentOrganization');
            // Параметры настроенных фильтров
            let search = [];
            if (!this.rsoService.isRsoUser()) {
                search.push({ field: 'unit.id', operator: 'eq', value: currentOrganization._id });
            }
            search.push({ field: 'userTasks', operator: 'neq', value: null });
            // let search = [{field: 'userTasks', operator: 'neq', value: null}];
            search = search.concat(this.filtersPanel.prepareFilters());

            // Страница
            let page = 0;
            if (type === 'add') {
                page = this.currentPage + 1;
            }

            // В случае если в панели фильтрации включена фильтрация по виду задачи
            const filterByTaskType: any = this.filtersPanel.checkInComponentProcessingParam('taskType');
            if (filterByTaskType) {
                search = search.concat({
                    field: 'mainId',
                    operator: filterByTaskType.code === 'processing' ? 'neq' : 'eq',
                    value: null,
                });
            }

            // Обработка параметров "Истекает срок выполнения" "Просрочено"
            const filterByTaskActualProperty: any = this.filtersPanel.checkInComponentProcessingParam('actualType');
            if (filterByTaskActualProperty) {
                search = search.concat(this.prepareFilterByActualProperty(filterByTaskActualProperty));
            }

            if (this.rsoService.isRsoOperatorUser()) {
                const index = search.findIndex((c) => c.field === 'userTasks.assignee');
                if (index !== -1) {
                    search[index] = {
                        orSubConditions: [
                            search[index],
                            {
                                field: 'userTasks.candidateGroups',
                                operator: 'in',
                                value: [this.rsoService.getRsoOperatorRole()],
                            },
                        ],
                    };
                }
            }

            const params = {
                search: { search: search },
                size: 20,
                page: page,
            };

            // Обработка параметров глобального поиска
            if (this.globalSearch) {
                params.search['textSearch'] = this.globalSearch;
            }

            // Получение списка дел с сервера
            const searchCallback = (tasks: any) => {
                const data = _.cloneDeep(this.tasksService.processingTasks(tasks));

                if (type === 'replace') {
                    this.tasks = _.cloneDeep(data);
                } else {
                    data.forEach((item) => {
                        this.tasks.push(item);
                    });
                }
                this.totalCount = this.rest.pagination.total;
                this.currentPage = this.rest.pagination.page;
                this.totalPages = this.rest.pagination.totalPages;
            };

            if (this.rsoService.isRsoAnySupervisorUser()) {
                params.search.search = params.search.search.filter(
                    (condition) => condition.field !== 'userTasks.assignee'
                );

                this.tasksService.getUsers().then((users) => {
                    const logins = users.map((user) => user.login).filter((login) => !!login);

                    params.search.search.push({
                        field: 'userTasks.assignee',
                        operator: 'in',
                        value: logins,
                    });

                    this.rest
                        .search('camundaBusinessInfo', params)
                        .then((infos) => {
                            infos
                                .filter((info) => info.userTasks)
                                .forEach((info) => {
                                    info.userTasks = info.userTasks.filter(
                                        (task) =>
                                            task.assignee &&
                                            logins.find((login) => login.toLowerCase() === task.assignee.toLowerCase())
                                    );
                                });
                            return infos;
                        })
                        .then(searchCallback);
                });
            } else {
                this.rest.search('camundaBusinessInfo', params).then(searchCallback);
            }
        }
    }

    /**
     * Изменение направления сортировки списка дел
     */
    public changeSortDirection() {
        // this.sort.direction = this.sort.direction === 'ASC' ? 'DESC' : 'ASC';
        // this.loadData('replace');
    }

    public setFilter() {}

    public getAssigneeUser(assignee) {
        const find = this.knoUsers.find((item) => item.login === assignee);
        return find ? find.name : assignee;
    }
}
