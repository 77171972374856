import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import {
    AccessService,
    ModalDialogComponent,
    SelectionService,
    ToasterService,
} from '@evolenta/core';
import { Router } from '@angular/router';
import {
    CommonAppealEntityAdditionalDataComponent,
} from '../../appeal-subjects-objects/appeal-entity-additional-data/common-appeal-entity-additional-data.component';
import { PersonService } from '../../../../../common/services/persons.service';
import { CommonAppealSubservicesService } from '../../../services/common-appeal-subservices.service';
import { CopyService } from '../../../../../common/services/copy.service';
import { CommonAppealStatusService } from '../../../services/common-appeal-status.service';
import { ModalUserComponent } from '../../../../subjects/modal-user/modal-user.component';
import { CommonAppealValidateService } from '../../../services/common-appeal-validate.service';
import { Permission } from '../../../../../common/services/permission';
import { SubjectFormComponent } from '../../../../subjects/subject-form/subject-form.component';
import {
    IndividualObjectData,
} from '../../../../subjects/individual-object/components/individual-applicant-form/common/individual-object.data';
import { BsModalRef } from 'ngx-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import { ErrorLoggingService } from '../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-subject-card',
    templateUrl: 'common-appeal-subject-card.component.html',
})
export class CommonAppealSubjectCardComponent implements OnInit, OnChanges {
    @Input() public subject: any; // Субъект
    @Input() public compareSubject: any; // Субъект дела (для сравнения)
    @Input() public availableKinds; // Доступные для выбора виды объектов
    @Input() public mode = 'view'; // Режим работы: просмотр карточки, редактирование данных
    @Input() public activeTab; // Активная вкладка
    @Input() public appeal; // Формируемое дело
    @Input() public subservice: any = {}; // услуга, на основании которой формируется дело
    @Input() public noMargin = false;
    @Input() public bottomMargin = false;
    @Input() public params;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onApplyError = new EventEmitter<any>();
    @Output() public onInitEdit = new EventEmitter<object>();
    @Output() public onDelete = new EventEmitter<object>();
    @ViewChild(ModalDialogComponent, { static: false }) public alertModalComponent: ModalDialogComponent;

    public modalMessage; // Текст сообщения для модального окна
    public modalTitle; // Заголовок сообщения для модального окна
    public modalOperation; // Вид операции (возврат к списку или удаление объекта)

    public modalRef: BsModalRef;

    public isEditObjectKind = false; // Флаг режима выбора вида объекта
    public isEditObjectSubKind = false; // Флаг режима выбора типа объекта
    public globalParams = IndividualObjectData; // Общие константы для работы с объектом
    public continueProcessingAppeal; // флаг продолжения операции сохранения (перехода на другой статус) дела после применения изменений в услуги

    @ViewChild('editSubject', { static: false }) public editSubjectComponent: SubjectFormComponent;

    public permissions = Permission; // Набор прав системы

    public searchText;
    public findedSubjects = [];
    public findedSubjectsType;
    @ViewChild(ModalUserComponent, { static: false }) public modalUserComponent: ModalUserComponent; // модальное окно для выода списка найденных
    public isFormValid = true;

    @ViewChild('additionalData', { static: false }) public additionalDataComponent: CommonAppealEntityAdditionalDataComponent;

    public subjectParams;
    public subjectsDataInSubservice;

    public isProcessingSearch = false;
    public isProcessingSelect = false;
    public isProcessValidate = false;

    public constructor(
        public validateService: CommonAppealValidateService,
        public commonAppealStatusService: CommonAppealStatusService,
        public accessService: AccessService,
        private commonAppealSubservicesService: CommonAppealSubservicesService,
        private elementRef: ElementRef,
        private copyService: CopyService,
        private toaster: ToasterService,
        private personsService: PersonService,
        private selectionService: SelectionService,
        private errorLoggingService: ErrorLoggingService,
        private router: Router,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.subjectsDataInSubservice = this.subservice.objects.subjectsData;
        this.initSubjectParams();

        if (!this.subject.kind && this.subject.specialTypeId) {
            this.initSubjectKindForSubject();
        }

        // Если у объекта не выбран вид, то инициализация режима выбора вида объекта
        if (!this.subject.kind && this.mode === 'edit') {
            this.isEditObjectKind = true;
        }
        // Если не выбран тип объекта, то инициализация режима выбора типа объекта
        if (this.subject.kind && !this.subject.kind.subKind && this.subject.kind.subKinds) {
            this.isEditObjectSubKind = true;
        } else if (!this.subject.data.person) {
            this.subject.data.person = {};
        }

        this.activeTab = 'common';

        // Прослушивание события вставки данных
        this.elementRef.nativeElement.addEventListener('paste', event => {
            this.pasteFromBuffer(event);
        });
    }

    public initSubjectKindForSubject() {
        this.subject.kind = {
            name: 'Участник',
            type: 'participant',
            subKind: {
                name: IndividualObjectData.subjectsData[this.subject.specialTypeId].name,
                specialTypeId: this.subject.specialTypeId,
                header: IndividualObjectData.subjectsData[this.subject.specialTypeId].header,
                shortHeader: IndividualObjectData.subjectsData[this.subject.specialTypeId].shortHeaderOptions,
            },
        };
    }

    public ngOnChanges(changes) {
        if (changes && changes.params && !changes.params.firstChange) {
            if (changes.params.previousValue !== changes.params.currentValue) {
                this.initSubjectParams();
            }
        }
    }

    public initSubjectParams() {
        this.subjectParams = cloneDeep(this.params);
        this.subjectParams.allowCreateNew = false;
        this.subjectParams.allowSelectFromRegistry = true;
        this.subjectParams.selectOnlyFromGlobalRegistry = false;
        this.correctSubjectParams();
    }

    public correctSubjectParams() {
        if (this.subject.specialTypeId && this.subjectsDataInSubservice && this.subjectsDataInSubservice.subjectTypes) {
            const usedType = this.subjectsDataInSubservice.subjectTypes.find(item => item.code === this.subject.specialTypeId);
            if (usedType) {
                this.subjectParams.allowCreateNew = !!usedType.allowCreateNew;
                this.subjectParams.allowSelectFromRegistry = !!usedType.allowSelectFromRegistry;
                this.subjectParams.selectOnlyFromGlobalRegistry = !!usedType.selectOnlyFromGlobalRegistry;
                this.subjectParams.fieldsRequirements = usedType.fieldsRequirements ? usedType.fieldsRequirements : null;
                this.subjectParams.typeXsd = usedType.xsd;
            }
            if (this.subjectParams.editedFields
                && this.subjectParams.editedFields[IndividualObjectData.subjectsData[this.subject.specialTypeId].shortCode]
                && this.subjectParams.editedFields[IndividualObjectData.subjectsData[this.subject.specialTypeId].shortCode].length > 0
            ) {
                this.subjectParams.allowedEditFields = this.subjectParams.editedFields[IndividualObjectData.subjectsData[this.subject.specialTypeId].shortCode];
                this.subjectParams.ignoreReestrId = true;
            }

            // если объект добавленный, то разрешаем все операции с ним
            if (this.subjectParams.allowCreateNew && !this.subject.reestrId && !this.subject.mainId) {
                Object.keys(this.subjectParams.permissions).forEach(key => {
                    this.subjectParams.permissions[key] = true;
                });
                this.subjectParams.allowedEditFields = null;
                this.subjectParams.ignoreReestrId = false;
            }
        }
    }

    public get isExistObjectGroups() {
        return this.subject.kind
            && (this.subject.kind.subKinds && this.subject.kind.subKind  && !this.subject.kind.subKind.onlyForPrincipal || !this.subject.kind.subKinds)
            && this.subservice.objects.objectGroups.length > 0;
    }

    public isCompleteSubserviceData() {
        if (!this.isExistObjectGroups) {
         return true;
        }

        if (!this.appeal.subservice.subjects) {
            return false;
        }

        const subject = this.appeal.subservice.subjects.find(item => item.guid === this.subject.guid);

        return subject && subject.group && subject.category && subject.subKind;
    }

    /**
     * КНМ события, что в данный момент осуществляется добавление нового пользователя, а не редактируется имеющийся (режим работы "Дело")
     * @returns {boolean}
     */
    public checkIsAddNewSubject() {
        return ['ulApplicant', 'foreignUlApplicant'].indexOf(this.subject.specialTypeId) !== -1 && (!this.subject.data.organization || !this.subject.data.organization.name)
               || (['ulApplicant', 'foreignUlApplicant'].indexOf(this.subject.specialTypeId) === -1
                  && this.subject.data.person && !this.subject.reestrId);
    }

    /**
     * Обработка события нажания на Enter при поиске
     * @param event
     */
    public keypress(event?) {
        if (event) {
            if (event.keyCode === 13) {
                this.searchSubject();
            }
        }
    }

    public async searchSubject() {
        this.isProcessingSearch = true;
        this.personsService.searchSubject(this.subject.specialTypeId, this.searchText, this.subjectParams.selectOnlyFromGlobalRegistry).then(subjects => {
            this.isProcessingSearch = false;
            if (subjects.length === 0) {
                this.toaster.info('Совпадения не найдены');
            } else {
                this.findedSubjects = subjects;
                this.modalUserComponent.showModal();
            }
        });
    }

    public async selectSubject(subject) {
        this.isProcessingSelect = true;
        const subjectData = await this.personsService.getSubjectData(subject, this.subjectParams.selectOnlyFromGlobalRegistry);
        this.subject = Object.assign(this.subject, subjectData);
        this.isProcessingSelect = false;
    }

    /**
     * Функция формирования массива подвидов объектов
     * @param kind
     * @returns {Array}
     */
    public getSubKinds(kind) {
        const find = this.availableKinds.find(item => item.guid === kind.guid);

        return find.subKinds;
    }

    /**
     * Выбор вида объекта
     * @param kind - вид объекта из списка доступных для выбора
     */
    public selectKind(kind) {
        this.subject.kind = Object.assign({}, kind);
        if (this.subject.kind && this.subject.kind.subKinds) {
            if (this.subject.kind.subKinds.length === 1) {
                // Если в выбранном виде объекта только один доступный для выбора тип, то сразу инициализируем его
                this.subject.kind.subKind = Object.assign({}, this.subject.kind.subKinds[0]);
                this.subject.specialTypeId = this.subject.kind.subKind.specialTypeId;
                this.initTabsData(this.subject.kind.subKinds[0]);
                this.isEditObjectKind = false;
            } else {
                // Инициализация режима выбора типа объекта
                this.isEditObjectKind = false;
                this.isEditObjectSubKind = true;
                this.subject.kind.subKind = null;
            }
            delete this.subject.kind.subKinds;
        } else {
            this.subject.specialTypeId = kind.specialTypeId;
            this.isEditObjectKind = false;
        }
        this.correctSubjectParams();
    }

    public initTabsData(subKind) {
        if (this.subject.specialTypeId === IndividualObjectData.legalPersonCode && !this.subject.data.organization) {
            this.subject.data.organization = {};
            this.subject.data.personInOrg = {position: null, authority: null}; // Инициализация представителя организации
            this.activeTab = 'subservices';
        } else if (this.subject.specialTypeId === 'realty') {
            this.subject.data.realty = {};
            this.activeTab = 'realty';
        } else if (subKind.onlyForPrincipal) {
            this.activeTab = 'common';
        } else {
            delete this.subject.data.organization;
            delete this.subject.data.personInOrg;
            this.activeTab = 'subservices';
        }
    }

    /**
     * Выбор типа объекта: ФЛ, ЮЛ, ИП
     */
    public changeSubKind(sub) {
        const beforeSubKind = cloneDeep(this.subject.kind.subKind);
        this.subject.kind.subKind = sub;
        // this.subject = cloneDeep(this.subject); // копируем объект, для того, чтобы сработало изменение
        if (this.subject.kind && this.subject.kind.subKind) {
            this.isEditObjectSubKind = false;
            // В случае изменения
            if (!beforeSubKind || beforeSubKind.name !== this.subject.kind.subKind.name) {
                this.subject.specialTypeId = this.subject.kind.subKind.specialTypeId;
                if (!this.subject.data.person) {
                    this.subject.data.person = {};
                }
                this.initTabsData(sub);
                this.commonAppealSubservicesService.initSubjectFieldsRequirements(this.subject);
            }
            this.correctSubjectParams();
            if (!this.isCompleteSubserviceData()) {
                this.activeTab = 'subservices';
            } else {
                this.activeTab = 'common';
            }
        } else {
            this.subject.specialTypeId = null;
        }
    }

    /**
     * Проверка возможности редактирования вида объекта
     * @returns {boolean}
     */
    public canEditKind() {
        return this.mode === 'edit' && this.availableKinds.length > 1;
    }

    /**
     * Инициализация режима редактирования вида объекта
     */
    public editKind() {
        if (this.canEditKind()) {
            this.isEditObjectKind = true;
        }
    }

    /**
     * КНМ возможности редактирования типа объекта
     * @returns {boolean}
     */
    public canEditSubKind() {
        if (this.mode === 'edit') {
            const kind = this.availableKinds.find(item => item.type === this.subject.kind.type);
            if (kind && kind.subKinds.length > 1) {
                return true;
            }
        }

        return false;
    }

    /**
     * Инициализация режима редактирования типа объекта
     */
    public editSubKind() {
        if (this.canEditSubKind()) {
            this.isEditObjectSubKind = true;
        }
    }

    /**
     * Инициализация режима редактирования услуги в деле (нажатие кнопки "Редактировать")
     */
    public edit() {
        this.onEdit.emit(this.subject);
    }

    /**
     * Применение изменений внесенных в объект (нажатие кнопки "Применить")
     * @param continueProcessingAppeal - продолжить сохранение (или перевод на другой статус) дела после применения изменений
     */
    public apply(continueProcessingAppeal = false) {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.continueProcessingAppeal = continueProcessingAppeal;
            this.onApply.emit({subject: this.subject, continueProcessingAppeal: continueProcessingAppeal});
        } else {
            this.onApplyError.emit();
            const errorText = 'На форме содержатся ошибки';
            this.toaster.error(errorText);
            this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
        }
    }

    /**
     * Отмена изменений внесенных в услугу дела (нажатие кнопки "Отменить")
     */
    public cancel() {
        this.onApply.emit(false);
    }

    /**
     * Возврат к списку объектов - КНМ на возможные изменения
     */
    public back() {
        if (!isEqual(this.subject, this.compareSubject)) {
            this.modalMessage = 'В объект внесены изменения. Вы хотите применить их перед переходом к списку?';
            this.modalTitle = 'Сохранение изменений';
            this.modalOperation = 'processingBack';
            this.alertModalComponent.showModal();
        } else {
            this.apply();
        }
    }

    /**
     * Обработка действий из модального окна: применение изменений, либо отмена
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingBack(data) {
        if (data === 'yes') {
            this.apply();
        } else if (data === 'no') {
            this.cancel();
        }
    }

    /**
     * Активация определенной вкладки при редактировании объекта
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        this.activeTab = tab;
    }

    /**
     * Передача управления ответа на нажатие кнопок модального окна нужному методу
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Инициализация вызова модального окна для подтверждения удаления объекта
     */
    public deleteSubject() {
        this.modalMessage = 'Вы действительно хотите удалить объект "' + this.subject.header + '"?';
        this.modalTitle = 'Удаление объекта';
        this.modalOperation = 'processingDelete';
        this.alertModalComponent.showModal();
    }

    /**
     * Обработка результата нажатия кнопок модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingDelete(data) {
        if (data === 'yes') {
            this.onDelete.emit(this.subject);
        }
    }

    /**
     * Совершение дополнительных действий после сохранения json-формы на вкладке "Доп.данные"
     * @param data - объект {tab: активация определенной вкладки; apply: переход к режиму сохранения}
     */
    public afterSaveAdditionalData(data) {
        // Переход на другую вкладку
        if (data.tab) {
            this.activeTab = data.tab;
        }
        // Сохранение изменений
        if (data.apply) {
            this.onApply.emit({subject: this.subject, continueProcessingAppeal: this.continueProcessingAppeal});
        }
    }

    /**
     * Копирование данных объекта в буфер
     */
    public copyToBuffer() {
        this.copyService.copyData(this.subject.data, 'object');
    }

    /**
     * Вставка данных из буфера, замена данных объекта данными из буфера
     * @param event
     */
    public pasteFromBuffer(event) {
        if (this.mode === 'edit') {
            const copyData = this.copyService.getDataFromClipboard(event, 'object');
            if (copyData) {
                this.subject.data = cloneDeep(copyData);
                // this.individualObjectComponent.updateDataAfterPasteObjectData();
            }
        }
    }

    public validate(): boolean {
        let additionalDataValid = true;
        if (this.additionalDataComponent) {
            this.additionalDataComponent.validate();
            additionalDataValid = this.additionalDataComponent.isValid();
        }
        let mainDataValid = true;
        if (this.editSubjectComponent) {
            mainDataValid = this.editSubjectComponent.isValid();
        }
        let isSelectSubjectFromRegistry = true;
        if (!this.editSubjectComponent && this.subjectParams && this.subjectParams.allowSelectFromRegistry && !this.subject.globalSubjectId) {
            isSelectSubjectFromRegistry = false;
        }

        return additionalDataValid && mainDataValid && isSelectSubjectFromRegistry;
    }

    public async selectFromRegistry() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.appeal;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectSubject';
        this.selectionService.additionalData = {
            viewMode: 'oneWindow',
            editedSubject: this.subject,
            selectFromGlobal: this.subjectParams.selectOnlyFromGlobalRegistry,
            entityType: 'subject',
        };
        this.selectionService.selectType = 'one';
        this.selectionService.baseSearch = [{
            field: this.subjectParams.selectOnlyFromGlobalRegistry ? 'specialTypeId' : 'subjectLink.specialTypeId',
            operator: 'eq',
            value: this.subject.specialTypeId,
        }];

        const paths = ['selecting', this.subjectParams.selectOnlyFromGlobalRegistry ? 'global-subjects' : 'subjects'];
        await this.router.navigate(paths);
    }

    public getSubjectTypeName() {
        switch (this.subject.specialTypeId) {
            case 'ulApplicant':
                return 'Юридическое лицо';
            case 'individualApplicant':
                return 'Физическое лицо';
            case 'ipApplicant':
                return 'Индивидуальный предприниматель';
            case 'foreignUlApplicant':
                return 'Иностранное юридическое лицо';
            default:
                return '';
        }
    }

    public get isVisiblePreviewCard() {
        return this.mode === 'view' || ((this.appeal.status.code === 'draft'
            && (!this.subject.kind
                || this.subject.kind && this.subject.kind.subKind && !this.subject.kind.subKind.allowEditCommonData)
            )
            && !this.checkIsAddNewSubject());
    }

    // Определение видимости блока с редактированием основного состава данных
    public get isShowEditCommonDataBlock() {
        // режим редактирования + субъект выбран не из реестра + разрешено создание нового элемента из настроек услуги + в текущем статусе разрешено редактирование
        return this.mode === 'edit'
               && (this.subjectParams.allowCreateNew && !this.subject.reestrId || this.subject.reestrId && this.subjectParams.ignoreReestrId)
               && this.subjectParams.permissions.edit;
    }

    public get isShowPreviewCard() {
        return this.mode === 'view' && this.subject.kind
               || (this.subject.reestrId && !this.subjectParams.ignoreReestrId)
               || !this.subjectParams.permissions.edit;
    }

    public get isShowSearchBlock() {
        return this.mode === 'edit' && this.subjectParams.allowSelectFromRegistry && this.isEmptySubject;
    }

    public get isEmptySubject() {
        return ['ulApplicant', 'foreignUlApplicant'].indexOf(this.subject.specialTypeId) !== -1 && (!this.subject.data.organization || !this.subject.data.organization.name)
            || (['ulApplicant', 'foreignUlApplicant'].indexOf(this.subject.specialTypeId) === -1
                && (!this.subject.data.person || !this.subject.data.person.lastName &&  !this.subject.data.person.firstName));
    }

    public getFieldsRequirementsRules() {
        if (this.subservice.objects.subjectsData && this.subservice.objects.subjectsData.subjectTypes) {
            const find = this.subservice.objects.subjectsData.subjectTypes.find(item => item.code === this.subject.specialTypeId);

            return find.fieldsRequirements ? find.fieldsRequirements : null;
        }

        return null;
    }

    public allowEditSubject() {
        return this.mode === 'view'
               && (this.subjectParams.permissions.edit
                   || this.subjectParams.permissions.editXsd && this.subjectParams.xsd
                   || this.subjectParams.permissions.editMainXsd && this.subjectParams.mainXsd
                   || this.subjectParams.permissions.editTypeXsd && this.subjectParams.typeXsd);
    }
}
