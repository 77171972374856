import { Injectable } from '@angular/core';
import { RestService, SelectionService, StorageService } from '@evolenta/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CheckListTemplatesResolve implements Resolve<any> {
    public constructor(
        private restService: RestService,
        private selectionService: SelectionService,
        private storage: StorageService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const checkListTemplateId = route.params['checkListTemplateId'];
        const isCreateAction = route.url.filter((item) => item.path === 'create').length > 0;
        const onlyForKno = route.parent.parent.url.filter((item) => item.path === 'kno-metodolog').length > 0;
        if (isCreateAction) {
            // Создание
            return Promise.resolve({});
        } else if (checkListTemplateId) {
            // Редактирование
            if (this.selectionService.isProcessSelect) {
                return {};
            } else {
                return this.restService.find('checkListTemplates', checkListTemplateId);
            }
        } else {
            // Список

            const currentOrganization = this.storage.getItem('currentOrganization');
            const params = [];

            if (onlyForKno) {
                params.push({
                    field: 'unit.id',
                    operator: 'eq',
                    value: currentOrganization._id,
                });
            }

            return this.restService.search('checkListTemplates', {
                search: { search: params },
                sort: 'dateCreation,DESC',
            });
        }
    }
}
