import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'entity-process-container',
    templateUrl: 'entity-process-container.component.html',
})
export class EntityProcessContainerComponent {
    @Input() public data;
    @Input() public isFirst = false;
    @Input() public number;
    @Input() public commentsPlace;

    public modalRef: BsModalRef;
    @ViewChild('commentsModal', { static: false }) public commentsModal: TemplateRef<any>;

    public activeEvent;

    public constructor(private modalService: BsModalService) {}

    public showComments() {
        this.modalRef = this.modalService.show(this.commentsModal, { backdrop: 'static', class: 'modal-md' });
    }

    public get commentsBlocks() {
        return this.commentsPlace.comments ? Object.keys(this.commentsPlace.comments) : [];
    }

    public getCommentsCount() {
        if (this.commentsPlace && this.commentsPlace.comments && Object.keys(this.commentsPlace.comments).length > 0) {
            let commentsCount = 0;

            Object.keys(this.commentsPlace.comments).forEach((blockCode) => {
                commentsCount += this.commentsPlace.comments[blockCode].comments.length;
            });

            return commentsCount;
        }

        return 0;
    }
}
