<!-- глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <!-- Обычный выбор одной услуги -->
    <ng-container *ngIf="!appealService.isProcessSetupAppealSubservices
                         && !appealService.isProcessSelectSubserviceForCopyAppeal
                         && !servicesService.isProcessSelectSubserviceForCreateAppealFromEvent
                         && !selectionService.isProcessSelect">
        <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
                type="button"
                [disabled]="!service"
                *ngIf="!isShowAnketa"
                (click)="dblclickByService(service)">
            <b><i class="icon-plus-circle2"></i></b>
            {{ localizations.common.create }}
        </button>

        <button class="btn btn-danger btn-xs btn-main btn-labeled btn-labeled-right"
                type="button"
                *ngIf="isShowAnketa"
                (click)="cancelAnketa()">
            <b>
                <i class="icon-cross2"></i>
            </b>
            {{ localizations.common.cancel }}
        </button>
    </ng-container>

    <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="appealService.isProcessSetupAppealSubservices"
            (click)="returnToAppeal()">
        <b>
            <i class="icon-arrow-right16"></i>
        </b>
        {{ localizations.services.back_to_knm }}
        <span class="badge bg-white ml-5 text-blue">
            {{ appealService.selectedSubservicesForSetupInAppeal.length }}
        </span>
    </button>

    <button type="button" class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            *ngIf="selectionService.isProcessSelect"
            (click)="returnToOperation()">
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5 text-success">
            {{ selectionService.selectedItems.length }}
        </span>
    </button>

    <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="servicesService.isProcessSelectSubserviceForCreateAppealFromEvent"
            (click)="returnToCreateAppeal()">
        <b>
            <i class="icon-arrow-right16"></i>
        </b>
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5 text-blue">
            {{ servicesService.selectedServices.length }}
        </span>
    </button>

    <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="appealService.isProcessSelectSubserviceForCopyAppeal"
            (click)="returnToCopyAppeal()">
        <b>
            <i class="icon-arrow-right16"></i>
        </b>
        {{ localizations.services.back_to_copying }}
        <span class="badge bg-white ml-5 text-blue"
              *ngIf="appealService.selectedSubserviceForCopyAppeal">
        </span>
    </button>


    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   type="text"
                   [placeholder]="localizations.common.search_dots"
                   [(ngModel)]="globalSearch"
                   (keypress)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    *ngIf="globalSearch"
                    (click)="clearSearch()">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData('replace')">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title no-padding-bottom no-border">
                    <h4 class="display-inline-block no-margin">
                    <span class="text-bold">
                        {{ localizations.common.catalogue }}
                    </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li><i class="icon-cog3"></i></li>
                    </ul>
                </div>
            </div>

            <!-- Панель фильтрации основных услуг -->
            <filters-bar filterName="servicesFilter"
                                  [filters]="servicesFiltersPanelItems"
                                  [afterHeader]="true"
                                  [base]="servicesBaseSearch"
                                  *ngIf="activeTab == 'services'"
                                  (onApplyFilters)="loadData('replace')"
                                  #filtersPanel>
            </filters-bar>
        </div>
    </div>
    <!--/secondary sidebar -->

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat no-border-top">
                        <evolenta-infinite-scrollbar scrollClass="panel-body overflow-anchor-none"
                                                     (scrolled)="loadData('add')">
                            <ng-container *ngIf="services.length > 0">
                                <!-- Список услуг-->
                                <div class="list-2">
                                    <div class="group"
                                         *ngFor="let group of services; let fst = first">
                                        <!-- Услуги группы -->
                                        <ng-container *ngIf="!group.hide">
                                            <div *ngFor="let service of group.children; let i = index"
                                                 (mouseenter)="service.isHover = true"
                                                 (mouseleave)="service.isHover = false">
                                                <div class="display-flex align-items-center border-left-4 border-right-4"
                                                    [ngClass]="getCardStyle(service,i)">
                                                    <div class="checkbox-block mt-20 mb-20 ml-20"
                                                         *ngIf="selectionService.isProcessSelect">
                                                        <input id="selectService{{ service._id }}"
                                                               class="checkbox"
                                                               type="checkbox"
                                                               [checked]="service.selected"
                                                               (click)="dblclickByService(service)">
                                                        <label for="selectService{{ service._id }}"
                                                               style="top: -18px">
                                                        </label>
                                                    </div>

                                                    <div class="list-item full-width"
                                                         [attr.data-index]="i"
                                                         (click)="clickByService(service)"
                                                         (dblclick)="dblclickByService(service)">

                                                        <!-- Информация по услуге -->
                                                        <div class="list-item-data"
                                                             style="white-space: inherit">
                                                            <h5 class="no-margin cursor-pointer">
                                                                {{ service.title }}
                                                            </h5>
                                                            <p class="no-margin-bottom">
                                                                <!-- Код услуги (номер регламента) -->
                                                                <span class="text-muted mr-5">№</span>
                                                                <span class="text-semibold">
                                                                    {{ service.shortId }}
                                                                </span>
                                                                <ng-container *ngIf="service.externalNumber">
                                                                    <span class="text-muted ml-10 mr-5">
                                                                        {{ localizations.common.external_number }}:
                                                                    </span>
                                                                    <span class="text-bold">
                                                                        {{ service.externalNumber }}
                                                                    </span>
                                                                </ng-container>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="service.isHover && !selectionService.isProcessSelect">
                                                        <button class="btn btn-primary"
                                                                type="button"
                                                                (click)="createAppeal(service)">
                                                            {{ localizations.common.create }}
                                                        </button>
                                                    </div>

                                                </div>
                                                <div class="list-separator">&nbsp;</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="panel panel-shadow m-15 p-15"
                                 *ngIf="services.length == 0">
                                <div class="panel-body text-center">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing }}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations.appeals.try_to_adjust_filters }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>

                <!-- Панель предпросмотра информации по услуге -->
                <div class="scroll-panel no-padding no-margin second-panel"
                     *ngIf="!isShowAnketa">
                    <div class="alert alert-warning alert-styled-left alert-bordered"
                         *ngIf="!previewMode || previewMode && !service">
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.organizations.select_on_left_for_details }}
                    </div>
                    <service-preview [service]="service"
                                     *ngIf="previewMode && service && !service.isComplex">
                    </service-preview>
                    <service-preview-complex [service]="service"
                                             *ngIf="previewMode && service && service.isComplex">
                    </service-preview-complex>
                </div>

                <!-- Анкета для фильтрации услуг -->
                <div class="scroll-panel no-margin no-padding no-border"
                     *ngIf="isShowAnketa">
                    <anketa-life-event viewType="short"
                                       (onComplete)="getServiceInAnketa($event)">
                    </anketa-life-event>
                </div>
            </div>
        </div>
    </div>
</div>
