import { NgModule } from '@angular/core';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal.component';

@NgModule({
    imports: [],
    declarations: [DeleteConfirmationModalComponent],
    exports: [DeleteConfirmationModalComponent],
    providers: [],
})
export class DeleteConfirmationModalModule {}
