import { Component, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'delete-confirmation-modal',
    templateUrl: 'delete-confirmation-modal.component.html',
})
export class DeleteConfirmationModalComponent {
    @Input() public title;
    @Input() public text;

    public modalRef: BsModalRef;
    @ViewChild('modal', { static: false }) public modal: TemplateRef<any>;

    @Output() public onConfirm = new EventEmitter<{}>();

    public constructor(private modalService: BsModalService) {}

    public showModal() {
        this.modalRef = this.modalService.show(this.modal, { backdrop: 'static' });
    }

    public hideModal() {
        this.modalRef.hide();
    }

    public confirm() {
        this.onConfirm.emit();
        this.modalRef.hide();
    }
}
