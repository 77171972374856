import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionService, TranslateService } from '@evolenta/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { CommonUtilities } from '@evolenta/utilities';
import { ProcessService } from '../../../processes/process.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'organization-processes-settings',
    templateUrl: 'organization-processes-settings.component.html',
})
export class OrganizationProcessesSettingsComponent implements OnInit {
    @Input() public organization;
    @Input() public processes = [];
    @Input() public processType;
    @Input() public scrollTopPosition;

    public process;
    public editedProcess;
    public isCreateOdEdit = false;
    public taskId;

    public tabs = [];
    public activeTab;

    public editedTab;

    public activeAdditionalDataTab = 'common';
    @ViewChild('additionalData', { static: false }) public additionalDataComponent: DynamicFormComponent;

    public additionalDataTabs = [];

    public model = {};

    public isProcessUploadProcess = false;

    public baseParentKnoSearch = {
        field: 'isAuthorized',
        operator: 'eq',
        value: true,
    };

    public processesData = {};

    public editedSignTab;
    public signActiveTab;
    public localizations;

    @Output() public onSaveOrganization = new EventEmitter<boolean>();

    public constructor(
        private router: Router,
        private selectionService: SelectionService,
        private processService: ProcessService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'organizations', 'standards', 'appeals.documents']).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.tabs = [
            {
                code: 'additionalData',
                name: this.localizations['appeals.documents'].additional_data,
            },
            {
                code: 'sign',
                name: this.localizations.organizations.signing,
            },
        ];

        this.additionalDataTabs = [
            {
                code: 'common',
                name: this.localizations.common.general_data,
            },
        ];

        if (!this.organization.processesSettings) {
            this.organization.processesSettings = {};
        }
        this.tabs = this.processes.concat(this.tabs);
        this.additionalDataTabs = this.additionalDataTabs.concat(this.processes);

        if (this.selectionService.isProcessSelect) {
            this.completeSelectBpmn();
        } else {
            this.processes.forEach((process) => {
                this.loadProcess(process.code);
            });
            this.activeTab = this.tabs[0].code;
        }
        if (!this.organization.processesSettings.signData) {
            this.organization.processesSettings.signData = [];
            this.organization.processesSettings.signData.push({
                guid: CommonUtilities.GenerateGuid(),
                code: 'standards',
                name: this.localizations.standards.file,
            });
            this.organization.processesSettings.signData.push({
                guid: CommonUtilities.GenerateGuid(),
                code: 'responsibleOrganizationsReglaments',
                name: this.localizations.organizations.reglament_file,
            });
        }
    }

    public loadProcess(processCode) {
        if (
            this.organization.processesSettings[processCode] &&
            this.organization.processesSettings[processCode].process
        ) {
            this.processService
                .loadProcessData(this.organization.processesSettings[processCode].process)
                .then((process) => {
                    this.processesData[processCode] = process;
                });
        }
    }

    public completeSelectBpmn() {
        if (this.selectionService.isProcessSelect) {
            if (this.selectionService.additionalData) {
                this.processesData = this.selectionService.additionalData.processesData;
                this.activeTab = this.selectionService.additionalData.activeTab;
            }
        }
    }

    public changeProcess(process, code) {
        this.processesData[code] = process;
    }

    public addTab() {
        if (!this.process.additionalDataTabs) {
            this.process.additionalDataTabs = [];
        }
        this.editedTab = {
            guid: CommonUtilities.GenerateGuid(),
            name: null,
            code: null,
            xsd: null,
        };
        this.activeAdditionalDataTab = 'common';
    }

    public editTab(tab) {
        this.editedTab = _.cloneDeep(tab);
        this.activeAdditionalDataTab = 'common';
    }

    public deleteTab(tab) {
        const findIndex = this.organization.processesSettings.additionalDataTabs.findIndex(
            (item) => item.guid === tab.guid
        );
        this.organization.processesSettings.additionalDataTabs.splice(findIndex, 1);
    }

    public createXsd(xsdId) {
        this.editedTab.xsd = xsdId;
    }

    public cancel() {
        this.editedTab = null;
    }

    public apply() {
        if (this.additionalDataComponent) {
            this.additionalDataComponent.saveXsd().then((xsdId) => {
                if (xsdId) {
                    this.editedTab.xsd = xsdId;
                }
                this.saveAdditionalDataTab();
            });
        } else {
            this.saveAdditionalDataTab();
        }
    }

    public saveAdditionalDataTab() {
        if (!this.organization.processesSettings.additionalDataTabs) {
            this.organization.processesSettings.additionalDataTabs = [];
        }
        const findIndex = this.organization.processesSettings.additionalDataTabs.findIndex(
            (item) => item.guid === this.editedTab.guid
        );
        if (findIndex !== -1) {
            this.organization.processesSettings.additionalDataTabs[findIndex] = Object.assign(
                this.organization.processesSettings.additionalDataTabs[findIndex],
                this.editedTab
            );
        } else {
            this.organization.processesSettings.additionalDataTabs.push(this.editedTab);
        }
        this.editedTab = null;
    }

    public selectProcess(params) {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.organization;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectProcess';
        this.selectionService.additionalData = {
            taskId: params.taskId,
            activeTab: this.activeTab,
            processesData: this.processesData,
            processes: this.processes,
        };
        this.selectionService.selectType = 'one';

        this.router.navigate(['processes', 'process-registry']);
    }

    public editSignData(data) {
        this.editedSignTab = data;
    }

    public saveSignData() {
        this.editedSignTab = null;
        this.onSaveOrganization.emit(true);
    }
}
