import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, SelectionService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'bpmn-card-tasks',
    templateUrl: './bpmn-card-tasks.component.html',
})
export class BpmnCardTasksComponent implements OnInit {
    @Input() public tasks;
    @Input() public type;
    @Input() public tasksName;
    @Input() public isCanChange = false;
    @Input() public process;
    @Input() public allowEdit = true;

    @Output() public onCreateSubProcess = new EventEmitter<any>();
    @Output() public onSearchSubProcess = new EventEmitter<any>();
    @Output() public onDeleteSubProcess = new EventEmitter<any>();
    @Output() public onEditSubProcess = new EventEmitter<any>();

    public panels = {};
    public showTasks = true;
    public dmnProcesses = {};

    public constructor(
        private selectionService: SelectionService,
        private router: Router,
        private rest: RestService,
    ) {
    }

    public ngOnInit(): void {
        if (this.isCanChange) {
            this.tasks.forEach(item => {
                this.panels[item.id] = false;
            });
        }
        if (this.type === 'businessRuleTasks') {
            if (this.selectionService.isProcessSelect ) {
                const processingTask = this.tasks.find(item => item.guid === this.selectionService.transferObjectChild.guid);
                this.dmnProcesses = _.cloneDeep(this.selectionService.additionalData.dmnProcesses);
                if (this.selectionService.selectedItems.length > 0) {
                    processingTask.dmn = this.selectionService.selectedItems[0]._id;
                    this.dmnProcesses[processingTask.dmn] = this.selectionService.selectedItems[0];
                } else {
                    processingTask.dmn = null;
                }
                this.selectionService.clearData();
            } else {
                this.processingDmn();
            }
        }
    }

    public searchProcess(taskId) {
        this.onSearchSubProcess.emit(taskId);
    }

    public addNew(taskId) {
        this.onCreateSubProcess.emit(taskId);
    }

    public toggleRow(task) {
        if (this.isCanChange) {
            this.panels[task.id] = !this.panels[task.id];
        }
    }

    public deleteProcess(task) {
        this.onDeleteSubProcess.emit(task.guid);
    }

    public editProcess(task) {
        this.onEditSubProcess.emit({ taskId: task.id, process: this.process });
    }

    public processingDmn() {
        const dmnIds = this.tasks.filter(item => item.dmn).map(item => item.dmn);
        if (dmnIds.length > 0) {
            this.rest.search('sperDmn', {search: {search: [{field: '_id', operator: 'in', value: dmnIds}]}}).then(dmn => {
                dmn.forEach(dmnItem => {
                    this.dmnProcesses[dmnItem._id] = dmnItem;
                });
            });
        }
    }

    public selectDmn(task) {
        this.selectionService.isProcessSelect = true;
        this.selectionService.selectedItems = task.dmn ? [this.dmnProcesses[task.dmn]] : [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectDmn';
        this.selectionService.transferObject = this.process;
        this.selectionService.transferObjectChild = task;
        this.selectionService.selectType = 'one';
        this.selectionService.additionalData = { dmnProcesses: _.cloneDeep(this.dmnProcesses) };

        this.router.navigate(['processes', 'business-rules']);
    }
}
