<form #form="ngForm">
    <div class="pl-20 pr-20 pt-15 pb-20">

        <div class="row">

            <!-- ИНН -->
            <div class="col-md-6 col-lg-2"
                 *ngIf="user.specialTypeId != 'ulApplicant' || fieldsRequirementsService.checkFieldRequired('person.inn', this.user)">
                <div class="form-group form-group-lg no-margin-bottom">
                    <ng-template #innRequestTemplate>
                        <appeal-object-request-info
                            [object]="user"
                            [requestCode]="'quickInn'">
                        </appeal-object-request-info>
                    </ng-template>
                    <label>
                        {{ localizations.common.inn }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.inn', this.user)">
                            *
                        </span>
                        <ng-container *ngIf="checkEnableQuickRequest('quickInn')">
                            <span class="ml-5 text-size-small"
                                  *ngIf="appealMode">
                                <i class="fa fa-info-circle text-slate cursor-pointer"
                                   placement="right"
                                   [popover]="innRequestTemplate"
                                   [outsideClick]="true">
                                </i>
                            </span>
                            <a class="anchor text-info text-size-small"
                               *ngIf="!checkProcessingQuickRequest('quickInn')"
                               (click)="sendQuickRequest('quickInn')">
                                {{ localizations.subjects.request }}
                            </a>
                            <span class="text-size-small text-muted"
                                  *ngIf="checkProcessingQuickRequest('quickInn')">
                                <i class="icon-spinner2 spinner"></i>
                                {{ localizations.subjects.data_receive }}
                            </span>
                            <span class="text-info text-size-small anchor ml-5"
                                  routerLink="/ais/envelopes/edit/{{user['quickRequests']['quickInn'][0].id}}"
                                  [title]="localizations.subjects.request_in_progress"
                                  *ngIf="user['quickRequests'] && user['quickRequests']['quickInn'] && checkProcessingQuickRequest('quickInn')">
                                {{ user['quickRequests']['quickInn'][0].auid }}
                            </span>
                        </ng-container>
                    </label>
                    <input type="text"
                           class="form-control"
                           name="inn"
                           #inn="ngModel"
                           [(ngModel)]="user.data.person.inn"
                           [textMask]="{mask: globalParams.innMask}"
                           [required]="fieldsRequirementsService.checkFieldRequired('person.inn', this.user)"
                           (ngModelChange)="updateErrors('person.inn')"
                           [disabled]="isDisabled('person.inn')"
                           evolentaValidateInn>
                    <ng-container *ngIf="checkEnableQuickRequest('quickEgrip') && user.specialTypeId != 'ulApplicant'">
                        <a class="anchor text-info text-size-small"
                           *ngIf="!checkProcessingQuickRequest('quickEgrip')"
                           (click)="sendQuickRequest('quickEgrip')">
                            {{ localizations.subjects.update_data_from_egrip }}
                        </a>
                        <span class="text-size-small text-muted"
                              *ngIf="checkProcessingQuickRequest('quickEgrip')">
                            <i class="icon-spinner2 spinner"></i>
                            {{ localizations.subjects.data_receive }}
                        </span>
                        <span class="text-info text-size-small anchor ml-5"
                              routerLink="/ais/envelopes/edit/{{user['quickRequests']['quickEgrip'][0].id}}"
                              [title]="localizations.subjects.request_in_progress"
                              *ngIf="user['quickRequests'] && user['quickRequests']['quickEgrip'] && checkProcessingQuickRequest('quickEgrip')">
                            {{ user['quickRequests']['quickEgrip'][0].auid }}
                        </span>
                    </ng-container>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && inn.errors && inn.errors.required" >
                        {{ localizations.subjects.inn_field_is_required }}
                    </div>
                    <div *ngIf="inn.errors && inn.errors.inn"
                         class="validation-error-label">
                        {{ inn.errors.inn }}
                    </div>
                </div>
            </div>

            <!-- СНИЛС -->
            <div class="col-md-6 col-lg-3"
                 *ngIf="user.specialTypeId != 'ulApplicant' || fieldsRequirementsService.checkFieldRequired('person.snils', this.user)">
                <div class="form-group form-group-lg no-margin-bottom">
                    <ng-template #snilsRequestTemplate>
                        <appeal-object-request-info
                            [object]="user"
                            [requestCode]="'quickSnils'"
                        ></appeal-object-request-info>
                    </ng-template>
                    <label>
                        {{ localizations.subjects.snils }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.snils', this.user)">
                            *
                        </span>
                        <ng-container  *ngIf="appealMode">
                            <ng-container *ngIf="checkEnableQuickRequest('quickSnils')">
                                <span class="ml-5 text-size-small">
                                    <i class="fa fa-info-circle text-slate cursor-pointer"
                                       placement="right"
                                       [popover]="snilsRequestTemplate"
                                       [outsideClick]="true"></i>
                                </span>
                                <a class="anchor text-info text-size-small"
                                   *ngIf="!checkProcessingQuickRequest('quickSnils')"
                                   (click)="sendQuickRequest('quickSnils')">
                                    {{ localizations.subjects.request }}
                                </a>
                                <span class="text-size-small text-muted"
                                      *ngIf="checkProcessingQuickRequest('quickSnils')">
                                    <i class="icon-spinner2 spinner"></i>
                                    {{ localizations.subjects.data_receive }}
                                </span>
                                <span class="text-info text-size-small anchor ml-5"
                                      routerLink="/ais/envelopes/edit/{{user['quickRequests']['quickSnils'][0].id}}"
                                      [title]="localizations.subjects.request_in_progress"
                                      *ngIf="user['quickRequests'] && user['quickRequests']['quickSnils'] && checkProcessingQuickRequest('quickSnils')">
                                    {{ user['quickRequests']['quickSnils'][0].auid }}
                                </span>
                            </ng-container>
                        </ng-container>
                    </label>

                    <div [ngClass]="{'input-group input-group-xs': appealMode}">
                        <input type="text"
                               class="form-control"
                               name="snils"
                               [textMask]="{mask: globalParams.snilsMask}"
                               [required]="fieldsRequirementsService.checkFieldRequired('person.snils', this.user)"
                               [disabled]="isDisabled('person.snils')"
                               [(ngModel)]="user.data.person.snils"
                               (ngModelChange)="updateErrors('person.snils')"
                               #snils="ngModel"
                               evolentaValidateSnils>
                        <div class="display-inline-block input-group-addon no-border-left"
                             *ngIf="appealMode">
                            <div class="checkbox-block no-margin">
                                <input id="usePortalMessaging"
                                       class="checkbox"
                                       type="checkbox"
                                       [checked]="checkUseMessagingType(['portal'])"
                                       [disabled]="!user.data.person.snils || snils.errors"
                                       (change)="setMessagingVariant({code: 'portal', name: 'Портал'})">
                                <label for="usePortalMessaging"
                                       [tooltip]="localizations.subjects.notificate_through_portal">
                                    <span class="text-slate"
                                          [ngClass]="{'opacity-50': !user.data.person.snils || snils.errors}">
                                        {{ localizations.common.epgu }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && snils.errors && snils.errors.required">
                        {{ localizations.appeals.subjects.snils_field_is_required }}
                    </div>
                    <div class="validation-error-label"
                         *ngIf="snils.errors && snils.errors.snils" >
                        {{ snils.errors.snils }}
                    </div>
                </div>
            </div>

            <!-- Мобильный телефон -->
            <ng-container [ngTemplateOutlet]="mobileTemplate"
                          [ngTemplateOutletContext]="getMobileContext()">
            </ng-container>

            <!-- Адрес электронной почты -->
            <ng-container [ngTemplateOutlet]="emailTemplate"
                          [ngTemplateOutletContext]="getEmailContext()">
            </ng-container>

            <!-- Стационарный телефон -->
            <ng-container [ngTemplateOutlet]="phoneTemplate"
                          [ngTemplateOutletContext]="getPhoneContext()">
            </ng-container>
        </div>
    </div>
</form>

<ng-template #mobileTemplate
             let-field="field"
             let-mainData="mainData">
    <div class="col-md-6 col-lg-4">
        <div class="form-group form-group-lg no-margin-bottom">
            <label>
                {{ localizations.common.cell_phone }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired(field, user)">*</span>
                <span class="anchor text-info text-size-small ml-5"
                      *ngIf="!mainData.isUseOtherPhone"
                      (click)="mainData.isUseOtherPhone = true">
                    {{ localizations.appeals.subjects.add_landlandline_phone }}
                </span>
            </label>

            <input type="text"
                   name="mobile"
                   class="form-control"
                   [textMask]="{mask: globalParams.mobilePhoneMask}"
                   [required]="fieldsRequirementsService.checkFieldRequired(field, this.user)"
                   [disabled]="isDisabled(field)"
                   [(ngModel)]="mainData.mobile"
                   (ngModelChange)="updateErrors(field)"
                   #mobile="ngModel">
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && mobile.errors">
                 <span *ngIf="mobile.errors.required">
                    {{ localizations.appeals.subjects.mobile_phone_is_required }}
                 </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template let-field="field"
             let-mainData="mainData"
             #phoneTemplate>
    <div class="col-md-6 col-lg-3"
         [ngClass]="{'mt-10': user.specialTypeId !== 'ulApplicant'}"
         *ngIf="mainData.isUseOtherPhone">
        <div class="form-group form-group-lg no-margin-bottom">
            <label>
                {{ localizations.appeals.subjects.landline_phone }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired(field, user)">*</span>
                <span class="anchor text-info text-size-small ml-5"
                      *ngIf="mainData.isUseOtherPhone"
                      (click)="mainData.isUseOtherPhone = false; mainData.phone = null">
                    {{ localizations.common.delete_small }}
                </span>
            </label>
            <input type="text"
                   name="phone"
                   class="form-control"
                   [required]="fieldsRequirementsService.checkFieldRequired(field, user)"
                   [disabled]="isDisabled(field)"
                   [(ngModel)]="mainData.phone"
                   #phone="ngModel">
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && phone.errors">
                 <span *ngIf="phone.errors.required">
                    {{ localizations.appeals.subjects.landline_phone_is_required }}
                 </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #emailTemplate
             let-field="field"
             let-mainData="mainData">
    <div class="col-md-6 col-lg-3">
        <div class="form-group form-group-lg no-margin-bottom">
            <label>
                {{ localizations.appeals.subjects.email_address }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired(field, user)">*</span>
            </label>
            <input class="form-control"
                   type="text"
                   name="email"
                   [textMask]="{mask: globalParams.emailMask}"
                   [required]="fieldsRequirementsService.checkFieldRequired(field, user)"
                   [disabled]="isDisabled(field)"
                   [(ngModel)]="mainData.email"
                   (ngModelChange)="updateErrors(field)"
                   #email="ngModel">
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && email.errors">
                 <span *ngIf="email.errors.required">
                     {{ localizations.appeals.subjects.email_address_field_is_required }}
                 </span>
            </div>
        </div>
    </div>
</ng-template>

