<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button" class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            [disabled]="isEditXsd"
            (click)="save()">
        <b><i class="icon-checkmark-circle"></i></b>
        Сохранить
    </button>
    <div class="ml-20 navbar-title mt-20">
        <h4>
            <span class="text-semibold">
                {{ dictionary.description }}
                <span class="text-default" *ngIf="isEdit">: Редактирование свойств справочника</span>
                <span class="text-default" *ngIf="!isEdit">Создание справочника</span>
            </span>
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>
<div class="page-container">
    <div class="panel-main wide">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding" (onScrollEvent)="topPosition = $event;">
            <div class="p-20" *ngIf="!isEditXsd">
                <form #editForm="ngForm">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Код справочника</label>
                                <input type="text" class="form-control" name="code" [(ngModel)]="dictionary.collectionName" #code="ngModel"
                                       (change)="dictionary.name = dictionary.collectionName" required [disabled]="isEdit">
                                <div *ngIf="isProcessValidate && code.errors" class="validation-error-label">
                                    <span *ngIf="code.errors.required">Поле "Код справочника" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group form-group-lg">
                                <label>Наименование справочника</label>
                                <input type="text" class="form-control" name="name" [(ngModel)]="dictionary.description" #name="ngModel" required>
                                <div *ngIf="isProcessValidate && name.errors" class="validation-error-label">
                                    <span *ngIf="name.errors.required">Поле "Наименование справочника" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="checkbox-inline">
                            <input type="checkbox" class="checkbox" id="allowEdit" name="allowEdit" [checked]="dictionary.dictionaryParams.allowEdit"
                                   (change)="dictionary.dictionaryParams.allowEdit = !dictionary.dictionaryParams.allowEdit">
                            <label for="allowEdit">Разрешить редактирование элементов</label>
                        </div>
                        <div class="checkbox-inline ml-20">
                            <input type="checkbox" class="checkbox" id="allowDelete" name="allowDelete" [checked]="dictionary.dictionaryParams.allowDelete"
                                   (change)="dictionary.dictionaryParams.allowDelete = !dictionary.dictionaryParams.allowDelete">
                            <label for="allowDelete">Разрешить удаление элементов</label>
                        </div>
                        <div class="checkbox-inline ml-20">
                            <input type="checkbox" class="checkbox" id="isElementXsdSetting" name="isElementXsdSetting" [checked]="dictionary.dictionaryParams.isElementXsdSetting"
                                   (change)="dictionary.dictionaryParams.isElementXsdSetting = !dictionary.dictionaryParams.isElementXsdSetting">
                            <label for="isElementXsdSetting">Возможна настройка доп.полей на элементы справочника</label>
                        </div>
                        <div class="checkbox-inline ml-20">
                            <input type="checkbox" class="checkbox" id="isMultiLevelsDictionary" name="isMultiLevelsDictionary" [checked]="dictionary.dictionaryParams.isMultiLevelsDictionary"
                                   (change)="dictionary.dictionaryParams.isMultiLevelsDictionary = !dictionary.dictionaryParams.isMultiLevelsDictionary">
                            <label for="isMultiLevelsDictionary">Многоуровневый справочник</label>
                        </div>
                        <div class="checkbox-inline ml-20">
                            <input type="checkbox" class="checkbox" id="isVersioning" name="isVersioning" [checked]="dictionary.dictionaryParams.isVersioning"
                                   (change)="dictionary.dictionaryParams.isVersioning = !dictionary.dictionaryParams.isVersioning">
                            <label for="isVersioning">Версионируемый</label>
                        </div>
                    </div>
                    <div class="checkbox-block">
                        <input type="checkbox" class="checkbox" id="autoGenerateCode" name="autoGenerateCode" [checked]="dictionary.dictionaryParams.autoGenerateCode"
                               (change)="dictionary.dictionaryParams.autoGenerateCode = !dictionary.dictionaryParams.autoGenerateCode">
                        <label for="autoGenerateCode">Формировать код элемента автоматически</label>
                    </div>
                    <fieldset *ngIf="dictionary.dictionaryParams.autoGenerateCode" class="mt-20">
                        <legend class="text-bold text-size-small">Параметры автоматического формирования кода</legend>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group form-group-lg no-margin-bottom">
                                    <label>Префикс</label>
                                    <input type="text" class="form-control" name="codePrefix" [(ngModel)]="dictionary.dictionaryParams.codePrefix" maxlength="10">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group form-group-lg no-margin-bottom">
                                    <label>Длина номерной части</label>
                                    <input type="number" class="form-control" name="codeLength" [(ngModel)]="dictionary.dictionaryParams.codeLength">
                                    <span class="text-slate text-size-small mt-5">дозаполнение нулями, например, {{formatNumber()}}</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group form-group-lg no-margin-bottom">
                                    <label>Начать нумерацию с</label>
                                    <input type="number" class="form-control" name="codeStart" [(ngModel)]="dictionary.dictionaryParams.codeStart">
                                    <span class="text-slate text-size-small mt-5">при отсутствии элементов с текущим префиксом</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <p class="mt-30">
                    <span class="text-bold">Дополнительные поля для справочника {{dictionary.xsd ? 'настроены' : 'не настроены'}}</span>
                    <span class="text-info anchor text-size-small ml-10" (click)="isEditXsd = true" *ngIf="!isEditXsd">{{dictionary.xsd ? 'изменить' : 'настроить'}}</span>
                    <span class="text-danger anchor text-size-small ml-15" *ngIf="dictionary.xsd" (click)="deleteXsd()">удалить</span>
                </p>
            </div>

            <ng-container *ngIf="isEditXsd">
                <dynamic-form-constructor [xsdId]="dictionary.xsd" [scrollTopPosition]="topPosition"
                                      (onSave)="completeEditXsd()" (onCreateXsd)="afterCreateXsd($event)"></dynamic-form-constructor>
            </ng-container>
        </evolenta-scrollbar>
    </div>
</div>
