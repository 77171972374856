<!--<div class="checkbox-block bg-primary-50 pt-10 pb-10 pr-20 pl-20 no-margin-bottom">-->
    <!--<input type="checkbox" class="checkbox" id="notValidate"-->
           <!--[value]="true" [checked]="appealSubservice.notValidateAdditionalData"-->
           <!--(change)="appealSubservice.notValidateAdditionalData = !appealSubservice.notValidateAdditionalData">-->
    <!--<label for="notValidate"> Не проверять корректность заполнения дополнительных полей</label>-->
<!--</div>-->
<div class="p-20">
    <jsonform [obj]="schema" (completedForm)="complete($event, 'xsdData')" *ngIf="schema" [completeWithErrors]="true">
        <form class="jsonform" novalidate [attr.data-validation]="validateService.processValidate && !appealSubservice.notValidateAdditionalData">
            <button class="hide" #saveBtn>Сохранить</button>
        </form>
    </jsonform>

    <jsonform [obj]="variantSchema" (completedForm)="complete($event, 'variantXsdData')" *ngIf="variantSchema" [completeWithErrors]="true">
        <form class="jsonform" novalidate [attr.data-validation]="validateService.processValidate && !appealSubservice.notValidateAdditionalData">
            <button class="hide" #saveVariantBtn>Сохранить</button>
        </form>
    </jsonform>
</div>

