import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { RestService, StorageService, ToasterService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'role-mnemonic',
    templateUrl: './role-mnemonic.component.html',
    styleUrls: ['../../element-edit.css'],
})
export class RoleMnemonicComponent implements OnInit {
    public roleMnemonic: any = {};
    public currentOrganization = this.storage.getItem('currentOrganization');
    public moduleBaseUrl;
    public isLoading = false;
    public localizations;

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private restService: RestService,
        private toaster: ToasterService,
        private storage: StorageService,
        private location: Location,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.data.subscribe(
            (response: any) => {
                this.roleMnemonic = response.resolves;
                if (!this.roleMnemonic._id) {
                    this.roleMnemonic.unitId = this.moduleBaseUrl === 'admin' ? null : this.currentOrganization._id;
                }
            },
            (error) => {
                this.toaster.error(error);
            }
        );
    }

    private _loadTranslations() {
        this.translate.get(['roles', 'common']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public create() {
        this.restService
            .create('catalogueRolesMnemonics', this.roleMnemonic)
            .then((result) => {
                this.isLoading = false;
                this.toaster.success(this.localizations.roles.created);
                this.router.navigate([this.moduleBaseUrl, 'roles', 'edit', result['_id']]);
            })
            .catch((error: any) => {
                this.toaster.error(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public update() {
        this.restService
            .update('catalogueRolesMnemonics', this.roleMnemonic)
            .then(() => {
                this.toaster.success(this.localizations.roles.changed);
            })
            .catch((error) => {
                this.toaster.error(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public save() {
        this.isLoading = true;
        if (this.roleMnemonic._id) {
            this.update();
        } else {
            this.create();
        }
    }

    public back() {
        this.location.back();
    }
}
