import { Injectable } from '@angular/core';
import { RestService, StorageService, ToasterService } from '@evolenta/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';

/**
 * Сервис для (снятия / взятия) субъекта (с / на) контрол(я)(ь)
 */
@Injectable()
export class ControlActionsOnSubjectService {

    public isExistSubjectKno = true; // Существуют ли у глобального субъекта субъект КНО
    public searchString = ''; // Строка поиска в модальном окне
    public relatedObjects: any[]; // Связанные объекты с субъектом
    public currentOrganization = this.storageService.getItem('currentOrganization');

    private _moduleBaseUrl: string; // Базовый URL основного приложения
    private _relatedObjectsModal: ModalDirective; // Модальное окно
    private _subject: any; // Выбранный субъект
    private _controlActionEvent: string; // Действие выполняющиеся на данный момент add / remove

    public constructor(
        private toasterService: ToasterService,
        private restService: RestService,
        private router: Router,
        private storageService: StorageService,
    ) {
    }

    public setModuleBaseUrl(value: string): void {
        this._moduleBaseUrl = value;
    }

    public setSubject(value: any): void {
        this._subject = value;
    }

    public setRelatedObjectsModal(value: ModalDirective): void {
        this._relatedObjectsModal = value;
    }

    public setControlAction(event: string): void {
        this._controlActionEvent = cloneDeep(event);
    }

    public getControlAction(): string {
        return this._controlActionEvent;
    }

    public getExistSubjectKno(globalSubjectId?: string): Promise<any> {
        const params = [
            {
                field: 'globalSubjectId',
                operator: 'eq',
                value: globalSubjectId ? globalSubjectId : this._subject._id,
            },
            {
                field: 'unit.id',
                operator: 'eq',
                value: this.currentOrganization._id,
            },
        ];

        if (this._moduleBaseUrl === 'financial-controlling') {
            params.push({
                field: 'typeControlObject',
                operator: 'neq',
                value: null,
            });
        } else {
            params.push({
                field: 'typeControlObject',
                operator: 'eq',
                value: null,
            });
        }

        return this.restService.search('subjectsKno', {search: {search: params}, size: 1})
            .then(subjects => {
                this.isExistSubjectKno = subjects.length !== 0;

                return subjects.length > 0 ? subjects[0] : null;
            });
    }

    public async takeSubjectUnderControl(typeControlObject: object = null, parentControlObject: object = null) {
        this.toasterService.info((this._moduleBaseUrl === 'financial-controlling' ? 'Объект ' : 'Субъект ') + 'будет взят на контроль');

        const knoSubject = {
            globalSubjectId: this._subject._id,
            typeControlObject: typeControlObject,
            parentControlObject: parentControlObject,
            unit: {
                id: this.currentOrganization._id,
                name: this.currentOrganization.name,
            },
        };

        const createdSubject: any = await this.restService.create('subjectsKno', knoSubject);
        this.toasterService.success((this._moduleBaseUrl === 'financial-controlling' ? 'Объект ' : 'Субъект ') + 'взят на контроль');
        await this.router.navigate([this._moduleBaseUrl, (this._moduleBaseUrl === 'financial-controlling' ? 'objects-of-control' : 'subjects'), 'kno', 'edit', createdSubject._id]);
    }

    public removeSubjectFromControl(): void {
        Promise.all([
            this.restService.search('appeals', {
                search: {
                    search:
                        [
                            {
                                orSubConditions:
                                    [
                                        {field: 'subjects.guid', operator: 'eq', value: this._subject.guid || 'данные отсутствуют'},
                                        {field: 'subjects.reestrId', operator: 'eq', value: this._subject._id || 'данные отсутствуют'},
                                    ], // 'данные отсутствуют', т.к монга на null некорректно выдает ответ
                            },
                        ],
                }, size: 1,
            }), // size 1, т.к нужен хотя бы один использующийся субъект
            this.restService.search('events', {
                search: {search: [{field: 'subjects._id', operator: 'eq', value: this._subject._id}]}, size: 1,
            }), // size 1, т.к нужен хотя бы один использующийся субъект
            this.getRelatedObjects(),
        ]).then(responses => {
            const appeals = responses[0];
            const events = responses[1];
            const {isUsed, relatedObjects} = responses[2];

            if (appeals.length > 0 || events.length > 0) {
                this.toasterService.error(
                    (appeals.length > 0 ? '- ' + (this._moduleBaseUrl === 'financial-controlling' ? 'объект контроля ' : 'субъект ') + 'используется в процессах<br/>' : '') +
                    (events.length > 0 ? '- ' + (this._moduleBaseUrl === 'financial-controlling' ? 'объект контроля ' : 'субъект ') + 'используется в событиях' : ''));
            }

            if (isUsed && relatedObjects.length > 0) {
                this.toasterService.error(
                    'Невозможно снять ' + (this._moduleBaseUrl === 'financial-controlling' ? 'объект контроля ' : 'субъект ') + 'с контроля т.к. объект(ы) (<br/>' +
                    relatedObjects.map(object => '- ' + object.name).join(',<br/>') +
                    '<br/>) используются в процессах или событиях',
                );
            }

            // Если объекты связаны, но не используются, открываем окно, чтобы убрать связь.
            // Если субъект не связан и не используется ни где, открываем окно.
            if (appeals.length === 0 && events.length === 0 && (!isUsed && relatedObjects.length > 0 || relatedObjects.length === 0)) {
                this._relatedObjectsModal.show();
            }

            if (!(appeals.length === 0 && events.length === 0 && (!isUsed && relatedObjects.length > 0 || relatedObjects.length === 0))) {
                setTimeout(() => this.setControlAction(null)); // Для вызова ngOnChanges SubjectDialogForControlActionsComponent
            }
        });
    }

    public async removeEntityFromControl(only: boolean) {
        this._relatedObjectsModal.hide();
        if (only) {
            await this._removeSubjectKnoFromControl();

            return;
        }

        await this._removeRelatedObjects();
    }

    public resetSearch(): void {
        this.searchString = '';
    }

    public searchObjects(): any[] {
        return this.relatedObjects.filter(object => String(object.name).toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);
    }

    private getRelatedObjects(): Promise<any> {
        return this.restService.findAll('objectsKno', {search: {search: [{field: 'linkedSubjects', operator: 'eq', value: this._subject._id}]}, size: 500})
            .then(async response => {
                const objects = response;
                this.relatedObjects = [];
                if (objects.length > 0) {
                    let objectsUsed = [];
                    const globalObjectIds = objects.filter(object => object.globalObjectId).map(object => object.globalObjectId);

                    if (globalObjectIds.length > 0) {
                        const globalObjects = await this.restService.findAll('objects', {search: {search: [{field: '_id', operator: 'in', value: globalObjectIds}]}, size: 500});

                        cloneDeep(globalObjects).forEach(globalObject => {
                            const array = objects.filter(item => item.globalObjectId === globalObject._id);

                            array.forEach(element => Object.assign(element, omit(globalObject, ['_id'])));
                        });

                        this.relatedObjects = cloneDeep(objects);
                        objectsUsed = await this.checkRelatedObjects(objects);

                        return objectsUsed.length > 0 ? {isUsed: true, relatedObjects: objectsUsed} : {isUsed: false, relatedObjects: cloneDeep(objects)};
                    }

                    this.relatedObjects = cloneDeep(objects);
                    objectsUsed = await this.checkRelatedObjects(objects);

                    return objectsUsed.length > 0 ? {isUsed: true, relatedObjects: objectsUsed} : {isUsed: false, relatedObjects: cloneDeep(objects)};
                }

                return {isUsed: false, relatedObjects: []};
            });
    }

    private checkRelatedObjects(objects: any[]): Promise<any> {
        const ids = objects.map(object => object._id);
        const guides = objects.map(object => object.guid);

        return Promise.all([
            this.restService.findAll('appeals', {
                search: {
                    search:
                        [
                            {
                                orSubConditions:
                                    [
                                        {field: 'objects.guid', operator: 'in', value: guides},
                                        {field: 'objects.reestrId', operator: 'in', value: ids},
                                    ],
                            },
                        ],
                }, size: 500,
            }),
            this.restService.findAll('events', {search: {search: [{field: 'objects._id', operator: 'in', value: ids}]}, size: 500}),
        ]).then(responses => [...responses[0], ...responses[1]].reduce((previousValue, currentValue) => {
            const array = [];

            // Убираются одинаковые объекты по полям (reestrId, _id)
            currentValue.objects.forEach(item => {
                if (!previousValue.some(element => {
                    if (element.reestrId && item.reestrId && element.reestrId === item.reestrId) {
                        return true;
                    } else {
                        return !item.reestrId && element._id && item._id && element._id === item._id;
                    }
                })) {
                    array.push(item);
                }
            });

            return [...previousValue, ...array];
        }, []));
    }

    private async _removeRelatedObjects() {
        const promises = [];

        this.relatedObjects.forEach(item => promises.push(this.restService.remove('objectsKno', {_id: item._id})));

        try {
            await Promise.all(promises);
            await this._removeSubjectKnoFromControl();
            this.toasterService.success('Все объекты сняты с контроля');
        } catch (error) {
            this.toasterService.error(error, 'Ошибка при снятии объекта с контроля');
        }
    }

    private async _removeSubjectKnoFromControl() {
        try {
            await this.restService.remove('subjectsKno', {_id: this._subject._id});
            this.toasterService.success((this._moduleBaseUrl === 'financial-controlling' ? 'Объект контроля ' : 'Субъект ') + 'снят с контроля');
            await this.router.navigate([this._moduleBaseUrl, (this._moduleBaseUrl === 'financial-controlling' ? 'objects-of-control' : 'subjects'), 'global']);
        } catch (error) {
            this.toasterService.error(error,
                'Ошибка при снятии ' + (this._moduleBaseUrl === 'financial-controlling' ? 'объекта контроля ' : 'субъекта ') + 'с контроля');
        }
    }
}
