<div *ngIf="!editedVariant">
    <ng-container *ngFor="let item of list; let idx = index">
        <div class="tree-line-drop-place tree-line-drop-place-first"
             dndDropzone
             *ngIf="!idx"
             [ngStyle]="{'margin-left': 2 * (level * 30 + 10) + 'px'}"
             (dndDrop)="onDrop($event, -1, list, rootItem)">
        </div>
        <div class="tree-line"
             dndType="element"
             [dndDraggable]="true"
             (dndStart)="onDragged(idx, item, list, level)"
             (dndCanceled)="onDragCanceled()"
             (dndEnd)="onDragEnd()"
             dndEffectAllowed="copy"
             [ngStyle]="{'padding-left': (level * 30 + 10) + 'px'}">
            <div class="tree-item-toggle-sign">
                    <span class="cursor-pointer"
                          *ngIf="item.children && item.children.length > 0"
                          (click)="item.hide = !item.hide">
                        <i class="icon-plus2"
                           *ngIf="item.hide">
                        </i>
                        <i class="icon-minus2"
                           *ngIf="!item.hide">
                        </i>
                    </span>
            </div>
            <div class="tree-item-title">
                <span>
                    {{item.name}}
                </span>
            </div>
            <div class="tree-item-buttons text-right pr-10">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.appeals.add_variant"
                        *ngIf="activeData.allowEdit"
                        (click)="add(item)">
                    <i class="icon-plus2"></i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="activeData.allowEdit ? localizations.common.edit : localizations.common.view"
                        (click)="edit(item, list)">
                    <i [ngClass]="{
                        'icon-pencil': activeData.allowEdit,
                        'icon-eye': !activeData.allowEdit
                        }"></i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.common.delete"
                        *ngIf="activeData.allowEdit"
                        (click)="delete(item, list)">
                    <i class="icon-trash"></i>
                </button>
            </div>
        </div>
        <div *ngIf="item.children && item.children.length > 0 && !item.hide">
            <common-standard-variant
                [rootItem]="item"
                [list]="item.children"
                [level]="level+1"
                [xsdData]="xsdData"
                [allowEdit]="allowEdit"
                [parentToggled]="parentToggled || (toggled && idx === standardsService.draggableIndex)"
                (editVariantClicked)="editedVariantClicked($event)"
                (dragStarted)="childDragStarted($event)">
            </common-standard-variant>
        </div>
        <div *ngIf="!item.children || !item.children.length">
            <div class="tree-line-drop-place tree-line-drop-place-addition"
                 dndDropzone
                 [ngStyle]="{'margin-left': ((level + 1) * 30 + 10) + 'px'}"
                 (dndDrop)="onDrop($event, 0, null, item)">
            </div>
        </div>
        <div class="tree-line-drop-place"
             dndDropzone
             [ngStyle]="{'margin-left': (level * 30 + 10) + 'px'}"
             (dndDrop)="onDrop($event, idx, list, rootItem, 1)">
        </div>
    </ng-container>
</div>
