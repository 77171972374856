<div class="scroll-panels">
    <div class="scroll-panel"
         style="width: 100%">
        <div class="panel panel-flat">
            <evolenta-infinite-scrollbar scrollClass="panel-body"
                                         (scrolled)="loadData()">
                <ul class="list-feed mt-20 mb-20 mr-20 ml-30">
                    <li class="version-block">
                        <div>
                            <ng-container *ngIf="!subject.userLastModification && (subject.dateLastModification || !subject.dateLastModification)">
                                <span class="text-muted">
                                    {{ localizations.subjects.automatically_created_record }}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="subject.userLastModification && subject.dateLastModification">
                                <span class="text-muted">
                                    {{ subject.dateLastModification | date: 'shortTime' }},
                                    {{ subject.dateLastModification | date: 'shortDate' }}
                                </span>
                                <span class="text-primary ml-5">
                                    {{ subject.userLastModification?.name }}
                                </span>
                            </ng-container>
                            <label class="badge text-uppercase bg-primary-300 ml-15"
                                   style="margin-bottom: 0">
                                {{ localizations.objects.actual_version }}
                            </label>
                            <span class="anchor text-info ml-15 go-to-version-button text-size-small"
                                  *ngIf="subject.version"
                                  (click)="goToCurrentVersion()">
                                {{ localizations.common.view_small }}
                            </span>
                        </div>
                    </li>
                    <li class="version-block"
                        *ngFor="let version of versions">
                        <div>
                            <span class="text-muted">
                                {{ version.dateLastModification | date: 'shortTime' }},
                                {{ version.dateLastModification | date: 'shortDate' }}
                            </span>
                            <span class="text-primary ml-5">
                                {{ version.userLastModification?.name }}
                            </span>
                            <label class="badge text-uppercase bg-orange-300 ml-15"
                                   style="margin-bottom: 0"
                                   *ngIf="subject.version && subject.version === version.version">
                                {{ localizations.objects.viewed_version }}
                            </label>
                            <span class="anchor text-info ml-15 go-to-version-button text-size-small"
                                  *ngIf="!subject.version || subject.version !== version.version"
                                  (click)="goToVersion(version)">
                                {{ localizations.common.view_small }}
                            </span>
                        </div>
                    </li>
                </ul>
            </evolenta-infinite-scrollbar>
        </div>
    </div>
</div>
