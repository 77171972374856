import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntityBuyAct } from './models/appeal-entity-buy-act';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { SelectService } from '@evolenta/core';

@Component({
    selector: 'appeal-entity-buy-act',
    templateUrl: 'appeal-entity-buy-act.component.html',
    styles: [
        '.input-group-spr { position: relative; font-size: 0; white-space: nowrap; }',
        '.input-group-spr > span { display: inline-block; font-weight: normal; white-space: nowrap; padding: 8px 8px; font-size: 13px; }',
    ],
})
export class AppealEntityBuyActComponent implements OnInit {
    @Input() public entity: AppealEntityBuyAct;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.acquainted) {
            this.entity.acquainted = false;
        }
        if (!this.entity.getCopy) {
            this.entity.getCopy = false;
        }
        if (!this.entity.getAct) {
            this.entity.getAct = false;
        }

        if (!this.entity.inspector) {
            this.entity.inspector = this.getCreationUser();
        }

        if (!this.entity.inspectorFailure) {
            this.entity.inspectorFailure = this.getCreationUser();
        }

        if (!this.entity.inspectorGetAct) {
            this.entity.inspectorGetAct = this.getCreationUser();
        }
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    /**
     * Ознакомление с приказом (распоряжением)
     * @param value
     */
    public changeAcquainted(value) {
        this.entity.acquainted = value;
    }

    /**
     * Получение копии приказа (распоряжения)
     * @param value
     */
    public changeGetCopy(value) {
        this.entity.getCopy = value;
    }

    /**
     * Получение копии приказа (распоряжения)
     * @param value
     */
    public changeGetAct(value) {
        this.entity.getAct = value;
    }

    /**
     * Автор КНМ
     */
    public getCreationUser() {
        return {
            guid: CommonUtilities.GenerateGuid(),
            name: [
                {
                    id: this.appeal.userCreation.id,
                    text: this.appeal.userCreation.name,
                },
            ],
            position: null,
        };
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
