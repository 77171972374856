<div *ngIf="subservice.variants && subservice.variants.length > 0" [ngClass]="{'m-20': !noMargin}">
    <p *ngIf="!isEditVariant && appealSubservice.variant">
        <span class="text-bold mr-5">Вариант услуги:</span>
        <ng-container *ngFor="let variant of appealSubservice.variant.selectedVariants; let lst = last">{{variant.name}} <ng-container *ngIf="!lst"> / </ng-container></ng-container>
        <span class="anchor text-info text-size-small ml-10" (click)="isEditVariant = !isEditVariant">изменить</span>
    </p>
    <div *ngIf="isEditVariant">
        <p class="text-bold">Выберите вариант услуги: </p>
        <div class="alert alert-danger no-border ml-20 mr-20" *ngIf="checkErrorExist()"><span class="text-semibold">Внимание!</span> Не выбран вариант услуги</div>
        <tree-select [treeArray]="selectedVariants" [elements]="serviceVariants"
                     [additionalFields]="['code', 'xsd', 'mnemonic', 'rguProcedureId', 'rguProcedureName', 'rguTargetId', 'rguTargetName']"
                     (onEnd)="onEndSelectVariant()"></tree-select>
    </div>

</div>


