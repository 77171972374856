import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonAppealDocumentService } from '../../common-appeal-document.service';
import { EnvelopesService } from '../../../../../../common/services/envelopes.service';
import { Permission } from '../../../../../../common/services/permission';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { AccessService, TranslateService } from '@evolenta/core';
import { CommonAppealDocumentEnvelopeComponent } from '../document-envelope/common-appeal-document-envelope.component';

@Component({
    selector: 'common-appeal-document-common',
    templateUrl: 'common-appeal-document-common.component.html',
})
export class CommonAppealDocumentCommonComponent implements OnInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документадокумент дела
    @Input() public appeal; // обрабатываемое дело
    @Input() public task; //

    @Output() public afterSendEnvelope = new EventEmitter<object>();
    @Output() public afterSaveEnvelope = new EventEmitter<object>();

    public data; // описательный объект для документа
    public isProcessSelectOrganization = false;

    public permissions = Permission;
    public localizations;

    @ViewChild('documentEnvelope', { static: false }) public documentEnvelopeComponent: CommonAppealDocumentEnvelopeComponent;

    public constructor(
        public validateService: CommonAppealValidateService,
        public accessService: AccessService,
        private documentService: CommonAppealDocumentService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.data = this.documentService.data[this.document.guid];
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'subjects',
                'appeals.documents',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    /**
     * КНМ необходимости выбора участника для документа
     * @returns {boolean | any}
     */
    public get visibleParticipantsBlock() {
        if (this.document.requestId) {
            return this.data.envelope && this.data.envelope.request && !this.data.envelope.request.disableParticipants && (this.data.allowEdit || this.data.allowPartiallyEdit);
        } else {
            return this.group && (this.group.appealRequirements && this.group.appealRequirements.byObjects || !this.group.guid) && this.data.allowEdit
                   && !this.data.link && !this.data.resultSubserviceLink;
        }
    }

    /**
     * КНМ необходимости вывода блока с добавлением документов дела
     */
    public get visibleDocumentsBlock() {
        return this.data.envelope && this.data.envelope.request && !this.data.envelope.request.disableOutputDocs;
    }

    /**
     * Получение параметров для документа-запроса
     * @param property - возвращаемое значение свойства
     * @returns {string}
     */
    public getEnvelopeStatusProperty(property) {
        const envelopeStatus = this.data.envelope.status ? this.data.envelope.status : 'created';

        return EnvelopesService.GetStatusProperty(envelopeStatus, property);
    }

    public async processSendEnvelope() {
        // Вызов метода отправки запроса из соответствующего компонента для документа
        await this.documentEnvelopeComponent.processSendEnvelope();
    }

    public saveEnvelope() {
        this.documentEnvelopeComponent.saveEnvelope();
    }

    public selectOrganization(org) {
        throw new Error('Method not implemented.');
    }

    /**
     * Сохранение XSD-данных запроса в документ и приме
     */
    public onAfterSaveEnvelope() {
        this.afterSaveEnvelope.emit(this.document);
    }

    public onAfterSendEnvelope() {
        this.afterSendEnvelope.emit(this.document);
    }
}
