import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService, RestService, StorageService, ToasterService, TranslateService, UserMessagesService } from '@evolenta/core';
import { DateUtilities } from '@evolenta/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TasksService } from '../../tasks.service';
import { Config } from '../../../../../common/services/config';
import * as moment from 'moment';
import chain from 'lodash-es/chain';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';

@Component({
    selector: 'task-form',
    templateUrl: 'task-form.component.html',
    styles: [
        '.priority-label { width: 80px; }',
        '.date-block { width: 250px; }',
    ],
})
export class TaskFormComponent implements OnInit {
    @Input() public task;
    @Input() public appeal;
    @Input() public usersForSelect;
    @Input() public candidateGroups;
    @Input() public camundaTask;
    @Input() public mainEntity;

    public isEditPriority = false;
    public isEditName = false;
    public isEditDueDate = false;
    public isEditFollowUpDate = false;
    public isEditUsers = false;
    public dueDate;
    public isEditComment = false;

    public followUp;
    public comment;
    public catalogueUser;

    public assignedUser;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public currentUser = this.storage.getItem('user');

    public basePath;
    public moduleBaseUrl;
    public parentTask = null;
    public camundaBusinessInfoId = null;

    public newComment;
    public modalRef: BsModalRef;

    public allowEdit = true;

    public isEditWorkTime = false;
    public newWorkTime = {period: null, type: 'hours'};
    public localizations;

    @Output() public onChange = new EventEmitter<any>();
    @ViewChild('userInfoModal', { static: false }) public userInfoModal: TemplateRef<any>; // модальное окно для печати html-формы

    public entityParams;

    public constructor(
        public taskService: TasksService,
        public httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService,
        private rest: RestService,
        private storage: StorageService,
        private userMessagesService: UserMessagesService,
        private modalService: BsModalService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe(parentUrl => {
            this.basePath = parentUrl[0].path;
        });

        if (this.task.parentTaskId) {
            this.loadParentTask(this.task.parentTaskId).then(result => {
                    this.parentTask = cloneDeep(result);
                },
            );
        }

        this.initEntityParams();
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'tasks',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getCanAssignToGroupOnlyMessage(): string {
        const template = this.localizations.tasks.can_assign_to_group_only;
        const group = this.localizations.common.one_group;
        const groups = this.localizations.common.many_groups;

        return this.candidateGroups.length === 1 ? template.replace('%s', group) : template.replace('%s', groups);
    }

    public initEntityParams() {
        if (this.camundaTask && this.camundaTask.mainId) {
            this.entityParams = {name: '', urlParts: []};
            switch (this.camundaTask.collectionName) {
                case 'inspectionProgramGsn':
                    this.entityParams.name = 'Программа ГСН';
                    this.entityParams.shortName = 'программе ГСН';
                    this.entityParams.urlParts = ['/', 'plans', 'inspection-program-gsn', 'edit', this.camundaTask.mainId];
                    this.entityParams.entityName = this.mainEntity ? this.mainEntity.name : '';
                    break;
                case 'appeals.subservices':
                    this.entityParams.name = 'Дело';
                    this.entityParams.shortName = 'деле';
                    this.entityParams.urlParts = ['/', this.moduleBaseUrl, 'appeals', 'edit', this.camundaTask.mainId];
                    this.entityParams.entityName = this.mainEntity && this.mainEntity.subservices ? this.mainEntity.subservices[0].title : '';
                    break;
                case 'inspectionPlans':
                    this.entityParams.name = 'План проверки';
                    this.entityParams.shortName = 'плане проверки';
                    this.entityParams.urlParts = ['/', 'plans', 'result-plans', 'edit', this.camundaTask.mainId];
                    this.entityParams.entityName = this.mainEntity ? this.mainEntity.name : '';
                    break;
                case 'subjectsKno':
                    this.entityParams.name = 'Субъект КНО';
                    this.entityParams.shortName = 'субъекте КНО';
                    this.entityParams.urlParts = ['/', 'registers', 'subjects', 'kno', this.camundaTask.mainId];
                    break;
                case 'objectsKno':
                    this.entityParams.name = 'Объект КНО';
                    this.entityParams.shortName = 'объекте КНО';
                    this.entityParams.urlParts = ['/', 'registers', 'objects', 'kno', this.camundaTask.mainId];
                    break;
            }
        }
    }

    public checkAllowEdit() {
        return !this.task._id || this.task.status === 'ACTIVE';
    }

    public loadParentTask(id) {
        const params = { search: {search: [{field: 'tasks.id', operator: 'eq', value: id}]}};

        return this.rest.search('camundaBusinessInfo', params).then(
            (tasks: any) => {
                this.camundaBusinessInfoId = tasks[0]._id;

                return  chain(tasks)
                    .map(item => item.tasks)
                    .flatten()
                    .find(item => item.id === id)
                    .value();
            },
        );
    }

    public assigneeUser(user) {
        this.assignedUser = user;
        this.task.assignee = user.login;
        if (this.task.id) {
            this.taskService.setTaskAssignee(this.task.id, user.login).then(() => {
                this.toaster.success('Задача успешно назначена на пользователя: ' + user.name);
                const message = '';
                this.sendMessageToTaskAssigneeUser(message);
                // Получение обновленного значения числа задач, назначенных на инспектора
                // setTimeout(() => {
                //     this.httpService.post(Config.server + Config.api + 'business/getAssignedCount', {login: user.login}).then((data) => {
                //         user.tasksCount = data[user.login];
                //         const findIndex = this.usersForSelect.findIndex(item => item.login === user.login);
                //         this.usersForSelect[findIndex].tasksCount = data[user.login]
                //     });
                // }, 1000);
                this.onChange.emit(true);
            });
        }
        this.isEditUsers = false;
    }

    public getTaskType(isEditProperty = false) {
        if (this.camundaTask && this.camundaTask.mainId) {
            switch (this.camundaTask.collectionName) {
                case 'inspectionPlans':
                    return  isEditProperty ? 'changePlanTask' : 'newPlanTask';
                case 'appeals.subservices':
                    return  isEditProperty ? 'changeProcessTask' : 'newProcessTask';
            }
        } else {
            return isEditProperty ? 'changeCustomTask' : 'newCustomTask';
        }
    }

    public changePriority(status) {
        const sendMessage = 'изменен приоритет на ' + this.taskService.getStatusByProperty(status.priority, 'name');
        this.task.priority = status.priority;
        if (this.task.id) {
            this.sendMessageToTaskAssigneeUser(sendMessage, true);
        }
        this.onChange.emit(true);
        this.isEditPriority = false;
    }

    public editDueDate() {
        if (this.task.due) {
            this.dueDate = DateUtilities.CreateDateObjectFromString(this.task.due);
        }
        this.isEditDueDate = true;
    }

    public editFollowUpDate() {
        if (this.task.followUp) {
            this.followUp = DateUtilities.CreateDateObjectFromString(this.task.followUp);
        }
        this.isEditFollowUpDate = true;
    }

    public changeDueDate() {
        if (this.task.id) {
            const sendMessage = 'изменена дата завершения исполнения на ' + moment(this.task.due).format('DD.MM.YYYY');
            this.sendMessageToTaskAssigneeUser(sendMessage, true);
        }
        this.isEditDueDate = false;
        this.onChange.emit(true);
    }

    public changeFollowUpDate() {
        if (this.task.id) {
            const sendMessage = 'изменена дата начала исполнения на ' +  moment(this.task.followUp).format('DD.MM.YYYY');
            // this.sendMessageToTaskAssigneeUser(message, true);
            this.sendMessageToTaskAssigneeUser(sendMessage, true);
        }
        this.isEditFollowUpDate = false;
        this.onChange.emit(true);
    }

    public selectUser(user) {
        if (!this.task.users) {
            this.task.users = [];
        }
        const find = this.task.users.find(item => item.id === user._id);
        if (!find) {
            this.task.users.push({
                id: user._id,
                login: user.login,
                name: user.name,
            });
            this.catalogueUser = null;
        } else {
            this.toaster.info('Пользователь ' + user.name + ' уже выбран');
            this.catalogueUser = null;
        }
    }

    public editComment() {
        this.comment = this.task.comment;
        this.isEditComment = true;
    }

    public saveComment() {
        if (this.newComment) {
            if (!this.task.comments) {
                this.task.comments = [];
            }
            this.task.comments.push({
                comment: this.newComment,
                date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                user: {id: this.currentUser._id, name: this.currentUser.name, login: this.currentUser.login},
            });
            if (this.task.id) {
                // Если задача назначена на пользователя не совпадающего с авторизованным, пишем сообщение
                const sendMessage = 'добавлен новый комментарий';
                this.sendMessageToTaskAssigneeUser(sendMessage, true);
            }
            this.isEditComment = false;
            this.onChange.emit(true);
        } else {
            this.toaster.error('Не введен текст комментария');
        }
    }

    public getAssigneeName() {
        const item = this.usersForSelect
            ? this.usersForSelect.find(v => v.login === this.task.assignee)
            : {};

        return item ? item.name : '-';
    }

    public getAssignee() {
        return this.usersForSelect
            ? this.usersForSelect.find(v => v.login === this.task.assignee)
            : null ;
    }

    public async showParent() {
        await this.router.navigate([this.moduleBaseUrl, this.basePath, 'edit', this.camundaBusinessInfoId, this.task.parentTaskId]);
    }

    public addComment() {
        this.newComment = '';
        this.isEditComment = true;
    }

    public createMessage(message, taskKind, user) {
        this.userMessagesService.createMessage(message, 'task', taskKind, user, ['tasks', 'edit', this.camundaTask._id, this.task.id], true);
    }

    public sendMessage(taskType, user, message) {
        const params: any = [
            {
                name: 'userLogin',
                value: user.login,
            },
            {
                name: 'message',
                value: message,
            },
            {
                name: 'camundaTaskId',
                value: this.camundaTask._id,
            },
            {
                name: 'taskId',
                value: this.task.id,
            },
            {
                name: 'taskName',
                value: this.task.name,
            },
        ];
        if (this.mainEntity && (taskType === 'newProcessTask' || taskType === 'changeProcessTask')) {
            params.push({
                name: 'appealNumber',
                value: this.mainEntity.number,
            });
            params.push({
                name: 'appealSubservice',
                value: this.mainEntity.subservices[0].shortTitle,
            });
        }
        this.userMessagesService.sendMessage(taskType, 'sendToUser', params);
    }

    public sendMessageToTaskAssigneeUser(message, isChangeTask = false) {
        this.assignedUser = this.getAssignee();
        if (this.task.assignee && this.assignedUser && this.task.assignee !== this.currentUser.login) {
            this.sendMessage(this.getTaskType(isChangeTask), this.assignedUser, message);
        }
    }

    public showUserInfo() {
        this.modalRef = this.modalService.show(this.userInfoModal, {backdrop: 'static'});
    }

    public addWorkTime() {
        this.newWorkTime = {period: null, type: 'hours'};
        this.isEditWorkTime = true;
    }

    public saveWorkTime() {
        if (this.newWorkTime.period) {
            if (!this.task.workTimes) {
                this.task.workTimes = [];
            }
            this.task.workTimes.push({
                period: this.newWorkTime.period,
                periodType: this.newWorkTime.type,
                date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                user: {id: this.currentUser._id, name: this.currentUser.name, login: this.currentUser.login},
            });
            this.isEditWorkTime = false;
            this.onChange.emit(true);
        } else {
            this.toaster.error('Не введена длительность');
        }
        this.isEditWorkTime = false;
    }

    public calculateTotalWorkTime() {
        let count = 0;

        if (this.task.workTimes) {
            this.task.workTimes.forEach(time => {
                if (time.periodType === 'hours') {
                    count += (parseInt(time.period, 10) * 60);
                } else {
                    count += parseInt(time.period, 10);
                }
            });
            const minutes = count % 60;
            const hours = (count - count % 60) / 60;

            return hours + ' ч. ' + minutes + ' мин.';
        }

        return '0 ч. 0 мин.';
    }
}
