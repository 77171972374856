import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AccessService, ModalDialogComponent, ToasterService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealValidateService } from '../../../appeal-validate.service';
import { AppealSaveService } from '../../../appeal-save.service';
import { AppealSubservicesService } from '../../../appeal-subservices.service';
import { AppealStatusService } from '../../../appeal-status.service';
import { Permission } from '../../../../../../common/services/permission';
import { DocumentService } from '../../documents/document.service';
import { AppealSubjectCardComponent } from '../appeal-subject-card/appeal-subject-card.component';
import * as _ from 'lodash';
import { IndividualObjectData } from '../../../../../subjects/individual-object/components/individual-applicant-form/common/individual-object.data';

@Component({
    selector: 'appeal-subjects-create',
    templateUrl: 'appeal-subjects-create.component.html',
})
export class AppealSubjectsCreateComponent implements OnInit {
    @Input() public appeal: any; // Текущее дело
    @Input() public subservice; // Услуги, на основании которых формируется дело

    @Output() public onAfterSave = new EventEmitter<string>(); // Передача возможности перехода на другую вкладку после КНМ необходимости сохранения данных
    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)

    public activeTab; // активная вкладка при редактировании пользователя
    public nextTab; // Следующая вкладка, на которую будет осуществлен переход в родительском компоненте после КНМ сохранения измененных данных

    public availableSubjectKinds = []; // Досупные для выбора виды субъектов
    public editedSubject = null; // Субъект, который находится в данный момент в статусе редактирования

    public modalOperation; // Функция компонента, осуществляющая обработку результатов отработки модального окна

    @ViewChild('applyModal', {static: false}) public modalDialogComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', {static: false}) public editSubjectCardComponent: AppealSubjectCardComponent; // Компонент - карточка редактирования субъекта дела

    public allowEdit = true; // Возможность редактирования субъектов дела

    public permissions = Permission; // Набор прав системы
    public localizations;

    public constructor(
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        private toaster: ToasterService,
        private appealSubservicesService: AppealSubservicesService,
        private validateService: AppealValidateService,
        private saveService: AppealSaveService,
        private documentService: DocumentService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        // Запрещено редактирование, если дело находится в финальном статусе, или зарегистрировано
        this.allowEdit = this.appealStatusService.allowEdit && this.appeal.status.code !== 'process';
        // формирование массива уникальных видов/типов субъетов из услуг, по которым формируется дело
        this.getUniqueSubjectsKinds();
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.subjects',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Добавление нового субъекта в дело
     */
    public addSubject() {
        const subjectGuid = CommonUtilities.GenerateGuid();
        const subject = {
            guid: subjectGuid,
            kind: null,
            data: {},
            specialTypeId: null,
        };
        if (this.availableSubjectKinds.length === 1) {
            subject.kind = Object.assign({}, this.availableSubjectKinds[0]);
            if (subject.kind.subKinds.length === 1) {
                subject.kind.subKind = Object.assign({}, subject.kind.subKinds[0]);
                subject.specialTypeId = subject.kind.subKind.specialTypeId;
                if (subject.specialTypeId === 'ulApplicant') {
                    subject.data = {organization: {}};
                } else {
                    subject.data = {person: {}};
                }
                this.activeTab = 'subservice';
            } else {
                subject.kind.subKind = null;
                subject.specialTypeId = null;
            }
        }

        // Добавление информации о субъекте в служебный объект
        this.appealSubservicesService.subjectsData[subjectGuid] = _.cloneDeep(subject);

        // Инициализация блока данных объекта в каждой услуге для работы внутри компонентов
        Object.keys(this.appealSubservicesService.data).forEach(key => {
            this.appealSubservicesService.data[key].subjects[subjectGuid] = {active: false, objects: []};
            // Инициализация групп документов при добавлении пользователя
            if (subject.kind && this.appeal.subservice.guid === key) {
                this.appealSubservicesService.generateServiceGroups(this.appeal.subservice, subject);
            }
        });
        this.validateService.initValidateElement('subjects', subjectGuid);

        // Переход в режим редактирования добавляемого субъекта
        this.onEditSubject(subject);
    }

    /**
     * Переход в режим редактирования субъекта
     * @param subject
     */
    public activateEditMode(subject) {
        // Сохраняем субъект в переменную редактирования
        setTimeout(() => {this.editedSubject = subject; }, 100);
    }

    /**
     * Формирование массива уникальных видов субъектов из данных услуг
     */
    public getUniqueSubjectsKinds() {
        let isExistPrincipal = false;  // флаг наличия в услуге доверителя
        let isExistIndividualApplicant = false; // флаг наличия ФЛ в составе видов участников
        let isExistUlApplicant = false; // флаг наличия ЮЛ в составе участников
        if (this.subservice.objects) {
            this.subservice.objects.objectKinds.forEach(kind => {
                if (kind.type === 'participant') {
                    const findIndex = this.availableSubjectKinds.findIndex(item => item.guid === kind.guid);
                    let editKind;
                    if (findIndex === -1) {
                        editKind = {
                            guid: kind.guid,
                            type: kind.type,
                            name: kind.name,
                            description: kind.description,
                            subKinds: [],
                        };
                    } else {
                        editKind = this.availableSubjectKinds[findIndex];
                    }
                    kind.subKinds.forEach(subKind => {
                        if (subKind.type.indexOf('principal') !== -1) {
                            isExistPrincipal = true; // В настройке услуги есть доверители
                        }

                        // Обрабатываем только не представителей
                        if (subKind.type.indexOf('agent') === -1) {
                            if (subKind.specialTypeId === 'ulApplicant') {
                                isExistUlApplicant = true;
                            }
                            if (subKind.specialTypeId === 'individualApplicant') {
                                isExistIndividualApplicant = true;
                            }
                            const findSubKind = editKind.subKinds.find(item => item.name === subKind.secondGroupName);
                            if (!findSubKind) {
                                editKind.subKinds.push({
                                    name: subKind.secondGroupName,
                                    specialTypeId: subKind.specialTypeId,
                                    headerOptions: subKind.headerOptions,
                                    shortHeaderOptions: subKind.shortHeaderOptions,
                                    type: subKind.specialTypeId === 'realty' ? subKind.type : null,
                                });
                            }
                        }
                    });
                    if (findIndex === -1) {
                        this.availableSubjectKinds.push(editKind);
                    } else {
                        this.availableSubjectKinds[findIndex] = editKind;
                    }
                }
            });
        } else {
            // Добавляем ЮЛ
            this.availableSubjectKinds.push({
                name: 'Юридическое лицо',
                specialTypeId: 'ulApplicant',
                headerOptions: ['f|data.organization.shortName', 's|, ОГРН: ', 'f|data.organization.ogrn'],
                shortHeaderOptions: ['f|data.organization.shortName'],
                type: 'applicantUl',
            });
            // Добавляем ИП
            this.availableSubjectKinds.push({
                name: 'Индивидуальный предприниматель',
                specialTypeId: 'ipApplicant',
                headerOptions: ['f|data.person.lastName', 's| ', 'f|data.person.firstName', 's| ', 'f|data.person.middleName', 's|, ', 'f|data.person.birthday.formatted', 's| г.р.'],
                shortHeaderOptions: ['f|data.person.lastName', 's| ', 'f|data.person.firstName|1', 's|.', 'f|data.person.middleName|1', 's|.'],
                type: 'applicantIp',
            });
        }

        if (isExistPrincipal && (!isExistIndividualApplicant || !isExistUlApplicant)) {
            const participantIndex = this.availableSubjectKinds.findIndex(item => item.type === 'participant');
            if (!isExistIndividualApplicant) {
                this.availableSubjectKinds[participantIndex].subKinds.push({
                    name: 'Физическое лицо',
                    specialTypeId: IndividualObjectData.individualPersonCode,
                    headerOptions: IndividualObjectData.individualHeaderOptions.header,
                    shortHeaderOptions: IndividualObjectData.individualHeaderOptions.shortHeader,
                    type: null,
                    onlyForPrincipal: true,
                });
            }
            if (!isExistUlApplicant) {
                this.availableSubjectKinds[participantIndex].subKinds.push({
                    name: 'Юридическое лицо',
                    specialTypeId: 'ulApplicant',
                    headerOptions: IndividualObjectData.ulHeaderOptions.header,
                    shortHeaderOptions: IndividualObjectData.ulHeaderOptions.shortHeader,
                    type: null,
                    onlyForPrincipal: true,
                });
            }
        }
    }

    /**
     * Инициализация режима редактирования субъекта
     * @param subject
     */
    public onEditSubject(subject) {
        this.appealSubservicesService.tempData = _.cloneDeep(this.appealSubservicesService.data);
        this.appealSubservicesService.tempSubjectsData = _.cloneDeep(this.appealSubservicesService.subjectsData);
//         console.log('before edit TEST!!!', _.cloneDeep(this.appealSubservicesService.objectsData));
        this.activateEditMode(this.appealSubservicesService.subjectsData[subject.guid]);
    }

    /**
     * Применение (отмена) изменений в субъекте, переход к режиму списка субъектов
     * @param data - объект формата {subject: измененный субъект, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public onApplySubject(data) {
        if (data) {
            const subjectIndex = this.appeal.subjects.findIndex(item => item.guid === data.subject.guid);
            // Применение изменений в структуру дела в блок appeal.objects
            const subjectData = _.cloneDeep(data.subject);
            delete subjectData.fieldRequirements;
            if (subjectIndex !== -1) {
                this.appeal.subjects[subjectIndex] = _.cloneDeep(subjectData);
            } else {
                this.appeal.subjects.push(_.cloneDeep(subjectData));
            }
            // Настройка субъектов в привязке к услугам
            this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
            // Проверка добавления представителя для текущего объекта
            this.appealSubservicesService.checkExistAgentInAppeal(data.subject);
            // this.appealSubservicesService.correctSubjectDataInSubserviceOnApply();
            // Переинициализация состава документов в соответствии с произведенными настройками (возможно в процессе настройки были изменения в варианте участия объекта в услуге)
            this.documentService.reInitSubserviceData(); // обновление настроек услуг в сервисе документов
            this.documentService.correctSubserviceDocGroups(); // корректировка состава групп документов

            // Передача управления в родительский компонент для продолжения операций с делом: сохранение, переход на новый статус
            if (data.continueProcessingAppeal || this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
                if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
                    this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete = true;
                }
                this.onApply.emit(true);
            } else if (this.appeal._id) {

                this.saveService.saveAppeal().then(
                    () => {
                        this.toaster.success('Субъект успешно сохранен');
                    }, error => {
                        this.toaster.error(error);
                    },
                );
            }
        } else {
            // Отмена изменений, внесенных в режиме редактирования субъекта, копирование данных из временных объектов
            this.appealSubservicesService.data = _.cloneDeep(this.appealSubservicesService.tempData); // данные по услугам
            this.appealSubservicesService.subjectsData = _.cloneDeep(this.appealSubservicesService.tempSubjectsData); // данные по объектам

            if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
                this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete = true;
                this.onApply.emit(true);
            }

            // сохранение дела
            if (this.appeal._id) {

                this.saveService
                    .saveAppeal()
                    .then()
                    .catch(error => {
                        this.toaster.error(error);
                    });
            }
        }
        // Сброс объектов с временными данными
        this.appealSubservicesService.tempData = {};
        this.appealSubservicesService.tempSubjectsData = {};
        this.appealSubservicesService.correctSubjectsDataByAppeal();

        // ПереКНМ дела
        this.validateService.validateAppeal(this.validateService.processValidate);

        this.activeTab = null;
        this.editedSubject = null;
        if (this.nextTab) {
            this.onAfterSave.emit(this.nextTab);
        }
    }

    /**
     * Инициализация режима редактирования при клике на ссылки внутри карточки-предспросмотра объекта
     * @param data - данные в формате {object: объект для редактирования, tab: активная вкладка}
     */
    public initEdit(data) {
        this.activeTab = data.tab;
        this.onEditSubject({guid: data.subjectGuid});
        // this.editObject = _.cloneDeep(this.appeal.objects.find(item => {return item.guid === data.objectGuid}));
    }

    /**
     * Удаление субъекта из дела
     * @param subject - удаляемый субъект
     */
    public async onDeleteSubject(subject) {
        // Удаление настроек удаляемого объекта в услугах из дела

        if (this.appeal.subservice.subjects && this.appeal.subservice.subjects.length) {
            const findIndex = this.appeal.subservice.subjects.findIndex(item => item.guid === subject.guid);
            if (findIndex !== -1) {
                // Удаление настроек удаляемого объекта из услуг дела
                this.appeal.subservice.subjects.splice(findIndex, 1);
            }
            // Если удаляемый объект является представителем для другого объекта, удаляем настройки представительства
            this.appeal.subservice.subjects.forEach(sbj => {
                if (sbj.representative && sbj.representative.guid === subject.guid) {
                    sbj.representative = null;
                }
            });
        }

        // Удаление субъекта из массива объектов дела
        const subjectIndex = this.appeal.subjects.findIndex(item => item.guid === subject.guid);
        this.appeal.subjects.splice(subjectIndex, 1);

        if (!this.appeal._id) {
            await this._afterDeleteSubject();

            return;
        }

        try {
            await this.saveService.saveAppeal();
            await this._afterDeleteSubject();
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * Операции после удаления дела из дела (с сервера и состава дела)
     */
    private async _afterDeleteSubject() {
        // Корректировка данных сервисов
        await this.saveService.correctServiceDataAfterDeleteAppealEntity();
        this.toaster.success('Субъект удален из дела');
    }

    /**
     * КНМ на необходимость сохранения изменений в редактируемый в данный момент времени объект дела
     * @param nextTab - вкладка, на которую будет осуществлен переход после КНМ
     */
    public checkSaveData(nextTab) {
        this.nextTab = nextTab;
        // Если в данный момент редактируется объект
        if (this.editedSubject) {
            if (this.checkSubjectChange()) {
                this.modalOperation = 'afterCheckSaveData';
                this.modalDialogComponent.title = this.localizations['common'].confirm_changes;
                this.modalDialogComponent.message = this.localizations['appeals.subjects'].apply_changes;
                this.modalDialogComponent.showModal();
            } else {
                this.onAfterSave.emit(this.nextTab);
            }
        } else {
            this.onAfterSave.emit(this.nextTab);
        }
    }

    /**
     * КНМ на возможные изменения в объекте
     * @returns {boolean}
     */
    public checkSubjectChange() {
        const findAppealSubject = this.appeal.subjects.find(item => item.guid === this.editedSubject.guid);
        // КНМ изменений в основной сущности объекта
        let changed = !_.isEqual(this.editedSubject, findAppealSubject);
        // Если объект в основной сущености не изменился, проверяем изменения в параметрах привязки объекта к услугам дела
        if (!changed) {
            const hasChangeInAppealSubservice = this.appealSubservicesService.checkChangeEntityDataInAppealSubservice(this.appeal.subservice, this.editedSubject);
            changed = hasChangeInAppealSubservice ? hasChangeInAppealSubservice : changed;
        }

        return changed;
    }

    /**
     * Передача ответа от модального окна методу, который активен для вызова текущего модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Обработка выбранного в модальном окне решения по изменениям в объекте
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public afterCheckSaveData(data) {
        if (data === 'yes') {
            // Сохранение данных
            if (this.editSubjectCardComponent) {
                this.editSubjectCardComponent.apply();
            }
        } else if (data === 'no') {
            // Отмена внесенных изменений
            if (this.editSubjectCardComponent) {
                this.editSubjectCardComponent.cancel();
            }
        } else {
            // Отмена перехода на другую вкладку
            this.nextTab = null;
        }
    }

}
