import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AccessService, RestService, ToasterService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppealEnvelopesService } from './appeal-envelopes.service';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealStatusService } from '../../appeal-status.service';
import { Permission } from '../../../../../common/services/permission';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-envelopes',
    templateUrl: 'appeal-envelopes.component.html',
})
export class AppealEnvelopesComponent implements OnInit, OnDestroy {
    @Input() public appeal: any;
    @Input() public envelopes;
    @Input() public printForms = []; // Печатные формы дела

    public editEnvelope = null; // Конверт, который находится в данный момент в режиме редактирования

    @Output() public onChangeEnvelopes = new EventEmitter<any>(); // Реакция на изменение состава конвертов для передачи данных в родительский компонент

    public modalRef: BsModalRef;
    @ViewChild('deleteModal', { static: false }) public deleteModal: TemplateRef<any>;
    public deletedEnvelope = null;
    public permissions = Permission; // Набор прав системы

    public allowEdit = false; // доступность редактирования
    public envelopePrintForms = [];
    public leave;
    public stay;
    public allowedToLeave = new Promise((resolve, reject) => {
        this.leave = resolve;
        this.stay = reject;
    });

    public constructor(
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        private appealEnvelopesService: AppealEnvelopesService,
        private rest: RestService,
        private toaster: ToasterService,
        private envelopesService: AppealEnvelopesService,
        private saveService: AppealSaveService,
        private modalService: BsModalService,
    ) {
    }

    /**
     * Инициализация данных
     */
    public ngOnInit() {
        if (!this.envelopes && this.accessService.hasAccess([this.permissions.Appeal_Envelopes_Search])) {
            this.rest.search('appealEnvelopes', {search: {search: [{field: 'appeal.id', operator: 'eq', value: this.appeal._id}]}}).then(envelopes => {
                this.envelopes = envelopes;
                // this.onChangeEnvelopes.emit(envelopes);
                this.appealEnvelopesService.initData(this.appeal, this.envelopes);
            });
        } else {
            this.appealEnvelopesService.initData(this.appeal, this.envelopes);
        }
        // Выбираем набор печатных форм для формирования реестра документов в конверте
        this.envelopePrintForms = this.printForms.filter(item => item.formTypeCode === 'dealEnvelopeDocumentsRegister');
    }

    public async ngOnDestroy() {
        await this.allowedToLeave;
    }

    /**
     * Создание нового конверта и переход в режим редактирования
     */
    public createEnvelope() {
        this.editEnvelope = this.envelopesService.createEnvelope();
    }

    /**
     * Переход в режим редактирования отдельного конверта
     */
    public onEditEnvelope(envelope) {
        this.editEnvelope = _.cloneDeep(envelope);
    }

    /**
     * Применение изменений
     * @param data
     */
    public onApplyEnvelope(data) {
        if (data) {
            if (!this.envelopes) {
                this.envelopes = [];
            }
            const envelopeIndex = this.envelopes.findIndex(item => item.guid === data.guid);
            let beforeEnvelope = {};
            if (envelopeIndex !== -1) {
                beforeEnvelope = _.cloneDeep(this.envelopes[envelopeIndex]);
                this.envelopes[envelopeIndex] = data;
            } else {
                this.envelopes.push(data);
            }
            // Сохранение данных на сервере
            this.save(beforeEnvelope, data);
        }
        this.editEnvelope = null;
    }

    /**
     * Сохранение данных конверта на сервере
     * @param beforeEnvelope
     * @param currentEnvelope
     */
    public save(beforeEnvelope, currentEnvelope) {
        this.saveService.saveAppealItem(beforeEnvelope, currentEnvelope, 'appealEnvelopes').then(envelope => {
            if (!envelope['noChange']) {
                const findIndex = this.envelopes.findIndex(item => item.guid === envelope['guid']);
                this.envelopes[findIndex] = Object.assign(this.envelopes[findIndex], envelope);
                // this.onChangeEnvelopes.emit(this.envelopes);
                this.toaster.success('Данные успешно сохранены');
            }
        });
    }

    /**
     * Отображение модального окна для подтверждения удаления конверта из дела
     * @param envelope - удаляемый конверт
     */
    public onDeleteEnvelope(envelope) {
        this.deletedEnvelope = envelope;
        this.modalRef = this.modalService.show(this.deleteModal, {backdrop: 'static'});
    }

    /**
     * Удаление конверта из дела на сервере
     */
    public processDeleteEnvelope() {
        this.saveService.deleteAppealItem(this.deletedEnvelope, 'appealEnvelopes').then(() => {
            const findIndex = this.envelopes.findIndex(item => item.guid === this.deletedEnvelope.guid);
            this.envelopes.splice(findIndex, 1);
            // this.onChangeEnvelopes.emit(this.envelopes);
            this.modalRef.hide();
            this.toaster.success('Конверт успешно удален');
        });
    }
}
