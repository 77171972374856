<!-- Редактирование данных выбранного объекта в деле -->
<ng-container *ngIf="editedObject">
    <common-appeal-object-card [object]="editedObject"
                               [appeal]="appeal"
                               [subservice]="subservice"
                               [params]="objectParams"
                               [noMargin]="viewMode === 'oneWindow'"
                               mode="edit"
                               (onApply)="onApplyObject($event)"
                               #editCard>
    </common-appeal-object-card>
</ng-container>

<!-- Список объектов дела -->
<div *ngIf="!editedObject">
    <!-- Режим отображения в виде блоков   -->
    <fieldset *ngIf="viewMode === 'oneWindow'">
        <legend class="text-bold cursor-pointer" [ngClass]="{'no-margin-bottom': appeal.objects.length == 0}">
            {{onlyOne ? objectParams.titles['single'] : objectParams.titles['plural']}}
            <span class="badge bg-info-300 ml-10">Всего: {{appeal.objects.length}}</span>
            <a class="anchor mr-5 ml-10 text-normal" (click)="addObject()" *ngIf="appeal.objects.length > 0 && isAllowAdd">добавить</a>
            <a class="control-arrow" (click)="toggleObjects()">
                <i [ngClass]="{'icon-arrow-down32': showObjects, 'icon-arrow-left32': !showObjects}"></i>
            </a>
        </legend>
        <div *ngIf="showObjects">
            <div class="alert alert-info no-border" *ngIf="appeal.objects.length == 0 && isAllowAdd">
                {{onlyOne ? objectParams.titles['single'] : objectParams.titles['plural']}} не добавлен{{onlyOne ? '' : 'ы'}}.
                <a class="anchor mr-5" (click)="addObject()">добавить</a>
            </div>
            <div class="row" *ngIf="appeal.objects.length > perPage">
                <div class="col-md-6">
                    <evolenta-pagination [items]="usedObjects" [marginBottom]="true" [count]="perPage"
                                (onChangePage)="changeObjectPage($event)"></evolenta-pagination>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-lg no-margin-bottom">
                        <input class='form-control' type="text" [(ngModel)]="searchObjectByName" (ngModelChange)="filterObjects()"
                               placeholder="Введите часть наименования или адреса...">
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let object of usedObjects; let idx = index; ">
                <ng-container *ngIf="!objectsPagination || objectsPagination && idx >= objectsPagination.start - 1 && idx <= objectsPagination.end - 1">
                    <common-appeal-object-card [object]="object"
                                        mode="view"
                                        [params]="objectParams"
                                        [appeal]="appeal"
                                        [noMargin]="true"
                                        [subservice]="subservice"
                                        (onEdit)="onEditObject($event)"
                                        (onDelete)="onDeleteObject($event)">
                    </common-appeal-object-card>
                </ng-container>
            </ng-container>
        </div>
    </fieldset>

    <!-- Режим отображения объектов на отдельной вкладке   -->
    <ng-container *ngIf="viewMode == 'default'">
        <ng-container *ngFor="let object of appeal.objects">
            <common-appeal-object-card [object]="object"
                                mode="view"
                                [appeal]="appeal"
                                [params]="objectParams"
                                [subservice]="subservice"
                                (onEdit)="onEditObject($event)"
                                (onDelete)="onDeleteObject($event)">
            </common-appeal-object-card>
        </ng-container>

        <button class="btn btn-primary-overline btn-labeled btn-labeled-right m-20"
                (click)="addObject()"
                *ngIf="isAllowAdd">
            Добавить
            <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
        </button>

        <button class="btn btn-primary-overline ml-20 mr-20 hide"
                (click)="selectFromRegistry()"
                *ngIf="hasAllowEdit() && isAllowAdd && objectParams.allowSelectFromRegistry">
            Выбрать из реестра
        </button>

    </ng-container>
</div>

<modal-dialog [title]="modalTitle" [message]="modalMessage" (onSelect)="processingModal($event)" #alertModal></modal-dialog>
