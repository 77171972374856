<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button"
            class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            *ngIf="dictionaryData.dictionaryParams.allowEdit"
            (click)="submit()"
            [disabled]="isOldVersion">
        <b><i class="icon-checkmark-circle"></i></b>
        Сохранить
    </button>
    <div class="ml-20 navbar-title mt-20" [ngClass]="{'wide': !dictionaryData.dictionaryParams.allowEdit}">
        <h4>
            <span>
                <!-- Ссылка возврата к списку -->
                <i class="icon-arrow-left52 position-left cursor-pointer mr-10" title="Вернуться к списку услуг" (click)="back()"></i>
                {{dictionaryData.description}}
                <span class="text-default" *ngIf="element._id && dictionaryData.dictionaryParams.allowEdit">: Редактирование элемента</span>
                <span class="text-default" *ngIf="element._id && !dictionaryData.dictionaryParams.allowEdit">: Просмотр элемента</span>
                <span class="text-default" *ngIf="!element._id">: Создание элемента</span>
            </span>
        </h4>
    </div>
    <div class="navbar-menu" [ngClass]="{'wide': !dictionaryData.dictionaryParams.allowEdit}">
        <user-tile></user-tile>
    </div>
</div>
<div class="page-container">
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content">
            <div *ngFor="let tab of tabs" class="sidebar-category" (click)="activeTab = tab.code"
                 [ngClass]="{'active': activeTab == tab.code, 'cursor-pointer' : activeTab != tab.code}">
                <div class="category-title">
                    <span>{{tab.name}}</span>
                    <ul class="icons-list" *ngIf="activeTab != tab.code">
                        <li><i class="icon-arrow-right22"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-main">
        <evolenta-scrollbar *ngIf="activeTab !== 'versions'"
                           scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
            <!-- Основные данные элемента -->
            <div *ngIf="activeTab == 'common'">
                <div class="panel panel-shadow m-20" *ngIf="!isEditXsd">
                    <div class="panel-body">
                        <div *ngIf="!xsd && schema">
                            <jsonform [obj]="schema" (completedForm)="complete($event)" [completeWithErrors]="true" attr.data-validation="true">
                                <form class="jsonform">
                                    <button class="hide" #saveBtn>Сохранить</button>
                                </form>
                            </jsonform>
                        </div>
                        <ng-container *ngIf="xsd">
                            <dynamic-form [xsd]="xsd" [edit]="false" tabsMode="none" [model]="element" [saveInMainObject]="true" #additionalDataComponent></dynamic-form>
                        </ng-container>

                        <p *ngIf="dictionaryData.dictionaryParams.isElementXsdSetting">
                            <span class="text-bold">Дополнительные поля {{element.xsd ? 'настроены' : 'не настроены'}}</span>
                            <span class="text-info anchor text-size-small ml-10" (click)="isEditXsd = true" *ngIf="!isEditXsd">{{element.xsd ? 'изменить' : 'настроить'}}</span>
                            <span class="text-danger anchor text-size-small ml-15" *ngIf="element.xsd" (click)="deleteXsd()">удалить</span>
                        </p>
                    </div>
                </div>

                <ng-container *ngIf="isEditXsd">
                    <dynamic-form-constructor [xsdId]="element.xsd" [scrollTopPosition]="topPosition"
                                          (onSave)="completeEditXsd()" (onCreateXsd)="afterCreateXsd($event)"></dynamic-form-constructor>
                </ng-container>

            </div>
        </evolenta-scrollbar>
        <!-- Версии элемента -->
        <ng-container *ngIf="element._id && activeTab === 'versions'">
            <nsi-dictionary-element-versions [element]='element' [collection]="dictionary"></nsi-dictionary-element-versions>
        </ng-container>
    </div>
</div>
