import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService, SelectService, StorageService, ToasterService, FilesService, TranslateService } from '@evolenta/core';
import { PrintingService } from '@evolenta/printing';
import { ObjectUtilities } from '@evolenta/utilities';
import { ReportsService } from './reports.service';
import cloneDeep from 'lodash-es/cloneDeep';
import * as moment from 'moment';

@Component({
    selector: 'registers',
    templateUrl: 'registers.component.html',
    styleUrls: [
        '../../../../modules/elements-list.css',
    ],
})
export class RegistersComponent implements OnInit {
    private DATE_TEMPLATE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    private DATE_TEMPLATE_TIME_WITHOUT_COLON = 'YYYY-MM-DDTHH:mm:ssZZ';
    public report = {
        name: 'Реестр стандартов',
        fields: [],
        conditions: [],
        units: [],
    };

    public searchCollection = 'appeals';

    public conditionTypes; // = ReportAppealsData.conditionTypes; // варианты условий, по которым осуществляется поиск
    public reportColumns; // = ReportAppealsData.fields; // группы колонок, которые доступны для выбора

    // Вкладки
    public tabs = {
        common: true,
        fields: true,
        conditions: true,
    };

    public isAllowSelectOrganizations = true; // Есть возможность выбора организаций (есть полномочие noLimitByCurrentUnit)
    public currentOrganization = this.storage.getItem('currentOrganization');
    public allOrganizations = [this.currentOrganization];
    public organizationsForSelect = [];
    public isProcessSelectOrganization = false;

    public baseSearch: any = {};

    public isProcessingReport = false;

    public reportData;
    public isEditReportType;

    public metaReglaments = [];
    public localizations;

    public ObjectUtilities = ObjectUtilities;

    public constructor(
        public reportsService: ReportsService,
        public selectService: SelectService,
        private route: ActivatedRoute,
        private storage: StorageService,
        private toaster: ToasterService,
        private restService: RestService,
        private printingService: PrintingService,
        private filesService: FilesService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.route.data.subscribe(response => {
                console.log('response', response);
                this.metaReglaments = response.resolves;
                this.report.units.push(this.allOrganizations[0]);

                if (this.allOrganizations.length > 1) {
                    // Если есть подчиненные, то можно выбирать организации
                    this.isAllowSelectOrganizations = true;
                }

                this._initBaseSearch();
                this._initReportData();

                this.isEditReportType = true;
            });
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'reports',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    private async _initReportData() {
        if (!this.reportsService.reportsData) {
            const reportData = await this.restService.search('settings', { search: { search: [{ field: 'name', operator: 'eq', value: 'reportData' }] }});

            if (!reportData.length) {
                return;
            }

            this.reportsService.reportsData = reportData[0].value;

            if (this.reportsService.reportsData.length === 1) {
                this.selectReportType(this.reportsService.reportsData[0]);
            }
        }
    }

    public selectReportType(type) {
        this.reportData = type;
        this.searchCollection = type.collection.code;
        this.report.name = type.collection.reportName;
        this.reportColumns = type.fields;
        this.report.fields = [];
        this.report.conditions = [];
        this.conditionTypes = this._prepareConditions(type.conditionTypes);
        this._addInitDateFilter();
        this.isEditReportType = false;
    }

    private _prepareConditions(conditions) {
        const conditionsFromData = conditions.filter(item => item.fromData);
        if (conditionsFromData.length > 0) {
            conditionsFromData.forEach(condition => {
                const statuses = [];
                this.metaReglaments.forEach(metaReglament => {
                    if (metaReglament[condition.dataField]) {
                        metaReglament[condition.dataField].forEach(status => {
                            const find = statuses.find(item => item.code === status.code);
                            if (!find) {
                                statuses.push({code: status.code, name: status.name});
                            }
                        });
                    }
                });
                condition.items = statuses;
            });
        }

        return conditions;
    }

    public _addInitDateFilter() {
        const type = this.reportData.conditionTypes.find(item => item.code === 'date');
        this.report.conditions.push({
            type,
            compareType: 'in',
            isChangeConditionType: false,
            items: [
                {
                    dateType: [
                        {
                            id: 'dateCreation',
                            text: 'Дата создания',
                        },
                    ],
                    dateFrom: moment().add(-1, 'M').format(this.DATE_TEMPLATE),
                    dateTo: moment().format(this.DATE_TEMPLATE),
                },
            ],
        });
    }

    /**
     * Добавление всех организаций
     */
    public addAllUnits() {
        this.report.units = cloneDeep(this.allOrganizations);
        this._initBaseSearch();
        this.isProcessSelectOrganization = false;
    }

    /**
     * Инициализация базовых параметров поиска по организации
     */
    private _initBaseSearch() {
        const unitIds = [];
        this.report.units.forEach(item => {
            unitIds.push(item._id);
        });
        this.baseSearch = {
            field: 'units.id',
            operator: 'in',
            value: unitIds,
        };
    }

    /**
     * Удаление организации из списка выбранных
     * @param idx
     */
    public removeOrganization(idx) {
        this.report.units.splice(idx, 1);
        this._initBaseSearch();
    }

    /**
     * Добавление организации
     */
    public addUnit() {
        this.organizationsForSelect = this.allOrganizations.filter(item => !this.report.units.find(child => child.id === item.id));
        if (this.organizationsForSelect.length === 1) {
            this.report.units.push(this.organizationsForSelect[0]);
            this._initBaseSearch();
        } else {
            this.isProcessSelectOrganization = true;
        }
    }

    /**
     * Выбор организации
     * @param unit
     */
    public selectUnit(unit) {
        this.report.units.push(unit);
        this.isProcessSelectOrganization = false;
        this._initBaseSearch();
    }

    /**
     * Добавление условий
     */
    public addCondition() {
        this.report.conditions.push({
            type: null,
            compareType: 'in',
            isChangeConditionType: true,
            items: [],
        });
    }

    /**
     * Событие выбора вида условия
     * @param type
     * @param condition
     */
    public selectConditionType(type, condition) {
        condition.type = type;
        condition.isChangeConditionType = false;
        condition.items = [];
        if (type.code === 'date') {
            this.addDateItem(condition);
        }
    }

    /**
     * Добавление элемента даты
     * @param condition
     */
    public addDateItem(condition) {
        condition.items.push({
            dateType: null,
            dateFrom: null,
            dateTo: null,
        });
    }

    /**
     * Добавление выбранных значений в список
     * @param element
     * @param condition
     */
    public addConditionItem(element, condition) {
        condition.items.push(element);
    }

    /**
     * Удаление из списка
     * @param element
     * @param condition
     * @param idx
     */
    public removedConditionItem(element, condition, idx) {
        if (idx) {
            condition.items.splice(idx, 1);
        } else {
            const findIndex = condition.items.findIndex(item => item.id === element.id);
            if (findIndex !== -1) {
                condition.items.splice(findIndex, 1);
            }
        }
    }

    /**
     * Удаление условия фильтрации
     * @param idx
     */
    public removeCondition(idx) {
        this.report.conditions.splice(idx, 1);
    }

    /**
     * Добавление, удаление поля из состава полей отчета
     * @param field
     */
    public toggleField(field) {
        const findIndex = this.report.fields.findIndex(item => item.code === field.code);
        if (findIndex !== -1) {
            this.report.fields.splice(findIndex, 1);
        } else {
            this.report.fields.push(field);
        }
    }

    /**
     * Выбор элемента из справочника
     * @param data
     * @param condition
     */
    public selectDictionaryItem(data, condition) {
        condition.items.push(cloneDeep(data));
        condition.processingItem = null;
    }

    public async buildReport() {
        if (this.report.fields.length === 0) {
            this.toaster.error('Не выбраны параметры, выводимые в отчете');
        } else {
            const params = {
                collection: this.searchCollection,
                columnsInfo: {
                    reportName: this.report.name,
                    columns: this.buildColumns(),
                },
                search: {search: this._buildReportSearchConditions()},
            };
            this.isProcessingReport = true;

            try {
                const reportData = await this.printingService.renderReport(params);

                return this.filesService.downloadAndSaveFile(reportData.file._id, reportData.file.originalName);
            } catch (reportError) {
                this.toaster.error(reportError);
            } finally {
                this.isProcessingReport = false;
            }
        }
    }

    public buildColumns() {
        const columns = [];
        this.report.fields.forEach((field, idx) => {
            const addedField = cloneDeep(field);
            delete addedField.code;
            if (addedField.linkCollection) {
                addedField.type = 'link';
            }
            addedField.serialNumber = idx + 1;
            columns.push(addedField);
        });

        return columns;
    }

    public _buildReportSearchConditions() {
        const searchByUnits = {field: 'unit.id', operator: 'in', value: this.baseSearch.value};
        const search: any = [searchByUnits]; // Поиск по организациям
        this.report.conditions.forEach(condition => {
            if (condition.type.items && condition.type.code !== 'date') {
                // обработка элементов из мультиселекта
                const items = [];
                condition.items.forEach(item => {
                    items.push(item['id']);
                });
                search.push({field: condition.type.path, operator: condition.compareType, value: items});
            } else if (condition.type.code === 'date') {
                const orSubConditions = [];
                condition.items.forEach(dateItem => {
                    const andSubConditions = [];
                    if (dateItem.dateType) {
                        if (dateItem.dateFrom) {
                            andSubConditions.push({
                                field: dateItem.dateType[0].id,
                                operator: 'ge',
                                value: moment(dateItem.dateFrom).startOf('day').format(this.DATE_TEMPLATE_TIME_WITHOUT_COLON),
                            });
                        }
                        if (dateItem.dateTo) {
                            andSubConditions.push({
                                field: dateItem.dateType[0].id,
                                operator: 'le',
                                value: moment(dateItem.dateTo).endOf('day').format(this.DATE_TEMPLATE_TIME_WITHOUT_COLON),
                            });
                        }
                    }

                    if (andSubConditions.length > 1) {
                        orSubConditions.push({
                            andSubConditions,
                        });
                    } else if (andSubConditions.length === 1) {
                        orSubConditions.push(andSubConditions[0]);
                    }
                });
                if (orSubConditions.length > 1) {
                    search.push({
                        orSubConditions,
                    });
                } else if (orSubConditions.length === 1) {
                    search.push(orSubConditions[0]);
                }
            } else if (condition.type.dictionary) {

                const dictionaryItems = [];
                condition.items.forEach(item => {
                    if (condition.type.code === 'subservices') {
                        dictionaryItems.push(item[condition.type.resultCodeField]);
                    } else {
                        dictionaryItems.push(item[condition.type.codeField ? condition.type.codeField : condition.type.resultCodeField]);
                    }
                });
                if (dictionaryItems.length === 1) {
                    search.push({field: condition.type.path, operator: 'eq', value: dictionaryItems[0]});
                } else {
                    search.push({field: condition.type.path, operator: condition.compareType, value: dictionaryItems});
                }

            }
        });

        return search;
    }
}
