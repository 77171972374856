import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule } from '@angular/forms';
import { PerformanceDisciplineDataSource } from './datasources/performance-discipline-data-source.service';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { DashboardResolve } from './dashboard.resolve';

@NgModule({
    imports: [
        NgxEchartsModule,
        FormsModule,
        SharedComponentsModule,
    ],
    providers: [
        PerformanceDisciplineDataSource,
        DashboardResolve,
    ],
    declarations: [
        DashboardComponent,
    ],
})
export class DashboardModule {
}
