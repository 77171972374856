<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            <span>
                {{user.name}}
            </span>
            <div>
                <label
                  class="badge text-uppercase"
                  [ngClass]="{
                    'bg-success-300': user.active,
                    'bg-danger-300': !user.active
                    }">
                    {{user.active ?
                        localizations.common.active :
                        localizations.common.disabled
                    }}
                </label>
            </div>
        </h6>

        <div class="heading-elements">
            <a [title]="localizations.common.edit"
               *ngIf="showEditIcon()"
               routerLink="/admin/users/edit/{{user._id}}">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </a>
            <a *ngIf="showRemoveIcon()"
               [title]="localizations.common.delete"
               (click)="deleteUser()">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </a>
        </div>
    </div>

    <modal-dialog
        #deleteModal
        [title]="localizations.users.delete"
        [message]="modalMessage"
        [showNo]="false"
        (onSelect)="answerHandler($event)">
    </modal-dialog>

    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">

                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        {{ localizations.common.general_data }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.common,
                                'icon-arrow-left12': !panels.common}">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.common}">
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.users.login }}:
                            </div>
                            <div>
                                {{user.login}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="user.position">
                            <div class="text-muted">
                                {{ localizations.users.position }}:
                            </div>
                            <div>
                                {{user.position}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="user.snils">
                            <div class="text-muted">
                                {{ localizations.users.snils }}:
                            </div>
                            <div>
                                {{user.snils}}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <!-- Роли пользователя -->
                <fieldset *ngIf="userRoles.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.roles = !panels.roles">
                        {{ localizations.users.roles }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                    'icon-arrow-down12': panels.common,
                                    'icon-arrow-left12': !panels.roles
                                }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 mb-10"
                         [ngClass]="{'in' : panels.roles}">
                        <ng-container>
                            <div>
                                <ul class="list">
                                    <li *ngFor="let role of userRoles; let fst = first"
                                        [ngClass]="{'no-margin-top': fst, 'mt-5': !fst}">
                                        {{role}}
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </fieldset>

                <!-- Доступные модули -->
                <fieldset *ngIf="userApplications.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.applications = !panels.applications">
                        {{ localizations.users.available_modules }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                    'icon-arrow-down12': panels.applications,
                                    'icon-arrow-left12': !panels.applications
                                 }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 mb-10"
                         [ngClass]="{'in' : panels.applications}">
                        <ng-container>
                            <div>
                                <ul class="list">
                                    <li *ngFor="let app of userApplications; let fst = first"
                                        [ngClass]="{'no-margin-top': fst, 'mt-5': !fst}">
                                        {{app}}
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </fieldset>

                <!-- Организации -->
                <fieldset *ngIf="userOrganizations.length > 0">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.organizations = !panels.organizations">
                        {{ localizations.common.organizations }}
                        <a class="control-arrow">
                            <i [ngClass]="{
                                    'icon-arrow-down12': panels.organizations,
                                    'icon-arrow-left12': !panels.organizations
                                }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 mb-10"
                         [ngClass]="{'in' : panels.organizations}">
                        <ng-container>
                            <div>
                                <ul class="list">
                                    <li *ngFor="let org of userOrganizations; let fst = first"
                                        [ngClass]="{'no-margin-top': fst, 'mt-5': !fst}">
                                        {{org}}
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
