import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-check-place',
    templateUrl: 'appeal-entity-check-place.component.html',
    styleUrls: ['appeal-entity-check-place.component.css'],
})
export class AppealEntityCheckPlaceComponent implements OnInit {
    @Input() public entity;
    @Input() public property;
    @Input() public catalogName;
    @Input() public title;
    @Input() public large;
    @Input() public small;
    @Input() public disabled = false;

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    public ngOnInit() {
        if (this.property && !this.entity[this.property]) {
            this.entity[this.property] = [this.newItem()];
        }
    }

    /**
     * Возвращает новый объект
     */
    public newItem() {
        const newItem = {};
        newItem['guid'] = CommonUtilities.GenerateGuid();
        newItem['place'] = null;

        if (this.entity[this.property] && this.entity[this.property].length > 1) {
            newItem['date'] = null;
            newItem['startHours'] = null;
            newItem['startMinutes'] = null;
            newItem['endHours'] = null;
            newItem['endMinutes'] = null;
            newItem['period'] = null;
        }
        return newItem;
    }

    /**
     * Добавление нового элемента массива
     */
    public addItem() {
        this.entity[this.property].push(this.newItem());
    }

    /**
     * Удаление ранее добавленного элемента
     * @param item
     */
    public deleteItem(item) {
        const findIndex = this.entity[this.property].findIndex((f) => f.guid === item.guid);
        this.entity[this.property].splice(findIndex, 1);

        if (this.entity[this.property].length === 1) {
            delete this.entity[this.property][0]['ControlDate'];
            delete this.entity[this.property][0]['ControlStartHours'];
            delete this.entity[this.property][0]['ControlStartMinutes'];
            delete this.entity[this.property][0]['ControlEndHours'];
            delete this.entity[this.property][0]['ControlEndMinutes'];
            delete this.entity[this.property][0]['ControlPeriod'];
            delete this.entity[this.property][0]['FactControlPeriod'];
            delete this.entity[this.property][0]['MeasureUnitTypeName'];
        }
    }
}
