<div class="panel-heading pt-10 pb-10" [ngClass]="getElementProperty('background')">
    <h4 class="panel-title ellipsis" style="width: 60%">
        <div class="mb-5 line-height-10">
            <ng-container *ngIf="!params.infoInHeader">
                <label class="badge text-uppercase mr-10 no-margin-bottom bg-{{params.theme}}-400">
                    {{getElementSubName()}}
                </label>
                <label class="badge text-uppercase mr-10 no-margin-bottom bg-danger-600 ml-10" *ngIf="isExpired">
                    Просрочена
                </label>
            </ng-container>
            <div class="text-size-small mt-10" style="font-style: normal" *ngIf="params.infoInHeader">
                <span class="text-muted">Старт:</span> {{element.startTime | date: 'medium'}}
                <span class="text-muted ml-10">Завершение:</span> {{element.endTime | date: 'medium'}}
            </div>
        </div>
        {{getElementName()}}
    </h4>
    <div class="heading-elements" *ngIf="allowApply || params.infoInHeader">
        <ng-container *ngIf="params.infoInHeader">
            <i class="{{params.icon}} text-{{params.theme}}-400" style="font-size: 30px" *ngIf="params.icon"></i>
            <span [innerHTML]="params.svg" *ngIf="params.svg"></span>
        </ng-container>
        <ng-container *ngIf="allowApply">
            <button [disabled]="isProcessing" [ngClass]="{'flash': isProcessing}" style="font-size: 16px"
                    class="btn btn-success-overline heading-btn text-size-large"
                    (click)="complete()">
                Завершить
            </button>
        </ng-container>
    </div>
</div>
