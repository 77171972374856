<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="selectionService.isProcessSelect"
            (click)="returnToOperation()">
        {{ localizations.common.back_to_operation }}
        <span class="badge bg-white ml-5 text-blue">
            {{selectionService.selectedItems.length ? selectionService.selectedItems.length : 0}}
        </span>
    </button>

    <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            *ngIf="!selectionService.isProcessSelect"
            [disabled]="!canCreate"
            (click)="getNewOrg()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations.organizations.create }}
    </button>

    <!-- Глобальный поиск -->
    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   [placeholder]="localizations.common.search_dots"
                   type="text"
                   [(ngModel)]="globalSearch"
                   (ngModelChange)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData('add')">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Панель фильтрации + Заголовок страницы -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-semibold">
                            {{ localizations.organizations.plural }}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{ getTotalMessage() }}
                            </span>
                        </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li>
                            <i class="icon-cog3"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Панель фильтрации -->
            <filters-bar [filters]="filterPanelItems"
                                  [afterHeader]="true"
                                  [filterName]="'orgFilter'"
                                  (afterLoadFile)="updateServiceCodes($event)"
                                  (onApplyFilters)="loadData('replace')"
                                  #filtersPanel>
            </filters-bar>
        </div>
    </div>

    <!-- Основной контент -->
    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">

                <!-- Список организаций -->
                <div class="scroll-panel">
                    <div class="panel panel-flat">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData('add')">
                            <ng-container *ngIf="organizations.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of organizations; trackBy:indexedFn let lst = last">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                             [ngClass]="{'active':organization?._id === item._id }">
                                            <div class="checkbox-block mt-20 mb-20 ml-20"
                                                 *ngIf="selectionService.isProcessSelect">
                                                <input id="selectObjectType{{ item.guid }}"
                                                       class="checkbox"
                                                       type="checkbox"
                                                       [checked]="item.selected"
                                                       (click)="addToRegistry(item)">
                                                <label for="selectObjectType{{ item.guid }}"
                                                       style="top: -18px">
                                                </label>
                                            </div>
                                            <div class="list-item"
                                                 (click)="click(item)"
                                                 (dblclick)="dblClick(item)"
                                                 [style.width] = "selectionService.isProcessSelect ?
                                                    'calc(100% - 50px)' :
                                                    '100%'">
                                                <div class="list-item-status-rectangle text-uppercase display-inline-block"
                                                     [ngClass]="getStatusProperty(item.isAuthorized,'background')">
                                                    {{ getStatusProperty(item.isAuthorized,'name') }}
                                                </div>
                                                <div class="list-item-data"
                                                     [ngStyle]="{'width': 'calc(100% - 100px)', 'whiteSpace': 'normal'}">
                                                    <h6 class="text-bold no-margin">
                                                        <span>
                                                            {{item.name}}
                                                        </span>
                                                    </h6>
                                                    <p class="no-margin-bottom">
                                                        <!-- Краткое наименование организации -->
                                                        <ng-container *ngIf="item.shortName">
                                                            <span class="text-muted text-uppercase text-size-mini">
                                                                {{ localizations.common.short_name }}:
                                                            </span>
                                                            <span>
                                                                {{item.shortName}}
                                                            </span>
                                                        </ng-container>
                                                        <!-- Код организации в СПЭР -->
                                                        <ng-container *ngIf="item.code">
                                                            <span class="text-muted text-uppercase text-size-mini ml-10">
                                                                {{ localizations.organizations.sper_code }}:
                                                            </span>
                                                            <span>
                                                                {{ item.code }}
                                                            </span>
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>

                <!-- Панель предпросмотра -->
                <div class="scroll-panel no-padding no-margin second-panel">
                    <div class="alert alert-warning alert-styled-left alert-bordered"
                         *ngIf="!previewMode || previewMode && !organization">
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.organizations.select_on_left_for_details }}
                    </div>
                    <organization-preview [organization]="organization"
                                          *ngIf="previewMode && organization"
                                          (onDelete)="deleteOrganization()">
                    </organization-preview>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #notFindedOrganizationsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.organizations.parameters }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-bold">
            {{ localizations.organizations.no_organizations_for_next_params }}
        </p>
        <ul>
            <li *ngFor="let item of notFindedOrgs">
                <span class="text-muted mr-5">
                    {{ localizations.common.ogrn }}:
                </span>
                {{ item.ogrn }}
                <span class="text-muted mr-5 ml-10">
                    {{ localizations.common.inn }}:
                </span>
                {{item.inn}}
                <span class="text-muted mr-5 ml-10">
                    {{ localizations.common.kpp }}:
                </span>
                {{item.kpp}}
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>
