import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppealEntityIncomingDocument } from './models/appeal-entity-incoming-document';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealData } from '../../../../appeal.data';

@Component({
    selector: 'appeal-entity-incoming-document',
    templateUrl: 'appeal-entity-incoming-document.component.html',
})
export class AppealEntityIncomingDocumentComponent implements OnInit {
    @Input() public entity: AppealEntityIncomingDocument;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public entityTypes = AppealData.entityTypes;
    public activeTab = 'common';
    public editTabs = [];
    public decisions = [];

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.decisionProsecutor) {
            this.entity.decisionProsecutor = { failures: 0, approvals: 0 };
            // Поиск всех сведений Решение прокуратуры в рассматриваемом деле
            this.decisions = this.appealEntitiesService.findEntityAll(
                this.appeal,
                this.entityTypes.violationDecision.code
            );
            if (this.decisions) {
                this.decisions.forEach((decision) => {
                    this.entity.decisionProsecutor.failures += decision.isFailure ? 1 : 0;
                    this.entity.decisionProsecutor.approvals += decision.isAgreed ? 1 : 0;
                });
            }
        }
        this.initEditTabs();
    }

    /**
     * Инициализация массива вкладок
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: 'Общие данные',
                active: true,
                visible: true,
            },
            {
                code: 'violations',
                name: 'Нарушения',
                active: false,
                visible: true,
            },
        ];
    }

    /**
     * Установка активной вкладки
     * @param tab
     */
    public activateTab(tab) {
        this.activeTab = tab;
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        if (!this.entity.conviction) {
            this.entity.typeCauser = null;
            this.entity.nsiArticle = null;
        }

        this.onApply.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
