<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component"
     *ngIf="mode === 'default'">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            [disabled]="task.status && task.status === 'COMPLETED'"
            (click)="save()">
        <b>
            <i class="icon-checkmark3 icon-checkmark-circle"></i>
        </b>
        {{ localizations.common.save }}
    </button>
    <div class="ml-20 navbar-title mt-20">
        <h4>
            {{!task.status || task.status && task.status === 'ACTIVE' ?
                localizations.tasks.setup : localizations.tasks.view }}
            <div class="display-inline-block">
                <label class="badge text-uppercase ml-15 no-margin-bottom"
                       [ngClass]="{'bg-primary': !task.status || task.status === 'ACTIVE', 'bg-success': task.status === 'COMPLETED'}">
                    {{task.status && task.status === 'COMPLETED' ? localizations.common.completed : localizations.common.in_progress }}
                </label>
            </div>
        </h4>
    </div>

    <div class="navbar-menu" *ngIf="mode === 'default'">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default"
         *ngIf="mode === 'default'">
        <div class="sidebar-content">

            <div *ngFor="let tab of tabs">
                <div class="sidebar-category"
                     (click)="activateTab(tab)"
                     [ngClass]="{'active': tab.active, 'cursor-pointer' : !tab.active}">
                    <div class="category-title">
                        <span>
                            {{tab.name}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="pl-10 pr-10 pt-20"
                 (click)="completeCustomTask()"
                 *ngIf="task.status === 'ACTIVE' && camundaTask && !camundaTask.mainId">
                <button class="btn btn-primary full-width">
                    {{ localizations.common.complete }}
                </button>
            </div>
        </div>
    </div>

    <!-- Блоки с настройками элементов дела -->
    <div [ngClass]="{'panel-main': mode==='default', 'full-width': mode === 'createFromEvent'}">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">

            <!-- Общие данные  -->
            <ng-container *ngIf="activeTab==='common'">
                <div class="panel panel-shadow m-20">
                    <task-form [task]="task"
                               [appeal]="appeal"
                               [usersForSelect]="usersForSelect"
                               [mainEntity]="mainEntity"
                               [candidateGroups]="candidateGroups"
                               [camundaTask]="camundaTask"
                               (onChange)="saveChanges()">
                    </task-form>
                </div>
            </ng-container>

            <!-- Подзадачи  -->
            <ng-container *ngIf="activeTab==='subTasks'">
                <sub-tasks [task]="task"
                           [appeal]="appeal"
                           [usersForSelect]="usersForSelect"
                           [candidateGroups]="candidateGroups"
                           [mainEntity]="mainEntity"
                           (onChange)="save()">
                </sub-tasks>
            </ng-container>

            <!-- Чек-лист -->
            <ng-container *ngIf="activeTab === 'checkList'">
                <task-check-list [questions]="task.questions"
                                 [task]="task"
                                 [camundaTask]="camundaTask"
                                 (onSelectQuestions)="selectQuestions()">
                </task-check-list>
            </ng-container>
        </evolenta-scrollbar>
    </div>

</div>
