import { Component, Input, OnInit, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'modal-user',
    templateUrl: 'modal-user.component.html',
})
export class ModalUserComponent implements OnInit {
    @ViewChild('userModal', { static: false }) public userModal: ModalDirective;
    @Input() public subjects;
    @Input() public type; // тип найденных данных: организации или ФЛ
    @Output() public onSelect = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<object>();

    public selectedSubject = null;
    public modalRef: BsModalRef;
    public localizations;

    @ViewChild('selectUserModal', { static: false }) public selectUserModal: TemplateRef<any>;

    public constructor(
        private modalService: BsModalService,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'subjects',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public showModal() {
        this.modalRef = this.modalService.show(this.selectUserModal, {backdrop: 'static'});
    }

    public hideModal() {
        this.modalRef.hide();
    }

    public cancelModal() {
        this.onCancel.emit();
        this.hideModal();
    }

    /**
     * Выбор ФЛ из списка - передача объекта в компонент
     */
    public selectSubject() {
        this.onSelect.emit(this.selectedSubject);
        this.hideModal();
    }
}
