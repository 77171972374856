import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RestService } from '@evolenta/core';
import { XsdJsonService } from '../../../../../../../../common/services/xsd-json.service';

@Component({
    selector: 'appeal-process-task-xsd-data',
    templateUrl: './appeal-process-task-xsd-data.component.html',
})
export class AppealProcessTaskXsdDataComponent implements OnInit {
    @Input() public params;
    @Input() public validate;
    @Output() public onApply = new EventEmitter<any>();
    @ViewChild('saveBtn', { static: false }) public saveBtn;

    public schema;

    public constructor(
        private rest: RestService,
        private xsdJsonService: XsdJsonService
    ) {}

    public ngOnInit() {
        this.initData();
    }

    public initData() {
        setTimeout(() => {
            if (!this.params.schema) {
                this.rest.find('xsd', this.params.xsd).then((xsd) => {
                    this.schema = this.xsdJsonService.getSchemaAndFormDescription(xsd['content'], false);
                    this.params.schema = this.schema;

                    if (this.params.value) {
                        this.schema.value = this.params.value;
                    }
                });
            } else {
                this.schema = this.params.schema;

                if (this.params.value) {
                    this.schema.value = this.params.value;
                }
            }
        }, 10);
    }

    /**
     * Запуск процедуры сохранения данных из родительского компонента (отдельное сохранение)
     */
    public save() {
        // Инициализация данных процесса сохранения
        if (this.saveBtn) {
            setTimeout(() => {
                this.saveBtn.nativeElement.click();
            }, 100);
        } else {
            this.complete({ values: null, errors: [] });
        }
    }

    /**
     * Получение данных с JSON-формы
     * @param data - json-объект с данными формы
     */
    public complete(data) {
        if (data.errors && data.errors.length > 0) {
            console.log('ERRORS', data.errors);
        }
        if (data.values) {
            this.params.value = data.values;
            this.params.isValid = data.errors.length === 0; // статус валидации формы
        }

        this.onApply.emit(this.params);
    }
}
