import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonStandardsService } from '../common-standards.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'common-standard-block-status',
    templateUrl: './common-standard-block-status.component.html',
    styles: ['.light { font-weight: normal; text-transform: none; }'],
})
export class CommonStandardBlockStatusComponent implements OnInit {
    public localizations;

    @Input() public block;
    @Input() public small = true;
    @Output() public onToggleEntity = new EventEmitter();
    @Output() public onDeleteReglamentData = new EventEmitter();

    public constructor(
        public standardsService: CommonStandardsService,
        public translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getSwitchToModeMessage(): string {
        const template = this.localizations.standards.switch_to_mode;

        if (this.block.entityMode === 'standard') {
            return template.replace('%s', this.localizations.standards.of_reglament);
        }

        return template.replace('%s', this.localizations.standards.of_standard);
    }

    public toggleEntity() {
        this.standardsService.toggleReglamentBlockEntity(this.block);
    }

    public deleteReglamentData() {
        this.standardsService.deleteReglamentBlock(this.block);
    }

    public get existInReglament() {
        return this.standardsService.checkReglamentFields(this.block);
    }
}
