<div class="alert alert-warning no-border no-border-radius"
     *ngIf="entityType && (!kndKinds || kndKinds.length == 0)">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations['entity-knd-kind-data'].knd_kinds_not_selected }}
</div>

<!-- Список видов КНД -->
<ng-container *ngIf="!editedKndKind">
    <div class="panel panel-shadow panel-small m-20"
         *ngFor="let kndKind of kndKinds">
        <div class="panel-heading">
            <h6 class="panel-title text-bold">
                {{kndKind.name}}
            </h6>
            <div class="heading-elements">
                <button class="btn btn-default btn-heading"
                        [title]="localizations.common.edit"
                        (click)="edit(kndKind)">
                    <b>
                        <i inlineSVG="assets/icon-edit.svg"></i>
                    </b>
                </button>
            </div>
        </div>
    </div>
</ng-container>


<entity-knd-kind-data *ngIf="editedKndKind"
                      [entity]="entity"
                      [entityName]="entityName"
                      [entityType]="entityType"
                      [kndKind]="editedKndKind"
                      (onApply)="editedKndKind = null"
                      #entityKndKindData>
</entity-knd-kind-data>




