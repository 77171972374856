import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
    AccessService,
    ModalDialogComponent,
    SelectionService,
    StorageService,
    ToasterService,
} from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import {
    CommonAppealEntityAdditionalDataComponent,
} from '../../appeal-subjects-objects/appeal-entity-additional-data/common-appeal-entity-additional-data.component';
import { CommonAppealService } from '../../../services/common-appeal.service';
import { CommonAppealSubservicesService } from '../../../services/common-appeal-subservices.service';
import { CommonAppealStatusService } from '../../../services/common-appeal-status.service';
import { AisObjectsService } from '../../../../objects/objects/ais-objects.service';
import { PrintUtilities } from '@evolenta/utilities';
import { ObjectEditComponent } from '../../../../objects/objects/object-edit/object-edit.component';
import cloneDeep from 'lodash-es/cloneDeep';
import { ErrorLoggingService } from '../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-object-card',
    templateUrl: 'common-appeal-object-card.component.html',
})
export class CommonAppealObjectCardComponent implements OnInit, OnChanges {
    @Input() public object: any; // Субъект
    @Input() public mode = 'view'; // Режим работы: просмотр карточки, редактирование данных
    @Input() public appeal; // Формируемое дело
    @Input() public subservice: any = {}; // услуга, на основании которой формируется дело
    @Input() public activeTab;
    @Input() public params;
    @Input() public noMargin = false;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onInitEdit = new EventEmitter<object>();
    @Output() public onDelete = new EventEmitter<object>();
    @ViewChild(ModalDialogComponent, { static: false }) public alertModalComponent: ModalDialogComponent;

    public modalMessage; // Текст сообщения для модального окна
    public modalTitle; // Заголовок сообщения для модального окна
    public modalOperation; // Вид операции (возврат к списку или удаление объекта)

    public isFormValid = true;

    public searchText;
    public findedObjects = [];
    public selectedObject;

    public modalRef: BsModalRef;
    @ViewChild('selectObjectModal', { static: false }) public selectObjectModal: TemplateRef<any>;

    @ViewChild('additionalData', { static: false }) public additionalDataComponent: CommonAppealEntityAdditionalDataComponent;
    @ViewChild('editObjectForm', { static: false }) public editObjectFormComponent: ObjectEditComponent;

    public objectParams;

    public checkOtherRegion = false;
    public isProcessValidate = false;

    public constructor(
        public appealStatusService: CommonAppealStatusService,
        public accessService: AccessService,
        public appealService: CommonAppealService,
        private appealSubservicesService: CommonAppealSubservicesService,
        private toaster: ToasterService,
        private modalService: BsModalService,
        private selectionService: SelectionService,
        private router: Router,
        private objectsService: AisObjectsService,
        private errorLoggingService: ErrorLoggingService,
        private storage: StorageService,
    ) {
    }

    public ngOnInit() {
        this._initObjectParams();
    }

    public ngOnChanges(changes) {
        if (changes && changes.params && !changes.params.firstChange && changes.params.previousValue !== changes.params.currentValue) {
            this._initObjectParams();
        }
    }

    private _initObjectParams() {
        this.objectParams = cloneDeep(this.params);
        this.checkOtherRegion = this.subservice.registersModel && this.subservice.registersModel.useOtherRegionCheck;
        // Обработка запрета редактирования данных другого региона
        if (this.checkOtherRegion && this.isOtherRegionObject && this.subservice.objects.objectsData && this.subservice.objects.objectsData.denyChangeOtherRegionObject) {
            Object.keys(this.objectParams.permissions).forEach(key => {
                this.objectParams.permissions[key] = false;
            });
        }
        // если объект добавленный, то разрешаем все операции с ним
        if (this.objectParams.allowCreateNew && !this.object.reestrId) {
            Object.keys(this.objectParams.permissions).forEach(key => {
                this.objectParams.permissions[key] = true;
            });
            this.objectParams.allowedEditFields = null;
            this.objectParams.ignoreReestrId = false;
        }
    }

    public get isOtherRegionObject() {
        return this.object.region && this.appeal.unit.region && this.object.region.code !== this.appeal.unit.region.code;
    }

    /**
     * Обработка события нажания на Enter при поиске
     * @param event
     */
    public keypress(event?) {
        if (event) {
            if (event.keyCode === 13) {
                this.searchObject();
            }
        }
    }

    public searchObject() {
        const searchFromGlobalRegistry = !!this.subservice.objects.selectSubjectsFromGlobalRegister;
        const collection = searchFromGlobalRegistry ? 'objects' : 'objectsKno';
        this.objectsService.searchObjects(this.searchText, collection).then(objects => {
            if (objects.length === 0) {
                this.toaster.info('Совпадения не найдены');
            } else {
                this.findedObjects = objects;
                this.modalRef = this.modalService.show(this.selectObjectModal, { backdrop: 'static', class: 'modal-lg' });
            }
        });
    }

    public async selectObject() {
        const objectData = await this.objectsService.getObjectData(this.selectedObject, !!this.objectParams.selectOnlyFromGlobalRegistry);
        this.object = Object.assign(this.object, objectData);
        this.modalRef.hide();
    }

    /**
     * Передача инициализации режима редактирования объекта в родительский компонент (переход из режима просмотра в режим редактирования)
     * @param tab - активируемая вкладка
     */
    public initEdit(tab) {
        this.activeTab = tab;
        this.onInitEdit.emit({objectGuid: this.object.guid, tab: this.activeTab});
    }

    /**
     * Инициализация режима редактирования услуги в деле (нажатие кнопки "Редактировать")
     */
    public edit() {
        this.onEdit.emit(this.object);
    }

    /**
     * Применение изменений внесенных в объект (нажатие кнопки "Применить")
     * @param continueProcessingAppeal - продолжить сохранение (или перевод на другой статус) дела после применения изменений
     */
    public apply(continueProcessingAppeal = false) {
        if (this.validate()) {
            if (this.checkOtherRegion && this.isOtherRegionObject && this.subservice.objects.objectsData.denyChangeOtherRegionObject) {
                const currentOrganization = this.storage.getItem('currentOrganization');
                this.modalMessage = 'Выбран регион "' + this.object.region.name + '", отличающийся от региона организации "' + currentOrganization.region.name + '". ' +
                    'Последующее редактирование данных объекта и его удаление будут невозможны. Вы уверены, что регион выбран верно?';
                this.modalTitle = 'Корректность региона';
                this.modalOperation = 'checkRegion';
                this.alertModalComponent.showModal();
            } else {
                this.onApply.emit({object: this.object});
            }
        } else {
            const errorText = 'На форме содержатся ошибки';
            this.toaster.error(errorText);
            this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
        }
    }

    /**
     * Отмена изменений внесенных в услугу дела (нажатие кнопки "Отменить")
     */
    public cancel() {
        this.onApply.emit(false);
    }

    public validate(): boolean {
        this.isProcessValidate = true;
        let additionalDataValid = true;
        if (this.additionalDataComponent) {
            this.additionalDataComponent.validate();
            additionalDataValid = this.additionalDataComponent.isValid();
        }
        let formValid = true;
        if (this.editObjectFormComponent) {
            formValid = this.editObjectFormComponent.objectForm.valid;
        }

        return additionalDataValid && formValid;
    }

    /**
     * Обработка действий из модального окна: применение изменений, либо отмена
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingBack(data) {
        if (data === 'yes') {
            this.apply();
        } else if (data === 'no') {
            this.cancel();
        }
    }

    public checkRegion(data) {
        if (data === 'yes') {
            this.onApply.emit({object: this.object});
        }
    }

    /**
     * Активация определенной вкладки при редактировании объекта
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        if (this.activeTab === 'additionalData') {
            // Если текущая вкладка "Доп. инфо", то перед переходом на новую сохраняем значения
            // this.appealSubjectAdditionalDataComponent.save(tab);
        } else {
            this.activeTab = tab;
            if (this.mode === 'view') {
                this.onInitEdit.emit({objectGuid: this.object.guid, tab: this.activeTab});
            }
        }
    }

    /**
     * Передача управления ответа на нажатие кнопок модального окна нужному методу
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Инициализация вызова модального окна для подтверждения удаления объекта
     */
    public deleteObject() {
        this.modalMessage = 'Вы действительно хотите удалить объект "' + this.object.name + '"?';
        this.modalTitle = 'Удаление объекта';
        this.modalOperation = 'processingDelete';
        this.alertModalComponent.showModal();
    }

    /**
     * Обработка результата нажатия кнопок модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingDelete(data) {
        if (data === 'yes') {
            this.onDelete.emit(this.object);
        }
    }

    /**
     * Проверка валидности объекта
     * @returns {boolean}
     */
    public checkObjectValid() {
        return true; // this.validateService.checkElementValid(this.object.guid, 'objects');
    }

    public get objectSubservices() {
        const subservices = [];
        Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
            if (this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid]
                && this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid].active) {
                const subservice = {
                    title: this.appealSubservicesService.data[appealSubserviceGuid].subservice.titles.shortTitle,
                    subjects: [],
                };
                if (this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid].subjects) {
                    const activeSubjects = this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid].subjects.filter(item => item.active);
                    if (activeSubjects.length > 0) {
                        activeSubjects.forEach(subject => {
                            const result = this.appealSubservicesService.entitiesData[subject.guid];
                            subservice.subjects.push(result && result.shortHeader ? result.shortHeader : 'данные отсутствуют');
                        });
                    }
                    subservices.push(subservice);
                }
            }
        });

        return subservices;
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }

    public async selectFromRegistry() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.appeal;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectObject';
        this.selectionService.additionalData = {
            viewMode: 'oneWindow',
            editedObject: this.object,
            selectFromGlobal: this.objectParams.selectOnlyFromGlobalRegistry,
            entityType: 'object',
        };
        this.selectionService.selectType = 'one';
        const paths = ['selecting', this.objectParams.selectOnlyFromGlobalRegistry ? 'global-objects' : 'objects'];
        await this.router.navigate(paths);
    }

    public get allowEditCommonData() {
        return this.mode === 'edit'
            && this.objectParams.permissions.edit
            && (this.objectParams.allowCreateNew && !this.object.reestrId || this.objectParams.ignoreReestrId);
    }

    public get isVisiblePreviewCard() {
        return (this.mode === 'view' || this.appeal.status.code === 'draft'
                && this.object.reestrId
            )
            && this.object.name;
    }

    public getFieldsRequirementsRules() {
        return this.subservice.objects.objectsData && this.subservice.objects.objectsData.fieldsRequirements && this.subservice.objects.objectsData.fieldsRequirements.length > 0
               ? this.subservice.objects.objectsData.fieldsRequirements : null;
    }
}
