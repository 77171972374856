<div
    [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit'}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i></b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()"
                    *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()"
                    *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div *ngIf="mode == 'view'" [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}">
            <span class="text-muted">Дата: </span>
            <span *ngIf="entity.date">{{entity.date | date: 'mediumDate'}}</span>
            <em *ngIf="!entity.date">не указана</em>

            <span class="text-muted ml-10">Место составления: </span>
            <ng-container *ngIf="entity.place">{{ PrintUtilities.PrintAddressAsLine(entity.place, true) }}</ng-container>
            <em *ngIf="!entity.place">не указанo</em>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button"
                    [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <ng-container *ngIf="mode === 'edit'">

            <!-- Основные данные -->
            <div class="pt-15 pl-20 pr-20">
                <form #form="ngForm">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="form-group form-group-lg">
                                <label>Место составления <span class="text-danger">*</span></label>
                                <evolenta-address-gar
                                        name="place"
                                        [large]="true"
                                        [(ngModel)]="entity.place"
                                        #place="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && place.errors" class="validation-error-label">
                                    <span *ngIf="place.errors.required">Поле "Место составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата составления <span class="text-danger">*</span></label>
                                <evolenta-datepicker [large]="true" name="date" [(ngModel)]="entity.date" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Начало строительства, реконструкции <span class="text-danger">*</span></label>
                                <evolenta-datepicker [large]="true" name="startConstruct" [(ngModel)]="entity.startConstruct" #startConstruct="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && startConstruct.errors" class="validation-error-label">
                                    <span *ngIf="startConstruct.errors.required">Поле "Начало строительства, реконструкции" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Окончание строительства, реконструкции <span class="text-danger">*</span></label>
                                <evolenta-datepicker [large]="true" name="endConstruct" [(ngModel)]="entity.endConstruct" #endConstruct="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && endConstruct.errors" class="validation-error-label">
                                    <span *ngIf="endConstruct.errors.required">Поле "Окончание строительства, реконструкции" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group form-group-lg">
                        <label>Наименования и реквизиты проектной документации, требования которой нарушены <span class="text-danger">*</span></label>
                        <textarea class="form-control" name="projectDocument" [(ngModel)]="entity.projectDocument" rows="3" #projectDocument="ngModel" required></textarea>
                        <div *ngIf="isProcessValidate && projectDocument.errors" class="validation-error-label">
                            <span *ngIf="projectDocument.errors.required">Поле "Наименования и реквизиты проектной документации, требования которой нарушены" обязательно для заполнения</span>
                        </div>
                    </div>

                    <fieldset *ngIf="appeal.objects && appeal.objects.length > 0">
                        <legend class="text-bold">Объект проверки</legend>
                        <object-preview-card [object]="appeal.objects[0]" [showName]="true" [showDetail]="false" [smallName]="true"></object-preview-card>
                    </fieldset>
                </form>
            </div>

            <appeal-entity-documents [entity]="entity" [task]="task" [allowEdit]="allowEdit"></appeal-entity-documents>

        </ng-container>
    </div>
</div>
