import { Component, Input, OnInit } from '@angular/core';
import { CommonProcessService } from '../common-process.service';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'common-bpmn-links',
    templateUrl: './common-bpmn-links.component.html',
    styles: ['.pl-60 { padding-left: 60px; }'],
})
export class CommonBpmnLinksComponent implements OnInit {
    @Input() public property; // свойство сущности, для которого настраиваются задачи
    @Input() public propertyName; // наименование (код) элемента стандарта, для которого настраиваются задачи
    @Input() public processDataInEntity; // структура процесса в стандарте (деле, и т.д.)
    @Input() public elementType = 'tasks'; // тип (tasks|catchEvents)
    @Input() public elementField = 'id'; // поле, по которому осуществляется сравнение
    @Input() public process; // процесс
    @Input() public operations = ['used', 'required'];
    @Input() public allowEdit = true;

    public elements = [];

    public operationTypes = [
        {
            code: 'used',
            name: 'Используется',
            field: 'isUse',
        },
        {
            code: 'required',
            name: 'Обязательно',
            field: 'isRequired',
        },
        {
            code: 'print',
            name: 'Формирование документа',
            field: 'isPrint',
        },
        {
            code: 'sign',
            name: 'Подписание',
            field: 'isSign',
        },
    ];
    public localizations;

    public constructor(
        private processService: CommonProcessService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        if (this.process) {
            this.elements = this.prepareElements(this.process);
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'bpmn-card']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Формирование списка задач (событий) из данных процесса
     * @param process
     */
    public prepareElements(process) {
        let elements;
        if (this.elementType === 'tasks') {
            if (process.tasks.userTasks) {
                elements = process.tasks.userTasks.map((item) => this.getElementParams(item));
            }
            if (process.tasks.subProcess) {
                elements = elements.concat(process.tasks.subProcess.map((item) => this.getElementParams(item)));
            }
        } else {
            elements = process[this.elementType].map((item) => this.getElementParams(item));
            if (process.tasks && process.tasks.subProcess) {
                elements = elements.concat(process.tasks.subProcess.map((item) => this.getElementParams(item)));
            }
        }
        return elements;
    }

    /**
     * Свойство отдельной задачи
     * @param item
     */
    public getElementParams(item) {
        const data: any = {
            id: item.id,
            guid: item.guid,
            name: item.name,
        };
        const elementData = this.processService.getElementDataFromProcessInEntity(
            item,
            this.elementType,
            this.elementField,
            this.processDataInEntity
        );
        if (this.operations.indexOf('used') !== -1) {
            data.isUse = this.initElementParams(elementData, 'isUse');
        }
        if (this.operations.indexOf('required') !== -1) {
            data.isRequired = this.initElementParams(elementData, 'isRequired');
        }
        if (this.operations.indexOf('print') !== -1) {
            data.isPrint = this.initElementParams(elementData, 'isPrint');
        }
        if (this.operations.indexOf('sign') !== -1) {
            data.isSign = this.initElementParams(elementData, 'isSign');
        }
        if (item.subProcess) {
            data.children = this.prepareElements(item.subProcess);
        }
        return data;
    }

    /**
     * Инициализация обрабатываемых компонентом параметров
     * @param elementData
     * @param checkedProperty
     */
    public initElementParams(elementData, checkedProperty) {
        if (elementData && elementData[this.propertyName]) {
            const findCurrentPropertyParams = elementData[this.propertyName].find(
                (item) => item.guid === this.property.guid
            );
            return findCurrentPropertyParams ? findCurrentPropertyParams[checkedProperty] : false;
        }
        return false;
    }

    /**
     * Изменение параметров для определенного элемента (задачи, события)
     * @param element
     * @param property
     */
    public changeElementProperty(element, property) {
        const elementData = this.processService.getElementInProcessById(
            element.id,
            this.elementType,
            this.processDataInEntity
        );
        if (!elementData[this.propertyName]) {
            elementData[this.propertyName] = [];
        }
        let find = elementData[this.propertyName].find((item) => item.guid === this.property.guid);
        if (!find) {
            elementData[this.propertyName].push({ guid: this.property.guid });
            find = elementData[this.propertyName][elementData[this.propertyName].length - 1];
        }
        find[property] = !find[property];
    }

    public getOperationProperty(operation, property) {
        const data = this.operationTypes.find((item) => item.code === operation);
        return data ? data[property] : '';
    }
}
