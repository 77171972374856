import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
// @ts-ignore
import { HandlersService, ToasterService } from '@evolenta/core';
import { CommonAppealService } from '../../../../services/common-appeal.service';
import { CommonAppealEntityDocumentsComponent } from '../entity-documents/common-appeal-entity-documents.component';
import { InternalHandlersService } from '../../../../../../common/services/internal-handlers.service';
import { ErrorLoggingService } from '../../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-entity-custom',
    templateUrl: 'common-appeal-entity-custom.component.html',
})
export class CommonAppealEntityCustomComponent implements OnInit {
    @Input() public entity;
    @Input() public appeal;
    @Input() public task;
    @Input() public mode;
    @Input() public allowEdit;

    @Output() public onApply = new EventEmitter<any>();
    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<any>();
    @Output() public onCancel = new EventEmitter<any>();
    @Output() public onNeedSaveBeforePrint = new EventEmitter<any>();

    @ViewChild('data', { static: false }) public data: DynamicFormComponent;
    @ViewChild('entityDocuments', { static: false })
    public entityDocumentsComponent: CommonAppealEntityDocumentsComponent;

    public showCommonData = true;
    public handlers;
    public externalData;

    public constructor(
        public appealService: CommonAppealService,
        private toaster: ToasterService,
        private handlersService: HandlersService,
        private internalHandlersService: InternalHandlersService,
        private errorLoggingService: ErrorLoggingService
    ) {}

    public ngOnInit(): void {
        this.showCommonData = this.mode === 'edit';
        if (this.entity.entityGuid) {
            const entityInSubservice = this.appealService.subservice.entities.find(
                (item) => item.guid === this.entity.entityGuid
            );
            this.handlers =
                entityInSubservice && entityInSubservice.xsdHandlers ? entityInSubservice.xsdHandlers : null;
        }
        this.externalData = { appeal: this.appeal, entity: this.appeal };
        if (this.handlers && this.mode === 'edit') {
            this.internalHandlersService.processingHandlers(this.handlers, 'startBefore', 'onStart', this.externalData);
        }
        this.allowEdit = this.mode !== 'view';
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.data.validate();
        if (this.data.isValid) {
            this.onApply.emit(true);
        } else {
            const errorText = 'На форме сведения ошибки';
            this.toaster.error(errorText);
            this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText), this.data);
        }
    }

    public cancel() {
        this.onCancel.emit();
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public isValid() {
        if (this.mode === 'view') {
            return true;
        } else {
            this.data.validate();
            if (this.data.isValid) {
                this.apply();
            }

            return this.data.isValid;
        }
    }

    public getTransferToProcessParams() {
        if (this.data && this.data.formRenderComponent) {
            return this.data.getDataByParam('isTransferToProcess');
        }

        return null;
    }

    public runHandlers(handlers) {
        if (handlers) {
            const usedHandlers = handlers.filter((item) => item.event === 'onChange');
            if (usedHandlers.length > 0) {
                usedHandlers.forEach((handler) => {
                    this.handlersService.runHandler(handler, { appeal: this.appeal, model: this.entity });
                });
            }
        }
    }

    public processingSaveBeforePrint(printDocument) {
        this.onNeedSaveBeforePrint.emit(printDocument);
    }

    public processingPrintFormAfterSave(printDocument) {
        if (this.entityDocumentsComponent) {
            this.entityDocumentsComponent.processingPrintFormAfterSave(printDocument);
        }
    }
}
