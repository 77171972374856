<ng-container *ngIf="!editedCategory">
    <ng-container *ngFor="let item of group.children;  let idx = index">
        <div class="tree-line-drop-place"
             dndDropzone
             *ngIf="!idx"
             [ngStyle]="{'margin-left': 2 * (level * 30 + 10) + 'px'}"
             (dndDrop)="onDrop($event, -1, group.children, group)">
        </div>
        <div class="tree-line"
             dndType="element"
             [dndDraggable]="true"
             (dndStart)="onDragged(idx, item, group.children, level)"
             (dndCanceled)="onDragCanceled()"
             (dndEnd)="onDragEnd()"
             dndEffectAllowed="copy"
             [ngStyle]="{'padding-left': (level * 30 + 10) + 'px'}">
            <div class="tree-item-toggle-sign">
                <span class="cursor-pointer"
                      *ngIf="item.children && item.children.length > 0"
                      (click)="item.hide = !item.hide">
                    <i class="icon-plus2"
                       *ngIf="item.hide">
                    </i>
                    <i class="icon-minus2"
                       *ngIf="!item.hide">
                    </i>
                </span>
            </div>
            <div class="tree-item-title">
                <span>
                    {{item.name}}
                </span>
            </div>
            <div class="tree-item-buttons text-right pr-10">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="getAddCategoryMessage()"
                        *ngIf="groupsActiveData.allowEdit"
                        (click)="addCategory(item)">
                    <i class="icon-plus2"></i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="getForCategoryMessage()"
                        (click)="editCategory(item, group)">
                    <i [ngClass]="{
                        'icon-pencil': groupsActiveData.allowEdit,
                        'icon-eye': !groupsActiveData.allowEdit}">
                    </i>
                </button>
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="getRemoveCategoryMessage()"
                        *ngIf="groupsActiveData.allowEdit"
                        (click)="deleteCategory(item, group)">
                    <i class="icon-trash"></i>
                </button>
            </div>
        </div>
        <ng-container *ngIf="item.children && item.children.length > 0 && !item.hide">
            <common-standard-subject
                [xsdData]="xsdData"
                [group]="item"
                [level]="level + 1"
                [parentToggled]="parentToggled || (toggled && idx === standardsService.draggableIndex)"
                (editCategoryClicked)="editedCategoryClicked($event)"
                (editGroupClicked)="editedGroupClicked($event)"
                (dragStarted)="childDragStarted($event)">
            </common-standard-subject>
        </ng-container>
        <div *ngIf="!item.children || !item.children.length">
            <div class="tree-line-drop-place"
                 dndDropzone
                 [ngStyle]="{'margin-left': ((level + 1) * 30 + 10) + 'px'}"
                 (dndDrop)="onDrop($event, 0, null, item)">
            </div>
        </div>
        <div class="tree-line-drop-place"
             dndDropzone
             [ngStyle]="{'margin-left': (level * 30 + 10) + 'px'}"
             (dndDrop)="onDrop($event, idx, group.children, group, 1)">
        </div>
    </ng-container>
</ng-container>
