import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealService } from '../../../appeal.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-subject-objects',
    templateUrl: 'appeal-subject-objects.component.html',
    styles: ['.number { padding: 0 5px; }'],
})
export class AppealSubjectObjectsComponent {
    @Input() public object: any; // Объект
    @Input() public subject: any; // Субъект
    @Input() public appeal; // Формируемое дело
    @Input() public subservices; // Описательный массив услуг, на основании которых создано дело

    public data; // Объект информации по услугам дела для визуального отображения
    public appealSubservices = []; // Массив услуг в деле

    public objects = []; // Объекты субъекта

    @Output() public onReInitEditTabs = new EventEmitter<boolean>(); // Переиницализация вкладок для влючения / отключения отображения вкладки с доп.данными
    public editObject = null; // Редактируемый объект

    public constructor(private appealService: AppealService) {}

    public addObject() {
        this.editObject = {
            guid: CommonUtilities.GenerateGuid(),
        };
    }

    public applyObject(object) {
        if (!this.subject.objects) {
            this.subject.objects = [];
        }
        const findIndex = this.subject.objects.findIndex((item) => item.guid === object.guid);
        if (findIndex === -1) {
            this.subject.objects.push(_.cloneDeep(object));
        } else {
            this.subject.objects[findIndex] = _.cloneDeep(object);
        }
        this.editObject = null;
    }

    public cancelObject() {
        this.editObject = null;
    }

    public onEditObject(object) {
        this.editObject = object;
    }

    public deleteObject(object) {
        this.appealService.deleteCheckList(object.reestrId);
        const findIndex = this.subject.objects.findIndex((item) => item.guid === object.guid);
        this.subject.objects.splice(findIndex, 1);
    }
}
