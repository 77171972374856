import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import {
    StorageService,
    RestService,
    FiltersBarComponent,
    AccessService,
    SelectionService,
    TranslateService,
    ToasterService,
} from '@evolenta/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';
import { Permission } from '../../../../common/services/permission';
import { UtilityService } from '../../../../common/services/utility.service';
import { CommonAppealData } from '../../services/common-appeal.data';
import { FiltersService } from '../../../../common/services/filters.service';
import {
    APPEAL_STATUSES,
    APPEALS_DEFAULT_LIST_SIZE,
    DEFAULT_APPEALS_PROJECTION,
    DEFAULT_SPO_SORT_FIELD,
    DESCENDING_ORDER,
    LOAD_DATA_MODES,
} from '../../../../common/constants';
import { RegistersService } from '../../../registers/registers.service';
import { ReportsService } from '../../../../routing-modules/reports/reports/registers/reports.service';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-appeals',
    templateUrl: './common-appeals.component.html',
    styleUrls: ['../../../elements-list.css', 'common-appeals.component.css'],
})
export class CommonAppealsComponent implements OnInit {
    @ViewChild('filtersPanel', { static: true }) private filtersPanel: FiltersBarComponent; // панель фильтров

    public appeals = []; // список дел
    public filtersPanelItems = []; // Настройки панели фильтрации

    public globalSearch; // Глобальный поиск (верхняя строка)

    public mode = 'short';

    // Параметры пагинации
    public totalAppealsCount = 0; // Общее число дел
    public currentPage = 0;
    public totalPages: number;

    // Параметры предпросмотра
    public appeal = null; // Дело, отображаемое в режиме предпросмотра
    public prevent = false;
    public previewMode = false;
    public timer = null;

    // Параметры для работы с групповой операцией
    public isAllAppealsSelected = false;

    public permissions = Permission; // Описание всех полномочий системы

    public baseSearch = []; // Параметры базового поиска

    public currentOrganization = this.storage.getItem('currentOrganization');
    public moduleBaseUrl;
    public baseUrl;

    public metaReglament;
    public routeUrls = [];

    public statuses;
    public allowAdd = true;
    public disabledFilters = [];
    public localizations;
    public sortOpts = {
        field: DEFAULT_SPO_SORT_FIELD,
        order: DESCENDING_ORDER,
    };
    public DEFAULT_SPO_SORT_FIELD = DEFAULT_SPO_SORT_FIELD;
    public DESCENDING_ORDER = DESCENDING_ORDER;
    public initialized = false;
    public uploadListButtonAllowed;
    private metaReglamentCode;
    private searchCriteria;
    public ADD = LOAD_DATA_MODES.ADD;
    public REPLACE = LOAD_DATA_MODES.REPLACE;

    private defaultSearchFields: Set<string>;
    private sourceFields: Set<string>;

    public constructor(
        public selectionService: SelectionService,
        public appealService: CommonAppealService,
        public accessService: AccessService,
        private route: ActivatedRoute,
        private ref: ApplicationRef,
        private router: Router,
        private rest: RestService,
        private appealStatusService: CommonAppealStatusService,
        private storage: StorageService,
        private filtersService: FiltersService,
        private utility: UtilityService,
        private translate: TranslateService,
        private reportsService: ReportsService,
        private toasterService: ToasterService,
        private restService: RestService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.sourceFields = new Set();
        this.sourceFields.add('fromEpgu');
        this.sourceFields.add('fromPgu');
        this.sourceFields.add('fromMfc');
        this.sourceFields.add('fromStroyu');

        this.defaultSearchFields = new Set();
        this.defaultSearchFields.add('status.code');
        this.defaultSearchFields.add('unit.id');
        this.defaultSearchFields.add('subservices.version');
        this.defaultSearchFields.add('organizationExecutor.id');

        this._loadTranslations();
        this.getCurrentUrls();
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe((urlPath) => {
            this.baseUrl = urlPath[urlPath.length - 1].path;
        });

        // Параметры базового поиска
        // Если не отключено ограничение по текущему МФЦ
        const currentOrganization = this.storage.getItem('currentOrganization');
        let usedUnitIds = [currentOrganization._id];

        if (
            Array.isArray(currentOrganization.childrenOrganizationsIds) &&
            currentOrganization.childrenOrganizationsIds.length > 0
        ) {
            usedUnitIds = usedUnitIds.concat(currentOrganization.childrenOrganizationsIds);
        }

        this.baseSearch = [
            {
                field: 'unit.id',
                operator: 'in',
                value: usedUnitIds,
            },
        ];

        if (this.selectionService.isProcessSelect && Array.isArray(this.selectionService.baseSearch)) {
            this.baseSearch.push(...this.selectionService.baseSearch);
        }

        // Получение
        this.route.data.subscribe(async (response) => {
            this.appeals = this.appealService.processAppealsProperties(response.resolves.appeals);
            this.metaReglament = response.resolves.metaReglament;
            this.appealService.metaReglament = this.metaReglament;

            this.uploadListButtonAllowed =
                response.uploadListButtonAllowed &&
                (await this._checkMetaReglamentHasLicenseReportMaker(response.metaReglamentCode));

            if (this.uploadListButtonAllowed) {
                this.metaReglamentCode = response.metaReglamentCode;
            }

            let filtersStructure = null;
            // Панель фильтрации
            let operationTypes = RegistersService.OperationTypes.map((item: any) => {
                return { ...item, background: 'bg-' + item.theme + '-300' };
            });

            if (this.metaReglament) {
                const appealData = this.metaReglament.blocks.find((item) => item.code === 'appealData');

                this.allowAdd = !appealData || (appealData && appealData.allowAddAppeal);

                if (appealData.appealsFilters && appealData.appealsFilters.length > 0) {
                    filtersStructure = appealData.appealsFilters;
                }

                const registersModel = this.metaReglament.blocks.find((item) => item.code === 'registersModel');

                if (registersModel && registersModel.operationTypes) {
                    operationTypes = registersModel.operationTypes.map((item: any) => {
                        return { ...item, background: 'bg-' + item.theme + '-300' };
                    });
                }
            }

            this.statuses = this.getStatuses();
            this.filtersPanelItems = this.filtersService.getFilters(
                this.appealService.getAppealsCollection(),
                {
                    statuses: this.statuses,
                    operationTypes: operationTypes,
                },
                filtersStructure
            );

            const user = this.storage.getItem('user');
            const externalAuth = this.storage.getItem('isExternalAuth');
            const isSuperAdmin = externalAuth && user.isSuperAdmin;
            const existNoLimitPermission = this.accessService.existPermission(
                this.permissions.No_Limit_By_Current_Unit
            );

            if (
                this.filtersPanelItems &&
                this.filtersPanelItems.length &&
                this.filtersPanelItems[0] &&
                (existNoLimitPermission || isSuperAdmin)
            ) {
                this.filtersPanelItems[0].data.unshift({
                    name: 'Все организации',
                    filters: [
                        {
                            fields: ['unit.id'],
                            type: 'checkbox',
                            actionType: 'noLimitByUnit',
                            placeholder: 'Не фильтровать',
                            shortName: 'Вкл.',
                        },
                    ],
                });
            }

            this.processingRegistryTypes(response);

            // Настройки для групповых операций
            this._selectAppeals();
            this.appealService.initRoutingPaths(this.moduleBaseUrl);
            // this._getSearchCriteria();

            while (!this.filtersPanel.insideFilters.length) {
                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 0);
                });
            }

            await this.loadData(this.REPLACE);
            this.initialized = true;
            // this.totalAppealsCount = this.rest.pagination.total;
            if (this.appeals.length > 0) {
                this.clickByAppeal(this.appeals[0]);
            }
        });
    }

    private async _checkMetaReglamentHasLicenseReportMaker(metaReglamentCode) {
        if (!metaReglamentCode) {
            return false;
        }

        const params = {
            search: {
                search: [{ field: 'code', operator: 'eq', value: metaReglamentCode }],
            },
        };
        const metaReglaments = await this.restService.findAll('metaReglaments', params);
        if (metaReglaments && metaReglaments.length) {
            const metaReglament = metaReglaments[0];

            return (
                metaReglament.reportMaker &&
                metaReglament.reportMaker.length &&
                metaReglament.reportMaker.some((item) => item.type === 'licenses')
            );
        }

        return false;
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals', 'reports', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public processingRegistryTypes(response) {
        if (response.applicationProperty) {
            const filtersData = this.utility.prepareFilterByApplicationProperty(
                response,
                'registryTypes',
                'subservices.mainElement.registryEntryType.code',
                this.moduleBaseUrl
            );

            if (filtersData && filtersData.filters.length > 0) {
                if (filtersData.filters.length > 0 && this.filtersPanelItems && this.filtersPanelItems[0]) {
                    this.filtersPanelItems[0].data.unshift({
                        name: 'Вид лицензии',
                        filters: filtersData.filters,
                    });
                }
                if (filtersData.baseSearch) {
                    this.baseSearch.push(filtersData.baseSearch);
                }
            }
        }
    }

    public getCurrentUrls() {
        const arr = this.router.url.split('/');
        this.routeUrls = arr.slice(1, arr.length - 1);
    }

    public getStatuses() {
        if (this.metaReglament) {
            let appealStatuses;
            if (this.metaReglament.isOldVersion) {
                appealStatuses = this.metaReglament.appealStatuses;
            } else {
                const appealData = this.metaReglament.blocks.find((item) => item.code === 'appealData');
                if (appealData && appealData.appealStatuses && appealData.appealStatuses.length > 0) {
                    appealStatuses = appealData.appealStatuses;
                }
            }
            if (appealStatuses.length > 0) {
                return appealStatuses.map((item) => ({
                    code: item.code,
                    name: item.name,
                    theme: item.theme,
                    background: 'bg-' + item.theme + '-300',
                }));
            }
        }

        return CommonAppealData.statuses;
    }

    /**
     * Индексирование дел в списке
     * @param index
     * @param appeal - дело
     */
    public indexedAppeals(index, appeal) {
        return appeal._id;
    }

    /**
     * Обработка одинарного клика по карточке дела - загрузка карточки предпросмотра
     * @param appeal - обрабатываемое дело
     */
    public clickByAppeal(appeal) {
        this.appeal = null;
        this.timer = setTimeout(async () => {
            if (!this.prevent) {
                this.previewMode = true;
                this.ref.tick();
                let collectionName = this.appealService.getAppealsCollection();
                if (appeal.status.code === 'archive') {
                    collectionName = collectionName + 'Archive';
                }
                this.appeal = await this.rest.find(collectionName, appeal._id);
            }
            this.prevent = false;
        }, 300);
    }

    /**
     * Обработка двойного клика по карточке дела - переход к редактированию дела
     * @param appeal - обрабатываемое дело
     */
    public async dblclickByAppeal(appeal) {
        // Если список дел открыт в режиме выбора дел, то не позволяем открывать дело на редактирование
        if (this.selectionService.isProcessSelect) {
            appeal.selected = !appeal.selected;

            if (appeal.selected) {
                if (this.selectionService.selectType === 'one') {
                    this.appeals.map((item) => (item.selected = item._id === appeal._id));
                    this.selectionService.selectedItems = [appeal];
                } else {
                    this.selectionService.selectedItems.push(appeal);
                }
            } else {
                const findIndex = this.selectionService.selectedItems.findIndex((item) => item._id === appeal._id);

                if (findIndex !== -1) {
                    this.selectionService.selectedItems.splice(findIndex, 1);
                }
            }
        } else if (!this.appealService.groupOperation) {
            clearTimeout(this.timer);
            this.prevent = true;
            await this.router.navigate(this.routeUrls.concat([this.baseUrl, 'edit', appeal._id]));
        }
    }

    /**
     * Определение классов для карточки дела в соответствии с его статусом
     * @param item - дело
     * @param last - признак последнего элемента в списке
     * @returns {any}
     */
    public appealCardStyle(item, last) {
        let classes = 'short border-left-4 border-right-4';
        if (item.status) {
            // Если обрабатываемое дело выбрано для предпросмотра
            if (this.appeal && this.appeal.guid === item.guid) {
                classes += ' ' + this.appealStatusService.getStatusProperty(item.status, 'borderLeft');
                classes += ' active';
            }
        }
        if (last) {
            classes += ' border-bottom border-bottom-default';
        }

        return classes;
    }

    /**
     * Поиск по нажатию клавиши Enter после ввода поискового запроса (глобальный поиск)
     */
    public async search(event?) {
        if (event && event.keyCode === 13) {
            await this.loadData('replace');
        }
    }

    /**
     * Очистка поискового запроса
     */
    public async clearSearch() {
        this.globalSearch = null;
        await this.loadData('replace');
    }

    /**
     * Определение свойства для статуса дела
     * @param item
     * @param property
     * @returns {any}
     */
    public getStatusProperty(item, property) {
        return this.appealStatusService.getStatusProperty(item.status, property, this.statuses);
    }

    /**
     * Определение стиля Label для карточки дела в соответствии с его статусом
     * @param item - дело
     * @returns {any | string}
     */
    public colorLabelStatuses(item) {
        const code = item.status.code ? item.status.code : item.status.mainStatusCode;

        return this.appealService.getStatusProperty(code, 'background', this.statuses);
    }

    /**
     * Расчет ширины Label в карточке дела
     * @param item - дело
     * @returns {string}
     */
    public widthLabel(item) {
        if (item.status && (item.status.code === 'issuedPost' || item.status.code === 'issuedReturnToOGV')) {
            return 155;
        }

        return 115;
    }

    public progressContainerWidth(item) {
        return 'calc(100% - ' + this.widthLabel(item) + 'px)';
    }

    /**
     * Получение наименования статуса дела (с учетом возможного нахождения дела в подстатусе)
     * @param item - дело
     */
    public getStatusName(item) {
        if (item.status.code) {
            return item.status.name;
        } else if (item.status.mainStatusCode) {
            const status = this.appealService.statuses.find((st) => item.status.mainStatusCode === st.code);

            return status.name;
        }
    }

    /**
     * Создание нового дела (переход на страницу с выбором услуги)
     */
    public async createNewDeal() {
        await this.router.navigate(this.routeUrls.concat([this.baseUrl, 'services']));
    }

    /**
     * КНМ на то, что осуществляется поиск по коллекции архивных дел
     * @param search
     * @returns {boolean}
     */
    private _checkArchiveAppealsSearch(search) {
        let isArchiveSearch = false;
        search.forEach((item) => {
            if (item.field && item.field === 'status.code' && item.value === 'archive') {
                isArchiveSearch = true;
            } else if (item.orSubConditions) {
                const value = this._checkArchiveAppealsSearch(item.orSubConditions);
                isArchiveSearch = value ? value : isArchiveSearch;
            }
        });

        return isArchiveSearch;
    }

    public async setSortOptsAndLoadData(sortOpts) {
        this.sortOpts = sortOpts;
        await this.loadData(LOAD_DATA_MODES.REPLACE, sortOpts);
    }

    /**
     * Метод получения дел после фильтрации, либо скроллинга
     * @param type: replace - полное обновление списка; add - добавление записей в конец списка (скроллинг)
     * @param sortOpts - поле и порядок сортировки
     * @param disableDraftsOnDefaultSortField - скрывать ли черновики в этой выборке (для фитрации по datePlaneFinish, когда это поле null и черновики отображаются вначале)
     */
    public async loadData(type, sortOpts = this.sortOpts, disableDraftsOnDefaultSortField = true) {
        const noPagesOrNotLastPage = !this.totalPages || this.currentPage < this.totalPages - 1;
        const isReplace = type === LOAD_DATA_MODES.REPLACE;

        if (!isReplace && !noPagesOrNotLastPage) {
            return;
        }

        // tslint:disable-next-line:prefer-const
        let { searchCollection, countSearch } = this._prepareSearchClause();

        const searchHasFilterSettings = this._searchHasFilterSettings(this.searchCriteria);

        if (!searchHasFilterSettings) {
            const sortFieldNotNullClause = {
                field: sortOpts.field,
                operator: 'neq',
                value: null,
            };

            this.searchCriteria.push(sortFieldNotNullClause);
        }

        // Страница
        let page = 0;
        if (type === LOAD_DATA_MODES.ADD) {
            page = this.currentPage + 1;
        }

        // Параметры сортировки
        const sort = `${sortOpts.field || DEFAULT_SPO_SORT_FIELD}${sortOpts.order ? ',' + sortOpts.order : ''}`;
        const params = {
            search: {
                search: this.searchCriteria,
            },
            size: APPEALS_DEFAULT_LIST_SIZE,
            page,
            sort,
            prj: DEFAULT_APPEALS_PROJECTION,
        };

        // Обработка параметров глобального поиска
        if (this.globalSearch) {
            params.search['textSearch'] = this.globalSearch;
        }

        // Получение списка дел с сервера
        const appeals: any = await this.rest.search(searchCollection, params);

        this.currentPage = this.rest.pagination.page;
        this.totalPages = this.rest.pagination.totalPages;

        if (type === LOAD_DATA_MODES.REPLACE) {
            if (appeals.length || searchHasFilterSettings) {
                this.appeals = appeals;
            } else if (this.appeals.length && disableDraftsOnDefaultSortField) {
                await this.loadData(LOAD_DATA_MODES.REPLACE, sortOpts, false);

                return;
            }
        } else if (appeals.length) {
            appeals.forEach((item) => {
                this.appeals.push(item);
            });
        }

        this.appeals = this.appealService.processAppealsProperties(this.appeals);
        this._selectAppeals();

        if (sortOpts && sortOpts.field && !searchHasFilterSettings) {
            const countParams = cloneDeep(params);
            countParams.search.search = countSearch;
            await this.rest.search(searchCollection, countParams);
        }

        if (this.appeals.length === 1) {
            this.clickByAppeal(this.appeals[0]);
        }

        this.currentPage = this.rest.pagination.page;
        this.totalPages = this.rest.pagination.totalPages;
        this.totalAppealsCount = this.rest.pagination.total;

        // если нет необходимости догрузить дела (черновики в конце, к примеру) - завершаем функцию
        if (searchHasFilterSettings || (appeals && appeals.length >= APPEALS_DEFAULT_LIST_SIZE)) {
            return;
        }

        // убираем условия, связанные с полями сортировки и указываем явно, что хотим искать по таким нулевым полям
        this.searchCriteria = this.searchCriteria.filter(
            (clause) => !(clause.field === sortOpts.field && clause.operator === 'neq' && !clause.value)
        );
        const sortFieldNullClause = {
            field: sortOpts.field,
            operator: 'eq',
            value: null,
        };
        this.searchCriteria.push(sortFieldNullClause);

        this.rest.pagination.page = 0;
        params.search.search = this.searchCriteria;

        let nullFieldAppeals;
        let currentNullPage = 0;
        let totalNullPages = 0;
        let notOnLastNullPage;

        // подгрузка дел с нулевыми полями сортировки
        do {
            params.page = currentNullPage;
            nullFieldAppeals = await this.rest.search(searchCollection, params);
            const processedNullFieldAppeals = this.appealService.processAppealsProperties(nullFieldAppeals);
            this.appeals = this.appeals.concat(processedNullFieldAppeals);

            currentNullPage = this.rest.pagination.page;
            totalNullPages = this.rest.pagination.totalPages;
            currentNullPage++;

            notOnLastNullPage = currentNullPage + 1 <= totalNullPages;
        } while (notOnLastNullPage);
    }

    private _prepareSearchClause() {
        this.searchCriteria = this.filtersPanel.prepareFilters();

        // Проверяем наличие в поисковых фильтрах фильтрации настроек по архивированным делам
        let searchCollection = this.appealService.getAppealsCollection();
        const searchInArchive = this._checkArchiveAppealsSearch(this.searchCriteria);
        if (searchInArchive) {
            searchCollection = searchCollection + 'Archive';
        }

        const countSearch = cloneDeep(this.searchCriteria);

        return { searchCollection, countSearch };
    }

    private _searchHasFilterSettings(search) {
        return (
            search &&
            search.some(
                (query) =>
                    this._hasNonDefaultFilters(query) ||
                    this._hasPlaneFinishFilter(query) ||
                    this._hasSourceFilter(query)
            )
        );
    }

    private _hasNonDefaultFilters(query) {
        return (
            (query.field && !this.defaultSearchFields.has(query.field)) ||
            (query.orSubConditions &&
                query.orSubConditions.some((item) => {
                    if (item.field === 'status.code') {
                        return item.value !== APPEAL_STATUSES.DRAFT && item.value !== APPEAL_STATUSES.PROCESS;
                    }

                    return !this.defaultSearchFields.has(item.field);
                }))
        );
    }

    private _hasPlaneFinishFilter(query) {
        return query.andSubConditions && query.andSubConditions.some((item) => item.field === 'datePlaneFinish');
    }

    private _hasSourceFilter(query) {
        return (
            (query.field && this.sourceFields.has(query.field)) ||
            (query.orSubConditions && query.orSubConditions.some((item) => this.sourceFields.has(item.field)))
        );
    }

    // ------------- МЕТОДЫ ОБРАБОТКИ ГРУППОВЫХ ОПЕРАЦИЙ ------------- //

    private _selectAppeals() {
        this.appeals.forEach((appeal) => {
            if (this.appealService.groupOperation) {
                this._checkForSelect(appeal);
            } else {
                appeal.selected = false;
            }
        });
    }

    public getSelectedAppealsCount() {
        const find = this.appeals.filter((item) => item.selected);

        return find.length;
    }

    /**
     * Добавление / удаление краткой информации по дела в / из состава дел групповой операции
     * @param appeal - обрабатываемое дело
     */
    public addOrRemoveShortAppeal(appeal) {
        appeal.selected = !appeal.selected;

        // Если включен режим выбора дел
        if (
            appeal.selected &&
            !this.appealService.groupOperation &&
            !this.appealService.isProcessSelectAppealsForPacket
        ) {
            this.appealService.groupOperation = { appeals: [] };
        }
        // Если активирован режим выбора дел для пакета
        if (this.appealService.isProcessSelectAppealsForPacket) {
            this._processAppealToPacket(appeal);
        }
        if (this.appealService.groupOperation) {
            // Дело выбрано - создаем его краткий объект и помещаем в общую коллекцию
            if (appeal.selected) {
                this._createAndAddShortAppeal(appeal);
            } else {
                // Дело не выбрано - находим его индекс и удаляем из общей коллекции
                const appealIndex = this.appealService.groupOperation.appeals.findIndex(
                    (item) => item._id === appeal._id
                );
                this.appealService.groupOperation.appeals.splice(appealIndex, 1);
                if (
                    !this.appealService.groupOperation.status &&
                    this.appealService.groupOperation.appeals.length === 0
                ) {
                    this.appealService.groupOperation = null;
                }
            }
        }
    }

    /**
     * Добавление / удаление дела в состав дел пакета
     * @param appeal
     */
    private _processAppealToPacket(appeal) {
        const findIndex = this.appealService.packet.appeals.findIndex((item) => item.id === appeal._id);
        if (!appeal.selected && findIndex !== -1) {
            this.appealService.packet.appeals.splice(findIndex, 1);
        } else if (appeal.selected && (findIndex === -1 || findIndex === undefined)) {
            const appealForAdd = {
                id: appeal._id,
                subservice: {
                    id: appeal.subservice.id,
                    title: appeal.subservice.shortTitle,
                },
                shortNumber: appeal.shortNumber,
            };

            this.appealService.packet.appeals.push(appealForAdd);
        }
    }

    /**
     * Создание нового короткого дела
     * @param appeal - либо проекция, либо полное дело
     */
    private _createAndAddShortAppeal(appeal) {
        const shortAppeal = {
            _id: appeal._id,
            auid: appeal.auid,
            shortNumber: appeal.shortNumber,
            complexTitle: appeal.complexSubservice ? appeal.complexSubservice.name : '',
            firstSubserviceTitle: appeal.subservice ? appeal.subservice.shortTitle : '',
            subservicesTitles: [appeal.subservice.shortTitle],
        };

        this.appealService.groupOperation.appeals.push(shortAppeal);
    }

    /**
     * Переход на создание новой сущности, для которой отмечались дела и создавались краткие объекты
     * @param type
     */
    public async createNewObjectByShortAppealList(type) {
        // Обновляем список коротких дел в сервисе
        if (type === 'groupOperation') {
            if (!this.appealService.groupOperation._id) {
                // Открываем новую групповую операцию
                await this.router.navigate(['/ais/group-operations/create']);
            } else {
                // Переходим в уже созданну (сохраненную) групповую операцию
                await this.router.navigate(['/ais/group-operations/edit', this.appealService.groupOperation._id]);
            }
        } else if (type === 'transportAppeal') {
            // todo: переход на передачу дел
        }
    }

    /**
     * КНМ на то, что дело отмечено для помещения в объект
     * @param appeal
     * @returns {boolean}
     */
    private _checkForSelect(appeal) {
        appeal.selected = false;
        if (this.appealService.groupOperation && this.appealService.groupOperation.appeals) {
            const find = this.appealService.groupOperation.appeals.find((item) => item._id === appeal._id);
            if (find) {
                appeal.selected = true;
            }
        }
    }

    /**
     * Выбор (снятие выбора) всех дел по заданным фильтрам и получение кратких дел
     */
    public async selectAllAppeals() {
        this.isAllAppealsSelected = !this.isAllAppealsSelected;
        if (this.isAllAppealsSelected) {
            // Параметры настроенных фильтров
            const search = this.filtersPanel.prepareFilters();
            const params = {
                search: { search: search, textSearch: '' },
                page: 0,
                size: 1000,
                sort: 'datePlaneFinish',
                prj: 'shortAppealList',
            };

            // Обработка параметров глобального поиска
            if (this.globalSearch) {
                params.search['textSearch'] = this.globalSearch;
            }

            const shortAppeals = await this.rest.search('appeals', params);
            this.appealService.groupOperation.appeals = [];
            shortAppeals.forEach((shortAppeal) => {
                this._createAndAddShortAppeal(shortAppeal);
            });
        }

        this._selectAppeals();
    }

    /**
     * Очистка всех фильтров в панели фильтров
     */
    public clearFilters() {
        this.clearSearch();
        this.filtersPanel.clearFilters();
    }

    /**
     * Возврат к режиму редактирования / создания пакета после выбора дел
     */
    public async returnToPacket() {
        if (this.appealService.packet) {
            if (this.appealService.packet._id) {
                await this.router.navigate(['ais/packets/edit', this.appealService.packet._id]);

                return;
            }

            await this.router.navigate(['ais/packets/create']);
        }
    }

    public async returnToOperation() {
        if (this.selectionService.isProcessSelect) {
            await this.router.navigate(this.selectionService.transferBackUrl);
        }
    }
}
