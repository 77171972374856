import { Injectable } from '@angular/core';
import { IStatus } from '../../interfaces/statuses.interface';

@Injectable()
export class ReportsDataSource {
    public statuses: IStatus[] = [
        {
            code: 'draft',
            background: 'bg-purple-300',
            borderTop: 'border-top-purple-400',
            name: 'Черновик',
        },
        {
            code: 'processing',
            background: 'bg-orange-300',
            borderTop: 'border-top-orange-400',
            label: 'bg-orange-50 text-orange',
            name: 'В обработке',
        },
        {
            code: 'error',
            background: 'bg-danger-300',
            borderTop: 'border-top-danger-400',
            label: 'bg-danger-50 text-danger',
            name: 'Ошибка',
        },
        {
            code: 'completed',
            background: 'bg-success-300',
            borderTop: 'border-top-success-400',
            label: 'bg-success-50 text-success',
            name: 'Сформирован',
        },
        {
            code: 'sended',
            background: 'bg-teal-300',
            borderTop: 'border-top-teal-400',
            name: 'Отправлен',
        },
        {
            code: 'starting',
            background: 'bg-primary-300',
            borderTop: 'border-top-primary-400',
            name: 'Формируется',
        },
        {
            code: 'signed',
            background: 'bg-warning-300',
            borderTop: 'border-top-warning-400',
            label: 'bg-warning-50 text-warning',
            name: 'Подписан',
        },
        {
            code: 'notSent',
            background: 'bg-info-300',
            borderTop: 'border-top-info-400',
            label: 'bg-info-50 text-info',
            name: 'Не отправлен',
        },
    ];
}
