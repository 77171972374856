<div class="width-100"
     *ngIf="schema">
    <jsonform name="envelopeForm"
              [obj]="schema"
              (completedForm)="saveForm($event)"
              (errorForm)="handleFormErrors($event)"
              [completeWithErrors]="true">
        <form id="jsonForm{{envelope.guid}}"
              class="jsonform"
              novalidate
              [attr.data-validation]="isValidate"
              #jsonform>
            <button id="jsonFormBtn{{envelope.guid}}"
                    class="btn btn-primary"
                    style="display: none;">
                {{ localizations.common.save }}
            </button>
        </form>
    </jsonform>
</div>

<ng-container *ngIf="isXsdForm">
    <dynamic-form
        tabsMode="none"
        [xsd]="xsd"
        [edit]="false"
        [model]="envelope.xml"
        [saveInMainObject]="true"
        [disabled]="envelope.status === 'closed' || envelope.status === 'sended'"
        #additionalDataComponent>
    </dynamic-form>
</ng-container>

<div class="text-info text-size-small"
     *ngIf="!envelope.request?.xsd">
    <i class="icon-exclamation"></i>
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ this.localizations['envelope-edit'].no_additional_data_required }}
</div>

<evolenta-cades-sign-modal
    [getCertificateData]="false"
    (onSignCompletedEnvelope)="onSignatureCompleteForEnvelope($event)"
    #cadesModal>
</evolenta-cades-sign-modal>
