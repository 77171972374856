<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Административное производство</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Регистрационный номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указан</em>

            <span class="text-muted ml-10">Дата составления: </span>
            <ng-container *ngIf="entity.date && !entity.date.formatted">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date || entity.date && entity.date.formatted">не указана</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Регистрационный номер</label>
                                <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" #number="ngModel" required>
                                <div *ngIf="isProcessValidate && number.errors" class="validation-error-label">
                                    <span *ngIf="number.errors.required">Поле "Регистрационный номер" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата составления</label>
                                <evolenta-datepicker name="date" [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" #date="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && date.errors" class="validation-error-label">
                                    <span *ngIf="date.errors.required">Поле "Дата составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Место составления</label>
                                <evolenta-address-gar
                                        name="place"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.place"
                                        required
                                        #place="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && place.errors" class="validation-error-label">
                                    <span *ngIf="place.errors.required">Поле "Место составления" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Виновник</label>
                                <catalogue catalogName="culprit" name="culprit" [(ngModel)]="entity.culprit" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Данные документа, удостоверяющего личность (номер)</label>
                                <input type="text" name="documentNumber" class="form-control" [(ngModel)]="entity.documentNumber">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Данные документа, удостоверяющего личность (серия)</label>
                                <input type="text" name="documentSeries" class="form-control" [(ngModel)]="entity.documentSeries">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Дата рождения</label>
                                <evolenta-datepicker name="birthday" [large]="true" [(ngModel)]="entity.birthday" [valueAsString]="true"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Место рождения</label>
                                <evolenta-address-gar
                                        name="placeBirth"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.placeBirth"
                                ></evolenta-address-gar>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Адрес проживания (нахождения)</label>
                                <evolenta-address-gar
                                        name="locationAddress"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.locationAddress"
                                        required
                                        #locationAddress="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && locationAddress.errors" class="validation-error-label">
                                    <span *ngIf="locationAddress.errors.required">Поле "Адрес проживания (нахождения)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <div class="form-group form-group-lg">
                                <label>Должность (для должностного лица)</label>
                                <input type="text" name="position" class="form-control" [(ngModel)]="entity.position" #position="ngModel" required>
                                <div *ngIf="isProcessValidate && position.errors" class="validation-error-label">
                                    <span *ngIf="position.errors.required">Поле "Должность (для должностного лица)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-8">
                            <div class="form-group form-group-lg">
                                <label>Место работы и адрес (для должностного лица)</label>
                                <evolenta-address-gar
                                        name="placeWork"
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.placeWork"
                                        required
                                        #placeWork="ngModel"
                                ></evolenta-address-gar>
                                <div *ngIf="isProcessValidate && placeWork.errors" class="validation-error-label">
                                    <span *ngIf="placeWork.errors.required">Поле "Место работы и адрес (для должностного лица)" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Событие административного правонарушения с указанием времени и места его совершения</label>
                                <textarea name="offenseEvent" class="form-control" [(ngModel)]="entity.offenseEvent" #offenseEvent="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && offenseEvent.errors" class="validation-error-label">
                                    <span *ngIf="offenseEvent.errors.required">Поле "Событие административного правонарушения с указанием времени и места его совершения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Статья КоАП</label>
                                <catalogue catalogName="koap" name="coap" [(ngModel)]="entity.coap" [priority]="true" [large]="true" [disabled]="true"></catalogue>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>ФИО физического лица или законного представителя юридического лица</label>
                                <input type="text" name="individual" class="form-control" [(ngModel)]="entity.individual" #individual="ngModel" required>
                                <div *ngIf="isProcessValidate && individual.errors" class="validation-error-label">
                                    <span *ngIf="individual.errors.required">Поле "ФИО физического лица или законного представителя юридического лица" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>ФИО защитника</label>
                                <input type="text" name="defender" class="form-control" [(ngModel)]="entity.defender" #defender="ngModel" required>
                                <div *ngIf="isProcessValidate && defender.errors" class="validation-error-label">
                                    <span *ngIf="defender.errors.required">Поле "ФИО защитника" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Реквизиты документа, подтверждающего полномочия защитника</label>
                                <textarea name="detailsDefender" class="form-control" [(ngModel)]="entity.detailsDefender" #detailsDefender="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && detailsDefender.errors" class="validation-error-label">
                                    <span *ngIf="detailsDefender.errors.required">Поле "Реквизиты документа, подтверждающего полномочия защитника" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Дата рассмотрения дела</label>
                                <evolenta-datepicker name="caseDate" [large]="true" [(ngModel)]="entity.caseDate" [valueAsString]="true" #caseDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && caseDate.errors" class="validation-error-label">
                                    <span *ngIf="caseDate.errors.required">Поле "Дата рассмотрения дела" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Время рассмотрения дела</label>
                                <input type="text" name="caseTime" class="form-control" [(ngModel)]="entity.caseTime" #caseTime="ngModel" required>
                                <div *ngIf="isProcessValidate && caseTime.errors" class="validation-error-label">
                                    <span *ngIf="caseTime.errors.required">Поле "Время рассмотрения дела" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
