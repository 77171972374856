<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <!-- Если запрещено одно из действий или запрещены оба -->
    <ng-container *ngIf="!allowEdit || !allowSend">
        <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
                type="button"
                *ngIf="!allowSend"
                [disabled]="!allowEdit"
                (click)="save(false)">
            <b>
                <i class="icon-checkmark-circle"
                   [ngClass]="{'flash': isProcessSave}">
                </i>
            </b>
            {{ localizations.common.save }}
        </button>
        <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
                type="button"
                *ngIf="allowSend"
                (click)="save(true)">
            <b>
                <i class="icon-checkmark-circle"
                   [ngClass]="{'flash': isProcessSend}">
                </i>
            </b>
            {{ localizations.common.send }}
        </button>
    </ng-container>

    <!-- Если разрешены оба действия -->
    <ng-container *ngIf="allowEdit && allowSend">
       <div class="btn-group btn-group-main">
            <button class="btn dropdown-toggle btn-success btn-labeled btn-labeled-right btn-xs btn-main ml-20"
                    type="button"
                    data-toggle="dropdown"
                    [disabled]="isProcessSave || isProcessSend">
                <b>
                    <i [ngClass]="{
                        'icon-menu7': !isProcessSave && !isProcessSend,
                        'icon-spinner2 spinner': isProcessSave || isProcessSend
                        }"></i>
                </b>
                {{ localizations['envelope-edit'].action }}
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
                <li>
                    <a (click)="save(false)">
                        {{ localizations.common.save }}
                    </a>
                </li>
                <li>
                    <a (click)="save(true)">
                        {{ localizations.common.send }}
                    </a>
                </li>
            </ul>
        </div>
    </ng-container>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            {{ getActionMessage() }}
            <ng-container *ngIf="envelope.auid">
                № {{envelope.auid}}
            </ng-container>

            <span class="badge text-uppercase ml-15 no-margin-bottom"
                  [ngClass]="['bg-' + statusProperty(envelope.status,'theme') + '-600']">
                {{statusProperty(envelope.status,'name')}}
            </span>
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content">
            <div class="sidebar-category"
                 (click)="activeTab = 'main'"
                 [ngClass]="{
                    'active': activeTab == 'main',
                     'cursor-pointer' : activeTab != 'main'
                  }">
                <div class="category-title">
                    <span>
                        {{ localizations['envelope-edit'].general_info }}
                    </span>
                </div>
            </div>
            <div class="sidebar-category"
                 [ngClass]="{
                    'active': activeTab == 'data',
                    'disabled': !envelope.request,
                    'cursor-pointer' : activeTab != 'data'
                  }"
                 *ngIf="envelope.request"
                 (click)="activeTab = 'data'">
                <div class="category-title">
                    <span>
                        {{ localizations['envelope-edit'].request_data }}
                    </span>
                </div>
            </div>
            <div class="sidebar-category"
                 (click)="activeTab = 'object'"
                 *ngIf="envelope.request && !envelope.request.disableParticipants"
                 [ngClass]="{
                    'active': activeTab == 'object',
                    'disabled': !envelope.request,
                    'cursor-pointer' : activeTab != 'object'
                 }">
                <div class="category-title">
                    <span>
                        {{ localizations['envelope-edit'].subject }}
                    </span>
                </div>
            </div>
            <div class="sidebar-category"
                 *ngIf="envelope._id"
                 [ngClass]="{
                    'active': activeTab == 'documents',
                    'disabled': !envelope.request,
                    'cursor-pointer' : activeTab != 'documents'
                  }"
                 (click)="activeTab = 'documents'">
                <div class="category-title">
                    <span>
                        {{ localizations.appeals.edit.documents }}
                    </span>
                </div>
            </div>
            <div class="sidebar-category"
                 *ngIf="envelope._id"
                 [ngClass]="{
                    'active': activeTab == 'soapDumps',
                    'disabled': !envelope.request,
                    'cursor-pointer' : activeTab != 'soapDumps'
                 }"
                 (click)="activeTab = 'soapDumps'">
                <div class="category-title">
                    <span>
                        Soap Dumps
                    </span>
                </div>
            </div>
            <div class="sidebar-category"
                 *ngIf="envelope.request && envelope.status === 'closed' && envelope.request.resultXsd"
                 [ngClass]="{
                    'active': activeTab == 'result',
                    'cursor-pointer' : activeTab != 'result'
                 }"
                 (click)="activeTab = 'result'">
                <div class="category-title">
                    <span>
                        {{ localizations.common.result_big }}
                    </span>
                </div>
            </div>
            <div class="sidebar-category"
                 *ngIf="envelope._id"
                 [ngClass]="{
                    'active': activeTab == 'history',
                    'disabled': !envelope.request,
                    'cursor-pointer' : activeTab != 'history'
                 }"
                 (click)="activeTab = 'history'">
                <div class="category-title">
                    <span>
                        {{ localizations.common.history_big }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-radius no-margin no-padding">
            <div class="panel panel-shadow m-20">
                <div class="panel-body no-padding"
                     [ngClass]="{'p-20':
                                activeTab == 'main' ||
                                activeTab == 'data' ||
                                activeTab == 'documents' ||
                                activeTab == 'soapDumps'
                     }">
                <!-- Общая информация -->
                <envelope-main-info [envelope]="envelope"
                                    [organizations]="organizations"
                                    [hidden]="activeTab != 'main'"
                                    [appeal]="envelopeAppeal"
                                    [allowEdit]="allowEdit"
                                    (selectedRequest)="selectedRequestParent($event)">
                </envelope-main-info>

                <!-- Данные запроса -->
                <envelope-data [envelope]="envelope"
                               [modulePath]="moduleBaseUrl"
                               [hidden]="activeTab != 'data'"
                               *ngIf="envelope.request"
                               (errorOcurred)="handleErrorOcurred()"
                               (returnEnvelopeAterSaveOrSend)="getEnvelopeAfterSend($event)">
                </envelope-data>


                <!-- Документы запроса -->
                <envelope-documents [envelope]="envelope"
                                    [allowEdit]="allowEdit"
                                    [hidden]="activeTab != 'documents'"
                                    *ngIf="envelope._id">
                </envelope-documents>

                <!-- Soap-dumps -->
                <envelope-soapdumps [envelope]="envelope"
                                    [hidden]="activeTab != 'soapDumps'"
                                    *ngIf="envelope._id">
                </envelope-soapdumps>

                <!-- Результат ответа -->
                <div *ngIf="envelope.request && envelope.request.resultXsd && activeTab == 'result'" class="p-20">
                    <dynamic-form
                        [model]="envelope.resultXml"
                        [saveInMainObject]="true"
                        [xsd]="envelope.request.resultXsd"
                        [textView]="false">
                    </dynamic-form>
                </div>

                <!-- История изменения -->
                <history [collection]="'envelopes'"
                         [id]="envelope._id"
                         *ngIf="envelope._id && activeTab == 'history'">
                </history>
                </div>
            </div>
            <!-- Объект запроса -->
            <envelope-object *ngIf="activeTab === 'object' && envelope.request && !envelope.request.disableParticipants"
                             [envelope]="envelope"
                             [appeal]="envelopeAppeal"
                             (onApply)="save(false)"
                             #envelopeObjectComponent>
            </envelope-object>
        </evolenta-scrollbar>
    </div>
</div>
