
<div class="panel panel-preview" *ngIf="element">
    <div class="panel-heading no-border-radius no-border-left">
        <h6 class="panel-title">
            {{ getInfoAboutStandardMessage() }}
            <div *ngIf="element.status">
                <label
                    class="badge text-uppercase bg-{{ element.status.theme }}-300">
                    {{ element.status.name }}
                </label>
            </div>
        </h6>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled pt-10 pb-10"
                    (click)="goToStandard()">
                {{ localizations.common.proceed }}
            </button>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body">
                <fieldset>
                    <legend class="text-bold mb-10 pb-20">
                        <span>
                            {{ localizations.common.general_data }}
                        </span>
                        <a class="control-arrow">
                            <i class="main icon-arrow-down12"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10 in">
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.standards.name }}:
                            </div>
                            <div>
                                {{element.name}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.standards.short_name }}:
                            </div>
                            <div>
                                {{element.shortName}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.common.creation_date }}:
                            </div>
                            <div>
                                {{element.dateCreation | date: 'shortDate'}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10" *ngIf="element.dateApproved">
                            <div class="text-muted">
                                {{ localizations.common.approve_date }}:
                            </div>
                            <div>
                                {{element.dateApproved | date: 'shortDate'}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="element.file">
                            <div class="text-muted">
                                {{ localizations.standards.file }}:
                            </div>
                            <div>
                                <span class="text-info anchor"
                                      (click)="downloadFile()">
                                    {{ localizations.common.download_small }}
                                </span>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
