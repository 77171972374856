import {
    Component,
    OnInit,
    Input,
    TemplateRef,
    ViewChild,
    EventEmitter,
    Output,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilesService, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { PrintingService } from '@evolenta/printing';
import { CommonUtilities } from '@evolenta/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgProgress } from 'ngx-progressbar';
import { PrintFormsService } from '../../../../../common/services/print/print-forms.service';
import { DocumentService } from './document.service';
import { AppealValidateService } from '../../appeal-validate.service';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealDocumentCardComponent } from './components/document-card/appeal-document-card.component';
import { AppealSubservicesService } from '../../appeal-subservices.service';
import { AppealSubserviceDocumentGroupsComponent } from './components/document-groups/appeal-subservice-document-groups.component';
import { AppealService } from '../../appeal.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'documents',
    templateUrl: 'documents.component.html',
    styleUrls: ['documents.component.less'],
})
export class DocumentsComponent implements OnInit {
    @Input() public appeal: any; // Формируемое дело
    @Input() public subservice; // Услуга по которой формируется дело
    @Input() public printForms; // Массив печатных форм для дела
    @Input() public onlyGroups; // Выводить только группы указанные в этом массиве
    @Input() public task;
    @Input() public allowEdit = true;
    @Input() public feedbackMessageGuid;

    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)
    @Output() public onUpdateAppealAfterSendStatusEnvelope = new EventEmitter<boolean>(); // Передача управления в родительский компонент для обновления информации по делу после отправки запроса со сменой статуса

    public editedDocument = null; // Редактируемый документ дела
    public editedDocumentGroup = null; // Группа для редактируемого документа дела

    public activeTab; // Активная вкладка для передачи управления из режима просмотра в режим редактирования
    public prepareForPrintDocument = null; // Документ, по которому будет формироваться печатная форма

    public continueSendEnvelope = false; // Продолжить процесс отправки запроса из документа после сохранения дела

    @ViewChild('selectDocumentsModal', { static: false }) public selectDocumentsModal: TemplateRef<any>; // модальное окно для выбора документов дела
    @ViewChild('editCard', { static: false }) public editDocumentCardComponent: AppealDocumentCardComponent; // Компонент - карточка редактирования документа дела
    @ViewChildren('subserviceDocumentGroups')
    public documentGroupsComponents: QueryList<AppealSubserviceDocumentGroupsComponent>; // Группы документов услуг дела

    public groupsForModal; // группы документов для выбора из списка документов дела при формировании специальных печатных форм
    public modalRef: BsModalRef;

    // Параметры для выбора заявителя при формировании ПФ комплексной расписки
    public applicantsInComplexAppeal = []; // список заявителей в комплексном деле
    public selectedApplicant; // выбранный для использования в комплексной ПФ заявитель
    @ViewChild('selectApplicantModal', { static: false }) public selectApplicantModal: TemplateRef<any>; // модальное окно для выбора заявителей (доверителей)

    // Параметры для выбора данных для формирования ПФ - платежной квитанции
    public selectedPayer; // выбранный плательщик
    public selectedPayment; // выбранный платеж
    // Кастомная сумма оплаты
    public customPaymentSum = {
        rub: 0,
        kop: 0,
    };
    public rubMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    public kopMask = [/\d/, /\d/];

    @ViewChild('selectPaymentParamsModal', { static: false }) public selectPaymentParamsModal: TemplateRef<any>; // модальное окно для выбора платежа и плательщика

    public htmlPrintFormContent; // Содержимое сгенерированной html-формы
    @ViewChild('printHtmlFormModal', { static: false }) public printHtmlFormModal: TemplateRef<any>; // модальное окно для печати html-формы
    public editorOptions = {
        language: 'ru',
        height: 700,
        plugins: 'lists advlist print table code',
        toolbar: 'print | undo redo | bold italic | bullist numlist outdent indent | table | code',
    };
    public leave;
    public stay;
    public allowedToLeave = new Promise((resolve, reject) => {
        this.leave = resolve;
        this.stay = reject;
    });

    public localizations;

    public constructor(
        public documentService: DocumentService,
        public appealSubservicesService: AppealSubservicesService,
        public appealService: AppealService,
        private appealSaveService: AppealSaveService,
        private saveService: AppealSaveService,
        private validateService: AppealValidateService,
        private printFormService: PrintFormsService,
        private printingService: PrintingService,
        private toaster: ToasterService,
        private filesService: FilesService,
        private progressService: NgProgress,
        private modalService: BsModalService,
        private translate: TranslateService,
        private router: Router,
        private storage: StorageService
    ) {}

    /**
     * Инициализация компонента
     */
    public async ngOnInit() {
        this.appealService.editing = false;
        this._loadTranslations();
        this.appeal = this.appealService.appeal;
        if (!this.appeal) {
            const route = this.router.url.split('/documents')[0];
            this.storage.cacheItem('nextRoute', 'documents');
            await this.router.navigate([route]);
        } else {
            if (!this.subservice) {
                // так как компонет может быть так встроенным, так и основным компонентом стейта
                this.subservice = this.appealService.subservice;
                this.appealSaveService.nextSection = 'documents';
            } else {
                if (!this.appealSaveService.nextSection) {
                    this.appealSaveService.nextSection = 'documents';
                }
            }

            this.printForms = this.appealService.printForms;
            this.documentService.initData(this.appeal, this.subservice);
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.documents']).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public async ngOnDestroy() {
        await this.allowedToLeave;
    }

    /**
     * Перевод отдельного документа в режим редактирования
     * @param data - {document: документ на редактирование, group: группа редактируемого документа, tab: активная вкладка}
     */
    public editDocument(data) {
        if (!this.documentService.tempData) {
            this.documentService.tempData = _.cloneDeep(this.documentService.data);
        }
        this.editedDocument = data.document;
        this.editedDocumentGroup = data.group;
        if (data.tab) {
            this.activeTab = data.tab;
        }
    }

    /**
     * Применение / отмена изменений документа
     * @param data - объект вида {document: измененный документ, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - null в случае отмены изменений
     */
    public async applyDocument(data) {
        // if (data && !data.forceClose && data.document && data.document.feedbackDocumentUnchecked === false) {
        //     return;
        // }

        if (data) {
            // Применение изменений
            const documentIndex = this.appeal.documents.findIndex((item) => item.guid === data.document.guid);
            const documentAppealData = this.documentService.generateDocumentDataToAppeal(data.document.guid);
            documentAppealData.feedbackMessageGuid = this.feedbackMessageGuid;
            documentAppealData.feedbackMessageDocFromOperator = true;
            if (documentIndex !== -1) {
                this.appeal.documents[documentIndex] = documentAppealData; // Обновление данных документа
            } else {
                this.appeal.documents.push(documentAppealData); // Добавление документа в дело
            }
            // Передача управления в родительский компонент для продолжения операций с делом: сохранение, переход на новый статус
            if (data.continueProcessingAppeal) {
                this.onApply.emit(true);
            } else if (this.appeal._id) {
                // Сохранение данных на сервере
                try {
                    this.saveService.appeal.documents = this.appeal.documents;
                    let redirectAfterDocumentSave = true;
                    if (data && data.document && data.document.feedbackDocumentUnchecked === false) {
                        redirectAfterDocumentSave = false;
                    }

                    await this.saveService.saveAppeal(false, redirectAfterDocumentSave);
                    if (data.applyForPrint) {
                        await this._prepareSendPrintForm(documentAppealData);

                        return;
                    }

                    this.toaster.success('Документ успешно сохранен');
                } catch (error) {
                    this.toaster.error(error);
                }
            }
        } else {
            // Отмена изменений
            this.documentService.data = _.cloneDeep(this.documentService.tempData);
        }

        // Если применение данных вызвано с целью печати из карточки редактирования, не закрываем ее
        if (!data || !data.applyForPrint) {
            this.documentService.tempData = null;
            this.validateService.validateAppeal(this.validateService.processValidate);
            this.editedDocument = null;
            this.editedDocumentGroup = null;
        }
    }

    /**
     * Создание печатной формы
     * @param printForm
     */
    public async createDocumentFromPrintForm(printForm) {
        const docGuid = CommonUtilities.GenerateGuid();
        const document: any = {
            guid: docGuid,
            docGuid: docGuid,
            name: printForm.title || printForm.name,
            parentEntries: 'appeals.documents',
            subjects: [],
            printForm: { id: printForm._id, formType: printForm.formType, formTypeCode: printForm.formTypeCode },
        };
        if (this.appeal.status.code !== 'draft') {
            document.afterRegister = true;
        }
        await this.documentService.initDocumentDataFromAppeal(document);
        this.appeal.documents.push(document);
        const documentInAppeal = this.appeal.documents.find((item) => item.guid === docGuid);

        this._prepareSendPrintForm(documentInAppeal);
    }

    /**
     * КНМ необходимости выбора документов перед формированием печатной формы
     * @param document
     */
    private async _prepareSendPrintForm(document) {
        if (document.printForm && document.printForm.formTypeCode === 'payment' && !document.payment) {
            const needSelectPayment = this.appealService.appealPayments && this.appealService.appealPayments.length > 1;
            const needSelectPayer = this.appeal.subject.length > 1;
            if (this.appealService.appealPayments && this.appealService.appealPayments.length === 1) {
                document.payment = _.cloneDeep(this.appealService.appealPayments[0]);
            }

            if (needSelectPayment || needSelectPayer || (document.payment && document.payment.amountKop === 0)) {
                // Отображение модального окна для выбора платежей или плательщиков
                this.prepareForPrintDocument = document;
                this.selectedPayer = null;
                this.selectedPayment = null;
                this.modalRef = this.modalService.show(this.selectPaymentParamsModal, { backdrop: 'static' });
            } else {
                await this.sendPrintForm(document);
            }
        } else if (this._checkPrintFormWithSelectedDocuments(document)) {
            this.prepareForPrintDocument = document;
            this._prepareSelectDocumentsForPrintForm();
        } else {
            const applicantsInComplexAppeal = this._checkMultipleApplicantsInAppeal(document);
            // Если формируется печатная форма комплексного дела и в деле несколько зявителей (доверителей)
            if (!applicantsInComplexAppeal.length) {
                return this.sendPrintForm(document);
            }

            if (applicantsInComplexAppeal.length === 1) {
                // Если один заявитель в деле, то модальное окно не нужен
                return this.sendPrintForm(document, [], applicantsInComplexAppeal[0]);
            }

            this.prepareForPrintDocument = document; // сохраняем печатаемый документ в переменную
            this.applicantsInComplexAppeal = this.appeal.subjects.filter(
                (item) => applicantsInComplexAppeal.indexOf(item.guid) !== -1
            ); // выбираем из объектов дела заявителей
            // Вызов модального окна
            this.selectedApplicant = null;
            this.modalRef = this.modalService.show(this.selectApplicantModal, { backdrop: 'static' });
        }
    }

    public printAfterApplyPaymentParams() {
        const errors = [];
        if (this.appealService.appealPayments.length > 1 && !this.selectedPayment) {
            errors.push('Не выбран платеж!');
        }

        if (this.appeal.subjects.length > 1 && !this.selectedPayer) {
            errors.push('Не выбран плательщик!');
        }

        if (
            ((this.appealService.appealPayments.length === 1 && this.appealService.appealPayments[0].amountKop === 0) ||
                (this.selectedPayment && this.selectedPayment.amountKop === 0)) &&
            this.customPaymentSum.rub === 0 &&
            this.customPaymentSum.kop === 0
        ) {
            errors.push('Не заполнена сумма оплаты');
        }

        if (errors.length > 0) {
            this.toaster.error(errors.join(' '));
        }

        if (this.selectedPayment) {
            this.prepareForPrintDocument.payment = _.cloneDeep(this.selectedPayment);
        }

        if (this.selectedPayer) {
            this.prepareForPrintDocument.payerGuid = this.selectedPayer.guid;
        }

        if (this.customPaymentSum.rub !== 0 || this.customPaymentSum.kop !== 0) {
            this.prepareForPrintDocument.payment.amountKop =
                Number(this.customPaymentSum.rub) * 100 + Number(this.customPaymentSum.kop);
        }

        this.modalRef.hide();

        return this.sendPrintForm(this.prepareForPrintDocument);
    }

    /**
     * Выбор заявителя / доверителя для комплексной печатной формы
     * @param applicant
     */
    public selectApplicantForPrintForm(applicant) {
        this.selectedApplicant = applicant;
    }

    public printAfterSelectApplicant() {
        if (!this.selectedApplicant) {
            this.toaster.error('Не выбрано ни одного участника');
        }

        this.modalRef.hide();

        return this.sendPrintForm(this.prepareForPrintDocument, [], this.selectedApplicant.guid);
    }

    /**
     * Формирование файла печатной формы
     * @param document - документ
     * @param selectedDocuments - выбранные документы для спец. печатных форм (bringRegister, issueRegister)
     * @param applicantGuid - guid заявителя (доверителя) в комплексном деле (для которого формируется ПФ)
     * @returns void
     */
    public async sendPrintForm(document, selectedDocuments = [], applicantGuid = null) {
        let printForm = this.printForms.find((item) => item._id === document.printForm.id);
        if (!printForm) {
            printForm = document.printForm;
            printForm._id = printForm.id;
        }
        if (document.printForm && document.printForm.applicantGuid) {
            applicantGuid = document.printForm.applicantGuid;
        } else if (applicantGuid) {
            document.printForm.applicantGuid = applicantGuid;
        }
        const baseData = await this.printFormService.prepareAppealData(
            this.appeal,
            this.subservice,
            document,
            printForm,
            selectedDocuments,
            applicantGuid
        );

        let responsibleOrganizationId = null;
        let additionalData = null;
        let variantData = null;
        const currentAppealSubservice = this._getServiceForPrintForm(document, printForm);
        if (currentAppealSubservice) {
            responsibleOrganizationId = currentAppealSubservice.responsibleOrganization
                ? currentAppealSubservice.responsibleOrganization.id
                : null;
            if (currentAppealSubservice.xsdData) {
                additionalData = { xsdData: currentAppealSubservice.xsdData };
            }
            if (currentAppealSubservice.variantXsdData) {
                variantData = { variantData: currentAppealSubservice.variantXsdData };
            }
        }

        this.progressService.start();
        try {
            const response = await this.printingService.renderPrintForm(
                printForm._id,
                this.appeal._id,
                'appeals',
                baseData,
                additionalData,
                variantData,
                this.appeal.unit.id,
                responsibleOrganizationId
            );

            if (!document.files) {
                document.files = [];
            }
            const findInFiles = document.files.find((item) => item._id && item._id === response.file._id);
            if (!findInFiles) {
                document.files.push(response.file);
            }
            const findInQuery = this.documentService.data[document.guid].queue.find(
                (item) => item._id && item._id === response.file._id
            );
            if (!findInQuery) {
                this.documentService.data[document.guid].queue.push(response.file);
            }

            await this.saveService.saveAppeal();
            if (this.editedDocument) {
                delete this.documentService.data[document.guid].isProcessGeneratePrintForm;
                this.documentService.tempData = _.cloneDeep(this.documentService.data);
            } else {
                this.toaster.success('Сформированный документ успешно добавлен в дело');
            }

            if (response.file.id.indexOf('.html') === -1) {
                await this.downloadFile(response.file._id, response.file.name);
            }
        } catch (error) {
            if (this.editedDocument) {
                delete this.documentService.data[document.guid].isProcessGeneratePrintForm;
                this.documentService.tempData = _.cloneDeep(this.documentService.data);
            }

            this.toaster.html(error.errorMessage || error);
        } finally {
            this.progressService.done();
        }
    }

    /**
     * Получение услуги, по которой формируется печатная форма
     * @param document - документ для которого формируется ПФ
     * @param printForm
     * @returns {any}
     */
    private _getServiceForPrintForm(document, printForm) {
        let appealSubservice = null;
        if (this.subservice) {
            // Если дело создано по одной услуге
            appealSubservice = this.appeal.subservice;
        } else if (document && document.subserviceGuid && this.appeal.subservice.guid === document.subserviceGuid) {
            // Определение активной услуги по документу, для которого печатная форма формируется
            appealSubservice = this.appeal.subservice;
        } else if (printForm && printForm.serviceIds.length) {
            // Информация об активных услугах берется из блока ServiceIds для печатной формы
            const printFormActiveServices = [];
            printForm.serviceIds.forEach((serviceId) => {
                if (this.subservice.serviceId === serviceId) {
                    printFormActiveServices.push(this.subservice);
                }
            });
            if (printFormActiveServices.length === 1 && this.appeal.subservice.id === printFormActiveServices[0]._id) {
                // Берется первая соответствующая serviceId (не универсальный вариант, возможна ошибка при использовании одинаковых услуг в комплексном деле)
                appealSubservice = this.appeal.subservice;
            }
        }

        return appealSubservice;
    }

    /**
     * Получение числа заявителей в услуге
     * @param document
     * @returns {Array}
     */
    private _checkMultipleApplicantsInAppeal(document) {
        const applicants = [];
        // Если дело по комплексной услуге
        // TODO по идее, всегда возвращает пустой массив
        /*if (this.appeal.subservices.length > 1) {
            const documentPrintForm = this.printForms.find(item => item._id === document.printForm.id);
            // Если печатная форма для комплексной услуги
            if (documentPrintForm.forComplex) {
                // Выбираем заявителей (доверителей)
                this.appeal.subservices.forEach(appealSubservice => {
                    if (appealSubservice.subjects && appealSubservice.subjects.length > 0) {
                        appealSubservice.subjects.forEach(subject => {
                            if (subject.subKind && subject.subKind.type
                                && (subject.subKind.type.indexOf('applicant') !== -1 || subject.subKind.type.indexOf('principal') !== -1)
                                && applicants.indexOf(subject.guid) === -1) {
                                applicants.push(subject.guid);
                            }
                        });
                    }
                });
            }
        }*/

        return applicants;
    }

    /**
     * КНМ на то, что в при формировании печатной формы нужно выбирать документы из списка
     * @param document - документ со свойством printForm
     * @returns {boolean}
     */
    private _checkPrintFormWithSelectedDocuments(document) {
        // Типы печатных форм для которых требуется выбор документов
        const types = ['bringRegister', 'issueRegister'];
        if (document.printForm && document.printForm.formType) {
            const docTypeArr = document.printForm.formType.split('.');

            return types.indexOf(docTypeArr[0]) !== -1;
        }

        return false;
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public downloadFile(id, originalName) {
        return this.filesService.downloadAndSaveFile(id, originalName);
    }

    /**
     * Подготовка групп документов и документов дела для выбора при формировании печатной формы
     */
    private _prepareSelectDocumentsForPrintForm() {
        this.groupsForModal = this.documentService.prepareDocumentsForSelectInModal(this.prepareForPrintDocument);
        this.modalRef = this.modalService.show(this.selectDocumentsModal, { backdrop: 'static', class: 'modal-lg' });
    }

    /**
     * Настройка выбранных документов и передача в метод формирования печатной формы
     */
    public selectDocuments() {
        const selectedDocuments = this.documentService.getSelectedDocumentsForModal(this.groupsForModal);
        if (selectedDocuments.length === 0) {
            this.toaster.info('Не выбрано ни одного документа');
        }
        this.modalRef.hide();

        return this.sendPrintForm(this.prepareForPrintDocument, selectedDocuments);
    }

    /**
     * Число выбранных из группы документов
     * @param group
     * @returns {number}
     */
    public countSelectedDocumentsInGroup(group) {
        const docs = group.documents.filter((item) => item.selected);

        return docs.length;
    }

    /**
     * Удаление документа из дела
     * @param document
     */
    public async deleteDocument(document) {
        const documentIndex = this.appeal.documents.findIndex((item) => item.guid === document.guid);
        if (this.documentService.data[document.guid].envelope) {
            this.documentService.data[document.guid].envelope.status = 'deleted';
        }
        // Удаление документов из списка документов запроса
        Object.keys(this.documentService.data).forEach((documentGuid) => {
            if (
                this.documentService.data[documentGuid].envelope &&
                this.documentService.data[documentGuid].envelope.appealDocuments &&
                this.documentService.data[documentGuid].envelope.appealDocuments.length > 0 &&
                this.documentService.data[documentGuid].envelope.appealDocuments.indexOf(document.guid) !== -1
            ) {
                this.documentService.data[documentGuid].envelope.appealDocuments.splice(
                    this.documentService.data[documentGuid].envelope.appealDocuments.indexOf(document.guid),
                    1
                );
            }
        });
        this.appeal.documents.splice(documentIndex, 1);
        if (this.appeal._id) {
            await this.saveService.saveAppeal();
        }

        return this.afterDeleteDocument();
    }

    /**
     * Процедуры после удаления документа из дела
     */
    public async afterDeleteDocument() {
        // Корректировка данных сервисов
        await this.saveService.correctServiceDataAfterDeleteAppealEntity();
        this.toaster.success('Документ успешно удален');
    }

    /**
     * Инициализация обновления дела после отправки запроса со сменой статуса
     */
    public updateAppealAfterSendStatusEnvelope() {
        this.editedDocument = null;
        this.editedDocumentGroup = null;
        this.onUpdateAppealAfterSendStatusEnvelope.emit(true);
    }
}
