import { RestService } from '@evolenta/core';
import { Config } from '../../../common/services/config';
import { Injectable } from '@angular/core';
import { SERVICES_COLLECTION } from '../../../common/constants';

@Injectable()
export class SmevConfigurationService {

    public baseUrl = Config.server + Config.api;

    public constructor(
        private restService: RestService,
    ) {

    }

    /**
     * ��������� ����������� �������� ����-��������
     * @param service
     */
    public save(service) {
        return this.restService.update(SERVICES_COLLECTION, service);
    }

    public getServices(search, page?, sort?) {
        const params = {
            search: {
                search,
            },
            page: page || 0,
            sort: sort ? `${sort.field},${sort.order}` : 'uri,ASC',
        };

        return this.restService.search(SERVICES_COLLECTION, params);
    }
}
