<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Контрольная закупка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <ng-container *ngIf="task && mode == 'view'">
                <span class="text-semibold text-uppercase">{{entity.name}}</span>
            </ng-container>
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указано</em>

            <span class="text-muted ml-10">Дата составления: </span>
            <ng-container *ngIf="entity.dateCompilation">{{entity.dateCompilation.formatted}}</ng-container>
            <em *ngIf="!entity.dateCompilation">не указано</em>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="panel panel-shadow m-20 p-20">
                <div class="panel-body no-padding pt-10">
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <label>Номер</label>
                            <div class="form-group form-group-lg">
                                <input type="text" name="number" class="form-control" [(ngModel)]="entity.number">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <label>Тип адреса составления</label>
                            <div class="form-group form-group-lg">
                                <catalogue
                                    catalogName="typeAddress"
                                    name="typeAddressName"
                                    [(ngModel)]="entity.typeAddressName"
                                    [priority]="true"
                                    [large]="true">
                                </catalogue>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label>Место составления</label>
                            <div class="form-group form-group-lg">
                                <evolenta-address-gar
                                        [large]="true"
                                        [showIndex]="false"
                                        [(ngModel)]="entity.placeCompilation"
                                ></evolenta-address-gar>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <label>Дата составления</label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker name="dateCompilation" [large]="true" [(ngModel)]="entity.dateCompilation" [valueAsString]="true"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <div class="row">
                                <div class="col-xs-6 col-md-6">
                                    <label>Время составления</label>
                                    <div class="row">
                                        <div class="col-xs-6 col-md-6">
                                            <div class="form-group input-group form-group-lg">
                                                <input type="number" name="hourCompilation" class="form-control" [(ngModel)]="entity.hourCompilation" min="0" max="23" placeholder="ч">
                                                <div class="input-group-spr"><span>:</span></div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-6">
                                            <div class="form-group form-group-lg">
                                                <input type="number" name="minCompilation" class="form-control" [(ngModel)]="entity.minCompilation" min="0" max="59" placeholder="мин">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-md-6">
                                    <label>Время завершения</label>
                                    <div class="row">
                                        <div class="col-xs-6 col-md-6">
                                            <div class="form-group input-group form-group-lg">
                                                <input type="number" name="hourEnd" class="form-control" [(ngModel)]="entity.hourEnd" min="0" max="23" placeholder="ч">
                                                <div class="input-group-spr"><span>:</span></div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-6">
                                            <div class="form-group form-group-lg">
                                                <input type="number" name="minEnd" class="form-control" [(ngModel)]="entity.minEnd" min="0" max="59" placeholder="мин">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-5">
                            <div class="row">
                                <div class="col-xs-6 col-md-6">
                                    <label>Длительность КНМ (в днях)</label>
                                    <div class="form-group form-group-lg">
                                        <input type="number" name="factControlPeriod" class="form-control" [(ngModel)]="entity.factControlPeriod" placeholder="дней" title="дней">
                                    </div>
                                </div>
                                <div class="col-xs-6 col-md-6">
                                    <label>Длительность КНМ (в часах)</label>
                                    <div class="form-group form-group-lg">
                                        <input type="number" name="minEnd" class="form-control" [(ngModel)]="entity.hourControlPeriod" placeholder="часов" title="часов">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Информация о способе, которым проведена дистанционная закупка</label>
                            <div class="form-group form-group-lg">
                                <textarea name="wayDistance" class="form-control" [(ngModel)]="entity.wayDistance" ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Товары, направленные на проведение исследований (испытаний)</label>
                            <div class="form-group form-group-lg">
                                <textarea name="testItem" class="form-control" [(ngModel)]="entity.testItem" ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Товары (работы, услуги), подлежащие возврату</label>
                            <div class="form-group form-group-lg">
                                <textarea name="returnItem" class="form-control" [(ngModel)]="entity.returnItem" ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <label>Сведения о возврате денежных средств/отказе в совершении действий по возврату</label>
                            <div class="form-group form-group-lg">
                                <textarea name="returnItemMany" class="form-control" [(ngModel)]="entity.returnItemMany" ></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- Инспектор -->
                    <div class="mb-15">
                        <appeal-entity-employee
                            [large]="true"
                            [entity]="entity"
                            property="inspector"
                            [title]="'Инспектор'"
                            [field]="['name','position']">
                        </appeal-entity-employee>
                    </div>

                    <!-- Выявленные нарушения обязательных требований -->
                    <div class="mb-15">
                        <appeal-entity-catalogue
                            [large]="true"
                            [entity]="entity"
                            [property]="'nsiViolations'"
                            [catalogName]="'nsiViolations'"
                            [title]="'Выявленные нарушения обязательных требований'"
                            [placeholder]="'Выберите обязательные требования'">
                        </appeal-entity-catalogue>
                    </div>

                    <!-- Прилагаемые документы -->
                    <div class="mb-15">
                        <appeal-entity-field-input
                            [multiple]="true"
                            [large]="true"
                            [entity]="entity"
                            [property]="'scrollDoc'"
                            [title]="'Перечень  документов, прилагаемых к акту'"
                            [placeholder]="'Введите наименование прилагаемого документа'">
                        </appeal-entity-field-input>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <label>Дата направления копии распоряжения (приказа), экземпляра акта</label>
                            <div class="form-group form-group-lg">
                                <evolenta-datepicker name="sendDateCopyOrder" [large]="true" [(ngModel)]="entity.sendDateCopyOrder" [valueAsString]="true"></evolenta-datepicker>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label>Способ направления копии распоряжения (приказа), экземпляра акта</label>
                            <div class="form-group form-group-lg">
                                <input type="text" name="sendWayCopyOrder" class="form-control" [(ngModel)]="entity.sendWayCopyOrder">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
