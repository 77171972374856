<ng-template #modal>
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="items">
            <div class="radio-block" *ngFor="let reason of items">
                <input type="radio" class="radio" name="selectedReason" id="reason{{reason.code}}" [value]="reason" [(ngModel)]="selectedReason">
                <label for="reason{{reason.code}}">
                    {{reason.title}}
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="fields">
            <div class="form-group" *ngFor="let field of fields; let idx = index">
                <label *ngIf="field.name">{{field.name}}</label>
                <textarea class="form-control" name="field{{idx}}" [(ngModel)]="fieldsData[field.code]" *ngIf="field.type == 'textarea'"></textarea>
                <input class="form-control" name="field{{idx}}" [(ngModel)]="fieldsData[field.code]" *ngIf="field.type == 'input'">
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="hideModal()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="complete()" *ngIf="items" [disabled]="!selectedReason">Выбрать</button>
        <button type="button" class="btn btn-primary" (click)="complete()" *ngIf="fields" [disabled]="!checkFields()">Применить</button>
    </div>
</ng-template>