import { Injectable } from '@angular/core';
import { StorageService, RestService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { CommonAppealData } from '../../services/common-appeal.data';
import { REST_SEARCH_USERS_THRESLOD } from '../../../../config/constants';
import { from, Observable } from 'rxjs';

@Injectable()
export class CommonAppealEntitiesService {
    public dictionaries = {};

    public entityTypes = CommonAppealData.entityTypes;

    public currentOrganization = this.storage.getItem('currentOrganization');

    public constructor(
        private rest: RestService,
        private storage: StorageService,
    ) {
    }

    public getUsers() {
        const params = [
            {
                field: 'sprOrganizations.id',
                operator: 'eq',
                value: this.currentOrganization.id,
            },
            {
                field: 'active',
                operator: 'eq',
                value: true,
            },
        ];

        return this.rest.search('users', {search: {search: params}, prj: 'usersList', sort: 'name,ASC', size: REST_SEARCH_USERS_THRESLOD});
    }

    public getDocumentTypes(): Observable<any> {
        const promise = this.rest.search('documentType');

        return from(promise);
    }

    /**
     * Сведения о журнале учета проверок
     */
    public getAuditLog() {
        return this.rest.search('auditLog');
    }

    /**
     * Меры по устранению нарушения
     */
    public getActionTakenItems(): Observable<any> {
        const promise = this.rest.search('actionTaken');

        return from(promise);
    }

    /**
     * Типы лиц допустившего нарушение
     */
    public getInfringerTypes(): Observable<any> {
        const promise = this.rest.search('infringerType');

        return from(promise);
    }

    /**
     * Возврат адреса в виде строки
     * @param addressItems
     * @returns {string}
     */
    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    /**
     * Поиск неустраненных нарушений в рассматриваемой проверке
     * @param appeal
     * @param objectId
     */
    public findCheckListFailure(appeal, objectId = null) {
        let checkLists = [];
        if (appeal.checkLists && appeal.checkLists.length > 0) {
            checkLists = appeal.checkLists.filter(checkList => {
                return checkList.questions.some(item => item.result && item.result.answer === 'no' && item.mandatoryReqs);
            });
            if (objectId) {
                checkLists = appeal.checkLists.filter(checkList => checkList.objectGuid === objectId);
            }

            return checkLists.map(check => ({
                subjectGuid: check.subjectGuid,
                objectGuid: check.objectGuid,
                questions: check.questions
                    .filter(item => item.result && item.result.answer === 'no' && item.mandatoryReqs)
                    .map(item => {
                        return {
                            text: item.text,
                            mandatoryReqs: item.mandatoryReqs ? item.mandatoryReqs : {},
                            nPA: item.nPA ? item.nPA : {},
                        };
                    }),
            }));
        }

        return checkLists;
    }

    /**
     * Внеплановая проверка
     * @param appeal
     */
    public isKndTypeUnPlan(appeal) {
        return appeal.subservice && appeal.subservice.kndInfo.kndType
            ? appeal.subservice.kndInfo.kndType.code === '1'
            : false;
    }

    /**
     * Плановая проверка
     * @param appeal
     */
    public isKndTypePlan(appeal) {
        return appeal.subservice && appeal.subservice.kndInfo.kndType
            ? appeal.subservice.kndInfo.kndType.code === '0'
            : false;
    }

    /**
     * Документарная проверка
     * @param appeal
     */
    public isDocumentaryVerification(appeal) {
        if (appeal.subservice.kndInfo.kndForm) {
            return appeal.subservice.kndInfo.kndForm.code === '2';
        }
    }

    /**
     * Выездная проверка
     * @param appeal
     */
    public isFieldAudit(appeal) {
        if (appeal.subservice.kndInfo.kndForm) {
            return appeal.subservice.kndInfo.kndForm.code === '1';
        }
    }

    /**
     * Поиск всех сведений по коду
     * @param appeal
     * @param entityCode
     */
    public findEntityAll(appeal, entityCode) {
        let entity = [];
        if (appeal.subservice && appeal.subservice.entities && this.entityTypes[entityCode]) {
            entity = appeal.subservice.entities.filter(item => item.type === this.entityTypes[entityCode].code);
        }

        return entity;
    }

    /**
     * Поиск последнего сведения по коду
     * @param appeal
     * @param entityType
     */
    public findEntity(appeal: any, entityType: string): any {
        let entity;
        if (appeal.subservice && appeal.subservice.entities) {
            entity = appeal.subservice.entities.filter(item => item.type === entityType);
            if (entity.length) {
                entity = entity[entity.length - 1];
            }
        }

        return entity;
    }

    /**
     * Возвращает адрес первого объекта первого субъекта
     * @param appeal
     */
    public findObjectAddress(appeal) {
        if (appeal.objects && appeal.objects.length > 0) {
            if (appeal.objects[0].objects && appeal.objects[0].objects.length > 0) {
                return appeal.objects[0].objects[0].address ? appeal.objects[0].objects[0].address : null;
            }
        }

        return null;
    }

    /**
     * Возвращает представителя субъекта
     * @param appeal
     */
    public findPerson(appeal) {
        let personInfo = {
            name: '',
            position: '',
        };
        if (appeal.objects) {
            const findIndex = appeal.objects.findIndex(object => object.specialTypeId === 'ulApplicant');
            if (findIndex !== -1 && appeal.objects[findIndex].data && appeal.objects[findIndex].data.person) {
                const objectData = appeal.objects[findIndex].data;
                personInfo = {
                    name: objectData.person.lastName + ' ' + objectData.person.firstName + ' ' + objectData.person.middleName,
                    position: objectData.personInOrg.position ? objectData.personInOrg.position : '',
                };
            }
        }

        return personInfo;
    }
}
