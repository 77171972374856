import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObjectUtilities } from '@evolenta/utilities';
import { TranslateService } from '@evolenta/core';

@Component({
    selector: 'common-standard-entity-select-data',
    templateUrl: './common-standard-entity-select-data.component.html',
    styles: [':host { width: 100%; }'],
})
export class CommonStandardEntitySelectDataComponent implements OnInit {
    @Input() public entity;
    @Input() public elements;
    @Input() public entityField;
    @Input() public elementNameField;
    @Input() public elementIdField;
    @Input() public selectedDataName;
    @Input() public selectingText;

    @Input() public allowEdit = true;

    @Output() public onEditXsd = new EventEmitter<object>();
    @Output() public onSave = new EventEmitter<boolean>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public isProcessSelect = false;
    public localizations;

    public constructor(private translate: TranslateService) {}

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getElementNameById(elementId) {
        const element = this.elements.find((item) => this.getElementField(item, 'id') === elementId);
        return element ? ObjectUtilities.GetPropertyByPath(element, this.elementNameField) : '';
    }

    public deleteElement(index) {
        this.entity[this.entityField].splice(index, 1);
    }

    public checkSelectedElement(element) {
        return (
            this.entity[this.entityField] &&
            this.entity[this.entityField].indexOf(this.getElementField(element, 'id')) !== -1
        );
    }

    public selectElement(element) {
        if (!this.entity[this.entityField]) {
            this.entity[this.entityField] = [];
        }
        const findIndex = this.entity[this.entityField].indexOf(this.getElementField(element, 'id'));
        if (findIndex === -1) {
            this.entity[this.entityField].push(this.getElementField(element, 'id'));
        } else {
            this.entity[this.entityField].splice(findIndex, 1);
        }
    }

    public getElementField(element, field) {
        return ObjectUtilities.GetPropertyByPath(element, field === 'id' ? this.elementIdField : this.elementNameField);
    }
}
