<ng-container *ngIf="data.envelope && data.envelope.agency
                     && data.envelope.request && (data.envelope.request.xsd && data.envelope || !data.envelope.request.xsd)">
    <envelope-data [envelope]="data.envelope"
                   [fromDocument]="true"
                   [data]="data.envelopeData"
                   (onCheckCorrectForm)="onAfterGetEnvelopeXsdData($event)"
                   (returnEnvelopeAterSaveOrSend)="onAfterSendEnvelope($event)"
                   #envelopeData>
    </envelope-data>
</ng-container>
