import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AccessService, ModalDialogComponent, ToasterService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealSubservicesService } from '../../appeal-subservices.service';
import { AppealStatusService } from '../../appeal-status.service';
import { Permission } from '../../../../../common/services/permission';
import { AppealObjectCardComponent } from './appeal-object-card/appeal-object-card.component';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-objects',
    templateUrl: 'appeal-objects.component.html',
})
export class AppealObjectsComponent implements OnInit, OnDestroy {
    @Input() public appeal: any; // Текущее дело
    @Input() public subservice; // Услуги, на основании которых формируется дело

    @Output() public onAfterSave = new EventEmitter<string>(); // Передача возможности перехода на другую вкладку после КНМ необходимости сохранения данных
    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)

    public activeTab; // активная вкладка при редактировании пользователя
    public nextTab; // Следующая вкладка, на которую будет осуществлен переход в родительском компоненте после КНМ сохранения измененных данных

    public editedObject = null; // Объект, который находится в данный момент в статусе редактирования

    public modalOperation; // Функция компонента, осуществляющая обработку результатов отработки модального окна

    @ViewChild('applyModal', { static: false }) public modalDialogComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', { static: false }) public editObjectCardComponent: AppealObjectCardComponent; // Компонент - карточка редактирования субъекта дела

    public allowEdit = true; // Возможность редактирования субъектов дела

    public permissions = Permission; // Набор прав системы

    public availableObjectKinds = []; // Доступные для выбора виды объектов
    public leave;
    public stay;
    public allowedToLeave = new Promise((resolve, reject) => {
        this.leave = resolve;
        this.stay = reject;
    });

    public localizations;

    public constructor(
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        private toaster: ToasterService,
        private appealSubservicesService: AppealSubservicesService,
        private saveService: AppealSaveService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        // Запрещено редактирование, если дело находится в финальном статусе, или зарегистрировано
        this.allowEdit = this.appealStatusService.allowEdit && this.appeal.status.code !== 'process';
        this.getUniqueObjectKinds();
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.objects',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public async ngOnDestroy() {
        this.checkSaveData();
        await this.allowedToLeave;
    }

    /**
     * Формирование массива уникальных видов объектов из данных услуг
     */
    public getUniqueObjectKinds() {
        if (!this.subservice.objects) {
            return;
        }

        this.subservice.objects.objectKinds.forEach(kind => {
            if (kind.type === 'object') {
                const findIndex = this.availableObjectKinds.findIndex(item => item.guid === kind.guid);
                let editKind;
                if (findIndex === -1) {
                    editKind = {
                        guid: kind.guid,
                        type: kind.type,
                        name: kind.name,
                        description: kind.description,
                        subKinds: [],
                    };
                } else {
                    editKind = this.availableObjectKinds[findIndex];
                }
                kind.subKinds.forEach(subKind => {
                    const findSubKind = editKind.subKinds.find(item => item.name === subKind.secondGroupName);
                    if (!findSubKind) {
                        editKind.subKinds.push({
                            name: subKind.secondGroupName,
                            specialTypeId: subKind.specialTypeId,
                            headerOptions: subKind.headerOptions,
                            shortHeaderOptions: subKind.shortHeaderOptions,
                            type: subKind.type,
                            xsd: subKind.xsd,
                        });
                    }
                });
                if (findIndex === -1) {
                    this.availableObjectKinds.push(editKind);
                } else {
                    this.availableObjectKinds[findIndex] = editKind;
                }
            }
        });
    }

    /**
     * Добавление нового субъекта в дело
     */
    public addObject() {
        const objectGuid = CommonUtilities.GenerateGuid();
        const kind = this.subservice.objects.objectKinds.find(item => item.type === 'object');
        const object: any = {
            guid: objectGuid,
            kind: {
                guid: kind.guid,
                type: kind.type,
                name: kind.name,
                description: kind.description,
            },
            data: {realty: {}},
        };
        if (kind.subKinds.length === 1) {
            const subKind = kind.subKinds[0];
            object.kind.subKind = {
                name: subKind.secondGroupName,
                specialTypeId: subKind.specialTypeId,
                type: subKind.type,
                xsd: subKind.xsd ? subKind.xsd : null,
            };
            object.specialTypeId = subKind.specialTypeId;
        }

        // Добавление информации объекте в служебный объект
        this.appealSubservicesService.objectsData[objectGuid] = _.cloneDeep(object);
        // this.validateService.initValidateElement('objects', subjectGuid);

        // Инициализация блока данных объекта в каждой услуге для работы внутри компонентов
        Object.keys(this.appealSubservicesService.data).forEach(key => {
            this.appealSubservicesService.data[key].objects[objectGuid] = {active: false, subjects: []};

            if (this.appealSubservicesService.data[key].subjects) {
                Object.keys(this.appealSubservicesService.data[key].subjects).forEach(subjectGuid => {
                    this.appealSubservicesService.data[key].objects[objectGuid].subjects.push({guid: subjectGuid, active: false});
                    if (!this.appealSubservicesService.data[key].subjects[subjectGuid].objects) {
                        this.appealSubservicesService.data[key].subjects[subjectGuid].objects = [];
                    }
                    this.appealSubservicesService.data[key].subjects[subjectGuid].objects.push({guid: objectGuid, active: false});
                });
            }
        });

        // Переход в режим редактирования добавляемого субъекта
        this.onEditObject(object);
    }

    /**
     * Переход в режим редактирования субъекта
     * @param object
     */
    public activateEditMode(object) {
        // Сохраняем субъект в переменную редактирования
        setTimeout(() => {
            this.editedObject = object;
        }, 100);
    }

    /**
     * Инициализация режима редактирования субъекта
     * @param object
     */
    public onEditObject(object) {
        this.appealSubservicesService.tempData = _.cloneDeep(this.appealSubservicesService.data);
        this.appealSubservicesService.tempObjectsData = _.cloneDeep(this.appealSubservicesService.objectsData);
        this.activateEditMode(this.appealSubservicesService.objectsData[object.guid]);
    }

    /**
     * Применение (отмена) изменений в субъекте, переход к режиму списка субъектов
     * @param data - объект формата {subject: измененный субъект, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public onApplyObject(data) {
        if (data) {
            const objectIndex = this.appeal.objects.findIndex(item => item.guid === data.object.guid);
            // Применение изменений в структуру дела в блок appeal.objects
            const objectData = _.cloneDeep(data.object);
            if (objectIndex !== -1) {
                this.appeal.objects[objectIndex] = _.cloneDeep(objectData);
            } else {
                this.appeal.objects.push(_.cloneDeep(objectData));
            }
           //  this.appealService.correctCheckLists();

            // Настройка субъектов в привязке к услугам
            this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
            // this.appealSubservicesService.correctObjectsDataInSubserviceOnApply();
            // Передача управления в родительский компонент для продолжения операций с делом: сохранение, переход на новый статус
            if (data.continueProcessingAppeal) {
                // this.onApply.emit(true);
            } else if (this.appeal._id) {

                this.saveService.saveAppeal().then(
                    () => {
                        this.toaster.success('Объект успешно сохранен');
                    },
                    error => {
                        this.toaster.error(error);
                    });
            }
        } else {
            // Отмена изменений, внесенных в режиме редактирования объекта, копирование данных из временных объектов
            this.appealSubservicesService.data = _.cloneDeep(this.appealSubservicesService.tempData); // данные по услугам
            this.appealSubservicesService.objectsData = _.cloneDeep(this.appealSubservicesService.tempObjectsData); // данные по объектам

            // сохранение дела
            if (this.appeal._id) {

                this.saveService
                    .saveAppeal()
                    .then()
                    .catch(error => {
                        this.toaster.error(error);
                    });
            }
        }

        // Сброс объектов с временными данными
        this.appealSubservicesService.tempData = {};
        this.appealSubservicesService.tempObjectsData = {};

        // ПереКНМ дела
        // this.validateService.validateAppeal(this.validateService.processValidate);

        this.activeTab = null;
        this.editedObject = null;
        if (this.nextTab) {
            // this.onAfterSave.emit(this.nextTab);
        }
    }

    /**
     * Инициализация режима редактирования при клике на ссылки внутри карточки предварительного просмотра объекта
     * @param data - данные в формате {object: объект для редактирования, tab: активная вкладка}
     */
    public initEdit(data) {
        this.activeTab = data.tab;
        this.onEditObject({guid: data.objectGuid});
    }

    /**
     * Удаление объекта из дела
     * @param object - удаляемый объекта
     */
    public async onDeleteObject(object) {
        // Удаление настроек удаляемого объекта в услугах из дела
        if (this.appeal.subservice.objects && this.appeal.subservice.objects.length) {
            const objectIdx = this.appeal.subservice.objects.indexOf(object.guid);
            if (objectIdx !== -1) {
                this.appeal.subservice.objects.splice(objectIdx, 1);
            }
        }

        if (this.appeal.subservice.subjects && this.appeal.subservice.subjects.length) {
            this.appeal.subservice.subjects.forEach(subject => {
                if (subject.objects) {
                    subject.objects = subject.objects.filter(item => item !== object.guid);
                }
            });
        }

        // Удаление субъекта из массива объектов дела
        const objectIndex = this.appeal.objects.findIndex(item => item.guid === object.guid);
        this.appeal.objects.splice(objectIndex, 1);

        if (!this.appeal._id) {
            await this._afterDeleteObject();

            return;
        }

        try {
            await this.saveService.saveAppeal();
            await this._afterDeleteObject();
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * Операции после удаления дела из дела (с сервера и состава дела)
     */
    private async _afterDeleteObject() {
        // Корректировка данных сервисов
        await this.saveService.correctServiceDataAfterDeleteAppealEntity();
        this.toaster.success('Объект удален из дела');
    }

    /**
     * КНМ на необходимость сохранения изменений в редактируемый в данный момент времени объект дела
     */
    public checkSaveData() {
        if (!(this.editedObject && this.checkObjectChange())) {
            this.leave();

            return;
        }

        this.modalOperation = 'afterCheckSaveData';
        this.modalDialogComponent.title = this.localizations.common.confirm_changes;
        this.modalDialogComponent.message = this.localizations['appeals.objects'].apply_changes;
        this.modalDialogComponent.showModal();
    }

    /**
     * КНМ на возможные изменения в объекте
     */
    public checkObjectChange(): boolean {
        const foundAppealObject = this.appeal.objects.find(item => item.guid === this.editedObject.guid);

        // КНМ изменений в основной сущности объекта
        return !_.isEqual(this.editedObject, foundAppealObject);
    }

    /**
     * Передача ответа от модального окна методу, который активен для вызова текущего модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Обработка выбранного в модальном окне решения по изменениям в объекте
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public afterCheckSaveData(data) {
        if (data === 'yes') {
            // Сохранение данных
            this.onApplyObject({ object: this.editedObject, continueProcessingAppeal: false });
        } else if (data === 'no') {
            // Отмена внесенных изменений
            this.leave();
        } else {
            // Отмена перехода на другую вкладку
            this.stay();
        }
    }
}
