import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { ToasterService } from '@evolenta/core';
import { ReactiveFormsUtilities } from '@evolenta/utilities';

import { CommonAppealEntityDocumentsComponent } from '../entity-documents/common-appeal-entity-documents.component';
import { ErrorLoggingService } from '../../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-entity-static',
    templateUrl: 'common-appeal-entity-static.component.html',
})
export class CommonAppealEntityStaticComponent {
    @Input() public entity;
    @Input() public appeal;
    @Input() public task;
    @Input() public mode;
    @Input() public allowEdit;
    @Input() public subservice;

    @Output() public onApply = new EventEmitter<any>();
    @Output() public onEdit = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<any>();
    @Output() public onCancel = new EventEmitter<any>();
    @Output() public onNeedSaveBeforePrint = new EventEmitter<any>();

    @ViewChild('data', { static: false }) public data: DynamicFormComponent;
    @ViewChild('entityDocuments', { static: false })
    public entityDocumentsComponent: CommonAppealEntityDocumentsComponent;

    public showCommonData = true;
    public handlers;
    public externalData;

    public constructor(
        private errorLoggingService: ErrorLoggingService,
        private toaster: ToasterService
    ) {}

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        const editedEntityComponent = this.getCurrentComponent();
        setTimeout(() => (editedEntityComponent.dirtyStatus = false));
        if (editedEntityComponent.form.valid) {
            editedEntityComponent.setEntityFromFormValue();
            this.onApply.emit(this.entity);
        } else {
            setTimeout(() => (editedEntityComponent.dirtyStatus = true));
            ReactiveFormsUtilities.MarkFormGroupAsDirty(editedEntityComponent.form);
            const errorText = 'На форме содержатся ошибки';
            this.toaster.error(errorText);
            this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));
        }
    }

    public applyBeforePrint() {
        const editedEntityComponent = this.getCurrentComponent();
        editedEntityComponent.setEntityFromFormValue();
    }

    public getCurrentComponent() {
        switch (this.entity.type) {
            default:
                return null;
        }
    }

    public cancel() {
        this.onCancel.emit();
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public isValid() {
        if (this.mode === 'view') {
            return true;
        } else {
            this.data.validate();
            if (this.data.isValid) {
                this.apply();
            }

            return this.data.isValid;
        }
    }

    public getTransferToProcessParams() {
        if (this.data && this.data.formRenderComponent) {
            return this.data.getDataByParam('isTransferToProcess');
        }

        return null;
    }

    public processingSaveBeforePrint(printDocument) {
        this.applyBeforePrint();
        this.onNeedSaveBeforePrint.emit(printDocument);
    }

    public processingPrintFormAfterSave(printDocument) {
        if (this.entityDocumentsComponent) {
            this.entityDocumentsComponent.processingPrintFormAfterSave(printDocument);
        }
    }
}
