import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AccessService,
    ModalDialogComponent,
    RestService,
    ToasterService,
    TranslateService,
    UsersService,
} from '@evolenta/core';
import { Permission } from '../../../../../common/services/permission';

@Component({
    selector: 'app-user-preview',
    templateUrl: './user-preview.component.html',
})
export class UserPreviewComponent implements OnInit {
    @Input() public user: any;
    @Output() public onDelete = new EventEmitter();

    public panels = {
        common: true,
        roles: true,
        organizations: true,
        applications: true,
    };

    public userRoles = [];
    public userOrganizations = [];
    public userApplications = [];
    @ViewChild('deleteModal', { static: false }) public modalDialogComponent: ModalDialogComponent;
    public modalMessage;
    public localizations;

    public constructor(
        private access: AccessService,
        private usersService: UsersService,
        private rest: RestService,
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.userRoles = this.usersService.getUserRolesList(this.user.linkRoles);
        this.userApplications = this.usersService.getUserAppsList(this.user.applications);
        this.userOrganizations = this.usersService.getUserOrgsList(this.user.sprOrganizations);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'users']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public showEditIcon() {
        return this.access.hasAccess([Permission.User_Update]);
    }

    public showRemoveIcon() {
        return this.access.hasAccess([Permission.User_Delete]);
    }

    public deleteUser() {
        this.modalMessage = 'Вы уверены что хотите удалить пользователя?';
        this.modalDialogComponent.showModal();
    }

    public answerHandler(action) {
        if (action === 'yes') {
            // Нажатие кнопки "Да"
            this.rest.remove('users', this.user).then(
                (response) => {
                    console.log(response);
                    this.onDelete.emit();
                    this.toaster.success('Элемент успешно удален.');
                },
                (error) => {
                    console.log('ошибка', error);
                }
            );
        }
    }
}
