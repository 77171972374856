import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonStandardsService } from '../common-standards.service';
import { TranslateService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-statuses-permissions',
    templateUrl: './common-standard-statuses-permissions.component.html',
})
export class CommonStandardStatusesPermissionsComponent implements OnInit {
    @Input() public statuses;
    @Input() public params;
    @Input() public data;
    @Input() public checkedTypes;

    public modalRef: BsModalRef;
    @ViewChild('editedFieldsSettingsModal', { static: false }) public editedFieldsSettingsModal: TemplateRef<any>;
    public checkedType;
    public editedStatus;
    public settingEditedFields = {};

    public localizations;

    public constructor(
        private standardsService: CommonStandardsService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        if (!this.data.statusesPermissions) {
            this.data.statusesPermissions = {};
        }

        if (!this.statuses) {
            // если не передаются статусы, то используются статусы дела
            const appealData = this.standardsService.metaReglament.blocks.find((item) => item.code === 'appealData');

            if (appealData && appealData.appealStatuses) {
                this.statuses = appealData.appealStatuses;
            }
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public checkSelected(status, param) {
        return this.data.statusesPermissions[status.code] && this.data.statusesPermissions[status.code][param.code];
    }

    public changeSelected(status, param) {
        if (!this.data.statusesPermissions[status.code]) {
            this.data.statusesPermissions[status.code] = {};
        }
        this.data.statusesPermissions[status.code][param.code] =
            !this.data.statusesPermissions[status.code][param.code];
    }

    public setEditedFields(status) {
        this.editedStatus = status;
        this.checkedType = null;
        if (this.data.statusesPermissions[status.code].editedFields) {
            this.settingEditedFields = cloneDeep(this.data.statusesPermissions[status.code].editedFields);
        }
        this.modalRef = this.modalService.show(this.editedFieldsSettingsModal, {
            backdrop: 'static',
            class: 'modal-lg',
        });
        this.changeCheckedType(this.checkedTypes[0]);
    }

    public changeCheckedType(type) {
        this.checkedType = null;
        setTimeout(() => {
            this.checkedType = type.shortCode;
            if (!this.settingEditedFields[this.checkedType]) {
                this.settingEditedFields[this.checkedType] = [];
            }
            // if (!this.data.statusesPermissions[this.editedStatus.code].editedFields[type.shortCode]) {
            //     this.data.statusesPermissions[this.editedStatus.code].editedFields[type.shortCode] = []
            // }
        }, 0);
    }

    public applyEditedFields() {
        this.data.statusesPermissions[this.editedStatus.code].editedFields = this.settingEditedFields;
        this.modalRef.hide();
    }
}
