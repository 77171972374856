<div class="input-group touch-spin" [ngClass]="{'touch-spin-small' : mode == 'small'}">
    <span class="input-group-btn">
        <button class="btn btn-default" type="button" [disabled]="value <= min || !enabled" (click)="downClick()"><i class="icon-minus3"></i></button>
    </span>
    <span class="input-group-addon" *ngIf="prefix">{{prefix}}</span>
    <input class="touchspin-postfix form-control" type="text" [(ngModel)]="value" (change)="change()" [disabled]="!enabled">
    <span class="input-group-addon" *ngIf="postfix">{{postfix}}</span>
    <span class="input-group-btn">
        <button class="btn btn-default" type="button" (click)="upClick()" [disabled]="value >= max || !enabled"><i class="icon-plus3"></i></button>
    </span>
</div>