import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';
import { DeleteConfirmationModalModule } from '../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.module';
import { SmevConfigurationComponent } from './components/smev-configuration/smev-configuration.component';
import { SmevConfigurationService } from './services/smev-configuration.service';
import { SmevConfigurationListComponent } from './components/smev-configuration-list/smev-configuration-list.component';
import { SmevConfigurationSectionComponent } from './smev-configuration-section.component';
import { SmevConfigurationPreviewEditComponent } from './components/smev-configuration-preview-edit/smev-configuration-preview-edit.component';

@NgModule({
    imports: [RouterModule, SharedComponentsModule, BreadcrumbsModule, DeleteConfirmationModalModule],
    declarations: [
        SmevConfigurationListComponent,
        SmevConfigurationComponent,
        SmevConfigurationSectionComponent,
        SmevConfigurationPreviewEditComponent,
    ],
    providers: [SmevConfigurationService],
    exports: [],
})
export class SmevConfigurationModule {}
