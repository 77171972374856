<div class="pt-20 pr-20 pl-20"
     [ngClass]="{'pb-10': data.isElectronicDocument, 'pb-20': !data.isElectronicDocument}">
    <div class="flex">

        <!-- Оригиналы -->
        <div class="form-group mr-30 no-margin-bottom">
            <label>
                {{localizations['appeals.documents'].originals}}
                <span class="text-size-small "
                      [ngClass]="getAmountClass('originals', 'origin')">
                    {{getGroupOrigin()}}
                </span>
            </label>
            <touch-spin mode="small"
                        [(ngModel)]="data.originals"
                        (ngModelChange)="change('originals', 'originalsSheets')"
                        [enabled]="data.allowEdit">
            </touch-spin>
        </div>

        <!-- Листы оригиналов -->
        <div class="form-group no-margin-bottom"
             *ngIf="!data.isElectronicDocument">
            <label>
                {{localizations['appeals.documents'].originals_sheets}}
            </label>
            <touch-spin mode="small"
                        [(ngModel)]="data.originalsSheets"
                        [enabled]="data.allowEdit">
            </touch-spin>
        </div>

        <!-- Копии -->
        <div class="form-group no-margin-bottom mr-30 "
             style="margin-left: 80px">
            <label>{{localizations['appeals.documents'].copies}}
                <span class="text-size-small "
                      [ngClass]="getAmountClass('copies', 'copy')">
                    {{ getGroupCopy() }}
                </span>
            </label>
            <touch-spin mode="small"
                        [(ngModel)]="data.copies"
                        (ngModelChange)="change('copies', 'copiesSheets')"
                        [enabled]="data.allowEdit">
            </touch-spin>
        </div>

        <!-- Листов копий -->
        <div class="form-group mr-30 no-margin-bottom"
             *ngIf="!data.isElectronicDocument">
            <label>
                {{localizations['appeals.documents'].copies_sheets}}
            </label>
            <touch-spin mode="small"
                        [(ngModel)]="data.copiesSheets"
                        [enabled]="data.allowEdit">
            </touch-spin>
        </div>

        <!-- Является электронным документом -->
        <div class="form-group ml-30 no-margin-bottom">
            <label>
                {{localizations['appeals.documents'].electronic}}
            </label>
            <div class="checkbox-block no-padding-top">
                <input id="electronicDocument"
                       type="checkbox"
                       class="checkbox"
                       [value]="true"
                       [(ngModel)]="data.isElectronicDocument"
                       [disabled]="!data.allowEdit">
                <label for="electronicDocument">
                    {{localizations['appeals.documents'].yes_electronic}}
                </label>
            </div>
        </div>

    </div>
</div>
