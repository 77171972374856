import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeleteConfirmationModalComponent } from '../../../knm/appeals/components/objects/special-types/delete-confirmation-modal/delete-confirmation-modal.component';
import {
    RestService,
    ToasterService,
    TranslateService,
    UniversalCollectionPagePreviewComponentInterface,
} from '@evolenta/core';

@Component({
    selector: 'print-form-preview',
    templateUrl: 'print-form-preview.component.html',
})

export class PrintFormPreviewComponent implements OnInit, UniversalCollectionPagePreviewComponentInterface {
    @Input() public element;

    @Output() public onDeleted = new EventEmitter<void>();

    @ViewChild('deleteConfirmationModal', { static: false }) public deleteConfirmationModalComponent: DeleteConfirmationModalComponent;

    public panels = {
        common: true,
    };
    public moduleBaseUrl;
    public deleteConfirmationHeader;
    public deleteConfirmationText;
    public localizations;

    public constructor(
        private rest: RestService,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
    }

    private _loadTranslations(): void {
        this.translate.get(
            [
                'common',
                'print-forms',
            ],
        ).subscribe(res => {
            this.localizations = res;
        });
    }

    public get isPrintFormActive(): boolean {
        return this.element.hasOwnProperty('active')
            ? this.element.active
            : true;
    }

    public deleteForm(): void {
        this.rest
            .remove('printForms', this.element)
            .then(() => {
                this.onDeleted.emit();
                this.toaster.success(this.localizations['print-forms'].notifications.deleted);
            });
    }

    public showDeleteConfirmationModal(): void {
        this.deleteConfirmationHeader = this.localizations['print-forms'].delete_modal.header;
        this.deleteConfirmationText = this.localizations['print-forms'].delete_modal.text;
        this.deleteConfirmationModalComponent.showModal();
    }
}
