import { NgModule } from '@angular/core';
import { LicensingActivityRouting } from './licensing-activity.routing';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { SubservicesModule } from '../../modules/subservices/subservices.module';
import { RegistersModule } from '../../modules/registers/registers.module';
import { CommonAppealsModule } from '../../modules/common-appeals/common-appeals.module';
import { NotificationsModule } from '../../modules/notifications/notifications.module';
import { LicensingActivityDefaultComponent } from './components/licensing-activity-default.component';

@NgModule({
    imports: [
        LicensingActivityRouting,
        SharedComponentsModule,
        SharedElementsModule,
        CommonAppealsModule,
        SubservicesModule,
        RegistersModule,
        NotificationsModule,
    ],
    declarations: [LicensingActivityDefaultComponent],
})
export class LicensingActivityModule {}
