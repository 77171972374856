<div class="text-size-small">
    <ng-container *ngIf="object.quickRequests && object.quickRequests[requestCode] && object.quickRequests[requestCode].length > 0">
        <span class="text-semibold">
            {{ localizations.standards.requests }}:
        </span>
        <ng-container *ngFor="let request of object.quickRequests[requestCode]; let lst = last">
            <span class="text-size-small anchor text-info ml-5"
                  routerLink="/ais/envelopes/edit/{{request.id}}">
                {{ request.auid }}
            </span>
        </ng-container>
        <hr class="mt-5 mb-5">
    </ng-container>

    <!-- Информация о условиях обязательности -->
    <div [innerHtml]="quickRequestsService.getRequiredFieldsInfo(requestCode)"></div>
</div>
