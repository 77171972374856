import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityProceedProtocol } from './models/appeal-entity-proceed-protocol';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash-es';
import { ToasterService } from '@evolenta/core';

@Component({
    selector: 'appeal-entity-proceed-protocol',
    templateUrl: 'appeal-entity-proceed-protocol.component.html',
})
export class AppealEntityProceedProtocolComponent implements OnInit {
    @Input() public entity: AppealEntityProceedProtocol;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.date) {
            this.entity.date = this.appeal.dateRegister;
        }
        if (!this.entity.birthday) {
            this.entity.birthday = '';
        }
        const proceedInitiation = this.appealEntitiesService.findEntity(this.appeal, 'proceedInitiation');
        if (!this.entity.individual && proceedInitiation && proceedInitiation.individual) {
            this.entity.individual = proceedInitiation.individual;
        }
        if (!this.entity.defender && proceedInitiation && proceedInitiation.defender) {
            this.entity.defender = proceedInitiation.defender;
        }
        if (!this.entity.detailsDefender && proceedInitiation && proceedInitiation.detailsDefender) {
            this.entity.detailsDefender = proceedInitiation.detailsDefender;
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        return isValidForm;
    }
}
