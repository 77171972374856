import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilesService, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { DocumentService } from '../../document.service';
import { CadesSignModalComponent } from '@evolenta/signing';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'appeal-document-documents',
    templateUrl: 'appeal-document-documents.component.html',
})
export class AppealDocumentDocumentsComponent implements OnInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public appeal; // формируемое дело

    public appealDocuments = [];
    public data; // описательный объект для текущего документа
    public allDocumentsData; // описательный объект для всех документов дела

    public filesForSign = []; // Файлы для подписи
    public file4Sign = null; // Файл, отправленный на подписание
    public isProcessSignFiles = false; // Флаг осуществления процесса подписания файлов
    public localizations;

    @ViewChild(CadesSignModalComponent, { static: false }) public cadesSignModalComponent: CadesSignModalComponent; // компонент подписания файлов

    public constructor(
        private documentService: DocumentService,
        private toaster: ToasterService,
        private filesService: FilesService,
        private storage: StorageService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.allDocumentsData = this.documentService.data;
        this.data = this.documentService.data[this.document.guid];

        // Список документов дела, в котором есть прикрепленные файлы
        // tslint:disable-next-line:max-line-length
        // const appealFilteredDocuments = this.appeal.documents.filter(item => {return !item.deleted && (item.subserviceGuid === this.document.subserviceGuid || !item.subserviceGuid) && item.guid !== this.document.guid && item.files && item.files.length > 0});
        this.appealDocuments = this.filterAppealDocuments(); // _.cloneDeep(appealFilteredDocuments);

        // Инициализация выбранных ранее документов
        if (this.data.envelope.appealDocuments && this.data.envelope.appealDocuments.length > 0) {
            this.appealDocuments.forEach(document => {
                if (this.data.envelope.appealDocuments && this.data.envelope.appealDocuments.indexOf(document.guid) !== -1) {
                    document.checked = true;
                }
            });
        }
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.documents',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Фильтрация документов дела возможных для выбора в составе запроса
     * @returns {Array}
     */
    public filterAppealDocuments() {
        const documents = [];
        const appealDocuments = cloneDeep(this.appeal.documents);
        appealDocuments.forEach(document => {
            // Выбираем только документы принадлежащие текущей услуге или документы из группы "Прочие документы"
            if (document.subserviceGuid === this.document.subserviceGuid || !document.subserviceGuid && (!document.groupCode || document.groupCode !== 'complex')) {
                if (document.files && document.files.length > 0) {
                    documents.push(document);
                } else if (document.link) {
                    // Если документ является ссылкой на другой документ и в том документе есть файлы
                    const linkedDocument = this.documentService.data[document.link];
                    if (linkedDocument.files && linkedDocument.files.length > 0) {
                        documents.push(document);
                    }
                }
            }
        });

        return documents;
    }

    /**
     * Выбор / отмена выбора документа дела в состав документов запроса
     * @param document - обрабатываемый документ
     */
    public toggleDocument(document) {
        if (!this.data.envelope.appealDocuments) {
            this.data.envelope.appealDocuments = [];
        }
        if (!document.checked) {
            // Отмена выбора документа
            const findIndex = this.data.envelope.appealDocuments.findIndex(item => item === document.guid);
            this.data.envelope.appealDocuments.splice(findIndex, 1);
        } else {
            this.data.envelope.appealDocuments.push(document.guid);
        }
    }

    /**
     * КНМ использования документов дела в запросе
     * @param document - проверяемый документ
     * @returns {boolean}
     */
    public checkDocumentUse(document) {
        return this.data.envelope.appealDocuments.indexOf(document.guid) !== -1;
    }

    /**
     * Подписание всех файлов для документов, привязанных к конверту-запросу
     */
    public signAllFiles() {
        this.isProcessSignFiles = true;
        this.filesForSign = [];
        this.data.envelope.appealDocuments.forEach(documentGuid => {
            this.allDocumentsData[documentGuid].queue.forEach(file => {
                if (!file.signature) {
                    this.filesForSign.push({
                        fileId: file._id,
                        originalName: file.originalName,
                        documentGuid: documentGuid,
                    });
                }
            });
        });

        // Запуск процедуры подписания файлов
        this.processSignFiles();
    }

    /**
     * Процесс подписания файлов
     */
    public processSignFiles() {
        if (this.filesForSign.length > 0) {
           this.storage.setItem('signAllFilesForRequest', true);
            // Запуск на подписание первого в списке файла
           this.signFile(this.filesForSign[0]);
        } else {
            this.isProcessSignFiles = false;
            this.storage.removeItem('signAllFilesForRequest');
            this.toaster.success('Все файлы успешно подписаны');
        }
    }

    /**
     * Подписание файла
     * @param file - подписываемый файл
     */
    public async signFile(file) {
        this.file4Sign = file;
        try {
            const fileInBase64: any = await this.filesService
                .downloadFileInBase64(this.file4Sign.fileId, 'text');

            this.cadesSignModalComponent.commonSignData(fileInBase64);
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * Обработка результата подписания для запроса
     * @param result - {certificate: ...., signature: .....}
     */
    public onSignatureComplete(result?) {
        if (result) {
            const signedFile = this.allDocumentsData[this.file4Sign.documentGuid].queue.find(item => item._id === this.file4Sign.fileId);

            // Сохраняем подпись и сертификат для файла
            signedFile.certificate = result.certificate;
            signedFile.signature = result.signature;
            if (result['certificateData']) {
                signedFile.certificateData = result.certificateData;
            }

            // Удаляем файл из массива для подписания
            const fileIndex = this.filesForSign.findIndex(item => item.fileId === this.file4Sign.fileId);
            this.filesForSign.splice(fileIndex, 1);

            // Запускаем процесс подписания следующего файла
            this.processSignFiles();
        }
    }
}
