import { Component, Input, OnInit } from '@angular/core';
import { CommonAppealDocumentService } from '../../common-appeal-document.service';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-appeal-document-participants',
    templateUrl: 'common-appeal-document-participants.component.html',
})
export class CommonAppealDocumentParticipantsComponent implements OnInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public appeal; // формируемео дело

    public data; // описательный массив
    public subjects; // объекты дела
    public forAgent = false; // документ на представителя
    public principals = []; // доверители
    public needSelectPrincipal = false; // необходимость выбора доверителя для документа
    public isIdentity = false; // документ является документом удостоверяющим личность

    public constructor(
        private documentService: CommonAppealDocumentService,
        private validateService: CommonAppealValidateService,
    ) {
    }

    public ngOnInit() {
        this.data = this.documentService.data[this.document.guid]; // информация по текущему документу в сервисе
        if (this.data && this.data.group && this.data.group.isIdentity) {
            this.isIdentity = true;
        }
        this.subjects = cloneDeep(this.appeal.subjects); // все субъекты дела
        if (this.group && this.group.appealRequirements && this.group.appealRequirements.byObjects) {
            // Фильтрация участников дела для простых документов в зависимости от условий обязательности для группы документов
            this.subjects = this.subjects.filter(item => this.group.appealRequirements.byObjects[item.guid]);
        } else if (this.data.requestId && this.data.doc && this.data.doc.participantCategories) {
            // Фильтрация участников дела для документов-запросов в зависимости от настроек документов-запросов в стандартах
            const usedSubjects = [];
            this.data.appealSubservice.subjects.forEach(subject => {
                if (subject.category && subject.category.selectedCategories) {
                    const find = subject.category.selectedCategories.find(item => this.data.doc.participantCategories.indexOf(item.guid) !== -1);
                    if (find) {
                        const findSubject = this.subjects.find(item => item.guid === subject.guid);
                        usedSubjects.push(cloneDeep(findSubject));
                    }
                }
            });
            this.subjects = cloneDeep(usedSubjects);
        }
        // Если документ относится к группе, описанной в структуре услуги
        if (this.data.group && this.data.group.requirements) {
            // Условия предоставления на представителя
            const requirementByAgent = this.data.group.requirements.find(item => item.byAgent);
            // Если группа на представителя
            if (requirementByAgent) {
                this.forAgent = true;
                // получение списка представителей для выбора
                this.getAgents();
                // Если в группу требуется добавление документов для каждого доверителя
                if (requirementByAgent.byAgent[0].forEachPrincipal) {
                    this.needSelectPrincipal = true;
                    // Формирование списка доверителей
                    this.getPrincipals();
                }
            }
        }
        // Если для документа ранее уже были выбраны объекты, инициализируем флаг "Выбран" (selected)
        if (this.data.useSubjects && this.data.useSubjects.length > 0) {
            this.subjects.forEach(subject => {
                const find = this.data.useSubjects.find(item => item.guid === subject.guid);
                if (find) {
                    subject.selected = true;
                }
            });
        } else if (this.subjects.length === 1) {
            if (this.data.requestId) {
                this.changeRequestSubject(this.subjects[0]);
            } else {
                // Выбор участника автоматически, если он один в деле
                this.changeDocumentSubjects(this.subjects[0]);
            }
        }
    }

    /**
     * Формирование списка представителей
     */
    public getAgents() {
        // Если у документа есть услуга
        if (this.data.subserviceGuid) {
            if (this.appeal.subservice.guid === this.data.subserviceGuid && this.appeal.subservice.subjects && this.appeal.subservice.subjects.length) {
                // Фильтрация объектов дела по наличию представителей
                const objectsWithRepresentative = this.appeal.subservice.subjects.filter(item => item.representative && item.representative.guid);
                const agents = [];
                objectsWithRepresentative.forEach(subject => {
                    const agentSubject = this.appeal.subjects.find(item => item.guid === subject.representative.guid);
                    const agentInList = agents.find(item => item.guid === subject.representative.guid);
                    if (!agentInList) {
                        agents.push(cloneDeep(agentSubject));
                    }
                });
                this.subjects = agents; // замена списка объектов для выбора набором представителей
            }
        }
    }

    /**
     * Выбор / отмена выбора привязки к документу объекта дела
     * @param subject - обрабатываемый объект
     */
    public changeDocumentSubjects(subject) {
        subject.selected = !subject.selected;
        if (this.forAgent || this.isIdentity) {
            this.data.useSubjects = [];
        }
        const findIndex = this.data.useSubjects.findIndex(item => item.guid === subject.guid);
        if (findIndex === -1) {
            if (this.group.isIdentity) {
                this.data.seria = subject.data.person.documentSeries;
                this.data.number = subject.data.person.documentNumber;
                this.data.issuer = subject.data.person.documentIssuer ? subject.data.person.documentIssuer.name : '';
                this.data.dateIssue = subject.data.person.documentIssueDate;
            }
            this.data.useSubjects.push(subject);
        } else {
            this.data.useSubjects.splice(findIndex, 1);
        }
        if (this.forAgent && this.needSelectPrincipal) {
            // Обновление списка доверителей для выбранного представителя
            delete this.data.principal;
            this.getPrincipals();
        }

        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Получение списка доверителей
     */
    public getPrincipals() {
        // Если выбран представитель
        if (this.data.useSubjects && this.data.useSubjects.length > 0) {
            const agent = this.data.useSubjects[0];
            // Выбираем доверителей, для выбранного представителя
            this.principals = [];
            if (this.appeal.subservice.guid === this.data.subserviceGuid && this.appeal.subservice.subjects && this.appeal.subservice.subjects.length > 0) {
                const objectsWithRepresentative = this.appeal.subservice.subjects.filter(item => item.representative && item.representative.guid === agent.guid);
                objectsWithRepresentative.forEach(subject => {
                    const appealSubject = this.appeal.subjects.find(item => item.guid === subject.guid);
                    this.principals.push(cloneDeep(appealSubject));
                });
                this.principals.forEach(item => {
                    // Выставление флага "Выбран"
                    item.selected = this.data.principal && this.data.principal.guid === item.guid;
                });
            }
        }
    }

    /**
     * Выбор доверителя
     * @param principal
     */
    public changeDocumentPrincipal(principal) {
        this.data.principal = principal;
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Выбор участника/объекта для документа-запроса
     * @param subject - обрабатываемый объект
     */
    public changeRequestSubject(subject) {
        this.data.envelope.object = {guid: subject.guid};
        this.data.envelopeSubject = subject;
    }

    /**
     * КНМ использования объекта в документа
     * @param subject - проверяемый объект
     * @returns {boolean}
     */
    public checkUseObject(subject) {
        return this.data.subjects.findIndex(item => item.guid === subject.guid) !== -1;
    }
}
