<ng-template #modal>
    <div class="modal-header">
        <h3 class="modal-title pull-left">
            {{ localizations['can-deactivate-modal'].approve }}
        </h3>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ localizations['can-deactivate-modal'].form_has_unsaved_data }}
    </div>
    <div class="modal-footer">
        <button id="deactivateSaveBtn"
                class="btn btn-default"
                type="button">
            {{ localizations.common.save }}
        </button>
        <button id="deactivateStateBtn"
                class="btn btn-primary"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations['can-deactivate-modal'].do_not_save }}
        </button>
        <button type="button"
                class="btn btn-primary"
                (click)="modalRef.hide()"
                id="cancel">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>
