import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ControlOperatorsService } from '../../services/control-operators.service';
import { ServicesService } from '../../../subservices/services/services.service';
import { RestService, StorageService, TranslateService } from '@evolenta/core';
import { SPECIALIST_ROLE } from '../../../../common/constants';

@Component({
    selector: 'app-control-operators',
    templateUrl: './control-operators.component.html',
    styleUrls: ['../../../elements-list.css', './control-operators.less'],
})
export class ControlOperatorsComponent implements OnInit {
    public controlOperator;
    public controlOperators;
    public currentOrganization;
    public isSetControlOperatorAutomatically;
    public subservice = {
        _id: '',
        classificSubserviceName: '',
        titles: {
            title: '',
        },
        standardCode: '',
    };
    public user = {
        _id: '',
        login: '',
        name: '',
    };
    public serviceSearchParams;
    public userSearchParams;

    public modalRef: BsModalRef;
    @ViewChild('addNewControlOperatorModal', { static: false }) public addNewControlOperatorModal: TemplateRef<any>;
    public modalTitle;
    public localizations;

    public constructor(
        private controlOperatorsService: ControlOperatorsService,
        private storageService: StorageService,
        private servicesService: ServicesService,
        private restService: RestService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        this.currentOrganization = this.storageService.getItem('currentOrganization');
        this._getControlOperatorsForCurrentOrganization();
        this._getIsSetControlOperatorAutomatically();
        this._initSearchParams();
    }

    private _initSearchParams() {
        this.serviceSearchParams = {
            andSubConditions: [
                {
                    field: 'units.id',
                    operator: 'eq',
                    value: this.currentOrganization._id,
                },
            ],
        };

        this.userSearchParams = {
            andSubConditions: [
                {
                    field: 'linkRolesMnemonics.sprOrganization',
                    operator: 'eq',
                    value: this.currentOrganization.guid,
                },
                {
                    field: 'linkRolesMnemonics.roleMnemonic',
                    operator: 'eq',
                    value: SPECIALIST_ROLE,
                },
            ],
        };
    }

    public async _getIsSetControlOperatorAutomatically() {
        this.isSetControlOperatorAutomatically = await this.controlOperatorsService.getAutoassignmentStatus(
            this.currentOrganization._id
        );
    }

    public _loadTranslations() {
        this.translate.get(['common', 'control_operator']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public async _getControlOperatorsForCurrentOrganization() {
        this.controlOperators = await this.controlOperatorsService.get(this.currentOrganization._id);
    }

    public setControlOperatorAutomatically(event) {
        const value = event.target.checked;
        this.controlOperatorsService.setOperatorAutomaticallySetting(this.currentOrganization._id, value);
    }

    public showAddControlOperatorModal() {
        if (!this.controlOperator) {
            this.controlOperator = this.controlOperatorsService.createEmptyControlOperator();
        }

        this.modalTitle = 'Укажите ответственного';
        this.modalRef = this.modalService.show(this.addNewControlOperatorModal, { backdrop: 'static' });
    }

    public subserviceSelected(subservice) {
        this.controlOperator.service = {
            _id: subservice._id,
            name: subservice.titles.title,
            standardCode: subservice.standardCode,
        };
    }

    public userSelected(user) {
        this.controlOperator.user = {
            login: user.login,
            name: user.name,
        };
    }

    public addControlOperator() {
        this.controlOperator.service = this.servicesService.correctSubserviceByCurrentUnit(
            this.controlOperator.service
        );
        this.controlOperator.service = {
            _id: this.subservice._id,
            name: this.subservice.titles.title,
            standardCode: this.subservice.standardCode,
        };

        this.controlOperator.user = {
            _id: this.user._id,
            login: this.user.login,
            name: this.user.name,
        };
        this._addControlOperator();
    }

    public async _addControlOperator() {
        await this.controlOperatorsService.add(this.controlOperator);
        this.modalRef.hide();
        this._getControlOperatorsForCurrentOrganization();
    }

    public handleControlOperatorDeletedEvent() {
        this._getControlOperatorsForCurrentOrganization();
    }
}
