import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'calendar-menu',
    templateUrl: './calendar-menu.component.html',
})
export class CalendarMenuComponent implements OnInit, OnChanges {
    @Input() public active;

    public tabs = [
        { code: 'calendar', title: 'Производственный календарь' },
        { code: 'holidays', title: 'Настройка праздников' },
    ];

    public activeTabCode: string;

    @Output() public onChangeTab = new EventEmitter<any>();
    public moduleBaseUrl; // базовый URL приложения

    public constructor(private route: ActivatedRoute) {}

    public ngOnInit() {
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
    }

    public ngOnChanges(changes) {
        if (changes && changes.active) {
            // отслеживание изменения активной вкладки из родительского компонента
            if (changes.active.currentValue) {
                this.activeTabCode = changes.active.currentValue;
            }
        }
    }

    public selectTab(tabCode): void {
        this.activeTabCode = tabCode;
        this.onChangeTab.emit(tabCode);
    }
}
