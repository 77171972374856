<div class="pl-20 pr-20"
     *ngIf="!editedProcess">
    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false"
                                                (onToggleEntity)="setActiveProcess()">
    </common-standard-block-with-additional-data>

    <!--  Процесс не выбран  -->
    <ng-container *ngIf="!process && activeData.allowEdit">
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
                (click)="selectProcess()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            <span>
                {{ localizations.standards.process.select }}
            </span>
        </button>
        <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20 ml-20"
                (click)="addNewProcess()">
            <b>
                <i inlineSVG="assets/icon-plus-round.svg"></i>
            </b>
            <span>
                {{ localizations.standards.process.add_new }}
            </span>
        </button>
    </ng-container>

    <!-- Карточка процесса -->

    <common-bpmn-card *ngIf="process"
                      [bpmn]="process"
                      [entityId]="this.activeData.entity._id"
                      [allowEdit]="activeData.allowEdit"
                      [entityProcessData]="activeData.entity.process"
                      (onEdit)="editProcess($event)"
                      (onDelete)="deleteProcess()"
                      (onDeleteSubProcess)="deleteSubProcess($event)"
                      (onCreateSubProcess)="createSubProcess($event)"
                      (onSearchSubProcess)="selectSubProcess($event)"
                      (onEditSubProcess)="editSubProcess($event)"
                      (onCloneProcess)="afterCloneProcess($event)">
    </common-bpmn-card>
</div>

<bpmn-process-edit
    mode="entity"
    [entityId]="this.activeData.entity._id"
    [entityProcess]="editedProcess"
    *ngIf="editedProcess"
    (onSaveFromEntity)="applyProcess($event)"
    (onCancelFromEntity)="cancelEditProcess()">
</bpmn-process-edit>
