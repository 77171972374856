<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <ng-container *ngIf="task && mode == 'view'">
                <span class="text-semibold text-uppercase">{{entity.name}}</span>
            </ng-container>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <!-- Визуальные вкладки с данными -->
        <div class="mt-20 mb-20" *ngIf="mode === 'edit'">
            <element-edit-tabs [tabs]="editTabs" [active]="activeTab" (onActivateTab)="activateTab($event)" *ngIf="mode === 'edit'"></element-edit-tabs>
        </div>

        <div *ngIf="mode == 'edit'  && activeTab === 'common'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Вопрос проверочного листа</label>
                                <catalogue catalogName="controlListQuestions" name="controlListQuestion" [(ngModel)]="entity.controlListQuestion" [priority]="true" [large]="true" #controlListQuestion="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && controlListQuestion.errors" class="validation-error-label">
                                    <span *ngIf="controlListQuestion.errors.required">Поле "Вопрос проверочного листа" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип виновника</label>
                                <catalogue catalogName="culprit" name="culprit" [(ngModel)]="entity.culprit" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Тип нарушения</label>
                                <catalogue catalogName="nsiOffenses" name="violationType" [(ngModel)]="entity.violationType" [priority]="true" [large]="true" #violationType="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && violationType.errors" class="validation-error-label">
                                    <span *ngIf="violationType.errors.required">Поле "Тип нарушения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Пункт предписания</label>
                                <input type="text" name="prescription" class="form-control" [(ngModel)]="entity.prescription" #prescription="ngModel" required>
                                <div *ngIf="isProcessValidate && prescription.errors" class="validation-error-label">
                                    <span *ngIf="prescription.errors.required">Поле "Пункт предписания" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Принятая мера</label>
                                <input type="text" name="actionTaken" class="form-control" [(ngModel)]="entity.actionTaken" #actionTaken="ngModel" required>
                                <div *ngIf="isProcessValidate && actionTaken.errors" class="validation-error-label">
                                    <span *ngIf="actionTaken.errors.required">Поле "Принятая мера" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Нарушенное обязательное требование</label>
                                <input type="text" name="violatedMandatoryRequirement" class="form-control" [(ngModel)]="entity.violatedMandatoryRequirement">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Описание фактического нарушения</label>
                                <textarea name="violationSpecificComment" class="form-control" [(ngModel)]="entity.violationSpecificComment" #violationSpecificComment="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && violationSpecificComment.errors" class="validation-error-label">
                                    <span *ngIf="violationSpecificComment.errors.required">Поле "Описание фактического нарушения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label class="form-label">Результаты проверки - принятые меры</label>
                                <textarea name="controlItemResult" class="form-control" [(ngModel)]="entity.controlItemResult"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg ">
                                <label>Срок устранения нарушения</label>
                                <evolenta-datepicker [valueAsString]="true" name="deadline" [large]="true" [(ngModel)]="entity.deadline" #deadline="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && deadline.errors" class="validation-error-label">
                                    <span *ngIf="deadline.errors.required">Поле "Срок устранения нарушения" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label class="form-label display-block">Угроза причинения вреда</label>
                                <div class="checkbox-inline">
                                    <input type="checkbox" id="isDamnificationThreat" class="checkbox" name="isDamnificationThreat" [(ngModel)]="entity.isDamnificationThreat">
                                    <label class="control-label" for="isDamnificationThreat">Да</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label class="form-label display-block">Причинение вреда</label>
                                <div class="checkbox-inline">
                                    <input type="checkbox" id="isDamnification" class="checkbox" name="isDamnification" [(ngModel)]="entity.isDamnification">
                                    <label class="control-label" for="isDamnification">Да</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label class="form-label display-block">Грубое</label>
                                <div class="checkbox-inline">
                                    <input type="checkbox" id="isRough" class="checkbox" name="isRough" [(ngModel)]="entity.isRough">
                                    <label class="control-label" for="isRough">Да</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Отменено</label>
                                <evolenta-datepicker [valueAsString]="true" name="cancelDate" [large]="true" [(ngModel)]="entity.cancelDate" #cancelDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && cancelDate.errors" class="validation-error-label">
                                    <span *ngIf="cancelDate.errors.required">Поле "Отменено" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-9">
                            <div class="form-group form-group-lg">
                                <label>Документ об отмене</label>
                                <input type="text" name="cancelDocument" class="form-control" [(ngModel)]="entity.cancelDocument" #cancelDocument="ngModel" required>
                                <div *ngIf="isProcessValidate && cancelDocument.errors" class="validation-error-label">
                                    <span *ngIf="cancelDocument.errors.required">Поле "Документ об отмене" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Изменено</label>
                                <evolenta-datepicker name="changeDate" [large]="true" [(ngModel)]="entity.changeDate" [valueAsString]="true" #changeDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && changeDate.errors" class="validation-error-label">
                                    <span *ngIf="changeDate.errors.required">Поле "Изменено" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-9">
                            <div class="form-group form-group-lg">
                                <label>Документ об изменении</label>
                                <input type="text" name="changeDocument" class="form-control" [(ngModel)]="entity.changeDocument" #changeDocument="ngModel" required>
                                <div *ngIf="isProcessValidate && changeDocument.errors" class="validation-error-label">
                                    <span *ngIf="changeDocument.errors.required">Поле "Документ об изменении" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Изменённый текст</label>
                                <textarea name="changeText" class="form-control" [(ngModel)]="entity.changeText" #changeText="ngModel" required></textarea>
                                <div *ngIf="isProcessValidate && changeText.errors" class="validation-error-label">
                                    <span *ngIf="changeText.errors.required">Поле "Изменённый текст" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit' && activeTab === 'violations'">
            <appeal-entity-violation-statistic
                [large]="true"
                [entity]="entity"
                [appeal]="appeal"
                [property]="'violations'">
            </appeal-entity-violation-statistic>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
