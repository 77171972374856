import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService, RestService, SelectionService, TranslateService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'subject-list',
    templateUrl: './subject-list.component.html',
})
export class SubjectListComponent implements OnInit, AfterViewInit {
    @Input() public object;
    @Input() public subjects;
    @Input() public allowEdit;
    @Input() public baseObject;

    @Output() public onChange = new EventEmitter<any>();

    public moduleBaseUrl;
    public workModeUrl;
    public localizations;

    public constructor(
        public accessService: AccessService,
        private restService: RestService,
        private router: Router,
        private selectionService: SelectionService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        // Получение URL основного модуля
        this.route.parent.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        this.route.parent.url.subscribe((urlPath) => {
            this.workModeUrl = urlPath[urlPath.length - 1].path;
        });
    }

    private _loadTranslations() {
        this.translate.get(['common', 'objects', 'appeals.subjects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            if (this.selectionService.isProcessSelect) {
                this.processingSelectedItems();
            } else {
                this.loadSubjects();
            }
        }, 0);
    }

    public loadSubjects() {
        if (!this.subjects) {
            if (this.object.linkedSubjects && this.object.linkedSubjects.length > 0) {
                this.restService
                    .search('subjectsKno', {
                        search: { search: [{ field: '_id', operator: 'in', value: this.object.linkedSubjects }] },
                    })
                    .then((subjects) => {
                        const globalSubjectIds = subjects
                            .filter((item) => item.globalSubjectId)
                            .map((item) => item.globalSubjectId);
                        if (globalSubjectIds.length > 0) {
                            this.restService
                                .search('subjects', {
                                    search: { search: [{ field: '_id', operator: 'in', value: globalSubjectIds }] },
                                    size: 500,
                                })
                                .then((globalSubjects) => {
                                    globalSubjects.forEach((globalSubject) => {
                                        const cloneGlobalSubject = _.cloneDeep(globalSubject);
                                        delete cloneGlobalSubject._id;
                                        const subjectsForGlobal = subjects.filter(
                                            (item) => item.globalSubjectId === globalSubject._id
                                        );
                                        subjectsForGlobal.forEach((subject) => {
                                            subject = Object.assign(subject, cloneGlobalSubject);
                                        });
                                    });
                                    this.subjects = subjects;
                                    this.changeSubjects();
                                });
                        } else {
                            this.subjects = subjects;
                            this.changeSubjects();
                        }
                    });
            } else {
                this.subjects = [];
                this.changeSubjects();
            }
        }
    }

    public processingSelectedItems() {
        this.subjects = this.selectionService.selectedItems;
        this.changeSubjects();
    }

    public changeSubjects() {
        this.object.linkedSubjects = this.subjects.map((item) => item._id);
        this.onChange.emit(this.subjects);
    }

    public selectSubjects() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.selectedItems = this.subjects;
        this.selectionService.transferObject = this.object;
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectSubjects';
        this.selectionService.additionalData = { baseObject: _.cloneDeep(this.baseObject) };

        this.router.navigate(['selecting', 'subjects', 'kno']);
    }

    public goToSubject(subject) {
        this.router.navigate([this.moduleBaseUrl, 'subjects', 'kno', 'edit', subject._id]);
    }

    public removeSubject(subject) {
        const findIndex = this.subjects.findIndex((item) => item._id === subject._id);
        if (findIndex !== -1) {
            this.subjects.splice(findIndex, 1);
            this.changeSubjects();
        }
    }
}
