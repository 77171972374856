import { Injectable } from '@angular/core';

@Injectable()
export class CategoriesTreeService {
    public openedCategories: string[] = [];

    public isCategoryOpened(guid: string): boolean {
        return this.openedCategories.some((c) => c === guid);
    }

    public toggleCategory(guid: string) {
        if (this.isCategoryOpened(guid)) {
            this.closeCategory(guid);
        } else {
            this.openCategory(guid);
        }
    }

    public openCategory(guid: string) {
        if (!this.isCategoryOpened(guid)) {
            this.openedCategories.push(guid);
        }
    }

    public closeCategory(guid: string) {
        if (this.isCategoryOpened(guid)) {
            const index = this.openedCategories.indexOf(guid);
            this.openedCategories.splice(index, 1);
        }
    }
}
