<span class="badge bg-primary-300 text-uppercase cursor-pointer"
      [title]="getSwitchToModeMessage()"
      [ngClass]="{
        'bg-primary-300': block.entityMode === 'standard',
        'bg-orange-300': block.entityMode === 'reglament',
        'ml-10 badge-xs': small
       }"
      (click)="toggleEntity()">
        {{ block.entityMode === 'reglament' ?
            localizations.standards.reglament_data :
            localizations.standards.standard_data }}
    </span>
<span class="badge bg-success-300 text-uppercase cursor-pointer ml-10"
      [ngClass]="{'badge-xs': small}"
      *ngIf="existInReglament">
     {{ localizations.standards.set_up_in_reglament }}
    </span>
<span class="anchor text-info text-size-small ml-10 light"
      *ngIf="standardsService.allowEditReglament && block.entityMode == 'standard'"
      (click)="toggleEntity()">
    {{ localizations.common.change }}
</span>
<span class="anchor text-slate text-size-small ml-10 light"
      *ngIf="standardsService.allowEditReglament && block.entityMode == 'reglament'"
      (click)="deleteReglamentData()">
    {{ localizations.common.delete_small }}
</span>

