import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppealValidateService } from '../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../appeal-subservices.service';
import { DocumentService } from '../../documents/document.service';

@Component({
    selector: 'appeal-subject-subservices',
    templateUrl: 'appeal-subject-subservices.component.html',
    styles: ['.number{padding: 0 5px}'],
})
export class AppealSubjectSubservicesComponent implements OnInit {
    @Input() public entity: any; // Объект
    @Input() public appeal; // Формируемое дело
    @Input() public subservice; // Описательный массив услуг, на основании которых создано дело

    public data; // Объект информации по услугам дела для визуального отображения
    public subjectsData;
    public appealSubservice: any = {}; // услуга в деле

    public representativeTypes = this.appealSubservicesService.representativeTypes; // Возможные виды представителей
    public subjectsForUseAsAgent; // Участники, которых можно выбрать в качестве
    public tempSubjectXsd; // Временный флаг наличия XSD-данных у текущего объекта

    public principals = [];
    public appealField;
    public isEntityObject = false;
    public entityData;

    @Output() public onReInitEditTabs = new EventEmitter<boolean>(); // Переиницализация вкладок для влючения / отключения отображения вкладки с доп.данными
    @Output() public onActivateCommonTab = new EventEmitter<boolean>(); // Активация вкладки с основными данными

    public constructor(
        private appealSubservicesService: AppealSubservicesService,
        private validateService: AppealValidateService,
        private documentService: DocumentService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.appealField = this.entity.specialTypeId === 'realty' ? 'objects' : 'subjects';
        this.isEntityObject = this.entity.specialTypeId === 'realty';
        this.appealSubservice = this.appeal.subservice;
        this.data = this.appealSubservicesService.data;
        this.subjectsData = this.appealSubservicesService.subjectsData;
        this._getSubjectsForUseAsAgent();
        this.appealSubservicesService.processingAddEntityFromAppealSubserviceCard(this.appeal.subservice, this.entity);
    }

    public _getSubjectsForUseAsAgent() {
        this.subjectsForUseAsAgent = {};
        Object.keys(this.data).forEach(appealSubserviceGuid => {
            if (!this.subjectsForUseAsAgent[appealSubserviceGuid]) {
                this.subjectsForUseAsAgent[appealSubserviceGuid] = [];
            }
            Object.keys(this.data[appealSubserviceGuid].subjects).forEach(subjectGuid => {
                if (subjectGuid !== this.entity.guid) {
                    const otherSubjectData = this.data[appealSubserviceGuid].subjects[subjectGuid];
                    if (!otherSubjectData || !otherSubjectData.subKind || otherSubjectData.subKind.type.indexOf('applicant') !== -1) {
                        this.subjectsForUseAsAgent[appealSubserviceGuid].push(this.subjectsData[subjectGuid]);
                    }
                }
            });
        });
    }

    /**
     * Инициализация выбора (отмены выбора) участия обрабатываемого объекта в определенной услуге
     * @param appealSubservice - обрабатываемая услуга дела
     */
    public changeEntityPresentInfoInAppealService(appealSubservice) {
        // Если выбран вариант услуги (либо варианты отсутствуют в настройке услуги)
        if (this.data[appealSubservice.guid].correctVariant) {
            this.data[appealSubservice.guid][this.appealField][this.entity.guid].active = !this.data[appealSubservice.guid][this.appealField][this.entity.guid].active;
            if (!this.data[appealSubservice.guid][this.appealField][this.entity.guid].active) {
                this.appealSubservicesService.removeEntityPresentInSubservice(appealSubservice, this.entity);
            } else {
                this.appealSubservicesService.addEntityPresentInSubservice(appealSubservice, this.entity);
            }
            if (!this.isEntityObject) {
                this.appealSubservicesService.getAppealSubserviceSubjectsNotAgreeMkguInterview(appealSubservice);
            }
            this.onReInitEditTabs.emit(true);
        }
    }

    /**
     * Выбор группы участников, переход к режиму выбора вида участия
     * @param appealSubservice
     * @param group
     */
    public selectEntityGroup(appealSubservice, group) {
        this.appealSubservicesService.selectEntityGroup(appealSubservice, this.entity, group);
        this._updateErrors(); // Обновление статуса ошибок
        this.onReInitEditTabs.emit(true);
    }

    /**
     * Выбор категории объекта
     * @param categories
     * @param appealSubservice - обрабатываемая услуга
     */
    public selectEntityCategories(categories, appealSubservice) {
        this.tempSubjectXsd = this.appealSubservicesService.objectsXsd[this.entity.guid];
        this.appealSubservicesService.selectEntityCategories(appealSubservice, this.entity);
        this._updateErrors(); // Обновление статуса ошибок
        // Переинициализация вкладок редактирования для включения/отключения вкладки с доп.данными
        this.onReInitEditTabs.emit(true);
    }

    /**
     * Инициализация режима выбора группы участия
     * @param appealSubservice
     */
    public changeEntityGroup(appealSubservice) {
        this.appealSubservicesService.changeEntityGroup(this.data[appealSubservice.guid][this.appealField][this.entity.guid]);
    }

    /**
     * Инициализация режима выбора категории объекта
     * @param appealSubservice - обрабатываемая услуга
     */
    public changeSubjectCategory(appealSubservice) {
        this.appealSubservicesService.changeEntityCategory(this.data[appealSubservice.guid][this.appealField][this.entity.guid]);
    }

    /**
     * Инициализация режима добавления нового участника - представителя
     * @param appealSubservice
     */
    public addAgent(appealSubservice) {
        this.appealSubservicesService.addAgent(appealSubservice, this.entity);
        this.validateService.initValidateElement('subjects', this.data[appealSubservice.guid][this.appealField][this.entity.guid].representative.guid);
    }

    public applyAgentSubKind(appealSubservice, type) {
        this.appealSubservicesService.applyAgentSubKind(appealSubservice, this.entity, type);
    }

    public applyAgentName(appealSubservice) {
        this.appealSubservicesService.applyAgentName(appealSubservice, this.entity);
    }

    public applyAgentAuthority(appealSubservice) {
        this.appealSubservicesService.applyAgentAuthority(appealSubservice, this.entity);
    }

    /**
     * Выбор типа представительства
     * @param appealSubservice
     * @param type - объект-представитель
     */
    public applyAgentType(appealSubservice, type) {
        this.appealSubservicesService.applyAgentType(appealSubservice, this.entity, type);
        this._updateErrors(); // Обновление статуса ошибок
    }

    public changeAgentProperty(appealSubservice, property) {
        this.appealSubservicesService.changeAgentProperty(appealSubservice, this.entity, property);
    }

    public cancelAddAgent(appealSubservice) {
        this.appealSubservicesService.cancelAddAgent(appealSubservice, this.entity);
    }

    public applyAddAgent(appealSubservice) {
        this.appealSubservicesService.applyAddAgent(appealSubservice, this.entity);
        this._getSubjectsForUseAsAgent();
    }

    public deleteAgent(appealSubservice) {
        this.appealSubservicesService.deleteAgent(appealSubservice, this.entity);
    }

    private _updateErrors() {
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    public deletePrincipal(appealSubservice, principal) {
        this.appealSubservicesService.deletePrincipal(appealSubservice, this.entity, principal);
    }

    public selectAgent(appealSubservice) {
        this.appealSubservicesService.selectAgent(appealSubservice, this.entity);
    }

    public applyAgent(appealSubservice, agent) {
        this.appealSubservicesService.applyAgent(appealSubservice, this.entity, agent);
    }

    public changeAgentPropertyInLinkObject(appealSubservice, property) {
        this.appealSubservicesService.changeAgentPropertyInLinkSubject(appealSubservice, this.entity, property);
    }

    public changeAgent(appealSubservice) {
        this.appealSubservicesService.changeAgent(appealSubservice, this.entity);
    }

    public applyAgentChanges(appealSubservice) {
        this.appealSubservicesService.applyAgentChanges(appealSubservice, this.entity);
    }

    public changePrincipalAgent(appealSubservice, principal) {
        this.appealSubservicesService.changePrincipalAgent(appealSubservice, this.entity, principal);
    }

    public changeAgentAuthorityType(appealSubservice) {
        this.appealSubservicesService.changeAgentAuthorityType(appealSubservice, this.entity);
    }

    public completeOnEnter(event, appealSubservice) {
        if (event.keyCode === 13) {
            this.applyAgentAuthority(appealSubservice);
        }
    }

    public setOnlyAgentPresentInSubservice(appealSubservice) {
        this.appealSubservicesService.setOnlyAgentPresentInSubservice(appealSubservice, this.entity);
        this.onReInitEditTabs.emit(true);
    }

    private _checkObjectCommonDataEmpty() {
        const subjectData = this.appealSubservicesService.subjectsData[this.entity.guid];

        return !subjectData
            || Object.keys(subjectData.data).length === 0
            || subjectData.specialTypeId === 'ulApplicant' && (!subjectData.data.organization || Object.keys(subjectData.data.organization).length === 0)
            || subjectData.specialTypeId !== 'ulApplicant' && (!subjectData.data.person || Object.keys(subjectData.data.person).length === 0);
    }

    /**
     * Проверка на возможность вывода ссылки на переход к заполнению основных данных участника
     */
    public async checkAllowCommonTabLink(appealSubservice) {
        const promise = new Promise(resolve => {
            setTimeout(() => {
                const data = this.data[appealSubservice.guid][this.appealField][this.entity.guid];

                const result = data.subKind
                    && (data.subKind.type.indexOf('third') !== -1
                        || data.subKind.type.indexOf('applicant') !== -1
                        || data.subKind.type.indexOf('principal') !== -1 && data.representative && data.representative.guid)
                    && !this.checkEditMode(appealSubservice) && this._checkObjectCommonDataEmpty();

                resolve(result);
            });
        });

        return promise;
    }

    public checkEditMode(appealSubservice) {
        const data = this.data[appealSubservice.guid][this.appealField][this.entity.guid];

        return data.editGroup
            || data.editCategory
            || data.isProcessingAddAgent
            || data.isProcessingChangeAgentKind
            || data.isProcessingChangeAgentName
            || data.isProcessingChangeAgentType
            || data.isProcessingChangeAgentAuthority
            || data.isProcessSelectAgent || data.isProcessingChangeAgent || data.isProcessingChangePrincipalAgent;
    }

    public activateCommonTab() {
        this.onActivateCommonTab.emit(true);
    }
}
