<div class="text-info text-bold cursor-pointer comments-indicator"
     [title]="localizations.common.comments"
     (click)="showComments()"
     *ngIf="isAllowComments">
    <i class="icon-bubbles6"></i>
    <span class="count">
        {{ getCommentsCount() }}
    </span>
</div>

<ng-template #commentsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.common.comments }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <evolenta-scrollbar maxHeight="650px">
            <ng-container *ngIf="!isEditComment">
                <div class="alert alert-warning"
                     *ngIf="getCommentsCount() === 0">
                    {{ localizations.standards.comments_not_added }}
                    <span class="anchor text-size-small"
                          *ngIf="block"
                          (click)="addComment()">
                        {{ localizations.common.add_small }}
                    </span>
                </div>
                <ng-container *ngFor="let blockCode of commentsBlocks">
                    <fieldset *ngIf="!block || blockCode == block.code">
                        <legend class="text-bold mb-10">
                            {{process.comments[blockCode].blockName}}
                        </legend>
                        <ul class="list-feed mb-20"
                            *ngIf="process.comments[blockCode].comments">
                            <li *ngFor="let comment of process.comments[blockCode].comments">
                                <div>
                                    <span class="text-muted">
                                        {{comment.date | date: 'mediumDate'}}
                                    </span>
                                    <span class="text-info ml-5">
                                        {{comment.user.name}}
                                    </span>
                                </div>
                                <div>
                                    {{comment.comment}}
                                </div>
                            </li>
                        </ul>
                    </fieldset>
                </ng-container>
            </ng-container>
            <div *ngIf="isEditComment">
                <div class="form-group form-group-lg" style="margin: 0 2px 2px">
                    <label>
                        {{ localizations.standards.add_comment_text }}
                        <span class="text-danger">*</span>
                    </label>
                    <textarea class="form-control"
                              [(ngModel)]="newComment"
                              rows="5"
                              #comment="ngModel"
                              required>
                    </textarea>
                    <div class="validation-error-label"
                         *ngIf="isApplyComment && comment.errors">
                        <span *ngIf="comment.errors.required">
                            {{ localizations.standards.comment_text_is_required }}
                        </span>
                    </div>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary pl-15 pr-15 pt-10 pb-10"
                type="button"
                (click)="addComment()"
                *ngIf="!isEditComment && block">
            {{ localizations.common.add_comment }}
        </button>
        <button class="btn btn-default pl-15 pr-15 pt-10 pb-10"
                type="button"
                (click)="modalRef.hide()"
                *ngIf="!isEditComment">
            {{ localizations.common.close }}
        </button>

        <button class="btn btn-primary pl-15 pr-15 pt-10 pb-10"
                type="button"
                *ngIf="isEditComment"
                (click)="saveComment()">
            {{ localizations.common.add_comment }}
        </button>
        <button class="btn btn-default pl-15 pr-15 pt-10 pb-10"
                type="button"
                *ngIf="isEditComment"
                (click)="clearNewComment()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>
