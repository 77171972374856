<div class="panel panel-preview">
    <div class="panel-heading no-border-radius no-border-left"
         *ngIf="mode == 'full'">
        <h6 class="panel-title">
            {{organization.name}}
            <div>
                <label class="badge text-uppercase"
                  [ngClass]="getStatusProperty(organization.isAuthorized,'background')">
                    {{getStatusProperty(organization.isAuthorized,'name')}}
                </label>
            </div>
        </h6>
        <div class="heading-elements">
            <a [title]="localizations.common.edit"
               routerLink="/admin/organizations/edit/{{organization._id}}">
                <i inlineSVG="assets/icon-edit.svg"></i>
            </a>
            <a [title]="localizations.common.delete"
               (click)="deleteOrganization()">
                <i inlineSVG="assets/icon-trash.svg"></i>
            </a>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body mb-20">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="panels.common = !panels.common">
                        <span>
                            {{ localizations.common.general_data }}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12': panels.common,
                                'icon-arrow-left12': !panels.common
                                }"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in' : panels.common}">
                        <div class="flex flex-row mb-10"
                             *ngIf="organization.shortName">
                            <div class="text-muted">
                                {{ localizations.common.short_name }}:
                            </div>
                            <div>
                                {{organization.shortName}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="organization.code">
                            <div class="text-muted">
                                {{ localizations.organizations.code }}:
                            </div>
                            <div>
                                {{organization.code}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="organization.code">
                            <div class="text-muted">
                                {{ localizations.organizations.is_entry_organization }}
                            </div>
                            <div>
                                {{organization.isAuthorized ?
                                    localizations.common.yes:
                                    localizations.common.no
                                }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="parentOrganization">
                            <div class="text-muted">
                                {{ localizations.organizations.parent }}:
                            </div>
                            <div>
                                {{parentOrganization.name}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="baseAddress">
                            <div class="text-muted">
                                {{ localizations.organizations.basic_address_for_address_elements }}:
                            </div>
                            <div>
                                {{baseAddress}}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>

<modal-dialog
    #deleteModal
    [title]="localizations.organizations.delete"
    [message]="modalMessage"
    [showNo]="false"
    (onSelect)="answerHandler($event)">
</modal-dialog>
