import { NgModule } from '@angular/core';
import { CoreModule } from '@evolenta/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InlineSVGModule } from 'ng-inline-svg';

import { SharedComponentsModule } from '../../common/shared-components.module';
import { SharedElementsModule } from '../../common/shared-elements.module';
import { EnvelopesModule } from '../envelopes/envelopes.module';
import { CommonAppealDocumentsComponent } from './components/appeal-documents/common-appeal-documents.component';
import { CommonAppealDocumentCardComponent } from './components/appeal-documents/components/document-card/common-appeal-document-card.component';
import { CommonAppealDocumentParticipantsComponent } from './components/appeal-documents/components/document-participants/common-appeal-document-participants.component';
import { CommonAppealDocumentCommonComponent } from './components/appeal-documents/components/document-common/common-appeal-document-common.component';
import { CommonAppealDocumentDocumentsComponent } from './components/appeal-documents/components/document-documents/common-appeal-document-documents.component';
import { CommonAppealDocumentEnvelopeComponent } from './components/appeal-documents/components/document-envelope/common-appeal-document-envelope.component';
import { CommonAppealDocumentPropertiesComponent } from './components/appeal-documents/components/document-properties/common-appeal-document-properties.component';
import { CommonAppealDocumentPreviewComponent } from './components/appeal-documents/components/document-preview/common-appeal-document-preview.component';
import { CommonAppealDocumentService } from './components/appeal-documents/common-appeal-document.service';
import { CommonAppealDocumentAmountComponent } from './components/appeal-documents/components/document-amount/common-appeal-document-amount.component';
import { DocumentObjectsFilterPipe } from './components/appeal-documents/document-objects-filter.pipe';
import { CommonAppealDocumentFilesComponent } from './components/appeal-documents/components/document-files/common-appeal-document-files.component';
import { CommonAppealSubserviceDocumentGroupsComponent } from './components/appeal-documents/components/document-groups/common-appeal-subservice-document-groups.component';
import { DocumentsFilterPipe } from './components/appeal-documents/documents-filter.pipe';

@NgModule({
    imports: [
        CoreModule,
        SharedComponentsModule,
        SharedElementsModule,
        InlineSVGModule,
        EnvelopesModule,
        DragDropModule,
    ],
    declarations: [
        CommonAppealDocumentsComponent,
        CommonAppealDocumentCardComponent,
        CommonAppealDocumentDocumentsComponent,
        CommonAppealDocumentEnvelopeComponent,
        CommonAppealDocumentFilesComponent,
        CommonAppealDocumentParticipantsComponent,
        CommonAppealDocumentPreviewComponent,
        CommonAppealDocumentPropertiesComponent,
        CommonAppealDocumentCommonComponent,
        DocumentObjectsFilterPipe,
        DocumentsFilterPipe,
        CommonAppealSubserviceDocumentGroupsComponent,
        CommonAppealDocumentAmountComponent,
    ],
    providers: [CommonAppealDocumentService],
    exports: [
        CommonAppealDocumentsComponent,
        CommonAppealSubserviceDocumentGroupsComponent,
        CommonAppealDocumentFilesComponent,
    ],
})
export class CommonAppealDocumentsModule {}
