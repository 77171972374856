import { NgModule } from '@angular/core';
import { NsiRouting } from './nsi.routing';
import { DictionariesModule } from '../../modules/dictionaries/dictionaries.module';

@NgModule({
    imports: [NsiRouting, DictionariesModule],
    declarations: [],
    providers: [],
    entryComponents: [],
})
export class NsiModule {}
