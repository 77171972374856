import { UniversalCollectionPageOptionsInterface } from '@evolenta/core';
import { NumberTemplatePreviewComponent, NumberTemplatesService } from '@evolenta/number-templates-module';
import get from 'lodash-es/get';

export const NumberTemplatesListOptions /*: UniversalCollectionPageOptionsInterface*/ = {
    elementsName: 'Шаблоны номеров',
    isSelectingAllowed: false,
    isExternalSearchAllowed: false,
    isMarkingAllowed: false,
    isUseItemButtonAllowed: false,
    isCreateButtonAllowed: true,
    createElementText: 'Новый шаблон',
    getCanCreateElementFunc: () => true,
    getCreateElementRouteFunc: (moduleUrl) => `/${moduleUrl}/number-templates/create`,
    getEditElementRouteFunc: (moduleUrl, sectionUrl, element) => `/${moduleUrl}/number-templates/edit/${element._id}`,
    getCollectionNameFunc: () => 'numberTemplates',
    defaultSort: 'dateCreation,DESC',
    getFilterNameFunc: () => 'numberTemplatesFilter',
    getFilterItemsFunc: () => NumberTemplatesService.FiltersPanelItems,
    getBaseSearchFunc: (context) => [{ field: 'unit.id', operator: 'eq', value: null }],
    getPreviewComponentFactoryFunc: (resolver) => resolver.resolveComponentFactory(NumberTemplatePreviewComponent),
    listItemOptions: {
        status: {
            isShownPrimary: true,
            getPrimaryPropertyFunc: (element, property) =>
                get(
                    NumberTemplatesService.Levels.find((l) => l.code === !!element.unit),
                    property,
                    ''
                ),
            isShownSecondary: false,
        },
        title: {
            isShownId: true,
            getIdFunc: (element) => element.auid,
            isShownTitle: true,
            getTitleFunc: (element) => element.name,
        },
        details: [{ title: 'Шаблон', getValueFunc: (element) => element.algorithm }],
    },
};
