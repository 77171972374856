<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div *ngIf="mode == 'view'" [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}">
            <span class="text-muted mr-5">Номер запроса: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указано</em>

            <span class="text-muted ml-10 mr-5">Дата запроса:</span>
            <ng-container *ngIf="entity.date">{{entity.date | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.date">не указано</em>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">

            <div class="pt-15 pl-20 pr-20">

                <!-- Данные запроса -->
                <div class="panel panel-shadow p-20">
                    <div class="panel-body no-padding pt-10">
                        <!-- Дата/номер -->
                        <div class="row">
                            <div class="col-md-3">
                                <label>Дата запроса</label>
                                <div class="form-group form-group-lg">
                                    <evolenta-datepicker [large]="true" [(ngModel)]="entity.date" [valueAsString]="true"></evolenta-datepicker>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>Номер запроса</label>
                                <div class="form-group form-group-lg">
                                    <input type="text" class="form-control input-xs" [(ngModel)]="entity.number" placeholder="Введите номер">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label>Срок предоставления</label>
                                <div class="form-group form-group-lg">
                                    <evolenta-datepicker [large]="true" [(ngModel)]="entity.requestedDocumentDate" [valueAsString]="true"></evolenta-datepicker>
                                </div>
                            </div>
                        </div>

                        <!-- Основание представления документов -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group form-group-lg">
                                    <label>Основание представления документов</label>
                                    <catalogue
                                        catalogName="nPA"
                                        name="requestedDocumentBasis"
                                        [(ngModel)]="entity.requestedDocumentBasis"
                                        [priority]="true"
                                        [large]="true"
                                        [disabled]="true">
                                    </catalogue>
                                </div>
                            </div>
                        </div>

                        <!-- Перечень запрашиваемых документов -->
                        <div class="mb-15">
                            <appeal-entity-catalogue
                                [entity]="entity"
                                [large]="true"
                                [property]="'requestedDocumentName'"
                                [catalogName]="'requestedDocument'"
                                [title]="'Перечень запрашиваемых документов'"
                                [placeholder]="'Выберите документ...'">
                            </appeal-entity-catalogue>
                        </div>

                        <!-- Прилагаемые документы -->
                        <div class="mb-15">
                            <appeal-entity-field-input
                                [multiple]="true"
                                [large]="true"
                                [entity]="entity"
                                [property]="'materials'"
                                [title]="'Прилагаемые документы'"
                                [placeholder]="'Введите наименование прилагаемого документа'">
                            </appeal-entity-field-input>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-md-6">
                                <label>ФИО лица, уполномоченного на проведение проверки</label>
                                <div class="form-group form-group-lg">
                                    <ng-select
                                        [allowClear]="true"
                                        [items]="usersForSelect"
                                        (selected)="selectEmployee($event)"
                                        [(ngModel)]="entity.employeeName"
                                        placeholder="Выберите значение">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label>Должность лица, уполномоченного на проведение проверки</label>
                                <div class="form-group form-group-lg">
                                    <input [(ngModel)]="entity.employeePosition" type="text" class="form-control input-xs">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="pt-15 pl-20 pr-20">
                <!-- Группы документов, привязанные к сведению -->
                <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
            </div>
        </div>
    </div>
</div>
