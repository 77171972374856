import { Injectable } from '@angular/core';
import { HttpService, StorageService } from '@evolenta/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Config } from '../../common/services/config';

@Injectable()
export class DashboardResolve implements Resolve<any> {
    public constructor(
        private storage: StorageService,
        private httpService: HttpService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const mode = route.data.mode;
        const currentOrganization = this.storage.getItem('currentOrganization');
        const params: any = { unitId: currentOrganization._id };

        if (mode !== 'all') {
            const currentUser = this.storage.getItem('user');

            params.login = currentUser.login;
        }

        return this.httpService.post(Config.server + Config.app + Config.api + 'business/getStats', params);
    }
}
