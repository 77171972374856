import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { SelectService } from '@evolenta/core';
import { AppealEntitiesService } from '../../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-employee',
    templateUrl: 'appeal-entity-employee.component.html',
    styles: ['::ng-deep .form-group .ui-select-toggle > .caret{top:10px!important;}'],
})
export class AppealEntityEmployeeComponent implements OnInit {
    @Input() public entity;
    @Input() public property: string = '';
    @Input() public multiple: boolean = false;
    @Input() public title: string = '';
    @Input() public large: boolean = false;
    @Input() public small: boolean = false;
    @Input() public field: any = [];
    @Input() public disabled = false;

    /** Список сотрудников */
    public userList = [];
    public userForSelect = [];

    /** Количество колонок */
    public columns = 1;
    @Input() public transformName: any = (user) => user.name;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService
    ) {}

    public ngOnInit() {
        if (this.field.length) {
            this.columns = 12 / this.field.length;
        }

        if (this.property && !this.entity[this.property]) {
            if (this.multiple) {
                this.entity[this.property] = [this.newItem()];
            } else {
                this.entity[this.property] = this.newItem();
            }
        }

        this.appealEntitiesService.getUsers().then((data) => {
            this.userList = data;
            if (this.userList) {
                this.userForSelect = this.selectService.transformForSelect(
                    'id',
                    'name',
                    this.userList.map((u) => ({ ...u, name: this.transformName(u) }))
                );
            }
        });
    }

    /**
     * Добавление нового элемента массива
     * @returns void
     */
    public addItem(): void {
        this.entity[this.property].push(this.newItem());
    }

    /**
     * Возвращает новый объект
     * @returns {object}
     */
    public newItem(): object {
        const newEmployee = {};
        newEmployee['guid'] = CommonUtilities.GenerateGuid();

        if (this.field.includes('name')) {
            newEmployee['name'] = '';
        }

        if (this.field.includes('position')) {
            newEmployee['position'] = '';
        }

        if (this.field.includes('phone')) {
            newEmployee['phone'] = '';
        }

        if (this.field.includes('email')) {
            newEmployee['email'] = '';
        }

        return newEmployee;
    }

    /**
     * Удаление ранее добавленного элемента
     * @param item
     * @returns void
     */
    public deleteItem(item): void {
        const findIndex = this.entity[this.property].findIndex((f) => f.guid === item.guid);
        this.entity[this.property].splice(findIndex, 1);
    }

    /**
     * Заполнение поля Должность, при наличии
     * @param value
     * @param idx
     * @returns void
     */
    public selectValue(value, idx = null): void {
        const userItem = this.userList.find((u) => u.id === value.id);
        if (this.multiple) {
            if (this.field.includes('position')) {
                this.entity[this.property][idx].position = userItem && userItem.position ? userItem.position : '';
            }
            if (this.field.includes('phone')) {
                this.entity[this.property][idx].phone = userItem && userItem.phone ? userItem.phone : '';
            }
            if (this.field.includes('email')) {
                this.entity[this.property][idx].email = userItem && userItem.email ? userItem.email : '';
            }
        } else {
            if (this.field.includes('position')) {
                this.entity[this.property].position = userItem && userItem.position ? userItem.position : '';
            }
            if (this.field.includes('phone')) {
                this.entity[this.property].phone = userItem && userItem.phone ? userItem.phone : '';
            }
            if (this.field.includes('email') && userItem && userItem.email) {
                this.entity[this.property].email = userItem && userItem.email ? userItem.email : '';
            }
        }
    }

    /**
     * Поиск ранее выбранного элемента списка
     * @param items
     * @param value
     * @returns {any}
     */
    public activeItemSelect(items, value): any {
        if (value && value.length) {
            return items.filter((item) => item.id === value[0].id);
        }
    }
}
