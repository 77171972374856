<fieldset class="mt-20">
    <legend class="pl-20 text-bold no-margin-bottom cursor-pointer">
        <div class="display-inline-block"  (click)="isFirst = !isFirst">
            <span *ngIf="number" class="mr-10">#{{number}}</span>{{data.isProcessActive ? 'Активный процесс' : 'Завершенный процесс'}}
            <span *ngIf="data.savedPlace && data.savedPlace.completedDate" class="ml-20">
                Дата завершения: {{data.savedPlace.completedDate | date: 'shortDate'}}, {{data.savedPlace.completedDate | date: 'shortTime'}}
            </span>
        </div>
        <a class="control-arrow"  (click)="isFirst = !isFirst"><i [ngClass]="{'icon-arrow-down12' : isFirst, 'icon-arrow-left12' : !isFirst}"></i></a>
        <div class="text-info text-bold cursor-pointer pull-right mr-20" title="Комментарии"
             (click)="showComments()" *ngIf="commentsPlace && commentsPlace.comments">
            <i class="icon-bubbles6"></i><span class="count">{{getCommentsCount()}}</span>
        </div>
    </legend>
    <div class="collapse" [ngClass]="{'in': isFirst}">
        <div class="alert alert-info no-border" *ngIf="data.isProcessUpdateData">
            <strong>Внимание!</strong> Осуществляется процесс обновления информации по процессу. Пожалуйста подождите... <i class="icon-spinner2 spinner"></i>
        </div>
        <entity-process-events [data]="data" [onLeft]="false"></entity-process-events>
        <entity-process-elements [data]="data"></entity-process-elements>
    </div>
</fieldset>

<ng-template #commentsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Комментарии</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <evolenta-scrollbar [maxHeight]="'650px'">
            <ng-container *ngFor="let blockCode of commentsBlocks">
                <fieldset>
                    <legend class="text-bold mb-10">{{commentsPlace.comments[blockCode].blockName}}</legend>
                    <ul class="list-feed mb-20" *ngIf="commentsPlace.comments[blockCode].comments">
                        <li *ngFor="let comment of commentsPlace.comments[blockCode].comments">
                            <div>
                                <span class="text-muted">{{comment.date | date: 'mediumDate'}}</span>
                                <span class="text-info ml-5">{{comment.user.name}}</span>
                            </div>
                            <div>{{comment.comment}}</div>
                        </li>
                    </ul>
                </fieldset>
            </ng-container>
        </evolenta-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default pl-15 pr-15 pt-10 pb-10" (click)="modalRef.hide()">Закрыть</button>
    </div>
</ng-template>


