<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            [disabled]="setting._id ? !access.hasAccess([permission.Setting_Update]) : !access.hasAccess([permission.Setting_Create])"
            (click)="save()">
        <b><i class="icon-checkmark-circle"
              [ngClass]="{'flash': isLoading}"></i></b>
        {{ localizations.common.save }}
    </button>
    <div class="ml-20 navbar-title mt-20">
        <h4>
            <i class="icon-arrow-left52 position-left cursor-pointer"
               (click)="back()"></i>
            {{ setting._id ?
                localizations.common.editing :
                localizations.common.creation }}
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="panel-main wide">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
            <div class="p-20">
                <div class="row"
                     *ngIf="!setting._id || (setting._id
                                             && ['ext.cto.integration.orgs',
                                                 'evolenta.eo.integration.pult.urls',
                                                 'evolenta.eo.integration.pult.api',
                                                 'integration.mkgu.settings'].indexOf(setting.name) == -1)">
                    <div class="col-lg-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label class="control-label"
                                   for="nameSetting">
                                {{ localizations.common.code }}
                                <span class="text-danger">*</span>
                            </label>
                            <input id="nameSetting"
                                   class="form-control"
                                   type="text"
                                   name="nameSetting"
                                   [(ngModel)]="setting.name">
                            <span class="help-block"></span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label"
                                   for="descriptionSetting">
                                {{ localizations.common.name }}
                            </label>
                            <input id="descriptionSetting"
                                   class="form-control"
                                   type="text"
                                   name="descriptionSetting"
                                   [(ngModel)]="setting.description">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label">
                                {{ localizations.organizations.value }}
                                <span class="text-danger">*</span>
                            </label>
                            <json-editor [(ngModel)]="setting.value"
                                         *ngIf="isValueObject">
                            </json-editor>
                            <input id="valueSetting"
                                   class="form-control"
                                   type="text"
                                   name="valueSetting"
                                   *ngIf="!isValueObject"
                                   [(ngModel)]="setting.value">
                        </div>
                        <div class="form-group">
                            <input #valueSettingObjectType
                                   id="valueSettingObjectType"
                                   class="checkbox"
                                   type="checkbox"
                                   [checked]="isValueObject"
                                   (change)="onValueSettingObjectTypeChanged()">
                            <label for="valueSettingObjectType">Объектный тип</label>
                        </div>
                    </div>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
