import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToasterService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-reject-conformity-conclusion',
    templateUrl: 'appeal-entity-reject-conformity-conclusion.component.html',
})
export class AppealEntityRejectConformityConclusionComponent {
    @Input() public entity; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public subservices; // описания услуг на основе которых формируется дело
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public isProcessValidate = false;
    @ViewChild('form', { static: false }) public form: NgForm;

    public PrintUtilities = PrintUtilities;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        private toaster: ToasterService
    ) {}

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.form.valid) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('Форма заполнена некорректно');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }
}
