<ng-container *ngIf="!block.valuePath || block.valuePath && blockValue && (!block.isMultiple || blockValue.length > 0)">
    <!-- Простые элементы -->
    <ng-container *ngIf="!block.isGroup && !block.specialType">

        <!-- Ключ - значение -->
        <p *ngIf="!isChild && !block.isMultiple && !block.xsdData"
           [ngClass]="{'no-margin': isLast}">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </p>

        <!-- Ключ - значение внутри строки -->
        <ng-container *ngIf="isChild">
            <ng-container *ngTemplateOutlet="blockContent; context:{ leftMargin: childIndex > 0 }">
            </ng-container>
        </ng-container>

        <!-- Простой элемент с множественным значением (список) -->
        <ng-container *ngIf="block.isMultiple">
            <fieldset>
                <legend class="text-bold text-normal mb-5">
                    {{ block.name }}
                </legend>
                <ul>
                    <li *ngFor="let item of blockValue">
                        <registry-blocks [dataSource]="item"
                                         [blocks]="block.contentItem"
                                         [noMargin]="true"
                                         [usePanel]="false">
                        </registry-blocks>
                    </li>
                </ul>
            </fieldset>
        </ng-container>

        <!-- Дополнительные поля -->
        <ng-container *ngIf="block.xsdData">
            <ng-container *ngTemplateOutlet="additionalDataContent"></ng-container>
        </ng-container>

    </ng-container>

    <!-- Группа элементов -->
    <ng-container *ngIf="block.isGroup && !checkIsEmpty()">
        <fieldset class="mt-10">
            <legend class="text-bold mt-10 text-uppercase cursor-pointer mb-10"
                    (click)="block.hide = !block.hide">
                {{ block.name }}
                <span class="text-slate text-normal ml-10"
                      *ngIf="block.header">
                    {{ getValueByPath(dataSource, block.header) }}
                </span>
                <span class="badge bg-info-300 text-uppercase ml-10"
                      *ngIf="block.isMultiple && block.showCount && block.valuePath && blockValue">
                    {{ localizations.common.total }}: {{blockValue.length}}
                </span>
                <a class="control-arrow">
                    <i [ngClass]="{
                            'icon-arrow-down32': !block.hide,
                            'icon-arrow-left32': block.hide
                        }">
                    </i>
                </a>
            </legend>
            <div [hidden]="block.hide">
                <ng-container *ngIf="block.content">
                    <registry-blocks [dataSource]="dataSource"
                                     [blocks]="block.content"
                                     [noMargin]="true"
                                     [marginBottom]="!isLast">
                    </registry-blocks>
                </ng-container>
                <ng-container *ngIf="block.isMultiple">
                    <ng-container *ngFor="let item of blockValue; let lst = last">
                        <registry-blocks [dataSource]="item"
                                         [blocks]="block.contentItem"
                                         [noMargin]="true"
                                         [parentData]="dataForChildren"
                                         [marginBottom]="!lst">
                        </registry-blocks>
                    </ng-container>
                </ng-container>
            </div>
        </fieldset>
    </ng-container>

    <!-- Специальные типы -->
    <ng-container *ngIf="block.specialType">
        <ng-container *ngTemplateOutlet="specialTypeComponent"></ng-container>
    </ng-container>
</ng-container>

<ng-template let-leftMargin="leftMargin"
             #blockContent>
    <span class="text-muted mr-5"
          [ngClass]="{'ml-10': leftMargin}"
          *ngIf="block.name">
        {{ block.name }}:
    </span>
    <span [innerHTML]="getBlockValue()">
    </span>
</ng-template>


<!-- ШАБЛОН: дополнительные данные -->
<ng-template #additionalDataContent>
    <ng-container *ngIf="getXsdDataProperty(block.xsdData.xsd, block.xsdData.xsdFromParentData) && getXsdDataProperty(block.xsdData.model)">
        <dynamic-form
            [model]="getXsdDataProperty(block.xsdData.model)"
            [xsd]="getXsdDataProperty(block.xsdData.xsd, block.xsdData.xsdFromParentData)"
            [textView]="true">
        </dynamic-form>
    </ng-container>
</ng-template>

<!-- ШАБЛОН: Специальный тип -->
<ng-template #specialTypeComponent>

    <!--  Номер дела с ссылкой на него + реестровая запись с типом операции и текущим статусом  -->
    <ng-container *ngIf="block.specialType === 'appealAndRegistryInfo'">
        <ng-container *ngTemplateOutlet="specialTypeAppealAndRegistryInfo"></ng-container>
    </ng-container>

    <!--  Список файлов  -->
    <ng-container *ngIf="block.specialType === 'files'">
        <ng-container *ngTemplateOutlet="specialTypeFiles"></ng-container>
    </ng-container>

    <!--  Карточка объекта  -->
    <ng-container *ngIf="block.specialType === 'objectCard'">
        <ng-container *ngTemplateOutlet="specialTypeObjectCard"></ng-container>
    </ng-container>

    <!--  Карточка субъекта  -->
    <ng-container *ngIf="block.specialType === 'subjectCard'">
        <ng-container *ngTemplateOutlet="specialTypeSubjectCard"></ng-container>
    </ng-container>

</ng-template>


<!--  ШАБЛОН: Номер дела с ссылкой на него + реестровая запись с типом операции и текущим статусом  -->
<ng-template #specialTypeAppealAndRegistryInfo>
    <p>
        <ng-container *ngIf="dataSource.appealEntry">
            <span class="text-muted">
                {{ localizations.registers.application }}:
            </span>
            № {{dataSource.appealEntry.number}}
            {{ localizations.common.from }}
            {{dataSource.appealEntry.date | date: 'shortDate'}}
            <span class="text-size-small anchor text-info ml-10"
                  (click)="goToAppeal()">
                {{ localizations.common.proceed }}
            </span>
        </ng-container>

        <span class="text-muted ml-10"
              [ngClass]="{'ml-10': dataSource.appealEntry}">
            {{ localizations.registers.entry }}:
        </span>
        <ng-container *ngIf="dataSource.auid">
            № {{dataSource.auid}}
        </ng-container>
        {{ localizations.common.from }}
        {{dataSource.dateCreation | date: 'shortDate'}}

        <!-- тип операции -->
        <span class="badge {{RegistersService.GetStatusProperty(dataSource.operationType, 'background', 'OperationTypes')}} ml-10 text-uppercase">
            {{ RegistersService.GetStatusProperty(dataSource.operationType, 'registerName', 'OperationTypes') }}
        </span>
        <!-- статус -->
        <span class="badge {{RegistersService.GetStatusProperty(dataSource.status, 'background', 'RegisterStatuses')}} text-uppercase ml-10">
            {{ RegistersService.GetStatusProperty(dataSource.status, 'name', 'RegisterStatuses') }}
        </span>
    </p>
</ng-template>


<!--  ШАБЛОН: Список файлов  -->
<ng-template #specialTypeFiles>
    <fieldset class="mt-10"
              *ngIf="blockValue && blockValue.length > 0">
        <legend class="text-size-mini text-bold mb-10">
            {{ localizations['appeals.documents'].files }}
        </legend>
        <ul class="no-margin">
            <ng-container *ngFor="let document of blockValue; let fstDocument = first; let lstDocument = last">
                <li *ngFor="let file of document.files; let fst = first">
                    <a class="anchor"
                       (click)="registersService.downloadFile(file)">
                        {{ file.originalName }}
                    </a>
                    <div class="display-inline-block ml-20"
                         *ngIf="file.certificate">
                        <i class="icon-medal text-danger cursor-pointer"
                           [title]="localizations.common.open_certificate"
                           (click)="registersService.openCertificate(file.certificate)">
                        </i>
                        <span class="text-size-small mr-10"
                              *ngIf="file.certificate">
                            {{ registersService.getFileCertificateInfo(file) }}
                        </span>
                    </div>
                </li>
            </ng-container>
        </ul>
    </fieldset>
</ng-template>


<!--  ШАБЛОН: Карточка объекта  -->
<ng-template #specialTypeObjectCard>
    <div class="mb-10">
        <object-preview-card [object]="blockValue ? blockValue : dataSource"
                             [showLabels]="true"
                             [showName]="true"
                             [showDetail]="false">
        </object-preview-card>
    </div>

</ng-template>


<!--  ШАБЛОН: Карточка субъекта  -->
<ng-template #specialTypeSubjectCard>
    <subject-preview-card [subject]="blockValue ? blockValue : dataSource"
                          [isFullCard]="true"
                          [showHeader]="true"
                          [noPadding]="true">
    </subject-preview-card>
</ng-template>
