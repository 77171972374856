import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessService, ModalDialogComponent, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealValidateService } from '../../appeal-validate.service';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealSubservicesService } from '../../appeal-subservices.service';
import { AppealStatusService } from '../../appeal-status.service';
import { Permission } from '../../../../../common/services/permission';
import { DocumentService } from '../documents/document.service';
import { AppealSubjectCardComponent } from './appeal-subject-card/appeal-subject-card.component';
import { AppealService } from '../../appeal.service';
import { IndividualObjectData } from '../../../../subjects/individual-object/components/individual-applicant-form/common/individual-object.data';
import { map, take } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import omit from 'lodash-es/omit';

@Component({
    selector: 'appeal-subjects',
    templateUrl: 'appeal-subjects.component.html',
})
export class AppealSubjectsComponent implements OnInit {
    public appeal; // Текущее дело
    public subservice; // Услуга, на основании которой формируется дело
    public activeTab; // активная вкладка при редактировании пользователя

    public availableSubjectKinds = []; // Досупные для выбора виды субъектов
    public editedSubject = null; // Субъект, который находится в данный момент в статусе редактирования

    @ViewChild('modalDialog', { static: false }) public modalDialogComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', { static: false }) public editSubjectCardComponent: AppealSubjectCardComponent; // Компонент - карточка редактирования субъекта дела

    public allowEdit = true; // Возможность редактирования субъектов дела

    public permissions = Permission; // Набор прав системы
    public localizations;

    public constructor(
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        private appealSaveService: AppealSaveService,
        public router: Router,
        private toaster: ToasterService,
        private appealSubservicesService: AppealSubservicesService,
        private validateService: AppealValidateService,
        private saveService: AppealSaveService,
        private documentService: DocumentService,
        private translate: TranslateService,
        private appealService: AppealService,
        private route: ActivatedRoute,
        private storage: StorageService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.appealService.editing = false;
        this._loadTranslations();
        this.appealSaveService.nextSection = 'subjects';
        this.appeal = this.appealService.appeal;
        if (!this.appeal) {
            this.storage.cacheItem('nextRoute', 'subjects');
            const route = this.router.url.split('/subjects')[0];
            this.router.navigate([route]);
        } else {
            this.subservice = this.appealService.subservice;
            // Запрещено редактирование, если дело находится в финальном статусе, или зарегистрировано
            this.allowEdit = this.appealStatusService.allowEdit && this.appeal.status.code !== 'process';
            // формирование массива уникальных видов/типов субъетов из услуг, по которым формируется дело
            this.getUniqueSubjectsKinds();
        }

        const createNewUser = this.storage.getFromCache('createNewUser');
        if (createNewUser) {
            this.storage.removeFromCache('createNewUser');
            this.addSubject();
            this.activeTab = 'subservice';
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.subjects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Добавление нового субъекта в дело
     */
    public addSubject() {
        const subjectGuid = CommonUtilities.GenerateGuid();
        const subject = {
            guid: subjectGuid,
            kind: null,
            data: {},
            specialTypeId: null,
        };
        if (this.availableSubjectKinds.length === 1) {
            subject.kind = Object.assign({}, this.availableSubjectKinds[0]);
            if (subject.kind.subKinds.length === 1) {
                subject.kind.subKind = Object.assign({}, subject.kind.subKinds[0]);
                subject.specialTypeId = subject.kind.subKind.specialTypeId;
                if (subject.specialTypeId === 'ulApplicant') {
                    subject.data = { organization: {} };
                } else {
                    subject.data = { person: {} };
                }
                this.activeTab = 'subservices';
            } else {
                subject.kind.subKind = null;
                subject.specialTypeId = null;
            }
        }

        // Добавление информации о субъекте в служебный объект
        this.appealSubservicesService.subjectsData[subjectGuid] = cloneDeep(subject);

        // Инициализация блока данных объекта в каждой услуге для работы внутри компонентов
        Object.keys(this.appealSubservicesService.data).forEach((key) => {
            this.appealSubservicesService.data[key].subjects[subjectGuid] = { active: false, objects: [] };
            // Инициализация групп документов при добавлении пользователя
            if (subject.kind && this.appeal.subservice.guid === key) {
                this.appealSubservicesService.generateServiceGroups(this.appeal.subservice, subject);
            }
        });
        this.validateService.initValidateElement('subjects', subjectGuid);

        // Переход в режим редактирования добавляемого субъекта
        this.onEditSubject(subject);
    }

    /**
     * Переход в режим редактирования субъекта
     * @param subject
     */
    public activateEditMode(subject) {
        // Сохраняем субъект в переменную редактирования
        setTimeout(() => {
            this.editedSubject = subject;
        }, 100);
    }

    /**
     * Формирование массива уникальных видов субъектов из данных услуг
     */
    public getUniqueSubjectsKinds() {
        let isExistPrincipal = false; // флаг наличия в услуге доверителя
        let isExistIndividualApplicant = false; // флаг наличия ФЛ в составе видов участников
        let isExistUlApplicant = false; // флаг наличия ЮЛ в составе участников
        if (this.subservice.objects) {
            this.subservice.objects.objectKinds.forEach((kind) => {
                if (kind.type === 'participant') {
                    const findIndex = this.availableSubjectKinds.findIndex((item) => item.guid === kind.guid);
                    let editKind;
                    if (findIndex === -1) {
                        editKind = {
                            guid: kind.guid,
                            type: kind.type,
                            name: kind.name,
                            description: kind.description,
                            subKinds: [],
                        };
                    } else {
                        editKind = this.availableSubjectKinds[findIndex];
                    }
                    kind.subKinds.forEach((subKind) => {
                        if (subKind.type.indexOf('principal') !== -1) {
                            isExistPrincipal = true; // В настройке услуги есть доверители
                        }

                        // Обрабатываем только не представителей
                        if (subKind.type.indexOf('agent') === -1) {
                            if (subKind.specialTypeId === 'ulApplicant') {
                                isExistUlApplicant = true;
                            }
                            if (subKind.specialTypeId === 'individualApplicant') {
                                isExistIndividualApplicant = true;
                            }
                            const findSubKind = editKind.subKinds.find((item) => item.name === subKind.secondGroupName);
                            if (!findSubKind) {
                                editKind.subKinds.push({
                                    name: subKind.secondGroupName,
                                    specialTypeId: subKind.specialTypeId,
                                    headerOptions: subKind.headerOptions,
                                    shortHeaderOptions: subKind.shortHeaderOptions,
                                    type: subKind.specialTypeId === 'realty' ? subKind.type : null,
                                });
                            }
                        }
                    });
                    if (findIndex === -1) {
                        this.availableSubjectKinds.push(editKind);
                    } else {
                        this.availableSubjectKinds[findIndex] = editKind;
                    }
                }
            });
        } else {
            // Добавляем ЮЛ
            this.availableSubjectKinds.push({
                name: 'Юридическое лицо',
                specialTypeId: 'ulApplicant',
                headerOptions: ['f|data.organization.shortName', 's|, ОГРН: ', 'f|data.organization.ogrn'],
                shortHeaderOptions: ['f|data.organization.shortName'],
                type: 'applicantUl',
            });
            // Добавляем ИП
            this.availableSubjectKinds.push({
                name: 'Индивидуальный предприниматель',
                specialTypeId: 'ipApplicant',
                headerOptions: [
                    'f|data.person.lastName',
                    's| ',
                    'f|data.person.firstName',
                    's| ',
                    'f|data.person.middleName',
                    's|, ',
                    'f|data.person.birthday.formatted',
                    's| г.р.',
                ],
                shortHeaderOptions: [
                    'f|data.person.lastName',
                    's| ',
                    'f|data.person.firstName|1',
                    's|.',
                    'f|data.person.middleName|1',
                    's|.',
                ],
                type: 'applicantIp',
            });
        }

        if (isExistPrincipal && (!isExistIndividualApplicant || !isExistUlApplicant)) {
            const participantIndex = this.availableSubjectKinds.findIndex((item) => item.type === 'participant');
            if (!isExistIndividualApplicant) {
                this.availableSubjectKinds[participantIndex].subKinds.push({
                    name: 'Физическое лицо',
                    specialTypeId: IndividualObjectData.individualPersonCode,
                    headerOptions: IndividualObjectData.individualHeaderOptions.header,
                    shortHeaderOptions: IndividualObjectData.individualHeaderOptions.shortHeader,
                    type: null,
                    onlyForPrincipal: true,
                });
            }
            if (!isExistUlApplicant) {
                this.availableSubjectKinds[participantIndex].subKinds.push({
                    name: 'Юридическое лицо',
                    specialTypeId: 'ulApplicant',
                    headerOptions: IndividualObjectData.ulHeaderOptions.header,
                    shortHeaderOptions: IndividualObjectData.ulHeaderOptions.shortHeader,
                    type: null,
                    onlyForPrincipal: true,
                });
            }
        }
    }

    /**
     * Инициализация режима редактирования субъекта
     * @param subject
     */
    public onEditSubject(subject) {
        this.appealSubservicesService.tempData = cloneDeep(this.appealSubservicesService.data);
        this.appealSubservicesService.tempSubjectsData = cloneDeep(this.appealSubservicesService.subjectsData);
        this.activateEditMode(this.appealSubservicesService.subjectsData[subject.guid]);
    }

    /**
     * Применение (отмена) изменений в субъекте, переход к режиму списка субъектов
     * @param data - объект формата {subject: измененный субъект, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public onApplySubject(subjectAndContinueFlag) {
        if (subjectAndContinueFlag) {
            const subjectIndex = this.appeal.subjects.findIndex(
                (item) => item.guid === subjectAndContinueFlag.subject.guid
            );
            // Применение изменений в структуру дела в блок appeal.objects
            const subjectData = cloneDeep(subjectAndContinueFlag.subject);
            delete subjectData.fieldRequirements;
            if (subjectIndex !== -1) {
                this.appeal.subjects[subjectIndex] = cloneDeep(subjectData);
            } else {
                this.appeal.subjects.push(cloneDeep(subjectData));
            }
            // Настройка субъектов в привязке к услугам
            this.appealSubservicesService.correctEntitiesDataInSubserviceOnApply();
            // Проверка добавления представителя для текущего объекта
            this.appealSubservicesService.checkExistAgentInAppeal(subjectAndContinueFlag.subject);
            // this.appealSubservicesService.correctSubjectDataInSubserviceOnApply();
            // Переинициализация состава документов в соответствии с произведенными настройками (возможно в процессе настройки были изменения в варианте участия объекта в услуге)
            this.documentService.reInitSubserviceData(); // обновление настроек услуг в сервисе документов
            this.documentService.correctSubserviceDocGroups(); // корректировка состава групп документов

            this.appealSaveService.appeal.subjects = cloneDeep(this.appeal.subjects);
            this.appealSaveService.appeal.subservice.subjects = cloneDeep(this.appeal.subservice.subjects);
            this.appealService.appeal.subjects = cloneDeep(this.appeal.subjects);
            this.appealService.appeal.subservice.subjects = cloneDeep(this.appeal.subservice.subjects);

            // this.appealSubservicesService.data[this.appeal.subservice.guid] = this.appealSubservicesService.data[this.appeal.subservice.guid] || cloneDeep(this.subservice);
            // this.appealSubservicesService.data[this.appeal.subservice.guid].subjects = this.appealSubservicesService.data[this.appeal.subservice.guid].subjects || {};
            // this.appealSubservicesService.data[this.appeal.subservice.guid].subjects[subjectAndContinueFlag.subject.guid] = subjectAndContinueFlag.subject;
            // Передача управления в родительский компонент для продолжения операций с делом: сохранение, переход на новый статус
            if (
                subjectAndContinueFlag.continueProcessingAppeal ||
                this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup
            ) {
                if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
                    this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete = true;
                }
                // this.onApply.emit(true);
            } else if (this.appeal._id) {
                this.saveService.saveAppeal().then(
                    () => {
                        this.toaster.success('Субъект успешно сохранен');
                    },
                    (error) => {
                        this.toaster.error(error);
                    }
                );
            }
        } else {
            // Отмена изменений, внесенных в режиме редактирования субъекта, копирование данных из временных объектов
            this.appealSubservicesService.data = cloneDeep(this.appealSubservicesService.tempData); // данные по услугам
            this.appealSubservicesService.subjectsData = cloneDeep(this.appealSubservicesService.tempSubjectsData); // данные по объектам

            if (this.appealSubservicesService.isProcessingAddSubjectToAppealSubserviceGroup) {
                this.appealSubservicesService.processingAddSubjectToAppealSubserviceGroupData.isComplete = true;
                // this.onApply.emit(true);
            }

            // сохранение дела
            if (this.appeal._id) {
                this.saveService.saveAppeal().then(
                    () => {},
                    (error) => {
                        this.toaster.error(error);
                    }
                );
            }
        }
        // Сброс объектов с временными данными
        this.appealSubservicesService.tempData = {};
        this.appealSubservicesService.tempSubjectsData = {};
        this.appealSubservicesService.correctSubjectsDataByAppeal();

        // ПереКНМ дела
        this.validateService.validateAppeal(this.validateService.processValidate);

        this.activeTab = null;
        this.editedSubject = null;
    }

    /**
     * Инициализация режима редактирования при клике на ссылки внутри карточки-предспросмотра объекта
     * @param data - данные в формате {object: объект для редактирования, tab: активная вкладка}
     */
    public initEdit(data) {
        this.activeTab = data.tab;
        this.onEditSubject({ guid: data.subjectGuid });
        // this.editObject = _.cloneDeep(this.appeal.objects.find(item => item.guid === data.objectGuid));
    }

    /**
     * Удаление субъекта из дела
     * @param subject - удаляемый субъект
     */
    public async onDeleteSubject(subject) {
        // Удаление настроек удаляемого объекта в услугах из дела
        if (this.appeal.subservice.subjects && this.appeal.subservice.subjects.length > 0) {
            const findIndex = this.appeal.subservice.subjects.findIndex((item) => item.guid === subject.guid);
            if (findIndex !== -1) {
                // Удаление настроек удаляемого объекта из услуг дела
                this.appeal.subservice.subjects.splice(findIndex, 1);
            }
            // Если удаляемый объект является представителем для другого объекта, удаляем настройки представительства
            this.appeal.subservice.subjects.forEach((sbj) => {
                if (sbj.representative && sbj.representative.guid === subject.guid) {
                    sbj.representative = null;
                }
            });
        }

        // Удаление субъекта из массива объектов дела
        const subjectIndex = this.appeal.subjects.findIndex((item) => item.guid === subject.guid);
        this.appeal.subjects.splice(subjectIndex, 1);

        // Сохранение дела

        try {
            if (this.appeal._id) {
                await this.saveService.saveAppeal();
                this.validateService.removeValidateElement('subjects', subject.guid);
            }

            await this._afterDeleteSubject();
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * Операции после удаления дела из дела (с сервера и состава дела)
     */
    private async _afterDeleteSubject() {
        // Корректировка данных сервисов
        await this.saveService.correctServiceDataAfterDeleteAppealEntity();
        this.toaster.success('Субъект удален из дела');
    }

    /**
     * Определение возможности деактивации маршрута компонента {@link CanDeactivateGuard}
     */
    public canDeactivate(): Promise<boolean> {
        // Проерка на необходимость сохранения изменений в редактируемый в данный момент субъект
        if (this.editedSubject && this.checkSubjectChange()) {
            this.modalDialogComponent.title = this.localizations['common'].confirm_changes;
            this.modalDialogComponent.message = this.localizations['appeals.subjects'].apply_changes;
            this.modalDialogComponent.showModal();

            return this.modalDialogComponent.onSelect
                .pipe(
                    take(1),
                    map((answer: string) => this.canDeactivateByAnswer(answer))
                )
                .toPromise();
        } else {
            return Promise.resolve(true);
        }
    }

    /**
     * Проверка на возможные изменения в объекте
     */
    public checkSubjectChange(): boolean {
        const findAppealSubject = this.appeal.subjects.find((item) => item.guid === this.editedSubject.guid);
        // КНМ изменений в основной сущности объекта
        let hasChange = !isEqual(omit(this.editedSubject, 'fieldRequirements'), findAppealSubject);

        // Если объект в основной сущености не изменился, проверяем изменения в параметрах привязки объекта к услугам дела
        if (!hasChange) {
            const hasChangeInAppealSubservice = this.appealSubservicesService.checkChangeEntityDataInAppealSubservice(
                this.appeal.subservice,
                this.editedSubject
            );

            hasChange = hasChangeInAppealSubservice ? hasChangeInAppealSubservice : hasChange;
        }

        return hasChange;
    }

    /**
     * Разрешение деактивации исходя из ответа в модальном окне о сохранении изменений субъекта
     * @param answer - ответ из модального окна (yes, no, cancel)
     */
    private canDeactivateByAnswer(answer: string): boolean {
        if (answer === 'yes') {
            // Сохранение данных и деактивация
            if (this.editSubjectCardComponent) {
                this.editSubjectCardComponent.apply();
            }

            return true;
        }

        if (answer === 'no') {
            // Сброс внесенных изменений и деактивация
            if (this.editSubjectCardComponent) {
                this.editSubjectCardComponent.cancel();
            }

            return true;
        }

        // Запрет деактивации при отмене
        return false;
    }
}
