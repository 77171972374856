<div *ngIf="subserviceEntityData || subserviceXsd || subjectTypeXsd"
    [ngClass]="{'pl-20 pr-20 pb-20': subserviceEntityData && (subserviceEntityData.mainXsd || subserviceEntityData.xsd || subserviceXsd || subjectTypeXsd)}">

    <ng-container *ngIf="subjectTypeXsd">
        <dynamic-form
            #subjectTypeAdditionalData
            [model]="subjectTypeSavedPlace"
            [saveInMainObject]="true"
            [xsd]="subjectTypeXsd"
            [externalData]="{appeal: appeal, entity: entity}"
            [useXsdService]="appealService.useXsdService"
            [textView]="mode === 'view' || permissions && !permissions.editTypeXsd">
        </dynamic-form>
    </ng-container>

    <ng-container *ngIf="subserviceEntityData && subserviceEntityData.mainXsd">
        <dynamic-form
            #mainAdditionalData
            [model]="entity.xsdData"
            [saveInMainObject]="true"
            [xsd]="subserviceEntityData.mainXsd"
            [externalData]="{appeal: appeal, entity: entity}"
            [useXsdService]="appealService.useXsdService"
            [textView]="mode === 'view' || permissions && !permissions.editMainXsd">
        </dynamic-form>
    </ng-container>

    <ng-container *ngIf="subserviceXsd">
<!--        <dynamic-form-->
<!--            #subserviceAdditionalData-->
<!--            [model]="subserviceData"-->
<!--            [saveInMainObject]="true"-->
<!--            [xsd]="subserviceXsd"-->
<!--            [textView]="mode === 'view'">-->
<!--        </dynamic-form>-->
    </ng-container>

    <!--  Дополнительные данные -->
    <fieldset *ngIf="subserviceEntityData && subserviceEntityData.xsd">
        <legend class="text-bold text-size-small no-margin-bottom cursor-pointer" (click)="showAdditionalData = !showAdditionalData">
            Дополнительная информация
            <span class="text-danger ml-10 text-normal" *ngIf="(this.isProcessValidate || validateService.processValidate) && !entity.xsdDataValid"><i class="icon-alert"></i> <strong>Внимание! </strong> Ошибки заполнения блока дополнительных данных</span>
            <a class="control-arrow">
                <i [ngClass]="{'icon-arrow-down32': showAdditionalData, 'icon-arrow-left32': !showAdditionalData}"></i>
            </a>
        </legend>
        <div class="mt-15" [hidden]="!showAdditionalData">
            <dynamic-form
                #additionalData
                [model]="entity.xsdData"
                [saveInMainObject]="true"
                [externalData]="{appeal: appeal, entity: entity}"
                [xsd]="subserviceEntityData.xsd"
                [useXsdService]="appealService.useXsdService"
                [textView]="mode === 'view' || permissions && !permissions.editXsd">
            </dynamic-form>
        </div>
    </fieldset>
</div>
