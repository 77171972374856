<ng-container *ngIf="!editedGroup && !editedDocument">
    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false">
    </common-standard-block-with-additional-data>


    <div class="dndZoneList"
         [dndDropzone]="['element']"
         dndEffectAllowed="copyMove"
         [dndHorizontal]="true"
         (dndDrop)="onDrop($event)">
        <div class="row no-margin-right no-margin-left no-margin-top"
             *ngFor="let group of activeData.entity.documentGroups"
             [dndDraggable]="group"
             dndType="element"
             (dndMoved)="onDragged($event, group)"
             dndEffectAllowed="move">
            <div class="zone">

                <!-- Элемент -->
                <div class="panel panel-shadow panel-small">
                    <div class="panel-heading">
                        <i class="icon-move text-slate"
                           dndHandle
                           [title]="localizations.common.shift">
                        </i>
                        <h5 class="panel-title pt-10"
                            [ngStyle]="{'max-width': '100%'}">
                            {{ group.name }}
                        </h5>
                        <div class="heading-elements">
                            <div class="heading-elements">
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="localizations.standards.add_document"
                                        *ngIf="activeData.allowEdit"
                                        (click)="addDocument(group)">
                                    <i inlineSVG="assets/icon-new-item.svg"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="activeData.allowEdit ? localizations.standards.edit_groups : localizations.standards.view_groups_data"
                                        (click)="editGroup(group)">
                                    <i [inlineSVG]="activeData.allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                                </button>
                                <button class="btn btn-default btn-sxs"
                                        type="button"
                                        [title]="localizations.common.delete"
                                        *ngIf="activeData.allowEdit"
                                        (click)="deleteGroup(group)">
                                    <i inlineSVG="assets/icon-trash.svg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body no-padding"
                         *ngIf="group.docs && group.docs.length">
                        <table class="table">
                            <tbody>
                                <tr dndType="element"
                                    [dndDraggable]="true"
                                    (dndStart)="onFileDragged(idx, document)"
                                    (dndCanceled)="onDragCanceled()"
                                    (dndEnd)="onDragEnd()"
                                    dndEffectAllowed="copy"
                                    dndDropzone
                                    *ngFor="let document of group.docs; let fst=first; let idx = index;"
                                    (dndDrop)="onFileDropped(idx, group.docs)">
                                    <td width="20"
                                        [ngClass]="{'no-border-top': fst}" class="no-padding-right">
                                        <i class="text-slate mr-5 icon-file-text2 ng-star-inserted"></i>
                                    </td>
                                    <td class="no-padding-left"
                                    [ngClass]="{'no-border-top': fst}">
                                    {{ document.name }}
                                </td>
                                    <td class="text-right"
                                    [ngClass]="{'no-border-top': fst}">
                                    <button class="btn btn-default btn-sxs"
                                            type="button"
                                            [title]="activeData.allowEdit ? localizations.common.edit : localizations.common.view"
                                            (click)="editDocument(document, group)">
                                        <i [ngClass]="{
                                            'icon-pencil': activeData.allowEdit,
                                            'icon-eye': !activeData.allowEdit
                                            }">
                                        </i>
                                    </button>
                                    <button class="btn btn-default btn-sxs"
                                            type="button"
                                            [title]="localizations.common.delete"
                                            *ngIf="activeData.allowEdit"
                                            (click)="deleteDocument(document, group)">
                                        <i class="icon-trash"></i>
                                    </button>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-12 bg-primary-50 dndPlaceholder p-20"
             dndPlaceholderRef>
        </div>
    </div>


    <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
            *ngIf="activeData.allowEdit"
            (click)="addGroup()">
        <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
        {{ localizations.standards.add_documents_group }}
    </button>
</ng-container>

<ng-container *ngIf="editedGroup">
    <common-standard-document-group-edit [group]="editedGroup"
                                         [data]="activeData"
                                         [allowEdit]="activeData.allowEdit"
                                         (onApply)="applyGroup()"
                                         (onCancel)="cancelEdit()"
                                         (onEditXsd)="editXsd($event)">
    </common-standard-document-group-edit>
</ng-container>

<ng-container *ngIf="editedDocument">
    <common-standard-document-edit [group]="editedDocumentGroup"
                                   [document]="editedDocument"
                                   [allowEdit]="activeData.allowEdit"
                                   (onApply)="applyDocument()"
                                   (onCancel)="cancelEdit()"
                                   (onEditXsd)="editXsd($event)">
    </common-standard-document-edit>
</ng-container>

