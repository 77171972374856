
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ModuleGuard } from '@evolenta/core';
import { ReportsResolve } from './reports/reports.resolve';
import { RegistersComponent } from './reports/registers/registers.component';
import { ReportsOctonicaComponent } from './reports/octonica/reports-octonica.component';
import { ReportsOctonicaResolve } from './reports/octonica/reports-octonica.resolve';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'reports',
        component: SidebarComponent,
        data: {
            module: 'reports',
            breadcrumb: 'Отчёты',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            {
                path: 'create',
                component: RegistersComponent,
                data: {
                    name: 'Отчёты',
                    img: 'main-menu/reports',
                    breadcrumb: 'Создание отчёта',
                },
                resolve: {
                    resolves: ReportsResolve,
                },
            },
            {
                path: '',
                redirectTo: 'create',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'reports/octonica',
        component: SidebarComponent,
        data: {
            breadcrumb: 'Отчёты Октоника',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            {
                path: '',
                component: ReportsOctonicaComponent,
                data: {
                    name: 'Отчёты Октоника',
                    img: 'main-menu/reports',
                    breadcrumb: 'Отчёты и аналитика (Октоника)',
                },
                resolve: {
                    reportTypes: ReportsOctonicaResolve,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class ReportsRouting {
}
