import { Injectable } from '@angular/core';
import { RestService } from '@evolenta/core';
import { NgProgress } from 'ngx-progressbar';

@Injectable()
export class TaskPingService {
    // Идентификатор задания, которое нужно получать
    public taskId;

    // Количество ошибок, после которых нужно прекратить опрос на получение задания
    public totalErrors = 10;
    public numberErrors = 0;

    // Интервал получения задания в миллисекундах
    public delay = 3000;

    public constructor(
        private progressService: NgProgress,
        private rest: RestService,
    ) {
    }

    /**
     * Старт опроса
     * @param taskId - идентификатор задания
     * @param interval - интервал, с которым следует пинговать
     */
    public startPingTask(taskId, interval?) {
        if (interval) {
            this.delay = interval;
        }

        this.taskId = taskId;
        this.progressService.start();

        return (new Promise((resolve, reject) => {
            this.getTask(resolve, reject);
        }));
    }

    /**
     * Получение обновленного задания
     * @param resolve
     * @param reject
     */
    public getTask(resolve, reject) {
        this.rest.find('tasks', this.taskId, false)
            .then((task: any) => {
                // Если обработка закончена, то останавливаем опрос и отдаем наверх объект задания
                if (task.state
                    && task.state === 'process') {
                    setTimeout(() => {
                        this.getTask(resolve, reject);
                    }, this.delay);
                } else {
                    this.progressService.done();
                    resolve(task);
                }
            })
            .catch(() => {
                this.numberErrors++;
                // Если количество ошибок превысило допустимое число, прекращаем опрос
                if (this.numberErrors <= this.totalErrors) {
                    setTimeout(() => {
                        this.getTask(resolve, reject);
                    }, this.delay);
                } else {
                    this.progressService.done();
                    reject('Превышено допустимое количество ошибок: ' + this.totalErrors);
                }
            });
    }
}
