import { Injectable } from '@angular/core';
import { FilesService, HttpService, RestService, StorageService } from '@evolenta/core';
import { ByteUtilities, CommonUtilities } from '@evolenta/utilities';
import { Config } from '../../common/services/config';
import * as _ from 'lodash-es';
import { AppealService } from '../knm/appeals/appeal.service';
import { RsoService } from '../../common/services/rso.service';
import { TasksService } from '../tasks/tasks/tasks.service';

@Injectable()
export class ProcessService {
    public constructor(
        private httpService: HttpService,
        private rest: RestService,
        private filesService: FilesService,
        private storage: StorageService,
        private appealService: AppealService,
        private rsoService: RsoService,
        private tasksService: TasksService,
    ) {
    }

    /**
     * КНМ активности текущего процесса
     * @returns {Promise<T>}
     */
    public async checkProcessIsActive(camundaProcessId) {
        return await this.checkCamundaProcessActivity(camundaProcessId);
    }

    /**
     * Проверка активности процесса камунды
     * @param camundaProcessId - ID процесса
     * @returns {Promise<Object>}
     */
    public async checkCamundaProcessActivity(camundaProcessId) {
        const url = Config.server + Config.app + Config.api + 'camunda/process-instance/' + camundaProcessId;

        try {
            const result: any = await this.httpService.get(url);

            return result.status !== 'NOT_FOUND';
        } catch (error) {
            if (error.status === 404 && error.error.status === 'NOT_FOUND') {
                return false;
            } else {
                throw false;
            }
        }
    }

    /**
     * Обновление списка задач текущего процесса.
     * @param mainId ID головной сущности, откуда был запущен процесс камунды.
     * @param guid сущности в которой запущен процесс (актуально для вложенных сущностей).
     * @param camundaBusinessInfoId - идентификатор записи в коллекции camundaBusinessInfo.
     */
    public async updateTasksList(
        mainId: string,
        guid: string,
        camundaBusinessInfoId: string | null,
    ): Promise<{ [key: string]: any }> {
        const result = await this.updateCamundaProcessTasks(mainId, guid, camundaBusinessInfoId);

        const users = await this.tasksService.getUsers();

        return {
            userTasks: this.getTasksList(result.userTasks, users),
            serviceTasks: this.getTasksList(result.serviceTasks, users),
            timers: result.timers,
            eventGateways: result.eventGateways,
            endEvents: result.endEvents,
            entryStatusChangedAt: result.entryStatusChangedAt,
        };
    }

    public async updateCamundaProcessTasks(
        mainId: string,
        guid: string,
        camundaBusinessInfoId: string | null,
    ): Promise<{ [key: string]: any }> {
        if (camundaBusinessInfoId) {
            const camundaBusinessInfoItem = await this.rest.find('camundaBusinessInfo', camundaBusinessInfoId);

            return this._formatCamundaTasks([camundaBusinessInfoItem]);
        } else {
            const params = [{
                andSubConditions: [
                    { field: 'mainId', operator: 'eq', value: mainId },
                    { field: 'guid', operator: 'eq', value: guid },
                ],
            }];

            const camundaBusinessInfoItems = await this.rest.search('camundaBusinessInfo', { search: { search: params }});

            return this._formatCamundaTasks(camundaBusinessInfoItems);
        }
    }

    private async _formatCamundaTasks(
        items: { [key: string]: any }[],
    ): Promise<{ [key: string]: any }> {
        let processUsersTasks = [];
        let processServiceTasks = [];
        let processEventGateways = [];
        let processTimers = [];
        let endEvents = [];
        let entryStatusChangedAt = null;

        if (items.length > 0) {
            const data = items[0];
            processUsersTasks = data.userTasks ? data.userTasks.map(item => ({ ...item, camundaBusinessInfoId: data._id })) : [];
            processServiceTasks = data.serviceTasks ? data.serviceTasks : [];
            processTimers = data.timers ? data.timers : [];
            processEventGateways = data.eventGateways ? data.eventGateways : [];
            endEvents = data.endEvents ? data.endEvents : [];
            entryStatusChangedAt = data.entryStatusChangedAt;
        }

        return {
            userTasks: processUsersTasks,
            serviceTasks: processServiceTasks,
            timers: processTimers,
            eventGateways: processEventGateways,
            endEvents: endEvents,
            entryStatusChangedAt: entryStatusChangedAt,
        };
    }

    public getTasksList(tasks, users?: any[]) {
        let activeTasks = tasks ? tasks.filter(item => item.status === 'ACTIVE') : [];
        let completedTasks = tasks ? tasks.filter(item => item.status !== 'ACTIVE') : [];

        if (this.rsoService.canNotEditAppeal(this.appealService.appeal) && !this.rsoService.isRsoOperatorUser()) {
            if (this.rsoService.isRsoAnySupervisorUser() && users) {
                activeTasks = activeTasks.filter(task => this.rsoService.isAssigneeAnyOfUser(task, users));
                completedTasks = completedTasks.filter(task => this.rsoService.isAssigneeAnyOfUser(task, users));
            } else {
                const login = this.storage.getItem('user').login;
                activeTasks = activeTasks.filter(task => task.assignee === login);
                completedTasks = completedTasks.filter(task => task.assignee === login);
            }
        }

        return {
            activeTasks,
            completedTasks,
        };
    }

    public generateProcessDataToEntity(process) {
        const data = {id: process._id, tasks: []};
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    data.tasks.push({guid: task.guid, id: task.id, type: taskType});
                });
            });
        }

        return data;
    }

    public correctProcessDataInEntity(process, entityProcessData) {
        if (!entityProcessData) {
            entityProcessData = {id: process._id, tasks: []};
        }
        if (!entityProcessData.id) {
            entityProcessData.id = process._id;
        }
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    const find = entityProcessData.tasks.find(item => item.id === task.id);
                    if (!find) {
                        entityProcessData.tasks.push({guid: task.guid, id: task.id, type: taskType, isUsed: true});
                    } else {
                        find.isUsed = true;
                    }
                });
            });
        }
        entityProcessData.tasks = entityProcessData.tasks
            .filter(item => item.isUsed)
            .map(item => ({guid: item.guid, id: item.id, type: item.type}));

        return entityProcessData;
    }

    public async loadProcessData(data) {
        if (data) {
            const processIds = [data.id];
            data.tasks.forEach(task => {
                if (task.subProcess) {
                    processIds.push(task.subProcess.id);
                }
            });

            const processes = await this.rest.search('sperBpmnProcesses', {search: {search: [{field: '_id', operator: 'in', value: processIds}]}});
            const find = processes.find(item => item._id === data.id);
            if (find) {
                const process = _.cloneDeep(find);
                const tasksWithSubProcess = data.tasks.filter(item => item.subProcess);
                if (tasksWithSubProcess.length > 0) {
                    tasksWithSubProcess.forEach(task => {
                        const findProcess = processes.find(item => item._id === task.subProcess.id);
                        if (findProcess) {
                            const subProcessIndex = process.tasks.subProcess.findIndex(item => item.id === task.id);
                            if (subProcessIndex !== -1) {
                                process.tasks.subProcess[subProcessIndex].subProcess = findProcess;
                            }
                        }
                    });
                }

                return process;
            }

            return null;
        }

        return null;
    }

    public findTaskInEntityProcess(taskGuid, entityData) {
        let task = entityData.tasks.find(item => item.guid === taskGuid);
        if (!task) {
            const taskWitSubProcess = entityData.tasks.filter(item => item.subProcess);
            taskWitSubProcess.forEach(parentTask => {
                if (!task) {
                    task = parentTask.subProcess.tasks.find(item => item.guid === taskGuid);
                }
            });
        }

        return task;
    }

    public async processingTasksVariables(process, additionalDataTabs) {
        if (additionalDataTabs && additionalDataTabs.length > 0) {
            const xsdIds = additionalDataTabs.map(item => item.xsd);

            const allXsd = await this.rest.search('xsd', {search: {search: [{field: '_id', operator: 'in', value: xsdIds}]}});
            process.tasks.forEach(task => {
                delete task.variables;
                this.completeTaskVariables(task, additionalDataTabs, allXsd);
                if (task.subProcess && task.subProcess.tasks.length > 0) {
                    task.subProcess.tasks.forEach(subTask => {
                        delete subTask.variables;
                        this.completeTaskVariables(subTask, additionalDataTabs, allXsd);
                    });
                }
            });

            return true;
        }

        return true;
    }

    public completeTaskVariables(task, additionalDataTabs, allXsd) {
        if (task.additionalDataTabs && task.additionalDataTabs.length > 0) {
            task.variables = [];
            task.additionalDataTabs.forEach(taskTab => {
                if (taskTab.isUse || taskTab.isRequired) {
                    const tab = additionalDataTabs.find(item => item.guid === taskTab.guid);
                    const xsdFile = allXsd.find(item => item._id === tab.xsd);
                    if (xsdFile) {
                        const xsdContent = xsdFile.xsdContent;
                        const variablesItems = {};
                        this.getVariablesPath(xsdContent, [], variablesItems);
                        if (Object.keys(variablesItems).length > 0) {
                            Object.keys(variablesItems).forEach(variableCode => {
                                task.variables.push({
                                    code: variableCode,
                                    xpath: tab.code + '.' + variablesItems[variableCode].join('.'),
                                });
                            });
                        }
                    }
                }
            });
        }
    }

    public getVariablesPath(data, paths = [], resultVariables = {}) {
        data.forEach(dataItem => {
            if (dataItem.cols) {
                dataItem.cols.forEach(column => {
                    this.getVariablesPath(column.content, _.cloneDeep(paths), resultVariables);
                });
            } else {
                const currentPaths = _.cloneDeep(paths);
                currentPaths.push(dataItem.options.name);
                if (dataItem.options.processVariable) {
                    // currentPaths.push(dataItem.options.processVariable);
                    resultVariables[dataItem.options.processVariable] = currentPaths;
                } else if (dataItem.content && dataItem.content.length > 0) {
                    this.getVariablesPath(dataItem.content, _.cloneDeep(currentPaths), resultVariables);
                }
            }
        });
    }

    public uploadCamundaProcess(process) {
        // содержимое файлов бизнес процессов
        const files = {};
        const promises = [];
        const processingItems = [];
        let fileId = process.file._id;
        processingItems.push('file' + fileId);
        promises.push(this.filesService.downloadFile(fileId, 'text'));
        if (process.tasks.subProcess && process.tasks.subProcess.length > 0) {
            const tasksWithSubProcess = process.tasks.subProcess.filter(item => item.subProcess);
            if (tasksWithSubProcess.length > 0) {
                tasksWithSubProcess.forEach(subProcess => {
                    fileId = subProcess.subProcess.file._id;
                    processingItems.push('file' + fileId);
                    promises.push(this.filesService.downloadFile(fileId, 'text'));
                });
            }
        }

        return Promise.all(promises).then(result => {
            processingItems.forEach((processingItem, idx) => {
                const idFile = processingItem.replace('file', '');
                files[idFile] = result[idx];
            });

            const bpmn = files[process.file._id];
            const subProcess = {};

            if (process.tasks.subProcess) {
                const tasksWithSubProcess = process.tasks.subProcess.filter(item => item.subProcess);
                if (tasksWithSubProcess.length > 0) {
                    tasksWithSubProcess.forEach(task => {
                        subProcess[task.id] = files[task.subProcess.file._id];
                    });
                }
            }

            const resultBpmn = this.correctBpmn(bpmn, subProcess);
            const xsdData = {
                name: 'bpmnXML',
                contentXsd: resultBpmn,
                parentEntries: 'xsd',
            };

            return this.rest.create('xsd', xsdData).then((xsd: any) => {
                return this.httpService
                    .post(Config.server + Config.api + 'camunda/deployment/create', { bpmn: ByteUtilities.B64Encode(resultBpmn) })
                    .then(
                        camundaResult => ({
                            xsdBpmn: xsd._id,
                            camundaDeploymentInfo: camundaResult,
                        }))
                    .catch(error => error);
            });
        });
    }

    /**
     * Корректировка BPMN (внедрение подпроцессов в структуру основного процесса)
     * @param bpmn
     * @param subProcess
     */
    public correctBpmn(bpmn, subProcess) {
        const mainParser = new DOMParser();
        const nameSpace = 'http://www.omg.org/spec/BPMN/20100524/MODEL';
        const mainDoc = mainParser.parseFromString(bpmn, 'application/xml');
        const mainRoot = mainDoc.documentElement;
        const subProcesses = mainRoot.getElementsByTagNameNS(nameSpace, 'subProcess');
        const nodesForDelete = [];
        for (let i = 0; i <= subProcesses.length - 1; i++) {
            const subProcessNode = subProcesses[i];
            const taskId = subProcessNode.getAttribute('id');
            if (subProcess[taskId]) {
                // Если выбран подпроцесс для subProcess
                const subParser = new DOMParser();
                const subDoc = subParser.parseFromString(subProcess[taskId], 'application/xml');
                const subRoot = subDoc.documentElement;
                const sub = subRoot.getElementsByTagNameNS(nameSpace, 'process')[0];
                const childNodes = sub.childNodes;
                for (let j = 0; j <= childNodes.length - 1; j++) {
                    const childNode = sub.childNodes[j];
                    subProcessNode.appendChild(mainDoc.importNode(childNode, true));
                }
            } else {
                // если подпроцесс не выбран преобразуем subProcess в userTask
                const newNode = mainDoc.createElementNS(nameSpace, 'userTask');
                newNode.setAttribute('id', taskId);
                newNode.setAttribute('name', subProcessNode.getAttribute('name'));
                const defaultAttr = subProcessNode.getAttribute('default');
                if (defaultAttr) {
                    newNode.setAttribute('default', subProcessNode.getAttribute('default'));
                }
                const subProcessNodeChilds = subProcessNode.childNodes;
                for (let k = 0; k <= subProcessNodeChilds.length - 1; k++) {
                    const childNode = subProcessNodeChilds[k];
                    if (childNode.nodeName === 'bpmn:incoming' || childNode.nodeName === 'bpmn:outgoing') {
                        const childNodeElement = mainDoc.createElement(childNode.nodeName);
                        childNodeElement.textContent = childNode.firstChild.nodeValue;
                        newNode.appendChild(childNodeElement);
                    }
                }
                subProcessNode.parentNode.insertBefore(newNode, subProcessNode);
                nodesForDelete.push(subProcessNode);
            }
        }
        // удаляем subProcess, к которым не прикреплены вложенные процессы
        // console.log('nodesFORDelete', nodesForDelete);
        if (nodesForDelete.length > 0) {
            for (let l = 0; l <= nodesForDelete.length - 1; l++) {
                const deletedNode = nodesForDelete[l];
                // console.log('delete node', deletedNode);
                deletedNode.parentNode.removeChild(deletedNode);
            }
        }

        return mainDoc.documentElement.outerHTML;
    }

    public completeCamundaTask(taskId, mainId, guid, camundaBusinessInfoId, collectionName, params) {
        const url = Config.server + Config.app + Config.api;
        const requestUrl = `${url}camunda/task/${taskId}/complete?mainId=${mainId}&guid=${guid}&camundaBusinessInfoId=${camundaBusinessInfoId}&collectionName=${collectionName}`;

        return this.httpService.post(requestUrl, params);
    }

    public async getEventSubscriptions(processInstanceId) {
        const data: any = await this.httpService.get(Config.server + Config.app + Config.api + 'camunda/getEventSubscriptions?processInstanceId=' + processInstanceId);

        return data && data.eventSubscriptions ? data.eventSubscriptions : [];
    }

    public eventSubscriptionTrigger(event) {
        const params = {
            executionId: event.executionId,
            messageName: event.eventName,
        };

        return this.httpService.post(Config.server + Config.app + Config.api + 'camunda/eventSubscriptionTrigger', params);
    }

    public generateProcessDataToStandard(process, standardProcess) {
        let tasks = [];
        if (standardProcess) {
            tasks = standardProcess.tasks;
        }
        const actualTasks = [];
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    const find = tasks.find(item => item.guid === task.guid);
                    if (!find) {
                        tasks.push({guid: task.guid, id: task.id, type: taskType});
                    }
                });
            });
            tasks.forEach(standardTask => {
                let taskIsFind = false;
                Object.keys(process.tasks).forEach(taskType => {
                    const findTask = process.tasks[taskType].find(item => item.guid === standardTask.guid);
                    taskIsFind = findTask ? true : taskIsFind;
                });
                if (taskIsFind) {
                    actualTasks.push(standardTask);
                }
            });
        }

        return {id: process._id, tasks: actualTasks};
    }

    public startCamundaProcess(processId, entityId, entityCollection) {
        // Если в деле есть процессы камунды
        const currentOrganization = this.storage.getItem('currentOrganization');
        const processGuid = CommonUtilities.GenerateGuid();
        const url = Config.server + Config.api + 'camunda/process-definition/' + processId + '/start?mainId=' + entityId + '&guid=' + processGuid + '&collectionName=' + entityCollection + '&unitId=' + currentOrganization._id;

        return this.httpService.post(url, {});
    }

    public getProcessExecutionResult(endEvents) {
        if (endEvents && endEvents.length > 0) {
            return endEvents[endEvents.length - 1].parameters;
        }
    }
}
