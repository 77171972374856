<div class="panel panel-shadow panel-small ml-20 mr-20 mb-20" [ngClass]="{'mt-20': !isFirst || data.events.length === 0}">
    <entity-process-element-header [element]="element"
                                   [allowApply]="allowApply"
                                   [data]="data"
                                   (onComplete)="complete()"
    ></entity-process-element-header>

    <div class="panel-body no-padding" *ngIf="element.type === 'USER_TASK' || element.type === 'EVENT'">
        <entity-process-element-data [element]="element"
                                     [allowEdit]="allowApply"
                                     [data]="data"
                                     #elementData
        ></entity-process-element-data>
    </div>

    <entity-process-element-footer [element]="element"
                                   [allowEdit]="allowApply"
                                   [data]="data"
                                   #elementFooter
    ></entity-process-element-footer>

</div>
