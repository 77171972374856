import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RestService } from '@evolenta/core';
import moment from 'moment';

@Injectable()
export class GlobalCalendarResolve implements Resolve<any> {
    public constructor(private restService: RestService) {}

    public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const data: { calendar?; holidays? } = {};

        // берем последние 5 лет и следующие 10
        const currYear = moment().year();
        const years = new Array(15).fill(null).map((_, idx) => idx + currYear - 5);

        data.calendar = await this.restService.search('unitsProductionCalendars', {
            search: {
                search: [
                    { field: 'year', operator: 'in', value: years },
                    { field: 'isDefault', operator: 'eq', value: true },
                ],
            },
        });

        const holidaysResult = await this.restService.search('calendarHolidays');
        data.holidays = holidaysResult[0] || { holidayList: [] };

        return data;
    }
}
