import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { CheckListTemplatesComponent } from './check-list-templates/check-list-templates.component';
import { CheckListTemplatesSectionComponent } from './check-list-templates/check-list-templates-section.component';
import { CheckListTemplatesResolve } from './check-list-templates/check-list-templates.resolve';
import { CheckListTemplateEditComponent } from './check-list-templates/components/edit/check-list-template-edit.component';
import { CheckListTemplateObjectsComponent } from './check-list-templates/components/objects/check-list-template-objects.component';
import { CheckListTemplateObjectEditComponent } from './check-list-templates/components/objects/edit/check-list-template-object-edit.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    imports: [SharedComponentsModule, InlineSVGModule],
    declarations: [
        CheckListTemplatesComponent,
        CheckListTemplateEditComponent,
        CheckListTemplatesSectionComponent,
        CheckListTemplateObjectsComponent,
        CheckListTemplateObjectEditComponent,
    ],
    providers: [CheckListTemplatesResolve],
    exports: [],
})
export class CheckListTemplatesModule {}
