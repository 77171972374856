<div class="panel panel-shadow panel-small" [ngClass]="{'m-20': !noMargin, 'no-margin': noMargin, 'mb-15': bottomMargin}">
    <div class="panel-heading" [ngClass]="{'pl-20 pr-20': mode == 'view'}">
        <div class="panel-title">

            <!-- Ссылка возврата к списку -->
            <a *ngIf="mode == 'edit'"
               (click)="back()"
               title="Вернуться к списку субъектов дела"
                class="text-size-small text-grey-300 hide">
                <svg class="display-inline-block mr-5" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L3.41421 6L15 6C15.5523 6 16 5.55229 16 5C16 4.44772 15.5523 4 15 4L3.41421 4L5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L0.292893 4.29289C0.0983936 4.48739 0.000764065 4.74208 4.30156e-06 4.997C1.36631e-06 4.998 0 4.999 0 5C0 5.001 1.36947e-06 5.002 4.30262e-06 5.003C0.000399107 5.1375 0.0273461 5.26575 0.0758787 5.38278C0.12357 5.49805 0.193742 5.60621 0.286395 5.70055C0.288737 5.70294 0.291091 5.70531 0.293457 5.70767M4.29289 9.70711L0.293457 5.70767L4.29289 9.70711Z" fill="#A7A7AB"/>
                </svg> Вернуться назад
            </a>
            <!-- Заголовок объекта -->
            <h4 class="no-margin-top mb-5">{{subject.header ? subject.header : 'Новый ' + subjectParams.titles['single']}}</h4>
            <div>
                <!-- Бэдж-ссылка на редактирование вида объекта -->
                <span class="badge badge-info text-uppercase mr-10"
                      [ngClass]="{'cursor-pointer' : canEditKind()}"
                      (click)="editKind()"
                      *ngIf="subject.kind">{{subjectParams.titles['single']}}</span>

                <!-- Бэдж-ссылка на редактирования типа объекта -->
                <span class="badge text-uppercase mr-10"
                      [ngClass]="{'bg-teal-300': subject.specialTypeId == 'ulApplicant',
                                  'bg-warning-300': subject.specialTypeId == 'ipApplicant',
                                  'bg-orange-300': subject.specialTypeId == 'individualApplicant',
                                  'bg-purple-300': subject.specialTypeId == 'foreignUlApplicant'}"
                      (click)="editSubKind()">{{getSubjectTypeName()}}</span>
            </div>
        </div>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default" title="Редактировать"
                    *ngIf="allowEditSubject()"
                    (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>
            <button class="btn btn-default"
                    title="Удалить"
                    (click)="deleteSubject()" *ngIf="mode == 'view' && subjectParams.permissions.remove">
                <b><i inlineSVG="assets/icon-trash.svg"></i></b>
            </button>
            <button class="btn btn-success-overline heading-btn btn-labeled" (click)="apply()"
                    *ngIf="mode == 'edit'">
                Применить
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()" *ngIf="mode == 'edit'">
                Отменить
            </button>
        </div>
    </div>
    <div class="panel-body no-padding no-margin-bottom">

        <!-- Выбор вида объекта -->
        <div class="p-20" *ngIf="isEditObjectKind && mode === 'edit'">
            <div class="text-bold">Выберите вид:</div>
            <div class="radio-block" *ngFor="let kind of availableKinds">
                <input type="radio" class="radio" name="kind" (click)="selectKind(kind)" id="kind{{kind.guid}}">
                <label for="kind{{kind.guid}}">{{kind.name}}</label>
            </div>
        </div>

        <!-- Выбор типа объекта -->
        <div class="p-20" *ngIf="!isEditObjectKind && isEditObjectSubKind && mode === 'edit'">
            <div class="text-bold">Выберите тип:</div>
            <div class="radio-inline" *ngFor="let sub of getSubKinds(subject.kind)">
                <input type="radio" class="radio" name="subkind" [checked]="subject.kind && subject.kind.subKind && subject.kind.subKind.name == sub.name"
                       (click)="changeSubKind(sub)" id="subkind{{sub.specialTypeId}}{{sub.type ? sub.type : ''}}">
                <label for="subkind{{sub.specialTypeId}}{{sub.type ? sub.type : ''}}">{{sub.name}}<span *ngIf="sub.onlyForPrincipal" class="text-size-small text-muted"> (только в качестве представителя)</span></label>
            </div>
        </div>
        <ng-container *ngIf="(subject.kind && (subject.kind.subKinds && subject.kind.subKind || !subject.kind.subKinds) || !subject.kind && mode === 'view') && !isEditObjectKind && !isEditObjectSubKind">

            <!-- ФЛ, ЮЛ, ИП - тип: Участник -->
            <div class="p-20" *ngIf="isShowPreviewCard">
                <subject-preview-card [subject]="subject" [mode]="mode"
                                      [showName]="false"
                                      [isFullCard]="true"
                                      [showHeader]="false"
                                      [noPadding]="true"
                                      [showDocumentData]="true"
                                      (onActivateTab)="activateTab($event)"></subject-preview-card>
            </div>


            <ng-container *ngIf="activeTab === 'common'">

                <div class="ml-20 mr-20 p-20 mt-20 border-top border-right border-bottom border-left border-grey-120"
                     [ngClass]="{'mb-20': !subject.kind.subKind.allowAdd}"
                     *ngIf="isShowSearchBlock">

                    <!-- Строка поиска в реестре -->
                    <div class="form-group no-margin-bottom">
                        <div class="input-group input-group-lg">
                            <input type="text" class="form-control input"
                                   placeholder="Поиск в реестре субъектов..."
                                   (keypress)="keypress($event)"
                                   name="searchText" [(ngModel)]="searchText">
                            <div class="input-group-btn pl-20">
                                <button type="button" class="btn btn-primary-overline" (click)="searchSubject()"
                                        [disabled]="isProcessingSearch || isProcessingSelect">
                                    Найти <i class="icon-spinner2 spinner" *ngIf="isProcessingSearch || isProcessingSelect"></i>
                                </button>
                            </div>
                            <div class="input-group-btn pl-20"
                                 *ngIf="appeal">
                                <button class="btn btn-primary-overline"
                                        type="button"
                                        [disabled]="isProcessingSearch || isProcessingSelect"
                                        (click)="selectFromRegistry()">
                                    Выбрать из реестра
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Форма редактирования данных субъекта -->
                <subject-form [user]="subject"
                              [appealMode]="false"
                              [requirementsRules]="getFieldsRequirementsRules()"
                              [isProcessValidate]="isProcessValidate || validateService.processValidate"
                              *ngIf="isShowEditCommonDataBlock"
                              [showInside]="true" #editSubject></subject-form>


                <!-- Дополнительные данные -->
                <common-appeal-entity-additional-data [entity]="subject"
                                                      type="subject"
                                                      [appeal]="appeal"
                                                      [permissions]="subjectParams.permissions"
                                                      [subservice]="subservice"
                                                      [mode]="mode" #additionalData>
                </common-appeal-entity-additional-data>


                <!-- Документы на субъект -->
                <ng-container *ngIf="subject.specialTypeId">
                    <common-appeal-documents [appeal]="appeal"
                                             [subservice]="subservice"
                                             [byGroupTypes]="true"
                                             [task]="{}"
                                             [showOnInit]="mode === 'edit'"
                                             [subject]="subject">
                    </common-appeal-documents>
                </ng-container>

            </ng-container>

        </ng-container>
    </div>
</div>

<modal-dialog [message]="modalMessage" [title]="modalTitle" (onSelect)="processingModal($event)"></modal-dialog>
<modal-user [subjects]="findedSubjects"
            (onSelect)="selectSubject($event)">
</modal-user>
