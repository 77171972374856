import { Injectable } from '@angular/core';
import { CommonStandardsService } from './common-standards.service';
// @ts-ignore
import { ToasterService, TranslateService } from '@evolenta/core';
import { UtilityService } from '../../common/services/utility.service';
import { ErrorLoggingService } from '../knm/error-logging.service';

interface PeriodsFrontType {
    autoFill: boolean;
    ungroup: Array<{
        taskGuid: string;
        taskId: string;
        period: number;
        taskName: string;
    }>;
    groups: Array<{
        period: number;
        tasks: Array<{
            taskGuid: string;
            taskName: string;
            taskId: string;
        }>;
    }>;
}

interface TasksPeriodDataBaseType {
    autoFill: boolean;
    processes?: ProcessType[];
    variants?: VariantsPeriodType[];
}

interface ProcessType {
    guid: string;
    tasks: TaskType[];
}

interface VariantsPeriodType {
    guid: string;
    processes: ProcessType[];
}

interface TaskType {
    taskGuid: string;
    period: number;
    group: null | number;
    taskName: string;
    taskId: string;
}

@Injectable()
export class CommonStandardsPeriodsService {
    private localizations;

    public constructor(
        private commonStandardsService: CommonStandardsService,
        private toasterService: ToasterService,
        private translate: TranslateService,
        private errorLoggingService: ErrorLoggingService,
        private utility: UtilityService
    ) {
        this._loadTranslations();
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getDefaultPeriodsData(tasks: any): PeriodsFrontType {
        const data = {
            autoFill: false,
            ungroup: tasks.map((task) => ({
                taskName: task.name,
                period: 0,
                taskGuid: task.guid,
                taskId: task.id,
            })),
            groups: [],
        };

        return data;
    }

    private _prepareTasksFromDB(tasks: TaskType[], taskList: any[]): Omit<PeriodsFrontType, 'autoFill'> {
        const getTaskData = (guid: string): { taskName: string; taskId: string | null } => {
            const foundTask = taskList.find((task) => task.guid === guid);

            return foundTask ? { taskName: foundTask.name, taskId: foundTask.id } : { taskName: '', taskId: null };
        };

        const ungroupTasks: PeriodsFrontType['ungroup'] = tasks
            .filter((task) => task.group === null)
            .map((task) => ({
                period: task.period,
                taskGuid: task.taskGuid,
                ...getTaskData(task.taskGuid),
            }));

        // собираем информацию о том, сколько всего групп [0, 1, 2, 3] например
        const groups = tasks
            .filter((task) => task.group !== null)
            .reduce((acc: number[], task) => {
                if (acc.includes(task.group)) {
                    return acc;
                } else {
                    acc.push(task.group);

                    return acc;
                }
            }, []);

        const groupsTasks: PeriodsFrontType['groups'] = [];

        // [0, 1, 2, ... N]
        groups.forEach((el) => {
            const tasksInGroup = tasks.filter((t) => t.group === el);
            // вставляем в массив по индексу
            groupsTasks[tasksInGroup[0].group] = {
                period: tasksInGroup[0].period,
                tasks: tasksInGroup.map((task) => ({
                    taskGuid: task.taskGuid,
                    ...getTaskData(task.taskGuid),
                })),
            };
        });

        const front: Omit<PeriodsFrontType, 'autoFill'> = {
            groups: groupsTasks,
            ungroup: ungroupTasks,
        };

        return front;
    }

    public async saveChanges(
        standardId: string,
        changes: PeriodsFrontType,
        fullPeriodDays: number,
        processGuid: string,
        variantGuid?: string
    ) {
        const ungroupedLeft = changes.ungroup.reduce((prev, curr) => prev + curr.period, 0);
        const groupedLeft = changes.groups.reduce((prev, curr) => prev + curr.period, 0);
        const daysLeft = fullPeriodDays - (ungroupedLeft + groupedLeft);

        if (daysLeft !== 0) {
            const toastText = this.getDaysText(daysLeft);
            this.toasterService.error(toastText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(toastText));
        } else {
            const saveData = this._saveTasksPeriod(changes, processGuid, variantGuid);
            this.commonStandardsService.standard = {
                ...this.commonStandardsService.standard,
                tasksPeriod: saveData,
            };
            await this.commonStandardsService.saveStandard();
        }
    }

    public getPeriodsData(standard: any, processGuid?: string, variant?: { guid: string; period: string }) {
        const dbTasksPeriod = standard.tasksPeriod;
        const currProcess = this.commonStandardsService.bpmnProcess;
        const isExistsSubProcess = currProcess['tasks'].subProcess.length > 0;

        const periodsData: any = {
            processOptions: [],
            periods: [],
            variantGuid: variant ? variant.guid : null,
        };

        let taskList;

        // если есть подпроцессы - ставим опции для селектора и выбираем первый в списке
        if (isExistsSubProcess) {
            periodsData.processOptions = this._getSubProcessOptions();
            periodsData.processGuid = processGuid || periodsData.processOptions[0].id;

            taskList = this.commonStandardsService.bpmnProcess['tasks'].subProcess.find(
                (process) => process.guid === periodsData.processGuid
            ).subProcess.tasks.userTasks;

            // ставим guid самого bpmn процесса
        } else {
            periodsData.processGuid = processGuid || this.commonStandardsService.bpmnProcess['guid'];

            taskList = this.commonStandardsService.bpmnProcess['tasks'].userTasks;
        }

        // если вообще имеется запись в базе tasksPeriod
        if (dbTasksPeriod) {
            let data = dbTasksPeriod;
            let processPeriod = null;

            if (periodsData.variantGuid) {
                data = data.variants ? data.variants.find((v) => v.guid === periodsData.variantGuid) : null;
            }

            if (data && data.processes) {
                processPeriod = data.processes.find((process) => process.guid === periodsData.processGuid);
            }

            // если нужный процесс нашли - формируем данные
            if (processPeriod) {
                periodsData.periods = this._prepareTasksFromDB(processPeriod.tasks, taskList);
                // создаем шаблон по умолчанию
            } else {
                periodsData.periods = this.getDefaultPeriodsData(taskList);
            }
            periodsData.periods.autoFill = dbTasksPeriod.autoFill;
        } else {
            periodsData.periods = this.getDefaultPeriodsData(taskList);
        }

        if (variant) {
            periodsData.fullPeriod = Number(variant.period);
        } else {
            periodsData.fullPeriod = Number(standard.terms.period);
        }

        return periodsData;
    }

    private _getSubProcessOptions() {
        const processOptions = this.commonStandardsService.bpmnProcess['tasks'].subProcess.map((process) => ({
            id: process.guid,
            text: process.name,
        }));

        return processOptions;
    }

    private _saveTasksPeriod(
        front: PeriodsFrontType,
        processGuid: string, // процесс
        variantGuid?: string // вариант
    ): TasksPeriodDataBaseType {
        let result: TasksPeriodDataBaseType;

        // преобразуем данные с фронта для базы данных
        const groupOfTasks = this._createGroupsForDB(front);

        result = this.commonStandardsService.standard.tasksPeriod || {};
        result.autoFill = front.autoFill;

        if (!result.processes) {
            result.processes = [];
        }

        if (!variantGuid) {
            const processIndex = result.processes.findIndex((process) => process.guid === processGuid);
            if (processIndex >= 0) {
                result.processes[processIndex].tasks = groupOfTasks;
            } else {
                result.processes.push({ guid: processGuid, tasks: groupOfTasks });
            }
            // если редактируем периоды для процесса
        } else {
            // если редактируем вариант
            // проверяем есть ли такой вариант, и если нет - создаем
            if (!result.variants) {
                result.variants = [];
            }

            const variantIndex = result.variants.findIndex((variant) => variant.guid === variantGuid);

            if (variantIndex >= 0) {
                if (!result.variants[variantIndex].processes.length) {
                    result.variants[variantIndex].processes = [];
                }
                const processIndex = result.variants[variantIndex].processes.findIndex(
                    (process) => process.guid === processGuid
                );

                if (processIndex >= 0) {
                    result.variants[variantIndex].processes[processIndex].tasks = groupOfTasks;
                } else {
                    result.variants[variantIndex].processes.push({ guid: processGuid, tasks: groupOfTasks });
                }
            } else {
                result.variants.push({
                    guid: variantGuid,
                    processes: [{ guid: processGuid, tasks: groupOfTasks }],
                });
            }
        }

        return result;
    }

    private _createGroupsForDB(periodsData: PeriodsFrontType): TaskType[] {
        const result: TaskType[] = [];

        periodsData.ungroup.forEach((task) => {
            result.push({
                taskGuid: task.taskGuid,
                period: task.period,
                group: null,
                taskId: task.taskId,
                taskName: task.taskName,
            });
        });

        periodsData.groups.forEach((group, idx) => {
            group.tasks.forEach((task) => {
                result.push({
                    taskGuid: task.taskGuid,
                    group: idx,
                    period: group.period,
                    taskName: task.taskName,
                    taskId: task.taskId,
                });
            });
        });

        return result;
    }

    public getDaysText(daysLeft: number) {
        const dayTitle = this.utility.getDayTitle(daysLeft, {
            days_one: this.localizations.common.days_one,
            days_several: this.localizations.common.days_several,
            days_many: this.localizations.common.days_many,
        });

        const mainTitle =
            daysLeft < 0
                ? this.localizations.standards.periods.warn_excess_by.replace('%s', daysLeft * -1)
                : this.localizations.standards.periods.warn_left_days.replace('%s', daysLeft);

        return `${mainTitle} ${dayTitle}`;
    }
}
