<ng-template #selectUserModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.subjects.selection }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body no-padding-top">
        <ng-container *ngFor="let subject of subjects">
            <div class="radio-block">
                <input id="subject{{subject._id}}"
                       class="radio"
                       type="radio"
                       name="selectedSubject"
                       [value]="subject"
                       [(ngModel)]="selectedSubject">
                <label for="subject{{subject._id}}">
                    <ng-container *ngIf="subject.specialTypeId !== 'ulApplicant'">
                        <span class="text-bold">
                            <ng-container *ngIf="subject.data.person.lastName">
                                {{ subject.data.person.lastName }}
                            </ng-container>
                            <ng-container *ngIf="subject.data.person.firstName">
                                {{ subject.data.person.firstName }}
                            </ng-container>
                            <ng-container *ngIf="subject.data.person.middleName">
                                {{ subject.data.person.middleName }}
                            </ng-container>
                        </span>
                        <ng-container *ngIf="subject.specialTypeId == 'individualApplicant'">
                            <span *ngIf="subject.data.person.birthday">,
                                {{subject.data.person.birthday.formatted}} {{ localizations.subjects.year_of_birth }}
                            </span>
                            <div *ngIf="subject.data.person.documentType">
                                {{subject.data.person.documentType[0].text}}
                                <span *ngIf="subject.data.person.documentSeries">
                                    {{ localizations.common.series }}
                                    {{ subject.data.person.documentSeries }}
                                </span>
                                <span *ngIf="subject.data.person.documentNumber">
                                    № {{subject.data.person.documentNumber}}
                                </span>
                                <span *ngIf="subject.data.person.documentIssuedDate">
                                    {{ localizations.subjects.issued }}
                                    {{ subject.data.person.documentIssuedDate.formatted }}
                                    <ng-container *ngIf="subject.data.person.documentIssuer">
                                        {{ subject.data.person.documentIssuer }}
                                    </ng-container>
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="subject.specialTypeId == 'ipApplicant'">
                            <span class="text-muted ml-5">
                                {{ localizations.common.ogrn }}:
                            </span>
                            {{ subject.data.person.ogrn }}
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="subject.specialTypeId == 'ulApplicant'">
                        <span class="text-bold">
                            {{ subject.data.organization.shortName }}
                        </span>
                        <div>
                            <span class="text-muted">
                                {{ localizations.common.ogrn }}:
                            </span>
                            {{ subject.data.organization.ogrn }}
                            <span class="text-muted ml-5">
                                {{ localizations.common.inn }}:
                            </span>
                            {{ subject.data.organization.inn }}
                            <span class="text-muted ml-5">
                                {{ localizations.common.kpp }}:
                            </span>
                            {{ subject.data.organization.kpp }}
                        </div>
                    </ng-container>
                </label>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="cancelModal()">
            {{ localizations.common.cancel }}
        </button>
        <button class="btn btn-primary"
                type="button"
                [disabled]="!selectedSubject"
                (click)="selectSubject()">
            {{ localizations.common.select }}
        </button>
    </div>
</ng-template>
