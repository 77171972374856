import { Component, OnInit } from '@angular/core';
import { CommonStandardsService } from '../common-standards.service';
import { TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-appeal',
    templateUrl: './common-standard-appeal.component.html',
    styles: [':host { width: 100%; }'],
})
export class CommonStandardAppealComponent implements OnInit {
    public standard;
    public metaData;

    public statuses;
    public localizations;

    public constructor(
        public standardsService: CommonStandardsService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.standard = this.standardsService.standard;
        this.metaData = this.standardsService.metaReglament.blocks.find((item) => item.code === 'appealData');
        if (!this.standard.appealData) {
            this.standard.appealData = {};
        }
        this.standard.appealData.statuses = this.initStatuses();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals']).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public initStatuses() {
        const statuses = cloneDeep(this.metaData.appealStatuses);
        return statuses.map((item) => {
            const savedStatus = this.standard.appealData.statuses
                ? this.standard.appealData.statuses.find((elm) => elm.code)
                : null;

            if (item.actions) {
                item.actions = item.actions.map((elm) => ({ code: elm.code, name: elm.name }));
            }
            if (item.actions && savedStatus && savedStatus.actions) {
                item.actions.forEach((action) => {
                    const find = savedStatus.actions.find((elm) => elm.code === action.code);
                    if (find && find.handler) {
                        action.handler = find.handler;
                    }
                });
            }

            delete item.guid;

            return item;
        });
    }
}
