<div class="panel panel-shadow"
     *ngIf="user">
    <div class="panel-heading">
        <h4 class="panel-title">
            {{ user.name }}
            <label class="badge text-uppercase bg-primary no-margin-bottom ml-10"
                   *ngIf="user.position">
                {{ user.position }}
            </label>
            <div class="text-size-small mt-10"
                 *ngIf="user.mobile || user.email">
                <ng-container *ngIf="user.mobile">
                    <span class="text-muted mr-5">
                        {{ localizations.common.phone }}:
                    </span>
                    <span class="text-slate">
                        {{ user.mobile }}
                    </span>
                </ng-container>
                <ng-container *ngIf="user.email">
                    <span class="text-muted mr-5"
                          [ngClass]="{'ml-10': user.mobile}">
                        {{ localizations.common.email }}:
                    </span>
                    <span class="text-slate"
                          *ngIf="user.email">
                        {{ user.email }}
                    </span>
                </ng-container>
            </div>
        </h4>
    </div>
    <div class="panel-body no-padding">

        <!--  Задачи  -->
        <ng-container>
            <ul class="info-row mt-20">
                <li [ngClass]="{'active': tab.code === activeTasksTab}"
                    *ngFor="let tab of tasksTabs"
                    (click)="activeTasksTab = tab.code">
                    {{ tab.name }}
                    <span class="badge ml-10"
                          [ngClass]="{
                                'badge-info': tasks[tab.code].length > 0,
                                'bg-grey': tasks[tab.code].length == 0
                            }">
                        {{ tasks[tab.code].length }}
                    </span>
                </li>
            </ul>
            <div class="ml-20 mr-20">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-bold text-size-small no-border-top">
                            {{ localizations.common.name }}
                        </th>
                        <th class="text-bold text-size-small no-border-top">
                            {{ localizations.common.creation_date }}
                        </th>
                        <th class="text-bold text-size-small no-border-top">
                            {{ localizations.common.completion_date }}
                        </th>
                        <th class="text-bold text-size-small no-border-top"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="tasks[activeTasksTab].length == 0">
                            <td class="bg-primary-50 no-border-top text-primary"
                                colspan="4">
                                {{ localizations.tasks.missing }}
                            </td>
                        </tr>
                        <tr *ngFor="let task of tasks[activeTasksTab]; let fst=first">
                            <td>
                                {{ task.name }}
                            </td>
                            <td>
                                {{ task.created | date: 'medium' }}
                            </td>
                            <td>
                                <ng-container *ngIf="task.due">
                                    {{ task.due | date: 'medium' }}
                                </ng-container>
                            </td>
                            <td class="text-right">
                                <span class="anchor text-info text-size-small"
                                      (click)="goToTask(task)">
                                    {{ localizations.common.proceed_small }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <hr>


        <!--  КНМ  -->
        <div class="mt-20 mb-20">
            <ul class="info-row mt-30">
                <li [ngClass]="{'active': tab.code === activeAppealsTab}"
                    *ngFor="let tab of appealsTabs"
                    (click)="activeAppealsTab = tab.code">
                    {{ tab.name }}
                    <span class="badge ml-10"
                          [ngClass]="{
                                'badge-info': appeals[tab.code].length > 0,
                                'bg-grey': appeals[tab.code].length == 0
                            }">
                        {{ appeals[tab.code].length }}
                    </span>
                </li>
            </ul>
            <div class="ml-20 mr-20">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-bold text-size-small no-border-top">
                            {{ localizations.users.knm.new }}
                        </th>
                        <th class="text-bold text-size-small no-border-top">
                            {{ localizations.users.knm.name }}
                        </th>
                        <th class="text-bold text-size-small no-border-top">
                            {{ localizations.users['subject-objects'] }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="appeals[activeAppealsTab].length == 0">
                        <td class="bg-primary-50 no-border-top text-primary"
                            colspan="3">
                            {{ localizations.users.knm.missing }}
                        </td>
                    </tr>
                    <tr *ngFor="let appeal of appeals[activeAppealsTab]; let fst=first">
                        <td>
                            <a class="anchor text-info"
                               (click)="goToAppeal(appeal)">
                                {{ appeal.shortNumber ? appeal.shortNumber : localizations.common.missing }}
                            </a>
                        </td>
                        <td>
                            {{ appeal.subservice.shortTitle }}
                        </td>
                        <td>
                            <ng-container *ngIf="appeal.objects && appeal.objects.length > 0">
                                {{ appeal.objects[0].shortHeader }}
                                <ng-container *ngIf="appeal.objects[0].objects && appeal.objects[0].objects.length > 0">
                                    <br>
                                    {{ appeal.objects[0].objects[0].name }}
                                </ng-container>
                            </ng-container>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr>

        <!--  Субъекты/Объекты  -->
        <div class="mt-20 mb-20">
            <ul class="info-row mt-30">
                <li [ngClass]="{'active': tab.code === activeEntitiesTab}"
                    *ngFor="let tab of entitiesTabs"
                    (click)="activeEntitiesTab = tab.code">
                    {{ tab.name }}
                    <span class="badge ml-10"
                          [ngClass]="{
                                'badge-info': entities[tab.code].length > 0,
                                'bg-grey-300': entities[tab.code].length == 0
                            }">
                        {{ entities[tab.code].length }}
                    </span>
                </li>
            </ul>
            <div class="ml-20 mr-20">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-bold text-size-small no-border-top">
                                {{ localizations.common.name }}
                            </th>
                            <th class="text-bold text-size-small no-border-top"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="entities[activeEntitiesTab].length == 0">
                            <td class="bg-primary-50 no-border-top text-primary"
                                colspan="2">
                                {{ getControlledInstancesMessage() }}
                            </td>
                        </tr>
                        <tr *ngFor="let entity of entities[activeEntitiesTab]; let fst=first">
                            <td>
                                <ng-container *ngIf="activeEntitiesTab === 'subjects'">
                                    <ng-container *ngIf="entity.specialTypeId === 'ulApplicant'">
                                        {{ entity.data.organization.shortName }},
                                        {{ localizations.common.ogrn }}:
                                        {{ entity.data.organization.ogrn }}
                                    </ng-container>
                                    <ng-container *ngIf="entity.specialTypeId === 'ipApplicant'">
                                        {{ entity.data.person.lastName }}
                                        {{ entity.data.person.firstName }}
                                        {{ entity.data.person.middleName }}
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="activeEntitiesTab === 'objects'">
                                    {{ entity.name }}
                                    <div class="text-size-small"
                                         *ngIf="entity.baseAddress">
                                        <span class="text-muted mr-5">
                                            {{ localizations.common.address }}:
                                        </span>
                                        {{ getAddress(entity.baseAddress) }}
                                    </div>
                                </ng-container>
                            </td>
                            <td class="text-right">
                                <span class="anchor text-info text-size-small"
                                      (click)="goToEntity(entity, activeEntitiesTab)">
                                    {{ localizations.common.proceed_small }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>
