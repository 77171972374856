<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>Настройка {{metaData.groupsAndCategoriesData.groupData.name ? metaData.groupsAndCategoriesData.groupData.name : 'группы'}}</span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled" (click)="save()" *ngIf="allowEdit">Применить</button>
            <button class="btn btn-danger-overline heading-btn btn-labeled" (click)="cancel()">{{allowEdit ? 'Отменить' : 'Закрыть'}}</button>
        </div>
    </div>

    <div class="panel-body">

        <!-- Основные данные -->
        <dynamic-form
            #mainData
            *ngIf="metaData.groupsAndCategoriesData.groupData.xsd"
            [xsd]="metaData.groupsAndCategoriesData.groupData.xsd"
            [model]="group"
            [saveInMainObject]="true"
            [disabled]="!allowEdit">
        </dynamic-form>

        <!-- Настройка дополнительных данных -->
        <p *ngIf="metaData.groupsAndCategoriesData.groupData.allowCreateXsd">
            <span class="text-bold">Дополнительные поля {{!group.xsd ? 'не настроены' : 'настроены'}}</span>
            <span class="text-size-small anchor text-info ml-10" (click)="editXsd()" *ngIf="allowEdit || group.xsd">{{!group.xsd ? 'настроить' : allowEdit ? 'изменить' : 'просмотреть'}}</span>
            <span class="text-size-small anchor text-slate ml-10" (click)="group.xsd = null" *ngIf="group.xsd && allowEdit">удалить</span>
        </p>

        <!-- Настройка вариантов -->
        <fieldset class="mb-20" *ngIf="metaData.groupsAndCategoriesData.groupData.isVariantsSetting && commonStandardsService.standard.variants && commonStandardsService.standard.variants.length > 0">
            <legend class="text-bold">Варианты</legend>
            <common-standard-entity-variants [entity]="group"></common-standard-entity-variants>
        </fieldset>
    </div>
</div>
