<ng-container *ngIf="entity[property]">
    <div class="row" >
        <div class="col-md-12">
            <label>{{title}}
                <span class="anchor text-info text-size-small ml-5" (click)="addItem()" *ngIf="!disabled">добавить</span>
            </label>
            <div class="form-group input-group" *ngFor="let item of entity[property]; index as idx; last as lst;" [ngClass]="{'input-group-lg': large, 'input-group-xs': small, 'mb-5': lst}">
                <catalogue
                    [(ngModel)]="item.value"
                    [priority]="true"
                    [large]="large"
                    [small]="small"
                    [disabled]="disabled"
                    projection="dictionaryCodeName"
                    [placeholder]="placeholder"
                    [catalogName]="catalogName">
                </catalogue>
                <div class="input-group-btn">
                    <button class="btn btn-default" type="button" (click)="addItem()" *ngIf="!idx" [disabled]="disabled"><i class="icon-plus-circle2"></i></button>
                    <button class="btn btn-default" type="button" (click)="deleteItem(item)" *ngIf="idx" [disabled]="disabled"><i class="icon-trash"></i></button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
