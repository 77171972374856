import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessService, SelectService, StorageService } from '@evolenta/core';
import { CommonUtilities, PrintUtilities } from '@evolenta/utilities';
import { AppealEntityActKnm } from './models/appeal-entity-act';
import { AppealEntitiesService } from '../../appeal-entities.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-act',
    templateUrl: 'appeal-entity-act.component.html',
    styleUrls: [
        'appeal-entity-act.component.css',
    ],
    styles: [
        '.tree-select .ui-select-search.form-control { display: inline-block; width: 100%; }',
        '::ng-deep .form-group .ui-select-toggle > .caret{ top: 10px!important; }',
    ],
})
export class AppealEntityActComponent implements OnInit {
    @Input() public entity: AppealEntityActKnm; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public subservice; // описани услуги на основе которой формируется дело
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public editTabs = [];
    public isProcAgreement = false;
    public auditLogsList = [];
    public auditLogsForSelect = [];
    public checkLists = [];
    public objectList = [];
    public subjectList = [];

    /** Единица продолжительности */
    public unitDuration = [
        {id: '01', text: 'Часы'},
        {id: '02', text: 'Раб. дни'},
    ];

    public isCheckPlan = false;
    public isCheckUnplan = false;

    public objects = [];
    public object = [];

    public isSelectInspectors = false;
    public isSelectExperts = false;

    public constructor(
        public accessService: AccessService,
        public appealEntitiesService: AppealEntitiesService,
        private selectService: SelectService,
        private storage: StorageService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);
        // Если проверка внеплановая и выездная
        if (this.appeal.subservice && this.appeal.subservice.kndInfo) {
            if (this.appeal.subservice.kndInfo.kndType.code === '0' && this.appeal.subservice.kndInfo.kndForm.code === '1') {
                this.isProcAgreement = true;
            }
        }

        // Режим редактирования
        if (this.mode === 'edit') {
            // Субъекты и объекты проверки
            // this.getAppealSubjects();
            this.getAppealObjects();

            // Сведения о журнале учета проверок
            this.appealEntitiesService.getAuditLog().then(data => {
                this.auditLogsList = data;
                if (this.auditLogsList) {
                    this.auditLogsForSelect = this.selectService.transformForSelect('code', 'name', data);
                }

                const isDemoStend = this.storage.getItem('demoStend').isDemo;
                if (isDemoStend) {
                    this.initDemo();
                } else {
                    this.init();
                }
            });

            this.initEditTabs();

            if (this.appeal.objects && this.appeal.objects.length > 0) {
                this.objects = this.selectService.transformForSelect('guid', 'name', this.appeal.objects);
            }
        }
    }

    public initDemo() {
        if (!this.entity.number) {
            this.entity.number = this.appeal.number;
        }
        if (!this.entity.createDate) {
            this.entity.createDate = this.appeal.dateRegister;
        }
        if (!this.entity.createPlace) {
            this.entity.createPlace = this.appealEntitiesService.findObjectAddress(this.appeal);
        }
        if (!_.get(this.entity, 'places.0.place', null)) {
            this.entity.places = [{
                guid: CommonUtilities.GenerateGuid(),
                place: this.appealEntitiesService.findObjectAddress(this.appeal),
            }];
        }
        if (!this.entity.periodTotal) {
            this.entity.periodTotal = 20;
        }
        if (!this.entity.periodType) {
            this.entity.periodType = [this.unitDuration.find(elm => elm.id === '02')];
        }
        const subjectPerson = this.appealEntitiesService.findPerson(this.appeal);
        // if (!this.entity.subjectName && subjectPerson && subjectPerson.name) {
        //     this.entity.subjectName = subjectPerson.name;
        // }
        if (!this.entity.subjectPosition && subjectPerson && subjectPerson.position) {
            this.entity.subjectPosition = subjectPerson.position;
        }
        if (!this.entity.informationAuditLog) {
            const findIndex = this.auditLogsForSelect.findIndex(elm => elm.id === '01');
            if (findIndex !== -1) {
                this.entity.informationAuditLog = [this.auditLogsForSelect[findIndex]];
            }
        }
        if (!this.entity.informationFamiliarization) {
            this.entity.informationFamiliarization = true;
        }
        // if (!this.entity.results) {
        //     this.entity.results = 'isMandatoryReq';
        // }
        this.getViolations();
    }

    public init() {
        if (!this.entity.createDate) {
            this.entity.createDate = '';
        }
        if (!this.entity.places) {
            this.entity.places = [];
        }
        if (!this.entity.informationAuditLog) {
            this.entity.informationAuditLog = [];
        }
        if (!this.entity.results) {
            this.entity.results = [];
        }
        if (!this.entity.violations) {
            this.checkLists = this.appealEntitiesService.findCheckListFailure(this.appeal);
            if (this.checkLists) {
                this.entity.violations = this.checkLists.map(checkList => {
                    return {
                        subject: this.getSubject(checkList.subjectGuid),
                        object: this.getObject(checkList.objectGuid),
                        questions: checkList.questions.map(question => {
                            return {
                                nPA: question.nPA,
                                mandatoryReqs: question.mandatoryReqs,
                                hide: true,
                            };
                        }),
                    };
                });
            }
        }
    }

    /**
     * Установка активной вкладки
     * @param tab
     */
    public activateTab(tab) {
        this.activeTab = tab;
    }

    /**
     * Инициализация массива вкладок
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'entity',
                name: 'Общие данные',
                active: true,
                visible: true,
            },
            {
                code: 'violations',
                name: 'Нарушения',
                active: false,
                visible: true,
            },
        ];
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    /**
     * Возврат адреса в виде строки
     * @param objectGuid
     * @returns {string}
     */
    public getAddress(objectGuid) {
        const object = this.getObject(objectGuid);
        if (object.address) {
            return PrintUtilities.PrintAddressAsLine(object.address, true);
        }

        return '';
    }
    /**
     * Возврат данных субъекта
     * @param subjectGuid
     */
    public getSubject(subjectGuid) {
        const findIndex = this.subjectList.findIndex(item => item.guid === subjectGuid);
        if (findIndex !== -1) {
            return this.subjectList[findIndex];
        }
    }

    /**
     * Возврат данных объекта
     * @param objectGuid
     */
    public getObject(objectGuid) {
        const findIndex = this.objectList.findIndex(item => item.guid === objectGuid);
        if (findIndex !== -1) {
            return this.objectList[findIndex];
        }
    }

    /**
     * Получение списка субъектов рассматриваемой проверки
     */
    public getAppealSubjects() {
        this.subjectList = this.appeal.subjects.map(item => {
            return {
                guid: CommonUtilities.GenerateGuid(),
                header: item.header,
                shortHeader: item.shortHeader,
                specialTypeId: item.specialTypeId,
            };
        });
    }

    /**
     * Получение списка объектов рассматриваемой проверки
     */
    public getAppealObjects() {
        this.appeal.objects.forEach(subject => {
            if (subject.objects && subject.objects.length) {
                subject.objects.forEach(object => {
                    this.objectList.push(object);
                });
            }
        });
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public cancel() {
        this.onCancel.emit(true);
    }

    public select(data, name) {
        this.entity[name] = data.id;
        this.entity.violations = null;
        this.getViolations();
    }

    public getSelected(items, name) {
        return _.filter(items, item => this.entity[name] === item.id );
    }

    public getViolations() {
        if (!this.entity.violations && this.entity.object) {
            this.checkLists = this.appealEntitiesService.findCheckListFailure(this.appeal, this.entity.object);
            if (this.checkLists) {
                this.entity.violations = this.checkLists.map(checkList => {
                    return checkList.questions.map(question => {
                        return {
                            guid: CommonUtilities.GenerateGuid(),
                            npa: question.nPA,
                            mandatoryReqs: question.mandatoryReqs,
                            hide: true,
                        };
                    });
                });
                if (this.entity.violations) {
                    this.entity.violations = this.entity.violations[0];
                }
            }
        }
    }

    public checkUsedInspector(inspector) {
        return !!(this.entity.inspectors && this.entity.inspectors.length > 0 && this.entity.inspectors.find(item => item.login === inspector.login));
    }

    public selectInspector(inspector) {
        if (!this.entity.inspectors) {
            this.entity.inspectors = [];
        }
        const findIndex = this.entity.inspectors.findIndex(item => item.login === inspector.login);
        if (findIndex === -1) {
            this.entity.inspectors.push(inspector);
        } else {
            this.entity.inspectors.splice(findIndex);
        }
    }

    public checkUsedExpert(expert) {
        return !!(this.entity.experts && this.entity.experts.length > 0 && this.entity.experts.find(item => item.guid === expert.guid));
    }

    public selectExpert(expert) {
        if (!this.entity.experts) {
            this.entity.experts = [];
        }
        const findIndex = this.entity.experts.findIndex(item => item.guid === expert.guid);
        if (findIndex === -1) {
            this.entity.experts.push(expert);
        } else {
            this.entity.experts.splice(findIndex);
        }
    }

    public addResult() {
        if (!this.entity.results) {
            this.entity.results = [];
        }
        console.log('entity results', this.entity.results);
        this.entity.results.push({guid: CommonUtilities.GenerateGuid(), content: '', type: null});
    }

    public deleteResult(result) {
        const findIndex = this.entity.results.findIndex(item => item.guid === result.guid);
        this.entity.results.splice(findIndex, 1);
    }

    public addViolation() {
        if (!this.entity.violations) {
            this.entity.violations = [];
        }
        this.entity.violations.push({guid: CommonUtilities.GenerateGuid(), content: '', mandatoryReq: null, type: null});
    }

    public deleteViolation(violation) {
        const findIndex = this.entity.violations.findIndex(item => item.guid === violation.guid);
        this.entity.violations.splice(findIndex, 1);
    }

    public calculateWorkTime(interval = null) {
        let count = 0;
        if (this.entity.workIntervals) {
            this.entity.workIntervals.forEach(time => {
                if (!interval || interval && interval.guid === time.guid) {
                    count += (parseInt(time.hours, 10) * 60);
                    count += parseInt(time.minutes, 10);
                }
            });
            const minutes = count % 60;
            const hours = (count - count % 60) / 60;
            if (!interval) {
                this.entity.periodTotal = hours; // {id: '01', text: 'Часы'},
                this.entity.periodType = {id: '01', text: 'Часы'};
            }

            return hours + ' ч. ' + minutes + ' мин.';
        }

        return '0 ч. 0 мин.';
    }

    public addWorkInterval() {
        if (!this.entity.workIntervals) {
            this.entity.workIntervals = [];
        }
        this.entity.workIntervals.push({guid: CommonUtilities.GenerateGuid(), hours: 0, minutes: 0});
    }

    public deleteWorkInterval(interval) {
        const findIndex = this.entity.workIntervals.findIndex(item => item.guid === interval.guid);
        this.entity.workIntervals.splice(findIndex, 1);
    }

    public addSubjectPerson() {
        if (!this.entity.subjectPersons) {
            this.entity.subjectPersons = [];
        }
        this.entity.subjectPersons.push({guid: CommonUtilities.GenerateGuid(), name: '', position: ''});
    }

    public deleteSubjectPerson(person) {
        const findIndex = this.entity.subjectPersons.findIndex(item => item.guid === person.guid);
        this.entity.subjectPersons.splice(findIndex, 1);
    }
}
