import { Injectable } from '@angular/core';
import { HttpService } from '@evolenta/core';
import { Config } from '../../../common/services/config';
import { IResponse } from '../interfaces/dashboardDS.interface';

@Injectable()
export class PerformanceDisciplineDataSource {
    public constructor(private http: HttpService) {}

    public getStats(login?: string): Promise<IResponse> {
        return this.http
            .post(Config.server + Config.app + Config.api + 'business/getStats', login ? { login } : {})
            .then(
                (response: IResponse) => {
                    return Promise.resolve(response);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
    }
}
