import { UniversalCollectionPageOptionsInterface } from '@evolenta/core';
import { DatePipe } from '@angular/common';
import get from 'lodash-es/get';
import { ApplicationPreviewComponent } from '../../../modules/applications/application-preview/application-preview.component';

export const ApplicationsListConfig: UniversalCollectionPageOptionsInterface = {
    elementsName: 'Список приложений',
    isSelectingAllowed: false,
    isExternalSearchAllowed: false,
    isMarkingAllowed: false,
    isUseItemButtonAllowed: false,
    isCreateButtonAllowed: true,
    createElementText: 'Добавить приложение',
    getCanCreateElementFunc: () => true,
    getCreateElementRouteFunc: (moduleUrl) => `/${moduleUrl}/applications/create`,
    getEditElementRouteFunc: (moduleUrl, sectionUrl, element) => `/${moduleUrl}/applications/edit/${element._id}`,
    getCollectionNameFunc: () => 'applications',
    defaultSort: 'dateLastModification,DESC',
    getFilterNameFunc: () => 'applicationsFilter',
    getFilterItemsFunc: () => [],
    getBaseSearchFunc: () => [],
    getPreviewComponentFactoryFunc: (resolver) => resolver.resolveComponentFactory(ApplicationPreviewComponent),
    listItemOptions: {
        status: {
            isShownPrimary: false,
            getPrimaryPropertyFunc: (element, property) =>
                property === 'background'
                    ? `bg-${get(element, 'status.theme', '')}-300`
                    : get(element, 'status.name', ''),
            isShownSecondary: false,
        },
        title: {
            isShownId: false,
            isShownTitle: true,
            getTitleFunc: (element) => element.name,
        },
        details: [
            { title: 'Код', getValueFunc: (element) => element.code },
            {
                title: 'Дата создания',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateCreation, 'shortDate'),
            },
            {
                title: 'Дата утверждения',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateApproved, 'shortDate'),
            },
        ],
    },
};
