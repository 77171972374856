import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StorageService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'generate-password',
    templateUrl: 'generate-password.component.html',
})
export class GeneratePasswordComponent implements OnInit {
    public password; // пароль
    public passwordLength = 10; // длина пароля
    public useNumbers = true; // включать цифры
    public useUppercase = true; // включать прописные буквы
    public useSymbols = false; // включать символы

    // Настройки
    public lowercase = 'abcdefghijklmnopqrstuvwxyz';
    public uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    public numbers = '0123456789';
    public symbols = '!@#$%^&*()+_-=}{[]|:;"/?.><,`~';
    public localizations;

    @Output() public afterGeneratePassword = new EventEmitter<any>();

    public constructor(
        private storage: StorageService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        const passwordSettings = this.storage.getItem('passwordSettings');
        if (passwordSettings) {
            this.useNumbers = passwordSettings.useNumbers;
            this.useUppercase = passwordSettings.useUppercase;
            this.useSymbols = passwordSettings.useSymbols;
            this.passwordLength = passwordSettings.length;
        }
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'generate-password',
                'common',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public generate() {
        this.password = '';
        // Generate character pool
        let pool = this.lowercase;

        // uppercase
        if (this.useUppercase) {
            pool += this.uppercase;
        }
        // numbers
        if (this.useNumbers) {
            pool += this.numbers;
        }
        // symbols
        if (this.useSymbols) {
            pool += this.symbols;
        }
        for (let i = 0; i < this.passwordLength - 1; i++) {
            const position = Math.floor(Math.random() * pool.length);
            this.password += pool[position];
        }
        this.afterGeneratePassword.emit(this.password);
    }
}
