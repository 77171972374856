import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccessService, RestService, StorageService } from '@evolenta/core';

@Injectable()
export class UserOrganizationsResolve implements Resolve<any> {
    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private access: AccessService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const currentUser = this.storage.getItem('user');
        let usedOrganizations = [];
        let isSuperAdmin = false;

        const search = [
            {
                field: 'isAuthorized',
                operator: 'eq',
                value: true,
            },
        ];

        if (!this.access.existPermission('allSystem')) {
            search.push({ field: 'parentId', operator: 'eq', value: currentOrganization._id });
        } else {
            search.push({ field: '_id', operator: 'neq', value: currentOrganization._id });
            isSuperAdmin = true;
        }

        const promises = [];

        promises.push(this.rest.find('users', currentUser._id));
        promises.push(this.rest.search('organizations', { search: { search: search }, size: 1000 }));

        return Promise.all(promises).then((result) => {
            const user = result[0];
            const childrenOrganizations = result[1];
            if (childrenOrganizations.length > 0) {
                if (!isSuperAdmin) {
                    const userOrganizations = [];
                    user.linkRoles.forEach((role) => {
                        userOrganizations.push(role.sprOrganization.id);
                    });

                    childrenOrganizations.forEach((unit) => {
                        if (userOrganizations.indexOf(unit.id) !== -1) {
                            usedOrganizations.push(unit);
                        }
                    });
                } else {
                    usedOrganizations = childrenOrganizations;
                }
            }
            return Promise.resolve(usedOrganizations);
        });
    }
}
