import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilesService, HttpService, ToasterService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { Config } from '../../../../../common/services/config';
import * as _ from 'lodash-es';

@Component({
    selector: 'task-check-list',
    templateUrl: 'task-check-list.component.html',
})
export class TaskCheckListComponent implements OnInit {
    @Input() public questions;
    @Input() public task;
    @Input() public camundaTask;

    public editedQuestion;
    public processingQuestions;
    public localizations;

    @Output() public onSelectQuestions = new EventEmitter<any>();

    public constructor(
        private filesService: FilesService,
        private httpService: HttpService,
        private toaster: ToasterService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        if (!this.questions) {
            this.questions = [];
        }
        if (this.task.isApplyCheckList) {
            this.processingQuestions = _.cloneDeep(this.questions);
        }
    }

    private _loadTranslations() {
        this.translate
            .get(['tasks', 'common', 'appeals', 'subjects', 'check-list-templates', 'appeals.requirements'])
            .subscribe((res: any) => {
                this.localizations = res;
            });
    }

    public addQuestion() {
        this.editedQuestion = {
            guid: CommonUtilities.GenerateGuid(),
            text: '',
            isCustom: true,
            answerYes: false,
            answerNo: false,
            answerNotConcidered: false,
            mandatoryReqs: {},
            nPA: {},
        };
    }

    public editQuestion(question) {
        this.editedQuestion = _.cloneDeep(question);
    }

    public applyEditQuestion() {
        const findIndex = this.questions.findIndex((item) => item.guid === this.editedQuestion.guid);
        if (findIndex === -1) {
            this.questions.push(_.cloneDeep(this.editedQuestion));
        } else {
            this.questions[findIndex] = _.cloneDeep(this.editedQuestion);
        }
        this.editedQuestion = null;
    }

    public cancelEditQuestion() {
        this.editedQuestion = null;
    }

    public deleteQuestion(question) {
        const findIndex = this.questions.findIndex((item) => item.guid === question.guid);
        this.questions.splice(findIndex, 1);
    }

    public selectQuestions() {
        this.onSelectQuestions.emit(true);
    }

    public applyCheckList() {
        this.task.isApplyCheckList = true;
    }

    public async fileChange(event, question) {
        const filesList = event.target.files;

        if (filesList.length > 0) {
            const file = filesList[0];
            const uploadedFile = await this.filesService.uploadFile(
                'camundaBusinessInfo',
                this.camundaTask._id,
                file,
                file.name
            );

            if (!question.result.files) {
                question.result.files = [];
            }

            question.result.files.push({
                _id: uploadedFile._id,
                originalName: uploadedFile.originalName,
            });

            this.saveDataInQuestion(question);
        }
    }

    public selectAction(question, actionType) {
        if (!question.result) {
            question.result = {};
        }
        if (question.result.answer && question.result.answer === actionType) {
            question.result.answer = null;
        } else {
            question.result.answer = actionType;
        }
        this.saveDataInQuestion(question);
    }

    public downloadFile(id, originalName) {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    public saveDataInQuestion(processingQuestion) {
        const question = this.questions.find((item) => item.auid === processingQuestion.auid);
        if (!question.result) {
            question.result = {};
        }
        question.result.answer = processingQuestion.result.answer;
        if (processingQuestion.result.files) {
            question.result.files = _.cloneDeep(processingQuestion.result.files);
        }
    }

    public async sendToMobile() {
        try {
            await this.httpService.get(
                Config.server +
                    Config.api +
                    'mp/generatePersonalTaskForMp?mainId=' +
                    this.camundaTask._id +
                    '&tenantId=10'
            );
            this.toaster.success(this.localizations.tasks.notifications.sent_to_mobile);
        } catch (error) {
            const errorText = error.error && error.error.description ? error.error.description : error;
            this.toaster.error(errorText);
        }
    }

    public getTimeFromSeconds(secs) {
        let minutes = Math.floor(secs / 60);
        secs = secs % 60;
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;

        return (
            hours.toString().padStart(2, '0') +
            this.localizations.common.hour_dot +
            minutes.toString().padStart(2, '0') +
            this.localizations.minute_dot +
            secs.toString().padStart(2, '0') +
            this.localizations.common.seconds
        );
    }
}
