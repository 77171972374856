import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs/util/noop';

@Component({
    selector: 'touch-spin',
    templateUrl: './touch-spin.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TouchSpinComponent),
            multi: true,
        },
    ],
})
export class TouchSpinComponent implements OnInit, ControlValueAccessor {
    @Input() public step = 1;
    @Input() public prefix: string;
    @Input() public postfix: string;
    @Input() public min = 0;
    @Input() public max: number;
    @Input() public value = 0;
    @Input() public mode = 'big';
    @Input() public enabled = true;

    @Output() public ngModelChange = new EventEmitter();

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => {};

    public ngOnInit() {}

    // From ControlValueAccessor interface
    public writeValue(value: any) {
        if (value !== this.value) {
            this.value = value;
        }
    }

    // From ControlValueAccessor interface
    public registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    public registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    public upClick() {
        this.value += this.step;
        this.onChangeCallback(this.value);
    }

    public downClick() {
        this.value -= this.step;
        this.onChangeCallback(this.value);
    }

    public change() {
        this.onChangeCallback(this.value);
    }
}
