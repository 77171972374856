import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TranslateService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-standard-objects-common',
    templateUrl: './common-standard-objects-common.component.html',
})
export class CommonStandardObjectsCommonComponent implements OnInit {
    @Input() public data;
    @Input() public metaData;

    @Output() public onEditXsd = new EventEmitter<string>();

    public titles;

    public permissionParams;

    public objectTypes = [
        {
            code: 'obj',
            name: '������',
            shortCode: 'obj',
        },
    ];
    @ViewChild('fieldsRequirementsSettingsModal', { static: false })
    public fieldsRequirementsSettingsModal: TemplateRef<any>;
    public modalRef: BsModalRef;
    public usedFieldsRequirements;
    public localizations;

    public constructor(
        private modalService: BsModalService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
    }

    public _loadTranslations() {
        this.translate.get(['common', 'standards']).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    public _initData() {
        this.titles = [
            {
                name: this.localizations.standards.name_plural,
                code: 'plural',
            },
            {
                name: this.localizations.standards.name_single,
                code: 'single',
            },
        ];

        this.permissionParams = [
            {
                code: 'add',
                name: this.localizations.standards.add_from_registry,
            },
            {
                code: 'edit',
                name: this.localizations.common.editing,
                isSettingEditedFields: true,
            },
            {
                code: 'remove',
                name: this.localizations.common.removal,
            },
        ];

        if (!this.data.entity.objectsCommon.titles) {
            this.data.entity.objectsCommon.titles = {};
        }
        if (this.metaData.commonData.allowCreateMainXsd) {
            this.permissionParams.push({
                code: 'editMainXsd',
                name: this.localizations.standards.main_block_editing,
            });
        }
        if (this.metaData.commonData.allowCreateXsd) {
            this.permissionParams.push({
                code: 'editXsd',
                name: this.localizations.standards.additional_data_editing,
            });
        }
        this.afterAdd();
        this.afterSelect();
    }

    public getMainBlockMessage() {
        const template = this.localizations.standards.main_block;
        const state = !this.data.entity.objectsCommon.mainXsd
            ? this.localizations.common.not_set_up
            : this.localizations.common.set_up;

        return template.replace('%s', state);
    }

    public getAdditionalFieldsSetUpStatusMessage(): string {
        const template = this.localizations.standards.additional_fields_are;

        if (!this.data.entity.objectsCommon.xsd) {
            return template.replace('%s', this.localizations.common.not_set_up_plural);
        }

        return template.replace('%s', this.localizations.common.set_up_plural);
    }

    public editXsd(field) {
        this.onEditXsd.emit(field);
    }

    public changeSelectFromRegistry() {
        this.data.entity.objectsCommon.allowSelectFromRegistry =
            !this.data.entity.objectsCommon.allowSelectFromRegistry;
        if (
            !this.data.entity.objectsCommon.allowSelectFromRegistry &&
            this.data.entity.objectsCommon.selectOnlyFromGlobalRegistry
        ) {
            this.data.entity.objectsCommon.selectOnlyFromGlobalRegistry = false;
        }
    }

    public afterAdd() {
        this.data.entity.objectsCommon.afterAdd = !!this.data.entity.objectsCommon.allowCreateNew;
    }

    public afterSelect() {
        this.data.entity.objectsCommon.afterSelect = !!this.data.entity.objectsCommon.allowSelectFromRegistry;
    }

    public setFieldsRequirements() {
        if (!this.data.entity.objectsCommon.fieldsRequirements) {
            this.usedFieldsRequirements = { subjectType: 'obj' };
        } else {
            this.usedFieldsRequirements = cloneDeep(this.data.entity.objectsCommon.fieldsRequirements);
        }
        this.modalRef = this.modalService.show(this.fieldsRequirementsSettingsModal, {
            backdrop: 'static',
            class: 'modal-lg',
        });
    }

    public applyFieldsRequirements() {
        this.data.entity.objectsCommon.fieldsRequirements = this.usedFieldsRequirements;
        this.modalRef.hide();
    }
}
