<!-- Отображение при просмотре -->
<ng-container *ngIf="mode == 'view'">
    <div  class="border-bottom border-bottom-default card-line pl-20 pr-20" id="document{{document.guid}}" #documentCard>
        <div class="display-inline-block card-preview">
            <common-appeal-document-preview [document]="document"
                                            [allowEdit]="allowEdit"
                                            [group]="group" mode="view"
                                            (onActivateTab)="activateTab($event)"></common-appeal-document-preview>
        </div>

        <div class="display-inline-block card-buttons mt-15 text-right" style="width: 180px">

            <!-- Кнопка "Загрузка файла" -->
            <ng-container *ngIf="data && !data.envelope">
                <input #fileInput type="file" name="file-{{document.guid}}" id="file-{{document.guid}}" [multiple]="multiple" class="inputfile hide"  (change)="fileChange($event)"/>
                <label for="file-{{document.guid}}" class="btn btn-default btn-sxs"  *ngIf="task && allowEdit"  title="загрузить файл">
                    <i class="icon-attachment"></i>
                </label>
            </ng-container>

            <!-- Кнопка "Печать документа" (при одной ПФ) -->
            <button class="btn btn-default btn-sxs"
                    [disabled]="!appeal._id || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])"
                    *ngIf="allowEdit && data.printForms && data.printForms.length == 1 && data.allowEdit && !data.isElectronicDocument" (click)="print()" type="button" title="Сформировать печатную форму">
                <i class="icon-printer2"></i>
            </button>

            <!-- Кнопка "Печать документа" (при нескольких ПФ) -->
            <div class="btn-group" *ngIf="allowEdit && data.printForms && data.printForms.length > 1 && data.allowEdit && !data.isElectronicDocument">
                <button class="btn btn-default btn-sxs dropdown-toggle" data-toggle="dropdown"
                        [disabled]="!appeal._id || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])">
                    <i class="icon-printer2"></i>
                    <span class="caret ml-5"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let printForm of data.printForms">
                        <a (click)="print(printForm)">{{printForm.name}}</a>
                    </li>
                </ul>
            </div>

            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" style="opacity: .35" (click)="edit()"
                    *ngIf="allowEdit && (!task && data.allowEdit || data.allowPartiallyEdit || data.queue.length > 0 || (data.envelope && (data.envelope.status == 'created' || data.envelope.status == 'error')))"
                    type="button" title="{{data.allowEdit ? 'Редактировать' : 'Просмотреть'}}">
                <b>
                    <i [inlineSVG]="!allowEdit || !data.allowEdit && !data.allowPartiallyEdit ? 'assets/icon-eye.svg' : 'assets/icon-edit-black.svg'"></i>
                </b>
            </button>

            <!-- Кнопка "Обновить данные запроса" -->
            <button class="btn btn-default btn-sxs" (click)="updateEnvelopeData()"
                    [disabled]="!accessService.hasAccess([permissions.Envelopes_Search], true, appeal.status)"
                    *ngIf="document.requestId && data.envelope && data.envelope.status && data.envelope.status == 'sended'" type="button" title="Обновить данные запроса">
                <i class="icon-loop"></i>
            </button>

            <!-- Кнопка "Удалить документ" -->
            <button class="btn btn-default btn-sxs" style="opacity: .35" (click)="delete()" type="button" title="Удалить"
                    [disabled]="!accessService.hasAccess([permissions.Appeal_Document_Delete], true, appeal.status, [{code: 'process', permission: this.permissions.Allow_Print_Registers_After_Appeal_Registration}])"
                    *ngIf="allowEdit && data.allowDelete">
                <i inlineSVG="assets/icon-trash-black.svg"></i>
            </button>

            <!-- Кнопка "Переместить документ" -->
            <ng-content *ngIf="allowEdit && group.name === 'Прочие документы'" select="[dragButton]"></ng-content>
        </div>
    </div>
    <!-- Файлы -->
    <common-appeal-document-files [document]="document" [group]="group" [task]="task" [appeal]="appeal" (onDeleteFile)="afterDeleteFile()" (inspectorChange)="inspectorChange($event)" (isCheckFileSign)="checkallowEdit($event)" *ngIf="task" [allowEdit]="allowEdit"></common-appeal-document-files>
</ng-container>


<!-- Редактирование отдельного документа -->
<div class="panel panel-shadow no-border m-20" *ngIf="mode == 'edit'" [ngClass]="{'no-border-top': !task, 'panel-small': task}">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span *ngIf="!data.name && !data.doc">Новый документ</span>
            <span *ngIf="data.name || data.doc">{{data.name ? data.name : data.doc.name}}</span>
            <span class="badge badge-xs badge-flat text-uppercase ml-5 text-info border-info"
                  *ngIf="data.isForwardingRequest">Передача дела в ОГВ</span>
            <span class="anchor text-info text-size-small ml-5"
                  *ngIf="((document.docGuid == 'other') || (document.docGuid && group && group.docs && group.docs.length > 1) || (!group.guid && !document.printForm)) && data.allowEdit && !isProcessSelectDocumentKind && !isProcessEditDocumentName"
                  (click)="changeDocument()">изменить</span>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">

            <!-- Кнопка "Печать документа" (при одной ПФ) -->
            <button class="btn btn-primary-overline heading-btn" [ngClass]="{'btn-xs': task, 'flash': data.isProcessGeneratePrintForm}"
                    [disabled]="!appeal._id || data.isProcessGeneratePrintForm
                                || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])"
                    *ngIf="data.printForms && data.printForms.length == 1 && data.allowEdit && !data.isElectronicDocument" (click)="print()" type="button">
                Печать
            </button>

            <!-- Кнопка "Печать документа" (при нескольких ПФ) -->
            <div class="btn-group"
                 *ngIf="data.printForms && data.printForms.length > 1 && data.allowEdit && !data.isElectronicDocument"
                 [ngClass]="{'btn-xs': task, 'flash': data.isProcessGeneratePrintForm}">
                <button class="btn btn-primary-overline heading-btn" data-toggle="dropdown"
                        [disabled]="!appeal._id || data.isProcessGeneratePrintForm
                                    || !accessService.hasAccess([permissions.Print_Forms], true, appeal.status, [{code: 'process', permission: permissions.Allow_Print_Registers_After_Appeal_Registration}])">
                    Печать
                    <span class="caret ml-5"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let printForm of data.printForms">
                        <a (click)="print(printForm)">{{printForm.name}}</a>
                    </li>
                </ul>
            </div>

            <button class="btn btn-primary-overline heading-btn"
                    [disabled]="!accessService.hasAccess([permissions.Envelope_Send]) || isProcessSendingEnvelope"
                    *ngIf="document.requestId && appeal._id
                           && data.allowEdit && appeal.dateRegister
                           && (data.envelope.status == 'created' || data.envelope.status == 'error')" (click)="send()">
                Отправить
            </button>

            <button class="btn btn-success-overline heading-btn" (click)="apply()"
                    [disabled]="isProcessSendingEnvelope"
                    *ngIf="data.allowEdit || data.allowPartiallyEdit">
                Применить
            </button>

            <button class="btn btn-danger-overline heading-btn" (click)="cancel()">
                {{ data.allowEdit ? 'Отменить' : 'Закрыть' }}
            </button>
        </div>
    </div>
    <div class="panel-body no-padding-left no-padding-right no-padding-bottom">

        <!-- Выбор вида документа (для документа группы) -->
        <div class="bg-primary-50 pt-10 pb-10 pl-20 pr-20" *ngIf="isProcessSelectDocumentKind && group.guid && document.guid !== 'other'">
            <div class="text-bold mb-10">Выберите вид: </div>
            <div class="radio-block" *ngFor="let doc of group.docs | orderBy: ['displayOrder']; let fst = first"
                 [ngClass]="{'no-padding-top': fst}">
                <input type="radio" class="radio"
                       id="doc{{doc.guid}}{{document.guid}}"
                       name="doc{{document.guid}}"
                       [value]="doc"
                       [checked]="document.docGuid == doc.guid"
                       (click)="selectDocumentKind(doc)">
                <label for="doc{{doc.guid}}{{document.guid}}">
                    {{doc.name}}
                    <span class="badge badge-xs badge-flat text-uppercase ml-5 text-info border-info"
                          *ngIf="doc.isForwardingRequest">Передача дела в ОГВ</span>
                    <span class="help-block no-margin" *ngIf="doc.description">{{doc.description}}</span>
                </label>
            </div>

            <span class="anchor text-info text-size-small" (click)="selectDocumentKind(otherDocument)">другой документ</span>
        </div>

        <!-- Ввод наименования произвольного документа -->
        <div class="input-group input-group-lg ml-20 mr-20 mb-20" *ngIf="isProcessEditDocumentName">
            <input class="form-control" type="text" placeholder="Введите наименование документа" [(ngModel)]="data.name">
            <span class="input-group-btn">
                <button class="btn btn-default" type="button" (click)="applyDocumentName(data.name)"><i class="icon-checkmark3"></i></button>
            </span>
        </div>

        <div *ngIf="!isProcessSelectDocumentKind && !isProcessEditDocumentName && !isProcessSimplifyAddDocumentToComplex && !data.link">

            <!-- Вкладки переключения блоков данных -->
            <element-edit-tabs [tabs]="editTabs" [active]="activeTab" [elementId]="document.guid" [type]="'documents'"
                               (onActivateTab)="activateTab($event)" *ngIf="mode == 'edit'"></element-edit-tabs>

            <!-- Общие данные (общая вкладка) -->
            <common-appeal-document-common [document]="document" [group]="group" [appeal]="appeal" [task]="task"
                                    (afterSaveEnvelope)="afterGetEnvelopeXsdData()"
                                    (afterSendEnvelope)="afterSendEnvelope()"
                                    [hidden]="activeTab != 'common'" #commonData></common-appeal-document-common>

            <!-- Файлы -->
            <ng-container *ngIf="activeTab == 'files'">
                <fieldset *ngIf="document.requestId && data.envelope.resultXml">
                    <legend class="text-bold no-margin-bottom pl-20">Результат запроса</legend>
                    <div class="p-20">
                        <dynamic-form
                            [model]="data.envelope.resultXml"
                            [saveInMainObject]="true"
                            [xsd]="data.envelope.request.resultXsd"
                            [textView]="true">
                        </dynamic-form>
                    </div>
                </fieldset>
                <fieldset>
                    <legend class="text-bold no-margin-bottom pl-20" *ngIf="document.requestId && data.envelope.resultXml">Файлы</legend>
                    <common-appeal-document-files [document]="document"
                                                  [appeal]="appeal"
                                                  [task]="task"
                                                  (onDeleteFile)="afterDeleteFile()"
                                                  (inspectorChange)="inspectorChange($event)"
                                                  (isCheckFileSign)="checkallowEdit($event)"></common-appeal-document-files>
                </fieldset>
            </ng-container>


            <!-- Реквизиты документа -->
            <common-appeal-document-properties [document]="document"
                                        (onAfterSave)="afterSaveDocumentProperties($event)"
                                        *ngIf="activeTab == 'properties'" #appealDocumentProperties></common-appeal-document-properties>

        </div>

    </div>
</div>

<modal-dialog
    #deleteModal
    message="Вы действительно хотите удалить документ из КНМ?"
    title="Удаление документа"
    (onSelect)="sendDeleteRequest($event)">
</modal-dialog>
