<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            (click)="saveAppeal()"
            [disabled]="commonAppealSaveService.isProcessSavingAppeal
                        || (appeal._id ? !accessService.hasAccess([permissions.Appeal_Update], true, appeal.status)
                                       : !accessService.hasAccess([permissions.Appeal_Create], true, appeal.status))">
        <b>
            <i class="icon-checkmark-circle"
               [ngClass]="{'flash': commonAppealSaveService.isProcessSavingAppeal}">
            </i>
        </b>
        {{ localizations.common.save }}
    </button>
    <div class="ml-20 navbar-title pt-20">
        <h4>
            <span *ngIf="!appeal._id">
                {{commonAppealService.getElementTitle('creatingAppeal', localizations.appeals.knm_creation )}}
            </span>
            <ng-container *ngIf="appeal._id && appeal.status.code != 'consultation'">
                <span>
                    {{commonAppealService.getElementTitle('singleAppeal', localizations.common.knm)}}
                <ng-container *ngIf="appeal.number">
                    <span class="text-muted"> № </span>
                    {{appeal.number}}
                    <ng-container *ngIf="appeal.dateRegister">
                        <span class="text-muted">
                            {{ localizations.common.from }} {{appeal.dateRegister | date: 'shortDate'}}
                        </span>
                    </ng-container>

                </ng-container>
                </span>
            </ng-container>

            <!-- Статус дела -->
            <label class="badge text-uppercase ml-15 mb-15"
                   [ngClass]="getStatusProperty('background')">
                {{getStatusProperty('shortName')}}
            </label>

            <label class="badge text-uppercase bg-danger-300 ml-15 mb-15"
                   *ngIf="appeal.fromEpgu">
                {{ localizations.common.epgu }}
            </label>
            <label class="badge text-uppercase bg-orange-300 ml-15 mb-15"
                   *ngIf="appeal.fromFns">
                {{ localizations.organizations.fns }}
            </label>

         </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <!-- Secondary sidebar -->
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content height-100">
            <evolenta-scrollbar>
                <!--  Вернуться назад  -->
                <div class="ml-20 mr-20 mb-20"
                     *ngIf="workMode !== 'default'"
                     [hidden]="processData && processData.data && processData.data.activeEvent">
                    <button class="btn btn-danger-overline pl-15 pr-15 pt-10 pb-10 width-100 mt-20 no-white-space btn-extra-large text-left"
                            (click)="back()">
                        {{ localizations.common.return }}
                    </button>
                </div>

                <!-- Обычный режим просмотра -->
                <ng-container *ngIf="workMode === 'default' && (!processData || !processData.data.activeEvent)">

                    <!-- Вкладки переключения разделов дела -->
                    <common-appeal-tabs [appeal]="appeal"
                                 [tabs]="tabs"
                                 [activeTab]="activeTab"
                                 (onActivateTab)="activateTab($event)">
                    </common-appeal-tabs>

                </ng-container>

                <!-- Кнопки действий статусов для дела и услуги -->
                <common-appeal-actions [appeal]="appeal"
                                [actions]="actions"
                                [serviceActions]="appealSubserviceActions"
                                [processData]="processData ? processData.data : null"
                                (onExecuteAction)="executeAction($event)"
                                *ngIf="!processData || !processData.data.activeEvent"
                                #appealActions>
                </common-appeal-actions>

                <!-- Кнопки событий бизнес-процесса -->
                <entity-process-events mode="buttons"
                                       [data]="processData.data"
                                       (onApplyEvent)="applyEvent()"
                                       *ngIf="processData && processData.data">
                </entity-process-events>
            </evolenta-scrollbar>

        </div>
    </div>

    <!-- Блоки с настройками элементов дела -->
    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-radius no-margin no-padding">
            <div>
                <common-appeal-header [appeal]="appeal"
                                      [hidden]="processData && processData.data && processData.data.activeEvent"
                                      [subservice]="subservice"
                                      *ngIf="appeal && appeal.subjects">
                </common-appeal-header>

                <!-- Данные бизнес-процессе -->
                <ng-container *ngIf="processInfo">
                    <entity-process [processId]="processInfo.id"
                                    [processInfoId]="processInfo.camundaBusinessInfoId"
                                    [checkProcess]="processInfo.status === 'ACTIVE'"
                                    [processInEntity]="processDataInSubservice"
                                    [dataForProcessing]="processParamsInSubservice"
                                    [entity]="appeal"
                                    [savedPlace]="appeal.subservice"
                                    [completedEvents]="appeal.events"
                                    [useXsdService]="commonAppealService.useXsdService"
                                    [isOldVersion]="subservice.isOldVersion"
                                    (onSaveEntity)="processingSaveAppeal()"
                                    (afterCompleteProcess)="afterCompleteProcess()"
                                    (needUpdateEntity)="updateAppeal()"
                                    (afterInitProcessData)="initActiveTab()"
                                    #processData>
                    </entity-process>

                    <!-- Форма настройки активного события -->
                    <entity-process-events mode="settings"
                                           [data]="processData.data"
                                           (afterApplyEvent)="commonAppealService.saveHistoryItem($event)"
                                           #processEvents>
                    </entity-process-events>
                </ng-container>

                <!-- Блоки дела -->
                <div [hidden]="processData && processData.data && processData.data.activeEvent">
                    <common-appeal-blocks [tabs]="tabs"
                                          [appeal]="appeal"
                                          [subservice]="subservice"
                                          [metaReglament]="metaReglament"
                                          [processData]="processData ? processData.data : null"
                                          [activeTab]="activeTab"
                                          [workMode]="workMode"
                                          (onChangeTab)="changeActiveTab($event)"
                                          (afterApplyData)="afterApplyAppealData()"
                                          (onErrorsApplyData)="onErrorsApplyAppealData()"
                                          #appealBlocks>
                    </common-appeal-blocks>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>

</div>
