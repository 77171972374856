import { Injectable } from '@angular/core';

@Injectable()
export class NotificationsPageService {
    public static Statuses = [
        {
            name: 'Не прочитано',
            shortName: 'НП',
            status: 'read',
            code: 'read',
            isInverse: true,
            background: 'bg-orange-300',
            backgroundLite: 'bg-orange-50',
            label: 'bg-orange-50 text-orange',
            border: 'border-top-orange-400',
            dates: {
                start: { operator: 'ge', value: 'current' },
                end: { operator: 'lt', value: 'currentPlusMonth' },
            },
        },
        {
            name: 'Прочитано',
            shortName: 'П',
            status: 'read',
            code: 'read',
            background: 'bg-success-300',
            backgroundLite: 'bg-success-50',
            label: 'bg-success-50 text-success',
            border: 'border-top-success-400',
            dates: {
                start: { operator: 'ge', value: 'currentPlusMonth' },
            },
        },
    ];

    // Типы дат дела
    public static DiffDates = [
        {
            id: 'dateCreation',
            text: 'Дата создания',
        },
    ];

    public static FiltersPanelItems = [
        {
            name: 'Период',
            filters: [
                {
                    type: 'period',
                    dates: NotificationsPageService.DiffDates,
                    itemName: 'text',
                    itemValue: 'id',
                    items: [
                        {
                            code: 'today',
                            name: 'Сегодня',
                        },
                        {
                            code: 'week',
                            name: 'Неделя',
                        },
                        {
                            code: 'month',
                            name: 'Месяц',
                        },
                        {
                            code: 'interval',
                            name: 'Интервал',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Статус',
            filters: [
                {
                    fields: ['status'],
                    type: 'checkboxes',
                    items: NotificationsPageService.Statuses,
                    hiddenProperty: 'isHidden',
                    itemStyleProperty: 'background',
                    itemName: 'name',
                    itemValue: 'code',
                    labelWidth: 140,
                },
            ],
        },
    ];

    public constructor() {
    }
}
