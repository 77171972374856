<common-standard-block-with-additional-data [block]="activeData"
                                            [showBlockTitle]="false"
                                            [useAdditionalData]="false">
</common-standard-block-with-additional-data>

<ng-container *ngFor="let request of standardsService.requests">
    <evolenta-request-card
            [request]="request"
            [allowEdit]="activeData.allowEdit"
            [setParams]="true"
            [params]="getEntityRequest(request._id)"
            (onDelete)="delete($event)"
    ></evolenta-request-card>
</ng-container>

<button class="btn btn-primary-overline btn-labeled btn-labeled-right"
        *ngIf="activeData.allowEdit"
        (click)="selectRequests()">
    <b>
        <i inlineSVG="assets/icon-plus-round.svg"></i>
    </b>
    {{ localizations.standards.select_queries }}
</button>
