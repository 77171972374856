import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentService } from '../../document.service';
import { AppealValidateService } from '../../../../appeal-validate.service';

@Component({
    selector: 'appeal-document-properties',
    templateUrl: 'appeal-document-properties.component.html',
})
export class AppealDocumentPropertiesComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    public canEdit = true;
    public data; // описательный объект для документа

    public schema; // XSD-схема
    public afterSaveNextTab; // Код вкладки, на которую нужно перейти после сохранения данных
    public afterSaveApply; // Флаг необходимости сохранения данных услуги в родительском компоненте (кнопка "Применить")
    public afterSaveCheckChanges; // Флаг необходимости КНМ изменения данных в родительском компоненте (при нажатии кнопки "Назад к списку")

    @ViewChild('saveBtn', { static: false }) public saveBtn;
    @Output() public onAfterSave = new EventEmitter<object>();

    public constructor(
        public validateService: AppealValidateService,
        private documentService: DocumentService,
    ) {
    }

    public ngOnInit() {
        this.data = this.documentService.data[this.document.guid];

        // Обработка XSD
        if (this.data.xsd && !this.data.additionalData) {
            this.data.additionalData = {};
        }
    }

    public ngAfterViewInit() {
        // В случае, если осуществляется КНМ дела, эмулируем нажатие кнопки сохранить, чтобы подсветились ошибки
        if (this.validateService.processValidate) {
            this.save();
        }
    }

    /**
     * Запуск процедуры сохранения данных из родительского компонента (отдельное сохранение)
     * @param tab - код вкладки, на которую нужно перейти после сохранения (при клике на другую вкладку)
     * @param apply - после сохранения переход на метод apply в родительском компоненте (нажатие кнопки "Применить")
     * @param check - после сохранения переход на метод checkChanges в родительском компоненте (нажатие кнопки "Назад к списку")
     */
    public save(tab = null, apply = false, check = false) {
        this.afterSaveNextTab = tab ? tab : null; // после сохранения переход на другую вкладку
        this.afterSaveApply = apply ? apply : false; // после сохранения сохранение данных (кнопка "Применить")
        this.afterSaveCheckChanges = check ? check : false; // после сохранения КНМ услуги на изменение (кнопка "Назад к списку")

        if (this.saveBtn) {
            // Небольшая задержка для выставления / снятия флага необходимости валидации
            if (this.saveBtn) {
                setTimeout(() => { this.saveBtn.nativeElement.click(); }, 100);
            } else {
                this.complete({values: null, errors: []});
            }
        } else {
            this.transferToParentComponent();
        }
    }

    /**
     * Получение данных с JSON-формы
     * @param data - json-объект с данными формы
     */
    public complete(data) {
        if (data.errors && data.errors.length > 0) {
            console.log('ERRORS', data.errors);
        }
        if (data.values) {
            this.data.xsdData = data.values; // сохранение данных в сервисе
            this.data.xsdDataValid = data.errors.length === 0; // сохранение данных об ошибках в сервисе
        }

        // Передача управления в родительский компонент
        this.transferToParentComponent();
    }

    public transferToParentComponent() {
        this.onAfterSave.emit({
            tab: this.afterSaveNextTab,
            apply: this.afterSaveApply,
            checkChanges: this.afterSaveCheckChanges,
        });
    }
}
