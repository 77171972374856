<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            (click)="getNewRole()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations.roles.create }}
    </button>

    <div class="ml-20 mt-5 navbar-title">
        <h4 class="page-title">
            {{ localizations.roles.plural }}
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<div class="page-container">
    <div class="panel no-border panel-main wide no-margin">
        <div class="panel-body no-padding">
            <evolenta-grid [model]="model"
                           [colDef]="colDef"
                           *ngIf="showGrid"
                           #gridElement>
            </evolenta-grid>
        </div>
    </div>
</div>

