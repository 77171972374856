import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EntityVersionsService, FiltersService, RestService, SelectionService, StorageService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Injectable()
export class AisObjectsResolve implements Resolve<any> {
    public onlyKnoObjectsAction = false;
    public globalObjectsAction = false;
    public isCreateAction = false;

    public constructor(
        private restService: RestService,
        private entityVersionsService: EntityVersionsService,
        private storage: StorageService,
        private selectionService: SelectionService,
        private filtersService: FiltersService
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.isCreateAction = route.url.filter((item) => item.path === 'create').length > 0;
        this.onlyKnoObjectsAction = route.parent.url.filter((item) => item.path === 'kno').length > 0;
        this.globalObjectsAction = route.parent.url.filter((item) => item.path === 'global').length > 0;

        return this.getSearchParams(route);
    }

    public getSearchParams(route) {
        const collection = this.onlyKnoObjectsAction ? 'objectsKno' : 'objects';
        if (!this.filtersService.collectionsSearchParams[collection]) {
            return this.restService
                .search('collectionsMeta', {
                    search: { search: [{ field: 'name', operator: 'eq', value: collection }] },
                })
                .then((data) => {
                    const collectionData = data[0];
                    this.filtersService.collectionsSearchParams[collection] = {
                        useExternalSearch: !!(collectionData.searchInfo && collectionData.searchInfo.enableSearch),
                        textSearchFields:
                            collectionData.searchInfo && collectionData.searchInfo.textSearchFields
                                ? collectionData.searchInfo.textSearchFields
                                : [],
                    };
                    return this.getRiskCategories(route);
                });
        } else {
            return this.getRiskCategories(route);
        }
    }

    public getRiskCategories(route) {
        const riskCategories = this.storage.getItem('riskCategories');
        if (!riskCategories) {
            return this.restService.search('nsiErpRiskCategories').then((settings) => {
                if (settings.length > 0) {
                    this.storage.setItem('riskCategories', settings);
                }
                return this.getData(route);
            });
        } else {
            return this.getData(route);
        }
    }

    public getData(route) {
        const objectId = route.params['objectId'];
        const versionId = route.params['versionId'];

        if (this.isCreateAction) {
            return Promise.resolve({ isOldVersion: false, object: {} });
        } else if (objectId) {
            // Текущая версия объекта
            const knoMode = route.parent.url.filter((item) => item.path === 'kno').length > 0;
            const collection = knoMode ? 'objectsKno' : 'objects';
            if (objectId && !versionId) {
                if (this.selectionService.isProcessSelect) {
                    return this.selectionService.transferObject;
                } else {
                    return this.restService.find(collection, objectId).then((object) => {
                        return {
                            isOldVersion: false,
                            object: object,
                        };
                    });
                }
            }

            // Историческая версия объекта
            if (objectId && versionId) {
                return this.entityVersionsService.find(collection, versionId).then((object) => {
                    return {
                        isOldVersion: true,
                        object: object,
                    };
                });
            }
        } else {
            return this.getObjectsList();
        }
    }

    public getObjectsList() {
        const params = [];
        const collection = this.onlyKnoObjectsAction ? 'objectsKno' : 'objects';
        if (this.onlyKnoObjectsAction) {
            const organization = this.storage.getItem('currentOrganization');
            params.push({
                field: 'unit.id',
                operator: 'eq',
                value: organization._id,
            });
        }

        return this.restService
            .search(collection, { search: { search: params }, prj: 'objectsList' })
            .then((objects) => {
                return this.filtersService.processingLinkedData(
                    collection,
                    objects,
                    'objectLink',
                    'globalObjectId',
                    'objectsList'
                );
            });
    }
}
