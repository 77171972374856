<div class="panel panel-shadow m-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>{{allowEdit ? 'Редактирование' : 'Просмотр'}} категории {{getCategoryName()}}</span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled" (click)="save()" *ngIf="allowEdit">Применить</button>
            <button class="btn btn-danger-overline heading-btn btn-labeled" (click)="cancel()">{{allowEdit ? 'Отменить' : 'Закрыть'}}</button>
        </div>
    </div>

    <div class="panel-body">
        <!-- Основные данные -->
        <dynamic-form
            #mainData
            *ngIf="metaData.groupsAndCategoriesData.categoryData.xsd"
            [xsd]="metaData.groupsAndCategoriesData.categoryData.xsd"
            [model]="category"
            [disabled]="!allowEdit"
            [saveInMainObject]="true">
        </dynamic-form>

        <!-- Настройка видов участия -->
        <ng-container *ngIf="metaData.groupsAndCategoriesData.categoryData.isSubjectKindsSetting">
            <p *ngIf="!isProcessSelectSubjectKind">
                <span class="text-muted">Вид участия: </span>
                <span *ngIf="!category.kind" [ngClass]="{'text-danger': isProcessValidate}"><em>не выбран</em></span>
                <ng-container *ngIf="category.kind">{{category.kind.name}}</ng-container>
                <span class="text-size-small anchor text-info ml-10" (click)="isProcessSelectSubjectKind = true" *ngIf="allowEdit">выбрать</span>
            </p>
            <div class="bg-primary-50 p-20 mb-15" *ngIf="isProcessSelectSubjectKind">
                <p class="text-bold no-margin">Выберите вид участия:</p>
                <div class="radio-block" *ngFor="let kind of subjectKinds">
                    <input type="radio" class="radio" name="request" id="kind{{kind.code}}"
                           [checked]="category.kind && category.kind.code === kind.code" (change)="selectKind(kind)">
                    <label for="kind{{kind.code}}">{{kind.name}}</label>
                </div>
            </div>
        </ng-container>

        <!-- Выбор вида участника -->
        <ng-container *ngIf="metaData.groupsAndCategoriesData.categoryData.isSubjectTypesSetting">
            <ng-container *ngIf="!isProcessSelectSubjectTypes">
                <p>
                    <span class="text-muted">Тип {{metaData.groupsAndCategoriesData.categoryData.name ? getCategoryTypeName() : ' субъекта'}}: </span>
                    <span [ngClass]="{'text-danger': isProcessValidate}" *ngIf="!category.subjectTypes || category.subjectTypes.length == 0"><em>не выбраны</em></span>
                    <span class="text-size-small anchor text-info ml-10" (click)="isProcessSelectSubjectTypes = true" *ngIf="allowEdit">выбрать</span>
                </p>
                <table class="table table-xs mb-15" *ngIf="category.subjectTypes && category.subjectTypes.length > 0">
                    <tbody>
                    <tr *ngFor="let type of category.subjectTypes; let idx = index">
                        <td>{{type.name}}</td>
                        <td class="text-right pt-5 pb-5">
                            <button class="btn btn-default btn-sxs" (click)="deleteSubjectType(idx)"><i class="icon-trash"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>

            <div class="bg-primary-50 p-20 mb-15" *ngIf="isProcessSelectSubjectTypes">
                <p class="text-bold no-margin">Выберите тип {{metaData.groupsAndCategoriesData.categoryData.name ? getCategoryTypeName() : ' субъекта'}}:</p>
                <div class="radio-block" *ngFor="let subjectType of subjectTypes">
                    <input type="checkbox" class="checkbox" name="selectedSubjectTypes" id="type{{subjectType.code}}"
                           [checked]="checkSelectedSubjectType(subjectType)" (change)="selectSubjectType(subjectType)">
                    <label for="type{{subjectType.code}}">{{subjectType.name}}</label>
                </div>
                <button class="btn btn-info mt-15" (click)="isProcessSelectSubjectTypes = false">Применить</button>
            </div>
        </ng-container>

        <!-- Настройка дополнительных данных -->
        <p *ngIf="metaData.groupsAndCategoriesData.categoryData.allowCreateXsd">
            <span class="text-muted">Дополнительные поля: </span> <em *ngIf="!category.xsd">не настроены</em><span *ngIf="category.xsd">настроены</span>
            <span class="text-size-small anchor text-info ml-10" (click)="editXsd()" *ngIf="allowEdit || category.xsd">{{!category.xsd ? 'настроить' : allowEdit ? 'изменить' : 'просмотреть'}}</span>
            <span class="text-size-small anchor text-slate ml-10" (click)="category.xsd = null" *ngIf="category.xsd && allowEdit">удалить</span>
        </p>

        <div class="form-group form-group-lg" *ngIf="fieldRequirements.length > 0">
            <label>Настройка обязательности полей</label>
            <ng-select [allowClear]="true"
                       [items]="fieldRequirements"
                       [active]="getSelected('fieldRequirements', fieldRequirements)"
                       [multiple]="false"
                       (selected)="selectProperty($event, 'fieldRequirements')"
                       (removed)="removeProperty('fieldRequirements')"
                       placeholder="Выберите значение">
            </ng-select>
        </div>

        <div class="form-group form-group-lg" *ngIf="fieldRequirements.length > 0">
            <label>Настройка обязательности полей</label>
            <ng-select [allowClear]="true"
                       [items]="portalFieldsSettings"
                       [active]="getSelected('portalFieldsSetting', portalFieldsSettings)"
                       [multiple]="false"
                       (selected)="selectProperty($event, 'portalFieldsSetting')"
                       (removed)="removeProperty('portalFieldsSetting')"
                       placeholder="Выберите значение">
            </ng-select>
        </div>
    </div>
</div>
