import { OnInit, Component, ViewChild, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {
    AccessService,
    FiltersBarComponent,
    RestService,
    SelectionService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Permission } from '../../../common/services/permission';
import { OrganizationsService } from './organizations.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'organizations',
    templateUrl: './organizations.component.html',
    styleUrls: ['../../elements-list.css'],
})
export class OrganizationComponent implements OnInit {
    /** Панели фильтрации */
    @ViewChild('filtersPanel', { static: false }) private filtersPanel: FiltersBarComponent;
    public canCreate = this.access.hasAccess([Permission.Organization_Create]);

    public model = 'organizations';
    public organizations = [];
    public organization;

    /** Настройки для панели фильтрации */
    public filterPanelItems;

    /** Параметры пагинации */
    public totalCount = 0;
    public currentPage = 0;
    public totalPages: number;
    public pageSize = 20;

    /** Режим предпросмотра */
    public previewMode = false;
    public prevent = false;
    public timer = null;
    public delay = 300;

    public globalSearch;

    public notFindedOrgs = [];
    public modalRef: BsModalRef;
    public localizations;
    @ViewChild('notFindedOrganizationsModal', { static: false }) public notFindedOrganizationsModal: TemplateRef<any>; // модальное окно для печати html-формы

    public constructor(
        public selectionService: SelectionService,
        private access: AccessService,
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private rest: RestService,
        private orgService: OrganizationsService,
        private toaster: ToasterService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();

        /** Получение списка организаций */
        this.route.data.subscribe((response) => {
            this.organizations = response.resolves[0];
            if (this.selectionService.isProcessSelect) {
                this.organizations = this.organizations.map((organization) => ({
                    ...organization,
                    selected:
                        this.selectionService.selectedItems.findIndex((item) => {
                            return item._id === organization._id;
                        }) !== -1,
                }));
            }
            this.totalCount = this.rest.pagination.total;
        });

        this.prepareFilterPanelData();
    }

    public getTotalMessage() {
        const template = this.localizations.organizations.total_message;

        return template.replace('%s', this.totalCount);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getNewOrg() {
        this.router.navigate(['admin/organizations/create']);
    }

    public back() {
        this.location.back();
    }

    /**
     * Подготовка данных для панели фильтрации
     */
    public prepareFilterPanelData() {
        this.filterPanelItems = this.orgService.getFilterPanelData();
    }

    /**
     * Поиск по нажатию клавиши Enter после ввода поискового запроса
     */
    public search(event?) {
        if (event) {
            this.loadData('replace');
        } else {
            this.clearSearch();
        }
    }

    /**
     * Загрузка данных
     * @param type
     */
    public loadData(type) {
        if (
            type === 'replace' ||
            (type === 'add' && ((this.totalPages && this.currentPage < this.totalPages - 1) || !this.totalPages))
        ) {
            // Параметры настроенных фильтров
            const search = this.filtersPanel.prepareFilters();
            this.organization = null;

            // Страница
            let page = 0;
            if (type === 'add') {
                page = this.currentPage + 1;
            }

            const params = {
                search: { search: search },
                size: 20,
                page: page,
            };
            // Обработка параметров глобального поиска - добавляем в результирующий поиск orSubCondition по нескольким полям
            if (this.globalSearch) {
                const fieldsParams = ['name', 'shortName'].map((field) => ({
                    field,
                    operator: 'like',
                    value: this.globalSearch,
                }));
                const orSubConditions = {
                    orSubConditions: fieldsParams,
                };
                search.push(orSubConditions);
            }

            // Получение списка организаций
            this.rest.search('organizations', params).then((organizations: any) => {
                if (type === 'replace') {
                    this.organizations = organizations;
                } else {
                    organizations.forEach((item) => {
                        this.organizations.push(item);
                    });
                }
                this.totalCount = this.rest.pagination.total;
                this.currentPage = this.rest.pagination.page;
                this.totalPages = this.rest.pagination.totalPages;
            });
        }
    }

    /** Очистка поисковой строки */
    public clearSearch() {
        this.globalSearch = null;
        this.loadData('replace');
    }

    /**
     * Обработка одиночного клика
     * @param item
     */
    public click(item) {
        this.organization = null;

        this.timer = setTimeout(() => {
            if (!this.prevent) {
                this.previewMode = true;
                this.rest.find('organizations', item._id).then((response) => {
                    this.organization = response;
                });
            }
            this.prevent = false;
        }, this.delay);
    }

    /**
     * Обработка двойного клика
     * @param item
     */
    public dblClick(item) {
        this.router.navigate(['admin/organizations/edit', item._id]);
    }

    /**
     * Возврат к предыдущей операци
     */
    public returnToOperation() {
        this.router.navigate(this.selectionService.transferBackUrl).then(null, (error) => console.log(error));
    }

    /**
     * Метод для набора данных во временный реестр
     * @param organization
     */
    public addToRegistry(organization) {
        organization.selected = !organization.selected;
        if (organization.selected) {
            this.selectionService.selectedItems.push(organization);
        } else {
            const findIndex = this.selectionService.selectedItems.findIndex((item) => {
                return item._id === organization._id;
            });
            if (findIndex !== -1) {
                this.selectionService.selectedItems.splice(findIndex, 1);
            }
        }
    }

    /**
     * Индексирование элементов списка
     * @param index
     * @param item
     */
    public indexedFn(index, item) {
        return item._id;
    }
    /**
     * Стиль для блока запроса в списке запросов при клике
     * @param item
     * @param last
     */
    public cardStyle(item, last) {
        let classes = 'short border-top';
        classes += ' ' + this.orgService.getStatusProperty(item.isAuthorized, 'borderTop');
        if (this.organization && this.organization.guid === item.guid) {
            classes += ' ' + this.orgService.getStatusProperty(item.isAuthorized, 'backgroundLite');
        }
        if (last) {
            classes += ' border-bottom border-bottom-default';
        }
        return classes;
    }

    /**
     * Получение свойства статуса
     * @param status - код статуса
     * @param property - необходимое свойство
     */
    public getStatusProperty(status, property) {
        return this.orgService.getStatusProperty(status, property);
    }

    public deleteOrganization() {
        this.organization = null;
        this.loadData('replace');
    }

    public processingOrganizations(data) {
        const count = 10;
        let usedData = data;
        let remainingData = [];
        if (data.length > count) {
            usedData = data.slice(0, count);
            remainingData = data.slice(count);
        }
        let promises = [];
        const orgs = [];
        usedData.forEach((organizationData) => {
            const params = [
                {
                    field: 'ogrn',
                    operator: 'eq',
                    value: organizationData.ogrn,
                },
                {
                    field: 'inn',
                    operator: 'eq',
                    value: organizationData.inn,
                },
                {
                    field: 'kpp',
                    operator: 'eq',
                    value: organizationData.kpp,
                },
            ];
            orgs.push(organizationData);
            promises.push(this.rest.search('organizations', { search: { search: params } }));
        });
        return Promise.all(promises).then((list) => {
            promises = [];
            list.forEach((organizations, idx) => {
                if (organizations.length === 0) {
                    this.notFindedOrgs.push(orgs[idx]);
                } else {
                    organizations.forEach((org) => {
                        const serviceCodesArray = org.serviceCodes ? org.serviceCodes.split(',') : [];
                        orgs[idx].codes.forEach((code) => {
                            if (serviceCodesArray.indexOf(code) === -1) {
                                serviceCodesArray.push(code);
                            }
                        });
                        org.serviceCodes = _.uniq(serviceCodesArray).join(',');
                        promises.push(this.rest.update('organizations', org));
                    });
                }
            });
            return Promise.all(promises).then(() => {
                if (remainingData.length > 0) {
                    return this.processingOrganizations(remainingData);
                } else {
                    return true;
                }
            });
        });
    }

    public updateServiceCodes(info) {
        this.notFindedOrgs = [];
        if (info) {
            const arr = info.data.split('\r\n');
            arr.splice(0, 1);
            const result: any = {};
            arr.forEach((row) => {
                if (row) {
                    const rowData = row.split(';');
                    const elementCode = rowData[0] + '|' + rowData[1] + '|' + rowData[2];
                    if (!result[elementCode]) {
                        result[elementCode] = {
                            ogrn: rowData[0],
                            inn: rowData[1],
                            kpp: rowData[2],
                            codes: [],
                        };
                    }
                    result[elementCode]['codes'].push(rowData[3]);
                }
            });
            const organizationsData = Object.values(result);

            this.processingOrganizations(organizationsData).then((item) => {
                if (this.notFindedOrgs.length > 0) {
                    this.modalRef = this.modalService.show(this.notFindedOrganizationsModal, { backdrop: 'static' });
                } else {
                    this.toaster.success('Настройки услуг успешно применены в организациях');
                }
            });
        }
    }
}
