<div class="" [ngClass]="{'pt-15 pl-20 pr-20': mode == 'edit'}">

    <!-- Шаблон popup-информации о документе-линке -->
    <ng-template #popTemplate>
        <p class="mb-3 text-size-small" *ngIf="allDocumentsData[data.link].subservice">
            <span class="text-muted">Процесс:</span> {{allDocumentsData[data.link].subservice.title}}
        </p>
        <p class="mb-3 text-size-small">
            <span class="text-muted">Группа:</span> {{allDocumentsData[data.link].groupName}}
        </p>
        <p class="mb-3 text-size-small">
            <span class="text-muted">Документ:</span> {{allDocumentsData[data.link].name}}
        </p>
    </ng-template>

    <!-- Ошибки при валидации документа -->
    <ng-template #errorsTemplate><span class="text-semibold">Ошибки документа:</span>
        <ul [innerHtml]="getErrorsList()" class="no-margin"></ul>
    </ng-template>

    <div class="display-flex">
        <div *ngIf="mode !== 'edit'" class="pt-5 pb-5 pr-20">
            <!-- Иконка документа -->
            <div [inlineSVG]="!document.requestId ? 'assets/document.svg' : 'assets/document.svg'" *ngIf="mode != 'edit'"></div>
        </div>
        <div class="pt-5 pb-5 ">
            <div *ngIf="mode !== 'edit'">
                <p class="text-size-large mb-5">
                    {{ document.name ? document.name : 'Новый документ' }}
                    <span class="badge badge-xs badge-flat text-uppercase ml-5 text-info border-info"
                          *ngIf="data.isForwardingRequest">Передача дела в ОГВ</span>
                </p>

                <!-- Кнопка с детализацией ошибок для режима просмотра -->
                <button class="btn btn-danger btn-badge ml-10"
                        *ngIf="checkErrorsExist()"
                        [popover]="errorsTemplate"
                        [outsideClick]="true"
                        placement="right">ошибки
                </button>
            </div>
            <div *ngIf="(mode == 'edit' && !data.link && !data.resultSubserviceLink) || mode == 'view'">
                <!-- Если есть ссылки на документ или услугу -->
                <ng-container *ngIf="data && (data.link || data.resultSubserviceLink)">

                    <!-- Связь с другой услугой в комплексной услуге -->
                    <div *ngIf="data.resultSubserviceLink && mode !== 'edit'">
                        <span class="text-size-small text-muted">Является результатом предоставления услуги: </span>
                        <span class="text-size-small">{{data.resultSubserviceLink.title}}</span>
                    </div>

                    <!-- Связь с другим документом дела в комплексной услуге -->
                    <div *ngIf="document.link && mode !== 'edit'">
                        <span class="text-size-small text-muted">Связь с требованием: </span>
                        <span *ngIf="data.link" class="ml-5 text-size-small">
                    {{allDocumentsData[data.link].groupName}} /
                            {{allDocumentsData[data.link] ? allDocumentsData[data.link].name : ''}}
                            <ng-container *ngIf="allDocumentsData[data.link].useObjects && allDocumentsData[data.link].useObjects.length > 0">
                        <span class="text-size-small ml-5">
                            <span class="text-muted text-size-small">Субъекты:</span>
                            <ng-container *ngFor="let object of allDocumentsData[data.link].useObjects; let lst = last">
                                {{object.shortHeader}}
                                <ng-container *ngIf="!lst">, </ng-container>
                            </ng-container>
                        </span>
                    </ng-container>
                    <i class="fa fa-info-circle text-slate cursor-pointer"
                       [popover]="popTemplate" placement="right"
                       [outsideClick]="true"></i>
                </span>
                    </div>
                </ng-container>

                <!-- Если нет ссылок на документ и услугу (обычный документ) -->
                <ng-container *ngIf="!data.link && !data.resultSubserviceLink">
                    <!-- Статус запроса, объекты запроса, документы дела запроса -->
                    <ng-container *ngIf="document.requestId && data.envelope">
                        <span class="text-muted text-size-small">Запрос: </span>
                        <span class="text-muted text-size-small">№</span>
                        <!-- Номер конверта-запроса -->
                        <span *ngIf="data.envelope.auid && !accessService.hasAccess([permissions.Envelopes_Search])">{{data.envelope.auid}}</span>
                        <a *ngIf="data.envelope.auid && accessService.hasAccess([permissions.Envelopes_Search])" class="anchor text-small-size text-info"
                           routerLink="/selecting/envelopes/edit/{{data.envelope._id}}">
                            {{data.envelope.auid}}</a>
                        <span *ngIf="!data.envelope.auid" class="ml-5">n/a</span>

                        <!-- Статус запроса -->
                        <span class="badge badge-xs text-uppercase ml-5"
                              [ngClass]="getEnvelopeStatusProperty('background')">{{getEnvelopeStatusProperty('name')}}</span>

                        <!-- Уведомление о наличии непросмотренного результата запроса -->
                        <i class="icon-bookmark2 ml-5 text-primary" *ngIf="data.envelope.isHavingResult" style="width: 16px" title="Непросмотренный ответ на запрос"></i>

                        <!-- Дата ответа на запрос -->
                        <ng-container *ngIf="data.envelope.status == 'closed' && data.envelope.dateHavingResult">
                            <span class="text-muted text-size-small ml-10">Дата ответа: </span>
                            <span>{{data.envelope.dateHavingResult | date:'shortDate'}} г.</span>
                        </ng-container>

                        <!-- Дата ответа на запрос -->
                        <ng-container *ngIf="data.envelope.status == 'sended' && data.envelope.datePlaneFinish">
                            <span class="text-muted text-size-small ml-10">Плановая дата ответа: </span>
                            <span>{{data.envelope.datePlaneFinish | date:'shortDate'}} г.</span>
                        </ng-container>

                        <!-- Участник в запросе -->
                        <ng-container *ngIf="data.envelopeObject">
                            <span class="text-muted text-size-small ml-10">Субъект: </span>
                            <span class="anchor text-info text-size-small ml-5" (click)="activateTab('common')"
                                  *ngIf="data.allowEdit">{{data.envelopeObject.shortHeader}}</span>
                            <span *ngIf="!data.allowEdit">{{data.envelopeObject.shortHeader}}</span>
                        </ng-container>
                    </ng-container>

                    <!-- Файлы -->
                    <ng-container>
                        <span class="text-muted text-size-small" [ngClass]="{'ml-5': document.requestId && data.envelope}">Файлы: </span>
                        <span [ngClass]="{'anchor text-info': (documentService.calculateFileCount(data) > 0 || data.allowPartiallyEdit) && allowEdit}" (click)="activateTab(data.requestId ? 'files' : 'common')">
                <ng-container *ngIf="data.queue">{{documentService.calculateFileCount(data)}}</ng-container>
                </span>
                    </ng-container>

                    <!-- Участники/объекты для документов НЕ запросов -->
                    <ng-container *ngIf="data.useObjects && data.useObjects.length > 0">
                        <span class="text-muted ml-10 text-size-small">Субъекты: </span>
                        <span [ngClass]="{'anchor text-info': data.allowEdit}"
                              (click)="activateTab('common')">
                    <ng-container *ngFor="let object of data.useObjects; let lst = last">
                        {{object.shortHeader}} <ng-container *ngIf="!lst">, </ng-container>
                    </ng-container>
                </span>
                        <ng-container *ngIf="data.principal">
                            <span class="text-muted ml-10">Доверитель: </span>
                            <span  [ngClass]="{'anchor text-info': data.allowEdit}" (click)="activateTab('common')">{{data.principal.shortHeader}}</span>
                        </ng-container>
                    </ng-container>

                    <!-- Реквизиты документа -->
                    <ng-container *ngIf="data.seria || data.number || data.issuer || data.dateIssue">
                        <span class="text-muted ml-10 text-size-small">Реквизиты требования:</span>
                        <span class="ml-5" *ngIf="data.seria">{{data.seria}}</span>
                        <span class="ml-5" *ngIf="data.number">№ {{data.number}}</span>
                        <ng-container *ngIf="data.dateIssue || data.issuer">
                            <span class="text-muted ml-5">выдан</span>
                            <span class="ml-5" *ngIf="data.issuer">{{data.issuer}}</span>
                            <span class="ml-5" *ngIf="data.dateIssue">{{data.dateIssue.formatted}} г.</span>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
