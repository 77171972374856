import {
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
    HttpService,
    RestService,
    SelectionService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { EntityAdditionalDataComponent } from '@components/entity-additional-data/entity-additional-data.component';
import { EntityKndKindsDataComponent } from '../../../entities-data/entity-knd-kinds-data/entity-knd-kinds-data.component';
import { Config } from '../../../../common/services/config';
import { OrganizationsService } from '../../../organizations/organization/organizations.service';
import { LeafletMapModalComponent } from '@components/modal/leaflet-map-modal/leaflet-map-modal.component';
import { ObjectVersionsComponent } from '../components/object-versions/object-versions.component';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-ais-object-edit',
    templateUrl: './ais-object-edit.component.html',
    styleUrls: [
        '../../../element-edit.css',
    ],
})
export class AisObjectEditComponent implements OnInit {
    public object: any;

    // Визуальные вкладки с данными пользователя
    public tabs = [];

    public activeTab = 'common'; // Код активной вкладки

    public isOldVersion = false;
    public allowEdit = false;
    public isLoading = false;
    public isEditObjectKind = false; // Флаг режима выбора вида объекта

    public kndKinds;
    public objectType;

    public moduleBaseUrl;
    public workModeUrl;

    public currentOrganization = this.storage.getItem('currentOrganization');

    public baseObject;

    @ViewChild('mainAdditionalData', { static: false }) public mainAdditionalDataComponent: EntityAdditionalDataComponent; // компонент дополнительными данными для объекта (по виду объекта)
    @ViewChild('kndKindsData', { static: false }) public kndKindsDataComponent: EntityKndKindsDataComponent;
    @ViewChild('objectVersions', { static: false }) public objectVersionsComponent: ObjectVersionsComponent; // компонент отображения версий объекта
    @ViewChild('map', { read: ViewContainerRef, static: false }) public mapComponentViewContainer: ViewContainerRef;
    private mapComponent: ComponentRef<LeafletMapModalComponent>;

    public objectXsd;

    public isGlobalObjectMode = false;
    public globalObject;

    public linkedSubjects;

    public collection;
    public existKnoObject = true;
    public objectKnoId;
    public localizations;

    public constructor(
        private selectionService: SelectionService,
        private route: ActivatedRoute,
        private restService: RestService,
        private toaster: ToasterService,
        private router: Router,
        private location: Location,
        private storage: StorageService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private httpService: HttpService,
        private organizationsService: OrganizationsService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'events',
                'objects',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.tabs = [
            {
                code: 'common',
                name: this.localizations.events.basic_params,
                active: true,
            },
            {
                code: 'versions',
                name: this.localizations.objects.versions,
                active: false,
            },
        ];

        // Получение URL основного модуля
        this.route.parent.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        this.route.parent.url.subscribe(urlPath => {
            this.workModeUrl = urlPath[urlPath.length - 1].path;
            this.collection = this.workModeUrl === 'kno' ? 'objectsKno' : 'objects';
        });
        this.route.data.subscribe(
            response => {
                this.isOldVersion = response.resolves.isOldVersion;

                if (this.selectionService.isProcessSelect) {
                    switch (this.selectionService.transferOperation) {
                        case 'selectSubjects':
                            this.object = this.selectionService.transferObject;
                            this.baseObject = _.cloneDeep(this.selectionService.additionalData.baseObject);
                            this.activeTab = 'subjects';
                            break;
                        case 'selectObjectKind':
                            this.processingSelectObjectType();
                            break;
                    }
                } else {
                    this.object = response.resolves.object;
                    if (!this.object._id) {
                        this.object = {
                            unit: {
                                id: this.currentOrganization._id,
                                name: this.currentOrganization.name,
                            },
                            linkedSubjects: [],
                            xsdData: {},
                        };
                        this.isEditObjectKind = true;
                    } else {
                        if (this.object.globalObjectId) {
                            this.getGlobalObject();
                        } else {
                            this.objectType = this.object.type;
                        }
                    }
                    this.baseObject = _.cloneDeep(this.object);
                }

                if (this.workModeUrl === 'global') {
                    this.isGlobalObjectMode = true;
                    this.collection = 'objects';
                    this.getKnoObject();
                } else {
                    this.tabs.splice(1, 0, {
                        code: 'subjects',
                        name: this.localizations.appeals.subjects.title,
                        active: false,
                    }, {
                        code: 'kndKinds',
                        name: this.localizations['knd-kinds'].name,
                        active: false,
                    });
                    this.getXsd();
                    this.collection = 'objectsKno';
                }
            },
        );
    }

    public getGlobalObject() {
        return this.restService.find('objects', this.object.globalObjectId).then(object => {
            this.globalObject = object;
            this.objectType = this.globalObject.type;
        });
    }

    public getKnoObject() {
        const params = [
            {
                field: 'globalObjectId',
                operator: 'eq',
                value: this.object._id,
            },
            {
                field: 'unit.id',
                operator: 'eq',
                value: this.currentOrganization._id,
            },
        ];
        this.restService.search('objectsKno', {search: {search: params}}).then(knoObjects => {
            if (knoObjects.length === 0) {
                this.existKnoObject = false;
            } else {
                this.objectKnoId = knoObjects[0]._id;
            }
        });
    }

    public getXsd() {
        if (this.object.type) {
            const params = [
                {
                    field: 'entityId',
                    operator: 'eq',
                    value: this.object.type.id,
                },
                {
                    field: 'entityName',
                    operator: 'eq',
                    value: 'objectType',
                },
                {
                    field: 'unitId',
                    operator: 'eq',
                    value: null,
                },
                {
                    field: 'kndKindId',
                    operator: 'eq',
                    value: null,
                },
            ];

            this.restService.search('entitiesXsd', {search: {search: params}}).then(response => {
                if (response && response.length > 0) {
                    if (!this.object.additionalData) {
                        this.object.additionalData = {};
                    }
                    this.objectXsd = response[0].xsd;
                }
            });
        }
    }

    /**
     * Создание объекта для КНО на основе глобального объекта
     */
    public createObjectInCurrentKno() {
        const addedObject = {
            unit: {
                id: this.currentOrganization._id,
                name: this.currentOrganization.name,
            },
            globalObjectId: this.object._id,
        };
        this.restService.create('objectsKno', addedObject).then((createdObject: any) => {
            this.router.navigate(['registers', 'objects', 'kno', 'edit', createdObject._id]);
        });
    }

    public navigateToKnoObject() {
        this.router.navigate([this.moduleBaseUrl, 'objects', 'kno', 'edit', this.objectKnoId]);
    }

    public processingSelectObjectType() {
        if (this.selectionService.isProcessSelect) {
            this.object = this.selectionService.transferObject;
            this.baseObject = _.cloneDeep(this.selectionService.additionalData.baseObject);

            const selectedValue = this.selectionService.selectedItems[0];

            this.object.type = {
                id: selectedValue._id,
                code: selectedValue.code,
                name: selectedValue.name,
            };
            this.objectType = this.object.type;

            this.selectionService.clearData();
        }
    }

    public async _create() {
        try {
            const result: any = await this.restService.create(this.collection, this.object);
            this.object._id = result._id;
            await this._startCalculateRisk();
        } catch (error) {
            this.toaster.error(error);
            console.log('error', error);
        } finally {
            this.isLoading = false;
        }
    }

    public saveDataInIOT(savedObject, isCreateObject = false) {
        const dataToSend = {
            guid: savedObject.guid,
            id: savedObject._id,
            name: savedObject.name,
            regionName: savedObject.region ? savedObject.region.name : '',
            address: savedObject.baseAddress ? PrintUtilities.PrintAddressAsLine(savedObject.baseAddress, true) : '',
        };
        if (!isCreateObject) {
            let needUpdate = false;
            if (this.baseObject.name !== savedObject.name) {
                needUpdate = true;
            }
            if (this.baseObject.region && savedObject.region && this.baseObject.region.name !== savedObject.region.name
                || !this.baseObject.region && savedObject.region || this.baseObject.region && !savedObject.region) {
                needUpdate = true;
            }
            if (this.baseObject.baseAddress && savedObject.baseAddress && PrintUtilities.PrintAddressAsLine(this.baseObject.baseAddress, true) !== PrintUtilities.PrintAddressAsLine(savedObject.baseAddress, true)
               || !this.baseObject.baseAddress && savedObject.baseAddress || this.baseObject.baseAddress && !savedObject.baseAddress) {
                needUpdate = true;
            }
            if (needUpdate) {
                this.httpService.post(Config.server + Config.api + 'business/iotUpdate', dataToSend).then(result => {
                    console.log('update result', result);
                    this.baseObject = _.cloneDeep(savedObject);
                    this.toaster.success(this.localizations.objects.changed);
                });
            } else {
                this.toaster.success(this.localizations.objects.changed);
            }
        } else {
            this.httpService.post(Config.server + Config.api + 'business/iotCreate', dataToSend).then(result => {
                this.completeCreateObject(savedObject);
            });
        }
    }

    public completeCreateObject(createdObject) {
        this.toaster.success('Объект создан');
        this.router.navigate([this.moduleBaseUrl, 'objects', this.workModeUrl, 'edit', createdObject._id]);
    }

    public async _update() {
        const objectForSave: any = {};
        // Пробегаемся по полям объекта сравнения с аналогичными полями базового объекта
        Object.keys(this.object).forEach(item => {
            if (JSON.stringify(this.object[item]) !== JSON.stringify(this.baseObject[item])) {
                objectForSave[item] = this.object[item];
            }
        });

        if (!Object.keys(objectForSave).length) {
            this.toaster.success('Данные успешно сохранены');
        }

        objectForSave._id = this.object._id;
        objectForSave.guid = this.object.guid;

        try {
            const savedObject = await this.restService.update(this.collection, objectForSave);
            if (this.activeTab && this.activeTab === 'versions') {
                this.objectVersionsComponent.init();
            }
            this.object = Object.assign(this.object, savedObject);
            this.baseObject = _.cloneDeep(this.object);
            await this._startCalculateRisk();
            this.toaster.success('Данные успешно сохранены');
        } catch (error) {
            this.toaster.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    private async _startCalculateRisk() {
        if (!this.object.type) {
            return {};
        }

        const kndKindIds = this.organizationsService.getKndKindsWithAutoCalculateRiskCategory(this.object.type.id, 'objectTypes');
        if (kndKindIds.length) {
            return this.organizationsService.startCalculateRiskCategory(kndKindIds, this.object, 'objectTypes');
        }

        return {};
    }

    public back() {
        this.location.back();
    }

    public async save() {
        if (!this.object.type) {
            this.toaster.error('Не выбран вид объекта');

            return;
        }

        if (this.kndKindsDataComponent) {
            await this.kndKindsDataComponent.save();
        }

        await this._processSave();
    }

    public async _processSave() {
        if (this.object._id) {
            await this._update();

            return;
        }

        await this._create();
    }

    public changeSubjectList(subject) {
        this.linkedSubjects = subject;
        this.selectionService.clearData();
    }

    /**
     * Выбор вида объека
     */
    public async selectObjectType() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.object;
        this.selectionService.selectedItems = this.object.type ? [this.object.type] : [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectObjectKind';
        this.selectionService.additionalData = { baseObject: _.cloneDeep(this.baseObject) };
        this.selectionService.selectType = 'one';

        await this.router.navigate(['metodolog', 'object-types']);
    }

    public async showOnMap() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LeafletMapModalComponent);

        this.mapComponent = this.mapComponentViewContainer.createComponent<LeafletMapModalComponent>(componentFactory) as ComponentRef<LeafletMapModalComponent>;
        await this.mapComponent.instance.init();
        await this.mapComponent.instance.showSingleObject(
            this.object.latitude,
            this.object.longitude,
            { title: this.object.name });
    }
}
