<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            [disabled]="!allowEdit || isLoading || user._id ? !accessService.hasAccess([permissions.User_Update]) : !accessService.hasAccess([permissions.User_Create])"
            (click)="save()">
            <b>
                <i class="icon-checkmark-circle"
                   [ngClass]="{'flash': isLoading}">
                </i>
            </b>
            {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            <i class="icon-arrow-left52 position-left cursor-pointer mr-10"
               style="font-size: 20px"
               (click)="back()"
               [title]="localizations.common.back">
            </i>
            {{ getUserActionMessage(user) }}
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">

    <!-- Меню разделов данных -->
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content">
            <!-- Вкладки -->
            <div *ngFor="let tab of tabs">
                <div class="sidebar-category"
                     [ngClass]="{
                        'active': activeTab === tab.code,
                        'cursor-pointer': activeTab != tab.code
                     }"
                     *ngIf="tab.show()"
                     (click)="activeTab = tab.code">
                    <div class="category-title">
                        <span>{{tab.name}}</span>
                        <ul class="icons-list"
                            *ngIf="activeTab != tab.code">
                            <li><i class="icon-arrow-right22"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-bottom no-border-rigth no-border-radius no-margin no-padding">
            <div>
                <div class="alert alert-warning no-border no-border-radius no-margin-bottom"
                     *ngIf="!allowEdit">
                    {{ localizations.tasks.user_card_is_in_viewing_mode }}
                </div>

                <!-- Основные настройки -->
                <div [hidden]="activeTab != 'common'">
                    <form #userForm="ngForm">
                        <div class="pt-20 pl-20 pr-20 pb-10">
                            <div class="row">
                                <div class="col-lg-3 col-sm-4 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label"
                                               for="login">
                                            {{ localizations.users.login }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input id="login"
                                               class="form-control"
                                               type="text"
                                               name="login"
                                               required
                                               [disabled]="!allowEdit"
                                               [(ngModel)]="user.login"
                                               #login="ngModel">
                                        <div class="validation-error-label"
                                             *ngIf="isProcessValidate && login.errors">
                                            <span *ngIf="login.errors.required">
                                                {{ localizations.users.notifications.login_field_is_required }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-4 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label"
                                               for="name">
                                            {{ localizations.tasks.fio }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input id="name"
                                               class="form-control"
                                               type="text"
                                               name="name"
                                               required
                                               [disabled]="!allowEdit"
                                               [(ngModel)]="user.name"
                                               #name="ngModel">
                                        <div class="validation-error-label"
                                             *ngIf="isProcessValidate && name.errors">
                                            <span *ngIf="name.errors.required">
                                                {{ localizations.users.notifications.full_name_field_is_required }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-4 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label"
                                               for="snils">
                                            {{ localizations.appeals.subjects.snils }}
                                        </label>
                                        <input id="snils"
                                               class="form-control"
                                               type="text"
                                               name="snils"
                                               validateSnils
                                               [textMask]="{mask: snilsMask}"
                                               [disabled]="!allowEdit"
                                               [(ngModel)]="user.snils"
                                               #snils="ngModel">
                                        <div class="validation-error-label"
                                             *ngIf="snils.errors">
                                            <span>
                                                {{ snils.errors.validateSnils }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-4 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label"
                                               for="position">
                                            {{ localizations.users.position }}
                                        </label>
                                        <input id="position"
                                               class="form-control"
                                               type="text"
                                               name="position"
                                               [disabled]="!allowEdit"
                                               [(ngModel)]="user.position">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group form-group-lg">
                                        <label>
                                            {{ localizations.common.cell_phone }}
                                        </label>
                                        <input class="form-control"
                                               type="text"
                                               name="mobile"
                                               [textMask]="{mask: mobilePhoneMask}"
                                               [(ngModel)]="user.mobile">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group form-group-lg">
                                        <label>
                                            {{ localizations.appeals.subjects.email_address }}
                                        </label>
                                        <input class="form-control"
                                               type="text"
                                               name="email"
                                               pattern="^[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-zA-Z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)*(?:[a-zA-Z][a-zA-Z]+)$"
                                               [(ngModel)]="user.email"
                                               #email="ngModel">
                                        <div class="validation-error-label"
                                             *ngIf="email.errors">
                                            <span *ngIf="email.errors.pattern">
                                                {{ localizations.tasks.email_is_incorrect }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Флаг активности пользователя -->
                            <div class="checkbox-inline">
                                <input id="userActive"
                                       class="checkbox"
                                       type="checkbox"
                                       name="userActive"
                                       [disabled]="!allowEdit"
                                       [(ngModel)]="user.active">
                                <label class="control-label"
                                       for="userActive">
                                    {{ localizations.tasks.user_is_active }}
                                </label>
                            </div>

                            <!-- Флаг необходимости смены пароля -->
                            <div class="checkbox-inline">
                                <input id="userChangePassword"
                                       class="checkbox"
                                       type="checkbox"
                                       name="userChangePassword"
                                       [disabled]="!allowEdit"
                                       [(ngModel)]="changePassword.isChange">
                                <label class="control-label"
                                       for="userChangePassword">
                                    {{ localizations.tasks.change_pasword }}
                                </label>
                            </div>

                            <!-- Флаг необходимости смены пароля при следующем входе в систему -->
                            <div class="checkbox-inline">
                                <input id="forceChangePassword"
                                       class="checkbox"
                                       type="checkbox"
                                       name="forceChangePassword"
                                       [disabled]="!allowEdit"
                                       [(ngModel)]="user.forceChangePassword">
                                <label class="control-label"
                                       for="forceChangePassword">
                                    {{ localizations.tasks.change_password_on_next_login }}
                                </label>
                            </div>

                            <!-- Флаг указания типа пользователя -->
                            <div class="checkbox-inline">
                                <input id="changeSpecialistType"
                                       class="checkbox"
                                       type="checkbox"
                                       name="changeSpecialistType"
                                       [disabled]="!allowEdit"
                                       [(ngModel)]="user.isTechnical">
                                <label class="control-label"
                                       for="changeSpecialistType">
                                    {{ localizations.tasks.technical_specialist }}
                                </label>
                            </div>
                        </div>

                        <!-- Смена пароля -->
                        <ng-container *ngIf="changePassword.isChange">
                            <hr class="no-margin">
                            <div class="pl-20 pr-20 pt-20">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-3 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                {{ localizations.user.password }}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input class="form-control"
                                                   type="password"
                                                   name="password"
                                                   required
                                                   [(ngModel)]="user.password"
                                                   #password="ngModel">
                                            <div class="validation-error-label"
                                                 *ngIf="isProcessValidate && password.errors">
                                                <span *ngIf="password.errors.required">
                                                    {{ localizations.tasks.password_field_is_required }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                {{ localizations.tasks.repeat_password }}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input class="form-control"
                                                   type="password"
                                                   name="repeatPassword"
                                                   required
                                                   [(ngModel)]="changePassword.repeatPassword"
                                                   #repeatPassword="ngModel">
                                            <div class="validation-error-label"
                                                 *ngIf="isProcessValidate && (repeatPassword.errors || user.password != changePassword.repeatPassword)">
                                                <span *ngIf="repeatPassword.errors && repeatPassword.errors.required">
                                                    {{ localizations.tasks.password_field_is_required }}
                                                </span>
                                                <span *ngIf="!repeatPassword.errors && user.password != changePassword.repeatPassword">
                                                    {{ localizations.tasks.passwords_do_not_match }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">&nbsp;</label>
                                            <generate-password (afterGeneratePassword)="afterGeneratePassword($event)">
                                            </generate-password>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Доступные модули -->
                        <hr class="no-margin">
                        <div class="pt-20 pl-20 pr-20 pb-10">
                            <div class="mb-10 text-bold">
                                {{ localizations.tasks.available_modules }}
                            </div>
                            <div class="row">
                                <div class="col-md-4"
                                     *ngFor="let app of applications">
                                    <div class="checkbox-block">
                                        <input id="application{{app._id}}"
                                               class="checkbox"
                                               type="checkbox"
                                               [checked]="app.use"
                                               [disabled]="!allowEdit"
                                               (change)="changeLinkApplications(app)">
                                        <label for="application{{app._id}}">
                                            {{ app.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Роли -->
                        <ng-container *ngIf="!isSuperAdminMode">
                            <hr class="no-margin">
                            <div class="pt-20 pl-20 pr-20 pb-20">
                                <div class="mb-10 text-bold">
                                    {{ localizations.tasks.general_system_roles }}
                                </div>
                                <div class="row">
                                    <div class="col-md-3"
                                         *ngFor="let roleMnemonic of rolesMnemonics">
                                        <div class="checkbox-block">
                                            <input id="mnemonic{{roleMnemonic.code}}"
                                                   class="checkbox"
                                                   type="checkbox"
                                                   [checked]="checkRolesMnemonicUse(roleMnemonic)"
                                                   (change)="changeRolesMnemonic(roleMnemonic)">
                                            <label for="mnemonic{{roleMnemonic.code}}">
                                                {{ roleMnemonic.name }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Дополнительные возможности -->
                        <ng-container *ngIf="userEnvelopesMode && !hasAdminAppAccess()">
                            <hr class="no-margin">
                            <div class="pt-20 pl-20 pr-20 pb-20">
                                <div class="mb-10 text-bold">
                                    {{ localizations.tasks.additional_features }}
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="checkbox-block">
                                            <input id="hasRequestsAccess"
                                                   class="checkbox"
                                                   type="checkbox"
                                                   [disabled]="!isSuperAdminMode"
                                                   [checked]="user.envelopeSettings?.allowAccess"
                                                   (change)="user.envelopeSettings.allowAccess = !user.envelopeSettings.allowAccess">
                                            <label for="hasRequestsAccess">
                                                {{ localizations.tasks.requests_access }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </form>
                </div>

               <!-- Настройка организаций -->
                <div *ngIf="activeTab == 'organisations'">
                    <div class="row mb-20">
                        <div class="col-md-6">
                            <div class="input-group input-group-lg mt-20 ml-20">
                                <input class="form-control"
                                       type="text"
                                       [placeholder]="localizations.common.search_dots"
                                       [(ngModel)]="globalSearch"
                                       (keypress)="searchOrganizations()">
                                <div class="input-group-btn">
                                    <button class="btn btn-default text-muted"
                                            type="button"
                                            (click)="searchOrganizations()">
                                        <i class="icon-search4 text-muted"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 hide">
                            <div class="alert alert-info mt-20 ml-20 mr-15 mb-15 pt-10 pb-10 no-border"
                                 *ngIf="allowEdit">
                                <strong>
                                    {{ localizations.common.attention }}
                                </strong>
                                {{ localizations.tasks.press_button }}
                                <strong>&laquo;Ctrl&raquo;</strong>
                            </div>
                        </div>
                    </div>
                    <ng-template let-list="list"
                                 let-level="level"
                                 #recursiveOrganizations>
                        <ng-container *ngFor="let item of list">
                            <div class="tree-line"
                                 [ngStyle]="{'padding-left': (level * 30 + 10) + 'px'}"
                                 *ngIf="item.visible || item.halfVisible">
                                <div class="tree-item-toggle-sign">
                                    <span class="cursor-pointer"
                                          *ngIf="item.children && item.children.length > 0"
                                          (click)="item.showChildren = !item.showChildren">
                                        <i class="icon-plus2"
                                           *ngIf="!item.showChildren">
                                        </i>
                                        <i class="icon-minus2"
                                           *ngIf="item.showChildren">
                                        </i>
                                    </span>
                                </div>
                                <div class="tree-item-title">
                                    <span [ngClass]="{'opacity-50': !item.selected}">
                                        {{ item.name }}
                                    </span>
                                </div>
                                <div class="tree-item-detail">
                                    <ng-container *ngIf="item.selected">
                                        <ng-container *ngIf="!item.isProcessingEdit">
                                            <ng-container *ngIf="item.rolesMnemonics.length > 0">
                                                <span class="badge bg-primary-300 text-uppercase mr-5"
                                                      *ngFor="let role of item.rolesMnemonics">
                                                    {{ role.text }}
                                                </span>
                                            </ng-container>
                                            <span class="badge bg-slate-300 text-uppercase"
                                                  *ngIf="item.rolesMnemonics.length == 0">
                                                {{ localizations.tasks.roles_not_defined }}
                                            </span>
                                        </ng-container>

                                        <ng-select id="{{item.id}}"
                                                   class="tree-select"
                                                   [placeholder]="localizations['bpmn-card'].select_value_dots"
                                                   [items]="getOrganizationTransformedRolesMnemonics(item._id)"
                                                   [multiple]="true"
                                                   [active]="item.rolesMnemonics"
                                                   *ngIf="item.isProcessingEdit"
                                                   (data)="changeOrganiztionRolesMnemonics($event, item)">
                                        </ng-select>
                                    </ng-container>
                                </div>
                                <div class="tree-item-buttons text-right">
                                    <ng-container *ngIf="item.selected">
                                        <ng-container *ngIf="!item.isProcessingEdit">
                                            <button class="btn btn-default btn-sxs"
                                                    type="button"
                                                    [title]="localizations.tasks.change_fields_content"
                                                    (click)="item.isProcessingEdit = true">
                                                <i class="icon-pencil"></i>
                                            </button>
                                            <button class="btn btn-default btn-sxs"
                                                    type="button"
                                                    [title]="localizations.tasks.remove_organization"
                                                    (click)="removeOrganization(item)">
                                                <i class="icon-trash"></i>
                                            </button>
                                        </ng-container>
                                        <button class="btn btn-default btn-sxs"
                                                type="button"
                                                *ngIf="item.isProcessingEdit"
                                                (click)="item.isProcessingEdit = false">
                                            <i class="icon-checkmark4"></i></button>
                                    </ng-container>
                                    <button class="btn btn-default btn-sxs"
                                            type="button"
                                            [title]="localizations.tasks.connect_organization_to_user"
                                            *ngIf="!item.selected"
                                            (click)="item.selected = true; item.isProcessingEdit = true">
                                        <i class="icon-plus2"></i>
                                    </button>

                                </div>
                            </div>
                            <ng-container *ngIf="item.children && item.children.length > 0 && item.showChildren">
                                <ng-container *ngTemplateOutlet="recursiveOrganizations; context:{ list: item.children, level: (level + 1)}"></ng-container>
                            </ng-container>

                        </ng-container>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="recursiveOrganizations; context:{ list: organizations, level: 0}"></ng-container>

                    <div class="bg-primary-50 p-20"
                         *ngIf="isProcessingAddOrganization">
                        <div class="row">
                            <div class="col-md-10">
                                <catalogue catalogName="organizations"
                                           projection="unitsList"
                                           [placeholder]="localizations.organizations.enter_name"
                                           [baseSearch]="organizationsBaseSearch"
                                           [showCode]="false"
                                           [large]="true"
                                           [(ngModel)]="organizationSearch"
                                           (onSelect)="selectOrg($event)">
                                </catalogue>
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-default pt-10 pb-10 pr-20 pl-20"
                                        (click)="isProcessingAddOrganization = false">
                                    {{ localizations.common.cancel }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary-overline btn-labeled btn-labeled-right ml-20 mt-15"
                            *ngIf="!isProcessingAddOrganization"
                            (click)="addOrganization()">
                        <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
                        {{ localizations.common.add_organization }}
                    </button>
                </div>

                <!-- Настройка уведомлений -->
                <div *ngIf="activeTab === 'notifications'">
                    <div class="panel panel-shadow m-20">
                        <div class="panel-body">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="text-bold">
                                        {{ localizations['notifications-preview'].kinds }}
                                    </th>
                                    <th class="text-center text-bold">
                                        {{ localizations.organizations.portal }}
                                    </th>
                                    <th class="text-center text-bold">
                                        {{ localizations.common.email }}
                                    </th>
                                    <th class="text-center text-bold">
                                        PUSH
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let type of userMessagesService.types">
                                    <tr>
                                        <td class="text-bold"
                                            colspan="4">
                                            {{ type.name }}
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let kind of userMessagesService.kinds">
                                        <tr *ngIf="kind.type == type.code">
                                            <td>
                                                {{ kind.name }}
                                            </td>
                                            <td class="text-center">
                                                <input id="notificationPortal{{kind.code}}"
                                                       class="checkbox"
                                                       type="checkbox"
                                                       checked
                                                       disabled>
                                                <label for="notificationPortal{{kind.code}}">
                                                    &nbsp;
                                                </label>
                                            </td>
                                            <td class="text-center">
                                                <input id="notificationEmail{{kind.code}}"
                                                       class="checkbox"
                                                       type="checkbox"
                                                       [checked]="checkNotificationSelected(kind, 'email')"
                                                       (change)="changeNotification(kind, 'email')">
                                                <label for="notificationEmail{{kind.code}}">
                                                    &nbsp;
                                                </label>
                                            </td>
                                            <td class="text-center">
                                                <input id="notificationMobile{{kind.code}}"
                                                       class="checkbox"
                                                       type="checkbox"
                                                       [checked]="checkNotificationSelected(kind, 'mobile')"
                                                       [disabled]="!user.mobile"
                                                       (change)="changeNotification(kind, 'mobile')">
                                                <label for="notificationMobile{{kind.code}}">&nbsp;</label>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr></tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="activeTab === 'envelopeSettings'">
                    <div class="p-20">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group form-group-lg">
                                    <multiple-catalogue catalogName="users"
                                                                 codeField="position"
                                                                 projection="usersList"
                                                                 [title]="localizations.users.access_to_users_requests"
                                                                 [placeholder]="localizations.users.select_dots"
                                                                 [large]="true"
                                                                 [baseSearch]="getUsersBaseSearch()"
                                                                 [returnFields]="['_id', 'name', 'position']"
                                                                 [(ngModel)]="user.envelopeSettings.users">
                                    </multiple-catalogue>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
