<div class="notify-label">
    <span class="badge"
          [ngClass]="{
              'bg-warning': type === 'warning',
              'bg-danger': type === 'error',
              'bg-info': type === 'info'
          }"
          title="{{ title }}"
    >&nbsp;</span>
</div>
