import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToasterService } from '@evolenta/core';
import { AppealEntityBuyOrder } from './models/appeal-entity-buy-order';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-buy-order',
    templateUrl: 'appeal-entity-buy-order.component.html',
})
export class AppealEntityBuyOrderComponent implements OnInit {
    @Input() public entity: AppealEntityBuyOrder;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;
    public knmEvents = {};
    public mandatoryReqs = {};
    public basisKnm = {};

    public constructor(private toaster: ToasterService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {}

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidKNMEvents = this.validateKNMEvents();
        const isValidManadatoryReqs = this.validateMandatoryReqs();
        const isValidBasisKnm = this.validateBasisKNM();

        return isValidForm && isValidKNMEvents && isValidManadatoryReqs && isValidBasisKnm;
    }

    public validateKNMEvents(): boolean {
        const isValid = !!_.get(this.entity.knmEvents, '0.value', null);

        this.knmEvents = isValid
            ? { value: this.entity.knmEvents }
            : { value: this.entity.knmEvents, errors: { required: true } };

        return isValid;
    }

    public validateMandatoryReqs(): boolean {
        const isValid = !!_.get(this.entity.mandatoryReqs, '0.value', null);

        this.mandatoryReqs = isValid
            ? { value: this.entity.mandatoryReqs }
            : { value: this.entity.mandatoryReqs, errors: { required: true } };

        return isValid;
    }

    public validateBasisKNM(): boolean {
        const isValid = !!_.get(this.entity.basisKnm, '0.value', null);

        this.basisKnm = isValid
            ? { value: this.entity.basisKnm }
            : { value: this.entity.basisKnm, errors: { required: true } };

        return isValid;
    }
}
