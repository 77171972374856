<div class="pl-20 pr-20"
     *ngIf="!editedStage && !editedTask">

    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false">
    </common-standard-block-with-additional-data>

    <div *ngIf="activeData.entity.stages && activeData.entity.stages.length > 0">
        <ng-container *ngFor="let stage of activeData.entity.stages">
            <div class="panel panel-shadow panel-small">
                <div class="panel-heading">
                    <h5 class="panel-title pt-10"
                        [ngStyle]="{'max-width': '100%'}">
                        {{ stage.name }}
                    </h5>
                    <div class="heading-elements">
                        <div class="heading-elements">
                            <button class="btn btn-default btn-sxs"
                                    type="button"
                                    [title]="localizations.standards.add_task"
                                    *ngIf="activeData.allowEdit"
                                    (click)="addTask(stage)">
                                <i inlineSVG="assets/icon-new-item.svg"></i>
                            </button>
                            <button class="btn btn-default btn-sxs"
                                    type="button"
                                    [title]="activeData.allowEdit ?
                                        localizations.standards.edit_stage :
                                        localizations.standards.check_this_stages"
                                    (click)="editStage(stage)">
                                <i [inlineSVG]="activeData.allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i>
                            </button>
                            <button class="btn btn-default btn-sxs"
                                    (click)="deleteStage(stage)"
                                    type="button"
                                    [title]="localizations.standards.delete_stage"
                                    *ngIf="activeData.allowEdit">
                                <i inlineSVG="assets/icon-trash.svg"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="panel-body no-padding"
                     *ngIf="stage.tasks && stage.tasks.length">
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let task of stage.tasks; let fst=first">
                                <td class="no-padding-right"
                                    width="20"
                                    [ngClass]="{'no-border-top': fst}">
                                    <i class="text-slate mr-5 icon-file-text2 ng-star-inserted"></i>
                                </td>
                                <td class="no-padding-left"
                                    [ngClass]="{'no-border-top': fst}">
                                    {{ task.name }}
                                </td>
                                <td class="text-right"
                                    [ngClass]="{'no-border-top': fst}">
                                    <button class="btn btn-default btn-sxs"
                                            type="button"
                                            [title]="activeData.allowEdit ?
                                            localizations.standards.edit_task : localizations.standards.check_this_tasks"
                                            (click)="editTask(task, stage)">
                                        <i [ngClass]="{
                                            'icon-pencil': activeData.allowEdit,
                                             'icon-eye': !activeData.allowEdit
                                             }">
                                        </i>
                                    </button>
                                    <button type="button"
                                            class="btn btn-default btn-sxs"
                                            [title]="localizations.standards.delete_task"
                                            *ngIf="activeData.allowEdit"
                                            (click)="deleteTask(task, stage)">
                                        <i class="icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>

    <button class="btn btn-primary-overline btn-labeled btn-labeled-right mt-20"
            *ngIf="activeData.allowEdit"
            (click)="addStage()">
        <b>
            <i inlineSVG="assets/icon-plus-round.svg"></i>
        </b>
        {{ localizations.standards.add_stage }}
    </button>
</div>

<div class="pl-20 pr-20" *ngIf="editedStage || editedTask">
    <ng-container *ngIf="editedStage">
        <common-standard-stages-and-tasks-process-stage-edit [stage]="editedStage"
                                                             [allowEdit]="activeData.allowEdit"
                                                             (onApply)="applyStage()"
                                                             (onCancel)="cancelEdit()">
        </common-standard-stages-and-tasks-process-stage-edit>
    </ng-container>

    <ng-container *ngIf="editedTask">
        <common-standard-stages-and-tasks-process-task-edit [task]="editedTask"
                                                            [allowEdit]="activeData.allowEdit"
                                                            (onApply)="applyTask()"
                                                            (onCancel)="cancelEdit()">
        </common-standard-stages-and-tasks-process-task-edit>
    </ng-container>
</div>


