<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div>
                <span class="badge bg-success-300 text-uppercase bg-info">Контрольная закупка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <span class="text-muted" [ngClass]="{'ml-5': task && mode == 'view'}">Номер: </span>
            <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
            <em *ngIf="!entity.number">не указано</em>

            <span class="text-muted ml-10">Дата составления: </span>
            <ng-container *ngIf="entity.dateCompilation && !entity.dateCompilation.formatted">{{entity.dateCompilation | date: 'shortDate'}}</ng-container>
            <em *ngIf="!entity.dateCompilation || entity.dateCompilation && entity.dateCompilation.formatted">не указано</em>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <div class="row">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Номер</label>
                            <input type="text" name="number" class="form-control" [(ngModel)]="entity.number" placeholder="Введите номер">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-9">
                        <label>Тип места проведения КНМ</label>
                        <div class="form-group form-group-lg">
                            <catalogue catalogName="nsiErpPlaceKnmType" name="knmPlaceType" [(ngModel)]="entity.knmPlaceType" [priority]="true" [large]="true"></catalogue>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <label>Тип адреса составления</label>
                        <div class="form-group form-group-lg">
                            <catalogue catalogName="typeAddress" name="typeAddressName" [(ngModel)]="entity.typeAddressName" [priority]="true" [large]="true"></catalogue>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <label>Место составления</label>
                        <div class="form-group form-group-lg">
                            <evolenta-address-gar
                                    [large]="true"
                                    [showIndex]="false"
                                    [(ngModel)]="entity.placeCompilation"
                            ></evolenta-address-gar>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-3">
                        <label>Дата составления</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker name="dateCompilation" [large]="true" [(ngModel)]="entity.dateCompilation" [valueAsString]="true"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="row">
                            <div class="col-xs-6 col-md-6">
                                <label>Время составления</label>
                                <div class="row">
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group input-group form-group-lg">
                                            <input type="number" name="hourCompilation" class="form-control" [(ngModel)]="entity.hourCompilation" min="0" max="23" placeholder="ч">
                                            <div class="input-group-spr"><span>:</span></div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group form-group-lg">
                                            <input type="number" name="minCompilation" class="form-control" [(ngModel)]="entity.minCompilation" min="0" max="59" placeholder="мин">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-6">
                                <label>Время завершения</label>
                                <div class="row">
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group input-group form-group-lg">
                                            <input type="number" name="hourEnd" class="form-control" [(ngModel)]="entity.hourEnd" min="0" max="23" placeholder="ч">
                                            <div class="input-group-spr"><span>:</span></div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-md-6">
                                        <div class="form-group form-group-lg">
                                            <input type="number" name="minEnd" class="form-control" [(ngModel)]="entity.minEnd" min="0" max="59" placeholder="мин">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-5">
                        <div class="row">
                            <div class="col-xs-6 col-md-6">
                                <label>Длительность КНМ (в днях)</label>
                                <div class="form-group form-group-lg">
                                    <input type="number" name="factControlPeriod" class="form-control" [(ngModel)]="entity.factControlPeriod" placeholder="дней" title="дней">
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-6">
                                <label>Длительность КНМ (в часах)</label>
                                <div class="form-group form-group-lg">
                                    <input type="number" name="minEnd" class="form-control" [(ngModel)]="entity.hourControlPeriod" placeholder="часов" title="часов">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-md-3">
                        <label>Дата приказа</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker name="orderDate" [large]="true" [(ngModel)]="entity.orderDate" [valueAsString]="true"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Номер приказа</label>
                            <input type="text" name="orderNumber" class="form-control" [(ngModel)]="entity.orderNumber" placeholder="Введите номер">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <label>Дата проведения</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker name="buyDate" [large]="true" [(ngModel)]="entity.buyDate" [valueAsString]="true"></evolenta-datepicker>
                        </div>
                    </div>
                </div>

                <div class="row mb-15">
                    <div class="col-xs-12 col-md-4">
                        <label class="display-block">Ознакомление с приказом (распоряжением)</label>
                        <div class="radio-inline no-margin-left pt-10">
                            <input id="isAcquaintedFail" class="radio" name="acquainted" type="radio" [checked]="entity.acquainted === false" (click)="changeAcquainted(false)">
                            <label for="isAcquaintedFail">Отказался(ась)</label>
                        </div>
                        <div class="radio-inline pt-10">
                            <input id="isAcquaintedAgree" class="radio" name="acquainted" type="radio" [checked]="entity.acquainted === true" (click)="changeAcquainted(true)">
                            <label for="isAcquaintedAgree">Ознакомлен(а)</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>ФИО представителя</label>
                            <input type="text" name="representative" class="form-control" [(ngModel)]="entity.representative">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Дата ознакомления</label>
                            <evolenta-datepicker name="dateAcquainted" [large]="true" [(ngModel)]="entity.dateAcquainted" [valueAsString]="true"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-2">
                        <div class="form-group form-group-lg">
                            <label>Время ознакомления</label>
                            <input type="text" name="timeAcquainted" class="form-control" [(ngModel)]="entity.timeAcquainted">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <label>Тип представителя проверяемого лица</label>
                        <div class="form-group form-group-lg">
                            <catalogue catalogName="nsiErpAgentType" name="knmPlaceType" [(ngModel)]="entity.agentType" [priority]="true" [large]="true"></catalogue>
                        </div>
                    </div>
                </div>
                <div class="row mb-15">
                    <div class="col-xs-12 col-md-4">
                        <label class="display-block">Получение копии приказа (распоряжения)</label>
                        <div class="radio-inline no-margin-left pt-10">
                            <input id="isGetCopyFail" class="radio" name="getCopy" type="radio" [checked]="entity.getCopy === false" (click)="changeGetCopy(false)">
                            <label for="isGetCopyFail">Отказался(ась)</label>
                        </div>
                        <div class="radio-inline pt-10">
                            <input id="isGetCopyAgree" class="radio" name="getCopy" type="radio" [checked]="entity.getCopy === true" (click)="changeGetCopy(true)">
                            <label for="isGetCopyAgree">Получил(а)</label>
                        </div>
                    </div>
                </div>
                <!-- Инспектор (в случае отказа) -->
                <div class="mb-15" *ngIf="!entity.acquainted && !entity.getCopy">
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        [property]="'inspectorFailure'"
                        [title]="'Инспектор (в случае отказа)'"
                        [field]="['name','position']">
                    </appeal-entity-employee>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <label>Характер сведений о результате</label>
                        <div class="form-group form-group-lg">
                            <catalogue catalogName="nsiErpResultInformNature" name="resultInformNature" [(ngModel)]="entity.resultInformNature" [priority]="true" [large]="true"></catalogue>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Товары, направленные на проведение исследований (испытаний)</label>
                            <textarea name="testItem" class="form-control" [(ngModel)]="entity.testItem" ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Реквизиты документа по стандартизации</label>
                            <textarea name="standard" class="form-control" [(ngModel)]="entity.standard" ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Температура хранения в месте проведения контрольной закупки</label>
                            <input type="text" name="temperature" class="form-control" [(ngModel)]="entity.temperature">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Товары (работы, услуги), подлежащие возврату</label>
                            <textarea name="returnItem" class="form-control" [(ngModel)]="entity.returnItem" ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Сведения о возврате денежных средств/отказе в совершении действий по возврату</label>
                            <textarea name="returnItemMany" class="form-control" [(ngModel)]="entity.returnItemMany" ></textarea>
                        </div>
                    </div>
                </div>
                <!-- Инспектор -->
                <div class="mb-15">
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        property="inspector"
                        [title]="'Инспектор'"
                        [field]="['name','position']">
                    </appeal-entity-employee>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Свидетели (ФИО, адрес места жительства/места регистрации)</label>
                            <textarea name="withness" class="form-control" [(ngModel)]="entity.withness" ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <label>Результат проверки</label>
                        <div class="form-group form-group-lg">
                            <textarea name="resultBuyAct" class="form-control" [(ngModel)]="entity.resultBuyAct" ></textarea>
                        </div>
                    </div>
                </div>

                <!-- Выявленные нарушения обязательных требований -->
                <div class="mb-15">
                    <appeal-entity-catalogue
                        [large]="true"
                        [entity]="entity"
                        [property]="'nsiViolations'"
                        [catalogName]="'nsiViolations'"
                        [title]="'Выявленные нарушения обязательных требований'"
                        [placeholder]="'Выберите обязательные требования'">
                    </appeal-entity-catalogue>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <label>Характер выявленного нарушения</label>
                        <div class="form-group form-group-lg">
                            <catalogue catalogName="nsiErpViolationNature" name="violationNature" [(ngModel)]="entity.violationNature" [priority]="true" [large]="true"></catalogue>
                        </div>
                    </div>
                </div>

                <!-- Перечень  документов, прилагаемых к акту -->
                <div class="mb-15">
                    <appeal-entity-field-input
                        [multiple]="true"
                        [large]="true"
                        [entity]="entity"
                        [property]="'scrollDoc'"
                        [title]="'Приложения'"
                        [placeholder]="'Введите наименование прилагаемого документа'">
                    </appeal-entity-field-input>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <div class="form-group form-group-lg">
                            <label>ФИО представителя</label>
                            <input type="text" name="employeeSignature" class="form-control" [(ngModel)]="entity.employeeSignature">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Дата подписания</label>
                            <evolenta-datepicker name="dateSignature" [large]="true" [(ngModel)]="entity.dateSignature" [valueAsString]="true"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Время подписания</label>
                            <input type="text" name="timeSignature" class="form-control" [(ngModel)]="entity.timeSignature">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Сведения об отказе в совершении подписи акта</label>
                            <textarea name="renouncementSignature" class="form-control" [(ngModel)]="entity.renouncementSignature" ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-4 mb-15">
                        <label class="display-block">Сведения о получении экземпляра акта</label>
                        <div class="radio-inline no-margin-left pt-10">
                            <input id="isGetActFail" class="radio" name="getAct" type="radio" [checked]="entity.getAct === false" (click)="changeGetAct(false)">
                            <label for="isGetActFail">Отказался(ась)</label>
                        </div>
                        <div class="radio-inline pt-10">
                            <input id="isGetActAgree" class="radio" name="getAct" type="radio" [checked]="entity.getAct === true" (click)="changeGetAct(true)">
                            <label for="isGetActAgree">Получил(а)</label>
                        </div>
                    </div>
                </div>
                <div class="mb-15" *ngIf="!entity.getAct">
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        [property]="'inspectorGetAct'"
                        [title]="'ФИО инспектора (в случае отказа получения экземпляра акта)'"
                        [field]="['name','position']">
                    </appeal-entity-employee>
                </div>
                <div class="row" *ngIf="entity.getAct">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Сведения о получении экземпляра акта</label>
                            <textarea name="infoGetAct" class="form-control" [(ngModel)]="entity.infoGetAct" ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="entity.getAct">
                    <div class="col-xs-12 col-md-6">
                        <div class="form-group form-group-lg">
                            <label>ФИО представителя</label>
                            <input type="text" name="representative" class="form-control" [(ngModel)]="entity.representativeGetAct">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Дата получения</label>
                            <evolenta-datepicker name="dateGetAct" [large]="true" [(ngModel)]="entity.dateGetAct" [valueAsString]="true"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="form-group form-group-lg">
                            <label>Время получения</label>
                            <input type="text" name="timeGetAct" class="form-control" [(ngModel)]="entity.timeGetAct">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Отметка  о принятии на исследования (испытания) товаров</label>
                            <textarea name="getTestItem" class="form-control" [(ngModel)]="entity.getTestItem" ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Время доставки товара</label>
                            <input type="text" name="timeDeliveryItem" class="form-control" [(ngModel)]="entity.timeDeliveryItem">
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Температура товара в момент доставки (в отношении пищевой продукции)</label>
                            <input type="text" name="temperatureItem" class="form-control" [(ngModel)]="entity.temperatureItem">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="form-group form-group-lg">
                            <label>ФИО эксперта/представителя экспертной организации</label>
                            <input type="text" name="expert" class="form-control" [(ngModel)]="entity.expert">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
