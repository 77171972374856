<ng-container *ngIf="entity[property]">
    <div class="form-group"  *ngFor="let item of entity[property]; index as idx;">
        <label>
            Место проведения проверки
            <span class="anchor text-info text-size-small ml-5" (click)="addItem()" *ngIf="!idx && !disabled">добавить</span>
            <span class="anchor text-info text-size-small ml-5" (click)="deleteItem(item)" *ngIf="idx && !disabled">удалить</span>
        </label>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group input-group input-group-lg">
                    <evolenta-address-gar
                            [large]="true"
                            [showIndex]="false"
                            [disabled]="disabled"
                            [(ngModel)]="item.place"
                    ></evolenta-address-gar>
                    <div class="input-group-btn">
                        <button class="btn btn-default" type="button" (click)="addItem()" *ngIf="!idx && !disabled"><i class="icon-plus-circle2"></i></button>
                        <button class="btn btn-default" type="button" (click)="deleteItem(item)" *ngIf="idx && !disabled"><i class="icon-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="entity[property].length > 1">
            <div class="row">
                <div class="col-md-4">
                    <label>Дата проведения</label>
                    <evolenta-datepicker [(ngModel)]="item.date" name="checkDate" [large]="true" [valueAsString]="true" [disabled]="disabled"></evolenta-datepicker>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Время начала</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group input-group form-group-lg">
                                        <input type="number" [(ngModel)]="item.startHours" class="form-control input-xs" min="0" max="23" placeholder="ч" [disabled]="disabled">
                                        <div class="input-group-spr"><span>:</span></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-lg">
                                        <input type="number" [(ngModel)]="item.startMinutes" class="form-control input-xs" min="0" max="59" placeholder="мин" [disabled]="disabled">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Время окончания</label>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group input-group form-group-lg">
                                        <input type="number" [(ngModel)]="item.endHours" class="form-control input-xs" min="0" max="23" placeholder="ч" [disabled]="disabled">
                                        <div class="input-group-spr"><span>:</span></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-lg">
                                        <input type="number" [(ngModel)]="item.endMinutes" class="form-control input-xs" min="0" max="59" placeholder="мин" [disabled]="disabled">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label>Продолжительность</label>
                    <div class="form-group form-group-lg">
                        <input [(ngModel)]="item.period" type="number" class="form-control input-xs" [disabled]="disabled">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
