<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button"
            class="btn btn-primary btn-xs btn-main btn-labeled btn-labeled-right"
            [disabled]="!canCreate"
            (click)="getNewUser()">
        <b>
            <i class="icon-plus-circle2"></i>
        </b>
        {{ localizations.users.create }}
    </button>

    <div class="global-search">
        <div class="input-group">
            <input class="form-control"
                   [placeholder]="localizations.common.search_dots"
                   type="text"
                   [(ngModel)]="globalSearch"
                   (ngModelChange)="search($event)">
            <button class="btn btn-clear"
                    type="button"
                    (click)="clearSearch()"
                    *ngIf="globalSearch">
                <i class="icon-cross3"></i>
            </button>
            <div class="input-group-btn">
                <button class="btn btn-default text-muted"
                        type="button"
                        (click)="loadData('add')">
                    <i class="icon-search4 text-muted"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>

</div>

<div class="page-container">

    <!-- Панель фильтрации + Заголовок страницы -->
    <div class="sidebar sidebar-secondary sidebar-default height-100">
        <div class="sidebar-content no-padding height-100">

            <!-- Заголовок страницы -->
            <div class="sidebar-category">
                <div class="category-title">
                    <h4 class="display-inline-block no-margin">
                        <span class="text-semibold">
                            {{ localizations.users.plural }}
                            <span class="badge badge-primary text-uppercase ml-10">
                                {{totalUsersCount}}
                            </span>
                        </span>
                    </h4>
                    <ul class="icons-list hide">
                        <li><i class="icon-cog3"></i></li>
                    </ul>
                </div>
            </div>

            <!-- Панель фильтрации -->
            <filters-bar [filters]="filtersPanelItems"
                                  [afterHeader]="true"
                                  [filterName]="'userssFilter'"
                                  [base]="baseSearch"
                                  (onApplyFilters)="loadData('replace')"
                                  #filtersPanel>
            </filters-bar>
        </div>
    </div>

    <div class="content-wrapper">
        <div class="content">
            <div class="scroll-panels">
                <div class="scroll-panel">
                    <div class="panel panel-flat">
                        <evolenta-infinite-scrollbar scrollClass="panel-body"
                                                     (scrolled)="loadData('add')">
                            <ng-container *ngIf="users.length > 0">
                                <div class="list-2">
                                    <div *ngFor="let item of users; let lst = last">
                                        <div class="display-flex align-items-center border-left-4 border-right-4"
                                             [ngClass]="{'active': user?.guid === item.guid}">
                                            <div class="list-item full-width"
                                                 (click)="click(item)"
                                                 (dblclick)="dblClickByUser(item)">
                                                <div class="list-item-status-rectangle text-uppercase"
                                                     [ngClass]="{
                                                        'bg-success-300': item.active,
                                                         'bg-danger-300': !item.active
                                                     }">
                                                    {{item.active ?
                                                        localizations.common.active :
                                                        localizations.common.disabled
                                                    }}
                                                </div>
                                                <!-- Информация по услуге -->
                                                <div class="list-item-data"
                                                     [ngStyle]="{'width': 'calc(100% - 55px)'}"
                                                     style="white-space: inherit">
                                                    <h5 class="no-margin cursor-pointer">
                                                        {{item.name}}
                                                    </h5>
                                                    <p>
                                                        <span class="text-muted text-uppercase text-size-mini"
                                                              *ngIf="item.login">
                                                            {{ localizations.users.login }}:
                                                        </span>
                                                        {{item.login}}

                                                        <span class="text-muted text-uppercase text-size-mini ml-10"
                                                              *ngIf="item.position">
                                                            {{ localizations.users.position }}:
                                                        </span>
                                                        {{item.position}}

                                                        <span class="text-muted text-uppercase text-size-mini ml-10"
                                                              *ngIf="item.snils">
                                                            {{ localizations.users.snils }}:
                                                        </span>
                                                        {{item.snils}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="list-separator">&nbsp;</div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="panel panel-shadow m-15 p-15"
                                 *ngIf="users.length == 0">
                                <div class="panel-body text-center">
                                    <div class="icon-object border-primary-600 text-primary">
                                        <i class="icon-info3"></i>
                                    </div>
                                    <h5>
                                        {{ localizations.common.results_missing}}
                                    </h5>
                                    <p class="mg-15">
                                        {{ localizations.users.try_to_adjust_filters }}
                                    </p>
                                    <button class="btn btn-primary-overline mb-10"
                                            (click)="clearFilters()">
                                        {{ localizations.common.reset_filters }}
                                    </button>
                                </div>
                            </div>
                        </evolenta-infinite-scrollbar>
                    </div>
                </div>
                <div class="scroll-panel no-padding no-margin">
                    <div class="alert alert-warning alert-styled-left alert-bordered no-margin-bottom"
                         *ngIf="!user">
                        <button class="close"
                                type="button"
                                data-dismiss="alert">
                            <span>×</span>
                        </button>
                        <span class="text-semibold">
                            {{ localizations.common.attention }}
                        </span>
                        {{ localizations.users.select_user_on_left_for_details}}
                    </div>
                    <app-user-preview [user]="user"
                                      *ngIf="previewMode && user"
                                      (onDelete)="deleteUser()">
                    </app-user-preview>
                </div>
            </div>
        </div>
    </div>
</div>
