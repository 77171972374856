<ng-container *ngIf="editObject">
    <appeal-subject-object-card [object]="editObject" mode="edit"
                                (onApply)="applyObject($event)"
                                (onCancel)="cancelObject()"></appeal-subject-object-card>
</ng-container>

<div *ngIf="!editObject">
    <ng-container *ngFor="let object of subject.objects">
        <appeal-subject-object-card [object]="object" mode="view"
                                    (onEdit)="onEditObject($event)"
                                    (onDelete)="deleteObject($event)"></appeal-subject-object-card>
    </ng-container>
    <button type="button" class="btn btn-primary-overline ml-20 mt-20 mb-20 btn-labeled btn-labeled-right" (click)="addObject()">
        <b>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#0F65D6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 6.5C13 5.94772 12.5523 5.5 12 5.5C11.4477 5.5 11 5.94772 11 6.5V11H6.5C5.94771 11 5.5 11.4477 5.5 12C5.5 12.5523 5.94771 13 6.5 13H11V17.5C11 18.0523 11.4477 18.5 12 18.5C12.5523 18.5 13 18.0523 13 17.5V13H17.5C18.0523 13 18.5 12.5523 18.5 12C18.5 11.4477 18.0523 11 17.5 11H13V6.5Z" fill="white"/>
            </svg>
        </b>
        Добавить объект
    </button>
</div>

