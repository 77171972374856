import { NgModule } from '@angular/core';
import { RolesComponent } from './roles/roles.component';
import { RoleEditComponent } from './roles/role-edit.component';
import { RolesSectionComponent } from './roles/roles-section.component';
import { RoleResolve } from './roles/role.resolve';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { FilterPermissionsPipe } from './roles/permissions.filter';

@NgModule({
    imports: [SharedComponentsModule],
    declarations: [RolesComponent, RoleEditComponent, RolesSectionComponent, FilterPermissionsPipe],
    providers: [RoleResolve],
})
export class RolesModule {}
