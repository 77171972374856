<div class=""
     *ngIf="setting"
     [ngClass]="{'panel panel-shadow m-20': !subjectType}">
    <div class="panel-heading"
         *ngIf="!subjectType">
        <h4 class="panel-title">
            <span>
                {{ getFieldsListActionMessage() }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="save()"
                    *ngIf="allowEdit">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body"
         [ngClass]="{'no-padding': subjectType}">
        <ng-container *ngIf="!subjectType">
            <div class="form-group form-group-lg no-margin-bottom"
                 *ngIf="!subjectType">
                <label>
                    {{ localizations.common.name }}
                    <span class="text-danger">*</span></label>
                <input class="form-control"
                       type="text"
                       [(ngModel)]="setting.name"
                       name="name"
                       #name="ngModel">
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && name.errors">
                    <span *ngIf="name.errors.required">
                        {{ localizations.common.name_field_is_required }}
                    </span>
                </div>
            </div>
            <div class="form-group">
                <ng-container *ngFor="let type of subjectTypes" >
                    <div class="radio-inline"
                         [ngClass]="{'no-padding': subjectType}">
                        <input id="subjectType{{type.code}}"
                               class="radio"
                               type="radio"
                               name="subjectType"
                               [checked]="setting.subjectType && setting.subjectType === type.code"
                               (change)="selectSubjectType(type)">
                        <label for="subjectType{{type.code}}">
                            {{type.name}}
                        </label>
                    </div>
                </ng-container>

                <div class="validation-error-label"
                     *ngIf="isProcessValidate && !setting.subjectType">
                    {{ localizations.standards.info.type_is_required }}
                </div>
            </div>
        </ng-container>

        <div class="page-container"
             *ngIf="fields && fields.length > 0">
            <div class="flex">
                <ng-container *ngFor="let column of fieldsData.tabsInColumns">
                    <div style="flex-wrap: wrap; margin-right: 70px;" *ngIf="checkColumnContentFields(column)">
                        <ng-container *ngFor="let tabCode of column; let fst = first; let idx = index">
                            <div *ngIf="checkGroupContentFields(tabCode)">
                                <fieldset [ngClass]="{'mt-20': !fst}">
                                    <legend class="mb-10 pb-3 text-bold" style="text-transform: none">{{fieldsData.tabs[tabCode]}}</legend>
                                    <ng-container *ngFor="let field of fields">
                                        <div class="checkbox-block" *ngIf="field.tab === tabCode">
                                            <input type="checkbox" class="checkbox" id="field{{field.code}}{{idx}}" [checked]="checkSelectedField(field)" (change)="toggleField(field)">
                                            <label for="field{{field.code}}{{idx}}">{{field.name}}</label>
                                        </div>
                                    </ng-container>
                                </fieldset>
                            </div>
                        </ng-container>
                    </div>

                </ng-container>
            </div>

        </div>
    </div>
</div>




