import { Directive, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

function validatePassportFactory(data) {
    return (c: FormControl) => {
        const passportIssueDate = c.value;
        let error = null;
        if (passportIssueDate) {
            // если указана дата рождения и выбран ДУЛ - паспорт РФ
            if (data && data.birthday && data.documentType && data.documentType[0].id === '29') {
                const age = moment().diff(data.birthday.jsdate, 'years');
                if (
                    age >= 20 &&
                    age < 45 &&
                    moment(passportIssueDate.jsdate) < moment(data.birthday.jsdate).add(20, 'y')
                ) {
                    error = 'Просрочен паспорт по достижении 20 лет';
                } else if (age >= 45 && moment(passportIssueDate.jsdate) < moment(data.birthday.jsdate).add(45, 'y')) {
                    error = 'Просрочен паспорт по достижении 45 лет';
                }
            }
        }
        return error ? { validatePassport: error } : null;
    };
}

@Directive({
    selector: '[validatePassport][ngModel],[validatePassport][formControl]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => PassportValidatorDirective), multi: true }],
})
export class PassportValidatorDirective implements OnInit {
    public validator: ReturnType<typeof validatePassportFactory>;
    @Input() public data;

    public ngOnInit() {
        this.validator = validatePassportFactory(this.data);
    }

    public validate(c: FormControl) {
        return this.validator(c);
    }
}
