<div class="ml-20 mr-20"
     *ngIf="appeal._id && (!processData || !processData.isProcessUpdateData && !processData.isProcessActive)">

    <!-- Доступные действия для дела -->
    <ng-container *ngIf="actions.length">
        <button class="btn bg-info-600 pl-15 pr-15 pt-10 pb-10 width-100 no-white-space mt-20 btn-extra-large text-left"
                *ngFor="let action of actions"
                [disabled]="isProcessingAction"
                [ngClass]="{'flash': isProcessingAction}"
                (click)="executeAction({action: action})">
            {{action.name}}
        </button>
    </ng-container>

    <!-- Доступные действия для услуги дела -->
    <ng-container *ngIf="serviceActions.length && appeal.status.code !== 'complete'">
        <button class="btn bg-info-600 pl-15 pr-15 pt-10 pb-10 width-100 no-white-space mt-20 btn-extra-large text-left hide"
                *ngFor="let action of serviceActions"
                (click)="executeAction({action: action, appealSubservice: appeal.subservice})">
            {{action.name}}
        </button>
    </ng-container>

</div>

