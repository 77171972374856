<div class="panel panel-shadow mt-20">
    <div class="panel-heading">
        <h4 class="panel-title">
            <span>
                {{ localizations.standards.document_type_settings }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled" (click)="apply()" *ngIf="allowEdit">Применить</button>
            <button class="btn btn-danger-overline heading-btn btn-labeled" (click)="cancel()">{{allowEdit ? 'Отменить' : 'Закрыть'}}</button>
        </div>
    </div>

    <div class="panel-body">
        <!-- Основные данные -->
        <dynamic-form
            #mainData
            *ngIf="metaData.groupsAndDocumentsData.documentData.xsd"
            [xsd]="metaData.groupsAndDocumentsData.documentData.xsd"
            [model]="document"
            [saveInMainObject]="true"
            [disabled]="!allowEdit">
        </dynamic-form>

        <ng-container *ngIf="metaData.requests && commonStandardsService.requests.length > 0">
            <p *ngIf="!isProcessSelectRequest">
                <span class="text-muted">
                    {{ localizations.standards.interdepartmental_inquiry }}
                </span>
                <em *ngIf="!document.requestId">
                    {{ localizations.common.not_selected_m }}
                </em>
                <ng-container *ngIf="document.requestId">
                    {{ getRequestById(document.requestId) }}
                </ng-container>
                <span class="text-size-small anchor text-info ml-10"
                      (click)="isProcessSelectRequest = true"
                      *ngIf="allowEdit">
                    {{ localizations.common.select_small }}
                </span>
                <span class="text-size-small anchor text-slate ml-10"
                      (click)="removeDocumentProperty('requestId')"
                      *ngIf="document.requestId && allowEdit">
                    {{ localizations.common.delete_small }}
                </span>
            </p>
            <div class="bg-primary-50 p-20 mb-15"
                 *ngIf="isProcessSelectRequest">
                <p class="text-bold no-margin">
                    {{ localizations.standards.select_query }}
                </p>
                <div class="radio-block"
                     *ngFor="let request of commonStandardsService.requests">
                    <input id="request{{request.auid}}"
                           class="radio"
                           type="radio"
                           name="request"
                           [checked]="document.requestId && document.requestId === request._id"
                           (change)="selectRequest(request)">
                    <label for="request{{request.auid}}">
                        {{request.name}}
                    </label>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="metaData.printForms && commonStandardsService.printForms.length > 0">
            <ng-container *ngIf="!isProcessSelectPrintForms">
                <p>
                    <span class="text-muted">
                        {{ localizations.standards.print_forms }}
                    </span>
                    <em *ngIf="!document.printFormIds || document.printFormIds.length == 0">
                        {{ localizations.common.not_selected_plural }}
                    </em>
                    <span class="text-size-small anchor text-info ml-10"
                          (click)="isProcessSelectPrintForms = true" *ngIf="allowEdit">
                        {{ localizations.common.select_small }}
                    </span>
                </p>
                <table class="table table-xs mb-15"
                       *ngIf="document.printFormIds && document.printFormIds.length > 0">
                    <tbody>
                    <tr *ngFor="let printFormId of document.printFormIds; let idx = index">
                        <td>
                            {{getPrintFormById(printFormId)}}
                        </td>
                        <td class="text-right pt-5 pb-5"
                            *ngIf="allowEdit">
                            <button class="btn btn-default btn-sxs"
                                    (click)="deletePrintForm(idx)">
                                <i class="icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>

            <div class="bg-primary-50 p-20 mb-15"
                 *ngIf="isProcessSelectPrintForms">
                <p class="text-bold no-margin">
                    {{ localizations.standards.select_print_forms }}
                </p>
                <div class="radio-block"
                     *ngFor="let printForm of commonStandardsService.printForms">
                    <input id="printForm{{printForm.auid}}"
                           type="checkbox"
                           class="checkbox"
                           name="selectedPrintForms"
                           [checked]="checkSelectedPrintForm(printForm)"
                           (change)="selectPrintForm(printForm)">
                    <label for="printForm{{printForm.auid}}">
                        {{printForm.title}}
                    </label>
                </div>
                <button class="btn btn-info mt-15"
                        (click)="isProcessSelectPrintForms = false">
                    {{ localizations.common.apply }}
                </button>
            </div>
        </ng-container>

        <div *ngIf="metaData.groupsAndDocumentsData.documentData.allowCreateXsd">
            <span class="text-bold">
                {{ getAdditionalDataFormMessage() }}
            </span>
            <ng-container *ngIf="allowEdit">
                <span class="text-size-small anchor text-info ml-10"
                      (click)="editXsd()"
                      *ngIf="allowEdit || document.xsd">
                    {{!document.xsd ? localizations.common.setup :
                        allowEdit ?
                            localizations.common.change_small :
                            localizations.common.view_small
                    }}
                </span>
                <span class="text-size-small anchor text-slate ml-10"
                      *ngIf="document.xsd && allowEdit"
                      (click)="document.xsd = null">
                    {{ localizations.common.delete_small }}
                </span>
            </ng-container>

            <div class="checkbox-inline no-padding pl-20"
                 *ngIf="commonStandardsService.standard.documentsCommon && commonStandardsService.standard.documentsCommon.xsd">
                <input id="useCommonXsd"
                       class="checkbox"
                       type="checkbox"
                       (change)="document.useCommonXsd = !document.useCommonXsd"
                       [checked]="document.useCommonXsd">
                <label for="useCommonXsd">
                    {{ localizations.standards.general_form_is_used }}
                </label>
            </div>
        </div>
    </div>
</div>
