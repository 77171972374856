<div class="ml-30"
     *ngFor="let element of treeElements | treeSelectFilter:limitArray">
    <div class="media-heading text-semibold mb-10"
         *ngIf="!element.hide">
        <input  type="radio"
                class="radio"
                [value]="element.guid"
                [checked]="element.selected"/>
        <label (click)="toggle(element, treeElements)">
            {{element[nodeName]}}
            <i class="icon-circle-left2 ml-10 text-muted"
               *ngIf="element.selected && element.showBtn"
               [title]="localizations['tree-select'].expand">
            </i>
        </label>
        <div class="comment media-annotation ml-30"
             *ngIf="element.comment">
            {{ element.comment }}
        </div>
    </div>
    <tree-select *ngIf="element.selected && element[subNodes] != null && element[subNodes].length > 0"
                 (onEnd)="onEndSelect()"
                 [treeArray]="treeArray"
                 [subNodes]="subNodes"
                 [nodeName]="nodeName"
                 [additionalFields]="additionalFields"
                 [elements]="element[subNodes]">
    </tree-select>
</div>
