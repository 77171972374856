<!-- Документ запрос -->
<ng-container *ngIf="document.requestId">
    <!-- Процесс получения данных -->
    <div class="alert alert-info"
         *ngIf="!data.envelope || !data.envelope.request">
        <i class="icon-spinner2 spinner"></i>
        {{ localizations['appeals.documents'].data_loading_in_progress }}
    </div>

    <div *ngIf="data.envelope && data.envelope.request">
        <!-- Выбор организации запроса -->
        <div class="mt-10 pt-10 pl-20 pr-20 pb-5 bg-primary-50"
             *ngIf="isProcessSelectOrganization">
            <div class="text-semibold mb-10">
                {{localizations['appeals.documents'].select_responsible_organization }}:
            </div>
            <div class="radio-block"
                 *ngFor="let agency of data.envelope.request.agencies">
                <input id="agency{{agency.organization._id}}"
                       class="radio"
                       type="radio"
                       name="agency"
                       [checked]="data.envelope.agency && data.envelope.agency.organization._id == agency.organization._id"
                       (click)="selectOrganization(agency)">
                <label for="agency{{agency.organization._id}}">
                    {{ agency.organization.name }}
                </label>
            </div>
        </div>

        <!-- Наименование организации запроса -->
        <div>
            <!-- Краткая информация о запросе -->
            <div class="pt-20 pr-20 pl-20 pb-10"
                 *ngIf="data.envelope">
                <span class="text-muted">
                    {{ localizations.subjects.request }}:
                </span>
                <span class="text-muted">№</span>
                <!-- Номер конверта-запроса -->
                <span *ngIf="data.envelope.auid && !accessService.hasAccess([permissions.Envelopes_Search])">
                    {{ data.envelope.auid }}
                </span>
                <a class="anchor text-small-size text-info"
                   routerLink="/selecting/envelopes/edit/{{data.envelope._id}}"
                   *ngIf="data.envelope.auid && accessService.hasAccess([permissions.Envelopes_Search])">
                    {{ data.envelope.auid }}
                </a>
                <span class="ml-5"
                      *ngIf="!data.envelope.auid">
                    n/a
                </span>

                <!-- Статус запроса -->
                <span class="badge badge-xs text-uppercase ml-5"
                      [ngClass]="getEnvelopeStatusProperty('background')">
                    {{ getEnvelopeStatusProperty('name') }}
                </span>

                <!-- Ответственная организация -->
                <span class="text-muted ml-10">
                    {{ localizations.common.organization }}:
                </span>
                <span class="text-semibold"
                      *ngIf="data.envelope.agency">
                    {{ data.envelope.agency.organization.name }}
                </span>
                <span class="anchor text-info cursor-pointer ml-5"
                      *ngIf="!data.envelope.agency"
                      (click)="isProcessSelectOrganization = true">
                    {{ localizations['appeals.documents'].not_selected_fem }}
                </span>
                <span class="anchor cursor-pointer text-info text-size-small ml-5"
                      *ngIf="data.envelope.request.agencies.length > 1 && data.envelope.angency"
                      (click)="isProcessSelectOrganization = true">
                    {{ localizations.common.change_small }}
                </span>

                <!-- Участник в запросе -->
                <ng-container *ngIf="!visibleParticipantsBlock && data.envelopeSubject">
                    <span class="text-muted ml-10">
                        {{ localizations.subjects.participant }}:
                    </span>
                    <span *ngIf="!data.allowEdit">
                        {{ data.envelopeSubject.shortHeader }}
                    </span>
                </ng-container>

                <!-- Дата ответа на запрос -->
                <ng-container *ngIf="data.envelope.status == 'closed' && data.envelope.dateHavingResult">
                    <span class="text-muted text-size-small ml-10">
                        {{ localizations['appeals.documents'].response_date }}:
                    </span>
                    <span>
                        {{ data.envelope.dateHavingResult | date:'shortDate' }}
                        {{ localizations.common.year_small }}
                    </span>
                </ng-container>

                <!-- Плановая дата ответа на запрос -->
                <ng-container *ngIf="data.envelope.status == 'sended' && data.envelope.datePlaneFinish">
                    <span class="text-muted text-size-small ml-10">
                        {{ localizations['appeals.documents'].planned_response_date }}:
                    </span>
                    <span>
                        {{ data.envelope.datePlaneFinish | date:'shortDate' }}
                        {{ localizations.common.year_small }}
                    </span>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Участник дела -->
    <fieldset *ngIf="visibleParticipantsBlock">
        <legend class="pl-20 text-bold no-margin-bottom">
            {{ localizations.common.participants }}
        </legend>
        <common-appeal-document-participants [document]="document"
                                             [appeal]="appeal"
                                             [group]="group">
        </common-appeal-document-participants>
    </fieldset>

    <!-- Документы дела для запроса -->
    <fieldset *ngIf="visibleDocumentsBlock">
        <legend class="pl-20 text-semibold no-margin-bottom">
            {{ localizations['appeals.documents'].appeal_documents }}
        </legend>
        <common-appeal-document-documents [document]="document"
                                          [appeal]="appeal">
        </common-appeal-document-documents>
    </fieldset>


    <!-- Документы дела для запроса -->
    <fieldset>
        <legend class="pl-20 text-bold no-margin-bottom">
            {{ localizations['appeals.documents'].additional_data }}
        </legend>
        <div class="p-20">
            <common-appeal-document-envelope [document]="document"
                                             (afterSaveEnvelope)="onAfterSaveEnvelope()"
                                             (afterSendEnvelope)="onAfterSendEnvelope()"
                                             #documentEnvelope>
            </common-appeal-document-envelope>
        </div>
    </fieldset>


</ng-container>

<!-- Обычный документ -->
<ng-container *ngIf="!document.requestId">

    <!-- Участники -->
    <fieldset class="mt-15"
              *ngIf="visibleParticipantsBlock">
        <legend class="pl-20 text-bold no-margin-bottom">
            {{ localizations.common.participants }}
        </legend>
        <common-appeal-document-participants [document]="document"
                                             [appeal]="appeal"
                                             [group]="group">
        </common-appeal-document-participants>
    </fieldset>

    <!-- Файлы -->
    <fieldset class="mt-15">
        <legend class="pl-20 text-bold no-margin-bottom">
            {{ localizations['appeals.documents'].files }}
        </legend>
        <common-appeal-document-files mode="edit"
                                      [document]="document"
                                      [group]="group"
                                      [appeal]="appeal"
                                      [task]="task">
        </common-appeal-document-files>
    </fieldset>
</ng-container>






