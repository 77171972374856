import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService, RestService, SelectionService, StorageService, ToasterService, TranslateService, UserMessagesService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { TasksService } from './tasks.service';
import { Config } from '../../../common/services/config';
import { EventsService } from '../../events/events/events.service';
import chain from 'lodash-es/chain';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';

@Component({
    selector: 'task-edit',
    templateUrl: 'task-edit.component.html',
    styleUrls: [
        '../../element-edit.css',
    ],
    styles: [
        '.priority-label { width: 80px; }',
        '.date-block { width: 250px; }',
    ],
})
export class TaskEditComponent implements OnInit {
    @Input() public mode = 'default';
    @Output() public onSave = new EventEmitter<any>();

    public task;
    public appeal;
    public camundaTask;
    public mainEntity; // объект основной сущности из которой была инициирована задача

    public activeTab = 'common';
    public tabs = [
        {
            code: 'common',
            name: 'Общие данные',
            active: true,
        },
    ];

    public subTaskTab = {
        code: 'subTasks',
        name: 'Подзадачи',
        active: false,
    };

    public checkListTab = {
        code: 'checkList',
        name: 'Чек-лист',
        active: false,
    };

    public isProcessSave = false;

    public isManualCreation = false;

    public dueDate;
    public followUp;
    public comment;
    public catalogueUser;

    public candidateGroups;
    public candidateUsers;
    public usersForSelect;
    public assignedUser;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public currentUser = this.storage.getItem('user');

    public basePath;
    public moduleBaseUrl;
    public allowEdit = true;
    public localizations;

    public constructor(
        public taskService: TasksService,
        public httpService: HttpService,
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService,
        private rest: RestService,
        private storage: StorageService,
        private selectionService: SelectionService,
        private eventService: EventsService,
        private userMessagesService: UserMessagesService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента: обработка данных, полученных по resolve
     */
    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe(parentUrl => {
            this.basePath = parentUrl[0].path;
        });
        this.route.data.subscribe(
            (response: any) => {
                if (this.selectionService.isProcessSelect) {
                    this.processingSelectQuestions();
                } else {
                    this.appeal = response.resolves.appeal;
                    this.task = response.resolves.task ? response.resolves.task : {};
                    this.camundaTask = response.resolves.camundaTask;
                    this.mainEntity = response.resolves.mainEntity;
                    this.initTabs();
                    this.isManualCreation = !response.resolves.task;
                    if (this.task.subTasks && this.task.subTasks.length > 0) {
                        this.loadSubTasks(this.task.subTasks).then(list => this.task.subTasks = cloneDeep(list),
                        );
                    }
                    if (!this.isManualCreation && !this.camundaTask.mainId && this.eventService.createFromEventId) {
                        this.linkWithEvent();
                    }
                    if (!this.task.priority) {
                        this.task.priority = 50;
                    }
                    this.getTaskUserParams().then(() => {
                        this.getUsers();
                    });
                    if (!this.task.status) {
                        this.task.status = 'ACTIVE';
                    }
                }
                if (this.task.status === 'COMPLETED') {
                    this.allowEdit = false;
                }
            },
        );
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'tasks',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public linkWithEvent() {
        this.task.eventId = this.eventService.createFromEventId;
        this.eventService.createFromEventId = null;
        this.rest.update('events', {_id: this.task.eventId, mainTaskId: this.camundaTask._id, taskId: this.task.id, status: 'process'}).then(() => {
            this.save();
        });
    }

    public initTabs() {
        if (this.task.id) {
            this.tabs.push(this.subTaskTab);
            if (!this.camundaTask.mainId) {
                this.tabs.push(this.checkListTab);
            }
        }
    }

    public processingSelectQuestions() {
        if (this.selectionService.isProcessSelect) {
            this.task = this.selectionService.transferObject;
            if (this.selectionService.additionalData) {
                this.appeal = this.selectionService.additionalData.appeal;
                this.candidateGroups = this.selectionService.additionalData.candidateGroups;
                this.candidateUsers = this.selectionService.additionalData.candidateUsers;
                this.usersForSelect = this.selectionService.additionalData.usersForSelect;
                this.assignedUser = this.selectionService.additionalData.assignedUser;
                this.appeal = this.selectionService.additionalData.appeal;
                this.camundaTask = this.selectionService.additionalData.camundaTask;
            }
            if (this.selectionService.selectedItems.length > 0) {
                this.selectionService.selectedItems.forEach(selectedQuestion => {
                    const find = this.task.questions.find(item => item.id && item.id === selectedQuestion._id);
                    if (!find) {
                        this.task.questions.push({
                            guid: CommonUtilities.GenerateGuid(),
                            id: selectedQuestion._id,
                            text: selectedQuestion.name,
                            answerYes: selectedQuestion.answerYes,
                            answertNo: selectedQuestion.answerNo,
                            answerNotConcidered: selectedQuestion.answerNotConcidered,
                            mandatoryReqs: selectedQuestion.mandatoryReqs,
                            nPA: selectedQuestion.nPA,
                        });
                    }
                });
            }
            this.selectionService.clearData();
            this.initTabs();
            const findTab = this.tabs.find(item => item.code === 'checkList');
            this.activateTab(findTab);
        }
    }

    public getTaskUserParams() {
        const promises = [];
        const processingItems = [];
        if (this.task.candidateGroups && this.task.candidateGroups.length > 0) {
            const params = [
                {
                    andSubConditions: [
                        {
                            field: 'unitId',
                            operator: 'in',
                            value: [null, this.currentOrganization._id],
                        },
                        {
                            field: 'code',
                            operator: 'in',
                            value: this.task.candidateGroups,
                        },
                    ],
                },
            ];
            promises.push(this.rest.search('catalogueRolesMnemonics', {search: {search: params}}));
            processingItems.push('candidateGroups');
        }
        if (this.task.candidateUsers && this.task.candidateUsers.length > 0) {
            const params = [
                {
                    field: 'login',
                    operator: 'in',
                    value: this.task.candidateUsers,
                },
            ];
            promises.push(this.rest.search('users', {search: {search: params}}));
            processingItems.push('candidateUsers');
        }
        if (this.task.assignee) {
            promises.push(this.rest.search('users', {search: {search: [{field: 'login', operator: 'eq', value: this.task.assignee}]}}));
            processingItems.push('assignedUser');
        }

        return Promise.all(promises).then(usersData => {
            processingItems.forEach((processingItem, idx) => {
                if (processingItem === 'candidateGroups' && usersData[idx].length > 0) {
                    this.candidateGroups = usersData[idx];
                }
                if (processingItem === 'candidateUsers' && usersData[idx].length > 0) {
                    this.candidateUsers = usersData[idx];
                }
                if (processingItem === 'assignedUser' && usersData[idx].length > 0) {
                    this.assignedUser = usersData[idx][0];
                }
            });

            return Promise.resolve();
        });
    }

    public getUsers() {
        const params = [
            {
                field: 'sprOrganizations.id',
                operator: 'eq',
                value: this.currentOrganization.id,
            },
        ];
        if (this.candidateGroups && this.candidateGroups.length > 0) {
            params.push({
                field: 'linkRolesMnemonics.roleMnemonic',
                operator: 'in',
                value: this.task.candidateGroups,
            });
            params.push({
                field: 'linkRolesMnemonics.sprOrganization',
                operator: 'eq',
                value: this.currentOrganization.id,
            });
        }
        if (this.candidateUsers && this.candidateUsers.length > 0) {
            params.push({
                field: 'login',
                operator: 'in',
                value: this.task.candidateUsers,
            });
        }
        this.rest.search('users', {search: {search: params}, prj: 'usersList', size: 100}).then(users => {
            const promises = [];
            // users.forEach((user) => {
            //     promises.push(this.httpService.post(Config.server + Config.api + 'business/getAssignedCount', {login: user.login}));
            // });
            Promise.all(promises).then(usersTasksCount => {
                users.forEach((user, idx) => {
                    user.tasksCount = usersTasksCount[idx][user.login];
                });
                this.usersForSelect = users;
            });
        });
    }

    public loadSubTasks(ids) {
        const params = { search: {search: [{field: 'userTasks.id', operator: 'in', value: ids}]}};

        return this.rest.search('camundaBusinessInfo', params).then(
            (tasks: any) => {
               return  chain(tasks)
                   .map(item => item.userTasks)
                   .flatten()
                   .filter(item => ids.includes(item.id))
                   .value();
            },
        );
    }

    public saveChanges() {
        if (this.task.id) {
            this.save();
        }
    }

    public save(isRedirectOnEdit = false) {
        if (this.task.name) {
            this.isProcessSave = true;
            if (this.task.subTasks && this.task.subTasks.length > 0) {
                this.task.subTasks = this.task.subTasks.map(item => item.id);
            }
            if (this.isManualCreation) {
                const url = Config.server + Config.app + Config.api + 'camunda/task/create?unitId=' + this.currentOrganization._id;

                return this.httpService.post(url, this.task).then(
                    (result: any) => {
                        this.rest.find('camundaBusinessInfo', result.camundaBusinessInfoId).then((camundaTask: any) => {
                            this.camundaTask = camundaTask;
                            const task = camundaTask.userTasks.find(item => item.id === result.taskId);
                            this.task = Object.assign(this.task, task);
                            this.isManualCreation = false;
                            if (this.task.assignee) {
                                this.taskService.setTaskAssignee(result.taskId, this.task.assignee).then(() => {
                                    if (this.task.assignee !== this.currentUser.login) {
                                        const message = 'Вам назначена задача "' + this.task.name + '"';
                                        const user = this.usersForSelect.find(item => item.login === this.task.assignee);
                                        if (user) {
                                            this.userMessagesService.createMessage(message, 'task', 'newCustomTask', user, ['tasks', 'edit', this.camundaTask._id, this.task.id], true);
                                        }
                                    }
                                    this.save(true);
                                });
                            } else {
                                this.save(true);
                            }
                        });
                    }, error => {
                        this.isProcessSave = false;
                        this.toaster.error(error);
                    },
                );
            } else {
                let saveData: any = {};
                if (this.appeal) {
                    const tasks = this.appeal.userTasks.map(item => item.id === this.task.id ? this.task : item);
                    saveData = {
                        ...this.appeal,
                        tasks,
                    };
                } else {
                    const userTasks = this.camundaTask.userTasks.map(item => item.id === this.task.id ? this.task : item);
                    saveData = {
                        ...this.camundaTask,
                        userTasks,
                    };
                }
                this.rest.update('camundaBusinessInfo', saveData).then(() => {
                    this.isProcessSave = false;
                    this.toaster.success('Изменения успешно сохранены');
                    if (this.task.subTasks && this.task.subTasks.length > 0) {
                        this.loadSubTasks(this.task.subTasks).then(list => this.task.subTasks = cloneDeep(list),
                        );
                    }
                    if (isRedirectOnEdit) {
                        this.router.navigate([this.moduleBaseUrl, this.basePath, 'edit', this.camundaTask._id, this.task.id]);
                    }
                });
            }
        } else {
            this.toaster.error('Не заполнено наименование задачи!');
        }
    }

    public activateTab(tab) {
        this.activeTab = tab.code;
        if (tab.code === 'checkList' && !this.task.questions) {
            this.task.questions = [];
        }
        this.tabs.forEach(item => {
            item.active = item.code === tab.code;
        });
    }

    public selectUser(user) {
        if (!this.task.users) {
            this.task.users = [];
        }
        const find = this.task.users.find(item => item.id === user._id);
        if (!find) {
            this.task.users.push({
                id: user._id,
                login: user.login,
                name: user.name,
            });
            this.catalogueUser = null;
        } else {
            this.toaster.info('Пользователь ' + user.name + ' уже выбран');
            this.catalogueUser = null;
        }
    }

    public selectQuestions() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.task;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectQuestionsForCustomTask';
        this.selectionService.transferUrl = ['nsi', 'other', 'controlListQuestions'];
        this.selectionService.additionalData = {
            candidateGroups: this.candidateGroups,
            candidateUsers: this.candidateUsers,
            usersForSelect: this.usersForSelect,
            assignedUser: this.assignedUser,
            appeal: this.appeal,
            camundaTask: this.camundaTask,
        };

        this.router.navigate(['nsi', 'other', 'controlListQuestions']);
    }

    public completeCustomTask() {
        return this.httpService
            .post(Config.server + Config.app + Config.api + 'camunda/task/' + this.task.id + '/complete?guid=' + this.camundaTask.guid + '&camundaBusinessInfoId=' + this.camundaTask._id, {})
            .then(data => {
                this.task.status = 'COMPLETED';
                this.toaster.success('Задача успешно завершена');
                this.eventService.checkCompleteAllProcessInEvent(this.task.eventId);
            });
    }
}
