import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    StorageService,
    RestService,
    UserMessagesService,
    NotificationsService as UserNotificationsService,
    UniversalCollectionPagePreviewComponentInterface, TranslateService,
} from '@evolenta/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'notifications-preview',
    templateUrl: 'notifications-preview.component.html',
})
export class NotificationsPreviewComponent implements UniversalCollectionPagePreviewComponentInterface, OnInit {
    @Input() public type = 'show';
    @Input() public element;

    public panels = {
        common: true,
    };
    public activeTab = 'common';
    public tabs = [];
    public moduleBaseUrl;
    public localizations;

    public constructor(
        public userMessagesService: UserMessagesService,
        private route: ActivatedRoute,
        private userNotificationsService: UserNotificationsService,
        private rest: RestService,
        private router: Router,
        private storage: StorageService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        setTimeout(() => {
            this.changeStatus();
        });
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'notifications-preview',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.tabs = [
            {
                code: 'common',
                name: this.localizations.common.basic_data,
                active: true,
            },
        ];
    }

    public changeStatus() {
        if (this.element.status !== 'read') {
            setTimeout(async () => {
                const data = { _id: this.element._id, status: 'read' };

                await this.rest.update('notifications', data);
                this.element.status = 'read';
                this.updateCount();
            }, 4000);
        }
    }

    public goToRoute() {
        const paths = cloneDeep(this.element.routeParams);

        if (this.element.isRelativeRoute) {
            paths.unshift(this.moduleBaseUrl);
        }

        this.router.navigate(paths);
    }

    public checkCanRoute() {
        if (this.element.kind && this.element.parameters && this.element.parameters.length > 0) {
            const camundaTaskId = this.element.parameters.find(item => item.name === 'camundaTaskId');
            const taskId = this.element.parameters.find(item => item.name === 'taskId');
            const appealId = this.element.parameters.find(item => item.name === 'appealId');
            const complaintId = this.element.parameters.find(item => item.name === 'complaintId');
            const mainId = this.element.parameters.find(item => item.name === 'mainId');
            const routingData = this.element.parameters.find(item => item.name === 'routing');

            return ['changeCustomTask', 'newCustomTask'].indexOf(this.element.kind) !== -1 && camundaTaskId && taskId
                || this.element.kind === 'linkProcess' && appealId
                || this.element.type === 'complaints' && complaintId
                || mainId && routingData;
        }

        return false;
    }

    public goToNotificationRoute() {
        const camundaTask = this.element.parameters.find(item => item.name === 'camundaTaskId');
        const camundaTaskId = camundaTask ? camundaTask.value : null;
        const task = this.element.parameters.find(item => item.name === 'taskId');
        const taskId = task ? task.value : null;
        const appeal = this.element.parameters.find(item => item.name === 'appealId');
        const appealId = appeal ? appeal.value : null;
        const complaint = this.element.parameters.find(item => item.name === 'complaintId');
        const complaintId = complaint ? complaint.value : null;
        const mainIdData = this.element.parameters.find(item => item.name === 'mainId');
        const mainId = mainIdData ? mainIdData.value : null;
        const routingData = this.element.parameters.find(item => item.name === 'routing');
        const routingParams = routingData ? routingData.value.split('/') : null;

        if (['changeCustomTask', 'newCustomTask'].indexOf(this.element.kind) !== -1 && camundaTaskId && taskId) {
            this.router.navigate(['supervisor', 'tasks', 'edit', camundaTaskId, taskId]);
        } else if (this.element.kind === 'linkProcess' && appealId) {
            this.router.navigate(['supervisor', 'appeals', 'edit', appealId]);
        } else if (this.element.type === 'complaints' && complaintId) {
            this.router.navigate(['complaints', 'complaints', 'edit', complaintId]);
        } else if (mainId && routingParams) {
            this.router.navigate(routingParams.concat([mainId]));
        }
    }

    public updateCount() {
        const user = this.storage.getItem('user');
        const currentOrganization = this.storage.getItem('currentOrganization');
        const params = {
            search: {
                search: [
                    {
                        orSubConditions: [
                            { field: 'to.login', operator: 'eq', value: user.login },
                            { field: 'to.id', operator: 'eq', value: user._id },
                        ],
                    },
                ],
            },
            size: 20,
            sort: 'dateCreation,DESC',
        };

        if (currentOrganization) {
            this.rest.search('notifications', params).then(
                data => {
                    const ct = this.userNotificationsService.getNotCount(data);
                    this.userNotificationsService.updateNotCount(ct);
                },
            );
        }
    }
}
