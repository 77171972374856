import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonAppealService } from '../../../../services/common-appeal.service';
import { CommonAppealDocumentsComponent } from '../../../appeal-documents/common-appeal-documents.component';

@Component({
    selector: 'common-appeal-entity-documents',
    templateUrl: 'common-appeal-entity-documents.component.html',
})
export class CommonAppealEntityDocumentsComponent implements OnInit {
    @Input() public entity; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public task;
    @Input() public allowEdit = true;
    @Input() public needSaveBeforePrint = false;

    @Output() public isCheckFileSign = new EventEmitter<boolean>();
    @Output() public onNeedSaveBeforePrint = new EventEmitter<boolean>();

    public entityDocumentGroupId;
    public showDocuments = true;
    public showDocumentsBlock = true;
    @ViewChild('appealDocuments', { static: false }) public appealDocumentsComponent: CommonAppealDocumentsComponent;

    public constructor(public appealService: CommonAppealService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        const find = this.appealService.subservice.entities.find((item) => item.code === this.entity.type);
        if (find && find.docGroupId && find.docGroupId !== '0') {
            this.entityDocumentGroupId = find.docGroupId;
        }
        if (this.task) {
            this.isCheckFileSign.emit(this.task.isCheckFileSign);
        } else {
            this.isCheckFileSign.emit(false);
        }
        if (this.entityDocumentGroupId) {
            this.showDocumentsBlock =
                !this.mode ||
                this.mode === 'edit' ||
                this.appealService.appeal.documents.filter(
                    (item) =>
                        item.groupGuid &&
                        item.groupGuid === this.entityDocumentGroupId &&
                        (!item.entityGuid || (this.task && item.entityGuid === this.task.currentEntityGuid))
                ).length > 0;
        } else {
            this.showDocumentsBlock = false;
        }
    }

    public checkAllowEdit(event) {
        this.isCheckFileSign.emit(event);
    }

    public processingSaveBeforePrint(printDocument) {
        this.onNeedSaveBeforePrint.emit(printDocument);
    }

    public processingPrintFormAfterSave(printDocument) {
        if (this.appealDocumentsComponent) {
            this.appealDocumentsComponent.sendPrintForm(printDocument, [], null, true);
        }
    }
}
