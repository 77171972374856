<div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
    <div class="ml-20 navbar-title pt-5">
        <h4>
            <span>
                Ответственные
            </span>
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="panel-main force-full-width">
        <div class="row mt-20 ml-15">

            <!-- Автоматически назначить ответственного по делу (услуге) -->
            <div class="col-md-5">
                <input type="checkbox"
                       id="auto-select"
                       name="setControlOperatorAutomatically"
                       [checked]="isSetControlOperatorAutomatically"
                       (change)="setControlOperatorAutomatically($event)">
                <label class="ml-5">
                    {{ localizations.control_operator.set_automatically }}
                </label>
            </div>

            <!-- Название организации -->
            <div id="organization-block" class="col-md-offset-3 col-md-4">
                <span>
                    {{ localizations.common.organization }}: <b>{{this.currentOrganization.name}}</b>
                </span>
            </div>
        </div>

        <!-- Добавить ответственного по делу -->
        <div class="mt-20">
            <div id="add-button-container">
                <button
                    class="btn btn-info btn-xs btn-labeled btn-labeled-right pt-10 pb-10 ml-20"
                    (click)="showAddControlOperatorModal()">
                    <b class="ml-15">
                        <i class="icon-plus-circle2"></i>
                    </b>
                    <span class="mr-20">
                        {{ localizations.control_operator.add }}
                    </span>
                </button>
        </div>
        </div>

        <!-- Список ответственных -->
        <div class="row mt-10">
            <div class="col-md-12">
                <ng-container *ngFor="let operator of controlOperators">
                    <control-operator [controlOperator]="operator"
                                      (onDelete)="handleControlOperatorDeletedEvent()">
                    </control-operator>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Модальное окно добавления нового ответственного -->
<ng-template #addNewControlOperatorModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{modalTitle}}
        </h4>
        <button type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body no-padding-top">
        <ng-container>
            <!-- Выбор услуги -->
            <div class="mt-20">
                <label for="subservices-selector">
                    {{ localizations.common.service }}:
                </label>
                <catalogue catalogName="subservices"
                           id="subservices-selector"
                           name="subservices"
                           nameField="classificSubserviceName"
                           codeField="standardCode"
                           [params]="serviceSearchParams"
                           [(ngModel)]="subservice"
                           [large]="true">
                </catalogue>
            </div>

            <!-- Выбор пользователя -->
            <div class="mt-10">
                <label for="users-selector">
                    {{ localizations.control_operator.name }}:
                </label>
                <catalogue catalogName="users"
                           id="users-selector"
                           name="users"
                           codeField="login"
                           [params]="userSearchParams"
                           [(ngModel)]="user"
                           [large]="true">
                </catalogue>
            </div>
           </ng-container>
           </div>
           <div class="modal-footer">
           <button type="button"
                class="btn btn-default"
                (click)="addControlOperator()">
                {{ localizations.common.apply }}
           </button>
           <button type="button"
                class="btn btn-default"
                (click)="modalRef.hide()">
               {{ localizations.common.close }}
           </button>
           </div>
           </ng-template>
