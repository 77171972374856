import { NgModule } from '@angular/core';
import { CoreModule } from '@evolenta/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InlineSVGModule } from 'ng-inline-svg';

import { SharedElementsModule } from '../../common/shared-elements.module';
import { CommonAppealSubserviceCardComponent } from './components/subservices/components/subservice-card/common-appeal-subservice-card.component';
import { CommonAppealCommonComponent } from './components/appeal-common/common-appeal-common.component';
import { CommonAppealSubserviceCommonComponent } from './components/subservices/components/subservice-common/common-appeal-subservice-common.component';
import { CommonAppealSubserviceOrganizationComponent } from './components/subservices/components/subservice-organization/common-appeal-subservice-organization.component';
import { CommonAppealSubservicesService } from './services/common-appeal-subservices.service';
import { PrintFormsModule } from '../print-forms/print-forms.module';
import { CommonAppealDocumentsModule } from './common-appeal-documents.module';
import { CommonAppealActionsComponent } from './components/appeal-actions/common-appeal-actions.component';
import { ObjectsService } from './components/objects/objects.service';
import { CommonAppealStatusService } from './services/common-appeal-status.service';
import { CommonStandardsModule } from '../common-standards/common-standards.module';
import { CommonAppealValidateService } from './services/common-appeal-validate.service';
import { CommonAppealEditComponent } from './components/appeal-edit/common-appeal-edit.component';
import { CommonAppealSubservicePreviewComponent } from './components/subservices/components/subservice-preview/common-appeal-subservice-preview.component';
import { CommonAppealSubserviceVariantComponent } from './components/subservices/components/subservice-variant/common-appeal-subservice-variant.component';
import { ProcessingEntitiesForRegistryService } from '../entity-registers/processing-entities-for-registry.service';
import { CommonAppealSubserviceAdditionalDataComponent } from './components/subservices/components/subservice-additional-data/common-appeal-subservice-additional-data.component';
import { CommonAppealSubservicesComponent } from './components/subservices/common-appeal-subservices.component';
import { CommonAppealPreviewComponent } from './components/appeal-preview/common-appeal-preview.component';
import { CommonAppealService } from './services/common-appeal.service';
import { CommonAppealSaveService } from './services/common-appeal-save.service';
import { EnvelopesModule } from '../envelopes/envelopes.module';
import { CommonAppealTabsComponent } from './components/appeal-tabs/common-appeal-tabs.component';
import { CommonAppealsComponent } from './components/appeals/common-appeals.component';
import { CommonAppealPublishErpInfoComponent } from './components/appeal-publish-erp-info/common-appeal-publish-erp-info.component';
import { CommonAppealSendErpInfoComponent } from './components/appeal-send-erp-info/common-appeal-send-erp-info.component';
import { CommonAppealBlocksComponent } from './components/appeal-blocks/common-appeal-blocks.component';
import { CommonAppealsResolve } from './common-appeals.resolve';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { CommonAppealData } from './services/common-appeal.data';
import { CommonAppealSubjectsObjectsModule } from './common-appeal-subjects-objects.module';
import { CommonAppealSubserviceSubjectsComponent } from './components/subservices/components/subservice-subjects/common-appeal-subservice-subjects.component';
import { SubjectsModule } from '../subjects/subjects.module';
import { CommonAppealHeaderComponent } from './components/appeal-header/common-appeal-header.component';
import { ReestrModalComponent } from './components/objects/reestr-modal/reestr-modal.component';
import { ReasonModalComponent } from './components/objects/reason-modal/reason-modal.component';
import { CommonAppealEntitiesModule } from './common-appeal-entities.module';
import { CommonProcessesModule } from '../common-processes/common-processes.module';
import { AppealProcessService } from '../knm/appeals/components/process/appeal-process.service';
import { CommonAppealCheckListsModule } from './common-appeal-check-lists.module';
import { CommonAppealLicensesComponent } from './components/appeal-licenses/common-appeal-licenses.component';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        CoreModule,
        SharedComponentsModule,
        SharedElementsModule,
        EnvelopesModule,
        PrintFormsModule,
        InlineSVGModule,
        CommonProcessesModule,
        CommonStandardsModule,
        CommonAppealDocumentsModule,
        CommonAppealEntitiesModule,
        CommonAppealSubjectsObjectsModule,
        CommonAppealCheckListsModule,
        SubjectsModule,
        DragDropModule,
        BreadcrumbsModule,
    ],
    declarations: [
        CommonAppealCommonComponent,
        CommonAppealEditComponent,
        CommonAppealsComponent,
        CommonAppealSubserviceAdditionalDataComponent,
        CommonAppealSubserviceCardComponent,
        CommonAppealSubserviceOrganizationComponent,
        CommonAppealSubservicePreviewComponent,
        CommonAppealSubservicesComponent,
        CommonAppealSubserviceSubjectsComponent,
        CommonAppealSubserviceVariantComponent,
        CommonAppealSubserviceCommonComponent,
        ReasonModalComponent,
        ReestrModalComponent,
        CommonAppealPreviewComponent,
        CommonAppealPreviewComponent,
        CommonAppealSendErpInfoComponent,
        CommonAppealPublishErpInfoComponent,
        CommonAppealActionsComponent,
        CommonAppealBlocksComponent,
        CommonAppealTabsComponent,
        CommonAppealHeaderComponent,
        CommonAppealLicensesComponent,
    ],
    providers: [
        CommonAppealSaveService,
        CommonAppealsResolve,
        CommonAppealStatusService,
        CommonAppealSubservicesService,
        CommonAppealValidateService,
        ObjectsService,
        CommonAppealData,
        ProcessingEntitiesForRegistryService,
        CommonAppealPreviewComponent,
        CommonAppealService,
        AppealProcessService,
    ],
    exports: [
        CommonAppealPreviewComponent,
    ],
})
export class CommonAppealsModule {
}
