import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessService, TranslateService } from '@evolenta/core';
import { Permission } from '../../../../../../../common/services/permission';
import { AppealService } from '../../../../appeal.service';
import { AppealData } from '../../../../appeal.data';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-card',
    templateUrl: 'appeal-entity-card.component.html',
})
export class AppealEntityCardComponent implements OnInit {
    @Input() public entity; // обрабатываемое сведение
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public appealSubservice; // описания услуг на основе которых формируется дело
    @Input() public task; // задача процесса
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public onCancel = new EventEmitter<boolean>();

    public editTabs = [];
    public permissions = Permission; // Набор прав системы

    public isProcessSelectEntityType = false;
    public dataEntityTypes = AppealData.entityTypes;

    public entityTypes = [];

    public availableForAddEntityTypes = [];

    public uniqueEntities = ['order', 'checkStatement'];

    public localizations;

    public constructor(
        public accessService: AccessService,
        private appealService: AppealService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.entityTypes = _.values(this.dataEntityTypes);
        if (this.task && this.task.params.entities && this.task.params.entities.length > 0) {
            this.task.params.entities.forEach(item => {
                this.availableForAddEntityTypes.push({
                    code: item.code,
                    name: item.name,
                });
            });
        } else {
            if (!(this.appealService.subservice._id === this.appealSubservice.id  && this.appealService.subservice.entities && this.appealService.subservice.entities.length > 0)) {
                return;
            }

            this.appealService.subservice.entities.forEach(entity => {
                const find = AppealData.entityTypes[entity.code];
                if (find) {
                    this.availableForAddEntityTypes.push({
                        code: entity.code,
                        name: entity.name,
                    });
                }
            });
        }

        this.availableForAddEntityTypes = _.orderBy(this.availableForAddEntityTypes, ['name'], ['asc']);

        if (!this.appeal.entities) {
            this.appeal.entities = [];
        }
        this.uniqueEntities.forEach(uniqueEntity => {
            const findEntityType = this.appeal.subservice.entities.find(item => item.type === uniqueEntity);
            if (findEntityType) {
                this.availableForAddEntityTypes = this.availableForAddEntityTypes.filter(item => item.code !== uniqueEntity);
            }
        });

        if (!this.entity.type && this.availableForAddEntityTypes.length) {
            this.isProcessSelectEntityType = true;
        }
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.entities',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public selectEntityType(type) {
        this.entity.type = type.code;
        this.entity.name = type.name;
        this.isProcessSelectEntityType = false;
    }

    public applyEntity() {
        this.onApply.emit(this.entity);
    }

    public editEntity(entity) {
        this.onEdit.emit({entity: entity, appealSubservice: this.appealSubservice});
    }

    public deleteEntity(entity) {
        this.onDelete.emit(entity);
    }

    public cancelEntity() {
        this.onCancel.emit(true);
    }
}
