import { Injectable } from '@angular/core';
import { FiltersService, HttpService, StorageService } from '@evolenta/core';
import { GantScheduleData } from '../schedule/components/gant/models/gant-schedule-data';
import { Config } from '../../../common/services/config';
import value from 'lodash-es/values';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { RsoService } from '../../../common/services/rso.service';
import { CommonAppealEntitiesService } from '../../common-appeals/components/appeal-entities/common-appeal-entities.service';

@Injectable()
export class TasksService {
    public statuses = [
        {
            code: 'ACTIVE',
            name: 'Активная',
            shortName: 'Активная',
            theme: 'primary',
            labelText: 'ЧР',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-400',
            text: 'text-primary-400',
        },
        {
            code: 'COMPLETED',
            name: 'Завершена',
            shortName: 'Завершена',
            theme: 'success',
            labelText: 'ЧР',
            label: 'bg-primary-50 text-success',
            background: 'bg-success-300',
            border: 'border-success-400',
            backgroundLite: 'bg-success-50',
            borderTop: 'border-top-success-400',
            borderLeft: 'border-left-success-400',
            text: 'text-success-400',
        },
    ];

    public priorities = [
        {
            priority: 25,
            code: 'low',
            name: 'Низкий',
            shortName: 'Низкий',
            theme: 'purple',
            labelText: 'ЧР',
            label: 'bg-purple-50 text-purple',
            background: 'bg-purple-300',
            border: 'border-purple-400',
            backgroundLite: 'bg-purple-50',
            borderTop: 'border-top-purple-400',
            borderLeft: 'border-left-purple-400',
            text: 'text-purple-400',
        },
        {
            priority: 50,
            code: 'normal',
            name: 'Обычный',
            shortName: 'Обычный',
            theme: 'primary',
            labelText: 'ЧР',
            label: 'bg-primary-50 text-primary',
            background: 'bg-primary-300',
            border: 'border-primary-400',
            backgroundLite: 'bg-primary-50',
            borderTop: 'border-top-primary-400',
            borderLeft: 'border-left-primary-400',
            text: 'text-primary-400',
        },
        {
            priority: 75,
            code: 'important',
            name: 'Важный',
            shortName: 'Важный',
            theme: 'orange',
            labelText: 'ЧР',
            label: 'bg-orange-50 text-orange',
            background: 'bg-orange-300',
            border: 'border-orange-400',
            backgroundLite: 'bg-orange-50',
            borderTop: 'border-top-orange-400',
            borderLeft: 'border-left-orange-400',
            text: 'text-orange-400',
        },
        {
            priority: 100,
            code: 'critical',
            name: 'Критичный',
            shortName: 'Критичный',
            theme: 'danger',
            labelText: 'ЧР',
            label: 'bg-danger-50 text-danger',
            background: 'bg-danger-300',
            border: 'border-danger-400',
            backgroundLite: 'bg-danger-50',
            borderTop: 'border-top-danger-400',
            borderLeft: 'border-left-danger-400',
            text: 'text-danger-400',
        },
    ];

    // Временные интервалы
    public period = [
        {
            code: 'today',
            name: 'Сегодня',
        },
        {
            code: 'week',
            name: 'Неделя',
        },
        {
            code: 'month',
            name: 'Месяц',
        },
        {
            code: 'interval',
            name: 'Интервал',
        },
    ];

    // Типы дат дела
    public diffDates = [
        {
            id: 'userTasks.due',
            text: 'Дата завершения',
        },
        {
            id: 'userTasks.followUp',
            text: 'Дата начала',
        },
        {
            id: 'userTasks.created',
            text: 'Дата создания',
        },
    ];

    public filtersPanelItems = [
        {
            name: 'Наименование',
            shortName: 'Наименование',
            filters: [
                {
                    code: 'taskName',
                    fields: ['userTasks.name'],
                    type: 'input',
                    shortName: 'Наименование',
                    placeholder: 'Наименование',
                },
            ],
        },
        {
            name: 'Ответственный исполнитель',
            filters: [
                {
                    code: 'taskAssignee',
                    fields: ['userTasks.assignee'],
                    type: 'input',
                    shortName: 'Ответственный',
                    placeholder: 'Логин',
                },
                {
                    type: 'checkbox',
                    code: 'notAssignee',
                    fields: ['userTasks.assignee'],
                    useValue: null,
                    placeholder: 'Не назначена',
                },
            ],
        },
        {
            name: 'Период',
            filters: [
                {
                    code: 'taskPeriod',
                    type: 'period',
                    dates: this.diffDates,
                    itemName: 'text',
                    itemValue: 'id',
                    items: [
                        {
                            code: 'today',
                            name: 'Сегодня',
                        },
                        {
                            code: 'week',
                            name: 'Неделя',
                        },
                        {
                            code: 'month',
                            name: 'Месяц',
                        },
                        {
                            code: 'interval',
                            name: 'Интервал',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Вид задачи',
            filters: [
                {
                    code: 'taskType',
                    fields: ['mainId'],
                    isInParentComponentProcessing: true,
                    type: 'radioboxes',
                    items: [
                        { code: 'processing', name: 'Процессные' },
                        {
                            code: 'missions',
                            name: 'Поручения',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Актуальность',
            filters: [
                {
                    type: 'checkboxes',
                    isInParentComponentProcessing: true,
                    code: 'actualType',
                    items: [
                        {
                            name: 'Просрочено',
                            code: 'expired',
                            placeholder: 'Истек срок выполнения',
                        },
                        {
                            name: 'Истекает',
                            code: 'termEnding',
                            placeholder: 'Истекает срок выполнения',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Приоритет',
            filters: [
                {
                    code: 'taskPriority',
                    fields: ['userTasks.priority'],
                    type: 'checkboxes',
                    items: this.priorities,
                    hiddenProperty: 'isHidden',
                    itemStyleProperty: 'background',
                    itemName: 'name',
                    itemValue: 'priority',
                    labelWidth: 140,
                },
            ],
        },
        {
            name: 'Статус',
            filters: [
                {
                    code: 'taskStatus',
                    fields: ['userTasks.status'],
                    type: 'checkboxes',
                    items: this.statuses,
                    hiddenProperty: 'isHidden',
                    itemStyleProperty: 'background',
                    itemName: 'name',
                    itemValue: 'code',
                    labelWidth: 140,
                },
            ],
        },
    ];

    public priorityOptions = [
        { id: '25', text: 'Низкий' },
        { id: '50', text: 'Обычный' },
        { id: '75', text: 'Важный' },
        { id: '100', text: 'Критичный' },
    ];

    public users: Promise<any[]> | null = null;

    public constructor(
        private filtersService: FiltersService,
        private storage: StorageService,
        private httpService: HttpService,
        private rsoService: RsoService,
        private commonAppealEntitiesService: CommonAppealEntitiesService
    ) {}

    public getStatusByProperty(priority, property) {
        priority = priority ? priority : 50;
        const status = this.priorities.find((item) => item.priority === priority);
        return status ? status[property] : '';
    }

    public processingTasks(list, filtered = true) {
        let result = [];
        list.forEach((data) => {
            const activeTasks = value(data.userTasks).map((item) => ({
                ...item,
                mainId: data.mainId,
                camundaId: data._id,
                priority: item.priority ? parseInt(item.priority, 10) : 50,
            }));
            if (activeTasks.length > 0) {
                result = result.concat(activeTasks);
            }
        });

        // Учет настроенных фильтров для финального тестирования
        const tasksFilters = this.filtersService.savedFilters['tasksFilter']
            ? this.filtersService.savedFilters['tasksFilter'][0].data
            : null;

        if (tasksFilters && filtered) {
            tasksFilters.forEach((taskBlock) => {
                if (taskBlock.filters) {
                    const filter = taskBlock.filters[0];
                    if (filter.value && filter.code) {
                        // Наименование
                        if (filter.code === 'filterName') {
                            result = result.filter(
                                (task) =>
                                    task.name && task.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                            );
                        }
                        // Ответственный
                        if (
                            filter.code === 'taskAssignee' &&
                            !this.rsoService.isRsoOperatorUser() &&
                            !this.rsoService.isRsoAnySupervisorUser()
                        ) {
                            result = result.filter(
                                (task) =>
                                    task.assignee &&
                                    task.assignee.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                            );
                        }
                        // Задача не назначена
                        if (filter.code === 'notAssignee') {
                            result = result.filter((task) => !task.assignee);
                        }
                        // Статус
                        if (filter.code === 'taskStatus' && filter.value.length > 0) {
                            const statusCodes = filter.value.map((item) => item.code);
                            result = result.filter((task) => statusCodes.indexOf(task.status) !== -1);
                        }
                        // Приоритет
                        if (filter.code === 'taskPriority' && filter.value.length > 0) {
                            const selectedPriority = filter.value.map((item) => item.priority);
                            result = result.filter(
                                (task) => task.priority && selectedPriority.indexOf(task.priority) !== -1
                            );
                        }
                        // Актуальность
                        if (filter.code === 'actualType' && filter.value.length > 0) {
                            const findExpired = filter.value.find((item) => item.code === 'expired');
                            const findTermEnding = filter.value.find((item) => item.code === 'termEnding');
                            result = result.filter((task) => {
                                return (
                                    (!findExpired ||
                                        (task.due &&
                                            findExpired &&
                                            moment(task.due).endOf('day') <= moment().endOf('day'))) &&
                                    (!findTermEnding ||
                                        (task.due &&
                                            findTermEnding &&
                                            moment(task.due).endOf('day') <= moment().endOf('day') &&
                                            moment(task.due).startOf('day') >= moment().add(-2, 'days').startOf('day')))
                                );
                            });
                        }
                        // Период
                        if (filter.code === 'taskPeriod') {
                            if (!filter.periodType) {
                                filter.periodType = filter.dates[0];
                            }
                            const checkedDate = filter.periodType.id.replace('userTasks.', '');
                            if (filter.value.code === 'today') {
                                result = result.filter(
                                    (task) =>
                                        task[checkedDate] &&
                                        moment().startOf('day') < moment(task[checkedDate]) &&
                                        moment().endOf('day') > moment(task[checkedDate])
                                );
                            }
                            if (filter.value.code === 'week') {
                                result = result.filter(
                                    (task) =>
                                        task[checkedDate] &&
                                        moment().add(-7, 'day').startOf('day') < moment(task[checkedDate]) &&
                                        moment().endOf('day') > moment(task[checkedDate])
                                );
                            }
                            if (filter.value.code === 'month') {
                                result = result.filter(
                                    (task) =>
                                        task[checkedDate] &&
                                        moment().add(-1, 'month').startOf('day') < moment(task[checkedDate]) &&
                                        moment().endOf('day') > moment(task[checkedDate])
                                );
                            }
                            if (filter.value.code === 'interval' && (filter.intervalStart || filter.intervalEnd)) {
                                result = result.filter((task) => {
                                    return (
                                        task[checkedDate] &&
                                        (!filter.intervalStart ||
                                            (filter.intervalStart &&
                                                moment(filter.intervalStart.jsdate).startOf('day') <
                                                    moment(task[checkedDate]))) &&
                                        (!filter.intervalEnd ||
                                            (filter.intervalEnd &&
                                                moment(filter.intervalEnd.jsdate).endOf('day') >
                                                    moment(task[checkedDate])))
                                    );
                                });
                            }
                        }
                    }
                }
            });
        } else {
            // Если не заданы фильтры, то считаем, что отображаются только активные задачи
            result = result.filter((task) => task.status === 'ACTIVE');
        }
        return result;
    }

    public getBackgroundColorByPriority(priority) {
        switch (parseInt(priority, 10)) {
            case 25:
                return '#9575CD';
            case 50:
                return '#64B5F6';
            case 75:
                return '#FFB74D';
            case 100:
                return '#E57373';
            default:
                return '#9575CD';
        }
    }

    public processingCalendar(data) {
        const list = _.cloneDeep(this.processingTasks(data));
        return list
            .filter((item) => item.due && item.followUp)
            .map((item, idx) => {
                const start = moment(item.followUp).format('YYYY-MM-DD');
                const end = moment(item.due).format('YYYY-MM-DD');
                const result: any = {
                    ...item,
                    extendedProps: {
                        id: item.id,
                        mainId: item.mainId,
                        camundaId: item.camundaId,
                    },
                    title: item.name,
                    color: this.getBackgroundColorByPriority(item.priority),
                };
                if (start === end) {
                    result.date = start;
                } else {
                    result.start = start;
                    result.end = end;
                }
                return result;
            });
    }

    public processingSchedule(appeals, users, currentMonth): GantScheduleData {
        const list = _.cloneDeep(this.processingTasks(appeals, false));

        return {
            groups: list
                .filter((task) => task.due && task.followUp)
                .filter((task, index, array) => array.findIndex((t) => t.assignee === task.assignee) === index)
                .map((task) => ({
                    label:
                        task.assignee && users.find((u) => u.login === task.assignee)
                            ? users.find((u) => u.login === task.assignee).name
                            : null,
                    expanded: true,
                    tasks: list
                        .filter((item) => item.due && item.followUp && item.assignee === task.assignee)
                        .map((item, index) => {
                            let start = parseInt(moment(item.followUp).format('D'), 10);
                            let end = parseInt(moment(item.due).format('D'), 10);
                            const endMonth = parseInt(moment(item.due).format('M'), 10);
                            const startMonth = parseInt(moment(item.followUp).format('M'), 10);

                            start = endMonth === startMonth ? start : startMonth < currentMonth ? 1 : start;

                            end =
                                endMonth === startMonth
                                    ? end === start
                                        ? end
                                        : end + 1
                                    : endMonth > currentMonth
                                      ? moment(item.followUp).daysInMonth()
                                      : end + 1;
                            return {
                                label: item.name,
                                start,
                                end,
                                color: this.getBackgroundColorByPriority(item.priority),
                                style: {
                                    background: this.getBackgroundColorByPriority(item.priority),
                                    'grid-column': start + '/' + end,
                                },
                                sourceObject: item,
                            };
                        }),
                })),
        };
    }

    public getSchedulerGantParams(date) {
        const momentDate = date ? moment(date) : moment();
        const startOfMonth = momentDate.startOf('month').format('YYYY-MM-DDTHH:mm:ssZZ');
        const endOfMonth = momentDate.endOf('month').format('YYYY-MM-DDTHH:mm:ssZZ');
        const currentOrganization = this.storage.getItem('currentOrganization');
        return [
            {
                field: 'userTasks',
                operator: 'neq',
                value: [],
            },
            {
                field: 'unit.id',
                operator: 'eq',
                value: currentOrganization._id,
            },
            {
                orSubConditions: [
                    {
                        andSubConditions: [
                            {
                                field: 'userTasks.due',
                                operator: 'le',
                                value: endOfMonth,
                            },
                            {
                                field: 'userTasks.due',
                                operator: 'ge',
                                value: startOfMonth,
                            },
                        ],
                    },
                    {
                        andSubConditions: [
                            {
                                field: 'userTasks.followUp',
                                operator: 'le',
                                value: endOfMonth,
                            },
                            {
                                field: 'userTasks.followUp',
                                operator: 'ge',
                                value: startOfMonth,
                            },
                        ],
                    },
                ],
            },
        ];
    }

    public getDaysOfMonth(date) {
        moment.locale('ru');

        const daysInMonth = [];

        const monthDate = moment(date).startOf('month');

        _.times(monthDate.daysInMonth(), (n) => {
            daysInMonth.push({
                dayNumber: monthDate.format('DD'),
                dayName: monthDate.format('ddd'),
            });
            monthDate.add(1, 'day');
        });
        return daysInMonth;
    }

    public setTaskAssignee(taskId, userLogin) {
        return this.httpService.post(
            Config.server + Config.api + 'camunda/task/' + taskId + '/assignee?userId=' + userLogin,
            {}
        );
    }

    public async getUsers() {
        if (!this.users) {
            this.users = await this.commonAppealEntitiesService.getUsers();
        }
        return this.users;
    }
}
