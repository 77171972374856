import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StorageService, ToasterService } from '@evolenta/core';
import { AppealEntityProceedDecree } from './models/appeal-entity-proceed-decree';
import { AppealEntitiesService } from '../../appeal-entities.service';
import { AppealEntityProceedProtocol } from '../entity-proceed-protocol/models/appeal-entity-proceed-protocol';
import { AppealEntityProceedInitiation } from '../entity-proceed-initiation/models/appeal-entity-proceed-initiation';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-proceed-decree',
    templateUrl: 'appeal-entity-proceed-decree.component.html',
})
export class AppealEntityProceedDecreeComponent implements OnInit {
    @Input() public entity: AppealEntityProceedDecree;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public currentUser = this.storage.getItem('user');
    public proceedProtocol: AppealEntityProceedProtocol;
    public proceedInitiation: AppealEntityProceedInitiation;

    public isProcessValidate = false;
    public employee = {};
    public coap = {};

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public storage: StorageService,
        private toaster: ToasterService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.entity.date) {
            this.entity.date = this.appeal.dateRegister;
        }
        if (!this.entity.employee) {
            this.entity.employee = {
                name: [{ id: this.currentUser.id, text: this.currentUser.name }],
                position: this.currentUser.position ? this.currentUser.position : '',
            };
        }
        this.proceedProtocol = this.appealEntitiesService.findEntity(this.appeal, 'proceedProtocol');
        if (this.proceedProtocol) {
            if (!this.entity.individual && this.proceedProtocol.individual) {
                this.entity.individual = this.proceedProtocol.individual;
            }
            if (!this.entity.documentNumber && this.proceedProtocol.documentNumber) {
                this.entity.documentNumber = this.proceedProtocol.documentNumber;
            }
            if (!this.entity.documentSeries && this.proceedProtocol.documentSeries) {
                this.entity.documentSeries = this.proceedProtocol.documentSeries;
            }
            if (!this.entity.birthday && this.proceedProtocol.birthday) {
                this.entity.birthday = this.proceedProtocol.birthday;
            }
            if (!this.entity.placeBirth && this.proceedProtocol.placeBirth) {
                this.entity.placeBirth = this.proceedProtocol.placeBirth;
            }
            if (!this.entity.locationAddress && this.proceedProtocol.locationAddress) {
                this.entity.locationAddress = this.proceedProtocol.locationAddress;
            }
            if (!this.entity.placeWork && this.proceedProtocol.placeWork) {
                this.entity.placeWork = this.proceedProtocol.placeWork;
            }
            if (!this.entity.position && this.proceedProtocol.position) {
                this.entity.position = this.proceedProtocol.position;
            }
            if (!this.entity.reviewDate) {
                this.entity.reviewDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            }
        }
    }

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidEmployee = this.validateEmployee();
        const isValidCoap = this.validateCoap();

        return isValidForm && isValidEmployee && isValidCoap;
    }

    public validateEmployee(): boolean {
        const isValidName = !!_.get(this.entity.employee, 'name.0.id', null);
        const isValidPosition = !!_.get(this.entity.employee, 'position', null);
        const isValid = isValidName && isValidPosition;

        this.employee = isValid
            ? { value: this.entity.employee }
            : {
                  value: this.entity.employee,
                  errors: { nameRequired: !isValidName, positionRequired: !isValidPosition },
              };

        return isValid;
    }

    public validateCoap(): boolean {
        const isValid = this.entity.coap.every((c) => !!_.get(c, 'value', null));

        this.coap = isValid ? { value: this.entity.coap } : { value: this.entity.coap, errors: { required: true } };

        return isValid;
    }
}
