import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    RestService,
    UniversalCollectionPageResolveDataInterface,
    UniversalCollectionPageResolveInterface,
} from '@evolenta/core';

@Injectable()
export class PrintFormsResolve implements UniversalCollectionPageResolveInterface {

    public constructor(
        private restService: RestService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Promise<UniversalCollectionPageResolveDataInterface> {
        const printFormId = route.params['printFormId'];
        const isCreateAction = route.url.some(item => item.path === 'create');

        const promises = [];
        if (isCreateAction) {
            // Создание
            promises.push({});
        } else if (printFormId) {
            // Редактирование
            promises.push(this.restService.find('printForms', printFormId));
        } else {
            promises.push(this.restService.search('printForms', { sort: 'dateLastModification,DESC' }));
        }

        promises.push(this.restService.search('cataloguePrintFormKinds'));
        promises.push(this.restService.search('cataloguePrintFormTypes'));

        return Promise.all(promises).then(response => {
            return {
                data: response[0],
                kinds: response[1],
                types: response[2],
            };
        });
    }
}
