<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn"
                    title="Редактировать"
                    *ngIf="mode == 'view'"
                    (click)="edit()">
                <strong [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></strong>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit' && allowEdit"
                    (click)="apply()">Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase"
                    *ngIf="mode == 'edit'"
                    (click)="cancel()">Закрыть
            </button>
        </div>
    </div>
    <div
        [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line no-padding-top no-padding-bottom': task && mode == 'view'}">
        <!-- Карточка предпросмотра -->
        <div class="pt-15 pb-15" [ngClass]="{'card-preview': task, 'pl-20 pr-20': !task}" *ngIf="mode == 'view'">
            {{entity.name}}
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right pt-15 pb-15" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" [title]="allowEdit ? 'Редактировать' : 'Посмотреть'">
                <i class="icon-pencil" *ngIf="allowEdit"></i>
                <i class="icon-eye" *ngIf="!allowEdit"></i>
            </button>

            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div [ngClass]="{'hide': mode !== 'edit'}">
            <fieldset>
                <legend class="text-bold pl-20 no-margin-bottom cursor-pointer text-slate pt-10" *ngIf="mode == 'edit'">
                    ОСНОВНЫЕ ДАННЫЕ
                    <a class="control-arrow" (click)="showCommonData = !showCommonData">
                        <i [ngClass]="{'icon-arrow-down32': showCommonData, 'icon-arrow-left32': !showCommonData}"></i>
                    </a>
                </legend>
                <div class="pt-15 pl-20 pr-20 mb-10" [hidden]="!showCommonData">
                    <dynamic-form
                        #data
                        *ngIf="entity.xsd"
                        [xsd]="entity.xsd"
                        [model]="entity"
                        [textView]="mode != 'edit' || !allowEdit"
                        [saveInMainObject]="true"
                        [externalData]="externalData"
                        [initHandlers]="handlers"
                        [useXsdService]="appealService.useXsdService"
                        (afterChangeForm)="runHandlers(handlers)">
                        [disabled]="!allowEdit">
                    </dynamic-form>
                </div>
            </fieldset>
        </div>
    </div>

    <!-- Группы документов, привязанные к сведению -->
    <common-appeal-entity-documents [entity]="entity"
                                    [task]="task ? task : {currentEntityGuid: entity.guid}"
                                    [needSaveBeforePrint]="true"
                                    [allowEdit]="mode === 'edit'"
                                    [mode]="mode"
                                    (onNeedSaveBeforePrint)="processingSaveBeforePrint($event)"
                                    #entityDocuments></common-appeal-entity-documents>
</div>
