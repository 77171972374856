import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { InlineSVGModule } from 'ng-inline-svg';
import { SharedComponentsModule } from '../../common/shared-components.module';
import { TasksComponent } from './tasks/tasks.component';
import { TaskEditComponent } from './tasks/task-edit.component';
import { TasksService } from './tasks/tasks.service';
import { TasksResolve } from './tasks/tasks.resolve';
import { ScheduleComponent } from './schedule/schedule.component';
import { GantScheduleComponent } from './schedule/components/gant/gant-schedule.component';
import { ScheduleEditTaskComponent } from './schedule/components/edit-task/schedule-edit-task.component';
import { TasksPreviewComponent } from './tasks/components/tasks-preview/tasks-preview.component';
import { SubTasksComponent } from './tasks/components/sub-tasks/sub-tasks.component';
import { TaskFormComponent } from './tasks/components/task-form/task-form.component';
import { SubTaskCardComponent } from './tasks/components/sub-tasks/sub-task-card/sub-task-card.component';
import { TaskCheckListComponent } from './tasks/components/task-check-list/task-check-list.component';
import { ScheduleCalendarComponent } from './schedule/components/calendar/schedule-calendar.component';
import { UsersModule } from '../users/users.module';
import { BreadcrumbsModule } from '@evolenta/breadcrumbs';

@NgModule({
    imports: [
        RouterModule,
        SharedComponentsModule,
        FullCalendarModule,
        UsersModule,
        InlineSVGModule,
        BreadcrumbsModule,
    ],
    declarations: [
        TasksComponent,
        TaskEditComponent,
        ScheduleComponent,
        GantScheduleComponent,
        ScheduleEditTaskComponent,
        TasksPreviewComponent,
        SubTasksComponent,
        TaskFormComponent,
        SubTaskCardComponent,
        TaskCheckListComponent,
        ScheduleCalendarComponent,
    ],
    exports: [
        TaskEditComponent,
        TasksPreviewComponent,
    ],
    providers: [
        TasksResolve,
        TasksService,
    ],
})
export class TasksModule {
}
