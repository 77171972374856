<div class="panel panel-shadow m-20"
     *ngIf="!editedTab && !editedSignTab">
    <div class="panel-body">
        <ul class="info-row active mb-20 no-margin-left no-margin-right">
            <li *ngFor="let tab of tabs"
                [ngClass]="{'active': activeTab && activeTab == tab.code}"
                (click)="activeTab = tab.code">
                {{tab.name}}
            </li>
        </ul>

        <!-- Настройка бизнес-процесса -->
        <ng-container *ngIf="activeTab !== 'additionalData' && activeTab !== 'sign'">
            <ng-container *ngFor="let process of processes">
                <organization-process [organization]="organization"
                                      [processCode]="process.code"
                                      [organizationField]="'processesSettings'"
                                      [process]="processesData[process.code]"
                                      *ngIf="activeTab == process.code"
                                      (onSelectProcess)="selectProcess($event)"
                                      (onChangeProcess)="changeProcess($event, process.code)"
                                      (onSaveOrganization)="onSaveOrganization.emit($event)"></organization-process>
            </ng-container>
        </ng-container>

        <!-- Вкладки с доп.данными -->
        <ng-container *ngIf="activeTab && activeTab == 'additionalData'">

            <ng-container *ngIf="!editedTab">
                <table class="table table-xs"
                       *ngIf="tabs.length > 0">
                    <thead>
                        <tr>
                            <th class="text-size-small text-bold">
                                {{ localizations.common.code }}
                            </th>
                            <th class="text-size-small text-bold">
                                {{ localizations.common.name }}
                            </th>
                            <th class="text-size-small text-bold"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let tab of organization.processesSettings.additionalDataTabs">
                        <td>
                            {{tab.code}}
                        </td>
                        <td>
                            {{tab.name}}
                        </td>
                        <td class="text-right">
                            <button class="btn btn-sxs btn-default"
                                    (click)="editTab(tab)">
                                <i class="icon-pencil"></i>
                            </button>
                            <button class="btn btn-sxs btn-default"
                                    (click)="deleteTab(tab)">
                                <i class="icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <button type="button"
                        class="btn btn-primary-overline heading-btn btn-labeled btn-labeled-right mt-20"
                        [title]="localizations.organizations.add_request"
                        (click)="addTab()">
                    <b><i inlineSVG="assets/icon-plus-round.svg"></i></b>
                    {{ localizations.organizations.add_block }}
                </button>
            </ng-container>

        </ng-container>

        <!-- Подписание -->
        <ng-container *ngIf="activeTab == 'sign'">
            <table class="table table-xs">
                <thead>
                <tr>
                    <th class="text-size-small text-bold">
                        {{ localizations.common.name }}
                    </th>
                    <th class="text-size-small text-bold"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tab of organization.processesSettings.signData">
                    <td>{{tab.name}}</td>
                    <td class="text-right">
                        <button class="btn btn-sxs btn-default"
                                (click)="editSignData(tab)">
                            <i class="icon-pencil"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>

<!-- Редактирование блока доп.данных -->
<ng-container *ngIf="editedTab">
    <div class="panel panel-shadow m-20" *ngIf="editedTab">
        <div class="panel-heading">
            <h4 class="panel-title">
                {{ localizations.standards.additional_data_settings }}
            </h4>
            <div class="heading-elements">
                <button class="btn btn-success-overline heading-btn btn-labeled"
                        (click)="apply()">
                    {{ localizations.common.apply }}
                </button>
                <button class="btn btn-danger-overline heading-btn btn-labeled"
                        (click)="cancel()">
                    {{ localizations.common.do_cancel }}
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <ul class="info-row active no-margin mt-20 pl-20">
                <li *ngFor="let tab of additionalDataTabs"
                    [ngClass]="{'active': activeAdditionalDataTab == tab.code}"
                    (click)="activeAdditionalDataTab = tab.code">
                    {{tab.name}}
                </li>
            </ul>

            <!-- Общие данные  -->
            <ng-container *ngIf="activeAdditionalDataTab === 'common'">
                <div class="p-20">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group form-group-lg no-margin-bottom">
                                <label>
                                    {{ localizations.common.code }}
                                </label>
                                <input class="form-control"
                                       type="text"
                                       [(ngModel)]="editedTab.code">
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group form-group-lg no-margin-bottom">
                                <label>
                                    {{ localizations.common.name }}
                                </label>
                                <input class="form-control"
                                       type="text"
                                       [(ngModel)]="editedTab.name">
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="no-margin">
                <dynamic-form
                    tabsMode="none"
                    defaultAction="build"
                    [xsd]="editedTab.xsd"
                    [edit]="true"
                    [model]="model"
                    [showTitle]="false"
                    [params]="{ isSetVariable: true }"
                    [scrollTopPosition]="scrollTopPosition"
                    (onCreateXsd)="createXsd($event)"
                    #additionalData>
                </dynamic-form>
            </ng-container>

            <!-- Настройка связи с задачами процесса старта плана -->
            <ng-container *ngFor="let process of processes">
                <ng-container *ngIf="activeAdditionalDataTab === process.code">
                    <bpmn-links [entity]="editedTab"
                                [entityName]="'additionalDataTabs'"
                                [process]="processesData[process.code]"
                                [useRequired]="false"
                                [entityProcess]="organization.processesSettings[process.code].process"
                                *ngIf="organization.processesSettings[process.code]"></bpmn-links>
                    <div class="alert alert-info no-border"
                         *ngIf="!organization.processesSettings[process.code]">
                        {{ localizations.organizations.process_not_setup }}
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>


<!-- Редактирование настроек подписания -->
<ng-container *ngIf="editedSignTab">
    <div class="panel panel-shadow m-20">
        <div class="panel-heading">
            <h4 class="panel-title">
                {{ localizations.organizations.file_signing_settings }}
            </h4>
            <div class="heading-elements">
                <button class="btn btn-success-overline heading-btn btn-labeled"
                        (click)="saveSignData()">
                    {{ localizations.common.apply }}
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <ul class="info-row active no-margin mt-20 pl-20">
                <li *ngFor="let tab of processes; let fst = first"
                    [ngClass]="{'active': signActiveTab == tab.code || !signActiveTab && fst}" (click)="signActiveTab = tab.code">
                    {{ tab.name }}
                </li>
            </ul>

            <!-- Настройка связи с задачами процесса старта плана -->
            <ng-container *ngFor="let process of processes; let fst = first">
                <ng-container *ngIf="signActiveTab === process.code || !signActiveTab && fst">
                    <bpmn-links [entity]="editedSignTab"
                                [entityName]="'signData'"
                                [process]="processesData[process.code]"
                                [useRequired]="false"
                                [entityProcess]="organization.processesSettings[process.code].process"
                                *ngIf="organization.processesSettings[process.code]"></bpmn-links>
                    <div class="alert alert-info no-border"
                         *ngIf="!organization.processesSettings[process.code]">
                        {{ localizations.organizations.process_not_setup}}
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>


