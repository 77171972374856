import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AccessService, FiltersService, ModalDialogComponent, RestService, ToasterService, TranslateService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppealValidateService } from '../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../appeal-subservices.service';
import { AppealStatusService } from '../../../appeal-status.service';
import { Permission } from '../../../../../../common/services/permission';
import { ModalUserComponent } from '../../../../../subjects/modal-user/modal-user.component';
import * as _ from 'lodash-es';
import { IndividualObjectData } from '../../../../../subjects/individual-object/components/individual-applicant-form/common/individual-object.data';

@Component({
    selector: 'appeal-object-card',
    templateUrl: 'appeal-object-card.component.html',
})
export class AppealObjectCardComponent implements OnInit {
    @Input() public object: any; // Субъект
    @Input() public mode = 'view'; // Режим работы: просмотр карточки, редактирование данных
    @Input() public appeal; // Формируемое дело
    @Input() public subservice: any = {}; // описание услуги, на основании которой формируется дело
    @Input() public activeTab;
    @Input() public availableKinds;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onInitEdit = new EventEmitter<object>();
    @Output() public onDelete = new EventEmitter<object>();
    @ViewChild(ModalDialogComponent, { static: false }) public modalDialogComponent: ModalDialogComponent;

    public modalMessage; // Текст сообщения для модального окна
    public modalTitle; // Заголовок сообщения для модального окна
    public modalOperation; // Вид операции (возврат к списку или удаление объекта)

    public editTabs; // Массив вкладок для доступа к редактируемым блокам

    public isEditObjectKind = false; // Флаг режима выбора вида объекта
    public isEditObjectSubKind = false; // Флаг режима выбора типа объекта
    public globalParams = IndividualObjectData; // Общие константы для работы с объектом
    public continueProcessingAppeal; // флаг продолжения операции сохранения (перехода на другой статус) дела после применения изменений в услуги

    public allowEdit = true; // Возможность редактирования объекта
    public allowDelete = true; // Возможность удаления объекта

    public permissions = Permission; // Набор прав системы

    public searchText;
    public findedObjects = [];
    public selectedObject;
    public localizations;

    @ViewChild(ModalUserComponent, { static: false }) public modalUserComponent: ModalUserComponent; // модальное окно для выода списка найденных

    public modalRef: BsModalRef;
    @ViewChild('selectObjectModal', { static: false }) public selectObjectModal: TemplateRef<any>;

    public constructor(
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        private appealSubservicesService: AppealSubservicesService,
        private validateService: AppealValidateService,
        private toaster: ToasterService,
        private filtersService: FiltersService,
        private restService: RestService,
        private modalService: BsModalService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        // Если нет полномочия на редактирование в любом статусе
        if (!this.accessService.existPermission(this.permissions.No_Edit_Limits)) {
            // Запрещено редактирование, если дело находится в финальном статусе, или зарегистрировано
            this.allowEdit = this.appealStatusService.allowEdit
                && (this.object.mainId && this.accessService.hasAccess([this.permissions.Appeal_Object_Update], false, this.appeal.status)
                    || !this.object.mainId && this.accessService.hasAccess([this.permissions.Appeal_Object_Create], false, this.appeal.status));
            this.allowDelete = this.appealStatusService.allowEdit && this.appealStatusService.appeal.status.code !== 'process'
                && this.accessService.hasAccess([this.permissions.Appeal_Object_Delete], true, this.appeal.status);
        }

        if (!this.object.kind.subKind) {
            this.isEditObjectSubKind = true;
        }

        // Инициализация табов с блоками для редактирования информации объекта
        // this.initEditTabs();
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.objects',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Выбор типа объекта: ФЛ, ЮЛ, ИП
     */
    public changeSubKind(sub) {
        const beforeSubKind = _.cloneDeep(this.object.kind.subKind);
        this.object.kind.subKind = sub;
        // this.subject = _.cloneDeep(this.subject); // копируем объект, для того, чтобы сработало изменение
        if (this.object.kind && this.object.kind.subKind) {
            this.isEditObjectSubKind = false;
            // В случае изменения
            if (!beforeSubKind || beforeSubKind.name !== this.object.kind.subKind.name) {
                this.object.specialTypeId = this.object.kind.subKind.specialTypeId;
                if (!this.object.data.realty) {
                    this.object.data.realty = {};
                }
            }
        } else {
            this.object.specialTypeId = null;
        }
    }

    public changeObjectSubKind() {
        if (this.availableKinds[0].subKinds.length > 1) {
            this.isEditObjectSubKind = true;
        }
    }

    /**
     * Обработка события нажания на Enter при поиске
     * @param event
     */
    public keypress(event?) {
        if (event) {
            if (event.keyCode === 13) {
                this.searchObject();
            }
        }
    }

    public searchObject() {
        const params: any = {
            search: {
                search: [],
            },
            prj: 'objectsList',
        };

        if (this.filtersService.collectionsSearchParams['objectsKno'].useExternalSearch) {
            const searchByFields = this.filtersService.prepareDataForExternalSearchFromTextSearch('objectsKno', this.searchText);
            if (searchByFields.length > 0) {
                params.search.search.push({
                    orSubConditions: searchByFields,
                });
            }
        } else {
            params.textSearch = this.searchText;
        }

        return this.restService.search('objectsKno', params).then(objects => {
            this.filtersService.processingLinkedData('objectsKno', objects, 'objectLink', 'globalObjectId', 'objectsList').then(resultObjects => {
                if (resultObjects.length > 0) {
                    this.findedObjects = resultObjects;
                    this.modalRef = this.modalService.show(this.selectObjectModal, {backdrop: 'static'});
                } else {
                    this.toaster.info('Совпадения не найдены');
                }
            });
        });
    }

    public selectObject() {
        const promises = [];
        promises.push(this.restService.find('objectsKno', this.selectedObject._id));
        if (this.selectedObject.globalObjectId) {
            promises.push(this.restService.find('objects', this.selectedObject.globalObjectId));
        }
        Promise.all(promises).then(objectData => {
            const knoObject = objectData[0];
            const globalObject = objectData[1] ? objectData[1] : null;
            const selectedObject: any = {
                reestrId: knoObject._id,
                auid: knoObject.auid,
                type: globalObject ? globalObject.type : knoObject.type,
                name: globalObject ? globalObject.name : knoObject.name,
                shortName: globalObject ? globalObject.shortName : knoObject.shortName,
                region: globalObject ? globalObject.region : knoObject.region,
                latitude: globalObject ? globalObject.latitude : knoObject.latitude,
                longitude: globalObject ? globalObject.longitude : knoObject.longitude,
                address: globalObject ? globalObject.address : knoObject.address,
            };
            if (globalObject && globalObject.additionalData || !globalObject && knoObject.additionalData) {
                selectedObject.additionalData = globalObject && globalObject.additionalData ? globalObject && globalObject.additionalData : knoObject.additionalData;
            }
            this.object = Object.assign(this.object, selectedObject);
            this.modalRef.hide();
        });
    }

    /**
     * Передача инициализации режима редактирования объекта в родительский компонент (переход из режима просмотра в режим редактирования)
     * @param tab - активируемая вкладка
     */
    public initEdit(tab) {
        this.activeTab = tab;
        this.onInitEdit.emit({objectGuid: this.object.guid, tab: this.activeTab});
    }

    /**
     * Инициализация режима редактирования услуги в деле (нажатие кнопки "Редактировать")
     */
    public edit() {
        this.onEdit.emit(this.object);
    }

    /**
     * Применение изменений внесенных в объект (нажатие кнопки "Применить")
     * @param continueProcessingAppeal - продолжить сохранение (или перевод на другой статус) дела после применения изменений
     */
    public apply(continueProcessingAppeal = false) {
        this.continueProcessingAppeal = continueProcessingAppeal;
        // если текущая вкладка "Доп.информация", то нужно сохранить данные
        if (this.activeTab === 'additionalData') {
            // this.appealObjectAdditionalDataComponent.save(null, true);
        } else {
            this.onApply.emit({object: this.object, continueProcessingAppeal: continueProcessingAppeal});
        }
    }

    /**
     * Отмена изменений внесенных в услугу дела (нажатие кнопки "Отменить")
     */
    public cancel() {
        this.onApply.emit(false);
    }

    /**
     * Обработка действий из модального окна: применение изменений, либо отмена
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingBack(data) {
        if (data === 'yes') {
            this.apply();
        } else if (data === 'no') {
            this.cancel();
        }
    }

    /**
     * Активация определенной вкладки при редактировании объекта
     * @param tab - активируемая вкладка
     */
    public activateTab(tab) {
        if (this.activeTab === 'additionalData') {
            // Если текущая вкладка "Доп. инфо", то перед переходом на новую сохраняем значения
            // this.appealSubjectAdditionalDataComponent.save(tab);
        } else {
            this.activeTab = tab;
            if (this.mode === 'view') {
                this.onInitEdit.emit({objectGuid: this.object.guid, tab: this.activeTab});
            }
        }
    }

    /**
     * Передача управления ответа на нажатие кнопок модального окна нужному методу
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    /**
     * Инициализация вызова модального окна для подтверждения удаления объекта
     */
    public deleteObject() {
        this.modalMessage = 'Вы действительно хотите удалить объект "' + this.object.name + '"?';
        this.modalTitle = 'Удаление объекта';
        this.modalOperation = 'processingDelete';
        this.modalDialogComponent.showModal();
    }

    /**
     * Обработка результата нажатия кнопок модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingDelete(data) {
        if (data === 'yes') {
            this.onDelete.emit(this.object);
        }
    }

    /**
     * КНМ валидности объекта
     * @returns {boolean}
     */
    public checkObjectValid() {
        return this.validateService.checkElementValid(this.object.guid, 'objects');
    }

    public get objectSubservices() {
        const subservices = [];
        Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
            if (this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid]
                && this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid].active) {
                const subservice = {
                    title: this.appealSubservicesService.data[appealSubserviceGuid].subservice.titles.shortTitle,
                    subjects: [],
                };
                if (this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid].subjects) {
                    const activeSubjects = this.appealSubservicesService.data[appealSubserviceGuid].objects[this.object.guid].subjects.filter(item => item.active);
                    if (activeSubjects.length > 0) {
                        activeSubjects.forEach(subject => {
                            subservice.subjects.push(this.appealSubservicesService.subjectsData[subject.guid].shortHeader);
                        });
                    }
                    subservices.push(subservice);
                }
            }
        });

        return subservices;
    }

    public getAddress(address) {
        return PrintUtilities.PrintAddressAsLine(address, true);
    }
}
