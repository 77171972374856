import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityRequirementExplanation } from './models/appeal-entity-requirement-explanation';
import { AppealEntityOrder } from '../entity-order/models/appeal-entity-order';
import { AppealEntitiesService } from '../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-requirement-explanation',
    templateUrl: 'appeal-entity-requirement-explanation.component.html',
    styles: ['.form-group textarea {height: auto!important;}'],
})
export class AppealEntityRequirementExplanationComponent implements OnInit {
    @Input() public entity: AppealEntityRequirementExplanation;
    @Input() public mode;
    @Input() public appeal;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    public isCheckPlan = false;
    public isCheckUnplan = false;

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        // Внеплановая проверка
        this.isCheckUnplan = this.appealEntitiesService.isKndTypeUnplan(this.appeal);
        // Плановая проверка
        this.isCheckPlan = this.appealEntitiesService.isKndTypePlan(this.appeal);
    }

    public edit() {
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.onApply.emit(this.entity);
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }
}
