import { Injectable } from '@angular/core';

@Injectable()
export class CommonAppealValidateService {
    public appeal; // Формируемое дело
    public subservice;
    public metaReglament;
    public tabs;

    public processValidate = false; // Флаг включения/отключения процесса валидации

    // Элементы валидации
    public validateElements: any;

    public constructor() {
        this.validateElements = {
            subservice: {},
            common: {},
            objects: {},
            subjects: {},
            documents: {},
        };
    }
    /**
     * Очистка данных сервиса
     */
    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.processValidate = false;
    }

    /**
     * Основной метод валидации дела
     * @param validate - Признак необходимости/отсутстввия валидации
     * @returns {any}
     */
    public validateAppeal(validate) {
        this.processValidate = validate;
        this.tabs.forEach(tab => {
            tab.errors = [];
        });
        const isValidCommon = this._validateCommon();
        const isValidObjects = this._validateObjects();
        const isValidSubjects = this._validateSubjects();

        return isValidCommon && isValidObjects && isValidSubjects;
    }

    private _validateCommon() {
        const currentTab = this.getTab('common');
        if (this.processValidate) {
            if (!this.appeal.subservice.xsdDataValid || !this.appeal.subservice.appealXsdDataValid) {
                currentTab.errors.push('Ошибка заполнения формы основных данных');
            }
        }

        return currentTab.errors.length === 0;
    }

    private _validateSubjects() {
        const currentTab = this.getTab('subjects');
        if (this.processValidate) {
            if (this.subservice.objects.subjectsData) {
                if (this.subservice.objects.subjectsData.min && this.appeal.subjects.length < this.subservice.objects.subjectsData.min) {
                    currentTab.errors.push('В дело не добавлено минимально возможное число субъектов. Минимальное значение &laquo;' + this.subservice.objects.subjectsData.min + '&raquo;');
                }
            }
        }

        return currentTab ? currentTab.errors.length === 0 : true;
    }

    private _validateObjects() {
        const currentTab = this.getTab('objects');
        if (this.processValidate) {
            if (this.subservice.objects.objectsData) {
                if (this.subservice.objects.objectsData.min && this.appeal.objects.length < this.subservice.objects.objectsData.min) {
                    currentTab.errors.push('В дело не добавлено минимально возможное число объектов. Минимальное значение &laquo;' + this.subservice.objects.objectsData.min + '&raquo;');
                }
            }
        }

        return currentTab ? currentTab.errors.length === 0 : true;
    }

    public validateDocuments() {
        const currentTab = this.getTab('documents');
        if (this.processValidate) {

        }

        return currentTab ? currentTab.errors.length === 0 : true;
    }

    public getTab(type) {
        if (type === 'subjects' || type === 'objects') {
            if (this.subservice.objects && ((type === 'objects' && this.subservice.objects.objectsData && this.subservice.objects.objectsData.useInMainBlock)
                || (type === 'subjects' && this.subservice.objects.subjectsData && this.subservice.objects.subjectsData.useInMainBlock))) {
                return this.tabs.find(item => item.code === 'common');
            }
        }

        return this.tabs.find(item => item.code === type);
    }

    public getTabErrorsList(tab) {
        let result = '';
        if (tab.errors && tab.errors.length > 0) {
            tab.errors.forEach(err => {
                result += '<li>' + err + '</li>';
            });
        }

        return result;
    }

    public getErrors() {
        let result = '<ul class="no-margin">';
        this.tabs.forEach(tab => {
            if (tab.errors.length > 0) {
                tab.errors.forEach(err => {
                    result += '<li>' + err + '</li>';
                });
            }
        });
        result += '</ul>';

        return result;
    }
}
