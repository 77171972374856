import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestService, ToasterService, TranslateService } from '@evolenta/core';
import { ServicesService } from '../../../modules/subservices/services/services.service';
import { SUBSERVICES_COLLECTION } from '../../constants';

@Component({
    selector: 'anketa-life-event',
    templateUrl: './anketa-life-event.component.html',
})
export class AnketaLifeEventComponent implements OnInit {
    @Input() public resultData; // данные пройденной ранее анкеты
    @Input() public viewType = 'full'; // режим отображения: full - полный, short - краткий

    public lifeEvents; // список жизненных ситуаций
    public selectedLifeEvent; // выбранная жизненная ситуация
    public isProcessSelectLifeEvent = false; // флаг процесса выбора жизненной ситуации

    public isProcessingAnketa = false; // флаг осуществления процесса анкетирования
    public isShowQuestsAndAnswers = true; // флаг отображения / скрытия блоков с вопросами и ответами
    public isShowSubservices = false; // флаг отображения / скрытия списка услуг

    public questionPath = []; // выбранные вопросы

    public currentQuestion; // текущий вопрос
    public activeAnswer; // активный ответ
    public activeAnswerIdx = -1; // индекс активного ответа

    public activeServices = []; // массив кодов услуг
    public positiveServices = []; // массив положительных услуг

    public subservice: any = {}; // услуга, найденая по кодам стандартов, отфильтрованных после анкетирования
    public localizations;

    @Output() public onUpdate = new EventEmitter<object>();
    @Output() public onComplete = new EventEmitter<object>();

    public constructor(
        private restService: RestService,
        private toaster: ToasterService,
        private servicesService: ServicesService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация данных
     */
    public async ngOnInit() {
        this._loadTranslations();
        // Загрузка списка ЖС
        try {
            this.lifeEvents = await this.restService.search('catalogueLifeEvents', {size: 1000});
            // Инициализация ранее выбранных данных
            if (!this.resultData) {
                // Новое анкетирование
                this.isProcessSelectLifeEvent = true;

                return;
            }

            // История ответов
            if (this.resultData.questions && this.resultData.questions.length) {
                this.questionPath = this.resultData.questions;
            }
            // Выбранная жизненная ситуация
            if (this.resultData.lifeEvent) {
                this.selectedLifeEvent = this.resultData.lifeEvent;
                await this.selectLifeEvent(this.selectedLifeEvent, false);
            }
            // Услуги
            if (this.resultData.subservice) {
                this.subservice = this.resultData.subservice;
            }
            this.isShowQuestsAndAnswers = false;
        } catch (error) {
            this.toaster.error(error);
        }
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'anketa-life-event',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Событие выбора жизненной ситуации: инициализация данных + загрузка анкеты по ЖС
     * @param lifeEvent - выбранная жизненная ситуация
     * @param initData - первичная инициализация данных или нет
     */
    public async selectLifeEvent(lifeEvent, initData = true) {
        const beforeLifeEventId = this.selectedLifeEvent ? this.selectedLifeEvent.id : null;
        this.selectedLifeEvent = {
            code: lifeEvent.code,
            title: lifeEvent.title,
        };
        this.isProcessSelectLifeEvent = false;
        // Обнуление данных пройденной анкеты, получение анкеты по выбранной ЖС
        if (!this.selectedLifeEvent._id  !== beforeLifeEventId) {
            if (initData) {
                this.questionPath = [];
                this.currentQuestion = null;
                this.subservice = [];
            }

            // Загрузка анкеты по жизненной ситуации
            try {
                const response = await this.restService.search('catalogueLifeEventsAnkets', {search: {search: [{field: 'lifeEvent.code', operator: 'eq', value: this.selectedLifeEvent.code}]}, size: 20});
                const anketa = response ? response[0] : null;
                if (!anketa || !anketa.anketaBlock) {
                    this.toaster.error('Анкета для выбранной жизненной ситуации не найдена!');

                    return;
                }

                if (!initData) {
                    return;
                }

                this.currentQuestion = anketa.anketaBlock.question;
                this.activeServices = anketa.services;
                this.activeAnswerIdx = -1;
                this.isProcessingAnketa = true;

                // Уведомление родительского компонента о, изменении состава данных
                this._afterChangeAnketa();
            } catch (error) {
                this.toaster.error(error);
            }

        }
    }

    /**
     * Событие на изменение данных анкеты: загрузка новой, изменение состава выбранных вопросов-ответов
     */
    private _afterChangeAnketa() {
        this.onUpdate.emit({
            lifeEvent: this.selectedLifeEvent,
            questions: this.questionPath,
            subservice: this.subservice,
        });
    }

    /**
     * Устанавливает активный ответ в текущем вопросе
     * @param answer - объект ответа
     * @param idx - индекс ответа
     */
    public setActiveAnswer(answer, idx) {
        this.activeAnswer = answer;
        this.activeAnswerIdx = idx;

        // Активация следующего вопроса
        this._nextQuestion();
    }

    /**
     * Переход на следующий вопрос
     * @returns {boolean}
     */
    private _nextQuestion() {
        if (this.activeAnswerIdx === -1) {
            this.toaster.info('Для продолжения выберите вариант ответа');

            return false;
        }

        // Сохраним историю
        this.currentQuestion.results = {
            activeServices: this.activeServices,
            positiveServices: this.positiveServices,
            answerIdx: this.activeAnswerIdx,
            activeAnswer: this.activeAnswer,
        };

        this.questionPath.push(this.currentQuestion);

        // Удаление отрицательных услуг из общего и положительного списков
        if (this.activeAnswer.negativeServices) {
            this.activeServices = this.activeServices.filter(item => this.activeAnswer.negativeServices.indexOf(item) === -1);
            this.positiveServices = this.positiveServices.filter(item => this.activeAnswer.negativeServices.indexOf(item) === -1);
        }

        // Добавление положительных услуг в положительный список
        if (this.activeAnswer.positiveServices) {
            this.activeAnswer.positiveServices.forEach(code => {
                if (this.positiveServices.indexOf(code) === -1) {
                    this.positiveServices.push(code);
                }
            });
        }

        if (!this.activeAnswer.question) {
            // Вопросы закончились, обнуляем активный
            this.currentQuestion = null;
            // Получение данных по отфильтрованным услугам
            this._getSubservicesByCodes();
        } else {
            // Переходим к следующему вопросу
            this.activeAnswerIdx = -1;
            this.currentQuestion = this.activeAnswer.question;
        }

        // Уведомляем родиетельский компонент о, изменениях
        this._afterChangeAnketa();
    }

    /**
     * Изменение ЖС
     */
    public changeLifeEvent() {
        this.isProcessSelectLifeEvent = true;
        this.selectedLifeEvent = null;
        this.questionPath = [];
        this.currentQuestion = null;
        this.subservice = [];
        this.positiveServices = [];
        this.activeServices = [];
    }

    /**
     * Возврат к предыдущему вопросу
     * @param idx - индекс текущего вопроса
     */
    public backToPreviousQuestion(idx) {
        const length = this.questionPath.length;
        if (this.questionPath.length > 0) {
            for (let i = 0; i < length - idx; i++) {
                this.currentQuestion = this.questionPath.pop();
            }
            this.positiveServices = this.currentQuestion.results.positiveServices;
            this.activeServices = this.currentQuestion.results.activeServices;
        }
    }

    /**
     * Получение услуг из БД по отфильтрованным кодам стандартов услуг и возврат результирующего значения
     */
    private async _getSubservicesByCodes() {
        const codes = this.positiveServices.concat(this.activeServices);

        if (!codes.length) {
            this.toaster.info('По выбранным вариантам ответов услуги отсутствуют');

            return;
        }

        try {
            const subserviceReceiveResult = await this.restService.search(SUBSERVICES_COLLECTION, {search: {search: [{field: 'standardCode', operator: 'in', value: codes}]}, size: 100, prj: 'serviceListForAnketa'});
            let subservice = subserviceReceiveResult[0];
            subservice = this.servicesService.correctSubserviceByCurrentUnit(subservice);
            const item = {
                id: subservice._id,
                serviceId: subservice.serviceId,
                code: this.servicesService.getServiceCodeFromServiceId(subservice.serviceId),
                title: subservice.titles.title,
                hasAnketa: !!subservice.anketa,
            };
            this.subservice = item;

            this.isProcessingAnketa = false; // сбрасываем флаг процесса опроса по анкете
            this.isShowQuestsAndAnswers = false; // скрываем вопросы и ответы
            this.toaster.success('Анкетирование успешно завершено.');
            this._afterChangeAnketa();
            // Возврат события о завершении процесса анкетирования
            this.onComplete.emit({
                lifeEvent: this.selectedLifeEvent,
                questions: this.questionPath,
                subservice: this.subservice,
            });
        } catch (error) {
            this.toaster.error(error);
        }
    }

    /**
     * Отображение / скрытия блоков вопросов и ответов
     */
    public showQuestsAndAnswers() {
        this.isShowQuestsAndAnswers = !this.isShowQuestsAndAnswers;
    }
}
