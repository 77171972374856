<ul class="navigation navigation-main navigation-accordion no-padding mb-10"
    *ngIf="helpData">
    <li class="active">
        <a>
            <!-- TODO всплывающее окно -->
            <i class="icon-info22"
               (click)="openAboutModal()"></i>
        </a>
        <div class="navigation-title">
            <a class="text-center">
                <span class="opacity-50">
                    {{ localizations.common.support }}
                </span>

                <br>
                {{ aboutConfig.phone }}

                <a href="mailto:{{ aboutConfig.email }}">
                    {{ aboutConfig.email }}
                </a>

                <br>
                <a (click)="goToServiceDesk()">
                    {{ localizations.common.by_portal }}
                </a>
            </a>
        </div>
    </li>
</ul>
<div class="text-muted text-center">
    <ng-container *ngIf="appConfig.showVersion">
        Версия:
        {{ this.versionService.currentVersion.client }}
    </ng-container>
    <button class="btn btn-danger"
            *ngIf="versionService.newVersion"
            (click)="reload()">
        {{ localizations.common.update }}
    </button>
</div>

<about-modal-dialog #aboutModal>
</about-modal-dialog>
