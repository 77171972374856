import { Component, Input, OnInit } from '@angular/core';
import { CommonAppealService } from '../../services/common-appeal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistersService } from '../../../registers/registers.service';

@Component({
    selector: 'common-appeal-header',
    templateUrl: './common-appeal-header.component.html',
})
export class CommonAppealHeaderComponent implements OnInit {
    @Input() public appeal: any;
    @Input() public subservice;
    @Input() public metaReglament;

    public showHeader = true;
    public appealSubservice;
    public registersModel;
    public mainElement;

    public licensesTitles = {
        single: 'Лицензия',
        plural: 'Лицензии',
        prevSingle: 'Предыдущая лицензия',
    };

    public moduleBaseUrl;

    public constructor(
        public appealService: CommonAppealService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    public ngOnInit() {
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        this.appealSubservice = this.appeal.subservice;
        this.mainElement = this.appealSubservice.mainElement;

        this.getRegistryModel();

        if (!this.registersModel) {
            return;
        }

        this.showHeader = true;

        if (!this.registersModel.titles) {
            return;
        }

        this.licensesTitles.single = this.registersModel.titles.hasOwnProperty('single')
            ? this.registersModel.titles.single : this.licensesTitles.single;

        this.licensesTitles.plural = this.registersModel.titles.hasOwnProperty('plural')
            ? this.registersModel.titles.plural : this.licensesTitles.plural;
    }

    public async goToLicense() {
        await this.router.navigate([this.moduleBaseUrl, this.appealService.registersPath, 'edit', this.mainElement.licenseId]);
    }

    public getRegistryModel() {
        if (this.subservice.registersModel) {
            this.registersModel = this.subservice.registersModel;

            return;
        }

        if (this.subservice.mainElement) {
            this.registersModel = {
                registryType: this.subservice.mainElement,
                operationType: this.subservice.additionalParams ? this.subservice.additionalParams.operationType : null,
            };
        }
    }

    public getOperationTypeProperty(property) {
        if (!(this.mainElement && this.mainElement.operationType)) {
            return '';
        }

        const operationTypes = RegistersService.OperationTypes;
        const operationType = operationTypes.find(item => item.code === this.mainElement.operationType);

        return operationType && operationType[property] ? operationType[property] : '';
    }
}
