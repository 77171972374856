<div class="panel panel-shadow m-20">
    <div class="panel-heading"
         [ngClass]="{'pl-20 pr-20': mode == 'view'}">
        <div class="panel-title">

            <!-- Ссылка возврата к списку -->
            <a *ngIf="mode == 'edit'"
               (click)="back()"
               [title]="localizations['appeals.subjects'].back_to_subject"
                class="text-size-small text-grey-300 hide">
                <svg class="display-inline-block mr-5"
                     width="16"
                     height="10"
                     viewBox="0 0 16 10"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L3.41421 6L15 6C15.5523 6 16 5.55229 16 5C16 4.44772 15.5523 4 15 4L3.41421 4L5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L0.292893 4.29289C0.0983936 4.48739 0.000764065 4.74208 4.30156e-06 4.997C1.36631e-06 4.998 0 4.999 0 5C0 5.001 1.36947e-06 5.002 4.30262e-06 5.003C0.000399107 5.1375 0.0273461 5.26575 0.0758787 5.38278C0.12357 5.49805 0.193742 5.60621 0.286395 5.70055C0.288737 5.70294 0.291091 5.70531 0.293457 5.70767M4.29289 9.70711L0.293457 5.70767L4.29289 9.70711Z" fill="#A7A7AB"/>
                </svg> {{localizations.common.go_back}}
            </a>
                <!-- Заголовок объекта -->
                <h4 class="no-margin-top mb-5">
                    {{subject.header ? subject.header : localizations['appeals.subjects'].default_header}}
                </h4>
            <div>
                <!-- Бэдж-ссылка на редактирование вида объекта -->
                <span class="badge badge-info text-uppercase mr-10"
                      [ngClass]="{'cursor-pointer' : canEditKind()}"
                      (click)="editKind()"
                      *ngIf="subject.kind">
                    {{localizations['appeals.subjects'].participant}}
                </span>

                <!-- Бэдж-ссылка на редактирования типа объекта -->
                <span class="badge text-uppercase mr-10"
                      [ngClass]="{'bg-teal-300': subject.specialTypeId == 'ulApplicant',
                                  'bg-warning-300': subject.specialTypeId == 'ipApplicant',
                                  'bg-orange-300': subject.specialTypeId == 'individualApplicant'}"
                      (click)="editSubKind()">
                    {{subject.specialTypeId == 'ulApplicant' ?
                                                localizations['appeals.subjects'].legal_body :
                    subject.specialTypeId == 'ipApplicant' ? localizations['appeals.subjects'].individual_entrepreneur :
                        localizations['appeals.subjects'].private_person}}
                </span>
            </div>

        </div>

        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default"
                    [title] = "allowEdit ? localizations.common.edit : localizations.common.view"
                    (click)="edit()"
                    *ngIf="mode == 'view' && (allowEdit || checkAdditionalDataExist())">
                <b>
                    <i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : ''"></i>
                </b>
            </button>
            <button class="btn btn-default"
                    [title]="localizations.common.delete"
                    (click)="deleteSubject()"
                    *ngIf="mode == 'view' && allowDelete">
                <b>
                    <i inlineSVG="assets/icon-trash.svg"></i>
                </b>
            </button>
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="apply()"
                    *ngIf="mode == 'edit' && allowEdit
                           && accessService.hasAccess([permissions.Appeal_Object_Update, permissions.Appeal_Object_Create], false, appeal.status)">
                {{localizations.common.apply}}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()" *ngIf="mode == 'edit'">
                        <span *ngIf="allowEdit && accessService.hasAccess([permissions.Appeal_Object_Update], true, appeal.status)">
                            {{localizations.common.cancel}}
                        </span>
                        <span *ngIf="!allowEdit || !accessService.hasAccess([permissions.Appeal_Object_Update], true, appeal.status)">
                            {{localizations.common.close}}
                        </span>
            </button>
        </div>
    </div>
    <div class="panel-body no-padding no-margin-bottom">

        <!-- Выбор вида объекта -->
        <div class="ml-20 mr-20 mb-20 p-20 border-top border-right border-bottom border-left border-grey-120"
             *ngIf="isEditObjectKind && mode === 'edit'">
            <h4 class="no-margin">
                {{localizations['appeals.subjects'].select_type}}:
            </h4>
            <div class="radio-block"
                 *ngFor="let kind of availableKinds">
                <input type="radio"
                       class="radio"
                       name="kind"
                       (click)="selectKind(kind)"
                       id="kind{{kind.guid}}">
                <label for="kind{{kind.guid}}">
                    {{kind.name}}
                </label>
            </div>
        </div>

        <!-- Выбор типа объекта -->
        <div class="ml-20 mr-20 mb-20 p-20 mt-20 border-top border-right border-bottom border-left border-grey-120"
             *ngIf="!isEditObjectKind && isEditObjectSubKind && mode === 'edit'">
            <p class="text-bold no-margin">
                {{localizations['appeals.subjects'].select_type}}:
            </p>
            <div class="radio-inline"
                 *ngFor="let sub of getSubKinds(subject.kind)">
                    <input id="subkind{{sub.specialTypeId}}{{sub.type ? sub.type : ''}}"
                           type="radio"
                           class="radio"
                           name="subkind"
                           [checked]="subject.kind && subject.kind.subKind && subject.kind.subKind.name == sub.name"
                           (click)="changeSubKind(sub)">
                    <label for="subkind{{sub.specialTypeId}}{{sub.type ? sub.type : ''}}">
                        {{sub.name}}
                        <span *ngIf="sub.onlyForPrincipal"
                              class="text-size-small text-muted">
                            ({{localizations['appeals.subjects'].only_as_a_representative}})
                        </span>
                    </label>
            </div>
        </div>

        <!-- Формы спецобъектов -->
        <ng-container *ngIf="subject.kind && (subject.kind.subKinds && subject.kind.subKind || !subject.kind.subKinds)">

            <subject-preview-card [subject]="subject"
                                  [mode]="mode"
                                  [showName]="false"
                                  *ngIf="mode == 'view'"
                                  (onActivateTab)="activateTab($event)">
            </subject-preview-card>


            <!-- Вкладки переключения блоков данных -->
            <div class="mt-20"
                 *ngIf="mode == 'edit'">
                <element-edit-tabs type="objects"
                                   [tabs]="editTabs"
                                   [active]="activeTab"
                                   [elementId]="subject.guid"
                                   (onActivateTab)="activateTab($event)">
                </element-edit-tabs>
            </div>

            <!-- Привязка к услугам -->
            <appeal-subject-subservices [entity]="subject"
                                        [appeal]="appeal"
                                        [subservice]="subservice"
                                        (onReInitEditTabs)="initEditTabs()"
                                        (onActivateCommonTab)="activateTab('common')"
                                       *ngIf="mode == 'edit' && activeTab == 'subservice'">
            </appeal-subject-subservices>

            <!-- ФЛ, ЮЛ, ИП - тип: Участник -->
            <ng-container *ngIf="activeTab === 'common'">

                <div class="ml-20 mr-20 mb-20 p-20 mt-20 border-top border-right border-bottom border-left border-grey-120"
                     *ngIf="mode == 'edit' && checkIsAddNewSubject()">
                    <!-- Строка поиска -->
                    <div class="form-group no-margin-bottom">
                        <div class="input-group input-group-lg">
                            <input type="text"
                                   class="form-control input"
                                   placeholder="Поиск в реестре субъектов..."
                                   (keypress)="keypress($event)"
                                   name="searchText"
                                   [(ngModel)]="searchText">
                            <div class="input-group-btn pl-20">
                                <button type="button"
                                        class="btn btn-primary-overline"
                                        (click)="searchSubject(true)">
                                    {{localizations.common.find}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <subject-form *ngIf="mode === 'edit'"
                          [hidden]="activeTab !== 'common'"
                          [user]="subject"
                          [requirementsRules]="getFieldsRequirementsRules()"
                          [showInside]="true"
                          [isProcessValidate]="validateService.processValidate"
                          #subjectForm></subject-form>

            <!-- Объекты субъектов -->
            <appeal-subject-objects *ngIf="activeTab == 'objects'"
                                    [subject]="subject"></appeal-subject-objects>

            <!-- Дополнительные данные -->
            <appeal-object-additional-data *ngIf="activeTab === 'additionalData'"
                                           [object]="subject"
                                           (onAfterSave)="afterSaveAdditionalData($event)"
                                           #additionalData></appeal-object-additional-data>
        </ng-container>
    </div>
</div>

<modal-dialog [message]="modalMessage"
                       [title]="modalTitle"
                       (onSelect)="processingModal($event)"></modal-dialog>

<modal-user [subjects]="foundSubjects"
            [type]="subject.specialTypeId"
            (onSelect)="selectSubject($event)"></modal-user>


