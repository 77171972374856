<div [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit', 'panel-small': task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()" *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()" *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  *ngIf="mode == 'view'" [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}">
            <p class="mb-3">
                <span class="text-muted mr-5">Номер: </span>
                {{entity.number ? entity.number : 'не указан'}}
                <span class="text-muted ml-10">Дата решения: </span>
                <ng-container *ngIf="entity.date">{{entity.date | date: 'mediumDate'}}</ng-container>
                <em *ngIf="!entity.date">не указана</em>
                <span class="text-bold ml-5 text-uppercase">{{entity.isAgreed ? 'Согласовано' : 'Не согласовано'}}</span>
            </p>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button"  [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div class="p-20" *ngIf="mode == 'edit'">
            <p class="mb-15">
                <span class="text-bold">Заявление на согласование распоряжения:</span>
                <span class="ml-10 text-muted" *ngIf="!entity.statement">не выбрано</span>
                <ng-container *ngIf="entity.statement && currentStatement">
                    <span class="text-muted ml-5">от</span> {{currentStatement.createDate | date: 'mediumDate'}}
                    <span class="text-muted ml-5">Прокуратура:</span> {{currentStatement.prosecutor?.name}}
                </ng-container>
                <span class="anchor text-info text-size-small ml-10" *ngIf="statements.length > 1" (click)="isSelectStatement = true">изменить</span>
            </p>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group form-group-lg">
                        <label>Номер решения</label>
                        <input type="text" class="form-control" [(ngModel)]="entity.number">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-group-lg">
                        <label>Дата решения</label>
                        <evolenta-datepicker [(ngModel)]="entity.date" [large]="true" [valueAsString]="true"></evolenta-datepicker>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group form-group-lg">
                        <label class="display-block">Распоряжение согласовано</label>
                        <ui-switch [checked]="entity.isAgreed"
                                   (change)="entity.isAgreed = !entity.isAgreed; entity.isFailure = !entity.isAgreed"></ui-switch>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!entity.isAgreed">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <label>Причина отказа в проведении КНМ</label>
                        <catalogue catalogName="rejectionReason" name="reasonDeny" [(ngModel)]="entity.reasonDeny" [priority]="true" [large]="true" [disabled]="true"></catalogue>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!entity.isAgreed">
                <div class="col-md-12">
                    <div class="form-group form-group-lg">
                        <label>Тип основания отказа</label>
                        <catalogue catalogName="nsiErpRejectBaseType" name="rejectBaseType" [(ngModel)]="entity.rejectBaseType" [priority]="true" [large]="true" [disabled]="true"></catalogue>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="entity.isAgreed">
                <div class="col-md-6">
                    <div class="form-group form-group-lg">
                        <label>ФИО подписанта о согласовании проведения КНМ органа прокуратуры</label>
                        <input [(ngModel)]="entity.prosecutorName" type="text" class="form-control input-xs" placeholder="Введите ФИО">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-group-lg">
                        <label>Должность подписанта о согласовании проведения КНМ органа прокуратуры</label>
                        <input [(ngModel)]="entity.prosecutorPosition" type="text" class="form-control input-xs" placeholder="Введите должность">
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
