<dynamic-form
        #additionalData
        [xsd]="tab.xsd"
        [edit]="false"
        tabsMode="none"
        [model]="model"
        [saveInMainObject]="true"
        [textView]="textView"
        [externalData]="externalData"
        [useXsdService]="useXsdService"
        [initHandlers]="tab.xsdHandlers"
        (afterChangeForm)="runHandler()"
    >
    </dynamic-form>