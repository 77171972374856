<div [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit'}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i [inlineSVG]="allowEdit ? 'assets/icon-edit.svg' : 'assets/icon-eye.svg'"></i></b>
            </button>
            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()" *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()" *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <p class="mb-3">
                <span class="text-muted">Номер: </span>
                <ng-container *ngIf="entity.number">{{entity.number}}</ng-container>
                <em *ngIf="!entity.number">не указано</em>

                <span class="text-muted ml-10">Дата: </span>
                <ng-container *ngIf="entity.date">{{entity.date | date: 'shortDate'}}</ng-container>
                <em *ngIf="!entity.date">не указано</em>
            </p>

        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">

            <div  class="pt-15 pl-20 pr-20">
                <!-- Дата/Номер -->
                <div class="row">
                    <div class="col-md-3">
                        <label>Номер</label>
                        <div class="form-group form-group-lg">
                            <input type="text" class="form-control input-xs" [(ngModel)]="entity.number" placeholder="Введите номер" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Дата составления</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker [large]="true" [(ngModel)]="entity.date" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                </div>

                <!-- Краткое содержание -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Краткое содержание</label>
                            <textarea class="form-control" [(ngModel)]="entity.brief" row="5" [disabled]="!allowEdit"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Текст документа -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-lg">
                            <label>Текст документа</label>
                            <textarea class="form-control" [(ngModel)]="entity.text" row="5" [disabled]="!allowEdit"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Получатель -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Кому направляется</label>
                            <input type="text" class="form-control input-xs" [(ngModel)]="entity.organization" [disabled]="!allowEdit">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group form-group-lg">
                            <label>Адрес кому направляется</label>
                            <evolenta-address-gar
                                    [large]="true"
                                    [showIndex]="false"
                                    [disabled]="!allowEdit"
                                    [(ngModel)]="entity.address"
                            ></evolenta-address-gar>
                        </div>
                    </div>
                </div>

                <!-- Лицо, подписавшее документ -->
                <div class="mb-15">
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        [multiple]="false"
                        [property]="'employeeSignatory'"
                        [disabled]="!allowEdit"
                        [title]="'Лицо, подписавшее документ'"
                        [field]="['name','position']">
                    </appeal-entity-employee>
                </div>
            </div>

            <appeal-entity-documents [entity]="entity" [task]="task" [allowEdit]="allowEdit"></appeal-entity-documents>

        </div>
    </div>
</div>
