import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../../appeal-entities.service';
import { SelectService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-entity-documents-field',
    templateUrl: 'entity-documents-field.component.html',
    styles: [
        '::ng-deep .input-group .ui-select-toggle { height: 40px !important; padding-top: 8px !important; }',
        '::ng-deep .input-group .ui-select-toggle > .caret { top: 10px !important; }',
    ],
})
export class AppealEntityDocumentsFieldComponent implements OnInit {
    @Input() public appeal: any;
    @Input() public entity: any;
    @Input() public property: string = '';
    @Input() public placeholder: string = '';
    @Input() public title: string = '';
    @Input() public disabled = false;

    public documents;
    public documentsForSelect;

    public constructor(
        public appealEntitiesService: AppealEntitiesService,
        public selectService: SelectService
    ) {}

    public ngOnInit() {
        this.documents = _.cloneDeep(this.appeal.documents);
        this.documentsForSelect = this.selectService.transformForSelect('guid', 'name', this.documents);
        if (this.property && !this.entity[this.property]) {
            this.entity[this.property] = [this.newItem()];
        }
    }

    public newItem(): object {
        return {
            guid: CommonUtilities.GenerateGuid(),
            document: null,
        };
    }

    public addSlot(): void {
        this.entity[this.property].push(this.newItem());
    }

    public deleteSlot(item): void {
        const findIndex = this.entity[this.property].findIndex((f) => f.guid === item.guid);
        this.entity[this.property].splice(findIndex, 1);
    }

    public getDocumentFromSlot(slotIndex) {
        const document = _.get(this.entity, `${this.property}[${slotIndex}].document`, null);
        if (!document) {
            return [];
        }
        return [this.documentsForSelect.find((d) => d.id === document.guid)];
    }

    public setDocumentToSlot(document, slotIndex) {
        this.entity[this.property][slotIndex].document = this.documents.find((d) => d.guid === document.id);
    }

    public clearSlot(slotIndex) {
        this.entity[this.property][slotIndex].document = null;
    }
}
