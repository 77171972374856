import {
    Component,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EpguIntegrationService } from '../../services/epgu-integration.service';

@Component({
    selector: 'epgu-integration',
    templateUrl: 'epgu-integration.component.html',
    styleUrls: ['./epgu-integration.component.less'],
})
export class EpguIntegrationComponent implements OnInit {

    public constructor(
        private router: Router,
        private epguIntegrationService: EpguIntegrationService,
        private translate: TranslateService,
    ) {
    }

    public initialized;
    public localizations;

    public ngOnInit(): void {
        this._loadTranslations();
    }

    public inMappingCreateMode() {
        return this.router.url.includes('/create-mapping');
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'epgu_integration',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
            this.initialized = true;
        });
    }

    public goToFieldsComplincePage() {
       return null;
    }

    public goToXslGenerationComponent() {
       return null;
    }

    public isCurrentCompliencePage() {
        return false;
    }

    public isCurrentXslGenerationPage() {
        return false;
    }

    public async createMapping() {
        const standardNumber = $('#standard-number').val();

        await this.epguIntegrationService.createMapping();

        return this.router.navigate(['/metodolog/epgu-integration']);
    }
}
