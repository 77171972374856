<div [ngClass]="{'panel panel-shadow m-20': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success heading-btn btn-labeled btn-labeled-right" (click)="apply()" *ngIf="mode == 'edit'">
                <b><i class="icon-checkmark3"></i></b>
                Применить
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'border-bottom border-bottom-default card-line pt-5 pb-5 pl-20 pr-20': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div  [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}" *ngIf="mode == 'view'">
            <ng-container *ngIf="task && mode == 'view'">
                <span class="text-semibold text-uppercase">{{entity.name}}</span>
            </ng-container>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button" title="Редактировать">
                <i class="icon-pencil3"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить">
                <i class="icon-trash"></i>
            </button>
        </div>

        <!-- Визуальные вкладки с данными -->
        <div class="mt-20 mb-20" *ngIf="mode === 'edit'">
            <element-edit-tabs [tabs]="editTabs" [active]="activeTab" (onActivateTab)="activateTab($event)" *ngIf="mode === 'edit'"></element-edit-tabs>
        </div>

        <div *ngIf="mode == 'edit' && activeTab === 'common'">
            <div class="pt-15 pl-20 pr-20 mb-10">
                <form #entityForm="ngForm">
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Нарушенное обязательное требование</label>
                                <catalogue catalogName="mandatoryReqs" name="violatedClaim" [(ngModel)]="entity.violatedClaim" [priority]="true" [large]="true" #violatedClaim="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && violatedClaim.errors" class="validation-error-label">
                                    <span *ngIf="violatedClaim.errors.required">Поле "Нарушенное обязательное требование" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Контрольно-надзорный орган</label>
                                <input type="text" name="kno" class="form-control" [(ngModel)]="entity.kno" #kno="ngModel" required>
                                <div *ngIf="isProcessValidate && kno.errors" class="validation-error-label">
                                    <span *ngIf="kno.errors.required">Поле "Контрольно-надзорный орган" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Профиль риска</label>
                                <catalogue catalogName="riskProfile" name="riskprofile" [(ngModel)]="entity.riskprofile" [priority]="true" [large]="true"></catalogue>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Признаки нарушений</label>
                                <input type="text" name="violationsSigns" class="form-control" [(ngModel)]="entity.violationsSigns" #violationsSigns="ngModel" required>
                                <div *ngIf="isProcessValidate && violationsSigns.errors" class="validation-error-label">
                                    <span *ngIf="violationsSigns.errors.required">Поле "Признаки нарушений" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Ущерб</label>
                                <catalogue catalogName="typeDamage" name="damageRisk" [(ngModel)]="entity.damageRisk" [priority]="true" [large]="true" #damageRisk="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && damageRisk.errors" class="validation-error-label">
                                    <span *ngIf="damageRisk.errors.required">Поле "Ущерб" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Вид контрольно-надзорной деятельности</label>
                                <catalogue catalogName="nsiErpKnmKinds" name="erpKnmKinds" [(ngModel)]="entity.erpKnmKinds" [priority]="true" [large]="true" #erpKnmKinds="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && erpKnmKinds.errors" class="validation-error-label">
                                    <span *ngIf="erpKnmKinds.errors.required">Поле "Вид контрольно-надзорной деятельности" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Тип события</label>
                                <catalogue catalogName="nsiTypeEvents" name="eventType" [(ngModel)]="entity.eventType" [priority]="true" [large]="true" #eventType="ngModel" required></catalogue>
                                <div *ngIf="isProcessValidate && eventType.errors" class="validation-error-label">
                                    <span *ngIf="eventType.errors.required">Поле "Тип события" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Дата события</label>
                                <evolenta-datepicker name="eventDate" [large]="true" [(ngModel)]="entity.eventDate" [valueAsString]="true" #eventDate="ngModel" required></evolenta-datepicker>
                                <div *ngIf="isProcessValidate && eventDate.errors" class="validation-error-label">
                                    <span *ngIf="eventDate.errors.required">Поле "Дата события" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <div class="form-group form-group-lg">
                                <label>Время события</label>
                                <input type="text" name="eventTime" class="form-control" [(ngModel)]="entity.eventTime" #eventTime="ngModel" required>
                                <div *ngIf="isProcessValidate && eventTime.errors" class="validation-error-label">
                                    <span *ngIf="eventTime.errors.required">Поле "Время события" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-12">
                            <div class="form-group form-group-lg">
                                <label>Источник информации</label>
                                <textarea name="sourceInformation" class="form-control" [(ngModel)]="entity.sourceInformation" ></textarea>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Статус события</label>
                                <input type="text" name="eventStatus" class="form-control" [(ngModel)]="entity.eventStatus">
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group form-group-lg">
                                <label>Место события</label>
                                <input type="text" name="eventLocation" class="form-control" [(ngModel)]="entity.eventLocation" #eventLocation="ngModel" required>
                                <div *ngIf="isProcessValidate && eventLocation.errors" class="validation-error-label">
                                    <span *ngIf="eventLocation.errors.required">Поле "Место события" обязательно для заполнения</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="mode == 'edit' && activeTab === 'violations'">
            <appeal-entity-violation-statistic
                [large]="true"
                [entity]="entity"
                [appeal]="appeal"
                [property]="'violations'">
            </appeal-entity-violation-statistic>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity"></appeal-entity-documents>
        </div>
    </div>
</div>
