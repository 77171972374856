import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppealEntityProceedReferral } from './models/appeal-entity-proceed-referral';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash-es';
import { ToasterService } from '@evolenta/core';

@Component({
    selector: 'appeal-entity-proceed-referral',
    templateUrl: 'appeal-entity-proceed-referral.component.html',
})
export class AppealEntityProceedReferralComponent implements OnInit {
    @Input() public entity: AppealEntityProceedReferral;
    @Input() public appeal;
    @Input() public mode;
    @Input() public task;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onDelete = new EventEmitter<object>();

    @ViewChild('entityForm', { static: false }) public entityForm: NgForm;

    public isProcessValidate = false;
    public inspector = {};

    public constructor(private toaster: ToasterService) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {}

    public edit() {
        this.isProcessValidate = false;
        this.onEdit.emit(this.entity);
    }

    public apply() {
        this.isProcessValidate = true;
        if (this.validate()) {
            this.onApply.emit(this.entity);
        } else {
            this.toaster.error('На форме содержатся ошибки');
        }
    }

    public delete() {
        this.onDelete.emit(this.entity);
    }

    public validate(): boolean {
        const isValidForm = this.entityForm.valid;
        const isValidInspector = this.validateInspector();

        return isValidForm && isValidInspector;
    }

    public validateInspector(): boolean {
        const isValidName = !!_.get(this.entity.inspector, 'name.0.id', null);
        const isValidPosition = !!_.get(this.entity.inspector, 'position', null);
        const isValid = isValidName && isValidPosition;

        this.inspector = isValid
            ? { value: this.entity.inspector }
            : {
                  value: this.entity.inspector,
                  errors: { nameRequired: !isValidName, positionRequired: !isValidPosition },
              };

        return isValid;
    }
}
