import { Injectable } from '@angular/core';
import { emailMask } from 'text-mask-addons';

@Injectable()
export class CustomValidatorsService {
    public validators = [
        {
            code: 'snils',
            name: 'СНИЛС',
            mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
            maskGuid: true,
            validateFunction: (snils) => {
                return this.validateSnils(snils);
            },
        },
        {
            code: 'inn',
            name: 'ИНН физического лица',
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maskGuid: true,
            validateFunction: (inn) => {
                return this.validateInn(inn);
            },
        },
        {
            code: 'organizationInn',
            name: 'ИНН организации',
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maskGuid: true,
            validateFunction: (inn) => {
                return this.validateOrganizationInn(inn);
            },
        },
        {
            code: 'kpp',
            name: 'КПП организации',
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maskGuid: true,
            validateFunction: (kpp) => {
                return this.validateKpp(kpp);
            },
        },
        {
            code: 'ogrnip',
            name: 'ОГРН индивидуального предпринимателя',
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maskGuid: true,
            validateFunction: (ogrn) => {
                return this.validateIpOgrn(ogrn);
            },
        },
        {
            code: 'ogrn',
            name: 'ОГРН организации',
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maskGuid: true,
            validateFunction: (ogrn) => {
                return this.validateOgrn(ogrn);
            },
        },
        {
            code: 'cadastralNumber',
            name: 'Кадастровый номер',
            mask: [
                /\d/,
                /\d/,
                ':',
                /\d/,
                /\d/,
                ':',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /[0-9:]/,
                /[0-9:]/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            maskGuid: false,
            validateFunction: (number) => {
                return this.validateCadastralNumber(number);
            },
        },
        {
            code: 'mobile',
            name: 'Мобильный телефон',
            mask: ['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
            maskGuid: true,
            validateFunction: (number) => {
                return this.validateMobile(number);
            },
        },
        {
            code: 'email',
            name: 'Адрес электронной почты',
            mask: emailMask,
            maskGuide: true,
            validateFunction: (email) => {
                return this.validateEmail(email);
            },
        },
        {
            code: 'email',
            name: 'Адрес электронной почты',
            mask: emailMask,
            maskGuide: true,
            validateFunction: (email) => {
                return this.validateEmail(email);
            }
        },
        {
            code: 'bik',
            name: 'БИК',
            mask: ['0', '4', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            maskGuid: true,
            validateFunction: (bik) => {
                return this.validateBik(bik);
            },
        },
        {
            code: 'account',
            name: 'Расчетный счет',
            mask: [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            maskGuid: true,
            validateFunction: (account) => {
                return this.validateBankAccount(account);
            },
        },
        {
            code: 'correspondentAccount',
            name: 'Корреспондентский счет',
            mask: [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            maskGuid: true,
            validateFunction: (account) => {
                return this.validateBankCorrespondentAccount(account);
            },
        },
        {
            code: 'time',
            name: 'Время в формате HH:mm',
            mask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            maskGuid: true,
            validateFunction: (time) => {
                return this.validateTime(time);
            },
        },
        {
            code: 'uin',
            name: 'УИН',
            mask: [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            maskGuid: false,
            validateFunction: (uin) => {
                return this.validateUin(uin);
            },
        },
    ];

    public constructor() {}

    /**
     * Проверка СНИЛС
     * @param snils
     */
    public validateSnils(snils) {
        let error = null;
        if (snils) {
            snils = snils.replace(/-/g, '');
            const snilsArr = snils.split(' ');
            const positionsNumbers = [9, 8, 7, 6, 5, 4, 3, 2, 1];
            const snilsDigits = snilsArr[0].match(/[\S\s]{1,1}/g);
            let controlSum = 0;
            for (let i = 0; i < snilsDigits.length; i++) {
                controlSum += parseInt(snilsDigits[i], 10) * positionsNumbers[i];
            }
            let checkSymb;
            if (controlSum === 100 || controlSum === 101) {
                checkSymb = '00';
            } else if (controlSum > 101) {
                checkSymb = (controlSum % 101).toLocaleString();
            } else if (controlSum < 100) {
                checkSymb = controlSum;
            }

            if (checkSymb === '100') {
                checkSymb = '0';
            }
            if (parseInt(checkSymb, 10) !== parseInt(snilsArr[1], 10)) {
                error = 'Введен некорректный СНИЛС';
            }
        }

        return error;
    }

    /**
     * Проверка корректности ИНН ФЛ
     * @param inn
     */
    public validateInn(inn) {
        let error = null;
        if (inn) {
            if (inn.length !== 12) {
                error = 'Длина ИНН для ФЛ должна быть 12 символов';
            } else {
                const controlNumbers1 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
                const controlNumbers2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
                const innArray = inn.match(/[\S\s]{1,1}/g);
                let controlSum = 0;
                for (let i = 0; i < innArray.length - 1; i++) {
                    controlSum += parseInt(innArray[i], 10) * controlNumbers1[i];
                }
                let controlDigit1 = controlSum % 11;
                if (controlDigit1 > 9) {
                    controlDigit1 = controlDigit1 % 10;
                }
                controlSum = 0;
                for (let j = 0; j < innArray.length; j++) {
                    controlSum += parseInt(innArray[j], 10) * controlNumbers2[j];
                }
                let controlDigit2 = controlSum % 11;
                if (controlDigit2 > 9) {
                    controlDigit2 = controlDigit2 % 10;
                }
                if (
                    controlDigit1 !== parseInt(inn.substr(inn.length - 2, 1), 10) ||
                    controlDigit2 !== parseInt(inn.substr(inn.length - 1, 1), 10)
                ) {
                    error = 'Введен некорректный ИНН';
                }
            }
        }

        return error;
    }

    /**
     * Валидация ИНН организации
     * @param inn
     */
    public validateOrganizationInn(inn) {
        let error = null;
        if (inn) {
            if (inn.length !== 10) {
                error = 'Длина ИНН для ЮЛ должна быть 10 символов';
            } else {
                const controlNumbers = [2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
                const innArray = inn.match(/[\S\s]{1,1}/g);
                let controlSum = 0;
                for (let i = 0; i < innArray.length; i++) {
                    controlSum += parseInt(innArray[i], 10) * controlNumbers[i];
                }
                let controlDigit = controlSum % 11;
                if (controlDigit > 9) {
                    controlDigit = controlDigit % 10;
                }
                if (controlDigit !== parseInt(inn.substr(inn.length - 1, 1), 10)) {
                    error = 'Введен некорректный ИНН';
                }
            }
        }

        return error;
    }

    /**
     * Валидация КПП организации
     * @param kpp
     * @returns {any}
     */
    public validateKpp(kpp) {
        let error = null;
        if (kpp) {
            if (typeof kpp === 'number') {
                kpp = kpp.toString();
            } else if (typeof kpp !== 'string') {
                kpp = '';
            }
            if (kpp.length !== 9) {
                error = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
            } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
                error = 'Неправильный формат КПП';
            }
        }

        return error;
    }

    /**
     * Проверка ОГРН ИП
     * @param ogrn
     * @returns {any}
     */
    public validateIpOgrn(ogrn) {
        let error = null;
        if (ogrn) {
            if (ogrn.length !== 15) {
                error = 'Длина ОГРН для ИП должна быть 15 цифр';
            } else if (ogrn.slice(-1) !== ((ogrn.slice(0, -1) % 13) + '').slice(-1)) {
                error = 'Введен некорректный ОГРН';
            }
        }

        return error;
    }

    /**
     * Проверка ОГРН организации
     * @param ogrn
     */
    public validateOgrn(ogrn) {
        let error = null;
        if (ogrn) {
            if (ogrn.length !== 13) {
                error = 'Длина ОГРН для организации должна быть 13 цифр';
            } else if (ogrn.slice(-1) !== ((ogrn.slice(0, -1) % 11) + '').slice(-1)) {
                error = 'Введен некорректный ОГРН';
            }
        }

        return error;
    }

    /**
     * Проверка кадастрового номера
     * @param number
     */
    public validateCadastralNumber(number) {
        let error = null;
        if (number) {
            if (number.length < 15) {
                error = 'Длина кадастрового номера не может быть меньше 15 символов';
            }
        }

        return error;
    }

    /**
     * Проверка корректности ввода мобильного телефона
     * @param mobile
     */
    public validateMobile(mobile) {
        let error = null;
        if (mobile) {
            mobile = mobile.replace(/\s/g, '').replace('+', '').replace('(', '').replace(')', '').replace(/_/g, '');
            if (mobile.length < 10) {
                error = 'Введен некорректный номер телефона';
            }
        }

        return error;
    }

    /**
     * Проверка корректности электронной почты.
     * @param email
     */
    public validateEmail(email) {
        if (email) {
            const pattern = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/;
            if (!pattern.test(email)) {
                return 'Введен некорректный адрес электронной почты';
            }
        }
        return null;
    }

    /**
     * Проверка БИК
     * @param bik
     */
    public validateBik(bik) {
        let error = null;
        if (bik) {
            bik = bik.replace(/_/g, '');
            if (bik.length < 9) {
                error = 'Длина БИК должна быть 9 цифр';
            } else {
                const lastSymbols = bik.substr(6);
                const lastSymbolsInDigits = parseInt(lastSymbols, 10);
                if (
                    !(
                        [0, 1, 2].indexOf(lastSymbolsInDigits) !== -1 ||
                        (lastSymbolsInDigits > 50 && lastSymbolsInDigits < 999)
                    )
                ) {
                    error = 'Введен некорректный БИК';
                }
            }
        }

        return error;
    }

    /**
     * Проверка расчетного счета
     * @param account
     */
    public validateBankAccount(account) {
        let error = null;
        if (account) {
            account = account.replace(/_/g, '');
            if (account.length < 20) {
                error = 'Длина расчетного счета должна быть 20 цифр';
            }
        }

        return error;
    }

    /**
     * Проверка кореспондентского счета
     * @param account
     */
    public validateBankCorrespondentAccount(account) {
        let error = null;
        if (account) {
            if (account.length < 20) {
                error = 'Длина корреспондентского счета должна быть 20 цифр';
            }
        }

        return error;
    }

    /**
     * Проверка времени в формате HH:mm
     * @param time
     */
    public validateTime(time) {
        let error = null;
        if (time) {
            const currentTime = time.replace(':', '').replace(/_/g, '');
            if (currentTime.length < 4) {
                error = 'Некорректно заполнено время';
            }
        }

        return error;
    }

    /**
     * Проверка корректности УИН
     * @param uin
     */
    public validateUin(uin) {
        let error = null;
        if (uin) {
            if (uin.length !== 20 && uin.length !== 25) {
                error = 'Длина УИН должна составлять 20 или 25 символов';
            } else {
                const uinArray = uin.split('');
                const controlDigit = this.calculateControlDigit(1, uinArray);
                if (controlDigit.toString() !== uinArray[uinArray.length - 1]) {
                    error = 'Введен некорректный УИН';
                }
            }
        }

        return error;
    }

    public calculateControlDigit(controlDigit, digitsArray) {
        let controlSum = 0;
        for (let i = 0; i < digitsArray.length - 1; i++) {
            controlSum += parseInt(digitsArray[i], 10) * controlDigit;
            controlDigit++;
            if (controlDigit === 11) {
                controlDigit = 1;
            }
        }
        controlDigit = controlSum % 11;
        if (controlDigit > 9) {
            return this.calculateControlDigit(3, digitsArray);
        } else {
            return controlDigit;
        }
    }
}
