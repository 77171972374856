<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component"
     *ngIf="mode === 'default'">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            *ngIf="mode==='default'"
            [disabled]="isLoading"
            (click)="save()">
        <b>
            <i class="icon-checkmark-circle"
               [ngClass]="{'flash': isLoading}">
            </i>
        </b>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            {{ getProcessActionMessage() }}
            <label class="badge text-uppercase ml-15 no-margin-bottom"
                   *ngIf="mode == 'default'"
                   [ngClass]="{
                        'bg-success': process.deploymentId,
                        'bg-purple': !process.deploymentId
                     }">
                {{ process.deploymentId ?
                    localizations.processes.uploaded :
                    localizations.processes.has_not_been_loaded }}
            </label>
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">

    <!-- Меню разделов данных -->
    <div class="sidebar sidebar-secondary sidebar-default"
         *ngIf="mode === 'default'">
        <div class="sidebar-content height-100">
            <!-- Вкладки -->
            <div *ngFor="let tab of tabs">
                <div class="sidebar-category"
                     (click)="activateSidebarTab(tab)"
                     [ngClass]="{
                            'active': tab.active,
                            'cursor-pointer' : !tab.active
                         }"
                     *ngIf="tab.use">
                    <div class="category-title">
                        <span>
                            {{ tab.name }}
                        </span>
                        <ul class="icons-list"
                            *ngIf="!tab.active">
                            <li>
                                <i class="icon-arrow-right22"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="p-10 mt-20">
                <button class="btn btn-primary full-width"
                        type="button"
                        *ngIf="process._id && process.file && processType !== 'bpmnProcess'"
                        (click)="loadToCamunda()">
                    {{ localizations.processes.upload_to_camunda }}
                    <i class="icon-spinner2 spinner ml-5"
                       *ngIf="isProcessingLoadToCamunda">
                    </i>
                </button>
            </div>
            <div class="p-10 mt-20">
                <button class="btn btn-primary full-width"
                        type="button"
                        *ngIf="process.image"
                        (click)="openImage(process.image)">
                    {{ localizations.processes.schema }}
                </button>
            </div>
        </div>
    </div>

    <div class="panel-main"
         [ngClass]="{
                'panel-main': mode==='default',
                'full-width': mode !== 'default'
            }">
        <evolenta-scrollbar [class]="mode === 'default' ? 'panel panel-default no-border-top no-border-left no-border-bottom no-border-right no-border-radius no-margin no-padding' : 'panel'">
            <div class="panel panel-default"
                 *ngIf="!isCamundaModellerMode"
                 [ngClass]="{'m-20 panel-shadow': mode !== 'default'}">
                <div class="panel-heading"
                     *ngIf="mode != 'default'">
                    <h4 class="panel-title">
                        {{ localizations.processes.editing }}
                        <div class="text-size-small mt-5">
                            <a class="text-info anchor"
                               *ngIf="process.image"
                               (click)="openImage(process.image)">
                                {{ localizations.processes.schema }}
                            </a>
                        </div>
                    </h4>
                    <div class="heading-elements">
                        <button class="btn btn-success-overline heading-btn btn-labeled"
                                (click)="saveFromEntity()">
                            {{ localizations.common.apply }}
                        </button>
                        <button class="btn btn-danger-overline heading-btn btn-labeled"
                                (click)="cancel()">
                            {{ localizations.common.do_cancel }}
                        </button>
                    </div>
                </div>
                <div class="panel-body"
                     [ngClass]="{'p-20': mode == 'default'}">
                    <form #processForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-xs-12">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.processes.name }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input id="name"
                                           type="text"
                                           class="form-control"
                                           name="title"
                                           #title="ngModel"
                                           [(ngModel)]="process.title"
                                           [disabled]="!allowEdit"
                                           required>
                                    <div class="validation-error-label"
                                         *ngIf="isProcessValidate && title.errors">
                                        <span *ngIf="title.errors.required">
                                            {{ localizations.processes.field_is_required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xs-12">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ localizations.common.level }}
                                    </label>
                                    <ng-select [allowClear]="false"
                                               [items]="levels"
                                               [placeholder]="localizations.processes.select_level"
                                               [active]="getActiveLevel()"
                                               (selected)="selectLevel($event)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="row"
                             *ngIf="reglamentType?.code === '294fz2008' || reglamentType?.code === '248fz2020'">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>
                                        {{ localizations.processes.government_control_kind
                                            + (reglamentType?.code === '248fz2020' ? localizations.processes.for_ervk : '') }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <catalogue name="kndKind"
                                                        [catalogName]="'nsiErpKnmKinds'"
                                                        #kndKind="ngModel"
                                                        required
                                                        [large]="true"
                                                        [baseSearch]="baseSearchKNDKind"
                                                        [returnFields]="['_id', 'code', 'name', (reglamentType.code === '294fz2008' ? 'erpKndKind' : 'ervkKndKind')]"
                                                        [(ngModel)]="process.kndKind">
                                    </catalogue>
                                    <div class="validation-error-label"
                                         *ngIf="isProcessValidate && kndKind.errors">
                                        <span *ngIf="kndKind.errors.required">
                                            {{ localizations.processes.field_is_required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ localizations.processes.code }}
                                    </label>
                                    <input name="id"
                                           type="text"
                                           class="form-control"
                                           readonly
                                           [ngModel]="process.processId">
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- Информация о выгрузке процесса в камунду -->
                    <ng-container *ngIf="process.deploymentId">
                        <hr class="mt-10">
                        <p class="pl-20">
                            <span class="text-semibold">
                                {{ localizations.processes.id_of_uploaded_process }}:
                            </span>
                            {{ process.deploymentId }}
                        </p>
                        <p class="pl-20">
                            <span class="text-semibold">
                                {{ localizations.processes.upload_date }}
                            </span>
                            {{process.deploymentDate | date:'medium'}}
                        </p>
                        <hr *ngIf="processType != 'bpmnProcess'">
                    </ng-container>

                    <ng-container *ngIf="!process.file">
                        <div class="alert alert-warning no-border no-border-radius mt-20">
                            <span class="text-semibold">
                                {{ localizations.common.attention }}
                            </span>
                            {{ localizations.processes.notifications.file_not_attached }}
                        </div>

                        <div>
                            <input id="file"
                                   class="inputfile hide"
                                   name="file"
                                   type="file"
                                   (change)="fileChange($event)"
                                   #fileUpload/>
                            <label class="btn btn-primary-overline btn-lg btn-labeled btn-labeled-right"
                                   for="file">
                                {{ localizations.common.upload }}
                                <b>
                                    <i inlineSVG="assets/icon-attach.svg"></i>
                                </b>
                            </label>
                            <button class="btn btn-primary-overline btn-lg btn-labeled btn-labeled-right ml-20"
                                    type="button"
                                    *ngIf="process._id"
                                    (click)="openModeller()">
                                {{ localizations.common.create }}
                                <b>
                                    <i inlineSVG="assets/icon-plus-round.svg"></i>
                                </b>
                            </button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="process.file">
                        <fieldset>
                            <legend class="text-bold no-margin-bottom">
                                {{ localizations.processes.business_process_file }}
                            </legend>
                            <table class="table table-xxs table-hover">
                                <tbody>
                                    <tr>
                                        <td class="no-border-top">
                                            <span class="text-muted mr-20 text-size-small"
                                                  *ngIf="process.file.dateCreation">
                                                {{ process.file.dateCreation | date: 'shortDate' }},
                                                {{process.file.dateCreation | date: 'shortTime' }},
                                            </span>
                                            <a class="anchor"
                                               (click)="downloadFile(process.file._id, process.file.originalName)">
                                                {{ process.file.originalName || process.file?.name }}
                                            </a>
                                        </td>

                                        <td class="no-border-top text-right p-20">
                                            <a class="btn no-padding-left no-padding-right"
                                               [title]="localizations.processes.edit_in_modeller"
                                               (click)="openModeller()">
                                                <i inlineSVG="assets/icon-edit.svg"></i>
                                            </a>
                                            <input id="newfile"
                                                   class="hide"
                                                   type="file"
                                                   name="new-file"
                                                   (change)="loadNewFile($event)"/>
                                            <label class="btn no-padding-left no-padding-right ml-10"
                                                   for="newfile"
                                                   [title]="localizations.processes.upload_file">
                                                <i inlineSVG="assets/icon-new-item.svg"></i>
                                            </label>
                                            <a class="btn pl-10 no-padding-right"
                                               [title]="localizations.common.delete"
                                               (click)="removeFile(process.file)">
                                                <i inlineSVG="assets/icon-trash.svg"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>

                        <!-- Задачи процесса -->
                        <bpmn-tasks [process]="process"
                                    [type]="processType">
                        </bpmn-tasks>
                    </ng-container>
                </div>
            </div>
            <div class="iframe"
                 *ngIf="isCamundaModellerMode">
                <button id="iframeClose"
                        class="btn btn-default no-border no-border-radius"
                        [title]="localizations.common.close"
                        (click)="closeModeller()">
                    <i class="icon-cross2"></i>
                </button>
                <iframe [attr.src]="camundaModellerUrl | safe"></iframe>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
<ng-template #imageModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.processes.schema }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img width="980"
             [src]="imageToShow">
    </div>
    <div class="modal-footer">
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>
