import { Component, Input, OnInit } from '@angular/core';
import { RestService, TranslateService } from '@evolenta/core';

@Component({
    selector: 'organization-meta',
    templateUrl: './organization-meta.component.html',
})
export class OrganizationMetaComponent implements OnInit {
    @Input() public organization;
    public meta: any = {
        items: [],
        unitCode: null,
        unitId: null,
        parentEntries: 'unitsMeta',
    };
    public localizations;

    public constructor(
        private rest: RestService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.initOrganizationMeta();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'organizations',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация мета данных
     */
    public initOrganizationMeta() {
        if (this.organization._id) {
            this.rest.search('unitsMeta', {search: {search: [{field: 'unitId', operator: 'eq', value: this.organization._id}]}}).then(data => {
                if (data && data.length > 0) {
                    this.meta   = data[0];
                }
            });
        }
    }

    /**
     * Удаление элемента
     * @param index
     */
    public deleteMetaItem(index) {
        this.meta.items.splice(index, 1);
    }

    /**
     * Добавление элемента
     */
    public addMetaItem() {
        this.meta.items.push({
            code: '',
            value: '',
        });
    }

    /**
     * Сохранение мета данных
     */
    public async save() {
        if (this.meta) {
            if (this.organization.code && !this.meta.unitCode) {
                this.meta.unitCode = this.organization.code;
            }
            if (!this.meta.unitId) {
                this.meta.unitId = this.organization._id;
            }
            let method = 'create';
            if (this.meta._id) {
                method = 'update';
            }

            const changedMeta = await this.rest[method]('unitsMeta', this.meta);
            this.meta = Object.assign(this.meta, changedMeta);

        }

        return true;
    }
}
