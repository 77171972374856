<table class="table table-xs" *ngIf="statuses">
    <thead>
    <tr>
        <th class="text-bold">
            {{ localizations.common.operations }}
        </th>
        <th class="text-center"
            *ngFor="let status of statuses">
            <span class="label bg-{{status.theme}}-300">
                {{status.name}}
            </span>
        </th>
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let param of params">
            <td>
                {{param.name}}
            </td>
            <td *ngFor="let status of statuses" class="text-center">
                <div class="checkbox-inline no-padding">
                    <input type="checkbox"
                           class="checkbox"
                           id="statusPermission{{param.code}}{{status.code}}"
                           [checked]="checkSelected(status, param)"
                           (change)="changeSelected(status, param)"
                    >
                    <label for="statusPermission{{param.code}}{{status.code}}" class="no-padding">&nbsp;</label>
                </div>

                <ng-container *ngIf="param.isSettingEditedFields && checkSelected(status, param)">
                    <span class="anchor text-info text-size-small ml-10"
                          (click)="setEditedFields(status)">
                        {{ localizations.standards.edited_fields }}
                    </span>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #editedFieldsSettingsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.standards.edited_fields_settings }}
        </h4>
        <button type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-20"
             *ngIf="checkedTypes.length > 1">
            <div class="radio-inline"
                 *ngFor="let type of checkedTypes">
                <input type="radio"
                       class="radio"
                       id="checkedType{{type.code}}"
                       name="checkedType"
                       [checked]="checkedType == type.shortCode"
                       (change)="changeCheckedType(type)">
                <label for="checkedType{{type.code}}">
                    {{type.name}}
                </label>
            </div>
        </div>
        <common-standard-form-fields-setting [subjectType]="checkedType"
                                             [initFields]="settingEditedFields[checkedType]"
                                             *ngIf="checkedType"
                                             #fieldsSettingComponent></common-standard-form-fields-setting>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-default"
                (click)="applyEditedFields()">
            {{ localizations.common.apply }}
        </button>
        <button type="button"
                class="btn btn-default"
                (click)="modalRef.hide()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>
