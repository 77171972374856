import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RestService, StorageService, ToasterService } from '@evolenta/core';
import { EnvelopesService } from '../services/envelopes.service';

@Injectable()
export class EnvelopeGuard implements CanActivate {

    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private toaster: ToasterService,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const envelopeId = route.params['envelopeId'];

        if (envelopeId) {
            if (this.storage.getItem('userEnvelopesMode') !== null) {
                return this.allowAccess(envelopeId);
            } else {
                return this.rest
                    .search('settings', { search: { search: [{ field: 'name', operator: 'eq', value: 'userEnvelopesMode' }] } })
                    .then((settings: any[]) => {
                        this.storage.setItem('userEnvelopesMode', settings[0] ? settings[0].value : false);

                        return this.allowAccess(envelopeId);
                    });
            }
        } else {
            return true;
        }
    }

    private allowAccess(envelopeId: string) {
        if (this.storage.getItem('userEnvelopesMode')) {
            return this.rest
                .find('envelopes', envelopeId, true, 'shortEnvelope')
                .then((envelope: any) => {
                    const user = this.storage.getItem('user');

                    if (EnvelopesService.AllowAccess(user, envelope, this.storage.getItem('applications'))) {
                        return Promise.resolve(true);
                    } else {
                        this.toaster.error('Доступ запрещён!');

                        return Promise.resolve(false);
                    }
                });
        } else {
            return true;
        }
    }
}
