<!-- Режим редактирования отдельного конверта -->
<ng-container *ngIf="editEnvelope">
    <appeal-envelope [envelope]="editEnvelope"
                     mode="edit"
                     (onApply)="onApplyEnvelope($event)">
    </appeal-envelope>
</ng-container>

<!-- Режим просмотра списка конвертов -->
<div class="p-20" *ngIf="!editEnvelope && envelopes">
    <ng-container *ngFor="let envelope of envelopes">
        <appeal-envelope mode="view"
                         [envelope]="envelope"
                         [printForms]="envelopePrintForms"
                         (onEdit)="onEditEnvelope($event)"
                         (onApply)="onApplyEnvelope($event)"
                         (onDelete)="onDeleteEnvelope($event)">
        </appeal-envelope>
    </ng-container>

    <div class="alert alert-info alert-styled-left alert-bordered" *ngIf="envelopes.length == 0">
        <span class="text-semibold">Внимание!</span>
        В деле не созданы конверты.
        <a class="anchor" (click)="createEnvelope()"
           *ngIf="appealStatusService.allowEdit && accessService.hasAccess([permissions.Appeal_Envelope_Create], true)">создать</a>
    </div>

    <button class="btn btn-primary btn-labeled btn-labeled-right"
            (click)="createEnvelope()" *ngIf="appealStatusService.allowEdit && accessService.hasAccess([permissions.Appeal_Envelope_Create], true)"><b><i class="icon-plus3"></i></b> Добавить конверт</button>
</div>

<!-- модальное окно для подтверждения удаления конверта -->
<ng-template #deleteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Удаление конверта</h4>
        <button type="button" class="close pull-right" aria-label="Закрыть" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Вы действительно хотите удалить конверт <span *ngIf="deletedEnvelope?.auid">№ {{deletedEnvelope.auid}}</span>?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="processDeleteEnvelope()">Удалить</button>
    </div>
</ng-template>
