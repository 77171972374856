import {
    UniversalCollectionPageDelegatesContextInterface,
    UniversalCollectionPageOptionsInterface,
} from '@evolenta/core';
import { RequestPreviewComponent, RequestsService } from '@evolenta/requests-module';
import { ComponentFactoryResolver } from '@angular/core';
import { Permission } from '../../../common/services/permission';
import { DatePipe } from '@angular/common';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';

function getOrgVal(element): void {
    let name;

    if (element.agencies.length === 1) {
        name = get(element, 'agencies[0].organization.name');
        Object.assign(element, { orgNames: name });
    } else {
        name = [];

        element.agencies.forEach((agency) => {
            name.push(get(agency, 'organization.name'));
        });

        Object.assign(element, { orgNames: name.filter((item) => item).join(', ') });
    }
}

export const RequestsListConfig /*: UniversalCollectionPageOptionsInterface*/ = {
    elementsName: 'Список запросов',
    isSelectingAllowed: true,
    isExternalSearchAllowed: true,
    isMarkingAllowed: false,
    isUseItemButtonAllowed: false,
    isCreateButtonAllowed: true,
    createElementText: 'Новый запрос',
    getCanCreateElementFunc: (context: UniversalCollectionPageDelegatesContextInterface) =>
        context.access.hasAccess([Permission.Request_Create]),
    getCreateElementRouteFunc: (moduleUrl: string) => `/${moduleUrl}/requests/create`,
    getEditElementRouteFunc: (moduleUrl, sectionUrl, element) => `/${moduleUrl}/requests/edit/${element._id}`,
    getCollectionNameFunc: () => 'requests',
    defaultSort: null,
    getPreprocessElementFunc: (...[, element]) => ({
        ...element,
        organization: element.agencies.length ? getOrgVal(element) : [],
    }),
    getFilterNameFunc: () => 'requestsFilter',
    getFilterItemsFunc: () => cloneDeep(RequestsService.FiltersPanelItems),
    getBaseSearchFunc: () => [],
    getPreviewComponentFactoryFunc: (resolver: ComponentFactoryResolver) =>
        resolver.resolveComponentFactory(RequestPreviewComponent),
    listItemOptions: {
        status: {
            isShownPrimary: true,
            getPrimaryPropertyFunc: (element, property) => RequestsService.GetStatusProperty(element.stopped, property),
            isShownSecondary: false,
        },
        title: {
            isShownId: false,
            isShownTitle: true,
            getTitleFunc: (element) => element.name,
        },
        details: [
            { title: 'Организация', getValueFunc: (element) => get(element, 'orgNames', null) },
            {
                title: 'Дата создания',
                getValueFunc: (element) => new DatePipe('ru').transform(element.dateCreation, 'shortDate'),
            },
        ],
    },
};
