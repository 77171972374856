<ng-template let-element="element"
             let-isSub="isSub"
             #elementTemplate>
    <td [ngClass]="{
            'text-size-small pl-60': isSub,
            'text-semibold': element.children
        }">
        {{element.id}}
    </td>
    <td [ngClass]="{
            'text-size-small pt-5': isSub,
             'text-semibold': element.children
         }">
        {{element.name}}
    </td>
    <td class="text-center"
        *ngFor="let operation of operations">
        <ng-container *ngIf="!element.children">
            <input id="{{element.id}}{{operation}}"
                   class="checkbox"
                   type="checkbox"
                   name="{{operation}}"
                   [disabled]="!allowEdit"
                   [checked]="element[getOperationProperty(operation, 'field')]"
                   (change)="changeElementProperty(element, getOperationProperty(operation, 'field'))">
            <label for="{{element.id}}{{operation}}">&nbsp;</label>
        </ng-container>
    </td>
</ng-template>

<div class="alert alert-info no-border no-border-radius no-margin"
     *ngIf="!process">
    <span class="text-semibold">
        {{ localizations.common.attention }}
    </span>
    {{ localizations['bpmn-card'].process_didnt_added_to_reglament }}
</div>
<table class="table table-mini table-hover"
       *ngIf="process">
    <thead>
    <tr>
        <th class="text-size-small text-bold">
            {{ localizations.common.code }}
        </th>
        <th class="text-size-small text-bold">
            {{ localizations.common.name }}
        </th>
        <ng-container *ngFor="let operation of operations">
            <th class="text-size-small text-bold text-center">
                {{getOperationProperty(operation, 'name')}}
            </th>
        </ng-container>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let element of elements">
        <tr>
            <ng-container *ngTemplateOutlet="elementTemplate; context:{element: element}">
            </ng-container>
        </tr>
        <ng-container *ngIf="element.children">
            <tr *ngFor="let childElement of element.children">
                <ng-container *ngTemplateOutlet="elementTemplate; context:{element: childElement, isSub: true}">
                </ng-container>
            </tr>
        </ng-container>
    </ng-container>
    </tbody>
</table>

