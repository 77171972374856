<ul *ngIf="variants" class="list-unstyled">
    <li class="ml-10" *ngFor="let subVariant of variants; let subIndex = index;">
        {{mainIndex + 1}}.{{subIndex + 1}}. {{subVariant.name}}<span class="text-muted">{{this.getTermText(subVariant)}}</span>
        <simple-tree-variant *ngIf="subVariant.subVariants" [mainIndex]="mainIndex + subIndex" [variants]="subVariant.subVariants" [terms]="terms"></simple-tree-variant>
    </li>
</ul>





