<div *ngIf="isProcessSelectEntityType"
     [ngClass]="{'bg-primary-50 pt-10 pb-10 pl-20 pr-20': availableForAddEntityTypes.length > 0, 'alert alert-warning': availableForAddEntityTypes.length == 0}">
    <ng-container *ngIf="availableForAddEntityTypes.length > 0">
        <div class="text-bold mb-10">
            {{ localizations['appeals.entities'].select_type }}:
        </div>
        <div class="radio-block"
             *ngFor="let type of availableForAddEntityTypes">
            <input id="type{{type.code}}"
                   class="radio"
                   type="radio"
                   [value]="type"
                   [checked]="entity.type && type.code == entity.type.code"
                   (click)="selectEntityType(type)">
            <label for="type{{type.code}}">
                {{type.name}}
            </label>
        </div>
    </ng-container>
    <ng-container *ngIf="availableForAddEntityTypes.length == 0">
        <span class="text-bold">
            {{ localizations.common.attention }}
        </span>
        {{ localizations['appeals.entities'].absent_in_knm }}
    </ng-container>
</div>

<ng-container *ngIf="!isProcessSelectEntityType">
    <!-- Приказ-распоряжение -->
    <appeal-entity-order
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onEdit)="editEntity($event)"
        (onDelete)="deleteEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.order.code">
    </appeal-entity-order>

    <!-- Акт КНМ -->
    <appeal-entity-act
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        activeTab="entity"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onEdit)="editEntity($event)"
        (onDelete)="deleteEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.act.code">
    </appeal-entity-act>

    <!-- Уведомление субъекту -->
    <appeal-entity-notification
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        *ngIf="entity.type === dataEntityTypes.notification.code"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onCancel)="cancelEntity()"
        (onEdit)="editEntity($event)">
    </appeal-entity-notification>

    <!-- Акт о невозможности проведения проверки -->
    <appeal-entity-act-not-impossible
        [mode]="mode"
        [task]="task"
        [appeal]="appeal"
        [entity]="entity"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.actNotImpossible.code">
    </appeal-entity-act-not-impossible>

    <!-- Мотивированный запрос -->
    <appeal-entity-request-motivated
        [mode]="mode"
        [task]="task"
        [appeal]="appeal"
        [entity]="entity"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.requestMotivated.code">
    </appeal-entity-request-motivated>

    <!-- Требования о представлении пояснений -->
    <appeal-entity-requirement-explanation
        [mode]="mode"
        [task]="task"
        [appeal]="appeal"
        [entity]="entity"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.requirementExplanation.code">
    </appeal-entity-requirement-explanation>

    <!-- Предписание -->
    <appeal-entity-regulation
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        activeTab="entity"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.regulation.code">
    </appeal-entity-regulation>

    <!-- Заявление о согласовании проведения внеплановой выездной проверки -->
    <appeal-entity-check-statement
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.checkStatement.code">
    </appeal-entity-check-statement>

    <!-- Решение прокуратуры -->
    <appeal-entity-violation-decision
        [mode]="mode"
        [task]="task"
        [entity]="entity"
        [appeal]="appeal"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.violationDecision.code">
    </appeal-entity-violation-decision>

    <!-- Направление на экспертизу -->
    <appeal-entity-check-examination
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.checkExamination.code">
    </appeal-entity-check-examination>

    <!-- Задание (Рейд) -->
    <appeal-entity-raid-task
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.raidTask.code">
    </appeal-entity-raid-task>

    <!-- Акт результатов планового (рейдового) осмотра, обследования (Рейд) -->
    <appeal-entity-raid-act
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.raidAct.code">
    </appeal-entity-raid-act>

    <!-- Предостережение о недопустимости нарушения обязательных требований (Рейд) -->
    <appeal-entity-raid-warning
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.raidWarning.code">
    </appeal-entity-raid-warning>

    <!-- Ответ субъекту на возражения (Рейд) -->
    <appeal-entity-raid-answer
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.raidAnswer.code">
    </appeal-entity-raid-answer>

    <!-- Организационно-распорядительный документ (Систематическое наблюдение) -->
    <appeal-entity-observation-document
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationDocument.code">
    </appeal-entity-observation-document>

    <!-- Акт систематического наблюдения (Систематическое наблюдение) -->
    <appeal-entity-observation-act
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationAct.code">
    </appeal-entity-observation-act>

    <!-- Уведомление субъекта о систематическом наблюдении (Систематическое наблюдение) -->
    <appeal-entity-observation-notify
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationNotify.code">
    </appeal-entity-observation-notify>

    <!-- Запрос документов и сведений (Систематическое наблюдение) -->
    <appeal-entity-observation-request
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationRequest.code">
    </appeal-entity-observation-request>

    <!-- Предостережение о недопустимости нарушения обязательных требований (Систематическое наблюдение) -->
    <appeal-entity-observation-warning
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationWarning.code">
    </appeal-entity-observation-warning>

    <!-- Предписание (Систематическое наблюдение) -->
    <appeal-entity-observation-prescription
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationPrescription.code">
    </appeal-entity-observation-prescription>

    <!-- Ответ субъекту на возражения (Систематическое наблюдение) -->
    <appeal-entity-observation-answer
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.observationAnswer.code">
    </appeal-entity-observation-answer>

    <!-- Приказ (распоряжение) (Контрольная закупка) -->
    <appeal-entity-buy-order
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.buyOrder.code">
    </appeal-entity-buy-order>

    <!-- Заявление (извещение) прокуратуре (Контрольная закупка, Внеплановые проверки) -->
    <appeal-entity-buy-notice
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.buyNotice.code">
    </appeal-entity-buy-notice>

    <!-- Акт о проведении контрольной закупки (Контрольная закупка) -->
    <appeal-entity-buy-act
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.buyAct.code">
    </appeal-entity-buy-act>

    <!-- Акт о проведении дистанционной контрольной закупки (Контрольная закупка) -->
    <appeal-entity-buy-act-distance
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.buyActDistance.code">
    </appeal-entity-buy-act-distance>

    <!-- Определение о возбуждении дела об АП (Административное производство) -->
    <appeal-entity-proceed-initiation
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedInitiation.code">
    </appeal-entity-proceed-initiation>

    <!-- Определение о проведении АП (Административное производство) -->
    <appeal-entity-proceed-conducting
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedConducting.code">
    </appeal-entity-proceed-conducting>

    <!-- Определение об истребовании сведений (Административное производство) -->
    <appeal-entity-proceed-information
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedInformation.code">
    </appeal-entity-proceed-information>

    <!-- Протокол об АП (Административное производство) -->
    <appeal-entity-proceed-protocol
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedProtocol.code">
    </appeal-entity-proceed-protocol>

    <!-- Извещение на составление протокола (Административное производство) -->
    <appeal-entity-proceed-protocol-makeup
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedProtocolMakeup.code">
    </appeal-entity-proceed-protocol-makeup>

    <!-- Извещение на рассмотрение протокола (Административное производство) -->
    <appeal-entity-proceed-protocol-review
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedProtocolReview.code">
    </appeal-entity-proceed-protocol-review>

    <!-- Определение о передачи дела об АП в суд (Административное производство) -->
    <appeal-entity-proceed-referral
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedReferral.code">
    </appeal-entity-proceed-referral>

    <!-- Постановление по делу об АП (Административное производство) -->
    <appeal-entity-proceed-decree
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedDecree.code">
    </appeal-entity-proceed-decree>

    <!-- Представление о принятии мер по устранению указанных причин и условий (Административное производство) -->
    <appeal-entity-proceed-action
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedAction.code">
    </appeal-entity-proceed-action>

    <!-- Постановление о прекращении АП (Административное производство) -->
    <appeal-entity-proceed-termination
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.proceedTermination.code">
    </appeal-entity-proceed-termination>

    <!-- Ущерб охраняемой законом ценности -->
    <appeal-entity-damage
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.damage.code">
    </appeal-entity-damage>

    <!-- Принятая мера -->
    <appeal-entity-violation-measure
        [mode]="mode"
        [task]="task"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.violationMeasure.code">
    </appeal-entity-violation-measure>


    <!-- Нарушение -->
    <appeal-entity-nsi-violations
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.nsiViolations.code">
    </appeal-entity-nsi-violations>

    <!-- Событие риска -->
    <appeal-entity-risk-event
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.riskEvent.code">
    </appeal-entity-risk-event>

    <!-- Входящий документ -->
    <appeal-entity-incoming-document
        [mode]="mode"
        [entity]="entity"
        [appeal]="appeal"
        (onApply)="applyEntity()"
        (onDelete)="deleteEntity($event)"
        (onEdit)="editEntity($event)"
        *ngIf="entity.type === dataEntityTypes.incomingDocument.code">
    </appeal-entity-incoming-document>

    <!-- Заключение о соответствии (ГСН) -->
    <appeal-entity-conformity-conclusion
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onEdit)="editEntity($event)"
        (onDelete)="deleteEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.conformityConclusion.code">
    </appeal-entity-conformity-conclusion>

    <!-- Решение об отказе в выдаче заключения о соответствии (ГСН) -->
    <appeal-entity-reject-conformity-conclusion
        [entity]="entity"
        [mode]="mode"
        [appeal]="appeal"
        [task]="task"
        [allowEdit]="allowEdit"
        (onApply)="applyEntity()"
        (onEdit)="editEntity($event)"
        (onDelete)="deleteEntity($event)"
        (onCancel)="cancelEntity()"
        *ngIf="entity.type === dataEntityTypes.rejectConformityConclusion.code">
    </appeal-entity-reject-conformity-conclusion>

</ng-container>
