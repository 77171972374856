import { Component, ViewChild, TemplateRef } from '@angular/core';
import { RestService, StorageService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'leaflet-map-modal',
    templateUrl: 'leaflet-map-modal.component.html',
})
export class LeafletMapModalComponent {
    public modalRef: BsModalRef;
    @ViewChild('modal', { static: true }) public modal: TemplateRef<any>;

    public leafletMapHostUrl;
    public leafletMapUrl;

    public constructor(
        private modalService: BsModalService,
        private storage: StorageService,
        private rest: RestService,
    ) {
    }

    public init(): Promise<void> {
        return new Promise((resolve, reject) => {
            const hostUrl = this.storage.getItem('leafletMapUrl');
            if (!hostUrl) {
                this.rest.search('settings', {search: {search: [{field: 'name', operator: 'eq', value: 'leafletMapUrl'}]}}).then(settings => {
                    this.storage.setItem('leafletMapUrl', settings[0].value);
                    this.leafletMapHostUrl = settings[0].value;
                    resolve();
                });
            } else {
                this.leafletMapHostUrl = hostUrl;
                resolve();
            }
        });
    }

    public showSingleObject(latitude, longitude, options?) {
        let url = `${this.leafletMapHostUrl}?lat=${latitude}&lng=${longitude}&zoom=16`;
        if (options && options.title) {
            url = url + `&title=${encodeURI(options.title)}`;
        }

        this.leafletMapUrl = url;
        this.modalRef = this.modalService.show(this.modal, { backdrop: 'static', class: 'modal-lg' });
    }

    public showTrack(points: {lat: number, lng: number}[], options?) {
        let polyline = '';
        points.forEach((point, index) => {
            polyline += `${index > 0 ? ',' : ''}[${point.lat},${point.lng}]`;
        });
        const url = `${this.leafletMapHostUrl}?lat=${points[0].lat}&lng=${points[0].lng}&zoom=14&polyline=[${polyline}]`;

        this.leafletMapUrl = url;
        this.modalRef = this.modalService.show(this.modal, { backdrop: 'static', class: 'modal-lg' });
    }

    public hide() {
        this.modalRef.hide();
    }
}
