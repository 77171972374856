import { Component, OnInit, Input, ComponentFactoryResolver, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { FilesService, HttpService, SelectionService, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { CommonUtilities, PrintUtilities } from '@evolenta/utilities';
import { LeafletMapModalComponent } from '@components/modal/leaflet-map-modal/leaflet-map-modal.component';
import { AppealRequirementsService } from '../../appeal-requirements.service';
import { AppealSaveService } from '../../appeal-save.service';
import { Config } from '../../../../../common/services/config';
import { AppealService } from '../../appeal.service';
import { AppealData } from '../../appeal.data';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-requirements',
    templateUrl: 'appeal-requirements.component.html',
    styles: [
        '.number { padding: 0 5px; }',
        '.pl-70 { padding-left: 70px; }',
    ],
})
export class AppealRequirementsComponent implements OnInit {
    @Input() public appeal: any; // Формируемое дело
    @Input() public subservice; // услуга по которой формируется дело
    @Input() public printForms;
    @Input() public task;

    public isCustomMode = false;

    public objects = [];

    public checkLists = [];
    public isAllowAddCheckList = false;
    public localizations;

    @ViewChild('map', { read: ViewContainerRef, static: false }) public mapComponentViewContainer: ViewContainerRef;
    private mapComponent: ComponentRef<LeafletMapModalComponent>;

    public constructor(
        private appealRequirementsService: AppealRequirementsService,
        private saveService: AppealSaveService,
        private filesService: FilesService,
        private httpService: HttpService,
        private toaster: ToasterService,
        private selectionService: SelectionService,
        private router: Router,
        private appealService: AppealService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private storage: StorageService,
        private translate: TranslateService,
    ) {
    }

    /**
     * Инициализация компонента
     */
    public async ngOnInit() {
        this._loadTranslations();
        this.saveService.nextSection = 'requirements';
        if (!this.appealService.appeal) {
            this.storage.cacheItem('nextRoute', 'subjects');
            const route = this.router.url.split('/subjects')[0];
            console.log('base route', route);
            await this.router.navigate([route]);
        } else {
            if (!this.appealService.appeal.checkLists) {
                this.appealService.appeal.checkLists = [];
            }
            this.checkLists = this.appealRequirementsService.preparingCheckLists();
            this.isAllowAddCheckList = this._checkAllowAddCheckList();
            if (this.appeal.subservice.kndInfo && this.appeal.subservice.kndInfo.kndType && this.appeal.subservice.kndInfo.kndType.code === '1') {
                this.isCustomMode = true;
            }
            if (this.isAllowAddCheckList) {
                await this.addCheckList();
            }
        }
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.requirements',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    private _checkAllowAddCheckList() {
        let isAllow = false;
        if (this.appeal.objects) {
            this.appeal.objects.forEach(object => {
                const find = this.appeal.checkLists.find(item => item.objectGuid === object.guid);
                if (!find) {
                    isAllow = true;
                }
            });
        }

        return isAllow;
    }

    public async addCheckList() {
        const newCheckList = {
            guid: CommonUtilities.GenerateGuid(),
            subjectGuid: null,
            objectGuid: null,
            questions: this.getQuestions(),
            npaLink: this.getNpaLink(),
        };
        this.checkLists.push(newCheckList);
        this.appeal.checkLists.push(_.cloneDeep(newCheckList));
        await this.selectSubject(this.checkLists[this.checkLists.length - 1]);
        await this.selectObject(this.checkLists[this.checkLists.length - 1]);
        this.isAllowAddCheckList = this._checkAllowAddCheckList();
    }

    public getQuestions() {
        const questions = [];
        let questionIndex = 1;

        if (this.appealService.subservice.checkLists && this.appealService.subservice.checkLists.length > 0) {
            this.appealService.subservice.checkLists.forEach(checkList => {
                if (checkList.questions && checkList.questions.length > 0) {
                    checkList.questions.forEach(question => {
                        const find = questions.find(item => item.id === question._id);
                        if (!find) {
                            questions.push({
                                ...question,
                                auid: questionIndex,
                                id: question.id,
                                text: question.name,
                                answerYes: question.answerYes,
                                answerNo: question.answerNo,
                                answerNotConcidered: question.answerNotConcidered,
                                mandatoryReqs: question.mandatoryReqs,
                                nPA: question.nPA,
                            });
                            questionIndex++;
                        }
                    });
                }
            });
        }

        return questions;
    }

    public getNpaLink() {
        let npaLink = null;
        if (this.appealService.subservice.checkLists && this.appealService.subservice.checkLists.length > 0) {
            npaLink = this.appealService.subservice.checkLists[0].npaLink;
        }

        return npaLink;
    }

    public async selectSubject(checkList) {
        if (this.appeal.subjects.length !== 1) {
            checkList.isProcessSelectSubject = true;

            return;
        }

        const subject = this.appeal.subjects[0];
        checkList.subject = {
            shortHeader: subject.shortHeader,
            header: subject.header,
            guid: subject.guid,
        };
        await this.afterSelectSubject(checkList, subject);
    }

    public async afterSelectSubject(checkList, subject) {
        this.appealRequirementsService.saveCheckListProperty(checkList, 'subjectGuid', subject.guid);
        checkList.isProcessSelectSubject = false;

        await this.saveService.saveAppeal();
    }

    public async afterSelectObject(checkList, object) {
        this.appealRequirementsService.saveCheckListProperty(checkList, 'objectGuid', object.guid);
        checkList.isProcessSelectObject = false;

        await this.saveService.saveAppeal();
    }

    public async selectObject(checkList) {
        if (this.appeal.objects.length !== 1) {
            checkList.isProcessSelectObject = true;

            return;
        }

        const object = this.appeal.objects[0];
        checkList.object = {
            name: object.name,
            address: object.address,
            guid: object.guid,
        };
        await this.afterSelectObject(checkList, object);
    }

    /**
     * Возврат адреса в виде строки
     * @param addressItems
     * @returns {string}
     */
    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    public async selectAction(checkList, question, actionType) {
        if (!question.result) {
            question.result = {};
        }
        if (question.result.answer && question.result.answer === actionType) {
            question.result.answer = null;
        } else {
            question.result.answer = actionType;
        }
        this.appealRequirementsService.processingRequirementAction(checkList, question);

        await this.saveService.saveAppeal();
    }

    public async fileChange(event, checkList, question) {
        const filesList = event.target.files;

        if (!filesList.length) {
            return;
        }

        const file = filesList[0];
        const uploadedFile: any = await this.filesService.uploadFile('appeals', this.appeal._id, file, file.name);
        question.result.files = question.result.files || [];

        question.result.files.push({
            _id: uploadedFile._id,
            originalName: uploadedFile.originalName,
        });
        this.appealRequirementsService.processingRequirementAction(checkList, question);

        await this.saveService.saveAppeal();
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public async downloadFile(id, originalName) {
        await this.filesService.downloadAndSaveFile(id, originalName);
    }

    public async deleteFile(object, requirement, file) {
        requirement.result.files = requirement.result.files.filter(item => item._id !== file._id);
        this.appealRequirementsService.processingRequirementAction(object, requirement);

        await this.saveService.saveAppeal();
    }

    public sendToMobile(checkList) {
        const checkingActPrintFormId = this._getPrintFormId(AppealData.entityTypes.act.code);
        const prescriptionPrintFormId = this._getPrintFormId(AppealData.entityTypes.regulation.code);
        const refExamPrintFormId = this._getPrintFormId(AppealData.entityTypes.checkExamination.code);
        const impVerActPrintFormId = this._getPrintFormId(AppealData.entityTypes.actNotImpossible.code);
        let url = Config.server + Config.api + 'mp/generateDataForMp?mainId=' + this.appeal._id + '&subjectGuid=' + checkList.subjectGuid + '&objectGuid=' + checkList.objectGuid + '&tenantId=10';
        if (checkingActPrintFormId) {
            url += '&checkingActPrintFormId=' + checkingActPrintFormId;
        }
        if (prescriptionPrintFormId) {
            url += '&prescriptionPrintFormId=' + prescriptionPrintFormId;
        }
        if (refExamPrintFormId) {
            url += '&refExamPrintFormId=' + refExamPrintFormId;
        }
        if (impVerActPrintFormId) {
            url += '&impVerActPrintFormId=' + impVerActPrintFormId;
        }
        this.httpService.get(url).then(() => {
            this.toaster.success('Данные успешно отправлены');
        }, error => {
            const errorText = error.error && error.error.description ? error.error.description : error.error.message ? error.error.message : error;
            this.toaster.html(errorText);
        });
    }

    public async selectQuestions(checkList) {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.appeal;
        this.selectionService.selectedItems = checkList.questions ? checkList.questions : [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectQuestions';
        this.selectionService.additionalData = { subservices: this.appealService.subservice, checkList: checkList };
        this.selectionService.compareFields = { field: '_id', compareField: 'id' };
        this.selectionService.transferUrl = ['nsi', 'other', 'controlListQuestions'];

        await this.router.navigate(['selecting', 'dictionary', 'other', 'controlListQuestions']);
    }

    private _getPrintFormId(entityCode) {
        let printFormId = null;
        const printForms = this.appealService.printForms;
        if (!(printForms && printForms.length)) {
            return;
        }

        const foundEntity =  this.appealService.subservice.entities.find(item => item.code === entityCode);
        if (!(foundEntity && foundEntity.docGroupId)) {
            return;
        }

        const group = this.appealService.subservice.documentGroups.find(item => item.code === foundEntity.docGroupId);
        if (!group) {
            return;
        }

        group.docs.forEach(doc => {
            const findPrintForm = printForms.find(printForm => printForm.docs && printForm.docs.find(item => item.docGroupId === group.code && item.docId === (doc.code ? doc.code : doc.guid)));
            if (findPrintForm) {
                printFormId = findPrintForm._id;
            }
        });

        return printFormId;
    }

    public async showTrackOnMap(checkList) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LeafletMapModalComponent);

        this.mapComponent = this.mapComponentViewContainer.createComponent<LeafletMapModalComponent>(componentFactory) as ComponentRef<LeafletMapModalComponent>;

        const track: { lat: number, lng: number }[] = [];

        if (checkList.track && checkList.track.coordinates) {
            checkList.track.coordinates.forEach(point => {
                track.push({ lat: point[1], lng: point[0] });
            });
        }

        await this.mapComponent.instance.init();

        return this.mapComponent.instance.showTrack(track);
    }

    public getTimeFromSeconds(secs) {
        let minutes = Math.floor(secs / 60);

        secs = secs % 60;

        const hours = Math.floor(minutes / 60);

        minutes = minutes % 60;

        return hours.toString().padStart(2, '0') + ' ч. ' +  minutes.toString().padStart(2, '0') + ' мин.' + secs.toString().padStart(2, '0') + ' сек';
    }

    public checkAllowResultValueType(result) {
        return result.value && typeof result.value  === 'string';
    }

    public getCommonResults(checkList) {
        return Object.values(checkList.commonResult.result);
    }

    public async deleteQuestion(checkList, question) {
        checkList.questions = checkList.questions.filter(item => item.guid !== question.guid);
        this.appealRequirementsService.deleteCheckListQuestion(checkList, question);

        await this.saveService.saveAppeal();
    }
}
