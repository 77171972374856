import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { CommonAppealSubservicesService } from '../../../../services/common-appeal-subservices.service';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-appeal-subservice-variant',
    templateUrl: './common-appeal-subservice-variant.component.html',
})
export class CommonAppealSubserviceVariantComponent implements OnInit {
    @Input() public appealSubservice;
    @Input() public subservice = null;
    @Input() public noMargin = false;
    @Output() public onAfterChangeVariant = new EventEmitter<boolean>();

    public serviceVariants = []; // Массив вариантов из описания услуги доступных для выбора
    public selectedVariants = []; // Массив ранее выбранных вариантов

    public data; // описательный объект по услуге

    public isEditVariant = false;

    public constructor(
        private appealSubservicesService: CommonAppealSubservicesService,
        private validateService: CommonAppealValidateService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];

        // Обработка вариантов услуги
        if (this.subservice.variants) {
            this.subservice.variants.forEach((item) => {
                this.serviceVariants.push(cloneDeep(item));
            });
            if (!this.appealSubservice.variant) {
                this.isEditVariant = true;
            }
        }
        // Инициализация ранее выбранного значения
        if (this.appealSubservice.variant) {
            this.selectedVariants = this.appealSubservice.variant.selectedVariants;
        }
    }

    /**
     * Метод выполняющийся при завершении выбора варианта услуги (сохранение выбранных данных в структуру услуги)
     */
    public onEndSelectVariant() {
        const before = this.appealSubservice.variantXsd;
        this.appealSubservice.variant = this.selectedVariants[this.selectedVariants.length - 1];
        this.appealSubservice.variant.selectedVariants = JSON.parse(JSON.stringify(this.selectedVariants));

        // Флаг корректности установки варианта
        this.data.correctVariant = true;

        // XSD-варианта услуги
        let isExistXsdInSelectedVariant = false;
        this.selectedVariants.forEach((variant) => {
            if (variant.xsdLink || variant.xsd) {
                this.data.variantXsd = variant.xsdLink ? variant.xsdLink.xsd : variant.xsd;
                this.data.variantXsdRequired = variant.xsdLink ? variant.xsdLink.required : true;
                isExistXsdInSelectedVariant = true;
            }
        });

        // Если в новом варианте нет XSD
        if (before && !isExistXsdInSelectedVariant) {
            delete this.data.variantXsd;
            delete this.data.variantSchema;
            this.appealSubservice.variantXsd = null;
            this.appealSubservice.variantXsdRequired = false;
            this.appealSubservice.variantXsdValid = true;
            this.appealSubservice.variantXsdData = null;
            this.onAfterChangeVariant.emit(true);
        }

        // Генерация групп объектов в соответствии с выбранным вариантом
        this.appealSubservicesService.generateServiceGroups(this.appealSubservice);

        // Если изменилась XSD, то удаляем связанные данные
        if (this.data.variantXsd && this.data.variantXsd !== this.appealSubservice.variantXsd) {
            delete this.data.variantSchema;
            delete this.appealSubservice.variantXsdValid;
            delete this.appealSubservice.variantXsdData;
            this.appealSubservice.variantXsd = this.data.variantXsd;
            this.appealSubservice.variantXsdRequired = this.data.variantXsdRequired;
            // Уведомляем родительский элемент о изменении варианта услуги с целью переинициализации набора вкладок, если у варианта есть xsd
            this.onAfterChangeVariant.emit(true);
        }

        // Корректировка параметров валидации
        this.validateService.validateAppeal(this.validateService.processValidate);

        // КНМ корректности настройки объектов внутри услуги (группа участников, вид участия, категория)
        this.appealSubservicesService.correctEntitiesDataAfterChangeAppealSubserviceVariant(this.appealSubservice);
        this.isEditVariant = false;
    }

    /**
     * КНМ наличия ошибок
     * @returns {number}
     */
    public checkErrorExist() {
        return false; // this.validateService.getTabErrorsCount(this.appealSubservice.id, 'subservices', 'variant');
    }
}
