import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../../appeal-subservices.service';
import { AppealService } from '../../../../appeal.service';

@Component({
    selector: 'appeal-subservice-additional-data',
    templateUrl: './appeal-subservice-additional-data.component.html',
})
export class AppealSubserviceAdditionalDataComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() public appealSubservice; // Редактируемая услуга дела
    @Input() public subservice; // Описательная услуга
    @Input() public appeal;
    @Input() public tab; // Обрабатываемая вкладка с дополнительными данными
    @Input() public disabled = false;

    @Output() public onAfterSave = new EventEmitter<object>();

    public data; // Описательный объект текущей услуги
    public schema; // XSD-схема услуги
    public variantSchema; // XSD-схема варианта услуги

    public afterSaveNextTab; // Код вкладки, на которую нужно перейти после сохранения данных
    public afterSaveApply; // Флаг необходимости сохранения данных услуги в родительском компоненте (кнопка "Применить")
    public afterSaveCheckChanges; // Флаг необходимости КНМ изменения данных в родительском компоненте (при нажатии кнопки "Назад к списку")

    public processSavingData; // Дата для КНМ завершения всех этапов сохранения и xsd по услуги и xsd по варианту

    @ViewChild('saveBtn', { static: false }) public saveBtn;
    @ViewChild('subserviceDataComponent', { static: false }) public subserviceDataComponents: DynamicFormComponent;
    @ViewChild('variantDataComponent', { static: false }) public variantDataComponents: DynamicFormComponent;

    public externalData: any = {};

    public constructor(
        public validateService: AppealValidateService,
        private appealSubservicesService: AppealSubservicesService,
        private appealService: AppealService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._initData();
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            // В случае, если осуществляется КНМ дела, эмулируем нажатие кнопки сохранить, чтобы подсветились ошибки
            if (this.validateService.processValidate) {
                if (this.subserviceDataComponents) {
                    this.subserviceDataComponents.validate();
                }

                if (this.variantDataComponents) {
                    this.variantDataComponents.validate();
                }
            }

            this._checkXsdDataValid();
        }, 1000);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.tab && !changes.tab.firstChange) {
            this._initData();
            setTimeout(() => this._checkXsdDataValid(), 1000);
        }
    }

    /**
     * Запуск процедуры сохранения данных из родительского компонента (отдельное сохранение)
     * @param tab - код вкладки, на которую нужно перейти после сохранения (при клике на другую вкладку)
     * @param apply - после сохранения переход на метод apply в родительском компоненте (нажатие кнопки "Применить")
     * @param check - после сохранения переход на метод checkChanges в родительском компоненте (нажатие кнопки "Назад к списку")
     */
    public save(tab = null, apply = false, check = false) {
        this.afterSaveNextTab = tab ? tab : null; // после сохранения переход на другую вкладку
        this.afterSaveApply = apply ? apply : false; // после сохранения сохранение данных (кнопка "Применить")
        this.afterSaveCheckChanges = check ? check : false; // после сохранения проверка услуги на изменение (кнопка "Назад к списку")

        // Инициализация данных процесса сохранения
        this._checkXsdDataValid();

        this.appealSubservice.xsdData = this.subserviceDataComponents ? this.data.xsdData : null;
        this.appealSubservice.variantXsdData = this.variantDataComponents ? this.data.variantXsdData : null;

        // Корректировка параметров валидации
        this.validateService.validateSubserviceAdditionalData(this.appealSubservice);
        this.validateService.appeal.subservice = this.appealSubservice;

        // Передача управления в родительский компонент
        this.onAfterSave.emit({
            tab: this.afterSaveNextTab,
            apply: this.afterSaveApply,
            checkChanges: this.afterSaveCheckChanges,
        });
    }

    private _initData() {
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];

        // XSD по услуге
        if (this.subservice.xsd && !this.data.xsdData) {
            this.data.xsdData = {};
        }

        // XSD по варианту услуги
        if (this.appealSubservice.variant && this.appealSubservice.variantXsd && !this.data.variantXsdData) {
            this.data.variantXsdData = {};
        }

        this._prepareExternalData();
    }

    private _checkXsdDataValid() {
        this.appealSubservice.xsdDataValid = !this.subserviceDataComponents || this.subserviceDataComponents.isValid;
        this.appealSubservice.variantXsdDataValid = !this.variantDataComponents || this.variantDataComponents.isValid;
    }

    private _prepareExternalData() {
        this.externalData = {};

        if (this.appealSubservice.variant && this.appealSubservice.variant.mnemonic) {
            this.externalData.variantMnemonic = this.appealSubservice.variant.mnemonic;
        }

        const appeal = this.appealService.appeal;

        if (appeal.subjects && appeal.subjects.length) {
            const subjectGuids = this.appealSubservice.subjects
                .filter(
                    (item) =>
                        item.subKind &&
                        item.subKind.type &&
                        (item.subKind.type.indexOf('applicant') !== -1 || item.subKind.type.indexOf('principal')) !== -1
                )
                .map((item) => item.guid);

            if (subjectGuids.length) {
                this.externalData.subjectTypes = appeal.subjects
                    .filter((item) => item.specialTypeId && subjectGuids.indexOf(item.guid) !== -1)
                    .map((item) => item.specialTypeId);
            }

            this.externalData.subjectTypes = appeal.subjects.map((item) => item.specialTypeId);
        }

        if (this.appealSubservice.externalNumber) {
            this.externalData.externalNumber = this.appealSubservice.externalNumber;
        }

        if (!this.externalData.entity) {
            this.externalData.entity = appeal;
        }
    }
}
