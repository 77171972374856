import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from '../../document.service';
import { EnvelopesService } from '../../../../../../../common/services/envelopes.service';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { Permission } from '../../../../../../../common/services/permission';
import { AccessService } from '@evolenta/core';
import { AppealSaveService } from '../../../../appeal-save.service';

@Component({
    selector: 'appeal-document-preview',
    templateUrl: 'appeal-document-preview.component.html',
})
export class AppealDocumentPreviewComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public mode; // режим работы

    @Output() public onActivateTab = new EventEmitter<any>(); // активация вкладки с данными для редактирования

    public data; // описательный объект обрабатываемого документа
    public allDocumentsData; // описательный объект всех документов дела

    public hasErrors = false;
    public permissions = Permission; // все полномочия системы

    public moduleBaseUrl;

    public constructor(
        public documentService: DocumentService,
        public validateService: AppealValidateService,
        public accessService: AccessService,
        public router: Router,
        private route: ActivatedRoute,
        private appealSaveService: AppealSaveService
    ) {}

    public ngOnInit() {
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.data = this.documentService.data[this.document.guid];
        this.allDocumentsData = this.documentService.data;
    }

    public ngAfterViewInit() {
        this.updateErrors();
    }

    /**
     * Активация вкладки (переход в режим редактирования)
     * @param tab - активируемая вкладка
     */
    public async activateTab(tab, document) {
        // Активация происходит только в случае, если доступно редактирование для дела, либо частичное редактирование для вкладки
        // либо вкладка "Файлы" и файлы есть
        // либо вкладка "Документы дела" и выбраны документы дела
        if (document && document.feedbackDocumentUnchecked) {
            await this._removeUncheckedDocumentIcon(document);
        }
        if (
            (this.documentService.allowPartiallyEditTabs.indexOf(tab) !== -1 && this.data.allowPartiallyEdit) ||
            this.data.allowEdit ||
            (((tab === 'files' && this.data.requestId) || (tab === 'common' && !this.data.requestId)) &&
                (this.data.queue.length > 0 || this.data.allowPartiallyEdit)) ||
            (tab === 'common' && this.data.envelope && this.data.envelope.appealDocuments.length > 0)
        ) {
            this.onActivateTab.emit(tab);
        }
    }

    private async _removeUncheckedDocumentIcon(document) {
        document.feedbackDocumentUnchecked = false;
        const appealSaveServiceDocument = this.appealSaveService.appeal.documents.find(
            (doc) => doc.guid === document.guid
        );
        appealSaveServiceDocument.feedbackDocumentUnchecked = false;

        await this.appealSaveService.saveAppeal(false, false);
    }

    /**
     * Получение параметров для документа-запроса
     * @param property - возвращаемое значение свойства
     * @returns {string}
     */
    public getEnvelopeStatusProperty(property) {
        const envelopeStatus = this.data.envelope.status ? this.data.envelope.status : 'created';

        return EnvelopesService.GetStatusProperty(envelopeStatus, property);
    }

    /**
     * КНМ наличия ошибок в документе
     */
    public updateErrors() {
        this.validateService.validateAppeal(this.validateService.processValidate);
        this.hasErrors = !this.validateService.checkElementValid(this.document.guid, 'documents');
    }

    /**
     * Получение списка ошибок для карточки документа
     * @returns {string}
     */
    public getErrorsList() {
        return this.validateService.getElementErrorsList(this.document.guid, 'documents');
    }

    /**
     * КНМ наличия ошибок в услуге
     */
    public checkErrorsExist() {
        return !this.validateService.checkElementValid(this.document.guid, 'documents');
    }

    public goToEnvelope() {
        this.router.navigate(['supervisor', 'envelopes', 'edit', this.data.envelope._id]);
    }
}
