<!-- Список сведений -->
<div class="m-20"
     *ngIf="!editedEntity">
    <common-standard-block-with-additional-data [block]="activeData"
                                                [showBlockTitle]="false"
                                                [useAdditionalData]="false">
    </common-standard-block-with-additional-data>
    <div class="panel panel-shadow panel-small">
        <div class="panel-heading">
            <h4 class="panel-title pt-10">
                <span class="mr-10">
                    {{metaData.name}}
                </span>
                <common-standard-block-status [block]="activeData"
                                              [small]="true"
                                              *ngIf="standardsService.reglament && !!metaData.entitiesData.allowEditInReglament"
                                              (onToggleEntity)="standardsService.toggleReglamentBlockEntity(activeData)"
                                              (onDeleteReglamentData)="standardsService.deleteReglamentBlock(activeData)">
                </common-standard-block-status>
            </h4>
            <div class="heading-elements">
                <button class="btn btn-default btn-sxs"
                        type="button"
                        [title]="localizations.standards.info.add"
                        *ngIf="activeData.allowEdit"
                        (click)="add()">
                    <i inlineSVG="assets/icon-new-item.svg"></i>
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <div class="alert alert-warning no-margin"
                 *ngIf="!activeData.entity.entities || activeData.entity.entities.length === 0">
                {{ localizations.standards.info.not_added }}
            </div>

            <table class="table table-sm">
                <tbody>
                <tr *ngFor="let item of activeData.entity.entities; let fst=first">
                    <td [ngClass]="{'no-border-top': fst}">
                        {{item.name}}
                    </td>
                    <td class="text-right no-padding-top no-padding-bottom"
                        [ngClass]="{'no-border-top': fst}">
                        <button type="button" class="btn btn-default btn-sxs"
                                [title]="activeData.allowEdit ?
                                    localizations.common.edit : localizations.common.view"
                                (click)="edit(item)">
                            <i [ngClass]="{
                                'icon-pencil': activeData.allowEdit,
                                'icon-eye': !activeData.allowEdit}"></i>
                        </button>
                        <button type="button"
                                class="btn btn-default btn-sxs"
                                title="localizations.common.delete"
                                *ngIf="activeData.allowEdit"
                                (click)="delete(item)">
                            <i class="icon-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>


<!-- Редактирование варианта -->
<common-standard-entity-edit [entity]="editedEntity"
                             [allowEdit]="activeData.allowEdit"
                             [activeData]="activeData"
                             (onSave)="apply()"
                             (onCancel)="cancel()"
                             (onEditXsd)="editXsd($event)"
                              *ngIf="editedEntity">
</common-standard-entity-edit>
