<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component"
     *ngIf="mode === 'default'">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            type="button"
            [disabled]="isLoading || !allowEdit"
            (click)="save()">
        <b>
            <i class="icon-checkmark-circle"
              [ngClass]="{'flash': isLoading}">
            </i>
        </b>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            {{ getPrintFormActionsMessage() }}
        </h4>
    </div>

    <div class="navbar-menu"
         *ngIf="mode==='default'">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">

    <!-- Меню разделов данных -->
    <div class="sidebar sidebar-secondary sidebar-default"
         *ngIf="mode == 'default'">
        <div class="sidebar-content">
            <!-- Вкладки -->
            <div *ngFor="let tab of tabs">
                <div class="sidebar-category"
                     [ngClass]="{
                        'active': tab.active,
                        'cursor-pointer' : !tab.active
                        }"
                     (click)="activateSidebarTab(tab)">
                    <div class="category-title">
                        <span>
                            {{ tab.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{
        'panel-main': mode == 'default',
         'full-width height-100': mode !== 'default'
         }">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border no-border-radius no-margin no-padding">
            <div [ngClass]="{'panel panel-shadow m-20': mode != 'default'}">
                <div class="panel-heading"
                     *ngIf="mode != 'default'">
                    <h4 class="panel-title">
                        {{ localizations['print-forms'].settings }}
                    </h4>
                    <div class="heading-elements">
                        <button class="btn btn-success-o8verline heading-btn btn-labeled"
                                *ngIf="allowEdit"
                                (click)="saveFromStandard()">
                            {{ localizations.common.apply }}
                        </button>
                        <button class="btn btn-danger-overline heading-btn btn-labeled"
                                (click)="cancel()">
                            {{
                                allowEdit ?
                                    localizations.common.do_cancel :
                                    localizations.common.close
                            }}
                        </button>
                    </div>
                </div>
                <div [ngClass]="{
                        'panel-body no-padding-left no-padding-right': mode != 'default',
                        'panel panel-shadow m-20': mode == 'default'
                        }" >
                    <element-edit-tabs [tabs]="tabs"
                                       [active]="activeTab"
                                       *ngIf="mode==='reglament'"
                                       (onActivateTab)="activateTab($event)">
                    </element-edit-tabs>
                    <div [ngClass]="{
                            'pl-20 pt-20 pr-20': mode != 'default',
                            'panel-body': mode == 'default'
                        }">
                        <div class="full-width"
                            *ngIf="activeTab === 'common'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group form-group-lg">
                                        <label class="control-label"
                                               for="shortName">
                                            {{ localizations.common.short_name }}
                                        </label>
                                        <input id="shortName"
                                               class="form-control"
                                               name="position"
                                               type="text"
                                               [disabled]="!allowEdit"
                                               [(ngModel)]="printForm.title">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-lg">
                                        <label class="control-label"
                                               for="fullName">
                                            {{ localizations.subjects.full_name }}*
                                        </label>
                                        <input id="fullName"
                                               class="form-control"
                                               name="fullName"
                                               type="text"
                                               [disabled]="!allowEdit"
                                               [(ngModel)]="printForm.docName">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-sm-4 col-xs-12">
                                    <div class="form-group form-group-lg">
                                        <label class="control-label">
                                            {{ localizations['print-forms'].format }}
                                        </label>
                                        <ng-select [allowClear]="false"
                                                   [items]="formats"
                                                   [active]="getSelectedValue(formats, selectedFormat)"
                                                   [disabled]="!allowEdit"
                                                   (selected)="selectFormat($event)">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-sm-4 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            {{ localizations['print-forms'].activity }}
                                        </label>
                                        <div class="checkbox-block">
                                            <input id="formActive"
                                                   class="checkbox"
                                                   name="formActive"
                                                   type="checkbox"
                                                   [disabled]="!allowEdit"
                                                   [(ngModel)]="isPrintFormInactive" [ngModelOptions]="{ standalone: true }">
                                            <label class="control-label"
                                                   for="formActive">
                                                {{ localizations.common.not_used }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-sm-4 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            {{ localizations['print-forms'].render_options }}
                                        </label>
                                        <div class="checkbox-block">
                                            <input id="isPdfWithImages"
                                                   class="checkbox"
                                                   name="isPdfWithImages"
                                                   type="checkbox"
                                                   [disabled]="!allowEdit"
                                                   [(ngModel)]="isPdfWithImages" [ngModelOptions]="{ standalone: true }">
                                            <label class="control-label"
                                                   for="isPdfWithImages">
                                                {{ localizations['print-forms'].pdf_with_images }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-xs-12"
                                     *ngIf="selectedFormat==='pdf' || selectedFormat==='xml'">
                                    <div class="form-group">
                                        <label class="control-label"
                                               for="xsl">
                                            {{ localizations['print-forms'].xsl_block }}
                                        </label>
                                        <textarea id="xsl"
                                                  class="form-control"
                                                  name="xsl"
                                                  [disabled]="!allowEdit"
                                                  [(ngModel)]="printForm.xsl">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <p>
                                <span class="text-bold">
                                    {{ localizations['print-forms'].template_file }}:
                                </span>
                                <ng-container *ngIf="!printForm.templateFile">
                                    <span class="text-muted ml-10">
                                        {{ localizations.common.not_added_f }}
                                    </span>
                                    <input id="file-{{printForm.document}}"
                                           class="inputfile hide"
                                           type="file"
                                           name="file-{{printForm.document}}"
                                           (change)="fileChange($event)"/>
                                    <label class="anchor text-info ml-10"
                                           for="file-{{printForm.document}}"
                                           *ngIf="allowEdit">
                                        {{ localizations['appeals.requirements'].attach_file }}
                                    </label>
                                </ng-container>

                                <span class="ml-10"
                                      *ngIf="printForm.templateFile">
                                    <a class="anchor"
                                       (click)="downloadFile(printForm.templateFile._id, printForm.templateFile.originalName)" title="скачать">
                                         {{ printForm.templateFile?.file?.name || printForm.templateFile.originalName }}
                                    </a>
                                    <span class="anchor text-info"
                                          *ngIf="!printForm.templateFile._id"
                                          (click)="openFile(printForm.templateFile)">{{ printForm.templateFile.name }}</span>
                                    <span class="anchor text-danger ml-10"
                                          *ngIf="allowEdit"
                                          (click)="removeFile()">
                                        {{ localizations.common.delete_small }}
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div class="full-width"
                             *ngIf="activeTab === 'xsd'">
                            <div class="pt-20 pl-20 pr-20 pb-10">
                                <print-form-xsd [xsd]="printForm.xsd"
                                                [allowEdit]="allowEdit"
                                                (saveXsd)="saveXsd($event)">
                                </print-form-xsd>
                            </div>
                        </div>
                        <div class="full-width"
                             *ngIf="activeTab === 'xsl'">
                            <print-form-xsl [xsl]="printForm.xslFields"
                                            [allowEdit]="allowEdit"
                                            (updateXslList)="updateXslList($event)">
                            </print-form-xsl>
                        </div>
                    </div>
                </div>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
