<div class="p-20">
    <div class="panel panel-shadow panel-small" *ngFor="let group of groups; let lst = last" [ngClass]="{'no-margin-bottom': lst}">
        <div class="panel-heading">
            <h4 class="panel-title">
                {{group.name}}
                <div>
                    <!-- Индикатор числа добавленных и необходимых документов внутри группы -->
                    <span class="badge bg-primary-300">{{getCountSubjectsInGroup(group)}}&nbsp;/&nbsp;{{group.minCount}}</span>
                </div>
            </h4>
            <div class="heading-elements visible-elements">
                <button class="btn btn-success-overline heading-btn no-margin pt-10 pb-10"
                        [disabled]="group.maxCount && getCountSubjectsInGroup(group) >= group.maxCount"
                        (click)="addSubject(group)">
                    <i class="icon-plus2"></i>
                </button>
            </div>
        </div>
        <div class="panel-body no-padding">
            <ng-container *ngFor="let subject of subjects">
                <div class="border-bottom border-bottom-default card-line"
                     *ngIf="data[subject.guid] && data[subject.guid].active && data[subject.guid].group && data[subject.guid].group.guid == group.guid">
                    <div class="display-inline-block card-preview">
                        <p class="mb-3">
                            <span class="text-semibold">{{subject.header}}</span>
                            <!-- Выбранный вид участия -->
                            <ng-container *ngIf="data[subject.guid].subKind">
                                <span class="text-muted ml-10 mr-5">Вид участия: </span>
                                <span class="anchor"
                                      [ngClass]="{'text-danger': validateService.processValidate,
                                                  'text-info': !validateService.processValidate}"
                                      *ngIf="!data[subject.guid].subKind">не выбран</span>
                                <ng-container *ngIf="data[subject.guid].subKind">
                                    {{data[subject.guid].subKind.firstGroupName}}
                                    <span class="anchor text-info text-size-small ml-5 hide"
                                          *ngIf="data[subject.guid].subKinds.length > 1"
                                          (click)="changeSubjectSubKind(subject)">изменить</span>

                                    <div class="display-inline-block ml-5" *ngIf="data[subject.guid].subKind.type.indexOf('principal') != -1">
                                        <span class="text-muted ml-10">Представитель: </span>
                                        <!--span class="anchor text-size-small"
                                              [ngClass]="{'text-danger': validateService.processValidate,
                                                          'text-info': !validateService.processValidate}"
                                              *ngIf="!data[subject.guid].representative"
                                              (click)="changeRepresentative(subject)">не выбран</span-->
                                        <span *ngIf="!data[subject.guid].representative">не выбран</span>
                                        <ng-container *ngIf="data[subject.guid] && data[subject.guid].representative">
                                            {{subjectsData[data[subject.guid].representative.guid].shortHeader}}
                                            <span *ngIf="data[subject.guid].representative.type"> - {{data[subject.guid].representative.type.name}}</span>
                                            <span class="anchor text-info text-size-small ml-5 hide"
                                                  (click)="changeRepresentative(subject)">изменить</span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </p>
                        <!-- Выбранная категория объекта -->
                        <p class="mb-3" *ngIf="data[subject.guid].categories">
                            <span class="text-muted mr-5">Категория</span>
                            <span class="anchor"
                                  [ngClass]="{'text-danger': validateService.processValidate, 'text-info': !validateService.processValidate}"
                                  *ngIf="!data[subject.guid].categories || data[subject.guid].categories.length == 0" (click)="changeSubjectCategory(subject)">не выбрана</span>
                            <ng-container *ngIf="data[subject.guid].categories && data[subject.guid].categories.length > 0">
                                <ng-container *ngFor="let category of data[subject.guid].categories; let lst = last">
                                    {{category.name}}
                                    <span *ngIf="!lst" class="ml-5 mr-5">/</span>
                                </ng-container>
                                <span class="anchor text-info text-size-small ml-5" (click)="changeSubjectCategory(subject)">изменить</span>
                            </ng-container>
                        </p>

                        <!-- Выбор вида участия -->
                        <div class="bg-primary-50 p-10 mt-10 mb-10" *ngIf="data[subject.guid].editSubKind">
                            <div class="text-semibold mb-10">Выберите вид участия:</div>
                            <div class="radio-block"
                                 *ngFor="let subKind of data[subject.guid].subKinds">
                                <input type="radio" class="radio"
                                       [checked]="data[subject.guid].subKind &&
                                          data[subject.guid].subKind.guid == subKind.guid"
                                       id="subKind{{subKind.guid}}{{subject.id}}">
                                <label for="subKind{{subKind.guid}}{{subject.id}}"
                                       (click)="selectSubjectSubKind(subject, subKind)">
                                    {{subKind.firstGroupName}}
                                </label>
                            </div>
                        </div>

                        <!-- Выбор категории объекта -->
                        <div class="bg-primary-50 p-10  mt-10 mb-10" *ngIf="data[subject.guid].editCategory">
                            <div class="text-semibold mb-10">Выберите категорию объекта: </div>
                            <div style="margin-left: -15px;">
                                <tree-select [treeArray]="data[subject.guid].categories"
                                             [elements]="data[subject.guid].categoriesForSelect"
                                             [limitArray]="data[subject.guid].group.categoryGuids"
                                             [updateElements]="data[subject.guid].categories"
                                             [additionalFields]="['xsd', 'xsdLink', 'code']"
                                             nodeName="name"
                                             subNodes="subCategory"
                                             (onEnd)="selectSubjectCategories($event, subject)"></tree-select>
                            </div>
                        </div>

                        <!-- Выбор представителя -->
                        <div class="bg-primary-50 p-10 mt-10 mb-10" *ngIf="data[subject.guid].editRepresentative">
                            <div class="text-semibold mb-10">Выберите представителя: </div>
                            <ng-container *ngFor="let representativesubject of subjects">
                                <div class="radio-block" *ngIf="subject.guid != representativesubject.guid">
                                    <input type="radio" class="radio"
                                           [checked]="data[subject.guid].representative &&
                                                      data[subject.guid].representative.guid == representativesubject.guid"
                                           id="representative{{representativesubject.guid}}{{subject.guid}}">
                                    <label for="representative{{representativesubject.guid}}{{subject.guid}}"
                                           (click)="selectRepresentative(subject, representativesubject)">
                                        {{representativesubject.shortHeader}}
                                    </label>
                                </div>
                            </ng-container>
                            <p class="text-danger" *ngIf="getOtherSubjectsCount(subject) == 0">
                                <span class="text-semibold">Внимание!</span> Для выбора представителя добавьте в дело другие объекты
                            </p>
                        </div>

                        <!-- Выбор вида представителя -->
                        <div class="bg-primary-50 p-10 mt-10 mb-10" *ngIf="data[subject.guid].editRepresentativeType">
                            <div class="text-semibold mb-10">Выберите вид представительства: </div>
                            <div class="radio-block" *ngFor="let representativeType of representativeTypes">
                                <input type="radio" class="radio"
                                       [checked]="data[subject.guid] && data[subject.guid].representative &&
                                                  data[subject.guid].representative.type == representativeType"
                                       id="representativeType{{representativeType.id}}{{subject.guid}}">
                                <label for="representativeType{{representativeType.id}}{{subject.guid}}"
                                       (click)="selectRepresentativeType(subject, representativeType)">
                                    {{representativeType.name}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="display-inline-block card-buttons text-right">
                        <button class="btn btn-default btn-sxs" (click)="deleteSubjectData(subject)" type="button" title="Удалить">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </ng-container>

            <div class="bg-primary-50 p-10" *ngIf="group.isSelectOrAddSubject">
                <span class="text-info anchor" (click)="selectSubjectFromAvailable(group)">выбрать участника из имеющихся</span> или <span class="text-info anchor" (click)="addNewSubjectToGroup(group)">добавить нового участника</span>
            </div>

            <!-- Выбор участника -->
            <div class="bg-primary-50 p-10" *ngIf="group.editsubject">
                <div class="text-semibold mb-10">Выберите участника/объект из списка:</div>
                <div class="radio-block" *ngFor="let subject of group.availableSubjects">
                    <input type="radio" class="radio"
                           id="obj{{subject.guid}}{{group.guid}}">
                    <label for="obj{{subject.guid}}{{group.guid}}"
                           (click)="selectSubject(subject, group)">
                        {{subject.header}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
