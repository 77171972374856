import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { RestService, SelectionService, TranslateService, UsersService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'common-bpmn-card-tasks',
    templateUrl: './common-bpmn-card-tasks.component.html',
})
export class CommonBpmnCardTasksComponent implements OnInit {
    @Input() public tasks;
    @Input() public type;
    @Input() public tasksName;
    @Input() public isCanChange = false;
    @Input() public process;
    @Input() public allowEdit = true;
    @Input() public entityProcessData = null;

    @Output() public onCreateSubProcess = new EventEmitter<any>();
    @Output() public onSearchSubProcess = new EventEmitter<any>();
    @Output() public onDeleteSubProcess = new EventEmitter<any>();
    @Output() public onEditSubProcess = new EventEmitter<any>();

    public panels = {};
    public showTasks = true;
    public dmnProcesses = {};

    public processingTask;
    public processingSettings = {
        roles: [],
        specialComponent: [],
        historyText: '',
    };
    public modalRef: BsModalRef;
    @ViewChild('taskSettingsModal', { static: false }) public taskSettingsModal: TemplateRef<any>;

    public roles = [];
    public transformedRoles = [];

    public specialComponents = [
        {
            id: 'setExecutor',
            text: 'Назначение исполнителя',
        },
        {
            id: 'transferToUnit',
            text: 'Передача в другое подразделение',
        },
    ];

    public usedEntityProcessData;
    public usedType;
    public localizations;

    public constructor(
        private selectionService: SelectionService,
        private router: Router,
        private rest: RestService,
        private modalService: BsModalService,
        private usersService: UsersService,
        private translate: TranslateService
    ) {}

    public async ngOnInit() {
        this._loadTranslations();
        if (this.isCanChange) {
            this.tasks.forEach((item) => {
                this.panels[item.id] = false;
            });
        }
        if (this.entityProcessData) {
            this.usedType = this.type === 'userTasks' || this.type === 'subProcess' ? 'tasks' : this.type;
            this.usedEntityProcessData =
                (this.type === 'userTasks' && this.entityProcessData['tasks']) || this.entityProcessData[this.type];
        }
        if (this.type === 'businessRuleTasks') {
            if (this.selectionService.isProcessSelect) {
                const processingTask = this.tasks.find(
                    (item) => item.guid === this.selectionService.transferObjectChild.guid
                );
                this.dmnProcesses = cloneDeep(this.selectionService.additionalData.dmnProcesses);
                if (this.selectionService.selectedItems.length > 0) {
                    processingTask.dmn = this.selectionService.selectedItems[0]._id;
                    this.dmnProcesses[processingTask.dmn] = this.selectionService.selectedItems[0];
                } else {
                    processingTask.dmn = null;
                }
                this.selectionService.clearData();
            } else {
                this.processingDmn();
            }
        }
        this.roles = await this.usersService.getRolesMnemonics();
        this.transformedRoles = this.roles.map((item) => ({ id: item.code, text: item.name }));
    }

    private _loadTranslations() {
        this.translate.get(['common', 'bpmn-card']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public initProcessingSettings() {
        this.processingSettings = {
            roles: [],
            specialComponent: [],
            historyText: '',
        };
    }

    public searchProcess(taskId) {
        this.onSearchSubProcess.emit(taskId);
    }

    public addNew(taskId) {
        this.onCreateSubProcess.emit(taskId);
    }

    public toggleRow(task) {
        if (this.isCanChange) {
            this.panels[task.id] = !this.panels[task.id];
        }
    }

    public deleteProcess(task) {
        this.onDeleteSubProcess.emit(task.guid);
    }

    public editProcess(task) {
        this.onEditSubProcess.emit({ taskId: task.id, process: this.process });
    }

    public processingDmn() {
        const dmnIds = this.tasks.filter((item) => item.dmn).map((item) => item.dmn);
        if (dmnIds.length > 0) {
            this.rest
                .search('sperDmn', { search: { search: [{ field: '_id', operator: 'in', value: dmnIds }] } })
                .then((dmn) => {
                    dmn.forEach((dmnItem) => {
                        this.dmnProcesses[dmnItem._id] = dmnItem;
                    });
                });
        }
    }

    public selectDmn(task) {
        this.selectionService.isProcessSelect = true;
        this.selectionService.selectedItems = task.dmn ? [this.dmnProcesses[task.dmn]] : [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectDmn';
        this.selectionService.transferObject = this.process;
        this.selectionService.transferObjectChild = task;
        this.selectionService.selectType = 'one';
        this.selectionService.additionalData = { dmnProcesses: cloneDeep(this.dmnProcesses) };
        this.router.navigate(['processes', 'business-rules']);
    }

    public setupTask(task) {
        this.processingTask = task;
        this.initProcessingSettings();
        const taskData = this.getTaskData(task);
        if (taskData) {
            this.processingSettings = Object.assign(this.processingSettings, taskData);
            if (taskData.roles) {
                this.processingSettings.roles = this.transformedRoles.filter(
                    (item) => taskData.roles.indexOf(item.id) !== -1
                );
            }
            if (taskData.specialComponent) {
                this.processingSettings.specialComponent = this.specialComponents.filter(
                    (item) => item.id === taskData.specialComponent
                );
            }
            if (taskData.historyText) {
                this.processingSettings.historyText = taskData.historyText;
            }
            this.modalRef = this.modalService.show(this.taskSettingsModal, { backdrop: 'static' });
        } else {
            console.log('данные задачи не найдены');
        }
    }

    public getTaskData(task) {
        const data = this.entityProcessData[this.usedType];
        return data ? data.find((item) => item.id === task.id) : null;
    }

    public applyTaskSettings() {
        const taskData = this.getTaskData(this.processingTask);
        if (this.processingSettings.roles && this.processingSettings.roles.length > 0) {
            taskData.roles = this.processingSettings.roles.map((item) => item.id);
        } else {
            delete taskData.roles;
        }
        if (this.processingSettings.specialComponent && this.processingSettings.specialComponent.length > 0) {
            taskData.specialComponent = this.processingSettings.specialComponent[0]['id'];
        } else {
            delete taskData.specialComponent;
        }
        if (this.processingSettings.historyText) {
            taskData.historyText = this.processingSettings.historyText;
        } else {
            delete taskData.historyText;
        }
        this.modalRef.hide();
    }

    public getTaskInfo(task) {
        const infoItems = [];
        const taskData = this.getTaskData(task);
        if (taskData && taskData.roles && taskData.roles.length > 0) {
            infoItems.push(
                '<span class="text-muted">Роли: </span>' +
                    this.roles
                        .filter((item) => taskData.roles.indexOf(item.code) !== -1)
                        .map((item) => item.name)
                        .join(', ')
            );
        }
        if (taskData && taskData.specialComponent) {
            const find = this.specialComponents.find((item) => item.id === taskData.specialComponent);
            if (find) {
                infoItems.push('<span class="text-muted">Специальный компонент: </span>' + find.text);
            }
        } else if (!taskData) {
            console.log('problem task', task);
        }
        return infoItems.length > 0 ? infoItems.join('<br>') : '';
    }

    public getEntityProcessData(task) {
        const findData = this.getTaskData(task);
        return findData ? findData.subProcess : null;
    }
}
