import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ViewChildren,
    QueryList,
    TemplateRef,
    OnDestroy,
} from '@angular/core';
import { RestService, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppealSaveService } from '../../appeal-save.service';
import { AppealProcessService } from '../process/appeal-process.service';
import { AppealProcessTaskCardComponent } from '../process/components/task-card/appeal-process-task-card.component';
import { AppealService } from '../../appeal.service';
import { ProcessService } from '../../../../processes/process.service';
import { DocumentService } from '../documents/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { CAMUNDA_BUSINESS_INFO_COLLECTION } from '../../../../../common/constants';

@Component({
    selector: 'appeal-process-tasks',
    templateUrl: './appeal-process-tasks.component.html',
    styleUrls: ['./appeal-process-tasks.component.css'],
})
export class AppealProcessTasksComponent implements OnInit, OnDestroy {
    @Input() public printForms;
    @Input() public mode;
    @Input() public tasks; // массив задач

    public editedTask;

    public serviceTasks = [];
    public timers = [];
    public eventGateways = [];

    public eventSubscriptions = [];

    public subservice;
    public appealSubservice;
    public appeal;

    @ViewChildren('taskCard') public taskCardComponents: QueryList<AppealProcessTaskCardComponent>;

    public isUpdateTasksProcess = false;

    public allTasks = [];
    public isShowAllInfoPanel = true;
    public processProgress;
    public globalEvents = [];
    public showAdditionalData = false;

    public modalRef: BsModalRef;
    @ViewChild('setResponsibleForAppealModal', { static: false }) public setResponsibleForAppealModal: TemplateRef<any>;
    public modalTitle;
    public usersToSelect;
    public currentAssignee;
    public assigneeUser;
    public isActiveTasksState;
    public controlOperatorsPage = 0;
    public noMoreControlOperators = false;
    public controlOperatorsInitialized = false;
    public localizations;

    @Output() public afterCompleteProcess = new EventEmitter<boolean>();

    private processTasksEntryStatusChangedSubscription: Subscription = null;
    public feedbackBlockOpened = true;
    public feedbackMessages;
    public messagesUpdateTimeout;

    public constructor(
        public appealProcessService: AppealProcessService,
        public appealService: AppealService,
        private route: ActivatedRoute,
        private appealSaveService: AppealSaveService,
        private toaster: ToasterService,
        private processService: ProcessService,
        private storage: StorageService,
        private documentService: DocumentService,
        private modalService: BsModalService,
        private router: Router,
        private translate: TranslateService,
        private restService: RestService,
    ) {
    }

    public async ngOnInit() {
        this.appealService.editing = false;
        this._loadTranslations();
        this.isActiveTasksState = this.router.url.includes('active-tasks');
        const thisRoute = this.isActiveTasksState ? 'active-tasks' : 'completed-tasks';
        this.appealSaveService.nextSection = thisRoute;
        this.tasks = this.isActiveTasksState ? this.appealProcessService.activeTasks : this.appealProcessService.completedTasks;
        if (!this.tasks || !this.tasks.length) {
            this.storage.cacheItem('nextRoute', thisRoute);

            return;
        }

        this.subservice = this.appealService.subservice;
        this.appealSubservice = cloneDeep(this.appealService.appeal.subservice);
        this.appealSubservice.xsdData = this.appealSubservice.xsdData || {};
        this.appeal = this.appealService.appeal;
        await this._prepareMessages();
        if (!this.appeal) {
            this.storage.cacheItem('nextRoute', thisRoute);
            const route = this.router.url.split(`/${thisRoute}`)[0];
            await this.router.navigate([route]);
        } else {
            this._initTasks();
        }
    }

    private _initTasks() {
        this.route.url.subscribe(async () => {
            if (this.appealProcessService.updateTasksInterval) {
                clearInterval(this.appealProcessService.updateTasksInterval);

                this.appealProcessService.updateTasksInterval = setInterval(() => {
                    this._updateTasksList();
                }, 10000);
            }

            this._prepareTasks();
            this._getGlobalEvents();

            // подписываемся на изменения поля entryStatusChangedAt
            this.processTasksEntryStatusChangedSubscription = this.appealProcessService.processTasksEntryStatusChanged
                .subscribe(async () => {
                   await this.appealService.refreshAppealAfterChangeStatus();
                   this.documentService.correctSubserviceDocGroups();
                });

            if (this.appeal.controlOperator && !Array.isArray(this.appeal.controlOperator)) {
                await this._getAssigneeUser();
            }

            await this._getUsersToSelect();
        });
    }

    public ngOnDestroy(): void {
        if (this.processTasksEntryStatusChangedSubscription) {
            this.processTasksEntryStatusChangedSubscription.unsubscribe();
        }
        if (this.messagesUpdateTimeout !== null) {
            clearTimeout(this.messagesUpdateTimeout);
        }
    }

    public _loadTranslations() {
        this.translate.get(
            [
                'common',
                'appeals.tasks',
                'appeals.widget_feedback',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    private _prepareTasks() {
        if (this.isActiveTasksState) {
            this.serviceTasks = this.appealProcessService.activeServiceTasks;
            this.timers = this.appealProcessService.processTimers.filter(item => moment(item.endTime).unix() > moment().unix());
            this.eventGateways = this.appealProcessService.eventGateways.filter(item => item.status === 'ACTIVE');
            if (this.eventGateways.length > 0) {
                this._getEventSubscriptions();
            }
        } else {
            this.serviceTasks = this.appealProcessService.completedServiceTasks;
        }
        this.allTasks = this.tasks.concat(this.serviceTasks).concat(this.timers);
        this._calculateProgress();
        this.allTasks = this.allTasks.sort((a, b) => {
            const aCompareField = a.type === 'USER_TASK' ? moment(a.created).unix() : moment(a.startTime).unix();
            const bCompareField = b.type === 'USER_TASK' ? moment(b.created).unix() : moment(b.startTime).unix();
            if (aCompareField < bCompareField) {
                return 1;
            } else if (aCompareField > bCompareField) {
                return -1;
            }

            return 0;
        });
    }

    private async _getEventSubscriptions() {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const eventSubscriptions = await this.processService.getEventSubscriptions(this.appealSubservice.camundaProcessInfo.id);
        this.eventSubscriptions = eventSubscriptions.filter(event => {
            if (event.activityInfo && event.activityInfo.extensions && event.activityInfo.extensions.length > 0) {
                const isSystem = event.activityInfo.extensions.filter(item => item.name === 'isSystem' && item.value === 'true').length > 0;
                const orgRole = event.activityInfo.extensions.find(item => item.name === 'orgRole');
                const organizationRole = orgRole ? orgRole.value : null;

                return !isSystem && !organizationRole
                    || (organizationRole === 'initiator' && this.appealService.appeal.unit.id === currentOrganization._id
                        || organizationRole === 'executor' && this.appealService.appeal.organizationExecutor
                        && this.appealService.appeal.organizationExecutor.id === currentOrganization._id);
            }

            return event.eventName !== 'Неожиданное уведомление';
        });
    }

    public async applyTask() {

        await this.appealSaveService.saveAppeal();
        this.editedTask = null;
    }

    public editTask(task) {
        this.editedTask = task;
    }

    public async completeTask(data) {
        if (this.messagesUpdateTimeout) {
            clearTimeout(this.messagesUpdateTimeout);
        }

        const task = data.task;
        let currentTaskCard;
        this.taskCardComponents.forEach(taskCard => {
            if (taskCard.task.id === task.id) {
                currentTaskCard = taskCard;
            }
        });

        this.appeal = await this.appealSaveService.saveAppeal();
        const errors = this.appealProcessService.validateTask(task, data.isValidAdditionalData);
        if (errors) {
            currentTaskCard.isProcessCompleteTask = false;
            this.toaster.error(errors);
        } else {
            const variablesParams = this.appealProcessService.getTaskVariables(task);
            await this.processService.completeCamundaTask(task.id, this.appeal._id, this.appealSubservice.guid, task.camundaBusinessInfoId, 'appeals.subservices', variablesParams);
            // обновляем статусы дела и услуг сразу после завершения пользовательской задачи,
            // т.к. в БП может быть смена статуса в синхронной служебной задачи
            await this._updateStatuses();

            /*setTimeout(async () => {
                this.appeal = await this.restService.find('appeals', this.appeal._id);
                this.appealService.appeal = this.appeal;
            }, 500);*/
            await this._checkProcessActive(task);
        }

        await this._prepareMessages();
    }

    private async _checkProcessActive(task = null) {
        try {
            if (!this.appealSubservice.camundaProcessInfo) {
                setTimeout(async () => {
                    this.appeal = await this.restService.find('appeals', this.appeal._id);
                    this.appealSubservice = this.appeal.subservice;
                    await this._checkProcessActive(task);
                }, 2000);

                return;
            }
            const isActive = await this.processService.checkCamundaProcessActivity(this.appealSubservice.camundaProcessInfo.id);
            this.appealProcessService.isProcessActive = isActive;
            await this._updateTasksList();
            if (!this.appealProcessService.isProcessActive) {
                this.appealProcessService.stopProcessTasksPolling();
                this.toaster.success('Все задачи процесса успешно завершены');
                setTimeout(() => {
                    this.router.navigate([`/appeals/edit/${this.appeal.id}`]);
                }, 2000);
            } else {
                this.toaster.success('Задача процесса успешно завершена');
                if (this.appealProcessService.activeTasks.length === 0) {
                    this.appealProcessService.updateTasksInterval = setInterval(() => {
                        this._updateTasksList();
                    }, 10000);
                }
            }
        } catch (error) {
            if (task) {
                this.taskCardComponents.forEach(taskCard => {
                    if (taskCard.task.id === task.id) {
                        taskCard.isProcessCompleteTask = false;
                    }
                });
            }
        }
    }

    private async _updateTasksList() {
        const resultData = await this.processService.updateTasksList(
                this.appeal._id,
                this.appealSubservice.guid,
                this.appealSubservice.camundaProcessInfo.camundaBusinessInfoId,
            );
        this.appealProcessService.activeTasks = resultData.userTasks.activeTasks;
        this.appealProcessService.completedTasks = resultData.userTasks.completedTasks;
        this.appealProcessService.activeServiceTasks = resultData.serviceTasks.activeTasks;
        this.appealProcessService.completedServiceTasks = resultData.serviceTasks.completedTasks;
        this.appealProcessService.processTimers = resultData.timers;
        this.appealProcessService.tasks = resultData.userTasks.activeTasks.concat(resultData.userTasks.completedTasks);
        this.tasks = this.appealProcessService[this.isActiveTasksState ? 'activeTasks' : 'completedTasks'];
        this.appealProcessService.lastProcessTasksEntryStatusChangedAt = resultData.entryStatusChangedAt;

        this._prepareTasks();

        if (this.appealProcessService.activeTasks.length > 0 || this.appealProcessService.eventGateways.some(item => item.status === 'ACTIVE')) {
            clearInterval(this.appealProcessService.updateTasksInterval);
        }

        return resultData;
    }

    public cancelTask() {
        this.editedTask = null;
    }

    public async startEvent(event) {
        this.appealProcessService.startProcessEvent(event, this.appealSubservice.camundaProcessInfo.id);
        await this._updateTasksList();
        if (this.appealProcessService.activeTasks.length === 0) {
            this.appealProcessService.updateTasksInterval = setInterval(() => {
                this._updateTasksList();
            }, 10000);
        }
    }

    private _calculateProgress() {
        this.processProgress = null;
        const activeTasksWithPercent = this.allTasks.filter(item => item.status === 'ACTIVE' && item.parameters && item.parameters.percent);
        if (activeTasksWithPercent.length > 0) {
            let totalPercents = 0;
            activeTasksWithPercent.forEach(item => {
                totalPercents += parseInt(item.parameters.percent, 10);
            });
            this.processProgress = totalPercents / activeTasksWithPercent.length;
        }
    }

    private _getGlobalEvents() {
        if (this.appealService.subservice.camundaProcess.messages && this.appealService.subservice.camundaProcess.messages.length > 0) {
            const globalEvents = this.appealService.subservice.camundaProcess.messages.filter(item => item.isGlobal && item.name !== 'Неожиданное уведомление');
            if (globalEvents.length) {
                this.globalEvents = globalEvents;
            }
        }
    }

    public async startEventSubscription(event) {
        await this.processService.eventSubscriptionTrigger(event);
        await this._checkProcessActive();
    }

    /**
     * Получить список пользователей для модального окна выбора ответсвенного по делу
     */
    private async _getUsersToSelect() {
        this.controlOperatorsInitialized = false;
        this.usersToSelect = this.usersToSelect || [];

        const currentOrganization = this.storage.getItem('currentOrganization');
        try {
            // TODO ?
            const users = await this.appealService.getUsersForControl(currentOrganization.guid, this.controlOperatorsPage);
            // @ts-ignore
            this.noMoreControlOperators = !users.length;
            const candidateUsers = this.tasks.reduce((acc, task) => acc.concat(task.candidateUsers), []);
            const newUsersToSelect = candidateUsers.length ?
                // @ts-ignore
                users.filter(user => candidateUsers.includes(user.login)) : _.cloneDeep(users);
            this.usersToSelect = this.usersToSelect.concat(newUsersToSelect);
        } catch (error) {
            // TODO дописать обработчик ошибок
        }

        this.controlOperatorsInitialized = true;
    }

    public async loadMore() {
        this.controlOperatorsPage++;
        await this._getUsersToSelect();
    }

    /**
     * Показать окно выбора ответсвенного по делу
     */
    public showResponsibleForAppealModal() {
        this.modalTitle = 'Выберите ответственного';
        this.modalRef = this.modalService.show(this.setResponsibleForAppealModal, {backdrop: 'static'});
    }

    /**
     * Установить отвественного по делу
     */
    public async setResponsibleForAppeal() {
        if (!this.currentAssignee || this.currentAssignee === this.appeal.controlOperator) {
            return;
        }

        this.appeal.controlOperator = this.currentAssignee;
        await this.appealService.sendSetControlOperatorRequest(this.appeal);
        await this._getAssigneeUser();
        this.modalRef.hide();
    }

    private async _getAssigneeUser() {
        const currentOrganization = this.storage.getItem('currentOrganization');

        const users = await this.appealService.getUserFormLogin(this.appeal.controlOperator, currentOrganization.guid);
        this.assigneeUser = users[0];
    }

    public toggleFeedbackAndMarkMessagesRead() {
        this.feedbackBlockOpened = !this.feedbackBlockOpened;
    }

    public checkHasUnreadUserMessage() {
        return !this.feedbackBlockOpened && this.appeal.subservice.feedbackMessageUnchecked;
    }

    public getOperatorMessageDate(message) {
        if (!message.createdAt) {
            return '';
        }

        const createdAt = new Date();
        createdAt.setTime(message.createdAt);

        const result = createdAt.toLocaleDateString() + ' ' + createdAt.toLocaleTimeString();

        return result;
    }

    public getUserMessageDate(message) {
        if (!message.respondedAt) {
            return '';
        }

        const respondedAt = new Date();
        respondedAt.setTime(message.respondedAt);

        const result = respondedAt.toLocaleDateString() + ' ' + respondedAt.toLocaleTimeString();

        return result;
    }

    private async _prepareMessages() {
        if (!this.appeal.subservice.xsdData || !Object.keys(this.appeal.subservice.xsdData).length) {
            this.feedbackMessages = [];

            return;
        }
        const tasksData = {};

        let retries = 10;
        while (!this.appealSubservice.camundaProcessInfo && retries) {
            retries--;
            if (this.appeal.subservice.camundaProcessInfo) {
                this.appealSubservice = cloneDeep(this.appeal.subservice);
            } else {
                this.appeal = await this.restService.find('appeals', this.appeal._id);
                await new Promise(resolve => setTimeout(() => resolve(), 500));
            }
        }

        const camundaBusinessInfoId = this.appealSubservice.camundaProcessInfo.camundaBusinessInfoId;
        const camundaBusinessInfo: any = await this.restService.find(CAMUNDA_BUSINESS_INFO_COLLECTION, camundaBusinessInfoId);

        camundaBusinessInfo.userTasks.forEach(task => {
            if (!task.xsdData) {
                return;
            }

            const taskIds = Object.keys(task.xsdData);
            if (!taskIds.length) {
                return;
            }

            taskIds.forEach(taskId => {
               tasksData[taskId] = {
                    userCompleted: task.userCompleted,
               };
            });
        });

        const xsdData = this.appeal.subservice.xsdData;
        Object.keys(xsdData).forEach(taskId => {
            if (!tasksData[taskId]) {
                return;
            }

            tasksData[taskId] = Object.assign(tasksData[taskId], xsdData[taskId]);
        });

        this.feedbackMessages = Object.values(tasksData)
            .filter((message: any) =>
                (message.sendMessagePGMU && message.sendMessagePGMU.length)
                || (message.userMessage && message.userMessage.length)
                || (message.requestFeedbackPGMU && message.requestFeedbackPGMU.length),
            )
            .sort((messageA: any, messageB: any) => {
                if (messageA.createdAt < messageB.createdAt) {
                    return -1;
                }

                if (messageA.createdAt > messageB.createdAt) {
                    return 1;
                }

                return 0;
            });

        if (!(this.feedbackBlockOpened && this.appeal && this.appeal.subservice && this.appeal.subservice.feedbackMessageUnchecked)) {
            return;
        }

        this.messagesUpdateTimeout = setTimeout(async () => {
            this.appeal = await this.restService.find('appeals', this.appeal._id);
            this.appealSubservice = cloneDeep(this.appeal.subservice);
            this.appeal.subservice.feedbackMessageUnchecked = false;
            if (this.appealSaveService.appeal) {
                this.appealSaveService.appeal.subservice = cloneDeep(this.appeal.subservice);
            }
            try {
                // обновление явно части данных услуги,
                // чтобы избежать возможное затирание данных услуги изменяемых фоново в БП (напр., статус)
                // в случае использования БП с параллельным выполнением веток,
                // в одной из которых меняется затираемые данные
                await this.appealSaveService.saveForceAppealSubserviceParts({
                    feedbackMessageUnchecked: false,
                });
            } catch (error) {
                console.log('Ошибка при сохранении сообщений', error);
            }
        }, 500);
    }

    private async _updateStatuses() {
        const newAppeal: any = await this.restService.find('appeals', this.appeal._id);
        if (this.isNewStatus(this.appeal.status, newAppeal.status)) {
            this.appeal.status = newAppeal.status;
        }

        if (newAppeal.subservice.id === this.appeal.subservice.id && this.isNewStatus(this.appeal.subservice.status, newAppeal.subservice.status)) {
            this.appeal.subservice.status = newAppeal.subservice.status;
        }
    }

    private isNewStatus(status1, status2) {
        return status2 && (
            !status1 || new Date(status2.dateStart).getTime() > new Date(status1.dateStart).getTime()
        );
    }
}
