import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AuthGuard,
    ModuleGuard,
    SectionComponent,
    UniversalCollectionPageComponent,
} from '@evolenta/core';
import { MetaReglamentsResolve, MetaReglamentEditComponent } from '@evolenta/meta-reglaments-module';
import { MetaReglamentsListConfig } from './option/meta-reglaments-list.config';
import { PermissionEditComponent } from '../../modules/devtools/permissions/permission-edit.component';
import { PermissionResolve } from '../../modules/devtools/permissions/permission.resolve';
import { PermissionComponent } from '../../modules/devtools/permissions/permissions.component';
import { MethodEditComponent } from '../../modules/devtools/methods/method-edit.component';
import { MethodResolve } from '../../modules/devtools/methods/method.resolve';
import { MethodComponent } from '../../modules/devtools/methods/methods.component';
import { ApplicationsListConfig } from './option/applications-list.config';
import { ApplicationsResolve } from '../../modules/applications/applications.resolve';
import { ApplicationEditComponent } from '../../modules/applications/application-edit/application-edit.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'developer',
        component: SidebarComponent,
        data: {
            module: 'developer',
            breadcrumb: 'Кабинет разработчика',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [
            // ----------------- Мета-регламенты --------------------- //
            {
                path: 'meta-reglaments',
                component: SectionComponent,
                data: {
                    icon: 'icon-grid5',
                    name: 'Мета-регламенты',
                    img: 'main-menu/processes',
                    breadcrumb: 'Мета-регламенты',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Список мета-регламентов',
                            options: MetaReglamentsListConfig,
                        },
                        resolve: {
                            resolves: MetaReglamentsResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: MetaReglamentEditComponent,
                        data: {
                            breadcrumb: 'Создание мета-регламента',
                        },
                        resolve: {
                            resolves: MetaReglamentsResolve,
                        },
                    },
                    {
                        path: 'edit/:id',
                        component: MetaReglamentEditComponent,
                        data: {
                            breadcrumb: 'Редактирование мета-регламента',
                        },
                        resolve: {
                            resolves: MetaReglamentsResolve,
                        },
                    },
                ],
            },

            // ----------------- Полномочия --------------------- //
            {
                path: 'permissions',
                component: SectionComponent,
                data: {
                    // icon: 'icon-grid5',
                    name: 'Полномочия',
                    // img: 'main-menu/processes',
                    icon: 'icon-key',
                    breadcrumb: 'Полномочия',
                },
                children: [
                    {
                        path: '',
                        component: PermissionComponent,
                        data: {
                            name: 'Список полномочий',
                        },
                    },
                    {
                        path: 'create',
                        component: PermissionEditComponent,
                        resolve: {
                            resolves: PermissionResolve,
                        },
                    },
                    {
                        path: 'edit/:id',
                        component: PermissionEditComponent,
                        resolve: {
                            resolves: PermissionResolve,
                        },
                    },
                ],
            },

            // ----------------- Методы --------------------- //
            {
                path: 'methods',
                component: SectionComponent,
                data: {
                    icon: 'icon-power3',
                    name: 'Методы',
                    // img: 'main-menu/processes',
                    breadcrumb: 'Методы',
                },
                children: [
                    {
                        path: '',
                        component: MethodComponent,
                        data: {
                            name: 'Список методов',
                        },
                    },
                    {
                        path: 'create',
                        component: MethodEditComponent,
                        resolve: {
                            resolves: MethodResolve,
                        },
                    },
                    {
                        path: 'edit/:id',
                        component: MethodEditComponent,
                        resolve: {
                            resolves: MethodResolve,
                        },
                    },
                ],
            },

            // ----------------- Модули (приложения) --------------- //
            {
                path: 'applications',
                component: SectionComponent,
                data: {
                    name: 'Приложения',
                    breadcrumb: 'Приложения',
                    icon: 'icon-files-empty2',
                    img: 'main-menu/external',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            options: ApplicationsListConfig,
                        },
                        resolve: {
                            resolves: ApplicationsResolve,
                        },
                    },
                    {
                        path: 'create',
                        component: ApplicationEditComponent,
                        data: {
                            name: 'Создание приложения',
                            breadcrumb: 'Создание приложения',
                        },
                        resolve: {
                            resolves: ApplicationsResolve,
                        },
                    },
                    {
                        path: 'edit/:applicationId',
                        component: ApplicationEditComponent,
                        data: {
                            name: 'Редактирование приложения',
                            breadcrumb: 'Редактирование приложения',
                        },
                        resolve: {
                            resolves: ApplicationsResolve,
                        },
                    },
                ],
            },

            {
                path: '',
                data: {},
                redirectTo: 'meta-reglaments',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DeveloperRouting {
}
