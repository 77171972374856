<div class="panel panel-shadow m-20 p-10">
    <div *ngIf="!holidays.length" class="mb-20">{{ localizations['global-calendar'].empty_holidays }}</div>

    <div class="form-group form-group-lg row center" *ngFor="let day of holidays; let i = index">
        <div class="col-sm-4">
            <input
                type="text"
                [placeholder]="localizations.common.name"
                class="form-control col-sm-4 input-lg"
                [(ngModel)]="day.name"
                required
            >
        </div>

        <div class="col-sm-1">
            <ng-select
                class="text-bold"
                [allowClear]="false"
                [items]="getDayOptions(day.month)"
                [multiple]="false"
                [placeholder]="localizations['global-calendar'].day"
                (selected)="selectDayValue($event.id, i)"
                [active]="getSelectedDateValue(day.day, 'day')"
                required>
            </ng-select>
        </div>

        <div class="col-sm-2">
            <ng-select
                class="text-bold"
                [allowClear]="false"
                [items]="dateOptions.months"
                [multiple]="false"
                [placeholder]="localizations['global-calendar'].month"
                (selected)="selectMonthValue($event.id, i)"
                [active]="getSelectedDateValue(day.month, 'month')"
                required>
            </ng-select>
        </div>

        <button class="btn btn-danger-overline btn-sm p-10" (click)="removeHoliday(i)">
            <i class="icon-cross3"></i>
        </button>
    </div>

    <button class="btn btn-primary p-10 mr-20" type="button" [title]="localizations.common.save" (click)="save()">
        <span class="mr-20">{{ localizations.common.save }}</span>
        <i class="icon-plus-circle2"></i>
    </button>
    <button class="btn btn-primary-overline p-10" type="button" [title]="localizations.common.add" (click)="addHoliday()">
        {{ localizations.common.add }}
    </button>
</div>
