import { Component, Input, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { ICustomParams } from '../../../../../interfaces/components/dynamic-forms.interface';

@Component({
    selector: 'task-additional-data-tab',
    templateUrl: './task-additional-data-tab.component.html',
})
export class TaskAdditionalDataTabComponent {
    @Input() public savedPlace; // место, куда сохраняются доп. данные
    @Input() public tab: any = {};
    @Input() public disabled = false;
    @Input() public processId;
    @Input() public appeal: any;
    @Input() public customParams: ICustomParams;
    public model;

    public showData = false;

    @ViewChild('additionalDataComponent', { static: false }) public additionalDataComponent: DynamicFormComponent;

    public getModel() {
        const tabCode = this.tab.tabCode ? this.tab.tabCode : this.tab.code;
        if (this.processId) {
            const place = this.savedPlace.camundaProcessInfo.find(item => item.id === this.processId);
            if (!place.xsdData) {
                place.xsdData = {};
            }
            if (!place.xsdData[tabCode]) {
                place.xsdData[tabCode] = {};
            }

            if (this.customParams) {
                place.xsdData[tabCode]['customParams'] = this.customParams;
            }

            return place.xsdData[tabCode];
        }
        if (!this.savedPlace) {
            this.savedPlace = {};
        }

        if (!this.savedPlace[tabCode]) {
            this.savedPlace[tabCode] = {};
        }

        if (this.customParams) {
            this.savedPlace[tabCode]['customParams'] = this.customParams;
        }

        return this.savedPlace[tabCode];
    }

    public getExternalData() {
        return {
            entity: this.appeal,
        };
    }

    public checkValid() {
        return this.additionalDataComponent.isValid;
    }

    public startValidate() {
        if (this.additionalDataComponent && this.additionalDataComponent.formRenderComponent)  {
            this.additionalDataComponent.formRenderComponent.activateValidate();
        }
    }
}
