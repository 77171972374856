import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AccessService, HandlersService, ModalDialogComponent, SelectionService, ToasterService } from '@evolenta/core';
import { CommonAppealObjectCardComponent } from './appeal-object-card/common-appeal-object-card.component';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonAppealStatusService } from '../../services/common-appeal-status.service';
import { CommonAppealValidateService } from '../../services/common-appeal-validate.service';
import { Permission } from '../../../../common/services/permission';
import { AisObjectsService } from '../../../objects/objects/ais-objects.service';
import { CommonUtilities } from '@evolenta/utilities';
import { Router } from '@angular/router';
import { InternalHandlersService } from '../../../../common/services/internal-handlers.service';
import cloneDeep from 'lodash-es/cloneDeep';
import some from 'lodash-es/some';
import { ErrorLoggingService } from '../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-objects',
    templateUrl: 'common-appeal-objects.component.html',
    styles: ['.text-normal { text-transform: none; font-weight: normal; }'],
})
export class CommonAppealObjectsComponent implements OnInit {
    @Input() public appeal: any; // Текущее дело
    @Input() public subservice; // Услуги, на основании которых формируется дело
    @Input() public viewMode = 'default'; // Режим отображения
    @Input() public externalParam; // Параметр, задаваемых из разных источников, например при изменении доп. полей

    @Output() public onAfterSave = new EventEmitter<string>(); // Передача возможности перехода на другую вкладку после КНМ необходимости сохранения данных
    @Output() public onApply = new EventEmitter<boolean>(); // Передача события применения данных в родительский компонент для последующего сохранения дела (перехода на другой статус)
    @Output() public onEdit = new EventEmitter<object>(); // Переход в режим редактирования

    public editedObject = null; // Объект, который находится в данный момент в статусе редактирования

    public modalTitle; // Заголовок модального окна
    public modalMessage; // Сообщение модального окна
    public modalOperation; // Функция компонента, осуществляющая обработку результатов отработки модального окна

    @ViewChild('alertModal', { static: false }) public alertModalComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены действий внутри компонента
    @ViewChild('editCard', { static: false }) public editObjectCardComponent: CommonAppealObjectCardComponent; // Компонент - карточка редактирования субъекта дела

    public permissions = Permission; // Набор прав системы

    public showObjects = false;

    public objectParams: any = {
        allowCreateNew: true, // добавление нового объекта (с заполнением формы)
        allowSelectFromRegistry: true, // выбор из реестра объектов КНО
        selectOnlyFromGlobalRegistry: false, // выбор из глобального реестра объекта
        titles: {
            plural: 'Объекты',
            single: 'Объект',
        },
        permissions: {
            add: false,
            edit: false,
            remove: false,
            editMainXsd: false,
            editXsd: false,
        },
        editedFields: null,
    };

    public usedObjects;
    public objectsPagination;
    public searchObjectByName;
    public perPage = 5;

    public objectsDataInSubservice;
    public externalData;
    public applyWithoutSave = false;

    public isChangeExternalParam = false;

    public constructor(
        public appealStatusService: CommonAppealStatusService,
        public accessService: AccessService,
        private toaster: ToasterService,
        private commonAppealValidateService: CommonAppealValidateService,
        private commonAppealSaveService: CommonAppealSaveService,
        private selectionService: SelectionService,
        private aisObjectsService: AisObjectsService,
        private router: Router,
        private internalHandlerService: InternalHandlersService,
        private handlersService: HandlersService,
        private errorLoggingService: ErrorLoggingService
    ) {}

    /**
     * Инициализация компонента
     */
    public async ngOnInit() {
        this.externalData = { appeal: this.appeal };
        this.prepareObjectParams();
        if (this.selectionService.isProcessSelect && this.selectionService.transferOperation === 'selectObject') {
            return await this.completeSelectObject();
        }

        this.toggleObjects(true);
    }

    public ngOnChanges(changes) {
        if (
            this.appeal.status.code === 'draft' &&
            changes &&
            changes.externalParam &&
            !changes.externalParam.firstChange
        ) {
            if (changes.externalParam.previousValue !== changes.externalParam.currentValue) {
                this.isChangeExternalParam = true;
                this.prepareObjectParams();
            }
        }
    }

    public reInitObjects() {
        this.showObjects = false;
        setTimeout(() => {
            this.showObjects = true;
        }, 100);
    }

    public prepareObjectParams() {
        this.objectsDataInSubservice = this.subservice.objects.objectsData ? this.subservice.objects.objectsData : null;
        if (this.objectsDataInSubservice) {
            if (this.objectsDataInSubservice.titles) {
                this.objectParams.titles = Object.assign(this.objectParams.titles, this.objectsDataInSubservice.titles);
            }
            this.objectParams.allowCreateNew = this.objectsDataInSubservice.hasOwnProperty('allowCreateNew')
                ? this.objectsDataInSubservice.allowCreateNew
                : true;
            this.objectParams.allowSelectFromRegistry = this.objectsDataInSubservice.hasOwnProperty(
                'allowSelectFromRegistry'
            )
                ? this.objectsDataInSubservice.allowSelectFromRegistry
                : true;
            this.objectParams.selectOnlyFromGlobalRegistry = this.objectsDataInSubservice.hasOwnProperty(
                'selectOnlyFromGlobalRegistry'
            )
                ? this.objectsDataInSubservice.selectOnlyFromGlobalRegistry
                : false;

            const appealStatusCode = this.appeal.status.code;
            if (this.objectsDataInSubservice.statusesPermissions) {
                Object.keys(this.objectParams.permissions).forEach((key) => {
                    this.objectParams.permissions[key] =
                        this.objectsDataInSubservice.statusesPermissions[appealStatusCode] &&
                        this.objectsDataInSubservice.statusesPermissions[appealStatusCode][key];
                });
                if (
                    this.objectsDataInSubservice.statusesPermissions[appealStatusCode] &&
                    this.objectsDataInSubservice.statusesPermissions[appealStatusCode].editedFields &&
                    this.objectsDataInSubservice.statusesPermissions[appealStatusCode].editedFields.obj &&
                    this.objectsDataInSubservice.statusesPermissions[appealStatusCode].editedFields.obj.length > 0
                ) {
                    // Если в настройках статуса есть настройки для состава редактируемых полей
                    this.objectParams.allowedEditFields =
                        this.objectsDataInSubservice.statusesPermissions[appealStatusCode].editedFields.obj;
                    this.objectParams.ignoreReestrId = true;
                } else {
                    this.objectParams.editedFields = null;
                    this.objectParams.ignoreReestrId = false;
                }
            } else if (appealStatusCode === 'draft') {
                Object.keys(this.objectParams.permissions).forEach((key) => {
                    this.objectParams.permissions[key] = true;
                });
            }

            if (
                this.appeal.status.code === 'draft' &&
                this.appeal.subservice.externalObjectParam &&
                this.appeal.subservice.externalObjectPermissions
            ) {
                const externalPermissions = this.appeal.subservice.externalObjectPermissions;
                Object.keys(this.objectParams.permissions).forEach((key) => {
                    if (externalPermissions.hasOwnProperty(key)) {
                        this.objectParams.permissions[key] = externalPermissions[key];
                    }
                });
                if (externalPermissions.allowedEditFields) {
                    this.objectParams.allowedEditFields = externalPermissions.allowedEditFields;
                }
                if (externalPermissions.ignoreReestrId) {
                    this.objectParams.ignoreReestrId = true;
                }
            }
            if (this.isChangeExternalParam) {
                this.reInitObjects();
                this.isChangeExternalParam = false;
            }
        }
        // console.log('objectParams', this.objectParams);
    }

    public async completeSelectObject() {
        if (this.selectionService.isProcessSelect) {
            // Выбор нескольких объектов
            if (this.selectionService.selectedItems.length > 1) {
                for (const selectedObject of this.selectionService.selectedItems) {
                    const objectData = await this.aisObjectsService.getObjectData(
                        selectedObject,
                        !!this.selectionService.additionalData.selectFromGlobal
                    );
                    delete objectData.guid;
                    let object = await this.addObject(true);
                    object = Object.assign(object, objectData);
                    if (this.objectsDataInSubservice && this.objectsDataInSubservice.afterSelectHandlers) {
                        await this.runHandlers(
                            this.objectsDataInSubservice.objectsDataInSubservice.afterSelectHandlers,
                            object
                        );
                    }
                    await this.onApplyObject({ object: object });
                }
            } else if (this.selectionService.selectedItems.length === 1) {
                // выбор одного объекта, переход в режим редактирования
                const objectData = await this.aisObjectsService.getObjectData(
                    this.selectionService.selectedItems[0],
                    !!this.selectionService.additionalData.selectFromGlobal
                );
                delete objectData.guid;
                if (this.objectsDataInSubservice && this.objectsDataInSubservice.afterSelectHandlers) {
                    await this.runHandlers(this.objectsDataInSubservice.afterSelectHandlers, objectData);
                }
                if (this.selectionService.additionalData.editedObject) {
                    this.editedObject = Object.assign(this.selectionService.additionalData.editedObject, objectData);
                } else {
                    let object = this.addObject(true);
                    object = Object.assign(object, objectData);
                    await this.onApplyObject({ object: object });
                }
            } else if (this.selectionService.selectedItems.length === 0) {
                this.editedObject = this.selectionService.additionalData.editedObject;
            }
        }
        this.selectionService.clearData();
    }

    public async selectFromRegistry() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.appeal;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectObject';
        this.selectionService.additionalData = { selectFromGlobal: this.objectParams.selectOnlyFromGlobalRegistry };
        this.selectionService.selectType = 'many';
        const paths = ['selecting', this.objectParams.selectOnlyFromGlobalRegistry ? 'global-objects' : 'objects'];
        await this.router.navigate(paths);
    }

    /**
     * Добавление нового субъекта в дело
     */
    public async addObject(notTransferToEditMode = false) {
        const objectGuid = CommonUtilities.GenerateGuid();
        const object = {
            guid: objectGuid,
        };

        // запуск обработчика на добавление объекта
        if (this.objectsDataInSubservice && this.objectsDataInSubservice.afterAddHandlers) {
            await this.runHandlers(this.objectsDataInSubservice.afterAddHandlers, object);
        }
        if (notTransferToEditMode) {
            return object;
        } else {
            this.onEditObject(object);
        }
    }

    public async runHandlers(handlers, object) {
        const externalData = cloneDeep(this.externalData);
        externalData.object = object;

        await this.internalHandlerService.processingHandlers(handlers, 'startBefore', 'onEnd', externalData);
        handlers.forEach((handler) => {
            this.handlersService.runHandler(handler, externalData);
        });
    }

    /**
     * Инициализация режима редактирования субъекта
     * @param object
     */
    public onEditObject(object) {
        this.editedObject = cloneDeep(object);
        this.showObjects = false;
        this.onEdit.emit({ entity: this.editedObject, type: 'object' });
    }

    /**
     * Применение (отмена) изменений в субъекте, переход к режиму списка субъектов
     * @param data - объект формата {subject: измененный субъект, continueProcessingAppeal: флаг продолжения операции с делом}
     *             - либо false при отмене изменений
     */
    public async onApplyObject(data) {
        if (data) {
            const objectIndex = this.appeal.objects.findIndex((item) => item.guid === data.object.guid);
            const objectData = cloneDeep(data.object);
            if (objectIndex !== -1) {
                this.appeal.objects[objectIndex] = cloneDeep(objectData);
            } else {
                this.appeal.objects.push(cloneDeep(objectData));
            }
            if (!this.applyWithoutSave && this.appeal._id) {
                try {
                    await this.commonAppealSaveService.saveAppeal();
                    this.toaster.success('Объект успешно сохранен');
                    this.commonAppealValidateService.validateAppeal(this.commonAppealValidateService.processValidate);
                } catch (error) {
                    this.toaster.error(error);
                    await this.errorLoggingService.log(this.errorLoggingService.SPO, error, data);
                }
            }
        }
        this.onApply.emit(false);
        this.editedObject = null;
        this.toggleObjects();
    }

    /**
     * Удаление объекта из дела
     * @param object - удаляемый объекта
     */
    public onDeleteObject(object) {
        // Удаление настроек удаляемого объекта в услугах из дела
        if (this.appeal.subservice.objects && this.appeal.subservice.objects.length > 0) {
            const objectIdx = this.appeal.subservice.objects.indexOf(object.guid);
            if (objectIdx !== -1) {
                this.appeal.subservice.objects.splice(objectIdx, 1);
            }
        }
        if (this.appeal.subservice.subjects && this.appeal.subservice.subjects.length > 0) {
            this.appeal.subservice.subjects.forEach((subject) => {
                if (subject.objects) {
                    subject.objects = subject.objects.filter((item) => item !== object.guid);
                }
            });
        }

        // Удаление субъекта из массива объектов дела
        const objectIndex = this.appeal.objects.findIndex((item) => item.guid === object.guid);
        this.appeal.objects.splice(objectIndex, 1);

        // Сохранение дела
        if (this.appeal._id) {
            this.commonAppealSaveService.saveAppeal().then(() => {
                this.commonAppealValidateService.validateAppeal(this.commonAppealValidateService.processValidate);
            });
        }
    }

    /**
     * Передача ответа от модального окна методу, который активен для вызова текущего модального окна
     * @param data - строковый ответ от модального окна (yes, no, cancel)
     */
    public processingModal(data) {
        this[this.modalOperation](data);
    }

    public hasAllowEdit(): boolean {
        return !some(this.appealStatusService.finishStatuses, (item) => item === this.appeal.status.code);
    }

    public toggleObjects(isInit = false) {
        this.showObjects = !this.showObjects;
        if (this.showObjects && this.appeal.objects.length > 0) {
            this.usedObjects = isInit ? [this.appeal.objects[0]] : this.appeal.objects;
            if (isInit) {
                setTimeout(() => {
                    this.showObjects = false;
                    this.usedObjects = this.appeal.objects;
                    this.showObjects = true;
                }, 1000);
            }
        }
    }

    public changeObjectPage(data) {
        this.objectsPagination = data;
    }

    public filterObjects() {
        if (this.searchObjectByName) {
            this.usedObjects = this.usedObjects.filter(
                (object) =>
                    object.name.toLowerCase().indexOf(this.searchObjectByName.toLowerCase()) !== -1 ||
                    (object.address &&
                        object.address.fullAddress &&
                        object.address.fullAddress.toLowerCase().indexOf(this.searchObjectByName.toLowerCase()) !== -1)
            );
        } else {
            this.usedObjects = this.appeal.objects;
        }
    }

    public get isAllowAdd() {
        return (
            this.objectsDataInSubservice &&
            (this.objectsDataInSubservice.max ? this.objectsDataInSubservice.max > this.appeal.objects.length : true) &&
            this.objectParams.permissions.add
        );
    }

    public get onlyOne() {
        return this.objectsDataInSubservice && this.objectsDataInSubservice.max === 1;
    }

    public applyEditedObject() {
        this.applyWithoutSave = true;
        this.editObjectCardComponent.apply();
    }

    public reInitObjectsData() {
        this.prepareObjectParams();
    }
}
