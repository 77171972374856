import { Injectable } from '@angular/core';
import { SessionService, StorageService, ToasterService } from '@evolenta/core';
import { Config } from './config';
import { HttpClient } from '@angular/common/http';
import { ClientModuleData } from './client-module.data';

// todo: Сделать блокирование интерфейса модальным окном или еще как-то на время пинга клиентского модуля
@Injectable()
export class ClientModuleService {
    // Интервал опроса клиентского модуля
    public static readonly POLLING_INTERVAL = 3000;
    public static readonly SENDER_DESCRIPTION = 'Клиент';
    // Типы запросов
    // Сканирование
    public static readonly REQUEST_TYPE_SCAN = 1;
    // Подпись ЭЦП
    public static readonly REQUEST_TYPE_SIGN = 2;
    // Получение результатов
    public static readonly REQUEST_TYPE_GET_RESULT = 3;
    // Оплата через терминал
    public static readonly REQUEST_TYPE_PAY = 7;
    // Чтение данных из буфера
    public static readonly REQUEST_TYPE_READING_BUFFER = 9;
    // Отправка скопированных в буфер данных
    public static readonly REQUEST_TYPE_SEND_COPY_DATA = 8;
    // Определение ID терминала подключенного к клиентскому модулю
    public static readonly REQUEST_TYPE_GET_TERMINALID = 10;

    // Типы ответов
    // Сессия не найдена
    public static readonly RESPONSE_TYPE_SESSION_NOT_FOUND = 0;
    // В работе
    public static readonly RESPONSE_TYPE_IN_WORK = 2;
    // Отменено пользователем
    public static readonly RESPONSE_TYPE_EXIT_BY_USER = 3;
    // Завершено
    public static readonly RESPONSE_TYPE_COMPLETED = 4;
    // Требуется отмена платежа
    public static readonly RESPONSE_TYPE_NEED_CANCEL_PAY = 406;
    // Внутренняя ошибка клиентского модуля
    public static readonly RESPONSE_INTERNAL_ERROR = 500;

    public requestFile: any;
    public sessionId: any;
    public filesForSign = [];

    public payPosStatuses = ClientModuleData.payResultStatuses; // Статусы ответов эквайринга
    public countScanAttempts = 0;

    public constructor(
        private http: HttpClient,
        private toaster: ToasterService,
        private storage: StorageService,
        private session: SessionService,
    ) {
    }

    /**
     * Формирование пакета для отправки данных на подпись
     *
     * @param data - массив объектов формата { binaryData:...,typeSign:... }
     * @returns {any}
     */
    public createSignature(data) {
        const request = {};
        request['task_type'] = ClientModuleService.REQUEST_TYPE_SIGN;
        request['sender_description'] = ClientModuleService.SENDER_DESCRIPTION;
        request['files'] = [];

        for (let i = 0; i < data.length; i++) {
            const file = data[i];
            const signFile: any = {};
            const fileName = 'requestFile' + (i + 1);
            this.requestFile = {};
            this.requestFile['name'] = 'requestFile' + (i + 1);
            this.requestFile['content'] = signFile.binaryData = file['binaryData'];
            if (file['typeSign'] === 'XMLDSIG') {
                this.requestFile['isXML'] = 'true';
            }
            signFile.typeSign = file['typeSign'];
            request['files'].push(this.requestFile);
            this.filesForSign[fileName] = signFile;
        }

        if (request['files'].length > 0) {
            return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
                .then((response: any) => {
                    this.sessionId = response.session;

                    return this.checkSignatureReady();
                })
                .catch(error => {
                    this.parseError(error);
                });
        }
    }

    /**
     * Формирование и отправка запроса на сканирование документов
     *
     * @returns {any}
     */
    public scanDocument(appealId, scanFormat = null) {
        const locationItems = window.location.href.split('\/');
        const server = locationItems[0] + '//' + locationItems[2] + '/';
        // const server = Config.server;
        const transferUrl = server + Config.api + 'storage/uploadPart';
        const request: any = {
            task_type: ClientModuleService.REQUEST_TYPE_SCAN,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            session_params: {
                scan_multipart_sending: true,
                scan_url_transfer: transferUrl,
                scan_file_part_size_byte: 262144,
                params: {
                    entryName: 'appeals',
                    entryId: appealId,
                },
                token: this.storage.getItem('accessToken'),
            },
        };
        if (scanFormat) {
            request.session_params.scan_file_format = scanFormat;
        }
        this.countScanAttempts = 0;

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
            .then((response: any) => {
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_IN_WORK) {
                    return this.checkScanComplete(response['session']);
                } else if (response.current_state === ClientModuleService.RESPONSE_TYPE_SESSION_NOT_FOUND) {
                    return Promise.reject(response.current_state_text);
                }
            })
            .catch(error => {
                this.parseError(error);
            });
    }

    /**
     * Опрашивает клиентский модуль на предмет завершения процедуры сканирования по ID сессии
     *
     * @returns {any} - массив объектов формата { content: <scaned_file_in_base64>, name: <scaned_file_name> }
     */
    public checkScanComplete(sessionId) {
        return this.session.check().then(
            () => {
                const request = {
                    task_type: ClientModuleService.REQUEST_TYPE_GET_RESULT,
                    sender_description: ClientModuleService.SENDER_DESCRIPTION,
                    session: sessionId,
                    session_params: {
                        token: this.storage.getItem('accessToken'),
                    },
                };

                return this.http.post(Config.crypto, JSON.stringify(request)).toPromise().then(
                    (response: any) => {
                        this.countScanAttempts++;
                        const resultFiles = [];
                        if (response.current_state === ClientModuleService.RESPONSE_TYPE_COMPLETED) {
                            response.files.forEach(file => {
                                resultFiles.push({
                                    isMultipartTransfer: file.is_multipart_transfer,
                                    isSuccessMultipartTransfer: file.is_success_multipart_transfer,
                                    transferError: file.error_transfer,
                                    name: file.name,
                                    content: file.content,
                                    savedFile: file.result_transfer,
                                    totalSheets: file.totalSheets,
                                });
                            });
                            this.toaster.success(response.current_state_text);

                            return Promise.resolve(resultFiles);
                        } else if (response.current_state === ClientModuleService.RESPONSE_TYPE_EXIT_BY_USER) {
                            this.toaster.info(response.current_state_text);

                            return Promise.resolve(resultFiles);
                        } else if (response.current_state === ClientModuleService.RESPONSE_TYPE_SESSION_NOT_FOUND || response.current_state === ClientModuleService.RESPONSE_INTERNAL_ERROR) {
                            this.toaster.error(response.current_state_text);

                            return Promise.resolve(resultFiles);
                        } else if (response.current_state === ClientModuleService.RESPONSE_TYPE_IN_WORK) {
                            return new Promise(resolve => setTimeout(() => resolve(this.checkScanComplete(sessionId)), ClientModuleService.POLLING_INTERVAL));
                        }
                    }, error => {
                        console.log('error', error);
                        this.parseError(error);

                        return Promise.reject(error);
                    },
                );
            }, error => {
                console.log('Ошибка обновления сессии пользователя: ' + error);

                return Promise.reject(error);
            },
        );
    }

    /**
     * Процедура оплаты через терминал
     * @param sumInKop - сумма в коппейках
     * @returns {Promise<any>}
     */
    public pay(sumInKop) {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_PAY,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            session_params: {
                pos_terminal_operation_type: 1,
                pos_terminal_amount: sumInKop.toString(),
            },
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise().then(response => {
                this.sessionId = response['session'];

                // Получение результата завершения оплаты
                return this.checkPayComplete();
            }, error => {
                // Ошибка подключенияк клиентскому модулю
                const errorText = this.parseError(error, true);

                return Promise.reject({code: 'noConnect', message: errorText, position: 1});
            },
        );
    }

    public checkPayComplete() {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_GET_RESULT,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            session: this.sessionId,
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise().then(
            (response: any) => {
                // Завершение процедуры эквайринга (код: 4)
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_COMPLETED) {
                    if (ClientModuleData.successPayResultStatuses.indexOf(response.pos_result.response_code) !== -1) {
                        // Платеж успешно оплачен
                        return Promise.resolve({response: response, status: 'paid'});
                    } else {
                        // Ошибка, платеж совершен не был, возврат ошибки
                        return Promise.reject({code: 'payCompleteProblem', message: ClientModuleData.payResultStatuses[response.pos_result.response_code], response: response, status: 'payError', position: 4});
                    }
                }
                // Не найдена сессия, либо внутренняя ошибка клиентского модуля
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_SESSION_NOT_FOUND || response.current_state === ClientModuleService.RESPONSE_INTERNAL_ERROR) {
                    return Promise.reject({code: 'payProblem', message: ClientModuleData.payResultStatuses[response.pos_result.response_code], response: response, position: 3});
                }
                // Процесс в работе, запуск процедуры проверки результата завершения через заданный интервал времени
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_IN_WORK) {
                    return new Promise(resolve => setTimeout(() => resolve(this.checkPayComplete()), ClientModuleService.POLLING_INTERVAL));
                }
                // Требуется отмена платежа
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_NEED_CANCEL_PAY) {
                    return Promise.reject({code: 'needCancelOtherPayment', message: response.current_state_text, response: response});
                }
            }, error => {
                // Какая-то внутренняя проблема (в процессе работы отваливается соединение с клиентским модулем, например)
                console.log('ОШИБКА запроса к клиентскому модулю');
                const errorText = this.parseError(error, true);

                return Promise.reject({code: 'noConnect', message: errorText, position: 2});
                //
                // this.parseError(error);
            },
        );
    }

    /**
     * Отмена платежа, возврат средств на картку
     * @param cardNumber - номер карты, по которой был осуществлен платеж
     * @param authCode - код авторизации платежа
     * @param terminalId - ID терминала, на котором была осуществлена отмена
     */
    public rollbackPay(cardNumber, authCode, terminalId) {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_PAY,
            session_params: {
                pos_terminal_operation_type: 4,
                pos_terminal_card_number: cardNumber,
                pos_terminal_auth_code: authCode,
                pos_terminal_id: terminalId,
            },
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise().then((response: any) => {
                this.sessionId = response.session;

                // Получение результата завершения оплаты
                return this.checkRollbackPayComplete();
            }, error => {
                // Ошибка подключенияк клиентскому модулю
                // Требуется нажать вручную кнопку отмены платежа
                const errorText = this.parseError(error, true);

                return Promise.reject({code: 'noConnect', message: errorText, position: 1});
            },
        );
    }

    /**
     * Проверка завершения процедуры отмены платежа
     * @returns {Promise<{response: any; status: string}>}
     */
    public checkRollbackPayComplete() {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_GET_RESULT,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            session: this.sessionId,
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
            .then((response: any) => {
                // Завершение процедуры эквайринга (код: 4)
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_COMPLETED) {
                    if (ClientModuleData.successPayResultStatuses.indexOf(response.pos_result.response_code) !== -1) {
                        // Отмена была успешно проведена
                        return Promise.resolve({response: response, status: 'canceled'});
                    } else {
                        // Ошибка отмены платежа
                        return Promise.reject({code: 'rollbackPayProblem', message: ClientModuleData.payResultStatuses[response.pos_result.response_code], response: response, status: 'needCancel', position: 4});
                    }
                }
                // Не найдена сессия, либо внутренняя ошибка клиентского модуля
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_SESSION_NOT_FOUND || response.current_state === ClientModuleService.RESPONSE_INTERNAL_ERROR) {
                    return Promise.reject({code: 'rollbackPayProblem', message: ClientModuleData.payResultStatuses[response.pos_result.response_code], response: response, position: 3});
                }
                // Процесс в работе, запуск процедуры проверки результата завершения через заданный интервал времени
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_IN_WORK) {
                    return new Promise(resolve => setTimeout(() => resolve(this.checkRollbackPayComplete()), ClientModuleService.POLLING_INTERVAL));
                }
            }, error => {
                const errorText = this.parseError(error, true);

                return Promise.reject({code: 'noConnect', message: errorText, position: 2});
            },
        );
    }

    /**
     * Опрашивает клиентский модуль на предмет завершения процедуры подписи по ID сессии
     * @returns {any} - массив объектов формата { binaryData:...,typeSign:... }
     */
    public checkSignatureReady() {
        const request = {};
        request['task_type'] = ClientModuleService.REQUEST_TYPE_GET_RESULT;
        request['session'] = this.sessionId;

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
            .then((response: any) => {
                // todo: Проверку на корректность подписи: current_state_text???
                if (response.current_state === 4) {
                    const resultFiles = [];
                    for (const file of response.files) {
                        file.signature = file.signature.replace(/\n/g, '');
                        const resultFile = {
                            typeSign: this.filesForSign[file.name].typeSign,
                            signedData: {
                                binaryData: this.filesForSign[file.name].binaryData,
                                signature: file.signature.replace(/\n/g, ''),
                            },
                        };
                        resultFiles.push(resultFile);
                    }

                    return Promise.resolve(resultFiles);
                } else {
                    return new Promise(resolve => setTimeout(() => resolve(this.checkSignatureReady()), ClientModuleService.POLLING_INTERVAL));
                }
            }, error => {
                this.parseError(error);
            },
        );
    }

    /**
     * Закрытие дня
     * @returns {Promise<{response: any}>}
     */
    public closeDay() {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_PAY,
            session_params: {
                pos_terminal_operation_type: 11,
            },
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
            .then((response: any) => {
                this.sessionId = response.session;

                // Получение результата завершения оплаты
                return this.checkCloseDayComplete();
            }, error => {
                // Ошибка подключенияк клиентскому модулю
                const errorText = this.parseError(error, true);

                return Promise.reject({code: 'noConnect', message: errorText});
            },
        );
    }

    /**
     * Проверка процедуры закрытия дня
     * @returns {Promise<{response: any}>}
     */
    public checkCloseDayComplete() {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_GET_RESULT,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            session: this.sessionId,
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
            .then((response: any) => {
                // Завершение процедуры закрытия дня
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_COMPLETED) {
                    if (ClientModuleData.successPayResultStatuses.indexOf(response.pos_result.response_code) !== -1) {
                        // Завершение успешно проведено
                        return Promise.resolve({response: response});
                    } else {
                        // Ошибка закрытия дня
                        return Promise.reject({code: 'closeDayProblem', message: ClientModuleData.payResultStatuses[response.pos_result.response_code], response: response});
                    }
                }
                // Не найдена сессия, либо внутренняя ошибка клиентского модуля
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_SESSION_NOT_FOUND || response.current_state === ClientModuleService.RESPONSE_INTERNAL_ERROR) {
                    return Promise.reject({code: 'closeDayProblem', message: ClientModuleData.payResultStatuses[response.pos_result.response_code], response: response});
                }
                // Процесс в работе, запуск процедуры проверки результата завершения через заданный интервал времени
                if (response.current_state === ClientModuleService.RESPONSE_TYPE_IN_WORK) {
                    return new Promise(resolve => setTimeout(() => resolve(this.checkRollbackPayComplete()), ClientModuleService.POLLING_INTERVAL));
                }
            }, error => {
                console.log('ERROR', error);
            },
        );
    }

    public parseError(error, returnError = false) {
        let errorText = '';
        if (error && !error.ok) {
            errorText = 'Клиентский модуль не отвечает по адресу: ' + Config.crypto;
        } else {
            errorText = error;
        }
        if (returnError) {
            return errorText;
        } else {
            this.toaster.error(error);
        }
    }

    /**
     * Чтение данных из буфера клиентского модуля
     * @returns {Promise<{response: any}>}
     */
    public readingBufferData() {
        const request = {
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            task_type: ClientModuleService.REQUEST_TYPE_READING_BUFFER,
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                // Ошибка подключенияк клиентскому модулю
                const errorText = this.parseError(error, true);

                return Promise.reject({code: 'noConnect', message: errorText});
            });
    }

    /**
     * Получение информации о терминале
     * @returns {Promise<{response: any}>}
     */
    public getTerminalId() {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_GET_TERMINALID,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
        };

        return this.http.post(Config.crypto, JSON.stringify(request)).toPromise();
    }

    /**
     * Отправка данных в буфер клиентского модуля
     * @param data
     */
    public sendBufferData(data) {
        const request = {
            task_type: ClientModuleService.REQUEST_TYPE_SEND_COPY_DATA,
            sender_description: ClientModuleService.SENDER_DESCRIPTION,
            session_params: {buffer_data: data},
        };
        // return this.http.post(Config.crypto, JSON.stringify(request)).toPromise();
        this.http.post(Config.crypto, JSON.stringify(request)).subscribe(
            response => console.log('success', response),
        );
    }
}
