import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    AuthGuard,
    ModuleGuard,
    SectionComponent,
    UniversalCollectionPageComponent,
} from '@evolenta/core';
import { CommonAppealsComponent } from '../../modules/common-appeals/components/appeals/common-appeals.component';
import { CommonAppealsResolve } from '../../modules/common-appeals/common-appeals.resolve';
import { ServicesComponent } from '../../modules/subservices/services/services.component';
import { ServicesResolve } from '../../modules/subservices/services/services.resolve';
import { CommonAppealEditComponent } from '../../modules/common-appeals/components/appeal-edit/common-appeal-edit.component';
import { RegistersListConfig } from '../../modules/registers/registers-list.config';
import { RegistersResolve } from '../../modules/registers/registers.resolve';
import { RegistryComponent } from '../../modules/registers/registry.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';

const routes: Routes = [
    {
        path: 'spo',
        component: SidebarComponent,
        data: {
            module: 'spo',
            breadcrumb: 'СПО',
        },
        canActivate: [AuthGuard, ModuleGuard],
        canActivateChild: [AuthGuard, ModuleGuard],
        children: [

            // ----------------- CПО --------------------- //
            // -- Заявления -- //
            {
                path: 'appeals',
                component: SectionComponent,
                data: {
                    icon: 'icon-grid5',
                    name: 'Заявления',
                    img: 'main-menu/processes',
                    breadcrumb: 'Заявления абитуриентов',
                },
                children: [
                    {
                        path: '',
                        component: CommonAppealsComponent,
                        data: {
                            name: 'Список заявлений',
                            breadcrumb: 'Список заявлений абитуриентов',
                            metaReglamentCode: 'spo',
                        },
                        resolve: {
                            resolves: CommonAppealsResolve,
                        },
                    },
                    {
                        path: 'services',
                        component: ServicesComponent,
                        data: {
                            name: 'Регламенты',
                            breadcrumb: 'Регламенты ',
                            metaReglamentCode: 'spo',
                        },
                        resolve: {
                            resolves: ServicesResolve,
                        },
                    },
                    {
                        path: 'create/:subserviceId',
                        component: CommonAppealEditComponent,
                        data: {
                            allPermissionsRequired: true,
                            name: 'Новое заявление абитуриента',
                            breadcrumb: 'Новое заявление абитуриента',
                            metaReglamentCode: 'spo',
                        },
                        resolve: {
                            resolves: CommonAppealsResolve,
                        },
                    },
                    {
                        path: 'edit/:appealId',
                        component: CommonAppealEditComponent,
                        data: {
                            name: 'Редактировать заявление абитуриента',
                            breadcrumb: 'Редактирование заявления абитуриента',
                            metaReglamentCode: 'spo',
                        },
                        resolve: {
                            resolves: CommonAppealsResolve,
                        },
                    },
                ],
            },

            // -- Реестровые записи -- //
            {
                path: 'registers',
                component: SectionComponent,
                data: {
                    name: 'Реестр абитуриентов',
                    breadcrumb: 'Реестр абитуриентов',
                    img: 'main-menu/reports',
                },
                children: [
                    {
                        path: '',
                        component: UniversalCollectionPageComponent,
                        data: {
                            name: 'Реестр абитуриентов',
                            breadcrumb: 'Реестр абитуриентов',
                            metaReglamentCode: 'spo',
                            options: RegistersListConfig,
                            uploadListButtonAllowed: true,
                        },
                        resolve: {
                            resolves: RegistersResolve,
                        },
                    },
                    {
                        path: 'edit/:licenseId',
                        component: RegistryComponent,
                        data: {
                            name: 'Данные абитуриента',
                            breadcrumb: 'Данные абитуриента',
                            metaReglamentCode: 'spo',
                            uploadListButtonAllowed: true,
                        },
                        resolve: {
                            resolves: RegistersResolve,
                        },
                    },
                ],
            },
            {
                path: '',
                data: {},
                redirectTo: 'appeals',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class SpoRouting {
}
