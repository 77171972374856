<div class="row" [ngClass]="{'pt-10 pl-10 pr-10': task}" *ngIf="appeal.objects.length > perPage">
    <div class="col-md-6">
        <evolenta-pagination [items]="usedCheckLists" [marginBottom]="true" [count]="perPage"
                             (onChangePage)="changePage($event)"></evolenta-pagination>
    </div>
    <div class="col-md-6">
        <div class="form-group form-group-lg no-margin-bottom">
            <input class='form-control' type="text" [(ngModel)]="searchCheckLists" (ngModelChange)="filterCheckLists()"
                   placeholder="Введите поисковое значение...">
        </div>
    </div>
</div>
<div *ngIf="checkLists.length > 0">
    <ng-container *ngFor="let checkList of usedCheckLists; let idx = index">
        <div class="panel panel-shadow panel-small m-10" *ngIf="!pagination || pagination && idx >= pagination.start - 1 && idx <= pagination.end - 1">
            <div class="panel-heading">
                <div class="panel-title">
                    <h4 class="no-margin">
                        <!-- Субъект -->
                        <ng-container *ngIf="appeal.subjects.length > 1">
                            <p class="text-bold mb-3 text-size-small" *ngIf="checkList.subject">{{checkList.subject.shortHeader}}</p>
                            <p class="mb-3" *ngIf="!checkList.subject"><span class="anchor text-info" (click)="selectSubject(checkList)">выбрать субъект</span></p>
                        </ng-container>

                        <!-- Объект -->
                        <ng-container *ngIf="checkList.objectGuid">
                            {{getObjectProperty(checkList.objectGuid, 'name')}}
                            <ng-container *ngIf="getObjectProperty(checkList.objectGuid, 'address.fullAddress')">
                                <div class="text-size-small text-slate text-normal">{{getObjectProperty(checkList.objectGuid, 'address.fullAddress')}}</div>
                            </ng-container>
                        </ng-container>
                        <span *ngIf="!checkList.objectGuid"><span class="anchor text-info text-size-small" (click)="selectObject(checkList)">выбрать объект</span></span>
                    </h4>
                    <div class="mt-5">
                        <span class="badge bg-teal-300 text-uppercase mr-10" *ngIf="checkList.sendToMP">Обработка в мобильном приложении</span>
                        <span class="badge bg-success-300 text-uppercase mr-10" *ngIf="checkList.getResultFromMP">Получен ответ</span>
                        <span class="badge bg-success-300 text-uppercase mr-10" *ngIf="checkList.isCompleted">Обработка завершена</span>
                        <span class="badge bg-orange-300 text-uppercase mr-10" *ngIf="!checkList.sendToMP">Обработка вручную</span>
                        <ng-container *ngIf="checkList.user">
                            <span class="text-muted mr-5">Инспектор:</span> <span class="text-bold mr-10">{{checkList.user?.name}}</span>
                        </ng-container>
                        <ng-container *ngIf="checkList.inspectionDate">
                            <span class="text-muted mr-5">Дата проведения:</span> <span class="text-bold mr-10">{{checkList.inspectionDate | date: 'shortDate'}}</span>
                        </ng-container>
                        <span class="badge bg-danger-300 text-uppercase ml-10 cursor-pointer"
                              *ngIf="hasErrors(checkList)"
                              [popover]="errorsTemplate"
                              [popoverContext]="getPopoverContext(checkList)"
                              [outsideClick]="true"
                              placement="right">ошибки
                        </span>
                        <p class="pt-7 no-margin-bottom" *ngIf="checkList.commonResult && checkList.commonResult.result">
                            <ng-container *ngIf="checkList.commonResult && checkList.commonResult.result && checkList.commonResult.result.task_start_at">
                                <span class="text-muted mr-5">Дата начала проверки:</span>
                                {{checkList.commonResult.result.task_start_at * 1000 | date: 'medium'}}
                            </ng-container>

                            <ng-container *ngIf="checkList.commonResult && checkList.commonResult.result && checkList.commonResult.result.task_end_at">
                                <span class="text-muted ml-10 mr-5">Дата завершения проверки:</span>
                                {{checkList.commonResult.result.task_end_at * 1000 | date: 'medium'}}
                            </ng-container>

                            <ng-container *ngIf="checkList.commonResult && checkList.commonResult.result && checkList.commonResult.result.inspection_duration">
                                <span class="text-muted ml-10 mr-5">Длительность проверки:</span>
                                {{convertTimeInSeconds(checkList.commonResult.result.inspection_duration)}}
                            </ng-container>
                            <span class="text-info ml-10 anchor mr-10 hide"
                                  *ngIf="checkList.track"
                                  (click)="showTrackOnMap(checkList)">Маршрут инспектора</span>
                        </p>

                    </div>
                </div>

                <!-- Управляющие кнопки -->
                <div class="heading-elements">
                    <ul class="icons-list">
                        <li (click)="checkListsProcessingData[checkList.guid].hide = !checkListsProcessingData[checkList.guid].hide">
                            <a data-action="collapse">
                                <i [ngClass]="{'icon-arrow-down12': !checkListsProcessingData[checkList.guid].hide, 'icon-arrow-left12': checkListsProcessingData[checkList.guid].hide}"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="panel-body no-padding" *ngIf="!checkListsProcessingData[checkList.guid].hide">

                <!-- Выбор субъекта -->
                <div class="bg-primary-50 p-20" *ngIf="checkListsProcessingData[checkList.guid].isProcessSelectSubject">
                    <p class="text-bold no-margin-bottom">Выберите субъект из списка:</p>
                    <div class="radio-block" *ngFor="let subject of appeal.subjects">
                        <input type="radio" name="subjects" class="radio" id="subject{{subject.guid}}" (change)="afterSelectSubject(checkList, subject)">
                        <label for="subject{{subject.guid}}">{{subject.shortHeader}}</label>
                    </div>
                </div>

                <!-- Выбор объекта -->
                <div class="bg-primary-50 p-20" *ngIf="checkListsProcessingData[checkList.guid].isProcessSelectObject">
                    <p class="text-bold no-margin-bottom">Выберите объект из списка:</p>
                    <div class="radio-block" *ngFor="let object of objectsForSelect">
                        <input type="radio" name="objects" class="radio" id="object{{object.guid}}" (change)="afterSelectObject(checkList, object)">
                        <label for="object{{object.guid}}">{{object.name}}</label>
                    </div>
                </div>

                <!-- Общие настройки -->
                <div class="pl-20 pr-20 pt-10" *ngIf="(!mode || mode === 'edit') && !checkList.isCompleted && !checkList.getResultFromMP">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Инспектор</label>
                                <catalogue name="kndForm"
                                                    [(ngModel)]="checkList.user"
                                                    catalogName="users"
                                                    [returnFields]="['_id', 'login', 'name', 'id', 'position']"
                                                    [baseSearch]="usersBaseSearch"
                                                    [placeholder]="'Выберите значение...'"
                                                    (ngModelChange)="updateErrors()"></catalogue>
                                <div *ngIf="hasErrors(checkList, 'user')" class="validation-error-label">
                                    Поле обязательно для заполнения
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="!checkList.sendToMP">
                            <label>Дата проведения проверки</label>
                            <evolenta-datepicker [(ngModel)]="checkList.inspectionDate"
                                                 (ngModelChange)="updateErrors()"></evolenta-datepicker>
                            <div *ngIf="hasErrors(checkList, 'inspectionDate')" class="validation-error-label">
                                Поле обязательно для заполнения
                            </div>
                        </div>
                        <div class="col-md-5" *ngIf="isSendToMP">
                            <div class="checkbox-block mt-20">
                                <input type="checkbox" class="checkbox" id="sendToMp{{checkList.guid}}"
                                       [checked]="checkList.sendToMP" (change)="checkList.sendToMP = !checkList.sendToMP; updateErrors()" >
                                <label for="sendToMp{{checkList.guid}}">Отправить в мобильное приложение</label>
                            </div>
                        </div>
                    </div>
                </div>

                <table class="table table-hover" [ngClass]="{'table-lg': mode === 'edit', 'table-xs': mode !== 'edit'}" *ngIf="checkList.subjectGuid && checkList.objectGuid">
                    <tbody>
                    <ng-container *ngFor="let question of checkList.questions; let idx = index;">
                        <tr [ngClass]="{'bg-danger-50': isProcessValidate && (!question.result || !question.result.answer)}">
                            <td class="pr-10">{{idx + 1}}.</td>
                            <td class="valign-middle">
                                {{question.text}}
                                <ng-container *ngIf="question.result && question.result.answer && !isShowQuestionResult(checkList, question)">
                                    <input type="file"
                                           [name]="'file-' + question.guid"
                                           [id]="'file-' + question.guid"
                                           class="inputfile hide"
                                           (change)="fileChange($event, checkList, question)"/>
                                    <label [for]="'file-' + question.guid"
                                           class="text-info anchor text-size-small no-margin ml-5">прикрепить файл</label>
                                </ng-container>

                                <ng-container  *ngIf="question.result && question.result.files && question.result.files.length > 0">
                                    <!--                                <p class="text-slate no-margin text-bold text-size-small mt-5">Файлы:</p>-->
                                    <ul class="text-size-small no-margin mt-5 pl-15">
                                        <li *ngFor="let file of question.result.files; let lst = last" [ngClass]="{'no-margin-bottom': lst}">
                                            <a (click)="downloadFile(file._id, file.originalName)" class="anchor text-primary" title="Открыть файл">{{file.originalName}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </td>
                            <td colspan="3" class="text-center" *ngIf="isShowQuestionResult(checkList, question)">
                                <button type="button" class="btn btn-icon btn-rounded btn-xs cursor-default"
                                        [title]="getResultActionProperty(checkList, question, 'title')"
                                        [ngClass]="getResultActionProperty(checkList, question, 'theme')">
                                    <i [ngClass]="getResultActionProperty(checkList, question, 'icon')"></i>
                                </button>
                            </td>
                            <ng-container *ngIf="isShowQuestionActions(checkList, question)">
                                <td class="text-center pt-5 pb-5 valign-middle" width="70">
                                    <button type="button" class="btn btn-success btn-icon btn-rounded btn-xs" (click)="selectAction(checkList, question, 'yes')"
                                            [disabled]="!question.answerYes" title="Соответствует"
                                            [ngClass]="{'opacity-25': isNotHoveredAction(checkList, question, 'positiveHover', 'yes'), 'hide': !question.answerYes}"
                                            (mouseenter)="setProcessingQuestionProperty(checkList, question, 'positiveHover', true)"
                                            (mouseleave)="setProcessingQuestionProperty(checkList, question, 'positiveHover', false)">
                                        <i class="icon-checkmark3"></i>
                                    </button>
                                </td>
                                <td class="text-center pt-5 pb-5 valign-middle" width="70">
                                    <button type="button" class="btn btn-danger btn-icon btn-rounded btn-xs" (click)="selectAction(checkList, question, 'no')"
                                            [disabled]="!question.answerNo" title="Не соответствует"
                                            [ngClass]="{'opacity-25': isNotHoveredAction(checkList, question, 'negativeHover', 'no'), 'hide': !question.answerNo}"
                                            (mouseenter)="setProcessingQuestionProperty(checkList, question, 'negativeHover', true)"
                                            (mouseleave)="setProcessingQuestionProperty(checkList, question, 'negativeHover', false)">
                                        <i class="icon-cross2"></i>
                                    </button>
                                </td>
                                <td class="text-center pt-5 pb-5 valign-middle" width="70">
                                    <button type="button" class="btn btn-info btn-icon btn-rounded btn-xs" (click)="selectAction(checkList, question, 'not_applicable')"
                                            [disabled]="!question.answerNotConcidered" title="Не применимо"
                                            [ngClass]="{'opacity-25': isNotHoveredAction(checkList, question, 'notApplicableHover', 'not_applicable'), 'hide': !question.answerNotConcidered}"
                                            (mouseenter)="setProcessingQuestionProperty(checkList, question, 'notApplicableHover', true)"
                                            (mouseleave)="setProcessingQuestionProperty(checkList, question, 'notApplicableHover', false)">
                                        <i class="icon-minus3"></i>
                                    </button>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>

                <fieldset *ngIf="checkList.getResultFromMP && checkList.commonResult">
                    <legend class="text-bold pl-20 no-margin-bottom">
                        Общие результаты
                        <span class="label label-info text-uppercase ml-10" *ngIf="checkList.commonResult.inspection_duration">общее время: {{getTimeFromSeconds(checkList.commonResult.inspection_duration)}}</span>
                    </legend>
                    <table class="table table-xs">
                        <tbody>
                        <ng-container *ngIf="checkList.commonResult.inspection_place">
                            <tr>
                                <td class="text-slate text-size-small">
                                    Место проведения проверки
                                </td>
                                <td>
                                    {{checkList.commonResult.inspection_place.value}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let resultItem of getCommonResults(checkList); let fst = first">
                            <tr *ngIf="checkAllowResultValueType(resultItem)">
                                <td class="text-slate text-size-small">{{resultItem.view_name}}</td>
                                <td>
                                    <ng-container *ngIf="resultItem.value">{{resultItem.value}}</ng-container>
                                    <ng-container  *ngIf="resultItem.files && resultItem.files.length > 0">
                                        <ul class="text-size-small no-margin mt-5 pl-15">
                                            <li *ngFor="let file of resultItem.files; let lst = last" [ngClass]="{'no-margin-bottom': lst}">
                                                <a (click)="downloadFile(file._id, file.originalName)" class="anchor text-primary" title="Открыть файл">{{file.originalName}}</a>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </fieldset>

            </div>
        </div>
    </ng-container>

</div>

<ng-container *ngIf="isAllowAddCheckList">
    <button class="btn btn-info ml-20 mb-20" (click)="addCheckList()">Добавить проверочный лист</button>
</ng-container>

<div class="alert alert-warning" *ngIf="!isAllowAddCheckList && (!appeal.checkLists || appeal.checkLists.length === 0)">В КНМ невозможно создание проверочного листа</div>

<ng-template #map></ng-template>

<!-- Ошибки при валидации документа -->
<ng-template #errorsTemplate let-checkList="checkList"><span class="text-semibold">Ошибки чек-листа:</span>
    <ul [innerHtml]="getErrorsList(checkList)" class="no-margin"></ul>
</ng-template>
