<form #form="ngForm">
    <div class="pl-20 pr-20 pt-15 pb-20" >
        <!-- Адрес регистрации -->
        <div class="form-group form-group-lg"
             [ngClass]="{'mb-10': user.data.person.isDifferFactAddress || user.data.person.isDifferTempRegistrationAddress,
                         'no-margin-bottom': !user.data.person.isDifferFactAddress && !user.data.person.isDifferTempRegistrationAddress}">
            <label>
                {{ localizations.subjects.permanent_registration_address }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.registrationAddress', this.user)">
                    *
                </span>
                <div class="display-inline-block ml-10">
                    <span class="text-size-small text-muted mr-5"
                          *ngIf="!user.data.person.isDifferFactAddress || !user.data.person.isDifferTempRegistrationAddress">
                        {{ localizations.common.add_small }}
                    </span>
                    <span class="text-info text-size-small anchor"
                          (click)="addFactAddress()"
                          *ngIf="!user.data.person.isDifferFactAddress">
                        {{ localizations.subjects.address_of_actual_residence_small }}
                    </span>
                    <span class="text-size-small ml-5 mr-5 text-muted"
                          *ngIf="!user.data.person.isDifferFactAddress && !user.data.person.isDifferTempRegistrationAddress">
                        {{ localizations.common.or }}
                    </span>
                    <span class="text-info text-size-small anchor"
                          (click)="user.data.person.isDifferTempRegistrationAddress = true"
                          *ngIf="!user.data.person.isDifferTempRegistrationAddress">
                        {{ localizations.subjects.temporary_registration_address_small }}
                    </span>
                </div>
            </label>
            <evolenta-address-gar
                    name="registrationAddress"
                    [large]="true"
                    [disabled]="isDisabled('person.registrationAddress')"
                    [(ngModel)]="user.data.person.registrationAddress"
                    (change)="updateErrors('person.registrationAddress')"
            ></evolenta-address-gar>
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.registrationAddress', this.user)
                        && !user.data.person.registrationAddress">
                {{ localizations.subjects.registration_address_field_is_required }}
            </div>
        </div>

        <!-- Адрес временной регистрации -->
        <div class="form-group form-group-lg"
             *ngIf="user.data.person.isDifferTempRegistrationAddress"
             [ngClass]="{
                    'mb-10': user.data.person.isDifferFactAddress,
                    'no-margin-bottom': !user.data.person.isDifferFactAddress
                }">
            <label>
                {{ localizations.subjects.temporary_registration_address }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.tempRegistrationAddress', this.user)">
                    *
                </span>
                <span class="text-size-small anchor text-info ml-5"
                      (click)="deleteAddress('tempRegistrationAddress')">
                    {{ localizations.common.delete_small }}
                </span>
            </label>
            <evolenta-address-gar
                    name="tempRegistrationAddress"
                    [large]="true"
                    [disabled]="isDisabled('person.tempRegistrationAddress')"
                    [(ngModel)]="user.data.person.tempRegistrationAddress"
                    (change)="updateErrors('person.tempRegistrationAddress')"
                    #tempRegistrationAddressElement
            ></evolenta-address-gar>
            <div *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.tempRegistrationAddress', this.user)
                        && !user.data.person.tempRegistrationAddress" class="validation-error-label">
                {{ localizations.subjects.temporary_registration_field_is_required }}
            </div>
        </div>

        <!-- Адрес фактического проживания -->
        <div class="form-group form-group-lg no-margin-bottom"
             *ngIf="user.data.person.isDifferFactAddress">
            <label>
                {{ localizations.subjects.address_of_actual_residence }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.factAddress', this.user)">
                    *
                </span>
                <span class="text-size-small anchor text-info ml-5"
                      (click)="deleteAddress('factAddress')">
                    {{ localizations.common.delete_small }}
                </span>
            </label>
            <evolenta-address-gar
                    name="factAddress"
                    [large]="true"
                    [disabled]="isDisabled('person.factAddress')"
                    [(ngModel)]="user.data.person.factAddress"
                    (change)="updateErrors('person.factAddress')"
                    #factAddressElement
            ></evolenta-address-gar>
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.factAddress', this.user)
                        && !user.data.person.factAddress">
                {{ localizations.subjects.actual_residence_field_is_required }}
            </div>
        </div>
    </div>
</form>

