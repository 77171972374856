import { Injectable } from '@angular/core';
import { FilesService, HttpService, RestService, ToasterService } from '@evolenta/core';
import { Config } from '../../../../common/services/config';

@Injectable()
export class ReportsService {
    public reportsData;
    public reportCreationIsInProgress = false;

    public intervals = [
        {
            id: 'dateRegister',
            text: 'Зарегистрировано с..по',
            shortText: 'Зарегистрировано',
        },
        {
            id: 'dateIssue',
            text: 'Выдано с..по',
            shortText: 'Выдано',
        },
        {
            id: 'dateFinish',
            text: 'Завершено с..по',
            shortText: 'Завершено',
        },
        {
            id: 'dateCreation',
            text: 'Создано с..по',
            shortText: 'Создано',
        },
    ];

    // Основная группировка
    public mainGrouping = [
        {
            id: '1',
            text: 'Заявитель',
        },
        {
            id: '2',
            text: 'Номер',
        },
        {
            id: '3',
            text: 'Статус дела',
        },
        {
            id: '4',
            text: 'Оператор',
        },
    ];

    // Дополнительная группировка
    public additionalGrouping = [
        {
            id: '1',
            text: 'Заявитель',
        },
        {
            id: '2',
            text: 'Номер',
        },
        {
            id: '3',
            text: 'Статус дела',
        },
        {
            id: '4',
            text: 'Оператор',
        },
    ];

    public params = [
    ];

    public reportTitleArray = [
        {
            id: 'dateRegister',
            text: 'Реестр зарегистрированных дел за период',
        },
        {
            id: 'dateIssue',
            text: 'Реестр выданных дел за период',
        },
        {
            id: 'dateFinish',
            text: 'Реестр завершенных дел за период',
        },
        {
            id: 'dateCreation',
            text: 'Реестр созданных дел за период',
        },
    ];

    public colDefService = [
        {
            field: 'titles.branch.title',
            headerName: 'Наименование',
        },
    ];

    public colDefUnit = [
        {
            field: 'name',
            headerName: 'Название',
        },
    ];

    public selectedService = [];
    public selectedUnit = [];

    public constructor(
        private restService: RestService,
        private httpService: HttpService,
        private toaster: ToasterService,
        private filesService: FilesService,
    ) {
    }

    public async startGenerateReport(params) {
        try {
            const response = await this.httpService.post(Config.server + Config.app + Config.api + 'reports/startGenerateReport', params);
            this._waitResultReport(response);
        } catch (error) {
            this.reportCreationIsInProgress = false;

            return error;
        }
    }

    public async startGeneratingRegistryModelReport(metaReglamentCode, searchCriteria, type, licenseId) {
        try {
            this.reportCreationIsInProgress = true;

            const params = licenseId ? {
                search: {
                    search: [{
                        field: 'licenseId',
                        operator: 'eq',
                        value: licenseId}]}} :
                    {search:
                            {
                                search: searchCriteria || []}};
            console.log('startGeneratingRegistryModelReport', metaReglamentCode, this.reportCreationIsInProgress);
            const response = await this.httpService.post(Config.server + Config.app + Config.tatarApi + 'rendering/' + metaReglamentCode + '/' + type, params);
            console.log('registry model report creation result', response);
            this._waitResultReport(response);
        } catch (error) {
            console.log('error', error);
            this.reportCreationIsInProgress = false;

            return error;
        }
    }

    /**
     * Получение отчета
     * @param params
     */
    private _getResultReport(params) {
        // запрос на получение готового отчета
        return this.restService.find('reports', params._id);
    }

    /**
     * Пингуем серевер пока формируется отчет
     * @param params
     */
    private async _waitResultReport(params) {
        await new Promise(resolve => {
            setTimeout(resolve, 200);
        });
        const response: any = await this._getResultReport(params);
        if (response.status === 'completed') {
            // обработка результата
            this.filesService.downloadAndSaveFile(response.file._id, response.file.name);
            this.reportCreationIsInProgress = false;
        } else if (response.status === 'error') {
            this.reportCreationIsInProgress = false;
            this.toaster.error('Произошла ошибка');
        } else {
            return this._waitResultReport(params);
        }
    }
}
