import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@evolenta/core';
import { ReportType } from './reports-octonica.types';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsOctonicaService } from './reports-octonica.service';

@Component({
    selector: 'reports-octonica',
    templateUrl: 'reports-octonica.component.html',
    styleUrls: [
        'reports-octonica.component.less',
    ],
    styles: [
        ':host { width: 100%; }',
    ],
})
export class ReportsOctonicaComponent implements OnInit {

    public reportTypes: [ReportType];
    public localizations;

    public constructor(
        private reportsOctonicaService: ReportsOctonicaService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.data.subscribe(response => {
           this.reportTypes = response.reportTypes;
        });
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'reports',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Создаёт отчет указанного типа
     */
    public create(reportType) {
        const url = this.reportsOctonicaService.getReportUrl(reportType.octonicaId);
        window.location.replace(url);
    }
}
