import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestService, StorageService, TranslateService } from '@evolenta/core';
import { AisObjectsService } from '../../ais-objects.service';

@Component({
    selector: 'ais-object-preview',
    templateUrl: './ais-object-preview.component.html',
})
export class AisObjectPreviewComponent implements OnInit {
    @Input() public object;
    public panels = {
        common: true,
        subjects: true,
        kndKinds: true,
    };

    public moduleBaseUrl;
    public workModeUrl;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public kndKinds = [];
    public localizations;

    public constructor(
        private objectService: AisObjectsService,
        private route: ActivatedRoute,
        private storage: StorageService,
        private rest: RestService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe((urlPath) => {
            this.workModeUrl = urlPath[urlPath.length - 1].path;
        });
        this.getRiskCategories();
    }

    private _loadTranslations() {
        this.translate.get(['common', 'objects']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getRiskCategories() {
        if (this.object.type) {
            const kndKindIds = this.currentOrganization.kndKinds
                .filter(
                    (item: any) => item.objectTypes && item.objectTypes.find((elm) => elm.id === this.object.type.id)
                )
                .map((item) => item.id);
            if (kndKindIds.length > 0) {
                const search = [
                    {
                        field: '_id',
                        operator: 'in',
                        value: kndKindIds,
                    },
                ];
                this.rest
                    .search('nsiErpKnmKinds', { search: { search: search }, size: 100, prj: 'dictionaryCodeName' })
                    .then((kndKinds) => {
                        const params = [
                            {
                                field: 'entityId',
                                operator: 'eq',
                                value: this.object._id,
                            },
                            {
                                field: 'unitId',
                                operator: 'eq',
                                value: this.currentOrganization._id,
                            },
                            {
                                field: 'entityName',
                                operator: 'eq',
                                value: 'object',
                            },
                            {
                                field: 'kndKindId',
                                operator: 'in',
                                value: kndKindIds,
                            },
                        ];
                        this.rest
                            .search('entitiesKndKnoData', {
                                search: { search: params },
                                size: 100,
                                prj: 'knoKndKindRiskCategory',
                            })
                            .then((data) => {
                                kndKinds.forEach((kndKind) => {
                                    const find = data.find((item: any) => item.kndKindId === kndKind._id);
                                    if (find) {
                                        kndKind.riskCategory = find.riskCategory;
                                    }
                                    this.kndKinds.push(kndKind);
                                });
                            });
                    });
            }
        }
    }

    public getAddress(address) {
        return this.objectService.getAddress(address);
    }
}
