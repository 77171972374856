<!-- Сведения -->
<fieldset *ngIf="isBlockVisible('entities')" #entitiesBlock>
    <legend class="text-bold pl-20 no-margin-bottom cursor-pointer text-slate pt-10"  [ngClass]="{'shadowed': tabs.entities}"
            (click)="tabs.entities = !tabs.entities">
        <i class="icon-grid3  mr-5"></i> Сведения
        <a class="control-arrow text-slate mr-20"><i [ngClass]="{'icon-circle-down2' : tabs.entities, 'icon-circle-left2' : !tabs.entities}"></i></a>
    </legend>
    <div class="collapse" [ngClass]="{'in': tabs.entities}">
        <common-appeal-entities [appeal]="data.entity"
                         [subservice]="data.dataForProcessing.subservice"
                         [task]="elementData"
                         [allowEdit]="allowEdit"
                         (onStartEdit)="startEditProperty('entities')"
                         (afterEndEdit)="endEditProperty()"
                         #entities>
        </common-appeal-entities>
    </div>
</fieldset>

<!-- Быстрые запросы -->
<fieldset *ngIf="element.quickRequests" #quickRequestsBlock>
    <legend class="text-bold pl-20 cursor-pointer text-slate no-margin-bottom pt-10"
            [ngClass]="{'shadowed': tabs.quickRequestsBlock, 'border-top-default border-top': checkTabShowTopBorder('quickRequestsBlock')}"
            (click)="tabs.quickRequestsBlock = !tabs.quickRequestsBlock">
        <i class="icon-grid3 mr-5"></i> Быстрые запросы
        <a class="control-arrow text-slate mr-20"><i [ngClass]="{'icon-circle-down2' : tabs.quickRequestsBlock, 'icon-circle-left2' : !tabs.quickRequestsBlock}"></i></a>
    </legend>
    <div class="collapse pt-20 pl-20 pr-20 pb-10" [ngClass]="{'in': tabs.quickRequestsBlock}">
        <div class="display-flex" *ngFor="let request of getQuickRequests()">
            <div class="pt-5 pb-5 pr-20">
                <!-- Иконка документа -->
                <div [inlineSVG]="'assets/document.svg'"></div>
            </div>
            <div class="pt-5 pb-5 ">
                <div>
                    <p class="text-size-large mb-5">{{ request.name }}</p>
                </div>

                <div>
                    <span class="text-muted text-size-small">Запрос: </span>
                    <span class="text-muted text-size-small">№</span>
                    <!-- Номер конверта-запроса -->
                    <a class="anchor text-small-size text-info" routerLink="/selecting/envelopes/edit/{{request.id}}"> {{request.auid}}</a>
                </div>
            </div>
        </div>
    </div>
</fieldset>

<!-- Дополнительные поля -->
<fieldset *ngIf="isBlockVisible('xsdTabs', 'specialComponent')" #additionalDataBlock>
    <legend class="text-bold pl-20 cursor-pointer text-slate no-margin-bottom pt-10"
            [ngClass]="{'shadowed': tabs.additionalDataBlock, 'border-top-default border-top': checkTabShowTopBorder('additionalDataBlock')}"
            (click)="tabs.additionalDataBlock = !tabs.additionalDataBlock">
        <i class="icon-grid3 mr-5"></i> Дополнительная информация
        <span class="badge bg-danger-300 badge-xs ml-10" *ngIf="isProcessingValidate && (!validateAdditionalData() || validateSpecialComponents().length > 0)">Ошибки</span>
        <a class="control-arrow text-slate mr-20"><i [ngClass]="{'icon-circle-down2' : tabs.additionalDataBlock, 'icon-circle-left2' : !tabs.additionalDataBlock}"></i></a>
    </legend>
    <div class="collapse pt-20 pl-20 pr-20 pb-10" [ngClass]="{'in': tabs.additionalDataBlock}">

        <div class="alert alert-info no-border no-margin" *ngIf="isProcessingInitHandler">
            <i class="icon-spinner2 spinner mr-10"></i><strong>Внимание!</strong> Осуществляется процесс обработки данных. Подождите пожалуйста...
        </div>

        <ng-container *ngIf="!isProcessingInitHandler">
            <ng-container *ngFor="let tab of elementData.params.xsdTabs; let fst = first">
                <!-- Формы доп.данных -->
                <entity-process-element-additional-data-tab [savedPlace]="data.savedPlace"
                                                            [tab]="tab"
                                                            [textView]="!allowEdit"
                                                            [useXsdService]="data.useXsdService"
                                                            [externalData]="externalData"
                                                            #additionalDataTabComponent></entity-process-element-additional-data-tab>
            </ng-container>

            <!-- Специальные обработчики -->
            <div *ngIf="elementData.specialComponent">
                <set-executor [entity]="data.entity" [element]="element" [allowEdit]="allowEdit" #setExecutor *ngIf="elementData.specialComponent === 'setExecutor'"></set-executor>
                <transfer-to-unit [entity]="data.entity"
                                  [element]="element" [allowEdit]="allowEdit" #transferToUnit
                                  [parentEntity]="data.parentEntity"
                                  *ngIf="elementData.specialComponent === 'transferToUnit'"></transfer-to-unit>
            </div>
        </ng-container>

    </div>
</fieldset>

<!-- Документы -->
<fieldset *ngIf="isBlockVisible('documentGroups')" #documentsBlock>
    <legend class="text-bold pl-20 cursor-pointer text-slate pt-10 no-margin-bottom" [ngClass]="{'shadowed': tabs.documents}"
            (click)="tabs.documents = !tabs.documents">
        <i class="icon-grid3  mr-5"></i> Документы
        <a class="control-arrow text-slate mr-20"><i [ngClass]="{'icon-circle-down2' : tabs.documents, 'icon-circle-left2' : !tabs.documents}"></i></a>
    </legend>
    <div class="collapse" [ngClass]="{'in': tabs.documents}">
        <common-appeal-documents [appeal]="data.entity"
                          [subservice]="data.dataForProcessing.subservice"
                          [task]="elementData"
                          [allowEdit]="allowEdit"
                          [showOnInit]="true"
                          [onlyGroups]="elementData.params.documentGroupsCodes">
        </common-appeal-documents>
    </div>
</fieldset>

<!-- Общие документы (для общих процессов) -->
<fieldset *ngIf="isBlockVisible('commonDocuments')" #commonDocumentsBlock>
    <legend class="text-bold pl-20 cursor-pointer text-slate no-margin-bottom pt-10"
            [ngClass]="{'shadowed': tabs.commonDocumentsBlock, 'border-top-default border-top': checkTabShowTopBorder('commonDocumentsBlock')}"
            (click)="tabs.commonDocumentsBlock= !tabs.commonDocumentsBlock">
        <i class="icon-grid3 mr-5"></i> Общие документы
        <span class="badge bg-danger-300 badge-xs ml-10" *ngIf="isProcessingValidate && validateCommonDocuments().length > 0">Ошибки</span>
        <a class="control-arrow text-slate mr-20"><i [ngClass]="{'icon-circle-down2' : tabs.commonDocumentsBlock, 'icon-circle-left2' : !tabs.commonDocumentsBlock}"></i></a>
    </legend>
    <div class="collapse pt-20 pl-20 pr-20 pb-10" [ngClass]="{'in': tabs.commonDocumentsBlock}">
        <div class="pb-10" *ngFor="let document of elementData.params.commonDocuments; let fst = first">
            <strong class="mr-5">{{document.name}}:</strong>
            <em class="text-muted" *ngIf="!data.entity[document.field]">не сформирован</em>
            <ng-container *ngIf="data.entity[document.field]">
                <a (click)="downloadFile(data.entity[document.field])">{{data.entity[document.field].originalName}}</a>
                <span class="anchor text-info text-size-small ml-5" (click)="signFile(document)" *ngIf="document.isSign && allowEdit">подписать</span>
            </ng-container>
            <span class="anchor text-info text-size-small ml-10" *ngIf="document.printForm && document.isPrint && allowEdit && !document.isProcessGeneratePrintForm"
                  (click)="generatePrintForm(document)">сформировать</span>
            <span class="text-muted ml-10" *ngIf="document.isProcessGeneratePrintForm"><i class="icon-spinner2 spinner"></i> Осуществляется формирование файла, пожалуйста подождите...</span>
            <ul class="list-feed mt-10 no-margin-left no-margin-bottom no-padding-top" *ngIf="isExistSign()">
                <li *ngFor="let signData of getSignData(document)">
                    <div>
                        <span class="text-muted">{{signData.date | date: 'mediumDate'}}</span>
                        <span class="text-info ml-5">{{signData.user.name}}</span>
                    </div>
                    <div class="display-inline-block">
                        <i class="icon-medal text-danger cursor-pointer"></i>
                        <span class="text-size-small mr-10">{{getFileCertificateInfo({certificateData: signData.certificateData})}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</fieldset>

<!-- Чек-листы -->
<fieldset *ngIf="isBlockVisible('checkLists')" #checkListsBlock>
    <legend class="text-bold pl-20 cursor-pointer text-slate pt-10 no-margin-bottom" [ngClass]="{'shadowed': tabs.checkListsBlock}"
            (click)="tabs.checkListsBlock = !tabs.checkListsBlock">
        <i class="icon-grid3  mr-5"></i> Проверочные листы
        <a class="control-arrow text-slate mr-20"><i [ngClass]="{'icon-circle-down2' : tabs.checkListsBlock, 'icon-circle-left2' : !tabs.checkListsBlock}"></i></a>
    </legend>
    <div class="collapse" [ngClass]="{'in': tabs.checkListsBlock}">
        <common-appeal-check-lists [appeal]="data.entity"
                                   [subservice]="data.dataForProcessing.subservice"
                                   [externalData]="externalData"
                                   [task]="element"
                                   [mode]="allowEdit ? 'edit' : 'view'"
                                   #checkLists
        ></common-appeal-check-lists>
    </div>
</fieldset>

<!-- Диалог для подписания файлов -->
<evolenta-cades-sign-modal
        (onSignCompletedEnvelope)="onSignatureComplete($event)"
        *ngIf="allowEdit"
></evolenta-cades-sign-modal>

<ng-template #selectItemModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Выберите значение</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="radio-block" *ngFor="let item of itemsForSelect; let idx = index">
            <input type="radio" class="radio"
                   name="selectedItems"
                   id="itemForSelect{{idx}}"
                   (change)="selectedItem = item">
            <label for="itemForSelect{{idx}}">{{prepareItemName(item)}}</label>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="afterSelectItem()" [disabled]="!selectedItem">Применить</button>
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Закрыть</button>
    </div>
</ng-template>

