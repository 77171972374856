<ng-template #selectObjectModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Выбор объекта</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            <div class="radio-block" *ngFor="let object of objects">
                <input type="radio" class="radio" name="selectedObject" id="user{{object._id}}" [value]="object" [(ngModel)]="selectedObject">
                <label for="user{{object._id}}">
                        <span class="text-semibold">
                            <span *ngIf="object.name">{{object.name}} </span>
                        </span>
                </label>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="cancelModal()">Отмена</button>
        <button type="button" class="btn btn-primary" (click)="selectObject()" [disabled]="!selectedObject">Выбрать</button>
    </div>
</ng-template>
