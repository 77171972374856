<bpmn-card-tasks type="serviceTasks"
                 [tasksName]="localizations['bpmn-card'].subprocesses"
                 [process]="process"
                 [tasks]="process.tasks.subProcess"
                 *ngIf="process.tasks && process.tasks.subProcess && process.tasks.subProcess.length > 0">
</bpmn-card-tasks>

<bpmn-card-tasks type="userTasks"
                 [tasksName]="localizations['bpmn-card'].user_tasks"
                 [tasks]="process.tasks.userTasks"
                 [process]="process"
                 *ngIf="process.tasks && process.tasks.userTasks && process.tasks.userTasks.length > 0">
</bpmn-card-tasks>

<bpmn-card-tasks type="serviceTasks"
                 [tasks]="process.tasks.serviceTasks"
                 [tasksName]="localizations['bpmn-card'].service_tasks"
                 [process]="process"
                 *ngIf="process.tasks && process.tasks.serviceTasks && process.tasks.serviceTasks.length > 0">
</bpmn-card-tasks>

<bpmn-card-tasks type="businessRuleTasks"
                 [tasksName]="localizations['bpmn-card'].cases"
                 [tasks]="process.tasks.businessRuleTasks"
                 [process]="process"
                 *ngIf="process.tasks && process.tasks.businessRuleTasks && process.tasks.businessRuleTasks.length > 0">
</bpmn-card-tasks>

<bpmn-card-tasks type="catchEvents"
                 tasksName="События"
                 [tasksName]="localizations['bpmn-card'].events"
                 [tasks]="process.catchEvents"
                 [process]="process"
                 *ngIf="process.catchEvents && process.catchEvents.length > 0">
</bpmn-card-tasks>
