<div *ngIf="checkLists.length > 0"
     [ngClass]="{'p-20': !task}">
    <div class="panel"
         [ngClass]="{'panel panel-shadow': !task}"
         *ngFor="let checkList of checkLists">

        <div class="panel-heading p-20">
            <h6 class="panel-title">
                <!-- Заголовок объекта -->
                <p class="text-bold mb-3 text-size-small"
                   *ngIf="checkList.subject">
                    {{checkList.subject.shortHeader}}
                </p>
                <p class="mb-3"
                   *ngIf="!checkList.subject">
                    <span class="anchor text-info"
                          (click)="selectSubject(checkList)">
                        {{ localizations['appeals.requirements'].select_subject }}
                    </span>
                </p>
                <ng-container *ngIf="checkList.object">
                    {{checkList.object.name}}
                    <ng-container *ngIf="checkList.object.address">
                        -
                        <span class="text-size-small">
                            {{getAddress(checkList.object.address)}}
                        </span>
                    </ng-container>
                </ng-container>
                <span *ngIf="!checkList.object && objects.length > 0">
                    <span class="anchor text-info"
                          (click)="selectObject(checkList)">
                        {{ localizations['appeals.requirements'].select_subject }}
                    </span>
                </span>
                <span class="text-danger"
                      *ngIf="!checkList.object && objects.length == 0">
                    {{ localizations['appeals.requirements'].checkup_objects_missing }}
                </span>
            </h6>

            <!-- Управляющие кнопки -->
            <div class="heading-elements">
                <button class="btn btn-success-overline heading-btn btn-labeled p-10"
                        (click)="showTrackOnMap(checkList)"
                        *ngIf="checkList.object && checkList.subject && checkList.track">
                    {{ localizations['appeals.requirements'].inspector_route }}
                </button>
                <button class="btn btn-primary-overline heading-btn btn-labeled p-10"
                        (click)="sendToMobile(checkList)"
                        *ngIf="checkList.object && checkList.subject">
                    {{ localizations['appeals.requirements'].upload_into_mobile_app }}
                </button>

                <ul class="icons-list"
                    *ngIf="!task">
                    <li (click)="checkList.hideRequirements = !checkList.hideRequirements">
                        <a data-action="collapse">
                            <i [ngClass]="{'icon-arrow-down12': !checkList.hideRequirements, 'icon-arrow-left12': checkList.hideRequirements}">
                            </i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="panel-body no-padding"
             *ngIf="!checkList.hideRequirements">
            <div class="bg-primary-50 p-20"
                 *ngIf="checkList.isProcessSelectSubject">
                <p class="text-bold no-margin-bottom">
                    {{ localizations['appeals.requirements'].select_subject_from_list }}:
                </p>
                <div class="radio-block"
                     *ngFor="let subject of appeal.subjects">
                    <input id="subject{{subject.guid}}"
                           class="radio"
                           type="radio"
                           name="subjects"
                           (change)="afterSelectSubject(checkList, subject)">
                    <label for="subject{{subject.guid}}">
                        {{subject.shortHeader}}
                    </label>
                </div>
            </div>

            <div class="bg-primary-50 p-20"
                 *ngIf="checkList.isProcessSelectObject">
                <p class="text-bold no-margin-bottom">
                    {{ localizations['appeals.requirements'].select_subject_from_list }}:
                </p>
                <div class="radio-block"
                     *ngFor="let object of appeal.objects">
                    <input id="object{{object.guid}}"
                           class="radio"
                           type="radio"
                           name="objects"
                           (change)="afterSelectObject(checkList, object)">
                    <label for="object{{object.guid}}">
                        {{object.name}}
                    </label>
                </div>
            </div>

            <table class="table table-lg table-hover">
                <tbody>
                <ng-container *ngFor="let question of checkList.questions; let idx = index; let mfst = first">
                    <tr>
                        <td width="10"
                            class="pr-10"
                            [ngClass]="{'no-border-top': mfst}">
                            {{idx + 1}}.
                        </td>
                        <td class="valign-middle"
                            [ngClass]="{'no-border-top': mfst}">
                            {{question.text}}
                            <ng-container *ngIf="question.result && question.result.answer">
                                <input id="file-{{checkList.guid}}"
                                       class="inputfile hide"
                                       type="file"
                                       name="file-{{checkList.guid}}"
                                       (change)="fileChange($event, checkList, question)" />
                                <label class="text-info anchor text-size-small no-margin ml-5"
                                       for="file-{{checkList.guid}}">
                                    {{ localizations['appeals.requirements'].attach_file }}
                                </label>
                            </ng-container>

                            <ng-container *ngIf="question.result && question.result.files && question.result.files.length > 0">
                                <ul class="text-size-small no-margin mt-5 pl-15">
                                    <li [ngClass]="{'no-margin-bottom': lst}"
                                        *ngFor="let file of question.result.files; let lst = last">
                                        <a class="anchor text-primary"
                                           [title]="localizations['appeals.requirements'].open_file"
                                           (click)="downloadFile(file._id, file.originalName)">
                                            {{file.originalName}}
                                        </a>
                                        <span class="anchor text-slate ml-10"
                                              [title]="localizations['appeals.requirements'].open_file"
                                              (click)="deleteFile(checkList, question, file)">
                                            {{ localizations.common.delete_small }}
                                        </span>
                                    </li>
                                </ul>
                            </ng-container>
                        </td>
                        <td [ngClass]="{'no-border-top': mfst}"
                            *ngIf="checkList.getResultFromMP || checkList.sendedJobToMP">
                            <button class="btn btn-success btn-icon btn-rounded btn-xs"
                                    type="button"
                                    *ngIf="question.result"
                                    [title]="!checkList.getResultFromMP ?
                                        localizations.common.being_detected :
                                        question.result.answer == 'yes' ?
                                         localizations.common.complies :
                                         question.result.answer == 'no' ?
                                          localizations['appeals.requirements'].does_not_comply :
                                           localizations.common.not_acceptable"
                                    [ngClass]="{'btn-success': checkList.getResultFromMP && question.result.answer && question.result.answer == 'yes',
                                                'btn-danger': checkList.getResultFromMP && question.result.answer && question.result.answer == 'no',
                                                'btn-info': checkList.getResultFromMP && question.result.answer && question.result.answer == 'not_applicable',
                                                'bg-slate-300': !checkList.getResultFromMP && checkList.sendedJobToMP}"
                                    >
                                <i [ngClass]="{'icon-checkmark3': checkList.getResultFromMP && question.result.answer && question.result.answer == 'yes',
                                               'icon-cross2': checkList.getResultFromMP && question.result.answer && question.result.answer == 'no',
                                               'icon-question7': !checkList.getResultFromMP && checkList.sendedJobToMP,
                                               'icon-minus3': checkList.getResultFromMP && question.result.answer && question.result.answer == 'not_applicable'}"></i>
                            </button>
                        </td>
                        <td class="text-center pt-5 pb-5 valign-middle" width="70"
                            [ngClass]="{'no-border-top': mfst}"
                            *ngIf="!checkList.getResultFromMP && !checkList.sendedJobToMP"                          >
                            <button type="button" class="btn btn-success btn-icon btn-rounded btn-xs"
                                    [ngClass]="{'opacity-25': !question.positiveHover && (!question.result || !question.result.answer || question.result.answer !== 'yes')}"
                                    (click)="selectAction(checkList, question, 'yes')"
                                    (mouseenter)="question.positiveHover = true"
                                    (mouseleave)="question.positiveHover = false">
                                <i class="icon-checkmark3"></i>
                            </button>
                        </td>
                        <td class="text-center pt-5 pb-5 valign-middle"
                            [ngClass]="{'no-border-top': mfst}"
                            width="70"
                            *ngIf="!checkList.getResultFromMP && !checkList.sendedJobToMP">
                            <button class="btn btn-danger btn-icon btn-rounded btn-xs"
                                    [ngClass]="{'opacity-25': !question.negativeHover && (!question.result || !question.result.answer || question.result.answer !== 'no')}"
                                    type="button"
                                    (click)="selectAction(checkList, question, 'no')"
                                    (mouseenter)="question.negativeHover = true"
                                    (mouseleave)="question.negativeHover = false">
                                <i class="icon-cross2"></i>
                            </button>
                        </td>
                        <td class="text-center pt-5 pb-5 valign-middle"
                            [ngClass]="{'no-border-top': mfst}"
                            width="70"
                            *ngIf="!checkList.getResultFromMP && !checkList.sendedJobToMP">
                            <button class="btn btn-info btn-icon btn-rounded btn-xs"
                                    [ngClass]="{'opacity-25': !question.notApplicableHover && (!question.result || !question.result.answer || question.result.answer !== 'not_applicable')}"
                                    type="button"
                                    (click)="selectAction(checkList, question, 'not_applicable')"
                                    (mouseenter)="question.notApplicableHover = true"
                                    (mouseleave)="question.notApplicableHover = false">
                                <i class="icon-minus3"></i>
                            </button>
                        </td>
                        <td class="text-right"
                            *ngIf="!checkList.getResultFromMP && !checkList.sendedJobToMP">
                            <button class="btn btn-default pl-10 pr-10"
                                    (click)="deleteQuestion(checkList, question)">
                                <i class="icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>

            <button class="btn btn-default m-20"
                    (click)="selectQuestions(checkList)"
                    *ngIf="!checkList.getResultFromMP && !checkList.sendedJobToMP">
                {{ localizations['appeals.requirements'].add_questions }}
            </button>

            <fieldset class="mt-20" *ngIf="checkList.getResultFromMP && checkList.commonResult && checkList.commonResult">
                <legend class="text-bold pl-20 no-margin-bottom">
                    {{ localizations['appeals.requirements'].total_results }}
                    <span class="label label-info text-uppercase ml-10"
                          *ngIf="checkList.commonResult.inspection_duration">
                        {{ localizations['appeals.requirements'].total_time }}:
                        {{getTimeFromSeconds(checkList.commonResult.inspection_duration)}}</span>
                </legend>
                <table class="table table-xs">
                    <tbody>
                    <ng-container *ngFor="let resultItem of getCommonResults(checkList); let fst = first">
                        <tr *ngIf="checkAllowResultValueType(resultItem)">
                            <td class="text-slate text-size-small" [ngClass]="{'no-border-top': fst}">{{resultItem.view_name}}</td>
                            <td [ngClass]="{'no-border-top': fst}">{{resultItem.value}}</td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </fieldset>

        </div>
    </div>
</div>

<ng-container  *ngIf="isAllowAddCheckList">
    <div class="alert alert-warning"
         *ngIf="checkLists.length == 0">
        {{ localizations['appeals.requirements'].no_checkup_sheets_in_knm }}
    </div>
    <button class="btn btn-info ml-20 mt-20"
            (click)="addCheckList()">
        {{ localizations['appeals.requirements'].add_checkup_sheet }}
    </button>
</ng-container>


<div class="alert alert-warning"
     *ngIf="!isAllowAddCheckList && (!appeal.checkLists || appeal.checkLists.length === 0)">
    {{ localizations['appeals.requirements'].unable_to_create_checkup_list_in_knm }}
</div>

<ng-template #map></ng-template>
