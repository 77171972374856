import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver,
    Type,
    Injector,
    ApplicationRef,
} from '@angular/core';
import { FilesService, HandlersService, StorageService } from '@evolenta/core';
import { CommonAppealCheckListsService } from '../../services/common-appeal-check-lists.service';
import { CommonAppealService } from '../../services/common-appeal.service';
import { CommonAppealSaveService } from '../../services/common-appeal-save.service';
import { CommonUtilities, ObjectUtilities } from '@evolenta/utilities';
import values from 'lodash-es/values';

@Component({
    selector: 'common-appeal-check-lists',
    templateUrl: 'common-appeal-check-lists.component.html',
    styles: ['.text-normal { font-weight: normal; font-size: 80%; }'],
})
export class CommonAppealCheckListsComponent implements OnInit {
    @Input() public appeal: any; // Формируемое дело
    @Input() public subservice;
    @Input() public printForms;
    @Input() public task;
    @Input() public externalData;
    @Input() public mode = 'view';

    @Output() public onApply = new EventEmitter<boolean>();

    public isCustomMode = false;

    public objects = [];

    public checkLists = [];
    public checkListTemplate;

    public checkListsProcessingData = {};

    @ViewChild('map', { read: ViewContainerRef, static: false }) public mapComponentViewContainer: ViewContainerRef;

    public perPage = 5;
    public pagination;
    public usersBaseSearch = [
        {
            field: 'sprOrganizations.id',
            operator: 'eq',
            value: this.storage.getItem('currentOrganization').id,
        },
    ];
    public template;
    public isSendToMP = false;

    public isProcessValidate = false;
    public checkListsWithErrors;
    public searchCheckLists;
    public usedCheckLists = [];

    public constructor(
        private checkListsService: CommonAppealCheckListsService,
        private saveService: CommonAppealSaveService,
        private filesService: FilesService,
        private appealService: CommonAppealService,
        private injector: Injector,
        private applicationRef: ApplicationRef,
        private handlersService: HandlersService,
        private storage: StorageService
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        if (!this.appeal.checkLists) {
            this.appeal.checkLists = [];
        }
        if (!this.appeal.objectsForCheckLists && this.appeal.objects) {
            this.appeal.objectsForCheckLists = this.appeal.objects.map((item) => item.guid);
        }
        this.checkLists = this.appeal.checkLists;
        this.template = this.subservice.checkLists[0];
        if (this.appeal.hasOwnProperty('sendCheckListsToMP')) {
            this.isSendToMP = this.appeal.sendCheckListsToMP;
        } else if (this.subservice.checkListsCommon) {
            this.isSendToMP = !!this.subservice.checkListsCommon.sendToMP;
        }
        this.initCheckLists();
        this.usedCheckLists = this.checkLists;
    }

    public get isAllowAddCheckList() {
        let isAllow = false;
        if (this.appeal.objectsForCheckLists && this.appeal.objectsForCheckLists.length > 0) {
            this.appeal.objectsForCheckLists.forEach((objectGuid) => {
                const find = this.appeal.checkLists.find((item) => item.objectGuid === objectGuid);
                if (!find) {
                    isAllow = true;
                }
            });
        }

        return isAllow;
    }

    public initCheckLists() {
        let subjectGuid;
        if (this.appeal.subjects.length === 1) {
            subjectGuid = this.appeal.subjects[0].guid;
        }
        this.appeal.objectsForCheckLists.forEach((objectGuid) => {
            const find = this.appeal.checkLists.find((item) => item.objectGuid === objectGuid);
            if (!find) {
                this.addCheckList(subjectGuid, objectGuid);
            }
        });
        this.appeal.checkLists.forEach((checkList) => {
            this.checkListsProcessingData[checkList.guid] = {
                hide: true,
                isProcessSelectSubject: !checkList.subjectGuid,
                isProcessSelectObject: !checkList.objectGuid,
                questions: {},
            };
            checkList.questions.forEach((question) => {
                this.checkListsProcessingData[checkList.guid].questions[question.guid] = {
                    positiveHover: false,
                    negativeHover: false,
                    notApplicableHover: false,
                };
            });
        });
    }

    public addCheckList(subjectGuid = null, objectGuid = null) {
        const newCheckList = {
            guid: CommonUtilities.GenerateGuid(),
            sendToMP: this.isSendToMP,
            subjectGuid: subjectGuid,
            objectGuid: objectGuid,
            questions: this.prepareQuestions(subjectGuid, objectGuid),
            npaLink: this.getNpaLink(),
        };
        this.appeal.checkLists.push(newCheckList);
    }

    public prepareQuestions(subjectGuid, objectGuid) {
        if (this.template && this.template.questions && subjectGuid && objectGuid) {
            return this.template.questions.map((question, idx) => {
                const processingQuestion: any = {
                    auid: idx,
                    guid: question.guid,
                    text: question.name,
                    answerYes: question.answerYes,
                    answerNo: question.answerNo,
                    answerNotConcidered: question.answerNotConcidered,
                    mandatoryReqs: question.mandatoryReqs ? question.mandatoryReqs : null,
                    nPA: question.nPA ? question.nPA : null,
                    fillingType: question.fillingType ? question.fillingType : 'manually',
                };
                if (question.handler && question.handler.length > 0 && this.externalData) {
                    this.externalData.question = processingQuestion;
                    this.externalData.questionData = { subjectGuid: subjectGuid, objectGuid: objectGuid };
                    question.handler.forEach((handler) => {
                        this.handlersService.runHandler(handler, this.externalData, true);
                    });
                }
                if (
                    processingQuestion.fillingType === 'auto' &&
                    (!processingQuestion.result || !processingQuestion.result.answer)
                ) {
                    processingQuestion.fillingType = 'autoAndManually';
                }

                return processingQuestion;
            });
        }

        return [];
    }

    public getObjectProperty(objectGuid, property) {
        const find = this.appeal.objects.find((item) => item.guid === objectGuid);
        if (find) {
            const propertyValue = ObjectUtilities.GetPropertyByPath(find, property);

            return propertyValue ? propertyValue : '';
        }

        return '';
    }

    public getNpaLink() {
        let npaLink = null;
        if (this.appealService.subservice.checkLists && this.appealService.subservice.checkLists.length > 0) {
            npaLink = this.appealService.subservice.checkLists[0].npaLink;
        }

        return npaLink;
    }

    public selectSubject(checkList) {
        this.checkListsProcessingData[checkList.guid].isProcessSelectSubject = true;
    }

    public afterSelectSubject(checkList, subject) {
        checkList.subjectGuid = subject.guid;
        this.checkListsProcessingData[checkList.guid].isProcessSelectSubject = false;
        this.saveService.saveAppeal();
    }

    public afterSelectObject(checkList, object) {
        checkList.objectGuid = object.guid;
        this.checkListsProcessingData[checkList.guid].isProcessSelectObject = false;

        this.saveService.saveAppeal();
    }

    public selectObject(checkList) {
        this.checkListsProcessingData[checkList.guid].isProcessSelectObject = true;
    }

    public get objectsForSelect() {
        return this.appeal.objects.filter((object) => {
            if (this.appeal.objectsForCheckLists.indexOf(object.guid) !== -1) {
                const findInCheckList = this.appeal.checkLists.find((item) => item.objectGuid === object.guid);

                return !findInCheckList;
            }

            return false;
        });
    }

    public selectAction(checkList, question, actionType) {
        if (!question.result) {
            question.result = {};
        }
        if (question.result.answer && question.result.answer === actionType) {
            question.result.answer = null;
        } else {
            question.result.answer = actionType;
        }
        this.updateErrors();
    }

    public fileChange(event, checkList, question) {
        const filesList = event.target.files;
        if (filesList.length > 0) {
            const file = filesList[0];
            this.filesService
                .uploadFile(this.appeal.parentEntries, this.appeal._id, file, file.name)
                .then((uploadedFile: any) => {
                    if (!question.result.files) {
                        question.result.files = [];
                    }
                    question.result.files.push({
                        _id: uploadedFile._id,
                        originalName: uploadedFile.originalName,
                    });

                    this.saveService.saveAppeal();
                });
        }
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public downloadFile(id, originalName) {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    public deleteFile(object, requirement, file) {
        const findIndex = requirement.result.files.findIndex((item) => item._id === file._id);
        requirement.result.files.splice(findIndex, 1);
        this.checkListsService.processingRequirementAction(object, requirement);

        this.saveService.saveAppeal();
    }

    public showTrackOnMap(checkList) {
        // const componentFactoryResolver = this.injector.get<ComponentFactoryResolver>(ComponentFactoryResolver as unknown as Type<ComponentFactoryResolver>);
        // const componentFactory = componentFactoryResolver.resolveComponentFactory<SelectCoordinatesModalComponent>(SelectCoordinatesModalComponent);
        // const coordinatesModalComponent = componentFactory.create(this.injector);
        // this.applicationRef.attachView(coordinatesModalComponent.hostView);
        //
        // const track: { lat: number, lng: number }[] = [];
        // if (checkList.track && checkList.track.coordinates) {
        //     checkList.track.coordinates.forEach(point => {
        //         track.push({ lat: point[1], lng: point[0] });
        //     });
        // }
        // coordinatesModalComponent.instance.track = track;
    }

    public getTimeFromSeconds(secs) {
        let minutes = Math.floor(secs / 60);
        secs = secs % 60;
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;

        return (
            hours.toString().padStart(2, '0') +
            ' ч. ' +
            minutes.toString().padStart(2, '0') +
            ' мин.' +
            secs.toString().padStart(2, '0') +
            ' сек'
        );
    }

    public checkAllowResultValueType(result) {
        return (result.value && typeof result.value === 'string') || result.files;
    }

    public getCommonResults(checkList) {
        return values(checkList.commonResult);
    }

    public changePage(data) {
        this.pagination = data;
    }

    public isShowQuestionActions(checkList, question) {
        return (
            !checkList.getResultFromMP &&
            !checkList.sendedJobToMP &&
            !checkList.isCompleted &&
            (!question.fillingType || question.fillingType !== 'auto') &&
            (!checkList.sendToMP || question.fillingType === 'autoAndManually') &&
            this.mode === 'edit'
        );
    }

    public setProcessingQuestionProperty(checkList, question, property, value) {
        if (!this.checkListsProcessingData[checkList.guid].questions[question.guid]) {
            this.checkListsProcessingData[checkList.guid].questions[question.guid] = {};
        }
        this.checkListsProcessingData[checkList.guid].questions[question.guid][property] = value;
    }

    public isNotHoveredAction(checkList, question, property, value) {
        return (
            (!this.checkListsProcessingData ||
                !this.checkListsProcessingData[checkList.guid] ||
                !this.checkListsProcessingData[checkList.guid].questions[question.guid] ||
                !this.checkListsProcessingData[checkList.guid].questions[question.guid][property]) &&
            (!question.result || !question.result.answer || question.result.answer !== value)
        );
    }

    public isShowQuestionResult(checkList, question) {
        return (
            checkList.getResultFromMP ||
            checkList.sendedJobToMP ||
            checkList.isCompleted ||
            (question.fillingType && question.fillingType === 'auto') ||
            (checkList.sendToMP && (!question.fillingType || question.fillingType !== 'autoAndManually')) ||
            this.mode === 'view'
        );
    }

    public getResultActionProperty(checkList, question, property) {
        const data = {
            yes: {
                theme: 'btn-success',
                icon: 'icon-checkmark3',
                title: 'Соответствует',
            },
            no: {
                theme: 'btn-danger',
                icon: 'icon-cross2',
                title: 'Не соответствует',
            },
            not_applicable: {
                theme: 'btn-info',
                icon: 'icon-minus3',
                title: 'Не применимо',
            },
            processing: {
                theme: 'bg-slate-300',
                icon: 'icon-question7',
                title: 'Определяется',
            },
        };
        const usedData = question.result && question.result.answer ? data[question.result.answer] : data['processing'];

        return usedData[property] ? usedData[property] : '';
    }

    public isExistErrors() {
        this.isProcessValidate = true;
        this.checkListsWithErrors = {};
        this.checkLists.forEach((checkList) => {
            this.checkListsWithErrors[checkList.guid] = {
                user: !checkList.user,
                inspectionDate: !checkList.sendToMP && !checkList.getResultFromMP && !checkList.inspectionDate,
                questions:
                    checkList.questions.filter(
                        (item) =>
                            ((!checkList.sendToMP ||
                                (checkList.sendToMP && item.fillingType && item.fillingType === 'autoAndManually')) &&
                                !item.result) ||
                            (item.result && !item.result.answer)
                    ).length > 0,
            };
        });
        let hasErrors = false;
        Object.keys(this.checkListsWithErrors).forEach((checkListGuid) => {
            const hasErrorsInCheckList =
                this.checkListsWithErrors[checkListGuid].user ||
                this.checkListsWithErrors[checkListGuid].inspectionDate ||
                this.checkListsWithErrors[checkListGuid].questions;
            hasErrors = hasErrorsInCheckList ? true : hasErrors;
        });

        return hasErrors;
    }

    public hasErrors(checkList, property = null) {
        if (this.isProcessValidate) {
            if (property) {
                return !checkList[property];
            } else {
                return (
                    this.checkListsWithErrors[checkList.guid].user ||
                    this.checkListsWithErrors[checkList.guid].inspectionDate ||
                    this.checkListsWithErrors[checkList.guid].questions
                );
            }
        }

        return false;
    }

    public completeCheckLists() {
        this.checkLists.forEach((checkList) => {
            if (!checkList.sendToMP && !checkList.isCompleted) {
                checkList.isCompleted = true;
            }
        });
    }

    public getErrorsList(checkList) {
        let result = '';
        if (this.checkListsWithErrors[checkList.guid].user) {
            result += '<li>не выбран проверяющий инспектор</li>';
        }
        if (this.checkListsWithErrors[checkList.guid].inspectionDate) {
            result += '<li>не заполнена дата проведения проверки</li>';
        }
        if (this.checkListsWithErrors[checkList.guid].questions) {
            result += '<li>отсутствуют ответы на вопросы</li>';
        }

        return result;
    }

    public getPopoverContext(checkList) {
        return { checkList: checkList };
    }

    public updateErrors() {
        if (this.isProcessValidate) {
            this.isExistErrors();
        }
    }

    public filterCheckLists() {
        if (this.searchCheckLists) {
            this.usedCheckLists = this.checkLists.filter((checkList) => {
                return (
                    (this.getObjectProperty(checkList.objectGuid, 'name') &&
                        this.getObjectProperty(checkList.objectGuid, 'name')
                            .toLowerCase()
                            .indexOf(this.searchCheckLists.toLowerCase()) !== -1) ||
                    (this.getObjectProperty(checkList.objectGuid, 'address.fullAddress') &&
                        this.getObjectProperty(checkList.objectGuid, 'address.fullAddress')
                            .toLowerCase()
                            .indexOf(this.searchCheckLists.toLowerCase()) !== -1)
                );
            });
        } else {
            this.usedCheckLists = this.checkLists;
        }
    }

    public convertTimeInSeconds(value) {
        const sec = parseInt(value, 10); // convert value to number if it's string
        const hours = Math.floor(sec / 3600); // get hours
        const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        const seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        let result = '';
        if (hours > 0) {
            result += hours + ' ч ';
        }
        if (minutes > 0 || hours > 0) {
            result += minutes + ' мин. ';
        }
        if (seconds > 0) {
            result += seconds + ' c';
        }

        return result;
    }
}
