import { environment } from '../../../environments/environment';

const isComplex = (value) => !!(value && typeof value === 'object' && !Array.isArray(value));

export const walkObject = (object = {}, visit: (value: any) => void) => {
    visit(object);
    if (isComplex(object)) {
        Object.entries(object).forEach(([, nestedObject]) => {
            if (isComplex(nestedObject)) {
                walkObject(nestedObject, visit);
            }
        });
    }
};

export const getLastHourTimeAndOffset = () => {
    // сохранено "зашитое" смещение по умолчанию для совместимости со старой логикой
    const offset = environment.dynamicOffset ? new Date().getTimezoneOffset() / 60 : -3;
    let hour = `${24 + offset - 1}`;
    hour = hour.length === 1 ? `0${hour}` : hour;
    return `T${hour}:00:00.000+0000`;
};
