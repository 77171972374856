import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {
    AccessService,
    RestService,
    SelectionService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CanDeactivateModalComponent } from '@components/modal/can-deactivate-modal.component';
import { Permission } from '../../../common/services/permission';
import { OrganizationMetaComponent } from './organization-meta/organization-meta.component';
import * as moment from 'moment';
import * as _ from 'lodash-es';

@Component({
    selector: 'organization',
    templateUrl: './organization-edit.component.html',
    styleUrls: [
        '../../element-edit.css',
    ],
})
export class OrganizationEditComponent implements OnInit {
    public organization;
    public otherOrganizations = [];
    public schema = null;
    public isLoading = false;
    public baseOrganization;
    public collection;
    public activeTab = 'mainInfo'; // Активная вкладка

    public permission;

    public tenantDb = this.storage.getItem('tenantDb');
    public findedOrganizations = [];
    public errors: any = {};
    public modalRef: BsModalRef;
    @ViewChild('findedOrganizationsModal', { static: false }) public findedOrganizationsModal: TemplateRef<any>;
    @ViewChild('saveBtn', { static: false }) public saveBtn;
    @ViewChild(CanDeactivateModalComponent, { static: false }) public canDeactivateModal: CanDeactivateModalComponent;
    @ViewChild('metaComponent', { static: false }) public metaComponent: OrganizationMetaComponent; // Компонент - мета-данные организации

    public isProfileMode = false; // режим профиля организации
    public moduleBaseUrl; // базовый URL модуля

    public kndKinds; // виды КНД, к которым относится данный КНО

    public tabs = [];
    public processesTab = {};
    public printFormTab = {};

    public calendarTab = {};

    public printForms = [];

    public topPosition = 0;

    public organizationProcesses = [];

    public organizationsBaseSearch = [];

    public organizationPrintForms;
    public requests = [];
    public stendSettings;

    public calendarData;
    public baseCalendarData;
    public activeCalendarTab;
    public isVisibleCalendarWidget = true;
    public localizations;

    public constructor(
        public access: AccessService,
        private route: ActivatedRoute,
        private router: Router,
        private restService: RestService,
        private toaster: ToasterService,
        private location: Location,
        private storage: StorageService,
        private selectionService: SelectionService,
        private modalService: BsModalService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.isProfileMode = this.moduleBaseUrl === 'profile';

        this.permission = Permission;
        this.route.data.subscribe(
            response => {
                const resolves = response.resolves;
                this.organization = resolves.organization;
                if (resolves.processes) {
                    this.organizationProcesses = resolves.processes;
                    this.tabs.push(this.processesTab);
                }
                if (resolves.printForms) {
                    this.organizationPrintForms = resolves.printForms;
                    this.tabs.push(this.printFormTab);
                }
                if (resolves.stendSettings) {
                    this.stendSettings = resolves.stendSettings;
                }
                this.baseOrganization = this.organization;
                if (!this.organization.plugins) {
                    this.organization.plugins = {};
                }

                if (this.selectionService.isProcessSelect) {
                    this.organization = _.cloneDeep(this.selectionService.transferObject);
                    switch (this.selectionService.transferOperation) {
                        case 'selectBpmnForPlan':
                            this.activeTab = 'planSettings';
                            break;
                        case 'selectPrintForms':
                            this.activeTab = 'printForms';
                            break;
                        case 'selectBpmnForInspectionGsn':
                            this.activeTab = 'gsnProgramSettings';
                            break;
                        case 'selectSupervisoryCaseRiskCalculateBpmn':
                            if (this.selectionService.selectedItems.length > 0) {
                                const selected = this.selectionService.selectedItems[0];
                                if (!this.organization.supervisoryCaseSettings) {
                                    this.organization.supervisoryCaseSettings = {};
                                }
                                this.organization.supervisoryCaseSettings.calculateRiskCategoryBpmn = {id: selected._id, name: selected.title};
                            }
                            this.selectionService.clearData();
                            break;
                        case 'selectProcess':
                            this.organizationProcesses = this.selectionService.additionalData.processes;
                            this.activeTab = 'processesSettings';
                            break;
                        case 'selectRequests':
                            this.activeTab = 'requests';
                            break;
                        default:
                            this.activeTab = 'kndKinds';
                            break;
                    }
                }
                if (this.organization._id) {
                    this.tabs.push(this.calendarTab);
                    if (resolves['calendar']) {
                        this.prepareCalendarData(resolves['calendar']);
                    }
                }
            },
            error => {
                console.log(error);
            },
        );
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'events',
                'objects',
                'organizations',
                'print-forms',
                'standards',
                'global-calendar',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
            this._initData();
        });
    }

    private _initData() {
        this.tabs = [
            {
                code: 'mainInfo',
                name: this.localizations.events.basic_params,
            },
            {
                code: 'metaInfo',
                name: this.localizations.objects.meta_data,
            },
            {
                code: 'requests',
                name: this.localizations.standards.requests,
            },
        ];

        this.processesTab = {
            code: 'processesSettings',
            name: this.localizations.objects.processes_setup,
        };
        this.printFormTab = {
            code: 'printForms',
            name: this.localizations['print-forms'].name,
        };

        this.calendarTab = {
            code: 'calendar',
            name: this.localizations['global-calendar'].name,
        };
    }

    public onScrollEvent(position) {
        this.topPosition = position;
    }

    public prepareCalendarData(data) {
        this.calendarData = [];
        data.forEach(item => {
            this.calendarData.push({
                id: item._id,
                year: item.year,
                days: item.days,
            });
        });
        const currentYear = moment().year();
        const find = this.calendarData.find(item => item.year === currentYear);
        if (!find) {
            this.calendarData.push({
                year: currentYear,
                days: {},
            });
        }
        this.baseCalendarData = _.cloneDeep(this.calendarData);
        this.activeCalendarTab = this.calendarData.find(item => item.year === currentYear);
    }

    public changeCalendarWidget(data) {
        this.isVisibleCalendarWidget = false;
        setTimeout(() => {
            this.activeCalendarTab = data;
            this.isVisibleCalendarWidget = true;
        }, 100);
    }

    public async getPlanCriteria() {
        const planCriteria = this.storage.getItem('planCriteria');
        if (!planCriteria) {
            const data = await this.restService.search('nsiPlanCriteria');
            this.storage.setItem('planCriteria', data);
        }
    }

    public async getRiskCategories() {
        const riskCategories = this.storage.getItem('riskCategories');
        if (!riskCategories) {
            const data = await this.restService.search('nsiErpRiskCategories');
            this.storage.setItem('riskCategories', data);
        }
    }

    public selectParent(organization) {
        const selectOrganization = this.otherOrganizations.find(item => item._id === organization.id);
        this.organization.parentId = selectOrganization._id;
        this.organization.parentOrganizationId = selectOrganization.guid;
    }

    public clearParent() {
        this.organization.parentOrg = null;
        this.organization.parentId = null;
        this.organization.parentOrganizationId = null;
    }

    /**
     * Проверка полномочий
     */
    public disabledSaveBtn(permissions) {
        return !this.access.hasAccess(permissions);
    }

    private async _create() {
        try {
            const result: any = await this.restService.create('organizations', this.organization);
            this.organization._id = result._id;
            await this.metaComponent.save();
            this.toaster.success(this.localizations.organizations.notifications.created);
            await this.router.navigate(['admin', 'organizations', 'edit', result._id]);
        } catch (error) {
            this.toaster.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    private async _saveCalendar() {
        const promises = [];
        const added = [];

        if (this.calendarData && this.calendarData.length) {
            this.calendarData.forEach(yearData => {
                const findInBase = this.baseCalendarData.find(item => item.year === yearData.year);
                if (JSON.stringify(findInBase.days) !== JSON.stringify(yearData.days)) {
                    const savedData: any = {
                        days: yearData.days,
                    };
                    if (yearData.id) {
                        savedData._id = yearData.id;
                        promises.push(this.restService.update('unitsProductionCalendars', savedData));
                    } else {
                        added.push(yearData.year);
                        savedData.year = yearData.year;
                        savedData.unitId = this.organization._id;
                        savedData.unitCode = this.organization.code;
                        promises.push(this.restService.create('unitsProductionCalendars', savedData));
                    }
                }
            });
        }

        const result = await Promise.all(promises);
        result.forEach(resultItem => {
            if (added.indexOf(resultItem.year) !== -1) {
                const find = this.calendarData.find(item => item.year === resultItem.year);
                find.id = resultItem._id;
            }
        });
        this.baseCalendarData = _.cloneDeep(this.calendarData);

        return true;
    }

    private async _update() {
        try {
            this.isLoading = true;
            await this.restService.update('organizations', this.organization);
            await this.metaComponent.save();
            await this._saveCalendar();
            this.toaster.success(this.localizations.organizations.notifications.data_saved);
            const currentOrganization = this.storage.getItem('currentOrganization');
            if (this.organization._id === currentOrganization._id
                && (this.organization.kndKinds || this.organization.plansSettings || this.organization.printForms
                    || this.organization.planApprove || this.organization.planApproveById)) {
                if (this.organization.kndKinds) {
                    currentOrganization.kndKinds = this.organization.kndKinds;
                }
                if (this.organization.plansSettings) {
                    currentOrganization.plansSettings = this.organization.plansSettings;
                }
                if (this.organization.printForms) {
                    currentOrganization.printForms = this.organization.printForms;
                }
                if (this.organization.inspectionGsnSettings) {
                    currentOrganization.inspectionGsnSettings = this.organization.inspectionGsnSettings;
                }
                if (this.organization.supervisoryCaseSettings) {
                    currentOrganization.supervisoryCaseSettings = this.organization.supervisoryCaseSettings;
                }
                if (this.organization.processesSettings) {
                    currentOrganization.processesSettings = this.organization.processesSettings;
                }
                if (this.organization.requests && this.organization.requests.length > 0) {
                    currentOrganization.requests = this.organization.request;
                }
                if (this.organization.registryTypes) {
                    currentOrganization.registryTypes = this.organization.registryTypes;
                }
                this.storage.setItem('currentOrganization', currentOrganization);
            }
        } catch (error) {
            this.toaster.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    public back() {
        this.location.back();
    }

    public async save(forceSave = false) {
        if (this.organization.serviceCodes) {
            this.organization.serviceCodes = this.organization.serviceCodes.replace(/\s/g, '');
        }
        if (this.organization.proxyServiceCodes) {
            this.organization.proxyServiceCodes = this.organization.proxyServiceCodes.replace(/\s/g, '');
        }
        // перевод GUID в int, если это возможно, иначе string
        const checkNumberGuid = (this.organization.externalGuid * 1);
        if (checkNumberGuid) {
            this.organization.externalGuid = this.organization.externalGuid * 1;
        }

        if (this.organization._id) {
            return this._update();
        }

        const orSubConditions = [];
        if (this.organization.name) {
            orSubConditions.push({
                field: 'name',
                operator: 'like',
                value: this.organization.name,
            });
        }
        if (this.organization.shortName) {
            orSubConditions.push({
                field: 'shortName',
                operator: 'like',
                value: this.organization.shortName,
            });
        }
        if (this.organization.inn && this.organization.ogrn && this.organization.kpp) {
            orSubConditions.push({
                andSubConditions: [
                    {
                        field: 'inn',
                        operator: 'eq',
                        value: this.organization.inn,
                    },
                    {
                        field: 'ogrn',
                        operator: 'eq',
                        value: this.organization.ogrn,
                    },
                    {
                        field: 'kpp',
                        operator: 'eq',
                        value: this.organization.kpp,
                    },
                ],
            });
        }

        // поиск похожих организаций
        if (orSubConditions.length) {
            const otherOrganizations: any = await this.restService.search('organizations', {search: {search: [{orSubConditions: orSubConditions}]}});

            if (otherOrganizations.length) {
                this.findedOrganizations = otherOrganizations;
                this.modalRef = this.modalService.show(this.findedOrganizationsModal, {backdrop: 'static', class: 'modal-lg'});

                return;
            }
        }

        if (this._validate() || forceSave) {
            return this._create();
        }
    }

    private _validate(): boolean {
        this.errors = {};

        if (!this.organization.name) {
            this.errors.name = !this.organization.name;
        }

        if (!this.organization.shortName) {
            this.errors.shortName = true;
        }

        if (!this.organization.ogrn) {
            this.errors.ogrn = true;
        }

        if (!this.organization.kpp) {
            this.errors.kpp = true;
        }

        if (!this.organization.inn) {
            this.errors.inn = true;
        }

        if (Object.keys(this.errors).length) {
            this.toaster.error(this.localizations.organizations.notifications.errors.general);

            return false;
        }

        return true;
    }

    public selectTenantDb(data) {
        const find = this.tenantDb.find(item => item._id === data.id);
        this.organization.tenantDb = {id: find._id, name: find.name};
    }

    public removeTenantDb() {
        this.organization.tenantDb = null;
    }

    public getTenantDbCurrentValue() {
        if (this.organization.tenantDb) {
            const find = this.tenantDb.find(item => item._id === this.organization.tenantDb.id);

            return [{id: find.id, text: find.description}];
        }

        return [];
    }

    public changeTenantProperty(flag, property) {
        this.organization[flag] = !this.organization[flag];
        if (!this.organization[flag]) {
            this.organization[property] = null;
        }
    }

    public changeKndKinds(data) {
        this.kndKinds = data;
    }

    public getActive(list, name) {
        return list.filter(item => item.id === this.organization[name]);
    }

    public select(data, list, name)  {
        const findItem = list.find(item => item.id === data.id);
        this.organization[name] = findItem ? findItem.id : null;
        if (data.id === 'self') {
            this.organization.planApproveById = null;
        }
    }

    public selectParentOrganization(org) {
        this.organization.parentOrganizationId = org.id;
        this.organization.parentId = org._id;
    }

    public clearParentOrganization() {
        this.organization.parentId = null;
        this.organization.parentOrganizationId = null;
    }

    public async selectSupervisoryCaseBpmn() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = _.cloneDeep(this.organization);
        this.selectionService.transferOperation = 'selectSupervisoryCaseRiskCalculateBpmn';
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.selectType = 'one';

        await this.router.navigate(['/processes/risk-models']);
    }

    public async goToUnit(unit) {
        this.modalRef.hide();
        await this.router.navigate(['admin', 'organizations', 'edit', unit._id]);
    }
}
