import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, SelectionService, TranslateService } from '@evolenta/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'organization-print-forms',
    templateUrl: 'organization-print-forms.component.html',
})
export class OrganizationPrintFormsComponent implements OnInit {
    @Input() public organization;
    @Input() public printForms = [];
    @Input() public mode = 'default';
    @Input() public printFormTypes = [];

    public printForm;
    public localizations;

    @Output() public onSaveOrganization = new EventEmitter<boolean>();

    public constructor(
        private rest: RestService,
        private router: Router,
        private selectionService: SelectionService,
        private translate: TranslateService
    ) {}

    /**
     * Инициализация компонента, получение данных
     */
    public ngOnInit() {
        this._loadTranslations();
        if (!this.organization.printForms) {
            this.organization.printForms = [];
        }
        if (this.selectionService.isProcessSelect) {
            this.completeSelectPrintForms();
        } else {
            if (this.organization.printForms) {
                const printFormIds = this.getUsedPrintFormIds();
                if (printFormIds.length > 0) {
                    this.rest
                        .search('printForms', {
                            search: { search: [{ field: '_id', operator: 'in', value: printFormIds }] },
                            size: 100,
                        })
                        .then((printForms) => {
                            this.printForms = printForms;
                        });
                }
            }
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'requests', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getUsedPrintFormIds() {
        return this.organization.printForms ? this.organization.printForms.map((item) => item.id) : [];
    }

    public completeSelectPrintForms() {
        if (this.selectionService.isProcessSelect) {
            if (!this.organization.printForms) {
                this.organization.printForms = [];
            }

            this.printForms = _.cloneDeep(this.selectionService.additionalData.printForms);

            const printFormCode = this.selectionService.additionalData.printFormCode;

            this.selectionService.selectedItems.forEach((printForm) => {
                const find = this.printForms.find((item) => item._id === printForm._id);
                if (!find) {
                    this.printForms.push(printForm);
                }
                const findInOrganization = this.organization.printForms.findIndex(
                    (item) => item.code === printFormCode
                );
                if (findInOrganization === -1) {
                    this.organization.printForms.push({ code: printFormCode, id: printForm._id });
                } else {
                    this.organization.printForms[findInOrganization].id = printForm._id;
                }
            });

            this.selectionService.clearData();
        }
    }

    public selectPrintForms(printFormCode) {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.organization;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectPrintForms';
        this.selectionService.additionalData = {
            printForms: this.printForms,
            printFormCode: printFormCode,
        };

        this.router.navigate(['metodolog', 'print-forms']);
    }

    public deletePrintForm(printFormCode) {
        const findIndex = this.organization.printForms.findIndex((item) => item.code === printFormCode);
        const deletedPrintFormId = this.organization.printForms[findIndex].id;

        this.organization.printForms.splice(findIndex, 1);

        const usedPrintFormsIds = this.getUsedPrintFormIds();
        const findPrintFormIndex = usedPrintFormsIds.findIndex((item) => item === deletedPrintFormId);

        if (findPrintFormIndex === -1) {
            const findIndexInAllPrintForms = this.printForms.findIndex((item) => item._id === deletedPrintFormId);

            if (findPrintFormIndex !== -1) {
                this.printForms.splice(findIndexInAllPrintForms, 1);
            }
        }
    }

    public getPrintFormByCode(printFormCode) {
        const find = this.organization.printForms.find((item) => item.code === printFormCode);

        if (find) {
            return this.printForms.find((item) => item._id === find.id);
        }

        return null;
    }
}
