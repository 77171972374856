<div class="alert alert-warning alert-styled-left alert-bordered" *ngIf="soapdumps.length == 0">
    <button class="close" type="button" data-dismiss="alert"><span>×</span></button>
    <span class="text-semibold">Внимание!</span>  SOAP-дампы отсутствуют.
</div>

<div class="panel panel-default" *ngIf="soapdumps.length > 0">
    <div class="panel-body no-padding">
        <table class="table table-striped table-hover" >
            <thead>
            <tr>
                <th class="no-border-top">
                    <span class="anchor text-info" (click)="sort()">Дата получения</span>
                    <i class="ml-5" [ngClass]="{'icon-arrow-small-up' : sortOrder === 'DESC', 'icon-arrow-small-down': sortOrder === 'ASC'}"></i>
                </th>
                <th class="no-border-top">Запрос</th>
                <th class="no-border-top">Ответ</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of soapdumps" (click)="selectSoapDump(item)" [ngClass]="{'success': soapDump && item._id === soapDump._id}">
                <td>{{item.dateCreation | date: 'short'}}</td>
                <td>
                    <ng-container *ngIf="!item.requestFile && item.request">
                        {{item.request.substring(0,50)}}
                    </ng-container>
                    <ng-container *ngIf="item.requestFile">
                        <a class="anchor" (click)="downloadFile(item.requestFile._id, item.requestFile.originalName)">{{ item.requestFile.originalName }}</a>
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="!item.responseFile && item.response">
                        {{item.response.substring(0,50)}}
                    </ng-container>
                    <ng-container *ngIf="item.responseFile">
                        <a class="anchor" (click)="downloadFile(item.responseFile._id, item.responseFile.originalName)">{{ item.responseFile.originalName }}</a>
                    </ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="row" *ngIf="soapDump">
    <div class="col-md-6">
        <div class="form-group">
            <label class="display-block mb-20">
                <span class="text-semibold">Дамп запроса</span>
                <button class="btn btn-default btn-xs pull-right" (click)="saveAsFile('request')"><i class="icon-floppy-disk"></i></button>
            </label>

            <textarea class="form-control" rows="20">{{soapDump.request}}</textarea>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group">
            <label class="display-block mb-20">
                <span class="text-semibold">Дамп ответа</span>
                <button class="btn btn-default btn-xs pull-right" (click)="saveAsFile('response')"><i class="icon-floppy-disk"></i></button>
            </label>
            <textarea class="form-control" rows="20">{{soapDump.response}}</textarea>
        </div>
    </div>
</div>
