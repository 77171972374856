import { Injectable } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { ADMIN_ROLE } from '../constants';

@Injectable()
export class RsoService {
    private RSO_OPERATOR_ROLE = 'operator_eks';

    private RSO_SPECIALIST_ROLE = 'rso_specialist';

    private RSO_SUPERVISOR_ROLE = 'rso_supervisor';

    private CONFIRMATION_SUPERVISOR_ROLE = 'rso_confirmation_supervisor';

    private user = {
        login: '',
        currentRolesMnemonics: [],
    };

    public constructor(
        private storage: StorageService,
    ) {
        this.user = this.storage.getItem('user');
    }

    // Далее используется для исключения ролей РСО для суперпользователя,
    // т.к. в сервисе КПП есть костыль для суперпользователя с добавлением всех ролей в независимости от настроек Аванпоста
    private isAdminUser() {
        return this.user.currentRolesMnemonics.includes(ADMIN_ROLE);
    }

    public isRsoOperatorUser() {
        return !this.isAdminUser() && this.user.currentRolesMnemonics.includes(this.RSO_OPERATOR_ROLE);
    }

    public isRsoSupervisorUser() {
        return !this.isAdminUser() && this.user.currentRolesMnemonics.includes(this.RSO_SUPERVISOR_ROLE);
    }

    public isRsoSpecialistUser() {
        return !this.isAdminUser() && this.user.currentRolesMnemonics.includes(this.RSO_SPECIALIST_ROLE);
    }

    public isRsoUser() {
        return this.isRsoOperatorUser() || this.isRsoSupervisorUser() || this.isRsoSpecialistUser();
    }

    public isRsoConfirmationSupervisorUser() {
        return !this.isAdminUser() && this.user.currentRolesMnemonics.includes(this.CONFIRMATION_SUPERVISOR_ROLE);
    }

    public isRsoAnySupervisorUser() {
        return this.isRsoSupervisorUser() || this.isRsoConfirmationSupervisorUser();
    }

    public getRsoOperatorRole() {
        return this.RSO_OPERATOR_ROLE;
    }

    public canNotEditAppeal(appeal: any) {
        if (this.isRsoUser()) {
            return this.isRsoOperatorUser() || !this.isSameOrganization(appeal);
        }

        return false;
    }

    public canNotEditTask(appeal: any, task: any, users: any[] | null) {
        if (this.isRsoUser()) {
            if (this.isSameOrganization(appeal)) {
                return !(this.isSameAssignee(task) || this.isUnassignedTask(task));
            } else {
                return !(this.isSameAssignee(task) || this.isAssigneeAnyOfUser(task, users));
            }
        }

        return false;
    }

    public isAssigneeAnyOfUser(task: any, users: any[]) {
        return users && users.find(user => user.login && user.login.toLowerCase() === task.assignee);
    }

    private isSameOrganization(appeal: any) {
        const currentOrganization = this.storage.getItem('currentOrganization');

        return currentOrganization && appeal.unitId === currentOrganization._id;
    }

    private isSameAssignee(task: any) {
        return task.assignee === this.user.login ||
            task.candidateGroups && !!task.candidateGroups.find(role => this.user.currentRolesMnemonics.includes(role));
    }

    private isUnassignedTask(task: any) {
        return !task.assignee && (!task.candidateGroups || !task.candidateGroups.length);
    }
}
