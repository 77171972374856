<div class="p-20">
    <ng-container *ngIf="data.allowEdit">
        <div class="checkbox-block" *ngFor="let appealDocument of appealDocuments">
            <input type="checkbox" class="checkbox" [(ngModel)]="appealDocument.checked" id="document{{appealDocument.guid}}" (ngModelChange)="toggleDocument(appealDocument)">
            <label for="document{{appealDocument.guid}}">{{appealDocument.name}}</label>
        </div>

        <button class="btn btn-default btn-xs btn-labeled btn-labeled-right mt-15"
                (click)="signAllFiles()"
                [disabled]="data.envelope.appealDocuments.length == 0 || isProcessSignFiles">
            <b><i [ngClass]="{'icon-spinner2 spinner': isProcessSignFiles, 'icon-pen': !isProcessSignFiles}"></i></b>
            Подписать файлы
        </button>
    </ng-container>

    <ng-container *ngIf="!data.allowEdit">
        <ul class="no-margin-bottom pl-15 list list-square">
            <ng-container *ngFor="let appealDocument of appealDocuments">
                <li *ngIf="checkDocumentUse(appealDocument)">{{appealDocument.name}}</li>
            </ng-container>
        </ul>
    </ng-container>

</div>

<!-- Диалог для подписания файлов -->
<evolenta-cades-sign-modal (onSignCompletedEnvelope)="onSignatureComplete($event)">
</evolenta-cades-sign-modal>
