import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, SessionService, StorageService, ToasterService } from '@evolenta/core';
import { PersonService } from '../../../common/services/persons.service';
import { DocumentService } from './components/documents/document.service';
import { Config } from '../../../common/services/config';
import { AppealSubservicesService } from './appeal-subservices.service';
import * as _ from 'lodash-es';
import { FilesQueueService } from '../../../common/services/filesQueue.service';
import { walkObject } from '../../../common/utils/misc.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorLoggingService } from '../error-logging.service';
import { AppealValidateService } from './appeal-validate.service';
import { RsoService } from '../../../common/services/rso.service';

@Injectable()
export class AppealSaveService {
    public appeal; // обрабатываемое дело
    public baseAppeal; // предыдущая актуальная на сервере версия дела
    public documentsWithFiles = {}; // Объект, необходимый для КНМ статуса загрузки файлов документов
    public reestrModal; // модальное окно для выбора соответствия объекта дела и клиента в реестре клиентов
    public isProcessSavingAppeal = false; // Флаг осуществления процесса сохранения дела в данный момент времени
    public isCreateAppealMode = false; // Режим создания дела
    public forbidRedirect = false;

    public moduleBaseUrl = 'ais';
    public collectionName = 'appeals';
    public appealCreated;
    public nextSection;
    public case: any;

    public constructor(
        private httpClient: HttpClient,
        private restService: RestService,
        private storageService: StorageService,
        private sessionService: SessionService,
        private router: Router,
        private toasterService: ToasterService,
        private documentService: DocumentService,
        private appealSubservicesService: AppealSubservicesService,
        private errorLoggingService: ErrorLoggingService,
        private personService: PersonService,
        private filesQueueService: FilesQueueService,
        private appealValidateService: AppealValidateService,
        private rsoService: RsoService,
    ) {}

    public clearDatePlaneFinishInBaseAppeal() {
        const baseAppeal = this.storageService.getItem('baseAppeal');
        delete baseAppeal.datePlaneFinish;
        delete baseAppeal.datePlaneFinishChangeHistory;

        this.storageService.setItem('baseAppeal', baseAppeal);
    }

    /**
     * Базовая функция сохранения дела: КНМ на изменения в основных сущностях дела + в объектах и документах - запуск дочерней процедуры сохранения
     */
    public async saveAppeal(force = false, redirectAfterDocumentSave = true) {
        this.isProcessSavingAppeal = true;
        this.appealCreated = false;
        if (this.appeal.status.code === 'archive') {
            this.collectionName = this.appeal.inPreArchive ? 'appealsPreArchive' : 'appealsArchive';
        }

        // если стоит флаг force, то кеширующий baseAppeal не используется
        this.baseAppeal = force ? {} : this.storageService.getItem('baseAppeal') || {};

        // Сравнение основных полей дела для формирования объекта на сохранение
        const appealForSave: any = {}; // объект дела, включающий только измененный состав полей

        // Пробегаем по полям дела для сравнения с аналогичными полями базового дела
        Object.keys(this.appeal).forEach(item => {
            // Если поле не самостоятельные сущности: объекты, документы, услуги (они сохраняются отдельными сущностями)
            if (item !== 'objects' && item !== 'documents' && item !== 'subservice' && item !== 'subjects') {
                // Сравнение остальных полей дела
                if (JSON.stringify(this.appeal[item]) !== JSON.stringify(this.baseAppeal[item])) {
                    appealForSave[item] = this.appeal[item];
                }
            }
        });

        if (!this.rsoService.isRsoUser()) {
            const currentOrganization = this.storageService.getItem('currentOrganization');
            if (currentOrganization.district) {
                if (!appealForSave.unit) {
                    appealForSave.unit = {
                        id: currentOrganization._id,
                        name: currentOrganization.name,
                        shortName: currentOrganization.shortName,
                    };
                }

                appealForSave.unit.district = {
                    id: currentOrganization.district._id,
                    name: currentOrganization.district.name,
                };
            }
        }

        if (!Object.keys(appealForSave).length) {
            return this._saveAppealSubservice(false, redirectAfterDocumentSave);
        }
        // Если есть изменения в основном наборе полей, сохраняем сначала основную сущность дела

        if (!this.appeal._id) {
            const standardTasksPeriodInfo = {
                tasksPeriod: null,
                periodType: 'calendarDays',
                subProcesses: [],
            };
            try {
                const subserviceId = this.appeal.subservice.id;
                const subservice: any = await this.restService.find('subservices', subserviceId);
                const standard: any = await this.restService.find('standards', subservice.standardId);

                if (standard && standard.tasksPeriod && standard.tasksPeriod.autoFill) {
                    standardTasksPeriodInfo.tasksPeriod = standard.tasksPeriod;

                    const variant = this.appeal.subservice.variant;
                    if (variant && variant.selectedVariants) {
                        const selectedVariant = variant.selectedVariants.reduce((curr, selectedVar) => {
                            return curr === null
                                ? standard.variants.find(el => el.guid === selectedVar.guid)
                                : curr.children.find(el => el.guid === selectedVar.guid);
                        }, null);
                        standardTasksPeriodInfo.periodType = selectedVariant.periodType;
                    } else {
                        standardTasksPeriodInfo.periodType = standard.terms.periodType;
                    }

                    if (standard.process.tasks && standard.tasksPeriod && standard.tasksPeriod.autoFill) {
                        standardTasksPeriodInfo.subProcesses = standard.process.tasks.filter(
                            task => task.type === 'subProcess',
                        );
                    }
                }
            } catch (error) {
                await this.errorLoggingService.log(this.case, error);
                console.error(error);
            }

            appealForSave.standardTasksPeriodInfo = standardTasksPeriodInfo;
        }

        if (this.appeal._id) {
            // Обновление дела на сервере
            // Добавление в сохраняемых объект ID и GUID дела
            appealForSave._id = this.appeal._id;
            appealForSave.guid = this.appeal.guid;
        }

        try {
            this.appealCreated = !this.appeal._id;
            const modifiedAppeal: any = this.appeal._id
                ? await this.restService.update('appeals', appealForSave)
                : await this.restService.create('appeals', appealForSave);
            delete modifiedAppeal.objects;
            delete modifiedAppeal.documents;
            delete modifiedAppeal.subservice;
            delete modifiedAppeal.subjects;
            // Объединяем поля измененного дела с текущим значением
            this.appeal = Object.assign(this.appeal, modifiedAppeal);

            // Обновление данных дела в базовом объекте дела
            this.baseAppeal = Object.assign(this.baseAppeal, modifiedAppeal);
            this.storageService.setItem('baseAppeal', this.baseAppeal);
        } catch (error) {
            await this.errorLoggingService.log(this.case, error);
            throw error;
        } finally {
            this.isProcessSavingAppeal = false;
        }

        return this._saveAppealSubservice(false, redirectAfterDocumentSave);
    }

    /**
     * Сохранение услуг в деле
     * @force - принудительное обновление услуг в деле
     */
    private async _saveAppealSubservice(forceUpdate = false, redirectAfterDocumentSave = true) {
        // Получение набора промисов для измененных (только измененный состав полей) или добавленных объектов
        // TODO убрать после выкатки бека

        const subserviceSaving = this._processAppealEntities(this.appeal, 'subservice', forceUpdate);
        const subserviceSavingResult = await Promise.all(
            subserviceSaving.map(saveResults =>
                saveResults.then(
                    success => ({ success }),
                    e => ({ error: e }),
                ),
            ),
        );

        if (!(subserviceSavingResult && subserviceSavingResult.length)) {
            return this._saveAppealSubjects(redirectAfterDocumentSave);
        }

        const subserviceData = subserviceSavingResult[0];
        let error = null; // ошибка при сохранении услуг дела

        // Если было не удаление, а добавление или обновление услуги
        if (subserviceData.success) {
            // Сохранение прошло успешно
            const subservice = subserviceData.success;
            // Если была процедура создания или редактирования, а не удаления
            this.appeal.subservice = _.merge(this.appeal.subservice, subservice);
            const baseAppeal = this.storageService.getItem('baseAppeal');
            baseAppeal.subservice = subservice;
            this.storageService.setItem('baseAppeal', baseAppeal);
        } else {
            // Ошибка при сохранении
            error = subserviceData.error;
        }

        if (!error && !forceUpdate) {
            // Инициализация сохранения объектов дела
            return this._saveAppealSubjects(redirectAfterDocumentSave);
        }

        // Ошибка, прерываем процедуру сохранения
        this.isProcessSavingAppeal = false;

        if (!forceUpdate) {
            throw error;
        }
    }

    /**
     * Принудительное фоновое сохранение части данных услуг в деле
     */
    public async saveForceAppealSubserviceParts(subservicePartData: any) {
        return await this.restService.update(
            this.appeal,
            {
                mainId: this.appeal.subservice.mainId,
                guid: this.appeal.subservice.guid,
                ...subservicePartData,
            },
            'subservices',
            null,
            this.collectionName,
        );
    }

    /**
     * Окончательное сохранение объектов и переход к сохранению объектов
     */
    private async _saveAppealSubjects(redirectAfterDocumentSave = true) {
        // Получение набора промисов для измененных (только измененный состав полей) или добавленных объектов
        const promises = this._processAppealEntities(this.appeal, 'subjects');

        const subjects = await Promise.all(
            promises.map(saveResults =>
                saveResults.then(
                    success => ({ success }),
                    e => ({ error: e }),
                ),
            ),
        );
        let error = null;

        subjects.forEach(subjectData => {
            if (subjectData.success) {
                // Данные объекта успешно сохранены, обновляем информацию в деле
                const subject = subjectData.success;
                if (subject) {
                    // Если было осуществлено редактирование или создание объекта, а не удаление
                    const findIndex = this.appeal.subjects.findIndex(item => item.guid === subject.guid);
                    this.appeal.subjects[findIndex] = Object.assign(this.appeal.subjects[findIndex], subject);
                    // Обновление данных в сервисе
                    if (
                        this.appealSubservicesService.subjectsData &&
                        this.appealSubservicesService.subjectsData[subject.guid]
                    ) {
                        this.appealSubservicesService.subjectsData[subject.guid] = Object.assign(
                            this.appealSubservicesService.subjectsData[subject.guid],
                            subject,
                        );
                    }
                    this._updateElementInBaseAppeal(subject, 'subjects');
                }
            } else {
                error = subjectData.error;
            }
        });

        if (error) {
            // Если при сохранении хотя бы одного элемента произошла ошибка, обрываем процедуру сохранения и возвращаем ошибку
            this.isProcessSavingAppeal = false;

            throw error;
        }

        // Инициализация сохранения файлов документов дела
        return await this._saveAppealObjects(redirectAfterDocumentSave);
    }

    /**
     * Окончательное сохранение объектов и переход к сохранению документов
     */
    private async _saveAppealObjects(redirectAfterDocumentSave = true) {
        // Получение набора промисов для измененных (только измененный состав полей) или добавленных объектов
        const promises = this._processAppealEntities(this.appeal, 'objects');

        const objects = await Promise.all(
            promises.map(saveResults =>
                saveResults
                    .then(success => ({ success }))
                    // tslint:disable-next-line:no-shadowed-variable
                    .catch(error => ({ error })),
            ),
        );

        let error = null;

        objects.forEach(objectData => {
            if (objectData.success) {
                // Данные объекта успешно сохранены, обновляем информацию в деле
                const object = objectData.success;
                if (object) {
                    // Если было осуществлено редактирование или создание объекта, а не удаление
                    const findIndex = this.appeal.objects.findIndex(item => item.guid === object.guid);
                    this.appeal.objects[findIndex] = Object.assign(this.appeal.objects[findIndex], object);
                    // Обновление данных в сервисе
                    if (
                        this.appealSubservicesService.objectsData &&
                        this.appealSubservicesService.objectsData[object.guid]
                    ) {
                        this.appealSubservicesService.objectsData[object.guid] = Object.assign(
                            this.appealSubservicesService.objectsData[object.guid],
                            object,
                        );
                    }
                    this._updateElementInBaseAppeal(object, 'objects');
                }
            } else {
                error = objectData.error;
            }
        });

        if (error) {
            // Если при сохранении хотя бы одного элемента произошла ошибка, обрываем процедуру сохранения и возвращаем ошибку
            this.isProcessSavingAppeal = false;

            throw error;
        }
        await this._saveFilesInAppealVariants(redirectAfterDocumentSave);

        // Инициализация сохранения файлов документов дела
        return this._saveFilesInAppealDocuments(redirectAfterDocumentSave);
    }

    /**
     * 1-ый этап сохранения документов: сохранение файлов
     */
    private async _saveFilesInAppealDocuments(redirectAfterDocumentSave = false) {
        this.documentsWithFiles = {};
        // Инициализация массива документов, для которых требуется сохранение файлов
        const promises = [];
        this.appeal.documents.forEach(document => {
            const documentData = this.documentService.data[document.guid]; // расширенные данные работы с документов в сервисе
            // Если есть очередь загрузки, добавляем документ в проверочный объект
            if (documentData && documentData.queue && documentData.queue.length > 0) {
                documentData.queue.forEach(file => {
                    // Если файл еще не сохранен на сервере
                    if (!file._id) {
                        document.files.forEach((fileDoc, index) => {
                            if (file.name === fileDoc.name) {
                                document.files.splice(index);
                            }
                        });

                        promises.push(this._saveDocumentFile(document, file));
                    } else if (file.fromAppealId) {
                        promises.push(this._transferDocumentFileFromCopyAppeal(document, file));
                    } else if (file.signature) {
                        promises.push(this._saveDocumentFileContent(document, file));
                    }
                });
            }
        });

        try {
            await Promise.all(promises);

            return await this._saveEnvelopesInAppealDocuments(redirectAfterDocumentSave);
        } catch (error) {
            await this.errorLoggingService.log(this.case, error);
            throw error;
        } finally {
            this.isProcessSavingAppeal = false;
        }
    }

    private async _saveFilesInAppealVariants(redirectAfterDocumentSave = true) {
        const uploads = [];
        const variantXsdData = this.appeal.subservice.variantXsdData;
        walkObject(variantXsdData, value => {
            if (value) {
                const upload = this.filesQueueService.tryUploadFile(
                    value.tempId,
                    this.appeal.parentEntries,
                    this.appeal._id,
                );
                if (upload) {
                    upload.then(id => {
                        value._id = id;
                    });
                    uploads.push(upload);
                }
            }
        });

        if (uploads.length) {
            await Promise.all(uploads);
            await this._saveAppealSubservice(true, redirectAfterDocumentSave);
        }
    }

    private _saveDocumentFileContent(document, file) {
        const baseDocument = this.baseAppeal.documents.find(item => item.guid === document.guid);
        const fileInBaseAppeal = baseDocument.files.find(item => item._id === file._id);

        const fileForSave: any = {};
        // Пробегаемся по полям файла
        Object.keys(file).forEach(item => {
            if (JSON.stringify(file[item]) !== JSON.stringify(fileInBaseAppeal[item])) {
                fileForSave[item] = file[item];
            }
        });
        if (Object.keys(fileForSave).length) {
            fileForSave._id = file._id;

            return this.restService.update('uploadedFiles', fileForSave);
        } else {
            // TODO по идее, никогда не сработает
            return Promise.resolve();
        }
    }

    private async _transferDocumentFileFromCopyAppeal(document, file) {
        const uploadUrl = Config.server + Config.app + Config.api + 'storage/copy';
        const fileIndex = document.files.findIndex(item => item._id === file._id);

        // Заполнение дополнительных данных дела
        const formData = new FormData();
        formData.append('entryName', 'appeals');
        formData.append('entryId', this.appeal._id);
        formData.append('fileId', file._id);

        // Сохранение файла на сервере
        const sessionService = await this.sessionService.check();

        // Формирование заголовков запроса
        const httpClientOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + sessionService.accessToken,
                Accept: '*',
            }),
        };

        try {
            const savedFile = await this.httpClient.post(uploadUrl, formData, httpClientOptions).toPromise();

            // Добавление сохраненного файла в документ
            document.files[fileIndex] = savedFile;
            // Обновление информации о файле в сервисе
            this.documentService.data[document.guid].queue[fileIndex] = savedFile;

            return true;
        } catch (error) {
            // TODO переделать в ошибку
            throw new Error(
                'При сохранении файлов документов произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!',
            );
        }
    }

    /**
     * Сохранение отдельного файла документа ранее не сохраненного на сервере
     * @param document - документ которому принадлежит файл
     * @param file - сохраняемый файл
     * @returns {Promise<boolean>}
     */
    private async _saveDocumentFile(document, file) {
        const uploadUrl = Config.server + Config.app + Config.api + 'storage/upload';

        // Заполнение дополнительных данных дела
        const formData = new FormData();
        formData.append('entryName', 'appeals');
        formData.append('entryId', this.appeal._id);
        formData.append('file', file, file.name);

        // Сохранение файла на сервере
        const sessionService = await this.sessionService.check();
        // Формирование заголовков запроса
        const httpClientOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + sessionService.accessToken,
                Accept: '*',
            }),
        };

        try {
            const savedFile = await this.httpClient.post(uploadUrl, formData, httpClientOptions).toPromise();
            if (!document.files) {
                document.files = [];
            }
            // Добавление сохраненного файла в документ
            document.files.push(savedFile);
            // Обновление информации о файле в сервисе
            const fileIndex = this.documentService.data[document.guid].queue.findIndex(item => item === file);
            this.documentService.data[document.guid].queue[fileIndex] = savedFile;

            return true;
        } catch (error) {
            throw new Error(
                'При сохранении файлов документов произошла ошибка. Данные не были сохранены. Повторите операцию сохранения!',
            );
        }
    }

    /**
     * 2-й этап сохранения документов: - сохранение конвертов (запросов) внутри документов-запросов
     */
    private async _saveEnvelopesInAppealDocuments(redirectAfterDocumentSave = true) {
        const promises = [];
        this.appeal.documents.forEach(document => {
            if (document.requestId) {
                promises.push(this._saveDocumentEnvelope(document));
            }
        });

        await Promise.all(promises);
        // После сохранения конвертов - переход на этап сохранения документов
        try {
            return this._saveAppealDocuments(redirectAfterDocumentSave);
        } catch (error) {
            await this.errorLoggingService.log(this.case, error);
            throw error;
        } finally {
            this.isProcessSavingAppeal = false;
        }
    }

    /**
     * Сохранения запроса, связанного с делом
     * @param document
     * @returns {Promise<any>}
     */
    private async _saveDocumentEnvelope(document) {
        const documentData = this.documentService.data[document.guid];
        if (documentData.envelope) {
            const envelope = documentData.envelope;
            const baseEnvelope = documentData.baseEnvelope;
            if (!envelope.appealId) {
                envelope.appealId = this.appeal._id;
            }

            const envelopeForSave = this._compareFields(envelope, baseEnvelope);
            if (!Object.keys(envelopeForSave).length) {
                return;
            }

            const promises = [];
            if (envelope._id) {
                // Обновление данных конверта
                promises.push(this.restService.update('envelopes', envelopeForSave));
            } else {
                // Создание конверта
                promises.push(this.restService.create('envelopes', envelopeForSave));
            }

            const envelopes = await Promise.all(promises);
            const savedEnvelope = envelopes[0];
            const newEnvelope = Object.assign(envelope, savedEnvelope);
            this.documentService.data[document.guid].envelope = newEnvelope;
            this.documentService.data[document.guid].baseEnvelope = _.cloneDeep(newEnvelope);
            document.envelopeId = savedEnvelope._id;

            return newEnvelope;
        }

        // Отсутствует envelope в документе-запросе
        return true;
    }

    /**
     * 3-й этап сохранения данных (последний) - сохранение документов на сервере
     */
    private async _saveAppealDocuments(redirectAfterDocumentSave = true) {
        let savingDocuments = this._processAppealEntities(this.appeal, 'documents');
        savingDocuments = savingDocuments.map(async saving => {
            try {
                const success = await saving;

                return { success };
            } catch (error) {
                return { error };
            }
        });

        try {
            const documents: any[] = await Promise.all(savingDocuments);
            let error = null;
            // Обновляем данные
            documents.forEach((documentData: any) => {
                if (documentData.success) {
                    const document = documentData.success;

                    if (document && document.guid) {
                        const findIndex = this.appeal.documents.findIndex(item => item.guid === document.guid);

                        this.appeal.documents[findIndex] = Object.assign(this.appeal.documents[findIndex], document);
                        // Повторная инициализация данных сервиса
                        this.documentService.data[document.guid] = Object.assign(
                            this.documentService.data[document.guid],
                            document,
                        );
                        this._updateElementInBaseAppeal(document, 'documents');
                    }
                } else {
                    error = documentData.error;
                }
            });

            // this.appealValidateService.appeal = cloneDeep(this.appeal);
            // this.appealValidateService.subservice = cloneDeep(this.appeal.subservices ? this.appeal.subservices[0] : this.appeal.subservice);
            this.isProcessSavingAppeal = false;

            if (error) {
                throw error;
            }

            this.storageService.removeItem('baseAppeal');

            // Если в процессе сохранения дело было создано, перенаправляем на страницу редактирования дела
            if (!this.forbidRedirect && (redirectAfterDocumentSave || this.isCreateAppealMode) && this.appeal._id) {
                if (this.nextSection) {
                    await this.router.navigate([
                        this.moduleBaseUrl,
                        'appeals',
                        'edit',
                        this.appeal._id,
                        this.nextSection,
                    ]);
                } else {
                    await this.router.navigate([this.moduleBaseUrl, 'appeals', 'edit', this.appeal._id]);
                }
            }

            // Сохраняем обновленное дело, как базовое
            this.forbidRedirect = false;
            this.storageService.setItem('baseAppeal', this.appeal);

            return this.appeal;
        } catch (error) {
            this.isProcessSavingAppeal = false;

            throw error;
        }
    }

    // ---------------------- ФУНКЦИИ СОХРАНЕНИЯ ОТДЕЛЬНЫХ СУЩНОСТЕЙ -----------------//

    /**
     * Удаление документа
     * @param document - удаляемый документ
     */
    public deleteDocument(document) {
        // Если документ был ранее сохранен на сервере
        if (document.mainId) {
            this.restService.remove(this.appeal, document, 'documents').then(() => {
                this.deleteElementFromAppeal(document, 'documents');
                this.toasterService.success('Документ успешно удален из дела');
            });
        } else {
            this.deleteElementFromAppeal(document, 'documents');
            this.toasterService.success('Документ успешно удален из дела');
        }
    }

    /**
     * Удаление элемента из дела
     * @param element
     * @param type
     */
    public deleteElementFromAppeal(element, type) {
        const indexElementInAppeal = this.appeal[type].findIndex(item => item.guid === element.guid);
        // Удаление информации о элементе из структуры дела
        if (indexElementInAppeal !== -1) {
            this.appeal[type].splice(indexElementInAppeal, 1);
        }
        if (type === 'documents') {
            // Удаление информации из описательного объекта соответствующего сервиса
            delete this.documentService.data[element.guid];
        } else if (type === 'objects') {
            // удаляем информацию об объекте в настройках услуг
            if (this.appeal.subservice.objects) {
                const findIndex = this.appeal.subservice.objects.findIndex(item => item.guid === element.guid);
                if (findIndex !== -1) {
                    this.appeal.subservice.objects.splice(findIndex, 1);
                    // Удаление настроек объекта в услуге из соответствующего сервиса
                    delete this.appealSubservicesService.data[this.appeal.subservice.id].objects[element.guid];
                }
                // Удаление ссылки на представителя в других объектах, если представителем является удаляемый объект
                const representativeIndex = this.appeal.subservice.objects.findIndex(
                    item => item.representative && item.representative.guid === element.guid,
                );
                if (representativeIndex !== -1) {
                    const objectWithRepresentativeGuid = this.appeal.subservice.objects[representativeIndex].guid;
                    delete this.appeal.subservice.objects[representativeIndex].representative;
                    delete this.appealSubservicesService.data[this.appeal.subservice.id].objects[
                        objectWithRepresentativeGuid
                    ].representative;
                }
            }
        }
        // Обновление информации
        this._updateElementInBaseAppeal(element, type, true);
    }

    /**
     * Сохранение элемента дела (конверты, сообщения)
     * @param before - объект до изменения
     * @param current - объект после изменения
     * @param collection - коллекция в БД
     */
    public saveAppealItem(before, current, collection) {
        const objectForSave = this._compareFields(current, before);
        if (!Object.keys(objectForSave).length) {
            return Promise.resolve({ noChange: true });
        }

        if (objectForSave._id) {
            return this.restService.update(collection, objectForSave);
        }

        return this.restService.create(collection, objectForSave);
    }

    /**
     * Удаление элемента дела с сервера
     * @param object - удаляемый объект
     * @param collection - коллекция в БД
     * @returns {any}
     */
    public deleteAppealItem(object, collection) {
        return this.restService.remove(collection, object);
    }

    // ----------------------------- СЛУЖЕБНЫЕ ФУНКЦИИ -------------------------------//
    private _updateElementInBaseAppeal(element, type, isDelete = false) {
        const baseAppeal = this.storageService.getItem('baseAppeal');
        if (!baseAppeal[type]) {
            baseAppeal[type] = [];
        }
        const elementIndexInAppeal = baseAppeal[type].findIndex(item => item.guid === element.guid);
        if (elementIndexInAppeal !== -1) {
            if (isDelete) {
                baseAppeal[type].splice(elementIndexInAppeal, 1);
                if (type === 'objects') {
                    if (baseAppeal.subservice.objects) {
                        const findObjectIndex = baseAppeal.subservice.objects.findIndex(
                            item => item.guid === element.guid,
                        );
                        if (findObjectIndex !== -1) {
                            baseAppeal.subservice.objects.splice(findObjectIndex, 1);
                        }
                        // Удаление ссылки на представителя в других объектах, если представителем является удаляемый объект
                        const representativeIndex = baseAppeal.subservice.objects.findIndex(
                            item => item.representative && item.representative.guid === element.guid,
                        );
                        if (representativeIndex !== -1) {
                            delete baseAppeal.subservice.objects[representativeIndex].representative;
                        }
                    }
                }
            } else {
                baseAppeal[type][elementIndexInAppeal] = element;
            }
        } else {
            baseAppeal[type].push(element);
        }
        this.storageService.setItem('baseAppeal', baseAppeal);
    }

    /**
     * Формирование массива промисов создания/обновления объектов/документов
     * @param appeal - обрабатываемео дело
     * @param type - тип элементов: объекты/документы (objects/documents)
     * @param forceUpdate - принудительное обновление сущностей
     * @returns {Array}
     */
    private _processAppealEntities(appeal, type, forceUpdate = false) {
        const promises = [];
        const items: any = this._compareEntities(appeal, type, forceUpdate);
        if (items.added.length > 0) {
            items.added.forEach(item => {
                promises.push(this.restService.create(appeal, item, type));
            });
        }
        if (items.changed.length > 0) {
            items.changed.forEach(item => {
                promises.push(this.restService.update(appeal, item, type, null, this.collectionName));
            });
        }
        if (items.deleted.length > 0) {
            items.deleted.forEach(item => {
                promises.push(this.restService.remove(appeal, item, type, null, this.collectionName));
            });
        }
        if (items.single.added.length > 0) {
            items.single.added.forEach(item => {
                promises.push(this.restService.create(appeal, item, type));
            });
        }
        if (items.single.changed.length > 0) {
            items.single.changed.forEach(item => {
                promises.push(this.restService.update(appeal, item, type, null, this.collectionName));
            });
        }

        return promises;
    }

    /**
     * Сравнение полей двух элементов
     * @param newItem - измененный объект
     * @param oldItem - предыдущая версия объекта
     * @returns {{}}
     */
    private _compareFields(newItem, oldItem) {
        let resultItem: any = {};
        if (!oldItem) {
            resultItem = _.cloneDeep(newItem);
        } else {
            Object.keys(newItem).forEach(field => {
                if (!_.isEqual(newItem[field], oldItem[field])) {
                    resultItem[field] = newItem[field];
                }
            });
            if (Object.keys(resultItem).length > 0) {
                if (newItem.mainId) {
                    resultItem.mainId = newItem.mainId;
                }
                if (newItem.auid) {
                    resultItem.auid = newItem.auid;
                }
                if (newItem._id) {
                    resultItem._id = newItem._id;
                }
                if (newItem.guid) {
                    resultItem.guid = newItem.guid;
                }
            }
        }

        return resultItem;
    }

    /**
     * Сравнение сущностей, и возврат только изменненного состава полей (для сохранения объектов и документов)
     * @param appeal
     * @param type
     * @param forceUpdate - принудительное отмечание сущностей как обновленных
     * @returns - объект формата {added: массив добавленных элементов, changed - массив измененных элементов}
     */
    private _compareEntities(appeal, type, forceUpdate = false) {
        const items = appeal[type] ? appeal[type] : type === 'subservice' ? {} : [];
        const baseItems =
            !forceUpdate && this.baseAppeal[type] ? this.baseAppeal[type] : type === 'subservice' ? {} : [];

        const added = [];
        const changed = [];
        const deleted = [];
        const single = {
            added: [],
            changed: [],
        };

        // в основном для subservice
        if (Object.prototype.toString.call(items) === '[object Object]') {
            const item = items;

            const compareItem = baseItems;
            if (forceUpdate || (compareItem && Object.keys(compareItem).length && !_.isEqual(item, compareItem))) {
                const changedItem: any = {};
                Object.keys(item).forEach(key => {
                    if (forceUpdate || !_.isEqual(item[key], compareItem[key])) {
                        changedItem[key] = item[key];
                    }
                });
                if (Object.keys(changedItem).length) {
                    changedItem.guid = item.guid;
                    changedItem.mainId = item.mainId;
                    single.changed.push(changedItem);
                }
            } else if (!(compareItem && Object.keys(compareItem).length)) {
                single.added.push(item);
            }
        }

        if (Array.isArray(items) && Array.isArray(baseItems) && items.length) {
            items.forEach(item => {
                const compareItem: unknown = baseItems.find(elm => elm.guid === item.guid);

                if (forceUpdate || (compareItem && !_.isEqual(item, compareItem))) {
                    const changedItem = Object.keys(item).reduce<Record<string, unknown>>((acc, key) => {
                        if (forceUpdate || !_.isEqual(item[key], compareItem[key])) {
                            if (
                                Array.isArray(item[key]) &&
                                Array.isArray(compareItem[key]) &&
                                item[key].length < 1 &&
                                compareItem[key].length > 0 &&
                                !item.mainId
                            ) {
                                acc[key] = compareItem[key];
                            } else {
                                acc[key] = item[key];
                            }
                        }
                        return acc;
                    }, {});
                    if (Object.keys(changedItem).length) {
                        changedItem.guid = item.guid;
                        const baseItem = baseItems.find(bitem => bitem.guid === item.guid);
                        changedItem.mainId = item.mainId || baseItem.mainId;
                        changed.push(changedItem);
                    }
                } else if (!compareItem) {
                    added.push(item);
                }
            });
        }

        // Определяем удаленные элементы
        if (Array.isArray(items) && Array.isArray(baseItems) && baseItems.length) {
            baseItems.forEach(item => {
                const currentItem = items.find(elm => elm.guid === item.guid);
                // Если элемент в текущем сохраняемом деле отсутствует, но у него в базовом деле есть mainId (т.е. был сохранен на сервере)
                if (!currentItem && item.mainId) {
                    deleted.push(item);
                }
            });
        }

        return { added, changed, deleted, single };
    }

    /**
     * Запуск процедуры сохранения (обновления/добавления) объектов в реестре и передача в функцию дальнейшего сохранения объектов и документов
     */
    public async saveObjectsInReestr() {
        let promises;
        promises = this.processSaveObjectInReestr();
        const reestrResolves = await Promise.all(promises);
        const problemObjects = [];
        reestrResolves.forEach((item: any) => {
            if (!item.complete) {
                problemObjects.push(item);
            } else {
                // Обновляем данные об объекте внутри дела
                const appealObjectIndex = this.appeal.objects.findIndex(object => object.guid === item.object.guid);
                this.appeal.objects[appealObjectIndex] = Object.assign(
                    this.appeal.objects[appealObjectIndex],
                    item.object,
                );
                // Обновляем данные в сервисах
                this.appealSubservicesService.subjectsData[item.object.guid] = Object.assign(
                    this.appealSubservicesService.subjectsData[item.object.guid],
                    item.object,
                );
            }
        });
        // Есть ФЛ, соответствие по ФИО + ДР для которых дало несколько записей, выводится модальное окно для реакции пользователя
        if (problemObjects.length > 0) {
            this.reestrModal.show(problemObjects, { appeal: this.appeal });
        } else {
            this.storageService.setItem('baseAppeal', this.appeal);
        }
    }

    /**
     * Запуск обработки (сохранения/обновления) клиентов в реестре
     * @returns {Array}
     */
    public processSaveObjectInReestr() {
        const promises = [];
        this.appeal.objects.forEach(item => {
            // Обрабатываем только участников, а не все объекты
            if (item.kind && item.kind.type === 'participant') {
                if (item.specialTypeId === 'ulApplicant') {
                    promises.push(this.personService.saveObjectInReestr(item, this.appeal, true));
                    promises.push(this.personService.saveObjectInReestr(item, this.appeal));
                } else {
                    promises.push(this.personService.saveObjectInReestr(item, this.appeal));
                }
            }
        });

        return promises;
    }

    /**
     * Корректировка данных сервисов при удалении элементов дела (услуг, объектов, документов)
     */
    public correctServiceDataAfterDeleteAppealEntity() {
        let needResaveAppeal = false;
        //  Обработка удаления услуг в деле
        const subservicesData = {};
        let hasDeletedSubservice = false;
        Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
            if (this.appeal.subservice.guid !== appealSubserviceGuid) {
                hasDeletedSubservice = true; // были удаления услуг из дела
            } else {
                subservicesData[appealSubserviceGuid] = this.appealSubservicesService.data[appealSubserviceGuid];
            }
        });

        // Были удаления услуг из дела
        if (hasDeletedSubservice) {
            this.appealSubservicesService.data = subservicesData;
            // Обновление данных в сервисе документов
            this.documentService.reInitSubserviceData();
            const hasUnusedDocuments = this.documentService.correctSubserviceDocGroups();
            if (hasUnusedDocuments) {
                needResaveAppeal = true;
            }
        }

        // Обработка удаления объектов в деле
        const existObjectsGuids = [];
        this.appeal.objects.forEach(object => {
            existObjectsGuids.push(object.guid);
        });
        const objectsData = {};
        let hasDeletedObject = false;
        Object.keys(this.appealSubservicesService.objectsData).forEach(objectGuid => {
            if (existObjectsGuids.indexOf(objectGuid) !== -1) {
                objectsData[objectGuid] = this.appealSubservicesService.objectsData[objectGuid];
            } else {
                hasDeletedObject = true;
            }
        });
        if (hasDeletedObject) {
            this.appealSubservicesService.objectsData = objectsData;
            // Пробегаемся по настройкам объектах в услугах и удаляем информацию об удаленных объектах
            Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
                const appealSubserviceObjects = {};
                if (
                    this.appealSubservicesService.data[appealSubserviceGuid].objects &&
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].objects).length > 0
                ) {
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].objects).forEach(
                        objectGuid => {
                            if (existObjectsGuids.indexOf(objectGuid) !== -1) {
                                appealSubserviceObjects[objectGuid] =
                                    this.appealSubservicesService.data[appealSubserviceGuid].objects[objectGuid];
                            }
                        },
                    );
                    this.appealSubservicesService.data[appealSubserviceGuid].objects = appealSubserviceObjects;
                }

                // Удаляем настройки привязки объектов к субъектам
                Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].subjects).forEach(subjectGuid => {
                    this.appealSubservicesService.data[appealSubserviceGuid].subjects[subjectGuid].objects =
                        this.appealSubservicesService.data[appealSubserviceGuid].subjects[subjectGuid].objects.filter(
                            item => existObjectsGuids.indexOf(item.guid) !== -1,
                        );
                });
            });

            // Обновление данных в сервисе документов
            this.documentService.reInitSubserviceData();

            const hasUnusedDocuments = this.documentService.correctSubserviceDocGroups();
            if (hasUnusedDocuments) {
                needResaveAppeal = true;
            }
        }

        // Обработка удаления субъектов в деле
        const existSubjectsGuids = [];
        this.appeal.subjects.forEach(subject => {
            existSubjectsGuids.push(subject.guid);
        });
        const subjectsData = {};
        let hasDeletedSubjects = false;
        Object.keys(this.appealSubservicesService.subjectsData).forEach(subjectGuid => {
            if (existSubjectsGuids.indexOf(subjectGuid) !== -1) {
                subjectsData[subjectGuid] = this.appealSubservicesService.subjectsData[subjectGuid];
            } else {
                hasDeletedSubjects = true;
            }
        });
        if (hasDeletedSubjects) {
            this.appealSubservicesService.subjectsData = subjectsData;
            // Пробегаемся по настройкам объектах в услугах и удаляем информацию об удаленных объектах
            Object.keys(this.appealSubservicesService.data).forEach(appealSubserviceGuid => {
                const appealSubserviceSubjects = {};

                if (
                    this.appealSubservicesService.data[appealSubserviceGuid].subjects &&
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].subjects).length > 0
                ) {
                    Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].subjects).forEach(
                        subjectGuid => {
                            if (existSubjectsGuids.indexOf(subjectGuid) !== -1) {
                                appealSubserviceSubjects[subjectGuid] =
                                    this.appealSubservicesService.data[appealSubserviceGuid].subjects[subjectGuid];

                                if (appealSubserviceSubjects[subjectGuid].representative) {
                                    const representativeGuid =
                                        appealSubserviceSubjects[subjectGuid].representative.guid;

                                    if (representativeGuid && existSubjectsGuids.indexOf(representativeGuid) === -1) {
                                        delete appealSubserviceSubjects[subjectGuid].representative;
                                    }
                                }

                                if (
                                    appealSubserviceSubjects[subjectGuid].principals &&
                                    appealSubserviceSubjects[subjectGuid].principals.length
                                ) {
                                    const principals = appealSubserviceSubjects[subjectGuid].principals.filter(
                                        item => existSubjectsGuids.indexOf(item.guid) !== -1,
                                    );

                                    if (principals.length) {
                                        appealSubserviceSubjects[subjectGuid].principals = principals;
                                    } else {
                                        delete appealSubserviceSubjects[subjectGuid].principals;
                                        appealSubserviceSubjects[subjectGuid].isAgent = false;
                                    }
                                }
                            }
                        },
                    );
                    this.appealSubservicesService.data[appealSubserviceGuid].subjects = appealSubserviceSubjects;
                }

                // Удаляем настройки привязки субъектов к объектам
                Object.keys(this.appealSubservicesService.data[appealSubserviceGuid].objects).forEach(objectGuid => {
                    this.appealSubservicesService.data[appealSubserviceGuid].objects[objectGuid].objects =
                        this.appealSubservicesService.data[appealSubserviceGuid].objects[objectGuid].subjects.filter(
                            item => existSubjectsGuids.indexOf(item.guid) !== -1,
                        );
                });
            });
        }

        const documentsData = {};
        let hasDeletedDocument = false;
        Object.keys(this.documentService.data).forEach(documentGuid => {
            const hasDocumentWithThisGuid = this.appeal.documents.some(item => item.guid === documentGuid);
            if (hasDocumentWithThisGuid) {
                let notUse = false;
                const documentInfo = this.documentService.data[documentGuid];
                if (documentInfo.subserviceGuid && this.appeal.subservice.guid !== documentInfo.subserviceGuid) {
                    notUse = true;
                    hasDeletedDocument = true;
                }

                if (
                    documentInfo.resultSubserviceLink &&
                    this.appeal.subservice.guid !== documentInfo.resultSubserviceLink.guid
                ) {
                    documentInfo.resultSubserviceLink = null;
                }

                if (!notUse) {
                    documentsData[documentGuid] = documentInfo;
                }
            } else {
                hasDeletedDocument = true;
            }
        });
        this.documentService.data = documentsData;

        if (needResaveAppeal) {
            return this.saveAppeal().then(() => {
                return this.correctServiceDataAfterDeleteAppealEntity();
            });
        } else {
            return Promise.resolve(true);
        }
    }

    /**
     * Обновление данных запроса в статусе "Отправлен"
     * @param document - документ-запрос
     */
    public updateEnvelopeData(document) {
        const envelopeId = this.documentService.data[document.guid].envelope._id;
        this.restService.find('envelopes', envelopeId).then(envelope => {
            this.documentService.data[document.guid].envelope = _.cloneDeep(envelope);
            // Обновление информации в сравнительном объекте для избежания ненужных сохранений (для избежания затирки актуальных данных)
            this.documentService.data[document.guid].baseEnvelope = _.cloneDeep(envelope);
            this.documentService.calculateDocumentPermissions(document.guid);

            // Получение и обновление информации о файлах документа (их состав обновляется в процессе обработки запроса-конверта)
            this.restService
                .findChild('appeals', 'documents', this.appeal._id, document.guid, 'appeals.documents')
                .then(
                    (serverDocument: any) => {
                        const indexDoc = this.appeal.documents.findIndex(item => serverDocument.guid === item.guid);
                        if (serverDocument.files && serverDocument.files.length > 0) {
                            this.appeal.documents[indexDoc].files = _.clone(serverDocument.files);
                            this.documentService.data[document.guid].queue = _.clone(serverDocument.files);
                        }
                        this.toasterService.success('Данные документа-запроса успешно обновлены');
                    },
                    error => {
                        this.toasterService.html('Произошла ошибка при обновлении документа:  ' + error);
                    },
                );
        });
    }

    public isObject(item) {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    public deepAssign(before, after) {
        Object.keys(before).forEach(field => {
            if (this.isObject(before[field])) {
                before[field] = Object.assign(before[field], after[field]);
                if (after[field]) {
                    this.deepAssign(before[field], after[field]);
                }
            } else if (after[field]) {
                before[field] = after[field];
            }
        });
        Object.keys(after).forEach(field => {
            if (!before[field]) {
                before[field] = after[field];
            }
        });

        return before;
    }
}
