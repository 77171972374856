<ng-template #dialogTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.common.about_program }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="handleCloseClick()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul>
            <!-- О приложении -->
            <li>
                <strong>
                    {{ config.aboutConfig.platfrom.name }}
                </strong>
                -
                {{ config.aboutConfig.platfrom.description }}
            </li>

            <!-- О разработчике -->
            <li>
                {{ localizations.common.developer }}: {{ config.aboutConfig.platfrom.developer }}
            </li>

            <!-- Контакты -->
            <li>
                {{ localizations.common.supported_with }}:
                <ul>
                    <li>
                        {{ localizations.common.by_phone }}:
                        {{ config.aboutConfig.contacts.phone }}
                    </li>
                    <li>
                        {{ localizations.common.by_portal }}:
                        {{ config.aboutConfig.contacts.link }}
                    </li>
                    <li>
                        {{ localizations.common.by_email }}:
                        {{ config.aboutConfig.contacts.email }}
                    </li>
                </ul>
            </li>
        </ul>

    </div>
</ng-template>
