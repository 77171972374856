import { Injectable } from '@angular/core';
import { AisObjectsComponent } from './objects/ais-objects.component';
import { AisObjectsResolve } from './objects/ais-objects.resolve';
import { AisObjectEditComponent } from './objects/ais-object-edit/ais-object-edit.component';

@Injectable()
export class ObjectsData {

    public static childRoutingWithEdit = [
        {
            path: '',
            component: AisObjectsComponent,
            data: {},
            resolve: {
                resolves: AisObjectsResolve,
            },
        },
        {
            path: 'create',
            component: AisObjectEditComponent,
            data: {
                breadcrumb: 'Создание объекта',
            },
            resolve: {
                resolves: AisObjectsResolve,
            },
        },
        {
            path: 'edit/:objectId',
            component: AisObjectEditComponent,
            data: {
                breadcrumb: 'Редактирование объекта',
            },
            resolve: {
                resolves: AisObjectsResolve,
            },
        },
        {
            path: 'edit/:objectId/:versionId',
            component: AisObjectEditComponent,
            data: {
                breadcrumb: 'Редактирование объекта',
            },
            resolve: {
                resolves: AisObjectsResolve,
            },
        },
    ];

    public static childRoutingSelect = [
        {
            path: '',
            component: AisObjectsComponent,
            data: {
                name: 'Список объектов',
            },
            resolve: {
                resolves: AisObjectsResolve,
            },
        },
    ];
}
