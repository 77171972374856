import { Injectable } from '@angular/core';
import { FilesService, HttpService, RestService, StorageService } from '@evolenta/core';
import { ByteUtilities, CommonUtilities } from '@evolenta/utilities';
import { Config } from '../../common/services/config';
import cloneDeep from 'lodash-es/cloneDeep';
import defaultTo from 'lodash-es/defaultTo';
import get from 'lodash-es/get';
import * as moment from 'moment';

@Injectable()
export class CommonProcessService {
    public getUserTaskListTimer;
    public getOtherTaskListInterval;
    public processElementsData = {};

    public constructor(
        private httpService: HttpService,
        private rest: RestService,
        private filesService: FilesService,
        private storage: StorageService,
    ) {
    }

    /**
     * Проверка активности текущего процесса
     * @returns {Promise<T>}
     */
    public checkProcessIsActive(processId) {
        return this.checkCamundaProcessActivity(processId).then(
            (isActive: any) => {
                return isActive;
            },
        );
    }

    /**
     * Проверка активности процесса камунды
     * @param processId - ID процесса
     * @returns {Promise<object>}
     */
    public checkCamundaProcessActivity(processId) {
        const url = Config.server + Config.api + 'camunda/process-instance/' + processId;

        return this.httpService.get(url).then(
            (result: any) => {
                if (result.status === 'NOT_FOUND') {
                    return Promise.resolve(false);
                } else {
                    return Promise.resolve(true);
                }
            }, error => {
                if (error.status === 404 && error.error.status === 'NOT_FOUND') {
                    return Promise.resolve(false);
                } else {
                    return Promise.reject(false);
                }
            },
        );
    }

    /**
     * Обновление списка задач текущего процесса
     * @param processInfoId - идентификатор записи в коллекции camundaBusinessInfo
     * @param processId
     * @param isActiveProcess
     * @returns {Promise<any>}
     */
    public updateProcessData(processInfoId, processId, isActiveProcess = false) {
        return this.getDataFromCamundaBusinessInfo(processInfoId).then(result => {
            if (isActiveProcess) {
                return this.getProcessEvents(processId).then(eventsData => {
                    return this.prepareProcessData(result, eventsData, isActiveProcess);
                });
            } else {
                return this.prepareProcessData(result, {isActiveProcess: false, eventSubscriptions: []}, isActiveProcess);
            }
        });
    }

    public prepareProcessData(data, eventsData: any = {}, isActiveProcess = true) {
        return {
            userTasks: this.getTasksList(data.userTasks),
            serviceTasks: this.getTasksList(data.serviceTasks),
            timers: data.timers && isActiveProcess ? data.timers : [],
            eventGateways: data.eventGateways,
            endEvents: data.endEvents,
            isEntryStatusChanged: !!data.isEntryStatusChanged,
            events: eventsData && eventsData.eventSubscriptions ? eventsData.eventSubscriptions : [],
            isProcessActive: eventsData ? eventsData.isProcessActive : true,
        };
    }

    public getTasksList(tasks) {
        return {
            activeTasks: tasks ? tasks.filter(item => item.status === 'ACTIVE') : [],
            completedTasks: tasks ? tasks.filter(item => item.status !== 'ACTIVE') : [],
        };
    }

    public getDataFromCamundaBusinessInfo(processInfoId) {
        if (processInfoId) {
            return this.rest.find('camundaBusinessInfo', processInfoId).then(result => {
                return this.processingCamundaTasks(result);
            });
        }
    }

    public setProcessTaskExecutor(taskId, executor) {
        return this.httpService.post(Config.server + Config.api + 'camunda/task/' + taskId + '/assignee?userId=' + executor, {});
    }

    public getProcessInfo(processInfoId) {
        return this.rest.find('camundaBusinessInfo', processInfoId);
    }

    public processingCamundaTasks(response) {
        let processUsersTasks = [];
        let processServiceTasks = [];
        let processEventGateways = [];
        let processTimers = [];
        let endEvents = [];
        let isEntryStatusChanged = false;
        if (response) {
            const data = response;
            processUsersTasks = data.userTasks ? data.userTasks.map(item => ({...item, camundaBusinessInfoId: data._id})) : [];
            processServiceTasks = data.serviceTasks ? data.serviceTasks : [];
            processTimers = data.timers ? data.timers.filter(item => moment(item.endTime).unix() > moment().unix()) : [];
            processEventGateways = data.eventGateways ? data.eventGateways : [];
            endEvents = data.endEvents ? data.endEvents : [];
            isEntryStatusChanged = !!data.isEntryStatusChanged;
        }

        return Promise.resolve({
            userTasks: processUsersTasks,
            serviceTasks: processServiceTasks,
            timers: processTimers,
            eventGateways: processEventGateways,
            endEvents: endEvents,
            isEntryStatusChanged: isEntryStatusChanged,
        });
    }

    public correctProcessDataInEntity(process, processDataInEntity = null) {
        if (!processDataInEntity) {
            processDataInEntity = {id: process._id, tasks: [], catchEvents: []};
        }
        if (!processDataInEntity.id) {
            processDataInEntity.id = process._id;
        }
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    const find = processDataInEntity.tasks.find(item => item.id === task.id);
                    if (!find) {
                        processDataInEntity.tasks.push({guid: task.guid, id: task.id, type: taskType, isUseInProcess: true});
                    } else {
                        find.isUseInProcess = true;
                    }
                });
            });
        }
        processDataInEntity.tasks = processDataInEntity.tasks.filter(item => item.isUseInProcess).map(item => {
            delete item.isUseInProcess;

            return item;
        });

        if (process.catchEvents) {
            if (!processDataInEntity.catchEvents) {
                processDataInEntity.catchEvents = [];
            }
            process.catchEvents.forEach(event => {
                const find = processDataInEntity.catchEvents.find(item => item.id === event.id);
                if (!find) {
                    processDataInEntity.catchEvents.push({guid: event.guid, id: event.id, isUseInProcess: true});
                } else {
                    find.isUseInProcess = true;
                }
            });
        }
        processDataInEntity.catchEvents = processDataInEntity.catchEvents.filter(item => item.isUseInProcess).map(item => {
            delete item.isUseInProcess;

            return item;
        });

        return processDataInEntity;
    }

    public loadProcessData(data) {
        if (data) {
            const processIds = [data.id];
            data.tasks.forEach(task => {
                if (task.subProcess) {
                    processIds.push(task.subProcess.id);
                }
            });

            return this.rest.search('sperBpmnProcesses', {search: {search: [{field: '_id', operator: 'in', value: processIds}]}}).then(processes => {
                const find = processes.find(item => item._id === data.id);
                if (find) {
                    const process = cloneDeep(find);
                    const tasksWithSubProcess = data.tasks.filter(item => {
                        return item.subProcess;
                    });
                    if (tasksWithSubProcess.length > 0) {
                        tasksWithSubProcess.forEach(task => {
                            const findProcess = processes.find(item => item._id === task.subProcess.id);
                            if (findProcess) {
                                const subProcessIndex = process.tasks.subProcess.findIndex(item => item.id === task.id);
                                if (subProcessIndex !== -1) {
                                    process.tasks.subProcess[subProcessIndex].subProcess = findProcess;
                                }
                            }
                        });
                    }

                    return Promise.resolve(process);
                } else {
                    return Promise.resolve(null);
                }
            });
        } else {
            return Promise.resolve(null);
        }
    }

    public getVariablesPath(data, paths = [], resultVariables = {}) {
        data.forEach(dataItem => {
            if (dataItem.cols) {
                dataItem.cols.forEach(column => {
                    this.getVariablesPath(column.content, cloneDeep(paths), resultVariables);
                });
            } else {
                const currentPaths = cloneDeep(paths);
                currentPaths.push(dataItem.options.name);
                if (dataItem.options.processVariable) {
                    // currentPaths.push(dataItem.options.processVariable);
                    resultVariables[dataItem.options.processVariable] = currentPaths;
                } else if (dataItem.content && dataItem.content.length > 0) {
                    this.getVariablesPath(dataItem.content, cloneDeep(currentPaths), resultVariables);
                }
            }
        });
    }

    /**
     * Формирование и выгрузка бизнес процесса в камунду
     * @param process
     */
    public uploadCamundaProcess(process) {
        // содержимое файлов бизнес процессов
        const files = {};
        const promises = [];
        const processingItems = [];
        let fileId = process.file._id;
        processingItems.push('file' + fileId);
        promises.push(this.filesService.downloadFile(fileId, 'text'));
        if (process.tasks.subProcess && process.tasks.subProcess.length > 0) {
            const tasksWithSubProcess = process.tasks.subProcess.filter(item => item.subProcess);
            if (tasksWithSubProcess.length) {
                tasksWithSubProcess.forEach(subProcess => {
                    fileId = subProcess.subProcess.file._id;
                    processingItems.push('file' + fileId);
                    promises.push(this.filesService.downloadFile(fileId, 'text'));
                });
            }
        }

        return Promise.all(promises).then(result => {
            processingItems.forEach((processingItem, idx) => {
                const idFile = processingItem.replace('file', '');
                files[idFile] = result[idx];
            });
            const bpmn = files[process.file._id];
            const subProcess = {};
            if (process.tasks.subProcess) {
                const tasksWithSubProcess = process.tasks.subProcess.filter(item => item.subProcess);
                if (tasksWithSubProcess.length > 0) {
                    tasksWithSubProcess.forEach(task => {
                        subProcess[task.id] = files[task.subProcess.file._id];
                    });
                }
            }
            const resultBpmn = this.correctBpmn(bpmn, subProcess);
            // console.log('result bpmn', resultBpmn);
            const xsdData = {
                name: 'bpmnXML',
                contentXsd: resultBpmn,
                parentEntries: 'xsd',
            };

            return this.rest.create('xsd', xsdData).then((xsd: any) => {
                return this.httpService.post(Config.server + Config.api + 'camunda/deployment/create', {bpmn: ByteUtilities.B64Encode(resultBpmn)}).then(camundaResult => {
                        return {
                            xsdBpmn: xsd._id,
                            camundaDeploymentInfo: camundaResult,
                        };
                    }, error => {
                        return error;
                    });
            });
        });
    }

    /**
     * Корректировка BPMN (внедрение подпроцессов в структуру основного процесса)
     * @param bpmn
     * @param subProcess
     */
    public correctBpmn(bpmn, subProcess) {
        const mainParser = new DOMParser();
        const nameSpace = 'http://www.omg.org/spec/BPMN/20100524/MODEL';
        const mainDoc = mainParser.parseFromString(bpmn, 'application/xml');
        const mainRoot = mainDoc.documentElement;
        const subProcesses = mainRoot.getElementsByTagNameNS(nameSpace, 'subProcess');
        const nodesForDelete = [];
        for (let i = 0; i <= subProcesses.length - 1; i++) {
            const subProcessNode = subProcesses[i];
            const taskId = subProcessNode.getAttribute('id');
            if (subProcess[taskId]) {
                // Если выбран подпроцесс для subProcess
                const subParser = new DOMParser();
                const subDoc = subParser.parseFromString(subProcess[taskId], 'application/xml');
                const subRoot = subDoc.documentElement;
                const sub = subRoot.getElementsByTagNameNS(nameSpace, 'process')[0];
                const childNodes = sub.childNodes;
                for (let j = 0; j <= childNodes.length - 1; j++) {
                    const childNode = sub.childNodes[j];
                    subProcessNode.appendChild(mainDoc.importNode(childNode, true));
                }
            } else {
                // если подпроцесс не выбран преобразуем subProcess в userTask
                const newNode = mainDoc.createElementNS(nameSpace, 'userTask');
                newNode.setAttribute('id', taskId);
                newNode.setAttribute('name', subProcessNode.getAttribute('name'));
                const defaultAttr = subProcessNode.getAttribute('default');
                if (defaultAttr) {
                    newNode.setAttribute('default', subProcessNode.getAttribute('default'));
                }
                const subProcessNodeChilds = subProcessNode.childNodes;
                for (let k = 0; k <= subProcessNodeChilds.length - 1; k++) {
                    const childNode = subProcessNodeChilds[k];
                    if (childNode.nodeName === 'bpmn:incoming' || childNode.nodeName === 'bpmn:outgoing') {
                        const childNodeElement = mainDoc.createElement(childNode.nodeName);
                        childNodeElement.textContent = childNode.firstChild.nodeValue;
                        newNode.appendChild(childNodeElement);
                    }
                }
                subProcessNode.parentNode.insertBefore(newNode, subProcessNode);
                nodesForDelete.push(subProcessNode);
            }
        }
        // удаляем subProcess, к которым не прикреплены вложенные процессы
        // console.log('nodesFORDelete', nodesForDelete);
        if (nodesForDelete.length) {
            for (let l = 0; l <= nodesForDelete.length - 1; l++) {
                const deletedNode = nodesForDelete[l];
                // console.log('delete node', deletedNode);
                deletedNode.parentNode.removeChild(deletedNode);
            }
        }

        return mainDoc.documentElement.outerHTML;
    }

    public completeProcessTask(taskId, mainId, guid, processInfoId, collectionName, params) {
        const url = Config.server + Config.api;

        return this.httpService.post(`${url}camunda/task/${taskId}/complete?mainId=${mainId}&guid=${guid}&camundaBusinessInfoId=${processInfoId}&collectionName=${collectionName}`
            , params);
    }

    public getProcessEvents(processInstanceId) {
        return this.httpService.get(Config.server + Config.api + 'camunda/getEventSubscriptions?processInstanceId=' + processInstanceId).then(
            (data: any) => {
                if (data.status && data.status === 'NOT_FOUND') {
                    return Promise.resolve({isProcessActive: false, eventSubscriptions: []});
                } else {
                    const events = data && data.eventSubscriptions ? data.eventSubscriptions
                        .filter(item => item.eventType === 'message')
                        .map(item => ({...item, type: 'EVENT'})) : [];

                    return Promise.resolve({isProcessActive: true, eventSubscriptions: events});
                }
            }, error => {
                if (error.status === 404 && error.error.status === 'NOT_FOUND') {
                    return Promise.resolve(false);
                } else {
                    return Promise.reject(false);
                }
            },
        );
    }

    public completeProcessEvent(event) {
        const params = {
            executionId: event.executionId,
            messageName: event.eventName,
        };

        return this.httpService.post(Config.server + Config.api + 'camunda/eventSubscriptionTrigger', params);
    }

    public startCamundaProcess(processId, entityId, entityCollection) {
        const currentOrganization = this.storage.getItem('currentOrganization');
        const processGuid = CommonUtilities.GenerateGuid();
        const url = Config.server + Config.api + 'camunda/process-definition/' + processId + '/start?mainId=' + entityId + '&guid=' + processGuid + '&collectionName=' + entityCollection + '&unitId=' + currentOrganization._id;

        return this.httpService.post(url, {}).then(data => {
            return Promise.resolve(data);
        });
    }

    public getCamundaVariables(processId) {
        const url = Config.server + Config.api + 'camunda/process-instance/' + processId + '/variables';

        return this.httpService.get(url);
    }

    public setCamundaVariables(processId, variables = {}) {
        const url = Config.server + Config.api + 'camunda/process-instance/' + processId + '/variables';

        return this.httpService.post(url, variables);
    }

    public processingElementsVariables(process: any, additionalDataTabs: any[], elementsNames: string[]) {
        if (Array.isArray(additionalDataTabs) && additionalDataTabs.length > 0) {
            const xsdIds = additionalDataTabs.map(item => item.xsd).filter(id => id);
            if (xsdIds.length > 0) {
                return this.rest.search('xsd', { search: { search: [{ field: '_id', operator: 'in', value: xsdIds }] } }).then(allXsd => {
                    if (Array.isArray(elementsNames) && elementsNames.length > 0) {
                        elementsNames.forEach(elementsName => {
                            if (Array.isArray(process[elementsName]) && process[elementsName].length > 0) {
                                process[elementsName].forEach(element => {
                                    delete element.variables;
                                    this.completeTaskVariables(element, additionalDataTabs, allXsd);
                                    if (element.subProcess && element.subProcess.tasks.length > 0) {
                                        element.subProcess.tasks.forEach(subTask => {
                                            delete subTask.variables;
                                            this.completeTaskVariables(subTask, additionalDataTabs, allXsd);
                                        });
                                    }
                                });
                            }
                        });
                    }

                    return Promise.resolve(true);
                });
            } else {
                return Promise.resolve(true);
            }
        } else {
            return Promise.resolve(true);
        }
    }

    public completeTaskVariables(task, additionalDataTabs, allXsd) {
        if (task.additionalDataTabs && task.additionalDataTabs.length > 0) {
            task.variables = [];
            task.additionalDataTabs.forEach(taskTab => {
                if (taskTab.isUse || taskTab.isRequired) {
                    const tab = additionalDataTabs.find(item => item.guid === taskTab.guid);
                    const xsdFile = allXsd.find(item => item._id === tab.xsd);
                    if (xsdFile) {
                        const xsdContent = xsdFile.xsdContent;
                        const variablesItems = {};
                        this.getVariablesPath(xsdContent, [], variablesItems);
                        if (Object.keys(variablesItems).length > 0) {
                            Object.keys(variablesItems).forEach(variableCode => {
                                task.variables.push({
                                    code: variableCode,
                                    xpath: tab.code + '.' + variablesItems[variableCode].join('.'),
                                });
                            });
                        }
                    }
                }
            });
        }
    }

    public generateProcessDataToEntity(process, processInEntity = null) {
        let tasks = [];
        let catchEvents = [];
        if (processInEntity) {
            tasks = processInEntity.tasks ? processInEntity.tasks : [];
            catchEvents = processInEntity.catchEvents ? processInEntity.catchEvents : [];
        }
        const actualTasks = [];
        if (process.tasks) {
            Object.keys(process.tasks).forEach(taskType => {
                process.tasks[taskType].forEach(task => {
                    const find = tasks.find(item => item.guid === task.guid);
                    if (!find) {
                        tasks.push({guid: task.guid, id: task.id, type: taskType});
                    }
                });
            });
            tasks.forEach(standardTask => {
                let taskIsFind = false;
                Object.keys(process.tasks).forEach(taskType => {
                    const findTask = process.tasks[taskType].find(item => item.guid === standardTask.guid);
                    taskIsFind = findTask ? true : taskIsFind;
                });
                if (taskIsFind) {
                    actualTasks.push(standardTask);
                }
            });
        }
        const actualCatchEvents = [];
        if (process.catchEvents) {
            process.catchEvents.forEach(event => {
                const find = catchEvents.find(item => item.guid === event.guid);
                if (!find) {
                    catchEvents.push({guid: event.guid, id: event.id});
                }
            });
            catchEvents.forEach(standardCatchEvent => {
                const findEvent = process.catchEvents.find(item => item.guid === standardCatchEvent.guid);
                if (findEvent) {
                    actualCatchEvents.push(standardCatchEvent);
                }
            });
        }

        return {id: process._id, tasks: actualTasks, catchEvents: actualCatchEvents};
    }

    public getProcessExecutionResult(endEvents): any {
        if (Array.isArray(endEvents) && endEvents.length > 0) {
            return defaultTo(get(endEvents[endEvents.length - 1], 'parameters'), {});
        }

        return {};
    }

    public getElementDataFromProcessInEntity(element, elementField = 'tasks', elementCompareField, processDataInEntity) {
        let elementInfo;
        if (processDataInEntity && processDataInEntity[elementField]) {
            elementInfo = this.getProcessingElementFromProcessInEntity(element, processDataInEntity[elementField], elementCompareField);
        }
        if (!elementInfo && processDataInEntity.tasks) {
            processDataInEntity.tasks.forEach(task => {
                if (!elementInfo && task.subProcess && task.subProcess[elementField]) {
                    elementInfo = this.getProcessingElementFromProcessInEntity(element, task.subProcess[elementField], elementCompareField);
                }
            });
        }

        return elementInfo;
    }

    public getProcessingElementFromProcessInEntity(element, processingElements, elementCompareField) {
        return processingElements.find(item => item.id && item.id === element[elementCompareField] || item.code && item.code === element[elementCompareField]);
    }

    public getElementInProcessById(elementId, elementType, process) {
        const elements = process && process[elementType] ? process[elementType] : [];
        let find = elements.find(item => item.id === elementId);
        if (!find) {
            if (process.tasks && process.tasks.length > 0) {
                process.tasks.forEach(task => {
                    if (!find && task.subProcess && task.subProcess[elementType]) {
                        find =  task.subProcess[elementType].find(item => item.id === elementId);
                    }
                });
            }
        }

        return find;
    }

    public getLinkedElementsToToEntityProperty(process, processInEntity, property, propertyName, fields) {
        const data = {
            tasks: [],
            catchEvents: [],
        };
        if (processInEntity) {
            data.tasks = data.tasks.concat(this.processingLinkedData(processInEntity, 'tasks', property, propertyName, fields));
            data.catchEvents = data.catchEvents.concat(this.processingLinkedData(processInEntity, 'catchEvents', property, propertyName, fields));
        }
    }

    public processingLinkedData(elements, elementType, property, propertyName, fields) {
        const data = [];
        if (elements && elements[elementType]) {
            elements[elementType].forEach(element => {
                if (this.processingLinkedElementsProperty(element, property, propertyName, fields)) {
                    data.push(element.guid);
                }
            });
        }

        return data;
    }

    public processingLinkedElementsProperty(element, property, propertyName, fields) {
        let isUsed = false;
        if (element[propertyName]) {
            const find = element[propertyName].find(item => item.guid === property.guid);
            if (find) {
                fields.forEach(field => {
                    if (find[field]) {
                        isUsed = true;
                    }
                });
            }
        }

        return isUsed;
    }

    public getProcessElementRule(element, elementData, data) {
        let isAllow = false;
        const user = this.storage.getItem('user');
        const userRoles = user.currentRolesMnemonics;
        const isSupervisor = userRoles.indexOf('supervisor') !== -1 || user.login === 'admin';
        if (isSupervisor) {
            isAllow = true;
        } else if (element.assignee) {
            // Если исполнитель по задаче
            isAllow = element.assignee === user.login;
        } else if (data.entity.executor) {
            isAllow = data.entity.executor.login === user.login;
        } else if (data.entity.inspectors && data.entity.inspectors.length > 0) {
            isAllow = !!data.entity.inspectors.find(item => item.person && item.person.login === user.login || item.login && item.login === user.login);
        } else if (elementData && elementData.roles && elementData.roles.length > 0) {
            let existRole = false;
            elementData.roles.forEach(role => {
                if (userRoles.indexOf(role) !== -1) {
                    existRole = true;
                }
            });
            if (existRole) {
                isAllow = true;
            }
        } else if (element.candidateGroups && element.candidateGroups.length) {
            const usedRolesInElement = element.candidateGroups.filter(item => userRoles.indexOf(item) !== -1);
            if (usedRolesInElement.length) {
                isAllow = true;
            }
        } else {
            isAllow = true;
        }

        return isAllow;
    }

    public checkProcessData(process, standard) {
        let processIds = [process._id];
        if (process.tasks && process.tasks.subProcess) {
            processIds = processIds.concat(process.tasks.subProcess.filter(item => item.subProcess && item.subProcess.entityId).map(item => item.subProcess._id));
        }
        const params = [
            {
                orSubConditions: [
                    {
                        field: 'process.id',
                        operator: 'in',
                        value: processIds,
                    },
                    {
                        field: 'process.tasks.subProcess.id',
                        operator: 'in',
                        value: processIds,
                    },
                ],
            },
            {
                field: '_id',
                operator: 'neq',
                value: standard._id,
            },
        ];

        return this.rest.search('reglaments', {search: {search: params}}).then(result => {
            return result.length > 0;
        });
    }
}
