<div class="pl-20 pr-20 pt-15 pb-20">
    <form #form="ngForm">
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="form-group mb-10 form-group-lg">
                    <label>
                        {{ localizations.subjects.document_kind }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.documentType', user)">
                            *
                        </span>
                    </label>
                    <ng-select name="documentType"
                               [allowClear]="false"
                               [items]="transformedDocuments"
                               [placeholder]="localizations.appeals.documents.select_type"
                               [required]="fieldsRequirementsService.checkFieldRequired('person.documentType', user)"
                               [disabled]="isDisabled('person.documentType')"
                               [(ngModel)]="user.data.person.documentType"
                               (ngModelChange)="changeDocumentType(); updateErrors('person.documentType')"
                               #documentType="ngModel">
                    </ng-select>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.documentType', user)
                                && !user.data.person.documentType">
                        {{ localizations.subjects.document_kind_field_is_required }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.subjects.citizenship }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.citizenship', user)">
                            *
                        </span>
                    </label>

                    <!-- Выбор из справочника -->
                    <ng-container *ngIf="!user.data.person.citizenshipMode
                                          || user.data.person.citizenshipMode && user.data.person.citizenshipMode == 'dictionary'">
                        <small class="text-info anchor ml-5"
                               *ngIf="!isDisabled('person.citizenship')"
                               (click)="changeCitizenshipMode('manual')">
                            {{ localizations.subjects.fill_manually }}
                        </small>
                        <catalogue catalogName="catalogueCitizenship"
                                            name="citizenship"
                                            [priority]="true"
                                            [large]="true"
                                            [disabled]="isDisabled('person.citizenship')"
                                            [(ngModel)]="user.data.person.citizenship"
                                            (change)="updateErrors('person.citizenship')"
                                            #citizenship="ngModel">
                        </catalogue>
                    </ng-container>

                    <!-- Заполнение вручную -->
                    <ng-container *ngIf="user.data.person.citizenshipMode && user.data.person.citizenshipMode == 'manual'">
                        <small class="text-info anchor ml-5"
                               (click)="changeCitizenshipMode('dictionary')"
                               *ngIf="!isDisabled('person.citizenship')">
                            {{ localizations.subjects.select_from_catalogue }}
                        </small>
                        <input class="form-control input-lg"
                               name="citizenship"
                               [disabled]="isDisabled('person.citizenship')"
                               (ngModelChange)="updateErrors('person.citizenship')"
                               [(ngModel)]="user.data.person.citizenship.name"
                               #citizenship="ngModel">
                    </ng-container>

                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.citizenship', user)
                                && !user.data.person.citizenship">
                        {{ localizations.subjects.citizenship_field_is_required }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-lg-1">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.common.series_big }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.documentSeries', user)">
                            *
                        </span>
                    </label>
                    <input class="form-control"
                           name="documentSeries"
                           type="text"
                           [required]="fieldsRequirementsService.checkFieldRequired('person.documentSeries', user)"
                           [pattern]="maskParams.series.pattern"
                           [disabled]="isDisabled('person.documentSeries') || !user.data.person.documentType"
                           [(ngModel)]="user.data.person.documentSeries"
                           (ngModelChange)="updateErrors('person.documentSeries', documentSeries.errors)"
                           #documentSeries="ngModel">
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && documentSeries.errors && documentSeries.errors.required && specialTypeId !== 'individualApplicant'" >
                        <span *ngIf="documentSeries.errors.required">
                            {{ localizations.appeals.subjects.series_field_is_required }}
                        </span>
                    </div>
                    <div class="validation-error-label"
                         *ngIf="documentSeries.errors && documentSeries.errors.pattern">
                        {{ localizations.appeals.subjects.wrong_series_format }}
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-1">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.common.number }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.documentNumber', user)">
                            *
                        </span>
                    </label>
                    <input class="form-control"
                           name="documentNumber"
                           [textMask]="{mask: maskParams.number.mask, guide: maskParams.number.guide}"
                           [pattern]="maskParams.number.pattern"
                           [required]="fieldsRequirementsService.checkFieldRequired('person.documentNumber', user)"
                           [disabled]="isDisabled('person.documentType') || this.specialTypeId === 'individualApplicant' && !user.data.person.documentType"
                           [(ngModel)]="user.data.person.documentNumber"
                           (ngModelChange)="updateErrors('person.documentNumber', documentNumber.errors)"
                           #documentNumber="ngModel">
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && documentNumber.errors && specialTypeId !== 'individualApplicant'">
                        {{ localizations.appeals.subjects.number_field_is_required }}
                    </div>
                    <div class="validation-error-label"
                         *ngIf="documentNumber.errors && documentNumber.errors.pattern">
                        {{ localizations.appeals.subjects.wrong_number_format }}
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-2">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.appeals.subjects.issue_date }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.documentIssueDate', user)">
                            *
                        </span>
                    </label>
                    <evolenta-datepicker name="documentIssueDate"
                                         [large]="true"
                                         [valueAsString]="false"
                                         [required]="fieldsRequirementsService.checkFieldRequired('person.documentIssueDate', user)"
                                         [disabled]="isDisabled('person.documentIssueDate')"
                                         [(ngModel)]="user.data.person.documentIssueDate"
                                         (ngModelChange)="updateErrors('person.documentIssueDate')"
                                         #documentIssueDate="ngModel"
                                         validatePassport>
                    </evolenta-datepicker>
                    <div class="validation-error-label"
                         *ngIf="isProcessValidate && documentIssueDate.errors && documentIssueDate.errors.required">
                        {{ localizations.appeals.subjects.issue_date_field_is_required }}
                    </div>
                    <div class="validation-warning-label"
                         *ngIf="documentIssueDate.errors && documentIssueDate.errors.validatePassport">
                        {{documentIssueDate.errors.validatePassport}}
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-8">
                <div class="form-group form-group-lg mb-10">
                    <label>
                        {{ localizations.appeals.subjects.issued_by }}
                        <span class="text-danger"
                              *ngIf="fieldsRequirementsService.checkFieldRequired('person.documentIssuer', user)">
                            *
                        </span>
                        <small class="text-info anchor ml-5"
                               (click)="user.data.person.documentIssuerMode = 'manually'"
                               *ngIf="usedCatalogueDocument && usedCatalogueDocument.catalogue
                                      && (user.data.person.documentIssuerMode && user.data.person.documentIssuerMode == 'dictionary'
                                          || !user.data.person.documentIssuerMode)">
                            {{ localizations.subjects.fill_manually }}
                        </small>
                        <small class="text-info anchor ml-5"
                               (click)="user.data.person.documentIssuerMode = 'dictionary'"
                               *ngIf="usedCatalogueDocument && usedCatalogueDocument.catalogue
                                      && user.data.person.documentIssuerMode && user.data.person.documentIssuerMode == 'manually'">
                            {{ localizations.subjects.select_from_catalogue }}
                        </small>
                    </label>
                    <!-- Выбор из справочника -->
                    <ng-container *ngIf="usedCatalogueDocument && usedCatalogueDocument.catalogue && user.data.person.documentIssuerMode != 'manually'">
                        <catalogue name="documentIssuer"
                                            [catalogName]="usedCatalogueDocument.catalogue"
                                            [large]="true"
                                            [required]="fieldsRequirementsService.checkFieldRequired('person.documentIssuer', user)"
                                            [disabled]="isDisabled('person.documentIssuer')"
                                            [(ngModel)]="user.data.person.documentIssuer"
                                            (change)="changeDocumentIssuer(true)"
                                            #documentIssuer="ngModel">
                        </catalogue>
                        <div class="validation-error-label"
                             *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.documentIssuer', user)
                                    && (!user.data.person.documentIssuer || user.data.person.documentIssuer && !user.data.person.documentIssuer.name)" >
                            {{ localizations.subjects.issued_by_field_is_required }}
                        </div>
                        <div class="validation-error-label"
                             *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.documentIssuer.code', user)
                                     && user.data.person.documentIssuer && user.data.person.documentIssuer.name && !user.data.person.documentIssuer.code">
                            {{ localizations.subjects.issued_by_code_field_is_required }}
                        </div>
                    </ng-container>
                    <!-- Ввод значения вручную -->
                    <ng-container *ngIf="!usedCatalogueDocument || !usedCatalogueDocument.catalogue || user.data.person.documentIssuerMode == 'manually'">
                        <div class="row">
                            <div class="col-md-3">
                                <input class="form-control"
                                       name="documentIssuerCode"
                                       [placeholder]="localizations.subjects.issued_by_code"
                                       [textMask]="{mask: globalParams.documentIssuerCodeMask}"
                                       [required]="fieldsRequirementsService.checkFieldRequired('person.documentIssuer.code', user)"
                                       [disabled]="isDisabled('person.documentIssuer.code')"
                                       [(ngModel)]="documentIssuer.code"
                                       (ngModelChange)="changeDocumentIssuer()"
                                       #documentIssuerCode="ngModel">
                            </div>
                            <div class="col-md-9">
                                <input class="form-control"
                                       name="documentIssuerName"
                                       placeholder="Наименование органа"
                                       #documentIssuerName="ngModel"
                                       [required]="fieldsRequirementsService.checkFieldRequired('person.documentIssuer', user)"
                                       [(ngModel)]="documentIssuer.name"
                                       [disabled]="isDisabled('person.documentIssuer')"
                                       (ngModelChange)="changeDocumentIssuer()">
                            </div>
                        </div>
                        <div class="validation-error-label"
                             *ngIf="isProcessValidate && documentIssuerName.errors">
                            {{ localizations.subjects.issued_by_authority_field_is_required }}
                        </div>
                        <div class="validation-error-label"
                             *ngIf="isProcessValidate && documentIssuerCode.errors">
                            {{ localizations.subjects.issued_by_code_field_is_required }}
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>

        <!-- Место рождения -->
        <div class="form-group form-group-lg no-margin-bottom">
            <label>
                {{ localizations.appeals.subjects.birth_place }}
                <span class="text-danger"
                      *ngIf="fieldsRequirementsService.checkFieldRequired('person.birthPlace', user)">
                    *
                </span>
            </label>
            <evolenta-address-gar
                    name="birthPlace"
                    [large]="true"
                    [showIndex]="false"
                    [birthAddress]="true"
                    [disabled]="isDisabled('person.birthPlace')"
                    [(ngModel)]="user.data.person.birthPlace"
                    (change)="updateErrors('person.birthPlace')"
            ></evolenta-address-gar>
            <div class="validation-error-label"
                 *ngIf="isProcessValidate && fieldsRequirementsService.checkFieldRequired('person.birthPlace', user) && !user.data.person.birthPlace">
                {{ localizations.subjects.place_of_birth_field_is_required }}
            </div>
        </div>
    </form>
</div>
