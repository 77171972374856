import $ from 'jquery';
import { Injectable } from '@angular/core';
import { ApplicationsService, HttpService, RestService, StorageService } from '@evolenta/core';
import { ObjectUtilities } from '@evolenta/utilities';
import { NgProgress } from 'ngx-progressbar';
import { Config } from './config';
import * as moment from 'moment';
import isEqual from 'lodash-es/isEqual';

@Injectable()
export class UtilityService {
    public url = Config.server + Config.app + Config.api + 'sper3/';
    public mainTerm: boolean;
    public defaultHeaderOptions = [
        'f|data.person.lastName',
        's| ',
        'f|data.person.firstName',
        's| ',
        'f|data.person.middleName',
        's|, ',
        'f|data.person.birthday.formatted',
        's| г.р.',
    ];
    public defaultShortHeaderOptions = [
        'f|data.person.lastName',
        's| ',
        'f|data.person.firstName|1',
        's|.',
        'f|data.person.middleName|1',
        's|.',
    ];

    public servicesInitStatuses = {
        filtersPanel: {
            logOut: true,
        },
    };

    public currentOrganization;
    public isCompleteAuth = true; // Завершена процедура авторизации, был проверен пароль

    public constructor(
        private applicationsService: ApplicationsService,
        private storage: StorageService,
        private progressService: NgProgress,
        private httpService: HttpService,
        private rest: RestService,
    ) {
    }

    public getPrintFormIcon(printForm) {
        const icons = {
            docx: 'fa-file-word-o',
            doc: 'fa-file-word-o',
            xlsx: 'fa-file-excel-o',
            pdf: 'fa-file-pdf-o',
            other: 'fa-file-text-o',
        };
        const arr = printForm.formType.split('.');
        const extension = arr[1].toLowerCase();

        return icons[extension] ? icons[extension] : icons['other'];
    }

    public getTermForVariant(terms, variant) {
        let varTerm: any;
        terms.forEach(guid => {
            if (!guid.variant) {
                this.mainTerm = true;
                varTerm = guid.value;
            } else {
                if (variant) {
                    guid.variant.forEach(item => {
                        if (item === variant.guid) {
                            varTerm = variant.name + ' Срок исполнения: ' + guid.value;
                        }
                    });
                }
            }
        });

        return varTerm;
    }

    public isExpandedTreeElements(nodes) {
        for (const node of nodes) {
            node.showChildren = !node.showChildren;

            if (node.isExpanded === undefined) {
                node.expand();
            }
            if (node.visibleChildren.length > 0) {
                this.isExpandedTreeElements(node.visibleChildren);
            }
        }
    }

    public addPermissionsInGroup(permissions) {
        const groups = [
            {
                id: 1,
                code: 'admin',
                name: 'Администрирование',
                permissions: [],
            },
            {
                id: 2,
                code: 'smev',
                name: 'СМЭВ',
                permissions: [],
            },
            {
                id: 3,
                code: 'ais',
                name: 'АИС',
                permissions: [],
            },
            {
                id: 4,
                code: 'all',
                name: 'Глобальные',
                permissions: [],
            },
        ];

        for (const permission of permissions) {
            const group = groups.find(item => {
                return permission.code.indexOf(item.code) === 0;
            });

            if (group != null) {
                const permissionInGroup = group.permissions.find(item => permission === item);

                if (!permissionInGroup) {
                    group.permissions.push(permission);
                }
            }
        }

        return groups;
    }

    // show - hide main navigation panel
    public hideSidebarMain(e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-xs');
    }

    // show - hide filter panel
    public hideSidebarSecondary(e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-secondary-hidden');
    }

    public getTimeTerms(service: any, termGuid: any) {
        let term: any;
        service.terms.term.forEach(item => {
            if (item.guid === termGuid) {
                term = item;
            }
        });

        return this.getServiceTerms(term);
    }

    public getServiceTerms(service) {
        let result = {};
        if (service.terms && service.terms.term) {
            const time = service.terms.term.times[1].processingTime;
            const timeType = service.terms.term.times[1].processingTimeType;
            result = {time: time, type: this.getServiceTermsType(timeType)};
        }

        return result;
    }

    /**
     * Человеческий вид типов сроков оказания услуг
     * @param timeType
     * @returns {any}
     */
    public getServiceTermsType(timeType: any) {
        let timeTypeString;
        switch (timeType) {
            case 'calendar':
                timeTypeString = ' дн.';
                break;
            case 'working':
                timeTypeString = ' раб. дн.';
                break;
            case 'month':
                timeTypeString = ' мес.';
                break;
            default:
                timeTypeString = ' дн.';
                break;
        }

        return (timeTypeString);
    }

    public getServiceTermsSperType(timeType: any) {
        let timeTypeString;
        switch (timeType) {
            case 'calendar':
                timeTypeString = '2';
                break;
            case 'working':
                timeTypeString = '1';
                break;
            case 'month':
                timeTypeString = '3';
                break;
            default:
                timeTypeString = '2';
                break;
        }

        return (timeTypeString);
    }

    /**
     * Массив типов участников без повторений
     * @param service - Услуга
     * @returns {string[]}
     */
    public getServiceApplicantTypes(service) {
        let applicantTypes;
        const objAppl = [];
        if (service.objects && service.objects.objectKinds) {
            service.objects.objectKinds.forEach(item => {
                item.subKinds.forEach(kind => {
                    applicantTypes = kind.secondGroupName;
                    objAppl[applicantTypes] = true;
                });
            });
        }

        return Object.keys(objAppl);
    }

    // формирование полей регламента по branch
    public selectOrgFieldsInService(service) {
        if (typeof service === 'object') {
            for (const key in service) {
                if (Array.isArray(service[key])) {
                    if (service[key].length > 0) {
                        const fieldArray = service[key];
                        for (const field of fieldArray) {
                            if (field['branch']) {
                                if (field['branch'].length > 1) {
                                    field['branch'] = this.selectBrach(field['branch']);
                                } else {
                                    const branch = field['branch'][0];
                                    field['branch'] = branch;
                                }
                            } else {
                                this.selectOrgFieldsInService(field);
                            }
                        }
                    }
                } else {
                    if (service[key] != null && service[key]['branch']) {
                        if (service[key]['branch'].length > 1) {
                            service[key]['branch'] = this.selectBrach(service[key]['branch']);
                        } else {
                            const branch = service[key]['branch'][0];
                            service[key]['branch'] = branch;
                        }
                    } else {
                        this.selectOrgFieldsInService(service[key]);
                    }
                }
            }
        }

        return service;
    }

    // из списка branch выбираем нужный по id unit
    public selectBrach(listBranchs) {
        let selectedBranch = false;

        for (const branch of listBranchs) {
            if (branch.unitId === this.storage.getItem('currentOrganization')) {
                selectedBranch = branch;
            }
        }

        if (!selectedBranch) {
            selectedBranch = listBranchs[0];
        }

        return selectedBranch;
    }

    public generateHeader(rules, data, isShort = false): string {
        let header = '';

        if (!rules) {
            if (isShort) {
                rules = this.defaultShortHeaderOptions;
            } else {
                rules = this.defaultHeaderOptions;
            }
        }

        if (Array.isArray(rules)) {
            rules.forEach(rule => {
                const arr = rule.split('|');

                if (arr[0] === 'f') {
                    const field = arr[1];
                    let fieldValue = ObjectUtilities.GetPropertyByPath(data, field) || '';

                    if (arr[2]) {
                        fieldValue = fieldValue.substring(0, arr[2]);
                    }

                    header += fieldValue;
                } else if (arr[0] === 's') {
                    header += arr[1];
                }
            }, this);
        }

        return header;
    }

    public isCollapsedFancyTreeNode(nameClass: any, event: any, filterArray: any) {
        if ($('.' + nameClass).css('display') !== 'none') {
            $('.' + nameClass).css('display', 'none');
            $(event.target).parent().removeClass('fancytree-expanded fancytree-exp-e ');
            $(event.target).parent().addClass('fancytree-exp-c ');
        } else {
            $('.' + nameClass).css('display', 'block');
            $(event.target).parent().removeClass('fancytree-exp-c ');
            $(event.target).parent().addClass('fancytree-expanded fancytree-exp-e ');
        }
    }

    /**
     * Проверяем на выделенние дочерние классификаторы у родителя
     *
     * @param classifier - родительский классификатор
     * @returns boolean (true - если есть хотя бы один выделенный дочерний)
     */
    public checkAllSelectedClassifier(classifier) {
        if (classifier.children.length > 1) {
            let checkedCount = 0;

            // считаем выделенные
            classifier.children.forEach(child => {
                if (child.isChecked) {
                    checkedCount++;
                }
            });

            // если выделенные все - родитель checked
            if (checkedCount === classifier.children.length) {
                classifier.isHalfChecked = false;
                classifier.isChecked = true;
            } else {
                // если есть хоть один из нескольких - родитель halfchecked
                if (checkedCount > 0) {
                    classifier.isHalfChecked = true;
                } else {
                    classifier.isHalfChecked = false;
                    classifier.isChecked = false;
                }
            }

        } else {
            classifier.isChecked = classifier.children[0].isChecked;
            classifier.isHalfChecked = false;
        }

        return classifier.isHalfChecked || classifier.isChecked;
    }

// разница между серверным и клиентским временем
    public getDiffServerClientTime(serverTime) {
        return moment(serverTime).diff(moment());
    }

    public getCurrentTime() {
        return moment(moment().valueOf() + this.storage.getItem('diffServerTime')).format('YYYY-MM-DD HH:mm Z');
    }

    /**
     * Присвоение номера сущности
     * @param {{}} params - тело запроса
     * @returns {Promise<any>} - ответ
     */
    public assignNumbers(params = {}) {
        this.progressService.start();

        return this.httpService.post(this.url + Config.mfc + Config.assignNumbers, params).then(
            response => {
                this.progressService.done();

                return Promise.resolve(response);
            },
            error => {
                this.progressService.done();

                return Promise.reject(error);
            },
        );
    }

    /**
     * Вычисление срока
     * @param {{}} params - тело запроса
     * @returns {Promise<any>} - ответ
     */
    public calculateDate(params = {}) {
        this.progressService.start();

        return this.httpService.post(this.url + Config.mfc + Config.calculateDate, params).then(
            response => {
                this.progressService.done();

                return Promise.resolve(response);
            },
            error => {
                this.progressService.done();

                return Promise.reject(error);
            },
        );
    }

    /**
     * Преобразование строки в base64
     * @param {string} str - исходная строка
     * @returns {string} - результат
     */
    public stringToBase64(str: string) {
        let binary = '';
        const bytes = new Uint8Array(this.str2ArrayBuffer(str));
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return window.btoa(binary);
    }

    /**
     * Преобразование ArrayBuffer в String
     * @param buf
     * @returns {any}
     */
    public arrayBuffer2Str(buf) {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }

    /**
     * Преобразование String в ArrayBuffer
     * @param str
     * @returns {ArrayBuffer}
     */
    public str2ArrayBuffer(str) {
        const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
        const bufView = new Uint16Array(buf);
        const strLen = str.length;
        for (let i = 0; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }

        return buf;
    }

    /**
     * Одноуровневое сравнение объектов
     * @param one - основной объект
     * @param two - сравниваемый объект
     * @returns {any} - объект с различиями
     */
    public compareOneLevelObjects(one, two) {
        const diff: any = {};
        Object.keys(one).forEach(field => {
            if (!isEqual(one[field], two[field])) {
                diff[field] = one[field];
            }
        });

        return diff;
    }

    /**
     * Разделы в preview компонентов
     * @param nameClass
     * @param event
     */
    public isCollapsedPreview(nameClass: any, event: any): void {
        if ($('.' + nameClass).css('display') !== 'none') {
            $('.' + nameClass).css('display', 'none');
            $('.' + event).addClass('rotate-90');
        } else {
            $('.' + nameClass).css('display', 'block');
            $('.' + event).removeClass('rotate-90');
        }
    }

    /**
     * Обновление запросов из СПЭР settingsComponent
     */
    public updateRequestSper() {
        return this.httpService.get(this.url + 'importRequests').then(
            response => {
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            },
        );
    }

    /**
     * Обновление метаданных из СПЭР settingsComponent
     */
    public updateMetaSper() {
        return this.httpService.get(this.url + 'importMetadata').then(
            response => {
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            },
        );
    }

    /**
     * Одновление производственных календарей из СПЭР settingsComponent
     */
    public updateCalendarSper() {
        return this.httpService.get(this.url + 'importProdCalendar').then(
            response => {
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            },
        );
    }

    /**
     * Добавление условий в параметры метода search
     * @param field
     * @param value
     * @param array
     * @returns {any}
     */
    public searchConditionPush(field, value, array) {
        array.push({
            field: field,
            operator: 'eq',
            value: value,
        });

        return array;
    }

    /**
     * Проверка настройки доступа к ЭО (Эволента) и сохранение URL в LocalStorage
     * @returns {boolean}
     */
    public checkUseEvolentaEo(): Promise<boolean | any> {
        const pultUrl = this.storage.getItem('evolentaPultUrl');
        if (!pultUrl) {
            return this.rest.search('settings',
                {search: {search: [{field: 'name', operator: 'eq', value: 'evolenta.eo.integration.pult.urls'}]}})
                .then(setting => {
                        if (setting.length > 0) {
                            const settingValue = JSON.parse(setting[0].value);
                            if (!settingValue.default) {
                                this.storage.setItem('evolentaPultUrl', '');

                                return Promise.resolve(false);
                            } else {
                                const currentOrganization = this.storage.getItem('currentOrganization');
                                const find = settingValue.organizations.find(item => {
                                    return item.id === currentOrganization['id'];
                                });
                                if (find) {
                                    this.storage.setItem('evolentaPultUrl', find.url);
                                } else {
                                    this.storage.setItem('evolentaPultUrl', settingValue.default);
                                }

                                return Promise.resolve(true);
                            }
                        } else {
                            return Promise.resolve(false);
                        }

                    }, error => {
                        return Promise.reject(error);
                    },
                );
        } else {
            return Promise.resolve(true);
        }
    }

    /**
     * Проверка настройки доступа к ЭО (Enter) и сохранение URL в LocalStorage
     * @returns {boolean}
     */
    public checkUseEnterEo(): Promise<boolean | any> {
        const pultUrl = this.storage.getItem('enterPultUrl');
        if (!pultUrl) {
            return this.rest.search('settings',
                {search: {search: [{field: 'name', operator: 'eq', value: 'enter.eo.integration.pult.urls'}]}})
                .then(setting => {
                        let settingValue: any = false;
                        if (setting.length > 0) {
                            settingValue = setting[0].value;
                        }

                        if (!settingValue) {
                            this.storage.setItem('enterPultUrl', '');

                            return Promise.resolve(false);
                        } else {
                            this.storage.setItem('enterPultUrl', settingValue);
                        }

                        return Promise.resolve(true);
                    }, error => {
                        return error;
                    },
                );
        } else {
            return Promise.resolve(true);
        }
    }

    public checkUseFrameEq(): Promise<boolean | any> {
        const pultUrl = this.storage.getItem('eq.frame.url');
        if (!pultUrl) {
            return this.rest.search('settings',
                {search: {search: [{field: 'name', operator: 'eq', value: 'eq.frame.url'}]}})
                .then(setting => {
                        let settingValue: any = false;
                        if (setting.length > 0) {
                            settingValue = setting[0].value;
                        }

                        if (!settingValue) {
                            this.storage.setItem('eq.frame.url', '');

                            return Promise.resolve(false);
                        } else {
                            this.storage.setItem('eq.frame.url', settingValue);
                        }

                        return Promise.resolve(true);
                    }, error => {
                        return error;
                    },
                );
        } else {
            return Promise.resolve(true);
        }
    }

    /**
     * Проверка настройки доступа ctoAsterisk
     * @returns {boolean}
     */
    public checkUseCtoAsterisk(): Promise<boolean | any> {
        const useCtoAsterisk = this.storage.getItem('useCtoAsterisk');
        if (!useCtoAsterisk) {
            return this.rest.search('settings',
                {search: {search: [{field: 'name', operator: 'eq', value: 'useCtoAsterisk'}]}})
                .then(setting => {
                        let settingValue: any = false;
                        if (setting.length > 0) {
                            settingValue = setting[0].value;
                        }

                        if (!settingValue) {
                            return Promise.resolve(false);
                        } else {
                            this.storage.setItem('useCtoAsterisk', settingValue);
                        }

                        return Promise.resolve(true);
                    }, error => {
                        return error;
                    },
                );
        } else {
            return Promise.resolve(true);
        }
    }

    public checkUseAsterisk(): Promise<boolean | any> {
        const asteriskSettings = this.storage.getItem('asteriskSettings');
        if (!asteriskSettings) {
            return this.rest.search('settings',
                {search: {search: [{field: 'name', operator: 'eq', value: 'asterisk.settings'}]}})
                .then(setting => {
                        if (setting && setting.length > 0) {
                            const settingValue = setting[0].value;
                            this.storage.setItem('asteriskSettings', settingValue);
                            if (settingValue.isUse) {
                                return Promise.resolve(true);
                            } else {
                                return Promise.resolve(false);
                            }
                        } else {
                            this.storage.setItem('asteriskSettings', 'none');

                            return Promise.resolve(false);
                        }
                    }, error => {
                        return error;
                    },
                );
        } else {
            return Promise.resolve(!!asteriskSettings.isUse);
        }
    }

    /**
     * Проверка и получение адреса Qmatic сервера
     * @returns {any}
     */
    public getQmaticServer() {
        const address = this.storage.getItem('qmaticIntegrationApi');
        if (!address) {
            return this.rest.search('settings',  {search: {search: [{field: 'name', operator: 'eq', value: 'qmatic.integration.api'}]}}).then(setting => {
                    if (setting.length > 0) {
                        const settingValue = setting[0].value;
                        this.storage.setItem('qmaticIntegrationApi', settingValue);

                        return Promise.resolve(settingValue);
                    } else {
                        return Promise.resolve(false);
                    }
                }, error => {
                    return Promise.reject(error);
                },
            );
        } else {
            return Promise.resolve(address);
        }
    }

    /**
     * Проверка сервера Eq
     */
    public getAdressServerEq(): Promise<string | boolean> {
        const serverAdress = this.storage.getItem('eqEvolentaServerApi');
        if (!serverAdress) {
            return this.rest.search('settings',
                {search: {search: [{field: 'name', operator: 'eq', value: 'evolenta.eo.integration.pult.api'}]}})
                .then(setting => {
                        if (setting.length) {
                            const settingValue = JSON.parse(setting[0].value);
                            if (!settingValue.default) {
                                this.storage.setItem('eqEvolentaServerApi', '');

                                return Promise.resolve(false);
                            } else {
                                const currentOrganization = this.storage.getItem('currentOrganization');
                                const find = settingValue.organizations.find(item => {
                                    return item.id === currentOrganization['_id'];
                                });
                                if (find) {
                                    this.storage.setItem('eqEvolentaServerApi', find.url);
                                } else {
                                    this.storage.setItem('eqEvolentaServerApi', settingValue.default);
                                }

                                return Promise.resolve(true);
                            }
                        } else {
                            return Promise.resolve(false);
                        }

                    }, error => {
                        return Promise.reject(error);
                    },
                );
        } else {
            return Promise.resolve(true);
        }

    }

    /**
     * Получение набора данных по определенным свойствам
     * @param array
     */
    public getDataForProperties(data, properties) {
        let resultObject = {};
        let i;
        if (Array.isArray(data)) {
            const resultArray = [];
            for (i = 0; i < data.length; i++) {
                resultObject = {};
                resultObject = seelistProperties();
                resultArray.push(resultObject);
            }

            return resultArray;
        } else {
            resultObject = seelistProperties();

            return resultObject;
        }

        function seelistProperties() {
            for (const property of properties) {
                resultObject[property] = data[i][property];
            }

            return resultObject;
        }
    }

    /**
     * Проверка снилс ФЛ
     * @param snils
     */
    public validateSnils(snils) {
        let error = null;
        if (snils) {
            snils = snils.replace(/-/g, '');
            const snilsArr = snils.split(' ');
            const positionsNumbers = [9, 8, 7, 6, 5, 4, 3, 2, 1];
            const snilsDigits = snilsArr[0].match(/[\S\s]{1,1}/g);
            let controlSum = 0;
            for (let i = 0; i < snilsDigits.length; i++) {
                controlSum += parseInt(snilsDigits[i], 10) * positionsNumbers[i];
            }
            let checkSymb;
            if (controlSum === 100 || controlSum === 101) {
                checkSymb = '00';
            } else if (controlSum > 101) {
                checkSymb = (controlSum % 101).toLocaleString();
            } else if (controlSum < 100) {
                checkSymb = controlSum;
            }

            if (checkSymb === '100') {
                checkSymb = '0';
            }
            if (parseInt(checkSymb, 10) !== parseInt(snilsArr[1], 10)) {
                error = 'Введен некорректный СНИЛС';
            }
        }

        return error;
    }

    /**
     * Проверка корректности ИНН ФЛ
     * @param inn
     */
    public validateInn(inn) {
        let error = null;
        if (inn) {
            if (inn.length !== 12) {
                error = 'Длина ИНН для ФЛ должна быть 12 символов';
            } else {
                const controlNumbers1 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
                const controlNumbers2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
                const innArray = inn.match(/[\S\s]{1,1}/g);
                let controlSum = 0;
                for (let i = 0; i < innArray.length - 1; i++) {
                    controlSum += parseInt(innArray[i], 10) * controlNumbers1[i];
                }
                let controlDigit1 = controlSum % 11;
                if (controlDigit1 > 9) {
                    controlDigit1 = controlDigit1 % 10;
                }
                controlSum = 0;
                for (let j = 0; j < innArray.length; j++) {
                    controlSum += parseInt(innArray[j], 10) * controlNumbers2[j];
                }
                let controlDigit2 = controlSum % 11;
                if (controlDigit2 > 9) {
                    controlDigit2 = controlDigit2 % 10;
                }
                if (controlDigit1 !== parseInt(inn.substr(inn.length - 2, 1), 10) || controlDigit2 !== parseInt(inn.substr(inn.length - 1, 1), 10)) {
                    error = 'Введен некорректный ИНН';
                }
            }
        }

        return error;
    }

    /**
     * Проверка ОГРН организации
     * @param ogrn
     */
    public validateOgrn(ogrn) {
        let error = null;
        if (ogrn) {
            if (ogrn.length !== 13) {
                error = 'Длина ОГРН для организации должна быть 13 цифр';
            } else if (ogrn.slice(-1) !== ((ogrn.slice(0, -1)) % 11 + '').slice(-1)) {
                error = 'Введен некорректный ОГРН';
            }
        }

        return error;
    }

    /**
     * Валидация ИНН организации
     * @param inn
     */
    public validateOrganizationInn(inn) {
        let error = null;
        if (inn) {
            if (inn.length !== 10) {
                error = 'Длина ИНН для ЮЛ должна быть 10 символов';
            } else {
                const controlNumbers = [2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
                const innArray = inn.match(/[\S\s]{1,1}/g);
                let controlSum = 0;
                for (let i = 0; i < innArray.length; i++) {
                    controlSum += parseInt(innArray[i], 10) * controlNumbers[i];
                }
                let controlDigit = controlSum % 11;
                if (controlDigit > 9) {
                    controlDigit = controlDigit % 10;
                }
                if (controlDigit !== parseInt(inn.substr(inn.length - 1, 1), 10)) {
                    error = 'Введен некорректный ИНН';
                }
            }
        }

        return error;
    }

    /**
     * Валидация КПП организации
     * @param kpp
     * @returns {any}
     */
    public validateKpp(kpp) {
        let error = null;
        if (kpp) {
            if (typeof kpp === 'number') {
                kpp = kpp.toString();
            } else if (typeof kpp !== 'string') {
                kpp = '';
            }
            if (kpp.length !== 9) {
                error = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
            } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
                error = 'Неправильный формат КПП';
            }
        }

        return error;
    }

    /**
     * Проверка ОГРН ИП
     * @param ogrn
     * @returns {any}
     */
    public validateIpOgrn(ogrn) {
        let error = null;
        if (ogrn) {
            if (ogrn.length !== 15) {
                error = 'Длина ОГРН для ИП должна быть 15 цифр';
            } else if (ogrn.slice(-1) !== ((ogrn.slice(0, -1)) % 13 + '').slice(-1)) {
                error = 'Введен некорректный ОГРН';
            }
        }

        return error;
    }

    /**
     * Формирование маски для элемента по ее краткому описанию
     * @param mask - краткий формат маски из БД
     */
    public calculateMask(mask) {
        mask = mask ? mask : 'SSSSSSSSSS';
        const items = [];
        let showGuid = true;
        if (mask) {
            if (mask === 'R-ББ') {
                showGuid = false;
                for (let i = 0; i < 10; i++) {
                    items.push(/[IVXLCА-Я-]/);
                }
            } else {
                for (let i = 0; i < mask.length; i++) {
                    const symbol = mask.charAt(i);
                    if (symbol === '9') {
                        items.push(/\d/);
                    } else if (symbol === 'R') {
                        items.push(/^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/);
                    } else if (symbol === 'Б') {
                        items.push(/[А-Я]/);
                    } else if (symbol === '-') {
                        items.push('-');
                    } else if (symbol === 'S') {
                        items.push(/[A-Za-zА-Яа-яЁё0-9]/);
                        showGuid = false;
                    } else if (symbol === '0') {
                        items.push(/\d?/);
                        showGuid = false;
                    }
                }
            }
        }

        return {
           mask: items,
           showGuid: showGuid,
        };
    }

    /**
     * Проверка актуальности пароля пользователя
     */
    public checkUserActualPassword() {
        const user = this.storage.getItem('user');
        let isActualUserPassword = true;
        if (!this.isCompleteAuth) {
            if (user.forceChangePassword) {
                isActualUserPassword = false; // Требуется принудительная смена пароля
            } else {
                const passwordSettings = this.storage.getItem('passwordSettings');
                if (passwordSettings && passwordSettings.changeInPeriod) {
                    if (!user.lastPasswordChangeDate) {
                        isActualUserPassword = false; // Если выставлен период смены пароля, а пароль еще ни разу не менялся
                    } else {
                        // Если текущее время больше времени смены пароля + период смены пароля
                        const actualDays = passwordSettings.changePeriodByDays;
                        const utcOffset = this.storage.getItem('utcOffset');
                        if (moment(user.lastPasswordChangeDate).utcOffset(utcOffset).add(actualDays, 'd') < moment().add(-utcOffset, 'm')) {
                            isActualUserPassword = false;
                        }
                    }
                }
            }
        }
        if (isActualUserPassword) {
            this.isCompleteAuth = true;
        }

        return isActualUserPassword;
    }

    /**
     * Дополнение нулями числа
     * @param number - число (строка)
     * @param length - конечная длина строки
     * @returns {string}
     */
    public addZeroToNumber(number, length) {
        let string = '';
        for (let i = 0; i < length; i++) {
            string += '0';
        }
        string += number;

        return string.slice(-length);
    }

    /**
     * Получение информации по организациям авторизованного пользователя
     * @returns {any}
     */
    public getUserOrganizations() {
        const userOrganizations = this.storage.getItem('userOrganizations');
        if (!userOrganizations) {
            const user = this.storage.getItem('user');
            const organizationGuids = [];
            user.sprOrganizations.forEach(org => {
                organizationGuids.push(org.id);
            });

            return this.rest.search('organizations', {search: {search: [{field: 'guid', operator: 'in', value: organizationGuids}]}, prj: 'shortOrganizations'}).then(orgs => {
                this.storage.setItem('userOrganizations', orgs);

                return Promise.resolve(orgs);
            });
        } else {
            return Promise.resolve(userOrganizations);
        }
    }

    public getMonth(month) {
        switch (month) {
            case '01':
                return 'января';
            case '02':
                return 'февраля';
            case '03':
                return 'марта';
            case '04':
                return 'апреля';
            case '05':
                return 'мая';
            case '06':
                return 'июня';
            case '07':
                return 'июля';
            case '08':
                return 'августа';
            case '09':
                return 'сентября';
            case '10':
                return 'октября';
            case '11':
                return 'ноября';
            case '12':
                return 'декабря';
            default:
                return '';
        }
    }

    public prepareFilterByApplicationProperty(data, unitProperty, fieldPath, basePath) {
        const currentOrganization = this.storage.getItem('currentOrganization');
        if (data.applicationProperty && currentOrganization[unitProperty] && currentOrganization[unitProperty].length > 0) {
            const propertyName = data.applicationProperty;
            const propertyValue = this.applicationsService.getApplicationPropertyByApplicationPath(basePath, propertyName);
            if (propertyValue) {
                let baseSearch;
                let organizationRegistryTypes = currentOrganization[unitProperty];
                if (propertyName === 'usedRegistryTypes') {
                    organizationRegistryTypes = organizationRegistryTypes.filter(item => propertyValue.indexOf(item.code) !== -1);
                    baseSearch = {
                        field: fieldPath,
                        operator: 'in',
                        value: organizationRegistryTypes.map(item => item.code),
                    };
                } else if (propertyName === 'notUsedRegistryTypes') {
                    organizationRegistryTypes = organizationRegistryTypes.filter(item => propertyValue.indexOf(item.code) === -1);
                    baseSearch = {
                        field: fieldPath,
                        operator: 'in',
                        value: organizationRegistryTypes.map(item => item.code),
                    };
                }
                const filters = [];
                if (organizationRegistryTypes.length > 0) {
                    organizationRegistryTypes.forEach(type => {
                        filters.push({
                            fields: [fieldPath],
                            code: type.code,
                            type: 'checkbox',
                            useValue: type.code,
                            placeholder: type.name,
                        });
                    });
                }

                return {filters, baseSearch, registryTypes: organizationRegistryTypes};
            }
        }

        return null;
    }

    public checkAddressValid(address) {
        return !!(address && (address.fullAddress || address.unrecognizablePart));
    }

    public getUnitAdditionalParams(params) {
        console.log('utility service getUnitAdditionalParams', params);
        if (params && params.length) {
            const currentOrganization = this.storage.getItem('currentOrganization');
            let needUpdate = false;
            params.forEach(param => {
                if (currentOrganization[param] === undefined) {
                    needUpdate = true;
                }
            });
            if (needUpdate) {
                return this.rest.find('organizations', currentOrganization._id).then(unit => {
                    params.forEach(param => {
                        currentOrganization[param] = unit[param] ? unit[param] : null;
                    });
                    this.storage.setItem('currentOrganization', currentOrganization);

                    return Promise.resolve();
                });
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    }

    public getDayTitle(number: number, titles: { days_one: string, days_several: string, days_many: string }, capitalize = false) {
        let title: string;
        const lastNum = Math.abs(number) % 10;

        if (number >= 10 && number <= 14) { title = titles.days_many; } else if (lastNum === 1) { title = titles.days_one; } else if (lastNum >= 2 && lastNum <= 4) { title = titles.days_several; } else { title = titles.days_many; }

        return capitalize
            ? title.slice(0, 1).toUpperCase() + title.slice(1).toLowerCase()
            : title;
    }
}
