import { NgModule } from '@angular/core';
import { RequestsModule } from '@evolenta/requests-module';
import { MetaReglamentsModule } from '@evolenta/meta-reglaments-module';
import { DeveloperRouting } from './developer.routing';
import { DevtoolsModule } from '../../modules/devtools/devtools.module';
import { ApplicationsModule } from '../../modules/applications/applications.module';

@NgModule({
    imports: [DeveloperRouting, RequestsModule, MetaReglamentsModule, DevtoolsModule, ApplicationsModule],
})
export class DeveloperModule {}
