<div>
    <button class="btn btn-default"
            type="button"
            style="height: 32px"
            (click)="generate()">
        {{ localizations['generate-password'].action }}
    </button>
    <div class="display-inline-block no-border ml-15 no-margin-bottom pt-5 pb-5 pr-10 pl-10 alert alert-info"
         *ngIf="password">
        <strong>
            {{password}}
        </strong>
    </div>
</div>
