<div [ngClass]="{'panel panel-shadow m-20': !entityType}"
     *ngIf="initialized">
    <div class="panel-heading"
         *ngIf="!entityType">
        <h4 class="panel-title">
            <span>
                {{ getSetupMessage() }}
            </span>
        </h4>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    *ngIf="allowEdit"
                    (click)="save()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn btn-danger-overline heading-btn btn-labeled"
                    (click)="cancel()">
                {{ allowEdit ? localizations.common.do_cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div [ngClass]="{'panel-body': !entityType}">
        <ng-container *ngIf="!entityType">
            <div class="form-group form-group-lg no-margin-bottom">
                <label>{{ localizations.common.name }}
                    <span class="text-danger">*</span>
                </label>
                <input class="form-control"
                       type="text"
                       name="name"
                       [(ngModel)]="fieldsRequirements.name"
                       #name="ngModel">
                <div *ngIf="isProcessValidate && name.errors"
                     class="validation-error-label">
                    <span *ngIf="name.errors.required">
                        {{ localizations.common.name_field_is_required }}
                    </span>
                </div>
            </div>
            <div class="form-group">
                <div class="radio-inline"
                     *ngFor="let type of subjectTypes">
                    <input id="subjectType{{type.code}}"
                           class="radio"
                           type="radio"
                           name="subjectType"
                           [checked]="fieldsRequirements.subjectType && fieldsRequirements.subjectType === type.code"
                           (change)="selectSubjectType(type)">
                    <label for="subjectType{{type.code}}">
                        {{type.name}}
                    </label>
                </div>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && !fieldsRequirements.subjectType">
                    {{ localizations.standards.type_is_required }}
                </div>
            </div>
        </ng-container>

        <div class="page-container"
             *ngIf="fields && fields.length > 0">
            <div class="panel-main">
                <div class="pr-20">
                    <common-standard-fields-requirements-dropzone [zone]="fieldsRequirements.data" (onRemoveFields)="returnFields($event)">
                    </common-standard-fields-requirements-dropzone>
                </div>
            </div>
            <div class="sidebar sidebar-secondary sidebar-default border-left border-default border-top">
                <div class="sidebar-content">
                    <div class="sidebar-category no-border">
                        <div class="category-title cursor-pointer">
                            <span>
                                {{ localizations.standards.logic_elements }}
                            </span>
                        </div>
                        <div class="category-content collapse in no-padding-bottom">
                            <div class="cursor-move mb-5"
                                 [dndDraggable]="andElement">
                                <div class="border-top border-left border-right border-bottom border-lg border-orange-300 pt-5 pb-5 pl-10 pr-10 font-size-large bg-orange-50 text-orange-800">
                                    {{ localizations.standards.and_elements }}
                                </div>
                            </div>
                            <div class="cursor-move mt-10"
                                 [dndDraggable]="orElement">
                                <div class="border-top border-left border-right border-bottom border-lg border-success-300 pt-5 pb-5 pl-10 pr-10 font-size-large bg-success-50 text-success-800">
                                    {{ localizations.standards.or_elements }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sidebar-category">
                        <div class="category-title cursor-pointer">
                            <span>
                                {{ localizations.standards.form_elements }}
                            </span>
                        </div>
                        <div class="category-content no-padding collapse in">
                            <evolenta-scrollbar>
                                <div class="fields">
                                    <ul class="navigation navigation-alt">
                                        <li class="pt-5 pb-5 cursor-move"
                                            *ngFor="let field of fields; let idx = index"
                                            [dndDraggable]="field"
                                            [dndType]="'element'"
                                            dndEffectAllowed="move"
                                            (dndMoved)="onDragged(idx)">
                                            <a class="field-name">
                                                {{field.name}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </evolenta-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




