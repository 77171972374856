<div class="sidebar-content height-100">
    <evolenta-scrollbar>
        <div class="sidebar-category"
             *ngFor="let tab of tabs"
             (click)="activateTab(tab)"
             [ngClass]="{
                'active': activeTab == tab.code,
                 'cursor-pointer' : activeTab != tab.code
                }" >
            <div class="category-title">
                <span>
                    {{ tab.name }}
                </span>
            </div>
        </div>

        <div class="mt-30">
            <!-- Доступные действия для стандарта -->
            <div class="status-action"
                 *ngIf="(actions || printForm || entity.file) && commonStandardsService.standard._id">
                <div class="status-action-btn">
                    <ul class="list-unstyled">
                        <ng-container *ngFor="let action of actions">
                            <li class="text-center m-15">
                                <div class="btn-group btn-group-sm flex">
                                    <button class="btn bg-blue-800 btn-labeled"
                                            [disabled]="processingAction"
                                            (click)="executeAction(action)">
                                        <i class="icon-checkmark3"
                                           [ngClass]="{'flash': processingAction && processingAction.guid == action.guid}">
                                        </i>
                                    </button>
                                    <button class="btn no-white-space bg-blue-800 text-center p-5 width-100"
                                            [disabled]="processingAction"
                                            (click)="executeAction(action)">
                                        {{action.name}}
                                    </button>
                                </div>
                            </li>
                        </ng-container>

                        <!-- Сформированный файл -->
                        <li class="text-center m-15 mt-30"
                            *ngIf="entity.file">
                            <div class="btn-group btn-group-sm flex mt-10 dropdown">
                                <button class="btn btn-success btn-labeled">
                                    <i class="icon-link2"></i>
                                </button>
                                <button class="btn no-white-space btn-success full-width text-center"
                                        (click)="downloadFile(entity.file)">
                                    {{ getDownloadMessage() }}
                                </button>
                            </div>
                            <span class="anchor text-size-small text-info mt-10 display-inline-block"
                                  *ngIf="!entity.file.certificate && entity.status == 'approved'"
                                  (click)="signFile(entity.file)">
                                {{ localizations.common.sign }}
                            </span>
                            <div class="display-inline-block mt-10 mb-20"
                                 *ngIf="entity.file.signData">
                                <span class="anchor text-size-small text-info"
                                      (click)="signInfo()">
                                    {{ localizations.standards.sign_data }}
                                </span>
                            </div>
                            <div class="display-inline-block mt-10 mb-20"
                                 *ngIf="entity.file.certificate">
                                <i class="icon-medal text-danger cursor-pointer"
                                   (click)="openCertificate(entity.file.certificate)"
                                   [title]="localizations.common.open_certificate">
                                </i>
                                <span class="text-size-small mr-10">
                                    {{getFileCertificateInfo(entity.file)}}</span>
                                <span class="anchor text-size-small text-info"
                                      (click)="verifySignFile(entity.file)">
                                    {{ localizations.common.check }}
                                </span>
                            </div>
                        </li>

                        <!-- Формирование ПФ -->
                        <li class="text-center m-15 mt-20"
                            *ngIf="printForm && (commonStandardsService.reglament && commonStandardsService.allowEditReglament
                                                                     || !commonStandardsService.reglament && commonStandardsService.allowEditStandard)">
                            <div class="btn-group btn-group-sm flex mt-10 dropdown">
                                <button class="btn btn-primary btn-labeled">
                                    <i class="icon-printer"></i>
                                </button>
                                <button class="btn no-white-space btn-primary-overline full-width text-center"
                                        style="border-bottom-left-radius: 0; border-top-left-radius: 0;"
                                        (click)="generatePrintForm()">
                                    {{ localizations.common.create_print_form }}
                                </button>
                            </div>
                        </li>
                        <li class="text-center m-15 mt-20">
                            <button class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                    type="button"
                                    *ngIf="kcrId"
                                    (click)="sendKCPRequest()">
                                {{ localizations.standards.send_kcr_request }}
                            </button>
                        </li>

                        <li class="text-center m-15 mt-20">
                            <button class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                    type="button"
                                    [disabled]="convertingStandardToSubserviceIsInProcess"
                                    (click)="convertStandardToSubservice()">
                                {{ localizations.common.upload }}
                                <i class="icon-spinner2 spinner ml-5"
                                   *ngIf="convertingStandardToSubserviceIsInProcess">
                                </i>
                            </button>
                            <div class="pl-20 pr-20 pb-20 text-size-mini text-center text-slate"
                                 *ngIf="commonStandardsService.standard.dateLastUpload">
                                {{ localizations.common.uploaded }}:
                                {{commonStandardsService.standard.dateLastUpload | date: 'medium'}}
                            </div>
                        </li>

                        <!-- TODO добавить кнопку смены статуса draft approved canceled -->

                        <li class="text-center m-15 mt-20">
                            <button class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                    type="button"
                                    (click)="changeStatus()">
                                {{ getChangeStatusButtonName() }}
                            </button>
                        </li>

                        <li class="text-center m-15 mt-20">
                            <button class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                    type="button"
                                    [disabled]="isProcessingCloneStandard"
                                    (click)="cloneStandard()">
                                {{ localizations.standards.clone }}
                                <i class="icon-spinner2 spinner"
                                   *ngIf="isProcessingCloneStandard">
                                </i>
                            </button>
                        </li>

                        <li class="text-center m-15 mt-20">
                            <button class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                    type="button"
                                    [disabled]="isProcessingExportStandard"
                                    (click)="exportStandard()">
                                {{ localizations.common.upload_to_file }}
                                <i class="icon-spinner2 spinner"
                                   *ngIf="isProcessingExportStandard">
                                </i>
                            </button>
                        </li>

                        <li class="text-center m-15 mt-20">
                            <input id="importFile"
                                   class="inputfile hide"
                                   type="file"
                                   name="importFile"
                                   (change)="importStandard($event)" />
                            <label class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                   for="importFile"
                                   *ngIf="!isProcessingImportStandard">
                                {{ localizations.standards.import_from_file }}
                            </label>
                            <button class="btn btn-primary upload-btn width-100 pt-5 pb-5"
                                    disabled
                                    *ngIf="isProcessingImportStandard">
                                {{ localizations.standards.upload_from_file }}
                                <i class="icon-spinner2 spinner"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </evolenta-scrollbar>
</div>

<!-- Диалог для подписания файлов -->
<evolenta-cades-sign-modal
        (onSignCompletedEnvelope)="onSignatureComplete($event)"
        (onVerifyCadesBesSignCompleted)="onVerifyCadesBesSignComplete($event)"
></evolenta-cades-sign-modal>


<ng-template #signInfoModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.standards.sign_info }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <evolenta-scrollbar [maxHeight]="'650px'">
            <ul class="list-feed mb-20 no-padding-top">
                <li *ngFor="let data of entity.file.signData">
                    <div>
                        <span class="text-muted">
                            {{data.date | date: 'mediumDate'}}
                        </span>
                        <span class="text-info ml-5">
                            {{data.user.name}}
                        </span>
                    </div>
                    <div class="display-inline-block">
                        <i class="icon-medal text-danger cursor-pointer"
                           [title]="localizations.common.open_certificate"
                           (click)="openCertificate(data.certificate)">
                        </i>
                        <span class="text-size-small mr-10">
                            {{getFileCertificateInfo({certificateData: data.certificateData})}}
                        </span>
                        <span class="anchor text-size-small text-info"
                              (click)="verifySignFile(entity.file, data)">
                            {{ localizations.common.check }}
                        </span>
                    </div>
                </li>
            </ul>
        </evolenta-scrollbar>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default pl-15 pr-15 pt-10 pb-10"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>

<ng-template #importFromFileModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.common.settings }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="cancelImportStandard()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="checkbox-block">
            <input class="checkbox"
                   type="checkbox"
                   [checked]="importParams.importOrganizations"
                   (change)="importParams.importOrganizations = !importParams.importOrganizations" id="importOrganizationProperty">
            <label for="importOrganizationProperty">
                {{ localizations.standards.update_organizations_info }}
            </label>
        </div>
        <div class="checkbox-block">
            <input type="checkbox"
                   class="checkbox"
                   [checked]="importParams.importRequests"
                   (change)="importParams.importRequests = !importParams.importRequests" id="importRequestsProperty">
            <label for="importRequestsProperty">
                {{ localizations.standards.update_requests_info }}
            </label>
        </div>
        <div class="checkbox-block">
            <input type="checkbox"
                   class="checkbox"
                   [checked]="importParams.importPrintForms"
                   (change)="importParams.importPrintForms = !importParams.importPrintForms" id="importPrintForms">
            <label for="importPrintForms">
                {{ localizations.standards.update_print_forms_info }}
            </label>
        </div>
        <div class="checkbox-block">
            <input type="checkbox"
                   class="checkbox"
                   [checked]="importParams.importDictionaries"
                   (change)="importParams.importDictionaries = !importParams.importDictionaries" id="importDictionaries">
            <label for="importDictionaries">
                {{ localizations.standards.update_reference_info }}
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-primary"
                (click)="processingImportStandard()">
            {{ localizations.standards.yes_update }}
        </button>
        <button type="button"
                class="btn btn-default"
                (click)="cancelImportStandard()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>

<ng-template #cloneParamsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.standards.cloning_settings }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="cancelCloneStandard()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="checkbox-block">
            <input class="checkbox"
                   type="checkbox"
                   [checked]="cloneParams.clonePrintForms"
                   (change)="cloneParams.clonePrintForms = !cloneParams.clonePrintForms" id="clonePrintForms">
            <label for="clonePrintForms">
                {{ localizations.standards.clone_print_forms }}
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary"
                type="button"
                (click)="processingCloneStandard()">
            {{ localizations.common.apply }}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="cancelCloneStandard()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>
