<div class="panel panel-preview"
     [ngClass]="{'panel-shadow m-20': mode == 'card'}"
     *ngIf="appeal">
    <div class="panel-heading no-border-radius no-border-left"
         *ngIf="mode == 'full' || mode == 'card'">
        <h6 class="panel-title">
            <ng-container *ngIf="appeal.status.code == 'consultation'">
                {{ getConsultationNumberMessage(appeal.consultationNumber, (appeal.dateConsultation | date:'shortDate')) }}
            </ng-container>
            <ng-container *ngIf="appeal.status.code != 'consultation'">
                {{ localizations.appeals.appeal }} №
                <span *ngIf="appeal.shortNumber">
                    {{appeal.shortNumber}}
                </span>
                <span *ngIf="!appeal.shortNumber">
                    n/a
                </span>
                <ng-container *ngIf="!appeal.dateRegister || appeal.dateRegister == null">
                    <span class="hidden-md hidden-xs hidden-sm">
                        {{ localizations.common.created_small }}
                    </span>
                    <span class="visible-md-inline">
                        {{ localizations.common.from }} </span>{{appeal.dateCreation | date:'shortDate'}} {{ localizations.common.year_small }}
                </ng-container>
                <ng-container *ngIf="appeal.dateRegister && appeal.dateRegister !== null">
                    <span class="hidden-md hidden-xs hidden-sm">
                        {{ localizations.common.registered }}
                    </span>
                    <span class="visible-md-inline"> {{ localizations.common.from }} </span>
                    {{appeal.dateRegister | date:'shortDate'}} {{ localizations.common.year_small }}
                </ng-container>
            </ng-container>
            <div>
                <label class="badge text-uppercase"
                       [ngClass]="getStatusProperty('background')">
                    {{getStatusProperty('name')}}
                </label>
                <label class="badge text-uppercase bg-danger-300 ml-10"
                       *ngIf="appeal.fromPgu || appeal.pguEventId">
                    {{ localizations.common.rpgu }}
                </label>
                <label class="badge text-uppercase bg-teal-300 ml-10"
                       *ngIf="appeal.fromPgu2 || appeal.pgu2EventId">
                    {{ localizations.common.rpgu2 }}
                </label>
                <label class="badge text-uppercase bg-brown-300 ml-10"
                       *ngIf="appeal.fromMfc || appeal.mfcEventId">
                    {{ localizations.common.mfc }}
                </label>
                <label class="badge text-uppercase bg-green-300 ml-10"
                       *ngIf="appeal.fromStroyu || appeal.stroyuEventId">
                    {{ localizations.common.yastroyu }}
                </label>
                <label class="badge text-uppercase bg-orange-300 ml-10"
                       *ngIf="appeal.fromLocolo || appeal.locoloEventId">
                    {{ localizations.common.locolo }}
                </label>
                <label class="badge text-uppercase bg-success-300 ml-10"
                       *ngIf="appeal.fromZabota || appeal.zabotaEventId">
                    {{ localizations.common.zabota }}
                </label>
                <label class="badge text-uppercase bg-purple-300 ml-10"
                       *ngIf="appeal.fromEpgu || appeal.epguOrderId">
                    {{ localizations.common.epgu }}
                </label>

                <label class="badge text-uppercase bg-blue-300 ml-10"
                       *ngIf="!(appeal.fromPgu || appeal.pguEventId)
                           && !(appeal.fromPgu2 || appeal.pgu2EventId)
                           && !(appeal.fromMfc || appeal.mfcEventId)
                           && !(appeal.fromStroyu || appeal.stroyuEventId)
                           && !(appeal.fromLocolo || appeal.locoloEventId)
                           && !(appeal.fromZabota || appeal.zabotaEventId)
                           && !(appeal.fromEpgu || appeal.epguOrderId)
                ">
                    {{ localizations.appeals.source.arm }}
                </label>
            </div>
        </h6>
        <div class="heading-elements">
            <button class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="edit()">
                {{ localizations.common.proceed }}
            </button>
        </div>
    </div>
    <div class="panel-preview-container">
        <evolenta-scrollbar>
            <div class="panel-body mb-20">
                <!-- Общие данные -->
                <fieldset>
                    <legend class="cursor-pointer mb-10 pb-20 text-bold"
                            (click)="tabs.common = !tabs.common">
                        <span>
                            {{ localizations.common.general_data }}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{
                                   'icon-arrow-down12' : tabs.common,
                                   'icon-arrow-left12' : !tabs.common
                                }"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': tabs.common}">
                        <div class="flex flex-row mb-10">
                            <div class="text-muted">
                                {{ localizations.common.organization }}:
                            </div>
                            <div>
                                {{appeal.unit.shortName ? appeal.unit.shortName : appeal.unit.name}}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="appeal.dateLastModification && appeal.dateLastModification != null">
                            <div class="text-muted">
                                {{ localizations.common.changed }} :
                            </div>
                            <div>
                                {{appeal.dateLastModification | date:'shortDate'}} {{ localizations.common.year_small }}
                            </div>
                        </div>
                        <div class="flex flex-row mb-10"
                             *ngIf="appeal.datePlaneFinish">
                            <div class="text-muted">
                                {{ localizations.common.term_till }}:
                            </div>
                            <div>
                                {{appeal.datePlaneFinish | date:'shortDate'}} {{ localizations.common.year_small }}
                            </div>
                        </div>
                        <!-- Если дело сформировано по комплексной услуге -->
                        <div class="flex flex-row mb-10"
                             *ngIf="appeal.complexSubservice">
                            <div class="text-muted">
                                {{ localizations.appeals.complex_check }}:
                            </div>
                            <div>
                                {{appeal.complexSubservice.name}}
                            </div>
                        </div>

                        <div class="flex flex-row mb-10" *ngIf="appeal.variant">
                            <div class="text-muted">
                                {{ localizations.common.variant }}:
                            </div>
                            <div>
                                <span *ngIf="appeal.variant && appeal.variant.selectedVariants">
                                    <span *ngFor="let variant of appeal.variant.selectedVariants">
                                        {{variant.name}}
                                    </span>
                                </span>
                            </div>
                        </div>

                        <ng-container *ngIf="appeal.status && appeal.status.code == 'consultation'">
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.appeals.consultation_subject }}:
                                </div>
                                <div *ngIf="appeal.consultationReason">
                                    {{appeal.consultationReason}}
                                </div>
                                <div *ngIf="!appeal.consultationReason">
                                    {{ localizations.common.not_specified_m }}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.appeals.consultation_result }}:
                                </div>
                                <div *ngIf="appeal.consultationResult">
                                    {{appeal.consultationResult}}
                                </div>
                                <div *ngIf="!appeal.consultationResult">
                                    {{ localizations.common.not_specified_m }}
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="appeal.reasonAnnul">
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.appels.cancel_date }}:
                                </div>
                                <div>
                                    {{appeal.dateAnnul | date: 'shortDate'}} {{ localizations.common.year_small }}
                                </div>
                            </div>
                            <div class="flex flex-row mb-10">
                                <div class="text-muted">
                                    {{ localizations.appeals.cancel_reason }}:
                                </div>
                                <div>
                                    {{appeal.reasonAnnul.title}}
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </fieldset>

                <!-- Услуги -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="tabs.subservice = !tabs.subservice">
                        <span>
                            {{ localizations.services.title }}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12' : tabs.subservice,
                                'icon-arrow-left12' : !tabs.subservice
                                }">
                            </i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': tabs.subservice}">
                        <div class="list list-square pl-20">
                            <label class="badge text-uppercase no-margin-bottom badge-xs mr-5"
                                   [ngClass]="getStatusProperty('background', appeal.subservice)">
                                {{getStatusProperty('name', appeal.subservice)}}
                            </label>
                            <label class="badge text-uppercase no-margin-bottom badge-xs mr-5"
                                   [ngClass]="getStatusProperty('background', appeal.subservice, 'rejectedBackground')"
                                   *ngIf="appeal.subservice.resultType && appeal.subservice.resultType.type == 'negative'">
                                {{ localizations.common.decline }}
                            </label>
                            <span class="text-muted mr-5">
                                    {{getSubserviceCode(appeal.subservice)}}
                                </span>
                            <span class="text-semibold">
                                    {{appeal.subservice.shortTitle}}
                                </span>
                            <div *ngIf="appeal.subservice.responsibleOrganization">
                                    <span class="text-muted text-uppercase">
                                        {{ localizations.common.organization }}:
                                    </span>
                                {{appeal.subservice.responsibleOrganization.name}}
                            </div>
                            <div *ngIf="appeal.subservice.variant">
                                    <span class="text-muted text-uppercase ">
                                        {{ localizations.common.variant }}:
                                    </span>
                                <ng-container *ngFor="let variant of appeal.subservice.variant.selectedVariants; let lst = last">
                                    {{variant.name}}
                                    <ng-container *ngIf="!lst"> / </ng-container>
                                </ng-container>
                            </div>
                            <div *ngIf="appeal.subservice.subjects && appeal.subservice.subjects.length > 0">
                                    <span class="text-muted text-uppercase">
                                        {{ localizations.appeals.subjects.title }}
                                    </span>
                                <ul class="list">
                                    <li *ngFor="let subject of appeal.subservice.subjects; let fst = first"
                                        [ngClass]="{
                                                'no-margin-top': fst,
                                                'mt-5': !fst
                                                }">
                                        <div class="text-bold">
                                            {{getSubjectHeaderByGuid(subject.guid)}}
                                        </div>
                                        <div *ngIf="subject.subKind">
                                                <span class="text-muted">
                                                    {{ localizations.appeals.subjects.participation_type }}:
                                                </span> {{subject.subKind.firstGroupName}}
                                        </div>

                                        <div *ngIf="subject.category">
                                                <span class="text-muted">
                                                    {{ localizations.common.category }}:
                                                </span>
                                            <ng-container *ngFor="let category of subject.category.selectedCategories; let lst = last">
                                                {{category.name}}<ng-container *ngIf="!lst"> / </ng-container>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="subject.numberErp">
                                                <span class="text-muted">
                                                    {{ localizations.appeals.subjects.account_number }}:
                                                </span>
                                            <span class="text-bold text-size-small">
                                                    {{subject.numberErp}}
                                                </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <!-- Субъекты -->
                <fieldset>
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="tabs.subjects = !tabs.subjects">
                        <span>
                            {{ localizations.common.participants }}
                        </span>
                        <a class="control-arrow"><i [ngClass]="{'icon-arrow-down12' : tabs.subjects, 'icon-arrow-left12' : !tabs.subjects}"></i></a>
                    </legend>
                    <div class="collapse pt-20 pb-10" [ngClass]="{'in': tabs.subjects}">
                        <ul class="list list-square pl-20" *ngIf="appeal.status.code != 'archive'">
                            <li *ngFor="let subject of appeal.subjects; let i = index; let lst = last">
                                <div>
                                    <span class="text-semibold">{{subject.header}}</span>
                                    <span class="anchor text-info text-size-small ml-10" (click)="copyToBuffer(subject)">копировать</span>
                                </div>
                                <div *ngIf="subject.specialTypeId === 'individualApplicant'">
                                    <div *ngIf="subject.data.person.documentType || subject.data.person.documentSeries || subject.data.person.documentNumber">
                                        <span class="text-size-small text-muted">
                                            {{ localizations.common.document }}:
                                        </span>
                                        <span *ngIf="subject.data.person.documentType">
                                            {{subject.data.person.documentType[0].text}}
                                        </span>
                                        <span *ngIf="subject.data.person.documentSeries">
                                            {{ localizations.common.series }} {{subject.data.person.documentSeries}}
                                        </span>
                                        <span *ngIf="subject.data.person.documentNumber">
                                            № {{subject.data.person.documentNumber}}
                                        </span>
                                    </div>
                                    <div *ngIf="subject.data.person.inn || subject.data.person.snils">
                                        <span class="text-size-small text-muted"
                                              *ngIf="subject.data.person.inn">
                                            {{ localizations.common.inn }}
                                        </span>
                                        <span *ngIf="subject.data.person.inn">
                                            {{subject.data.person.inn}}
                                        </span>
                                        <span class="text-size-small text-muted"
                                              *ngIf="subject.data.person.snils">
                                            {{ localizations.subjects.snils }}
                                        </span>
                                        <span *ngIf="subject.data.person.snils">
                                            {{subject.data.person.snils}}
                                        </span>
                                    </div>
                                    <div *ngIf="subject.data.person.mobile || subject.data.person.phone || subject.data.person.email || subject.data.person.workPhone">
                                        <ng-container *ngIf="subject.data.person.mobile">
                                            <span class="text-size-small text-muted">
                                                {{ localizations.common.cell_phone }}:
                                            </span>
                                            {{subject.data.person.mobile}}
                                        </ng-container>
                                        <ng-container *ngIf="subject.data.person.workPhone">
                                            <span class="text-size-small text-muted">
                                                {{ localizations.common.work_phone }}:
                                            </span>
                                            {{subject.data.person.workPhone}}
                                        </ng-container>
                                        <ng-container *ngIf="subject.data.person.phone">
                                            <span class="text-size-small text-muted">
                                                {{ localizations.common.home_phone }}:
                                            </span> {{subject.data.person.phone}}
                                        </ng-container>
                                        <ng-container *ngIf="subject.data.person.email">
                                            <span class="text-size-small text-muted">
                                                Email:
                                            </span>
                                            {{subject.data.person.email}}
                                        </ng-container>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </fieldset>

                <!--Документы-->
                <fieldset *ngIf="appeal.status.code != 'archive'">
                    <legend class="text-bold cursor-pointer mb-10 pb-20"
                            (click)="tabs.documents = !tabs.documents">
                        {{ localizations.common.documents }}
                        <span class="badge bg-green-700 ml-10">
                            {{appeal.documents.length}}
                        </span>
                        <a class="control-arrow">
                            <i [ngClass]="{
                                'icon-arrow-down12' : tabs.documents,
                                'icon-arrow-left12' : !tabs.documents
                                }"></i>
                        </a>
                    </legend>
                    <div class="collapse pt-20 pb-10"
                         [ngClass]="{'in': tabs.documents}">
                        <ng-container *ngIf="appeal.status.code != 'archive'">
                            <div class="list-item-data"
                                 *ngIf="appeal.documents.length > 0">
                                <ul class="list list-square">
                                    <li *ngFor="let group of groups">
                                        <span class="text-semibold">
                                            {{group.name}}
                                        </span>
                                        <ol>
                                            <li *ngFor="let document of group.documents">
                                                {{document.name}}
                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </div>
                            <div class="text-muted"
                                 *ngIf="appeal.documents.length == 0">
                                {{ localizations.appeals.documents_not_added_to_process }}
                            </div>
                        </ng-container>
                    </div>
                </fieldset>
            </div>
        </evolenta-scrollbar>
    </div>
</div>
