import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestService, SelectService, StorageService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'app-person-checking-people',
    templateUrl: 'person-checking-people.component.html',
})
export class PersonCheckingPeopleComponent implements OnInit {
    @Input() public persons;
    @Output() public onSave = new EventEmitter<any>();

    public isEdit = false;
    public isEditPersons = false;
    public organizationId = this.storage.getItem('currentOrganization').id;
    public checkingPersons = [];
    public checkingPerson: any = {};
    public users;
    public localizations;

    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private selectService: SelectService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit() {
        this._loadTranslations();
        const params = {
            search: {
                search: [
                    {
                        field: 'sprOrganizations.id',
                        operator: 'eq',
                        value: this.organizationId,
                    },
                ],
            },
            size: 100,
            page: 0,
        };
        this.checkingPersons = this.persons ? cloneDeep(this.persons) : [];
        this.rest.search('users', params).then(
            result => this.users = this.selectService.transformForSelect('_id', 'name', result),
        );
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'tasks',
                'common',
                'person-checking-people',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public apply() {
        if (this.isEditPersons) {
             this.checkingPersons = this.checkingPersons.map(
                 item => item.guid === this.checkingPerson.guid ? this.checkingPerson : item,
             );
        } else {
            this.checkingPersons.push(this.checkingPerson);
        }
        this.onSave.emit(this.checkingPersons);
        this.checkingPerson = {};
        this.isEdit = false;
        this.isEditPersons = false;
    }
    public cancel() {
        this.isEdit = false;
    }

    public addCheckingPerson() {
        const guid = CommonUtilities.GenerateGuid();
        this.checkingPerson = {guid};
        this.isEdit = true;
    }

    public edit(checkingPerson) {
        this.checkingPerson = cloneDeep(checkingPerson);
        this.isEdit = true;
        this.isEditPersons = true;
    }

    public delete(idx) {
        this.checkingPersons.splice(idx, 1);
        this.onSave.emit(this.checkingPersons);
    }
}
