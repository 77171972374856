import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    MODES,
} from '../../../../common/constants';

@Component({
    selector: 'mapping-item',
    templateUrl: 'mapping-item.component.html',
    styleUrls: ['./mapping-item.component.less'],
})
export class MappingItemComponent implements OnInit {

    @Input() public item;
    @Input() public inCreate = false;

    @Output() public changed = new EventEmitter();

    public mode = MODES.VIEW;
    public oldItem;
    public localizations;

    public constructor(
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'epgu_integration',
            ],
        ).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public switchToEditMode() {
        this.oldItem = Object.assign({}, this.item);
        this.mode = MODES.EDIT;
    }

    public switchToViewMode() {
        this._checkItemChanged();
        this.oldItem = null;
        this.mode = MODES.VIEW;
    }

    public copy(param) {
        this.item[param.replace('current', 'new')] = this.item[param];
        this._checkItemChanged();
    }

    private _checkItemChanged() {
        const itemChanged = !!Object.keys(this.item).find(param => this.item[param] !== this.oldItem[param]);
        if (itemChanged) {
            this.changed.emit();
        }
    }

    public isViewMode() {
        return this.mode === MODES.VIEW;
    }

    public isEditMode() {
        return this.mode === MODES.EDIT;
    }

    public getPreviousParamsList() {
        return Object.keys(this.item).filter(item => item.indexOf('new'));
    }
}
