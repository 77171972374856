import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@evolenta/core';
import { TasksService } from '../../tasks.service';

@Component({
    selector: 'tasks-preview',
    templateUrl: 'tasks-preview.component.html',
})
export class TasksPreviewComponent implements OnInit {
    @Input() public task;
    @Input() public knoUsers;

    @Input() public mode;
    public moduleBaseUrl;
    public baseUrl;
    public appeal;
    public panels = {
        common: true,
    };

    public localizations;

    public constructor(
        public taskService: TasksService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe((urlPath) => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.route.parent.url.subscribe((parentUrl) => {
            this.baseUrl = parentUrl[0].path;
        });
        if (this.mode === 'card') {
            this.panels.common = false;
        }
    }

    public _loadTranslations() {
        this.translate.get(['common', 'tasks']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public getStatus(param) {
        return this.taskService.getStatusByProperty(this.task.priority, param);
    }

    public edit() {
        this.router.navigate([this.moduleBaseUrl, this.baseUrl, 'edit', this.task.camundaId, this.task.id]);
    }
    public navigateToTask() {
        let node;
        if (this.moduleBaseUrl === 'supervisor') {
            node = 'all-tasks';
        } else {
            node = 'tasks';
        }
        console.log([this.moduleBaseUrl, node, 'edit', this.task.camundaId, this.task.id]);
        this.router.navigate([this.moduleBaseUrl, node, 'edit', this.task.camundaId, this.task.id]);
    }

    public getAssigneeUser(assignee) {
        const find = this.knoUsers.find((item) => item.login === assignee);
        return find ? find.name : assignee;
    }
}
