<!-- Основная кнопка + глобальный поиск -->
<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button type="button"
            class="btn btn-success btn-main btn-labeled btn-labeled-right btn-xs"
            [disabled]="isOldVersion || !subject.globalSubjectId"
            (click)="save()">
        <em class="icon-checkmark-circle"></em>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            <span>
                <!-- Ссылка возврата к списку -->
                <em class="icon-arrow-left52 position-left cursor-pointer"
                    [title]="localizations.common.go_back"
                    (click)="back()"></em>
                {{ localizations.subjects.settings }}
            </span>
        </h4>
    </div>

    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>

<div class="page-container">
    <div class="sidebar sidebar-secondary sidebar-default">

        <div class="sidebar-content">
            <div class="sidebar-category"
                 *ngFor="let tab of tabs"
                 [ngClass]="{
                    'active': activeTab == tab.code,
                    'cursor-pointer' : activeTab != tab.code
                  }"
                 (click)="activeTab = tab.code">
                <div class="category-title">
                    <span>
                        {{ tab.name }}
                    </span>
                </div>
            </div>

            <div class="status-action ml-15 mr-15 mt-15"
                 *ngIf="subject?._id && !subject?.globalSubjectId">
                <button class="btn no-white-space btn-info full-width"
                        *ngIf="!controlActionsOnSubjectService.isExistSubjectKno"
                        (click)="controlActionsOnSubjectService.setControlAction('add')">
                    {{ localizations.objects.take_under_control }}
                </button>
                <button class="btn no-white-space btn-info full-width"
                        [routerLink]="['/', activatedRouteService.moduleBaseUrl,
                        (activatedRouteService.moduleBaseUrl === 'financial-controlling' ? 'objects-of-control' : 'subjects'), 'kno', 'edit', subjectKnoId]"
                        *ngIf="subjectKnoId">
                    {{ localizations.subjects.controlled }}
                </button>
            </div>

            <div class="status-action ml-15 mr-15 mt-15"
                 *ngIf="subject._id && subject.globalSubjectId">
                <button class="btn no-white-space btn-info full-width"
                        (click)="controlActionsOnSubjectService.setControlAction('remove')">
                    {{ localizations.subjects.disable_control }}
                </button>
            </div>
        </div>
    </div>
    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-margin no-padding"
                            *ngIf="activeTab !== 'versions'">
            <!-- Общие данные -->
            <ng-container *ngIf="activeTab == 'common'">
                <div class="panel panel-shadow m-20">
                    <div class="panel-body no-padding">
                        <subject-preview-card [subject]="subject.globalSubjectId && globalSubject ? globalSubject : subject"
                                              [fromAppeal]="false"
                                              [isFullCard]="true"
                                              *ngIf="subject.globalSubjectId && globalSubject || !subject.globalSubjectId">
                        </subject-preview-card>
                    </div>
                </div>

                <!-- Данные из ФНС -->
                <subject-params [type]="subject.globalSubjectId && globalSubject.specialTypeId == 'ulApplicant' || !subject.globalSubjectId && subject.specialTypeId == 'ulApplicant' ? 'UL' : 'IP'"
                                [subject]="subject.globalSubjectId && globalSubject ? globalSubject : subject"
                                *ngIf="subject.globalSubjectId && globalSubject || !subject.globalSubjectId">
                </subject-params>

            </ng-container>

            <!-- Объекты, относящиеся к субъекту -->
            <ng-container *ngIf="activeTab == 'objects'">
                <subject-objects [subject]="subject"
                                 [objects]="linkedObjects"
                                 [allowEdit]="!isOldVersion"
                                 (onChange)="changeObjectList($event)">
                </subject-objects>
            </ng-container>


            <!-- КНМ, в которых участвовал субъект -->
            <ng-container *ngIf="activeTab == 'knm'">
                <div class="alert alert-warning"
                     *ngIf="appeals.length === 0">
                    <span class="text-semibold">
                        {{ localizations.common.attention }}
                    </span>
                    {{ localizations['knd-kinds'].notifications.missing }}
                </div>
                <ng-template [ngIf]="appeals?.length > 0">
                    <div class="panel panel-shadow panel-small m-20"
                         *ngFor="let appeal of appeals">
                        <div class="panel-heading"
                             *ngIf="activatedRouteService.moduleBaseUrl !== 'financial-controlling'">
                            <h6 class="panel-title text-bold">
                                <span class="text-slate mr-10"
                                      *ngIf="appeal.shortNumber">
                                    {{ appeal.shortNumber }}
                                </span>
                                <span class="mr-10">
                                    {{ appeal.subservice.shortTitle }}
                                </span>
                                <span class="badge text-uppercase"
                                      [ngClass]="getAppealStatusProperty(appeal.status.code, 'background')">
                                    {{ getAppealStatusProperty(appeal.status.code, 'shortName') }}
                                </span>
                            </h6>
                            <div class="heading-elements">
                                <button type="button"
                                        class="btn btn-success-overline heading-btn btn-labeled pt-10 pb-10"
                                        [routerLink]="['/inspector/appeals/edit/', appeal._id]"
                                        [title]="localizations.standards.additional_data_settings">
                                    {{ localizations.common.proceed }}
                                </button>
                            </div>
                        </div>
                        <div class="panel-heading"
                             *ngIf="activatedRouteService.moduleBaseUrl === 'financial-controlling'">
                            <div class="row">
                                <div class="col-md-12 mb-10">
                                    <div class="mr-10">
                                        <p class="no-margin ">
                                            <span class="mr-10">
                                                <span class="text-bold text-size-large mr-10">
                                                    {{ localizations.subjects.check_period_from }}:
                                                </span>
                                                {{ appeal.dateRegister | date: 'shortDate' }}
                                            </span>
                                            <span>
                                                <span class="text-bold text-size-large mr-10">
                                                    {{ localizations.common.till }}:
                                                </span>
                                                {{ appeal.datePlaneFinish | date: 'shortDate' }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-10 display-flex">
                                    <p class="text-bold no-margin text-size-large mr-10">
                                        {{ localizations.subjects.control_event_type }}:
                                    </p>
                                    <ng-template [ngIf]="appeal?.subservice?.shortTitle"
                                                 [ngIfElse]="notSpecified">
                                        {{ appeal.subservice.shortTitle }}
                                    </ng-template>
                                </div>
                                <div class="col-md-12 mb-10 display-flex">
                                    <p class="text-bold no-margin text-size-large mr-10">
                                        {{ localizations.subjects.control_actions_end_date }}:
                                    </p>
                                    <ng-template [ngIf]="appeal?.dateFinish"
                                                 [ngIfElse]="notSpecified">
                                        {{ appeal.dateFinish | date: 'shortDate' }}
                                    </ng-template>
                                </div>
                            </div>

                            <div class="heading-elements">
                                <button type="button"
                                        class="btn btn-success-overline heading-btn btn-labeled pt-10 pb-10"
                                        [routerLink]="['/inspector/appeals/edit/', appeal._id]"
                                        [title]="localizations.standards.additional_data_settings">
                                    {{ localizations.common.proceed }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </evolenta-scrollbar>

        <!-- Версии субъекта -->
        <ng-container *ngIf="subject._id && activeTab === 'versions'">
            <subject-versions [subject]='subject' #subjectVersions>
            </subject-versions>
        </ng-container>
    </div>
</div>

<can-deactivate-modal></can-deactivate-modal>

<subject-dialog-for-control-actions [event]="controlActionsOnSubjectService.getControlAction()"
                                    *ngIf="controlActionsOnSubjectService.getControlAction()">
</subject-dialog-for-control-actions>

<ng-template #notSpecified>
    <span class="text-muted">
        {{ localizations.common.not_specified }}
    </span>
</ng-template>
