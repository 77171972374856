import { Injectable } from '@angular/core';
import { RestService, StorageService } from '@evolenta/core';
import { ObjectUtilities, PrintUtilities } from '@evolenta/utilities';
import { PrintUtilityService } from './print-utility.service';
import { PrintObjectsService } from './print-objects.service';
import { PrintReglamentService } from './print-reglament.service';
import { PrintDocumentsService } from './print-documents.service';
import { AppealData } from '../../../modules/knm/appeals/appeal.data';
import { AppealService } from '../../../modules/knm/appeals/appeal.service';
import * as moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';
import defaultTo from 'lodash-es/defaultTo';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';

@Injectable()
export class PrintAppealService {
    public entityTypes = AppealData.entityTypes;
    private xsdDataList: any[];
    private EXCLUDE_FIELDS = new Set();

    public constructor(
        private printUtilityService: PrintUtilityService,
        private printObjectsService: PrintObjectsService,
        private printReglamentService: PrintReglamentService,
        private printDocumentsService: PrintDocumentsService,
        private storage: StorageService,
        private restService: RestService,
        private appealService: AppealService,
    ) {
        this.EXCLUDE_FIELDS.add('auid');
        this.EXCLUDE_FIELDS.add('dateCreation');
        this.EXCLUDE_FIELDS.add('dateLastModification');
        this.EXCLUDE_FIELDS.add('entityType');
        this.EXCLUDE_FIELDS.add('guid');
        this.EXCLUDE_FIELDS.add('parentEntries');
        this.EXCLUDE_FIELDS.add('userCreation');
        this.EXCLUDE_FIELDS.add('id');
        this.EXCLUDE_FIELDS.add('_id');
        this.EXCLUDE_FIELDS.add('userLastModification');

    }

    // @ts-ignore
    /**
     * Присваивание типа для всех полей у объекта
     * @param baseData - объект для установки типов
     * @param subservice - услуга по которым формируется дело
     * @param collectionName - название внешнего объекта переменной
     */
    protected _setTypes(baseData: any, subservice, collectionName?: string): void {
        if (Array.isArray(baseData)) {
            baseData = {
                _type: 'array',
                [collectionName]: baseData.map(item => typeof item === 'object'
                    ? this._setTypes(item, subservice)
                    : this._getBaseData(item, collectionName)),
            };
        // добавлено по PPGMUD-1611
        } else if (baseData) {
            if ((typeof baseData) === 'object') {
                Object.keys(baseData).forEach((name: string) => {
                    // Исключаются все поля, в которых есть символ "_", для корректного вывода значения в атрибут
                    const isAttribute = name.substring(0, 1).includes('_');

                    // Используется сравнение с undefined, а не просто baseData[name], чтобы, baseData[name] === false прошёл
                    if (baseData[name] !== undefined && !isAttribute) {
                        baseData[name] = this._getBaseData(baseData[name], name);
                        // Вызывается рекурсия для дальнейшего разбора объекта на типы
                        if (!baseData[name].__text) {
                            this._setTypes(
                                baseData[name]._type === 'array'
                                    ? baseData[name][Object.entries(baseData[name]).find(entry => isArray(entry[1]))[0]]
                                    : baseData[name],
                                subservice,
                                name,
                            );
                        }
                    }
                });
            } else {
                baseData = this._getBaseData(baseData, collectionName);
            }
        } else {
            console.error('Некорректное значение', baseData);
        }
    }

    /**
     * Получение объекта с типом данных и самими значениями
     * @param baseData - объект для сбора
     * @param name - название поля(в коде)
     * @return вновь собранный объект
     */
    private _getBaseData(baseData: any, name?: string): any {
        if (baseData && typeof baseData === 'object') {
            const entries = Object.entries(baseData);
            const findObject = entries.find(entry => isArray(entry[1]));

            if (entries.length === 1 && findObject) {
                const findNameByObject = this._getName(findObject[0]);

                return findNameByObject
                    ? { _type: 'array', [findObject[0]]: findObject[1], _name: findNameByObject }
                    : { _type: 'array', [findObject[0]]: findObject[1] };
            }
            const findName = this._getName(name);

            return Object.assign(baseData, baseData.__text
                ? (findName ? { _type: this.getType(baseData.__text), _name: this._getName(name) } : { _type: this.getType(baseData.__text) })
                : { _type: 'object' });
        } else {
            const displayName = this._getName(name);

            return displayName ? { _type: this.getType(baseData), __text: baseData, _name: displayName }
                : { _type: this.getType(baseData), __text: baseData };
        }
    }

    protected getType(baseData) {
        return baseData !== true && baseData !== false && (Number(baseData) === 0 || Number(baseData))
            ? typeof Number(baseData) : (Date.parse(baseData) ? 'date' : typeof baseData);
    }

    /**
     * Метод для получения названия поля(выводимого для пользователя)
     * @param name - название поля(в коде)
     * @return название поля(выводимого для пользователя)
     */
    private _getName(name: string): string {
        let displayName = null;

        this.xsdDataList.find(parent => {
            const findXsdContent = parent.xsdContent.find(xsd => xsd.options && xsd.options.name === name);

            displayName = findXsdContent ? findXsdContent.options.displayName : this._getNameFromXsdData(parent.xsdContent, name);

            return displayName;
        });

        return displayName;
    }

    /**
     * Метод для разбора объекта и поиска в нем названия поля(выводимого для пользователя)
     * @param xsdData - объект в котором будет искаться названия поля(выводимого для пользователя)
     * @param name - название поля(в коде)
     * @return название поля(выводимого для пользователя) или объект в котором оно ищется(актуально для вызова в рекурсии)
     */
    private _getNameFromXsdData(xsdData: any, name: string): any {
        const findXsdOptionsName = Array.isArray(xsdData) ? xsdData.find(xsd => xsd.options && xsd.options.name === name) : xsdData.options;

        if (findXsdOptionsName) {
            return findXsdOptionsName.options.displayName;
        } else {
            let returnName;

            if (Array.isArray(xsdData)) {
                const findXsdWithContents = xsdData.filter(xsd => xsd.content);

                // проверка content, так как например для полей типа fieldSet названия хранятся в нем, для остальных в options
                if (!findXsdWithContents.length) {
                    const findXsdContentsWithOutOptions = xsdData.filter(xsd => !xsd.options);

                    findXsdContentsWithOutOptions.find(xsdContent => {
                        if (Array.isArray(xsdContent) && !isEmpty(xsdContent)) {
                            // Проходимся по объектам для просмотра в них названия поля, если здесь нет то идем внутрь и так до конца
                            let temporaryName = xsdContent.find(xsd => xsd.options && xsd.options.name === name);

                            if (temporaryName) {
                                returnName = temporaryName.options.displayName;

                                return;
                            } else {
                                temporaryName = this._getNameFromXsdData(xsdContent, name);

                                if (temporaryName) {
                                    returnName = temporaryName;

                                    return;
                                }
                            }
                        } else if (typeof xsdContent === 'object' && xsdContent) {
                            // В случае если выпал объект то разбираем его и ищем название поля внутри
                            const temporaryName = this._getNameFromXsdData(xsdContent, name);

                            if (temporaryName) {
                                returnName = temporaryName;

                                return;
                            }
                        }
                    });
                } else {
                    // Разбор объектов у которых есть content. Используется find() чтобы можно было остановить цикл
                    findXsdWithContents.find(xsdContent => {
                        const temporaryName: any = this._getNameFromXsdData(xsdContent.content, name);

                        if (temporaryName) {
                            returnName = temporaryName;

                            return;
                        }
                    });
                }

                return returnName;
            } else {
                // В случае если пришел объект то смотрим его переменные и ищем среди них название поля.
                // Если нет, то отправляем список его полей для поиска внутри них
                returnName = Object.entries(xsdData).find((xsd: [string, any]) => xsd[1].options && xsd[1].options.name === name);

                if (returnName) {
                    return returnName;
                }

                return this._getNameFromXsdData(Object.entries(xsdData).map(entry => entry[1]), name);
            }
        }
    }

    /**
     * Метод для преобразования объекта в xml с учетом типов данных
     * @param checkBaseData - объект для преобразования
     * @param subservice - массив описаний услуг по которым формируется дело
     * @param name - название принимаемого поля
     */
    private async _prepareBaseData(checkBaseData: any, subservice, name: string) {
        const xsdIds = [];

        if (typeof checkBaseData === 'object' && !isArray(checkBaseData)) {
            subservice.xsdTabs.forEach(tab => {
                Object.keys(checkBaseData).forEach(key => {
                    if (tab.tabCode === key) {
                        xsdIds.push(tab.xsd);
                    }
                });
            });
        }

        if (subservice.xsd) {
            xsdIds.push(subservice.xsd);
        }

        if (checkBaseData && checkBaseData.xsd) {
            xsdIds.push(checkBaseData.xsd);
        }

        if (isArray(checkBaseData) && !isEmpty(checkBaseData)) {
            checkBaseData.forEach(entity => {
                if (entity.xsd) {
                    xsdIds.push(entity.xsd);
                }
            });
        }

        if (subservice.objects && subservice.objects.objectsData) {
            if (subservice.objects.objectsData.mainXsd) {
                xsdIds.push(subservice.objects.objectsData.mainXsd);
            }

            if (subservice.objects.objectsData.xsd) {
                xsdIds.push(subservice.objects.objectsData.xsd);
            }

            if (subservice.objects.objectsData.subjectsData) {
                if (subservice.objects.objectsData.subjectsData.mainXsd) {
                    xsdIds.push(subservice.objects.objectsData.subjectsData.mainXsd);
                }

                if (subservice.objects.objectsData.subjectsData.xsd) {
                    xsdIds.push(subservice.objects.objectsData.subjectsData.xsd);
                }
            }
        }

        const params = [{
            field: '_id',
            operator: 'in',
            value: xsdIds,
        }];

        this.xsdDataList = await this.restService.search('xsd', { search: { search: params } });

        await this._setTypes(checkBaseData, subservice, name);
    }

    /**
     * Формирует информацию по делу для печатной формы BaseXML
     * @param appeal - дело
     * @param subservice - массив описаний услуг по которым формируется дело
     * @param document - документ для печати которого формируются данные
     * @param printForm - печатная форма
     * @param selectedDocuments - массив выбранных для обработки документов (печатная форма доноса документов и др.)
     * @param applicantGuid - GUID объекта заявителя (доверителя) - для формирования данных для комплексного дела
     * @param processElement
     * @returns {any}
     */
    public async prepareAppealData(appeal, subservice, document, printForm = null, selectedDocuments = [], applicantGuid = null, processElement = null) {
        let activeService = null; // активная услуга
        let appealSubservice = null; // описание услуги в деле
        let isComplexPrintForm = true; // формирование данных для комплексной печатной формы (не по одной услуге, а по всем в комплексном деле)
        if (subservice) {
            // Если дело создано по одной услуге
            activeService = subservice;
            appealSubservice = appeal.subservice;
            isComplexPrintForm = false;
        } else if (document && document.subserviceGuid && appeal.subservice.guid === document.subserviceGuid) {
            // Определение активной услуги по документу, для которого печатная форма формируется
            appealSubservice = appeal.subservice;
            activeService = subservice;
            isComplexPrintForm = false;
        } else if (printForm && printForm.serviceIds.length > 0) {
            // Информация об активных услугах берется из блока ServiceIds для печатной формы
            const printFormActiveServices = [];
            printForm.serviceIds.forEach(serviceId => {
                if (subservice.serviceId === serviceId) {
                    printFormActiveServices.push(subservice);
                }
            });
            if (printFormActiveServices.length === 1 && appeal.subservice.guid === document.subserviceGuid) {
                activeService = printFormActiveServices[0];
                // Берется первая соответствующая serviceId (не универсальный вариант, возможна ошибка при использовании одинаковых услуг в комплексном деле)
                appealSubservice = appeal.subservice;
                isComplexPrintForm = false;
            }
        }
        const currentOrg: any = this.storage.getItem('currentOrganization'); // текущая организация, под которой работает пользователь
        const currentUser: any = this.storage.getItem('user'); // текущий пользователь / оператор

        const application: any = {
            Application: {
                Service: null,
                ServiceShortName: null,
                ServiceId: null,
                ClassificSubserviceId: null,
                ClassificSubserviceName: null,
                OrgRepresentative: null,
                DealID: appeal.number ? appeal.number : null, // ?
                DealShortNumberBAR: appeal.shortNumber ? appeal.shortNumber : null, // 8 символов DealID
                PIN: appeal.pin ? appeal.pin : '',
                Applicants: null,
                Documents: null,
                Entities: null,
                MissingDocuments: null,
                SelectedDocuments: {},
                PortalCreateTime: appeal.portalCreateTime ? appeal.portalCreateTime : '',
                // данные оператора и организации
                TechnicianSignature: {
                    '_id': currentUser._id,
                    FullName: currentUser.name,
                    SNILS: currentUser.snils ? currentUser.snils : '',
                    Position: currentUser.position ? currentUser.position : 'специалист',
                    Date: this.printUtilityService.formatDateForPrintForm(null, false, null, true),
                    DepartmentId: currentOrg.code ? currentOrg.code : currentOrg.id ? currentOrg.id : '',
                    Department: currentOrg.name ? currentOrg.name : '',
                    DateTime: this.printUtilityService.formatDateForPrintForm(null, true, null, true),
                    DateTimePlus20Min: this.printUtilityService.formatDateForPrintForm(null, true, {
                        sign: '+',
                        number: 20,
                        param: 'm',
                    }, true),
                    WhereRegistered: currentOrg.name ? currentOrg.name : '',
                    WhoRegistered: appeal.userCreation ? appeal.userCreation.name : currentUser.name,
                    AppealWayToGet: 'Лично',
                    AppealDateGet: '',
                    TZ: this.printUtilityService.getFormattedTimeZone(),
                },
                MaxTerm: null, // '21 дней', // откуда взять? это максимальный срок
                ReturnDate: null,
                RegistrationDate: appeal.dateRegister ? this.printUtilityService.formatDateForPrintForm(appeal.dateRegister, true) : '',
                FinishDate: appeal.dateFinish ? this.printUtilityService.formatDateForPrintForm(appeal.dateFinish, true) : '',
                PlaneFinishDate: appeal.datePlaneFinish ? this.printUtilityService.formatDateForPrintForm(appeal.datePlaneFinish, true) : '',
                StartDate: appeal.dateStart ? moment({
                    year: appeal.dateStart.date.year,
                    month: appeal.dateStart.date.month - 1,
                    day: appeal.dateStart.date.day,
                }).format('DD.MM.YYYY') : '',
                EndDate: appeal.dateEnd ? moment({
                    year: appeal.dateEnd.date.year,
                    month: appeal.dateEnd.date.month - 1,
                    day: appeal.dateEnd.date.day,
                }).format('DD.MM.YYYY') : '',
                Purpose: appeal.purpose ? appeal.purpose : '',
                DealProblems: null,
                DealComment: appeal.comment ? appeal.comment : '',
                Reglament: null,
                IsNoFullDocs: !!appeal.incompleteSetOfDocuments,
                GovAgency: null,
                GovAgencyId: null,
                Chancellery: null,
                ChancelleryId: null,
                IssueResultForm: 'В данном подразделении',
                SelectedIssueResultForm: 6,
                IssueIn: null,
                IssueInText: null,
                SelectedConfigData: null,
                SelectedServiceCategory: null,
                RejectReasonName: null,
                Requirements: null,
                CheckLists: this.prepareCheckLists(appeal),
            },
        };

        if (activeService && appealSubservice) {
            // Если есть активная (одна) услуга
            application.Application.Service = activeService.titles.title;
            application.Application.ServiceShortName = activeService.titles.shortTitle;
            application.Application.ServiceId = activeService.serviceId;
            application.Application.ClassificSubserviceId = activeService.classificSubserviceId ? activeService.classificSubserviceId : null;
            application.Application.ClassificSubserviceName = activeService.classificSubserviceName ? activeService.classificSubserviceName : null;
            application.Application.Applicants = {Person: this.printObjectsService.prepareAppealPersons(appeal, activeService, appealSubservice)};
            application.Application.Objects = {Object: this.printObjectsService.prepareAppealObjects(appeal, activeService, appealSubservice)};
            application.Application.Documents = {Document: this.printDocumentsService.prepareAppealDocuments(appeal, activeService, appealSubservice)};
            application.Application.Entities = this.prepareEntities(appeal, activeService, appealSubservice, processElement);
            application.Application.MissingDocuments = {MissingDocument: this.printDocumentsService.prepareAppealMissingDocuments(appeal, appealSubservice)};
            application.Application.Requirements = {Requirement: this.generateRequirementsData(appeal, activeService)};
            application.Application.Reglament = this.printReglamentService.prepareReglament(activeService);
            application.Application.SelectedServiceCategory = this._generateVariantsStructure(appealSubservice.variant, activeService);
            if (application.Application.SelectedServiceCategory.RguProcedureId) {
                application.Application.Reglament.RguProcedureId = application.Application.SelectedServiceCategory.RguProcedureId;
                application.Application.Reglament.RguServiceTitle = application.Application.SelectedServiceCategory.RguServiceTitle;
                application.Application.Reglament.RguTargetId = application.Application.SelectedServiceCategory.RguTargetId;
                application.Application.Reglament.RguTargetTitle = application.Application.SelectedServiceCategory.RguTargetTitle;
            }

            application.Application.GovAgency = appeal.subservice.responsibleOrganization ? appeal.subservice.responsibleOrganization.name : '';
            application.Application.GovAgencyId = appeal.subservice.responsibleOrganization && appeal.subservice.responsibleOrganization.code ? appeal.subservice.responsibleOrganization.code : '';
            if (appealSubservice.xsdData) {
                application.Application.XsdData = cloneDeep(appealSubservice.xsdData);
                await this._prepareBaseData(application.Application.XsdData, subservice, '');
            }
            if (appealSubservice.variantXsdData) {
                application.Application.VariantXsdData = appealSubservice.variantXsdData;
            }
            if (appealSubservice.issueResultForm && appealSubservice.issueResultForm === 'otherUnit') {
                application.Application.IssueResultForm = 'В ОГВ';
                application.Application.SelectedIssueResultForm = 5;
                application.Application.IssueIn = appealSubservice.responsibleOrganization.id;
                application.Application.IssueInText = appealSubservice.responsibleOrganization.text;
            }
            if (appealSubservice.datePlaneFinish) {
                application.Application.PlaneFinishDate = this.printUtilityService.formatDateForPrintForm(appealSubservice.datePlaneFinish, true);
            }
            if (appealSubservice.dateRegister) {
                application.Application.RegistrationDate = this.printUtilityService.formatDateForPrintForm(appealSubservice.dateRegister, true);
            }
            if (appealSubservice.dateFinish) {
                application.Application.FinishDate = this.printUtilityService.formatDateForPrintForm(appealSubservice.dateFinish, true);
            }
            if (appeal.inspectors && Array.isArray(appeal.inspectors)) {
                application.Application.Inspectors = appeal.inspectors.map(item => {
                    return {
                        Name: item.name ? item.name : '',
                        Position: item.position ? item.position : '',
                    };
                });
            }
            if (appeal.experts && Array.isArray(appeal.experts)) {
                application.Application.Experts = appeal.experts.map(item => {
                    return {
                        Name: item.name ? item.name : '',
                        Position: item.position ? item.position : '',
                        Organization: item.organization ? item.organization : '',
                        AccreditationOrganization: item.accreditationOrganization ? item.accreditationOrganization : '',
                    };
                });
            }
            if (appealSubservice.kndInfo && appealSubservice.kndInfo.basisKnm && Array.isArray(appealSubservice.kndInfo.basisKnm)) {
                application.Application.BasisKnm = appealSubservice.kndInfo.basisKnm.map(item => item.name);
            }
            if (appealSubservice.kndInfo && appealSubservice.kndInfo.npa && Array.isArray(appealSubservice.kndInfo.npa)) {
                application.Application.Npa = appealSubservice.kndInfo.npa.map(item => item.name);
            }
            if (appealSubservice.rgu) {
                application.Application.RguServices = {RguService: this._getRguData(appealSubservice)};
            }
            if (appealSubservice.mainElement) {
                const mainElement = cloneDeep(appealSubservice.mainElement);
                if (mainElement.currentRegisterId) {
                    mainElement.RegistryUrlLQR = this.prepareRegistryURLQR(appealSubservice.mainElement.currentRegisterId, appealSubservice.mainElement.registryUrlForQR);
                }
                this.correctDates(mainElement);
                application.Application.MainElement = mainElement;
            }
            if (appealSubservice.mainElements) {
                application.Application.MainElements = {MainElement: cloneDeep(appealSubservice.mainElements)};
                await this._prepareBaseData(application.Application.MainElements, subservice, '');
            }
        } else if (isComplexPrintForm) {
            // Если формируются данные для дела по комплексной услуге
            application.Application.Applicants = {Person: this.printObjectsService.prepareAppealPersons(appeal)};
            application.Application.Documents = {Document: this.printDocumentsService.prepareAppealDocuments(appeal)};
            application.Application.Services = {Service: this._prepareComplexServices(appeal, subservice)};
            if (applicantGuid) {
                this._generateApplicantIdDataInComplexAppeal(application.Application, appeal, applicantGuid);
            }
        }

        // Формирование данных для платежной квитанции
        if (document && document.payment) {
            application.Application.Payment = this.generatePaymentData(appeal, document);
        }

        // Данные консультации
        if (appeal.status.code === 'consultation') {
            application.Application.Consultation = {
                ConsultationNumber: appeal.consultationNumber,
                ConsultationDate: appeal.dateConsultation,
                ConsultationReason: appeal.consultationReason,
                ConsultationResult: appeal.consultationResult,
            };
        }
        if (selectedDocuments && selectedDocuments.length > 0) {
            application.Application.SelectedDocuments = {DocId: this.printDocumentsService.prepareSelectedDocuments(selectedDocuments)};
        }

        return application;
    }

    private _getRguData(appealSubservice) {
        const items = [];
        if (appealSubservice.rgu && appealSubservice.rgu.length > 0) {
            appealSubservice.rgu.forEach(item => {
                items.push({ServiceId: item.rguServiceId, ServiceName: item.rguServiceName});
            });
        }

        return items;
    }

    public prepareCheckLists(appeal) {

        const checkLists = [];
        if (appeal.checkLists && appeal.checkLists.length > 0) {
            appeal.checkLists.forEach(checkListData => {
                const subject = appeal.subjects.find(item => item.guid === checkListData.subjectGuid);
                const object = appeal.objects.find(item => item.guid === checkListData.objectGuid);
                const checkList = {
                    Npa: null,
                    SubjectName: subject.header,
                    ObjectName: object.name,
                    Questions: [],
                };
                if (checkListData.npaLink) {
                    checkList.Npa = {
                        Name: checkListData.npaLink.name,
                        Code: checkListData.npaLink.code,
                    };
                }
                checkListData.questions.forEach(question => {
                    const questionToExport = {};

                    const formatExportName = sourceName => {
                        return sourceName.charAt(0).toUpperCase() + sourceName.slice(1);
                    };

                    const exportObject = (source, target) => {
                        if (source) {
                            Object.keys(source).forEach(sourceProperty => {
                                if (!this.EXCLUDE_FIELDS.has(sourceProperty)) {
                                    if (Array.isArray(source[sourceProperty])) {
                                        target[formatExportName(sourceProperty)] = [];
                                        source[sourceProperty].forEach(arrayItem => {
                                            const arrayItemToExport = {};
                                            exportObject(arrayItem, arrayItemToExport);
                                            target[formatExportName(sourceProperty)].push(arrayItemToExport);
                                        });
                                    } else if (typeof source[sourceProperty] === 'object') {
                                        const propertyToExport = {};
                                        exportObject(source[sourceProperty], propertyToExport);
                                        target[formatExportName(sourceProperty)] = propertyToExport;
                                    } else {
                                        target[formatExportName(sourceProperty)] = source[sourceProperty];
                                    }
                                }
                            });
                        }
                    };

                    exportObject(question, questionToExport);
                    checkList.Questions.push(questionToExport);
                });
                checkLists.push(checkList);
            });
        }

        return checkLists;
    }

    public prepareEntities(appeal, subservice, appealSubservice, processElement) {
        const entities = [];
        if (appealSubservice && appealSubservice.entities && appealSubservice.entities.length > 0) {
            appealSubservice.entities.forEach(entity => {
                switch (entity.type) {
                    // Распоряжение
                    case this.entityTypes.order.code:
                        entities.push({Order: this.prepareEntityOrder(entity, appeal)});
                        break;
                    // Акт КНМ
                    case this.entityTypes.act.code:
                        entities.push({Act: this.prepareEntityAct(entity, appeal)});
                        break;
                    // Уведомление субъекту
                    case this.entityTypes.notification.code:
                        entities.push({Notification: this.prepareEntityNotification(entity, appeal)});
                        break;
                    // Акт о невозможности проведения проверки
                    case this.entityTypes.actNotImpossible.code:
                        entities.push({ActNotImpossible: this.prepareEntityActNotImpossible(entity, appeal)});
                        break;
                    // Мотивированный запрос
                    case this.entityTypes.requestMotivated.code:
                        entities.push({RequestMotivated: this.prepareEntityRequestMotivated(entity, appeal)});
                        break;
                    // Требования о представлении пояснений
                    case this.entityTypes.requirementExplanation.code:
                        entities.push({RequirementExplanation: this.prepareEntityRequirementExplanation(entity, appeal)});
                        break;
                    // Предписание
                    case this.entityTypes.regulation.code:
                        entities.push({Regulation: this.prepareEntityRegulation(entity, appeal)});
                        break;
                    // Заявление о согласовании проведения внеплановой выездной проверки
                    case this.entityTypes.checkStatement.code:
                        entities.push({CheckStatement: this.prepareEntityCheckStatement(entity, appeal)});
                        break;
                    // Направление на экспертизу
                    case this.entityTypes.checkExamination.code:
                        entities.push({CheckExamination: this.prepareEntityCheckExamination(entity, appeal)});
                        break;
                    // Приказ (распоряжение) КЗ
                    case this.entityTypes.buyOrder.code:
                        entities.push({BuyOrder: this.prepareEntityBuyOrder(entity, appeal)});
                        break;
                    // Акт о КЗ
                    case this.entityTypes.buyAct.code:
                        entities.push({BuyAct: this.prepareEntityBuyAct(entity, appeal)});
                        break;
                    // Акт о дистанционной КЗ
                    case this.entityTypes.buyActDistance.code:
                        entities.push({BuyActDistance: this.prepareEntityBuyActDistance(entity, appeal)});
                        break;
                    // Заявление (извещение) прокуратуре о КЗ
                    case this.entityTypes.buyNotice.code:
                        entities.push({BuyNotice: this.prepareEntityBuyNotice(entity, appeal)});
                        break;
                    // Организационно-распорядительный документ (Систематическое наблюдение)
                    case this.entityTypes.observationDocument.code:
                        entities.push({ObservationDocument: this.prepareEntityObservationDocument(entity, appeal)});
                        break;
                    // Ответ субъекту на возражения (Систематическое наблюдение)
                    case this.entityTypes.observationAnswer.code:
                        entities.push({ObservationAnswer: this.prepareEntityObservationAnswer(entity, appeal)});
                        break;
                    // Предписание (Систематическое наблюдение)
                    case this.entityTypes.observationPrescription.code:
                        entities.push({ObservationPrescription: this.prepareEntityObservationPrescription(entity, appeal)});
                        break;
                    // Предостережение о недопустимости нарушения обязательных требований (Систематическое наблюдение)
                    case this.entityTypes.observationWarning.code:
                        entities.push({ObservationWarning: this.prepareEntityObservationWarning(entity, appeal)});
                        break;
                    // Запрос документов и сведений (Систематическое наблюдение)
                    case this.entityTypes.observationRequest.code:
                        entities.push({ObservationRequest: this.prepareEntityObservationRequest(entity, appeal)});
                        break;
                    // Уведомление субъекта о систематическом наблюдении (Систематическое наблюдение)
                    case this.entityTypes.observationNotify.code:
                        entities.push({ObservationNotify: this.prepareEntityObservationNotify(entity, appeal)});
                        break;
                    // Акт систематического наблюдения (Систематическое наблюдение)
                    case this.entityTypes.observationAct.code:
                        entities.push({ObservationAct: this.prepareEntityObservationAct(entity, appeal)});
                        break;
                    // Задание (Рейд)
                    case this.entityTypes.raidTask.code:
                        entities.push({RaidTask: this.prepareEntityRaidTask(entity, appeal)});
                        break;
                    // Акт результатов планового (рейдового)  осмотра, обследования (Рейд)
                    case this.entityTypes.raidAct.code:
                        entities.push({RaidAct: this.prepareEntityRaidAct(entity, appeal)});
                        break;
                    // Предостережение о недопустимости нарушения обязательных требований (Рейд)
                    case this.entityTypes.raidWarning.code:
                        entities.push({RaidWarning: this.prepareEntityRaidWarning(entity, appeal)});
                        break;
                    // Ответ субъекту на возражения (Рейд)
                    case this.entityTypes.raidAnswer.code:
                        entities.push({RaidAnswer: this.prepareEntityRaidAnswer(entity, appeal)});
                        break;
                    // Определение о возбуждении дела об АП (Административное производство)
                    case this.entityTypes.proceedInitiation.code:
                        entities.push({ProceedInitiation: this.prepareEntityProceedInitiation(entity, appeal)});
                        break;
                    // Определение о проведении АП (Административное производство)
                    case this.entityTypes.proceedConducting.code:
                        entities.push({ProceedConducting: this.prepareEntityProceedConducting(entity, appeal)});
                        break;
                    // Определение об истребовании сведений (Административное производство)
                    case this.entityTypes.proceedInformation.code:
                        entities.push({ProceedInformation: this.prepareEntityProceedInformation(entity, appeal)});
                        break;
                    // Протокол об АП (Административное производство)
                    case this.entityTypes.proceedProtocol.code:
                        entities.push({ProceedProtocol: this.prepareEntityProceedProtocol(entity, appeal)});
                        break;
                    // Извещение о составлении протокола (Административное производство)
                    case this.entityTypes.proceedProtocolMakeup.code:
                        entities.push({ProceedProtocolMakeup: this.prepareEntityProceedProtocolMakeup(entity, appeal)});
                        break;
                    // Извещение о рассмотрении протокола (Административное производство)
                    case this.entityTypes.proceedProtocolReview.code:
                        entities.push({ProceedProtocolReview: this.prepareEntityProceedProtocolReview(entity, appeal)});
                        break;
                    // Определение о передачи дела об АП в суд (Административное производство)
                    case this.entityTypes.proceedReferral.code:
                        entities.push({ProceedReferral: this.prepareEntityProceedReferral(entity, appeal)});
                        break;
                    // Постановление по делу об АП (Административное производство)
                    case this.entityTypes.proceedDecree.code:
                        entities.push({ProceedDecree: this.prepareEntityProceedDecree(entity, appeal)});
                        break;
                    // Представление о принятии мер по устранению указанных причин и условий (Административное производство)
                    case this.entityTypes.proceedAction.code:
                        entities.push({ProceedAction: this.prepareEntityProceedAction(entity, appeal)});
                        break;
                    // Постановление о прекращении АП (Административное производство)
                    case this.entityTypes.proceedTermination.code:
                        entities.push({ProceedTermination: this.prepareEntityProceedTermination(entity, appeal)});
                        break;
                    // Решение прокуратуры
                    case this.entityTypes.violationDecision.code:
                        entities.push({ViolationDecision: this.prepareEntityViolationDecision(entity, appeal)});
                        break;
                    // Принятая мера
                    case this.entityTypes.violationMeasure.code:
                        entities.push({ViolationMeasure: this.prepareEntityViolationMeasure(entity, appeal)});
                        break;
                    // Ущерб охраняемой законом ценности
                    case this.entityTypes.damage.code:
                        entities.push({Damage: this.prepareEntityDamage(entity, appeal)});
                        break;
                    // Заключение о соответствии
                    case this.entityTypes.conformityConclusion.code:
                        entities.push({ConformityConclusion: this.prepareEntityConformityConclusion(entity, appeal)});
                        break;
                    // Решение об отказе в выдаче заключения о соответствии
                    case this.entityTypes.rejectConformityConclusion.code:
                        entities.push({RejectConformityConclusion: this.prepareEntityRejectConformityConclusion(entity, appeal)});
                        break;
                    default:
                        const temporaryEntity = this.prepareCustomEntity(entity, processElement);

                        Object.keys(temporaryEntity).forEach(key => {
                            if (temporaryEntity[key].xsd) {
                                this._prepareBaseData(temporaryEntity[key], [subservice], '');
                            }
                        });
                        entities.push(temporaryEntity);
                        break;
                }
            });
        }

        return entities;
    }

    public processingCurrentEntity(entity, entityData, processElement) {
        if (processElement && processElement.currentEntityGuid && processElement.currentEntityGuid === entity.guid) {
            entityData.IsCurrentEntity = true;
        }
        entityData.GUID = entity.guid;

        return entityData;
    }

    public prepareCustomEntity(entity, processElement = null) {
        const obj = {};
        obj[entity.type] = this.processingCurrentEntity(entity, cloneDeep(entity), processElement);

        return obj;
    }

    /**
     * Подготовка данных для сведения: Распоряжение
     * @param entity
     * @param appeal
     */
    public prepareEntityOrder(entity, appeal) {
        const result: any = {
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Number: entity.number ? entity.number : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            PlaceKnmType: entity.placeKnmType ? entity.placeKnmType.name : '',
            ObjectKnmType: entity.objectKnmType ? entity.objectKnmType.name : '',
            Employees: appeal.inspectors && Array.isArray(appeal.inspectors) ? appeal.inspectors.map(item => {
                return {
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : '',
                };
            }) : [],
            FaceKnmType: entity.faceKnmType ? entity.faceKnmType.name : '',
            MethodKnm: entity.methodKnm ? entity.methodKnm.name : '',
            Experts: appeal.experts && Array.isArray(appeal.experts) ? appeal.experts.map(item => {
                return {
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : '',
                    Organization: item.organization ? item.organization : '',
                    AccreditationOrganization: item.accreditationOrganization ? item.accreditationOrganization : '',
                };
            }) : [],
            PurposeKnm: entity.purposeKnm ? entity.purposeKnm.name : '',
            DocumentsApprovalUnplanAudit: entity.documentsApprovalUnplanAudit ? entity.documentsApprovalUnplanAudit.name : '',
            DocumentsApprovalPlanAudit: entity.documentsApprovalPlanAudit ? entity.documentsApprovalPlanAudit.name : '',
            DocumentType: entity.documentType && entity.documentType[0].text ? entity.documentType[0].text : '',
            RegisterBaseType: entity.registerBaseType ? entity.registerBaseType.name : '',
            DocumentRequisites: entity.documentRequisites ? entity.documentRequisites : '',
            ViolationDocument: entity.violationDocument ? entity.violationDocument : '',
            ViolationDocumentRequisites: entity.violationDocumentRequisites ? entity.violationDocumentRequisites : '',
            Objective: entity.objective ? entity.objective : '',
            Themes: entity.themes && entity.themes[0].text ? entity.themes[0].text : '',
            DateStart: entity.dateStart ? moment(entity.dateStart).format('DD.MM.YYYY') : '',
            DateEnd: entity.dateEnd ? moment(entity.dateEnd).format('DD.MM.YYYY') : '',
            LiadTimeInHourse: entity.liadTimeInHourse ? entity.liadTimeInHourse : '',
            TypeFederalLaw: entity.typeFederalLaw ? entity.typeFederalLaw.name : '',
            BasisKnm: appeal.subservice.kndInfo.basisKnm && Array.isArray(appeal.subservice.kndInfo.basisKnm) ? appeal.subservice.kndInfo.basisKnm.map(item => item.name) : [],
            MandatoryReqs: entity.mandatoryReqs ? entity.mandatoryReqs.map(item => item.value && item.value.name ? item.value.name : '') : [],
            EventKnm: entity.eventKnm && Array.isArray(entity.eventKnm) ? entity.eventKnm.map(item => {
                return {
                    Event: item.event && item.event.name ? item.event.name : '',
                    Date: item.date ? moment(item.date).format('DD.MM.YYYY') : '',
                };
            }) : [],
            Npa: appeal.subservice.kndInfo.npa && Array.isArray(appeal.subservice.kndInfo.npa) ? appeal.subservice.kndInfo.npa.map(item => item.name) : [],
            RequestedDocument: entity.requestedDocument && Array.isArray(entity.requestedDocument) ? entity.requestedDocument.map(item => item.value && item.value.name ? item.value.name : '') : [],
            EmployeeSignatory: entity.employeeSignatory ? {
                Name: entity.employeeSignatory.name ? entity.employeeSignatory.name : '',
                Position: entity.employeeSignatory.position ? entity.employeeSignatory.position : '',
                Phone: entity.employeeSignatory.mobile ? entity.employeeSignatory.mobile : '',
                Email: entity.employeeSignatory.email ? entity.employeeSignatory.email : '',
            } : {},
            EmployeeAuthor: entity.employeeAuthor ? {
                Name: entity.employeeAuthor.name ? entity.employeeAuthor.name : '',
                Position: entity.employeeAuthor.position ? entity.employeeAuthor.position : '',
                Phone: entity.employeeAuthor.mobile ? entity.employeeAuthor.mobile : '',
                Email: entity.employeeAuthor.email ? entity.employeeAuthor.email : '',
            } : {},
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Акт КНМ
     * @param entity
     * @param appeal
     */
    public prepareEntityAct(entity, appeal) {
        const result: any = {
            CreatePlace: entity.createPlace ? PrintUtilities.PrintAddressAsLine(entity.createPlace, true) : '',
            CreateDate: entity.createDate ? moment(entity.createDate).format('DD.MM.YYYY') : '',
            CreateHours: entity.createHours ? entity.createHours : '',
            CreateMinutes: entity.createMinutes ? entity.createMinutes : '',
            Number: entity.number ? entity.number : '',
            Places: entity.places && Array.isArray(entity.places) ? entity.places.map(item => {
                return {
                    Place: item.place ? PrintUtilities.PrintAddressAsLine(item.place, true) : '',
                    Date: item.date ? moment(item.date).format('DD.MM.YYYY') : '',
                    StartHours: item.startHours ? item.startHours : 0,
                    StartMinutes: item.startMinutes ? item.startMinutes : 0,
                    EndHours: item.endHours ? item.endHours : 0,
                    EndMinutes: item.endMinutes ? item.endMinutes : 0,
                    Period: item.period ? item.period : 0,
                };
            }) : [],
            WorkIntervals: entity.workIntervals && entity.workIntervals.length > 0 ? entity.workIntervals.map(item => {
                return {
                    Date: item.date ? moment(item.date).format('DD.MM.YYYY') : '',
                    Hours: item.hours ? item.hours : 0,
                    Minutes: item.minutes ? item.minutes : 0,
                };
            }) : [],
            PeriodTotal: entity.periodTotal ? entity.periodTotal : '',
            PeriodMeasure: entity.periodType && entity.periodType.text ? entity.periodType.text : '',
            SubjectPersons: entity.subjectPersons && entity.subjectPersons.length > 0 ? entity.subjectPersons.map(item => {
                return {
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : '',
                };
            }) : [],
            OtherPersons: entity.otherPersons && Array.isArray(entity.otherPersons) ? entity.otherPersons.map(item => {
                return {
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : '',
                };
            }) : [],
            Results: entity.results && entity.results.length > 0 ? entity.results.map(item => {
                return {
                    ResultContent: item.content ? item.content : '',
                    ResultType: item.type ? item.type.name : '',
                };
            }) : [],
            Violations: entity.violations && Array.isArray(entity.violations) ? entity.violations.map(item => {
                return {
                    ID: item.guid ? item.guid : null,
                    Npa: item.mandatoryReq && item.mandatoryReq.nPA ? item.mandatoryReq.nPA.name : '',
                    MandatoryReq: !item.mandatoryReq ? null : {
                        Code: item.mandatoryReq.code,
                        Name: item.mandatoryReq.name,
                    },
                    ViolationType: item.type ? item.type.name : '',
                    ViolationContent: item.content ? item.content : '',
                    // ViolationNature: item.violationNature ? item.violationNature : '',
                    // Character: item.violation.character ? item.violation.character : '',
                    // InfringerType: item.violation.infringerType ? item.violation.infringerType[0].text : '',
                    // InfringerName: item.violation.infringerName ? item.violation.infringerName : '',
                    // InfringerPosition: item.violation.infringerPosition ? item.violation.infringerPosition : '',
                    // InfringerIndividual: item.violation.infringerIndividual ? item.violation.infringerIndividual : '',
                    // InfringerLegal: item.violation.infringerLegal ? item.violation.infringerLegal : '',
                };
            }) : [],
            // Characters: entity.characters && Array.isArray(entity.characters) ? entity.characters.map(item => {
            //     return {
            //         Comment: item.comment ? item.comment : '',
            //         Requirement: item.requirement ? item.requirement.name : ''
            //     }
            // }) : [],
            InformationAuditLog: entity.informationAuditLog && Array.isArray(entity.informationAuditLog) ? entity.informationAuditLog.map(item => item.text ? item.text : '') : [],
            // InformationFamiliarization: entity.informationFamiliarization ? entity.informationFamiliarization : false,
            Materials: entity.materials && Array.isArray(entity.materials) ? entity.materials.map(item => item.text ? item.text : '') : [],
            // PrescriptionRequisites: entity.prescriptionRequisites ? entity.prescriptionRequisites : '',
            // Prescriptions: entity.prescriptions && Array.isArray(entity.prescriptions) ? entity.prescriptions.map(item => {
            //     return {
            //         Item: item.item ? item.item : '',
            //         FactFailure: item.factFailure ? item.factFailure : ''
            //     }
            // }) : [],
        };
        const order = this.findEntity(appeal, this.entityTypes.order.code);
        if (order) {
            result.OrderDate = order.date ? moment(order.date).format('DD.MM.YYYY') : '';
            result.OrderNumber = order.number ? order.number : '';
            result.OrderEmployees = appeal.inspectors && Array.isArray(appeal.inspectors) ? appeal.inspectors.map(item => {
                return {
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : '',
                };
            }) : [];
            result.OrderExperts = appeal.experts && Array.isArray(appeal.experts) ? appeal.experts.map(item => {
                return {
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : '',
                    Organization: item.organization ? item.organization : '',
                    AccreditationOrganization: item.accreditationOrganization ? item.accreditationOrganization : '',
                };
            }) : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Уведомление субъекту
     * @param entity
     * @param appeal
     */
    public prepareEntityNotification(entity, appeal) {
        const result: any = {
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            RequestedDocumentDate: entity.requestedDocumentDate ? moment(entity.requestedDocumentDate).format('DD.MM.YYYY') : '',
            Materials: entity.materials && Array.isArray(entity.materials) ? entity.materials.map(item => item.text ? item.text : '') : [],
            NotifyWay: entity.notifyWay ? entity.notifyWay.name : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : {},
        };
        const order = this.findEntity(appeal, this.entityTypes.order.code);
        if (order) {
            result.OrderDate = order.date ? moment(order.date).format('DD.MM.YYYY') : '';
            result.OrderNumber = order.number ? order.number : '';
            result.OrderDateStart = order.dateStart ? moment(order.dateStart).format('DD.MM.YYYY') : '';
            result.OrderDateEnd = order.dateEnd ? moment(order.dateEnd).format('DD.MM.YYYY') : '';
            result.OrderThemes = order.themes && Array.isArray(order.themes) ? order.themes.map(item => item.text ? item.text : '') : [];
            result.OrderRequestedDocument = order.requestedDocument && Array.isArray(order.requestedDocument) ? order.requestedDocument.map(item => item.value && item.value.name ? item.value.name : '') : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Акт о невозможности проведения проверки
     * @param entity
     * @param appeal
     */
    public prepareEntityActNotImpossible(entity, appeal) {
        const result: any = {
            TypeAddress: entity.typeAddress ? entity.typeAddress.name : '',
            CreatePlace: entity.createPlace ? PrintUtilities.PrintAddressAsLine(entity.createPlace, true) : '',
            CreateDate: entity.createDate ? moment(entity.createDate).format('DD.MM.YYYY') : '',
            CreateHours: entity.createHours ? entity.createHours : '',
            CreateMinutes: entity.createMinutes ? entity.createMinutes : '',
            Number: entity.number ? entity.number : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            ReasonImpossibility: entity.reasonImpossibility && Array.isArray(entity.reasonImpossibility) ? entity.reasonImpossibility.map(item => item.value && item.value.name ? item.value.name : '') : '',
            CheckControlRestrict: entity.checkControlRestrict ? entity.checkControlRestrict : '',
            PersonSubjectName: entity.personSubjectName ? entity.personSubjectName : '',
            PersonSubjectPosition: entity.personSubjectPosition ? entity.personSubjectPosition : '',
            PersonWitnessName: entity.personWitnessName ? entity.personWitnessName : '',
            PersonWitnessAddress: entity.personWitnessAddress ? PrintUtilities.PrintAddressAsLine(entity.personWitnessAddress, true) : '',
            PersonWitnessPasport: entity.personWitnessPasport ? entity.personWitnessPasport : '',
            Materials: entity.materials && Array.isArray(entity.materials) ? entity.materials.map(item => item.text ? item.text : '') : [],
            ResultInformNature: entity.resultInformNature ? entity.resultInformNature.name : '',
            DateNotify: entity.dateNotify ? moment(entity.dateNotify).format('DD.MM.YYYY') : '',
        };
        const order = this.findEntity(appeal, this.entityTypes.order.code);
        if (order) {
            result.OrderDate = order.date ? moment(order.date).format('DD.MM.YYYY') : '';
            result.OrderNumber = order.number ? order.number : '';
            result.OrderEmployees = order.employees && Array.isArray(order.employees) ? order.employees.map(item => {
                return {
                    Name: item.name ? item.name[0].text : '',
                    Position: item.position ? item.position : '',
                };
            }) : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Мотивированный запрос
     * @param entity
     * @param appeal
     */
    public prepareEntityRequestMotivated(entity, appeal) {
        const result: any = {
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Number: entity.number ? entity.number : '',
            RequestedDocumentBasis: entity.requestedDocumentBasis ? entity.requestedDocumentBasis.name : '',
            RequestedDocumentName: entity.requestedDocumentName && Array.isArray(entity.requestedDocumentName)
                ? entity.requestedDocumentName.map(item => item.value && item.value.name ? item.value.name : '')
                : [],
            RequestedDocumentDate: entity.requestedDocumentDate ? moment(entity.requestedDocumentDate).format('DD.MM.YYYY') : '',
            Materials: entity.materials && Array.isArray(entity.materials) ? entity.materials.map(item => item.text ? item.text : '') : [],
            EmployeeName: entity.employeeName ? entity.employeeName[0].name : '',
            EmployeePosition: entity.employeePosition ? entity.employeePosition : '',
        };
        const order = this.findEntity(appeal, this.entityTypes.order.code);
        if (order) {
            result.OrderDate = order.date ? moment(order.date).format('DD.MM.YYYY') : '';
            result.OrderNumber = order.number ? order.number : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Требования о представлении пояснений
     * @param entity
     * @param appeal
     */
    public prepareEntityRequirementExplanation(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Text: entity.text ? entity.text : '',
            Employee: entity.employee ? {
                Name: entity.employee ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : {},
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Предписание
     * @param entity
     * @param appeal
     */
    public prepareEntityRegulation(entity, appeal) {
        const result: any = {
            ActId: entity.act ? entity.act : null,
            ViolationId: entity.violation ? entity.violation : null,
            Number: entity.number ? entity.number : '',
            CreatePlace: entity.createPlace ? PrintUtilities.PrintAddressAsLine(entity.createPlace, true) : '',
            CreateDate: entity.dateCreate ? moment(entity.dateCreate).format('DD.MM.YYYY') : '',
            CompleteDate: entity.dateComplete ? moment(entity.dateComplete).format('DD.MM.YYYY') : '',
            Eliminations: entity.checkLists && Array.isArray(entity.checkLists) ? entity.checkLists.map(checkList => {
                return checkList.questions.map(item => {
                   return {
                       Elimination: {
                           Name: item.mandatoryReqs ? item.mandatoryReqs.name : '',
                           Measure: item.eliminationMeasure ? item.eliminationMeasure : '',
                           Date: item.eliminationDate ? moment(item.eliminationDate).format('DD.MM.YYYY') : '',
                       },
                   };
                });
            }) : [],
            DateNoticeElimination: entity.dateNoticeElimination ? moment(entity.dateNoticeElimination).format('DD.MM.YYYY') : '',
            Content: entity.content ? entity.content : '',
            ExecutionStatus: entity.executionStatus ? { Id: entity.executionStatus._id, Name: entity.executionStatus.name } : '',
        };
        const act = this.findEntity(appeal, this.entityTypes.act.code);
        if (act) {
            result.ActViolations = act.violations && Array.isArray(act.violations) ? act.violations.map(item => {
                return {
                    Questions: item.questions && Array.isArray(item.questions) ? item.questions.map(question => {
                        return {
                            Npa: question.nPA ? question.nPA.name : '',
                            MandatoryReqs: question.mandatoryReqs ? question.mandatoryReqs.name : '',
                            ViolationNature: question.violationNature ? question.violationNature : '',
                            Violation: question.violation ? {
                                Character: question.violation.character ? question.violation.character : '',
                                InfringerType: question.violation.infringerType ? question.violation.infringerType[0].text : '',
                                InfringerName: question.violation.infringerName ? question.violation.infringerName : '',
                                InfringerPosition: question.violation.infringerPosition ? question.violation.infringerPosition : '',
                                InfringerIndividual: question.violation.infringerIndividual ? question.violation.infringerIndividual : '',
                                InfringerLegal: question.violation.infringerLegal ? question.violation.infringerLegal : '',
                            } : {},
                            Measure: question.eliminationMeasure ? question.eliminationMeasure : '',
                            Date: question.eliminationDate ? moment(question.eliminationDate).format('DD.MM.YYYY') : '',
                        };
                    }) : [],
                };
            }) : [];
            result.ActCharacters = act.characters && Array.isArray(act.characters) ? act.characters.map(item => {
                return {
                    Comment: item.comment ? item.comment : '',
                    Requirement: item.requirement ? item.requirement.name : '',
                };
            }) : [];
            result.ActPrescriptionRequisites = act.prescriptionRequisites ? act.prescriptionRequisites : '';
            result.ActPrescriptions = act.prescriptions && Array.isArray(act.prescriptions) ? act.prescriptions.map(item => {
                return {
                    Item: item.item ? item.item : '',
                    FactFailure: item.factFailure ? item.factFailure : '',
                };
            }) : [];
            result.ActResults = act.results && Array.isArray(act.results) && act.results[0] ? act.results[0].text : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Заявление о согласовании проведения внеплановой выездной проверки
     * @param entity
     * @param appeal
     */
    public prepareEntityCheckStatement(entity, appeal) {
        const result: any = {
            Prosecutor: entity.prosecutor ? entity.prosecutor.name : '',
            CreateDate: entity.createDate ? moment(entity.createDate).format('DD.MM.YYYY') : '',
            CreateTime: entity.createTime ? entity.createTime : '',
            Materials: entity.materials && Array.isArray(entity.materials) ? entity.materials.map(item => item.text) : [],
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : {},
        };
        const order = this.findEntity(appeal, this.entityTypes.order.code);
        if (order) {
            result.OrderDate = order.date ? moment(order.date).format('DD.MM.YYYY') : '';
            result.OrderStartDate = order.dateStart ? moment(order.dateStart).format('DD.MM.YYYY') : '';
            result.OrderBasisKnm = appeal.subservice.kndInfo.basisKnm && Array.isArray(appeal.subservice.kndInfo.basisKnm)
                ? appeal.subservice.kndInfo.basisKnm.map(item => item.name)
                : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Направление на экспертизу
     * @param entity
     * @param appeal
     */
    public prepareEntityCheckExamination(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Brief: entity.brief ? entity.brief : '',
            Text: entity.text ? entity.text : '',
            Organization: entity.organization ? entity.organization : '',
            Address: entity.address ? PrintUtilities.PrintAddressAsLine(entity.address, true) : '',
            EmployeeSignatory: entity.employeeSignatory
                ? {
                    Name: entity.employeeSignatory.name ? entity.employeeSignatory.name[0].text : '',
                    Position: entity.employeeSignatory.position ? entity.employeeSignatory.position : '',
                } : '',
            OrderEmployees: appeal.inspectors && Array.isArray(appeal.inspectors)
                ? appeal.inspectors.map(item => ({
                    Name: item.name ? item.name : '',
                    Position: item.position ? item.position : ''}))
                : [],
        };

        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Заявление (извещение) прокуратуре о КЗ
     * @param entity
     * @param appeal
     */
    public prepareEntityBuyOrder(entity, appeal) {
        const result: any = {
            OrderNumber: entity.orderNumber ? entity.orderNumber : '',
            OrderDate: entity.orderDate ? moment(entity.orderDate).format('DD.MM.YYYY') : '',
            PlaceBuyDistance: entity.placeBuyDistance ? entity.placeBuyDistance : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            DateFinish: entity.dateFinish ? moment(entity.dateFinish).format('DD.MM.YYYY') : '',
            PeriodHour: entity.periodHour ? entity.periodHour : 0,
            PlaceKnmType: entity.placeKnmType ? entity.placeKnmType.name : '',
            ObjectKnmType: entity.objectKnmType ? entity.objectKnmType.name : '',
            MethodKnm: entity.methodKnm ? entity.methodKnm.name : '',
            Inspectors: entity.inspectors && Array.isArray(entity.inspectors)
                ? entity.inspectors.map(item => {
                    return {
                        Name: item.name ? item.name[0].text : '',
                        Position: item.position ? item.position : '',
                    };
                }) : [],
            FaceKnmType: entity.faceKnmType ? entity.faceKnmType.name : '',
            KnmEvents: entity.knmEvents && Array.isArray(entity.knmEvents)
                ? entity.knmEvents.map(item => item.value ? item.value.name : '') : [],
            MandatoryReqs: entity.mandatoryReqs && Array.isArray(entity.mandatoryReqs)
                ? entity.mandatoryReqs.map(item => item.value ? item.value.name : '') : [],
            BasisKnm: entity.basisKnm && Array.isArray(entity.basisKnm)
                ? entity.basisKnm.map(item => item.value ? item.value.name : '') : [],
            Target: entity.target ? entity.target : '',
            Task: entity.task ? entity.task : '',
            Products: entity.products ? entity.products : '',
            ProductsPurchase: entity.productsPurchase ? entity.productsPurchase : '',
            ProductsPayment: entity.productsPayment ? entity.productsPayment : '',
            Fixation: entity.fixation ? entity.fixation : '',
            ProcDataAgreement: entity.procDataAgreement ? moment(entity.procDataAgreement).format('DD.MM.YYYY') : '',
            ProcNumberAgreement: entity.procNumberAgreement ? entity.procNumberAgreement : '',
            OtherInformation: entity.otherInformation ? entity.otherInformation : '',
            Supervisor: entity.supervisor ? {
                Name: entity.supervisor && entity.supervisor.name ? entity.supervisor.name[0].text : '',
                Position: entity.supervisor.position ? entity.supervisor.position : '',
            } : '',
            Inspector: entity.inspector ? {
                Name: entity.inspector && entity.inspector.name ? entity.inspector.name[0].text : '',
                Position: entity.inspector.position ? entity.inspector.position : '',
            } : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Акт о КЗ
     * @param entity
     * @param appeal
     */
    public prepareEntityBuyAct(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            KnmPlaceType: entity.knmPlaceType ? entity.knmPlaceType : '',
            PlaceCompilation: entity.placeCompilation ? PrintUtilities.PrintAddressAsLine(entity.placeCompilation, true) : '',
            TypeAddressName: entity.typeAddressName ? entity.typeAddressName.name : '',
            DateCompilation: entity.dateCompilation ? moment(entity.dateCompilation).format('DD.MM.YYYY') : '',
            HourCompilation: entity.hourCompilation ? entity.hourCompilation : 0,
            MinCompilation: entity.minCompilation ? entity.minCompilation : 0,
            HourEnd: entity.hourEnd ? entity.hourEnd : 0,
            MinEnd: entity.minEnd ? entity.minEnd : 0,
            FactControlPeriod: entity.factControlPeriod ? entity.factControlPeriod : 0,
            HourControlPeriod: entity.hourControlPeriod ? entity.hourControlPeriod : 0,
            Acquainted: entity.acquainted ? entity.acquainted : false,
            GetCopy: entity.getCopy ? entity.getCopy : false,
            Representative: entity.representative ? entity.representative : '',
            DateAcquainted: entity.dateAcquainted ? moment(entity.dateAcquainted).format('DD.MM.YYYY') : '',
            TimeAcquainted: entity.timeAcquainted ? entity.timeAcquainted : '',
            AgentType: entity.agentType ? entity.agentType.name : '',
            Inspector: entity.inspector ? {
                Name: entity.inspector.name ? entity.inspector.name[0].text : '',
                Position: entity.inspector.position ? entity.inspector.position : '',
            } : '',
            InspectorFailure: entity.inspectorFailure ? {
                Name: entity.inspectorFailure.name ? entity.inspectorFailure.name[0].text : '',
                Position: entity.inspectorFailure.position ? entity.inspectorFailure.position : '',
            } : '',
            Standard: entity.standart ? entity.standard : '',
            Temperature: entity.temperature ? entity.temperature : '',
            ReturnItem: entity.returnItem ? entity.returnItem : '',
            ReturnItemMany: entity.returnItemMany ? entity.returnItemMany : '',
            Withness: entity.withness ? entity.withness : '',
            ResultInformNature: entity.resultInformNature ? entity.resultInformNature.name : '',
            ResultBuyAct: entity.resultBuyAct ? entity.resultBuyAct : '',
            BuyFixation: entity.buyFixation ? entity.buyFixation : '',
            NsiViolations: entity.nsiViolations && Array.isArray(entity.nsiViolations)
                ? entity.nsiViolations.map(item => item.value && item.value.name ? item.value.name : '') : [],
            ViolationNature: entity.violationNature ? entity.violationNature.name : '',
            ScrollDoc: entity.scrollDoc && Array.isArray(entity.scrollDoc) ? entity.scrollDoc.map(item => item.text) : [],
            EmployeeSignature: entity.employeeSignature ? entity.employeeSignature : '',
            DateSignature: entity.dateSignature ? moment(entity.dateSignature).format('DD.MM.YYYY') : '',
            TimeSignature: entity.timeSignature ? entity.timeSignature : '',
            RenouncementSignature: entity.renouncementSignature ? entity.renouncementSignature : '',
            GetAct: entity.getAct ? entity.getAct : false,
            InspectorGetAct: entity.inspectorGetAct ? {
                Name: entity.inspectorGetAct.name ? entity.inspectorGetAct.name[0].text : '',
                Position: entity.inspectorGetAct.position ? entity.inspectorGetAct.position : '',
            } : '',
            RepresentativeGetAct: entity.representativeGetAct ? entity.representativeGetAct : '',
            InfoGetAct: entity.infoGetAct ? entity.infoGetAct : '',
            DateGetAct: entity.dateGetAct ? moment(entity.dateGetAct).format('DD.MM.YYYY') : '',
            TimeGetAct: entity.timeGetAct ? entity.timeGetAct : '',
            RenouncemeGetAct: entity.renouncemeGetAct ? entity.renouncemeGetAct : '',
            GetTestItem: entity.getTestItem ? entity.getTestItem : '',
            TestItem: entity.testItem ? entity.testItem : '',
            TimeDeliveryItem: entity.timeDeliveryItem ? entity.timeDeliveryItem : '',
            TemperatureItem: entity.temperatureItem ? entity.temperatureItem : '',
            Expert: entity.expert ? entity.expert : '',
        };

        const buyOrder = this.findEntity(appeal, this.entityTypes.buyOrder.code);
        if (buyOrder) {
            result.BuyOrderOrderNumber = buyOrder.orderNumber ? buyOrder.orderNumber : '';
            result.BuyOrderOrderDate = buyOrder.orderDate ? moment(buyOrder.orderDate).format('DD.MM.YYYY') : '';
            result.BuyOrderFixation = buyOrder.fixation ? buyOrder.fixation : '';
            result.BuyOrderBuyDate = buyOrder.date ? moment(buyOrder.date).format('DD.MM.YYYY') : '';
            result.BuyOrderProducts = entity.products ? entity.products : '';
            result.BuyOrderProductsPurchase = buyOrder.productsPurchase ? buyOrder.productsPurchase : '';
            result.BuyOrderProductsPayment = buyOrder.productsPayment ? buyOrder.productsPayment : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Акт о дистанционной КЗ
     * @param entity
     * @param appeal
     */
    public prepareEntityBuyActDistance(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            PlaceCompilation: entity.placeCompilation ? PrintUtilities.PrintAddressAsLine(entity.placeCompilation, true) : '',
            TypeAddressName: entity.typeAddressName ? entity.typeAddressName.name : '',
            DateCompilation: entity.dateCompilation ? moment(entity.dateCompilation).format('DD.MM.YYYY') : '',
            HourCompilation: entity.hourCompilation ? entity.hourCompilation : 0,
            MinCompilation: entity.minCompilation ? entity.minCompilation : 0,
            HourEnd: entity.hourEnd ? entity.hourEnd : 0,
            MinEnd: entity.minEnd ? entity.minEnd : 0,
            FactControlPeriod: entity.factControlPeriod ? entity.factControlPeriod : 0,
            HourControlPeriod: entity.hourControlPeriod ? entity.hourControlPeriod : 0,
            WayDistance: entity.wayDistance ? entity.wayDistance : '',
            TestItem: entity.testItem ? entity.testItem : '',
            ReturnItem: entity.returnItem ? entity.returnItem : '',
            ReturnItemMany: entity.returnItemMany ? entity.returnItemMany : '',
            NsiViolations: entity.nsiViolations && Array.isArray(entity.nsiViolations)
                ? entity.nsiViolations.map(item => item.value ? item.value.name : '') : [],
            ScrollDoc: entity.scrollDoc && Array.isArray(entity.scrollDoc)
                ? entity.scrollDoc.map(item => item.text) : [],
            SendDateCopyOrder: entity.sendDateCopyOrder ? moment(entity.sendDateCopyOrder).format('DD.MM.YYYY') : '',
            SendWayCopyOrder: entity.sendWayCopyOrder ? entity.sendWayCopyOrder : '',
            Inspector: entity.inspector ? {
                Name: entity.inspector.name ? entity.inspector.name[0].text : '',
                Position: entity.inspector.position ? entity.inspector.position : '',
            } : '',
            PlacePurchase: entity.placePurchase ? PrintUtilities.PrintAddressAsLine(entity.placePurchase, true) : '',
            OrderNumber: entity.orderNumber ? entity.orderNumber : '',
            OrderDate: entity.orderDate ? entity.orderDate : '',
            BuyDate: entity.buyDate ? moment(entity.buyDate).format('DD.MM.YYYY') : '',
        };

        const buyOrder = this.findEntity(appeal, this.entityTypes.buyOrder.code);
        if (buyOrder) {
            result.BuyOrderPlaceBuyDistance = buyOrder.placeBuyDistance ? buyOrder.placeBuyDistance : '';
            result.BuyOrderOrderDate = buyOrder.orderDate;
            result.BuyOrderOrderNumber = buyOrder.orderNumber ? buyOrder.orderNumber : '';
            result.BuyOrderDate = buyOrder.date ? moment(buyOrder.date).format('DD.MM.YYYY') : '';
            result.BuyOrderBasisKnm = buyOrder.basisKnm && Array.isArray(buyOrder.basisKnm)
                ? entity.basisKnm.map(item => item.value ? item.value.name : '') : [];
            result.BuyOrderProducts = buyOrder.products ? buyOrder.products : '';
            result.BuyOrderProductsPurchase = buyOrder.productsPurchase ? buyOrder.productsPurchase : '';
            result.BuyOrderProductsPayment = buyOrder.productsPayment ? buyOrder.productsPayment : '';
            result.BuyOrderFixation = buyOrder.fixation ? buyOrder.fixation : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Заявление (извещение) прокуратуре о КЗ
     * @param entity
     * @param appeal
     */
    public prepareEntityBuyNotice(entity, appeal) {
        const result: any = {
            Prosecutor: entity.prosecutor ? entity.prosecutor.name : '',
            TerritoryDirectory: entity.territoryDirectory ? entity.territoryDirectory.name : '',
            ControlBase: entity.controlBase && Array.isArray(entity.controlBase)
                ? entity.controlBase.map(item => item.value ? item.value.name : '')
                : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            HourBuy: entity.hourBuy ? entity.hourBuy : '',
            MinBuy: entity.minBuy ? entity.minBuy : '',
            ScrollDoc: entity.scrollDoc && Array.isArray(entity.scrollDoc)
                ? entity.scrollDoc.map(item => item.text ? item.text : '')
                : [],
            DateNotice: entity.dateNotice ? moment(entity.dateNotice).format('DD.MM.YYYY') : '',
            HourTimeNotice: entity.hourTimeNotice ? entity.hourTimeNotice : '',
            MinTimeNotice: entity.minTimeNotice ? entity.minTimeNotice : '',
            Executive: entity.executive
                ? {
                    Name: entity.executive.name ? entity.executive.name[0].text : '',
                    Position: entity.executive.position ? entity.executive.position : '',
                } : '',
        };

        const buyOrder = this.findEntity(appeal, this.entityTypes.buyOrder.code);
        if (buyOrder) {
            result.OrderDate = buyOrder.orderDate;
            result.OrderBasisKnm = buyOrder.basisKnm && Array.isArray(buyOrder.basisKnm)
                ? buyOrder.basisKnm.map(item => item.value ? item.value.name : '') : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Организационно-распорядительный документ (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationDocument(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            PurposeSystematic: entity.purposeSystematic ? entity.purposeSystematic : '',
            Employees: entity.employees && Array.isArray(entity.employees)
                ? entity.employees.map(item => {
                    return {
                        Name: item.name ? item.name[0].text : '',
                        Position: item.position ? item.position : '',
                    };
                }) : [],
            MandatoryReqs: entity.mandatoryReqs && Array.isArray(entity.mandatoryReqs)
                ? entity.mandatoryReqs.map(item => item.value ? item.value.name : '') : [],
            Scroll: entity.scroll ? entity.scroll : '',
            StartDate: entity.startDate ? moment(entity.startDate).format('DD.MM.YYYY') : '',
            EndDate: entity.endDate ? moment(entity.endDate).format('DD.MM.YYYY') : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Ответ субъекту на возражения (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationAnswer(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            AnswerSubject: entity.answerSubject ? entity.answerSubject : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Предписание (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationPrescription(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            ReportDeadline: entity.reportDeadline ? moment(entity.reportDeadline).format('DD.MM.YYYY') : '',
        };
        const observationDocument = this.findEntity(appeal, this.entityTypes.observationDocument.code);
        if (observationDocument) {
            result.StartDate = observationDocument.startDate ? moment(observationDocument.startDate).format('DD.MM.YYYY') : '';
            result.EndDate = observationDocument.endDate ? moment(observationDocument.endDate).format('DD.MM.YYYY') : '';
            result.OrderNumber = observationDocument.number ? observationDocument.number : '';
            result.OrderDate = observationDocument.date ? moment(observationDocument.date).format('DD.MM.YYYY') : '';
            result.Employees = observationDocument.employees && Array.isArray(observationDocument.employees)
                ? observationDocument.employees.map(item => {
                    return {
                        Name: item.name ? item.name[0].text : '',
                        Position: item.position ? item.position : '',
                    };
                }) : [];
        }
        const observationAct = this.findEntity(appeal, this.entityTypes.observationAct.code);
        if (observationAct) {
            result.Results = observationAct.results ? observationAct.results : '';
            result.NsiViolations = observationAct.nsiViolations && Array.isArray(observationAct.nsiViolations)
                ? observationAct.nsiViolations.map(item => item.value ? item.value.name : '')
                : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Предостережение о недопустимости нарушения обязательных требований (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationWarning(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            ActionInformation: entity.actionInformation ? entity.actionInformation : '',
            Requirements: entity.requirements && Array.isArray(entity.requirements) ? entity.requirements.map(item => {
                return {
                    MandatoryReq: item.mandatoryReq ? item.mandatoryReq.name : '',
                    Npa: item.npa ? item.npa.name : '',
                };
            }) : [],
            ProposedMeasures: entity.proposedMeasures ? entity.proposedMeasures : '',
            Deadline: entity.deadline ? moment(entity.deadline).format('DD.MM.YYYY') : '',
            IndividualName: entity.individualName ? entity.individualName : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
            IndividualPosition: entity.individualPosition ? entity.individualPosition : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Запрос документов и сведений (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationRequest(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Npa: entity.npa && Array.isArray(entity.npa) ? entity.npa.map(item => item.value ? item.value.name : '') : '',
            ProvideDocuments: entity.provideDocuments ? entity.provideDocuments : '',
            Deadline: entity.deadline ? moment(entity.deadline).format('DD.MM.YYYY') : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
        };
        const observationDocument = this.findEntity(appeal, this.entityTypes.observationDocument.code);
        if (observationDocument) {
            result.OrderNumber = observationDocument.number ? observationDocument.number : '';
            result.OrderDate = observationDocument.date ? moment(observationDocument.date).format('DD.MM.YYYY') : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Уведомление субъекта о систематическом наблюдении (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationNotify(entity, appeal) {
        const result: any = {
            Npa: entity.npa && Array.isArray(entity.npa) ? entity.npa.map(item => item.value ? item.value.name : '') : '',
            Subject: entity.subject ? entity.subject : '',
            ProvideDocuments: entity.provideDocuments ? entity.provideDocuments : '',
        };
        const observationDocument = this.findEntity(appeal, this.entityTypes.observationDocument.code);
        if (observationDocument) {
            result.StartDate = observationDocument.startDate ? moment(observationDocument.startDate).format('DD.MM.YYYY') : '';
            result.EndDate = observationDocument.endDate ? moment(observationDocument.endDate).format('DD.MM.YYYY') : '';
            result.OrderNumber = observationDocument.number ? observationDocument.number : '';
            result.OrderDate = observationDocument.date ? moment(observationDocument.date).format('DD.MM.YYYY') : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Акт систематического наблюдения (Систематическое наблюдение)
     * @param entity
     * @param appeal
     */
    public prepareEntityObservationAct(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            Scroll: entity.scroll ? entity.scroll : '',
            Results: entity.results ? entity.results : '',
            Circumstances: entity.circumstances ? entity.circumstances : '',
            Actions: entity.actions && Array.isArray(entity.actions) ? entity.actions.map(item => {
                return {
                    NsiViolation: item.requirement ? item.requirement.name : '',
                    NpaName: item.npa ? item.npa.name : '',
                    ActionName: item.actionTaken ? item.actionTaken : '',
                    Deadline: item.date ? moment(item.date).format('DD.MM.YYYY') : '',
                };
            }) : [],
            ScrollDoc: entity.scrollDoc ? entity.scrollDoc : '',
        };
        const observationDocument = this.findEntity(appeal, this.entityTypes.observationDocument.code);
        if (observationDocument) {
            result.StartDate = observationDocument.startDate ? moment(observationDocument.startDate).format('DD.MM.YYYY') : '';
            result.EndDate = observationDocument.endDate ? moment(entity.endDate).format('DD.MM.YYYY') : '';
            result.OrderNumber = observationDocument.number ? observationDocument.number : '';
            result.OrderDate = observationDocument.date ? moment(observationDocument.date).format('DD.MM.YYYY') : '';
            result.Employees = observationDocument.employees && Array.isArray(observationDocument.employees) ? observationDocument.employees.map(item => {
                return {
                    Name: item.name ? item.name[0].text : '',
                    Position: item.position ? item.position : '',
                };
            }) : [];
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityRaidTask(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            RaidTasks: entity.raidTasks ? entity.raidTasks : '',
            ExpertOrganization: entity.expertOrganization ? entity.expertOrganization : '',
            CertificateAccreditation: entity.certificateAccreditation ? entity.certificateAccreditation : '',
            AccreditationOrgan: entity.accreditationOrgan ? entity.accreditationOrgan : '',
            ExpertName: entity.expertName ? entity.expertName : '',
            ExpertPosition: entity.expertPosition ? entity.expertPosition : '',
            BasisKNM: entity.basisKNM && Array.isArray(entity.basisKNM) ? entity.basisKNM.map(item => item.value ? item.value.name : '') : '',
            Location: entity.location ? entity.location : '',
            PurposeKNM: entity.purposeKNM ? entity.purposeKNM.name : '',
            Limitation: entity.limitation ? entity.limitation : '',
            StartDate: entity.startDate ? moment(entity.startDate).format('DD.MM.YYYY') : '',
            EndDate: entity.endDate ? moment(entity.endDate).format('DD.MM.YYYY') : '',
            Employees: entity.employees && Array.isArray(entity.employees)
                ? entity.employees.map(item => {
                    return {
                        Name: item.name ? item.name[0].text : '',
                        Position: item.position ? item.position : '',
                    };
                }) : [],
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityRaidAct(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            StartDate: entity.startDate ? moment(entity.startDate).format('DD.MM.YYYY') : '',
            EndDate: entity.endDate ? moment(entity.endDate).format('DD.MM.YYYY') : '',
            CharacteristicTerritory: entity.characteristicTerritory ? entity.characteristicTerritory : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : {},
            RaidEvents: entity.raidEvents ? entity.raidEvents : '',
            HardwareModels: entity.hardwareModels ? entity.hardwareModels : '',
            Circumstances: entity.circumstances ? entity.circumstances : '',
            NsiViolations: entity.nsiViolations && Array.isArray(entity.nsiViolations) ? entity.nsiViolations.map(item => item.value ? item.value.name : '') : '',
            Violators: entity.violators ? entity.violators : '',
            AttachedDocuments: entity.attachedDocuments ? entity.attachedDocuments : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityRaidWarning(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            ActionInformation: entity.actionInformation ? entity.actionInformation : '',
            Requirements: entity.requirements && Array.isArray(entity.requirements) ? entity.requirements.map(item => {
                return {
                    MandatoryReq: item.mandatoryReq ? item.mandatoryReq.name : '',
                    Npa: item.npa ? item.npa.name : '',
                };
            }) : [],
            ProposedMeasures: entity.proposedMeasures ? entity.proposedMeasures : '',
            Deadline: entity.deadline ? moment(entity.deadline).format('DD.MM.YYYY') : '',
            IndividualName: entity.individualName ? entity.individualName : '',
            IndividualPosition: entity.individualPosition ? entity.individualPosition : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : {},
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityRaidAnswer(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            AnswerSubject: entity.answerSubject ? entity.answerSubject : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedInitiation(entity, appeal) {
        const result: any = {
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
            Case: entity.case ? entity.case : '',
            OffenseEvent: entity.offenseEvent ? entity.offenseEvent : '',
            Coap: entity.coap ? entity.coap.name : '',
            Individual: entity.individual ? entity.individual : '',
            Defender: entity.defender ? entity.defender : '',
            DetailsDefender: entity.detailsDefender ? entity.detailsDefender : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedConducting(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedInformation(entity, appeal) {
        const result: any = {
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
            Coap: entity.coap ? entity.coap.name : '',
            Address: entity.address ? PrintUtilities.PrintAddressAsLine(entity.address, true) : '',
            Email: entity.email ? entity.email : '',
            RequestedInformation: entity.requestedInformation ? entity.requestedInformation : '',
            RequiredDocuments: entity.requiredDocuments ? entity.requiredDocuments : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedProtocol(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            DocumentNumber: entity.documentNumber ? entity.documentNumber : '',
            DocumentSeries: entity.documentSeries ? entity.documentSeries : '',
            Culprit: entity.culprit ? entity.culprit.name : '',
            Birthday: entity.birthday ? moment(entity.birthday).format('DD.MM.YYYY') : '',
            PlaceBirth: entity.placeBirth ? PrintUtilities.PrintAddressAsLine(entity.placeBirth, true) : '',
            LocationAddress: entity.locationAddress ? PrintUtilities.PrintAddressAsLine(entity.locationAddress, true) : '',
            PlaceWork: entity.placeWork ? PrintUtilities.PrintAddressAsLine(entity.placeWork, true) : '',
            Position: entity.position ? entity.position : '',
            OffenseEvent: entity.offenseEvent ? entity.offenseEvent : '',
            Individual: entity.individual ? entity.individual : '',
            Defender: entity.defender ? entity.defender : '',
            DetailsDefender: entity.detailsDefender ? entity.detailsDefender : '',
            CaseDate: entity.caseDate ? moment(entity.caseDate).format('DD.MM.YYYY') : '',
            CaseTime: entity.caseTime ? entity.caseTime : '',
            Coap: entity.coap ? entity.coap.name : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedProtocolMakeup(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Npa: entity.npa && Array.isArray(entity.npa) ? entity.npa.map(item => item.value ? item.value.name : '') : [],
            Coap: entity.coap ? entity.coap.name : '',
            CommitDate: entity.commitDate ? moment(entity.commitDate).format('DD.MM.YYYY') : '',
            ProtocolDate: entity.protocolDate ? moment(entity.protocolDate).format('DD.MM.YYYY') : '',
            ProtocolTime: entity.protocolTime ? entity.protocolTime : '',
            ProtocolPlace: entity.protocolPlace ? PrintUtilities.PrintAddressAsLine(entity.protocolPlace, true) : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedProtocolReview(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Npa: entity.npa && Array.isArray(entity.npa) ? entity.npa.map(item => item.value ? item.value.name : '') : [],
            CommitDate: entity.commitDate ? moment(entity.commitDate).format('DD.MM.YYYY') : '',
            ProtocolReviewDate: entity.protocolReviewDate ? moment(entity.protocolReviewDate).format('DD.MM.YYYY') : '',
            ProtocolReviewTime: entity.protocolReviewTime ? entity.protocolReviewTime : '',
            ProtocolReviewPlace: entity.protocolReviewPlace ? PrintUtilities.PrintAddressAsLine(entity.protocolReviewPlace, true) : '',
            Coap: entity.coap ? entity.coap.name : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedReferral(entity, appeal) {
        const result: any = {
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            Court: entity.court ? entity.court : '',
            Inspector: entity.inspector ? {
                Name: entity.inspector.name ? entity.inspector.name[0].text : '',
                Position: entity.inspector.position ? entity.inspector.position : '',
            } : '',
            CommitDate: entity.commitDate ? moment(entity.commitDate).format('DD.MM.YYYY') : '',
            CrimeAddress: entity.crimeAddress ? PrintUtilities.PrintAddressAsLine(entity.crimeAddress, true) : '',
            BasicInformation: entity.basicInformation ? entity.basicInformation : '',
            FineSize: entity.fineSize ? entity.fineSize : '',
            FineSize2: entity.fineSize2 ? entity.fineSize2 : '',
            Coap: entity.coap ? entity.coap.name : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedDecree(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            ReviewDate: entity.reviewDate ? moment(entity.reviewDate).format('DD.MM.YYYY') : '',
            ReviewPlace: entity.reviewPlace ? PrintUtilities.PrintAddressAsLine(entity.reviewPlace, true) : '',
            Employee: entity.employee ? {
                Name: entity.employee.name ? entity.employee.name[0].text : '',
                Position: entity.employee.position ? entity.employee.position : '',
            } : '',
            Coap: entity.coap
                ? entity.coap.filter(c => c.value).map(c => ({ Id: c.value._id, Name: c.value.name }))
                : '',
            BasicInformation: entity.basicInformation ? entity.basicInformation : '',
            Individual: entity.individual ? entity.individual : '',
            DocumentSeries: entity.documentSeries ? entity.documentSeries : '',
            DocumentNumber: entity.documentNumber ? entity.documentNumber : '',
            Birthday: entity.birthday ? moment(entity.birthday).format('DD.MM.YYYY') : '',
            PlaceBirth: entity.placeBirth ? PrintUtilities.PrintAddressAsLine(entity.placeBirth, true) : '',
            LocationAddress: entity.locationAddress ? PrintUtilities.PrintAddressAsLine(entity.locationAddress, true) : '',
            PlaceWork: entity.placeWork ? PrintUtilities.PrintAddressAsLine(entity.placeWork, true) : '',
            Position: entity.position ? entity.position : '',
            Aggravating: entity.aggravating ? entity.aggravating : '',
            Emollients: entity.emollients ? entity.emollients : '',
            AppealMethods: entity.appealMethods ? entity.appealMethods : '',
            Install: entity.install ? entity.install : '',
            FineSize: entity.fineSize ? entity.fineSize : '',
            FineSize2: entity.fineSize2 ? entity.fineSize2 : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedAction(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            CausesCrime: entity.causesCrime ? entity.causesCrime : '',
        };
        const proceedDecree = this.findEntity(appeal, this.entityTypes.proceedDecree.code);
        if (proceedDecree) {
            result.ProceedDecreeDate = proceedDecree.date ? moment(proceedDecree.date).format('DD.MM.YYYY') : '';
            result.ProceedDecreeNumber = proceedDecree.number ? proceedDecree.number : '';
            result.ProceedDecreeIndividual = proceedDecree.individual ? proceedDecree.individual : '';
        }
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения
     * @param entity
     * @param appeal
     */
    public prepareEntityProceedTermination(entity, appeal) {
        const result: any = {
            Number: entity.number ? entity.number : '',
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Решение прокуратуры
     * @param entity
     * @param appeal
     */
    public prepareEntityViolationDecision(entity, appeal) {
        const result: any = {
            Date: entity.date ? moment(entity.date).format('DD.MM.YYYY') : '',
            Prosecutor: entity.prosecutor ? entity.prosecutor.name : '',
            ProsecutorAddress: entity.prosecutorAddress ? PrintUtilities.PrintAddressAsLine(entity.prosecutorAddress, true) : '',
            ReasonDeny: entity.reasonDeny ? entity.reasonDeny.name : '',
            IsAgreed: entity.isAgreed ? entity.isAgreed : '',
            IsFailure: entity.isFailure ? entity.isFailure : '',
            RejectBaseType: entity.rejectBaseType ? entity.rejectBaseType.name : '',
            ProsecutorName: entity.prosecutorName ? entity.prosecutorName : '',
            ProsecutorPosition: entity.prosecutorPosition ? entity.prosecutorPosition : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Принятая мера
     * @param entity
     * @param appeal
     */
    public prepareEntityViolationMeasure(entity, appeal) {
        const result: any = {
            Materials: entity.materials && Array.isArray(entity.materials) ? entity.materials.map(item => item.text) : [],
            SumAdmFine: entity.sumAdmFine ? entity.sumAdmFine.name : '',
            IsPaid: entity.isPaid ? entity.isPaid : false,
            Win: entity.win ? entity.win : '',
            PunishmentType: entity.punishmentType ? entity.punishmentType : '',
            PayeeDetails: entity.payeeDetails ? entity.payeeDetails : '',
            Violations: entity.violations ? entity.violations.name : '',
            SumAdmFineStatus: entity.sumAdmFineStatus ? entity.sumAdmFineStatus : '',
            Knm: entity.knm ? entity.knm.name : '',
            ControlLists: entity.controlLists ? entity.controlLists : '',
            damageType: entity.damageType ? entity.damageType : '',
            TypeActionTaken: entity.typeActionTaken ? entity.typeActionTaken.name : '',
            ValueActionTaken: entity.valueActionTaken ? entity.valueActionTaken : '',
            DateStart: entity.dateStart ? moment(entity.dateStart).format('DD.MM.YYYY') : '',
            DateEnd: entity.dateEnd ? moment(entity.dateEnd).format('DD.MM.YYYY') : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Ущерб охраняемой законом ценности
     * @param entity
     * @param appeal
     */
    public prepareEntityDamage(entity, appeal) {
        const result: any = {
            LegallyProtected: entity.legallyProtected ? entity.legallyProtected : '',
            ErpKnmKinds: entity.erpKnmKinds ? entity.erpKnmKinds.name : '',
            DamageType: entity.damageType ? entity.damageType.name : '',
            NameDamage: entity.nameDamage ? entity.nameDamage : '',
            UnitOfMeasurement: entity.unitOfMeasurement ? entity.unitOfMeasurement.name : '',
            AmountDamage: entity.amountDamage ? entity.amountDamage : '',
            AmountDamageRub: entity.amountDamageRub ? entity.amountDamageRub : '',
            ProbabilityDamage: entity.probabilityDamage ? entity.probabilityDamage : '',
            InformationSource: entity.informationSource ? entity.informationSource : '',
            CompensatedDamage: entity.compensatedDamage ? entity.compensatedDamage : '',
        };
        result.KndInfo = this.findKndInfo(appeal);

        return result;
    }

    /**
     * Подготовка данных для сведения: Заключение о соответствии
     * @param entity
     * @param appeal
     */
    public prepareEntityConformityConclusion(entity, appeal) {
        const appealLinksData: any = this.appealService.appealLinksData;
        const result: any = {
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            Date: entity.date ? this.getDate(entity.date) : '',
            DateFormatted: entity.date ? this.getDate(entity.date, true) : '',
            CaseNumber: appealLinksData && appealLinksData.supervisoryCase ? appealLinksData.supervisoryCase.auid : '',
            Object: {
                Name: appeal.objects && appeal.objects[0] && appeal.objects[0].name ? appeal.objects[0].name : '',
                Specifications: '',
                Construct: '',
                ObjectsAddress: appeal.objects && appeal.objects[0] && appeal.objects[0].address
                                ? PrintUtilities.PrintAddressAsLine(appeal.objects[0].address, true) : '',
                Energetic: this.getField(entity.energetic),
            },
            Permit: {
                PermitNumber: '',
                PermitDate: '',
                PermitDateFormatted: '',
                PermitIssued: '',
                PermitTerm: '',
            },
            Expertise: {
                ExpertiseNumber: '',
                ExpertiseDate: '',
                ExpertiseDateFormatted: '',
                ExpertiseIssued: '',
            },
            Ecolog: {
                EcologNumber: '',
                EcologDate: '',
                EcologDateFormatted: '',
                EcologIssued: '',
            },
            StartConstruct: entity.startConstruct ? this.getDate(entity.startConstruct) : '',
            StartConstructFormatted: entity.startConstruct ? this.getDate(entity.startConstruct, true) : '',
            EndConstruct: entity.endConstruct ? this.getDate(entity.endConstruct) : '',
            EndConstructFormatted: entity.endConstruct ? this.getDate(entity.endConstruct, true) : '',
            ProjectDocument: this.getField(entity.projectDocument),
            EnergyEfficiency: this.getField(entity.energyEfficiency),
            ClassEfficiency: this.getField(entity.classEfficiency),
            ActNumber: '',
            ActDate: '',
            ActDateFormatted: '',
        };
        const act = this.findEntity(appeal, this.entityTypes.act.code);
        if (act) {
            result.ActNumber = this.getField(act.number);
            result.ActDate = act.date ? this.getDate(act.date) : '';
            result.ActDateFormatted = act.date ? this.getDate(act.date, true) : '';
        }
        const program = appealLinksData.inspectionProgramGsn;
        if (program && program.gsnNotice) {
            const notice = program.gsnNotice;
            result.Permit = {
                PermitNumber: this.getField(notice.numberPermission),
                PermitDate: this.getDate(notice.datePermission),
                PermitDateFormatted: this.getDate(notice.datePermission, true),
                PermitIssued: this.getField(notice.issuedPermission),
                PermitTerm: '',
                PermitPeriodBegin: this.getDate(notice.periodPermissionBegin),
                PermitPeriodBeginFormatted: this.getDate(notice.periodPermissionBegin, true),
                PermitPeriodEnd: this.getDate(notice.periodPermissionEnd),
                PermitPeriodEndFormatted: this.getDate(notice.periodPermissionEnd, true),
            };
            result.Expertise = {
                ExpertiseNumber: this.getField(notice.numberExpertise),
                ExpertiseDate: this.getDate(notice.dateExpertise),
                ExpertiseDateFormatted: this.getDate(notice.dateExpertise, true),
                ExpertiseIssued: this.getField(notice.issuedExpertise),
            };
            result.Ecolog = {
                EcologNumber: this.getField(notice.numberEcology),
                EcologDate: this.getDate(notice.dateEcology),
                EcologDateFormatted: this.getDate(notice.dateEcology, true),
                EcologIssued: this.getField(notice.issuedEcology),
                EcologPeriodBegin: this.getDate(notice.periodEcologyBegin),
                EcologPeriodBeginFormatted: this.getDate(notice.periodEcologyBegin, true),
                EcologPeriodEnd: this.getDate(notice.periodEcologyEnd),
                EcologPeriodEndFormatted: this.getDate(notice.periodEcologyEnd, true),
            };
        }

        return result;
    }

    /**
     * Подготовка данных для сведения: Решение об отказе в выдаче заключения о соответствии
     * @param entity
     * @param appeal
     */
    public prepareEntityRejectConformityConclusion(entity, appeal) {
        const appealLinksData: any = this.appealService.appealLinksData;
        const result: any = {
            Place: entity.place ? PrintUtilities.PrintAddressAsLine(entity.place, true) : '',
            Date: entity.date ? this.getDate(entity.date) : '',
            DateFormatted: entity.date ? this.getDate(entity.date, true) : '',
            CaseNumber: appealLinksData && appealLinksData.supervisoryCase ? appealLinksData.supervisoryCase.auid : '',
            Object: {
                Name: appeal.objects && appeal.objects[0] && appeal.objects[0].name ? appeal.objects[0].name : '',
                Specifications: '',
                Construct: '',
                ObjectsAddress: appeal.objects && appeal.objects[0] && appeal.objects[0].address
                    ? PrintUtilities.PrintAddressAsLine(appeal.objects[0].address, true) : '',
            },
            PermitData: {
                PermitNumber: '',
                PermitDate: '',
                PermitDateFormatted: '',
                PermitIssued: '',
                PermitTerm: '',
            },
            Expertise: {
                ExpertiseNumber: '',
                ExpertiseDate: '',
                ExpertiseDateFormatted: '',
                ExpertiseIssued: '',
            },
            Ecolog: {
                EcologNumber: '',
                EcologDate: '',
                EcologDateFormatted: '',
                EcologIssued: '',
            },
            StartConstruct: entity.startConstruct ? this.getDate(entity.startConstruct) : '',
            StartConstructFormatted: entity.startConstruct ? this.getDate(entity.startConstruct, true) : '',
            EndConstruct: entity.endConstruct ? this.getDate(entity.endConstruct) : '',
            EndConstructFormatted: entity.endConstruct ? this.getDate(entity.endConstruct, true) : '',
            ProjectDocument: this.getField(entity.projectDocument),
            ActNumber: '',
            ActDate: '',
            ActDateFormatted: '',
        };
        const act = this.findEntity(appeal, this.entityTypes.act.code);
        if (act) {
            result.ActNumber = this.getField(act.number);
            result.ActDate = act.date ? this.getDate(act.date) : '';
            result.ActDateFormatted = act.date ? this.getDate(act.date, true) : '';
        }
        const program = appealLinksData.inspectionProgramGsn;
        if (program && program.gsnNotice) {
            const notice = program.gsnNotice;
            result.Permit = {
                PermitNumber: this.getField(notice.numberPermission),
                PermitDate: this.getDate(notice.datePermission),
                PermitDateFormatted: this.getDate(notice.datePermission, true),
                PermitIssued: this.getField(notice.issuedPermission),
                PermitTerm: '',
                PermitPeriodBegin: this.getDate(notice.periodPermissionBegin),
                PermitPeriodBeginFormatted: this.getDate(notice.periodPermissionBegin, true),
                PermitPeriodEnd: this.getDate(notice.periodPermissionEnd),
                PermitPeriodEndFormatted: this.getDate(notice.periodPermissionEnd, true),
            };
            result.Expertise = {
                ExpertiseNumber: this.getField(notice.numberExpertise),
                ExpertiseDate: this.getDate(notice.dateExpertise),
                ExpertiseDateFormatted: this.getDate(notice.dateExpertise, true),
                ExpertiseIssued: this.getField(notice.issuedExpertise),
            };
            result.Ecolog = {
                EcologNumber: this.getField(notice.numberEcology),
                EcologDate: this.getDate(notice.dateEcology),
                EcologDateFormatted: this.getDate(notice.dateEcology, true),
                EcologIssued: this.getField(notice.issuedEcology),
                EcologPeriodBegin: this.getDate(notice.periodEcologyBegin),
                EcologPeriodBeginFormatted: this.getDate(notice.periodEcologyBegin, true),
                EcologPeriodEnd: this.getDate(notice.periodEcologyEnd),
                EcologPeriodEndFormatted: this.getDate(notice.periodEcologyEnd, true),
            };
        }

        return result;
    }

    public getDate(date, isFormat = false, format = 'DD.MM.YYYY') {
        return date ? (isFormat ? moment(date).format(format) : date) : '';
    }

    public getField(field) {
        return field ? field : '';
    }

    /**
     * Поиск сведения по коду
     * @param appeal
     * @param entityCode
     */
    public findEntity(appeal, entityCode) {
        let entity;
        if (appeal.subservice && appeal.subservice.entities && this.entityTypes[entityCode]) {
            entity = appeal.subservice.entities.filter(item => item.type === this.entityTypes[entityCode].code);
            if (entity.length !== 0) {
                entity = entity[entity.length - 1];
            }
        }

        return entity;
    }

    /**
     * Поиск информации о КНД
     * @param appeal
     */
    public findKndInfo(appeal) {
        const data: any = {};
        if (appeal.subservice.kndInfo) {
            data.KndKind = appeal.subservice.kndInfo.kndKind ? appeal.subservice.kndInfo.kndKind.name : '';
            data.KndForm = appeal.subservice.kndInfo.kndForm ? appeal.subservice.kndInfo.kndForm.name : '';
            data.KndType = appeal.subservice.kndInfo.kndType ? appeal.subservice.kndInfo.kndType.name : '';
        }

        return data;
    }

    public generateRequirementsData(appeal, service) {
        const serviceRequirements = service.requirements;
        const requirements = [];
        appeal.objects.forEach(subject => {
            const requirement = {
                Subject: subject.shortHeader,
                Objects: [],
            };
            if (subject.objects && subject.objects.length > 0) {
                const subjectObjects = [];
                subject.objects.forEach(object => {
                    const subjectObject = {
                        Name: object.name,
                        Address: PrintUtilities.PrintAddressAsLine(object.address),
                        Requirements: [],
                    };
                    const objectRequirements = {};
                    const subjectsData = appeal.subservice.objects;
                    const subjectData = subjectsData.find(item => {
                        return item.guid === subject.guid;
                    });
                    if (subjectData && subjectData.objects) {
                        const objectData = subjectData.objects.find(item => {
                            return item.guid === object.guid;
                        });
                        if (objectData.requirements) {
                            objectData.requirements.forEach(objectRequirement => {
                                objectRequirements[objectRequirement.guid] = objectRequirement.result;
                            });
                        }
                    }
                    if (serviceRequirements && serviceRequirements.length > 0) {
                        serviceRequirements.forEach(serviceRequirement => {
                            const addRequirement = {
                                Name: serviceRequirement.title,
                                Result: objectRequirements[serviceRequirement.guid] ? objectRequirements[serviceRequirement.guid] : '',
                            };
                            subjectObject.Requirements.push(addRequirement);
                        });
                    }

                    subjectObjects.push(subjectObject);
                });
                requirement.Objects = subjectObjects;
            }

            requirements.push(requirement);
        });

        return requirements;
    }

    /**
     * Формирование данных для платежной квитанции
     * @param appeal
     * @param document
     * @returns {{Receiver: {Name: string; INN: string; KPP: string; Bank: string; Account: string; KS: number; OKTMO: string; KBK: string; BIK: string}; Info: {Sum: string; Purpose: string}; PayerGuid: any; QR: any}}
     */
    public generatePaymentData(appeal, document) {
        const payment = {
            Receiver: {
                Name: document.payment.recipient ? document.payment.recipient : '',
                INN: document.payment.recipientInn ? document.payment.recipientInn : '',
                KPP: document.payment.recipientKpp ? document.payment.recipientKpp : '',
                Bank: document.payment.recipientBankName ? document.payment.recipientBankName : '',
                Account: document.payment.recipientAccount ? document.payment.recipientAccount : '',
                KS: document.payment.recipientCorrespondentAccount ? document.payment.recipientCorrespondentAccount : 0,
                OKTMO: document.payment.recipientOktmo ? document.payment.recipientOktmo : '',
                KBK: document.payment.kbk ? document.payment.kbk : '',
                BIK: document.payment.recipientBankBik ? document.payment.recipientBankBik : '',
            },
            Info: {
                Sum: document.payment.amountKop ? document.payment.amountKop / 100 : '0',
                SumInText: document.payment.amountKop ? this.printUtilityService.sumToString(document.payment.amountKop) : '',
                Purpose: document.payment.destination ? document.payment.destination : '',
            },
            PayerGuid: document.payerGuid,
            PaymentQR: null,
        };
        const qrItems = ['ST00012'];
        qrItems.push('Name=' + (document.payment.recipient ? document.payment.recipient : ''));
        qrItems.push('PersonalAcc=' + (document.payment.recipientAccount ? document.payment.recipientAccount : ''));
        qrItems.push('BankName=' + (document.payment.recipientBankName ? document.payment.recipientBankName : ''));
        qrItems.push('BIC=' + (document.payment.recipientBankBik ? document.payment.recipientBankBik : ''));
        qrItems.push('CorrespAcc=' + (document.payment.recipientCorrespondentAccount ? document.payment.recipientCorrespondentAccount : '0'));
        if (document.payment.recipientInn) {
            qrItems.push('PayeeINN=' + document.payment.recipientInn);
        }
        if (document.payment.recipientKpp) {
            qrItems.push('KPP=' + document.payment.recipientKpp);
        }
        if (document.payment.destination) {
            qrItems.push('Purpose=' + document.payment.destination);
        }
        if (document.payment.kbk) {
            qrItems.push('CBC=' + document.payment.kbk);
        }
        if (document.payment.recipientOktmo) {
            qrItems.push('OKTMO=' + document.payment.recipientOktmo);
        }
        if (document.payment.amountKop) {
            qrItems.push('Sum=' + document.payment.amountKop);
        }
        const payer = appeal.objects.find(item => {
            return item.guid === document.payerGuid;
        });
        if (payer.data.person.lastName) {
            qrItems.push('LastName=' + payer.data.person.lastName);
        }
        if (payer.data.person.firstName) {
            qrItems.push('FirstName=' + payer.data.person.firstName);
        }
        if (payer.data.person.middleName) {
            qrItems.push('MiddleName=' + payer.data.person.middleName);
        }
        const payerAddress = PrintUtilities.PrintAddressAsLine(payer.data.person.registrationAddress, true);
        if (payerAddress) {
            qrItems.push('PayerAddress=' + payerAddress);
        }
        const documentItems = [];
        if (payer.data.person.documentType) {
            documentItems.push(payer.data.person.documentType[0].text);
            const catalogueDocuments = this.storage.getItem('catalogueDocuments');
            const catalogueDocument = catalogueDocuments.find(item => {
                return item.code === payer.data.person.documentType[0].id;
            });
            if (catalogueDocument && catalogueDocument.gisGmpCode) {
                qrItems.push('DocType=' + catalogueDocument.gisGmpCode);
            }
        }
        if (payer.data.person.documentSeries || payer.data.person.documentNumber) {
            documentItems.push(payer.data.person.documentSeries);
            qrItems.push('PayerIdNum=' + (payer.data.person.documentSeries ? payer.data.person.documentSeries : '') + (payer.data.person.documentNumber ? payer.data.person.documentNumber : ''));
        }
        if (payer.data.person.documentNumber) {
            documentItems.push(payer.data.person.documentNumber);
        }
        if (documentItems.length > 0) {
            qrItems.push('Document=' + documentItems.join(' '));
        }

        if (payer.data.person.inn) {
            qrItems.push('PayerINN=' + payer.data.person.inn);
        }
        // qrItems.push('DocIdx=0'); // индекс документа
        payment.PaymentQR = qrItems.join('|');

        return payment;
    }

    /**
     * Формирование в структуре base.xml данных по заявителю (доверителю), на которого формируется комплексная ПФ
     * @param applicationStructure
     * @param appeal
     * @param applicantGuid
     */
    private _generateApplicantIdDataInComplexAppeal(applicationStructure, appeal, applicantGuid) {
        let applicantType;
        let representativeGuid;
        // Если в одной из услуг выбранный участник является заявителем, считаем, что и во всем деле он заявитель
        if (!applicantType || applicantType !== 'applicant') {
            if (appeal.subservice.objects && appeal.subservice.objects.length > 0) {
                const applicantDataInSubservice = appeal.subservice.objects.find(item => item.guid === applicantGuid);
                if (applicantDataInSubservice) {
                    if (applicantDataInSubservice.subKind && applicantDataInSubservice.subKind.type) {
                        if (applicantDataInSubservice.subKind.type.indexOf('applicant') !== -1) {
                            applicantType = 'applicant';
                        } else {
                            applicantType = 'principal'; // доверитель
                            if (applicantDataInSubservice.representative) {
                                representativeGuid = applicantDataInSubservice.representative.guid;
                            }
                        }
                    }
                }
            }
        }

        applicationStructure.ApplicantInComplex = {ID: applicantGuid};
        if (applicantType && applicantType === 'principal') {
            applicationStructure.ApplicantInComplex.RepresentativeID = representativeGuid ? representativeGuid : '';
        }
    }

    /**
     * Циклически формирует структуру выбранных вариантов в деле
     * @param selectedVariants - выбранные в услуге варианты
     * @param serviceVariants - варианты услуги
     * @returns {any}
     */
    private _generateVariantsNode(selectedVariants, serviceVariants) {
        const data: any = {};
        serviceVariants.forEach(variant => {
            if (variant.guid === selectedVariants[0].guid) {
                // data.CategoryId = variant.guid;
                data.CategoryId = variant.code;
                data.CategoryName = variant.name;
                data.Mnemonic = variant.mnemonic ? variant.mnemonic : null;
                if (variant.rguProcedureId) {
                    data.RguProcedureId = variant.rguProcedureId;
                }
                if (variant.rguProcedureName) {
                    data.RguProcedureTitle = variant.rguProcedureName;
                }
                if (variant.rguTargetId) {
                    data.RguTargetId = variant.rguTargetId;
                }
                if (variant.rguTargetName) {
                    data.RguTargetTitle = variant.rguTargetName;
                }
                if (variant.subVariants && variant.subVariants.length > 0) {
                    data.SelectedServiceCategory = this._generateVariantsNode(selectedVariants.slice(1), variant.subVariants);
                }
            }
        });

        return data;
    }

    /**
     * Формирует блок выбранных вариантов для дела
     * @param selectedVariant - услуга в деле
     * @param service - описание услуги
     * @returns {any}
     */
    private _generateVariantsStructure(selectedVariant, service) {
        let categories: any = {};
        if (selectedVariant) {
            categories = this._generateVariantsNode(JSON.parse(JSON.stringify(selectedVariant.selectedVariants)), service.variants);
        }

        return categories;
    }

    /**
     * Формирование блока по услугам комплексной услуги
     * @param appeal
     * @param subservice
     */
    private _prepareComplexServices(appeal, subservice) {
        const servicesInfo = [];
        servicesInfo.push(this._prepareServiceInfoInComplex(appeal.subservice, subservice, appeal));

        return servicesInfo;
    }

    /**
     * Формирование информации по отдельной услуге внутри комплекса
     * @param appealServiceData - блок услуги в деле
     * @param service - услуга
     * @param appeal - дело
     */
    private _prepareServiceInfoInComplex(appealServiceData, service, appeal) {
        return {
            Guid: appealServiceData.guid,
            Name: service.titles.title,
            ShortName: service.titles.shortTitle,
            ServiceId: service.serviceId,
            ClassificSubserviceId: service.classificSubserviceId ? service.classificSubserviceId : null,
            ClassificSubserviceName: service.classificSubserviceName ? service.classificSubserviceName : null,
            GovAgency: appealServiceData.responsibleOrganization.name,
            GovAgencyId: appealServiceData.responsibleOrganization.id,
            SelectedServiceCategory: this._generateVariantsStructure(appealServiceData.variant, service),
            Objects: {Object: this.printObjectsService.prepareObjectsInServiceData(appealServiceData, service)},
            XsdData: appealServiceData.xsdData ? appealServiceData.xsdData : null,
            VariantXsdData: appealServiceData.variantXsdData ? appealServiceData.variantXsdData : null,
            DependFromSubservices: this.getSubserviceDependences(appealServiceData, appeal),
            Reglament: this.printReglamentService.prepareReglament(service),
        };
    }

    /**
     * Определение наличия зависимости услуги от других услуг
     * @param appealServiceData - данные обрабатываемой услуги
     * @param appeal - дело
     */
    public getSubserviceDependences(appealServiceData, appeal) {
        if (appealServiceData.startAfterGuids && appealServiceData.startAfterGuids.length > 0) {
            return {SubserviceGuid: appealServiceData.startAfterGuids};
        }

        return null;
    }

    /**
     * Формирует информацию по истории обращения
     * @param appeal - обращение
     * @param appealHistoryItems - история обращения
     * @returns {} - итоговая информация по истории обращения
     */
    public prepareAppealHistoryData(appeal, appealHistoryItems) {
        return {
            HistoryOfDeal: {
                DealID: appeal.number ? appeal.number : null,
                Changes: {Change: this.prepareAppealHistoryItems(appealHistoryItems)},
            },
        };
    }

    /**
     * Формирует запись по истории обращения
     * @param appealHistoryItems - исходная запись истории обращения
     * @returns {Array} - сформированные данные для печатной формы
     */
    public prepareAppealHistoryItems(appealHistoryItems) {
        const historyItems = [];
        if (appealHistoryItems) {
            appealHistoryItems.forEach(item => {
                const historyItem: any = {
                    Date: this.printUtilityService.formatDateForPrintForm(item.dateCreation, true),
                    Description: item.description ? item.description : '',
                    OperatorFullName: item.userLastModification && item.userLastModification.name ? item.userLastModification.name : '',
                };
                historyItems.push(historyItem);
            });
        }

        return historyItems;
    }

    public prepareLicenseRegisterData(registryData, licenseData) {
        const subjectsData = {
            subjects: [registryData.subject],
        };
        const currentUser = this.storage.getItem('user');
        const currentOrg = this.storage.getItem('currentOrganization');
        const mainElement = cloneDeep(registryData);
        mainElement.RegistryUrlLQR = this.prepareRegistryURLQR(registryData._id);
        mainElement.ActivityTypes = {ActivityType: this.getMainElementActivityTypes(registryData.objects, 'xsdData.activityTypes')};
        let statusName;
        switch (licenseData.status) {
            case 'active':
                statusName = 'Действующая';
                break;
            case 'paused':
                statusName = 'Приостановлена';
                break;
            case 'annulled':
                statusName = 'Прекращена';
                break;
            default:
                statusName = 'Не определено';
        }
        mainElement.license = {
            number: licenseData.number,
            date: licenseData.dateIssued,
            status: licenseData.status,
            statusName: statusName,
            orderData: {
                type: 'order',
                number: licenseData.orderData.number,
                date: licenseData.orderData.date,
                dateFrom: licenseData.orderData.dateFrom,
            },
        };
        if (registryData.orderData) {
            mainElement.orderData = registryData.orderData;
        }
        this.correctDates(mainElement);

        const application: any = {
            Application: {
                Applicants: {Person: this.printObjectsService.prepareAppealPersons(subjectsData, null, null)},
                MainElement: mainElement,
                TechnicianSignature: {
                    FullName: currentUser.name,
                    Position: currentUser.position ? currentUser.position : 'специалист',
                    Date: this.printUtilityService.formatDateForPrintForm(null, false, null, true),
                    Department: currentOrg.name ? currentOrg.name : '',
                    DateTime: this.printUtilityService.formatDateForPrintForm(null, true, null, true),
                    DateTimePlus20Min: this.printUtilityService.formatDateForPrintForm(null, true, {
                        sign: '+',
                        number: 20,
                        param: 'm',
                    }, true),
                },
            },
        };
        if (registryData.notApprovedObjects) {
            application.Application.NotApprovedObjects = registryData.notApprovedObjects;
        }

        return application;
    }

    public correctDates(data) {
        if (data) {
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'string') {
                    const processingKey = key.toLowerCase();
                    if (processingKey.indexOf('date') !== -1) {
                        data[key] = this.correctDate(data[key]);
                    }
                } else if (Array.isArray(data[key])) {
                    data[key].forEach(element => {
                        this.correctDates(element);
                    });
                } else if (typeof data[key] === 'object') {
                    this.correctDates(data[key]);
                }
            });
        }
    }

    public correctDate(date, format = 'YYYY-MM-DD') {
        return date ? moment(date).format(format) : null;
    }

    public prepareRegistryURLQR(registryEntryId, urlPrefix = '') {
        return (urlPrefix ? urlPrefix : 'https://knd.gov.ru/registry-entry?id=') + registryEntryId;
    }

    public getMainElementActivityTypes(objects, activityTypesFieldPath) {
        const activityTypes = {};

        objects.forEach(object => {
            const objectActivityTypes = ObjectUtilities.GetPropertyByPath(object, activityTypesFieldPath);

            if (objectActivityTypes) {
                objectActivityTypes.forEach(activityTypeData => {
                    if (activityTypeData.activityType) {
                        const activityObject = defaultTo(get(object, 'address.fullAddress'), object.name);

                        if (!activityTypes[activityTypeData.activityType.code]) {
                            activityTypes[activityTypeData.activityType.code] = {
                                type: activityTypeData.activityType,
                                objects: [],
                            };
                        }

                        if (activityObject) {
                            activityTypes[activityTypeData.activityType.code].objects.push(activityObject);
                        }
                    }
                });
            } else {
                const activityObject = defaultTo(get(object, 'address.fullAddress'), object.name);
                if (!activityTypes['noActivityTypes']) {
                    activityTypes['noActivityTypes'] = {
                        type: {code: 'noActivityTypes', name: ''},
                        objects: [],
                    };
                }
                if (activityObject) {
                    activityTypes['noActivityTypes'].objects.push(activityObject);
                }
            }
        });
        const allActivityTypes = [];
        Object.keys(activityTypes).forEach(activityTypeCode => {
            allActivityTypes.push({Name: activityTypes[activityTypeCode].type.name, Objects: {Object: activityTypes[activityTypeCode].objects}});
        });

        return allActivityTypes;
    }
}
