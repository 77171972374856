import { Injectable } from '@angular/core';

const snilsMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];

@Injectable()
export class UsersData {
    // Статусы
    public static Statuses = [
        {
            code: 'notActive',
            name: 'Активен',
            shortName: 'Активен',
            theme: 'success',
            labelText: 'АК',
            label: 'bg-success-50 text-success',
            background: 'bg-success-300',
            border: 'border-success-400',
            backgroundLite: 'bg-purple-50',
            borderTop: 'border-success-purple-400',
            borderLeft: 'border-left-success-400',
            text: 'text-success-400',
        },
    ];

    // Структура панели фильтрации
    public static FiltersPanelItems = [
        {
            name: 'ФИО/Логин',
            filters: [
                {
                    fields: ['name'],
                    type: 'input',
                    shortName: 'ФИО',
                    placeholder: 'ФИО',
                },
                {
                    fields: ['login'],
                    type: 'input',
                    shortName: 'Логин',
                    placeholder: 'Логин',
                },
            ],
        },
        {
            name: 'Документы',
            filters: [
                {
                    fields: ['snils'],
                    type: 'input',
                    shortName: 'СНИЛС',
                    placeholder: 'СНИЛС',
                    mask: snilsMask,
                },
            ],
        },
        {
            name: 'Организации',
            filters: [
                {
                    fields: ['sprOrganizations.id'],
                    type: 'dictionary',
                    labelField: 'shortName',
                    dictionary: {
                        name: 'organizations',
                        field: 'id',
                        baseSearch: {
                            field: 'isAuthorized',
                            operator: 'eq',
                            value: true,
                        },
                    },
                },
            ],
        },
        {
            name: 'Статус',
            shortName: 'Статус',
            filters: [
                {
                    fields: ['active'],
                    type: 'radioboxes',
                    isBoolean: true,
                    items: [
                        { code: 'true', name: 'Активный' },
                        {
                            code: 'false',
                            name: 'Заблокированный',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Тип',
            filters: [
                {
                    fields: ['isTechnical'],
                    type: 'checkbox',
                    placeholder: 'Технический специалист',
                },
            ],
        },
    ];
}
