<div [ngClass]="{'panel panel-shadow no-margin-bottom': !task || mode == 'edit', 'ml-20 mr-20 mt-20': mode == 'view' && !task, 'm-20': !task && mode == 'edit', 'panel-small': task}">
    <div class="panel-heading" *ngIf="!task || mode !== 'view'">
        <h4 class="panel-title">
            {{entity.name}}
            <div *ngIf="isCheckUnplan">
                <span class="badge bg-success-300 text-uppercase bg-info">Внеплановая проверка</span>
            </div>
            <div *ngIf="isCheckPlan">
                <span class="badge bg-success-300 text-uppercase bg-info">Плановая проверка</span>
            </div>
        </h4>
        <!-- Управляющие кнопки -->
        <div class="heading-elements">
            <button class="btn btn-default heading-btn" title="Редактировать" *ngIf="mode == 'view'" (click)="edit()">
                <b><i inlineSVG="assets/icon-edit.svg"></i></b>
            </button>

            <button class="btn btn-success-overline heading-btn text-uppercase" (click)="apply()" *ngIf="mode == 'edit' && allowEdit">
                Применить
            </button>

            <button class="btn btn-primary-overline heading-btn text-uppercase" (click)="cancel()" *ngIf="mode == 'edit'">
                Закрыть
            </button>
        </div>
    </div>
    <div [ngClass]="{'panel-body no-padding': !task || mode == 'edit', 'card-line': task && mode == 'view'}">

        <!-- Карточка предпросмотра -->
        <div *ngIf="mode == 'view'" [ngClass]="{'display-inline-block card-preview': task, 'p-15': !task}">
            <p class="mb-3">
                <span class="text-muted mr-5">Дата составления: </span>
                <ng-container *ngIf="entity.createDate">{{entity.createDate | date: 'shortDate'}}</ng-container>
                <em *ngIf="!entity.createDate">не указано</em>

                <span class="text-muted mr-5 ml-10">Орган прокуратуры: </span>
                <ng-container *ngIf="entity.prosecutor">{{entity.prosecutor.name}}</ng-container>
                <em *ngIf="!entity.prosecutor">не указано</em>
            </p>
        </div>

        <!-- Кнопки редактирования / просмотра в режиме просмотра сведения в карточке редактируемой задачи -->
        <div class="display-inline-block card-buttons text-right" *ngIf="mode == 'view' && task">
            <!-- Кнопка "Редактировать документ" / "Просмотреть документ" -->
            <button class="btn btn-default btn-sxs" (click)="edit()" type="button"  [title]="allowEdit ? 'Редактировать' : 'Просмотреть'">
                <i [ngClass]="{'icon-pencil3': allowEdit, 'icon-eye': !allowEdit}"></i>
            </button>
            <button class="btn btn-default btn-sxs" (click)="delete()" type="button" title="Удалить" *ngIf="allowEdit">
                <i class="icon-trash"></i>
            </button>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="p-20">
                <!-- Прокуратура -->
                <p *ngIf="!isChangeProsecutor">
                    <span class="text-slate mr-5">Прокуратура: </span>
                    <span *ngIf="entity.prosecutor">{{entity.prosecutor.name}}</span>
                    <span class="text-info anchor ml-5 text-size-small" (click)="isChangeProsecutor = true">изменить</span>
                </p>
                <div class="form-group" *ngIf="isChangeProsecutor">
                    <label>Орган прокуратуры</label>
                    <catalogue catalogName="nsiErpProsecutors" name="prosecutor"
                               [large]="true" projection="dictionaryCodeName"
                               [(ngModel)]="entity.prosecutor"
                               [returnFields]="['_id', 'code', 'name']" (onSelect)="isChangeProsecutor = false"></catalogue>
                </div>

                <p *ngIf="appeal.subjects && appeal.subjects.length > 0"><span class="text-muted">Субъект проверки:</span> {{appeal.objects[0].header}}</p>
                <ng-container *ngIf="appeal.subjects && appeal.subjects.length > 0 && appeal.objects && appeal.objects.length > 0">
                    <p>
                        <span class="text-muted">{{appeal.objects.length > 1 ? 'Объекты' : 'Объект'}} проверки:</span>
                        <ng-container *ngIf="appeal.objects.length === 1">
                            {{appeal.objects[0].name}} <ng-container *ngIf="appeal.objects[0].address"><span class="text-muted">Адрес:</span> {{getAddress(appeal.objects[0].address)}}</ng-container>
                        </ng-container>
                    </p>
                    <ul *ngIf="appeal.objects.length > 1">
                        <li *ngFor="let object of appeal.objects">{{object.name}} <ng-container *ngIf="object.address"><span class="text-muted">Адрес:</span> {{getAddress(object.address)}}</ng-container></li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="orderData">
                    <p *ngIf="orderData.dateStart"><span class="text-muted mr-5">Дата начала проверки:</span> {{orderData.dateStart | date : 'mediumDate'}}</p>
                    <p *ngIf="orderData.dateEnd"><span class="text-muted mr-5">Дата окончания проверки:</span> {{orderData.dateEnd | date : 'mediumDate'}}</p>
                    <p *ngIf="orderData.purposeKnm"><span class="text-muted mr-5">Цель проверки:</span> {{orderData.purposeKnm.name}}</p>
                    <p *ngIf="orderData.themes"><span class="text-muted mr-5">Предмет проверки:</span> {{orderData.themes[0].text}}</p>
                    <p *ngIf="orderData.objective"><span class="text-muted mr-5">Задачи проверки:</span> {{orderData.objective}}</p>
                    <ng-container *ngIf="orderData.eventKnm && orderData.eventKnm">
                        <p class="text-muted">Мероприятия:</p>
                        <ul>
                            <li *ngFor="let event of orderData.eventKnm">{{ event?.event?.name }} ({{ event.date | date: 'mediumDate' }})</li>
                        </ul>
                    </ng-container>
                </ng-container>

                <hr>

                <div class="row">
                    <div class="col-md-3">
                        <label>Дата составления документа</label>
                        <div class="form-group form-group-lg">
                            <evolenta-datepicker [large]="true" [(ngModel)]="entity.createDate" [valueAsString]="true" [disabled]="!allowEdit"></evolenta-datepicker>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Время составления документа</label>
                        <div class="form-group form-group-lg">
                            <input type="text" class="form-control input-xs" [(ngModel)]="entity.createTime" placeholder="Введите время" [disabled]="!allowEdit">
                        </div>
                    </div>
                </div>

                <!-- Приложения -->
                <div class="mb-15">
                    <appeal-entity-field-input
                        [multiple]="true"
                        [large]="true"
                        [entity]="entity"
                        [disabled]="!allowEdit"
                        [property]="'materials'"
                        [title]="'Приложения'">
                    </appeal-entity-field-input>
                </div>

                <!-- Должностное лицо КНО -->
                <div>
                    <appeal-entity-employee
                        [large]="true"
                        [entity]="entity"
                        property="employee"
                        [title]="'Должностное лицо КНО'"
                        [disabled]="!allowEdit"
                        [field]="['name','position']">
                    </appeal-entity-employee>
                </div>
            </div>
        </div>

        <div *ngIf="mode == 'edit'">
            <!-- Группы документов, привязанные к сведению -->
            <appeal-entity-documents [entity]="entity" [task]="task" [allowEdit]="allowEdit"></appeal-entity-documents>
        </div>
    </div>
</div>
