import { Component, Input, OnInit } from '@angular/core';
import {
    AccessService,
    FilesService,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { PrintingService } from '@evolenta/printing';
import { NgProgress } from 'ngx-progressbar';
import { UtilityService } from '../../../../../common/services/utility.service';
import { PrintReglamentService } from '../../../../../common/services/print/print-reglament.service';
import { Permission } from '../../../../../common/services/permission';
import { ServicesService } from '../../services.service';
import { SUBSERVICES_COLLECTION } from '../../../../../common/constants';

@Component({
    selector: 'service-preview',
    templateUrl: './service-preview.component.html',
    styles: ['.no-box-shadow { box-shadow: none; }'],
})
export class ServicePreviewComponent implements OnInit {
    @Input() public service: any; // обрабатываемая услуга
    @Input() public mode = 'full'; // режим отображения
    public isProcessingPrintForm = false; // флаг инициализации процесса формирования печатной формы
    public panels = {
        common: true,
        documents: true,
        variants: true,
        objects: true,
        rejectReasons: true,
        rejectDocsReasons: true,
        kndInfo: true,
    };
    public uniqueApplicantTypes = {};
    public applicantTypes;
    public objAppl = {};
    public rejectServiceReasons = [];
    public rejectReceiptDocsReasons = [];
    public terms: any;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public printForms = [];
    public permissions = Permission;

    public nsiErpKnmForms: { code; name }[];
    public nsiErpKnmTypes: { code; name }[];

    public isProcessConvertService = false;
    public convertedStandard;
    public localizations;

    public constructor(
        public utility: UtilityService,
        private rest: RestService,
        private toaster: ToasterService,
        private progressService: NgProgress,
        private printReglamentService: PrintReglamentService,
        private storage: StorageService,
        private filesService: FilesService,
        private servicesServices: ServicesService,
        private accessService: AccessService,
        private printingService: PrintingService,
        private translate: TranslateService
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        this.nsiErpKnmForms = this.storage.getItem('nsiErpKnmForms');
        this.nsiErpKnmTypes = this.storage.getItem('nsiErpKnmTypes');

        this.collectApplicantType();
        this.getTerms(); // Корректировка сроков оказания услуги
        this.getRejectReasons('rejectService'); // Причины отказа в оказании услуги
        this.getRejectReasons('rejectReceiptDocs'); // Причины отказа в предоставлении услуги

        if (this.mode === 'full' && this.accessService.hasAccess([this.permissions.Print_Forms])) {
            this.getPrintForms(); // Получение списка печатных форм
        }
    }

    private _loadTranslations() {
        this.translate.get(['common', 'events', 'services', 'standards']).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    /**
     * Получение списка печатных форм
     */
    public async getPrintForms() {
        const search = [
            {
                field: 'formType',
                operator: 'like',
                value: 'handout',
            },
            {
                orSubConditions: [
                    {
                        field: 'serviceIds',
                        operator: 'eq',
                        value: this.service.serviceId,
                    },
                    {
                        field: 'orgIds',
                        operator: 'eq',
                        value: this.currentOrganization._id,
                    },
                    {
                        andSubConditions: [
                            {
                                field: 'isGlobal',
                                operator: 'eq',
                                value: true,
                            },
                            {
                                field: 'notUseServiceIds',
                                operator: 'neq',
                                value: this.service.serviceId,
                            },
                            {
                                field: 'notUseOrgIds',
                                operator: 'neq',
                                value: this.currentOrganization._id,
                            },
                        ],
                    },
                ],
            },
        ];

        try {
            const response = await this.rest.search('printForms', { search: { search: search } });
            this.printForms = response;
        } catch (error) {
            this.toaster.error(this.localizations.events.notifications.print_form_errors_occurred + error);
        }
    }

    // TODO Move into angular service
    public getKndFormName(code) {
        const form = this.nsiErpKnmForms.filter((formItem) => formItem.code === code)[0];

        return form ? form.name : '-';
    }

    // TODO Move into angular service
    public getKndTypeName(code) {
        const type = this.nsiErpKnmTypes.filter((typeItem) => typeItem.code === code)[0];

        return type ? type.name : '-';
    }

    /**
     * Формирование печатной формы
     * @param printForm
     */
    public async printServiceInfo(printForm) {
        this.progressService.start();

        const reglamentData = { Reglament: this.printReglamentService.prepareReglament(this.service) };
        const additionalData = null;
        const thirdData = null;

        try {
            const response = await this.printingService.renderPrintForm(
                printForm._id,
                this.service._id,
                'subservices',
                reglamentData,
                additionalData,
                thirdData,
                this.currentOrganization._id,
                null
            );

            if (!printForm.files) {
                printForm.files = [];
            }

            this.filesService.downloadAndSaveFile(response.file._id, response.file.name);
        } catch (error) {
            if (error.errorMessage) {
                this.toaster.html(error.errorMessage);
            } else {
                this.toaster.html(error);
            }
        }

        this.progressService.done();
    }

    /**
     * Формирование массива видов участников без повторений
     */
    public collectApplicantType() {
        if (this.service.objects) {
            this.service.objects.objectKinds.forEach((item) => {
                item.subKinds.forEach((kind) => {
                    this.applicantTypes = kind.secondGroupName;
                    this.objAppl[this.applicantTypes] = true;
                });
            });
            this.uniqueApplicantTypes = Object.keys(this.objAppl);
        }
    }

    /**
     * Корректировка сроков оказания услуги
     */
    public getTerms() {
        this.terms = this.servicesServices.calculateSubserviceTerms(this.service);
    }

    /**
     * Получение текстового описания срока оказания услуги для вариант
     * @param variant - обрабатываемый вариант
     */
    public getTermText(variant) {
        const variantTerm = this.terms.find(
            (item) =>
                (variant && item.variantGuids && item.variantGuids.indexOf(variant.guid) !== -1) ||
                (!variant && !item.variantGuids)
        );

        return variantTerm ? ' - ' + variantTerm.textValue : '';
    }

    /**
     * Формирование массива причин отказа в оказании услуги
     */
    public getRejectReasons(type) {
        if (this.service.references && this.service.references.rejectReasons) {
            this.service.references.rejectReasons.forEach((reason) => {
                if (reason.type === type) {
                    this[type + 'Reasons'].push(reason.title);
                }
            });
        }
    }

    public async convertSubserviceToStandard() {
        this.isProcessConvertService = true;
        const createdStandard: any = await this.servicesServices.convertSubserviceToStandard(this.service);

        await this.rest.update(SUBSERVICES_COLLECTION, { _id: this.service._id, standardId: createdStandard._id });
        this.service.standardId = createdStandard._id;
        this.isProcessConvertService = false;
        this.toaster.success(this.localizations.services.sucessfully_converted);
    }

    public convertXsd(xsdId) {
        this.servicesServices.convertXsd(xsdId);
    }
}
