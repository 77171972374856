<div class="navbar navbar-default no-padding-left no-border-top navbar-inside-component">
    <breadcrumbs></breadcrumbs>
    <button class="btn btn-success btn-xs btn-main btn-labeled btn-labeled-right"
            type="button"
            [disabled]="isLoading || organization._id ? disabledSaveBtn([permission.Organization_Update]) : disabledSaveBtn([permission.Organization_Create])"
            (click)="save()">
        <b>
            <i class="icon-checkmark-circle"
              [ngClass]="{'flash': isLoading}">
            </i>
        </b>
        {{ localizations.common.save }}
    </button>

    <div class="ml-20 navbar-title mt-20">
        <h4>
            <ng-container *ngIf="!isProfileMode">
                {{ organization?._id ?
                    localizations.objects.organization.editing :
                    localizations.objects.organization.creation }}
            </ng-container>
            <span *ngIf="isProfileMode">
                {{ localizations.objects.kno_settings }}
            </span>
        </h4>
    </div>
    <div class="navbar-menu">
        <user-tile></user-tile>
    </div>
</div>


<div class="page-container">
    <!-- Меню разделов -->
    <div class="sidebar sidebar-secondary sidebar-default">
        <div class="sidebar-content">
            <div class="sidebar-category"
                 *ngFor="let tab of tabs"
                 [ngClass]="{
                    'active': activeTab == tab.code,
                    'cursor-pointer' : activeTab != tab.code
                 }"
                 (click)="activeTab = tab.code">
                <div class="category-title">
                    <span>
                        {{tab.name}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-main">
        <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-margin no-padding"
                            (onScrollEvent)="onScrollEvent($event)">
            <!-- Основные данные организации -->
            <ng-container *ngIf="activeTab == 'mainInfo'">
                <div class="panel panel-shadow m-20">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.name }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               type="text"
                                               name="orgName"
                                               [placeholder]="localizations.common.name"
                                               [(ngModel)]="organization.name"/>
                                    </div>
                                    <div class="validation-error-label"
                                         *ngIf="errors.name">
                                        <span>
                                            {{ this.localizations.organizations.notifications.errors.name.required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.short_name }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               type="text"
                                               name="shortName"
                                               [placeholder]="localizations.common.name"
                                               [(ngModel)]="organization.shortName"/>
                                    </div>
                                    <div class="validation-error-label"
                                         *ngIf="errors.shortName">
                                        <span>
                                            {{ this.localizations.organizations.notifications.errors.shortName.required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.code }}
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               type="text"
                                               name="code"
                                               [placeholder]="localizations.common.code"
                                               [(ngModel)]="organization.code"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ localizations.organizations.higher_level_organization }}
                                    </label>
                                    <catalogue catalogName="organizations"
                                               projection="unitsList"
                                               [placeholder]="localizations.organizations.enter_name"
                                               [baseSearch]="organizationsBaseSearch"
                                               [allowClear]="true"
                                               [showCode]="false"
                                               [(ngModel)]="organization.parentOrganization"
                                               (onSelect)="selectParentOrganization($event)"
                                               (onClear)="clearParentOrganization()">
                                    </catalogue>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ localizations.organizations.region }}
                                    </label>
                                    <catalogue catalogName="catalogueDistricts"
                                               projection="unitsList"
                                               [placeholder]="localizations.organizations.enter_region_name"
                                               [allowClear]="true"
                                               [showCode]="false"
                                               [(ngModel)]="organization.district"
                                               (onClear)="organization.district = null">
                                    </catalogue>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.type }}
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               type="text"
                                               name="orgType"
                                               [placeholder]="localizations.objects.organization.type"
                                               [(ngModel)]="organization.orgType"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ localizations.objects.base_address_for_address_elements }}
                                    </label>
                                    <evolenta-address-gar
                                            [large]="true"
                                            [(ngModel)]="organization.baseAddress">
                                    </evolenta-address-gar>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.ogrn }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               type="text"
                                               name="ogrn"
                                               [placeholder]="localizations.common.ogrn"
                                               [(ngModel)]="organization.ogrn"/>
                                    </div>
                                    <div class="validation-error-label"
                                         *ngIf="errors.ogrn">
                                        <span>
                                            {{ this.localizations.organizations.notifications.errors.ogrn.required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.inn }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               name="inn"
                                               type="text"
                                               [placeholder]="localizations.common.inn"
                                               [(ngModel)]="organization.inn"/>
                                    </div>
                                    <div class="validation-error-label"
                                         *ngIf="errors.inn">
                                        <span>
                                            {{ this.localizations.organizations.notifications.errors.inn.required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group form-group-lg">
                                    <label class="control-label">
                                        {{ localizations.common.kpp }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="controls">
                                        <input class="form-control"
                                               name="kpp"
                                               type="text"
                                               [placeholder]="localizations.common.kpp"
                                               [(ngModel)]="organization.kpp"/>
                                    </div>
                                    <div class="validation-error-label"
                                         *ngIf="errors.kpp">
                                        <span>
                                            {{ this.localizations.organizations.notifications.errors.kpp.required }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ localizations.organizations.in_fgru }}
                            </label>
                            <catalogue catalogName="frguOrganizations"
                                       name="frguCode"
                                       projection="dictionaryCodeName"
                                       [large]="true"
                                       [returnFields]="['_id', 'code', 'name']"
                                       [(ngModel)]="organization.frgu">
                            </catalogue>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ localizations.organizations.external_identifier }}
                                    </label>
                                    <input class="form-control"
                                           type="text"
                                           [(ngModel)]="organization.externalGuid">
                                </div>
                            </div>
                        </div>

                        <div class="mb-15 pt-10">
                            <input id="isAutorized"
                                   class="checkbox"
                                   type="checkbox"
                                   [value]="true"
                                   [(ngModel)]="organization.isAuthorized">
                            <label for="isAutorized">
                                {{ localizations.organizations.to_work_in_system }}
                            </label>
                        </div>

                        <div class="mb-15 pt-10">
                            <input id="isNotUseForEnvelope"
                                   class="checkbox"
                                   type="checkbox"
                                   [value]="false"
                                   [(ngModel)]="organization.isNotUseForEnvelope">
                            <label for="isNotUseForEnvelope">
                                {{ localizations.organizations.not_use_for_envelope }}
                            </label>
                        </div>

                        <div class="mb-15 pt-10">
                            <input id="isExtSystemExists"
                                   class="checkbox"
                                   type="checkbox"
                                   [value]="false"
                                   [(ngModel)]="organization.isExtSystemExists">
                            <label for="isExtSystemExists">
                                {{ localizations.organizations.is_ext_system_exists }}
                            </label>
                        </div>

                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.organizations.services_code }}
                            </label>
                            <!--TPPPGMU-711-->
                            <textarea class="form-control"
                                      rows="5"
                                      [(ngModel)]="organization.serviceCodes">
                            </textarea>
                            <small class="text-slate">
                                {{ localizations.organizations.without_spaces}}
                            </small>
                        </div>

                        <div class="form-group form-group-lg">
                            <label>
                                {{ localizations.organizations.proxy_services_code }}
                            </label>
                            <textarea class="form-control"
                                      rows="5"
                                      [(ngModel)]="organization.proxyServiceCodes">
                            </textarea>
                            <small class="text-slate">
                                {{ localizations.organizations.without_spaces}}
                            </small>
                        </div>

                        <multiple-catalogue projection="dictionaryCodeName"
                                            catalogName="registryTypes"
                                            [large]="true"
                                            [title]="localizations.organizations.licening_activity_kinds"
                                            [placeholder]="localizations.organizations.select_view"
                                            [(ngModel)]="organization.registryTypes">
                        </multiple-catalogue>

                        <ng-container *ngIf="stendSettings && stendSettings.form">
                            <div *ngFor="let block of stendSettings.form">
                                <ng-container *ngFor="let element of block">
                                    <div class="checkbox-inline">
                                        <input id="element{{element.code}}"
                                               class="checkbox"
                                               type="checkbox"
                                               [checked]="organization[element.code]"
                                               (change)="organization[element.code] = !organization[element.code]">
                                        <label for="element{{element.code}}">
                                            {{ element.name }}
                                        </label>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>


                    </div>
                </div>
            </ng-container>

            <!-- Мета-информация -->
            <div [hidden]="activeTab != 'metaInfo'">
                <div class="panel panel-shadow m-20">
                    <div class="panel-body no-padding">
                        <organization-meta [organization]="organization"
                                           #metaComponent>
                        </organization-meta>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="activeTab === 'printForms'">
                <organization-print-forms [organization]="organization"
                                          [printFormTypes]="organizationPrintForms">
                </organization-print-forms>
            </ng-container>

            <ng-container *ngIf="activeTab == 'processesSettings'">
                <organization-processes-settings [organization]="organization"
                                                 [processes]="organizationProcesses"
                                                 (onSaveOrganization)="save()">
                </organization-processes-settings>
            </ng-container>

            <div class="m-20" *ngIf="activeTab == 'requests'">
            </div>

            <div  class="m-20" *ngIf="activeTab == 'calendar'">
                <ul class="info-row active mb-20 no-margin-left no-margin-right">
                    <li *ngFor="let yearData of calendarData"
                        [ngClass]="{'active': activeCalendarTab && activeCalendarTab.year == yearData.year}"
                        (click)="changeCalendarWidget(yearData)">
                        {{ yearData.year }}
                    </li>
                </ul>
                <widget-calendar [year]="activeCalendarTab.year"
                                 [days]="activeCalendarTab.days"
                                 *ngIf="isVisibleCalendarWidget">
                </widget-calendar>
            </div>

        </evolenta-scrollbar>
    </div>
</div>

<ng-template #findedOrganizationsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.organizations.found }}
        </h4>
        <button class="close pull-right"
                type="button"
                aria-label="Close"
                (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul>
            <li *ngFor="let unit of findedOrganizations">
                <span class="text-muted mr-5">
                    {{ localizations.common.name }}:
                </span>
                {{unit.name}}
                <span class="text-muted ml-10 mr-5">
                    {{ localizations.common.short_name }}:
                </span>
                    {{unit.shortName}}
                <br>
                <span class="text-muted mr-5">
                    {{ localizations.common.inn }}:
                </span>
                {{unit.inn ? unit.inn : localizations.common.not_specified_m }}
                <span class="text-muted ml-10 mr-5">
                    {{ localizations.common.ogrn }}:
                </span>
                {{unit.ogrn ? unit.ogrn : localizations.common.not_specified_m }}
                <span class="text-muted ml-10 mr-5">
                    {{ localizations.common.kpp }}:
                </span>
                {{unit.kpp ? unit.kpp : localizations.common.not_specified_m }}
                <span class="anchor text-info text-size-small ml-10"
                      (click)="goToUnit(unit)">
                    {{ localizations.common.proceed_small }}
                </span>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-primary"
                (click)="save(true)">
            {{ localizations.common.create_anyway }}
        </button>
        <button class="btn btn-default"
                type="button"
                (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>
