import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { RestService, StorageService, ToasterService } from '@evolenta/core';
import { DynamicFormComponent } from '@evolenta/forms-constructor';
import { NsiService } from '../nsi.service';
import * as _ from 'lodash-es';

@Component({
    selector: 'nsi-edit-dictionary',
    templateUrl: 'nsi-edit-dictionary.component.html',
    styleUrls: ['../../../elements-list.css'],
})
export class NsiEditDictionaryComponent implements OnInit {
    public groupCode;
    public dictionary;
    public schema;

    @ViewChild('saveBtn', { static: false }) public saveBtn;
    @ViewChild('additionalData', { static: false }) public additionalDataComponent: DynamicFormComponent;
    public isRegionalDictionary = false;
    public currentOrganizationId = this.storage.getItem('currentOrganization')._id;

    public dictionaryModel = {}; // для просмотра формы доп.полей

    public isEditXsd = false;

    public topPosition = 0;

    public isProcessValidate = false;
    public isEdit = false;
    @ViewChild('editForm', { static: false }) public editForm: NgForm; // Форма для проверка валидности

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService,
        private nsiService: NsiService,
        private rest: RestService,
        private storage: StorageService
    ) {}

    public ngOnInit() {
        this.route.url.subscribe((data) => {
            this.groupCode = data[0].path;
            if (data[1].path === 'edit') {
                this.isEdit = true;
            }
            this.isRegionalDictionary = this.nsiService.structure.find(
                (item) => item.code === this.groupCode
            ).isRegional;
        });
        this.route.data.subscribe((response: any) => {
            this.dictionary = response.resolves;
            if (!this.dictionary.dictionaryParams) {
                this.dictionary.isDictionary = true;
                this.dictionary.dictionaryParams = {
                    section: this.groupCode,
                };
                if (this.isRegionalDictionary) {
                    this.dictionary.dictionaryParams.unitId = this.currentOrganizationId;
                }
            }
        });
    }

    public onScrollEvent(position) {
        this.topPosition = position;
    }

    /**
     * Возвратк к списку справочников
     */
    public back(): void {
        this.router.navigate(['/nsi/' + this.groupCode]);
    }

    public save() {
        this.isProcessValidate = true;
        if (this.editForm.valid) {
            if (!this.isEdit) {
                this.rest
                    .search('collectionsMeta', {
                        search: {
                            search: [
                                { field: 'collectionName', operator: 'eq', value: this.dictionary.collectionName },
                            ],
                        },
                    })
                    .then((data) => {
                        if (data.length > 0) {
                            this.toaster.error(
                                `Код '${this.dictionary.collectionName}' уже используется другим справочником`
                            );
                        } else {
                            this.createDictionary();
                        }
                    });
            } else {
                this.updateDictionary();
            }
        } else {
            this.toaster.error('На форме содержатся ошибки.');
        }
    }

    public createDictionary() {
        this.dictionary.parentEntries = 'collectionsMeta';
        this.dictionary.metaInfo = {};
        this.rest.create('collectionsMeta', this.dictionary).then(() => {
            this.nsiService.collectionsMeta.push(this.dictionary);
            this.router.navigate(['nsi', this.groupCode]);
        });
    }

    public updateDictionary() {
        this.rest.update('collectionsMeta', this.dictionary).then(() => {
            const findIndex = this.nsiService.collectionsMeta.findIndex(
                (item) => item.collectionName === this.dictionary.collectionName
            );
            this.nsiService.collectionsMeta[findIndex] = this.dictionary;
            this.router.navigate(['nsi', this.groupCode]);
        });
    }

    public formatNumber() {
        let numberStr = '12';
        if (this.dictionary.codeLength) {
            numberStr = numberStr.padStart(this.dictionary.codeLength, '0');
        }
        return (this.dictionary.codePrefix ? this.dictionary.codePrefix : '') + numberStr;
    }

    public deleteXsd() {
        this.dictionary.xsd = null;
    }

    public completeEditXsd() {
        this.isEditXsd = false;
    }

    public afterCreateXsd(xsdId) {
        this.dictionary.xsd = xsdId;
    }
}
