<ng-container *ngIf="erpPublishStatus">
<div class="panel panel-shadow m-20">
    <div class="panel-body">
        <h5>Статус размещения паспорта в ФГИС ЕРП: {{erpPublishStatus.name}}</h5>
        <div
            class="text-muted"
            *ngIf="erpPublishStatus.description"
            [innerHTML]="erpPublishStatus.description"
        ></div>
    </div>
</div>
</ng-container>
