import { Component, Input, OnInit } from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealEntitiesService } from '../../../appeal-entities.service';

@Component({
    selector: 'appeal-entity-requirements',
    templateUrl: 'appeal-entity-requirements.component.html',
    styles: ['.input-group { width: 100%; }'],
})
export class AppealEntityRequirementsComponent implements OnInit {
    @Input() public entity;
    @Input() public property;
    @Input() public catalogName;
    @Input() public title;
    @Input() public large;
    @Input() public small;

    public constructor(public appealEntitiesService: AppealEntitiesService) {}

    public ngOnInit() {
        if (this.property && !this.entity[this.property]) {
            this.entity[this.property] = [this.newItem()];
        }
    }

    /**
     * Возвращает новый объект
     */
    public newItem() {
        return {
            guid: CommonUtilities.GenerateGuid(),
            mandatoryReq: null,
            npa: null,
        };
    }

    /**
     * Добавление нового элемента массива
     */
    public addItem() {
        this.entity[this.property].push(this.newItem());
    }

    /**
     * Удаление ранее добавленного элемента
     * @param item
     */
    public deleteItem(item) {
        const findIndex = this.entity[this.property].findIndex((f) => f.guid === item.guid);
        this.entity[this.property].splice(findIndex, 1);
    }
}
