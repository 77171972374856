<!-- Группы документов, привязанные к сведению -->
<fieldset *ngIf="entityDocumentGroupId">
    <legend class="text-bold pl-20 no-margin-bottom cursor-pointer text-slate pt-10">
        <i class="icon-grid3  mr-5"></i> Документы
    </legend>
    <documents [appeal]="appealService.appeal"
               [subservice]="appealService.subservice"
               [onlyGroups]="[entityDocumentGroupId]"
               [task]="task"
               [allowEdit]="allowEdit"></documents>
</fieldset>
