<form #form="ngForm">
    <div class="row">
        <div class="col-md-8">
            <div class="form-group form-group-lg">
                <label class="control-label">
                    {{ localizations.common.name }}
                    <span class="text-danger"
                          *ngIf="fieldsRequirementsService.checkFieldRequired('name', object)">*</span>
                </label>
                <div class="controls">
                    <input type="text"
                           class="form-control"
                           name="orgName"
                           [required]="fieldsRequirementsService.checkFieldRequired('name', object)"
                           [disabled]="isDisabled('name')"
                           [placeholder]="localizations.objects.place_of_licensing_activity"
                           [(ngModel)]="object.name"
                           #orgName="ngModel"/>
                </div>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && orgName.errors">
                    <span *ngIf="orgName.errors.required">
                        {{ localizations.common.name_field_is_required }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group form-group-lg">
                <label>
                    {{ localizations.events.cadastral_number }}
                    <span class="text-danger"
                          *ngIf="fieldsRequirementsService.checkFieldRequired('cadastralNumber', object)">
                        *
                    </span>
                </label>
                <input class="form-control"
                       type="text"
                       name="cadastralNumber"
                       [required]="fieldsRequirementsService.checkFieldRequired('cadastralNumber', object)"
                       [disabled]="isDisabled('cadastralNumber')"
                       [placeholder]="localizations.objects.enter_cadastral_number"
                       [(ngModel)]="object.cadastralNumber"
                       #cadastralNumber="ngModel"/>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && cadastralNumber.errors">
                    <span *ngIf="cadastralNumber.errors.required">
                        {{ localizations.objects.cadastral_number_is_required }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group form-group-lg">
        <label>
            {{ localizations.events.object_address }}
            <span class="text-danger"
                  *ngIf="fieldsRequirementsService.checkFieldRequired('address', object)">
                *
            </span>
        </label>
        <evolenta-address-gar
                name="address"
                [large]="true"
                [disabled]="isDisabled('address')"
                [required]="fieldsRequirementsService.checkFieldRequired('address', object)"
                [(ngModel)]="object.address"
                #address="ngModel"
        ></evolenta-address-gar>
        <div class="validation-error-label"
             *ngIf="isProcessValidate && (address.errors || !utility.checkAddressValid(object.address))">
            <span *ngIf="address.errors && address.errors.required || !utility.checkAddressValid(object.address)">
                {{ localizations.objects.address_is_required }}
            </span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group form-group-lg">
                <label>
                    {{ localizations.objects.region }}
                    <span class="text-danger"
                          *ngIf="fieldsRequirementsService.checkFieldRequired('region', object)">
                        *
                    </span>
                </label>
                <catalogue
                    name="region"
                    catalogName="catalogueRegions"
                    [large]="true"
                    [returnFields]="['code','name']"
                    [disabled]="isDisabled('region')"
                    [required]="fieldsRequirementsService.checkFieldRequired('region', object)"
                    [(ngModel)]="object.region"
                    #region="ngModel">
                </catalogue>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && region.errors">
                    <span *ngIf="region.errors.required">
                        {{ localizations.objects.region_is_required }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group form-group-lg">
                <label>
                    {{ localizations.objects.latitude }}
                    <span class="text-danger"
                          *ngIf="fieldsRequirementsService.checkFieldRequired('latitude', object)">
                        *
                    </span>
                </label>
                <input class="form-control"
                       name="latitude"
                       type="text"
                       [(ngModel)]="object.latitude"
                       [disabled]="isDisabled('latitude')"
                       [required]="fieldsRequirementsService.checkFieldRequired('latitude', object)"
                       [placeholder]="localizations.objects.enter_latitude"
                       #latitude="ngModel"/>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && latitude.errors">
                    <span *ngIf="latitude.errors.required">
                        {{ localizations.objects.latitude_is_required }}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group form-group-lg">
                <label>
                    {{ localizations.objects.longitude }}
                    <span class="text-danger"
                          *ngIf="fieldsRequirementsService.checkFieldRequired('longitude', object)">
                        *
                    </span>
                </label>
                <input class="form-control"
                       name="longitude"
                       type="text"
                       [required]="fieldsRequirementsService.checkFieldRequired('longitude', object)"
                       [disabled]="isDisabled('longitude')"
                       [placeholder]="localizations.objects.enter_longitude"
                       [(ngModel)]="object.longitude"
                       #longitude="ngModel"/>
                <div class="validation-error-label"
                     *ngIf="isProcessValidate && longitude.errors">
                    <span *ngIf="longitude.errors.required">
                        {{ localizations.objects.longtitude_is_required }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</form>
