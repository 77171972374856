<div class="scroll-panels">
    <div class="scroll-panel" style="width: 100%">
        <div class="panel panel-flat">
            <evolenta-infinite-scrollbar scrollClass="panel-body" (scrolled)="loadData()">
                <ul class="list-feed mt-20 mb-20 mr-20 ml-30">
                    <li class="version-block">
                        <div>
                            <span class="text-muted">{{element.dateLastModification | date: 'shortTime'}}, {{element.dateLastModification | date: 'shortDate'}}</span>
                            <span class="text-primary ml-5">{{element.userLastModification?.name}}</span>
                            <label class="badge text-uppercase bg-primary-300 ml-15" style="margin-bottom: 0">Актуальная версия</label>
                            <span *ngIf="element.version" class="anchor text-info ml-15 go-to-version-button text-size-small" (click)="goToCurrentVersion()">просмотреть</span>
                        </div>
                    </li>
                    <li *ngFor="let version of versions" class="version-block">
                        <div>
                            <span class="text-muted">{{version.dateLastModification | date: 'shortTime'}}, {{version.dateLastModification | date: 'shortDate'}}</span>
                            <span class="text-primary ml-5">{{version.userLastModification?.name}}</span>
                            <label *ngIf="element.version && element.version === version.version" class="badge text-uppercase bg-orange-300 ml-15" style="margin-bottom: 0">Просматриваемая версия</label>
                            <span *ngIf="!element.version || element.version !== version.version" class="anchor text-info ml-15 go-to-version-button text-size-small" (click)="goToVersion(version)">просмотреть</span>
                            <span class="anchor text-info ml-15 go-to-version-button text-size-small" (click)="restoreVersion(version)">восстановить</span>
                        </div>
                    </li>
                </ul>
            </evolenta-infinite-scrollbar>
        </div>
    </div>
</div>
