<ng-container *ngIf="initialized">
    <div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
        <breadcrumbs></breadcrumbs>
        <!-- Функциональные кнопки-->
        <button class="btn btn-primary btn-main btn-xs btn-labeled btn-labeled-right"
                type="button"
                *ngIf="inMappingCreateMode()"
                (click)="createMapping()">
            {{ localizations.common.save }}
        </button>

        <!-- Кнопки авторизации + доп.меню -->
        <div class="navbar-menu">
            <user-tile></user-tile>
        </div>
    </div>

    <div class="page-container">
        <div class="full-width">
            <div class="panel panel-default no-border-top no-border-radius no-margin no-padding mt-10 ml-10 mr-10">
                <router-outlet>
                </router-outlet>
            </div>
        </div>
    </div>
</ng-container>
