<!-- Список групп документов -->
<ng-container *ngIf="!editedDocument">
    <fieldset *ngIf="byGroupTypes" [ngClass]="{'ml-20 mr-20': !showDocuments}">
        <legend class="text-bold" [ngClass]="{'no-margin-bottom pl-20': showDocuments}" *ngIf="showDocumentsHeader" (click)="showDocuments = !showDocuments">
            Документы
            <a class="control-arrow" [ngClass]="{'mr-20': showDocuments}">
                <i [ngClass]="{'icon-arrow-down32': showDocuments, 'icon-arrow-left32': !showDocuments}"></i>
            </a>
        </legend>
        <!-- Группы документов по типам: входящие, процессные, результирующие -->
        <div *ngIf="showDocuments">
            <ng-container *ngFor="let group of groupsByTypes; let fst = first">
                <fieldset class="" *ngIf="group.groups.length > 0">
                    <legend class="text-bold text-slate-600 pl-20 no-margin bg-slate-50 pt-10">{{group.name}}</legend>
                    <ng-container *ngTemplateOutlet="groups; context:{ onlyGroups: group.groups, onlyNotEmpty: group.code === 'isOutput'}"></ng-container>
                </fieldset>
            </ng-container>
        </div>
    </fieldset>

    <!-- Все группы документов  -->
    <div  [ngClass]="{'p-20': !onlyGroups}" *ngIf="!byGroupTypes">
        <ng-container *ngTemplateOutlet="groups; context:{ onlyGroups: onlyGroups}"></ng-container>
    </div>
</ng-container>

<ng-template #groups let-onlyGroups="onlyGroups" let-onlyNotEmpty="onlyNotEmpty">
    <common-appeal-subservice-document-groups [appeal]="appeal"
                                              [appealSubservice]="appeal.subservice"
                                              [task]="task"
                                              [onlyGroups]="onlyGroups"
                                              [allowEdit]="allowEdit"
                                              [onlyNotEmpty]="onlyNotEmpty"
                                              [mode]="mode"
                                              (onEditDocument)="editDocument($event)"
                                              (onDeleteDocument)="deleteDocument($event)"
                                              (onApplyDocument)="applyDocument($event)"
                                              (isCheckFileSign)="checkallowEdit($event)"
                                              (onPrintDocument)="sendPrintForm($event)"
                                              #subserviceDocumentGroups>
    </common-appeal-subservice-document-groups>

    <!-- Добавляем группу "Прочие документы" -->
    <common-appeal-subservice-document-groups [appeal]="appeal"
                                       (onEditDocument)="editDocument($event)"
                                       (onPrintDocument)="sendPrintForm($event)"
                                       (onDeleteDocument)="deleteDocument($event)"
                                       (isCheckFileSign)="checkallowEdit($event)"
                                       (onApplyDocument)="applyDocument($event)"
                                       #subserviceDocumentGroups
                                       *ngIf="!onlyGroups || onlyGroups.length == 0">
    </common-appeal-subservice-document-groups>
</ng-template>

<!-- Форма редактирования отдельного документа -->
<common-appeal-document-card *ngIf="editedDocument" mode="edit"
                      [activeTab]="activeTab"
                      [appeal]="appeal"
                      [task]="task"
                      [subservice]="subservice"
                      [sendEnvelope]="continueSendEnvelope"
                      [document]="editedDocument" [group]="editedDocumentGroup"
                      (onPrint)="sendPrintForm($event)"
                      (updateAppealAfterSendStatusEnvelope)="updateAppealAfterSendStatusEnvelope()"
                      (onApply)="applyDocument($event)"
                      #editCard>
</common-appeal-document-card>


<!-- Невидимая ссылка для корректной работы загрузки файлов -->
<a id='fileLink' download='' style="display:none;"></a>


<!-- модальное окно для выбора документов с целью формирования специальных печатных форм -->
<ng-template #selectDocumentsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Выберите вид документа</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
            <tr>
                <th colspan="2">Наименование документа</th>
                <th class="p5 text-center" width="100">Оригиналов</th>
                <th class="p5 text-center" width="100">Листов оригиналов</th>
                <th class="p5 text-center" width="100">Копий</th>
                <th class="p5 text-center" width="100">Листов копий</th>
            </tr>
            </thead>
            <ng-container *ngFor="let subservice of groupsForModal">
                <tbody>
                <tr *ngIf="subservice.title" class="bg-slate-50">
                    <td colspan="6" class="p-5">
                        <span class="control-arrow text-slate cursor-pointer mr-10" (click)="subservice.hide = !subservice.hide"><i class="" [ngClass]="{'icon-circle-down2': !subservice.hide, 'icon-circle-right2': subservice.hide}"></i></span>
                        <span class="text-muted">УСЛУГА: </span><span class="text-semibold">{{subservice.title}}</span>

                    </td>
                </tr>
                <ng-container *ngIf="!subservice.hide">
                    <ng-container *ngFor="let group of subservice.groups">
                        <tr>
                            <td colspan="6" class="pt-5 no-padding-right pb-5 pl-10">
                                <span class="text-semibold">{{group.name}}</span>
                                <!--<span class="badge badge-flat border-primary text-primary-600 ml-10">{{countSelectedDocumentsInGroup(group)}}&nbsp;/&nbsp;{{group.appealDocuments.length}}</span>-->
                                <!--<span class="control-arrow pull-right text-slate cursor-pointer" (click)="group.hide = !group.hide"><i class="" [ngClass]="{'icon-circle-down2': !group.hide, 'icon-circle-left2': group.hide}"></i></span>-->
                            </td>
                        </tr>
                        <ng-container *ngIf="!group.hide">
                            <tr *ngFor="let doc of group.documents" [ngClass]="{'bg-blue-50': doc.selected}">
                                <td class="text-center p-5">
                                    <input type="checkbox" class="checkbox" id="selectDocument{{doc.guid}}"
                                           [(ngModel)]="doc.selected">
                                    <label for="selectDocument{{doc.guid}}">&nbsp;</label>
                                </td>
                                <td class="no-padding-left pt-5 pr-5 pb-5">
                                    {{doc.name}}
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small" [(ngModel)]="doc.originals"></touch-spin>
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small" [(ngModel)]="doc.originalsSheets"></touch-spin>
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small" [(ngModel)]="doc.copies"></touch-spin>
                                </td>
                                <td class="p-5">
                                    <touch-spin mode="small" [(ngModel)]="doc.copiesSheets">></touch-spin>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
                </tbody>
            </ng-container>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="selectDocuments()">Выбрать</button>
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Отмена</button>
    </div>
</ng-template>


<!-- модальное окно для выбора заявителя -->
<ng-template #selectApplicantModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Выберите заявителя / доверителя</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="radio-block" *ngFor="let applicant of applicantsInComplexAppeal">
            <input type="radio" id="applicant{{applicant.guid}}" class="radio" (click)="selectApplicantForPrintForm(applicant)">
            <label for="applicant{{applicant.guid}}">{{applicant.header}}</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="printAfterSelectApplicant()">Выбрать</button>
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Отмена</button>
    </div>
</ng-template>


<!-- модальное окно для выбора параметров платежа -->
<ng-template #selectPaymentParamsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Выберите параметры платежа</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <!-- Выбор платежа -->
        <div  *ngIf="appealService.appealPayments.length > 1">
            <p class="text-semibold mb-10">Выберите платеж:</p>
            <div class="radio-block" *ngFor="let payment of appealService.appealPayments">
                <input type="radio" name="paymentType" id="payment{{payment.code}}" class="radio" (click)="selectedPayment = payment">
                <label for="payment{{payment.code}}">{{payment.name}}</label>
            </div>
        </div>

        <hr *ngIf="appealService.appealPayments.length > 1 && appeal.objects.length > 1">

        <!-- Выбор плательщика -->
        <div  *ngIf="appeal.objects.length > 1">
            <p class="text-semibold mb-10">Выберите плательщика:</p>
            <div class="radio-block" *ngFor="let payer of appeal.objects">
                <input type="radio" id="payer{{payer.guid}}" class="radio" (click)="selectedPayer = payer">
                <label for="payer{{payer.guid}}">{{payer.header}}</label>
            </div>
        </div>

        <!-- Заполнение суммы -->
        <hr *ngIf="(appealService.appealPayments.length == 1 && appealService.appealPayments[0].amountKop == 0 || selectedPayment && selectedPayment.amountKop == 0) && (appealService.appealPayments.length > 1 || appeal.objects.length > 1)">

        <div *ngIf="appealService.appealPayments.length == 1 && appealService.appealPayments[0].amountKop == 0 || selectedPayment && selectedPayment.amountKop == 0">
            <div class="text-semibold mb-10">Введите сумму платежа:</div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Рублей</label>
                        <input type="text" class="form-control" [(ngModel)]="customPaymentSum.rub" min="0" [textMask]="{mask: rubMask, guide: false}">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Копеек</label>
                        <input type="text" class="form-control" [(ngModel)]="customPaymentSum.kop" [textMask]="{mask: kopMask, guide: false}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="printAfterApplyPaymentParams()">Выбрать</button>
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Отмена</button>
    </div>
</ng-template>

<!-- модальное окно для выбора параметров платежа -->
<ng-template #printHtmlFormModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Печать</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!--<editor [(ngModel)]="htmlPrintFormContent" [init]="editorOptions"></editor>-->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Закрыть</button>
    </div>
</ng-template>
