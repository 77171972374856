import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    QueryList,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import { FilesService, HandlersService, RestService, StorageService, ToasterService } from '@evolenta/core';
import { Router } from '@angular/router';
import { CadesSignModalComponent } from '@evolenta/signing';
import { NgProgress } from 'ngx-progressbar';
import { SetExecutorComponent } from '@components/set-executor/set-executor.component';
import { TransferToUnitComponent } from '@components/transfer-to-unit/transfer-to-unit.component';
import { PrintingService } from '@evolenta/printing';
import { CommonUtilities, ObjectUtilities } from '@evolenta/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { AppealProcessService } from '../../../../knm/appeals/components/process/appeal-process.service';
import { CertificatesService } from '../../../../../common/services/certificates.service';
import { CommonAppealEntitiesComponent } from '../../../../common-appeals/components/appeal-entities/common-appeal-entities.component';
import { InternalHandlersService } from '../../../../../common/services/internal-handlers.service';
import { CommonStandardsService } from '../../../../common-standards/common-standards.service';
import { EntityProcessElementAdditionaDataTabComponent } from './entity-process-element-additiona-data-tab.component';
import { CommonProcessService } from '../../../common-process.service';
import { CommonAppealCheckListsComponent } from '../../../../common-appeals/components/appeal-check-lists/common-appeal-check-lists.component';
import { ProcessingEntitiesForRegistryService } from '../../../../entity-registers/processing-entities-for-registry.service';
import { TypesOfTasksEnum } from '../../../../../common/models/types-of-tasks.enum';

@Component({
    selector: 'entity-process-element-data',
    templateUrl: './entity-process-element-data.component.html',
})
export class EntityProcessElementDataComponent implements OnInit {
    @Input() public element; // текущая задача или событие
    @Input() public allowEdit = true;
    @Input() public data;

    @Output() public onEmptyData = new EventEmitter<any>();

    public elementData; // объект с параметрами задачи (события)

    public currentOrganization = this.storage.getItem('currentOrganization');
    public currentUser = this.storage.getItem('user');

    // вкладки с элементами
    public tabs = {
        entities: true,
        quickRequestsBlock: true,
        additionalDataBlock: true,
        documents: true,
        commonDocumentsBlock: true,
        checkListsBlock: true,
    };

    @ViewChildren('additionalDataTabComponent')
    public additionalDataTabComponents: QueryList<EntityProcessElementAdditionaDataTabComponent>;
    @ViewChild('setExecutor', { static: false }) public setExecutorComponent: SetExecutorComponent;
    @ViewChild('transferToUnit', { static: false }) public transferToUnitComponent: TransferToUnitComponent;
    @ViewChild('entities', { static: false }) public entitiesComponent: CommonAppealEntitiesComponent;
    @ViewChild('checkLists', { static: false }) public checkListsComponent: CommonAppealCheckListsComponent;

    @ViewChild('additionalDataBlock', { static: false }) public additionalDataBlock: TemplateRef<any>;
    @ViewChild('commonDocumentsBlock', { static: false }) public commonDocumentsBlock: TemplateRef<any>;
    @ViewChild('entitiesBlock', { static: false }) public entitiesBlock: TemplateRef<any>;
    @ViewChild('documentsBlock', { static: false }) public documentsBlock: TemplateRef<any>;
    @ViewChild('quickRequestsBlock', { static: false }) public quickRequestsBlock: TemplateRef<any>;
    @ViewChild('checkListsBlock', { static: false }) public checkListsBlock: TemplateRef<any>;

    @ViewChild(CadesSignModalComponent, { static: false }) public cadesSignModalComponent: CadesSignModalComponent;
    public file4Sign;
    public document4Sign;

    public isProcessingValidate = false;

    public editedProperty; // редактируемый в данный момент элемент (сведение)
    public externalData;

    public isProcessingInitHandler = false;

    public itemsForSelect;
    public selectedItem;
    public paramsForSelect;
    public modalRef: BsModalRef;
    @ViewChild('selectItemModal', { static: false }) public selectItemModal: TemplateRef<any>;

    public constructor(
        private router: Router,
        private rest: RestService,
        private storage: StorageService,
        private filesService: FilesService,
        private standardsService: CommonStandardsService,
        private progressService: NgProgress,
        private toaster: ToasterService,
        private processService: CommonProcessService,
        private printingService: PrintingService,
        private internalHandlersService: InternalHandlersService,
        private handlerService: HandlersService,
        private modalService: BsModalService,
        private oldProcessService: AppealProcessService,
        private processingEntitiesForRegistryService: ProcessingEntitiesForRegistryService
    ) {}

    public ngOnInit() {
        this.externalData = {
            entity: this.data.entity,
            appeal: this.data.entity,
            subservice: this.data.dataForProcessing.subservice,
            printForms: this.data.dataForProcessing.printForms,
            metaReglament: this.data.dataForProcessing.metaReglament,
            element: this.element,
            documentService: this.data.dataForProcessing.documentService,
        };
        if (!this.allowEdit) {
            Object.keys(this.tabs).forEach((tab) => {
                this.tabs[tab] = false;
            });
        }
        const elementField = this.element.type === 'USER_TASK' ? 'tasks' : 'catchEvents';
        const elementCompareField = this.element.type === 'USER_TASK' ? 'taskDefinitionKey' : 'activityId';
        this.elementData = cloneDeep(
            this.processService.getElementDataFromProcessInEntity(
                this.element,
                elementField,
                elementCompareField,
                this.data.processInEntity
            )
        );
        if (!this.elementData) {
            this.elementData = {};
        }
        this.elementData.executionId = this.element.executionId;
        this.elementData.params = {};
        this.initAdditionalDataTabs(); // Дополнительные данные
        this.initCommonDocuments(); // Настройка основных документов
        this.initDocuments(); // Документы дела
        this.initEntities();
        this.initCheckLists();
        if (this.allowEdit) {
            const isEmpty = this.checkEmptyData();
            if (!isEmpty) {
                this.runStartHandlers();
            }
        }
    }

    public runStartHandlers() {
        if (this.data.isOldVersion && this.elementData.useFunction && this.elementData.useFunction.onStart) {
            this.isProcessingInitHandler = true;
            // обработчик старой версии
            this.oldProcessService[this.elementData.useFunction.functionName](
                this.externalData.appeal,
                this.externalData.subservice,
                this.externalData.metaReglament,
                this.externalData.printForms,
                this.externalData.documentService
            ).then(() => {
                this.isProcessingInitHandler = false;
            });
        } else if (this.elementData.handlers) {
            this.isProcessingInitHandler = true;
            const startHandlers = this.elementData.handlers.filter((item) => item.event === 'onStart');
            const startBeforeHandlers = startHandlers.filter(
                (item) => item.useInternalHandler && item.internalHandler.event === 'startBefore'
            ); // запуск обработчиков при старте события (задачи)
            const startAfterHandlers = startHandlers.filter(
                (item) => item.useInternalHandler && item.internalHandler.event === 'startAfter'
            ); // запуск обработчиков при старте события (задачи)
            // запускаем внутренние обработчики, которые выполняются до основных скриптов (например, поиск лицензии по номеру)
            this.internalHandlersService
                .processingHandlers(startBeforeHandlers, 'startBefore', 'onStart', this.externalData)
                .then((beforeInternalHandlersResults) => {
                    // результат функций, стартующих до основного скрипта, передается через externalData.internalHandlerData, в них возможно изменение состава данных для обработки
                    const promises = [];
                    // запускаем все основные функции обработчиков (не внутренние) - они должны возвращать promise
                    startHandlers.forEach((handler) => {
                        promises.push(this.handlerService.runHandler(handler, this.externalData, true));
                    });

                    return Promise.all(promises).then((beforeHandlersResults) => {
                        let isComplete = true;
                        beforeHandlersResults.forEach((result) => {
                            if (result.itemsForSelect && result.itemsForSelect.length > 0) {
                                isComplete = false;
                                this.itemsForSelect = result.itemsForSelect;
                                this.paramsForSelect = result.paramsForSelect;
                            }
                        });
                        if (isComplete) {
                            // запуск внутренних функций, которые должны выполняться после основных скриптов (например, быстрые запросы, в основном скрипте задаются параметры запроса)
                            return this.internalHandlersService
                                .processingHandlers(startAfterHandlers, 'startAfter', 'onStart', this.externalData)
                                .then(
                                    (afterHandlersResults) => {
                                        afterHandlersResults.forEach((result) => {
                                            if (result.saveResultToXsd) {
                                                this.saveResultToXsd(result.data);
                                            }
                                        });
                                        this.isProcessingInitHandler = false;
                                    },
                                    (afterHandlersErrors) => {
                                        this.isProcessingInitHandler = false;
                                    }
                                );
                        } else {
                            this.modalRef = this.modalService.show(this.selectItemModal, { backdrop: 'static' });
                        }
                    });
                });
        }
    }

    public prepareItemName(item) {
        let result = '';
        const parts = this.paramsForSelect.itemNameRule.split('|');
        parts.forEach((part) => {
            const fieldParts = part.split('#');
            if (fieldParts[0] === 's') {
                result += fieldParts[1];
            } else {
                const fieldValue = ObjectUtilities.GetPropertyByPath(item, fieldParts[1]);
                if (fieldValue) {
                    result +=
                        fieldParts[2] && fieldParts[2] === 'date'
                            ? moment(fieldValue).format('DD.MM.YYYY')
                            : fieldValue;
                }
            }
        });

        return result;
    }

    public afterSelectItem() {
        if (this.selectedItem) {
            this.modalRef.hide();
            this.internalHandlersService[this.paramsForSelect.functionAfterSelect](
                this.externalData,
                this.selectedItem
            ).then((result) => {
                console.log('final result', result);
                this.isProcessingInitHandler = false;
            });
        } else {
            this.isProcessingInitHandler = false;
        }
    }

    public checkEmptyData() {
        if (
            (!this.elementData.commonDocuments ||
                this.elementData.commonDocuments.length === 0 ||
                this.elementData.commonDocuments.filter((item) => item.isUse || item.isPrint || item.isSign).length ===
                    0) &&
            (!this.elementData.additionalDataTabs || this.elementData.additionalDataTabs.length === 0) &&
            (!this.elementData.xsdTabs || this.elementData.xsdTabs.length === 0) &&
            !this.elementData.specialComponent &&
            (!this.elementData.documents || this.elementData.documents.length === 0) &&
            (!this.elementData.entities || this.elementData.entities.length === 0) &&
            (!this.elementData.checkLists || this.elementData.checkLists.length === 0)
        ) {
            // console.log('empty');
            this.onEmptyData.emit(true);

            return true;
        }

        return false;
    }

    public get isProcessing() {
        return this.data.processingElements.indexOf(this.element.guid) !== -1;
    }

    public initCommonDocuments() {
        this.elementData.params.commonDocuments = [];
        if (this.elementData && this.elementData.commonDocuments && this.elementData.commonDocuments.length > 0) {
            this.getCommonDocuments().then((commonDocuments) => {
                this.elementData.commonDocuments.forEach((document) => {
                    if (document.isUse || document.isPrint || document.isSign) {
                        const findDocument = commonDocuments.find((item) => item.guid === document.guid);
                        if (findDocument) {
                            const doc = cloneDeep(findDocument);
                            doc.isUse = !!document.isUse;
                            doc.isPrint = !!document.isPrint;
                            doc.isSign = !!document.isSign;
                            this.elementData.params.commonDocuments.push(doc);
                        }
                    }
                });
            });
        }
    }

    public getCommonDocuments() {
        const commonDocuments = this.storage.getItem('commonDocuments');
        if (!commonDocuments) {
            return this.rest.search('commonDocuments').then((documents) => {
                const docs = documents.map((item) => {
                    return {
                        guid: item.guid,
                        _id: item._id,
                        printForm: item.printForm,
                        name: item.name,
                        field: item.field,
                    };
                });
                this.storage.setItem('commonDocuments', docs);

                return docs;
            });
        } else {
            return Promise.resolve(commonDocuments);
        }
    }

    /**
     * Инициализация набора вкладок с дополнительными данными
     */
    public initAdditionalDataTabs() {
        this.elementData.params.xsdTabs = [];
        if (this.elementData) {
            const additionalData = this.elementData.additionalDataTabs
                ? this.elementData.additionalDataTabs
                : this.elementData.xsdTabs;
            if (
                additionalData &&
                additionalData.length > 0 &&
                this.data.dataForProcessing &&
                this.data.dataForProcessing.additionalDataTabs
            ) {
                additionalData.forEach((tab) => {
                    if (tab.isUse || tab.isUsed) {
                        const findTab = this.data.dataForProcessing.additionalDataTabs.find(
                            (item) => (item.guid && item.guid === tab.guid) || (item.code && item.code === tab.sperId)
                        );
                        if (findTab) {
                            this.elementData.params.xsdTabs.push(findTab);
                        }
                    }
                });
            }
        }
    }

    public initDocuments() {
        this.elementData.params.documentGroups = [];
        this.elementData.params.documentGroupsCodes = [];
        if (this.elementData.documents && this.elementData.documents.length > 0) {
            this.elementData.documents.forEach((document) => {
                if (document.isUsed || document.isRequired) {
                    const find = this.data.dataForProcessing.subservice.documentGroups.find(
                        (item) => item.code === document.sperId
                    );
                    if (find) {
                        this.elementData.params.documentGroups.push(find);
                        this.elementData.params.documentGroupsCodes.push(document.sperId);
                    }
                }
            });
        }
    }

    public initEntities() {
        this.elementData.params.entities = [];
        if (this.elementData && this.elementData.entities) {
            this.elementData.entities.forEach((entity) => {
                const find = this.data.dataForProcessing.subservice.entities.find(
                    (item) => item.sperId === entity.sperId
                );
                if (find) {
                    if (find.docGroupId) {
                        find.documentGroup = this.data.dataForProcessing.subservice.documentGroups.find(
                            (item) => item.guid === find.docGroupId
                        );
                        // Убираем группу документов, привязанную к сведению из общего состава групп раздела "Документы"
                        const findGroupIndex = this.elementData.params.documentGroups.findIndex(
                            (item) => item.code === find.docGroupId
                        );
                        if (findGroupIndex !== -1) {
                            this.elementData.params.documentGroups.splice(findGroupIndex, 1);
                        }
                        const findIndex = this.elementData.params.documentGroupsCodes.findIndex(
                            (item) => item === find.docGroupId
                        );
                        if (findIndex !== -1) {
                            this.elementData.params.documentGroupsCodes.splice(findIndex);
                        }
                    }
                    this.elementData.params.entities.push(find);
                }
            });
        }
    }

    public initCheckLists() {
        this.elementData.params.checkLists = [];
        if (this.elementData && this.elementData.checkLists) {
            this.elementData.checkLists.forEach((checkList) => {
                const find = this.data.dataForProcessing.subservice.checkLists.find(
                    (item) => item.guid === checkList.sperId
                );
                if (find) {
                    this.elementData.params.checkLists.push(find);
                }
            });
        }
    }

    public validateAdditionalData() {
        let isValid = true;
        if (this.additionalDataTabComponents && this.additionalDataTabComponents.length > 0) {
            this.additionalDataTabComponents.forEach((additionalDataComponent) => {
                isValid = !additionalDataComponent.checkValid() ? false : isValid;
            });
        }

        return isValid;
    }

    public validateCommonDocuments() {
        const errors = [];
        if (this.elementData.params.commonDocuments.length > 0) {
            const noExistFiles = [];
            const noSignFiles = [];
            this.elementData.params.commonDocuments.forEach((commonDocument) => {
                if (commonDocument.isPrint && !this.data.entity[commonDocument.field]) {
                    noExistFiles.push(commonDocument.name);
                } else if (
                    commonDocument.isSign &&
                    this.data.entity[commonDocument.field] &&
                    (!this.data.entity[commonDocument.field].signData ||
                        this.data.entity[commonDocument.field].signData.length === 0)
                ) {
                    noSignFiles.push(commonDocument.name);
                }
            });
            if (noExistFiles.length > 0) {
                errors.push('Не сформированы файлы для документов: ' + noExistFiles.join(', '));
            }
            if (noSignFiles.length > 0) {
                errors.push('Не подписаны файлы для документов: ' + noSignFiles.join(', '));
            }
        }

        return errors;
    }

    public checkTabShowTopBorder(tab) {
        let showTopBorder = false;
        let prevBlock = null;
        let isFind = false;
        Object.keys(this.tabs).forEach((blockCode, idx) => {
            if (blockCode === tab) {
                showTopBorder = prevBlock && this.tabs[prevBlock];
                isFind = true;
            }
            if (!isFind && this[blockCode]) {
                prevBlock = blockCode;
            }
        });

        return showTopBorder;
    }

    public validateSpecialComponents() {
        const errors = [];
        if (this.elementData.specialComponent) {
            if (this.setExecutorComponent && this.setExecutorComponent.validate()) {
                errors.push(this.setExecutorComponent.validate());
            }
            if (this.transferToUnitComponent && this.transferToUnitComponent.validate()) {
                errors.push(this.transferToUnitComponent.validate());
            }
        }

        return errors;
    }

    public validateDocuments() {
        const groupsForCurrentElement = [];
        const groupsWithErrors = [];
        if (this.elementData.documents) {
            this.elementData.documents.forEach((docGroup) => {
                if (docGroup.isRequired || docGroup.isSign) {
                    const group = this.data.dataForProcessing.subservice.documentGroups.find(
                        (item) => item.code === docGroup.sperId
                    );
                    if (group) {
                        groupsForCurrentElement.push({
                            guid: group.guid,
                            name: group.name,
                            isRequired: docGroup.isRequired,
                            isSign: docGroup.isSign,
                        });
                    }
                }
            });
        }
        if (groupsForCurrentElement.length > 0) {
            const entityDocuments = this.data.entity.documents;
            groupsForCurrentElement.forEach((group) => {
                const groupErrors = [];
                const groupDocumentsInEntity = entityDocuments.filter((item) => item.groupGuid === group.guid);
                if (groupDocumentsInEntity.length === 0) {
                    groupErrors.push('не добавлены документы');
                } else {
                    const documentsWithoutFiles = groupDocumentsInEntity.filter(
                        (item) => !item.files || item.files.length === 0
                    );
                    if (documentsWithoutFiles.length > 0) {
                        groupErrors.push('не добавлены файлы документов');
                    }
                    if (group.isSign) {
                        const documentsWithoutSignFiles = groupDocumentsInEntity.filter(
                            (item) => item.files && item.files.filter((elm) => !elm.signature).length > 0
                        );
                        if (documentsWithoutSignFiles.length > 0) {
                            groupErrors.push('файлы не подписаны ЭЦП');
                        }
                    }
                }
                if (groupErrors.length > 0) {
                    groupsWithErrors.push({ name: group.name, errors: groupErrors });
                }
            });
        }

        if (groupsWithErrors.length > 0) {
            let documentsErrors = '<ul>';
            groupsWithErrors.forEach((groupError) => {
                documentsErrors += '<li>' + groupError.name + ': ' + groupError.errors.join(', ') + '</li>';
            });
            documentsErrors += '</ul>';

            return 'Ошибка настройки документов в обязательных группах: ' + documentsErrors;
        }

        return null;
    }

    public validateEntities() {
        if (this.elementData.entities) {
            if (this.entitiesComponent && !this.entitiesComponent.isValid()) {
                return 'Ошибка заполнения формы сведения';
            } else {
                const notExistEntities = [];
                const requiredEntities = this.elementData.entities.filter((item) => item.isRequired);
                if (requiredEntities.length > 0) {
                    const appealEntities = this.data.entity.subservice.entities
                        ? this.data.entity.subservice.entities
                        : [];
                    requiredEntities.forEach((requiredEntity) => {
                        const entity = this.elementData.params.entities.find(
                            (item) => item.guid === requiredEntity.sperId
                        );
                        const isExistEntity = appealEntities.find((item) => item.type === entity.code);
                        if (!isExistEntity) {
                            notExistEntities.push(entity.name);
                        }
                    });
                }
                if (notExistEntities.length > 0) {
                    return (
                        'Не добавлены следующие обязательные сведения: &laquo;' +
                        notExistEntities.join('&raquo;, ') +
                        '&raquo;'
                    );
                }
            }
        }

        return null;
    }

    public isValid() {
        this.isProcessingValidate = true;

        let errors = [];

        // Проверка доп. данных
        if (!this.validateAdditionalData()) {
            errors.push('Ошибки заполнения дополнительной информации');
        }

        // Проверка общих документов
        errors = errors.concat(this.validateCommonDocuments());

        // Проверка спец.компонентов
        errors = errors.concat(this.validateSpecialComponents());

        const documentsErrors = this.validateDocuments();
        if (documentsErrors) {
            errors.push(documentsErrors);
        }

        const entitiesErrors = this.validateEntities();
        if (entitiesErrors) {
            errors.push(entitiesErrors);
        }

        if (this.checkListsComponent && this.checkListsComponent.isExistErrors()) {
            errors.push('Ошибки настройки проверочных листов');
        }
        if (errors.length > 0) {
            const allErrors = '<ul><li>' + errors.join('</li><li>') + '</li></ul>';
            this.toaster.html(allErrors);

            return false;
        }

        return true;
    }

    public async prepareCompleteData() {
        const promises = [];
        if (this.elementData.specialComponent) {
            if (this.setExecutorComponent) {
                promises.push(this.setExecutorComponent.complete());
            }
            if (this.transferToUnitComponent) {
                promises.push(this.transferToUnitComponent.complete());
            }
        }
        // завершение обработки проверочных листов
        if (this.checkListsComponent) {
            this.checkListsComponent.completeCheckLists();
        }
        // получение переменных для отправки в бизнес-процесс
        const paramsTransferToProcess = this.prepareTransferToProcessParams();
        if (Object.keys(paramsTransferToProcess).length) {
            promises.push(this.processService.setCamundaVariables(this.data.processId, paramsTransferToProcess));
        }

        // сохранение данных в реестре документов
        promises.push(
            this.processingEntitiesForRegistryService.saveDocumentsInRegistry(
                this.elementData,
                this.data.entity,
                TypesOfTasksEnum.APPEAL,
                this.router.url
            )
        );

        await Promise.all(promises);

        if (this.data.isOldVersion && this.elementData.useFunction && this.elementData.useFunction.onEnd) {
            // обработчик старой версии
            return this.oldProcessService[this.elementData.useFunction.functionName](
                this.externalData.appeal,
                this.externalData.subservice
            );
        }

        if (!this.elementData.handlers) {
            return true;
        }

        const endHandlers = this.elementData.handlers.filter((item) => item.event === 'onEnd');

        await this.internalHandlersService.processingHandlers(endHandlers, 'startBefore', 'onEnd', this.externalData);
        endHandlers.forEach((handler) => {
            try {
                this.handlerService.runHandler(handler, this.externalData);
            } catch (error) {
                console.error(error);
            }
        });

        await this.internalHandlersService.processingHandlers(endHandlers, 'startAfter', 'onEnd', this.externalData);
        if (this.data.entity.validationErrors && this.data.entity.validationErrors.length) {
            console.log('VALIDATION ERRORS', this.data.entity.validationErrors);
            this.toaster.html('<ul><li>' + this.data.entity.validationErrors.join('</li><li>') + ' </li></ul>');

            return true;
        }

        return true;
    }

    public prepareTransferToProcessParams() {
        let result = {};
        if (this.additionalDataTabComponents && this.additionalDataTabComponents.length > 0) {
            this.additionalDataTabComponents.forEach((additionalDataTabComponent) => {
                const params = additionalDataTabComponent.getDataByParam('isTransferToProcess');
                if (params) {
                    result = Object.assign(result, params);
                }
            });
        }
        if (this.entitiesComponent) {
            const entityParams = this.entitiesComponent.getTransferToProcessParams();
            result = Object.assign(result, entityParams);
        }

        return result;
    }

    public isExistSign() {
        return !!(
            this.data.savedPlace &&
            this.data.savedPlace.signData &&
            this.data.savedPlace.signData.find((item) => item.elementGuid === this.element.guid)
        );
    }

    public getSignData(document) {
        const signDataGuids = this.data.savedPlace.signData
            .filter((item) => item.elementGuid === this.element.guid)
            .map((item) => item.signGuid);

        return this.data.entity[document.field].signData.filter((item) => signDataGuids.indexOf(item.guid) !== -1);
    }

    public getFileCertificateInfo(file) {
        return CertificatesService.GetFileCertificateInfo(file);
    }

    public downloadFile(file) {
        this.filesService.downloadAndSaveFile(file._id, file.originalName);
    }

    public generatePrintForm(document) {
        document.isProcessGeneratePrintForm = true;
        this.standardsService.preparePrintFormData().then((baseData) => {
            console.log('baseData', baseData);

            this.progressService.start();
            this.printingService
                .renderPrintForm(
                    document.printForm,
                    this.data.entity._id,
                    this.data.entity.parentEntries,
                    baseData,
                    null,
                    null,
                    this.data.entity.unit.id
                )
                .then(
                    (response: any) => {
                        this.progressService.done();
                        document.isProcessGeneratePrintForm = false;
                        this.data.entity[document.field] = response.file;
                        this.downloadFile(this.data.entity[document.field]);
                    },
                    (error) => {
                        this.progressService.done();
                        if (error.errorMessage) {
                            this.toaster.html(error.errorMessage);
                        } else {
                            this.toaster.html(error);
                        }
                    }
                );
        });
    }

    /**
     * Подписание файла
     * @param document - подписываемый файл
     */
    public signFile(document) {
        this.document4Sign = document;
        this.file4Sign = this.data.entity[document.field];
        this.filesService.downloadFileInBase64(this.file4Sign._id, 'text').then(
            (fileInBase64) => {
                const blob = new Blob([fileInBase64], { type: 'text/plain' });
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const fileContent = e.target.result;
                    this.cadesSignModalComponent.commonSignData(
                        fileContent,
                        false,
                        'file',
                        this.file4Sign.originalName
                    );
                };
                reader.readAsText(blob);
            },
            (error) => {
                this.toaster.error(error);
            }
        );
    }

    /**
     * Обработка результата подписания для запроса
     * @param result - {certificate: ...., signature: .....}
     */
    public onSignatureComplete(result?) {
        if (result) {
            const user = this.storage.getItem('user');
            // Сохраняем подпись и сертификат для документа
            if (!this.file4Sign.signData) {
                this.file4Sign.signData = [];
            }
            const signGuid = CommonUtilities.GenerateGuid();
            const signData: any = {
                guid: signGuid,
                date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                user: {
                    id: user._id,
                    login: user.login,
                    name: user.name,
                    position: user.position,
                },
                certificate: result.certificate,
                signature: result.signature,
                certificateData: null,
            };
            if (result['certificateData']) {
                signData.certificateData = result.certificateData;
            }
            this.file4Sign.signData.push(signData);
            this.data.entity[this.document4Sign.field] = this.file4Sign;
            if (!this.data.savedPlace.signData) {
                this.data.savedPlace.signData = [];
            }
            this.data.savedPlace.signData.push({ elementGuid: this.element.guid, signGuid: signGuid });
        }
    }

    /**
     * Открытие сертификата
     * @param certificate -  сертификат в base64
     */
    public openCertificate(certificate) {
        const data = new Blob([certificate]);
        FileSaver.saveAs(data, 'certificate.cer');
    }

    /**
     * Переход в режим редактирования параметра задачи: сведения, документа и т.д.
     * @param property - параметр, который в данный момент редактируется
     */
    public startEditProperty(property) {
        this.editedProperty = property;
    }

    /**
     * Выход из режима редактирования параметра задачи
     */
    public endEditProperty() {
        this.editedProperty = null;
    }

    public isBlockVisible(blockField, mainProperty = null) {
        return (
            ((this.elementData &&
                this.elementData.params &&
                this.elementData.params[blockField] &&
                this.elementData.params[blockField].length > 0) ||
                this.elementData[mainProperty]) &&
            (!this.editedProperty || this.editedProperty === blockField)
        );
    }

    public saveResultToXsd(result) {
        if (result && this.elementData.params.xsdTabs && this.elementData.params.xsdTabs.length > 0) {
            const tabData = this.elementData.params.xsdTabs[0];
            const savedPlace = this.data.savedPlace;
            if (!savedPlace.xsdData) {
                savedPlace.xsdData = {};
            }
            if (!savedPlace.xsdData[tabData.tabCode ? tabData.tabCode : tabData.code]) {
                savedPlace.xsdData[tabData.tabCode ? tabData.tabCode : tabData.code] = {};
            }
            Object.keys(result).forEach((key) => {
                if (key.indexOf('date') !== -1 || key.indexOf('Date') !== -1) {
                    result[key] = moment(result[key]).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
                }
            });
            savedPlace.xsdData[tabData.tabCode ? tabData.tabCode : tabData.code] = Object.assign(
                savedPlace.xsdData[tabData.tabCode ? tabData.tabCode : tabData.code],
                result
            );
        }
    }

    public getQuickRequests() {
        const quickRequestsSettings = this.storage.getItem('quickRequestsSettings');
        const requests = [];
        if (this.element && this.element.quickRequests) {
            Object.keys(this.element.quickRequests).forEach((key) => {
                const quickRequests = this.element.quickRequests[key];
                quickRequests.forEach((request) => {
                    requests.push({
                        id: request.id,
                        auid: request.auid,
                        name: quickRequestsSettings[key].name,
                    });
                });
            });
        }

        return requests;
    }
}
