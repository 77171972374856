import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AccessService, FilesService, HttpService, RestService, StorageService, ToasterService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';
import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { CadesSignModalComponent } from '@evolenta/signing';

import { CommonAppealDocumentService } from '../../common-appeal-document.service';
import { ClientModuleService } from '../../../../../../common/services/client-module.service';
import { Permission } from '../../../../../../common/services/permission';
import { CommonAppealSaveService } from '../../../../services/common-appeal-save.service';
import { PrintFormsService } from '../../../../../../common/services/print/print-forms.service';
import { Config } from '../../../../../../common/services/config';
import { CertificatesService } from '../../../../../../common/services/certificates.service';
import { ActivatedRouteService } from '../../../../../../common/services/activated-route.service';
import { EnvelopesService } from '../../../../../../common/services/envelopes.service';
import { ErrorLoggingService } from '../../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-document-files',
    templateUrl: 'common-appeal-document-files.component.html',
    styles: [':host ::ng-deep .ui-select-toggle > .caret { top: 25%!important; }'],
})
export class CommonAppealDocumentFilesComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public task;
    @Input() public allowEdit = true;
    @Input() public appeal = null;
    @Input() public mode = 'view';
    @Input() public multiple = true;

    @Output() public onDeleteFile = new EventEmitter<boolean>();
    @Output() public isCheckFileSign = new EventEmitter<boolean>();
    @Output() public inspectorChange = new EventEmitter<boolean>();

    public file4Sign = null; // Файл, отправленный на подписание
    public file4VerifySign = null; // Файл, отправленный для КНМ подписи

    @ViewChild(CadesSignModalComponent, { static: false }) public cadesSignModalComponent: CadesSignModalComponent;

    public data: any = {}; // описательный объект для документа

    public permissions = Permission; // Набор прав системы
    public uploadedFileMaxSize = 0;
    public uploadedFileMaxSizeInMb = 0;
    public isProcessingScan = false;
    public currentOrganization = this.storage.getItem('currentOrganization');
    public userEnvelopesMode = this.storage.getItem('userEnvelopesMode');
    public applications = this.storage.getItem('applications');
    public user = this.storage.getItem('user');
    public readyFiles = [];

    public constructor(
        public documentService: CommonAppealDocumentService,
        public accessService: AccessService,
        public activatedRouteService: ActivatedRouteService,
        private route: ActivatedRoute,
        private filesService: FilesService,
        private toaster: ToasterService,
        private clientModuleService: ClientModuleService,
        private printFormsService: PrintFormsService,
        private appealSaveService: CommonAppealSaveService,
        private storage: StorageService,
        private rest: RestService,
        private httpService: HttpService,
        private saveService: CommonAppealSaveService,
        private errorLoggingService: ErrorLoggingService
    ) {
        this.activatedRouteService.processRoute(this.route);
    }

    public ngOnInit() {
        // Максимальный размер файла для загрузки
        const uploadedFileMaxSize = this.storage.getItem('uploadedFileMaxSize');
        if (uploadedFileMaxSize === undefined || uploadedFileMaxSize === null) {
            this.rest
                .search('settings', {
                    search: { search: [{ field: 'name', operator: 'eq', value: 'uploadedFileMaxSize' }] },
                })
                .then((setting) => {
                    if (setting && setting.length > 0) {
                        const sizeInMb = parseInt(setting[0].value, 10);
                        const maxSizeInBt = sizeInMb * 1024 * 1024;
                        this.uploadedFileMaxSizeInMb = sizeInMb;
                        this.uploadedFileMaxSize = maxSizeInBt;
                        this.storage.setItem('uploadedFileMaxSize', maxSizeInBt);
                    } else {
                        this.storage.setItem('uploadedFileMaxSize', 0);
                    }
                });
        } else {
            this.uploadedFileMaxSize = uploadedFileMaxSize;
            this.uploadedFileMaxSizeInMb = this.uploadedFileMaxSize / (1024 * 1024);
        }
        this.data = this.documentService.data[this.document.guid];
    }

    public ngAfterViewInit() {
        // Корректировка флага наличия непросмотренного результата в запросе и в деле
        setTimeout(() => {
            this.correctEnvelopeAndAppealHavingResultFlag();
        });
    }

    public allowFileAccess(file: any): boolean {
        return (
            !this.userEnvelopesMode ||
            file.entryName !== 'envelopes' ||
            !this.data.envelope ||
            EnvelopesService.AllowAccess(this.user, this.data.envelope, this.applications)
        );
    }

    /**
     * Добавление файла в очередь загрузки
     * @param event
     */
    public fileChange(event) {
        const filesList = event.target.files;
        if (filesList.length > 0) {
            Array.from(filesList).forEach(async (file) => {
                if ((await this._isValidFileFormat(file)) && (await this._isValidFileMaxSize(file))) {
                    this.data.queue.push(file);
                }
            });
        }
    }

    private async _isValidFileMaxSize(file: any): Promise<boolean> {
        if (this.uploadedFileMaxSize !== 0 && file.size > this.uploadedFileMaxSize) {
            const errorText = `Файл ${file.name} не может быть загружен! Размер файла превышает ${this.uploadedFileMaxSizeInMb}Мб`;
            this.toaster.error(errorText);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

            return false;
        }

        return true;
    }

    public isFinancialControl(): boolean {
        return this.activatedRouteService.moduleBaseUrl === 'financial-controlling';
    }

    public showButtonsForWorkingWithFiles(): boolean {
        return (
            this.mode === 'edit' &&
            !this.document.requestId &&
            (this.data.allowEdit || this.data.allowPartiallyEdit) &&
            (this.accessService.hasAccess([this.permissions.File_Operations]) ||
                this.accessService.hasAccess([this.permissions.Documents_Scan]))
        );
    }

    private async _isValidFileFormat(file: any): Promise<boolean> {
        // Если указан допустимый формат файла
        if (this.group.scanFormat) {
            let fileExt;
            const nameArr = file.name.split('.');
            if (nameArr.length > 1) {
                fileExt = nameArr[nameArr.length - 1].toLowerCase();
                if (fileExt !== this.group.scanFormat) {
                    const errorText = `Недопустимый формат файла: ${fileExt}. Допустим для выбора файл в формате: ${this.group.scanFormat}`;
                    this.toaster.error(errorText);
                    await this.errorLoggingService.log(this.errorLoggingService.SPO, new Error(errorText));

                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Открытие файла (скачивание) еще не сохраненного на сервере
     * @param file
     */
    public openFile(file) {
        FileSaver.saveAs(file, file.name);
    }

    public checkFileIsRequestXml(file) {
        if (this.document.requestId) {
            const fileName = file.name;
            const arr = fileName.split('.');
            const fileExtension = arr[arr.length - 1].toLowerCase();

            return fileExtension === 'xml';
        }

        return false;
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public downloadFile(id, originalName) {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    /**
     * Подписание файла
     * @param file - подписываемый файл
     */
    public async signFile(file) {
        this.file4Sign = file;
        try {
            const fileInBase64 = await this.filesService.downloadFileInBase64(this.file4Sign._id, 'text');
            const blob = new Blob([fileInBase64], { type: 'text/plain' });
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const fileContent = e.target.result;
                this.cadesSignModalComponent.commonSignData(fileContent, false, 'file', this.file4Sign.originalName);
            };
            reader.readAsText(blob);
        } catch (error) {
            this.toaster.error(error);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
        }
    }

    /**
     * Обработка результата подписания для запроса
     * @param result - {certificate: ...., signature: .....}
     */
    public async onSignatureComplete(result?) {
        if (!result) {
            return;
        }

        // Сохраняем подпись и сертификат для документа
        this.file4Sign.certificate = result.certificate;
        this.file4Sign.signature = result.signature;
        if (result['certificateData']) {
            this.file4Sign.certificateData = result.certificateData;
        }
        const fileIndex = this.data.queue.findIndex((item) => item._id === this.file4Sign._id);
        this.data.queue[fileIndex] = this.file4Sign;

        await this.saveService.saveAppeal();
    }

    /**
     * Открытие сертификата
     * @param certificate - сертификат в base64
     */
    public openCertificate(certificate) {
        const data = new Blob([certificate]);
        FileSaver.saveAs(data, 'certificate.cer');
    }

    /**
     * КНМ подписи файла
     * @param file - файл
     */
    public async verifySignFile(file, arr = null) {
        this.file4VerifySign = file;
        try {
            const fileInBase64 = await this.filesService.downloadFileInBase64(this.file4VerifySign._id, 'text');
            const blob = new Blob([fileInBase64], { type: 'text/plain' });
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const fileContent = e.target.result;
                if (arr) {
                    this.cadesSignModalComponent.verifyCadesBesSign(arr, fileContent);
                } else {
                    this.cadesSignModalComponent.verifyCadesBesSign(this.file4VerifySign.signature, fileContent);
                }
            };
            reader.readAsText(blob);
        } catch (error) {
            this.toaster.error(error);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
        }
    }

    /**
     * Удаление прикрепленного файла из документа
     * @param file - удаляемый файл
     */
    public removeFile(file) {
        let index = this.data.queue.indexOf(file);
        if (index !== -1) {
            this.data.queue.splice(index, 1);
        }
        index = this.document.files.indexOf(file);
        if (index !== -1) {
            this.document.files.splice(index, 1);
        }
        this.onDeleteFile.emit(true);
    }

    /**
     * Сканирование файлов с помощью клиентского модуля
     */
    public async scanFiles() {
        this.isProcessingScan = true;
        try {
            const result: any = await this.clientModuleService.scanDocument(
                this.documentService.appeal._id,
                this.group.scanFormat
            );
            // Если в результате есть, что сохранять, то выставляем признак массового сохранения
            let amount = 0;
            let errorTransfer;
            result.forEach((scanResultItem) => {
                if (scanResultItem.isMultipartTransfer) {
                    if (scanResultItem.isSuccessMultipartTransfer) {
                        this.data.queue.push(scanResultItem.savedFile);
                        if (scanResultItem.totalSheets) {
                            amount = amount + scanResultItem.totalSheets;
                        }
                    } else {
                        errorTransfer = scanResultItem.transferError;
                    }
                } else {
                    this.data.queue.push(
                        new File([this.base64ToBlob(scanResultItem.content, '', 512)], scanResultItem.name)
                    );
                    if (scanResultItem.totalSheets) {
                        amount = amount + scanResultItem.totalSheets;
                    }
                }
            });

            if (this.group.copy) {
                this.data.copiesSheets = this.data.copiesSheets + amount;
                if (this.data.copies === 0) {
                    this.data.copies = 1;
                }
            } else {
                this.data.originalsSheets = this.data.originalsSheets + amount;
                if (this.data.originals === 0) {
                    this.data.originals = 1;
                }
            }
            this.isProcessingScan = false;
            if (errorTransfer) {
                this.toaster.error(errorTransfer);
                await this.errorLoggingService.log(this.errorLoggingService.SPO, errorTransfer);
            }
        } catch (error) {
            this.isProcessingScan = false;
            await this.errorLoggingService.log(this.errorLoggingService.SPO, error);
        }
    }

    /**
     * Преобразование файла base64 в blob
     * @param base64Data - файл в base64
     * @param contentType - тип контента
     * @param sliceSize
     * @returns {Blob}
     */
    public base64ToBlob(base64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    /**
     * Обработка результата КНМ подписи Cades BES
     * @param result -  - {isValid: ....}
     */
    public onVerifyCadesBesSignComplete(result?) {
        if (result) {
            this.file4VerifySign.isSignValid = result.isValid;
        }
    }

    /**
     * Получение информации о подписи
     * @param file
     * @returns {any}
     */
    public getFileCertificateInfo(file, arr = false) {
        if (arr) {
            return CertificatesService.GetFileCertificateInfoArr(file.certificateDataArr);
        }

        return CertificatesService.GetFileCertificateInfo(file);
    }

    /**
     * Повторная генерация результирующего файла PDF для скачивания без прикрепления к запросу
     */
    public generateResultPdfForResultDoc(fileEnvelopeDocGroupGuid) {
        this.printFormsService.generateEnvelopeResultPDF(this.data.envelope._id, fileEnvelopeDocGroupGuid);
    }

    /**
     * Корректировка флага наличия непросмотренного результата в запросе и в деле
     */
    public correctEnvelopeAndAppealHavingResultFlag() {
        if (this.data.envelope && this.data.envelope.isHavingResult) {
            this.data.envelope.isHavingResult = false;
            // КНМ наличия флага для других запросов
            let isHavingResultInAppeal = false; // наличие непросмотренных результатов в других запросах
            Object.keys(this.documentService.data).forEach((documentGuid) => {
                const documentData = this.documentService.data[documentGuid];
                if (documentData.envelope && documentData.envelope.isHavingResult) {
                    isHavingResultInAppeal = true;
                }
            });
            this.documentService.appeal.isHavingResult = isHavingResultInAppeal;
            // Заменяем данные, чтобы при нажатии кнопки "Закрыть" они не заменились
            this.documentService.tempData = cloneDeep(this.documentService.data);
            // Сохраняем изменения в конверте и в деле (если в этом есть необходимость - все результаты во всех запросах обработаны)

            this.appealSaveService.saveAppeal();
        }
    }

    public fileIsReady(fileId) {
        return this.readyFiles.includes(fileId);
    }

    public onFileDelete(event) {
        this.onDeleteFile.emit(event);
    }

    public onChangeInspector(event) {
        this.inspectorChange.emit(event);
    }

    public onCheckSignFile(event) {
        this.isCheckFileSign.emit(true);
    }

    public createSignFile(file) {
        if (file.signature) {
            const data = new Blob([file.signature]);
            FileSaver.saveAs(data, 'signature.sig');
        }
    }

    public isAllowCreatePdfWithSignature(file) {
        const formats = [
            'odt',
            'ott',
            'fodt',
            'docx',
            'dotx',
            'doc',
            'html',
            'xhtml',
            'rtf',
            'txt',
            'sxw',
            'wpd',
            'ods',
            'ots',
            'fods',
            'xlsx',
            'xltx',
            'xls',
            'csv',
            'tsv',
            'sxc',
            'odp',
            'otp',
            'fodp',
            'pptx',
            'ppt',
            'sxi',
            'odg',
            'otg',
            'fodg',
            'pdf',
            'swf',
            'vsdx',
            'vsd',
            'bmp',
            'gif',
            'jpg',
            'jpeg',
            'png',
            'svg',
            'tif',
            'tiff',
        ];
        const fileNameData = file.originalName.split('.');
        const extension = fileNameData[fileNameData.length - 1].toLowerCase();

        return formats.indexOf(extension) !== -1;
    }

    public createPdfWithSignatureFile(file) {
        this.httpService
            .getContentFile(
                Config.server + Config.api + 'rendering/transformToPdf/intFileWithSig?fileId=' + file._id,
                'blob'
            )
            .then((response: any) => {
                const fileName = file.originalName;
                const fileNameArr = fileName.split('.');
                const clearFileName = fileNameArr.slice(0, fileNameArr.length - 1).join('.');

                const data = new Blob([response]);
                FileSaver.saveAs(data, clearFileName + '_signed.pdf');
            });
    }
}
